import styled from 'styled-components'
import { Box, Stack } from '@mui/material'

interface AccessCardItemProps {
  isObject?: boolean;
}

export const AccessCardWrapper = styled(Stack)`
  padding: 10px 10px 20px 20px;
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 8px;
`

export const AccessCardItem = styled(Box)<AccessCardItemProps>`
  width: 100%;
  max-width: 310px;
  padding: 8px 16px;
  text-align: ${props => props.isObject ? 'center' : 'left'};
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 6px;
  
  & .MuiTypography-root {
    color: ${props => props.theme.palette.text.dark};
  }
`

export const CheckboxesWrapper = styled(Stack)`
  width: 100%;
  max-width: 310px;
`
