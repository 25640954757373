import { Button, IconButton, Stack, Typography } from "@mui/material";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import styled from "styled-components";
import { theme } from "../../../../../../../styles/theme";

const checkedColor = theme.palette.secondary.main
const disabledColor = theme.palette.text.dark + '1F'
const approvalColor = theme.palette.warning.light

export const StyledMoveCell = styled(IconButton)`
  padding: 0 6px;
  color: ${props => props.theme.palette.legends.text};
  
  & .icon-hover {
    display: none;
  }

  &:hover {
    background-color: transparent;

    & .icon {
      display: none;
    }

    & .icon-hover {
      display: block;
    }
  }

`
export const StyledName = styled(Typography)`
  font-size: 14px;
  color: ${props => props.theme.palette.text.dark};
  min-width: 230px;
  text-align: start;
`
export const StyledPosition = styled(Typography)`
  font-size: 14px;
  color: ${props => props.theme.palette.text.dark};
  min-width: 250px;
  width: 100%;
`
export const IconWrapper = styled(Stack)`
  min-width: 110px;
  justify-content: center;
  //margin-left: 0!important;
`

export const ApprovalIcon = styled(DoneAllIcon)<{$approval: boolean}>`
  color: ${props => props.$approval ? approvalColor : disabledColor};
`
export const AssuranceIcon = styled(DoneAllIcon)<{$assurance: boolean}>`
  color: ${props => props.$assurance ? checkedColor : disabledColor};
`
export const SigningIcon = styled(DoneAllIcon)<{$signing: boolean}>`
  color: ${props => props.$signing ? checkedColor : disabledColor};
`
export const RemarksIcon = styled(DoneAllIcon)<{$remarks: boolean}>`
  color: ${props => props.$remarks ? checkedColor : disabledColor};
`

export const StyledIconButton = styled(IconButton)<{$color?: string}>`
  padding: 6px;
  border-radius: 6px;
  height: 30px;
  background-color: ${props => props.$color === 'lightBlue'
    ? props.theme.palette.bg.lightBlue
    : props.theme.palette.bg.white};

  &:hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }
`

export const StyledButton = styled(Button)`
  text-transform: none;
  padding: 6px 10px;
  font-weight: 400;
  height: 30px;
`