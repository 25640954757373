import React, { FC, useEffect, useMemo, useState } from 'react'
import { ExportArchiveDrawerFormProps, ExportArchiveFormData } from './ExportArchiveDrawerForm.types'
import { ExportArchiveDrawerFormWrapper, ExportArchiveDrawerSwitchesWrapper } from './styles'
import { Form, FormikProvider } from 'formik'
import { Button, Stack, Typography } from '@mui/material'
import { useForm } from '../../../../../../hooks/useForm'
import FieldForm from '../../../../../../components/FieldForm'
import { theme } from '../../../../../../styles/theme'
import { ExportArchiveDrawerMainList } from '../ExportArchiveDrawerMainList'
import { ExportArchiveDrawerSelectedList } from '../ExportArchiveDrawerSelectedList'
import { getExportArchiveValidation } from './validation'
import { useSnackbar } from 'notistack'
import { useExportInitialValues } from './hooks/useExportInitialValues'
import { useExportArchiveSubmit } from './hooks/useExportArchiveSubmit'
import { useCheckExportSubmitErrors } from './hooks/useCheckExportSubmitErrors'
import { useAppDispatch, useTypedSelector } from '../../../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../../../store/slices/documentsPages/projectInfo'
import { onDrawerClose } from '../../../../../../store/slices/documentsPages/drawerInfo'
import Tooltip from '../../../../../../components/Tooltip'
import { ExportIcon } from '../../../../../../assets/icons/ExportIcon'
import { useExportTomsRdMutation } from '../../../../../../api/rdPhase'
import { useExportTomsPdMutation } from '../../../../../../api/pdPhase'
import { useExportTomsIiMutation } from '../../../../../../api/iiPhase'
import { useExportTomsIrdMutation } from '../../../../../../api/irdPhase'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import { downloadBlob } from '../../../../../../utils/dowloadBlob'
import { isEqual } from 'lodash'

export const ExportArchiveDrawerForm: FC<ExportArchiveDrawerFormProps> = ({ onFormChange }) => {
  const dispatch = useAppDispatch()

  const { enqueueSnackbar } = useSnackbar()
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  const { initialPhaseValues } = useExportInitialValues()

  const initialValues: ExportArchiveFormData = useMemo(() => ({
    includeChanges: false,
    includeAttach: false,
    includeExcel: false,
    ...initialPhaseValues,
  }), [initialPhaseValues])


  const validationExportArchive = useMemo(() => {
    return getExportArchiveValidation(selectedProjectPhase)
  }, [selectedProjectPhase])

  const { onSubmit } = useExportArchiveSubmit()

  const { formik } = useForm({
    validationSchema: validationExportArchive,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, errors, dirty, isSubmitting } = formik
  const { minTomsCountError } = useCheckExportSubmitErrors({ errors })
  const [isValuesDirty, setIsValuesDirty] = useState<boolean>(false)

  useEffect(() => {
    if (isSubmitting && minTomsCountError) {
      enqueueSnackbar('Выберите минимум один том', { variant: 'error' })
    }
  }, [isSubmitting, errors])

  useEffect(() => {
    const { iiFileList, irdFileList, pdFileList, rdFileList, ...localInitialValues } = initialValues
    const {
      iiFileList: iiFileListValues,
      irdFileList: irdFileListValues,
      pdFileList: pdFileListValues,
      rdFileList: rdFileListValues,
      ...localValues
    } = values

    setIsValuesDirty(!isEqual(localInitialValues, localValues))
  }, [initialValues, values])

  useEffect(() => {
    onFormChange(isValuesDirty)
  }, [isValuesDirty])

  const [exportTomsRd, exportTomsRdResponse] = useExportTomsRdMutation()
  const [exportTomsPd, exportTomsPdResponse] = useExportTomsPdMutation()
  const [exportTomsIi, exportTomsIiResponse] = useExportTomsIiMutation()
  const [exportTomsIrd, exportTomsIrdResponse] = useExportTomsIrdMutation()

  const onExportClick = () => {
    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        exportTomsRd({ id: project.id })
        return
      case 'Проектная документация':
        exportTomsPd({ id: project.id })
        return
      case 'Инженерные изыскания':
        exportTomsIi({ id: project.id })
        return
      case 'Сбор исходных данных':
        exportTomsIrd({ id: project.id })
        return
    }
  }

  useMutationHandlers(
    exportTomsRdResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `${project.shifrName} РД.xlsx`)
    }
  )

  useMutationHandlers(
    exportTomsPdResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `${project.shifrName} ПД.xlsx`)
    }
  )

  useMutationHandlers(
    exportTomsIiResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `${project.shifrName} Изыскания.xlsx`)
    }
  )

  useMutationHandlers(
    exportTomsIrdResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `${project.shifrName} ИРД.xlsx`)
    }
  )

  return (
    <ExportArchiveDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} component={Form} justifyContent='space-between' height='100%'>
          <Stack direction='row' justifyContent='space-between' spacing={2.5} overflow='hidden' height='100%'>
            <ExportArchiveDrawerMainList />
            <ExportArchiveDrawerSelectedList />
          </Stack>


          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <ExportArchiveDrawerSwitchesWrapper>
              <Tooltip title='Добавить к экспортируемому архиву реестр в Excel'>
                <div>
                  <FieldForm
                    version='checkbox'
                    name='includeExcel'
                    checkboxData={{
                      label: (
                        <Typography
                          variant='subtitle2'
                          color={theme.palette.text.dark}
                          fontWeight={400}
                          component='span'
                        >
                          Включить Excel отчет
                        </Typography>
                      ),
                      checked: values.includeExcel,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          `includeExcel`,
                          e.target.checked
                        )
                      }
                    }}
                  />
                </div>
              </Tooltip>

              <Tooltip title='Добавить к экспортируемому архиву редактируемые версии документов'>
                <div>
                  <FieldForm
                    version='checkbox'
                    name='includeAttach'
                    checkboxData={{
                      label: (
                        <Typography
                          variant='subtitle2'
                          color={theme.palette.text.dark}
                          fontWeight={400}
                          component='span'
                        >
                          Ред. версии
                        </Typography>
                      ),
                      checked: values.includeAttach,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          `includeAttach`,
                          e.target.checked
                        )
                      }
                    }}
                  />
                </div>
              </Tooltip>

              <Tooltip title='Добавить к экспортируемому архиву все изменения документов'>
                <div>
                  <FieldForm
                    version='checkbox'
                    name='includeChanges'
                    checkboxData={{
                      label: (
                        <Typography
                          variant='subtitle2'
                          color={theme.palette.text.dark}
                          fontWeight={400}
                          component='span'
                        >
                          Изменения док-тов
                        </Typography>
                      ),
                      checked: values.includeChanges,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          `includeChanges`,
                          e.target.checked
                        )
                      }
                    }}
                  />
                </div>
              </Tooltip>
            </ExportArchiveDrawerSwitchesWrapper>

            <Stack direction='row' spacing={2}>
              <Tooltip title='Экспортировать реестр в Excel'>
                <Button
                  onClick={onExportClick}
                  color='success'
                  size='small'
                  sx={{ minWidth: 40, padding: '8px' }}
                >
                  <ExportIcon />
                </Button>
              </Tooltip>

              <Tooltip title='Сформировать и скачать архив с рабочей документацией'>
                <Button
                  type='submit'
                  disabled={isSubmitting}
                  color='success'
                  size='small'
                >
                  Сформировать
                </Button>
              </Tooltip>

              <Button
                size='small'
                onClick={() => dispatch(onDrawerClose({ dirty: isValuesDirty, immediately: false }))}
              >
                Отменить
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormikProvider>
    </ExportArchiveDrawerFormWrapper>
  )
}
