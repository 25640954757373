import styled from 'styled-components'
import { Stack, Typography } from '@mui/material'

export const ProjectAgreementSchemeEmptyWrapper = styled(Stack)`
  flex: 1;
  gap: 30px;
  align-items: center;
  max-height: 100%;
`

export const ProjectAgreementSchemeTitleText = styled(Typography)`
  font-weight: 400;
  color: ${props => props.theme.palette.primary.light};
`

export const ProjectAgreementSchemeText = styled(Typography)`
`