import { Stack } from "@mui/material"
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import GroupIcon from '@mui/icons-material/Group';
import { NavbarSchemaItemDuration, NavbarSchemaItemSubtitle, NavbarSchemaItemTitle, NavbarSchemaItemWrapper } from "./NavbarSchemaItem.styles"
import { NavbarSchemaItemProps } from "./NavbarSchemaItem.types"

export const NavbarSchemaItem = ({ onInnermapClick, schema, selected }: NavbarSchemaItemProps) => {
  const { isBroken, title, moderator, duration, unit, members } = schema
  const unitSelector = unit === 'day' ? 'дн' : 'ч'

  return (
    <NavbarSchemaItemWrapper
      onClick={() => onInnermapClick(schema)}
      selected={selected}
      isBroken={isBroken && members > 0}>
      <Stack>
        <NavbarSchemaItemTitle variant='body2'>
          {title}
        </NavbarSchemaItemTitle>
        <NavbarSchemaItemSubtitle variant='body2'>
          {moderator.name}
        </NavbarSchemaItemSubtitle>
      </Stack>
      <Stack direction='row' alignItems='center' spacing={1}>
        <Stack direction='row' alignItems='center' spacing={0.75}>
          <GroupIcon fontSize="small" color="secondary" />
          <NavbarSchemaItemSubtitle>{members}</NavbarSchemaItemSubtitle>
        </Stack>
        {isBroken && members > 0 && (
          <WarningAmberIcon fontSize='medium' color='warning' />
        )}
        {duration && (
          <Stack direction='row' alignItems='center' spacing={0.75}>
            <WatchLaterIcon fontSize='medium' color='info' />
            <NavbarSchemaItemDuration>
              {duration} {unitSelector}
            </NavbarSchemaItemDuration>
          </Stack>
        )}
      </Stack>
    </NavbarSchemaItemWrapper>
  )
}