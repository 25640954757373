import DeleteIcon from '@mui/icons-material/Delete'
import { InternalAgreementDeleteButton } from './DeleteSchemaButton.styles'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../hooks/useConfirmDialog'

interface DeleteSchemaButtonProps {
  confirmDeleteSchema: () => void
  schemaName: string
}

export const DeleteSchemaButton = ({ confirmDeleteSchema, schemaName }: DeleteSchemaButtonProps) => {

  const confirmDelete = (confirm: boolean) => {
    if (confirm) {
      confirmDeleteSchema()
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmDelete,
    title: 'Удалить схему согласования?',
    body: (<>Схема внутреннего согласования "{schemaName}" будет удалена без возможности восстановить.</>),
    denyButtonText: 'Отменить',
    confirmButtonColor: 'error'
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  return (
    <>
      <InternalAgreementDeleteButton onClick={openConfirm}>
        <DeleteIcon />
      </InternalAgreementDeleteButton>
      <ConfirmDialog />
    </>
  )
}