import { Divider, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledTyporapy = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
  margin-left: 8px;
`

export const StyledDivider = styled(Divider)`
  margin: 0!important;
  border-color: #D1D8FA;
`