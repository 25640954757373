import React from 'react'
import Divider from '../../../../components/Divider'
import ProjectCardHeader from './ProjectCardHeader'
import ProjectCardContent from './ProjectCardContent'
import { ProjectCardProps } from './ProjectCard.types'
import { ProjectCardHeaderData } from './ProjectCardHeader/ProjectCardHeader.types'
import { ProjectCardContentData } from './ProjectCardContent/ProjectCardContent.types'
import { FullUserCardWrapper } from '../../AdminUsers/AdminUsers.styles'

const ProjectCard: React.FC<ProjectCardProps> = ({ data, onClick }) => {
  const { id, shifrName, shortName, status, phase, type, startDate, endDate } = data

  const headerData: ProjectCardHeaderData = { id, status, shortName, shifrName, phase }
  const contentData: ProjectCardContentData = { startDate, endDate, type }

  return (
    <FullUserCardWrapper onClick={() => onClick(id)}>
      <ProjectCardHeader data={headerData} />
      <Divider />
      <ProjectCardContent data={contentData} />
    </FullUserCardWrapper>
  )
}

export default ProjectCard