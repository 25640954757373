import { FC, MouseEvent, ReactNode, useCallback, useState } from 'react'
import ColoredTitle from '../../../../../../components/ColoredTitle'
import { WorkStatus, workStatuses, workStatusesColors } from '../../../../../../types/works'
import { IconButton, Menu, Stack } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { StatusCellWrapper } from './StatusCell.styles'
import { StyledSelectMenuItem } from '../../../../../../components/UserManagement/components/UserRoleSelection/styles'
import { useEditTomStatusMutation, useEditWorkMutation } from '../../../../../../api/works'
import { useParams } from 'react-router-dom'
import { SelfCellProps } from '../cells.types'
import { TABLE_CELL_HEIGHT } from '../../WorksTable.types'
import { StyledTableCell } from '../../WorksTable.styles'
import { StatusCellView } from './StatusCell.types'
import { TomStatus, tomStatusesArray } from '../../../../../../types/tom'

export const StatusCell: FC<SelfCellProps> = ({ work, ...cellProps }) => {
  const { id: workId, status, period, bind } = work
  const { isExpired } = period

  const { projectId: projectIdStr, scheduleId: scheduleIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)
  const scheduleId = parseInt(scheduleIdStr!)

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [editWork] = useEditWorkMutation()
  const [editTomStatus] = useEditTomStatusMutation()

  const onStatusCellClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setMenuAnchor(e.currentTarget)
  }, [])

  const onMenuClose = useCallback(() => {
    setMenuAnchor(null)
  }, [])

  const onMenuClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  const currentStatusCellView: StatusCellView = bind.tom ? 'tom' : 'work'

  const WorkColoredStatus = (
    <ColoredTitle
      body={status}
      color={workStatusesColors[status]}
    />
  )

  const TomColoredStatus = (
    <ColoredTitle
      body={bind.status || ''}
      color={workStatusesColors[status]}
    />
  )

  const ColoredStatus: Record<StatusCellView, ReactNode> = {
    work: WorkColoredStatus,
    tom: TomColoredStatus,
  }

  const menuData: Record<StatusCellView, string[]> = {
    work: workStatuses,
    tom: tomStatusesArray.filter(status => status === 'Не согласуется' ? bind.phase === 'Сбор исходных данных' : true),
  }

  const onWorkMenuItemClick = useCallback((status: WorkStatus) => {
    setMenuAnchor(null)
    editWork({ id: projectId, scheduleId, workId, status })
  }, [projectId, workId])

  const onTomMenuItemClick = useCallback((status: TomStatus) => {
    setMenuAnchor(null)
    if (!bind.phase || !bind.tom) return

    editTomStatus({
      id: projectId,
      phase: bind.phase,
      newStatus: status,
      tom: bind.tom,
    })
  }, [projectId, bind])

  const onMenuItemClick: Record<StatusCellView, (status: string) => void> = {
    work: (status) => onWorkMenuItemClick(status as WorkStatus),
    tom: (status) => onTomMenuItemClick(status as TomStatus),
  }


  return (
    <StyledTableCell
      {...cellProps}
      onClick={onStatusCellClick}
    >
      <Stack height={TABLE_CELL_HEIGHT} justifyContent='center'>
        <StatusCellWrapper>
          {ColoredStatus[currentStatusCellView]}
          <IconButton>
            <KeyboardArrowDownIcon fontSize='small' />
          </IconButton>
        </StatusCellWrapper>

        <Menu
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClick={onMenuClick}
          onClose={onMenuClose}
        >
          {menuData[currentStatusCellView].map(menuStatus => {
              const checked: Record<StatusCellView, boolean> = {
                work: status === menuStatus,
                tom: bind.status === menuStatus,
              }

              return (
                <StyledSelectMenuItem
                  onClick={() => onMenuItemClick[currentStatusCellView](menuStatus)}
                  style={{ maxWidth: '100%' }}
                  value={menuStatus}
                  checked={checked[currentStatusCellView]}
                  key={menuStatus}
                >
                  {menuStatus}
                </StyledSelectMenuItem>
              )
            }
          )}
        </Menu>
      </Stack>
    </StyledTableCell>
  )
}
