import { api } from '../api'
import {
  BindInnermapRequest,
  BindInnermapResponse,
  CreateRoadmapRequest,
  CreateRoadmapResponse,
  CreateStageRequest,
  CreateStageResponse,
  DeleteRoadmapRequest,
  DeleteRoadmapResponse,
  DeleteStageRequest,
  DeleteStageResponse,
  EditRoadmapRequest,
  EditRoadmapResponse,
  EditStageRequest,
  EditStageResponse,
  getNextStagesRequest,
  getNextStagesResponse,
  GetProjectStatusStatisticsRequest,
  GetProjectStatusStatisticsResponse,
  GetRoadmapByIdRequest,
  GetRoadmapByIdResponse,
  GetRoadmapsMenuRequest,
  GetRoadmapsMenuResponse,
  GetStageByIdRequest,
  GetStageByIdResponse,
  GetStagesRequest,
  GetStagesResponse,
  SetRoadmapMainRequest,
  SetRoadmapMainResponse
} from './api.types'

export const projectAgreementApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjectStatusStatistics: build.query<GetProjectStatusStatisticsResponse, GetProjectStatusStatisticsRequest>({
      query: ({ projectId, ...body }) => ({
        url: `/project/${projectId}/roadmap/statistic`,
        method: 'POST',
        body,
      }),
    }),
    getRoadmapsMenu: build.query<GetRoadmapsMenuResponse, GetRoadmapsMenuRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/roadmap/menu`,
        method: 'GET',
      }),
      providesTags: ['Agreement'],
    }),
    createRoadmap: build.mutation<CreateRoadmapResponse, CreateRoadmapRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/roadmap/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreement'],
    }),
    getRoadmapById: build.query<GetRoadmapByIdResponse, GetRoadmapByIdRequest>({
      query: ({ id, roadmapId }) => ({
        url: `/project/${id}/roadmap/${roadmapId}/get`,
        method: 'GET',
      }),
      providesTags: ['Agreement'],
    }),
    editRoadmap: build.mutation<EditRoadmapResponse, EditRoadmapRequest>({
      query: ({ id, roadmapId, ...body }) => ({
        url: `/project/${id}/roadmap/${roadmapId}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreement'],
    }),
    deleteRoadmap: build.mutation<DeleteRoadmapResponse, DeleteRoadmapRequest>({
      query: ({ id, roadmapId }) => ({
        url: `/project/${id}/roadmap/${roadmapId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Agreement'],
    }),
    setRoadmapMain: build.mutation<SetRoadmapMainResponse, SetRoadmapMainRequest>({
      query: ({ projectId, roadmapId }) => ({
        url: `/project/${projectId}/roadmap/${roadmapId}/set-main`,
        method: 'POST',
      }),
      invalidatesTags: ['Agreement'],
    }),

    getStages: build.query<GetStagesResponse, GetStagesRequest>({
      query: ({ id, roadmapId }) => ({
        url: `/project/${id}/roadmap/${roadmapId}/stage/list`,
        method: 'GET',
      }),
      providesTags: ['Agreement'],
    }),
    getNextStages: build.query<getNextStagesResponse, getNextStagesRequest>({
      query: ({ id, roadmapId, ...params }) => ({
        url: `/project/${id}/roadmap/${roadmapId}/stage/list-next`,
        params,
        method: 'GET',
      }),
      providesTags: ['Agreement'],
    }),
    createStage: build.mutation<CreateStageResponse, CreateStageRequest>({
      query: ({ id, roadmapId, body }) => ({
        url: `/project/${id}/roadmap/${roadmapId}/stage/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreement'],
    }),
    getStageById: build.query<GetStageByIdResponse, GetStageByIdRequest>({
      query: ({ id, roadmapId, stageId }) => ({
        url: `/project/${id}/roadmap/${roadmapId}/stage/${stageId}/get`,
        method: 'GET',
      }),
      providesTags: ['Agreement'],
    }),
    editStage: build.mutation<EditStageResponse, EditStageRequest>({
      query: ({ id, roadmapId, stageId, body }) => ({
        url: `/project/${id}/roadmap/${roadmapId}/stage/${stageId}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreement'],
    }),
    deleteStage: build.mutation<DeleteStageResponse, DeleteStageRequest>({
      query: ({ id, roadmapId, stageId }) => ({
        url: `/project/${id}/roadmap/${roadmapId}/stage/${stageId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Agreement'],
    }),
    bindInnermap: build.mutation<BindInnermapResponse, BindInnermapRequest>({
      query: ({ id, roadmapId, stageId, ...body }) => ({
        url: `/project/${id}/roadmap/${roadmapId}/stage/${stageId}/bind-to-innermap`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Agreement'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetProjectStatusStatisticsQuery,

  useGetRoadmapsMenuQuery,
  useCreateRoadmapMutation,
  useGetRoadmapByIdQuery,
  useEditRoadmapMutation,
  useDeleteRoadmapMutation,
  useSetRoadmapMainMutation,

  useGetStagesQuery,
  useGetNextStagesQuery,
  useCreateStageMutation,
  useGetStageByIdQuery,
  useEditStageMutation,
  useDeleteStageMutation,
  useBindInnermapMutation,
} = projectAgreementApi
