import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { theme } from '../../../../../../styles/theme'
import { NumberBlock, TitleText } from '../TitleCell'
import DeleteIcon from '@mui/icons-material/Delete'
import { StyledTableCell } from '../../WorksTable.styles'
import { GroupTitleCellProps } from './GroupTitleCell.types'
import Tooltip from '../../../../../../components/Tooltip'

export const GroupTitleCell: FC<GroupTitleCellProps> = ({
  onDeleteClick,
  defaultColSpan,
  numGroup,
  group
}) => {
  const onDelete = (e: MouseEvent) => {
    e.stopPropagation()
    onDeleteClick(group, numGroup)
  }

  const groupRef = useRef<HTMLParagraphElement | null>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  useEffect(() => {
    setShowTooltip((groupRef?.current?.scrollHeight || 0) > 42)
  }, [groupRef?.current])

  return (
    <StyledTableCell
      colSpan={defaultColSpan}
      className='groupNameCell'
    >
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
        <Stack direction='row' alignItems='center' spacing={1.375}>
          <NumberBlock bgColor={theme.palette.primary.main}>
            <Typography variant='body2'>
              {numGroup}
            </Typography>
          </NumberBlock>

          <Tooltip title={showTooltip ? group : ''}>
            <TitleText ref={groupRef} variant='body2' style={{ color: theme.palette.primary.main, textIndent: 0 }}>
              {group}
            </TitleText>
          </Tooltip>
        </Stack>

        <IconButton onClick={onDelete}>
          <DeleteIcon fontSize='medium' color='error' />
        </IconButton>
      </Stack>
    </StyledTableCell>
  )
}
