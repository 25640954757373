import { List, ListItem, Stack } from "@mui/material"
import { StyledStack, TitleDrawerFootnote, TitleDrawerHeader, TitleDrawerListItemText, TitleWrapper } from "./TitleDrawer.styles"
import { ReactComponent as EmptyImage } from "../../../../../../assets/empty-internal-agreement.svg"
import { listItem } from "../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementTitle"

export const TitleDrawer = () => {
  return (
    <TitleWrapper spacing='20px'>
      <StyledStack direction='row' spacing='40px'>
        <EmptyImage width={100} height={100} />
        <TitleDrawerHeader>Внутренняя процедура согласования:</TitleDrawerHeader>
      </StyledStack>

      <Stack>
        {listItem.map((item, index) =>
          <List key={index} disablePadding sx={{ marginBottom: '20px' }}>
            <ListItem disablePadding>
              <TitleDrawerListItemText
                primary={item.primary}
                secondary={item.secondary}
              />
            </ListItem>
          </List>
        )}
        <TitleDrawerFootnote>* Функционал появится в будущем</TitleDrawerFootnote>
      </Stack>
    </TitleWrapper >
  )
}