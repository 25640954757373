import styled from 'styled-components'
import { Badge } from '@mui/material'

interface IStyledBadgeProps {
  _color: string
}

export const StyledBadge = styled(Badge)<IStyledBadgeProps>`
  & .MuiBadge-badge {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 1.5px solid ${(props) => props.theme.palette.bg.white};
    background-color: ${(props) => props._color};
  }
`
