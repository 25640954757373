import React, { FC, useCallback, useEffect, useState } from 'react'
import { InnerContainer, MainContainer } from '../../layouts/HomeLayout/styles'
import Header from '../../components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { PocketsNavbar } from './components/PocketsNavbar'
import { PocketsLegend } from './components/PocketsLegend/PocketsLegend'
import { Grid, Stack } from '@mui/material'
import { AgreementPocket, AgreementPocketType } from '../../api/agreementPocket/types'
import { RoadmapPhase } from '../../api/projectAgreement/types'
import { useGetAgreementPocketsQuery } from '../../api/agreementPocket'
import Progress from '../../components/Progress'
import { useGetProjectByIdQuery } from '../../api/projects'
import { PocketCard } from './components/PocketCard'
import useSearch from '../../hooks/useSearch'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import { filterByFieldNames } from '../../utils/filterByFieldNames'
import EmptyPage from '../../components/EmptyPage'
import { EmptyPageData } from '../../components/EmptyPage/EmptyPage.types'
import { getEmptyPageData } from '../Home'
import AddIcon from '@mui/icons-material/Add'
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog'
import { FormPocketDrawer } from '../DocView/components/FormPocketDrawer'
import useQuery from '../../hooks/useQuery'
import { useBreadcrumbMenu } from '../../hooks/useBreadcrumbMenu'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { PagesViewMode, pocketsViewSelector, setPageViewMode } from '../../store/slices/ui'
import { AgGridPockets } from './components/AgGridPockets'

export const Pockets: FC = () => {
  const { searchValue } = useSearch()
  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const queryHandler = useQuery()
  const dispatch = useAppDispatch()

  const initialIncomingQuery: string | null = queryHandler.get('initialIncoming')
  const initialOutgoingQuery: string | null = queryHandler.get('initialOutgoing')
  const initialIncoming = initialIncomingQuery === 'true'
  const initialOutgoing = initialOutgoingQuery === 'true'

  const initialPocketType: AgreementPocketType | 'all' = initialIncoming
    ? 'incoming'
    : initialOutgoing
      ? 'outgoing'
      : 'all'

  const viewMode = useTypedSelector(pocketsViewSelector)
  const [pocketDrawerOpen, setPocketDrawerOpen] = useState<boolean>(false)
  const [pocketType, setPocketType] = useState<AgreementPocketType | 'all'>(initialPocketType)
  const [pocketPhase, setPocketPhase] = useState<RoadmapPhase | 'all'>('all')

  const onPocketDrawerOpen = () => {
    setPocketDrawerOpen(true)
  }

  const onPocketTypeChange = (type: AgreementPocketType | 'all') => {
    setPocketType(type)
  }

  const onPocketPhaseChange = (tabValue: RoadmapPhase) => {
    if (tabValue === null) return
    setPocketPhase(tabValue)
  }

  const { data: projectData, isFetching: isProjectFetching } = useGetProjectByIdQuery({ id: projectId })
  const { data: project } = projectData || {}

  const { data: pocketsData, isFetching: pocketsFetching } = useGetAgreementPocketsQuery({ projectId })
  const { data: pockets = [] } = pocketsData || {}

  const onPocketCardClick = (pocketId: number) => {
    navigate(`${pocketId}`)
  }

  const onProjectItemClick = (projectId: number) => {
    navigate(`/project/${projectId}/pockets`)
  }

  const { onProjectBreadClick, isProjectsMenuOpened, ProjectMenu } = useBreadcrumbMenu({ onProjectItemClick })
  useBreadcrumbs([
    {
      title: project?.shifrName || '',
      onClick: (e) => onProjectBreadClick(e),
      MenuOpen: isProjectsMenuOpened,
      type: 'project'
    },
    { title: 'Реестр передачи документации' },
  ],
    [isProjectsMenuOpened, project],
    isProjectFetching
  )

  const getFilteredData = (data: AgreementPocket[]) => {
    const filteredByType = pocketType === 'all' ? data : data.filter(item => item[pocketType])
    const filteredByPhase = pocketPhase === 'all' ? filteredByType : filteredByType.filter(item => item.phase === pocketPhase)
    const filteredBySearch = searchValue
      ? filterByFieldNames<AgreementPocket>(filteredByPhase, ['title', 'roadmapTitle'], searchValue)
      : filteredByPhase

    return filteredBySearch
  }

  const filteredData = getFilteredData(pockets)

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setPocketDrawerOpen(false)
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onPocketDrawerClose = (dirty: boolean, immediately?: boolean) => {
    immediately || !dirty
      ? setPocketDrawerOpen(false)
      : openConfirm()
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>Накладных нет.</>,
    [
      {
        text: 'Создать накладную',
        icon: AddIcon,
        onClick: () => onPocketDrawerOpen()
      },
    ]
  )

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют накладные, соответствующие результатам запроса.</>
  )

  useEffect(() => {
    const cachedPagesViewMode: PagesViewMode = JSON.parse(localStorage.getItem('pagesViewMode') || '{}')
    if (cachedPagesViewMode?.pockets) {
      dispatch(setPageViewMode({ page: 'pockets', viewMode: cachedPagesViewMode.pockets }))
    }
  }, [])

  return (
    <>
      <PocketsNavbar
        project={project}
        pocketPhase={pocketPhase}
        onPocketPhaseChange={onPocketPhaseChange}
      />
      <MainContainer>
        <Header />
        <InnerContainer>
          <Stack width='100%'>
            {pocketsFetching
              ? <Progress />
              : pockets.length
                ? (
                  <>
                    <PocketsLegend
                      onPocketDrawerOpen={onPocketDrawerOpen}
                      pocketType={pocketType}
                      onPocketTypeChange={onPocketTypeChange}
                    />

                    {filteredData.length
                      ?
                      viewMode === 'list' ? (
                        <Grid sx={{ py: 1.25 }} spacing={2.5} container alignContent='flex-start'>
                          {filteredData.map(pocket => (
                            <Grid
                              item
                              xs={12} md={6} lg={6} xl={4} xxl={3} xxxl={2}
                              container
                              key={pocket.id}
                            >
                              <PocketCard pocket={pocket} onClick={onPocketCardClick} />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <AgGridPockets pockets={filteredData} allPhases={pocketPhase === 'all'} />
                      )
                      : <EmptyPage data={emptyFilteredData} forFilter />
                    }
                  </>
                )
                : <EmptyPage data={emptyPageData} fullPage />
            }
          </Stack>
        </InnerContainer>
      </MainContainer>

      <FormPocketDrawer
        open={pocketDrawerOpen}
        onDrawerClose={onPocketDrawerClose}
        initialPhase={project?.phase || 'Рабочая документация'}
        showPhaseSwitcher
      />
      {ProjectMenu}
      <ConfirmDialog />
    </>
  )
}
