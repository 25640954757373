import DownloadIcon from '@mui/icons-material/Download'
import { DownloadDocumentComponentProps } from "./DownloadDocumentComponent.types"
import { pdfjs } from "react-pdf"
import { useEffect, useState } from "react"
import { CircularProgress, Stack, Typography } from "@mui/material"

const getFileName = (url: string) => url.split('/').pop()?.split('#')[0]?.split('?')[0] || "document.pdf"

export const DownloadDocumentComponent = ({ file, selectedItem, clearAction }: DownloadDocumentComponentProps) => {

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (selectedItem === 1) {
      const downloadPDF = async () => {
        setIsLoading(true)
        if (file) {
          try {
            const loadingTask = pdfjs.getDocument(file)
            const pdfDocument = await loadingTask.promise
            const pdfData = await pdfDocument.getData()

            const blob = new Blob([pdfData], { type: "application/pdf" })
            const url = URL.createObjectURL(blob)

            const a = document.createElement("a")
            a.href = url;
            a.download = getFileName(file)
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
          } catch (error) {
            console.log('Не удалось загрузить файл')
          } finally {
            setIsLoading(false)
          }
        }
      }
      downloadPDF()
      clearAction()
    }
  }, [file, selectedItem])



  return (
    <Stack direction='row' alignItems='center' spacing={1.5}>
      {isLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
      <Typography>Скачать</Typography>
    </Stack>
  )
}