import React from 'react'
import { ColoredTitleProps } from './ColoredTitle.types'
import { StyledBox } from './styles'
import { Typography } from '@mui/material'

const ColoredTitle: React.FC<ColoredTitleProps> = ({ body, color, ...props }) => {
  return (
    <StyledBox bgColor={color} className='colored-title' {...props}>
      <Typography variant='tooltip'>{body}</Typography>
    </StyledBox>
  )
}

export default ColoredTitle