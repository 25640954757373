import { Box, Button, Drawer, Stack, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SchedulesDrawerProps, SchedulesFormValues } from "./SchedulesDrawer.types"
import { DrawerHeader, DrawerWrapper, SchedulesAutocomplete, SchedulesTextField, ScheduleSwitch } from "./SchedulesDrawer.styles"
import { Form, FormikProvider } from "formik"
import { useForm } from "../../../../hooks/useForm"
import { allProjectsPhases, ProjectPhase } from "../../../../types/project"
import { useTypedSelector } from "../../../../store/store"
import { profileSelector } from "../../../../store/slices/profile"
import { validationSchema } from "./SchedulesDrawer.utils"
import { useAddScheduleMutation, useUpdateScheduleMutation, useDeleteScheduleMutation } from "../../../../api/works"
import { useParams } from "react-router-dom"
import { useMutationHandlers } from "../../../../hooks/useMutationHandlers"
import { useSnackbar } from "notistack"
import useConfirmDialog, { UseExitConfirmProps } from "../../../../hooks/useConfirmDialog"
import Tooltip from "../../../../components/Tooltip"
import { useEffect } from "react";

export const SchedulesDrawer = ({ open, closeDrawer, editableData }: SchedulesDrawerProps) => {

  const { projectId: projectIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)
  const { enqueueSnackbar } = useSnackbar()

  const { role } = useTypedSelector(profileSelector)

  const [addSchedule, addScheduleResponse] = useAddScheduleMutation()
  const [updateSchedule, updateScheduleResponse] = useUpdateScheduleMutation()
  const [deleteSchedule, deleteScheduleResponse] = useDeleteScheduleMutation()

  const initialValues: SchedulesFormValues = {
    title: editableData?.title || '',
    phase: editableData?.phase || '',
    description: editableData?.description || '',
    isMain: editableData?.isMain || false
  }

  const onSubmit = (values: SchedulesFormValues) => {
    const commonData = {
      isMain: values.isMain,
      title: values.title,
      description: values.description,
      phase: values.phase ? values.phase as ProjectPhase : null
    }

    editableData ?
      updateSchedule({
        projectId,
        scheduleId: editableData.scheduleId!,
        ...commonData
      }) :
      addSchedule({
        projectId,
        ...commonData
      })
  }

  const { formik } = useForm({
    validationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values)
  })

  const { isValid, dirty } = formik

  const deleteScheduleConfirm = (confirm: boolean) => {
    if (confirm && editableData?.scheduleId) {
      deleteSchedule({
        projectId,
        scheduleId: editableData.scheduleId
      })
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: deleteScheduleConfirm,
    title: 'Удалить график?',
    body: (<>Все добавленные в график работы будут безвозвратно удалены.</>),
    denyButtonText: 'Отменить',
    confirmButtonColor: 'error',
    confirmButtonText: 'Удалить'
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  useMutationHandlers(
    addScheduleResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(`График успешно добавлен.`, { variant: 'success' })
        closeDrawer(false)
      }
    },
    (error) => {
      if (error) {
        if ('data' in error && error.data === 'not_unique_title') {
          enqueueSnackbar(`График с таким наименованием уже существует.`, { variant: 'error' })
        } else {
          enqueueSnackbar(`Не удалось изменить график`, { variant: 'error' })
        }
      }
    }
  )

  useMutationHandlers(
    updateScheduleResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(`График успешно изменен.`, { variant: 'success' })
        closeDrawer(false)
      }
    },
    (error) => {
      if (error) {
        if ('data' in error && error.data === 'not_unique_title') {
          enqueueSnackbar(`График с таким наименованием уже существует.`, { variant: 'error' })
        } else {
          enqueueSnackbar(`Не удалось изменить график`, { variant: 'error' })
        }
      }
    }
  )

  useMutationHandlers(
    deleteScheduleResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(`График успешно удален.`, { variant: 'success' })
        closeDrawer(false)
      }
    },
    (error) => {
      if (error) {
        enqueueSnackbar(`Не удалось удалить график`, { variant: 'error' })
      }
    }
  )

  useEffect(() => {
    if (!open) {
      formik.resetForm()
    }
  }, [open])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => closeDrawer(dirty)}
    >
      <DrawerWrapper >
        <DrawerHeader variant="h2">
          {(editableData ? 'Редактирование' : 'Создание') + ' графика проектирования'}
        </DrawerHeader>
        {!editableData && <Typography variant="body1" fontSize={14} textAlign='center' mt={2.5}>
          Для проекта вы можете создать не более четырех графиков.
        </Typography>}
        <FormikProvider value={formik} >
          <Stack component={Form} flex={1} mt={2.5}>
            <Stack flex={1} spacing={1.5}>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant="h3">Наименование *</Typography>
                <SchedulesTextField
                  placeholder="Наименование графика"
                  version="project"
                  name="title" />
              </Stack>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant="h3">Стадия</Typography>
                <SchedulesAutocomplete
                  fieldName="phase"
                  placeholder="Выберите стадию"
                  customRenderOption={(props, option) => (
                    <Box component="li" {...props} key={option.key}>
                      <Typography variant="body2">
                        {option.label}
                      </Typography>
                    </Box>
                  )}
                  data={allProjectsPhases.map(phase => ({
                    value: phase,
                    label: phase,
                    key: phase,
                  }))}
                />
              </Stack>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Typography variant="h3">Основной график проекта</Typography>
                  <Tooltip variant='light' maxWidth={350} fontSize={14}
                    title={<>При наличии признака "Основной" данный график по умолчанию будет отображаться на главной странице проекта и в разделе ПИР в модуле Pragma.Dashboard (при наличии активной интеграции). Основным в проекте может быть только один график.</>}>
                    <InfoOutlinedIcon fontSize="small" color="disabled" />
                  </Tooltip>
                </Stack>
                <ScheduleSwitch
                  version="switch"
                  name="isMain"
                  checkboxData={{
                    label: null,
                    checked: !!editableData?.isMain,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('isMain', e.target.checked)
                    }
                  }} />
              </Stack>
              <SchedulesTextField
                placeholder="Комментарий"
                multiline
                version="project"
                name="description" />
            </Stack>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <Button fullWidth color='success' type="submit"
                disabled={!isValid || !dirty}>
                Сохранить
              </Button>
              <Button fullWidth onClick={() => closeDrawer(dirty)} >
                Отменить
              </Button>
            </Stack>
            {role === 'admin' && editableData &&
              <Button
                onClick={openConfirm}
                startIcon={<DeleteIcon />}
                variant='text'
                color='error'
                sx={{ mt: 2.5 }}
              >Удалить график</Button>}
          </Stack>
        </FormikProvider>
      </DrawerWrapper>
      <ConfirmDialog />
    </Drawer>
  )
}