import React, { FC } from 'react'
import { ProjectAgreementSchemeSubtitle, ProjectAgreementSchemeValueText } from '../ProjectAgreementLegend.styles'
import { Stack } from '@mui/material'
import {
  useAgreementPerson
} from '../../../../ProjectAgreementForm/components/ProjectAgreementFormContent/hooks/useAgreementPerson'
import { ProjectRoadmapFormData } from '../ProjectAgreementLegend.types'
import { useParams } from 'react-router-dom'
import {
  AgreementLegendAvatarWrapper,
  ProjectAgreementLegendPersonBlockAutocomplete
} from './ProjectAgreementLegendPersonBlock.styles'
import { useTypedSelector } from '../../../../../../store/store'
import { roadmapFormModeSelector } from '../../../../../../store/slices/agreement'
import { ProjectAgreementLegendPersonBlockProps } from './ProjectAgreementLegendPersonBlock.types'
import PersonSharpIcon from '@mui/icons-material/PersonSharp'

export const ProjectAgreementLegendPersonBlock: FC<ProjectAgreementLegendPersonBlockProps> = ({ person }) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const roadmapFormMode = useTypedSelector(roadmapFormModeSelector)

  const { companyNames, employeesByProjectAndCompany } = useAgreementPerson<ProjectRoadmapFormData>({ projectId })

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <ProjectAgreementSchemeSubtitle variant='body2'>Ответственный:</ProjectAgreementSchemeSubtitle>
      {roadmapFormMode === 'view'
        ? person?.name
          ? (
            <Stack direction='row' alignItems='center' spacing={1}>
              <AgreementLegendAvatarWrapper>
                {person?.avatar
                  ? <img src={person.avatar} width={24} height={24} alt='Аватар' />
                  : <PersonSharpIcon fontSize='medium' />
                }
              </AgreementLegendAvatarWrapper>

              <ProjectAgreementSchemeValueText variant='body2'>{person?.name || '—'}</ProjectAgreementSchemeValueText>
            </Stack>
          )
          : <ProjectAgreementSchemeValueText variant='body2'>—</ProjectAgreementSchemeValueText>
        : (
          <Stack direction='row' alignItems='center' spacing={1}>
            <ProjectAgreementLegendPersonBlockAutocomplete
              fieldName='companyName'
              placeholder='Компания'
              data={companyNames?.map(company => ({
                value: company,
                label: company,
                key: company,
              })) || []}
            />

            <ProjectAgreementLegendPersonBlockAutocomplete
              fieldName='personId'
              placeholder='Введите ФИО'
              data={employeesByProjectAndCompany?.map(({ id, name }) => ({
                value: id,
                label: name,
                key: id,
              })) || []}
            />
          </Stack>
        )
      }
    </Stack>
  )
}
