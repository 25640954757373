import { Avatar, Stack } from "@mui/material"
import { ApprovalIcon, AssuranceIcon, IconWrapper, NameBlock, RemarksIcon, SigningIcon, StyledName, StyledPosition, TableRow } from "./MemberRow.styles"
import { MemberRowProps } from "./MemberRow.types"

export const MemberRow = ({ member, minWidthNameBlock }: MemberRowProps) => {

  return (
    <TableRow direction='row' spacing={1}>
      <Stack direction='row' justifyContent='space-between' width='100%'>
        <NameBlock direction='row' spacing={1} $minWidth={minWidthNameBlock}>
          <Avatar src={member.person.avatar} sx={{ width: '24px', height: '24px', marginLeft: '8px' }} />
          <Stack direction='column'>
            <StyledName>{member.person.name}</StyledName>
            <StyledPosition>{member.person.position}</StyledPosition>
          </Stack>
        </NameBlock>
        <IconWrapper direction='row'>
          <ApprovalIcon $approval={member.approval} fontSize="small" />
        </IconWrapper>
        <IconWrapper direction='row'>
          <AssuranceIcon $assurance={member.assurance} fontSize="small" />
        </IconWrapper>
        <IconWrapper direction='row'>
          <SigningIcon $signing={member.signing} fontSize="small" />
        </IconWrapper>
        <IconWrapper direction='row'>
          <RemarksIcon $remarks={member.remarks} fontSize="small" />
        </IconWrapper>
      </Stack>
    </TableRow>
  )
}