import styled, { css } from 'styled-components'
import { Stack } from '@mui/material'
import { ProjectAgreementCardLineProps } from './ProjectAgreementCardLine.types'

export const AGREEMENT_CARD_WIDTH = 260
export const AGREEMENT_CARD_GAP = 66

// минимальная длина текста, для отображения тултипа
export const CARDLINE_TOOLTIP_MINLENGTH = 23

const LINE_HEIGHT = 32
const LINE_TOP_OFFSET = 4
const LINE_WIDTH = 1.5

const LINE_START_WIDTH = (AGREEMENT_CARD_WIDTH / 2) + AGREEMENT_CARD_GAP
const LINE_BODY_WIDTH = AGREEMENT_CARD_WIDTH + AGREEMENT_CARD_GAP
const LINE_END_WIDTH = AGREEMENT_CARD_WIDTH / 2

interface ProjectAgreementCardLineTitleProps extends Pick<ProjectAgreementCardLineProps,
  'lineDirection'> {
}

export const ProjectAgreementCardLineTitle = styled(Stack)<ProjectAgreementCardLineTitleProps>`
  position: absolute;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  min-height: 20px;
  max-height: 40px;
  padding: 0 8px;
  background-color: ${props => props.theme.palette.bg.white};
  transform: ${props => `translate(-50%, -50%) ${props.lineDirection === 'left' ? 'rotateY(180deg)' : ''}`};

  & .MuiTypography-root {
    max-width: 190px;
    min-height: 20px;
    max-height: 40px;
    line-height: 20px;
    color: ${props => props.theme.palette.text.dark};
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

interface ProjectAgreementCardLineDurationProps extends Pick<ProjectAgreementCardLineProps,
  'lineDirection'
  | 'verticalStart'> {
}

export const ProjectAgreementCardLineDuration = styled(Stack)<ProjectAgreementCardLineDurationProps>`
  position: absolute;
  flex-direction: row;
  gap: 6px;
  height: 28px;
  padding: 0 8px;
  background-color: ${props => props.theme.palette.bg.white};
  transform: ${props => `translate(${AGREEMENT_CARD_WIDTH / 2}px, -50%) ${props.lineDirection === 'left' ? 'rotateY(180deg)' : ''}`};

  & .innerWrapper {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px 6px 4px 4px;
    background-color: ${props => props.verticalStart === 'top'
        ? props.theme.palette.legends.lightgreen
        : props.theme.palette.error.main
    };
    border-radius: 100px;
    
    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.text.light};
    }
  }
  
  & .MuiTypography-root {
    line-height: 15px;
    color: ${props => props.theme.palette.text.light};
  }
`

interface ProjectAgreementCardLineWrapperProps extends Pick<ProjectAgreementCardLineProps,
  'lineDirection'
  | 'verticalStart'
  | 'fullCardsSkip'
  | 'show'
  | 'mode'
  > {
}

const TopLineCss = css<ProjectAgreementCardLineWrapperProps>`
  top: -${LINE_HEIGHT + LINE_TOP_OFFSET}px;
  bottom: auto;
  border: ${props => `${LINE_WIDTH}px solid ${props.theme.palette.legends.lightgreen}`};
  border-bottom: 0;

  // mode === 'short'
  border: ${props => props.mode === 'short' && '0'};
  border-left: ${props => props.mode === 'short' && `${LINE_WIDTH}px solid ${props.theme.palette.legends.lightgreen}`};
  
  & .arrowIcon {
    top: 16px;
    color: ${props => props.theme.palette.legends.lightgreen};
    transform: translateX(calc(-50% + ${LINE_WIDTH / 2}px));
  }
`

const BottomLineCss = css<ProjectAgreementCardLineWrapperProps>`
  top: auto;
  bottom: -${LINE_HEIGHT + LINE_TOP_OFFSET}px;
  border: ${props => `${LINE_WIDTH}px solid ${props.theme.palette.error.main}`};
  border-top: 0;

  // mode === 'short'
  border: ${props => props.mode === 'short' && '0'};
  border-left: ${props => props.mode === 'short' && `${LINE_WIDTH}px solid ${props.theme.palette.error.main}`};

  ${ProjectAgreementCardLineTitle} {
    bottom: 0;
    transform: ${props => `translate(-50%, 50%) ${props.lineDirection === 'left' ? 'rotateY(180deg)' : ''}`};
  }

  ${ProjectAgreementCardLineDuration} {
    bottom: 0;
    transform: ${props => `translate(${AGREEMENT_CARD_WIDTH / 2}px, 50%) ${props.lineDirection === 'left' ? 'rotateY(180deg)' : ''}`};
  }

  & .arrowIcon {
    bottom: 16px;
    color: ${props => props.theme.palette.error.main};
    transform: translateX(calc(-50% + ${LINE_WIDTH / 2}px)) rotate(180deg);
  }
`

export const ProjectAgreementCardLineWrapper = styled(Stack)<ProjectAgreementCardLineWrapperProps>`
  position: absolute;
  ${props => props.verticalStart === 'top' ? TopLineCss : BottomLineCss}
  left: 50%;
  display: ${props => (!props.show || props.mode === 'none') && 'none'};
  width: ${props => LINE_START_WIDTH + props.fullCardsSkip * LINE_BODY_WIDTH + LINE_END_WIDTH}px;
  height: ${LINE_HEIGHT}px;
  transform: ${props => props.lineDirection === 'left' && `rotate3d(0, 1, 0, 180deg) translateX(calc(100% - 1px))`};

  & .arrowIcon {
    position: absolute;
    left: 100%;
  }
`
