import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import { VariableSizeList as List } from 'react-window';
import styled from "styled-components";
import { AccordionStyledProps } from "./ModelStructureAccordion.types";

export const ScrollableList = styled(List)<{hasScroll: boolean}>`
  margin-right: 20px;
  margin-left: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    margin-right: ${props => props.hasScroll && '7px'};

    &::-webkit-scrollbar {
      display: block;
    }
  }

   @supports (-moz-appearance:none) {
    scrollbar-width: none;

    &:hover {
      padding-right: 12px;
      scrollbar-width: thin;
    }
  }
`

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  
  &::before {
    display: none;
  }
`
export const StyledAccordionSummary = styled(AccordionSummary) <AccordionStyledProps>`
  background-color: ${props => props.isModelText ?
  props.theme.palette.bg.shades : props.theme.palette.bg.white};
  border-radius: 4px;
  padding-right: 8px;
  flex-direction: row-reverse;

  & .MuiAccordionSummary-content {
    margin: 0;
    margin-left: 8px;
    flex: 1;
  }
  
  & .MuiAccordionSummary-expandIconWrapper {
    & .MuiSvgIcon-root {
      color: ${props => props.isSelected
        ? props.theme.palette.text.light
        : props.theme.palette.secondary.gray};
    }
  }
`
export const TitleText = styled(Typography) <{ isModelText: boolean, isHidden?: boolean}>`
  font-size: 14px;
  font-weight: ${props => props.isModelText ? 500 : 400};
  line-height: 130%;
  width: 100%;
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
  color: ${props => props.isModelText ?
    props.theme.palette.primary.main : props.theme.palette.text.dark};
  color: ${props => props.isHidden && props.theme.palette.disabled};
`
export const CountText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.palette.disabled};
`
export const ElementWrapper = styled(Stack) <{ selected: boolean }>`
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding-left: 76px;
  padding-right: 8px;
  cursor: pointer;
  position: relative;
  z-index: 0;

  & .MuiTypography-root {
    color: ${props => props.selected && props.theme.palette.text.light};
  }

  ::before {
    content: '';
    position: absolute;
    width: 319px;
    height: 48px;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 4px;
    background-color: ${props => props.selected &&
    props.theme.palette.legends.text + 'CC'};
  }
`

export const NodeWrapper = styled(Stack) <{ selected: boolean, level: number }>`
  align-items: center;
  height: 48px;
  position: relative;
  z-index: 0;

  & .MuiTypography-root {
    color: ${props => props.selected && props.theme.palette.text.light};
  }

  ::before {
    content: '';
    position: absolute;
    width: 319px;
    height: 48px;
    top: 0;
    left: -${props => props.level * 16 + 28 + 'px'};
    z-index: -1;
    border-radius: 4px;
    background-color: ${props => props.selected &&
    props.theme.palette.legends.text + 'CC'};
  }
`