import { LegendColors } from '../global'

export type UserRoles = 'Администратор' | 'Заказчик' | 'Проектировщик' | 'Надзор' | 'Подрядчик' | 'Без роли'
export type UserRolesEn = 'admin' | 'client' | 'architector' | 'supervisor' | 'contractor' | 'none'
export type UserRolesFilter = UserRoles | 'Все пользователи'
export type UserRoleTabLabels = 'Все пользователи' | 'Администраторы' | 'Заказчики' | 'Проектировщики' | 'Надзор' | 'Подрядчики' | 'Без роли'



export interface UserRolesEnToRu extends Record<UserRolesEn, UserRoles> {}
export interface UserRolesRuToEn extends Record<UserRoles, UserRolesEn> {}

export const userRolesRuToEn: UserRolesRuToEn = {
  'Администратор': 'admin',
  'Заказчик': 'client',
  'Проектировщик': 'architector',
  'Надзор': 'supervisor',
  'Подрядчик': 'contractor',
  'Без роли': 'none',
}

export const userRolesEnToRu: UserRolesEnToRu = {
  'admin': 'Администратор',
  'client': 'Заказчик',
  'architector': 'Проектировщик',
  'supervisor': 'Надзор',
  'contractor': 'Подрядчик',
  'none': 'Без роли',
}

export const userRolesArray = Object.keys(userRolesRuToEn) as Array<keyof typeof userRolesRuToEn>
export const userRolesArrayEn = Object.keys(userRolesEnToRu) as Array<keyof typeof userRolesEnToRu>
export const userTabNamesArray: UserRoleTabLabels[] = ['Все пользователи', 'Администраторы', 'Заказчики', 'Проектировщики', 'Надзор', 'Подрядчики', 'Без роли']

export const UserRoleByTabLabel: Record<Exclude<UserRoleTabLabels, 'Все пользователи'>, UserRolesEn> = {
  'Администраторы': 'admin',
  'Заказчики': 'client',
  'Проектировщики': 'architector',
  'Надзор': 'supervisor',
  'Подрядчики': 'contractor',
  'Без роли': 'none',
}

export enum userRoleQueryName {
  'all' = 0,
  'admins' = 1,
  'clients' = 2,
  'architectors' = 3,
  'contractors' = 4,
  'none' = 5
}

export type UserQueryName = 'admins' | 'clients' | 'architectors'| 'contractors' | 'none'
export const userRoleByQuery: Record<UserQueryName, UserRolesEn> = {
  'admins': 'admin',
  'clients': 'client',
  'architectors': 'architector',
  'contractors': 'contractor',
  'none': 'none',
}

export const UserRolesColors: Record<UserRoles, LegendColors> = {
  'Администратор': 'blue',
  'Заказчик': 'orange',
  'Проектировщик': 'lightgreen',
  'Надзор': 'red',
  'Подрядчик': 'purple',
  'Без роли': 'disabled',
}