import { Button, Divider, ListItem, ListItemText, Select, Stack, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { ObjectsSelectMenuItem } from "../../../../../components/UserManagement/components/UserObjectsSelection/styles";

export const StyledSelect = styled(Select)`

  & .MuiSelect-select {
    border-radius: 6px;
    display: flex;
    align-items: center;
  }

  & .MuiSelect-iconStandard {
    font-size: 16px!important;
    right: 6px;
    color: #2B3648;
  }
`
export const FilterSelectMenuItem = styled(ObjectsSelectMenuItem)`
`
export const FilterRenderText = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
`

export const FilterTitleText = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
`
export const StyledCompanyText = styled(Typography)`
  color: ${props => props.theme.palette.secondary.dark};
  font-size: 12px;
  font-weight: 500;
`
export const StyledListItem = styled(ListItem)`
  & .MuiListItemSecondaryAction-root {
    right: 0;
  }
`
export const StyledItemText = styled(ListItemText)`
  max-width: 190px;
  margin: 0;


  & .MuiListItemText-primary {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  & .MuiListItemText-secondary {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${props => props.theme.palette.secondary.dark};
  }
`
export const StyledDivider = styled(Divider)`
  margin: 0;
  color: ${props => props.theme.palette.legends.gray};
`
export const StyledTextField = styled(TextField)`
  & .MuiInputBase-input {
    padding: 8px 16px 8px 8px;
  }

  & .MuiInputBase-root {
    height: 40px;
    width: 230px;
    border-radius: 6px;
    padding: 0;
    color: ${props => props.theme.palette.secondary.dark};

    & .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 1px;
    }

    svg {
      margin-left: 8px;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primaryBorderLight};
    }
  }
`

export const StyledButton = styled(Button)`
  text-transform: none;
  font-size: 12px;
  padding: 0;
`
export const StickyContainer = styled(Stack)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  align-items: center;
`