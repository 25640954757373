import { FC, ReactElement } from 'react'
import { GraphCellBody, GraphCellEnd, GraphCellOwn, GraphCellStart, GraphCellWrapper } from './GraphCell.styles'
import { DateForCell, GraphCellProps, GraphCellVariant } from './GraphCell.types'
import {
  getCellColorForQuarters,
  getCellColorOpacityForQuarters,
  getCellDashedForQuarters,
  getVariantForQuarters
} from './utils/quartersCellData'
import { LegendColors } from '../../../../../../types/global'
import {
  getCellColorForMonth,
  getCellColorOpacityForMonths,
  getCellDashedForMonths,
  getVariantForMonths
} from './utils/monthsCellData'
import {
  getCellColorForDays,
  getCellColorOpacityForDays,
  getCellDashedForDays,
  getVariantForDays
} from './utils/daysCellData'
import { parseDateForCell } from '../../WorksTable.utils'
import { formatToDate } from '../../../../../../utils/formatDate'


export const GraphCell: FC<GraphCellProps> = ({
  work,
  tableView,
  year,
  quarter,
  month,
  day
}) => {
  const { status, period } = work
  const { dates, isExpired } = period
  const { datePlanStart, datePlanEnd, dateFactStart, dateFactEnd } = dates

  const datePlanStartForCell: DateForCell = parseDateForCell(formatToDate(datePlanStart)!)
  const datePlanEndForCell: DateForCell = parseDateForCell(formatToDate(datePlanEnd)!)
  const dateFactStartForCell: DateForCell | null = (dateFactStart && parseDateForCell(formatToDate(dateFactStart)!)) || null
  const dateFactEndForCell: DateForCell | null = (dateFactEnd && parseDateForCell(formatToDate(dateFactEnd)!)) || null

  // color
  let color: LegendColors = 'blue'

  switch (tableView) {
    case 'quarters':
      color = getCellColorForQuarters({
        datePlanStart: datePlanStartForCell,
        datePlanEnd: datePlanEndForCell,
        dateFactStart: dateFactStartForCell,
        status,
        isExpired,
        year,
        quarter
      })
      break
    case 'months':
      color = getCellColorForMonth({
        datePlanStart: datePlanStartForCell,
        datePlanEnd: datePlanEndForCell,
        dateFactStart: dateFactStartForCell,
        status,
        isExpired,
        year,
        month
      })
      break
    case 'days':
      color = getCellColorForDays({
        datePlanStart: datePlanStartForCell,
        datePlanEnd: datePlanEndForCell,
        dateFactStart: dateFactStartForCell,
        status,
        isExpired,
        year,
        month,
        day
      })
      break
  }
  
  // cellColorOpacity
  let cellColorOpacity: number = 1

  switch (tableView) {
    case 'quarters':
      cellColorOpacity = getCellColorOpacityForQuarters({ status, isExpired, year, quarter })
      break
    case 'months':
      cellColorOpacity = getCellColorOpacityForMonths({ status, isExpired, year, month })
      break
    case 'days':
      cellColorOpacity = getCellColorOpacityForDays({ status, isExpired, year, month, day })
      break
  }

  // dashed
  let dashed: boolean = false

  switch (tableView) {
    case 'quarters':
      dashed = getCellDashedForQuarters({ status, year, quarter })
      break
    case 'months':
      dashed = getCellDashedForMonths({ status, year, month })
      break
    case 'days':
      dashed = getCellDashedForDays({ status, year, month, day })
      break
  }

  // variant
  const graphCellContent: Record<GraphCellVariant, ReactElement> = {
    'start': <GraphCellStart cellColor={color} cellColorOpacity={cellColorOpacity} />,
    'body': <GraphCellBody cellColor={color} cellColorOpacity={cellColorOpacity} dashed={dashed} />,
    'end': <GraphCellEnd cellColor={color} cellColorOpacity={cellColorOpacity} dashed={dashed} />,
    'empty': <></>,
    'own': <GraphCellOwn cellColor={color} contained={color === 'lightgreen'} />,
  }

  let variant: GraphCellVariant = 'empty'

  switch (tableView) {
    case 'quarters':
      variant = getVariantForQuarters({
        isExpired,
        status,
        datePlanStart: datePlanStartForCell,
        datePlanEnd: datePlanEndForCell,
        dateFactStart: dateFactStartForCell,
        dateFactEnd: dateFactEndForCell,
        year,
        quarter,
      })
      break
    case 'months':
      variant = getVariantForMonths({
        isExpired,
        status,
        datePlanStart: datePlanStartForCell,
        datePlanEnd: datePlanEndForCell,
        dateFactStart: dateFactStartForCell,
        dateFactEnd: dateFactEndForCell,
        year,
        month,
      })
      break
    case 'days':
      variant = getVariantForDays({
        isExpired,
        status,
        datePlanStart: datePlanStartForCell,
        datePlanEnd: datePlanEndForCell,
        dateFactStart: dateFactStartForCell,
        dateFactEnd: dateFactEndForCell,
        year,
        month,
        day,
      })
      break
  }

  if (variant === 'empty') return null

  return (
    <GraphCellWrapper variant={variant}>
      {graphCellContent[variant]}
    </GraphCellWrapper>
  )
}
