import React, { FC, ReactElement, useState } from 'react'
import { MainCardProps, MainCardView } from './MainCard.types'
import { MainCardTabs, MainCardWrapper } from './MainCard.styles'
import { Stack, Tab, Tooltip } from '@mui/material'
import { TabProps } from '@mui/material/Tab/Tab'
import { AboutTab } from './components/AboutTab'
import { MoreTab } from './components/MoreTab'
import Button from '../../../../components/Button'
import { ExportIcon } from '../../../../assets/icons/ExportIcon'
import { ProjectFullExportDrawer } from '../ProjectFullExportDrawer'
import { MenuOpen as RevealIcon } from '@mui/icons-material';

export const MainCard: FC<MainCardProps> = ({
  projectDashboard,
}) => {
  const { project, addressData, responsiblePersons, images, link } = projectDashboard

  const [exportDrawerOpened, setExportDrawerOpened] = useState<boolean>(false)
  const [mainCardFullView, setMainCardFullView] = useState<boolean>(!!JSON.parse(localStorage.getItem('mainCardFullView') || '{}'))
  const [currentTab, setCurrentTab] = useState<MainCardView>('about')

  const onExportDrawerOpen = () => {
    setExportDrawerOpened(true)
  }

  const onExportDrawerClose = () => {
    setExportDrawerOpened(false)
  }

  const onChangeMainCardView = () => {
    localStorage.setItem('mainCardFullView', JSON.stringify(!mainCardFullView))
    setMainCardFullView(prev => !prev)
  }

  const onTabChange = (value: MainCardView) => {
    value !== currentTab && setCurrentTab(value)
  }

  const tabsData: TabProps[] = [
    { value: 'about', label: 'О проекте' },
    /* { value: 'model', label: '3D модель', disabled: true, }, */
    /* { value: 'plans', label: 'Планы', disabled: true, }, */
    { value: 'more', label: 'Ещё' },
  ]

  const ContentByTab: Record<MainCardView, ReactElement> = {
    about: <AboutTab mainCardFullView={mainCardFullView} project={project} addressData={addressData!} link={link} />,
    model: <></>,
    plans: <></>,
    more: <MoreTab mainCardFullView={mainCardFullView} responsiblePersons={responsiblePersons!} images={images!} />,
  }

  return (
    <MainCardWrapper mainCardFullView={mainCardFullView}>
      <Stack direction='row' justifyContent='space-between' alignItems='flex-start' spacing={2}>
        <Stack direction='row' alignItems='center' spacing={2}>
          <Tooltip title={mainCardFullView ? 'Свернуть' : 'Развернуть'}>
            <div>
              <Button onClick={onChangeMainCardView} color='secondary' icon>
                <RevealIcon sx={{ transform: mainCardFullView ? 'none' : 'rotate(180deg)' }} />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title="Экспортировать">
            <div>
              <Button onClick={onExportDrawerOpen} color='secondary' icon>
                <ExportIcon />
              </Button>
            </div>
          </Tooltip>

          {/* <TitleText variant='h1' textAlign='start'>
            {shortName}
          </TitleText> */}
        </Stack>

        <MainCardTabs
          value={currentTab}
          onChange={(e: React.SyntheticEvent, tabValue: any) => onTabChange(tabValue as MainCardView)}
        >
          {tabsData.map(tabData => (
            <Tab {...tabData} />
          ))}
        </MainCardTabs>
      </Stack>

      {ContentByTab[currentTab]}

      <ProjectFullExportDrawer open={exportDrawerOpened} onDrawerClose={onExportDrawerClose} />
    </MainCardWrapper>
  )
}
