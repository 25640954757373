import { Link, useNavigate, useParams } from "react-router-dom"
import { DrawerWrapper, RemarksDesignLogo, RemarksDrawerHeader, RemarksLogoWrapper } from "./RemarkFilterDrawer.styles"
import { Stack } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import HomeIcon from "../../../../assets/icons/HomeIcon"
import Button from "../../../../components/Button"
import { theme } from "../../../../styles/theme"
import { RemarkFilter } from "../RemarkFilter/RemarkFilter"

export const RemarkFilterDrawer = () => {

  const navigate = useNavigate()
  const { projectId } = useParams()

  return (
    <DrawerWrapper variant='permanent'>
      <RemarksDrawerHeader direction='row' justifyContent='space-between' spacing={1}>
        <Link to='/home'>
          <RemarksLogoWrapper>
            <RemarksDesignLogo />
          </RemarksLogoWrapper>
        </Link>
        <Stack direction='row' alignItems='center' spacing={1.25}>
          <Button icon bgColor={theme.palette.bg.shades} color='primary'
            variant='text' onClick={() => navigate(-1)}> {/* `/project/${projectId}`, {replace: true} */}
            <ArrowBackIcon />
          </Button>
          <Button icon bgColor={theme.palette.bg.shades} color='primary'
            variant='text' onClick={() => navigate(`/project/${projectId}`)}>
            <HomeIcon />
          </Button>
        </Stack>
      </RemarksDrawerHeader>
      <RemarkFilter />
    </DrawerWrapper>
  )
}