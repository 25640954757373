import React, { FC, useCallback } from 'react'
import { IconButton, Stack } from '@mui/material'
import FieldForm from '../../../../../components/FieldForm'
import { FormItem } from '../FormItem'
import { FormSelectField, FormTextField, FormTitledWrapper } from './FormLeftBlock.styles'
import { useFormikContext } from 'formik'
import {
  allProjectsExpertise,
  allProjectsFullStatuses,
  allProjectsPhases,
  allProjectsTypes,
  projectTypeShort
} from '../../../../../types/project'
import { StyledSelectMenuItem } from '../../../../../components/UserManagement/components/UserRoleSelection/styles'
import { useParams } from 'react-router-dom'
import { ProjectFormData } from '../../ProjectManagement.types'
import CloseIcon from '@mui/icons-material/Close';

export const FormLeftBlock: FC = () => {
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFormData>()

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const onExpertiseClear = useCallback(() => {
    setFieldValue('expertise', '')
  }, [])

  return (
    <Stack flex={1} maxWidth={600} spacing={2.5}>
      <FormTitledWrapper
        title='О проекте *'
        variant='primary'
        contentWrapper='stack'
        bordered='border'
      >
        <FormItem
          title='Шифр проекта'
          input={(
            <FormTextField
              version='project'
              name='shifrName'
              placeholder='Укажите шифр проекта'
            />
          )}
        />
        <FormItem
          title='Краткое название'
          input={(
            <FormTextField
              version='project'
              name='shortName'
              placeholder='Укажите краткое название проекта'
            />
          )}
        />
        <FormItem
          title='Полное наименование'
          input={(
            <FormTextField
              version='project'
              name='fullName'
              placeholder='Укажите полное наименование проекта или его описание'
              multiline
              minRows={3}
            />
          )}
        />
      </FormTitledWrapper>

      <FormTitledWrapper
        title='Сроки реализации *'
        variant='primary'
        contentWrapper='stack'
        bordered='border'
      >
        <FormItem
          title='Дата начала'
          input={(
            <FieldForm
              version='date'
              name='startDate'
              dataValue={formValues.startDate}
              onDataChange={(value: Date | null) => setFieldValue('startDate', value)}
            />
          )}
        />
        <FormItem
          title='Дата окончания'
          input={(
            <FieldForm
              version='date'
              name='endDate'
              dataValue={formValues.endDate}
              onDataChange={(value: Date | null) => setFieldValue('endDate', value)}
            />
          )}
        />
      </FormTitledWrapper>

      <FormTitledWrapper
        title='Параметры проекта *'
        variant='primary'
        contentWrapper='stack'
        bordered='border'
      >
        <FormItem
          title='Статус проекта'
          input={(
            <FormSelectField
              version='select'
              name='status'
              label='Выберите статус проекта'
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: { minWidth: 'fit-content' }
                  }
                }
              }}
              disablePlaceholder={!!formValues.status}
            >
              {allProjectsFullStatuses.map(status => (
                <StyledSelectMenuItem
                  value={status}
                  key={status}
                >
                  {status}
                </StyledSelectMenuItem>
              ))}
            </FormSelectField>
          )}
        />

        <FormItem
          title='Тип проекта'
          input={(
            <FormSelectField
              version='select'
              name='type'
              label='Выберите тип проекта'
              SelectProps={{
                disabled: !!projectId,
                MenuProps: {
                  PaperProps: {
                    style: { minWidth: 'fit-content' }
                  }
                }
              }}
              disablePlaceholder={!!formValues.type}
            >
              {allProjectsTypes.map(type => (
                <StyledSelectMenuItem
                  value={type}
                  key={type}
                >
                  {projectTypeShort[type]}
                </StyledSelectMenuItem>
              ))}
            </FormSelectField>
          )}
        />

        <FormItem
          title='Стадия проекта'
          input={(
            <FormSelectField
              version='select'
              name='phase'
              label='Выберите стадию проекта'
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: { minWidth: 'fit-content' }
                  }
                }
              }}
              disablePlaceholder={!!formValues.phase}
            >
              {allProjectsPhases.map(phase => (
                <StyledSelectMenuItem
                  value={phase}
                  key={phase}
                >
                  {phase}
                </StyledSelectMenuItem>
              ))}
            </FormSelectField>
          )}
        />

        {formValues.phase === 'Проектная документация' && (
          <FormItem
            title='Экспертиза проекта'
            input={(
              <FormSelectField
                version='select'
                name='expertise'
                label='Выберите экспертизу проекта'
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: { minWidth: 'fit-content' }
                    }
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={onExpertiseClear} size='small' sx={{ mr: 1 }}>
                      <CloseIcon fontSize='small' />
                    </IconButton>
                  )
                }}
                disablePlaceholder={!!formValues.expertise}
              >
                {allProjectsExpertise.map(expertise => (
                  <StyledSelectMenuItem
                    value={expertise}
                    key={expertise}
                  >
                    {expertise}
                  </StyledSelectMenuItem>
                ))}
              </FormSelectField>
            )}
          />
        )}
      </FormTitledWrapper>
    </Stack>
  )
}
