import { IconButton, Stack, Typography, alpha } from "@mui/material";
import styled from "styled-components";

export const InternalAgreementAdviceWrapper = styled(Stack) <{ $expanded: boolean }>`
  background-color: ${props => props.$expanded
    ? props.theme.palette.warning.main
    : alpha(props.theme.palette.table.yellow, 0.15)};
  border-radius: 0px 16px 16px 16px;
  padding: ${props => props.$expanded ? '8px 20px 8px 16px' : '8px 12px'};
  width: ${props => props.$expanded ? '600px' : 'max-content'};
  gap: 4px;
  cursor: pointer;
  position: relative;

  & .MuiSvgIcon-root {
    color: ${props => props.$expanded
    ? props.theme.palette.text.light
    : props.theme.palette.warning.main};
  }
`

export const InternalAgreementAdviceTitleWrapper = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 20px;
`

export const InternalAgreementAdviceTitle = styled(Typography) <{ $expanded: boolean }>`
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.$expanded
    ? props.theme.palette.text.light
    : props.theme.palette.warning.main};
`

export const InternalAgreementAdviceTextWrapper = styled(Stack)`
  padding-left: 20px;
  gap: 2px;
`

export const InternalAgreementAdviceText = styled(Typography) <{ $expanded: boolean }>`
 position: relative;
 text-align: start;
 font-size: 14px;
 color: ${props => props.$expanded
    ? props.theme.palette.text.light
    : props.theme.palette.warning.main};

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -13px;
    width: 3px;
    height: 3px;
    background-color: ${props => props.theme.palette.text.light};
    border-radius: 50%;
  }
`
export const InternalAgreementAdviceCloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`