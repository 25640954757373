import { UseDownloadFilesResponse } from './useDownloadFiles.types'
import { useGetLinkForDownloadTomRdFilesQuery } from '../../../../../../api/rdPhase'
import { useGetLinkForDownloadTomPdFilesQuery } from '../../../../../../api/pdPhase'
// @ts-ignore:next-line
import { useGetLinkForDownloadTomIiFilesQuery } from '../../../../../../api/iiPhase/tom/archiveExport'
import { useTypedSelector } from '../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { tomSelector } from '../../../../../../store/slices/documentsPages/tom'
import { useGetLinkForDownloadTomIrdFilesQuery } from '../../../../../../api/irdPhase/tom/archiveExport'

export const useDownloadFiles = (): UseDownloadFilesResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)


  // Rd
  const { data: linkForDownloadTomRdFilesData } = useGetLinkForDownloadTomRdFilesQuery({
    id: tom?.id!,
  }, {
    skip: !tom || selectedProjectPhase !== 'Рабочая документация',
  })
  const { link: linkForDownloadTomRdFiles } = linkForDownloadTomRdFilesData || {}


  // Pd
  const { data: linkForDownloadTomPdFilesData } = useGetLinkForDownloadTomPdFilesQuery({
    id: tom?.id!,
  }, {
    skip: !tom || selectedProjectPhase !== 'Проектная документация',
  })
  const { link: linkForDownloadTomPdFiles } = linkForDownloadTomPdFilesData || {}


  // Ii
  const { data: linkForDownloadTomIiFilesData } = useGetLinkForDownloadTomIiFilesQuery({
    id: tom?.id!,
  }, {
    skip: !tom || selectedProjectPhase !== 'Инженерные изыскания',
  })
  const { link: linkForDownloadTomIiFiles } = linkForDownloadTomIiFilesData || {}


  // Ird
  const { data: linkForDownloadTomIrdFilesData } = useGetLinkForDownloadTomIrdFilesQuery({
    id: tom?.id!,
  }, {
    skip: !tom || selectedProjectPhase !== 'Сбор исходных данных',
  })
  const { link: linkForDownloadTomIrdFiles } = linkForDownloadTomIrdFilesData || {}


  // Common
  const onDownloadTomFilesClick = () => {
    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        linkForDownloadTomRdFiles && window.open(linkForDownloadTomRdFiles)
        break
      case 'Проектная документация':
        linkForDownloadTomPdFiles && window.open(linkForDownloadTomPdFiles)
        break
      case 'Инженерные изыскания':
        linkForDownloadTomIiFiles && window.open(linkForDownloadTomIiFiles)
        break
      case 'Сбор исходных данных':
        linkForDownloadTomIrdFiles && window.open(linkForDownloadTomIrdFiles)
        break
    }
  }

  return {
    onDownloadTomFilesClick,
  }
}
