import { Accordion, AccordionDetails, AccordionSummary, alpha, FormControlLabel } from "@mui/material";
import styled from "styled-components";

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  
  &::before {
    display: none;
  }
`
export const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 4px;
  flex-direction: row-reverse;

  & .MuiFormControlLabel-root {
    margin: 0;
    width: 100%;
    justify-content: space-between;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    margin-left: 16px;
  }

  & .MuiCheckbox-root {
    padding: 0;
  }
  
  & .MuiAccordionSummary-expandIconWrapper {
    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.secondary.gray};
    }
  }
  
  & .MuiTypography-root {
    font-weight: 400;
    color: ${props => props.theme.palette.text.dark};
  }
`
export const StyledCheckbox = styled(FormControlLabel)`
  margin-left: 0;

  & .MuiCheckbox-root {
    margin-left: 16px;
    padding: 5px;

    &.Mui-checked {
      & .MuiSvgIcon-root {
        color: ${props => props.theme.palette.primary.main};
      }
    }

    & .MuiSvgIcon-root {
      color: ${props => alpha(props.theme.palette.disabled!, 0.35)};
    }
  }

  & .MuiTypography-root {
    font-size: 14px;
    font-weight: 500;
    text-align: start;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: anywhere;
    color: ${props => props.theme.palette.primary.main};
  }
`
export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 16px;

  & .MuiFormControlLabel-root {
    width: 100%;
    justify-content: space-between;
    height: 40px;
    margin-right: 0;
  }
`
