import styled from 'styled-components'
import { Stack } from '@mui/material'

export const SideBarDocsWrapper = styled(Stack)`
  min-width: 195px;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @supports (-moz-appearance:none) {
    scrollbar-width: none;
  }
`