import { Checkbox, Select, Stack, Typography } from "@mui/material"
import { FilterRenderText, FilterSelectMenuItem, StickyContainer, StyledButton, StyledTextField } from "./FilterSubmitSelectMultiple.styles"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FilterSelectProps, SelectItem } from "./FilterSubmitSelectMultiple.types"
import { SelectMenuProps } from "../UserManagement/components/UserObjectsSelection"
import SearchIcon from '@mui/icons-material/Search';

export const FilterSubmitSelectMultiple = <T extends number | string>({
  startIcon,
  items,
  selectedOptions,
  defaultValue,
  onSubmit,
  isDisabled,
  allSelectedText,
  paperFullHeight = false,
  className,
  ...props
}: FilterSelectProps<T> & { children?: React.ReactNode }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState<T[]>([])
  const [filteredOptions, setFilteredOptions] = useState<SelectItem<T>[]>([])

  const isAllSelected = items?.length === values.length

  const itemsByValue = useMemo(() => {
    const result = {} as Record<SelectItem<T>['value'], SelectItem<T>>
    items?.forEach((item) => {
      result[item.value] = item
    })
    return result
  }, [items])

  const handleChange = (e: any) => {
    const value = e?.target?.value

    if (value[value.length - 1] === 'all') {
      setValues(isAllSelected ? [] : items?.map(item => (item.value as T)))
      return
    }

    setValues(typeof value === 'string' ? value.split(',') : value)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toLowerCase()
    const filteredData = items.filter(item => String(item.value).toLowerCase().includes(inputValue))
    setFilteredOptions(filteredData)
  }

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const resetFilter = () => {
    onSubmit([])
    setValues([])
  }

  const arrayCompare = useCallback((a: T[], b: T[]) => (a.length === b.length)
    && a.every((element) => b.includes(element)), [])

  useEffect(() => {
    if (items.length > 0) {
      setFilteredOptions(items)
    }
  }, [items])

  useEffect(() => {
    if (selectedOptions.length > 0) {
      const selectedValues = selectedOptions.map(option => items.find(opt => opt.value === option)!.value)
      setValues(selectedValues)
    }
  }, [items, selectedOptions])

  return (
    <Stack justifyContent='center' className={className} {...props}>
      <Select
        disabled={isDisabled}
        multiple
        sx={{
          '& > .MuiSvgIcon-root': {
            right: '6px',
            fontSize: '16px !important',
            color: '#2B3648',
          },
          '& .MuiSelect-select': {
            borderRadius: '6px'
          },
          '& div': {
            alignItems: 'center',
            paddingRight: 0.2,
          },
        }}
        variant='standard'
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={values}
        onChange={handleChange}
        disableUnderline
        displayEmpty
        MenuProps={{
          disableAutoFocusItem: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 100
          },
          MenuListProps: {
            sx: {paddingTop: 0}
          },
          PaperProps: {
            style: {
              ...(!paperFullHeight ? SelectMenuProps.PaperProps.style : {}),
              minWidth: 200, maxHeight: 200
            }
          },
        }}
        renderValue={(selected) => {
          return (
            <Stack direction='row'>
              {!!startIcon ? startIcon : <Stack height={24} />}
              <Stack ml={1} className='contentWrapper'>
                {selected?.length === 0
                  ? (
                    <FilterRenderText variant='buttonSmall' className='contentText'>{defaultValue}</FilterRenderText>
                  )
                  : (
                    <FilterRenderText
                      variant='buttonSmall'
                      textOverflow='ellipsis'
                      overflow='hidden'
                      maxWidth={200}
                      className='contentText'
                    >
                      {isAllSelected
                        ? allSelectedText
                        : selected
                          ?.map((itemValue) => itemsByValue[itemValue]?.title)
                          .join(', ')}
                    </FilterRenderText>
                  )}
              </Stack>
            </Stack>
          )
        }}
      >
        <StickyContainer direction='row' spacing={2} py={1} px={2}>
          <StyledTextField
            onKeyDown={e => e.stopPropagation()}
            type="search"
            placeholder="Поиск"
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <SearchIcon fontSize="medium" />
              )
            }}
          />
          {!selectedOptions.length || !arrayCompare(selectedOptions, values) ? (
            <StyledButton
              variant="text"
              color="primary"
              onClick={() => onSubmit(values)}
              disabled={values.length === 0 && selectedOptions.length === 0}
            >
              Применить
            </StyledButton>
          ) : (
            <StyledButton variant="text" color="warning" onClick={resetFilter}>
              Сбросить
            </StyledButton>
          )}
        </StickyContainer>

        <FilterSelectMenuItem value='all'>
          <Checkbox checked={isAllSelected} />
          <Typography variant='body1'>{allSelectedText}</Typography>
        </FilterSelectMenuItem>

        {filteredOptions.map((item) => {
          const { value: name, title } = item
          return (
            <FilterSelectMenuItem key={name} value={name}>
              <Checkbox checked={values.includes(name)} />
              <Typography variant='body1'>{title}</Typography>
            </FilterSelectMenuItem>
          )
        })}
      </Select>
    </Stack>
  )
}
