import { Button, ListItem, ListItemText } from "@mui/material";
import styled from "styled-components";

export const StyledListItem = styled(ListItem)`
  & .MuiListItemSecondaryAction-root {
    right: 0;
  }
`
export const StyledItemText = styled(ListItemText)`
  max-width: 200px;
  margin: 0;


  & .MuiListItemText-primary {
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  & .MuiListItemText-secondary {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${props => props.theme.palette.disabled}
  }
`
export const CloseButton = styled(Button)`
  text-transform: none;
  font-weight: 400;
  height: 30px;
  padding: 5px 10px;
  background-color: ${props => props.theme.palette.table.orange + '1A'};
  color: ${props => props.theme.palette.table.orange};

  :hover {
    background-color: ${props => props.theme.palette.table.orange + '2A'};
  }
`

export const OpenButton = styled(Button)`
  text-transform: none;
  font-weight: 400;
  height: 30px;
  padding: 5px 10px;
  background-color: ${props => props.theme.palette.bg.lightBlue};
  color: ${props => props.theme.palette.primary.main};

  :hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }
`

