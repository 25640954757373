import { useEffect } from 'react'
import { UseRdUploadProps, UseRdUploadReturn } from './useRdUpload.types'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import { useUploadRdMutation } from '../../../../../../api/rdPhase'
import { useUploadPdMutation, useUploadPpdMutation } from '../../../../../../api/pdPhase'
import { useUploadIiMutation } from '../../../../../../api/iiPhase'
import { useUploadIrdMutation } from '../../../../../../api/irdPhase'
import { useUploadRdpMutation } from '../../../../../../api/rdPhase/rdp'

export const useRdUpload = ({ variant, onRdUpload, files, projectId }: UseRdUploadProps): UseRdUploadReturn => {
  // Rd
  const [uploadRd, {
    isLoading: isUploadRdLoading,
    ...uploadRdResponse
  }] = useUploadRdMutation()

  useMutationHandlers(
    uploadRdResponse,
    (data) => {
      onRdUpload(data)
    }
  )


  // Rdp
  const [uploadRdp, {
    isLoading: isUploadRdpLoading,
    ...uploadRdpResponse
  }] = useUploadRdpMutation()

  useMutationHandlers(
    uploadRdpResponse,
    (data) => {
      onRdUpload(data)
    }
  )


  // Pd
  const [uploadPd, {
    isLoading: isUploadPdLoading,
    ...uploadPdResponse
  }] = useUploadPdMutation()

  useMutationHandlers(
    uploadPdResponse,
    (data) => {
      onRdUpload(data)
    }
  )


  // Ppd
  const [uploadPpd, {
    isLoading: isUploadPpdLoading,
    ...uploadPpdResponse
  }] = useUploadPpdMutation()

  useMutationHandlers(
    uploadPpdResponse,
    (data) => {
      onRdUpload(data)
    }
  )


  // Ii
  const [uploadIi, {
    isLoading: isUploadIiLoading,
    ...uploadIiResponse
  }] = useUploadIiMutation()

  useMutationHandlers(
    uploadIiResponse,
    (data) => {
      onRdUpload(data)
    }
  )


  // Ird
  const [uploadIrd, {
    isLoading: isUploadIrdLoading,
    ...uploadIrdResponse
  }] = useUploadIrdMutation()

  useMutationHandlers(
    uploadIrdResponse,
    (data) => {
      onRdUpload(data)
    }
  )


  // Common
  useEffect(() => {
    if (files?.length) {
      const file = files[0]
      switch (variant) {
        case 'rd':
          uploadRd({ file })
          break
        case 'rdp':
          projectId && uploadRdp({ file, id: projectId })
          break
        case 'pd':
          uploadPd({ file })
          break
        case 'ppd':
          projectId && uploadPpd({ file, id: projectId })
          break
        case 'ii':
          projectId && uploadIi({ file, id: projectId })
          break
        case 'ird':
          projectId && uploadIrd({ file, id: projectId })
          break
      }
    }
  }, [files])

  const rdsLoading =
    isUploadRdLoading
    || isUploadRdpLoading
    || isUploadPdLoading
    || isUploadPpdLoading
    || isUploadIiLoading
    || isUploadIrdLoading

  return {
    rdsLoading
  }
}
