import React, { useEffect } from 'react'

import { tomIiApi, useUploadTomIiMutation } from '../../../../api/iiPhase'
import { tomIrdApi, useUploadTomIrdMutation } from '../../../../api/irdPhase'
import { tomPdApi, useUploadTomPdMutation } from '../../../../api/pdPhase'
import { tomRdApi, useUploadTomRdMutation } from '../../../../api/rdPhase'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { onDrawerClose as onDrawerCloseStore } from '../../../../store/slices/documentsPages/drawerInfo'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector,
} from '../../../../store/slices/documentsPages/projectInfo'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import {
  UseDocsSubmitDocsUploadDrawerProps,
  UseDocsSubmitDocsUploadDrawerResponse,
} from './useDocsSubmitDocsUploadDrawer.types'

export const useDocsSubmitDocsUploadDrawer = ({
  files,
  setFiles,
  responseData,
  setResponseData,
}: UseDocsSubmitDocsUploadDrawerProps): UseDocsSubmitDocsUploadDrawerResponse => {
  const dispatch = useAppDispatch()

  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  // Rd
  const [uploadTomRd, { isLoading: isTomRdLoading, ...uploadTomRdResponse }] = useUploadTomRdMutation()

  useMutationHandlers(uploadTomRdResponse, (data) => {
    setResponseData(data)
  })

  // Pd
  const [uploadTomPd, { isLoading: isTomPdLoading, ...uploadTomPdResponse }] = useUploadTomPdMutation()

  useMutationHandlers(uploadTomPdResponse, (data) => {
    setResponseData(data)
  })

  // Ii
  const [uploadTomIi, { isLoading: isTomIiLoading, ...uploadTomIiResponse }] = useUploadTomIiMutation()

  useMutationHandlers(uploadTomIiResponse, (data) => {
    setResponseData(data)
  })

  // Ird
  const [uploadTomIrd, { isLoading: isTomIrdLoading, ...uploadTomIrdResponse }] = useUploadTomIrdMutation()

  useMutationHandlers(uploadTomIrdResponse, (data) => {
    setResponseData(data)
  })

  // Common
  useEffect(() => {
    if (files?.length && files[0] instanceof File) {
      switch (selectedProjectPhase) {
        case 'Рабочая документация':
          uploadTomRd({ id: project.id, file: files[0] })
          break
        case 'Проектная документация':
          uploadTomPd({ id: project.id, file: files[0] })
          break
        case 'Инженерные изыскания':
          uploadTomIi({ id: project.id, file: files[0] })
          break
        case 'Сбор исходных данных':
          uploadTomIrd({ id: project.id, file: files[0] })
          break
      }
    }
  }, [files])

  const onDrawerClose = (dirty: boolean, immediately?: boolean) => {
    dispatch(onDrawerCloseStore({ dirty, immediately }))

    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        responseData?.success && dispatch(tomRdApi.util.invalidateTags([{ type: 'TomRd', id: 'UPLOAD' }]))
        break
      case 'Проектная документация':
        responseData?.success && dispatch(tomPdApi.util.invalidateTags([{ type: 'TomPd', id: 'UPLOAD' }]))
        break
      case 'Инженерные изыскания':
        responseData?.success && dispatch(tomIiApi.util.invalidateTags([{ type: 'TomIi', id: 'UPLOAD' }]))
        break
      case 'Сбор исходных данных':
        responseData?.success && dispatch(tomIrdApi.util.invalidateTags([{ type: 'TomIrd', id: 'UPLOAD' }]))
        break
    }

    setResponseData(undefined)
    setFiles(null)
  }

  const isTomLoading = isTomRdLoading || isTomPdLoading || isTomIiLoading || isTomIrdLoading

  return {
    onDrawerClose,
    isTomLoading,
  }
}
