import styled from "styled-components";
import { Popover } from "../../../../components/Popover";

export const PersonCellPopover = styled(Popover)`
  & .popover-content-wrapper {
    min-width: 300px;
    max-width: 400px;
  }

  & .email {
    max-width: 130px;
  }
`