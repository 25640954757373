import React from 'react'
import { UseGetColumnsProps, UseGetColumnsResponse } from './useGetColumns.types'
import { GridColumns } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { RowEditIconButton } from '../../../../styles/global/StyledDataGrid'
import EditIcon from '@mui/icons-material/Edit'
import { ProjectPhase } from '../../../../types/project'
import { useTypedSelector } from '../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../store/slices/documentsPages/projectInfo'

export const useGetColumns = ({
  onObjectEditClick,
  onRdpEditClick,
  onPdEditClick,
  onIiEditClick,
  onIrdEditClick,
}: UseGetColumnsProps): UseGetColumnsResponse => {
  const { userWithLessControls } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  // Rd
  const objectsColumns: GridColumns = [
    // {
    //   field: 'id',
    //   headerName: '№',
    //   width: 60,
    //   align: 'center',
    //   renderCell: params => {
    //     const index = objects.findIndex(object => object.id === params.value)
    //     return <div className='MuiDataGrid-cellContent'>{index}</div>
    //   },
    //   headerAlign: 'center',
    //   sortable: false,
    // },
    {
      field: 'number',
      headerName: 'Номер объекта',
      width: 376,
      align: 'left',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'title',
      headerName: 'Краткое название',
      width: 376,
      align: 'left',
      headerAlign: 'center',
      sortable: false,

    },
    {
      field: 'description',
      headerName: 'Полное наименование',
      minWidth: 300,
      flex: 1,
      cellClassName: 'editableRow',
      headerAlign: 'center',
      sortable: false,
      renderCell: params => {
        return !userWithLessControls && (
          <>
            <Box className='MuiDataGrid-cellContent' flex={1} style={{ textAlign: 'left' }}>{params.value}</Box>
            <RowEditIconButton
              onClick={(e) => onObjectEditClick(e, params.row.id)}
              variant='text'
              icon
            >
              <EditIcon fontSize='medium' />
            </RowEditIconButton>
          </>
        )
      },
    },
  ]


  // Rdp
  // Pd
  // Ii
  // Ird
  const pdColumns: GridColumns = [
    // {
    //   field: 'id',
    //   headerName: '№',
    //   width: 60,
    //   align: 'center',
    //   headerAlign: 'center',
    //   sortable: false,
    //   renderCell: params => {
    //     const index = pds.findIndex(pd => pd.id === params.value)
    //     return <div className='MuiDataGrid-cellContent'>{index}</div>
    //   },
    // },
    {
      field: 'mark',
      headerName: 'Марка',
      width: 204,
      align: 'left',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'fullName',
      headerName: 'Наименование',
      width: 595,
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Примечание',
      minWidth: 480,
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      renderCell: params => {
        const onClick: Record<ProjectPhase, (
          e: React.MouseEvent<HTMLButtonElement>,
          id: number
        ) => void> = {
          'Рабочая документация': (e: React.MouseEvent<HTMLButtonElement>, rdpId: number) => onRdpEditClick(e, rdpId),
          'Проектная документация': (e: React.MouseEvent<HTMLButtonElement>, pdId: number) => onPdEditClick(e, pdId),
          'Инженерные изыскания': (e: React.MouseEvent<HTMLButtonElement>, iiId: number) => onIiEditClick(e, iiId),
          'Сбор исходных данных': (e: React.MouseEvent<HTMLButtonElement>, irdId: number) => onIrdEditClick(e, irdId),
        }

        return !userWithLessControls && (
          <>
            <Box className='MuiDataGrid-cellContent' flex={1} style={{ textAlign: 'left' }}>{params.value}</Box>
            <RowEditIconButton
              onClick={(e) => onClick[selectedProjectPhase](e, params.row.id)}
              variant='text'
              icon
            >
              <EditIcon fontSize='medium' />
            </RowEditIconButton>
          </>
        )
      },
    },
  ]

  return {
    objectsColumns,
    pdColumns,
  }
}
