import { useCallback, useState } from 'react'
import { UseExpandResponse } from './useExpand.types'

// @ts-ignore:next-line
import { ProjectPhase } from '../../../../../../types/project'
import { useTypedSelector } from '../../../../../../store/store'
import { filterDataSelector } from '../../../../../../store/slices/documentsPages/filter'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'

export const useExpand = (): UseExpandResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { filterData } = useTypedSelector(filterDataSelector)
  const { selectedObjectsIds, selectedPdsIds, selectedIisIds, selectedIrdsIds } = filterData

  const defaultExpanded: Record<ProjectPhase, number> = {
    'Рабочая документация': selectedObjectsIds[0],
    'Проектная документация': selectedPdsIds[0],
    'Инженерные изыскания': selectedIisIds[0],
    'Сбор исходных данных': selectedIrdsIds[0],
  }

  const [expanded, setExpanded] = useState<number | false>(defaultExpanded[selectedProjectPhase] || false)
  const onExpand = useCallback((panelId: number, isExpanded: boolean) => {
    setExpanded(isExpanded ? false : panelId)
  }, [])

  return {
    expanded,
    onExpand,
  }
}
