import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Crop } from 'react-image-crop/dist/types'
import { CropModalProps } from './CropModal.types'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import { getCroppedImg } from './utils'
import 'react-image-crop/dist/ReactCrop.css'
import { Button, Dialog, Stack } from '@mui/material'

const CropModal: React.FC<CropModalProps> = ({
  file,
  isOpen,
  onClose,
  onSave,
  onCancel,
  aspect = 1
}) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const src = useMemo(() => (file && URL.createObjectURL(file)) || '', [file])

  const [crop, setCrop] = useState<Crop>({} as Crop)

  const handleImageLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const width = e?.currentTarget?.width || 0
    const height = e?.currentTarget?.height || 0
    const isHorizontal = width > height

    setCrop(centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: isHorizontal ? width / 2 : undefined,
          height: !isHorizontal ? height / 2 : undefined,
        },
        aspect,
        width,
        height
      ),
      width,
      height
    ))
  }, [aspect])

  const handleSave = useCallback(async () => {
    if (imageRef.current) {
      const croppedImage = await getCroppedImg(imageRef?.current, crop)
      onSave?.(croppedImage)
    }
  }, [onSave, crop])

  return (
    <Dialog
      open={!!isOpen}
      onClose={onClose}
    >
      <Stack width={400}>
        <ReactCrop
          aspect={aspect}
          crop={crop}
          onChange={setCrop}
        >
          <img ref={imageRef} src={src} onLoad={handleImageLoad} alt='cropping' />
        </ReactCrop>
        <Stack p={1} flex={1} direction='row'>
          <Button
            fullWidth
            variant='contained'
            type='submit'
            color='success'
            onClick={handleSave}
          >
            Сохранить
          </Button>
          <Button
            sx={{ ml: 1 }}
            fullWidth
            variant='contained'
            onClick={onCancel}
          >
            Отменить
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default CropModal