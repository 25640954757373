import React, { useCallback, useMemo, useState } from 'react'
import AdminRdLegend from '../components/AdminRdLegend'
import { Grid, Stack, useMediaQuery } from '@mui/material'
import { GetAllRdResponse, Rd, useGetAllRdQuery } from '../../../api/rdPhase'
import RdCard from '../components/RdCard'
import { Limit } from '../../../types/global'
import { NUMBER_OF_ROWS_PER_RD_PAGE, XXL_FOR_LAYOUT } from '../../../utils/constants'
import { GridColumns } from '@mui/x-data-grid'
import RdDrawer from './RdDrawer'
import { filterByFieldNames } from '../../../utils/filterByFieldNames'
import useSearch from '../../../hooks/useSearch'
import useConfirmDialog, { UseExitConfirmProps } from '../../../hooks/useConfirmDialog'
import Progress from '../../../components/Progress'
import { EmptyPageData } from '../../../components/EmptyPage/EmptyPage.types'
import { getEmptyPageData } from '../../Home'
import EmptyPage from '../../../components/EmptyPage'
import AddIcon from '@mui/icons-material/Add'
import { StyledDataGrid } from '../../../styles/global/StyledDataGrid'
import useBreadcrumbs from '../../../hooks/useBreadcrumbs'
import { adminRdViewSelector } from '../../../store/slices/ui'
import { useTypedSelector } from '../../../store/store'
import { RdUploadDrawer } from './RdUploadDrawer'
import DownloadingIcon from '@mui/icons-material/Downloading'

const AdminRd: React.FC = () => {
  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_LAYOUT})`)
  const { searchValue } = useSearch()

  const viewMode = useTypedSelector(adminRdViewSelector)

  useBreadcrumbs([
    { title: 'Разделы РД' }
  ])

  const [openedDrawer, setOpenedDrawer] = useState<'create' | 'upload' | null>(null)
  const [chosenRdId, setChosenRdId] = useState<number | null>(null)

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setOpenedDrawer(null)
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onAddRdClick = useCallback(() => {
    setOpenedDrawer('create')
    setChosenRdId(null)
  }, [])

  const onUploadClick = useCallback(() => {
    setOpenedDrawer('upload')
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty
      ? setOpenedDrawer(null)
      : openConfirm()
  }, [])

  const onRdItemClick = useCallback((rdId: number) => {
    setOpenedDrawer('create')
    setChosenRdId(rdId)
  }, [])


  const [limitValue, setLimitValue] = useState<Limit['value']>(
    NUMBER_OF_ROWS_PER_RD_PAGE[0].value
  )
  const [page, setPage] = useState(1)

  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page])

  const { data, isLoading, isFetching } = useGetAllRdQuery({
    // limit: !!searchParam ? 999 : limitValue,
    // offset: !!searchParam ? 0 : offset,
    limit: limitValue,
    offset: offset,
  })

  const { data: rdData, total } = data || ({} as GetAllRdResponse)

  const countPagination = useMemo(
    () => Math.ceil(total / limitValue) || 1,
    [total, limitValue]
  )

  const handleChangeLimit = useCallback(
    (limit: number) => {
      setLimitValue(Number(limit))
      setPage(1)
    },
    [setLimitValue, setPage]
  )
  const handleChangePage = useCallback((page: number) => setPage(page), [setPage])

  const filteredData = useMemo(() => {
    return searchValue && rdData?.length
      ? (() => {
        setPage(1)
        return filterByFieldNames<Rd>(rdData, ['mark', 'description', 'fullName'], searchValue)
      })()
      : rdData
  }, [rdData, searchValue])


  const columns: GridColumns = [
    // {
    //   field: 'id',
    //   headerName: '№',
    //   width: 60,
    //   align: 'center',
    //   headerAlign: 'center',
    //   sortable: false,
    // },
    {
      field: 'mark',
      headerName: 'Марка',
      width: 204,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'fullName',
      headerName: 'Наименование',
      width: 595,
      headerAlign: 'center',
      sortable: false,

    },
    {
      field: 'description',
      headerName: 'Примечание',
      minWidth: 300,
      flex: 1,
      headerAlign: 'center',
      sortable: false,
    },
  ]

  const renderDataView = () => {
    switch (viewMode) {
      case 'list':
        return (
          <Grid sx={{ py: 1.25 }} spacing={2.5} container>
            {filteredData?.map(rd => (
              <Grid
                item
                xs={12} md={6} lg={4} xl={xxl ? 2 : 3}
                container
                justifyContent='center'
                key={rd.id}
              >
                <RdCard data={rd} onClick={onRdItemClick} />
              </Grid>
            ))}
          </Grid>
        )
      case 'table':
        return (
          <StyledDataGrid
            rows={filteredData}
            columns={columns}
            pageSize={limitValue}
            rowsPerPageOptions={[limitValue]}
            onRowClick={(params) => onRdItemClick(params.row.id)}
            hideFooter
            headerHeight={40}
            rowHeight={40}
            showCellRightBorder
            showColumnRightBorder
            disableSelectionOnClick
            disableColumnMenu
          />
        )
    }
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>Все группы РД были удалены,
      для добавления документации добавьте марки.</>,
    [
      {
      text: 'Добавить раздел РД',
      icon: AddIcon,
      onClick: () => onAddRdClick()
    },
      {
        text: 'Загрузить',
        icon: DownloadingIcon,
        onClick: () => onUploadClick()
      }
    ]
  )

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют группы РД, соответствующие результатам запроса.</>
  )

  return (
    <Stack flex={1}>
      {isLoading || isFetching
        ? <Progress />
        : rdData?.length
          ? (
            <>
              <AdminRdLegend
                onAddClick={onAddRdClick}
                onUploadClick={onUploadClick}
                countPagination={countPagination}
                limit={limitValue}
                onChangeLimit={handleChangeLimit}
                onChangePage={handleChangePage}
                page={page}
                numberRows={NUMBER_OF_ROWS_PER_RD_PAGE}
              />
              {filteredData?.length
                ? renderDataView()
                : <EmptyPage data={emptyFilteredData} forFilter />
              }
            </>
          )
          : <EmptyPage data={emptyPageData} fullPage />
      }

      <RdDrawer
        open={openedDrawer === 'create'}
        onClose={onDrawerClose}
        rdId={chosenRdId}
      />

      <RdUploadDrawer
        variant='rd'
        open={openedDrawer === 'upload'}
        onClose={onDrawerClose}
      />
      <ConfirmDialog />
    </Stack>
  )
}

export default AdminRd