import { TextField } from "@mui/material";
import styled from "styled-components";

export const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    font-size: 14px;
    border-radius: 8px;
    width: 73px;
  }

  & .MuiOutlinedInput-input {
    height: 23px;
    padding: 5px 8px 5px 16px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #2B36481F;
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2b36488c;
    border-width: 1px;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #2b36488c;
  }
`