import { useFormikContext } from "formik"
import { ClearButton, SectionWrapper } from "./ManualSubmitViewSelected.styles"
import { ManualSubmitValues } from "../ManualSubmitDrawer"
import { Stack, Typography } from "@mui/material"
import { AccordionDetailsItem } from "../AccordionDetailsItem"
import { ScrollableContainer } from "styles/global/ScrollableContainer"

interface ManualSubmitViewSelectedProps {
  setSelectAll: (value: boolean) => void
}

export const ManualSubmitViewSelected = ({ setSelectAll }: ManualSubmitViewSelectedProps) => {

  const { values, setFieldValue } = useFormikContext<ManualSubmitValues>()

  const onClearList = () => {
    setFieldValue('selectedToms', [])
    setSelectAll(false)
  }

  return (
    <SectionWrapper>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant="subtitle2">Выбранные документы</Typography>
        <ClearButton variant="text" color="warning" onClick={onClearList}>
          очистить список
        </ClearButton>
      </Stack>
      <ScrollableContainer spacing={1.5}>
        {values.selectedToms.map((tom) =>
          <AccordionDetailsItem tom={tom} key={tom.id} />
        )}
      </ScrollableContainer>
    </SectionWrapper>
  )
}