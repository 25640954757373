import React from 'react'
import { UseGetSidebarItemsProps, UseGetSidebarItemsResponse } from './useGetSidebarItems.types'
import { useGetSidebarObjectsQuery } from '../../../../api/rdPhase'
import { useGetSidebarPdsQuery } from '../../../../api/pdPhase'
import { useGetSidebarIiQuery } from '../../../../api/iiPhase'
import { useGetSidebarIrdQuery } from '../../../../api/irdPhase'
import { SidebarItems } from '../../../../store/slices/documentsPages/sidebar'

export const useGetSidebarItems = ({
  projectId,
  currentPage,
  selectedProjectPhase,
}: UseGetSidebarItemsProps): UseGetSidebarItemsResponse => {

  // Rd
  const { data: sidebarObjectsData, isFetching: sidebarObjectsFetching } = useGetSidebarObjectsQuery(
    { id: projectId },
    { skip: currentPage !== 'docs' || selectedProjectPhase !== 'Рабочая документация' }
  )
  const { menuList: sidebarObjects = [] } = sidebarObjectsData || {}


  // Pd
  const { data: sidebarPdsData, isFetching: sidebarPdsFetching } = useGetSidebarPdsQuery(
    { id: projectId },
    { skip: currentPage !== 'docs' || selectedProjectPhase !== 'Проектная документация' }
  )
  const { menuList: sidebarPds = [] } = sidebarPdsData || {}


  // Ii
  const { data: sidebarIisData, isFetching: sidebarIisFetching } = useGetSidebarIiQuery(
    { id: projectId },
    { skip: currentPage !== 'docs' || selectedProjectPhase !== 'Инженерные изыскания' }
  )
  const { menuList: sidebarIis = [] } = sidebarIisData || {}


  // Ird
  const { data: sidebarIrdsData, isFetching: sidebarIrdsFetching } = useGetSidebarIrdQuery(
    { id: projectId },
    { skip: currentPage !== 'docs' || selectedProjectPhase !== 'Сбор исходных данных' }
  )
  const { menuList: sidebarIrds = [] } = sidebarIrdsData || {}


  // Common
  const sidebarItems: SidebarItems = {
    sidebarObjects,
    sidebarPds,
    sidebarIis,
    sidebarIrds,
  }

  const sidebarDataLoading =
    sidebarObjectsFetching
    || sidebarPdsFetching
    || sidebarIisFetching
    || sidebarIrdsFetching

  return {
    sidebarItems,
    sidebarDataLoading,
  }
}
