import {
  ProjectStatisticsIi,
  ProjectStatisticsIrd,
  ProjectStatisticsPd,
  ProjectStatisticsRd,
  StatIi,
  StatIrd,
  StatPd,
  StatRd,
  StatTIM,
} from '../../api/projects/types'
import { LegendColors } from '../global'
import { TomType } from '../tom'

// export type ProjectStatusesRu = 'Разработка' | 'Авторский надзор' | 'Согласовано' | 'Архив'
export type ProjectStatuses = 'all' | 'develop' | 'agreed' | 'archive'
export type ProjectStatusesRu = 'Разработка' | 'Согласовано' | 'Архив'
export type ProjectStatusesRuFilter = 'Все проекты' | ProjectStatusesRu

export type ProjectFullStatusesRu = 'Разработка документации' | 'Документация согласована' | 'Архив'
export type ProjectTabLabels = 'Все проекты' | ProjectFullStatusesRu

export type ProjectCardStatuses =
  | 'В производстве'
  | 'Не передано'
  | 'Не согласуется'
  | 'Согласовано'
  | 'В работе'
  | 'Корректировка'
  | 'На согласовании'
  | 'Разработка'
  | 'Не в работе'

export type ProjectStatusesEn =
  | 'agreed'
  | 'inProd'
  | 'notInProd'
  | 'inWork'
  | 'agreement'
  | 'correction'
  | 'development'
  | 'noNeedAgree'
  | 'noInWork'

export type ProjectPhase =
  | 'Сбор исходных данных'
  | 'Инженерные изыскания'
  | 'Проектная документация'
  | 'Рабочая документация'
export type ProjectType = 'Производственного и непроизводственного назначения' | 'Линейные объекты'
export type ProjectExpertise =
  | 'Направлено в экспертизу'
  | 'Получены замечания'
  | 'Корректировка документации по замечаниям'
  | 'Положительное заключение'
  | 'Отрицательное заключение'
  | 'Повторная экспертиза'
export type ProjectPhaseEn = 'ird' | 'ii' | 'pd' | 'rd'

export const projectPhaseRuToEn: Record<ProjectPhase, ProjectPhaseEn> = {
  'Сбор исходных данных': 'ird',
  'Инженерные изыскания': 'ii',
  'Проектная документация': 'pd',
  'Рабочая документация': 'rd',
}

export const projectPhaseEnToRu: Record<ProjectPhaseEn, ProjectPhase> = {
  ird: 'Сбор исходных данных',
  ii: 'Инженерные изыскания',
  pd: 'Проектная документация',
  rd: 'Рабочая документация',
}

export const projectPhaseRuToRuShort: Record<ProjectPhase, TomType> = {
  'Сбор исходных данных': 'ИРД',
  'Инженерные изыскания': 'ИИ',
  'Проектная документация': 'ПД',
  'Рабочая документация': 'РД',
}

export const projectTypeShort: Record<ProjectType, string> = {
  'Линейные объекты': 'Линейные объекты',
  'Производственного и непроизводственного назначения': 'Произв. и непроизв. назначения',
}

// export const allProjectsFullStatuses: ProjectFullStatusesRu[] = ['Разработка документации', 'Авторский надзор', 'Документация согласована', 'Архив']
export const allProjectsFullStatuses: ProjectFullStatusesRu[] = [
  'Разработка документации',
  'Документация согласована',
  'Архив',
]
export const allProjectsPhases: ProjectPhase[] = [
  'Сбор исходных данных',
  'Инженерные изыскания',
  'Проектная документация',
  'Рабочая документация',
]
export const allProjectsPhasesShort: TomType[] = ['ИРД', 'ИИ', 'ПД', 'РД']
export const allProjectsTypes: ProjectType[] = [
  'Производственного и непроизводственного назначения',
  'Линейные объекты',
]
export const allProjectsExpertise: ProjectExpertise[] = [
  'Направлено в экспертизу',
  'Получены замечания',
  'Корректировка документации по замечаниям',
  'Положительное заключение',
  'Отрицательное заключение',
  'Повторная экспертиза',
]

export const projectStatusesTabLabelRuByEn: Record<ProjectStatuses, ProjectTabLabels> = {
  all: 'Все проекты',
  develop: 'Разработка документации',
  // supervision: 'Авторский надзор',
  agreed: 'Документация согласована',
  archive: 'Архив',
}

export const ProjectStatusesTabLabels: Record<ProjectStatusesRuFilter, ProjectTabLabels> = {
  'Все проекты': 'Все проекты',
  Разработка: 'Разработка документации',
  // 'Авторский надзор': 'Авторский надзор',
  Согласовано: 'Документация согласована',
  Архив: 'Архив',
}

export const shortStatusByFullStatus: Record<ProjectFullStatusesRu, ProjectStatusesRu> = {
  'Разработка документации': 'Разработка',
  'Документация согласована': 'Согласовано',
  // 'Авторский надзор': 'Авторский надзор',
  Архив: 'Архив',
}

export const ProjectStatusesColors: Record<ProjectFullStatusesRu, LegendColors> = {
  'Разработка документации': 'blue',
  // 'Авторский надзор': 'purple',
  'Документация согласована': 'green',
  Архив: 'disabled',
}

export const ProjectCardStatusesColors: Record<ProjectCardStatuses, LegendColors> = {
  'В производстве': 'olive',
  'Не передано': 'lightLime',
  'Не согласуется': 'olive',
  Согласовано: 'green',
  'В работе': 'blue',
  Корректировка: 'yellow',
  'На согласовании': 'red',
  Разработка: 'purple',
  'Не в работе': 'gray',
}

export const isDotLegend = (value: string) => {
  return ['В производстве', 'Не передано', 'Корректировка', 'На согласовании', 'Разработка'].includes(value)
}

export type ProjectStatistics = ProjectStatisticsRd | ProjectStatisticsPd | ProjectStatisticsIi | ProjectStatisticsIrd
export type Stat = StatRd | StatPd | StatIi | StatIrd | StatTIM
