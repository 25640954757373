import { Stack } from "@mui/material"
import { useMemo } from "react"
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useFormikContext } from "formik"
import { ManualSubmitValues } from "../ManualSubmitDrawer"
import { AccordionDetailsItemProps } from "./AccordionDetailsItem.types"
import { ColoredCircleIcon, ItemButton, ItemText } from "./AccordionDetailsItem.styles"
import { tomStatusesColors } from "types/tom"
import Tooltip from "components/Tooltip"
import { RemarkMailTom } from "api/remarks/types"

export const AccordionDetailsItem = ({ tom }: AccordionDetailsItemProps) => {
  const { values, setFieldValue } = useFormikContext<ManualSubmitValues>()

  const selected = useMemo(() => values.selectedToms.some(selectedTom => selectedTom.id === tom.id), [tom.id, values.selectedToms])

  const onRemoveClick = () => {
    const updatedSelectedToms = values.selectedToms.filter(selectedTom => selectedTom.id !== tom.id)
    setFieldValue('selectedToms', updatedSelectedToms)
  }

  const onAddClick = () => {
    const tomvalue: RemarkMailTom = {
      id: tom.id,
      name: tom.name,
      status: tom.status
    }
    setFieldValue('selectedToms', [...values.selectedToms, tomvalue])
  }

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} py={0.75} pr={1}>
      <Stack direction='row' spacing={1} alignItems='center' width='calc(100% - 25px)'>
        <ItemButton onClick={selected ? onRemoveClick : onAddClick}>
          {selected ?
            <RemoveIcon color='primary' /> :
            <AddIcon color='primary' fontSize="medium" />
          }
        </ItemButton>
        <ItemText variant='subtitle2'>{tom.name || ''}</ItemText>
      </Stack>
      <Tooltip variant="light" title={<>{tom.status}</>}>
      <ColoredCircleIcon $color={tomStatusesColors[tom.status]} />
      </Tooltip>
    </Stack>
  )
}