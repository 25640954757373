import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { AgreementFormConfirmTrigger, emptyResultTemplate, ProjectAgreementFormData, ProjectAgreementFormProps } from './ProjectAgreementForm.types'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectAgreementWrapper } from '../ProjectAgreement'
import { Box, Button, Stack, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ArrowBackButton, ProjectAgreementFormDeleteButton, ProjectAgreementFormTitle, SchemaContainer, StyledButton } from './ProjectAgreementForm.styles'
import { ProjectAgreementFormContent } from './components/ProjectAgreementFormContent'
import { useForm } from '../../../hooks/useForm'
import { Form, FormikProvider } from 'formik'
import { ProjectAgreementInstruction } from './components/ProjectAgreementInstruction'
import {
  useBindInnermapMutation,
  useCreateStageMutation,
  useDeleteStageMutation,
  useEditStageMutation,
  useGetStageByIdQuery
} from '../../../api/projectAgreement'
import { StageRequest } from '../../../api/projectAgreement/api.types'
import { validationAgreement } from './ProjectAgreementForm.validation'
import { useMutationHandlers } from '../../../hooks/useMutationHandlers'
import useQuery from '../../../hooks/useQuery'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useConfirmDialog, { UseExitConfirmProps } from '../../../hooks/useConfirmDialog'
import { currentRoadmapSelector, setIsStageFormDirty, setStageCompany, stagesSelector } from '../../../store/slices/agreement'
import { useAppDispatch, useTypedSelector } from '../../../store/store'
import { profileSelector } from '../../../store/slices/profile'
import { InternalAgreementDrawer } from '../../DocView/components/InternalAgreementDrawer'
import { onDrawerClose, openedDrawerSelector, setOpenedDrawer } from '../../../store/slices/documentsPages/drawerInfo'
import { selectedProjectPhaseSelector } from '../../../store/slices/documentsPages/projectInfo'
import { determineTomType } from '../../../types/tom'
import { TableFormValues, setCurrentSchema, setInitialSchemaCompanyFromMenu, setIsOpenFormAddMember, setSchemaFormMode, setTableFormValues } from '../../../store/slices/internalAgreement'
import { Innermap } from '../../../api/internalAgreement/types'
import { useGetInnermapByIdQuery, useGetInnermapsOfCompanyQuery } from '../../../api/internalAgreement'
import Progress from '../../../components/Progress'
import { BoundInternalSchema } from './components/BoundInternalSchema'
import Tooltip from '../../../components/Tooltip'
import { useSnackbar } from 'notistack'

export const ProjectAgreementForm: FC<ProjectAgreementFormProps> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { projectId: projectIdString, stageId: stageIdString } = useParams()
  const projectId = Number(projectIdString)
  const stageId = Number(stageIdString)
  const queryHandler = useQuery()
  const idLeftString: string | null = queryHandler.get('idLeft')
  const idRightString: string | null = queryHandler.get('idRight')
  const roadmapIdStr: string | null = queryHandler.get('roadmapId')
  const roadmapId = Number(roadmapIdStr)

  const stages = useTypedSelector(stagesSelector)
  const { employeeId, role } = useTypedSelector(profileSelector)
  const { person: responsibleRoadmap } = useTypedSelector(currentRoadmapSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { isLocked } = useTypedSelector(currentRoadmapSelector)

  const { data: currentStage, isFetching: isStageFetching } = useGetStageByIdQuery({ id: projectId, roadmapId, stageId }, { skip: !roadmapId || !stageId })

  const {
    title,
    duration,
    optDocs,
    optVisa,
    person,
    innermapId,
    isStart,
    results,
  } = currentStage || {}
  const company = person?.company

  const { data: innermapsResponse } = useGetInnermapsOfCompanyQuery({ projectId, company: company! }, { skip: !company })

  const { data: innermap, isFetching: isInnermapFetching } = useGetInnermapByIdQuery(
    { id: projectId, innermapId: innermapId! }, { skip: !innermapId }
  )

  const isAdmin = role === 'admin'
  const isResponsibleRoadmap = responsibleRoadmap && responsibleRoadmap.id === employeeId
  const isResponsibleStage = person?.id === employeeId
  const firstCreate = !stages?.length

  const initialValues: ProjectAgreementFormData = {
    title: title || '',
    duration: duration || 10,
    personId: person?.id || null,
    companyName: person?.company || '',
    idLeft: idLeftString ? Number(idLeftString) : undefined,
    idRight: idRightString ? Number(idRightString) : undefined,
    optDocs: optDocs || false,
    optVisa: optVisa || false,
    isStart: firstCreate ? true : (isStart || false),
    results: results?.map(result => ({ ...result, nextStageId: result.isFinal ? 'final' : result.nextStage?.id }))
      || [
        {
          ...emptyResultTemplate,
          type: true,
        },
        {
          ...emptyResultTemplate,
          type: false,
        }
      ],
  }

  const [createStage, createStageResponse] = useCreateStageMutation()
  const [editStage, editStageResponse] = useEditStageMutation()
  const [deleteStage, deleteStageResponse] = useDeleteStageMutation()
  const [deleteBindInnermap, deleteBindInnermapResponse] = useBindInnermapMutation()


  const onSubmit = (values: ProjectAgreementFormData) => {
    const personId = values.personId
    if (!personId || !roadmapId) return

    const correctResults = values.results
      .map(result => ({
        ...result,
        tomStatus: result?.tomStatus || undefined
      }))
      .map(result => {
        return result.nextStageId === 'final'
          ? {
            ...result,
            isFinal: true,
            nextStageId: undefined,
          }
          : {
            ...result,
            isFinal: false,
          }
      })

    const body: StageRequest = {
      ...values,
      personId,
      results: correctResults
    }

    if (stageId && innermapId && person?.company !== values.companyName) {
      onChangeCompany(body)
      return
    }

    stageId
      ? editStage({ id: projectId, roadmapId, stageId, body })
      : createStage({ id: projectId, roadmapId, body })
  }

  const { formik } = useForm({
    validationSchema: validationAgreement,
    enableReinitialize: true,
    initialValues,
    initialTouched: {
      results: [
        { title: true },
        { title: true },
      ]
    },
    onSubmit: (values) => onSubmit(values)
  })

  const { isValid, dirty } = formik

  useEffect(() => {
    dispatch(setIsStageFormDirty(dirty))
  }, [dirty])

  const returnToAgreement = () => {
    navigate(`/project/${projectId}/schemes/roadmap`)
  }

  const onAddSchemaClick = () => {
    if (person?.company) {
      dispatch(setCurrentSchema({} as Innermap))
      dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
      dispatch(setStageCompany(person.company))
      dispatch(setOpenedDrawer({ openedDrawer: 'internalAgreement' }))
    }

  }

  useMutationHandlers(
    createStageResponse,
    () => {
      returnToAgreement()
    }
  )

  useMutationHandlers(
    editStageResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(('Этап успешно изменен.'), { variant: 'success' })
      }
      returnToAgreement()
    }
  )

  useMutationHandlers(
    deleteBindInnermapResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(('Схема внутреннего согласования успешно откреплена'), { variant: 'success' })
      } else {
        enqueueSnackbar(('Не удалось открепить схему внутреннего согласования, попробуйте еще раз'), { variant: 'error' })
      }
    }
  )

  // confirm action

  const confirmReturn = (confirm: boolean) => {
    if (confirm) {
      returnToAgreement()
    }
  }

  const confirmDelete = (confirm: boolean) => {
    if (confirm && roadmapId) {
      deleteStage({ id: projectId, roadmapId, stageId })
    }
  }

  const confirmCloseDrawer = (confirm: boolean) => {
    if (confirm) {
      onInternalAgreementDrawerClose(true)
    }
  }

  const confirmDeleteBoundInnermap = (confirm: boolean) => {
    if (confirm) {
      if (confirm && roadmapId && stageId) {
        deleteBindInnermap({
          id: projectId,
          roadmapId,
          stageId
        })
      }
    }
  }

  const confirmChangeCompany = (confirm: boolean, body: StageRequest) => {
    if (confirm) {
      deleteBindInnermap({ id: projectId, roadmapId, stageId })
      editStage({ id: projectId, roadmapId, stageId, body })
    }
  }

  // mouse action
  const onCloseDrawer = (dirty: boolean) => {
    if (dirty) {
      setConfirmTrigger('closeDrawer')
      openConfirm()
    } else onInternalAgreementDrawerClose(true)
  }

  const onEditBoundInnermap = () => {
    if (person?.company && innermap) {
      dispatch(setStageCompany(person.company))
      dispatch(setCurrentSchema(innermap))
      dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
      dispatch(setOpenedDrawer({ openedDrawer: 'internalAgreement' }))
    }
  }


  const onDeleteBoundInnermap = () => {
    setConfirmTrigger('deleteBoundInnermap')
    openConfirm()
  }

  const onDeleteStage = () => {
    setConfirmTrigger('delete')
    openConfirm()
  }

  const onCancelClick = () => {
    if (dirty) {
      setConfirmTrigger('return')
      openConfirm()
    } else {
      returnToAgreement()
    }
  }

  const onChangeCompany = (body: StageRequest) => {
    setConfirmTrigger('changeCompany')
    openConfirm(body)
  }

  const [confirmTrigger, setConfirmTrigger] = useState<AgreementFormConfirmTrigger>('return')
  const dataForConfirmDialog = useMemo((): Record<AgreementFormConfirmTrigger, UseExitConfirmProps> => {
    return {
      closeDrawer: { handleConfirm: confirmCloseDrawer },
      return: { handleConfirm: confirmReturn },
      delete: {
        handleConfirm: confirmDelete,
        title: 'Удалить этап согласования?',
        body: 'Этап согласования будет полностью удален',
      },
      deleteBoundInnermap: {
        handleConfirm: confirmDeleteBoundInnermap,
        title: 'Открепить схему от этапа согласования?',
        body: 'Схема будет откреплена от этапа внешней процедуры согласования. Уже запущенные внутренние процедуры согласования документации по данной схеме не будут остановлены.',
      },
      changeCompany: {
        handleConfirm: confirmChangeCompany,
        title: 'Изменить компанию?',
        body: 'При изменении компании, ответственной за этап, текущая внутренняя схема будет откреплена от этапа.',
      },
    }

  }, [])

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmTrigger])

  useMutationHandlers(
    deleteStageResponse,
    () => {
      returnToAgreement()
    }
  )

  const onInternalAgreementDrawerClose = (confirm: boolean) => {
    if (confirm) {
      dispatch(setCurrentSchema({} as Innermap))
      dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
      dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
      dispatch(setTableFormValues({ tableFormValues: {} as TableFormValues }))
      dispatch(setInitialSchemaCompanyFromMenu({ initialSchemaCompanyFromMenu: '' }))
      dispatch(setStageCompany(''))
      dispatch(onDrawerClose({ dirty: false }))
    }
  }

  return (
    <FormikProvider value={formik}>
      <ProjectAgreementWrapper as={Form}>
        <Stack direction='row' justifyContent='space-between' textAlign='start' mb={2.5} spacing={1}>
          <Stack direction='row' spacing={2.5} alignItems='center'>
            <ArrowBackButton onClick={onCancelClick} >
              <ArrowBackIcon color='primary' />
            </ArrowBackButton>
            <Box pl={2}>
              <ProjectAgreementFormTitle variant='h1'>
                {stageId ? currentStage?.title || '' : 'Новый этап согласования'}
              </ProjectAgreementFormTitle>
            </Box>
          </Stack>
          {!isLocked && (isAdmin || isResponsibleRoadmap) &&
            <Stack direction='row' alignItems='center' spacing={1.5} height={36}>
              <Button type='submit' size='small' sx={{ height: 'inherit' }} color='success'
                disabled={!dirty || !isValid || editStageResponse.isLoading || createStageResponse.isLoading}>
                Сохранить
              </Button>
              {!!stageId && (
                <ProjectAgreementFormDeleteButton onClick={onDeleteStage}>
                  <DeleteIcon />
                </ProjectAgreementFormDeleteButton>
              )}
            </Stack>
          }
        </Stack>

        <Stack direction='row' justifyContent='space-between' alignItems='start' flex={1} spacing='25px'>
          <ProjectAgreementFormContent canEdit={!isLocked && (isAdmin || isResponsibleRoadmap)} />
          {isInnermapFetching || isStageFetching ?
            <Box m='auto !important'>
              <Progress />
            </Box> :
            innermapId ? (
              <Stack spacing={3.5} flex={1}>
                <Stack direction='row' spacing={2} alignItems='center'>
                  <Typography variant='h2' fontWeight={500}>Внутренняя процедура согласования</Typography>
                  <Tooltip title={<>Ответственный за этап может привязать одну из внутренних схем согласования к этапу внешней схемы. Внутренняя процедура согласования тома запустится автоматически при переходе к этапу внешнего согласования. </>}>
                    <InfoOutlinedIcon fontSize='medium' sx={{ color: '#D1D8FA' }} />
                  </Tooltip>

                </Stack>
                <SchemaContainer flex={1}>
                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body2' fontSize={18}>{innermap?.title}</Typography>
                    {(isAdmin || isResponsibleRoadmap || isResponsibleStage) &&
                      <Stack direction='row' spacing={1.5} height={36}>
                        {/*<Button onClick={onEditBoundInnermap} size='small' sx={{ height: 'inherit' }} color='success' disabled={dirty}>
                          Изменить схему
                        </Button> */}
                        <ProjectAgreementFormDeleteButton onClick={onDeleteBoundInnermap} color='error' disabled={dirty}>
                          <DeleteIcon color='error' />
                        </ProjectAgreementFormDeleteButton>
                      </Stack>}
                  </Stack>

                  <BoundInternalSchema
                    projectId={projectId}
                    innermapId={innermapId}
                  />
                </SchemaContainer>
              </Stack>
            ) : (
              <Stack spacing={3}>
                  <StyledButton variant='contained' onClick={onAddSchemaClick}
                    disabled={dirty || !isValid}>
                    Добавить внутреннюю схему согласования
                  </StyledButton>
                <ProjectAgreementInstruction />
              </Stack>
            )}
        </Stack>
      </ProjectAgreementWrapper>
      <InternalAgreementDrawer
        tomId={null}
        open={openedDrawer === 'internalAgreement'}
        closeDrawer={onCloseDrawer}
        type={determineTomType(selectedProjectPhase)}
      />
      <ConfirmDialog />
    </FormikProvider>
  )
}
