import styled from 'styled-components'
import { Box, Button, Stack } from '@mui/material'

export const ProjectFullExportTomItemWrapper = styled(Stack)`
  width: 100%;
  margin-left: -11px;
`

export const ProjectFullExportTomItemButton = styled(Button)`
  justify-content: start;

  & .MuiTypography-root {
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
    color: ${props => props.theme.palette.text.dark};
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const ProjectFullExportTomItemPhase = styled(Box)`
  height: 20px;
  margin-right: 8px;
  padding: 0 3px;
  background-color: ${props => props.theme.palette.primary.light};
  border-radius: 4px;

  & .MuiTypography-root {
    margin-top: 3px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${props => props.theme.palette.text.light};
  }
`