import { Stack } from "@mui/material";
import styled from "styled-components";
import { StyledFootnote, StyledHeader, StyledListItemText } from "../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementTitle";

export const TitleWrapper = styled(Stack)`
  padding: 56px 57px 60px 56px;
  height: 100%;
  border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 16px;
`

export const StyledStack = styled(Stack)`
  padding: 0 0 20px 20px;
  border-bottom: 1px solid #D1D8FA;
`

export const TitleDrawerHeader = styled(StyledHeader)`
border-bottom: none;
`

export const TitleDrawerListItemText = styled(StyledListItemText)`
`

export const TitleDrawerFootnote = styled(StyledFootnote)`
`