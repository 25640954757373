import { VariableSizeList as List, ListChildComponentProps } from 'react-window';
import { IconButton } from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CountText, ElementWrapper, NodeWrapper, ScrollableList, StyledAccordion, StyledAccordionSummary, TitleText } from "./ModelStructureAccordion.styles"
import { ModelStructureAccordionProps, TreeNode } from "./ModelStructureAccordion.types";
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useTypedSelector } from "store/store";
import { setIsolatedElements, setIsShowAll, setSelectedFromTreeElements } from "store/slices/tim/tim";
import { isolatedElementsSelector, isShowAllSelector, selectedFromModelElementsSelector, selectedModelsSelector } from "store/slices/tim/selectors/tim.selectors";
import { renderManager, sceneManager } from "../TanglViewer/TanglManagers";
import { SelectedModels } from "store/slices/tim/tim.types";
import Tooltip from "components/Tooltip";

const ROW_HEIGHT = 48
const INNER_HEIGHT = window.innerHeight - 152 - 20 // высота хедера/отступ снизу

const extractElNums = (node: TreeNode): number[] => {
  const elNums: number[] = []

  const extractFromChildNodes = (childNodes: TreeNode[]) => {
    childNodes.forEach(child => {
      elNums.push(...extractElNums(child))
    })
  }

  if (node.children && node.children.length > 0) {
    extractFromChildNodes(node.children)
  } else if (node.level === 4 && node.elNum !== undefined) {
    elNums.push(node.elNum)
  }

  return elNums
}

const getModelName = (node: TreeNode, models: SelectedModels[]): string => {
  if (node.level === 1) {
    return models.find((model) => node.name === model.tanglID)?.name || ''
  } else return node.name || ''
}

const flattenTree = (nodes: TreeNode[]): TreeNode[] => {
  let flatList: TreeNode[] = []

  const flattenNode = (node: TreeNode): TreeNode[] => {
    let flatNodeList: TreeNode[] = [node]

    if (node.expanded && node.children) {
      for (let child of node.children) {
        flatNodeList = flatNodeList.concat(flattenNode(child))
      }
    }

    return flatNodeList
  }

  for (let node of nodes) {
    flatList = flatList.concat(flattenNode(node))
  }

  return flatList
}

export const ModelStructureAccordion: React.FC<ModelStructureAccordionProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch()
  const selectedElements = useTypedSelector(selectedFromModelElementsSelector)
  const selectedModels = useTypedSelector(selectedModelsSelector)
  const isolatedElements = useTypedSelector(isolatedElementsSelector)
  const isShowAll = useTypedSelector(isShowAllSelector)
  const [data, setData] = useState<TreeNode[]>(flattenTree(children))
  const [selectedNodeKey, setSelectedNodeKey] = useState<string>('')
  const listRef = useRef<List>(null)
  const shouldScroll = useRef(false)

  const hasScroll = useMemo((): boolean => {
    const maxVisibleRow = INNER_HEIGHT / ROW_HEIGHT
    return data.length > maxVisibleRow ? true : false
  }, [data.length])

  const changeVisibility = (e: MouseEvent<HTMLButtonElement>, node: TreeNode) => {
    e.stopPropagation()
    const newVisibility = !node.isHidden
    node.isHidden = newVisibility

    const changeChildrenVisibility = (parentNode: TreeNode) => {
      if (parentNode.children && parentNode.children.length > 0) {
        parentNode.children.forEach(child => {
          child.isHidden = newVisibility
          changeChildrenVisibility(child)
        })
      }
    }

    const changeParentVisibility = (childNode: TreeNode) => {
      if (childNode.isHidden === newVisibility) {
        const parentNode = findParent(children, childNode)
        if (parentNode && parentNode.children!.length === 1) {
          parentNode.isHidden = newVisibility
          changeParentVisibility(parentNode)
        }
      }
    }

    changeChildrenVisibility(node)
    changeParentVisibility(node)

    const affectedElNums = extractElNums(node)

    if (newVisibility) {
      sceneManager.tools.hideElements(affectedElNums)
    } else {
      sceneManager.tools.showElements(affectedElNums)
    }

    renderManager.requestUpdate()
    setData(flattenTree(children))
    listRef.current?.resetAfterIndex(0)
  }

  const findParent = (rootNodes: TreeNode[], targetNode: TreeNode): TreeNode | null => {
    for (let node of rootNodes) {
      if (node.children && node.children.includes(targetNode)) {
        return node
      } else if (node.children) {
        const found = findParent(node.children, targetNode)
        if (found) {
          return found
        }
      }
    }
    return null
  }

  const updateVisibilityBasedOnIsolation = () => {
    if (isolatedElements.length === 0) return

    const updateVisibilityRecursively = (node: TreeNode, shouldHide: boolean) => {
      node.isHidden = shouldHide && !isolatedElements.includes(node.elNum!)

      if (node.children) {
        const hasVisibleChild = node.children.some(child => !isolatedElements.includes(child.elNum!))

        if (node.children.length === 1 && isolatedElements.includes(node.children[0].elNum!)) {
          node.isHidden = false
        } else if (!hasVisibleChild) {
          node.isHidden = shouldHide
        }

        node.children.forEach(child => {
          updateVisibilityRecursively(child, !!node.isHidden)
        })

        const allChildrenVisible = node.children.every(child => !child.isHidden)
        if (allChildrenVisible) {
          node.isHidden = false
        }

        if (node.children && node.children.some(child => !child.isHidden)) {
          node.isHidden = false;
        }
      }
    }

    data.forEach(node => {
      updateVisibilityRecursively(node, !isolatedElements.includes(node.elNum!))
    })

    setData(flattenTree(children))
  }

  const showAllElements = () => {
    const resetVisibility = (node: TreeNode) => {
      node.isHidden = false

      if (node.children) {
        node.children.forEach(child => {
          resetVisibility(child)
        })
      }
    }

    data.forEach(node => {
      resetVisibility(node)
    })

    setData(flattenTree(children))
  }

  useEffect(() => {
    if (isolatedElements.length > 0) {
      updateVisibilityBasedOnIsolation()
      dispatch(setIsolatedElements([]))
    }
  }, [isolatedElements]);

  useEffect(() => {
    if (isShowAll) {
      showAllElements()
      dispatch(setIsShowAll(false))
    }
  }, [isShowAll])

  const onSelectClick = (e: MouseEvent<HTMLDivElement>, node: TreeNode) => {
    e.stopPropagation()
    setSelectedNodeKey(node.key)
    const selectedElNums = extractElNums(node)
    dispatch(setSelectedFromTreeElements(selectedElNums))
  }

  useEffect(() => {
    const checkAndExpand = (node: TreeNode, selectedElNum: number): boolean => {
      if (node.level === 4 && node.elNum === selectedElNum) {
        setSelectedNodeKey(node.key)
        return true
      }

      if (node.children) {
        for (let child of node.children) {
          const found = checkAndExpand(child, selectedElNum)

          if (found) {
            node.expanded = true
            return true
          }
        }
      }

      return false
    }

    if (selectedElements.length > 0) {
      const selectedElNum = selectedElements[0]
      let elementFound = false

      for (let rootNode of children) {
        const foundInCurrentTree = checkAndExpand(rootNode, selectedElNum)

        if (foundInCurrentTree) {
          elementFound = true
          break
        }
      }

      if (elementFound) {
        shouldScroll.current = true
        dispatch(setSelectedFromTreeElements([]))
        setData(flattenTree(children))
      }
    }
  }, [selectedElements, children])

  useEffect(() => {
    if (shouldScroll.current && selectedElements.length > 0) {
      const selectedElNum = selectedElements[0]

      const indexToScroll = data.findIndex(
        (node) => node.level === 4 && node.elNum === selectedElNum
      )

      if (indexToScroll !== -1) {
        setTimeout(() => {
          listRef.current?.scrollToItem(indexToScroll, "center")
          shouldScroll.current = false
        }, 0)
      }
    }
  }, [data])

  const toggleExpand = (index: number) => {
    const item = data[index]
    item.expanded = !item.expanded
    setData(flattenTree(children))
    listRef.current?.resetAfterIndex(0)
  }

  const renderItem = ({ index, style }: ListChildComponentProps) => {
    const item: TreeNode = data[index]
    const labelName = getModelName(item, selectedModels)
    if (item.level !== 4) {
      return (
        <StyledAccordion
          expanded={item.expanded}
          onChange={() => toggleExpand(index)}
          disableGutters
          TransitionProps={{ unmountOnExit: true }}
          style={{ ...style }}
          key={item.key}
        >
          <StyledAccordionSummary
            expandIcon={<ArrowDropDownIcon fontSize="medium" />}
            aria-controls={`${item.key}-content`}
            isModelText={item.level === 1}
            isSelected={item.key === selectedNodeKey}
            id={`${item.key}-header`}
            sx={{ paddingLeft: 2 * item.level }}
          >
            <NodeWrapper direction="row" flex={1} level={item.level} spacing={1}
              onClick={(e) => onSelectClick(e, item)} selected={item.key === selectedNodeKey}>
              <Tooltip variant="light" placement="right" title={labelName.length > 35 ? labelName : ''}>
                <TitleText isModelText={item.level === 1} isHidden={item.isHidden}>{labelName}</TitleText>
              </Tooltip>
              <CountText>({item.count})</CountText>
              <IconButton onClick={e => changeVisibility(e, item)} size="small" color={!item.isHidden ? 'primary' : 'default'}>
                {!item.isHidden ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
              </IconButton>
            </NodeWrapper>
          </StyledAccordionSummary>
        </StyledAccordion>
      )
    } else {
      return (
        <ElementWrapper direction='row' spacing={1} style={{ ...style }} key={item.key}
          onClick={(e) => onSelectClick(e, item)} selected={item.key === selectedNodeKey}>
          <Tooltip variant="light" placement="right" title={labelName.length > 35 ? labelName : ''}>
            <TitleText isModelText={false} isHidden={item.isHidden}>{labelName}</TitleText>
          </Tooltip>
          <IconButton onClick={e => changeVisibility(e, item)} size="small" color={!item.isHidden ? 'primary' : 'default'}>
            {!item.isHidden ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
          </IconButton>
        </ElementWrapper>
      )
    }
  }

  return (
    <ScrollableList
      height={INNER_HEIGHT}
      width={'100%'}
      itemCount={data.length}
      itemSize={() => ROW_HEIGHT}
      ref={listRef}
      hasScroll={hasScroll}
    >
      {renderItem}
    </ScrollableList>
  )
}