import React, { useCallback, useEffect, useState } from 'react'
import { RdDrawerProps } from './RdDrawer.types'
import { Box, Drawer, Stack, Typography } from '@mui/material'
import { RdDrawerTopBar, RdDrawerWrapper } from './styles'
import { theme } from '../../../../styles/theme'
import Divider from '../../../../components/Divider'
import RdDrawerForm from '../../components/RdDrawerForm'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { RdBaseResponse, useDeleteRdMutation, useGetRdByIdQuery } from '../../../../api/rdPhase'
import DeleteConfirm from '../../../../components/DeleteConfirm'
import Button from '../../../../components/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import Progress from '../../../../components/Progress'
import { useSnackbar } from 'notistack'

const RdDrawer: React.FC<RdDrawerProps> = ({ rdId, onClose, open }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  const { data: RdData, isLoading, isFetching } = useGetRdByIdQuery({
    id: rdId!
  }, {
    skip: !rdId,
  })

  const { data: currentRd } = RdData || {}

  const onDeleteButtonClick = useCallback(() => {
    setIsDeleteClicked(true)
  }, [])

  const [deleteRd, deleteRdResponse] = useDeleteRdMutation()

  const onDeleteSubmit = useCallback(() => {
    if (rdId) {
      deleteRd({ id: rdId })
    }
  }, [currentRd])

  const onDeleteCancel = useCallback(() => {
    setIsDeleteClicked(false)
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    onClose(dirty, immediately)
  }, [])

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  useMutationHandlers(
    deleteRdResponse,
    (data: RdBaseResponse) => {
      if (data.success) {
        onClose(false, true)
      } else {
        enqueueSnackbar(`Не удалось удалить раздел РД. ${data.description}`, { variant: 'error' })
        setIsDeleteClicked(false)
      }
    }
  )

  useEffect(() => {
    if (!open) {
      setIsDeleteClicked(false)
    }
  }, [open])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => onDrawerClose(dirty)}
    >
      <RdDrawerWrapper>
        <RdDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {rdId ? 'Редактирование раздела РД' : 'Добавление раздела РД'}
          </Typography>
        </RdDrawerTopBar>

        <Divider />

        {rdId
          ? !(isLoading || isFetching)
            ? (
              <>
                <RdDrawerForm
                  onClose={onDrawerClose}
                  onFormChange={onFormChange}
                  rd={currentRd}
                />

                <Stack justifyContent='space-between' alignItems='center' flex={1} sx={{ p: 2.5, width: '100%' }}>
                  <Box style={{ width: '100%', paddingTop: '50px' }}>
                    {isDeleteClicked
                      ? (
                        <DeleteConfirm
                          text='
                            Вы действительно хотите удалить раздел рабочей документации?
                            Это действие нельзя отменить.
                          '
                          onDelete={onDeleteSubmit}
                          onCancel={onDeleteCancel}
                        />
                      )
                      : null
                    }
                  </Box>
                  <Box style={{ width: 'fit-content' }}>
                    <Button
                      onClick={onDeleteButtonClick}
                      startIcon={<DeleteIcon />}
                      variant='text'
                      color='error'
                    >
                      удалить раздел РД
                    </Button>
                  </Box>
                </Stack>
              </>
            )
            : <Progress />

          : (
            <RdDrawerForm
              onClose={onDrawerClose}
              onFormChange={onFormChange}
            />
          )
        }
      </RdDrawerWrapper>
    </Drawer>
  )
}

export default RdDrawer