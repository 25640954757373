import { IconButton } from "@mui/material";
import styled from "styled-components";

export const TanglIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;

  :hover {
    background-color: #d7f6ff;
  }

  svg {
    color: #000;
  }
  
`