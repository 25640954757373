import React, { FC } from 'react'
import { FullExportDrawerSelectedListWrapper } from './FullExportDrawerSelectedList.styles'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import { useFormikContext } from 'formik'
import { ProjectFullExportFormData } from '../ProjectFullExportForm'
import { ProjectFullExportTomItem } from '../ProjectFullExportTomItem'

export const FullExportDrawerSelectedList: FC = () => {
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFullExportFormData>()
  const { phaseList, selectedToms } = formValues

  // Rd
  const onClearSelectedToms = () => {
    setFieldValue('selectedToms', [])

    selectedToms.forEach(({ phaseIndex, objectIndex, groupIndex, tomIndex }) => {
      if (objectIndex) {
        setFieldValue(`phaseList[${phaseIndex}].objects[${objectIndex}].groups[${groupIndex}].toms[${tomIndex}].selected`, false)
        setFieldValue(`phaseList[${phaseIndex}].objects[${objectIndex}].groups[${groupIndex}].selected`, false)
        setFieldValue(`phaseList[${phaseIndex}].objects[${objectIndex}].selected`, false)
      } else {
        setFieldValue(`phaseList[${phaseIndex}].groups[${groupIndex}].toms[${tomIndex}].selected`, false)
        setFieldValue(`phaseList[${phaseIndex}].groups[${groupIndex}].selected`, false)
      }

      setFieldValue(`phaseList[${phaseIndex}].selected`, false)
    })
  }

  return (
    <FullExportDrawerSelectedListWrapper>
      <Stack direction='row' justifyContent='space-between' alignItems='center' pr={2.5} pb={2} spacing={1}>
        <Box>
          <Typography
            variant='subtitle2'
            fontSize={16}
            lineHeight='24px'
            letterSpacing='0.15px'
          >
            Выбранные документы
          </Typography>
        </Box>

        <Button
          onClick={() => onClearSelectedToms()}
          variant='text'
          color='error'
          sx={{ height: 30, p: '4px 8px' }}
        >
          <Typography
            variant='tooltip'
            color='inherit'
            fontSize={12}
            lineHeight='13.2px'
            textTransform='lowercase'
          >
            очистить список
          </Typography>

        </Button>
      </Stack>

      <ScrollableContainer>
        {selectedToms.map(({ tom, phase, phaseIndex, objectIndex, groupIndex, tomIndex }) => (
          // @ts-ignore
          <ProjectFullExportTomItem
            phase={phase}
            phaseIndex={phaseIndex}
            tom={tom}
            objectIndex={phase === 'РД' ? objectIndex : undefined}
            groupIndex={groupIndex}
            tomIndex={tomIndex}
            key={`${phase}-tom-${tom.id}`}
          />
        ))}
      </ScrollableContainer>
    </FullExportDrawerSelectedListWrapper>
  )
}
