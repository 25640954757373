import styled from 'styled-components'
import { PocketsDrawerContent } from '../../../Pockets/components/PocketsNavbar/PocketsNavbar.styles'
import { Stack, Typography } from '@mui/material'

export const PocketViewNavbarContent = styled(PocketsDrawerContent)`
  padding-right: 0;
`

interface PocketStageWrapperProps {
  selected: boolean
}

export const PocketStageWrapper = styled(Stack)<PocketStageWrapperProps>`
  padding: 10px 16px;
  border: ${props => `1px solid ${props.selected ? props.theme.palette.primary.light : props.theme.palette.bg.gray}`};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.palette.primary.light};
  }
`

export const PocketStageStatusTitle = styled(Typography)`
  color: ${props => props.theme.palette.info.main};
  font-size: 14px;
`
export const PocketStageStatusSubtitle = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  margin-left: 24px;
  margin-top: 8px;
  color: ${props => props.theme.palette.secondary.gray};
`
export const AccordionButtonText = styled(Typography)`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`