import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from "styled-components";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  border-radius: 6px;
`
export const StyledToggleButton = styled(ToggleButton)`
  padding: 7px;
  background-color: ${props => props.theme.palette.bg.gray};
  border: 1px solid #0044B4;

  &:hover {
    background-color: ${props => props.theme.palette.bg.gray};
  }

  &:not(:last-child) {
    box-shadow: ${props => `inset -1px 0 0 ${props.theme.palette.primary.main}`};
  }

  &,
  & .MuiTypography-root {
    color: ${props => props.theme.palette.primary.main};
  }

  &.Mui-selected {
    background-color: ${props => props.theme.palette.primary.main};

    &,
    & .MuiTypography-root {
      color: ${props => props.theme.palette.bg.white};
    }

    &:hover {
      background-color: ${props => props.theme.palette.primary.main};
    }
  }
`