import React, { MouseEvent, useState } from 'react'
import { CardStatisticsProps, PhaseCardData, StatisticModalData, StatisticModalInnerData } from './CardStatistics.types'
import { CardStatisticsWrapper, PhaseChangeButton } from './CardStatistics.styles'
import { DefaultStatistics } from './variants/DefaultStatistics'
import { IrdStatistics } from './variants/IrdStatistics'
import { IiStatistics } from './variants/IiStatistics'
import { Stack } from '@mui/material'
import { StatusItemModal } from './components/StatusItemModal'
import { agreementPhaseByProjectPhase } from '../../../../api/projectAgreement/types'

const CardStatistics: React.FC<CardStatisticsProps> = ({
  data,
  projectId,
  phase,
  onPhaseChange,
  expertise,
}) => {
  const [statisticModalData, setStatisticModalData] = useState<StatisticModalData | null>(null)

  const onStatisticHeaderClick = (e: MouseEvent<HTMLDivElement>, data: StatisticModalInnerData) => {
    e.stopPropagation()

    setStatisticModalData({
      anchorEl: e.currentTarget,
      projectId,
      phase: agreementPhaseByProjectPhase[phase],
      ...data,
    })
  }

  const onStatisticModalClose = () => {
    setStatisticModalData(null)
  }

  const buttonsData: PhaseCardData[] = [
    { title: 'ИРД', phase: 'Сбор исходных данных' },
    { title: 'ИИ', phase: 'Инженерные изыскания' },
    { title: 'ПД', phase: 'Проектная документация' },
    { title: 'РД', phase: 'Рабочая документация' },
  ]

  return (
    <CardStatisticsWrapper>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1.5}>
        {buttonsData.map(({ title, phase: buttonPhase }) => (
          <PhaseChangeButton
            onClick={onPhaseChange(buttonPhase)}
            checked={phase === buttonPhase}
            color='secondary'
            variant='outlined'
            fullWidth
          >
            {title}
          </PhaseChangeButton>
        ))}
      </Stack>

      {(phase === 'Рабочая документация' || phase === 'Проектная документация') && (
        <DefaultStatistics
          data={data}
          onStatisticHeaderClick={onStatisticHeaderClick}
          withExpertise={phase === 'Проектная документация'}
          expertise={expertise}
        />
      )}

      {phase === 'Инженерные изыскания' && (
        <IiStatistics data={data} onStatisticHeaderClick={onStatisticHeaderClick} />
      )}

      {phase === 'Сбор исходных данных' && (
        <IrdStatistics data={data} />
      )}

      <StatusItemModal statisticModalData={statisticModalData} onClose={onStatisticModalClose} />
    </CardStatisticsWrapper>
  )
}

export default CardStatistics