import React, { FC } from 'react'
import {
  FormPocketDrawerSelectedListAutocomplete,
  FormPocketDrawerSelectedListTextField,
  FormPocketDrawerSelectedListWrapper,
  FormPocketSelectedListAutocompleteItem
} from './FormPocketDrawerSelectedList.styles'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import { useFormikContext } from 'formik'
import { FormPocketDrawerFormData } from '../FormPocketDrawerForm'
import { FormPocketDrawerTomItem } from '../FormPocketDrawerTomItem'

import { FormPocketDrawerSelectedListProps } from './FormPocketDrawerSelectedList.types'
import { Roadmap } from '../../../../../../api/projectAgreement/types'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { BorderedVerifiedIcon } from '../../../../../../assets/icons/BorderedVerifiedIcon'
import {
  AgreementNavbarPhaseItemNumber,
  AgreementNavbarPhaseItemTitle,
  AgreementNavbarPhaseItemPerson
} from '../../../../../../layouts/AgreementLayout/AgreementNavbarContent/components/AgreementNavbarPhaseItem/AgreementNavbarPhaseItem.styles'

export const FormPocketDrawerSelectedList: FC<FormPocketDrawerSelectedListProps> = ({ roadmaps }) => {
  const { values: formValues, setFieldValue, errors, touched } = useFormikContext<FormPocketDrawerFormData>()
  const { selectedToms } = formValues

  const isTitleError = !!(touched.title && errors.title)

  // Rd
  const onClearSelectedToms = () => {
    setFieldValue('selectedToms', [])

    selectedToms.forEach(({ objectIndex, groupIndex, tomIndex }) => {
      if (objectIndex) {
        setFieldValue(`objectList[${objectIndex}].groups[${groupIndex}].toms[${tomIndex}].selected`, false)
        setFieldValue(`objectList[${objectIndex}].groups[${groupIndex}].selected`, false)
        setFieldValue(`objectList[${objectIndex}].selected`, false)
      } else {
        setFieldValue(`groupList[${groupIndex}].toms[${tomIndex}].selected`, false)
        setFieldValue(`groupList[${groupIndex}].selected`, false)
      }
    })
  }

  return (
    <FormPocketDrawerSelectedListWrapper>
      <Stack direction='row' alignItems='center' pr={2.5} mb={2} spacing={1.5}>
        <FormPocketDrawerSelectedListTextField
          version='project'
          name='title'
          placeholder='Накладная №1'
          error={isTitleError}
        />

        <FormPocketDrawerSelectedListAutocomplete
          fieldName='roadmapId'
          placeholder='Процедура согласования'
          data={roadmaps?.map(roadmap => ({
            value: roadmap.id,
            label: roadmap.title,
            key: roadmap.id,
            fullData: roadmap
          })) || []}
          customRenderOption={(props, option: any) => {
            const roadmap = option.fullData as Roadmap
            return (
              <FormPocketSelectedListAutocompleteItem
                {...props}
                disabled={roadmap.isBroken}
                isBroken={roadmap.isBroken}
                key={option.key}
              >
                <Stack direction='row' alignItems='center' spacing={1.5}>
                  <AgreementNavbarPhaseItemNumber className='number'>
                    {roadmap.isMain && (
                      <BorderedVerifiedIcon className='verifiedIcon' />
                    )}

                    <Typography>{roadmap.number}</Typography>
                  </AgreementNavbarPhaseItemNumber>

                  <Stack>
                    <AgreementNavbarPhaseItemTitle variant='body2'>
                      {roadmap.title}
                    </AgreementNavbarPhaseItemTitle>
                    <AgreementNavbarPhaseItemPerson variant='body2'>
                      {roadmap.person.name}
                    </AgreementNavbarPhaseItemPerson>
                  </Stack>
                </Stack>

                {roadmap.isBroken && (
                  <WarningAmberIcon fontSize='medium' color='warning' />
                )}
              </FormPocketSelectedListAutocompleteItem>
            )
          }}
          componentsProps={{
            popper: { placement: 'bottom-end', style: { width: 290 } }
          }}
        />
      </Stack>

      <Stack direction='row' justifyContent='space-between' alignItems='center' pr={2.5} pb={1} spacing={1}>
        <Box>
          <Typography
            variant='subtitle2'
            fontSize={16}
            lineHeight='24px'
            letterSpacing='0.15px'
          >
            Выбранные документы
          </Typography>
        </Box>

        <Button
          onClick={() => onClearSelectedToms()}
          variant='text'
          color='error'
          sx={{ height: 30, p: '4px 8px' }}
        >
          <Typography
            variant='tooltip'
            color='inherit'
            fontSize={12}
            lineHeight='13.2px'
            textTransform='lowercase'
          >
            очистить список
          </Typography>

        </Button>
      </Stack>

      <ScrollableContainer>
        {selectedToms.map(({ tom, phase, objectIndex, groupIndex, tomIndex }) => (
          // @ts-ignore
          <FormPocketDrawerTomItem
            phase={phase}
            tom={tom}
            objectIndex={phase === 'РД' ? objectIndex : undefined}
            groupIndex={groupIndex}
            tomIndex={tomIndex}
            key={`${phase}-tom-${tom.id}`}
          />
        ))}
      </ScrollableContainer>
    </FormPocketDrawerSelectedListWrapper>
  )
}
