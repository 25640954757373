import styled from 'styled-components'
import { alpha, Box, Stack } from '@mui/material'
import { LegendColors } from '../../../../../../types/global'

export const IrdLegendItemWrapper = styled(Stack)`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 2px 4px;
  border: ${props => `1px solid ${alpha(props.theme.palette.primary.main, 0.08)}`};
  border-radius: 20px;
`

interface IrdLegendItemCircleProps {
  bgColor: LegendColors;
}

export const IrdLegendItemCircle = styled(Box)<IrdLegendItemCircleProps>`
  width: 12px;
  height: 12px;
  background-color: ${props => props.theme.palette.legends[props.bgColor]};
  border-radius: 50%;
`
