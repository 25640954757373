import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MenuState } from './menu.types'
import { SetSelectedMenuViewPayload } from './menu.payloads.types'


let initialState: MenuState = {
  selectedMenuView: 'info',
}

const menuSlice = createSlice({
  name: 'menuState',
  initialState,
  reducers: {
    setSelectedMenuView: (state, { payload }: PayloadAction<SetSelectedMenuViewPayload>) => {
      const { selectedMenuView } = payload

      state.selectedMenuView = selectedMenuView
      localStorage.setItem('selectedMenuView', selectedMenuView)
    },
  },
})

export const { reducer: menuReducer } = menuSlice
export const { setSelectedMenuView } = menuSlice.actions
