import { Avatar, Stack, Typography } from '@mui/material'
import { CardTitle, DateText, StyledModelCard, StyledData, ElipsisText, StyledLabel } from './ModelCard.styles'
import ColoredTitle from '../../../../components/ColoredTitle'
import EventNoteIcon from '@mui/icons-material/EventNote'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { ModelCardProps } from './ModelCard.types'
import Divider from '../../../../components/Divider'
import { timStatusesColors } from '../../../../types/models'
import { compareAsc, startOfDay } from 'date-fns'
import { formatToDate } from '../../../../utils/formatDate'
import Tooltip from '../../../../components/Tooltip'
import VersionIcon from '../../../../assets/icons/VersionIcon'
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff'
import { TomColoredInfo } from '../../../Docs/components/TomCard/TomCard.styles'
import { ModelExtension } from '../ModelExtension'

export const ModelCard = ({ data, onUpdateData }: ModelCardProps) => {
  const { id, title, status, createdAt, plannedDate, actualDate, authorId } = data.tim
  const { version, extension, tanglBindStatus } = data.versions[0] || {}
  const durationDateExpired =
    !!plannedDate && !actualDate && compareAsc(formatToDate(plannedDate)!, startOfDay(new Date())) === -1
  const timStatus = !status ? 'Не установлен' : status

  return (
    <StyledModelCard onClick={() => onUpdateData(id)}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
        <ColoredTitle body={timStatus} color={timStatusesColors[timStatus]} />
        {data.versions.length ? (
          <Stack direction='row' spacing={1.25}>
            <Tooltip title={`Текущая версия модели: ${version}`}>
              <TomColoredInfo
                direction='row'
                alignItems='center'
                spacing={1}
                customColor='secondary'
                alignSelf='start'
                height='24px'
              >
                <VersionIcon fontSize='small' />
                <Typography variant='tooltip' style={{ marginTop: 2 }}>
                  {version}
                </Typography>
              </TomColoredInfo>
            </Tooltip>
            <ModelExtension extension={extension} tanglBindStatus={tanglBindStatus} />
          </Stack>
        ) : (
          <Tooltip title='Нет загруженных файлов'>
            <TomColoredInfo direction='row' alignItems='center' spacing={1} customColor='error' alignSelf='start'>
              <FileDownloadOffIcon fontSize='small' />
              <Typography variant='tooltip' style={{ marginTop: 2 }}>
                0
              </Typography>
            </TomColoredInfo>
          </Tooltip>
        )}
      </Stack>
      <CardTitle>{title}</CardTitle>
      <Stack spacing={1}>
        <Typography variant='body2'>Даты согласования:</Typography>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' alignItems='center' spacing={1}>
            <EventNoteIcon fontSize='small' color='secondary' />
            <Typography variant='body2'>Плановая:</Typography>
          </Stack>
          <StyledData expired={durationDateExpired} variant='subtitle2'>
            {plannedDate || '—'}
          </StyledData>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' alignItems='center' spacing={1}>
            <EventAvailableIcon fontSize='small' color='secondary' />
            <Typography variant='body2'>Фактическая:</Typography>
          </Stack>
          <StyledData variant='subtitle2'>{actualDate || '—'}</StyledData>
        </Stack>
      </Stack>
      <Typography variant='h3' fontSize={12} mt={1.75}>
        Автор
      </Typography>
      <Divider />
      <Stack direction='row' alignItems='center' spacing={1.5} mt={1}>
        <Avatar src={authorId.avatar} sx={{ height: 32, width: 32 }} />
        <Stack width='calc(100% - 40px)'>
          <ElipsisText>{authorId.company || '—'}</ElipsisText>
          <ElipsisText>{authorId.name}</ElipsisText>
        </Stack>
      </Stack>
      <DateText>
        <Typography>{`Дата создания: ${createdAt || '—'}`}</Typography>
      </DateText>
    </StyledModelCard>
  )
}
