import { FC } from 'react'
import {
  ProjectAgreementSchemeEmptyWrapper,
  ProjectAgreementSchemeText,
  ProjectAgreementSchemeTitleText
} from './ProjectAgreementSchemeEmpty.styles'
import EmptyPreview from '../../../../../assets/empty-agreement-scheme.png'
import { Box, Stack } from '@mui/material'

export const ProjectAgreementSchemeEmpty: FC = () => {
  return (
    <ProjectAgreementSchemeEmptyWrapper>
      <Stack width={852} textAlign='left' spacing={0.5}>
        <ProjectAgreementSchemeTitleText variant='subtitle1'>
          Создание схемы
        </ProjectAgreementSchemeTitleText>
        <ProjectAgreementSchemeText variant='body2'>
          Если для проекта еще не было создано ни одной схемы согласования, выберите стадию и создайте схему.
        </ProjectAgreementSchemeText>
      </Stack>

      <Box>
        <img src={EmptyPreview} width={852} height={400} alt='Шаблон схемы' draggable={false} />
      </Box>

      <Stack width={852} textAlign='left' spacing={3}>
        <Stack spacing={0.5}>
          <ProjectAgreementSchemeTitleText variant='subtitle1'>
            Карточка этапа
          </ProjectAgreementSchemeTitleText>
          <ProjectAgreementSchemeText variant='body2'>
            Содержит основные параметры этапа: Название, срок на прохождение, а также ответственного или куратора.
          </ProjectAgreementSchemeText>
        </Stack>

        <Stack spacing={0.5}>
          <ProjectAgreementSchemeTitleText variant='subtitle1'>
            Позитивные и негативные статусы при прохождении этапа
          </ProjectAgreementSchemeTitleText>
          <ProjectAgreementSchemeText variant='body2'>
            Отмечены цветовой индикацией на схеме. Они указывают направление движения и на какой этап будет передан том
            для дальнейшей процедуры согласования. Системой предусмотрено написание собственного заголовка статуса при
            прохождении этапа.
          </ProjectAgreementSchemeText>
        </Stack>
      </Stack>
    </ProjectAgreementSchemeEmptyWrapper>
  )
}
