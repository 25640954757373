import { IconButton } from '@mui/material'
import styled from 'styled-components'

export const StyledTomProdButton = styled(IconButton)<{ inProd?: boolean }>`
  border-radius: 6px;
  padding: 6px;
  background-color: ${(props) =>
    props.inProd ? props.theme.palette.legends.green + '26' : props.theme.palette.bg.lightBlue};

  :hover {
    background-color: transparent;
  }
`
