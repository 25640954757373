import React from 'react'
import { PdCardProps } from './PdCard.types'
import { PdCardText, PdCardTooltip, StyledPdCard, StyledPdCardContent, StyledPdCardHeader } from '../PdCard/styles'
import { Box, Stack, Typography } from '@mui/material'
import Divider from '../../../../components/Divider'
import InfoRow from '../../../../components/InfoRow'
import { InfoRowData } from '../../../../components/InfoRow/InfoRow.types'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { projectTypeShort } from '../../../../types/project'

const PdCard: React.FC<PdCardProps> = ({ data, onClick }) => {
  const { id, fullName, mark, description, type } = data

  const projectTypeRowData: InfoRowData = {
    icon: InsertDriveFileOutlinedIcon,
    title: 'Тип проекта',
    value: projectTypeShort[type],
  }

  return (
    <Box style={{ position: 'relative', width: '100%' }}>
      <StyledPdCard onClick={() => onClick(id)}>
        <StyledPdCardHeader
          title={
            <Box>
              <Typography variant='subtitle1'>{mark}</Typography>
            </Box>
          }
          subheader={
            <Box>
              <Typography variant='body2' className='ellipsisText'>{fullName}</Typography>
            </Box>
          }
        />

        <Divider />

        <StyledPdCardContent>
          <Stack spacing={1.25}>
            <InfoRow rowData={projectTypeRowData} />

            <Divider />

            {description
              ? (
                <Stack spacing={0.5}>
                  <Box>
                    <PdCardTooltip variant='body2'>Примечание:</PdCardTooltip>
                  </Box>
                  <Box>
                    <PdCardText variant='body1' className='ellipsisText'>{description}</PdCardText>
                  </Box>
                </Stack>
              )
              : null
            }
          </Stack>
        </StyledPdCardContent>
      </StyledPdCard>
    </Box>
  )
}

export default PdCard