import { Box, IconButton, Stack } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled(Stack)`
  width: 100%;
  overflow: hidden;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
`
export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
`
export const ScrollableContent = styled(Box)`
  max-height: 100%;
  padding: 0 10px;
  margin: auto;
`
export const ToolBar = styled(Stack)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  padding: 10px 25px;
  justify-content: space-between;
  align-items: center;
  border-radius: 33px;
  background-color: #1976D214;
`
export const ActionButton = styled(IconButton)<{iconcolor?: string}>`
  padding: 0;
  height: 34px;
  width: 34px;
  color: #0044B4;
  color: ${props => props.iconcolor === 'gray' && '#5C6E8C'};
  border-radius: 8px;
  background-color: #1976D214;
  border: 1px solid #0044b41a;

  :hover {
    box-shadow: 0px 0px 8px 0px #0044b436;
    background-color: #1976D214;
  }
`
export const ScrollContainer = styled(Box)`
  height: 100vh;
  overflow: auto;
  width: 100%;
  position: relative;
`
export const PageContainer = styled(Box)`
  display: flex;
  justify-content: center;
  //flex-direction: column;
  margin: auto;
  width: min-content;
  position: relative;
`
export const RightBlockToolbar = styled(Stack)`
  align-items: center;
  justify-content: flex-end;

  @media(min-width: 1600px)  {
    min-width: 202px;
  }
`



