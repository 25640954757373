import { styled, Typography } from "@mui/material";

interface TextBoxProps {
  $isOverflowing?: boolean
  $mode?: 'default' | 'table'
}

export const TextBox = styled(Typography) <TextBoxProps>`
  overflow: hidden;
  width: 100%;
  text-align: start;
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
  -webkit-line-clamp: ${props => props.$isOverflowing ? 2 : 'inherit'};

  &:hover {
    max-height: ${props => props.$isOverflowing ? 'max-content' : 'inherit'};
    -webkit-line-clamp: inherit;
    z-index: ${props => props.$isOverflowing && 1};
    position: ${props => props.$mode === 'table' && props.$isOverflowing && 'absolute'};
    top: 0px;
    left: 0px;
    padding: ${props => props.$mode === 'table' && props.$isOverflowing && '8px 16px'};
    width: ${props => props.$mode === 'table' && props.$isOverflowing && 'calc(100% - 1px)'};
    background-color: ${props => props.$mode === 'table' && props.$isOverflowing && '#f5f5f5'};
    box-shadow: ${props => props.$mode === 'table' && props.$isOverflowing
    && '0px 5px 5px 1px #cdcdcd, 0px -8px 11px 3px #ffffff'};
    transition: box-shadow 170ms ease-in-out;
    margin: ${props => props.$mode === 'table' && props.$isOverflowing && 0};
  }
`