import { IconButton, Stack } from "@mui/material";
import styled from "styled-components";

export const StyledIconButton = styled(IconButton)`
  padding: 6px;
  border-radius: 6px;
  background-color: ${props => props.theme.palette.bg.lightBlue};

  &:hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }

  &.Mui-disabled {
    color: ${props => props.theme.palette.disabled};
    background-color: ${props => props.theme.palette.bg.gray};
  }
  

  
`
export const DepartmentCell = styled(Stack)`
  background-color: ${props => props.theme.palette.bg.lightBlue};
  width: 100%;
  min-width: 945px;
  height: inherit;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;

  & .MuiTypography-root {
    padding: 0 8px;
    color: ${props => props.theme.palette.primary.main};
  }
`