import { Box, Stack, Tabs } from "@mui/material";
import styled from "styled-components";

export const StyledButtonGroup = styled(Tabs)`
  padding: 0 20px 12px 0;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: transparent;

  .MuiTabs-flexContainer {
    background-color: ${props => props.theme.palette.bg.shades};
    border-radius: 6px;
    height: 30px;
    align-items: center;

    & .MuiDivider-root {
      opacity: 0.3;
      border-color: ${props => props.theme.palette.primaryBorderLight};
    }
    
  }

  & .MuiTab-fullWidth {
    height: 26px;
    min-height: min-content;
    padding: 0;
    border-radius: 6px;
    margin: 0 2px;
    text-transform: none;
    font-size: 12px;
    font-weight: 400;
    color: ${props => props.theme.palette.secondary.gray};
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .Mui-selected {
      color: ${props => props.theme.palette.primary.main};
      background-color: ${props => props.theme.palette.bg.white};
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    }
`

export const ScrollableContainer = styled(Stack)`
  height: calc(100vh - 200px);
  padding-right: 20px;
  overflow: auto scroll;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    padding-right: 7px;

    &::-webkit-scrollbar {
      display: block;
    }
  }

  @supports (-moz-appearance:none) {
    scrollbar-width: none;

    &:hover {
      padding-right: 12px;
      scrollbar-width: thin;
    }
  }
`
export const StyledWrapper = styled(Stack)`
  align-items: center;
  position: absolute;
  top: 50%;
  left: calc((100% - 200px) /2);
`

