import { Box, Stack, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import React, { FC } from 'react'

import { NotificationItemContentWrapper, NotificationItemWrapper } from '.'
import { StyledCheckbox } from '../../../../components/FieldForm/styles'
import { theme } from '../../../../styles/theme'
import { NotificationsFormData } from '../NotificationsDrawer/NotificationsDrawer.types'
import { NotificationItemProps } from './NotificationItem.types'

export const NotificationItem: FC<NotificationItemProps> = ({ tom, onChange }) => {
  const { id, title, description, contractorName, prodDate, prodAuthorName } = tom
  const { values: formValues } = useFormikContext<NotificationsFormData>()

  return (
    <NotificationItemWrapper direction='row'>
      <Box>
        <StyledCheckbox checked={formValues.tomIds.includes(id)} onChange={() => onChange(id)} />
      </Box>
      <NotificationItemContentWrapper spacing={1} flex={1}>
        <Box>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <Typography variant='subtitle2'>{title}</Typography>
            <Typography variant='tooltip' color={theme.palette.text.dark}>
              {prodDate || '—'}
            </Typography>
          </Stack>
          <Typography variant='body2' color={theme.palette.text.dark}>
            {description}
          </Typography>
          <Typography variant='body2' color={theme.palette.text.dark}>
            {contractorName}
          </Typography>
        </Box>

        <Typography variant='body2'>{prodAuthorName}</Typography>
      </NotificationItemContentWrapper>
    </NotificationItemWrapper>
  )
}