import { useState } from "react"
import { AlertIcon } from "../../../../../assets/icons/AlertIcon"
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  InternalAgreementAdviceCloseButton,
  InternalAgreementAdviceText,
  InternalAgreementAdviceTextWrapper,
  InternalAgreementAdviceTitle,
  InternalAgreementAdviceTitleWrapper,
  InternalAgreementAdviceWrapper
} from "./InternalAgreementAdvice.styles"

export const InternalAgreementAdvice = () => {

  const [expanded, setExpanded] = useState<boolean>(false)

  const onExpandClick = () => {
    setExpanded(true)
  }

  const onShrinkClick = () => {
    setExpanded(false)
  }

  return (
    <InternalAgreementAdviceWrapper
      onClick={expanded ? onShrinkClick : onExpandClick}
      $expanded={expanded}
    >
      <InternalAgreementAdviceTitleWrapper>
        <AlertIcon fontSize='medium' />
        <InternalAgreementAdviceTitle $expanded={expanded}>
          Предупреждение
        </InternalAgreementAdviceTitle>
      </InternalAgreementAdviceTitleWrapper>
      {expanded && (
        <>
          <InternalAgreementAdviceTextWrapper>
            <InternalAgreementAdviceText $expanded={expanded} >
              Внутреннее согласование не может быть завершено, ни у одного участника нет доступа к утверждению
            </InternalAgreementAdviceText>
          </InternalAgreementAdviceTextWrapper>
          <InternalAgreementAdviceCloseButton onClick={onShrinkClick}>
            <HighlightOffIcon color='warning' />
          </InternalAgreementAdviceCloseButton>
        </>
      )}

    </InternalAgreementAdviceWrapper>
  )
}