import styled from 'styled-components'
import { IconButton, Stack } from '@mui/material'

export const PopoverContentWrapper = styled(Stack)`
  min-width: 150px;
  max-width: 300px;
  padding: 20px;
  border-radius: 8px;

  & .comment-title {
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0;
  }

  & .comment-text {
    color: ${props => props.theme.palette.text.dark};
  }
`

export const PopoverCloseButton = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 10px;
  padding: 2px;
  background-color: ${props => props.theme.palette.bg.shades};

  & .MuiSvgIcon-root {
    border-radius: 50%;
  }
`