import { Button, Drawer, Stack, } from "@mui/material"
import { RemarkHistoryDrawerProps, TRemarkHistoryDialogTrigger, TRemarkHistoryDrawerTab } from "./RemarkHistoryDrawer.types"
import { DrawerHeader, DrawerWrapper } from "./RemarkHistoryDrawer.styles"
import { useAppDispatch, useTypedSelector } from "store/store"
import { remarkHistoryDataSelector } from "store/slices/remarks/selectors/remarks.selectors"
import { useGetRemarkOnIdQuery } from "api/remarks"
import { useParams } from "react-router-dom"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { profileSelector } from "store/slices/profile"
import { RemarkStatus } from "api/remarks/types"
import { TabData } from "components/Tabs/Tabs.types"
import { ToggleButtonGroup } from "components/ToggleButtonGroup"
import { RemarkEliminationHistory } from "./components/RemarkEliminationHistory"
import { RemarkDirectionHistory } from "./components/RemarkDirectionHistory/RemarkDirectionHistory"
import useConfirmDialog, { UseExitConfirmProps } from "hooks/useConfirmDialog"
import { setOpenedConfirm } from "store/slices/documentsPages/drawerInfo"

export const RemarkHistoryDrawer = ({ open, closeDrawer }: RemarkHistoryDrawerProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const { remarkId, index } = useTypedSelector(remarkHistoryDataSelector)
  const { data: remarkResponse } = useGetRemarkOnIdQuery({ projectId, remarkId: remarkId! }, { skip: !remarkId })
  const { canAddMessage, messages, remark } = remarkResponse || {}
  const { avatar, id, name } = remark?.author || {}
  const profile = useTypedSelector(profileSelector)
  const initialTab: TRemarkHistoryDrawerTab = 'elimination'
  const [currentTab, setCurrentTab] = useState<TRemarkHistoryDrawerTab>(initialTab)
  const [status, setStatus] = useState<RemarkStatus | null>(null)
  const [dirty, setDirty] = useState<boolean>(false)

  useEffect(() => {
    if (!open) {
      setStatus(null)
      setDirty(false)
    } else setCurrentTab(initialTab)
  }, [open])

  const tabsData: TabData<TRemarkHistoryDrawerTab>[] = [
    { value: 'elimination', label: 'История устранения' },
    {
      value: 'direction',
      label: 'История направлений',
      disabled: true
    },
  ]

  const onChangeTab = (tab: TRemarkHistoryDrawerTab) => {
    if (tab === currentTab) return

    if (dirty) {
      setConfirmDialogTrigger('changeTab')
      openConfirm()
    } else {
      setCurrentTab(tab)
    }
  }

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  const remarkHistoryDrawerContentByTab = useMemo(() => {
    const data: Record<TRemarkHistoryDrawerTab, ReactNode> = {
      'elimination': <RemarkEliminationHistory remarkResponse={remarkResponse || null} onFormChange={onFormChange} />,
      'direction': <RemarkDirectionHistory remarkResponse={remarkResponse || null} />
    }

    return data[currentTab]
  }, [currentTab, remarkResponse])

  const handleChangeTab = useCallback((confirm: boolean) => {
    if (confirm) {
      setCurrentTab((prev) => (prev === 'direction' ? 'elimination' : 'direction'))
      setDirty(false)
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<TRemarkHistoryDialogTrigger>('changeTab')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    changeTab: {
      handleConfirm: handleChangeTab,
      title: 'Сменить режим формы?',
      body: 'Режим отображения истории замечания будет изменён, а все введенные данные безвозвратно утеряны.',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => closeDrawer(!!status || dirty)}
    >
      <DrawerWrapper>
        <DrawerHeader variant="h2">Замечание №{index}</DrawerHeader>

        <Stack overflow={'auto'} height={'100%'}>
          <Stack p={2.5}>
            <ToggleButtonGroup<TRemarkHistoryDrawerTab>
              size={36}
              onToggleButtonChange={(e: React.SyntheticEvent, toggleValue: TRemarkHistoryDrawerTab) =>
                onChangeTab(toggleValue)
              }
              toggleButtonData={tabsData.map((tab) => ({ value: tab.value, label: tab.label }))}
              currentValue={currentTab}
            />
          </Stack>

          <Stack overflow={'auto'} height={'100%'}>
            {remarkHistoryDrawerContentByTab}

            <Stack p={2.5}>
              <Button fullWidth
                onClick={() => closeDrawer(dirty)}>
                Закрыть
              </Button>
            </Stack>
          </Stack>
        </Stack>

        <ConfirmDialog />
      </DrawerWrapper>
    </Drawer>
  )
}