import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

export const PenEditIcon: FC<IconProps> = (props) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13.6125 1.5025L14.7045 2.593C14.9302 2.81775 14.9302 3.1835 14.7045 3.409L13.831 4.27975L11.9212 2.37325L12.7947 1.50225C12.9075 1.3895 13.0555 1.33325 13.2035 1.33325C13.3515 1.33325 13.4997 1.38975 13.6125 1.5025ZM8.16098 9.93974L6.25098 8.03299L11.5122 2.78149L13.4222 4.68824L8.16098 9.93974ZM5.83874 8.441L5.43099 10.754L7.74874 10.3475L5.83874 8.441ZM3.91724 11.7848H11.1187V7.694L12.3687 6.44625V12.2265C12.3687 12.6713 12.005 13.035 11.5602 13.035H3.47549C3.03074 13.035 2.66699 12.6713 2.66699 12.2265V4.14175C2.66699 3.697 3.03074 3.33325 3.47549 3.33325H10.252L8.99949 4.58325H3.91724V11.7848Z'
        fill='#5C6E8C'
      />
    </svg>
  )
}
