import { ListItemText, Stack, Typography } from '@mui/material'
import styled from 'styled-components'

export const TitleWrapper = styled(Stack)`
  min-width: 660px;
  padding: 60px 60px 60px 0px;
  align-items: center;
  height: 100%;
  gap: 40px;
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 16px;
`
export const StyledHeader = styled(Typography)`
  font-size: 32px;
  font-weight: 300;
  width: 100%;
  text-align: start;
  border-bottom: 1px solid #D1D8FA;
  color: ${props => props.theme.palette.secondary.dark};
  margin-bottom: 20px;
`

export const StyledListItemText = styled(ListItemText)`
  
  font-size: 16px;
  margin: 0;

  & .MuiListItemText-primary {
    color: ${props => props.theme.palette.legends.text};
  }

  & .MuiListItemText-secondary {
    color: ${props => props.theme.palette.secondary.dark};
  }
`

export const StyledFootnote = styled.span`
  font-size: 14px;
  text-align: start;
  color: ${props => props.theme.palette.secondary.dark};
`