import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { SetPageViewModePayload } from './ui.payloads.types'
import { UiState } from './ui.types'

let initialState: UiState = {
  pagesViewMode: {
    adminRd: 'list',
    adminPd: 'list',
    objects: 'list',
    docs: 'table',
    pockets: 'table',
    pocketToms: 'table',
    models: 'table',
  },
}

const uiSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    setPageViewMode: (state, { payload }: PayloadAction<SetPageViewModePayload>) => {
      const { page, viewMode } = payload
      state.pagesViewMode[page] = viewMode

      const updatedPagesViewMode = { ...state.pagesViewMode }
      localStorage.setItem('pagesViewMode', JSON.stringify(updatedPagesViewMode))
    },
  },
})

export const { reducer: uiReducer } = uiSlice
export const { setPageViewMode } = uiSlice.actions
