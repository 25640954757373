import { PlaceholderProps } from "./Placeholder.types"
import { HeaderBlock, HeaderBlock2, HeaderBlock3, PlaceHolderContainer, TextBlock, TextBlock2, TextBlock3, TextBlock4 } from "./Placeholder.styles"
import { memo } from "react"

export const Placeholder = memo(({ height, width }: PlaceholderProps) => {
  return (
    <PlaceHolderContainer height={height} width={width}>
{/*       <HeaderBlock />
      <HeaderBlock2/>
      <HeaderBlock3/> */}
      <TextBlock/>
      <TextBlock2/>
      <TextBlock3/>
{/*       <TextBlock4/>
      <TextBlock3/>
      <TextBlock3/>
      <TextBlock3/>
      <TextBlock2/>
      <TextBlock/>
      <TextBlock3/>
      <TextBlock3/>
      <TextBlock/>
      <TextBlock3/>
      <TextBlock/>
      <TextBlock3/> */}
    </PlaceHolderContainer>
  )
})