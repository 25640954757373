import { IconButton, Stack } from "@mui/material";
import styled from "styled-components";

export const StyledContainer = styled(Stack)`
  background-color: ${props => props.theme.palette.legends.red + '26'};
  border-radius: 8px;
  padding: 8px 8px 8px 16px;
  margin-top: 10px;
  position: relative;
`
export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 5px;
`