import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const InfoIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M14.2799 2C15.59 2 16.2448 2.89354 16.2448 3.91524C16.2448 5.19128 15.1073 6.37165 13.6249 6.37165C12.3837 6.37165 11.6595 5.63757 11.694 4.4255C11.694 3.404 12.5558 2 14.2799 2ZM10.2458 21.5652C9.21135 21.5652 8.4528 20.927 9.17691 18.1188L10.3645 13.1381C10.5713 12.3404 10.6052 12.0215 10.3645 12.0215C10.0538 12.0215 8.71106 12.5725 7.91691 13.1152L7.40039 12.2533C9.91706 10.1157 12.8125 8.86191 14.0533 8.86191C15.0874 8.86191 15.2601 10.1065 14.743 12.0213L13.3834 17.257C13.142 18.1822 13.2459 18.5011 13.4865 18.5011C13.7968 18.5011 14.8144 18.117 15.814 17.3192L16.4004 18.1172C13.9524 20.6065 11.2802 21.5652 10.2458 21.5652Z'
      />
    </SvgIcon>
  )
}

export default InfoIcon