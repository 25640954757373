import { Button, IconButton, Stack, TextField, ToggleButton, Typography } from "@mui/material";
import styled from "styled-components";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { ScrollableContainer } from "styles/global/ScrollableContainer";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const StyledContainer = styled(Stack) <{ open: boolean }>`
  width: 360px;
  box-shadow: 8px 0px 8px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  margin-left: 16px;
  margin-top: 8px;
  border-radius: 8px;
  padding-bottom: 20px;
  background-color: ${props => props.theme.palette.bg.white};
`
export const MetaHeader = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding-right: 4px;
  padding-left: 12px;
  border-bottom: 1px solid #D1D8FA;
`
export const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.palette.secondary.gray};
`
export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  color: ${props => props.theme.palette.secondary.gray};
`
export const MetaInfoWrapper = styled(ScrollableContainer)`
  text-align: start;
  padding-left: 20px;
`
export const TitleText = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
`
export const SubTitleText = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`

export const TitleName = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  text-align: start;
  padding: 10px 20px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`

export const SnackBarText = styled(Typography)`
  font-size: 13px;
  color: ${props => props.theme.palette.text.light};
  overflow-wrap: anywhere;
`
export const StickyWrapper = styled(Stack)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${props => props.theme.palette.bg.white};
`

export const StyledTextField = styled(TextField)`
  margin: 10px 20px 20px 20px;
  width: auto;

  & .MuiOutlinedInput-root {
    height: 30px;
    padding-right: 2px;
  }
`
export const RoundingWrapper = styled(Stack)<{isRounding: boolean}>`
  background-color: ${props => props.isRounding && props.theme.palette.table.yellow + '26'};
  padding: 10px 20px;
`
export const StyledControlButton = styled(Button)`
  height: 36px;
  min-width: 36px;
  padding: 8px;
  font-size: 20px;
  background-color: ${props => props.theme.palette.bg.lightBlue};
  color: ${props => props.theme.palette.primary.main};

  :hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }
`
export const StyledToggleButton = styled(ToggleButton)`
  height: 36px;
  width: 36px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${props => props.theme.palette.bg.lightBlue};

  :hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }

  &.Mui-selected {
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.bg.white};

    :hover {
      background-color: ${props => props.theme.palette.primary.main + 'c7'};
    }
  }
`
export const WarningIcon = styled(WarningAmberOutlinedIcon)`
  color: ${props => props.theme.palette.warning.light};
`