import { FC, useEffect, useLayoutEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectAgreementWrapper } from './ProjectAgreement.styles'
import { ProjectAgreementLegend } from './components/ProjectAgreementLegend'
import { ProjectAgreementScheme } from './components/ProjectAgreementScheme'
import { useAppDispatch, useTypedSelector } from '../../../store/store'
import {
  currentRoadmapSelector,
  setIsAddStageToExist,
  setIsStageFormDirty,
  setStages
} from '../../../store/slices/agreement'
import { useGetStagesQuery } from '../../../api/projectAgreement'

export const ProjectAgreement: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const currentRoadmap = useTypedSelector(currentRoadmapSelector)

  useLayoutEffect(() => {
    dispatch(setIsAddStageToExist({ isAddStageToExist: false }))
    dispatch(setIsStageFormDirty(false))
  }, [])

  const { data: stagesData, isFetching: isStagesFetching } = useGetStagesQuery({
    id: projectId,
    roadmapId: currentRoadmap.id
  }, { skip: !currentRoadmap.id })
  const { data: stages = [] } = stagesData || {}

  useEffect(() => {
    dispatch(setStages({ stages }))
  }, [stages])

  const onAddStageClick = (idLeft?: number | null, idRight?: number) => {
    dispatch(setIsAddStageToExist({ isAddStageToExist: false }))

    if (idLeft) {
      navigate(`add?idLeft=${idLeft}&roadmapId=${currentRoadmap.id}&phase=${currentRoadmap.phase}`)
      return
    }

    if (idRight) {
      navigate(`add?idRight=${idRight}&roadmapId=${currentRoadmap.id}&phase=${currentRoadmap.phase}`)
      return
    }

    navigate(`add?roadmapId=${currentRoadmap.id}&phase=${currentRoadmap.phase}`)
  }

  const addStageHandler = () => {
    stages?.length
      ? dispatch(setIsAddStageToExist({ isAddStageToExist: true }))
      : onAddStageClick()
  }

  return (
    <ProjectAgreementWrapper>
      <ProjectAgreementLegend
        addStageHandler={addStageHandler}
        loading={isStagesFetching}
      />
      <ProjectAgreementScheme
        stages={stages}
        isStagesFetching={isStagesFetching}
        onAddStageClick={onAddStageClick}
      />
    </ProjectAgreementWrapper>
  )
}
