import { Stack, Typography } from "@mui/material"

export const EmptyRemarks = () => {
  return (
    <Stack spacing={1.5} justifyContent='center' alignItems='center' mt='100%' px={2.5}>
      <Typography variant="h3" fontSize='16px' fontWeight={500}>Замечаний нет.</Typography>
      <Typography variant="h3">
      {/* Чтобы добавить замечание к тому, нажмите на кнопку + ЗАМЕЧАНИЕ в области просмотра документа. */}
      </Typography>
    </Stack>
  )
}