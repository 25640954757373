import { i18n } from 'i18n/index'
import * as yup from 'yup'

export const validationTanglIntegrating = yup.object({
  key: yup.string().trim().required('common:errors.required'),
  secret: yup.string().trim().required('common:errors.required'),
  login: yup.string().trim().required('common:errors.required'),
  password: yup.string().trim().required('common:errors.required'),
  companyId: yup.string().trim().required('common:errors.required'),
})
