import Tooltip from 'components/Tooltip'
import { FC, useEffect, useRef, useState } from 'react'

import { StyledOverflowTextNew } from './OverflowTextNew.styles'
import { IOverflowTextNewProps, isTextOverflowed } from './OverflowTextNew.types'

export const OverflowTextNew: FC<IOverflowTextNewProps> = ({ rows = 1, children, ...props }) => {
  const textRef = useRef<HTMLParagraphElement | null>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  useEffect(() => {
    setShowTooltip(isTextOverflowed(textRef))
  }, [textRef, children])

  return (
    <Tooltip title={showTooltip ? children : ''}>
      <StyledOverflowTextNew {...props} rows={rows} ref={textRef}>
        {children}
      </StyledOverflowTextNew>
    </Tooltip>
  )
}
