import { Button, FormControlLabel, ToggleButton, ToggleButtonGroup } from '@mui/material'
import styled from 'styled-components'

export const ExpiredCheckboxControl = styled(FormControlLabel)`
  margin-left: 16px;

  & .MuiCheckbox-root {
    padding: 0;
  }
`

export const DocsTopLegendStyledButton = styled(Button)`
  border-color: ${(props) => props.theme.palette.primaryBorderLight};
  min-width: 40px;

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 40px;
`
export const StyledToggleButton = styled(ToggleButton)`
  padding: 7px;
  width: 123px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  border: 1px solid ${props => props.theme.palette.primaryBorderLight};
  background-color: transparent;
  color: ${props => props.theme.palette.secondary.main};

  &.Mui-selected {
    background-color: ${props => props.theme.palette.bg.lightBlue};
  }
`