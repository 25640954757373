import styled from 'styled-components'
import Button from '../../../../components/Button'
import { TitledWrapper } from '../../../../components/TitledWrapper'

export const ProjectCardInfoWrapper = styled(TitledWrapper)`
  & .stack-wrapper {
    padding: 14px 10px;
    gap: 10px;
  }
`

export const HoveredButton = styled(Button)`
  max-width: none;
  
  &:hover {
    background-color: #dedfee;
  }
`
