import { ReactNode } from 'react'
import { theme } from '../styles/theme'
import { TomRdDocument } from '../api/rdPhase'
import { ProjectType } from './project'
import { SvgIconComponent } from '@mui/icons-material'
import ObjectsIcon from '../assets/icons/ObjectsIcon'

export interface WithChildren<T = ReactNode> {
  children: T;
}

export interface StatusResponse {
  description: string;
  roadmapAccess: boolean;
  success: boolean;
}

export type ExcelParseErrorType =
  'unknown'
  | 'sheet_not_found'
  | 'many_sheets'
  | 'missed_columns'
  | 'cell_not_found'
  | 'empty_cell'
  | 'invalid_cell_value '
  | 'unacceptable_cell_value'

type BlockErrors = Extract<ExcelParseErrorType, 'unknown' | 'many_sheets' | 'sheet_not_found' | 'missed_columns'>

export interface ExcelParseError {
  col: number;
  row: number;
  message: string;
  type: ExcelParseErrorType;
}

export const errorTextByType: Record<ExcelParseErrorType, string> = {
  'unknown': 'Неизвестная ошибка',
  'sheet_not_found': 'Книга не содержит данных',
  'many_sheets': 'Книга содержит несколько листов',
  'missed_columns': 'Отсутствуют обязательные данные',
  'cell_not_found': 'Не найдена колонка',
  'empty_cell': 'Не найдено значение',
  'invalid_cell_value ': 'Некорректные данные',
  'unacceptable_cell_value': 'Неприемлемые данные',
}

export const isBlockError = (error: any): error is BlockErrors => {
  return ['unknown', 'many_sheets', 'sheet_not_found', 'missed_columns'].includes(error)
}

export interface FileUploadResponse<T> extends StatusResponse {
  data: T;
}

export interface ExcelUploadResponse {
  create: number;
  error: ExcelParseError[];
  success: boolean;
  update: number;
}

export interface UploadStatus {
  description: string;
  fileName: string;
}

export interface DefaultMultiResponse {
  failed: UploadStatus[];
  success: UploadStatus[];
}

export type SingleResponse<T extends null | TomRdDocument> = ExcelUploadResponse | FileUploadResponse<T>
export type MultiResponse = DefaultMultiResponse

export const isExcelResponse = <T extends null | TomRdDocument>(response: SingleResponse<T>): response is ExcelUploadResponse => {
  return (response as ExcelUploadResponse).create !== undefined
}

export const isExcelResponseForErrors = (response: ExcelUploadResponse | MultiResponse): response is ExcelUploadResponse => {
  return (response as ExcelUploadResponse).create !== undefined
}

export const isMultiResponse = <T extends null | TomRdDocument>(response: MultiResponse | SingleResponse<T>): response is MultiResponse => {
  return (response as MultiResponse).failed !== undefined
}

export type IconComponent = SvgIconComponent | typeof ObjectsIcon;

export type LegendColors = keyof typeof theme.palette.legends
export const isLegendColor = (colorName: string): colorName is LegendColors => {
  return colorName in theme.palette.legends
}

export interface Limit {
  value: number;
  title: string;
}

export interface PaginationProps {
  limit?: number;
  offset?: number;
}

export interface ComponentPaginationProps {
  limit: number;
  onChangeLimit: (limit: number) => void;
  numberRows: Limit[];
  page: number;
  onChangePage: (page: number) => void;
  countPagination: number;
}

export interface FilterByProjectTypeRequest {
  type?: ProjectType;
}