import styled from 'styled-components'
import { Drawer, Stack } from '@mui/material'
import { LogoWrapper } from '../AdminNavBar/styles'
import { ReactComponent as DesignIcon } from '../../assets/design-logo.svg'

export const UserDrawer = styled(Drawer)`
  width: 350px;
  text-align: left;


  & > .MuiPaper-root {
    width: 350px;
    box-shadow: 8px 0 8px rgba(0, 0, 0, 0.06);
    overflow: hidden;
  }
`

export const UserDrawerHeader = styled(Stack)`
  height: 64px;
  padding: 0 20px;
`

export const UserLogoWrapper = styled(LogoWrapper)`
  width: 160px;
  height: 64px;
  display: block;
`

export const UserDesignLogo = styled(DesignIcon)`
  width: 160px;
  height: 64px;
`

export const UserDrawerContent = styled(Stack)`
  padding: 10px 0 20px 20px;
  overflow: hidden;

/*   @supports (-moz-appearance:none) {
    scrollbar-width: none;

    &:hover {
      padding-right: 12px;
      scrollbar-width: thin;
    }
  } */
`
