import { UseAccordionOpenStateProps } from './useAccordionOpenState.types'
import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { ExportArchiveFormData } from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'

export const useAccordionOpenState = ({
  objectIndex,
  pdIndex,
  iiIndex,
  irdIndex,
  setExpanded,
}: UseAccordionOpenStateProps) => {
  const { values: formValues, setFieldValue } = useFormikContext<ExportArchiveFormData>()

  // Rd
  // set object checkbox checked when all rd checked
  useEffect(() => {
    const filteredToms = formValues.rdFileList[objectIndex!]?.rdList?.filter(rd => !rd.hideByFilter)

    if (filteredToms?.length && filteredToms.every(rd => rd.selected)) {
      setFieldValue(`rdFileList[${objectIndex}].selected`, true)
    }
  }, [formValues.rdFileList[objectIndex!]?.rdList])

  // close and open rd accordion depending on checked status
  useEffect(() => {
    if (formValues.rdFileList[objectIndex!]?.selected) {
      setExpanded(false)
      return
    }

    if (formValues.rdFileList[objectIndex!]?.selected !== undefined && formValues.rdFileList[objectIndex!]?.openedManually) {
      setExpanded(true)
    }
  }, [formValues.rdFileList[objectIndex!]?.selected])


  //Pd
  // close and open pd accordion depending on checked status
  useEffect(() => {
    if (formValues.pdFileList[pdIndex!]?.selected) {
      setExpanded(false)
      return
    }

    if (formValues.pdFileList[pdIndex!]?.selected !== undefined && formValues.pdFileList[pdIndex!]?.openedManually) {
      setExpanded(true)
    }
  }, [formValues.pdFileList[pdIndex!]?.selected])


  // Ii
  // close and open ii accordion depending on checked status
  useEffect(() => {
    if (formValues.iiFileList[iiIndex!]?.selected) {
      setExpanded(false)
      return
    }

    if (formValues.iiFileList[iiIndex!]?.selected !== undefined && formValues.iiFileList[iiIndex!]?.openedManually) {
      setExpanded(true)
    }
  }, [formValues.iiFileList[iiIndex!]?.selected])

  // Ird
  // close and open ird accordion depending on checked status
  useEffect(() => {
    if (formValues.irdFileList[irdIndex!]?.selected) {
      setExpanded(false)
      return
    }

    if (formValues.irdFileList[irdIndex!]?.selected !== undefined && formValues.irdFileList[irdIndex!]?.openedManually) {
      setExpanded(true)
    }
  }, [formValues.irdFileList[irdIndex!]?.selected])
}
