import React, { FC } from 'react'
import { ProjectAgreementFormContentProps } from './ProjectAgreementFormContent.types'
import { Box, Stack, Typography } from '@mui/material'
import {
  AgreementCheckboxStart,
  AgreementDurationField,
  AgreementTitledWrapper,
  AgreementTitledWrapperAutocomplete
} from './ProjectAgreementFormContent.styles'
import FieldForm from '../../../../../components/FieldForm'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import { useFormikContext } from 'formik'
import { ProjectAgreementFormData } from '../../ProjectAgreementForm.types'
import { agreementTomStatusArray, RoadmapPhase } from '../../../../../api/projectAgreement/types'
import { useParams } from 'react-router-dom'
import { useGetNextStagesQuery } from '../../../../../api/projectAgreement'
import useQuery from '../../../../../hooks/useQuery'
import { theme } from '../../../../../styles/theme'
import { useAgreementPerson } from './hooks/useAgreementPerson'
import { FormItem } from '../../../../Administration/ProjectManagement/components/FormItem'

export const ProjectAgreementFormContent: FC<ProjectAgreementFormContentProps> = ({ canEdit }) => {
  const { projectId: projectIdString, stageId: stageIdString } = useParams()
  const projectId = Number(projectIdString)
  const stageId = Number(stageIdString)

  const queryHandler = useQuery()
  const phase: RoadmapPhase | null = queryHandler.get('phase')
  const roadmapIdStr: string | null = queryHandler.get('roadmapId')
  const roadmapId = Number(roadmapIdStr)

  const { values: formValues, setFieldValue, initialValues } = useFormikContext<ProjectAgreementFormData>()

  const { companyNames, employeesByProjectAndCompany, isFetching } = useAgreementPerson<ProjectAgreementFormData>({ projectId })

  const { data: nextStagesData, isFetching: stagesFetching } = useGetNextStagesQuery({ id: projectId, roadmapId, stageId }, { skip: !roadmapId })
  const { data: nextStages = [] } = nextStagesData || {}

  return (
    <Stack minWidth={400} spacing={1.25}>
      <AgreementTitledWrapper
        title='Основная информация'
        variant='primary'
        contentWrapper='stack'
        bordered='border'
      >
        <Stack alignItems='end' spacing={1.5}>
          <FormItem
            title='Название этапа*'
            input={(
              <FieldForm
                disabled={!canEdit}
                version='project'
                name='title'
                placeholder='Введите название'
              />
            )}
          />
          <FormItem
            title='Срок прохождения этапа, в днях'
            input={(
              <AgreementDurationField
                disabled={!canEdit}
                version='project'
                name='duration'
                placeholder='0'
                InputProps={{
                  startAdornment: <WatchLaterIcon fontSize='medium' className='clockIcon' />
                }}
                type='number'
                onKeyDown={e => {
                  if (e.key === '-' || e.key === 'e') {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  setFieldValue('duration', e.target.value.slice(0, 3))
                }}
                inputProps={{
                  min: '0',
                }}
              />
            )}
          />
          <AgreementCheckboxStart
            version='checkbox'
            name='isStart'
            disabled={initialValues.isStart || !canEdit}
            checkboxData={{
              label: (
                <Typography
                  variant='subtitle2'
                  color={theme.palette.text.dark}
                  fontWeight={400}
                  component='span'
                >
                  Начальный этап согласования
                </Typography>
              ),
              checked: formValues.isStart,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(
                  `isStart`,
                  e.target.checked
                )
              }
            }}
          />
        </Stack>
      </AgreementTitledWrapper>

      <AgreementTitledWrapper
        title='Куратор или ответственный за этап'
        variant='primary'
        contentWrapper='stack'
        bordered='border'
      >
        {/*         {isFetching ? 
        <Box height="76px">
          <Progress/>
        </Box> */}

        <Stack alignItems='end' spacing={1.5}>
          <FormItem
            title='Компания*'
            input={(
              <AgreementTitledWrapperAutocomplete
                disabled={!canEdit}
                fieldName='companyName'
                placeholder='Компания'
                data={companyNames?.map(company => ({
                  value: company,
                  label: company,
                  key: company,
                })) || []}
              />

            )}
          />
          <FormItem
            title='Ответственный*'
            input={(
              <AgreementTitledWrapperAutocomplete
                disabled={!canEdit}
                fieldName='personId'
                placeholder='Введите ФИО'
                data={employeesByProjectAndCompany?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                  key: id,
                })) || []}
              />

            )}
          />
        </Stack>
      </AgreementTitledWrapper>

      <AgreementTitledWrapper
        title='Позитивный результат прохождения'
        variant='primary'
        contentWrapper='stack'
        bordered='border'
      >
        <Stack alignItems='end' spacing={1.5}>
          <FormItem
            title='Позитивный'
            input={(
              <FieldForm
                version='project'
                disabled={!canEdit}
                name='results[0].title'
                placeholder='Например: Согласовано'
              />
            )}
            Icon={<CheckCircleIcon fontSize='medium' color='lightgreen' />}
          />
          <FormItem
            title='Передать'
            input={(
              <AgreementTitledWrapperAutocomplete
                fieldName='results[0].nextStageId'
                disabled={!canEdit}
                placeholder='Название этапа'
                data={[
                  { value: 'final', label: 'Завершение процедуры', key: 'final', color: 'lightgreen' },
                  ...nextStages.map(({ id, title }) => ({
                    value: id, label: title, key: id
                  })),
                ]}
              />
            )}
            Icon={<ArrowRightAltIcon fontSize='medium' color='lightgreen' />}
          />
          <FormItem
            title='Статус тома'
            input={(
              <AgreementTitledWrapperAutocomplete
                fieldName='results[0].tomStatus'
                disabled={!canEdit}
                placeholder='Выберите статус'
                data={agreementTomStatusArray
                  .filter(status => status === 'В производстве' ? phase === 'РД' : true)
                  .map(status => ({
                    value: status,
                    label: status,
                    key: status
                  }))}
              />
            )}
            Icon={<InfoOutlinedIcon fontSize='medium' color='secondary' />}
          />
        </Stack>
      </AgreementTitledWrapper>

      <AgreementTitledWrapper
        title='Негативный результат прохождения'
        variant='primary'
        contentWrapper='stack'
        bordered='border'
      >
        <Stack alignItems='end' spacing={1.5}>
          <FormItem
            title='Негативный'
            input={(
              <FieldForm
                version='project'
                disabled={!canEdit}
                name='results[1].title'
                placeholder='Например: Не согласовано'
              />
            )}
            Icon={<CancelIcon fontSize='medium' color='error' />}
          />
          <FormItem
            title='Передать'
            input={(
              <AgreementTitledWrapperAutocomplete
                fieldName='results[1].nextStageId'
                disabled={!canEdit}
                placeholder='Название этапа'
                data={nextStages.map(({ id, title }) => ({
                  value: id, label: title, key: id
                }))}

              />
            )}
            Icon={<ArrowRightAltIcon fontSize='medium' color='error' />}
          />
          <FormItem
            title='Статус тома'
            input={(
              <AgreementTitledWrapperAutocomplete
                fieldName='results[1].tomStatus'
                disabled={!canEdit}
                placeholder='Выберите статус'
                data={agreementTomStatusArray
                  .filter(status => status === 'В производстве' ? phase === 'РД' : true)
                  .map(status => ({
                    value: status,
                    label: status,
                    key: status
                  }))}
              />
            )}
            Icon={<InfoOutlinedIcon fontSize='medium' color='secondary' />}
          />
          <FormItem
            title='Срок следующего этапа, в днях'
            input={(
              <AgreementDurationField
                version='project'
                disabled={!canEdit}
                name='results[1].duration'
                placeholder='0'
                InputProps={{
                  startAdornment: <WatchLaterIcon fontSize='medium' className='clockIcon' />
                }}
                type='number'
                onKeyDown={e => {
                  if (e.key === '-' || e.key === 'e') {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  setFieldValue('results[1].duration', e.target.value.slice(0, 3))
                }}
                inputProps={{
                  min: '0',
                }}
              />
            )}
          />
        </Stack>
      </AgreementTitledWrapper>

      {/*<AgreementTitledWrapper*/}
      {/*  title='Дополнительно'*/}
      {/*  variant='primary'*/}
      {/*  contentWrapper='stack'*/}
      {/*  bordered='border'*/}
      {/*>*/}
      {/*  <Stack>*/}
      {/*    <AgreementCheckbox*/}
      {/*      version='checkbox'*/}
      {/*      name='optVisa'*/}
      {/*      checkboxData={{*/}
      {/*        label: (*/}
      {/*          <Typography*/}
      {/*            variant='subtitle2'*/}
      {/*            color={theme.palette.text.dark}*/}
      {/*            fontWeight={400}*/}
      {/*            component='span'*/}
      {/*          >*/}
      {/*            Виза с утверждением согласования*/}
      {/*          </Typography>*/}
      {/*        ),*/}
      {/*        checked: formValues.optVisa,*/}
      {/*        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {*/}
      {/*          setFieldValue(*/}
      {/*            `optVisa`,*/}
      {/*            e.target.checked*/}
      {/*          )*/}
      {/*        }*/}
      {/*      }}*/}
      {/*    />*/}
      {/*    <AgreementCheckbox*/}
      {/*      version='checkbox'*/}
      {/*      name='optDocs'*/}
      {/*      checkboxData={{*/}
      {/*        label: (*/}
      {/*          <Typography*/}
      {/*            variant='subtitle2'*/}
      {/*            color={theme.palette.text.dark}*/}
      {/*            fontWeight={400}*/}
      {/*            component='span'*/}
      {/*          >*/}
      {/*            Сопроводительные документы для передачи*/}
      {/*          </Typography>*/}
      {/*        ),*/}
      {/*        checked: formValues.optDocs,*/}
      {/*        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {*/}
      {/*          setFieldValue(*/}
      {/*            `optDocs`,*/}
      {/*            e.target.checked*/}
      {/*          )*/}
      {/*        }*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Stack>*/}
      {/*</AgreementTitledWrapper>*/}
    </Stack>
  )
}
