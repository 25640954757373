import React, { ReactNode } from 'react'
import { Box, Pagination, Stack } from '@mui/material'
import { AdminLegendProps } from './AdminLegend.types'
import AddIcon from '@mui/icons-material/Add'
import Tabs from '../../../../components/Tabs'
import Button from '../../../../components/Button'
import FilterSelect from '../../../../components/FilterSelect'
import { theme } from '../../../../styles/theme'
import { useTranslation } from 'react-i18next'
import { TabsEntities } from '../../../../components/Tabs/Tabs.types'

const AdminLegend = <T extends TabsEntities>({
  currentTab,
  pagination = false,
  tabsData,
  onTabChange,
  onAddClick,
  page,
  countPagination,
  numberRows,
  onChangeLimit,
  onChangePage,
  limit
}: AdminLegendProps<T> & { children?: ReactNode }) => {
  const { t } = useTranslation('user')

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1 }}>
        <Tabs<T> currentTab={currentTab} onTabChange={onTabChange} tabsData={tabsData} />

        <Stack spacing={5} direction='row' justifyContent='center' alignItems='center'>
          {pagination
            ? (
              <>
                <FilterSelect
                  items={numberRows!}
                  value={limit!}
                  onChange={onChangeLimit!}
                  startIcon={t('header.rows')}
                />
                <Pagination
                  onChange={(e, v) => onChangePage!(v)}
                  count={countPagination}
                  shape='rounded'
                  page={page}
                  sx={{
                    '& .MuiPaginationItem-root': {
                      '&.Mui-selected': {
                        background: 'rgba(109, 154, 220, 0.2)',
                        color: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.primary.main}`,
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '20px !important',
                      },
                    },
                  }}
                />
                <Button icon onClick={onAddClick}><AddIcon /></Button>
              </>
            )
            : (
              <Button startIcon={<AddIcon />} onClick={onAddClick}>Добавить</Button>
            )
          }
        </Stack>
      </Stack>
    </Box>
  )
}

export default AdminLegend