import { RemarkStatus } from "../../../../api/remarks/types"

export type FilterValues = 'all' | RemarkStatus
export interface RemarkFiltersRuToEn extends Record<RemarkFilters, FilterValues> { }
export interface RemarkFiltersEnToRu extends Record<FilterValues, RemarkFilters> { }
export type RemarkFilters =
  'Все замечания'
  | 'Создано (не направлено)'
  | 'Направлено ответственному'
  | 'Принято в работу'
  | 'Отклонено ответственным'
  | 'Помечено как устраненное'
  | 'Закрыто'
  | 'Снято'

export const remarkFilters: Array<RemarkFilters> = [
  'Все замечания',
  'Создано (не направлено)',
  'Направлено ответственному',
  'Принято в работу',
  'Отклонено ответственным',
  'Помечено как устраненное',
  'Закрыто',
  'Снято'
]

export const remarkFilterValues: RemarkFiltersRuToEn = {
  'Все замечания': 'all',
  'Направлено ответственному': 'submitted',
  'Закрыто': 'closed',
  'Принято в работу': 'accepted',
  'Отклонено ответственным': 'rejected',
  'Снято': 'cancelled',
  'Создано (не направлено)': 'created',
  'Помечено как устраненное': 'resolved'
}

export const remarkFilterValuesRu: RemarkFiltersEnToRu = {
  'all': 'Все замечания',
  'submitted': 'Направлено ответственному',
  'closed': 'Закрыто',
  'accepted': 'Принято в работу',
  'rejected': 'Отклонено ответственным',
  'cancelled': 'Снято',
  'created': 'Создано (не направлено)',
  'resolved': 'Помечено как устраненное'
}