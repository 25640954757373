import { SvgIconProps } from "@mui/material"

const BoxIcon = ({ color }: Partial<SvgIconProps>) => {

  if (color === 'secondary') {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill='none'>
        <path d="M7.03329 1.91737L3.06329 4.12137C2.75336 4.29397 2.49535 4.54647 2.31608 4.8526C2.13682 5.15872 2.04285 5.50728 2.04395 5.86203V10.138C2.04297 10.4927 2.13699 10.8411 2.31625 11.1471C2.49551 11.4531 2.75346 11.7055 3.06329 11.878L7.03462 14.0827C7.33001 14.2472 7.66251 14.3336 8.00062 14.3336C8.33873 14.3336 8.67124 14.2472 8.96662 14.0827L12.938 11.8787C13.2479 11.7061 13.5059 11.4536 13.6852 11.1475C13.8644 10.8413 13.9584 10.4928 13.9573 10.138V5.86203C13.9583 5.5074 13.8643 5.15897 13.685 4.85297C13.5057 4.54698 13.2478 4.29459 12.938 4.12203L8.96662 1.91737C8.67124 1.75285 8.33873 1.6665 8.00062 1.6665C7.66251 1.6665 7.33001 1.75285 7.03462 1.91737H7.03329Z" stroke="#FFFFFF" stroke-width="1.5" />
        <path d="M13.5534 4.66406L8.00011 8.00006M8.00011 8.00006L2.44678 4.66406M8.00011 8.00006V14.3267" stroke="#FFFFFF" stroke-width="1.5" />
      </svg>
    )
  } if (color === 'disabled') {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill='none'>
        <path d="M7.03329 1.91737L3.06329 4.12137C2.75336 4.29397 2.49535 4.54647 2.31608 4.8526C2.13682 5.15872 2.04285 5.50728 2.04395 5.86203V10.138C2.04297 10.4927 2.13699 10.8411 2.31625 11.1471C2.49551 11.4531 2.75346 11.7055 3.06329 11.878L7.03462 14.0827C7.33001 14.2472 7.66251 14.3336 8.00062 14.3336C8.33873 14.3336 8.67124 14.2472 8.96662 14.0827L12.938 11.8787C13.2479 11.7061 13.5059 11.4536 13.6852 11.1475C13.8644 10.8413 13.9584 10.4928 13.9573 10.138V5.86203C13.9583 5.5074 13.8643 5.15897 13.685 4.85297C13.5057 4.54698 13.2478 4.29459 12.938 4.12203L8.96662 1.91737C8.67124 1.75285 8.33873 1.6665 8.00062 1.6665C7.66251 1.6665 7.33001 1.75285 7.03462 1.91737H7.03329Z" stroke="#9AA2B0" stroke-width="1.5" />
        <path d="M13.5534 4.66406L8.00011 8.00006M8.00011 8.00006L2.44678 4.66406M8.00011 8.00006V14.3267" stroke="#9AA2B0" stroke-width="1.5" />
      </svg>
    )
  } else {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill='none'>
        <path d="M7.03329 1.91737L3.06329 4.12137C2.75336 4.29397 2.49535 4.54647 2.31608 4.8526C2.13682 5.15872 2.04285 5.50728 2.04395 5.86203V10.138C2.04297 10.4927 2.13699 10.8411 2.31625 11.1471C2.49551 11.4531 2.75346 11.7055 3.06329 11.878L7.03462 14.0827C7.33001 14.2472 7.66251 14.3336 8.00062 14.3336C8.33873 14.3336 8.67124 14.2472 8.96662 14.0827L12.938 11.8787C13.2479 11.7061 13.5059 11.4536 13.6852 11.1475C13.8644 10.8413 13.9584 10.4928 13.9573 10.138V5.86203C13.9583 5.5074 13.8643 5.15897 13.685 4.85297C13.5057 4.54698 13.2478 4.29459 12.938 4.12203L8.96662 1.91737C8.67124 1.75285 8.33873 1.6665 8.00062 1.6665C7.66251 1.6665 7.33001 1.75285 7.03462 1.91737H7.03329Z" stroke="#0044B4" stroke-width="1.5" />
        <path d="M13.5534 4.66406L8.00011 8.00006M8.00011 8.00006L2.44678 4.66406M8.00011 8.00006V14.3267" stroke="#0044B4" stroke-width="1.5" />
      </svg>
    )
  }
}

export default BoxIcon