import React, { FC } from 'react'
import { ProjectAgreementCardProps } from './ProjectAgreementCard.types'
import {
  AgreementCardAddButton,
  AgreementCardAddButtonLeft,
  AgreementCardAvatarWrapper,
  AgreementCardDuration,
  AgreementCardFirstStage,
  AgreementCardStatusIcon,
  AgreementCardSubtitle,
  AgreementCardText,
  AgreementCardTitle,
  BoundStatusIcon,
  CARD_TOOLTIP_MINLENGTH,
  ColoredCircleIcon,
  ProjectAgreementCardFullWrapper,
  ProjectAgreementCardWrapper
} from './ProjectAgreementCard.styles'
import { Divider, Stack, Typography } from '@mui/material'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import AddIcon from '@mui/icons-material/Add'
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import { useAppDispatch, useTypedSelector } from '../../../../../store/store'
import {
  hoverCardIdSelector,
  isAddStageToExistSelector,
  isBeenHoveredSelector,
  setHoverCardId,
  setIsBeenHovered,
  stagesSelector
} from '../../../../../store/slices/agreement'
import { AgreementCardLineMode, ProjectAgreementCardLine } from '../ProjectAgreementCardLine'
import PersonSharpIcon from '@mui/icons-material/PersonSharp'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import Tooltip from '../../../../../components/Tooltip'
import { AlertIcon } from '../../../../../assets/icons/AlertIcon'
import { tomStatusesColors } from '../../../../../types/tom'
import BookFilledIcon from '../../../../../assets/icons/BookFilledIcon'

export const ProjectAgreementCard: FC<ProjectAgreementCardProps> = ({
  stage,
  onCardClick,
  onAddStageClick,
  isFirst,
}) => {
  const dispatch = useAppDispatch()

  const stages = useTypedSelector(stagesSelector)
  const isAddStageToExist = useTypedSelector(isAddStageToExistSelector)
  const hoverCardId = useTypedSelector(hoverCardIdSelector)
  const isBeenHovered = useTypedSelector(isBeenHoveredSelector)
  const { id, title, duration, person, results = [], isStart, isAlone, innermapId } = stage

  const isFinal = results[0].isFinal
  const isNextStageEmpty = !isFinal && !results[0]?.nextStage?.id

  const onCardMouseEnter = () => {
    dispatch(setHoverCardId({ hoverCardId: id }))

    if (!isBeenHovered) {
      dispatch(setIsBeenHovered({ isBeenHovered: true }))
    }
  }

  return (
    <ProjectAgreementCardFullWrapper>
      {isFirst && isAddStageToExist && (
        <AgreementCardAddButtonLeft onClick={() => onAddStageClick(null, id)}>
          <AddIcon fontSize='medium' />
        </AgreementCardAddButtonLeft>
      )}

      <ProjectAgreementCardWrapper
        onClick={() => onCardClick(id)}
        onMouseEnter={onCardMouseEnter}
        spacing={1}
        yellowBg={isNextStageEmpty}
        whiteBg={!isNextStageEmpty && isAlone}
      >
        <Tooltip title={title.length > CARD_TOOLTIP_MINLENGTH ? title : ''}>
          <AgreementCardTitle>
            {title}
          </AgreementCardTitle>
        </Tooltip>

        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
          <AgreementCardText>Срок прохождения:</AgreementCardText>

          <AgreementCardDuration>
            <WatchLaterIcon fontSize='small' />
            <Typography>{duration}</Typography>
          </AgreementCardDuration>
        </Stack>

        <AgreementCardSubtitle>Ответственный за этап:</AgreementCardSubtitle>
        <Divider />

        <Stack direction='row' alignItems='center' spacing={1.5}>
          <AgreementCardAvatarWrapper>
            {person?.avatar
              ? <img src={person.avatar} width={32} height={32} alt='Аватар' />
              : <PersonSharpIcon />
            }
          </AgreementCardAvatarWrapper>

          <Stack maxWidth={175} flex={1}>
            <Tooltip title={person?.company.length > CARD_TOOLTIP_MINLENGTH ? person.company : ''}>
              <AgreementCardText>{person?.company || '—'}</AgreementCardText>
            </Tooltip>
            <Tooltip title={person?.name.length > CARD_TOOLTIP_MINLENGTH ? person.name : ''}>
              <AgreementCardText>{person?.name || '—'}</AgreementCardText>
            </Tooltip>
          </Stack>
        </Stack>

        {results.map(result => {
          const isTitleExist = !!result.title
          const isNextStageExist = !!result.nextStage?.title
          const isFinal = !!result.isFinal
          const tomStatus = result.tomStatus

          return (
            <>
              <AgreementCardSubtitle>
                {result.type
                  ? 'Позитивный результат прохождения:'
                  : 'Негативный результат прохождения:'
                }
              </AgreementCardSubtitle>
              <Divider />

              <Stack spacing={1}>
                <Stack direction='row' alignItems='center' spacing={1} height={19}>
                  {result.type
                    ? <CheckCircleIcon fontSize='small' color={isTitleExist ? 'lightgreen' : 'disabled'} />
                    : <CancelIcon fontSize='small' color={isTitleExist ? 'error' : 'disabled'} />
                  }

                  <Tooltip title={result.title.length > CARD_TOOLTIP_MINLENGTH ? result.title : ''}>
                    <AgreementCardText disabled={!isTitleExist}>
                      {result.title || (
                        result.type
                          ? 'Нет положительного сценария'
                          : 'Нет отрицательного сценария'
                      )}
                    </AgreementCardText>
                  </Tooltip>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={1} height={19}>
                  {result.type
                    ? (
                      <ArrowRightAltIcon
                        fontSize='small'
                        color={(isFinal || isNextStageExist)
                          ? 'lightgreen'
                          : result.type === true && isNextStageEmpty
                            ? 'warning'
                            : 'disabled'
                        }
                      />
                    )
                    : <ArrowRightAltIcon fontSize='small' color={isNextStageExist ? 'error' : 'disabled'} />
                  }

                  <Tooltip title={result.nextStage && result.nextStage.title.length > CARD_TOOLTIP_MINLENGTH ? result.nextStage?.title : ''}>
                    <AgreementCardText
                      disabled={!(isFinal || isNextStageExist)}
                      isLightGreen={isFinal}
                      isWarning={result.type === true && isNextStageEmpty}
                    >
                      {isFinal
                        ? 'Завершение процедуры'
                        : isNextStageExist
                          ? result.nextStage?.title
                          : 'Нет следующего этапа'
                      }
                    </AgreementCardText>
                  </Tooltip>
                </Stack>
                {result.type === false && result.nextStage?.duration && (
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <AccessTimeIcon fontSize='small' color='error' />
                      <AgreementCardText>Срок прохождения:</AgreementCardText>
                    </Stack>

                    <AgreementCardDuration disabled>
                      <WatchLaterIcon fontSize='small' />
                      <Typography>{result.nextStage?.duration}</Typography>
                    </AgreementCardDuration>
                  </Stack>
                )}
                <Stack direction='row' alignItems='center' spacing={1}>
                  <BookFilledIcon color={result.type ? 'lightgreen' : 'error'} />
                  {tomStatus && <ColoredCircleIcon $color={tomStatusesColors[tomStatus]} />}
                  <AgreementCardText>{tomStatus || '—'}</AgreementCardText>
                </Stack>

              </Stack>
            </>
          )
        })}

        {/*{isNextStageEmpty && (*/}
        {/*  <AgreementCardEmptyNotification>*/}
        {/*    Необходимо заполнить следующий этап согласования!*/}
        {/*  </AgreementCardEmptyNotification>*/}
        {/*)}*/}

        {isStart && (
          <AgreementCardFirstStage>
            <SlowMotionVideoIcon />
          </AgreementCardFirstStage>
        )}

        {isNextStageEmpty && (
          <Tooltip title={<>Для этапа не заполнен результат< br />согласования</>}>
            <AgreementCardStatusIcon yellowBg>
              <AlertIcon fontSize='medium' />
            </AgreementCardStatusIcon>
          </Tooltip>

        )}

        {isAlone && !isNextStageEmpty && (
          <Tooltip title={<>Ни один результат согласования<br />не ведёт к наступлению этого этапа</>}>
            <AgreementCardStatusIcon whiteBg>
              <AlertIcon fontSize='medium' />
            </AgreementCardStatusIcon>
          </Tooltip>
        )}

        {!isAlone && !isNextStageEmpty && isFinal && (
          <AgreementCardStatusIcon lightgreenIcon>
            <WhereToVoteIcon fontSize='medium' color='lightgreen' />
          </AgreementCardStatusIcon>
        )}

        {innermapId && (
          <BoundStatusIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M20 12.1668C20 12.6668 19 13.0002 17.8333 13.1668C17.0833 11.7502 15.5833 10.6668 13.8333 9.91683C14 9.66683 14.1667 9.50016 14.3333 9.25016H15C17.5833 9.16683 20 10.7502 20 12.1668ZM5.66667 9.16683H5C2.41667 9.16683 0 10.7502 0 12.1668C0 12.6668 1 13.0002 2.16667 13.1668C2.91667 11.7502 4.41667 10.6668 6.16667 9.91683L5.66667 9.16683ZM10 10.0002C11.8333 10.0002 13.3333 8.50016 13.3333 6.66683C13.3333 4.8335 11.8333 3.3335 10 3.3335C8.16667 3.3335 6.66667 4.8335 6.66667 6.66683C6.66667 8.50016 8.16667 10.0002 10 10.0002ZM10 10.8335C6.58333 10.8335 3.33333 13.0002 3.33333 15.0002C3.33333 16.6668 10 16.6668 10 16.6668C10 16.6668 16.6667 16.6668 16.6667 15.0002C16.6667 13.0002 13.4167 10.8335 10 10.8335ZM14.75 8.3335H15C16.4167 8.3335 17.5 7.25016 17.5 5.8335C17.5 4.41683 16.4167 3.3335 15 3.3335C14.5833 3.3335 14.25 3.41683 13.9167 3.5835C14.5833 4.41683 15 5.50016 15 6.66683C15 7.25016 14.9167 7.8335 14.75 8.3335ZM5 8.3335H5.25C5.08333 7.8335 5 7.25016 5 6.66683C5 5.50016 5.41667 4.41683 6.08333 3.5835C5.75 3.41683 5.41667 3.3335 5 3.3335C3.58333 3.3335 2.5 4.41683 2.5 5.8335C2.5 7.25016 3.58333 8.3335 5 8.3335Z" fill="#0288D1" />
            </svg>
          </BoundStatusIcon>
        )}
      </ProjectAgreementCardWrapper>

      {isAddStageToExist && (
        <AgreementCardAddButton onClick={() => onAddStageClick(id)}>
          <AddIcon fontSize='medium' />
        </AgreementCardAddButton>
      )}

      {results.map(result => {
        const nextStageId = result.nextStage?.id

        const currentCardIndex = stages.findIndex(arrayStage => arrayStage.id === id)
        const nextStageCardIndex = stages.findIndex(arrayStage => arrayStage.id === nextStageId)
        const indexDifference = nextStageCardIndex - currentCardIndex

        const direction = indexDifference > 0 ? 'right' : 'left'
        const verticalStart = result.type ? 'top' : 'bottom'
        const fullCardsSkip = Math.abs(indexDifference) - 1
        const mode: AgreementCardLineMode = nextStageId
          ? 'full'
          : result.title
            ? 'short'
            : 'none'

        return (
          <ProjectAgreementCardLine
            lineDirection={direction}
            verticalStart={verticalStart}
            fullCardsSkip={fullCardsSkip}
            title={result.title || ''}
            duration={result.nextStage?.duration || 0}
            show={hoverCardId === id}
            mode={mode}
          />
        )
      })}
    </ProjectAgreementCardFullWrapper>
  )
}
