import { UseAttachmentsExportSubmitResponse } from './useAttachmentsExportSubmit.types'
import { useMutationHandlers } from '../../../../../../../../hooks/useMutationHandlers'
import {
  useSendCmnAttachmentFileListMutation,
  useSendIiAttachmentFileListMutation,
  useSendIrdAttachmentFileListMutation,
  useSendPdAttachmentFileListMutation,
  useSendRdAttachmentFileListMutation
} from '../../../../../../../../api/attachments'
import {
  SendAttachmentFileListRequest,
  SendAttachmentFileListResponse
} from '../../../../../../../../api/attachments/types'
import { AttachmentsExportFormData } from '../../AttachmentsExportDrawerForm.types'
import { useAppDispatch, useTypedSelector } from '../../../../../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../../../../../store/slices/documentsPages/projectInfo'
import { isCmnTomSelector, tomSelector } from '../../../../../../../../store/slices/documentsPages/tom'
import { onDrawerClose } from '../../../../../../../../store/slices/documentsPages/drawerInfo'

export const useAttachmentsExportSubmit = (): UseAttachmentsExportSubmitResponse => {
  const dispatch = useAppDispatch()

  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)

  // Rd
  const [sendRdAttachmentFileList, sendRdAttachmentFileListResponse] = useSendRdAttachmentFileListMutation()

  useMutationHandlers(
    sendRdAttachmentFileListResponse,
    (data: SendAttachmentFileListResponse) => {
      window.open(data.link)
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
  )


  // Pd
  const [sendPdAttachmentFileList, sendPdAttachmentFileListResponse] = useSendPdAttachmentFileListMutation()

  useMutationHandlers(
    sendPdAttachmentFileListResponse,
    (data: SendAttachmentFileListResponse) => {
      window.open(data.link)
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
  )


  // Ii
  const [sendIiAttachmentFileList, sendIiAttachmentFileListResponse] = useSendIiAttachmentFileListMutation()

  useMutationHandlers(
    sendIiAttachmentFileListResponse,
    (data: SendAttachmentFileListResponse) => {
      window.open(data.link)
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
  )


  // Ird
  const [sendIrdAttachmentFileList, sendIrdAttachmentFileListResponse] = useSendIrdAttachmentFileListMutation()

  useMutationHandlers(
    sendIrdAttachmentFileListResponse,
    (data: SendAttachmentFileListResponse) => {
      window.open(data.link)
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
  )


  // Cmn
  const [sendCmnAttachmentFileList, sendCmnAttachmentFileListResponse] = useSendCmnAttachmentFileListMutation()

  useMutationHandlers(
    sendCmnAttachmentFileListResponse,
    (data: SendAttachmentFileListResponse) => {
      window.open(data.link)
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
  )


  // Common
  const onSubmit = (values: AttachmentsExportFormData) => {
    const data: SendAttachmentFileListRequest = {
      tomId: isCmnTom ? project.id : tom?.id!,
      attachId: values.selectedAttachments.map(attachmentData => attachmentData.attachment.id),
    }

    if (isCmnTom) {
      sendCmnAttachmentFileList(data)
      return
    }

    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        sendRdAttachmentFileList(data)
        break
      case 'Проектная документация':
        sendPdAttachmentFileList(data)
        break
      case 'Инженерные изыскания':
        sendIiAttachmentFileList(data)
        break
      case 'Сбор исходных данных':
        sendIrdAttachmentFileList(data)
        break
    }
  }

  return {
    onSubmit,
  }
}
