import styled from 'styled-components'
import { Box, IconButton, Stack, Typography } from '@mui/material'

export const TitleWrapper = styled(Box)`
`

interface NumberBlockProps {
  bgColor: string;
}

export const NumberBlock = styled(Stack)<NumberBlockProps>`
  place-content: center;
  min-width: 34px;
  width: fit-content;
  height: 20px;
  padding-top: 1px;
  text-align: center;
  background-color: ${props => props.bgColor};
  border-radius: 4px;
  float: left;

  & .MuiTypography-root {
    font-size: 12px;
    line-height: 15.6px;
    letter-spacing: 0.1px;
    color: ${props => props.theme.palette.text.light} !important;
  }
`

export const NumberBlockAbsolute = styled(NumberBlock)`
  position: absolute;
`

export const TitleText = styled(Typography)`
  min-height: 22px;
  max-height: 44px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-indent: 45px;
`

interface CommentButtonProps {
  disabled?: boolean;
}

export const TitleButton = styled(IconButton)<CommentButtonProps>`
  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.primary.light};
    color: ${props => props?.disabled && props.theme.palette.bg.gray};
  }
`
