import styled from 'styled-components'
import { Stack } from '@mui/material'

export const StatusCellWrapper = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  padding-left: 7px;

  & .colored-title {
    justify-content: center;
    min-width: 90px;
  }

  & .MuiTypography-root {
    color: ${props => props.theme.palette.text.light} !important;
  }

  & .MuiIconButton-root {
    padding: 0;

    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.text.dark};
    }
  }
`
