import React, { useMemo } from 'react'

import { SelectItem } from '../../../../components/FilterSelectMulti/FilterSelectMultiple.types'
import {
  filterDataSelector,
  filterItemsSelector,
  updateFilterData,
} from '../../../../store/slices/documentsPages/filter'
import { selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { ProjectPhase } from '../../../../types/project'
import { UseMainFilterDataResponse } from './useMainFilterData.types'

export const useMainFilterData = (): UseMainFilterDataResponse => {
  const dispatch = useAppDispatch()

  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { filterData } = useTypedSelector(filterDataSelector)
  const { allObjectsItems, allPdsItems, allIisItems, allIrdsItems } = useTypedSelector(filterItemsSelector)

  const { selectedObjectsIds, selectedPdsIds, selectedIisIds, selectedIrdsIds } = filterData

  const mainFilterItems: Record<ProjectPhase, SelectItem<number>[]> = useMemo(
    () => ({
      'Рабочая документация': allObjectsItems,
      'Проектная документация': allPdsItems,
      'Инженерные изыскания': allIisItems,
      'Сбор исходных данных': allIrdsItems,
    }),
    [allIisItems, allIrdsItems, allObjectsItems, allPdsItems],
  )

  const mainFilterValue: Record<ProjectPhase, number[]> = useMemo(
    () => ({
      'Рабочая документация': selectedObjectsIds,
      'Проектная документация': selectedPdsIds,
      'Инженерные изыскания': selectedIisIds,
      'Сбор исходных данных': selectedIrdsIds,
    }),
    [selectedIisIds, selectedIrdsIds, selectedObjectsIds, selectedPdsIds],
  )

  const mainFilterOnChange: Record<ProjectPhase, (selectedIds: number[]) => void> = useMemo(
    () => ({
      'Рабочая документация': (selectedIds: number[]) => {
        dispatch(updateFilterData({ selectedIds, filterType: 'object' }))
      },
      'Проектная документация': (selectedIds: number[]) => {
        dispatch(updateFilterData({ selectedIds, filterType: 'pd' }))
      },
      'Инженерные изыскания': (selectedIds: number[]) => {
        dispatch(updateFilterData({ selectedIds, filterType: 'ii' }))
      },
      'Сбор исходных данных': (selectedIds: number[]) => {
        dispatch(updateFilterData({ selectedIds, filterType: 'ird' }))
      },
    }),
    [dispatch],
  )

  return {
    mainFilterItems: mainFilterItems[selectedProjectPhase],
    mainFilterValue: mainFilterValue[selectedProjectPhase],
    mainFilterOnChange: mainFilterOnChange[selectedProjectPhase],
  }
}
