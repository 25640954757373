import styled from 'styled-components'
import { AutocompleteField } from '../../../../../components/AutocompleteField/AutocompleteField'
import { Stack } from '@mui/material'

export const InternalAgreementAutocomplete = styled(AutocompleteField)<{$height?: string}>`
  max-width: 200px;

  & .MuiOutlinedInput-root {
    height: ${props => props.$height && props.$height};
    width: 170px;
    padding: 4.5px 12px;
  }

  & .MuiFormHelperText-root {
    display: none;
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    max-width: calc(100% - 50px);
    padding: 0;
    transform: ${props => props.$height === '30px'
      ? 'translate(12px, 5px) scale(1)'
      : 'translate(12px, 8px) scale(1)'};
  }

  & .MuiAutocomplete-inputRoot {

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.secondary.dark + '3A'};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }    
  }

  & .MuiAutocomplete-popper {
    width: 270px;
  }
`

export const InternalAgreementViewPerson = styled(Stack)`
  width: max-content;
  margin-left: 8px!important;
  color: ${props => props.theme.palette.text.dark};
`


