import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material"
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { TanglIconButton } from "./TanglButton.styles";

interface ExtVisibilityButtonsProps {
  isolate: () => void;
  showAll: () => void;
}

const TanglTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#000000cc',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000cc',
    fontWeight: 600,
    borderRadius: 0,
    marginTop: '20px'
  },
}))

export const ExtVisibilityButtons: React.FC<ExtVisibilityButtonsProps> = ({ isolate, showAll }) => {
  return (
    <>
    <TanglTooltip title={<>Изолировать элемент</>}>
      <TanglIconButton onClick={isolate} disableRipple sx={{borderRadius: 0}}>
        <VisibilityOffOutlinedIcon />
      </TanglIconButton>
    </TanglTooltip>
    <TanglTooltip title={<>Показать всё</>}>
      <TanglIconButton onClick={showAll} disableRipple sx={{borderRadius: 0}}>
        <VisibilityOutlinedIcon />
      </TanglIconButton>
    </TanglTooltip>
    </>
  )
}