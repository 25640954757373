import CloseIcon from '@mui/icons-material/Close'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import { Stack } from '@mui/material'
import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react'

import DownloadFileIcon from '../../../../assets/icons/DownloadFileIcon'
import InfoIcon from '../../../../assets/icons/InfoIcon'
import SpeakIcon from '../../../../assets/icons/SpeakIcon'
import Button from '../../../../components/Button'
import { TooltipToggleButton } from '../../../../components/TooltipToggleButton'
import useQuery from '../../../../hooks/useQuery'
import { DocViewMenuView } from '../../../../layouts/DocumentsLayout'
import { selectedMenuViewSelector, setSelectedMenuView } from '../../../../store/slices/documentsPages/menu'
import { defaultProjectInfoSelector } from '../../../../store/slices/documentsPages/projectInfo'
import {
  clearCurrentDocument,
  isCmnTomSelector,
  setCurrentChange,
  setCurrentDocument,
} from '../../../../store/slices/documentsPages/tom'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { ScrollableContainer } from '../../../../styles/global/ScrollableContainer'
import { DocViewNavBarContentAgreement } from '../DocViewNavBarContentAgreement'
import { DocViewNavBarContentAttachments } from '../DocViewNavBarContentAttachments'
import { DocViewNavBarContentChanges } from '../DocViewNavBarContentChanges'
import DocViewNavBarContentInfo from '../DocViewNavBarContentInfo'
import DocViewNavBarContentNav from '../DocViewNavBarContentNav'
import { DocViewNavBarContentVersions } from '../DocViewNavBarContentVersions'
import { ToggleButtonData } from './DocViewNavBarContent.types'
import { DocViewToggleButtonGroup } from './styles'

const DocViewNavBarContent: React.FC = () => {
  const dispatch = useAppDispatch()
  const queryHandler = useQuery()

  const initialMenuView: string | null = queryHandler.get('menuView')
  const initialPocketId: string | null = queryHandler.get('pocketId')

  const { userWithLessControls } = useTypedSelector(defaultProjectInfoSelector)
  const { selectedMenuView } = useTypedSelector(selectedMenuViewSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)

  const [navigationMenuOpened, setNavigationMenuOpened] = useState<boolean>(
    localStorage.getItem('docDetailedNavBarOpen') === 'true',
  )

  useEffect(() => {
    if (isCmnTom && selectedMenuView === 'info') {
      dispatch(setSelectedMenuView({ selectedMenuView: 'versions' }))
    }
  }, [isCmnTom])

  useLayoutEffect(() => {
    if (initialMenuView) {
      dispatch(setSelectedMenuView({ selectedMenuView: initialMenuView as DocViewMenuView }))
    }
  }, [initialMenuView])

  const onNavigationMenuToggle = () => {
    localStorage.setItem('docDetailedNavBarOpen', `${!navigationMenuOpened}`)
    setNavigationMenuOpened((prevState) => !prevState)
  }

  const onMenuItemToggle = (menuView: DocViewMenuView) => {
    if (menuView !== null) {
      dispatch(setSelectedMenuView({ selectedMenuView: menuView }))
    } else if (selectedMenuView === 'info') {
      dispatch(clearCurrentDocument())
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setSelectedMenuView({ selectedMenuView: 'info' }))
    }
  }, [])

  const toggleButtonsData: ToggleButtonData[] = [
    ...((isCmnTom
      ? []
      : [{ value: 'info', icon: <InfoIcon />, tooltipTitle: 'Информация о томе' }]) as ToggleButtonData[]),
    { value: 'versions', icon: <TaskOutlinedIcon />, tooltipTitle: 'Версии', disabled: userWithLessControls },
    { value: 'changes', icon: <DownloadFileIcon />, tooltipTitle: 'Изменения', disabled: userWithLessControls },
    {
      value: 'attachments',
      icon: <FolderOpenOutlinedIcon />,
      tooltipTitle: 'Редактируемые версии',
      disabled: userWithLessControls,
    },
    { value: 'remarks', icon: <SpeakIcon />, tooltipTitle: 'Согласование' },
  ]

  const navBarContentBySelectedMenu: Record<DocViewMenuView, ReactNode> = {
    info: <DocViewNavBarContentInfo />,
    versions: <DocViewNavBarContentVersions />,
    changes: <DocViewNavBarContentChanges />,
    attachments: <DocViewNavBarContentAttachments />,
    remarks: <DocViewNavBarContentAgreement initialPocketId={initialPocketId ? Number(initialPocketId) : null} />,
  }

  return (
    <>
      {
        /* !userWithLessControls && */ <Stack direction='row' alignItems='center' spacing={1.5} pr={2.5}>
          <Button icon onClick={() => onNavigationMenuToggle()}>
            {navigationMenuOpened ? <CloseIcon /> : <MenuIcon />}
          </Button>

          {/*<Button*/}
          {/*  onClick={() => setDocDrawerOpen(status === 'Согласовано' ? 'docChange' : 'version')}*/}
          {/*  paddingSize='small'*/}
          {/*  icon*/}
          {/*>*/}
          {/*  {status === 'Согласовано' ? <CustomEditIcon /> : <AddIcon />}*/}
          {/*</Button>*/}

          <DocViewToggleButtonGroup
            value={selectedMenuView}
            onChange={(e, newValue) => onMenuItemToggle(newValue)}
            exclusive
            fullWidth
          >
            {toggleButtonsData.map(({ value, icon, tooltipTitle, ...props }) => (
              <TooltipToggleButton value={value} TooltipProps={{ title: tooltipTitle }} {...props}>
                {icon}
              </TooltipToggleButton>
            ))}
          </DocViewToggleButtonGroup>
        </Stack>
      }

      {navigationMenuOpened ? (
        <ScrollableContainer>
          <DocViewNavBarContentNav />
        </ScrollableContainer>
      ) : (
        navBarContentBySelectedMenu[selectedMenuView]
      )}
    </>
  )
}

export default DocViewNavBarContent
