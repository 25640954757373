import { Button, ToggleButtonGroup, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Form } from 'formik'
import styled from 'styled-components'
import { InternalAgreementAutocomplete } from '../InternalAgreementPerson'


export const StyledFormTitle = styled(Typography)`
  font-size: 18px;
  text-align: start;
  padding-left: 20px;
`

export const StyledForm = styled(Form)<{$minWidth?: string}>`
  display: flex;
  flex-direction: column;
  min-width: ${props => props.$minWidth || '1130px'};
  padding: 20px;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid ${props => props.theme.palette.bg.gray};
`

export const StyledAutocomplete = styled(InternalAgreementAutocomplete)<{$width?: string}>`
  min-width: ${props => props.$width ? props.$width : '300px'};
  height: 30px;

  & .MuiOutlinedInput-root {
    height: 30px;
    width: inherit;
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(12px, 5px) scale(1);
  }

  & .MuiAutocomplete-inputRoot {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }
  }

  & .MuiFormHelperText-root {
    display: none;
  }
`

export const StyledLabel = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
`

export const StyledSearchIcon = styled(SearchIcon)`
  color: ${props => props.theme.palette.primaryBorderLight}
`

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  & .MuiToggleButtonGroup-grouped {
    height: 30px;
    border-radius: 100px!important;
    padding: 6px 6px 6px 10px;

    :hover {
       background-color: ${props => props.theme.palette.bg.gray + 'E6'};
      }

    &:not(:first-of-type) {
      //margin-left: 16px;
      border-left: 1px solid #0000001f;
    }

    &.Mui-selected:not(:first-of-type) {
      //margin-left: 16px!important;
    }

    &.Mui-selected {
      background-color: ${props => props.theme.palette.primary.light};

       :hover {
       background-color: ${props => props.theme.palette.primary.light + 'E6'};
      }
    }
  }
`

export const StyledSubmitButton = styled(Button)`
  background-color: ${props => props.theme.palette.table.green + '26'};
  color: ${props => props.theme.palette.success.dark};
  text-transform: none;
  padding: 5px 10px;
  height: 30px;
  margin-left: 12px;
  font-weight: 400;

  &:hover {
    background-color: ${props => props.theme.palette.table.green + '50'};
  }
`

export const StyledCancelButton = styled(Button)`
  background-color: ${props => props.theme.palette.bg.blue + '14'};
  color: ${props => props.theme.palette.primary.main};
  text-transform: none;
  padding: 5px 10px;
  height: 30px;
  margin-left: 12px;

  &:hover {
    background-color: ${props => props.theme.palette.bg.blue + '38'};
  }
`






