import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SidebarState } from './sidebar.types'
import { SetDetailedSidebarItemsPayload, SetSidebarItemsPayload } from './sidebar.payloads.types'


let initialState: SidebarState = {
  sidebarObjects: [],
  sidebarPds: [],
  sidebarIis: [],
  sidebarIrds: [],
  detailedSidebarObjects: [],
  detailedSidebarPds: [],
  detailedSidebarIis: [],
  detailedSidebarIrds: [],
}

export const sidebarSlice = createSlice({
  name: 'sidebarState',
  initialState,
  reducers: {
    setSidebarItems: (state, { payload }: PayloadAction<SetSidebarItemsPayload>) => {
      const { sidebarObjects, sidebarPds, sidebarIis, sidebarIrds } = payload

      state.sidebarObjects = sidebarObjects
      state.sidebarPds = sidebarPds
      state.sidebarIis = sidebarIis
      state.sidebarIrds = sidebarIrds
    },
    setDetailedSidebarItems: (state, { payload }: PayloadAction<SetDetailedSidebarItemsPayload>) => {
      const { detailedSidebarObjects, detailedSidebarPds, detailedSidebarIis, detailedSidebarIrds } = payload

      state.detailedSidebarObjects = detailedSidebarObjects
      state.detailedSidebarPds = detailedSidebarPds
      state.detailedSidebarIis = detailedSidebarIis
      state.detailedSidebarIrds = detailedSidebarIrds
    },
  },
})

export const { reducer: sidebarReducer } = sidebarSlice
export const { setSidebarItems, setDetailedSidebarItems } = sidebarSlice.actions
