import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

export const ShiftDownOutlinedIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M13.322 21.3967C13.1577 21.5861 12.9547 21.738 12.7266 21.842C12.4985 21.9461 12.2507 22 12 22C11.7493 22 11.5015 21.9461 11.2734 21.842C11.0453 21.738 10.8423 21.5861 10.678 21.3967L2.433 11.8927C1.449 10.7597 2.254 8.99567 3.754 8.99567H7V6.74967C7 5.78367 7.784 4.99967 8.75 4.99967H15.25C15.7141 4.99967 16.1593 5.18405 16.4874 5.51223C16.8156 5.84042 17 6.28554 17 6.74967V8.99567H20.245C21.745 8.99567 22.55 10.7597 21.567 11.8927L13.322 21.3967ZM11.811 20.4137C11.8345 20.4408 11.8635 20.4625 11.8961 20.4774C11.9287 20.4923 11.9642 20.5 12 20.5C12.0359 20.5 12.0713 20.4923 12.1039 20.4774C12.1365 20.4625 12.1655 20.4408 12.189 20.4137L20.434 10.9097C20.4655 10.8735 20.4859 10.8289 20.4927 10.7815C20.4996 10.734 20.4927 10.6855 20.4727 10.6418C20.4528 10.5982 20.4207 10.5612 20.3804 10.5353C20.34 10.5094 20.293 10.4956 20.245 10.4957H16.25C16.0511 10.4957 15.8603 10.4167 15.7197 10.276C15.579 10.1353 15.5 9.94458 15.5 9.74567V6.74967C15.5 6.68337 15.4737 6.61978 15.4268 6.57289C15.3799 6.52601 15.3163 6.49967 15.25 6.49967H8.75C8.6837 6.49967 8.62011 6.52601 8.57323 6.57289C8.52634 6.61978 8.5 6.68337 8.5 6.74967V9.74567C8.5 9.94458 8.42099 10.1353 8.28033 10.276C8.13968 10.4167 7.94892 10.4957 7.75 10.4957H3.754C3.70611 10.4958 3.65925 10.5097 3.61902 10.5357C3.57879 10.5617 3.54687 10.5987 3.52706 10.6423C3.50726 10.6859 3.5004 10.7343 3.5073 10.7817C3.51421 10.8291 3.53458 10.8735 3.566 10.9097L11.811 20.4137ZM7.75 3.49967C7.55109 3.49967 7.36033 3.42065 7.21967 3.28C7.07902 3.13935 7 2.94858 7 2.74967C7 2.55076 7.07902 2.35999 7.21967 2.21934C7.36033 2.07869 7.55109 1.99967 7.75 1.99967H16.25C16.4489 1.99967 16.6397 2.07869 16.7803 2.21934C16.921 2.35999 17 2.55076 17 2.74967C17 2.94858 16.921 3.13935 16.7803 3.28C16.6397 3.42065 16.4489 3.49967 16.25 3.49967H7.75Z'
      />
    </SvgIcon>
  )
}

export const ShiftDownContainedIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M13.322 21.3967C13.1577 21.5861 12.9547 21.738 12.7266 21.842C12.4985 21.9461 12.2507 22 12 22C11.7493 22 11.5015 21.9461 11.2734 21.842C11.0453 21.738 10.8423 21.5861 10.678 21.3967L2.433 11.8927C1.449 10.7597 2.254 8.99567 3.754 8.99567H7V6.74967C7 5.78367 7.784 4.99967 8.75 4.99967H15.25C15.7141 4.99967 16.1593 5.18405 16.4874 5.51223C16.8156 5.84042 17 6.28554 17 6.74967V8.99567H20.245C21.745 8.99567 22.55 10.7597 21.567 11.8927L13.322 21.3967ZM7.75 3.49967C7.55109 3.49967 7.36033 3.42065 7.21967 3.28C7.07902 3.13935 7 2.94858 7 2.74967C7 2.55076 7.07902 2.35999 7.21967 2.21934C7.36033 2.07869 7.55109 1.99967 7.75 1.99967H16.25C16.4489 1.99967 16.6397 2.07869 16.7803 2.21934C16.921 2.35999 17 2.55076 17 2.74967C17 2.94858 16.921 3.13935 16.7803 3.28C16.6397 3.42065 16.4489 3.49967 16.25 3.49967H7.75Z'
      />
    </SvgIcon>
  )
}