import React from 'react'
import { FormControl, Stack, Typography } from '@mui/material'
import { theme } from '../../../../styles/theme'
import { StyledSelectMenuItem } from './styles'
import { userRolesArray, userRolesRuToEn } from '../../../../types/user'
import { UserRoleSelectionProps } from './UserRoleSelection.types'
import FieldForm from '../../../FieldForm'
import { useFormikContext } from 'formik'
import { UserFormData } from '../../UserForm/UserForm.types'

const UserRoleSelection: React.FC<UserRoleSelectionProps> = () => {
  const { setFieldValue } = useFormikContext<UserFormData>()

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Typography variant='subtitle1' color={theme.palette.text.dark}>Роль пользователя:</Typography>

      <FormControl
        style={{ width: '100%', maxWidth: '276px' }}
        sx={{
          '& .MuiSelect-outlined': {
            padding: '10px 16px !important',
            fontSize: '16px !important',
            lineHeight: '23px !important',
            textAlign: 'left !important',
            color: `${theme.palette.text.dark} !important`
          },
         ' & .MuiOutlinedInput-notchedOutline': {
          border: '1px solid rgba(92, 110, 140, 0.3) !important',
          borderRadius: '6px !important'
        },
          '& .MuiSvgIcon-root': {
            right: '14px !important'
          }
        }}
      >
        <FieldForm
          version='select'
          name='role'
          onChange={(e) => {
            setFieldValue('role', e.target.value)
            setFieldValue('projects', [])
          }}
        >
          {userRolesArray.map(role => (
            <StyledSelectMenuItem value={userRolesRuToEn[role]} key={role}>{role}</StyledSelectMenuItem>
          ))}
        </FieldForm>
      </FormControl>
    </Stack>
  )
}

export default UserRoleSelection