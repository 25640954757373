import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useGetObjectsByProjectIdQuery } from '../../../../api/rdPhase'
import { useGetAllRdpQuery } from '../../../../api/rdPhase/rdp'
import ObjectsIcon from '../../../../assets/icons/ObjectsIcon'
import PersonWorkerIcon from '../../../../assets/icons/PersonWorkerIcon'
import RdIcon from '../../../../assets/icons/RdIcon'
import FieldForm from '../../../../components/FieldForm'
import FileUpload from '../../../../components/FileUpload'
import useFilteredEmployees from '../../../../hooks/useFilteredEmployees'
import { isExtraAccessArchitectorSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { profileSelector } from '../../../../store/slices/profile'
import { useTypedSelector } from '../../../../store/store'
import { timStatusArray } from '../../../../types/models'
import { uploadData } from '../DrawerVersionForm/DrawerVersionForm.utils'
import { ElipsisText } from '../ModelCard'
import { ModelFormData } from '../ModelDrawer'
import { LabelTextRequired, ModelAutocomplete, StyledSearchIcon } from './DrawerInfoForm.styles'
import { DrawerInfoFormProps } from './DrawerInfoForm.types'

export const DrawerInfoForm = ({ forUpdateData, open, loading }: DrawerInfoFormProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { role } = useTypedSelector(profileSelector)
  const isExtraAccessArchitector = useTypedSelector(isExtraAccessArchitectorSelector)
  const isExtraAccess = role === 'admin' || role === 'client' || isExtraAccessArchitector

  const { setFieldValue, values } = useFormikContext<ModelFormData>()

  const { data: objectsData } = useGetObjectsByProjectIdQuery({ id: projectId }, { skip: !open })
  const { data: objectsList } = objectsData || {}

  const { data: rdpData } = useGetAllRdpQuery({ id: projectId }, { skip: !open })
  const { data: rdpList } = rdpData || {}

  const skipQuery = !open
  const { architectorsAndAdmins, clientsAndAdmins } = useFilteredEmployees(skipQuery)

  const statusData = useMemo(() => {
    if (isExtraAccess) return timStatusArray
    return timStatusArray.filter((status) => status !== 'Согласовано')
  }, [isExtraAccess])

  return (
    <Stack spacing={2.5} pt={2.5}>
      <Stack spacing={1}>
        <LabelTextRequired variant='body2'>Шифр модели</LabelTextRequired>
        <FieldForm helperText='' version='doc' name='title' placeholder='Шифр модели' />
      </Stack>
      <Stack spacing={1}>
        <Typography variant='body2'>Полное наименование модели</Typography>
        <FieldForm version='doc' name='description' multiline minRows={2} placeholder='Полное наименование модели' />
      </Stack>

      {
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={1}>
            <ErrorOutlineIcon fontSize='medium' color='secondary' />
            <Typography variant='body2' component='span'>
              Статус:
            </Typography>
          </Stack>
          <ModelAutocomplete
            fieldName='status'
            placeholder='Выберите статус'
            disabled={forUpdateData !== null && forUpdateData.status === 'Согласовано' && role !== 'admin'}
            customRenderOption={(props, option) => (
              <Box component='li' {...props} key={option.key}>
                <Typography variant='body2'>{option.label}</Typography>
              </Box>
            )}
            data={statusData.map((type) => ({
              value: type,
              label: type,
              key: type,
            }))}
          />
        </Stack>
      }

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1}>
          <ObjectsIcon fontSize='medium' color='secondary' />
          <LabelTextRequired variant='body2'>Объект:</LabelTextRequired>
        </Stack>
        <ModelAutocomplete
          fieldName='objectId'
          placeholder='Выберите из списка'
          customRenderOption={(props, option) => (
            <Box component='li' {...props} key={option.key}>
              <Typography variant='body2'>{option.label}</Typography>
            </Box>
          )}
          data={
            objectsList?.map((object) => ({
              value: object.id,
              label: object.title,
              key: object.id,
            })) || []
          }
        />
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1}>
          <RdIcon fontSize='medium' color='secondary' />
          <Typography variant='body2' component='span'>
            Раздел РД:
          </Typography>
        </Stack>
        <ModelAutocomplete
          fieldName='rdpId'
          placeholder='Выберите из списка'
          customRenderOption={(props, option) => (
            <Box component='li' {...props} key={option.key}>
              <Typography variant='body2'>{option.label}</Typography>
            </Box>
          )}
          data={
            rdpList?.map((rdp) => ({
              value: rdp.id,
              label: rdp.mark,
              key: rdp.id,
            })) || []
          }
        />
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1}>
          <PersonWorkerIcon fontSize='medium' color='secondary' />
          <Typography variant='body2' component='span'>
            Проектировщик:
          </Typography>
        </Stack>
        <ModelAutocomplete
          fieldName='architectorData'
          placeholder='Выберите из списка'
          freeSolo
          customFreeSoloMessage={undefined}
          customRenderOption={(props, option) => (
            <Box component='li' {...props} key={option.key}>
              <Stack direction='row' alignItems='center' spacing={2} width='100%'>
                {option.key ? (
                  <ElipsisText variant='body2'>{option.label}</ElipsisText>
                ) : (
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <StyledSearchIcon fontSize='small' />
                    <Typography variant='h3'>
                      Не найдено! Для добавления проектировщика, введите в поле и нажмите на это сообщение для
                      сохранения
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
          )}
          data={
            architectorsAndAdmins.map(({ id, name }) => ({
              value: id,
              label: name,
              key: id,
            })) || []
          }
        />
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1}>
          <PersonWorkerIcon fontSize='medium' color='secondary' />
          <Typography variant='body2' component='span'>
            Заказчик:
          </Typography>
        </Stack>
        <ModelAutocomplete
          fieldName='clientData'
          placeholder='Выберите из списка'
          freeSolo
          customFreeSoloMessage={undefined}
          customRenderOption={(props, option) => (
            <Box component='li' {...props} key={option.key}>
              <Stack direction='row' alignItems='center' spacing={2}>
                {option.key ? (
                  <ElipsisText variant='body2'>{option.label}</ElipsisText>
                ) : (
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <StyledSearchIcon fontSize='small' />
                    <Typography variant='h3'>
                      Не найдено! Для добавления заказчика, введите в поле и нажмите на это сообщение для сохранения
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
          )}
          data={
            clientsAndAdmins.map(({ id, name }) => ({
              value: id,
              label: name,
              key: id,
            })) || []
          }
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant='body2' component='span'>
          Дата согласования
        </Typography>
        <Divider />
        <Stack direction='row' alignItems='center' justifyContent='space-between' pt={0.5}>
          <Stack direction='row' spacing={1}>
            <EventNoteIcon fontSize='medium' color='secondary' />
            <Typography variant='body2' component='span'>
              Плановая:
            </Typography>
          </Stack>
          <FieldForm
            version='date'
            name='plannedDate'
            helperText=''
            dataValue={values.plannedDate}
            inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
            onDataChange={(value: Date | null) => setFieldValue('plannedDate', value)}
            sx={{ maxWidth: 200 }}
          />
        </Stack>

        {values.status === 'Согласовано' && (
          <Stack direction='row' alignItems='center' justifyContent='space-between' pt={0.5}>
            <Stack direction='row' spacing={1}>
              <EventNoteIcon fontSize='medium' color='secondary' />
              <LabelTextRequired variant='body2'>Фактическая:</LabelTextRequired>
            </Stack>
            <FieldForm
              version='date'
              name='actualDate'
              helperText=''
              dataValue={values.actualDate}
              inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
              onDataChange={(value: Date | null) => setFieldValue('actualDate', value)}
              sx={{ maxWidth: 200 }}
            />
          </Stack>
        )}
      </Stack>
      {!forUpdateData && (
        <FileUpload
          height='225px'
          name='file'
          mode='single'
          uploadData={uploadData}
          loading={loading}
          responseData={undefined /* responseData */}
        />
      )}
    </Stack>
  )
}
