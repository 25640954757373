import { api } from '../../api'
import {
  CreatePdRequest,
  DeletePdRequest,
  EditPdRequest,
  GetAllPdRequest,
  GetAllPdResponse,
  GetPdByIdRequest,
  GetPdLinkExampleResponse,
  PdBaseResponse,
  UploadPdRequest,
  UploadPdResponse
} from './pdApi.types'

export const pdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPd: build.query<GetAllPdResponse, GetAllPdRequest>({
      query: ({ limit, offset, type }) => ({
        url: '/pd/list',
        params: { limit, offset, type },
        method: 'GET',
      }),
      providesTags: (result) =>
        result?.data?.length
          ? [
            ...result.data.map(({ id }) => ({ type: 'Pd' as const, id })),
            { type: 'Pd', id: 'LIST' },
            { type: 'Pd', id: 'PARTIAL-LIST' },
          ]
          : [
            { type: 'Pd', id: 'LIST' },
            { type: 'Pd', id: 'PARTIAL-LIST' },
          ],
    }),
    createPd: build.mutation<PdBaseResponse, CreatePdRequest>({
      query: (body) => ({
        url: `/pd/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Pd', id: 'LIST' }],
    }),
    uploadPd: build.mutation<UploadPdResponse, UploadPdRequest>({
      query: ({ file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/pd/upload`,
          method: 'POST',
          body: formData,
        }
      },
    }),
    exportPd: build.mutation<Blob, void>({
      query: () => ({
        url: `/pd/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    getPdById: build.query<PdBaseResponse, GetPdByIdRequest>({
      query: ({ id }) => ({
        url: `/pd/${id}/get`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => {
        return [{ type: 'Pd', id: arg.id }]
      },
    }),
    editPd: build.mutation<PdBaseResponse, EditPdRequest>({
      query: ({ id, ...body }) => ({
        url: `/pd/${id}/update`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            pdApi.util.invalidateTags(['Pd'])
          )

        } catch {
        }
      },
    }),
    deletePd: build.mutation<PdBaseResponse, DeletePdRequest>({
      query: ({ id }) => ({
        url: `/pd/${id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Pd', id: 'PARTIAL-LIST' }],
    }),
    getPdLinkExample: build.mutation<GetPdLinkExampleResponse, void>({
      query: () => ({
        url: `/pd/link-example`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllPdQuery,
  useCreatePdMutation,
  useUploadPdMutation,
  useExportPdMutation,
  useGetPdByIdQuery,
  useEditPdMutation,
  useDeletePdMutation,
  useGetPdLinkExampleMutation,
} = pdApi
