import { UseCheckExportSubmitErrorsProps, UseCheckExportSubmitErrorsResponse } from './useCheckExportSubmitErrors.types'

export const useCheckExportSubmitErrors = ({ errors }: UseCheckExportSubmitErrorsProps): UseCheckExportSubmitErrorsResponse => {
  const minTomsCountError = !!(
    errors.selectedRdToms
    || errors.selectedPdToms
    || errors.selectedIiToms
    || errors.selectedIrdToms
  )

  return {
    minTomsCountError,
  }
}
