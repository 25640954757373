import { Box, Stack, Typography } from "@mui/material"
import { useGetInnermapMembersQuery } from "../../../../../api/internalAgreement"
import { StyledCell, StyledTitle, TableHeaderRow } from "./BoundInternalSchema.styles"
import { BoundInternalSchemaProps } from "./BoundInternalSchema.types"
import { TableHeaders } from "../../../InternalAgreementSchemes/components/InternalAgreementTable"
import { MemberRow } from "../MemberRow"

const columns: TableHeaders[] = [
  {
    title: 'ФИО, должность',
    minwidth: '290px',
    padding: '8px 16px 8px 8px',
    textAlign: 'start'
  },
  {
    title: 'Утверждение',
    minwidth: '96px',
    padding: '8px'
  },
  {
    title: 'Визирование',
    minwidth: '96px',
    padding: '8px'
  },
  {
    title: 'Подписание',
    minwidth: '96px',
    padding: '8px'
  },
  {
    title: 'Замечания',
    minwidth: '96px',
    padding: '8px'
  },
]

export const BoundInternalSchema = ({ innermapId, projectId }: BoundInternalSchemaProps) => {

  const { data: innermapMembers } = useGetInnermapMembersQuery({ projectId, innermapId })
  const { data: innermapMembersData } = innermapMembers || {}

  return (
    <>
      <TableHeaderRow direction='row'>
        {columns.map((item, index) => (
          <Box key={index} padding={item.padding} minWidth={item.minwidth}>
            <StyledTitle variant="body2" textAlign={item.textAlign}>{item.title}</StyledTitle>
          </Box>
        ))}
      </TableHeaderRow>
      {innermapMembersData?.map((list) => (
        <>
          <Stack direction='row' spacing={1} key={list.department}>
            <StyledCell>
              <Typography variant='body2'>{list.department}</Typography>
            </StyledCell>
          </Stack>
          {list.members.map((member) => (
            <MemberRow
            minWidthNameBlock={columns[0].minwidth}
              key={member.id}
              member={member}
            />
          ))}
        </>
      ))}

    </>
  )
}