import React, { FC, useCallback } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import { theme } from '../../../../styles/theme'
import Button from '../../../../components/Button'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import {
  openedConfirmSelector,
  setOpenedConfirm,
  setOpenedDrawer,
} from '../../../../store/slices/documentsPages/drawerInfo'
import { IConfirmDialogProps } from './ConfirmDialog.types'

export const ConfirmDialog: FC<IConfirmDialogProps> = ({ body }) => {
  const dispatch = useAppDispatch()

  const { openedConfirm } = useTypedSelector(openedConfirmSelector)

  const onClose = useCallback((confirm: boolean) => {
    dispatch(setOpenedConfirm({ openedConfirm: false }))

    if (confirm) {
      dispatch(setOpenedDrawer({ openedDrawer: null }))
    }
  }, [])

  return (
    <Dialog
      open={openedConfirm}
      onClose={() => onClose(false)}
      PaperProps={{
        style: {
          width: 400,
          textAlign: 'center',
        },
      }}
    >
      <DialogTitle style={{ justifyContent: 'center', paddingTop: 30, paddingBottom: 12 }}>
        <Typography variant='subtitle1'>Подтвердить закрытие формы?</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant='body2' color={theme.palette.text.dark}>
            {body || 'Форма будет закрыта, а все введенные данные безвозвратно утеряны.'}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', paddingBottom: 30 }}>
        <Button onClick={() => onClose(true)} autoFocus color='success' fullWidth style={{ maxWidth: 165 }}>
          Да
        </Button>
        <Button onClick={() => onClose(false)} fullWidth style={{ maxWidth: 165 }}>
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  )
}
