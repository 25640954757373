import { api } from '../../api'
import { GetTomIiByIdRequest, GetTomsIiRequest, tomIiApi } from '../tom'
import {
  DeleteTomIiChangeRequest,
  DeleteTomIiChangeResponse,
  DownloadTomIiChangeRequest,
  GetTomIiChangesRequest,
  GetTomIiChangesResponse,
  TomIiChangeBaseResponse,
  UpdateTomIiChangeRequest,
  UploadTomIiChangeRequest,
} from './tomChangeApi.types'
import { setCurrentDocument } from '../../../store/slices/documentsPages/tom'

export const tomIiChangeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomIiChanges: build.query<GetTomIiChangesResponse, GetTomIiChangesRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom-ii/${id}/pdf/change/v2/list`,
        params,
        method: 'GET',
      }),
      providesTags: ['TomIi', 'TomIiDoc'],
    }),
    uploadTomIiChange: build.mutation<TomIiChangeBaseResponse, UploadTomIiChangeRequest>({
      query: ({ id, file, change }) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('change', JSON.stringify(change))

        return {
          url: `/project/tom-ii/${id}/pdf/change/v2/upload`,
          method: 'POST',
          body: formData,
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedChange } = await queryFulfilled
          const { doc } = uploadedChange

          // getTomsIi
          const getTomsIiKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomsIi'))
          const getTomsIiLastKey = getTomsIiKeys[getTomsIiKeys?.length - 1]

          dispatch(
            tomIiApi.util.updateQueryData(
              'getTomsIi',
              state.api.queries[getTomsIiLastKey]?.originalArgs as GetTomsIiRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex((tom) => tom.id === doc.tomId)
                const currentChangeMax = draft.data[changedTomIndex].changeMax

                if (doc.change > currentChangeMax) {
                  draft.data[changedTomIndex].changeMax = doc.change
                }
              },
            ),
          )

          // getTomIiById
          const getTomIiByIdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomIiById'))
          const getTomIiByIdLastKey = getTomIiByIdKeys[getTomIiByIdKeys?.length - 1]

          dispatch(
            tomIiApi.util.updateQueryData(
              'getTomIiById',
              state.api.queries[getTomIiByIdLastKey]?.originalArgs as GetTomIiByIdRequest,
              (draft) => {
                if (doc.change > draft.data.changeMax) {
                  draft.data.changeMax = doc.change
                }
              },
            ),
          )

          // getTomIiChanges
          const getTomIiChangesKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomIiChanges'))
          const getTomIiChangesLastKey = getTomIiChangesKeys[getTomIiChangesKeys?.length - 1]

          dispatch(
            tomIiChangeApi.util.updateQueryData(
              'getTomIiChanges',
              state.api.queries[getTomIiChangesLastKey]?.originalArgs as GetTomIiChangesRequest,
              (draft) => {
                const indexForPlace = draft.data.findIndex((change) => doc.change > change.doc.change)
                draft.data.splice(indexForPlace, 0, uploadedChange)
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: doc }))
        } catch { }
      },
    }),
    updateTomIiChange: build.mutation<TomIiChangeBaseResponse, UpdateTomIiChangeRequest>({
      query: ({ id, changeNum, change }) => ({
        url: `/project/tom-ii/${id}/pdf/change/v2/update`,
        method: 'POST',
        params: { change: changeNum },
        body: change,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: updatedChange } = await queryFulfilled
          const { doc } = updatedChange

          // getTomsIi
          const getTomsIiKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomsIi'))
          const getTomsIiLastKey = getTomsIiKeys[getTomsIiKeys?.length - 1]

          dispatch(
            tomIiApi.util.updateQueryData(
              'getTomsIi',
              state.api.queries[getTomsIiLastKey]?.originalArgs as GetTomsIiRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex((tom) => tom.id === doc.tomId)
                const currentChangeMax = draft.data[changedTomIndex].changeMax

                if (doc.change > currentChangeMax) {
                  draft.data[changedTomIndex].changeMax = doc.change
                }
              },
            ),
          )

          // getTomIiById
          const getTomIiByIdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomIiById'))
          const getTomIiByIdLastKey = getTomIiByIdKeys[getTomIiByIdKeys?.length - 1]

          dispatch(
            tomIiApi.util.updateQueryData(
              'getTomIiById',
              state.api.queries[getTomIiByIdLastKey]?.originalArgs as GetTomIiByIdRequest,
              (draft) => {
                if (doc.change > draft.data.changeMax) {
                  draft.data.changeMax = doc.change
                }
              },
            ),
          )

          // getTomIiChanges
          const getTomIiChangesKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomIiChanges'))
          const getTomIiChangesLastKey = getTomIiChangesKeys[getTomIiChangesKeys?.length - 1]

          dispatch(
            tomIiChangeApi.util.updateQueryData(
              'getTomIiChanges',
              state.api.queries[getTomIiChangesLastKey]?.originalArgs as GetTomIiChangesRequest,
              (draft) => {
                const currentChangeIndex = draft.data.findIndex((change) => doc.docId === change.doc.docId)
                draft.data.splice(currentChangeIndex, 1)

                const indexForPlace = draft.data.findIndex((change) => doc.change > change.doc.change)
                draft.data.splice(indexForPlace, 0, updatedChange)
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: doc }))
        } catch { }
      },
    }),
    deleteTomIiChange: build.mutation<DeleteTomIiChangeResponse, DeleteTomIiChangeRequest>({
      query: ({ id, change }) => ({
        url: `/project/tom-ii/${id}/pdf/change/delete`,
        params: { change },
        method: 'DELETE',
      }),
      invalidatesTags: ['TomIi', 'TomIiDoc'],
    }),
    downloadTomIiChange: build.mutation<Blob, DownloadTomIiChangeRequest>({
      query: ({ id, change }) => ({
        url: `/project/tom-ii/${id}/pdf/change/file`,
        method: 'POST',
        params: { change },
        responseHandler: (response: any) => response.blob(),
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomIiChangesQuery,
  useUploadTomIiChangeMutation,
  useUpdateTomIiChangeMutation,
  useDeleteTomIiChangeMutation,
  useDownloadTomIiChangeMutation,
} = tomIiChangeApi
