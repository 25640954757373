import { UseSelectAnnotationsProps } from "../CanvasAnnotations.types"
import { checkIsInsideSelectAnnotation, checkIsSelectAnnotation } from "./common"

export const useSelectAnnotations = ({
  canvasRef,
  annotations,
  scale,
  selectAnnotationId,
}: UseSelectAnnotationsProps): {
  onSelectMouseDown: (e: MouseEvent) => void
  onSelectMouseMove: (e: MouseEvent) => void
} => {

  const onSelectMouseDown = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!(canvas && e.buttons === 1)) return

    const rect = canvas.getBoundingClientRect()
    const x = (e.clientX - rect.left)
    const y = (e.clientY - rect.top)

    const selected = checkIsSelectAnnotation({
      annotations,
      canvasRef,
      clientX: x,
      clientY: y,
      scale,
      isCreateMode: false
    })

    const isInsideSelection = checkIsInsideSelectAnnotation({
      annotations,
      canvasRef,
      clientX: x,
      clientY: y,
      isCreateMode: false
    })

    if (isInsideSelection && isInsideSelection.type === 'text') {
      const annotationId = annotations[isInsideSelection.index].id
      if (annotationId) selectAnnotationId(annotationId)
    } else if (selected) {
      const annotationId = annotations[selected.index].id
      if (annotationId) selectAnnotationId(annotationId)
    }
  }

  const onSelectMouseMove = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!canvas) return

    const rect = canvas.getBoundingClientRect()
    const x = (e.clientX - rect.left)
    const y = (e.clientY - rect.top)
    const ctx = canvas.getContext('2d')

    if (ctx) {
      let isOnBorder = false
      isOnBorder = checkIsSelectAnnotation({
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        scale,
        isCreateMode: false
      }) === null ? false : true

      const isInsideSelection = checkIsInsideSelectAnnotation({
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        isCreateMode: false
      })

      canvas.style.cursor = isOnBorder || (isInsideSelection && isInsideSelection.type === 'text')
        ? 'pointer'
        : 'inherit'
    }
  }

  return { onSelectMouseDown, onSelectMouseMove }
}
