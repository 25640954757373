import { alpha, Box, Stack, Typography } from "@mui/material";
import styled from "styled-components";
import { LegendColors } from "../../../../types/global";

export const ElipsisText = styled(Typography)`
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`

export const StatusBox = styled(Box) <{ isExpired: boolean }>`
  padding: 3px 7px;
  color: ${props => props.isExpired
    ? props.theme.palette.error.main
    : props.theme.palette.secondary.dark};
  background-color: ${props => props.isExpired
    ? alpha(props.theme.palette.legends.red, 0.15)
    : props.theme.palette.bg.shades};
  border-radius: 4px;

  & .MuiTypography-root {
    color: inherit;
  }
`

export const PocketViewAgGridStatus = styled(Stack) <{ $bg: LegendColors }>`
  align-self: center;
  justify-content: center;
  width: 191px;
  height: 24px;
  padding: 4px 6px;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${props => props.theme.palette.text.light};
  background-color: ${props => props.theme.palette.legends[props.$bg]};
  border-radius: 4px;
`