import React from 'react'
import {
  HeaderStatisticsData,
  UseGetHeaderStatisticsProps,
  UseGetHeaderStatisticsResponse
} from './useGetHeaderStatistics.types'
import ObjectsIcon from '../../../../../../assets/icons/ObjectsIcon'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined'
import Tooltip from '../../../../../../components/Tooltip'
import { Stack, Typography } from '@mui/material'
import { theme } from '../../../../../../styles/theme'
import { InfoCardMediumVariant } from '../../InfoCard.types'
import BoxIcon from '../../../../../../assets/icons/BoxIcon'

export const useGetHeaderStatistics = ({
  variant,
  statData,
}: UseGetHeaderStatisticsProps): UseGetHeaderStatisticsResponse => {
  if (!variant) return { MediumTemplateHeaderStatistics: <></> }

  // Rd
  const rdHeaderStatistics: HeaderStatisticsData = {
    firstItem: {
      tooltipTitle: 'Объекты',
      value: statData?.firstItem || 0,
      Icon: ObjectsIcon,
    },
    secondItem: {
      tooltipTitle: 'Тома РД',
      value: statData?.secondItem || 0,
      Icon: InsertDriveFileOutlinedIcon,
    },
  }


  // Pd
  const pdHeaderStatistics: HeaderStatisticsData = {
    firstItem: {
      tooltipTitle: 'Разделы ПД',
      value: statData?.firstItem || 0,
      Icon: GradingOutlinedIcon,
    },
    secondItem: {
      tooltipTitle: 'Тома ПД',
      value: statData?.secondItem || 0,
      Icon: InsertDriveFileOutlinedIcon,
    },
  }


  // Ii
  const iiHeaderStatistics: HeaderStatisticsData = {
    firstItem: {
      tooltipTitle: 'Разделы изысканий',
      value: statData?.firstItem || 0,
      Icon: GradingOutlinedIcon,
    },
    secondItem: {
      tooltipTitle: 'Тома изысканий',
      value: statData?.secondItem || 0,
      Icon: InsertDriveFileOutlinedIcon,
    },
  }


  // Ird
  const irdHeaderStatistics: HeaderStatisticsData = {
    firstItem: {
      tooltipTitle: 'Разделы ИРД',
      value: statData?.firstItem || 0,
      Icon: GradingOutlinedIcon,
    },
    secondItem: {
      tooltipTitle: 'Тома ИРД',
      value: statData?.secondItem || 0,
      Icon: InsertDriveFileOutlinedIcon,
    },
  }

  // BIM
  const timHeaderStatistics: HeaderStatisticsData = {
    firstItem: {
      tooltipTitle: 'Объекты',
      value: statData?.firstItem || 0,
      Icon: ObjectsIcon,
    },
    secondItem: {
      tooltipTitle: 'Модели',
      value: statData?.secondItem || 0,
      Icon: BoxIcon,
    },
  }


  // Common
  const headerStatistics: Record<InfoCardMediumVariant, HeaderStatisticsData> = {
    'rd': rdHeaderStatistics,
    'pd': pdHeaderStatistics,
    'ii': iiHeaderStatistics,
    'ird': irdHeaderStatistics,
    'tim': timHeaderStatistics,
  }
  const { firstItem, secondItem } = headerStatistics[variant]

  const MediumTemplateHeaderStatistics = (
    <Stack spacing={0.25} borderLeft={`1px solid ${theme.palette.bg.gray}`} pl={1}>
      <Tooltip title={firstItem.tooltipTitle}>
        <Stack direction='row' alignItems='center' spacing={0.25}>
          <firstItem.Icon fontSize='small' color='primary' />
          <Typography variant='body2' color={theme.palette.primary.main} pt='3px'>{firstItem.value}</Typography>
        </Stack>
      </Tooltip>

      <Tooltip title={secondItem.tooltipTitle}>
        <Stack direction='row' alignItems='center' spacing={0.25}>
          <secondItem.Icon fontSize='small' color='primary' />
          <Typography variant='body2' color={theme.palette.primary.main} pt='3px'>{secondItem.value}</Typography>
        </Stack>
      </Tooltip>
    </Stack>
  )

  return {
    MediumTemplateHeaderStatistics,
  }
}
