import { SvgIconProps } from "@mui/material"

export const StructureIcon = ({ color }: Partial<SvgIconProps>) => {

  if (color === 'secondary') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C8 3.44772 7.55228 3 7 3H3C2.44772 3 2 3.44772 2 4V8C2 8.55228 2.44772 9 3 9H4.25V16.7007C3.80165 16.96 3.5 17.4448 3.5 18C3.5 18.8284 4.17157 19.5 5 19.5C5.55521 19.5 6.03997 19.1984 6.29933 18.75H9V20C9 20.5523 9.44772 21 10 21H14C14.5523 21 15 20.5523 15 20V18.75H17.7007C17.96 19.1984 18.4448 19.5 19 19.5C19.8284 19.5 20.5 18.8284 20.5 18C20.5 17.4448 20.1984 16.96 19.75 16.7007V9H21C21.5523 9 22 8.55228 22 8V4C22 3.44772 21.5523 3 21 3H17C16.4477 3 16 3.44772 16 4V5.25H13.2993C13.04 4.80165 12.5552 4.5 12 4.5C11.4448 4.5 10.96 4.80165 10.7007 5.25H8V4ZM18.25 16.7007V9H17C16.4477 9 16 8.55228 16 8V6.75H13.2993C13.1676 6.97772 12.9777 7.1676 12.75 7.29933V15H14C14.5523 15 15 15.4477 15 16V17.25H17.7007C17.8324 17.0223 18.0223 16.8324 18.25 16.7007ZM5.75 16.7007C5.97772 16.8324 6.1676 17.0223 6.29933 17.25H9V16C9 15.4477 9.44772 15 10 15H11.25V7.29933C11.0223 7.1676 10.8324 6.97772 10.7007 6.75H8V8C8 8.55228 7.55228 9 7 9H5.75V16.7007Z" fill="white" />
      </svg>
    )
  } 
  if (color === 'disabled') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C8 3.44772 7.55228 3 7 3H3C2.44772 3 2 3.44772 2 4V8C2 8.55228 2.44772 9 3 9H4.25V16.7007C3.80165 16.96 3.5 17.4448 3.5 18C3.5 18.8284 4.17157 19.5 5 19.5C5.55521 19.5 6.03997 19.1984 6.29933 18.75H9V20C9 20.5523 9.44772 21 10 21H14C14.5523 21 15 20.5523 15 20V18.75H17.7007C17.96 19.1984 18.4448 19.5 19 19.5C19.8284 19.5 20.5 18.8284 20.5 18C20.5 17.4448 20.1984 16.96 19.75 16.7007V9H21C21.5523 9 22 8.55228 22 8V4C22 3.44772 21.5523 3 21 3H17C16.4477 3 16 3.44772 16 4V5.25H13.2993C13.04 4.80165 12.5552 4.5 12 4.5C11.4448 4.5 10.96 4.80165 10.7007 5.25H8V4ZM18.25 16.7007V9H17C16.4477 9 16 8.55228 16 8V6.75H13.2993C13.1676 6.97772 12.9777 7.1676 12.75 7.29933V15H14C14.5523 15 15 15.4477 15 16V17.25H17.7007C17.8324 17.0223 18.0223 16.8324 18.25 16.7007ZM5.75 16.7007C5.97772 16.8324 6.1676 17.0223 6.29933 17.25H9V16C9 15.4477 9.44772 15 10 15H11.25V7.29933C11.0223 7.1676 10.8324 6.97772 10.7007 6.75H8V8C8 8.55228 7.55228 9 7 9H5.75V16.7007Z" fill="#9AA2B0" />
      </svg>
    )
  } else return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C8 3.44772 7.55228 3 7 3H3C2.44772 3 2 3.44772 2 4V8C2 8.55228 2.44772 9 3 9H4.25V16.7007C3.80165 16.96 3.5 17.4448 3.5 18C3.5 18.8284 4.17157 19.5 5 19.5C5.55521 19.5 6.03997 19.1984 6.29933 18.75H9V20C9 20.5523 9.44772 21 10 21H14C14.5523 21 15 20.5523 15 20V18.75H17.7007C17.96 19.1984 18.4448 19.5 19 19.5C19.8284 19.5 20.5 18.8284 20.5 18C20.5 17.4448 20.1984 16.96 19.75 16.7007V9H21C21.5523 9 22 8.55228 22 8V4C22 3.44772 21.5523 3 21 3H17C16.4477 3 16 3.44772 16 4V5.25H13.2993C13.04 4.80165 12.5552 4.5 12 4.5C11.4448 4.5 10.96 4.80165 10.7007 5.25H8V4ZM18.25 16.7007V9H17C16.4477 9 16 8.55228 16 8V6.75H13.2993C13.1676 6.97772 12.9777 7.1676 12.75 7.29933V15H14C14.5523 15 15 15.4477 15 16V17.25H17.7007C17.8324 17.0223 18.0223 16.8324 18.25 16.7007ZM5.75 16.7007C5.97772 16.8324 6.1676 17.0223 6.29933 17.25H9V16C9 15.4477 9.44772 15 10 15H11.25V7.29933C11.0223 7.1676 10.8324 6.97772 10.7007 6.75H8V8C8 8.55228 7.55228 9 7 9H5.75V16.7007Z" fill="#0044B4" />
    </svg>
  )
}

