import { ProjectPhase } from '../../types/project'
import { CurrentPages } from './DocumentsLayout'

export const docsConfigByPhase: Record<ProjectPhase, DocumentsLayoutConfigItem> = {
  'Рабочая документация': {
    objects: {
      breadcrumbTitle: 'Объекты',
    },
    docs: {
      breadcrumbTitle: 'Рабочая документация',
      exportArchiveTitle: 'Экспорт архива рабочей документации',
      mainFilterDefaultValue: 'Объекты',
      mainFilterAllSelectedText: 'Все объекты',
      subFilter: true,
      navLinkText: 'Объекты',
      showSendNotificationsButton: true,
    },
    'doc-view': {
      breadcrumbTitle: 'Рабочая документация',
    },
  },
  'Проектная документация': {
    objects: {
      breadcrumbTitle: 'Разделы ПД',
    },
    docs: {
      breadcrumbTitle: 'Проектная документация',
      exportArchiveTitle: 'Экспорт архива проектной документации',
      mainFilterDefaultValue: 'Разделы ПД',
      mainFilterAllSelectedText: 'Все разделы ПД',
      subFilter: false,
      navLinkText: 'Разделы ПД',
      showSendNotificationsButton: false,
    },
    'doc-view': {
      breadcrumbTitle: 'Проектная документация',
    },
  },
  'Инженерные изыскания': {
    objects: {
      breadcrumbTitle: 'Разделы изысканий',
    },
    docs: {
      breadcrumbTitle: 'Инженерные изыскания',
      exportArchiveTitle: 'Экспорт архива инженерных изысканий',
      mainFilterDefaultValue: 'Разделы изысканий',
      mainFilterAllSelectedText: 'Все разделы изысканий',
      subFilter: false,
      navLinkText: 'Разделы изысканий',
      showSendNotificationsButton: false,
    },
    'doc-view': {
      breadcrumbTitle: 'Инженерные изыскания',
    },
  },
  'Сбор исходных данных': {
    objects: {
      breadcrumbTitle: 'Разделы ИРД',
    },
    docs: {
      breadcrumbTitle: 'Исходно-разрешительная документация',
      exportArchiveTitle: 'Экспорт архива ИРД',
      mainFilterDefaultValue: 'Разделы ИРД',
      mainFilterAllSelectedText: 'Все разделы ИРД',
      subFilter: false,
      navLinkText: 'Разделы ИРД',
      showSendNotificationsButton: false,
    },
    'doc-view': {
      breadcrumbTitle: 'Исходно-разрешительная документация',
    },
  },
}

export type DocumentsLayoutConfigItem = Record<NonNullable<CurrentPages>, PageConfigItem>

export type PageConfigItem = {
  breadcrumbTitle: string
  mainFilterDefaultValue?: string
  mainFilterAllSelectedText?: string
  subFilter?: boolean
  navLinkText?: string
  showSendNotificationsButton?: boolean
  exportArchiveTitle?: string
}
