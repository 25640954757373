import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { api } from '../api/api'

import { setupListeners } from '@reduxjs/toolkit/query'
import { authReducer } from './slices/auth'
import { profileReducer } from './slices/profile'
import { projectInfoReducer } from './slices/documentsPages/projectInfo'
import { tomReducer } from './slices/documentsPages/tom'
import { drawerInfoReducer } from './slices/documentsPages/drawerInfo'
import { menuReducer } from './slices/documentsPages/menu'
import { sidebarReducer } from './slices/documentsPages/sidebar'
import { filterReducer } from './slices/documentsPages/filter'
import { uiReducer } from './slices/ui'
import { agreementReducer } from './slices/agreement'
import { objectsReducer } from './slices/documentsPages/objects'
import { internalAgreementReducer } from './slices/internalAgreement'
import { remarksReducer } from './slices/remarks/remarks'
import { timReducer } from './slices/tim/tim'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    profile: profileReducer,
    ui: uiReducer,
    projectInfo: projectInfoReducer,
    tom: tomReducer,
    drawerInfo: drawerInfoReducer,
    menu: menuReducer,
    sidebar: sidebarReducer,
    filter: filterReducer,
    agreement: agreementReducer,
    internalAgreement: internalAgreementReducer,
    objects: objectsReducer,
    remarks: remarksReducer,
    tim: timReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
