import React, { useCallback, useState } from 'react'

import { DocsUploadDrawerProps } from '.'
import UploadDrawer from '../../../../components/UploadDrawer'
import { ExcelUploadResponse } from '../../../../types/global'
import { useDocsSubmitDocsUploadDrawer } from '../../hooks/useDocsSubmitDocsUploadDrawer'
import { useExampleDownload } from '../../hooks/useExampleDownload'

export const DocsUploadDrawer: React.FC<DocsUploadDrawerProps> = ({ open }) => {
  const [files, setFiles] = useState<File[] | null>(null)
  const [responseData, setResponseData] = useState<ExcelUploadResponse | undefined>(undefined)

  const { onDrawerClose, isTomLoading } = useDocsSubmitDocsUploadDrawer({
    files,
    setFiles,
    responseData,
    setResponseData,
  })

  const onFormSubmit = useCallback((files: File[]) => {
    setFiles(files)
  }, [])

  const onFormChange = useCallback(() => {
    setResponseData(undefined)
  }, [])

  const { onExampleDownload } = useExampleDownload({})

  return (
    <UploadDrawer
      open={open}
      mode='single'
      onSubmit={onFormSubmit}
      responseData={responseData}
      onChange={onFormChange}
      onClose={onDrawerClose}
      title='Загрузка томов'
      text='Вы можете добавить тома с помощью exсel документа с уже подготовленными данными.'
      attentionText='В случае если у Вас будут совпадения названий существующих томов с новыми из файла, новые тома заменят существующие.
                    Это действие нельзя отменить.'
      uploadData={{
        title: 'Загрузить xls(х)',
        text: 'Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
        formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
      }}
      loading={isTomLoading}
      getExampleLink={onExampleDownload}
    />
  )
}