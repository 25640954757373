import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const DirectorIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.9131 7.00001C15.9131 9.20916 14.1352 11 11.9421 11C9.74905 11 7.97121 9.20916 7.97121 7.00001C7.97121 4.79087 9.74905 3 11.9421 3C14.1352 3 15.9131 4.79087 15.9131 7.00001ZM15.9078 13.5V13.39C18.2854 14.055 19.8837 15.295 19.8837 16.75V21H4V16.725C4 15.295 5.59333 14.055 7.97093 13.39V13.5C7.97093 16.515 9.053 19.06 10.5669 20.06L11.2668 14.33C11.0229 14.205 10.8179 14.0148 10.6743 13.7802C10.5307 13.5456 10.454 13.2756 10.4528 13C10.4503 12.9817 10.4503 12.9632 10.4528 12.945C10.9491 12.9 11.4455 12.875 11.9419 12.875C12.4382 12.875 12.9346 12.9 13.4309 12.945C13.4334 12.9632 13.4334 12.9817 13.4309 13C13.4297 13.2756 13.353 13.5456 13.2094 13.7802C13.0658 14.0148 12.8608 14.205 12.6169 14.33L13.3118 20.06C14.8257 19.075 15.9078 16.5 15.9078 13.5Z'
      />
    </SvgIcon>
  )
}

export default DirectorIcon