import { List, ListItem, Stack } from "@mui/material"
import { StyledFootnote, StyledHeader, StyledListItemText, TitleWrapper } from "./InternalAgreementTitle.styles"
import { ReactComponent as EmptyImage } from '../../../../../assets/empty-internal-agreement.svg'
import { listItem } from "./InternalAgreementTitle.utils"

export const InternalAgreementTitle = () => {
  return (
    <TitleWrapper direction='row'>
      <EmptyImage width={400} height={400} />
      <Stack>
        <StyledHeader>Внутренняя процедура согласования:</StyledHeader>
        {listItem.map((item, index) =>
          <List key={index} disablePadding sx={{ marginBottom: '20px' }}>
            <ListItem disablePadding>
              <StyledListItemText
                primary={item.primary}
                secondary={item.secondary}
              />
            </ListItem>
          </List>
        )}
        <StyledFootnote>* Функционал появится в будущем</StyledFootnote>
      </Stack>
    </TitleWrapper>
  )
}