import styled from 'styled-components'

export const StyledAgGridWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ag-theme-alpine {
    width: 100%;
    height: 100%;
    --ag-selected-row-border-color: none;
    --ag-range-selection-border-color: transparent;
    --ag-border-color: rgba(0, 36, 95, 0.1);
    --ag-header-cell-moving-background-color: ${({ theme }) => theme.palette.secondary.dark};
    --ag-value-change-value-highlight-background-color: ${({ theme }) => theme.palette.bg.lightBlue};
  }

  .ag-sort-indicator-container {
    .ag-icon {
      color: #fff;
      position: relative;
      top: 1px;
      font-size: 16px;
    }
  }

  .ag-root-wrapper {
    border: none;
    border-radius: 8px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: inherit;
  }

  .ag-header {
    border-bottom: none;

    .current-period {
      background: ${props => props.theme.palette.secondary.dark};
    }
  }

  .ag-cell-wrapper {
    width: inherit;
    padding: 8px 16px;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding: 0;
    white-space: pre-line;
  }

  & .ag-header-container,
  .ag-pinned-left-header,
  .ag-header-viewport {
    background: ${props => props.theme.palette.secondary.gray};
  }

  .ag-pinned-left-header {
    border-right: 0;
  }

  .ag-header-cell-label,
  .ag-header-group-cell,
  .ag-header-cell-comp-wrapper {
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: rgba(0, 36, 95, 0.1);
    }
  }

  .ag-header-group-cell-with-group {
    box-shadow: inset -1px 0 #00245f1a;
  }

  .ag-header-cell-comp-wrapper {
    &:hover {
       background: #6E86AB;
    }
  }

  .ag-header-cell-text,
  .ag-header-group-text {
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.1px;
  }

  .ag-header-cell_align-bottom {
    .ag-header-cell-label {
      align-self: end;
      padding-bottom: 6px;
    }
  }

  .ag-header-row:not(:first-child),
  .ag-header-cell:not(.ag-header-span-height.ag-header-span-total) {
    border-top: none;
  }

  .ag-header-cell_type_current-period {
    box-shadow: rgba(0, 26, 77, 0.08) 0 0 250px inset;
  }

  .ag-row {
    white-space: unset;
  }

  .ag-row_type-disabled {
    & .ag-cell {
      color: ${({ theme }) => theme.palette.disabled};
    }
  }

  .ag-row_type-total {
     background: ${({ theme }) => theme.palette.bg.shades};
  }

  .ag-row_type-group {
    .ag-cell {
      font-weight: 500;
      color: ${({ theme }) => theme.palette.primary.main};
      background: ${({ theme }) => theme.palette.bg.shades};
      box-shadow: none;
    }
  }

  .ag-row:hover {
    cursor: pointer;
     background: #F5F5F5;
  }

  .ag-cell {
    height: 100%;
    padding: 0;
    white-space: unset;
    color: ${props => props.theme.palette.text.dark};
    border-width: 0;
    box-shadow: inset -1px 0 rgba(0, 36, 95, 0.1);

    &.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
      border-right: none !important;
    }

    &.ag-cell_padding_0 {
      padding: 0;
    }

    &.ag-cell_box-shadow_disabled {
      box-shadow: none;
    }
  }

  .ag-cell_horizontal-align_center {
    text-align: center;
  }

  .ag-cell_flex {
    display: flex;
  }

  .ag-cell_flex_column {
    display: flex;
    flex-direction: column;
  }

  .ag-cell_align-items_start {
    align-items: flex-start;
  }

  .ag-cell_align-items_center {
    align-items: center;
  }

  .ag-cell_justify-content_start {
    justify-content: start;
  }

  .ag-cell_justify-space-between {
    justify-content: space-between;
  }
  
  .ag-cell_justify-content_center {
    justify-content: center;
  }

  .ag-cell_justify-content_end {
    justify-content: end;
  }

  .ag-cell_horizontal-align_end {
    text-align: end;
  }

  .ag-cell-focus {
    border: none !important;
  }

  .ag-cell_type_current-period {
    box-shadow: #e5e9ef 0px -1px 0px inset, #e5e9ef -1px 0px 0px inset,
      #001a4d14 0px 0px 250px inset;
  }

  //
  & .statusCell {
    padding: 0 16px !important;
    white-space: nowrap;

    & .MuiBox-root {
      justify-content: center;
    }
  }
  
  & .dateCell-wrapper {
    display: flex;
    height: 24px;
  }

  & .commonDocsRow .ag-cell {
    color: ${props => props.theme.palette.warning.main};
  }

  & .emptyDocRow .ag-cell {
    color: ${props => props.theme.palette.disabled};
  }

  & .ag-cell.largeXPaddingHeader {
    padding: 0 32px;
  }

  & .ag-cell.largeXPaddingCell {
    padding: 0 32px;
  }

  .ag-cell_horizontal-align_start {
    text-align: start;
  }

  .ag-cell_defaultPadding {
    padding: 8px 16px;
  }

  .font-size_14 {
    font-size: 14px;
  }

  .font-size_12 {
    font-size: 12px;
  }

  .ag-cell_auto-height {
    height: auto
  }

  .ag-cell_overflow_visible {
    overflow: visible;
  }

  .ag-row_style_shades {
    background-color: ${props => props.theme.palette.bg.shades};
  }

  .scroll_shades {
    .ag-body-vertical-scroll-viewport {
      background-color: ${props => props.theme.palette.bg.shades};
    }
  }

  
`