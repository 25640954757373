import UserManagement from '../../../../components/UserManagement'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetUserByIdQuery } from '../../../../api/users'
import Progress from '../../../../components/Progress'
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs'

const EditUser: React.FC = () => {
  const { userId } = useParams()

  useBreadcrumbs([
    { title: 'Управление пользователем' }
  ])

  const {
    data: profile,
    error: profileError,
    isLoading: profileIsLoading,
    isFetching: profileIsFetching,
  } = useGetUserByIdQuery({ userId: Number(userId) },  { refetchOnMountOrArgChange: true })

  const isDataLoading = profileIsLoading || profileIsFetching

  return (
    <>
      {isDataLoading
        ? <Progress />
        : <UserManagement profile={profile} />
      }
    </>
  )
}

export default EditUser