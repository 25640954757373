import {
  GetDetailedSidebarIrdRequest,
  GetDetailedSidebarIrdResponse,
  GetSidebarIrdRequest,
  GetSidebarIrdResponse
} from './sidebarApi.types'
import { tomIrdApi } from '../tomApi'

export const sidebarIrdApi = tomIrdApi.injectEndpoints({
  endpoints: (build) => ({
    getSidebarIrd: build.query<GetSidebarIrdResponse, GetSidebarIrdRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom-ird/side-menu-map`,
        method: 'GET',
      }),
      providesTags: [
        'TomIrd',
        { type: 'TomIrd', id: 'SIDEBAR_MENU' },
        { type: 'TomIrd', id: 'UPLOAD' },
        { type: 'TomIrd', id: 'DELETE' },
      ],
    }),
    getDetailedSidebarIrd: build.query<GetDetailedSidebarIrdResponse, GetDetailedSidebarIrdRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom-ird/detail-side-menu-map`,
        method: 'GET',
      }),
      providesTags: [
        'TomIrd',
        { type: 'TomIrd', id: 'UPLOAD' },
        { type: 'TomIrd', id: 'DELETE' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetSidebarIrdQuery,
  useGetDetailedSidebarIrdQuery,
} = sidebarIrdApi