import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const DownloadFilesIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d='M16 2H4C2.9 2 2 2.9 2 4V18H4V4H16V2Z' />
      <path
        d='M15.625 5H7.125C5.95625 5 5.01063 5.765 5.01063 6.7L5 20.3C5 21.235 5.94562 22 7.11437 22H19.875C21.0438 22 22 21.235 22 20.3V10.1L15.625 5ZM20 20H7V7H15V11H20V20Z'
      />
      <path d='M9 14.99L10.41 13.58L12 15.16V11H14V15.16L15.59 13.57L17 14.99L13.01 19L9 14.99Z' />
    </SvgIcon>
  )
}

export default DownloadFilesIcon