import styled from 'styled-components'
import { Stack } from '@mui/material'
import { LegendColors } from '../../../../../../types/global'

const StatisticsBarItemMinHeight = 25
const StatisticsBarGroupGap = 6

export const StatisticsBarWrapper = styled(Stack)`
  align-self: stretch;
  width: 100px;
  border-radius: 6px;
`

interface StatisticsBarGroupProps {
  heightPercent: number;
  backgroundColor: LegendColors;
  childrenCount: number;
  empty?: boolean;
}
export const StatisticsBarGroup = styled(Stack)<StatisticsBarGroupProps>`
  flex: ${props => props.heightPercent / 100};
  justify-content: center;
  align-items: stretch;
  //gap: ${`${StatisticsBarGroupGap}px`};
  min-height: ${props => props.empty && '20px'};
  padding: ${props => !props.empty && '10px'};
  background-color: ${props => props.theme.palette.legends[props.backgroundColor]};
  
  & .MuiTypography-root {
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0;
  }
  
  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
  }
`

interface StatisticsBarItemProps extends Pick<StatisticsBarGroupProps, 'backgroundColor' | 'heightPercent'> {
  parentHeightPercent: number;
}
export const StatisticsBarItem = styled(Stack)<StatisticsBarItemProps>`
    flex: ${props => props.heightPercent / props.parentHeightPercent};
  justify-content: center;
  align-items: center;
  min-height: ${`${StatisticsBarItemMinHeight}px`};
  background-color: ${props => props.theme.palette.legends[props.backgroundColor]};

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`