import { ProjectPhase } from '../../../../../types/project'
import { RootState } from '../../../../store'
import { DefaultProjectInfoSelector } from './projectInfo.selectors.types'

export const defaultProjectInfoSelector = (state: RootState): DefaultProjectInfoSelector => ({
  project: state.projectInfo.project,
  irdAccess: state.projectInfo.irdAccess,
  iiAccess: state.projectInfo.iiAccess,
  pdAccess: state.projectInfo.pdAccess,
  rdAccess: state.projectInfo.rdAccess,
  userWithLessControls: state.projectInfo.userWithLessControls,

})

export const selectedProjectPhaseSelector = (state: RootState): ProjectPhase => state.projectInfo.selectedProjectPhase

export const isExtraAccessArchitectorSelector = (state: RootState): boolean => {
  const role = state.profile.profile.role
  const currentProjectId = state.projectInfo.project.id
  const currentProject = role ? state.profile.profile.access.projects.find(project => project.id === currentProjectId) : undefined

  return role === 'architector' && !!currentProject?.extraAccess
}