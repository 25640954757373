import React from 'react'
import { UseRenderDataProps, UseRenderDataResponse } from './useRenderData.types'
import { Object, Rd } from '../../../../api/rdPhase'
import { Pd, Ppd } from '../../../../api/pdPhase'
import { Ii } from '../../../../api/iiPhase'
import { ProjectPhase } from '../../../../types/project'
import ObjectCard from '../../components/ObjectCard'
import RdCard from '../../../Administration/components/RdCard'
import { Grid, useMediaQuery } from '@mui/material'
import { StyledDataGrid } from '../../../../styles/global/StyledDataGrid'
import { COMMON_DOCS_NUMBER, XXL_FOR_LAYOUT } from '../../../../utils/constants'
import EmptyPage from '../../../../components/EmptyPage'
import { useTypedSelector } from '../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../store/slices/documentsPages/projectInfo'
import { Ird } from '../../../../api/irdPhase'
import { ViewMode } from '../../../../store/slices/ui'
import { Rdp } from '../../../../api/rdPhase/rdp'
import { rdViewTabSelector } from '../../../../store/slices/documentsPages/objects'
import { useLocation } from 'react-router-dom'

export const useRenderData = ({
  onObjectItemClick,
  onObjectEditClick,
  //onRdpItemClick,
  onRdpEditClick,
  onPdItemClick,
  onPdEditClick,
  onIiItemClick,
  onIiEditClick,
  onIrdItemClick,
  onIrdEditClick,

  onRowClick,

  objectsColumns,
  pdColumns,
  emptyPageData,
}: UseRenderDataProps): UseRenderDataResponse => {
  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_LAYOUT})`)

  const location = useLocation()
  const isTimPage = location.pathname.includes('tim')
  const { userWithLessControls } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const rdViewTab = useTypedSelector(rdViewTabSelector)

  const {
    emptyObjectsPageData,
    emptyObjectsPageDataWithoutControls,
    emptyObjectsFilteredData,
    emptyRdpPageData,
    emptyRdpFilteredData,
    emptyPdPageData,
    emptyPdFilteredData,
    emptyIiPageData,
    emptyIiFilteredData,
    emptyIrdPageData,
    emptyIrdFilteredData,
  } = emptyPageData


  const getListViewItem = (item: Object | Rdp | Pd | Ii | Ird, projectPhase: ProjectPhase) => {
    switch (projectPhase) {
      case 'Рабочая документация':
        if (rdViewTab === 'objects') {
          return (
            <ObjectCard
              data={item as unknown as Object}
              onClick={onObjectItemClick}
              onEditClick={onObjectEditClick}
              disableEdit={userWithLessControls}
            />
          )
        }
        return (
          <RdCard
            data={item as unknown as Rdp}
            onClick={() => undefined/* onRdpItemClick */}
            isPpd
            onEditPpdClick={onRdpEditClick}
            disablePpdEdit={userWithLessControls}
          />
        )


      case 'Проектная документация':
        return (
          <RdCard
            data={item as unknown as Rd}
            onClick={onPdItemClick}
            isPpd
            onEditPpdClick={onPdEditClick}
            disablePpdEdit={userWithLessControls}
          />
        )
      case 'Инженерные изыскания':
        return (
          <RdCard
            data={item as unknown as Rd}
            onClick={onIiItemClick}
            isPpd
            onEditPpdClick={onIiEditClick}
            disablePpdEdit={userWithLessControls}
          />
        )
      case 'Сбор исходных данных':
        return (
          <RdCard
            data={item as unknown as Rd}
            onClick={onIrdItemClick}
            isPpd
            onEditPpdClick={onIrdEditClick}
            disablePpdEdit={userWithLessControls}
          />
        )
    }
  }


  const renderDataView = (view: ViewMode, filteredData: (Object | Rdp | Ppd | Ii | Ird)[]) => {
    switch (view) {
      case 'list':
        return (
          !filteredData.length ?
            <EmptyPage data={emptyObjectsFilteredData} forFilter />
            : (
              <Grid sx={{ py: 1.25 }} spacing={2.5} container>
                {filteredData?.map(item => (
                  <Grid
                    item
                    xs={12} md={6} lg={4} xl={xxl ? 2 : 3}
                    container
                    justifyContent='center'
                    key={item.id}
                  >
                    {getListViewItem(item, selectedProjectPhase)}
                  </Grid>
                ))}
              </Grid>)
        )
      case 'table':
        return (
          <StyledDataGrid
            rows={filteredData}
            columns={((selectedProjectPhase === 'Рабочая документация' || isTimPage) && rdViewTab === 'objects')
              ? objectsColumns
              : pdColumns
            }
            onRowClick={(params) => onRowClick(params.row.id)}
            hideFooter
            headerHeight={40}
            rowHeight={40}
            showCellRightBorder
            showColumnRightBorder
            disableSelectionOnClick
            disableColumnMenu
            localeText={{
              noRowsLabel: rdViewTab === 'objects' || isTimPage
                ? 'Объектов нет. Проверьте состояние переключателя "Все объекты".'
                : 'Разделов нет. Проверьте состояние переключателя "Все разделы".'
            }}
            getRowClassName={(params) => {
              return params.row.number === COMMON_DOCS_NUMBER
                ? 'commonDocsRow'
                : ''
            }}
          />
        )
    }
  }

  const renderPageData = (
    view: ViewMode,
    filteredData: (Object | Rdp | Ppd | Ii | Ird)[],
    allItems: boolean,
    isObjectsExist: boolean,
    isRdpsExist: boolean,
    isPdsExist: boolean,
    isIisExist: boolean,
    isIrdsExist: boolean,
  ) => {
    if (isTimPage) {
      if (rdViewTab === 'objects') {
        const objectData = filteredData as Object[]
        return isObjectsExist
          ? objectData?.length
            ? allItems
              ? renderDataView(view, objectData)
              : renderDataView(view, objectData.filter(item => item.existTim))
            : <EmptyPage data={emptyObjectsFilteredData} forFilter />
          : <EmptyPage data={userWithLessControls ? emptyObjectsPageDataWithoutControls : emptyObjectsPageData} />
      }
      const rdpData = filteredData as Rdp[]
      return isRdpsExist
        ? rdpData?.length
          ? allItems
            ? renderDataView(view, rdpData)
            : renderDataView(view, rdpData.filter(item => item.existTom || item.existTim))
          : <EmptyPage data={emptyRdpFilteredData} forFilter />
        : <EmptyPage data={emptyRdpPageData} />
    } else
      switch (selectedProjectPhase) {
        case 'Рабочая документация':
          if (rdViewTab === 'objects') {
            const objectData = filteredData as Object[]
            return isObjectsExist
              ? objectData?.length
                ? allItems
                  ? renderDataView(view, objectData)
                  : renderDataView(view, objectData.filter(item => item.existTom || item.existTim))
                : <EmptyPage data={emptyObjectsFilteredData} forFilter />
              : <EmptyPage data={userWithLessControls ? emptyObjectsPageDataWithoutControls : emptyObjectsPageData} />
          }
          const rdpData = filteredData as Rdp[]
          return isRdpsExist
            ? rdpData?.length
              ? allItems
                ? renderDataView(view, rdpData)
                : renderDataView(view, rdpData.filter(item => item.existTom || item.existTim))
              : <EmptyPage data={emptyRdpFilteredData} forFilter />
            : <EmptyPage data={emptyRdpPageData} />

        case 'Проектная документация':
          return isPdsExist
            ? filteredData?.length
              ? renderDataView(view, filteredData)
              : <EmptyPage data={emptyPdFilteredData} forFilter />
            : <EmptyPage data={emptyPdPageData} />
        case 'Инженерные изыскания':
          return isIisExist
            ? filteredData?.length
              ? renderDataView(view, filteredData)
              : <EmptyPage data={emptyIiFilteredData} forFilter />
            : <EmptyPage data={emptyIiPageData} />
        case 'Сбор исходных данных':
          return isIrdsExist
            ? filteredData?.length
              ? renderDataView(view, filteredData)
              : <EmptyPage data={emptyIrdFilteredData} forFilter />
            : <EmptyPage data={emptyIrdPageData} />
      }
  }

  return {
    renderPageData,
  }
}
