import { FC } from 'react'
import { SvgIcon } from '@mui/material'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'

const ObjectsIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.96997 13.3333V3.71716L8.00027 2L11.0306 3.71716V13.3333H4.96997ZM6.18209 11.9595H7.39421V10.5858H6.18209V11.9595ZM6.18209 9.21208H7.39421V7.83835H6.18209V9.21208ZM6.18209 6.46462H7.39421V5.09089H6.18209V6.46462ZM9.81846 5.09089H8.60633V6.46462H9.81846V5.09089ZM9.81846 7.83835H8.60633V9.21208H9.81846V7.83835ZM9.81846 10.5858H8.60633V11.9595H9.81846V10.5858ZM3.75749 13.3333V12.1128H2.54537V8.45131H3.75749V7.2308H1.33325V13.3333H3.75749ZM12.2421 7.2308V8.45131H13.4542V12.1128H12.2421V13.3333H14.6663V7.2308H12.2421Z'
      />
    </SvgIcon>
  )
}

export default ObjectsIcon
