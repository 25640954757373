import { ProjectType } from '../../../types/project'
import { Ppd } from '../ppd'
import { Ii } from '../../iiPhase'
import { Ird } from '../../irdPhase'

export interface Pd {
  id: number;
  description: string;
  fullName: string;
  mark: string;
  type: ProjectType;
}

export const isPd = (item: Pd | Ppd | Ii | Ird): item is Pd => {
  return (item as Pd)?.type !== undefined
}
