import styled from 'styled-components'
import { Autocomplete, FormControl } from '@mui/material'
import { LegendColors } from '../../types/global'

export const AutocompleteFormControl = styled(FormControl)`
  width: 100%;
`

interface AutocompleteFieldProps {
  inputColor?: LegendColors;
  disablePlaceholder?: boolean;
}

export const StyledAutocompleteField = styled(Autocomplete)<AutocompleteFieldProps>`
  width: 100%;
  background-color: ${props => props.theme.palette.bg.white};

  & .MuiOutlinedInput-root {
    height: 41px;
    padding: 10px 18px;
    padding-right: 32px !important;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${props => props.inputColor ? props.theme.palette.legends[props.inputColor] : props.theme.palette.text.dark};
    background-color: ${props => props.theme.palette.bg.shades};
    border-radius: 8px;
  }

  & .MuiOutlinedInput-input {
    padding: 0 !important;
  }

  & .MuiInputLabel-root {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${props => props.theme.palette.disabled};
    transform: translate(18px, 10px) scale(1);

    &.MuiInputLabel-shrink {
      opacity: ${props => props.disablePlaceholder ? 0 : 1};
      transform: translate(18px, 10px) scale(1);
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.1);

    & legend {
      padding: 0;

      & span {
        display: none;
      }
    }
  }

  & .MuiAutocomplete-endAdornment {
    top: calc(50% - 11px);
    right: 12px;

    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.text.dark};
      font-size: 16px !important;
    }
  }

  & .MuiFormHelperText-root {
    text-align: right;
  }
`