import { BreadcrumbsItem, SearchChangeAction, UIContextState, UIContextValue } from './ui.types'
import { createContext, useEffect, useState } from 'react'
import { WithChildren } from '../../types/global'
import { AlertColor } from '@mui/material/Alert/Alert'
import { SnackbarCloseReason } from '../../utils/constants'

export const uiContext = createContext({} as UIContextValue)

let timeout: NodeJS.Timeout

export const UICProvider = ({ children }: WithChildren) => {
  const [state, setState] = useState(new UIContextState())

  function showSnackbarMessage(type: AlertColor, message: string) {
    setState((v) => ({ ...v, snackbarType: type, snackbarMessage: message }))
  }

  function setHideSnackbar(reason: SnackbarCloseReason) {
    if (reason === 'clickaway') return
    setState((v) => ({ ...v, snackbarType: null, snackbarMessage: null }))
  }

  function setBreadcrumbs(...items: BreadcrumbsItem[]) {
    setState((v) => ({ ...v, breadcrumbsItems: items }))
  }

  function setShowSearch(value: boolean) {
    clearSearchRefValue()
    setState((v) => ({ ...v, showSearch: value, searchValue: '' }))
  }

  function setSearchValue(value: string, action: SearchChangeAction, immediately?: boolean) {
    clearTimeout(timeout)

    let searchIsDirty = false
    let searchIsDefault = false
    switch (action) {
      case 'userInput':
        searchIsDirty = true
        break
      case 'setDefaultValue':
        searchIsDefault = true
        break
      case 'clear':
        break
    }

    if (value && !immediately) {
      timeout = setTimeout(() => {
        setState((v) => ({
          ...v,
          searchValue: value,
          searchIsDirty,
          searchIsDefault
        }))
      }, 200)
      return
    }
    setState((v) => ({
      ...v,
      searchValue: value,
      searchIsDirty,
      searchIsDefault
    }))
  }

  useEffect(() => {
    if (!state.searchIsDefault) return

    const input = _getSearchInputElement()
    if (input) input.value = state.searchValue
  }, [state.searchIsDefault])

  function _getSearchInputElement(): HTMLInputElement | null {
    const target = state.searchRef?.current
    const input = (target?.children[0]?.children[1] as HTMLInputElement) ?? null

    return input
  }

  function clearSearchRefValue() {
    setSearchValue('', 'clear')

    const input = _getSearchInputElement()
    if (input) input.value = ''
  }

  return (
    <uiContext.Provider value={{
      ...state,

      showSnackbarMessage,
      setHideSnackbar,
      setBreadcrumbs,
      setShowSearch,
      setSearchValue,
      clearSearchRefValue
    }}>
      {children}
    </uiContext.Provider>
  )
}