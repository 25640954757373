import styled from 'styled-components'
import {
  alpha,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { TABLE_CELL_HEIGHT, TITLE_CELL_OFFSET } from './WorksTable.types'

interface StyledTableCellProps {
  sticky?: boolean;
  noPadding?: boolean;
  dark?: boolean;
  hover?: boolean;
}

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  padding: 0 8px;
  padding: ${props => props.noPadding && 0};
  background-color: transparent;
  background-color: ${props => props.dark && props.theme.palette.secondary.dark} !important;

  &:hover {
    box-shadow: ${props => props.hover && `
      inset -1px -1px 0 ${props.theme.palette.primary.light},
      inset 1px 1px 0 ${props.theme.palette.primary.light} !important`};
  }
`

interface StyledTableRowProps {
  focused?: boolean;
}

export const StyledTableRow = styled(TableRow)<StyledTableRowProps>`
  min-height: 40px;
  height: ${TABLE_CELL_HEIGHT}px;

  & ${StyledTableCell} {
    background-color: ${props => props.focused && '#E2EBF8'} !important;
    box-shadow: inset -1px -1px 0 rgba(0, 36, 95, 0.1);

    &:last-child {
      box-shadow: inset -1px -1px 0 rgba(0, 36, 95, 0.1)
        inset -1px 0px 0 rgba(0, 36, 95, 0.1);
    }
  }
`

export const StyledTableBody = styled(TableBody)`
  & ${StyledTableRow} {
    &:hover {
      cursor: pointer;

      & ${StyledTableCell} {
        background-color: ${props => props.theme.palette.bg.shades};
      }
    }
  }

  & ${StyledTableCell} {
    background-color: ${props => props.theme.palette.bg.white};

    &.moveCell {
      box-shadow: inset 0 -1px 0 rgba(0, 36, 95, 0.1);
    }

    & .MuiTypography-root {
      color: ${props => props.theme.palette.text.dark};
    }
  }
`

export const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 2;

  & ${StyledTableRow} {
    height: 40px;
    background-color: ${props => props.theme.palette.secondary.gray};


    &:first-child {
      & ${StyledTableCell} {
        box-shadow: inset 1px 0px 0px rgba(0, 36, 95, 0.1);
      }
    }

    & ${StyledTableCell} {
      box-shadow: inset 1px 0px 0px rgba(0, 36, 95, 0.1),
      inset 0px 1px 0px rgba(0, 36, 95, 0.1);
    }

    &,
    &:first-child,
    &:first-child ${StyledTableCell}:first-child {
      box-shadow: none;
    }
  }

  & ${StyledTableCell} {
    background-color: ${props => props.theme.palette.secondary.gray};

    &.moveCell {
      box-shadow: none;
    }

    &.titleCell {
      padding-left: 16px !important;
      box-shadow: inset -1px 0 0 rgba(0,36,95,0.1) !important;
    }

    & .MuiTypography-root {
      font-weight: 400;
      color: ${props => props.theme.palette.text.light};
      text-align: center;
    }
  }
`

export const StyledTableContainer = styled(TableContainer)`
  max-height: none;
  height: 100%;
`

export const StyledTable = styled(Table)`
  & * {
    border: 0;
  }
`

export const StyledTablePaper = styled(Paper)`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: none;
  overflow-x: auto;
`

export const GroupRow = styled(StyledTableRow)`
  background-color: ${props => props.theme.palette.bg.shades} !important;
  background-color: ${props => props.focused && alpha(props.theme.palette.primary.light, 0.2)} !important;

  & ${StyledTableCell}.moveCell {
    position: sticky;
    left: 0;
  }

  & ${StyledTableCell}.groupNameCell {
    position: sticky;
    left: ${TITLE_CELL_OFFSET}px;
  }

  & ${StyledTableCell}.ratioCell {
    //position: sticky;
  }

  & ${StyledTableCell} {
    z-index: 1;
    color: ${props => props.theme.palette.primary.main};
    background: inherit;
    background-color: ${props => props.focused && 'transparent'} !important;

    box-shadow: inset 0 -1px 0 rgba(0, 36, 95, 0.1);
    
    &.graphCell {
      //box-shadow: inset -1px -1px 0 rgba(0,36,95,0.1);
    }
  }
`

export const ShowWorksIconButton = styled(IconButton)`
  padding: 4px;

  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.text.light};
  }
`

export const RatioValueWrapper = styled(Box)`
  width: fit-content;
  padding: 0 5px;
  font-weight: 400;
  line-height: 19px;
  color: ${props => props.theme.palette.text.light};
  background-color: ${props => props.theme.palette.secondary.main};
  border-radius: 20px;
`

export const GroupRatioValueWrapper = styled(Box)`
  width: 100%;
  padding: 2px 5px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  color: ${props => props.theme.palette.primary.light};
  background-color: transparent;
  border: ${props => `1px solid ${alpha(props.theme.palette.primary.light, 0.5)}`};
  border-radius: 20px;
`