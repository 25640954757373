import React from 'react'
import {
  UserDesignLogo,
  UserDrawer,
  UserDrawerContent,
  UserDrawerHeader,
  UserLogoWrapper
} from './styles'
import { Link } from 'react-router-dom'
import { UserNavBarProps } from './UserNavBar.types'
import Progress from '../Progress'
import { Stack } from '@mui/material'
import Button from '../Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { theme } from '../../styles/theme'
import HomeIcon from '../../assets/icons/HomeIcon'

const UserNavBar: React.FC<UserNavBarProps> = ({ getContent, loading, onReturnClick, onHomeClick }) => {

  return (
    <UserDrawer
      variant='permanent'
    >
      {loading
        ? <Progress />
        : (
          <>
            <UserDrawerHeader direction='row' justifyContent='space-between' spacing={1}>
              <Link to='/home'>
                <UserLogoWrapper>
                  <UserDesignLogo />
                </UserLogoWrapper>
              </Link>

              <Stack direction='row' alignItems='center' spacing={1.25}>
                <Button icon bgColor={theme.palette.bg.shades} color='primary' variant='text' onClick={() => onReturnClick()}>
                  <ArrowBackIcon />
                </Button>
                <Button icon bgColor={theme.palette.bg.shades} color='primary' variant='text' onClick={() => onHomeClick()}>
                  <HomeIcon />
                </Button>
              </Stack>
            </UserDrawerHeader>

            <UserDrawerContent spacing={2.5} flex={1}>
              {getContent()}
            </UserDrawerContent>
          </>
        )
      }

    </UserDrawer>
  )
}

export default UserNavBar