import React, { FC } from 'react'
import { Stack } from '@mui/material'
import { FormTitledWrapper } from '../FormLeftBlock/FormLeftBlock.styles'
import { FormItem } from '../FormItem'
import { AutocompleteField } from '../../../../../components/AutocompleteField/AutocompleteField'
import { MapFormButton } from '../MapForm'
import { useFormikContext } from 'formik'
import { useTypedSelector } from '../../../../../store/store'
import { currentCompanyIdSelector, profileSelector } from '../../../../../store/slices/profile'
import { ProjectFormData } from '../../ProjectManagement.types'
import useFilteredEmployees from '../../../../../hooks/useFilteredEmployees'
import { useGetCompanyNamesQuery } from '../../../../../api/users'
import { EmployeesContractorsWrapper } from './EmployeesBlock.styles'
import { useSettingFormikValues } from '../../hooks/useSettingFormikValues'
import { ContractorFields } from '../ContractorFields'

export const EmployeesBlock: FC = () => {
  const { employeeId } = useTypedSelector(profileSelector)
  const companyId = useTypedSelector(currentCompanyIdSelector)
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFormData>()

  const { architectorsAndAdmins, clientsAndAdmins, contractors } = useFilteredEmployees()

  const { data: companyNamesData } = useGetCompanyNamesQuery({ companyId })
  const { data: companyNames } = companyNamesData || {}

  const onResponsibleClick = (employeeId: number) => {
    setFieldValue('responsiblePersons.client.data', employeeId)
  }

  const { employeesByCompany: clientEmployees } = useSettingFormikValues({
    employeeValue: formValues.responsiblePersons.client.data,
    companyValue: formValues.responsiblePersons.client.company,
    employees: clientsAndAdmins,
    fieldNameCompany: 'responsiblePersons.client.company',
    fieldNameEmployee: 'responsiblePersons.client.data'
  })

  const { employeesByCompany: architectorEmployees } = useSettingFormikValues({
    employeeValue: formValues.responsiblePersons.architector.data,
    companyValue: formValues.responsiblePersons.architector.company,
    employees: architectorsAndAdmins,
    fieldNameCompany: 'responsiblePersons.architector.company',
    fieldNameEmployee: 'responsiblePersons.architector.data'
  })

  return (
    <>
      <FormTitledWrapper
        title='Заказчик'
        variant='primary'
        contentWrapper='stack'
        bordered='border'
      >
        <Stack alignItems='end' spacing={1.5}>
          <FormItem
            title='Компания'
            input={(
              <AutocompleteField
                fieldName='responsiblePersons.client.company'
                placeholder='Введите компанию или выберите из списка'
                data={companyNames?.map(company => ({
                  value: company,
                  label: company,
                  key: company,
                })) || []}
                freeSolo
              />

            )}
          />
          <FormItem
            title='Куратор'
            input={(
              <AutocompleteField
                fieldName='responsiblePersons.client.data'
                placeholder='Введите ФИО или выберите из списка'
                data={clientEmployees.map(({ id, name }) => ({
                  value: id,
                  label: name,
                  key: id,
                }))}
                freeSolo
              />

            )}
          />
          <MapFormButton onClick={() => onResponsibleClick(employeeId)} variant='text'>
            Я ответственный за проект
          </MapFormButton>
        </Stack>
      </FormTitledWrapper>

      <FormTitledWrapper
        title='Проектировщик'
        variant='primary'
        contentWrapper='stack'
        bordered='border'
      >
        <FormItem
          title='Компания'
          input={(
            <AutocompleteField
              fieldName='responsiblePersons.architector.company'
              placeholder='Введите компанию или выберите из списка'
              data={companyNames?.map(company => ({
                value: company,
                label: company,
                key: company,
              })) || []}
              freeSolo
            />

          )}
        />

        <FormItem
          title='Ответственный'
          input={(
            <AutocompleteField
              fieldName='responsiblePersons.architector.data'
              placeholder='Введите ФИО или выберите из списка'
              data={architectorEmployees.map(({ id, name }) => ({
                value: id,
                label: name,
                key: id,
              }))}
              freeSolo
            />

          )}
        />
      </FormTitledWrapper>

      <EmployeesContractorsWrapper
        title='Подрядчики'
        variant='primary'
        contentWrapper='stack'
        bordered='none'
      >
        {formValues.responsiblePersons?.contractorsArray?.map((contractor, index) => (
          <ContractorFields
            key={index}
            fieldIndex={index}
            companyNames={companyNames}
            contractors={contractors}
          />
        ))}
      </EmployeesContractorsWrapper>
    </>
  )
}
