import { TomSelectedInfo, UseGetBindSelectsDataProps, UseGetBindSelectsDataResponse } from './useGetBindSelectsData.types'
import { useGetObjectsByProjectIdQuery, useGetTomsRdQuery } from '../../../../../../../api/rdPhase'
import { useGetAllPpdQuery, useGetTomsPdQuery } from '../../../../../../../api/pdPhase'
import { useGetAllIiQuery, useGetTomsIiQuery } from '../../../../../../../api/iiPhase'
import { useGetAllIrdQuery, useGetTomsIrdQuery } from '../../../../../../../api/irdPhase'
import { OptionData } from '../../../../../../../components/SelectFormField/SelectFormField.types'
import { ProjectPhase } from '../../../../../../../types/project'

export const useGetBindSelectsData = ({
  projectId,
  phase,
  selectedObjId,
}: UseGetBindSelectsDataProps): UseGetBindSelectsDataResponse => {

  // Rd
  const { data: objectsData } = useGetObjectsByProjectIdQuery({ id: projectId }, { skip: phase !== 'Рабочая документация' })
  const { data: objects = [] } = objectsData || {}
  const objectSelectData: OptionData<number>[] = objects.map(({ id, title }) => ({
    value: id,
    label: title,
    key: id,
  }))

  const { data: tomsRdData } = useGetTomsRdQuery({
    id: projectId,
    obj: selectedObjId ? [selectedObjId] : undefined,
  }, {
    skip: phase !== 'Рабочая документация' || !selectedObjId
  })
  const { data: tomsRd = [] } = tomsRdData || {}
  const tomsRdSelectData: OptionData<number>[] = tomsRd.map(({ id, title }) => ({
    value: id,
    label: title,
    key: id,
  }))
  const tomsRdSelectInfo: TomSelectedInfo[] = tomsRd.map(({ id, status }) => ({ id, status }))

  // Pd
  const { data: pdData } = useGetAllPpdQuery({ id: projectId }, { skip: phase !== 'Проектная документация' })
  const { data: pdArray = [] } = pdData || {}
  const pdSelectData: OptionData<number>[] = pdArray.map(({ id, mark }) => ({
    value: id,
    label: mark,
    key: id,
  }))

  const { data: tomsPdData } = useGetTomsPdQuery({
    id: projectId,
    pd: selectedObjId ? [selectedObjId] : undefined,
  }, {
    skip: phase !== 'Проектная документация' || !selectedObjId
  })
  const { data: tomsPd = [] } = tomsPdData || {}
  const tomsPdSelectData: OptionData<number>[] = tomsPd.map(({ id, title }) => ({
    value: id,
    label: title,
    key: id,
  }))
  const tomsPdSelectInfo: TomSelectedInfo[] = tomsPd.map(({ id, status }) => ({ id, status }))

  // Ii
  const { data: iiData } = useGetAllIiQuery({ id: projectId }, { skip: phase !== 'Инженерные изыскания' })
  const { data: iiArray = [] } = iiData || {}
  const iiSelectData: OptionData<number>[] = iiArray.map(({ id, mark }) => ({
    value: id,
    label: mark,
    key: id,
  }))

  const { data: tomsIiData } = useGetTomsIiQuery({
    id: projectId,
    ii: selectedObjId ? [selectedObjId] : undefined,
  }, {
    skip: phase !== 'Инженерные изыскания' || !selectedObjId
  })
  const { data: tomsIi = [] } = tomsIiData || {}
  const tomsIiSelectData: OptionData<number>[] = tomsIi.map(({ id, title }) => ({
    value: id,
    label: title,
    key: id,
  }))
  const tomsIiSelectInfo: TomSelectedInfo[] = tomsIi.map(({ id, status }) => ({ id, status }))

  // Ird
  const { data: irdData } = useGetAllIrdQuery({ id: projectId }, { skip: phase !== 'Сбор исходных данных' })
  const { data: irdArray = [] } = irdData || {}
  const irdSelectData: OptionData<number>[] = irdArray.map(({ id, mark }) => ({
    value: id,
    label: mark,
    key: id,
  }))
  

  const { data: tomsIrdData } = useGetTomsIrdQuery({
    id: projectId,
    ird: selectedObjId ? [selectedObjId] : undefined,
  }, {
    skip: phase !== 'Сбор исходных данных' || !selectedObjId
  })
  const { data: tomsIrd = [] } = tomsIrdData || {}
  const tomsIrdSelectData: OptionData<number>[] = tomsIrd.map(({ id, title }) => ({
    value: id,
    label: title,
    key: id,
  }))
  const tomsIrdSelectInfo: TomSelectedInfo[] = tomsIrd.map(({ id, status }) => ({ id, status }))

  // Common
  const objCreateOptionData: OptionData<string> = {
    value: 'create',
    label: 'Создать объект',
    key: 'create',
  }

  const sectionCreateOptionData: OptionData<string> = {
    value: 'create',
    label: 'Создать раздел',
    key: 'create',
    className: 'select-item-button',
  }

  const tomCreateOptionData: OptionData<string> = {
    value: 'create',
    label: 'Создать том',
    key: 'create',
    className: 'select-item-button',
  }

  const objData: Record<ProjectPhase, OptionData<number | string>[]> = {
    'Рабочая документация': [objCreateOptionData, ...objectSelectData],
    'Проектная документация': [sectionCreateOptionData, ...pdSelectData],
    'Инженерные изыскания': [sectionCreateOptionData, ...iiSelectData],
    'Сбор исходных данных': [sectionCreateOptionData, ...irdSelectData],
  }

  const tomData: Record<ProjectPhase, OptionData<number>[]> = {
    'Рабочая документация': tomsRdSelectData,
    'Проектная документация': tomsPdSelectData,
    'Инженерные изыскания': tomsIiSelectData,
    'Сбор исходных данных': tomsIrdSelectData,
  }

  const tomInfo: Record<ProjectPhase, TomSelectedInfo[]> = {
    'Рабочая документация': tomsRdSelectInfo,
    'Проектная документация': tomsPdSelectInfo,
    'Инженерные изыскания': tomsIiSelectInfo,
    'Сбор исходных данных': tomsIrdSelectInfo,
  }

  return {
    objData: phase ? objData[phase] : [],
    tomData: phase ? [tomCreateOptionData, ...tomData[phase]] : [],
    tomInfo: phase ? tomInfo[phase] : []
  }
}
