import { FC } from 'react'
import { Stack, Typography } from '@mui/material'
import { CustomCellProps } from '../cells.types'
import { formatToShortYear } from '../../../../../../utils/formatDate'
import { PeriodCellLetter, PeriodCellRow } from './PeriodCell.styles'
import { theme } from '../../../../../../styles/theme'

export const PeriodCell: FC<CustomCellProps> = ({ work }) => {
  const { period } = work
  const { datePlanStart, datePlanEnd, dateFactStart, dateFactEnd } = period.dates
  const shortDatePlanStart = formatToShortYear(datePlanStart)
  const shortDatePlanEnd = formatToShortYear(datePlanEnd)
  const shortDateFactStart = dateFactStart ? formatToShortYear(dateFactStart) : ' .  .  .  .  . '
  const shortDateFactEnd = dateFactEnd ? formatToShortYear(dateFactEnd) : ' .  .  .  .  . '

  return (
    <Stack spacing={0.25}>
      <PeriodCellRow rowColor={theme.palette.primary.light}>
        <PeriodCellLetter variant='body2' pl='1px'>П</PeriodCellLetter>
        <Typography variant='body2' whiteSpace='pre'>{shortDatePlanStart} — {shortDatePlanEnd}</Typography>
      </PeriodCellRow>
      <PeriodCellRow letterColor='#7890B2'>
        <PeriodCellLetter variant='body2'>Ф</PeriodCellLetter>
        <Typography variant='body2' whiteSpace='pre'>{shortDateFactStart} — {shortDateFactEnd}</Typography>
      </PeriodCellRow>
    </Stack>
  )
}
