import { useState } from "react"
import { ButtonWrapper, StyledButton, SubtitleText } from "./DrawerVersionView.styles"
import { DrawerVersionForm } from "../DrawerVersionForm"
import { Button, Stack } from "@mui/material"
import { DrawerVersionViewProps } from "./DrawerVersionView.types"
import { VersionCard } from "../VersionCard"
import { useDeleteTimVersionMutation, useGetTimVersionsQuery } from "../../../../api/tim"
import { useParams } from "react-router-dom"
import Progress from "../../../../components/Progress"
import { useMutationHandlers } from "../../../../hooks/useMutationHandlers"
import { useSnackbar } from "notistack"

export const DrawerVersionView = ({ onClose, timId }: DrawerVersionViewProps) => {

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { enqueueSnackbar } = useSnackbar()

  const { data: versionList, isFetching } = useGetTimVersionsQuery({ id: projectId, timId }, { skip: !timId })
  const { versions } = versionList || {}

  const [deleteTimVersion, deleteTimVersionResponse] = useDeleteTimVersionMutation()
  const [isOpenVersionDrawer, setIsOpenVersionDrawer] = useState<boolean>(false)

  const closeVersionDrawer = (fully: boolean) => {
    setIsOpenVersionDrawer(false)
    if (fully) onClose(false)
  }

  const onDeleteTimVersion = (timId: number, version: number) => {
    deleteTimVersion({id: projectId, timId, version})
  }

  useMutationHandlers(
    deleteTimVersionResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(`Версия успешно удалена.`, { variant: 'success' })
      }
    }
  )

  return (
    <>
      <StyledButton variant="text" fullWidth onClick={() => setIsOpenVersionDrawer(true)}>
        Добавить новую версию модели
      </StyledButton>
      <Stack spacing={1.25} pt={2.5}>
        {isFetching ? <Progress /> :
          versions &&
          versions.map((version, index) =>
            index === 0 ? (
              <>
                <SubtitleText>Текущая версия</SubtitleText>
                <VersionCard version={versions[0]} key={versions[0].version} onDeleteTimVersion={onDeleteTimVersion}/>
                <SubtitleText pt={1.25}>{versions.length > 1 && 'Архив версий'}</SubtitleText>
              </>
            ) : (
              <VersionCard key={version.version} version={version} onDeleteTimVersion={onDeleteTimVersion}/>
            )
          )

        }
      </Stack>
      <ButtonWrapper direction='row' spacing={2} flex={1}>
        <Button size='medium' fullWidth onClick={() => onClose(false)}>Закрыть</Button>
      </ButtonWrapper>
      <DrawerVersionForm
        timId={timId}
        open={isOpenVersionDrawer}
        closeVersionDrawer={closeVersionDrawer}
      />
    </>
  )
}