import { Stack, Typography } from "@mui/material"
import { StyledContainer, StyledIconButton } from "./TanglStatus.styles"
import { tanglIntegrationErrorTextByStatusAdditional, tanglIntegrationStatusColorByEn, tanglIntegrationStatusIconByEn } from "api/settings/types"
import { useGetTanglIntegrationStatusQuery } from "api/settings"
import CloseIcon from '@mui/icons-material/Close'
import { useState } from "react"

export const TanglStatus = () => {

  const { data: statusConnectionResponse } = useGetTanglIntegrationStatusQuery(
    undefined,
    { refetchOnMountOrArgChange: true }
  )
  const { status: statusConnection } = statusConnectionResponse || {}

  const [show, setShow] = useState<boolean>(true)

  if (show && statusConnection &&
    (statusConnection === 'ERROR_ACCESS'
      || statusConnection === 'ERROR_AUTH'
      || statusConnection === 'ERROR_COMPANY')
  ) {
    return (
      <StyledContainer spacing={0.5}>
        <Stack direction='row' spacing={1} alignItems='center'>
          {tanglIntegrationStatusIconByEn[statusConnection]}
          <Typography
            textAlign={'start'}
            color={tanglIntegrationStatusColorByEn[statusConnection]}
            variant='body2'
            lineHeight={'normal'}
          >
            Ошибка подключения
          </Typography>
        </Stack>
        <Typography variant='body1' fontSize={14}>
          {tanglIntegrationErrorTextByStatusAdditional[statusConnection]}
        </Typography>
        <StyledIconButton onClick={() => setShow(false)} size="small">
          <CloseIcon fontSize="medium" color="secondary" />
        </StyledIconButton>
      </StyledContainer>
    )
  } else return null

}