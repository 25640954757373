import React, { ChangeEvent, useCallback } from 'react'
import { Box, FormControl, IconButton, Stack, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import EventNoteIcon from '@mui/icons-material/EventNote'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import {
  DrawerContentTextWrapper,
  DrawerProjectDescription,
  DrawerProjectTitle,
  ProjectAccentText,
  ProjectText,
  StyledToggleButton,
  StyledToggleButtonGroup
} from './styles'
import { theme } from '../../../../styles/theme'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import {
  allProjectsExpertise,
  allProjectsPhases,
  ProjectExpertise,
  ProjectPhase,
  projectTypeShort
} from '../../../../types/project'
import { StyledSelectField } from '../../../../components/FieldForm/styles'
import { StyledSelectMenuItem } from '../../../../components/UserManagement/components/UserRoleSelection/styles'
import { useEditProjectStageMutation } from '../../../../api/projects'
import GradingIcon from '@mui/icons-material/Grading'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { profileSelector } from '../../../../store/slices/profile'
import { ScrollableContainer } from '../../../../styles/global/ScrollableContainer'
import {
  defaultProjectInfoSelector,
  isExtraAccessArchitectorSelector,
  selectedProjectPhaseSelector,
  setSelectedProjectPhase
} from '../../../../store/slices/documentsPages/projectInfo'
import ExpertiseIcon from '../../../../assets/icons/ExpertiseIcon'
import CloseIcon from '@mui/icons-material/Close'
import { useLocation } from 'react-router-dom'

const ObjectsNavBarContent: React.FC = () => {
  const dispatch = useAppDispatch()
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { role } = useTypedSelector(profileSelector)
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const isExtraAccessArchitector = useTypedSelector(isExtraAccessArchitectorSelector)
  const { pdAccess, rdAccess } = useTypedSelector(defaultProjectInfoSelector)
  const location = useLocation()
  const isTimPage = location.pathname.includes('tim')

  const { id, shortName, fullName, status, phase, type, startDate, endDate, expertise = '' } = project

  const [editProjectStage] = useEditProjectStageMutation()

  const showStageSwitcher =
  (selectedProjectPhase === 'Рабочая документация' || selectedProjectPhase === 'Проектная документация')
  && rdAccess
  && pdAccess
  && !isTimPage

  const onStageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    editProjectStage({
      id,
      phase: e.target.value as ProjectPhase
    })
  }, [project])

  const onExpertiseChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    editProjectStage({
      id,
      expertise: e.target.value as ProjectExpertise
    })
  }, [project])

  const onExpertiseClear = useCallback(() => {
    editProjectStage({
      id,
      expertise: undefined,
    })
  }, [])

  const onViewPhaseChange = useCallback((newViewPhase: ProjectPhase) => {
    if (newViewPhase !== null) {
      dispatch(setSelectedProjectPhase({ selectedProjectPhase: newViewPhase }))
    }
  }, [])

  return (
    <ScrollableContainer spacing={2.5}>
      <Stack spacing={1.5}>
        {showStageSwitcher && (
          <StyledToggleButtonGroup
          value={selectedProjectPhase}
          exclusive
          onChange={(e, newValue) => onViewPhaseChange(newValue)}
        >
          <StyledToggleButton value='Проектная документация'>
            <Typography width={24} height={24} variant='buttonMedium' lineHeight='25px'>
              ПД
            </Typography>
          </StyledToggleButton>
          <StyledToggleButton value='Рабочая документация'>
            <Typography width={24} height={24} variant='buttonMedium' lineHeight='25px'>
              РД
            </Typography>
          </StyledToggleButton>
        </StyledToggleButtonGroup>)}
        <DrawerContentTextWrapper>
          <DrawerProjectTitle variant='subtitle1'>{shortName}</DrawerProjectTitle>
        </DrawerContentTextWrapper>
        <DrawerContentTextWrapper style={{ padding: '16px' }}>
          <DrawerProjectDescription variant='body2'>{fullName}</DrawerProjectDescription>
        </DrawerContentTextWrapper>
      </Stack>

      <Stack spacing={1}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 38 }}>
          <Stack direction='row' spacing={1}>
            <GradingIcon fontSize='medium' color='secondary' />
            <Typography variant='body2' component='span'>Стадия:</Typography>
          </Stack>

          {phase && (role === 'admin' || role === 'client' || isExtraAccessArchitector)
            ? (
              <FormControl
                style={{ width: '100%' }}
                sx={{
                  maxWidth: 190,
                  '& .MuiSelect-outlined': {
                    fontWeight: 500,
                    color: `${theme.palette.primary.main} !important`
                  },
                }}
              >
                <StyledSelectField
                  select
                  value={phase}
                  onChange={onStageChange}
                >
                  {allProjectsPhases.map(stage => (
                    <StyledSelectMenuItem
                      value={stage}
                      key={stage}
                    >
                      {stage}
                    </StyledSelectMenuItem>
                  )
                  )}
                </StyledSelectField>
              </FormControl>
            )
            : (
              <ProjectAccentText variant='subtitle2'>{phase}</ProjectAccentText>
            )
          }
        </Stack>

        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 38 }}>
          <Stack direction='row' spacing={1}>
            <InsertDriveFileOutlinedIcon fontSize='medium' color='secondary' />
            <Typography variant='body2' component='span'>Тип:</Typography>
          </Stack>

          <ProjectText variant='subtitle2'>{projectTypeShort[type]}</ProjectText>
        </Stack>

        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 38 }}>
          <Stack direction='row' spacing={1}>
            <ErrorOutlineIcon fontSize='medium' color='secondary' />
            <Typography variant='body2' component='span'>Статус:</Typography>
          </Stack>

          <ProjectText variant='subtitle2'>{status}</ProjectText>
        </Stack>

        {phase === 'Проектная документация' && (
          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 38 }}>
            <Stack direction='row' spacing={1}>
              <ExpertiseIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Экспертиза:</Typography>
            </Stack>

            {role === 'admin' || role === 'client' || isExtraAccessArchitector
              ? (
                <FormControl
                  style={{ width: '100%' }}
                  sx={{
                    maxWidth: 190,
                    '& .MuiSelect-outlined': {
                      fontWeight: 500,
                      color: `${theme.palette.primary.main} !important`
                    },
                  }}
                >
                  <StyledSelectField
                    select
                    value={expertise}
                    onChange={onExpertiseChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={onExpertiseClear} size='small' sx={{ mr: 1 }}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      ),
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (value: any) => value || 'Не установлен'
                    }}
                    style={{ backgroundColor: theme.palette.bg.shades }}
                  >
                    {allProjectsExpertise.map(expertise => (
                      <StyledSelectMenuItem
                        value={expertise}
                        key={expertise}
                      >
                        {expertise}
                      </StyledSelectMenuItem>
                    )
                    )}
                  </StyledSelectField>
                </FormControl>
              )
              : (
                <ProjectText variant='subtitle2'>{expertise}</ProjectText>
              )
            }
          </Stack>
        )}
      </Stack>

      <Stack spacing={2}>
        <Box>
          <Typography variant='body2'>Сроки реализации:</Typography>
        </Box>

        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' spacing={1}>
            <EventNoteIcon fontSize='medium' color='secondary' />
            <Typography variant='body2' component='span'>Дата начала:</Typography>
          </Stack>

          <ProjectText variant='subtitle2'>{startDate}</ProjectText>
        </Stack>

        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' spacing={1}>
            <EventAvailableIcon fontSize='medium' color='secondary' />
            <Typography variant='body2' component='span'>Дата окончания:</Typography>
          </Stack>

          <ProjectText variant='subtitle2'>{endDate}</ProjectText>
        </Stack>
      </Stack>
    </ScrollableContainer>
  )
}

export default ObjectsNavBarContent