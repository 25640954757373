import { useCallback } from 'react'
import { useGetEmployeesQuery } from '../api/users'
import { EmployeeShortProfile, GetEmployeesResponse } from '../api/users/types'
import { UserRolesEn } from '../types/user'
import { useTypedSelector } from '../store/store'
import { currentCompanyIdSelector } from '../store/slices/profile'

export interface FilteredEmployees {
  admins: EmployeeShortProfile[];
  clients: EmployeeShortProfile[];
  architectors: EmployeeShortProfile[];
  contractors: EmployeeShortProfile[];

  clientsAndAdmins: EmployeeShortProfile[];
  architectorsAndAdmins: EmployeeShortProfile[];
}


const useFilteredEmployees = (skipQuery?: boolean): FilteredEmployees => {
  const currentCompanyId = useTypedSelector(currentCompanyIdSelector)

  const { data: employeesData } = useGetEmployeesQuery({
    companyId: currentCompanyId,
    roles: ['admin', 'client', 'architector', 'contractor']
  }, { skip: skipQuery })
  const { data: employees } = employeesData || ({} as GetEmployeesResponse)

  const filterEmployees = useCallback((roles: UserRolesEn[]) => {
    return employees?.filter(employee => roles.includes(employee.role)) || []
  }, [employees])

  return {
    admins: filterEmployees(['admin']),
    clients: filterEmployees(['client']),
    architectors: filterEmployees(['architector']),
    contractors: filterEmployees(['contractor']),

    clientsAndAdmins: filterEmployees(['admin', 'client']),
    architectorsAndAdmins: filterEmployees(['admin', 'architector']),
  }
}

export default useFilteredEmployees