import React, { FC, useCallback, useEffect, useState } from 'react'
import { AgreementNavbarConfirmTrigger, AgreementNavbarContentProps } from './AgreementNavbarContent.types'
import Progress from '../../../components/Progress'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { AgreementNavbarPhase } from './components/AgreementNavbarPhase'
import { useGetRoadmapsMenuQuery } from '../../../api/projectAgreement'
import { useAppDispatch, useTypedSelector } from '../../../store/store'
import {
  currentRoadmapSelector,
  isStageFormDirtySelector,
  setCurrentRoadmap,
  setInitialRoadmapPhaseFromMenu,
  setNextRoadmapNumber,
  setRoadmapFormMode
} from '../../../store/slices/agreement'
import { Roadmap, RoadmapPhase } from '../../../api/projectAgreement/types'
import useConfirmDialog, { UseExitConfirmProps } from '../../../hooks/useConfirmDialog'
import { UserDrawerContent } from '../../../components/UserNavBar/styles'

export const AgreementNavbarContent: FC<AgreementNavbarContentProps> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { projectId: projectIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)

  const isAgreementPage = !!useMatch(`/project/${projectId}/schemes/roadmap`)

  const currentRoadmap = useTypedSelector(currentRoadmapSelector)
  const isStageFormDirty = useTypedSelector(isStageFormDirtySelector)

  const { data: roadmapsMenuData, isFetching } = useGetRoadmapsMenuQuery(
    { id: projectId }
  )
  const { data: roadmapsMenu = [], nextNumber } = roadmapsMenuData || {}

  const setRoadmap = useCallback(() => {
    if (roadmapsMenuData) {
      const firstExistRoadmap = roadmapsMenu.find(menuItem => !!menuItem?.roadmaps?.length)?.roadmaps[0]
      firstExistRoadmap
        ? dispatch(setCurrentRoadmap(firstExistRoadmap))
        : dispatch(setCurrentRoadmap({} as Roadmap))
    }
  }, [roadmapsMenuData])

  useEffect(() => {
    if (!currentRoadmap?.id) {
      setRoadmap()
    }
  }, [currentRoadmap?.id, setRoadmap])

  useEffect(() => {
    setRoadmap()
  }, [projectId])

  useEffect(() => {
    nextNumber && dispatch(setNextRoadmapNumber({ nextRoadmapNumber: nextNumber }))
  }, [nextNumber])

  const returnToAgreement = () => {
    navigate(`/project/${projectId}/schemes/roadmap`)
  }

  const confirmReturn = (confirm: boolean) => {
    if (confirm) {
      navigate(-1)
    }
  }

  const confirmHomeClick = (confirm: boolean) => {
    if (confirm) {
      navigate(`/project${projectId}`)
    }
  }

  const confirmRoadmapAdd = (confirm: boolean, phase: RoadmapPhase) => {
    if (confirm) {
      dispatch(setRoadmapFormMode({ roadmapFormMode: 'create' }))
      dispatch(setCurrentRoadmap({} as Roadmap))
      dispatch(setInitialRoadmapPhaseFromMenu({ initialRoadmapPhaseFromMenu: phase }))
    }

    if (!isAgreementPage) {
      returnToAgreement()
    }
  }

  const confirmRoadmapClick = (confirm: boolean, roadmap: Roadmap) => {
    if (confirm) {
      dispatch(setCurrentRoadmap(roadmap))
      dispatch(setRoadmapFormMode({ roadmapFormMode: 'view' }))
    }

    if (!isAgreementPage) {
      returnToAgreement()
    }
  }

  const [confirmTrigger, setConfirmTrigger] = useState<AgreementNavbarConfirmTrigger>('return')

  const dataForConfirmDialog: Record<AgreementNavbarConfirmTrigger, UseExitConfirmProps> = {
    return: { handleConfirm: confirmReturn },
    home: { handleConfirm: confirmHomeClick },
    roadmapAdd: { handleConfirm: confirmRoadmapAdd },
    roadmapClick: { handleConfirm: confirmRoadmapClick },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmTrigger])

  /*   const onReturnClick = () => {
      if (isStageFormDirty) {
        setConfirmTrigger('return')
        openConfirm()
      } else {
        confirmReturn(true)
      }
    } */

  /*   const onHomeClick = () => {
      if (isStageFormDirty) {
        setConfirmTrigger('home')
        openConfirm()
      } else {
        confirmHomeClick(true)
      }
    } */

  const onRoadmapAddClick = (phase: RoadmapPhase) => {
    if (isStageFormDirty) {
      setConfirmTrigger('roadmapAdd')
      openConfirm(phase)
    } else {
      confirmRoadmapAdd(true, phase)
    }
  }

  const onRoadmapClick = (roadmap: Roadmap) => {
    if (isStageFormDirty) {
      setConfirmTrigger('roadmapClick')
      openConfirm(roadmap)
    } else {
      confirmRoadmapClick(true, roadmap)
    }
  }

  return (
    <>
      <UserDrawerContent height="100%">
        {isFetching
          ? <Progress />
          : (
            <Stack spacing={2.5}>
              {roadmapsMenu.map(phaseData => (
                <AgreementNavbarPhase
                  phaseData={phaseData}
                  onRoadmapAddClick={onRoadmapAddClick}
                  onRoadmapClick={onRoadmapClick}
                  key={phaseData.phase}
                />
              ))}
            </Stack>
          )
        }
      </UserDrawerContent>

      <ConfirmDialog />
    </>
  )
}

