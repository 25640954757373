import { ChangeEvent, FC } from 'react'
import { IWidgetsSectionItemProps } from './WidgetsSectionItem.types'
import { Draggable } from 'react-beautiful-dnd'
import { Stack, Typography } from '@mui/material'
import { DragHandle as DragHandleIcon } from '@mui/icons-material'
import FieldForm from 'components/FieldForm'
import { dashboardWidgetLabelByValue } from 'api/projects/types'
import { theme } from 'styles/theme'
import { useFormikContext } from 'formik'
import { IWidgetsDrawerFormData } from '../../WidgetsDrawer.types'

export const WidgetsSectionItem: FC<IWidgetsSectionItemProps> = ({ data, index }) => {
  const { values, setFieldValue } = useFormikContext<IWidgetsDrawerFormData>()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const localWidgets = values.widgets.map((item) => {
      return item.name === data.name ? { name: data.name, enabled: e.target.checked } : item
    })

    setFieldValue('widgets', localWidgets)
  }

  const getSwitch = (checked: boolean) => (
    <FieldForm
      version='switch'
      name=''
      checkboxData={{
        checked,
        onChange,
        label: (
          <Typography marginLeft={-2} variant='body2' color={theme.palette.text.dark}>
            {dashboardWidgetLabelByValue[data.name]}
          </Typography>
        ),
      }}
    />
  )

  return (
    <Draggable draggableId={String(index)} index={index}>
      {(provided) => (
        <Stack
          ref={provided.innerRef}
          {...provided.draggableProps}
          direction={'row'}
          alignItems={'center'}
          spacing={'0px'}
          height={'46px'}
        >
          <div {...provided.dragHandleProps}>
            <DragHandleIcon fontSize='medium' color='secondary' />
          </div>

          {data.enabled && getSwitch(true)}

          {!data.enabled && getSwitch(false)}
        </Stack>
      )}
    </Draggable>
  )
}
