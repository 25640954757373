import { Button, Popover } from "@mui/material";
import styled from "styled-components";

export const HelpButton = styled(Button)`
  height: 40px;
  min-width: 40px;
  padding: 5px 10px;
  background-color: ${props => props.theme.palette.bg.lightBlue};
  color: ${props => props.theme.palette.primary.main};

  :hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }
`

export const StyledPopover = styled(Popover)`
 .MuiPopover-paper {
    max-width: 427px;
 }
`