import { api } from '../api'
import {
  CreateAgreementPocketRequest,
  CreateAgreementPocketResponse,
  GetAgreementPocketMenuRequest,
  GetAgreementPocketMenuResponse,
  GetAgreementPocketRequest,
  GetAgreementPocketResponse,
  GetAgreementPocketsInfoRequest,
  GetAgreementPocketsInfoResponse,
  GetAgreementPocketsRequest,
  GetAgreementPocketsResponse,
  GetAgreementPocketStagesRequest,
  GetAgreementPocketStagesResponse,
  GetAgreementPocketTomsRequest,
  GetAgreementPocketTomsResponse,
  StartAgreementProcedureRequest,
  StartAgreementProcedureResponse,
  TransferTomToNextStageRequest,
  TransferTomToNextStageResponse
} from './api.types'

export const agreementPocketApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAgreementPocketsInfo: build.query<GetAgreementPocketsInfoResponse, GetAgreementPocketsInfoRequest>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/pocket/count`,
        method: 'GET',
      }),
      providesTags: ['AgreementPocket'],
    }),
    getAgreementPockets: build.query<GetAgreementPocketsResponse, GetAgreementPocketsRequest>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/pocket/list`,
        method: 'GET',
      }),
      providesTags: ['AgreementPocket'],
    }),
    getAgreementPocketMenu: build.query<GetAgreementPocketMenuResponse, GetAgreementPocketMenuRequest>({
      query: ({ projectId, phase }) => ({
        url: `/project/${projectId}/pocket/menu`,
        params: { phase },
        method: 'GET',
      }),
      providesTags: ['AgreementPocket'],
    }),
    createAgreementPocket: build.mutation<CreateAgreementPocketResponse, CreateAgreementPocketRequest>({
      query: ({ projectId, ...body }) => ({
        url: `/project/${projectId}/pocket/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'AgreementPocket',
        'TomAgreement',
        'AgreementHistory',
      ],
    }),
    getAgreementPocket: build.query<GetAgreementPocketResponse, GetAgreementPocketRequest>({
      query: ({ projectId, pocketId }) => ({
        url: `/project/${projectId}/pocket/${pocketId}/get`,
        method: 'GET',
      }),
      providesTags: ['AgreementPocket'],
    }),
    getAgreementPocketStages: build.query<GetAgreementPocketStagesResponse, GetAgreementPocketStagesRequest>({
      query: ({ projectId, pocketId }) => ({
        url: `/project/${projectId}/pocket/${pocketId}/stages`,
        method: 'GET',
      }),
      providesTags: ['AgreementPocket', { type: 'AgreementPocket', id: 'STAGES-LIST' }],
    }),
    getAgreementPocketToms: build.query<GetAgreementPocketTomsResponse, GetAgreementPocketTomsRequest>({
      query: ({ projectId, pocketId, ...params }) => ({
        url: `/project/${projectId}/pocket/${pocketId}/tom/list`,
        method: 'GET',
        params
      }),
      providesTags: [{ type: 'AgreementPocket', id: 'AGREEMENT-TOMS-LIST' }],
    }),
    transferTomToNextStage: build.mutation<TransferTomToNextStageResponse, TransferTomToNextStageRequest>({
      query: ({ projectId, pocketId, tomId, ...body }) => ({
        url: `/project/${projectId}/pocket/${pocketId}/tom/${tomId}/transfer`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ projectId, pocketId, tomId, ...body }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: updatedTomData } = await queryFulfilled

          // getAgreementPocketToms
          const getAgreementPocketTomsKeys = Object.keys(state.api.queries).filter(key => key.includes('getAgreementPocketToms'))
          const getAgreementPocketTomsLastKey = getAgreementPocketTomsKeys[getAgreementPocketTomsKeys?.length - 1]
          const originalArgs = state.api.queries[getAgreementPocketTomsLastKey]?.originalArgs as GetAgreementPocketTomsRequest
          const stageIdsArg = originalArgs?.stageIds

          setTimeout(() => {
            dispatch(
              agreementPocketApi.util.updateQueryData(
                'getAgreementPocketToms',
                originalArgs,
                (draft) => {
                  const changedTomIndex = draft.data.findIndex(tom => tom.id === updatedTomData.id)

                  if (stageIdsArg?.length && (!updatedTomData.stage || !stageIdsArg.includes(updatedTomData.stage.id))) {
                    const tomsCopy = [...draft.data]
                    tomsCopy.splice(changedTomIndex, 1)
                    draft.data = tomsCopy
                  } else {
                    (changedTomIndex !== undefined) && (draft.data[changedTomIndex] = updatedTomData)
                  }
                }
              )
            )
          }, 1000)

          // getAgreementPocketStages
          dispatch(
            agreementPocketApi.util.invalidateTags([{ type: 'AgreementPocket', id: 'STAGES-LIST' }])
          )
        } catch {
        }
      },
    }),
    startAgreementProcedure: build.mutation<StartAgreementProcedureResponse, StartAgreementProcedureRequest>({
      query: ({ projectId, pocketId, ...body }) => ({
        url: `/project/${projectId}/pocket/${pocketId}/start`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'AgreementPocket',
        'TomAgreement',
        'AgreementHistory',
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAgreementPocketsInfoQuery,
  useGetAgreementPocketsQuery,
  useGetAgreementPocketMenuQuery,
  useCreateAgreementPocketMutation,
  useGetAgreementPocketQuery,
  useGetAgreementPocketStagesQuery,
  useGetAgreementPocketTomsQuery,
  useTransferTomToNextStageMutation,
  useStartAgreementProcedureMutation,
} = agreementPocketApi
