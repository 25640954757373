import { parse, format } from 'date-fns';

// format type of "11.02.2024 12:21:34 GMT+00:00"
// output withHour=true: 11.02.2024, 15:21
// output withHour=false: 11.02.2024

export function convertDateTime(dateTimeString: string, withTime?: boolean, shortYear?: boolean): string {
  const parsedDate = parse(dateTimeString, 'dd.MM.yyyy HH:mm:ss \'GMT\'XXX', new Date())
  const formattedDate = format(
    parsedDate,
    withTime
      ? shortYear
        ? 'dd.MM.yy в HH:mm' : 'dd.MM.yyyy в HH:mm'
      : shortYear
        ? 'dd.MM.yy' : 'dd.MM.yyyy'
  )

  return formattedDate
}