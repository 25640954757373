import { Stack, TextField } from '@mui/material'
import Button from 'components/Button'
import styled from 'styled-components'

interface ISettingsFormRowProps {
  _alignItems?: string
  _direction?: string
}

interface ISettingsFormItemProps extends ISettingsFormRowProps { }

export const SettingsFormHeader = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`

export const SettingsFormRow = styled(Stack) <ISettingsFormRowProps>`
  flex-direction: ${(props) => props._direction || 'row'};
  align-items: ${(props) => props._alignItems || 'center'};
  gap: 40px;
`

export const SettingsFormItem = styled(Stack) <ISettingsFormItemProps>`
  flex-direction: ${(props) => props._direction || 'row'};
  align-items: ${(props) => props._alignItems || 'center'};
  width: 50%;
`

export const SmallButton = styled(Button)`
  min-width: 30px;
  width: 30px;
  height: 30px;
`

export const SettingsFormPlaceholder = styled(TextField)`
  & .MuiFilledInput-root {
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.bg.shades};
    border: 1px solid rgba(92, 110, 140, 0.1);
  }
  & .MuiFilledInput-input {
    padding: 8px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-transform: ${(props) => props.theme.typography.subtitle1.textTransform};
    color: ${(props) => props.theme.palette.text.dark};
    text-overflow: ellipsis;
    border-radius: 8px;

    &::placeholder {
      font-weight: ${(props) => props.theme.typography.body2.fontWeight};
      font-size: ${(props) => props.theme.typography.body2.fontSize};
      letter-spacing: 0.17px;
      text-transform: ${(props) => props.theme.typography.body2.textTransform};
      color: ${(props) => props.theme.palette.disabled};
      opacity: 1;
    }
  }
`
