import * as yup from 'yup'
import { IInternalAgreementControlValues } from './InternalAgreementControl.types';

export const validationSchema = yup.object({
  title: yup.string().trim().required('common:errors.required'),
  companyName: yup.string().required('common:errors.required'),
  personId: yup.number().nullable().required('common:errors.required'),
  duration: yup.string().matches(/^[0-9]+$/, 'Поле должно содержать цифры').nullable().max(3, 'Максимум три символа')
})

export const compareObjects = (a: IInternalAgreementControlValues, b: IInternalAgreementControlValues) => {
  const stringifyObject = (obj: any): string => {
    return JSON.stringify(obj, function (key, value) {
      if (typeof value === 'number') {
        return String(value)
      }
      return value;
    })
  }
  
  return stringifyObject(a) === stringifyObject(b)
}