import styled, { css } from 'styled-components'
import { Stack } from '@mui/material'

export const BaseCardCss = css`
  grid-row: span 2;
  gap: 8px;
  padding: 20px;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 20px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14),
    0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
`

export const SmallTemplateWrapper = styled(Stack)`
  ${BaseCardCss}
`

export const MediumTemplateWrapper = styled(Stack)`
  ${BaseCardCss}
`
