import { Button, Stack } from "@mui/material";
import styled from "styled-components";

export const SectionWrapper = styled(Stack)`
  width: 391px;
  padding: 20px 0 20px 20px;
  border: 1px solid ${props => props.theme.palette.bg.gray};
  border-radius: 16px;
`
export const ClearButton = styled(Button)`
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
`