import { RemarkStatus } from '../../../../../../api/remarks/types'
import { RemarkMenuTitle, RemarkStatusRu, remarkStatusEnToRu } from './Remarks.types'

export const MAX_FILENAME_LENGTH = 30
export const MAX_HEIGHT_REMARK = 700

export interface AvailableStatuses extends Record<RemarkStatus, typeof userRolesArray> {}
export interface RemarkMenuList extends Record<RemarkStatus, Array<RemarkMenuTitle>> {}
export const userRolesArray = Object.values(remarkStatusEnToRu) as Array<RemarkStatusRu>
type RemarkMembers = 'responsible' | 'author' | 'admin'

// Ответственная компания
const availableStatusesForResponsible: Partial<AvailableStatuses> = {
  submitted: ['В работе', 'Устранено', 'Отклонено'],
  accepted: ['Устранено'],
}
// Автор
const availableStatusesForAuthor: Partial<AvailableStatuses> = {
  submitted: ['Снято'],
  accepted: ['Снято'],
  rejected: ['Создано', 'Снято'],
  resolved: ['Создано', 'Закрыто', 'Снято'],
}
// Администратор
const availableStatusesForAdmin: Partial<AvailableStatuses> = {
  submitted: ['В работе', 'Устранено', 'Отклонено', 'Снято'],
  accepted: ['Устранено', 'Снято'],
  rejected: ['Создано', 'Снято'],
  resolved: ['Создано', 'Закрыто', 'Снято'],
}

export const availableStatuses: Record<RemarkMembers, Partial<AvailableStatuses>> = {
  responsible: availableStatusesForResponsible,
  author: availableStatusesForAuthor,
  admin: availableStatusesForAdmin,
}

export const titleStatus: Record<string, string> = {
  'В работе': 'Принять в работу',
  Закрыто: 'Закрыто',
  Отклонено: 'Отклонено',
  Снято: 'Снять замечание',
  Создано: 'Создать повторно',
  Устранено: 'Устранено',
  'Редактировать замечание': 'Редактировать замечание',
  'Удалить замечание': 'Удалить замечание',
}

export const remarkMenuList = (isAdmin: boolean, isSecond: boolean): RemarkMenuList => ({
  submitted: isAdmin ? ['Удалить замечание'] : [],
  accepted: isAdmin ? ['Удалить замечание'] : [],
  rejected: isAdmin ? ['Удалить замечание'] : [],
  resolved: isAdmin ? ['Удалить замечание'] : [],
  closed: isAdmin ? ['Удалить замечание'] : [],
  cancelled: isAdmin ? ['Удалить замечание'] : [],
  created: isSecond ? ['Удалить замечание'] : ['Редактировать замечание', 'Удалить замечание'],
})
