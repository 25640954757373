import React from 'react'
import {
  EmptyObjectsPageData,
  UseGetEmptyPagesDataProps,
  UseGetEmptyPagesDataResponse
} from './useGetEmptyPagesData.types'
import { EmptyPageData } from '../../../../components/EmptyPage/EmptyPage.types'
import { getEmptyPageData } from '../../../Home'
import AddIcon from '@mui/icons-material/Add'
import DownloadingIcon from '@mui/icons-material/Downloading'

export const useGetEmptyPagesData = ({
  onAddClick,
  onUploadClick,
  isTimPage,
}: UseGetEmptyPagesDataProps): UseGetEmptyPagesDataResponse => {

  // Rd objects | TIM
  const emptyObjectsPageData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют объекты, к которым {isTimPage ? 'привязана хотя бы одна ТИМ-модель.' : 'привязан хотя бы один том.'} <br />
      Вы можете добавить объект через форму или загрузить подготовленный список объектов с помощью excel-файла. <br />
      Перед добавлением объекта убедитесь, что данный объект отсутствует в списке Все объекты.
    </>,
    [
      {
        text: 'Добавить объект',
        icon: AddIcon,
        onClick: () => onAddClick()
      },
      {
        text: 'Импорт Excel',
        icon: DownloadingIcon,
        onClick: () => onUploadClick()
      },
    ]
  )

  const emptyObjectsPageDataWithoutControls: EmptyPageData = getEmptyPageData(
    <>У вас еще нет объектов для отображения.</>
  )

  const emptyObjectsFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют объекты, соответствующие результатам запроса.</>
  )


  // Rdp
  const emptyRdpPageData: EmptyPageData = getEmptyPageData(
    <>У вас еще нет разделов РД для отображения.</>,
  )

  const emptyRdpFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют разделы РД, соответствующие результатам запроса.</>
  )

  // Pd
  const emptyPdPageData: EmptyPageData = getEmptyPageData(
    <>У вас еще нет разделов ПД для отображения.</>,
  )

  const emptyPdFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют разделы ПД, соответствующие результатам запроса.</>
  )


  // Ii
  const emptyIiPageData: EmptyPageData = getEmptyPageData(
    <>У вас еще нет разделов изысканий для отображения.</>,
  )

  const emptyIiFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют разделы изысканий, соответствующие результатам запроса.</>
  )


  // Ird
  const emptyIrdPageData: EmptyPageData = getEmptyPageData(
    <>У вас еще нет разделов ИРД для отображения.</>,
  )

  const emptyIrdFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют разделы ИРД, соответствующие результатам запроса.</>
  )

  // Common
  const emptyPageData: EmptyObjectsPageData = {
    emptyObjectsPageData,
    emptyObjectsPageDataWithoutControls,
    emptyObjectsFilteredData,

    emptyRdpPageData,
    emptyRdpFilteredData,

    emptyPdPageData,
    emptyPdFilteredData,

    emptyIiPageData,
    emptyIiFilteredData,

    emptyIrdPageData,
    emptyIrdFilteredData,
  }

  return {
    emptyPageData,
  }
}
