import { Box, Pagination, Stack } from '@mui/material'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import FilterSelect from '../../../components/FilterSelect'
import Tabs from '../../../components/Tabs'
import { TabsEntities } from '../../../components/Tabs/Tabs.types'
import { theme } from '../../../styles/theme'
import { HomeBottomLegendProps } from './HomeBottomLegend.types'

const HomeBottomLegend = <T extends TabsEntities>({
  tabsData,
  onTabChange,
  currentTab,
  page,
  countPagination,
  numberRows,
  onChangeLimit,
  onChangePage,
  limit,
}: HomeBottomLegendProps<T> & { children?: ReactNode }) => {
  const { t } = useTranslation('user')

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ pb: 1.5 }}>
        <Tabs<T> currentTab={currentTab} onTabChange={onTabChange} tabsData={tabsData} />

        <Stack spacing={5} direction='row' justifyContent='center' alignItems='center'>
          <FilterSelect items={numberRows!} value={limit!} onChange={onChangeLimit!} startIcon={t('header.rows')} />
          <Pagination
            onChange={(e, v) => onChangePage!(v)}
            count={countPagination}
            shape='rounded'
            page={page}
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: 'rgba(109, 154, 220, 0.2)',
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '20px !important',
                },
              },
            }}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

export default HomeBottomLegend
