import { Box, Stack } from "@mui/material";
import styled from "styled-components";

export const RemarkHistoryContainer = styled(Stack)`
  // flex: 1;
  overflow: hidden scroll;
  overscroll-behavior: contain;
  padding: 0 7px 0 20px;

  // &::-webkit-scrollbar {
  //   display: none;
  // }

  &:hover {
    // padding-right: 7px;

    // &::-webkit-scrollbar {
    //   display: block;
    // }
  }
  background-color: ${(props) => props.theme.palette.bg.white};
  //border-radius: 0px 32px 32px 32px;
  //box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  // @supports (-moz-appearance: none) {
  //   scrollbar-width: none;

  //   &:hover {
  //     padding-right: 12px;
  //     scrollbar-width: thin;
  //   }
  // }
`;

export const ReplyContainer = styled(Box)`
  border-radius: 16px;
  box-shadow: 0px 4px 6px -2px #10182826, 0px 12px 16px -4px #10182847;
`;
