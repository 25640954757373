import { Select, Stack } from "@mui/material";
import styled from "styled-components";
import { ActionButton } from "../../PragmaPdfViewer.styles";

export const ZoomActionButton = styled(ActionButton)`
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  height: 24px;
  width: 24px;
`
export const StyledSelect = styled(Select)`
  border-radius: 8px;
  height: 34px;

  & .MuiSelect-select {
    padding: 5px 6px;
    padding-right: 6px !important;
    display: flex;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #2B36481F;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: #2b36488c;
  }

  & .MuiSvgIcon-root {
    /* font-size: 20px !important; */
    display: none;
  }
`