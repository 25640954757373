import { Api } from '@reduxjs/toolkit/dist/query'
import { TomIiBaseResponse, tomIiApi } from 'api/iiPhase'
import { TomIrdBaseResponse, tomIrdApi } from 'api/irdPhase'
import { TomPdBaseResponse, tomPdApi } from 'api/pdPhase'
import { TomRdBaseResponse, tomRdApi } from 'api/rdPhase'
import { TomType } from 'types/tom'

import { setCurrentDocument, setTom } from '../../../store/slices/documentsPages/tom'
import { api } from '../../api'
import { GetTomCmnVersionsResponse } from '../tomVersion'
import {
  DeleteTomCmnChangeRequest,
  DownloadTomCmnChangeRequest,
  DownloadTomCmnChangeResponse,
  GetTomCmnChangesRequest,
  GetTomCmnChangesResponse,
  ISetChangeModeRequest,
  ISetChangeModeResponse,
  TomCmnChangeBaseResponse,
  UploadTomCmnChangeRequest,
} from './tomChangeApi.types'

export const tomCmnChangeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomCmnChanges: build.query<GetTomCmnChangesResponse, GetTomCmnChangesRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-cmn/pdf/change/list`,
        params,
        method: 'GET',
      }),
      transformResponse: (res: GetTomCmnChangesResponse, _, requestData) => {
        const { id: tomId } = requestData
        const updatedChanges = res.data.map((change) => ({ ...change, tomId }))

        return {
          ...res,
          data: updatedChanges,
        }
      },
      providesTags: ['TomCmnDoc'],
    }),
    uploadTomCmnChange: build.mutation<TomCmnChangeBaseResponse, UploadTomCmnChangeRequest>({
      query: ({ id, file, change }) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('change', change.toString())

        return {
          url: `/project/${id}/tom-cmn/pdf/change/upload`,
          method: 'POST',
          body: formData,
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedChangeData } = await queryFulfilled
          const uploadedChange = uploadedChangeData.data

          // getTomCmnChanges
          const getTomCmnChangesKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomCmnChanges'))
          const getTomCmnChangesLastKey = getTomCmnChangesKeys[getTomCmnChangesKeys?.length - 1]

          dispatch(
            tomCmnChangeApi.util.updateQueryData(
              'getTomCmnChanges',
              state.api.queries[getTomCmnChangesLastKey]?.originalArgs as GetTomCmnChangesRequest,
              (draft) => {
                const indexForPlace = draft.data.findIndex((change) => uploadedChange.change > change.change)
                draft.data.splice(indexForPlace, 0, uploadedChange)
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: uploadedChange }))
        } catch {}
      },
    }),
    deleteTomCmnChange: build.mutation<TomCmnChangeBaseResponse, DeleteTomCmnChangeRequest>({
      query: ({ id, change }) => ({
        url: `/project/${id}/tom-cmn/pdf/change/delete`,
        params: { change },
        method: 'DELETE',
      }),
      invalidatesTags: ['TomCmnDoc'],
    }),
    downloadTomCmnChange: build.mutation<DownloadTomCmnChangeResponse, DownloadTomCmnChangeRequest>({
      query: ({ id, change }) => ({
        url: `/project/${id}/tom-cmn/pdf/change/download`,
        params: { change },
        method: 'GET',
      }),
    }),
    // getTomCmnChangeLink: build.mutation<GetTomCmnChangeLinkResponse, GetTomCmnChangeLinkRequest>({
    //   query: ({ id, change }) => ({
    //     url: `/project/tom-cmn/${id}/pdf/change/get-link`,
    //     params: { change },
    //     method: 'GET',
    //   }),
    // }),
    setChangeMode: build.mutation<ISetChangeModeResponse, ISetChangeModeRequest>({
      query: ({ projectId, body }) => ({
        url: `/project/${projectId}/change-mode`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ body, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const { tom, changeMode } = body
          const { id, type } = tom

          const apiData: Record<TomType, { apiName: Api<any, any, any, any, any>; enpointName: string }> = {
            ИРД: { apiName: tomIrdApi, enpointName: 'getTomIrdById' },
            ИИ: { apiName: tomIiApi, enpointName: 'getTomIiById' },
            ПД: { apiName: tomPdApi, enpointName: 'getTomPdById' },
            РД: { apiName: tomRdApi, enpointName: 'getTomRdById' },
          }

          const currentApiData = apiData[type]
          const { apiName, enpointName } = currentApiData

          dispatch(
            apiName.util.updateQueryData(
              enpointName,
              { id },
              (draft: TomIrdBaseResponse | TomIiBaseResponse | TomPdBaseResponse | TomRdBaseResponse) => {
                draft.data.features.changeMode = changeMode
              },
            ),
          )
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomCmnChangesQuery,
  useUploadTomCmnChangeMutation,
  useDeleteTomCmnChangeMutation,
  useDownloadTomCmnChangeMutation,
  useSetChangeModeMutation,
} = tomCmnChangeApi
