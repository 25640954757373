import React, { FC, useCallback, useState } from 'react'
import { FormPocketDrawerProps } from './FormPocketDrawer.types'
import { Box, Drawer, Typography } from '@mui/material'
import { ProjectFullExportDrawerTopBar, ProjectFullExportDrawerWrapper } from './FormPocketDrawer.styles'
import { theme } from '../../../../styles/theme'
import Divider from '../../../../components/Divider'
import { FormPocketDrawerForm } from './components/FormPocketDrawerForm'
import { useTypedSelector } from '../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'

export const FormPocketDrawer: FC<FormPocketDrawerProps> = ({
  open,
  onDrawerClose,
  initialPhase,
  showPhaseSwitcher
}) => {

  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => onDrawerClose(dirty)}
    >
      <ProjectFullExportDrawerWrapper>
        <ProjectFullExportDrawerTopBar direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
          <Box>
            <Typography variant='h1' color={theme.palette.primary.main}>
              Сформировать накладную на согласование
            </Typography>
          </Box>

          {/*<Button*/}
          {/*  href='https://gge.ru/'*/}
          {/*  target='_blank'*/}
          {/*  size='small'*/}
          {/*  variant='text'*/}
          {/*  endIcon={<ArrowForwardIcon fontSize='small' />}*/}
          {/*  style={{ height: 29 }}*/}
          {/*>*/}
          {/*  <Typography*/}
          {/*    variant='buttonSmall'*/}
          {/*    fontWeight={400}*/}
          {/*    fontSize={14}*/}
          {/*    letterSpacing={0.17}*/}
          {/*    textTransform='none'*/}
          {/*  >*/}
          {/*    Перейти на сайт главгосэкспертизы*/}
          {/*  </Typography>*/}
          {/*</Button>*/}
        </ProjectFullExportDrawerTopBar>

        <Divider />

        <FormPocketDrawerForm
          onFormChange={onFormChange}
          onDrawerClose={onDrawerClose}
          initialPhase={initialPhase || selectedProjectPhase}
          showPhaseSwitcher={showPhaseSwitcher}
        />
      </ProjectFullExportDrawerWrapper>
    </Drawer>
  )
}
