import { FC } from 'react'
import { LegendColors } from '../../../types/global'
import { theme } from '../../../styles/theme'

interface BookIconProps {
  color: LegendColors
  width?: string
  height?: string
}

const BookFilledIcon: FC<BookIconProps> = ({color, height, width}) => {
  return (
    <svg width={width || '16px'} height={height || '16px'}>
      <path
        viewBox="0 0 16 16"
        fill-rule="evenodd" clip-rule="evenodd" d="M2.66663 2.6032C2.66663 1.96829 3.33329 1.33337 3.99996 1.33337H12C12.6666 1.33337 13.3333 1.96829 13.3333 2.6032V12.1269C13.3333 12.4776 13.0348 12.7618 12.6666 12.7618H4.07138C4.15441 13.0786 4.32818 13.3615 4.68143 13.3967H12.6666C13.0348 13.3967 13.3333 13.681 13.3333 14.0317C13.3333 14.3823 13.0348 14.6666 12.6666 14.6666L9.93682 14.6666C7.62725 14.6667 5.02463 14.6668 4.66563 14.6666C3.28725 14.6633 2.66663 13.2509 2.66663 12.1269V2.6032ZM7.33329 8.95235H10.6666V10.2222H7.33329V8.95235ZM10.6666 6.41268H7.33329V7.68251H10.6666V6.41268ZM7.33329 3.87303H10.6666V5.14285H7.33329V3.87303ZM6.66663 3.87303H5.33329V5.14285H6.66663V3.87303ZM5.33329 6.41268H6.66663V7.68251H5.33329V6.41268ZM6.66663 8.95235H5.33329V10.2222H6.66663V8.95235Z"
        fill={theme.palette.legends[color]}
      />
    </svg>
  )
}

export default BookFilledIcon