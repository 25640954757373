import styled from 'styled-components'
import { Stack } from '@mui/material'
import { AGREEMENT_CARD_GAP } from '../ProjectAgreementCardLine'

export const ProjectAgreementSchemeWrapper = styled(Stack)`
  position: relative;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 16px;
`

export const ProjectAgreementSchemeCardsWrapper = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: ${AGREEMENT_CARD_GAP}px;
  min-height: 450px;
  height: 100%;
  padding: 30px ${AGREEMENT_CARD_GAP}px;
  overflow-y: auto;
`
