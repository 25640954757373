import { UseGetTomStoreUpdateHandlersResponse } from './useGetTomStoreUpdateHandlers.types'
import { tomRdApi, tomRdChangeApi, tomRdVersionApi } from '../../../../../../api/rdPhase'
import { tomPdApi, tomPdChangeApi, tomPdVersionApi } from '../../../../../../api/pdPhase'
import { tomIiApi, tomIiChangeApi, tomIiVersionApi } from '../../../../../../api/iiPhase'
import { ProjectPhase } from '../../../../../../types/project'
import { useAppDispatch, useTypedSelector } from '../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { tomIrdApi, tomIrdChangeApi, tomIrdVersionApi } from '../../../../../../api/irdPhase'
import { tomCmnChangeApi, tomCmnVersionApi } from '../../../../../../api/cmnTom'
import { isCmnTomSelector } from '../../../../../../store/slices/documentsPages/tom'

export const useGetTomStoreUpdateHandlers = (): UseGetTomStoreUpdateHandlersResponse => {
  const dispatch = useAppDispatch()

  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)

  const removeCurrentDocumentFromLocalStorage = () => {
    localStorage.setItem('currentDocStorageData', '{}')
  }

  // Rd
  const updateRdTomStore = () => {
    dispatch(tomRdApi.util.invalidateTags(['TomRd']))
  }

  const updateRdVersionStore = (clearCurrentDocument: boolean = false) => {
    dispatch(tomRdVersionApi.util.invalidateTags(['TomRdDoc']))
    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }

  const updateRdChangeStore = (clearCurrentDocument: boolean = false) => {
    dispatch(tomRdChangeApi.util.invalidateTags(['TomRdDoc']))
    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }

  const updateFullRdTomStore = (clearCurrentDocument: boolean = false) => {
    updateRdTomStore()
    updateRdVersionStore(clearCurrentDocument)
    updateRdChangeStore(clearCurrentDocument)

    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }


  // Pd
  const updatePdTomStore = () => {
    dispatch(tomPdApi.util.invalidateTags(['TomPd']))
  }

  const updatePdVersionStore = (clearCurrentDocument: boolean = false) => {
    dispatch(tomPdVersionApi.util.invalidateTags(['TomPdDoc']))
    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }

  const updatePdChangeStore = (clearCurrentDocument: boolean = false) => {
    dispatch(tomPdChangeApi.util.invalidateTags(['TomPdDoc']))
    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }

  const updateFullPdTomStore = (clearCurrentDocument: boolean = false) => {
    updatePdTomStore()
    updatePdVersionStore(clearCurrentDocument)
    updatePdChangeStore(clearCurrentDocument)

    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }


  // Ii
  const updateIiTomStore = () => {
    dispatch(tomIiApi.util.invalidateTags(['TomIi']))
  }

  const updateIiVersionStore = (clearCurrentDocument: boolean = false) => {
    dispatch(tomIiVersionApi.util.invalidateTags(['TomIiDoc']))
    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }

  const updateIiChangeStore = (clearCurrentDocument: boolean = false) => {
    dispatch(tomIiChangeApi.util.invalidateTags(['TomIiDoc']))
    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }

  const updateFullIiTomStore = (clearCurrentDocument: boolean = false) => {
    updateIiTomStore()
    updateIiVersionStore(clearCurrentDocument)
    updateIiChangeStore(clearCurrentDocument)

    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }


  // Ird
  const updateIrdTomStore = () => {
    dispatch(tomIrdApi.util.invalidateTags(['TomIrd']))
  }

  const updateIrdVersionStore = (clearCurrentDocument: boolean = false) => {
    dispatch(tomIrdVersionApi.util.invalidateTags(['TomIrdDoc']))
    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }

  const updateIrdChangeStore = (clearCurrentDocument: boolean = false) => {
    dispatch(tomIrdChangeApi.util.invalidateTags(['TomIrdDoc']))
    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }

  const updateFullIrdTomStore = (clearCurrentDocument: boolean = false) => {
    updateIrdTomStore()
    updateIrdVersionStore(clearCurrentDocument)
    updateIrdChangeStore(clearCurrentDocument)

    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }


  // Cmn
  const updateCmnVersionStore = (clearCurrentDocument: boolean = false) => {
    dispatch(tomCmnVersionApi.util.invalidateTags(['TomCmnDoc']))

    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }

  const updateCmnChangeStore = (clearCurrentDocument: boolean = false) => {
    dispatch(tomCmnChangeApi.util.invalidateTags(['TomCmnDoc']))

    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }

  const updateFullCmnTomStore = (clearCurrentDocument: boolean = false) => {
    updateCmnVersionStore(clearCurrentDocument)
    updateCmnChangeStore(clearCurrentDocument)

    clearCurrentDocument && removeCurrentDocumentFromLocalStorage()
  }


  // Common
  const updateTomStore: Record<ProjectPhase, () => void> = {
    'Рабочая документация': updateRdTomStore,
    'Проектная документация': updatePdTomStore,
    'Инженерные изыскания': updateIiTomStore,
    'Сбор исходных данных': updateIrdTomStore,
  }

  const updateVersionStoreByPhase: Record<ProjectPhase, () => void> = {
    'Рабочая документация': updateRdVersionStore,
    'Проектная документация': updatePdVersionStore,
    'Инженерные изыскания': updateIiVersionStore,
    'Сбор исходных данных': updateIrdVersionStore,
  }

  const updateVersionStore = isCmnTom ? updateCmnVersionStore : updateVersionStoreByPhase[selectedProjectPhase]

  const updateChangeStoreByPhase: Record<ProjectPhase, () => void> = {
    'Рабочая документация': updateRdChangeStore,
    'Проектная документация': updatePdChangeStore,
    'Инженерные изыскания': updateIiChangeStore,
    'Сбор исходных данных': updateIrdChangeStore,
  }

  const updateChangeStore = isCmnTom ? updateCmnChangeStore : updateChangeStoreByPhase[selectedProjectPhase]

  const updateFullTomStoreByPhase: Record<ProjectPhase, () => void> = {
    'Рабочая документация': updateFullRdTomStore,
    'Проектная документация': updateFullPdTomStore,
    'Инженерные изыскания': updateFullIiTomStore,
    'Сбор исходных данных': updateFullIrdTomStore,
  }

  const updateFullTomStore = isCmnTom ? updateFullCmnTomStore : updateFullTomStoreByPhase[selectedProjectPhase]

  return {
    updateTomStore: updateTomStore[selectedProjectPhase],
    updateVersionStore: updateVersionStore,
    updateChangeStore: updateChangeStore,
    updateFullTomStore: updateFullTomStore,
  }
}
