import styled from 'styled-components'
import { MenuItem, Typography } from '@mui/material'
import FieldForm from '../../../FieldForm'

export const ObjectsSelect = styled(FieldForm)`
  & .MuiSelect-select {
    text-align: left;
    background-color: ${props => props.theme.palette.bg.white};
  }


  & .MuiSelect-select {
    padding: 10px 16px;
  }

  & .MuiSvgIcon-root {
    right: 14px;
    font-size: 16px;

    & path {
      fill: ${props => props.theme.palette.text.dark};
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.3);
    border-radius: 6px;
  }
`

export const ObjectsSelectMenuItem = styled(MenuItem)`
  &:hover {
    color: ${props => props.theme.palette.primary.main};
    background-color: ${props => props.theme.palette.bg.shades};
  }

  &.Mui-selected {
    background-color: ${props => props.theme.palette.bg.white};
  }

  &.Mui-focusVisible,
  &.Mui-selected:hover {
    background-color: ${props => props.theme.palette.bg.gray} !important;
  }

  & .MuiCheckbox-root {
    margin-right: 16px;
    padding: 0;

    & .MuiSvgIcon-root {
      fill: ${props => props.theme.palette.primary.main};

    }
  }
`
export const EllipsisText = styled(Typography)<{$maxWidth?: string}>`
  max-width: ${props => props.$maxWidth ? props.$maxWidth : 'inherit'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
