import qs from 'qs'

import { setTom } from '../../../store/slices/documentsPages/tom'
import { ExcelUploadResponse } from '../../../types/global'
import { api } from '../../api'
import { ExportObjectsRequest } from '../../rdPhase'
import { GetDetailedSidebarIiRequest, sidebarIiApi } from './sidebar'
import {
  CreateTomIiRequest,
  DeleteTomIiRequest,
  EditTomIiRequest,
  GetTomIiByIdRequest,
  GetTomIiLinkExampleResponse,
  GetTomsIiFullRequest,
  GetTomsIiFullResponse,
  GetTomsIiRequest,
  GetTomsIiResponse,
  ITerminateTomIiRequest,
  ITerminateTomIiResponse,
  TomIiBaseResponse,
  TomIiMultiUploadRequest,
  TomIiMultiUploadResponse,
  UploadTomIiRequest,
} from './tomApi.types'

export const tomIiApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomsIi: build.query<GetTomsIiResponse, GetTomsIiRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-ii/list?${qs.stringify(params, { indices: false })}`,
        method: 'GET',
      }),
      providesTags: [
        'TomIi',
        { type: 'TomIi', id: 'UPDATE' },
        { type: 'TomIi', id: 'UPLOAD' },
        { type: 'TomIi', id: 'DELETE' },
        { type: 'TomIi', id: 'UPLOAD_MASS' },
        { type: 'TomIi', id: 'ARCHIVE_TOMS' },
      ],
    }),
    getTomsIiFull: build.query<GetTomsIiFullResponse, GetTomsIiFullRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-ii/list-full?${qs.stringify(params, { indices: false })}`,
        method: 'GET',
      }),
      providesTags: [
        'TomIi',
        { type: 'TomIi', id: 'UPDATE' },
        { type: 'TomIi', id: 'UPLOAD' },
        { type: 'TomIi', id: 'DELETE' },
        { type: 'TomIi', id: 'UPLOAD_MASS' },
        { type: 'TomIi', id: 'ARCHIVE_TOMS' },
      ],
    }),
    createTomIi: build.mutation<TomIiBaseResponse, CreateTomIiRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/tom-ii/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'TomIi', id: 'UPDATE' }, { type: 'Projects', id: 'dashboard' }],
    }),
    uploadTomIi: build.mutation<ExcelUploadResponse, UploadTomIiRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${id}/tom-ii/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
    }),
    exportTomsIi: build.mutation<Blob, ExportObjectsRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom-ii/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    tomIiMultiUpload: build.mutation<TomIiMultiUploadResponse, TomIiMultiUploadRequest>({
      query: ({ id, files }) => {
        const formData = new FormData()
        files.forEach((file) => {
          formData.append('file', file)
        })

        return {
          url: `/project/${id}/tom-ii/mass-update`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: [{ type: 'TomIi', id: 'UPLOAD_MASS' }],
    }),
    getTomIiById: build.query<TomIiBaseResponse, GetTomIiByIdRequest>({
      query: ({ id }) => ({
        url: `/project/tom-ii/${id}/get`,
        method: 'GET',
      }),
      providesTags: ['TomIi', { type: 'TomIi', id: 'UPLOAD' }],
    }),
    editTomIi: build.mutation<TomIiBaseResponse, EditTomIiRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/tom-ii/${id}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'TomIi', id: 'UPDATE' }, { type: 'Projects', id: 'dashboard' }],
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const queries = getState().api.queries

          const { data: updatedTomData } = await queryFulfilled
          const updatedTom = updatedTomData.data

          dispatch(setTom({ tom: updatedTom }))

          // getTomIiById
          dispatch(
            tomIiApi.util.updateQueryData('getTomIiById', { id: updatedTom.id }, (draft) => {
              draft.data = updatedTom
            }),
          )

          // getDetailedSidebarIi
          const getDetailedSidebarIiKey = Object.keys(queries).find((key) => key.includes('getDetailedSidebarIi'))

          if (getDetailedSidebarIiKey) {
            dispatch(
              sidebarIiApi.util.updateQueryData(
                'getDetailedSidebarIi',
                queries[getDetailedSidebarIiKey]!.originalArgs as GetDetailedSidebarIiRequest,
                (draft) => {
                  const changedDraft = draft.menuList.map((item) => {
                    if (item.mark === updatedTom.iiMark) {
                      return {
                        ...item,
                        tomList: item.tomList.map((tom) => {
                          if (tom.tomId === updatedTom.id) {
                            return { ...tom, title: updatedTom.title }
                          }
                          return tom
                        }),
                      }
                    }
                    return item
                  })
                  draft.menuList = changedDraft
                },
              ),
            )
          }
        } catch {}
      },
    }),
    deleteTomIi: build.mutation<TomIiBaseResponse, DeleteTomIiRequest>({
      query: ({ id }) => ({
        url: `/project/tom-ii/${id}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(tomIiApi.util.invalidateTags([{ type: 'TomIi', id: 'DELETE' }, { type: 'Projects', id: 'dashboard' }]))
        } catch {}
      },
    }),
    getTomIiLinkExample: build.mutation<GetTomIiLinkExampleResponse, void>({
      query: () => ({
        url: `/project/tom-ii/link-example`,
        method: 'GET',
      }),
    }),
    terminateTomIi: build.mutation<ITerminateTomIiResponse, ITerminateTomIiRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/toms/${tomId}/features/annull`,
        method: 'POST',
        params,
      }),
      async onQueryStarted({ tomId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTomData } = await queryFulfilled
          const updatedAnnulment = updatedTomData.annulment

          // getTomIiById
          dispatch(
            tomIiApi.util.updateQueryData('getTomIiById', { id: tomId }, (draft) => {
              draft.data.features.annulment = updatedAnnulment
            }),
          )
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomsIiQuery,
  useGetTomsIiFullQuery,
  useCreateTomIiMutation,
  useUploadTomIiMutation,
  useExportTomsIiMutation,
  useTomIiMultiUploadMutation,
  useGetTomIiByIdQuery,
  useEditTomIiMutation,
  useDeleteTomIiMutation,
  useGetTomIiLinkExampleMutation,
  useTerminateTomIiMutation,
} = tomIiApi
