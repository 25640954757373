import React, { FC, SyntheticEvent } from 'react'
import { PocketViewNavbarProps } from './PocketViewNavbar.types'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DoneIcon from '@mui/icons-material/Done';
import { Stack, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Button from '../../../../components/Button'
import { theme } from '../../../../styles/theme'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import HomeIcon from '../../../../assets/icons/HomeIcon'
import { ScrollableContainer } from '../../../../styles/global/ScrollableContainer'
import { AccordionButtonText, PocketStageStatusSubtitle, PocketStageStatusTitle, PocketStageWrapper, PocketViewNavbarContent } from './PocketViewNavbar.styles'
import { AgreementDrawer, AgreementDrawerHeader, AgreementLogoWrapper, AgreementDesignLogo } from '../../../../layouts/AgreementLayout/AgreementNavbar.styles'
import { AccordionDocButton, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../../../Docs/components/ObjectAccordion/ObjectAccordion.styles';
import { ExpiredCheckboxControl } from '../../../Docs/components/DocsTopLegend/DocsTopLegend.styles';
import { StyledCheckbox } from '../../../../components/FieldForm/styles';

export const PocketViewNavbar: FC<PocketViewNavbarProps> = ({
  stages,
  filterExpired,
  filterStageIds,
  onStageClick,
  statusTabValue,
  onStatusTabChange,
  onFilterExpiredChange,
  agreedTomsCount,
}) => {
  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const onReturnClick = () => {
    navigate(-1)
  }

  const onHomeClick = () => {
    navigate(`/project/${projectId}`)
  }

  const onAgreedCardClick = (e: SyntheticEvent) => {
    statusTabValue === 'all' && onStatusTabChange(e, 'ПРОЙДЕНО')
    statusTabValue === 'ПРОЙДЕНО' && onStatusTabChange(e, 'all')
  }

  return (
    <AgreementDrawer
      variant='permanent'
    >
      <AgreementDrawerHeader direction='row' justifyContent='space-between' spacing={1}>
        <Link to='/home'>
          <AgreementLogoWrapper>
            <AgreementDesignLogo />
          </AgreementLogoWrapper>
        </Link>

        <Stack direction='row' alignItems='center' spacing={1.25}>
          <Button icon bgColor={theme.palette.bg.shades} color='primary' variant='text' onClick={() => onReturnClick()}>
            <ArrowBackIcon />
          </Button>
          <Button icon bgColor={theme.palette.bg.shades} color='primary' variant='text' onClick={() => onHomeClick()}>
            <HomeIcon />
          </Button>
        </Stack>
      </AgreementDrawerHeader>

      <PocketViewNavbarContent>
        <ScrollableContainer spacing={2}>
          <PocketStageWrapper selected={statusTabValue === 'ПРОЙДЕНО'} onClick={onAgreedCardClick}>
            <Stack direction='row' spacing={1}>
              <DoneIcon color='info' fontSize='small' />
              <PocketStageStatusTitle>Процедура завершена</PocketStageStatusTitle>
            </Stack>
            <PocketStageStatusSubtitle>
              Томов: {agreedTomsCount}
            </PocketStageStatusSubtitle>
          </PocketStageWrapper>

          <Typography textTransform='uppercase' fontWeight={500} fontSize={14}>Фильтры</Typography>

          <StyledAccordion defaultExpanded>
            <StyledAccordionSummary
              sx={{padding: '6px 8px!important'}}
              expandIcon={
                <ExpandMoreIcon
                  fontSize='medium'
                  color='primary'
                />}
            >
              <Stack direction='row' spacing={1} alignItems='center'>
                <FilterAltIcon fontSize="medium" color="primary" />
                <Typography variant='buttonSmall'>Этапы согласования</Typography>
              </Stack>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Stack pl={1.5}>
                <AccordionDocButton
                  onClick={() => !filterStageIds.length ? undefined : onStageClick(null)}
                  variant='text'
                  startIcon={<DescriptionOutlinedIcon color="dark" fontSize="small" />}
                >
                  <Typography variant='body1' fontSize={14}
                    fontWeight={!filterStageIds.length ? 500 : 400}>
                    Все этапы
                  </Typography>
                </AccordionDocButton>
                {stages.map(stage =>
                  <AccordionDocButton
                    key={stage.id}
                    onClick={() => onStageClick(stage.id)}
                    variant='text'
                    startIcon={<DescriptionOutlinedIcon color="dark" fontSize="small" />}
                  >
                    <AccordionButtonText variant='body1'
                      fontWeight={filterStageIds.includes(stage.id) ? 500 : 400}>
                      {stage.title}
                    </AccordionButtonText>
                  </AccordionDocButton>
                )}
              </Stack>
            </StyledAccordionDetails>
          </StyledAccordion>

          <ExpiredCheckboxControl
            sx={{paddingLeft: 1}}
            control={
              <StyledCheckbox
                checked={filterExpired}
                onChange={onFilterExpiredChange}
                icon={filterExpired
                  ? <CheckBoxIcon />
                  : <CheckBoxOutlineBlankIcon />
                }
              />
            }
            label={<Typography textTransform='uppercase' fontWeight={500} fontSize={14}>Просрочено</Typography>
            }
          />
        </ScrollableContainer>
      </PocketViewNavbarContent>
    </AgreementDrawer>
  )
}
