import { Avatar, Collapse, List, ListItemAvatar, Stack } from "@mui/material"
import { ListHeader } from "../ListHeader"
import { useState } from "react"
import { EmployeesProps } from "./Employees.types"
import { CloseButton } from "../Companies"
import { StyledItemText, StyledListItem } from "./Employees.styles"

export const Employees = ({
  title,
  employees,
  tabUsed,
  changeStatus,
  onAddEmployeesClick,
  onDeleteEmployeesClick
}: EmployeesProps) => {


  const [open, setOpen] = useState(true)
  const setIsOpen = (value: boolean) => setOpen(value)

  const onAddClick = () => {
    onAddEmployeesClick(employees[0].company)
  }

  const onDeleteClick = () => {
    onDeleteEmployeesClick(employees)
  }

  if (!employees.length) return null
  return (
    <Stack spacing="12px">
      <ListHeader
        open={open}
        title={title}
        tabUsed={tabUsed}
        setIsOpen={setIsOpen}
        onAddClick={onAddClick}
        onDeleteClick={onDeleteClick} />

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component={Stack} spacing='12px' disablePadding>
          {employees.map((person) => (
            <StyledListItem
              key={person.id}
              disablePadding
              secondaryAction={
                <CloseButton
                  onClick={() => changeStatus(person.id, 'close')}>
                  Закрыть
                </CloseButton>
              }>
              <ListItemAvatar>
                <Avatar src={person.avatar} sx={{ width: "32px", height: "32px", marginRight: "16px" }} />
              </ListItemAvatar>
              <StyledItemText
                primary={person.name}
                secondary={person.position} />
            </StyledListItem>
          ))}
        </List>
      </Collapse>
    </Stack>
  )
}