import { FC } from 'react'
import { AgreementNavbarPhaseProps } from './AgreementNavbarPhase.types'
import { AgreementNavbarPhaseWrapper } from './AgreementNavbarPhase.styles'
import { Divider, IconButton, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { AgreementNavbarPhaseItem } from '../AgreementNavbarPhaseItem/AgreementNavbarPhaseItem'
import InfoIcon from '../../../../../assets/icons/InfoIcon'
import { useTypedSelector } from '../../../../../store/store'
import { currentRoadmapSelector, roadmapFormModeSelector } from '../../../../../store/slices/agreement'
import { profileSelector } from '../../../../../store/slices/profile'

export const AgreementNavbarPhase: FC<AgreementNavbarPhaseProps> = ({
  phaseData,
  onRoadmapAddClick,
  onRoadmapClick,
}) => {

  const { title, phase, roadmaps } = phaseData
  const currentRoadmap = useTypedSelector(currentRoadmapSelector)
  const { role } = useTypedSelector(profileSelector)
  const roadmapFormMode = useTypedSelector(roadmapFormModeSelector)
  const isAdmin = role === 'admin'

  return (
    <AgreementNavbarPhaseWrapper spacing={1.5}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
        <Typography variant='body2' className='phaseTitle'>
          {title}
        </Typography>
        {isAdmin && <IconButton onClick={() => onRoadmapAddClick(phase)}>
          <AddIcon fontSize='medium' color='primary' />
        </IconButton>}
      </Stack>

      <Divider flexItem />

      {!roadmaps?.length
        ? (
          <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
            <InfoIcon color='disabled' />
            <Typography variant='body2' className='emptyPhase'>
              Нет схем согласования
            </Typography>
          </Stack>
        )
        : roadmaps.map(roadmap => (
          <AgreementNavbarPhaseItem
            roadmap={roadmap}
            onRoadmapClick={onRoadmapClick}
            selected={currentRoadmap.id === roadmap.id && roadmapFormMode !== 'create'}
            key={roadmap.id}
          />
        ))
      }
    </AgreementNavbarPhaseWrapper>
  )
}
