import React, { useCallback, useEffect, useState } from 'react'
import { ObjectsDrawerProps } from './ObjectsDrawer.types'
import { Box, Drawer, Stack, Typography } from '@mui/material'
import { ObjectsDrawerTopBar, ObjectsDrawerWrapper } from './styles'
import { theme } from '../../../../styles/theme'
import Divider from '../../../../components/Divider'
import ObjectsDrawerForm from './ObjectsDrawerForm'
import { ObjectBaseResponse, useDeleteObjectMutation, useGetObjectByIdQuery } from '../../../../api/rdPhase'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import Progress from '../../../../components/Progress'
import DeleteConfirm from '../../../../components/DeleteConfirm'
import Button from '../../../../components/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'

const ObjectsDrawer: React.FC<ObjectsDrawerProps> = ({ projectId, objectId, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const isTimPage = location.pathname.includes('tim')
  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  const { data: ObjectData, isLoading, isFetching } = useGetObjectByIdQuery({
    id: objectId!
  }, {
    skip: !objectId,
  })

  const { data: currentObject } = ObjectData || {}

  const onDeleteButtonClick = useCallback(() => {
    setIsDeleteClicked(true)
  }, [])

  const [deleteObject, deleteObjectResponse] = useDeleteObjectMutation()

  const onDeleteSubmit = useCallback(() => {
    // TODO добавить проверку на отсутствие моделей
    if (objectId) {
      deleteObject({ id: objectId })
    }
  }, [currentObject])

  const onDeleteCancel = useCallback(() => {
    setIsDeleteClicked(false)
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    onClose(dirty, immediately)
  }, [])

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  useMutationHandlers(
    deleteObjectResponse,
    (data: ObjectBaseResponse) => {
      if (data.success) {
        enqueueSnackbar(`Объект успешно удален.`, { variant: 'success' })
        onClose(false, true)
      } else {
        enqueueSnackbar(`Не удалось удалить объект. ${data.description}`, { variant: 'error' })
        setIsDeleteClicked(false)
      }
    }
  )

  useEffect(() => {
    if (!open) {
      setIsDeleteClicked(false)
    }
  }, [open])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => onDrawerClose(dirty)}
    >
      <ObjectsDrawerWrapper>
        <ObjectsDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {objectId ? 'Редактирование объекта' : 'Добавление объекта'}
          </Typography>
        </ObjectsDrawerTopBar>

        <Divider />

        {objectId
          ? !(isLoading || isFetching)
            ? (
              <>
                <ObjectsDrawerForm
                  projectId={projectId}
                  onClose={onDrawerClose}
                  onFormChange={onFormChange}
                  object={currentObject}
                />

                <Stack justifyContent='space-between' alignItems='center' flex={1} sx={{ p: 2.5, width: '100%' }}>
                  <Box width='100%' pb={2.5} mt='auto'>
                    {isDeleteClicked
                      ? (
                        <DeleteConfirm
                          text={`Вы действительно хотите удалить объект${isTimPage ? '?' : ' и все вложенные в него тома РД?'} Это действие нельзя отменить.`}
                          onDelete={onDeleteSubmit}
                          onCancel={onDeleteCancel}
                        />
                      )
                      : null
                    }
                  </Box>
                  <Box style={{ width: 'fit-content' }}>
                    <Button
                      onClick={onDeleteButtonClick}
                      startIcon={<DeleteIcon />}
                      variant='text'
                      color='error'
                    >
                      Удалить объект
                    </Button>
                  </Box>
                </Stack>
              </>
            )
            : <Progress />

          : (
            <ObjectsDrawerForm
              projectId={projectId}
              onClose={onDrawerClose}
              onFormChange={onFormChange}
            />
          )
        }
      </ObjectsDrawerWrapper>
    </Drawer>
  )
}

export default ObjectsDrawer