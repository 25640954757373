import { FC } from 'react'
import InstructionImage from '../../../../../assets/agreement-instruction.png'
import { ProjectAgreementInstructionWrapper, TitleText } from './ProjectAgreementInstruction.styles'
import { Box, Stack } from '@mui/material'
import {
  ProjectAgreementSchemeText,
  ProjectAgreementSchemeTitleText
} from '../../../ProjectAgreement/components/ProjectAgreementSchemeEmpty'

export const ProjectAgreementInstruction: FC = () => {
  const textData = [
    {
      title: 'Название этапа',
      text: 'Обязательное поле, которое позволяет задать собственное название для этапа согласования, например: Первичное согласование или Первый этап.'
    },
    { title: 'Срок прохождения этапа', text: 'Задаёт количество календарных дней на согласование текущего этапа' },
    { title: 'Куратор или ответственный', text: 'Определяет кто отвечает за этап согласования.' },
    {
      title: 'Позитивный и негативный результат прохождения',
      text: 'В этом разделе можно задать собственное название позитивного статуса, а так же указать, на какой этап будет передан том для дальнейшей процедуры согласования. Также, если необходимо, можно изменить привязку к системному статусу.'
    },
    {
      title: 'Внутренняя процедура согласования этапа',
      text: 'Ответственный за этап может добавить одну из валидных внутренних схем согласования компании к этапу внешней схемы. При наличии схемы внутренняя процедура согласования запустится автоматически при переходе к этапу внешнего согласования.'
    },
  ]

  return (
    <ProjectAgreementInstructionWrapper>
      <Box>
        <img src={InstructionImage} width={400} height={400} alt='Картинка' draggable={false} />
      </Box>

      <Stack textAlign='left' spacing={3} pt={3}>
        <TitleText variant='body2'>Схема согласования документации</TitleText>
        {textData.map(({ title, text }) => (
          <Stack spacing={0.5}>
            <ProjectAgreementSchemeTitleText variant='subtitle1'>
              {title}
            </ProjectAgreementSchemeTitleText>
            <ProjectAgreementSchemeText variant='body2'>
              {text}
            </ProjectAgreementSchemeText>
          </Stack>
        ))}
      </Stack>

    </ProjectAgreementInstructionWrapper>
  )
}
