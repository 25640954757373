import { TableHeaders } from "./InternalAgreementTable.types"

export const columns: TableHeaders[] = [
  {
    title: 'Фамилия, имя, отчество',
    minwidth: '236px',
    padding: '8px 16px 8px 8px',
    textAlign: 'start',
    marginLeft: '0!important'
  },
  {
    title: 'Должность',
    width: '100%',
    minwidth: '250px',
    padding: '8px 16px'
  },
  {
    title: 'Утверждение',
    minwidth: '110px',
    padding: '8px'
  },
  {
    title: 'Визирование',
    minwidth: '110px',
    padding: '8px'
  },
  {
    title: 'Подписание',
    minwidth: '110px',
    padding: '8px'
  },
  {
    title: 'Замечания',
    minwidth: '110px',
    padding: '8px'
  },
  {
    title: '',
    minwidth: '64px'
  },
]