import { Box, IconButton, Stack, Typography } from "@mui/material"
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PersonIcon from '@mui/icons-material/Person';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileName, LabelTextRequired, ManualSubmitAutocomplete, ManualSubmitField, StyledButton } from "./ManualSubmitMainForm.styles";
import FieldForm from "components/FieldForm";
import { useFormikContext } from "formik";
import { ManualSubmitValues } from "../ManualSubmitDrawer";
import { ElipsisText } from "styles/global/ElipsisText";
import Tooltip from "components/Tooltip";
import { ChangeEvent, useEffect } from "react";
import { useSnackbar } from "notistack";
import Divider from "components/Divider";
import { reduceFileName } from "utils/reduceFileName";
import { MAX_FILENAME_LENGTH } from "pages/DocView/components/DocViewRightSideBar/components/Remark/Remark.config";
import { useGetRemarkResponsibleQuery } from "api/remarks";
import { useParams } from "react-router-dom";
import { ProjectExportPhaseType } from "api/projects/types";
import { useTypedSelector } from "store/store";
import { currentCompanyIdSelector } from "store/slices/profile";
import { useGetEmployeesByProjectQuery } from "api/users";

interface ManualSubmitMainFormProps {
  selectedPhase: ProjectExportPhaseType
}

export const ManualSubmitMainForm = ({ selectedPhase }: ManualSubmitMainFormProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { enqueueSnackbar } = useSnackbar()
  const { values, setFieldValue } = useFormikContext<ManualSubmitValues>()
  const companyId = useTypedSelector(currentCompanyIdSelector)

  const { data: responsibleCompany } = useGetRemarkResponsibleQuery(
    { id: projectId, type: selectedPhase },
    { skip: !selectedPhase }
  )
  const { data: companies } = responsibleCompany || {}

  const { data: employeesByProjectData } = useGetEmployeesByProjectQuery(
    { companyId, projectId },
    { skip: !companyId }
  )
  const { data: employeesByProject = [] } = employeesByProjectData || {}

  const employeesByProjectAndCompany = values.responsibleCompany
  ? employeesByProject.filter(({ company }) => company === values.responsibleCompany)
  : employeesByProject

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 100 * 1024 * 1024) {
        enqueueSnackbar('Файл превышает максимально допустимый размер 100 МБ.', { variant: 'error' })
      } else {
        setFieldValue('file', file);
      }
    }
  }

  const removeFile = () => {
    setFieldValue('file', null)
  }

  useEffect(() => {
    if (!values.responsibleCompany) {
      setFieldValue('responsibleId', null)
    }
  }, [values.responsibleCompany])

  return (
    <Stack spacing={1.5} maxWidth={395}>
      <Stack py={2.5} height={160} justifyContent='space-between' alignItems='center'>
        <Typography variant="body1" fontSize={14} textAlign='center'>
          Для выбора доступны тома, в которых есть замечания в статусе Создано, в т. ч. повторные.
        </Typography>
        <Typography variant="body1" fontSize={14} textAlign='center'>
          Для направления замечаний по выбранным томам укажите контрагента, ответственного за устранение замечаний, номер и дату письма.
        </Typography>
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <EmailIcon fontSize="small" color="secondary" />
          <LabelTextRequired variant="body2">Номер письма</LabelTextRequired>
        </Stack>
        <ManualSubmitField
          helperText=''
          version='doc'
          name='mailNum'
          placeholder='Введите номер'
        />
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <EventIcon fontSize="small" color="secondary" />
          <LabelTextRequired variant="body2">Дата письма</LabelTextRequired>
        </Stack>
        <FieldForm
          version='date'
          name='mailDate'
          maxDate={new Date()}
          helperText=''
          dataValue={values.mailDate}
          inputProps={{ placeholder: 'ДД.ММ.ГГГГ' }}
          onDataChange={(value: Date) => setFieldValue('mailDate', value)}
          sx={{ maxWidth: 200 }}
        />
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.9993 12.6667H15.3327V14H0.666016V12.6667H1.99935V2.66667C1.99935 2.48986 2.06959 2.32029 2.19461 2.19526C2.31964 2.07024 2.4892 2 2.66602 2H9.33268C9.50949 2 9.67906 2.07024 9.80409 2.19526C9.92911 2.32029 9.99935 2.48986 9.99935 2.66667V12.6667H11.3327V6H13.3327C13.5095 6 13.6791 6.07024 13.8041 6.19526C13.9291 6.32029 13.9993 6.48986 13.9993 6.66667V12.6667ZM4.66602 7.33333V8.66667H7.33268V7.33333H4.66602ZM4.66602 4.66667V6H7.33268V4.66667H4.66602Z" fill="#5C6E8C" />
          </svg>
          <LabelTextRequired variant="body2">Компания</LabelTextRequired>
        </Stack>
        <ManualSubmitAutocomplete
          fieldName="responsibleCompany"
          placeholder="Выберите из списка"
          customRenderOption={(props, option) => (
            <Box component="li" {...props} key={option.key}>
              <ElipsisText variant="body2">{option.label}</ElipsisText>
            </Box>
          )}
          data={companies?.map((company, index) => ({
            value: company,
            label: company,
            key: `${company}-${index}`,
          })) || []}
        />
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <PersonIcon fontSize='small' color='secondary' />
          <Typography variant="body2">Ответственный</Typography>
        </Stack>
        <ManualSubmitAutocomplete
          fieldName="responsibleId"
          placeholder={!values.responsibleCompany ? "Выберите компанию" : "Выберите из списка"}
          disabled={!values.responsibleCompany}
          customRenderOption={(props, option) => (
            <Box component="li" {...props} key={option.key}>
              <ElipsisText variant="body2">{option.label}</ElipsisText>
            </Box>
          )}
          data={employeesByProjectAndCompany.map(({ id, name }) => ({
            value: id,
            label: name,
            key: id,
          })) || []}
        />
      </Stack>
      <Stack pt={2.5}>
        <Stack direction='row' spacing={1.25} alignItems='center' pb={1.25}>
          <Typography variant="h3">Вложение</Typography>
          <Tooltip variant='light'
            title={<>Не более одного файла до 100 МБ</>}>
            <InfoOutlinedIcon fontSize="medium" sx={{ color: '#D1D8FA' }} />
          </Tooltip>
        </Stack>
        <Divider />
        {values.file ? (
          <Stack direction='row' alignItems='center' spacing={0.5} pt={1.5}>
            <IconButton size="small" color="error"
              onClick={removeFile}>
              <DeleteIcon fontSize="small" />
            </IconButton>
            <Tooltip variant='light'
              title={<>{values.file.name}</>}
              disableHoverListener={values.file.name.length < MAX_FILENAME_LENGTH}>
              <FileName>{reduceFileName(values.file.name, MAX_FILENAME_LENGTH)}</FileName>
            </Tooltip>
          </Stack>
        ) : (
          <StyledButton fullWidth role={undefined} tabIndex={-1}>
            <AttachFileIcon fontSize="medium" />
            <input type="file" onChange={handleFileChange} style={{ display: 'none' }} />
            Прикрепить вложение
          </StyledButton>
        )}
      </Stack>
    </Stack >
  )
}


