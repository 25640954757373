import qs from 'qs'

import { setTom } from '../../../store/slices/documentsPages/tom'
import { ExcelUploadResponse } from '../../../types/global'
import { api } from '../../api'
import { ExportObjectsRequest } from '../objects'
import {
  CreateTomRdRequest,
  DeleteTomRdRequest,
  EditTomRdRequest,
  GetTomRdByIdRequest,
  GetTomRdLinkExampleResponse,
  GetTomsRdFullRequest,
  GetTomsRdFullResponse,
  GetTomsRdRequest,
  GetTomsRdResponse,
  ITerminateTomRdRequest,
  ITerminateTomRdResponse,
  SendToProdTomRdRequest,
  SendToProdTomRdResponse,
  TomRdBaseResponse,
  TomRdMultiUploadRequest,
  TomRdMultiUploadResponse,
  UploadTomRdRequest,
} from './tomApi.types'

export const tomRdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomsRd: build.query<GetTomsRdResponse, GetTomsRdRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom/list?${qs.stringify(params, { indices: false })}`,
        method: 'GET',
      }),
      providesTags: [
        'TomRd',
        { type: 'TomRd', id: 'UPDATE' },
        { type: 'TomRd', id: 'UPLOAD' },
        { type: 'TomRd', id: 'DELETE' },
        { type: 'TomRd', id: 'UPLOAD_MASS' },
        { type: 'TomRd', id: 'NOTIFICATIONS' },
        { type: 'TomRd', id: 'ARCHIVE_TOMS' },
      ],
    }),
    getTomsRdFull: build.query<GetTomsRdFullResponse, GetTomsRdFullRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom/list-full?${qs.stringify(params, { indices: false })}`,
        method: 'GET',
      }),
      providesTags: [
        'TomRd',
        { type: 'TomRd', id: 'UPDATE' },
        { type: 'TomRd', id: 'UPLOAD' },
        { type: 'TomRd', id: 'DELETE' },
        { type: 'TomRd', id: 'UPLOAD_MASS' },
        { type: 'TomRd', id: 'NOTIFICATIONS' },
        { type: 'TomRd', id: 'ARCHIVE_TOMS' },
      ],
    }),
    createTomRd: build.mutation<TomRdBaseResponse, CreateTomRdRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/tom/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'TomRd', id: 'UPDATE' }, 'ObjectsByProject', { type: 'Projects', id: 'dashboard' }],
    }),
    uploadTomRd: build.mutation<ExcelUploadResponse, UploadTomRdRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${id}/tom/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
      invalidatesTags: ['ObjectsByProject'],
    }),
    exportTomsRd: build.mutation<Blob, ExportObjectsRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    tomRdMultiUpload: build.mutation<TomRdMultiUploadResponse, TomRdMultiUploadRequest>({
      query: ({ id, files }) => {
        const formData = new FormData()
        files.forEach((file) => {
          formData.append('file', file)
        })

        return {
          url: `/project/${id}/tom/mass-update`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: [{ type: 'TomRd', id: 'UPLOAD_MASS' }],
    }),
    getTomRdById: build.query<TomRdBaseResponse, GetTomRdByIdRequest>({
      query: ({ id }) => ({
        url: `/project/tom/${id}/get`,
        method: 'GET',
      }),
      providesTags: ['TomRd', { type: 'TomRd', id: 'UPLOAD' }, { type: 'TomRd', id: 'NOTIFICATIONS' }],
    }),
    editTomRd: build.mutation<TomRdBaseResponse, EditTomRdRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/tom/${id}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'TomRd', id: 'UPDATE' }, { type: 'Projects', id: 'dashboard' }],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTomData } = await queryFulfilled
          const updatedTom = updatedTomData.data

          dispatch(setTom({ tom: updatedTom }))

          // getTomRdById
          dispatch(
            tomRdApi.util.updateQueryData('getTomRdById', { id: updatedTom.id }, (draft) => {
              draft.data = updatedTom
            }),
          )
        } catch {}
      },
    }),
    sendToProdTomRd: build.mutation<SendToProdTomRdResponse, SendToProdTomRdRequest>({
      query: ({ id }) => ({
        url: `/project/tom/${id}/to-prod`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'TomRd', id: 'NOTIFICATIONS' }],
    }),
    deleteTomRd: build.mutation<TomRdBaseResponse, DeleteTomRdRequest>({
      query: ({ id }) => ({
        url: `/project/tom/${id}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(tomRdApi.util.invalidateTags([{ type: 'TomRd', id: 'DELETE' }, 'ObjectsByProject', { type: 'Projects', id: 'dashboard' }]))
        } catch {}
      },
    }),
    // downloadTomRdExample: build.mutation<string, void>({
    //   query: () => ({
    //     url: `/project/tom/download-example`,
    //     method: 'GET',
    //   }),
    // }),
    getTomRdLinkExample: build.mutation<GetTomRdLinkExampleResponse, void>({
      query: () => ({
        url: `/project/tom/link-example`,
        method: 'GET',
      }),
    }),
    terminateTomRd: build.mutation<ITerminateTomRdResponse, ITerminateTomRdRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/toms/${tomId}/features/annull`,
        method: 'POST',
        params,
      }),
      async onQueryStarted({ tomId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTomData } = await queryFulfilled
          const updatedAnnulment = updatedTomData.annulment

          // getTomRdById
          dispatch(
            tomRdApi.util.updateQueryData('getTomRdById', { id: tomId }, (draft) => {
              draft.data.features.annulment = updatedAnnulment
            }),
          )
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomsRdQuery,
  useGetTomsRdFullQuery,
  useCreateTomRdMutation,
  useUploadTomRdMutation,
  useExportTomsRdMutation,
  useTomRdMultiUploadMutation,
  useGetTomRdByIdQuery,
  useEditTomRdMutation,
  useSendToProdTomRdMutation,
  useDeleteTomRdMutation,
  useGetTomRdLinkExampleMutation,
  useTerminateTomRdMutation,
} = tomRdApi
