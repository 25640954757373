import styled from 'styled-components'
import { alpha, Stack } from '@mui/material'

export const AgreementNavbarPhaseWrapper = styled(Stack)`
  & .phaseTitle {
    color: ${props => props.theme.palette.secondary.gray};
  }

  & .MuiIconButton-root {
    padding: 5px;
    background-color: ${alpha('#1976D2', 0.08)};
    border-radius: 6px;

    &:hover {
      background-color: #BFDBFE;
    }
  }

  & .MuiDivider-root {
    margin-top: 8px;
    border-color: #D1D8FA;
  }

  & .emptyPhase {
    font-size: 12px;
    line-height: 14px;
    color: ${props => props.theme.palette.disabled};
  }
`