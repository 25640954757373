import styled from "styled-components"
import { UserDrawer, UserDrawerHeader, UserLogoWrapper, UserDesignLogo } from "../../../../components/UserNavBar/styles"
import { FormControlLabel } from "@mui/material"

export const DrawerWrapper = styled(UserDrawer)`
`
export const ModelsDrawerHeader = styled(UserDrawerHeader)`
`
export const ModelsLogoWrapper = styled(UserLogoWrapper)`
`
export const ModelsDesignLogo = styled(UserDesignLogo)`
`
export const ExpiredCheckboxControl = styled(FormControlLabel)`
  padding-left: 8px;

  & .MuiCheckbox-root {
    margin-right: 16px;
    padding: 0;
    color: ${props => props.theme.palette.text.dark + '61'};

    &.Mui-checked {
      color: ${props => props.theme.palette.primary.main};
    }
  }
`