import styled from 'styled-components'
import { Stack, Typography } from '@mui/material'

export const TitleText = styled(Typography)`
  font-size: 18px;
  border-bottom: 1px solid #D1D8FA;
  padding-bottom: 8px;
`

export const ProjectAgreementInstructionWrapper = styled(Stack)`
  flex-direction: row;
  gap: 20px;
  margin-top: 34px !important;
  padding: 60px 60px 60px 30px;
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 16px;
`