import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Popover, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { HelpButtonContainer, StyledHelpButton } from './HelpButton.styles'

export const HelpButton = () => {

  const [anchorElTooltip, setAnchorElTooltip] = useState<null | HTMLElement>(null)
  const tooltipOpen = Boolean(anchorElTooltip)

  return (
    <HelpButtonContainer>
      <StyledHelpButton onClick={(e) => setAnchorElTooltip(anchorElTooltip ? null : e.currentTarget)}>
        <HelpOutlineIcon />
      </StyledHelpButton>
      <Popover
        open={tooltipOpen}
        anchorEl={anchorElTooltip}
        onClose={() => setAnchorElTooltip(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ '.MuiPopover-paper': { maxWidth: '427px' } }}>
        <Box p={2}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant="h2" fontWeight={500} mb={1.5}>Выбор моделей</Typography>
            <IconButton onClick={() => setAnchorElTooltip(null)} size="small" sx={{ height: 30 }}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Stack>
          <Typography variant="body2">
            Для просмотра моделей в формате IFC размером до 300 МБ необходимо настроить интеграцию с Tangl в режиме администрирования. Для получения обработанной модели интеграция должна быть активной на момент добавления версии модели.
          </Typography>
          <Typography variant="body2">
            Выбор моделей для визуализации доступен в списке в левой части экрана. Доступен выбор как отдельных версий модели, так и всех моделей по объектам / разделам в формате IFC.
          </Typography>
          <Typography variant="body2">
            После выбора моделей нажмите на кнопку “Просмотр”.
          </Typography>
        </Box>
      </Popover>
    </HelpButtonContainer>
  )
}