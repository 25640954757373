import styled from 'styled-components'
import {
  ExportArchiveDrawerFilter,
  ExportArchiveDrawerFormControlLabel,
  ExportArchiveDrawerMainListWrapper
} from '../../../../../Docs/components/ExportArchiveDrawer/components/ExportArchiveDrawerMainList/styles'
import {
  AgreementPhaseButton,
  AgreementPhaseGroup
} from '../../../../../Schemes/ProjectAgreement/components/ProjectAgreementLegend'
import { alpha, TextField } from '@mui/material'

export const FullExportDrawerMainListWrapper = styled(ExportArchiveDrawerMainListWrapper)`
`

export const FullExportDrawerFilter = styled(ExportArchiveDrawerFilter)`
  max-width: 50%;

  & .contentWrapper {
    max-width: 75%;
  }

  & .contentText {
    max-width: 100%;
  }
`

export const FullExportDrawerFormControlLabel = styled(ExportArchiveDrawerFormControlLabel)`
`

export const FullExportDrawerPhaseGroup = styled(AgreementPhaseGroup)`
  width: calc(100% - 20px); //20 - right padding
`

export const FullExportDrawerPhaseButton = styled(AgreementPhaseButton)`
  height: 28px;
  padding: 0 4px;

  &,
  & .MuiTypography-root {
    font-size: 14px;
    line-height: 16px;
  }
`

export const FullExportDrawerSearch = styled(TextField)`
  height: 36px;

  & .MuiInputBase-input {
    padding: 6px 14px;
  }

  & fieldset {
    border: ${props => `1px solid ${alpha(props.theme.palette.secondary.gray!, 0.2)}`};
    border-radius: 6px;
  }
`

