import {
  GetDetailedSidebarObjectsRequest,
  GetDetailedSidebarObjectsResponse,
  GetSidebarObjectsRequest,
  GetSidebarObjectsResponse
} from './sidebarApi.types'
import { tomRdApi } from '../tomApi'

export const sidebarRdApi = tomRdApi.injectEndpoints({
  endpoints: (build) => ({
    getSidebarObjects: build.query<GetSidebarObjectsResponse, GetSidebarObjectsRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom/side-menu-map`,
        method: 'GET',
      }),
      providesTags: [
        'TomRd',
        { type: 'TomRd', id: 'UPLOAD' },
        { type: 'TomRd', id: 'DELETE' },
      ],
    }),
    getDetailedSidebarObjects: build.query<GetDetailedSidebarObjectsResponse, GetDetailedSidebarObjectsRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom/detail-side-menu-map`,
        method: 'GET',
      }),
      providesTags: [
        'TomRd',
        { type: 'TomRd', id: 'UPLOAD' },
        { type: 'TomRd', id: 'DELETE' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetSidebarObjectsQuery,
  useGetDetailedSidebarObjectsQuery,
} = sidebarRdApi