import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const ExpertiseIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7957 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7957 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7957 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7957 7 10 7ZM9.29289 9.29289C9.48043 9.10536 9.73478 9 10 9C10.2652 9 10.5196 9.10536 10.7071 9.29289C10.8946 9.48043 11 9.73478 11 10C11 10.2652 10.8946 10.5196 10.7071 10.7071C10.5196 10.8946 10.2652 11 10 11C9.73478 11 9.48043 10.8946 9.29289 10.7071C9.10536 10.5196 9 10.2652 9 10C9 9.73478 9.10536 9.48043 9.29289 9.29289Z'
      />
      <path d='M14 17V15H6V17H14Z' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18 6V11H21C21.5523 11 22 11.4477 22 12V18C22 18.4935 21.8602 19.2113 21.4115 19.8345C20.9261 20.5086 20.1258 21 19 21H5C3.87418 21 3.07394 20.5086 2.58849 19.8345C2.13976 19.2113 2 18.4935 2 18V6C2 5.20435 2.31607 4.44129 2.87868 3.87868C3.44129 3.31607 4.20435 3 5 3H15C15.7956 3 16.5587 3.31607 17.1213 3.87868C17.6839 4.44129 18 5.20435 18 6ZM4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5H15C15.2652 5 15.5196 5.10536 15.7071 5.29289C15.8946 5.48043 16 5.73478 16 6V18C16 18.368 16.0525 18.7012 16.1467 19H5C4.52582 19 4.32606 18.8249 4.21151 18.6658C4.06024 18.4557 4 18.1735 4 18V6C4 5.73478 4.10536 5.48043 4.29289 5.29289ZM19.7885 18.6658C19.6739 18.8249 19.4742 19 19 19C18.8265 19 18.5443 18.9398 18.3342 18.7885C18.1751 18.6739 18 18.4742 18 18V13H20V18C20 18.1735 19.9398 18.4557 19.7885 18.6658Z'
      />
    </SvgIcon>
  )
}

export default ExpertiseIcon