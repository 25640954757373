import { FC } from 'react'
import { StatisticsBarProps } from './StatisticsBar.types'
import { StatisticsBarGroup, StatisticsBarItem, StatisticsBarWrapper } from './StatisticsBar.styles'
import { Typography } from '@mui/material'

export const StatisticsBar: FC<StatisticsBarProps> = ({ statisticsBarGroups }) => {
  return (
    <StatisticsBarWrapper>
      {statisticsBarGroups.map(({ percent, backgroundColor, items, empty, typographyProps }) => (
        <StatisticsBarGroup
          heightPercent={percent}
          childrenCount={items?.length || 0}
          backgroundColor={backgroundColor}
          empty={empty}
        >
          {items?.length
            ? items.map(item => (
              <StatisticsBarItem
                heightPercent={item.percent}
                parentHeightPercent={percent}
                backgroundColor={item.backgroundColor}
              >
                <Typography {...typographyProps}>{item.percent} %</Typography>
              </StatisticsBarItem>
            ))
            : (
              <Typography {...typographyProps}>{percent} %</Typography>
            )
          }
        </StatisticsBarGroup>
      ))}
    </StatisticsBarWrapper>
  )
}
