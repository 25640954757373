import {
  bottomLeftAdvicePositionProps,
  topLeftAdvicePositionProps,
  UseAdvicesProps,
  UseAdvicesReturn
} from './useAdvices.types'
import { ProjectAgreementAdvice } from '../components/ProjectAgreementAdvice'
import { ProjectAgreementExpandAdvice } from '../components/ProjectAgreementExpandAdvice'

export const useAdvices = ({ topLeft, bottomLeft, hideAdvices }: UseAdvicesProps): UseAdvicesReturn => {
  // const [state, setState] = useState<AdvicesState>({
  //   topLeft: [],
  //   bottomLeft: [],
  // })

  // useEffect(() => {
  //   data.forEach(item => {
  //     const currentPositionArray = state[item.position]
  //     const alreadyShowIndex = currentPositionArray.findIndex(alreadyShowItem => alreadyShowItem.text === item.text)
  //     const updatedArray = [...currentPositionArray]
  //
  //     // add to array if show and it does not in array
  //     if (item.show && alreadyShowIndex === -1) {
  //       updatedArray.splice(item.priorityOrder - 1, 0, item)
  //
  //       setState(prevState => (
  //         {
  //           ...prevState,
  //           [item.position]: updatedArray
  //         }
  //       ))
  //
  //       return
  //     }
  //
  //     // remove from array if not show and its in array
  //     if (!item.show && alreadyShowIndex > -1) {
  //       updatedArray.splice(alreadyShowIndex, 1)
  //
  //       setState(prevState => (
  //         {
  //           ...prevState,
  //           [item.position]: updatedArray
  //         }
  //       ))
  //     }
  //   })
  // }, [data])

  const topLeftAdviceDataForShow = topLeft.find(item =>
    (item.variant === 'expand' && !!item.items?.some(innerItem => innerItem.show))
    || (item.variant === 'regular' && item.show)
  )

  const bottomLeftAdviceDataForShow = bottomLeft.find(item =>
    (item.variant === 'expand' && !!item.items?.some(innerItem => innerItem.show))
    || (item.variant === 'regular' && item.show)
  )

  return {
    TopLeftAdviceElement: topLeftAdviceDataForShow && !hideAdvices
      ? topLeftAdviceDataForShow.variant === 'expand'
        ? <ProjectAgreementExpandAdvice data={topLeftAdviceDataForShow} {...topLeftAdvicePositionProps} />
        : <ProjectAgreementAdvice data={topLeftAdviceDataForShow} {...topLeftAdvicePositionProps} />
      : null,

    BottomLeftAdviceElement: bottomLeftAdviceDataForShow && !hideAdvices
      ? bottomLeftAdviceDataForShow.variant === 'expand'
        ? <ProjectAgreementExpandAdvice data={bottomLeftAdviceDataForShow} {...bottomLeftAdvicePositionProps} />
        : <ProjectAgreementAdvice data={bottomLeftAdviceDataForShow} {...bottomLeftAdvicePositionProps} />
      : null,
  }
}
