import styled from 'styled-components'
import { alpha, Stack, Typography } from '@mui/material'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'
import { CommonStatisticsItemProps } from './StatisticsItem.types'

interface StatisticsItemWrapperProps extends Pick<CommonStatisticsItemProps, 'checked'>{
}
export const StatisticsItemWrapper = styled(Stack)<StatisticsItemWrapperProps>`
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 12.5px 10px;
  border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
  border-color: ${props => props.checked && props.theme.palette.primary.light};
  border-radius: 8px;
  
  &:hover {
    border-color: ${props => props.theme.palette.primary.light};
    cursor: pointer;
  }
`

interface StatisticsItemIconWrapperProps extends Pick<SvgIconProps, 'color'> {
}

export const StatisticsItemIconWrapper = styled(Stack)<StatisticsItemIconWrapperProps>`
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;

  background-color: ${props => props.color === 'primary' && `${alpha(props.theme.palette.primary.main, 0.08)}`};
  background-color: ${props => props.color === 'success' && `${alpha(props.theme.palette.table.green, 0.15)}`};
  background-color: ${props => props.color === 'error' && `${alpha(props.theme.palette.table.orange, 0.1)}`};
  background-color: ${props => props.color === 'purple' && `${props.theme.palette.bg.shades}`};

  & .MuiSvgIcon-root {
    color: ${props => props.color === 'primary' && props.theme.palette.primary.light};
    color: ${props => props.color === 'success' && props.theme.palette.legends.lightgreen};
    color: ${props => props.color === 'error' && props.theme.palette.error.main};
    color: ${props => props.color === 'purple' && props.theme.palette.legends.purple};
  }
`

export const StatisticsItemDescription = styled(Typography)`
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StatisticsItemNumberWrapper = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border: ${props => `1px solid ${props.theme.palette.bg.shades}`};
  border-radius: 50%;
`