import { TProjectWorksStatistic, Project } from 'api/projects/types'

export interface DesignGraphCardProps {}

export const projectWorksLabelStatistics: Record<TProjectWorksStatistic, string> = {
  totalGroups: 'Группы работ',
  totalWorks: 'Всего работ',
  notInWork: 'Не в работе',
  inWork: 'В работе',
  done: 'Выполнено',
  expired: 'Просрочено',
}
