import { api } from '../../api'
import {
  DeleteTomRdVersionRequest,
  DownloadTomRdVersionRequest,
  GetTomRdVersionsRequest,
  GetTomRdVersionsResponse,
  TomRdVersionBaseResponse,
  UploadTomRdVersionRequest,
} from './tomVersionApi.types'
import { GetTomRdByIdRequest, GetTomsRdRequest, tomRdApi } from '../tom'
import { setCurrentDocument } from '../../../store/slices/documentsPages/tom'

export const tomRdVersionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomRdVersions: build.query<GetTomRdVersionsResponse, GetTomRdVersionsRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom/${id}/pdf/list`,
        params,
        method: 'GET',
      }),
      providesTags: ['TomRd', 'TomRdDoc'],
    }),
    createTomRdVersion: build.mutation<TomRdVersionBaseResponse, UploadTomRdVersionRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/tom/${id}/pdf/create`,
            method: 'POST',
            body: formData,
          }
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedVersionData } = await queryFulfilled
          const uploadedVersion = uploadedVersionData.data

          // getTomsRd
          const getTomsRdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomsRd'))
          const getTomsRdLastKey = getTomsRdKeys[getTomsRdKeys?.length - 1]

          dispatch(
            tomRdApi.util.updateQueryData(
              'getTomsRd',
              state.api.queries[getTomsRdLastKey]?.originalArgs as GetTomsRdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex((tom) => tom.id === uploadedVersion.tomId)
                draft.data[changedTomIndex].version = uploadedVersion.version
              },
            ),
          )

          // getTomRdById
          const getTomRdByIdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomRdById'))
          const getTomRdByIdLastKey = getTomRdByIdKeys[getTomRdByIdKeys?.length - 1]

          dispatch(
            tomRdApi.util.updateQueryData(
              'getTomRdById',
              state.api.queries[getTomRdByIdLastKey]?.originalArgs as GetTomRdByIdRequest,
              (draft) => {
                draft.data.version = uploadedVersion.version
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: uploadedVersion }))
        } catch {}
      },
    }),
    uploadTomRdVersion: build.mutation<TomRdVersionBaseResponse, UploadTomRdVersionRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/tom/${id}/pdf/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedVersionData } = await queryFulfilled
          const uploadedVersion = uploadedVersionData.data

          // getTomsRd
          const getTomsRdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomsRd'))
          const getTomsRdLastKey = getTomsRdKeys[getTomsRdKeys?.length - 1]

          dispatch(
            tomRdApi.util.updateQueryData(
              'getTomsRd',
              state.api.queries[getTomsRdLastKey]?.originalArgs as GetTomsRdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex((tom) => tom.id === uploadedVersion.tomId)
                draft.data[changedTomIndex].version = uploadedVersion.version
              },
            ),
          )

          // getTomRdById
          const getTomRdByIdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomRdById'))
          const getTomRdByIdLastKey = getTomRdByIdKeys[getTomRdByIdKeys?.length - 1]

          dispatch(
            tomRdApi.util.updateQueryData(
              'getTomRdById',
              state.api.queries[getTomRdByIdLastKey]?.originalArgs as GetTomRdByIdRequest,
              (draft) => {
                draft.data.version = uploadedVersion.version
              },
            ),
          )

          // getTomRdVersions
          const getTomRdVersionsKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomRdVersions'))
          const getTomRdVersionsLastKey = getTomRdVersionsKeys[getTomRdVersionsKeys?.length - 1]

          dispatch(
            tomRdVersionApi.util.updateQueryData(
              'getTomRdVersions',
              state.api.queries[getTomRdVersionsLastKey]?.originalArgs as GetTomRdVersionsRequest,
              (draft) => {
                draft.data.unshift(uploadedVersion)
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: uploadedVersion }))
        } catch {}
      },
    }),
    deleteTomRdVersion: build.mutation<TomRdVersionBaseResponse, DeleteTomRdVersionRequest>({
      query: ({ id, version }) => ({
        url: `/project/tom/${id}/pdf/delete`,
        params: { version },
        method: 'DELETE',
      }),
      invalidatesTags: ['TomRd', 'TomRdDoc'],
    }),
    downloadTomRdVersion: build.mutation<Blob, DownloadTomRdVersionRequest>({
      query: ({ id, version }) => ({
        url: `/project/tom/${id}/pdf/file`,
        method: 'POST',
        params: { version },
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    // getTomRdVersionLink: build.mutation<string, GetTomRdVersionLinkRequest>({
    //   query: ({ id, version }) => ({
    //     url: `/project/tom/${id}/pdf/get-link`,
    //     params: { version },
    //     method: 'GET',
    //   }),
    // }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomRdVersionsQuery,
  useCreateTomRdVersionMutation,
  useUploadTomRdVersionMutation,
  useDeleteTomRdVersionMutation,
  useDownloadTomRdVersionMutation,
} = tomRdVersionApi
