import { api } from '@api/api'

import {
  AgreementOverviewActionRequest,
  AgreementOverviewActionRespone,
  CompleteAgreementOverviewStageRequest,
  CompleteAgreementOverviewStageResponse,
  CompleteTomAgreementOverviewStageRequest,
  CompleteTomAgreementOverviewStageResponse,
  GetAgreementOverviewRequest,
  GetAgreementOverviewResponse,
} from './api.types'

export const agreementOverviewApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAgreementOverview: build.query<GetAgreementOverviewResponse, GetAgreementOverviewRequest>({
      query: ({ id, tomId, type }) => ({
        url: `/project/${id}/approval/overview/tom/${tomId}`,
        method: 'GET',
        params: { type },
      }),
      providesTags: ['AgreementOverview'],
    }),
    /* getAgreementOverviewMembers: build.query<GetAgreementOverviewMembersResponse, GetAgreementOverviewMembersRequest>({
      query: ({ id, innermapId }) => ({
        url: `/project/${id}/approval/overview/innermap/${innermapId}/members`,
        method: 'GET',
      }),
      //providesTags: ['AgreementOverview'],
    }), */
    actionAgreementOverview: build.mutation<AgreementOverviewActionRespone, AgreementOverviewActionRequest>({
      query: ({ id, innermapId, memberId, ...body }) => ({
        url: `/project/${id}/approval/overview/innermap/${innermapId}/member/${memberId}/action`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AgreementOverview'],
    }),
    completeAgreementOverviewStage: build.mutation<
      CompleteAgreementOverviewStageResponse,
      CompleteAgreementOverviewStageRequest
    >({
      query: ({ id, innermapId, memberId, ...body }) => ({
        url: `/project/${id}/approval/overview/innermap/${innermapId}/member/${memberId}/complete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AgreementOverview'],
    }),
    completeTomAgreementOverviewStage: build.mutation<
      CompleteTomAgreementOverviewStageResponse,
      CompleteTomAgreementOverviewStageRequest
    >({
      query: ({ params, body }) => ({
        url: `/project/${params.id}/approval/overview/tom/${params.tomId}/complete`,
        method: 'POST',
        params: { type: params.type },
        body,
      }),
      invalidatesTags: ['AgreementOverview'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAgreementOverviewQuery,
  /* useGetAgreementOverviewMembersQuery, */
  useActionAgreementOverviewMutation,
  useCompleteAgreementOverviewStageMutation,
  useCompleteTomAgreementOverviewStageMutation,
} = agreementOverviewApi
