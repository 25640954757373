import { useState } from "react"
import { IconBox, SpecialText, StyledContainer, StyledHeaderText, StyledLegendText, StyledListHeader, StyledListItem, StyledText, StyledTitleText } from "./CompanySchemes.styles"
import { ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowRight, WarningAmber } from "@mui/icons-material"
import { Collapse, IconButton, List, Stack } from "@mui/material"
import { useAppDispatch, useTypedSelector } from "../../../../../../store/store"
import { TableFormValues, currentSchemaSelector, setCurrentSchema, setIsOpenFormAddMember, setIsSelectedProcedure, setSchemaFormMode, setTableFormValues } from "../../../../../../store/slices/internalAgreement"
import { CompanySchemesProps } from "./CompanySchemes.types"
import { Innermap } from "../../../../../../api/internalAgreement/types"
import { convertDateTime } from "../../../../../../utils/formatDateAndTimeGMT"
import Tooltip from "../../../../../../components/Tooltip"

export const CompanySchemes = ({ company, procedures }: CompanySchemesProps) => {

  const dispatch = useAppDispatch()

  const [open, setOpen] = useState(true)

  const currentSchema = useTypedSelector(currentSchemaSelector)


  const selectProcedure = (innermap: Innermap) => {
    if (innermap.id === currentSchema.id) {
      dispatch(setCurrentSchema({} as Innermap))
      dispatch(setIsSelectedProcedure({ isSelectedProcedure: false }))
      return
    }
    dispatch(setCurrentSchema(innermap))
    dispatch(setIsSelectedProcedure({ isSelectedProcedure: true }))
    dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
    dispatch(setTableFormValues({ tableFormValues: {} as TableFormValues }))
  }

  return (
    <Stack spacing={1.5}>
      <StyledListHeader direction="row" spacing={1}>
        <IconButton onClick={() => setOpen(!open)} sx={{ padding: 0 }} color="secondary">
          {open ? <ExpandLess fontSize="medium" /> : <ExpandMore fontSize="medium" />}
        </IconButton>
        <StyledHeaderText variant="body2">
          {company}
        </StyledHeaderText>
      </StyledListHeader>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component={Stack} spacing={1.5} disablePadding>
          {procedures.map((procedure) => {
            const { approved, approver, expired, innermap } = procedure
            const withTime = innermap.unit === 'hour'
            return (
              <StyledListItem
                component='li'
                key={innermap.id}
                $isBroken={currentSchema.isBroken}
                $selected={currentSchema.id === innermap.id}
                onClick={() => selectProcedure(innermap)}
              >
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <StyledTitleText $selected={currentSchema.id === innermap.id}>{innermap.title}</StyledTitleText >
                  <IconBox>
                    {currentSchema.id === innermap.id ? (
                      <KeyboardArrowRight color="primary" fontSize="small" />
                    ) : (
                      <KeyboardArrowDown color="primary" fontSize="small" />
                    )}
                  </IconBox>
                </Stack>
                <Stack>
                  <StyledContainer direction='row'>
                    <StyledLegendText>Модератор:</StyledLegendText>
                    <StyledText>{innermap.moderator.name}</StyledText>
                  </StyledContainer>
                  {approved
                    ? (
                      <StyledContainer direction='row'>
                        <StyledLegendText>Утверждено:</StyledLegendText>
                        <SpecialText>{convertDateTime(approver.datetime!, withTime)}</SpecialText>
                      </StyledContainer>
                    ) : (
                      <StyledContainer direction='row'>
                        <StyledLegendText>Срок согласования:</StyledLegendText>
                        <StyledText>{approver.datetime ? convertDateTime(approver.datetime, withTime) : '-'}</StyledText>
                      </StyledContainer>
                    )}
                  <StyledContainer direction='row'>
                    <StyledLegendText>{approved ? 'Утвердил:' : 'Утверждение:'}</StyledLegendText>
                    {!approver.id
                      ? (
                        <Tooltip title={<>Внутреннее согласование не может быть завершено, ни у одного участника нет доступа к утверждению</>}>
                          <WarningAmber fontSize='small' color='warning' />
                        </Tooltip>
                      ) : (
                        <StyledText>{approver.name}</StyledText>
                      )}
                  </StyledContainer>
                </Stack>
              </StyledListItem>
            )
          })}
        </List>
      </Collapse>
    </Stack>
  )
}