import styled from "styled-components";
import { FullUserCardWrapper } from "../../../Administration/AdminUsers/AdminUsers.styles";
import { Box, Typography } from "@mui/material";

export const StyledModelCard = styled(FullUserCardWrapper)`
  display: flex;
  flex-direction: column;
  height: 334px;
  background-color: ${props => props.theme.palette.bg.white};
  text-align: start;
`
export const DateText = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-top: 8px;
  height: 32px;
  border-radius: 4px;
  background-color: ${props => props.theme.palette.bg.shades};

  & .MuiTypography-root {
    font-size: 12px;
    line-height: normal;
    color: ${props => props.theme.palette.secondary.dark};
  }
`
export const CardTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  min-height: 38px;
  display: -webkit-box;
  overflow-wrap: anywhere;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-pack: center;
  -webkit-box-orient: vertical;
  margin-top: 14px;
  margin-bottom: 18px;
  color: ${props => props.theme.palette.primary.main};
`

export const StyledData = styled(Typography)<{expired?: boolean}>`
  color: ${props => props.theme.palette.text.dark};
  color: ${props => props.expired && props.theme.palette.error.main};
  background-color: ${props => props.expired && props.theme.palette.legends.red + '26'};
  border-radius: 20px;
  padding: 2px 6px;
`
export const ElipsisText = styled(Typography)`
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`
export const StyledLabel = styled(Box)`
  background-color: ${props => props.theme.palette.primary.main};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 24px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${props => props.theme.palette.text.light};
`