import styled from 'styled-components'
import { Stack } from '@mui/material'


export const ScrollableContainer = styled(Stack)`
  flex: 1;
  padding-right: 20px;
  overflow: auto scroll;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    padding-right: 7px;

    &::-webkit-scrollbar {
      display: block;
    }
  }

  @supports (-moz-appearance:none) {
    scrollbar-width: none;

    &:hover {
      padding-right: 12px;
      scrollbar-width: thin;
    }
  }
`