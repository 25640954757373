import React, { FC, useCallback, useEffect, useState } from 'react'
import { WorksUploadDrawerProps, WorksUploadVariant } from './WorksUploadDrawer.types'
import { ExcelUploadResponse } from '../../../../types/global'
import UploadDrawer from '../../../../components/UploadDrawer'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import {
  GetWorkLinkExampleResponse,
  useGetWorkLinkExampleMutation,
  useUploadWithReplaceWorkMutation,
  useUploadWorkMutation,
  worksApi
} from '../../../../api/works'
import { useAppDispatch } from '../../../../store/store'
import { TextToggleButton, TextToggleButtonGroup } from './WorksUploadDrawer.styles'
import { Stack } from '@mui/material'

export const WorksUploadDrawer: FC<WorksUploadDrawerProps> = ({ open, projectId, scheduleId, onClose }) => {
  const dispatch = useAppDispatch()

  const [files, setFiles] = useState<File[] | null>(null)
  const [uploadVariant, setUploadVariant] = useState<WorksUploadVariant>('rewrite')
  const [responseData, setResponseData] = useState<ExcelUploadResponse | undefined>(undefined)

  const [uploadWork, { isLoading: isWorkLoading, ...uploadWorkResponse }] = useUploadWorkMutation()
  const [uploadWithReplaceWork, {
    isLoading: isReplaceWorkLoading,
    ...uploadWithReplaceWorkResponse
  }] = useUploadWithReplaceWorkMutation()

  const [getWorkLinkExample, getWorkLinkExampleResponse] = useGetWorkLinkExampleMutation()

  const onUploadVariantChange = (newUploadVariant: WorksUploadVariant) => {
    if (newUploadVariant !== null) {
      setUploadVariant(newUploadVariant)
    }
  }

  useEffect(() => {
    if (files?.length && files[0] instanceof File) {
      uploadVariant === 'add' && uploadWork({ id: projectId, scheduleId, file: files[0] })
      uploadVariant === 'rewrite' && uploadWithReplaceWork({ id: projectId, scheduleId, file: files[0] })
    }
  }, [files])

  useMutationHandlers(
    uploadWorkResponse,
    (data) => {
      setResponseData(data)
    }
  )

  useMutationHandlers(
    uploadWithReplaceWorkResponse,
    (data) => {
      setResponseData(data)
    }
  )

  const onDrawerClose = (dirty: boolean, immediately?: boolean) => {
    onClose(dirty, immediately)

    responseData?.success && dispatch(
      worksApi.util.invalidateTags([{ type: 'Works', id: 'LIST' }])
    )

    setResponseData(undefined)
    setFiles(null)
  }

  const onFormSubmit = useCallback((files: File[]) => {
    setFiles(files)
  }, [])

  const onFormChange = useCallback(() => {
    setResponseData(undefined)
  }, [])

  useMutationHandlers(
    getWorkLinkExampleResponse,
    (data: GetWorkLinkExampleResponse) => {
      window.location.href = data.data
    }
  )

  return (
    <UploadDrawer
      open={open}
      mode='single'
      onSubmit={onFormSubmit}
      responseData={responseData}
      onChange={onFormChange}
      onClose={onDrawerClose}
      title='Загрузить график проектирования'
      text='Вы можете добавить график работ с помощью exсel документа с уже подготовленными данными.'
      attentionText='В случае если у Вас будут совпадения названий существующих работ с новыми из файла, новый график работ заменит существующий.
Это действие нельзя отменить.'
      uploadData={{
        title: 'Загрузить xls(х)',
        text: 'Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
        formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel']
      }}
      loading={isWorkLoading || isReplaceWorkLoading}
      getExampleLink={getWorkLinkExample}
      ContentAfterLinkExample={responseData
        ? undefined
        : (
          <Stack direction='row' justifyContent='center' px={1}>
            <TextToggleButtonGroup
              value={uploadVariant}
              exclusive
              onChange={(e, newValue) => onUploadVariantChange(newValue)}
            >
              <TextToggleButton value='add'>
                Дополнить
              </TextToggleButton>
              <TextToggleButton value='rewrite'>
                Перезаписать
              </TextToggleButton>
            </TextToggleButtonGroup>
          </Stack>
        )}
    />
  )
}
