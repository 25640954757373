import styled from 'styled-components'
import { Stack, Popover, IconButton } from '@mui/material'
import { HeaderPercentage } from '../StatusItem'
import {
  AgreementAccordionSubtitle,
  AgreementAccordionText,
  AgreementAccordionTitle
} from '../../../../../DocView/components/DocViewNavBarContentAgreementAccordion'

export const StatusItemPopover = styled(Popover)`
  & .MuiPaper-root {
    width: 420px;
    padding: 20px 10px 20px 30px;
    border-radius: 16px;

    & .MuiDivider-root {
      height: 0.5px;
      background-color: ${props => props.theme.palette.bg.shades};
      opacity: 0.5;
    }
  }
`

export const StatusItemPopoverPercentage = styled(HeaderPercentage)`
  width: 65px;
  height: 31px;

  & .MuiTypography-root {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0;
    text-align: center;
    color: ${props => props.theme.palette.text.light} !important;
  }
`

export const StatusItemPopoverTitle = styled(AgreementAccordionTitle)`
  font-size: 20px;
`

export const StatusItemPopoverCloseButton = styled(IconButton)`
  margin-right: -8px !important;
  padding: 4px;

  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.secondary.gray};
  }
`

export const StatusItemPopoverText = styled(AgreementAccordionText)`
  max-width: 240px;
`

export const StatusItemPopoverSubtitle = styled(AgreementAccordionSubtitle)`
  max-width: 360px;
  line-height: 17px;
`

export const StatusItemPopoverStatWrapper = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 58px;
  border: ${props => `1px solid ${props.theme.palette.bg.shades}`};
  border-radius: 6px;

  & .value {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0;
    color: ${props => props.theme.palette.secondary.main};
  }

  & .title {
    font-size: 9px;
    line-height: 100%;
    letter-spacing: 0;
    color: ${props => props.theme.palette.secondary.gray};
  }
`