import styled from 'styled-components'
import { Stack } from '@mui/material'

export const MoveCellIconWrapper = styled(Stack)`
  place-content: center;
  height: 24px;

  & .MuiIconButton-root {
    padding: 0;
    background-color: initial;
  }

  & .icon {
    color: ${props => props.theme.palette.legends.gray}
  }

  & .icon-hover {
    display: none;
  }

  &:hover {
    & .icon {
      display: none;
    }

    & .icon-hover {
      display: block;
    }
  }
`
