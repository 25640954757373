import {
  GetLinkForDownloadTomPdFilesRequest,
  GetLinkForDownloadTomPdFilesResponse,
  GetTomsPdFileListRequest,
  GetTomsPdFileListResponse,
  SendTomsPdFileListRequest,
  SendTomsPdFileListResponse
} from './archiveExportApi.types'
import { tomPdApi } from '../tomApi'

export const archiveExportPdApi = tomPdApi.injectEndpoints({
  endpoints: (build) => ({
    getTomsPdFileList: build.query<GetTomsPdFileListResponse, GetTomsPdFileListRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-pd/download/get-file-list`,
        method: 'GET',
        params
      }),
      providesTags: [{ type: 'TomRd', id: 'ARCHIVE_TOMS' }],
    }),
    sendTomsPdFileList: build.mutation<SendTomsPdFileListResponse, SendTomsPdFileListRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/tom-pd/download/create-download-link`,
        method: 'POST',
        body,
      }),
    }),
    getLinkForDownloadTomPdFiles: build.query<GetLinkForDownloadTomPdFilesResponse, GetLinkForDownloadTomPdFilesRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom-pd/${id}/create-download-link`,
        method: 'GET',
        params
      }),
      providesTags: [
        'TomPd',
        { type: 'TomPd', id: 'UPLOAD' },
        { type: 'TomPd', id: 'DELETE' },
        { type: 'TomPd', id: 'UPLOAD_MASS' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomsPdFileListQuery,
  useSendTomsPdFileListMutation,
  useGetLinkForDownloadTomPdFilesQuery,
} = archiveExportPdApi
