import { useEffect, useState } from "react"
import { UseUseHighLightToolProps } from "../CanvasAnnotations.types"
import { Annotation, AnnotationRects, colorNotes } from "../../../PragmaPdfViewer.types"
import { checkIsInsideSelectAnnotation, checkIsSelectAnnotation } from "./common"

interface DrawHightLight {
  ctx: CanvasRenderingContext2D
  x1: number
  y1: number
  x2: number
  y2: number
  rotate: number
  index?: number
  id?: number | null
}

export const useHighLightTool = ({
  documentRef,
  canvasRef,
  annotations,
  pageNumber,
  rotate,
  selectedRect,
  isCreateMode,
  selectedAnnotationId,
  annotationType,
  setSelectedRect,
  setAnnotations,
  drawAnnotations
}: UseUseHighLightToolProps) => {
  const [currentRect, setCurrentRect] = useState<AnnotationRects[] | null>(null)
  const [isDrawing, setIsDrawing] = useState(false)

  const drawHightLight = ({ ctx, x1, y1, x2, y2, rotate, index, id }: DrawHightLight) => {
    const totalRotation = (rotate + 360) % 360
    /*     if (index === selectedRect?.index) {
          const canvas = canvasRef.current
          ctx.fillStyle = 'rgba(255, 0, 0, 0.2)'
          ctx.beginPath()
          switch (totalRotation) {
            case 90:
              ctx.rect(y1, x1, y2 - y1, x2 - x1) TODO
              break
            case 180:
              ctx.rect(x1, y1, x2 - x1, 2)
              break
            case 270:
              ctx.rect(x2, y1, 2, y2 - y1)
              break
            default:
              ctx.rect(x1, y1, x2 - x1, y2 - y1)
          }
          ctx.fill()
        } */

    ctx.strokeStyle = index === selectedRect?.index || (selectedAnnotationId && selectedAnnotationId === id) ? colorNotes['blue'] : colorNotes['red']
    ctx.lineWidth = 2
    ctx.beginPath()

    switch (totalRotation) {
      case 90:
        ctx.moveTo(x1, y1)
        ctx.lineTo(x1, y2)
        break
      case 180:
        ctx.moveTo(x1, y1)
        ctx.lineTo(x2, y1)
        break
      case 270:
        ctx.moveTo(x2, y1)
        ctx.lineTo(x2, y2)
        break
      default:
        ctx.moveTo(x1, y2)
        ctx.lineTo(x2, y2)
    }
    ctx.stroke()
  }

  /*   const isTextInSelection = (selection: Selection): boolean => {
      const range = selection.getRangeAt(0)
      return range.commonAncestorContainer.nodeType === Node.TEXT_NODE
    } */
  const isTextInSelection = (selection: Selection): boolean => {
    const range = selection.getRangeAt(0)

    return (
      range.commonAncestorContainer.nodeType === Node.TEXT_NODE ||
      range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE
    )
  }

  const isTextNode = (node: Node | null): boolean => {
    return node?.nodeType === Node.TEXT_NODE
  }

  const handleTextSelectionStart = (e: MouseEvent) => {
    document.getSelection()?.removeAllRanges()
    const canvas = canvasRef.current
    if (canvas) {
      const rect = canvas.getBoundingClientRect()
      const x = (e.clientX - rect.left)
      const y = (e.clientY - rect.top)
      const selected = checkIsSelectAnnotation({
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        scale: 1,
        isCreateMode
      })
      if (selected && selected.type === 'highlight') {
        setSelectedRect(selected)
      } else if (selectedRect) {
        setSelectedRect(null)
      }
    }
    setIsDrawing(true)
  }

  const handleTextSelectionMove = (e: MouseEvent) => {

    const selection = document.getSelection()
    const canvas = canvasRef.current

    if (isDrawing) {
      setSelectedRect(null)
    }
    if (selection && canvas) {
      const selectedText = selection.toString().trim()
      const currentPageElement: HTMLDivElement | null = document.querySelector(`[data-page='${pageNumber - 1}']`)

      /* if (selection.anchorNode && !isTextNode(selection.anchorNode)) {
        selection.removeAllRanges()
        return
      }

      if (selection.anchorNode && selectedText === "" && isTextNode(selection.anchorNode)) {
        selection.collapse(selection.anchorNode, selection.anchorOffset)
        return
      } */

      if (selectedText && currentPageElement && isTextInSelection(selection) && !selection.isCollapsed) {
        const rect = canvas.getBoundingClientRect()
        const ranges: AnnotationRects[] = []
        for (let i = 0; i < selection.rangeCount; i++) {
          const range = selection.getRangeAt(i)
          const rangeRects = range.getClientRects()

          Array.from(rangeRects).forEach((selectRect: DOMRect) => {
            ranges.push({
              left: (selectRect.left - rect.left) / rect.width,
              top: (selectRect.top - rect.top) / rect.height,
              width: selectRect.width / rect.width,
              height: selectRect.height / rect.height,
            })
          })
        }

        const ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          drawAnnotations()

          ranges.forEach((currentRect) => {
            drawHightLight({
              ctx,
              x1: currentRect.left * canvas.width,
              x2: (currentRect.left + currentRect.width) * canvas.width,
              y1: currentRect.top * canvas.height,
              y2: (currentRect.top + currentRect.height) * canvas.height,
              rotate,
            })
          })
        }
        setCurrentRect(ranges)
      }
    }
    if (canvas) {
      const rect = canvas.getBoundingClientRect()
      const x = (e.clientX - rect.left)
      const y = (e.clientY - rect.top)
      const isInsideTextAnnotation = checkIsInsideSelectAnnotation({
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        isCreateMode
      })
      documentRef.current!.style.cursor = isInsideTextAnnotation && isInsideTextAnnotation.type === 'highlight' ? 'pointer' : 'auto'
    }
  }

  const isRectInside = (innerRect: AnnotationRects, outerRect: AnnotationRects): boolean => {
    return (
      innerRect.left >= outerRect.left &&
      innerRect.left + innerRect.width <= outerRect.left + outerRect.width &&
      innerRect.top >= outerRect.top &&
      innerRect.top + innerRect.height <= outerRect.top + outerRect.height
    )
  }

  const isOverlapLine = (rect1: AnnotationRects, rect2: AnnotationRects, rotate: number): boolean => {
    if (rotate === 0 || rotate === 180) {
      return (
        rect1.top === rect2.top &&
        rect1.left < rect2.left + rect2.width &&
        rect1.left + rect1.width > rect2.left
      )
    } else if (rotate === 90 || rotate === 270) {
      return (
        rect1.left === rect2.left &&
        rect1.top < rect2.top + rect2.height &&
        rect1.top + rect1.height > rect2.top
      )
    }
    return false
  }
  const mergeOverlapRects = (rect1: AnnotationRects, rect2: AnnotationRects, rotate: number): AnnotationRects => {
    if (rotate === 0 || rotate === 180) {
      const left = Math.min(rect1.left, rect2.left)
      const right = Math.max(rect1.left + rect1.width, rect2.left + rect2.width)
      return { ...rect1, left, width: right - left }
    } else if (rotate === 90 || rotate === 270) {
      const top = Math.min(rect1.top, rect2.top)
      const bottom = Math.max(rect1.top + rect1.height, rect2.top + rect2.height)
      return { ...rect1, top, height: bottom - top }
    }
    return rect1
  }

  const removeNestedRects = (rects: AnnotationRects[]): AnnotationRects[] => {
    return rects.filter((rect, index, arr) => {
      return !arr.some((otherRect, otherIndex) => {
        if (index !== otherIndex) {
          return isRectInside(rect, otherRect)
        }
        return false
      })
    })
  }
  const mergeOverlappingAnnotations = (annotations: Annotation[], annotationToMerge: Annotation): Annotation[] => {
    let merged = annotationToMerge
    let hasChanges = false

    const updatedAnnotations = annotations.filter((annotation) => {
      if (annotation.highlightRects && merged.highlightRects && annotation.type === 'highlight') {
        const mergedRects = [...annotation.highlightRects]
        let anyOverlap = false

        merged.highlightRects.forEach((newRect) => {
          const existingRectIndex = annotation.highlightRects!.findIndex((existingRect) =>
            isOverlapLine(existingRect, newRect, annotation.rotate)
          )

          if (existingRectIndex !== -1) {
            mergedRects[existingRectIndex] = mergeOverlapRects(
              mergedRects[existingRectIndex],
              newRect,
              annotation.rotate
            )
            hasChanges = true
            anyOverlap = true
          } else {
            mergedRects.push(newRect)
            hasChanges = false
          }
        })

        if (anyOverlap) {
          merged.highlightRects = removeNestedRects(mergedRects)
          return false
        }
      }
      return true
    })

    if (hasChanges) {
      return mergeOverlappingAnnotations(updatedAnnotations, merged)
    } else if (merged.highlightRects) { //EXP
      merged.highlightRects = removeNestedRects(merged.highlightRects) //EXP
    } //EXP

    return [...updatedAnnotations, merged]
  }

  const handleTextSelectionEnd = (e: MouseEvent) => {
    document.getSelection()?.removeAllRanges()

    if (currentRect) {
      const newAnnotation: Annotation = {
        id: null,
        color: 'red',
        highlightRects: currentRect,
        rotate,
        type: 'highlight'
      }
      setAnnotations(mergeOverlappingAnnotations(annotations, newAnnotation))
      setCurrentRect(null)
    }
    setIsDrawing(false)
  }


  useEffect(() => {
    const document = documentRef.current
    if (document && annotationType === 'highlight') {
      document.addEventListener('mousedown', handleTextSelectionStart)
      document.addEventListener('mousemove', handleTextSelectionMove)
      document.addEventListener('mouseup', handleTextSelectionEnd)
    }

    return () => {
      document?.removeEventListener('mousedown', handleTextSelectionStart)
      document?.removeEventListener('mousemove', handleTextSelectionMove)
      document?.removeEventListener('mouseup', handleTextSelectionEnd)
    }
  }, [documentRef, handleTextSelectionEnd, handleTextSelectionMove])

  useEffect(() => {
    const documentElement = document.querySelector(`[data-page-number='${pageNumber}']`)

    if (documentElement && annotationType === 'highlight') {
      const brElements = Array.from(documentElement.getElementsByTagName('br'))
      brElements.forEach(br => br.parentNode?.removeChild(br))

      const endElement = documentElement.querySelector('.endOfContent')
      if (endElement) {
        endElement.remove()
      }
    }
  }, [annotationType, pageNumber])

  return { drawHightLight }
}
