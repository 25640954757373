import { Avatar, Stack } from '@mui/material'

import { useAccess } from '../../../../../../../Schemes/InternalAgreementSchemes'
import { MembersMenu } from '../../../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementTable/components/MembersMenu'
import {
  ApprovalIcon,
  AssuranceIcon,
  IconWrapper,
  NameBlock,
  RemarksIcon,
  SigningIcon,
  StyledName,
  StyledPosition,
  TableRow,
} from './MemberRow.styles'
import { MemberRowProps } from './MemberRow.types'

export const MemberRow = ({ member, deleteMember, editMember }: MemberRowProps) => {
  const { isAdmin, isModerator } = useAccess()

  const handleEditMember = () => editMember(member)
  const handleDeleteMember = () => deleteMember(member.id)

  return (
    <TableRow direction='row' spacing={1}>
      <Stack direction='row' justifyContent='space-between' width='100%'>
        <NameBlock direction='row' spacing={1}>
          <Avatar src={member.person.avatar} sx={{ width: '24px', height: '24px', marginLeft: '8px' }} />
          <Stack direction='column'>
            <StyledName>{member.person.name}</StyledName>
            <StyledPosition>{member.person.position}</StyledPosition>
          </Stack>
        </NameBlock>
        <IconWrapper direction='row'>
          <ApprovalIcon $approval={member.approval} fontSize='small' />
        </IconWrapper>
        <IconWrapper direction='row'>
          <AssuranceIcon $assurance={member.assurance} fontSize='small' />
        </IconWrapper>
        <IconWrapper direction='row'>
          <SigningIcon $signing={member.signing} fontSize='small' />
        </IconWrapper>
        <IconWrapper direction='row'>
          <RemarksIcon $remarks={member.remarks} fontSize='small' />
        </IconWrapper>
      </Stack>
      {(isAdmin || isModerator) && (
        <MembersMenu
          onFirstItemClick={handleEditMember}
          onSecondItemClick={handleDeleteMember}
          firstItemTitle='Редактировать'
          secondItemTitle='Удалить участника'
        />
      )}
    </TableRow>
  )
}
