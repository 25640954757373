import { Stack } from "@mui/material"
import { CheckBoxInput, FieldName, StyledButton, StyledSpanSwithInput, SwitchInput, TextInput, TimeField, TimeInput } from "./SchemeSettings.styles"
import { useAppDispatch, useTypedSelector } from "../../../../../../store/store"
import { currentSchemaSelector, initialSchemaCompanyFromMenuSelector, schemaFormModeSelector, setCurrentSchema, setInitialSchemaCompanyFromMenu, setSchemaFormMode } from "../../../../../../store/slices/internalAgreement"
import { InternalAgreementPerson } from "../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementPerson"
import { Form, FormikProvider } from "formik"
import Tooltip from "../../../../../../components/Tooltip"
import { useForm } from "../../../../../../hooks/useForm";
import { SchemeSettingsInputValues } from "../SchemeSelection";
import { InnermapRequest } from "../../../../../../api/internalAgreement/api.types";
import { useCreateInnermapMutation, useEditInnermapMutation } from "../../../../../../api/internalAgreement";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { profileSelector } from "../../../../../../store/slices/profile"
import { useAccess } from "../../../../../Schemes/InternalAgreementSchemes"
import { setStageCompanySelector } from "../../../../../../store/slices/agreement"
import { validationSchema } from "../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementControl"


interface SchemeSettingsProps {
  isSchemaBinding: boolean
}

export const SchemeSettings = ({ isSchemaBinding }: SchemeSettingsProps) => {

  const dispatch = useAppDispatch()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const profile = useTypedSelector(profileSelector)
  const schemaFormMode = useTypedSelector(schemaFormModeSelector)
  const currentSchema = useTypedSelector(currentSchemaSelector)
  const initialSchemaCompanyFromMenu = useTypedSelector(initialSchemaCompanyFromMenuSelector)
  const stageCompany = useTypedSelector(setStageCompanySelector)
  const { isAdmin } = useAccess()

  const [createSchema, createSchemaResponse] = useCreateInnermapMutation()
  const [editSchema, editSchemaResponse] = useEditInnermapMutation()


  const initialValues: SchemeSettingsInputValues = {
    companyName: schemaFormMode === 'create' ? initialSchemaCompanyFromMenu : currentSchema?.company,
    duration: schemaFormMode === 'edit' ? currentSchema.duration : null,
    expandability: schemaFormMode === 'edit' ? currentSchema.expandability : false,
    publication: schemaFormMode === 'edit' ? currentSchema.publication : false,
    personId: schemaFormMode === 'edit' ? currentSchema.moderator.id : (schemaFormMode === 'create' && !isAdmin && !isSchemaBinding) ? profile.employeeId : null,
    title: schemaFormMode === 'edit' ? currentSchema.title : '',
    unit: schemaFormMode === 'edit' ? currentSchema.unit === 'hour' && true : false
  }

  const onSubmit = (values: SchemeSettingsInputValues) => {

    const commonData: InnermapRequest = {
      company: values.companyName,
      duration: values.duration || null,
      expandability: values.expandability,
      moderatorId: values.personId,
      publication: values.publication,
      title: values.title as string,
      unit: values.unit ? 'hour' : 'day'
    }

    schemaFormMode === 'create' && createSchema({ id: projectId, body: commonData })
    schemaFormMode === 'edit' && currentSchema.id && editSchema({
      ...commonData,
      id: projectId,
      innermapId: currentSchema.id
    })
    dispatch(setInitialSchemaCompanyFromMenu({ initialSchemaCompanyFromMenu: '' }))
  }

  const { formik } = useForm({
    validationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values)
  })

  useEffect(() => {
    if (createSchemaResponse.data) {
      dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
      dispatch(setCurrentSchema(createSchemaResponse.data))
    }
  }, [createSchemaResponse.data, dispatch])

  useEffect(() => {
    if (editSchemaResponse.data) {
      dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
      dispatch(setCurrentSchema(editSchemaResponse.data))
    }
  }, [dispatch, editSchemaResponse.data])

  return (
    <FormikProvider value={formik}>
      <Stack direction='row' component={Form} spacing='12px' justifyContent='space-between' alignItems='center'>
        <TextInput
          name='title'
          placeholder='Укажите название'
          version='project'
        />

        <InternalAgreementPerson
          autocompleteHeight="30px"
          mode={schemaFormMode}
          stageCompany={stageCompany}
          person={currentSchema?.moderator}
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' spacing='12px' justifyContent='space-between'>
          <Stack direction='row' alignItems='center' spacing={1}>
            <FieldName>Срок:</FieldName>
            <TimeField direction='row' spacing={0}>
              <TimeInput
                inputProps={{ variant: 'standart' }}
                name='duration'
                placeholder='00'
                version='project' />
              <SwitchInput
                name='unit'
                version='switch'
                checkboxData={{
                  label: null,
                  checked: formik.values.unit,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('unit', e.target.checked)
                  }
                }} />
              <StyledSpanSwithInput $checked={formik.values.unit ? false : true}>ДНИ</StyledSpanSwithInput>
              <StyledSpanSwithInput $checked={formik.values.unit ? true : false}>ЧАС</StyledSpanSwithInput>
            </TimeField>
          </Stack>

          <Tooltip title={<>Разрешить участникам внутреннего согласования дополнять схему</>}>
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
              <FieldName>
                Дополнять схему
              </FieldName>
              <CheckBoxInput
                name='expandability'
                version='checkbox'
                InputProps={{ defaultChecked: currentSchema?.expandability }}
                checkboxData={{
                  label: null,
                  checked: formik.values.expandability,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('expandability', e.target.checked)
                  }
                }}
              />
            </Stack>
          </Tooltip>

          <Tooltip title={<>Опубликовать том для компании после утверждения</>}>
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
              <FieldName>
                Публикация
              </FieldName>
              <CheckBoxInput
                name='publication'
                version='checkbox'
                InputProps={{ defaultChecked: currentSchema?.publication }}
                checkboxData={{
                  label: null,
                  checked: formik.values.publication,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('publication', e.target.checked)
                  }
                }}
              />
            </Stack>
          </Tooltip>
        </Stack>
        <StyledButton onClick={() => formik.submitForm()}
        disabled={!formik.isValid || createSchemaResponse.isLoading || editSchemaResponse.isLoading}>
          {schemaFormMode === 'create' ? 'Создать схему' : 'Сохранить'}
        </StyledButton>
      </Stack>
    </FormikProvider>
  )
}