import { Search as SearchIcon } from '@mui/icons-material'
import { Checkbox, Divider, Select, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { OverflowTextNew } from '@components/OverflowTextNew'
import { ObjectsSelectMenuItem } from '@components/UserManagement/components/UserObjectsSelection/styles'

import { profileSelector } from '@store/slices/profile'
import { setRemarkGroupFilter } from '@store/slices/remarks/remarks'
import { remarkGroupFilterSelector } from '@store/slices/remarks/selectors/remarks.selectors'
import { useAppDispatch, useTypedSelector } from '@store/store'

import { theme } from '@styles/theme'

import { measureTextWidth } from '@utils/measureTextWidth'

import { StyledSearch } from './RemarkAuthorSelect.styles'
import { IRemarkAuthorSelectProps, TItemByValue } from './RemarkAuthorSelect.types'

export const RemarkAuthorSelect: FC<IRemarkAuthorSelectProps> = ({ authors, isDisabled }) => {
  const groupFilter = useTypedSelector(remarkGroupFilterSelector)
  const { authors: selectedAuthorsSelector } = groupFilter || {}
  const selectedAuthors: number[] = selectedAuthorsSelector || []
  const { employeeId } = useTypedSelector(profileSelector)
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const dispatch = useAppDispatch()
  const maxWidth = 230
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined)
  const selectRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (selectRef.current) {
      setMenuWidth(selectRef.current.clientWidth)
    }
  }, [])

  const handleChange = useCallback(
    (personId: number) => {
      const isSelected: boolean = selectedAuthors.includes(personId)
      const localSelectedAuthors: number[] = [...selectedAuthors]

      dispatch(
        setRemarkGroupFilter({
          remarkGroupFilter: {
            ...groupFilter,
            authors: isSelected
              ? localSelectedAuthors.filter((item) => item !== personId)
              : [...localSelectedAuthors, personId],
          },
        }),
      )
    },
    [selectedAuthors, groupFilter],
  )

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const filtredAuthors = useMemo(() => {
    const formattedSearchValue: string = searchValue.trim().toLowerCase()

    if (!formattedSearchValue) return authors

    return authors
      .map((author) => ({
        company: author.company,
        persons: author.persons.filter((person) => {
          const formattedPersonname: string = person.name.trim().toLowerCase()

          return formattedPersonname.includes(formattedSearchValue)
        }),
      }))
      .filter((item) => item.persons.length > 0)
  }, [authors, searchValue])

  const itemByValue: TItemByValue = useMemo(() => {
    let result: TItemByValue = {}

    result = authors.reduce((acc, item) => {
      item.persons.forEach((person) => {
        acc[person.id] = person.name
      })

      return acc
    }, {} as TItemByValue)

    result[employeeId] = 'Мои замечания'

    return result
  }, [employeeId, authors])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Select
      ref={selectRef}
      disabled={isDisabled}
      multiple
      open={open}
      value={selectedAuthors}
      onClose={handleClose}
      onOpen={handleOpen}
      variant='standard'
      disableUnderline
      displayEmpty
      renderValue={(selected) => {
        const allPersonsLength = authors.reduce((acc, curr) => acc + curr.persons.length, 0)
        const profileSelected: boolean = selected.includes(employeeId)
        const allSelected: boolean = selected.length >= allPersonsLength && profileSelected

        if (allSelected || selected.length === 0) {
          return (
            <Typography variant='body2' pl={1} lineHeight={'inherit'} color={theme.palette.text.dark}>
              Все авторы
            </Typography>
          )
        }

        let totalWidth = 0
        let visibleCount = 0

        selected.forEach((itemValue) => {
          const selectedPersonName: string = itemByValue[itemValue]

          if (selectedPersonName) {
            const width = measureTextWidth(selectedPersonName + ', ')
            totalWidth += width

            if (totalWidth <= maxWidth) visibleCount++
            else return
          }
        })

        const hiddenCount = selected.length - visibleCount

        return (
          <Stack direction={'row'} spacing={1}>
            <Typography
              variant='body2'
              textOverflow='ellipsis'
              overflow='hidden'
              className='contentText'
              lineHeight={'inherit'}
              pl={1}
            >
              {selected?.map((itemValue) => itemByValue[itemValue]).join(', ')}
            </Typography>

            {!allSelected && !!hiddenCount && (
              <Tooltip
                arrow
                PopperProps={{
                  sx: {
                    filter:
                      'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10))',
                    [`& .${tooltipClasses.tooltip}`]: {
                      padding: '5px',
                      background: ' #FFF',
                      color: '#2B3648',
                      maxWidth: 270,
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      borderRadius: '6px',
                    },
                    [`& .${tooltipClasses.arrow}`]: {
                      color: ' #FFF',
                    },
                  },
                }}
                placement='right'
                title={selected?.map((itemValue) => itemByValue[itemValue]).join(', ')}
              >
                <Stack
                  ml={'auto'}
                  justifyContent={'center'}
                  height={'24px'}
                  p={'0px 8px !important'}
                  width={'fit-content'}
                  borderRadius={3}
                  bgcolor={theme.palette.bg.lightBlue}
                >
                  <Typography variant='body2' color={`${theme.palette.primary.main} !important`}>
                    + {hiddenCount}
                  </Typography>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        )
      }}
      sx={{
        '& > .MuiSvgIcon-root': {
          right: '8px',
          fontSize: '20px !important',
          color: '#9AA2B0',
        },
        '& .MuiInputBase-input': {
          borderRadius: '6px !important',
          padding: '7px 0px',
          paddingRight: '32px !important',
          backgroundColor: '#F6F7FB !important',
          border: !open ? '1px solid  #2B36481F' : '1px solid  #0044B4',
          ':hover': {
            border: !open ? '1px solid  #2B3648' : '1px solid  #0044B4',
          },
        },
        '& div': {
          alignItems: 'center',
          paddingRight: 0.2,
        },
      }}
      MenuProps={{
        disableAutoFocusItem: true,
        PaperProps: {
          style: {
            minWidth: `${menuWidth}px`,
            maxWidth: `${menuWidth}px`,
          },
        },
      }}
    >
      <Stack overflow={'auto'} maxHeight={'300px'}>
        <Stack direction={'row'} alignItems={'center'} p={'8px 16px'} spacing={1}>
          <SearchIcon fontSize='medium' color='secondaryDark' />
          <StyledSearch value={searchValue} onChange={onChangeSearch} placeholder='Поиск' />
        </Stack>

        <ObjectsSelectMenuItem onClick={() => handleChange(employeeId)}>
          <Checkbox checked={selectedAuthors.includes(employeeId)} />
          <Typography variant='body2' color={theme.palette.primary.main}>
            Мои замечания
          </Typography>
        </ObjectsSelectMenuItem>

        <Divider />

        {filtredAuthors.map((author) => {
          return (
            <Stack key={author.company}>
              <Typography variant='body2' padding={'8px 16px'} borderBottom={`1px solid ${theme.palette.legends.gray}`}>
                {author.company}
              </Typography>

              {author.persons.map((person) => (
                <ObjectsSelectMenuItem onClick={() => handleChange(person.id)} key={person.id} value={person.id}>
                  <Checkbox checked={selectedAuthors.includes(person.id)} />
                  <OverflowTextNew variant='body2' display={'block !important'}>
                    {person.name}
                  </OverflowTextNew>
                </ObjectsSelectMenuItem>
              ))}
            </Stack>
          )
        })}
      </Stack>
    </Select>
  )
}
