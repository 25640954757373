import styled from 'styled-components'

import { DocsFilterStyledAccordion } from '@pages/Docs'

export const RemarksFilterStyledAccordion = styled(DocsFilterStyledAccordion)`
  padding-right: 0;

  &.Mui-expanded {
    margin: 12px 0;
  }
`
