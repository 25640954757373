import CreateIcon from '@mui/icons-material/Create'
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowUp'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, Stack, Typography } from '@mui/material'
import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  ColumnResizedEvent,
  ColumnState,
  GetRowIdParams,
  GridReadyEvent,
  ICellRendererParams,
  RowClassParams,
  RowDataUpdatedEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { TomIiType, getTomIiShortType } from 'api/iiPhase'
import { DocMoveIcon } from 'assets/icons/DocMove'
import { DocReadyIcon } from 'assets/icons/DocReady'
import ColoredStatus from 'components/ColoredStatus'
import ColoredTitle from 'components/ColoredTitle'
import { OverflowText } from 'components/OverflowText/OverflowText'
import Progress from 'components/Progress'
import Tooltip from 'components/Tooltip'
import { compareAsc, startOfDay } from 'date-fns'
import useRouteTracker from 'hooks/useRouteTracker'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { selectedProjectPhaseSelector } from 'store/slices/documentsPages/projectInfo'
import { setAllowScrolling } from 'store/slices/remarks/remarks'
import { allowScrollingSelector } from 'store/slices/remarks/selectors/remarks.selectors'
import { useAppDispatch, useTypedSelector } from 'store/store'
import { StyledAgGridWrapper } from 'styles/global/StyledAgGridWrapper'
import { IN_PROD_TOM_TITLE, TOM_ANNULLED_TITLE, TomStatus, isIiTom, isRdTom, tomStatusesColors } from 'types/tom'
import { formatToDate } from 'utils/formatDate'

import { TColoredTitleColor } from '@components/ColoredTitle/ColoredTitle.types'

import { profileSelector } from '@store/slices/profile'

import { theme } from '@styles/theme'

import { TChangeStatus, TOM_CHANGE_DEFAULT_STATUS } from '../DocsChangeForm'
import { TomProdButton } from '../TomProdButton'
import { CreateIconContainer, LabelText, Level, StyledIconButton } from './AgGridToms.styles'
import { AgGridTomsProps, CollapseParams, ToProdParams, TomRowData, onSendToProdClickProps } from './AgGridToms.types'

import 'ag-grid-community/styles/ag-theme-alpine.css'

const collapseCell = (id: number, close: boolean, collapse: (id: number) => void) => {
  return (
    <Level
      arrowClose={close}
      ref={(ref: any) => {
        if (!ref) return
        ref.onclick = (e: any) => {
          e.stopPropagation()
          collapse(id)
        }
      }}
    >
      <KeyboardArrowDownIcon fontSize='large' />
    </Level>
  )
}

const showAllButton = (showAll: boolean, handleClick: (showAll: boolean) => void) => {
  return (
    <Tooltip variant='light' title={<>{showAll ? 'Скрыть изменения' : 'Показать изменения'}</>}>
      <StyledIconButton onClick={() => handleClick(showAll)}>
        {showAll ? <VisibilityOffIcon fontSize='large' /> : <VisibilityIcon fontSize='large' />}
      </StyledIconButton>
    </Tooltip>
  )
}

// TODO: class emptyDocRow for new nulled status

export const AgGridToms = ({ tomData, onTomItemClick, onSendToProdClick }: AgGridTomsProps) => {
  const dispatch = useAppDispatch()

  const { role } = useTypedSelector(profileSelector)
  const { allowScrolling } = useTypedSelector(allowScrollingSelector)
  const savedColumnState = localStorage.getItem('tomsColumnState')
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const accessTomSendToProd: boolean = role === 'admin' || role === 'client'
  const flexCell = 'ag-cell_flex ag-cell_justify-content_center ag-cell_align-items_center font-size_14'
  const rowClassRules = {
    'ag-row_style_shades': (params: RowClassParams) => {
      return !params.data?.isChange
    },
  }

  const [tomChangesIds, setTomChangesIds] = useState<number[]>([])
  const [showAll, setShowAll] = useState<boolean>(false)

  const { previousPath, removePreviousPath } = useRouteTracker({ record: false })

  const collapse = useCallback((tomId: number) => {
    setTomChangesIds((idsArray) => {
      if (idsArray.includes(tomId)) {
        return idsArray.filter((id) => id !== tomId)
      } else {
        return [...idsArray, tomId]
      }
    })
  }, [])

  const onShowAllClick = useCallback((showAll: boolean) => {
    if (showAll) {
      setTomChangesIds([])
    } else {
      const tomWithChanges = tomData.filter((data) => data.changes.length > 0)
      const ids = tomWithChanges.map(({ tom }) => tom.id)
      setTomChangesIds(ids)
    }
    setShowAll((prev) => !prev)
  }, [])

  useEffect(() => {
    if (!tomChangesIds.length) {
      setShowAll(false)
    } else {
      setShowAll(true)
    }
  }, [tomChangesIds.length])

  const columnDef = useMemo(
    (): (ColDef | ColGroupDef)[] => [
      {
        field: 'collapse',
        headerComponent: () => showAllButton(showAll, onShowAllClick),
        flex: !savedColumnState ? 1 : undefined,
        width: savedColumnState
          ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'collapse').width
          : 50,
        minWidth: 50,
        cellClass: [flexCell],
        suppressMovable: true,
        lockPosition: true,
        cellRenderer: (params: ICellRendererParams<TomRowData, CollapseParams>) =>
          params.value?.hasChanges ? collapseCell(params.value.id, params.value.close, collapse) : null,
      },
      {
        field: 'title',
        headerName: 'Шифр РД',
        flex: !savedColumnState ? 2 : undefined,
        width: savedColumnState
          ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'title').width
          : 220,
        minWidth: 220,
        cellClass: ['ag-cell_flex ag-cell_overflow_visible ag-cell_align-items_center ag-cell_defaultPadding'],
        cellRenderer: (params: ICellRendererParams) => (
          <OverflowText
            mode='table'
            text={params.value}
            color={params.data.annulment.annulled ? theme.palette.text.disabled : theme.palette.text.dark}
            defaultHeight={59 - 16}
          />
        ),
      },
      {
        field: 'description',
        headerName: 'Полное наименование',
        flex: !savedColumnState ? 3 : undefined,
        width: savedColumnState
          ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'description').width
          : 300,
        minWidth: 300,
        cellClass: ['ag-cell_flex ag-cell_overflow_visible ag-cell_align-items_center ag-cell_defaultPadding'],
        cellRenderer: (params: ICellRendererParams) => (
          <OverflowText
            mode='table'
            text={params.value}
            color={params.data.annulment.annulled ? theme.palette.text.disabled : theme.palette.text.dark}
            defaultHeight={59 - 16}
          />
        ),
      },
      {
        field: 'type',
        headerName: 'Тип',
        hide: selectedProjectPhase !== 'Инженерные изыскания',
        flex: !savedColumnState ? 1 : undefined,
        width: savedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'type').width : 73,
        minWidth: 73,
        cellClass: ['ag-cell_flex ag-cell_align-items_center ag-cell_defaultPadding'],
        cellRenderer: (params: ICellRendererParams) => (
          <Typography
            variant='body2'
            color={params.data.annulment.annulled ? theme.palette.text.disabled : theme.palette.text.dark}
          >
            {getTomIiShortType[params.data.type as TomIiType]}
          </Typography>
        ),
      },
      {
        field: 'version',
        headerName: 'Версия',
        flex: !savedColumnState ? 1 : undefined,
        width: savedColumnState
          ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'version').width
          : 52,
        minWidth: 52,
        cellClass: [flexCell],
        cellRenderer: (params: ICellRendererParams<TomRowData>) => {
          const value: string = params.value
          const noAccess: boolean = params.data?.accessStatus === 'close'
          return !params.data?.changeMax && value === null ? (
            <Tooltip variant='light' title={<>Нет загруженных документов</>}>
              <Box display='inline-flex'>
                <FileDownloadOffIcon fontSize='small' color={noAccess ? 'disabled' : 'error'} />
              </Box>
            </Tooltip>
          ) : (
            <Typography
              variant='body2'
              color={params.data?.annulment.annulled ? theme.palette.text.disabled : theme.palette.text.dark}
            >
              {value === null ? '—' : value}
            </Typography>
          )
        },
      },
      {
        field: 'changeMax',
        headerName: 'Изменение',
        flex: !savedColumnState ? 1 : undefined,
        width: savedColumnState
          ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'changeMax').width
          : 80,
        minWidth: 80,
        cellClass: [flexCell],
        cellRenderer: (params: ICellRendererParams<TomRowData>) => {
          const isAnnulled: boolean = !!params.data?.annulment.annulled

          return (
            <>
              {!isAnnulled && params.data?.changeMode ? (
                <Stack direction='row' alignItems='center'>
                  {!params.value ? '' : params.value}
                  <Tooltip
                    variant='light'
                    title={
                      <Stack spacing={0.75}>
                        {params.value && (
                          <Typography variant='body2' fontSize={12}>
                            Текущее изменение: {params.value}
                          </Typography>
                        )}
                        <LabelText variant='body2' color='' fontSize={12}>
                          Вносятся изменения
                        </LabelText>
                      </Stack>
                    }
                  >
                    <CreateIconContainer>
                      <CreateIcon fontSize='small' />
                    </CreateIconContainer>
                  </Tooltip>
                </Stack>
              ) : (
                <Typography variant='body2' color={isAnnulled ? theme.palette.text.disabled : theme.palette.text.dark}>
                  {params.value === null ? '—' : params.value}
                </Typography>
              )}
            </>
          )
        },
      },
      {
        headerName: 'Даты согласования',
        minWidth: 200,
        marryChildren: true,
        children: [
          {
            field: 'plannedDate',
            headerName: 'Плановая',
            flex: !savedColumnState ? 1 : undefined,
            width: savedColumnState
              ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'plannedDate').width
              : 100,
            minWidth: 100,
            cellClass: [flexCell],
            cellRenderer: (params: ICellRendererParams) => {
              const noAccess: boolean = params.data.accessStatus === 'close'
              const value: string = params.value
              const isAnnulled: boolean = params.data?.annulment.annulled

              return !isAnnulled &&
                params.data.status !== 'Согласовано' &&
                !params.data.forecastDate &&
                compareAsc(formatToDate(params.data.plannedDate)!, startOfDay(new Date())) === -1 ? (
                <ColoredStatus color={noAccess ? 'disabled' : 'error'}>
                  <Typography variant='subtitle2' component='span'>
                    {value}
                  </Typography>
                </ColoredStatus>
              ) : (
                <Typography variant='body2' color={isAnnulled ? theme.palette.text.disabled : theme.palette.text.dark}>
                  {value || '—'}
                </Typography>
              )
            },
          },
          {
            field: 'actualDate',
            headerName: 'Фактическая',
            flex: !savedColumnState ? 1 : undefined,
            width: savedColumnState
              ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'actualDate').width
              : 100,
            minWidth: 100,
            cellClass: [flexCell],
            cellRenderer: (params: ICellRendererParams) => {
              const status: TomStatus = params.data.status
              const actualDate: string | null = params.data.actualDate
              const issueDate: string | null = params.data.issueDate
              const isAnnulled: boolean = params.data?.annulment.annulled

              return (
                <Typography variant='body2' color={isAnnulled ? theme.palette.text.disabled : theme.palette.text.dark}>
                  {(status === 'Не согласуется' ? issueDate : actualDate) || '—'}
                </Typography>
              )
            },
          },
        ],
      },
      {
        field: 'status',
        headerName: 'Статус',
        flex: !savedColumnState ? 2 : undefined,
        width: savedColumnState
          ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'status').width
          : 154,
        minWidth: 154,
        cellClass: [flexCell, 'statusCell'],
        cellRenderer: (params: ICellRendererParams) => {
          const status: TomStatus = params.value
          const inProd: boolean = params.data?.toProd?.inProd || false
          const noAccess: boolean = params.data.accessStatus === 'close'
          const isAnnulled: boolean = params.data.annulment.annulled
          const isChange: boolean = params.data.isChange

          let body: string = status
          let color: TColoredTitleColor = 'noAccess'

          if (isAnnulled && !isChange) body = TOM_ANNULLED_TITLE
          else if (inProd) body = IN_PROD_TOM_TITLE

          if ((noAccess || isAnnulled) && !isChange) color = 'noAccess'
          else if (inProd) color = tomStatusesColors[IN_PROD_TOM_TITLE]
          else if (!inProd) color = tomStatusesColors[status] || theme.palette.bg.shades

          return (
            <ColoredTitle
              body={body}
              color={color}
              style={{
                width: '100%',
                marginBottom: 0,
              }}
            />
          )
        },
      },
      {
        field: 'toProd',
        hide: selectedProjectPhase !== 'Рабочая документация',
        headerName: '',
        flex: !savedColumnState ? 1 : undefined,
        width: savedColumnState
          ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'toProd').width
          : 50,
        minWidth: 50,
        cellClass: [flexCell],
        cellRenderer: (params: ICellRendererParams<TomRowData, ToProdParams>) => {
          const isAnnulled: boolean = !!params.data?.annulment.annulled

          if (params.data?.status !== 'Согласовано' || isAnnulled) return null

          if (!params.value?.inProd && !accessTomSendToProd) return null

          return <TomProdButton params={params.value as ToProdParams} onSendToProdClick={onSendToProdClick} />
        },
      },
    ],
    [collapse, onSendToProdClick, onShowAllClick, savedColumnState, selectedProjectPhase, showAll, accessTomSendToProd],
  )

  const rowData = useMemo((): Partial<TomRowData>[] | undefined => {
    if (!tomData) return undefined

    const data: Partial<TomRowData>[] = tomData.flatMap(({ tom, changes }) => {
      const mainRow: TomRowData = {
        id: tom.id,
        accessStatus: tom.accessStatus,
        collapse: {
          id: tom.id,
          hasChanges: changes.length > 0,
          close: tomChangesIds.includes(tom.id),
        },
        title: tom.title,
        description: tom.description,
        version: tom.version,
        changeMax: tom.changeMax,
        type: isIiTom(tom) ? tom.type : null,
        actualDate: tom.actualDate || '—',
        plannedDate: tom.plannedDate || '—',
        status: tom.status as TomStatus,
        isChange: false,
        changeMode: tom.features.changeMode,
        annulment: tom.features.annulment,
        toProd: isRdTom(tom)
          ? {
              id: tom.id,
              inProd: tom.inProd,
              prodDate: tom.prodDate,
              title: tom.title,
            }
          : null,
      }

      if (tomChangesIds.includes(tom.id)) {
        const changeRows: Partial<TomRowData>[] = changes.map((change) => ({
          id: tom.id,
          changeId: change.id,
          accessStatus: tom.accessStatus,
          changeMax: change.change,
          plannedDate: change.plannedDate || '—',
          actualDate: change.actualDate || '—',
          status: (change.status as TChangeStatus) || TOM_CHANGE_DEFAULT_STATUS,
          isChange: true,
          annulment: tom.features.annulment,
          toProd: isRdTom(tom)
            ? {
                id: tom.id,
                inProd: change.inProd,
                prodDate: change.prodDate,
                changeNum: change.change,
              }
            : null,
        }))
        return [mainRow, ...changeRows]
      }
      return [mainRow]
    })

    return data
  }, [tomChangesIds, tomData])

  const gridReadyListener = (e: GridReadyEvent) => {
    const savedColumnState = localStorage.getItem('tomsColumnState')
    if (savedColumnState) {
      e.columnApi.applyColumnState({
        state: JSON.parse(savedColumnState),
        applyOrder: true,
      })
    }
    if (previousPath) {
      const id = previousPath.substring(previousPath.lastIndexOf('/') + 1)
      const updatedRow = tomData.findIndex(({ tom }) => tom.id === Number(id))
      const editedCell = updatedRow === 0 ? 1 : updatedRow
      const editedRowNode = e.api.getDisplayedRowAtIndex(updatedRow)!
      if (editedCell !== -1) {
        e.api.ensureIndexVisible(editedCell, 'middle')
        e.api.flashCells({ rowNodes: [editedRowNode], flashDelay: 3000, fadeDelay: 1000 })
      }
      removePreviousPath()
    }
  }

  const columnResizedListener = useCallback((e: ColumnResizedEvent) => {
    const columnState = e.columnApi.getColumnState()
    const withoutSortState = columnState.map(({ sort, hide, ...state }) => state)
    if (e.finished) {
      localStorage.setItem('tomsColumnState', JSON.stringify(withoutSortState))
    }
  }, [])

  const cellClickedListener = (e: CellClickedEvent<TomRowData>) => {
    const notAllowedCell = e.colDef.field === 'collapse' || e.colDef.field === 'toProd'
    if (notAllowedCell) return
    if (e.data) {
      onTomItemClick(e.data.id, e.data.accessStatus, e.data.isChange && e.data.changeId ? e.data.changeId : null)
    }
  }

  const onRowDataUpdatedListener = (e: RowDataUpdatedEvent) => {
    const lastRenderedRow = e.api.getDisplayedRowCount()
    const createdRowNode = e.api.getDisplayedRowAtIndex(lastRenderedRow - 1)!

    if (lastRenderedRow && allowScrolling) {
      e.api.showLoadingOverlay()
      setTimeout(() => {
        e.api.ensureIndexVisible(lastRenderedRow - 1, 'bottom')
        e.api.flashCells({ rowNodes: [createdRowNode], flashDelay: 3000, fadeDelay: 1000 })
      }, 250)
      setTimeout(() => {
        e.api.hideOverlay()
        dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: false, mode: 'create', id: null } }))
      }, 500)
    }
  }

  return (
    <StyledAgGridWrapper>
      <div className='ag-theme-alpine'>
        <AgGridReact
          onGridReady={gridReadyListener}
          onColumnResized={columnResizedListener}
          onCellClicked={cellClickedListener}
          onRowDataUpdated={onRowDataUpdatedListener}
          columnDefs={columnDef}
          rowData={rowData}
          getRowId={(params: GetRowIdParams) => `${params.data.id}-${params.data.changeId || params.data.changeMax}`}
          headerHeight={32}
          detailRowAutoHeight
          rowHeight={58}
          rowClassRules={rowClassRules}
          suppressRowTransform={true}
          suppressDragLeaveHidesColumns={true}
          loadingOverlayComponent={Progress}
          overlayNoRowsTemplate='Нет данных для отображения'
          className='scroll_shades'
          defaultColDef={{
            cellDataType: false,
            editable: false,
            sortable: false,
            filter: false,
            resizable: true,
          }}
        />
      </div>
    </StyledAgGridWrapper>
  )
}
