import { Container, Stack, Typography } from '@mui/material'
import styled from 'styled-components'
import { boolean } from 'yup'

import { TomColoredInfo } from '../../../Docs/components/TomCard/TomCard.styles'

export const PocketCardWrapper = styled(Stack)`
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 286px;
  padding: 20px;
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    box-shadow:
      0px 6px 20px 2px rgba(16, 24, 40, 0.1),
      0px 7px 8px -3px rgba(16, 24, 40, 0.08),
      0px -6px 12px 0px rgba(16, 24, 40, 0.08);
  }

  & .MuiDivider-root {
    border-color: #d1d8fa;
  }
`

export const PocketColoredInfo = styled(TomColoredInfo)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  height: 24px;

  & .MuiTypography-root {
    font-weight: 400;
    text-transform: none;
    white-space: nowrap;
  }
`
export const PocketStatusInfo = styled(TomColoredInfo)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0;
  min-height: 32px;

  & .MuiTypography-root {
    font-weight: 400;
    text-transform: none;
    white-space: nowrap;
    color: ${(props) => props.customColor === 'orange' && props.theme.palette.table.orange};
  }
`

export const PocketSubtitle = styled(Typography)`
  text-align: left;
  color: ${(props) => props.theme.palette.text.dark};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const PocketTitle = styled(PocketSubtitle)`
  font-weight: 500;
  font-size: 16px;
`
