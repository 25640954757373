import React, { useCallback, useState } from 'react'
import { UploadDrawerProps } from './UploadDrawer.types'
import { Drawer, Typography } from '@mui/material'
import { UploadDrawerTopBar, UploadDrawerWrapper } from './styles'
import { theme } from '../../styles/theme'
import Divider from '../Divider'
import UploadDrawerForm from './UploadDrawerForm'
import UploadResultsDrawer from './UploadResultsDrawer'

const UploadDrawer: React.FC<UploadDrawerProps> = ({
  open,
  mode,
  onSubmit,
  responseData,
  onChange,
  onClose,
  title,
  text,
  attentionText,
  uploadData,
  getExampleLink,
  loading,
  ContentBeforeFileUpload,
  ContentAfterLinkExample
}) => {
  const [dirty, setDirty] = useState<boolean>(false)
  const [isResultsDrawerOpen, setIsResultsDrawerOpen] = useState<boolean>(false)

  const onResultsDrawerOpen = useCallback(() => {
    setIsResultsDrawerOpen(true)
  }, [])

  const onResultsDrawerClose = useCallback(() => {
    setIsResultsDrawerOpen(false)
  }, [])

  const onDrawerClose = (dirty: boolean, immediately?: boolean) => {
    onClose(dirty, immediately)
  }

  const onFormChange = useCallback((dirty: boolean) => {
    onChange()
    setDirty(dirty)
  }, [onChange])

  const onFormSubmit = useCallback((value: File[]) => {
    onSubmit(value)
    setDirty(false)
  }, [onSubmit])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => onDrawerClose(dirty)}
    >
      <UploadDrawerWrapper>
        <UploadDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {title}
          </Typography>
        </UploadDrawerTopBar>

        <Divider />

        <UploadDrawerForm
          mode={mode}
          onSubmit={onFormSubmit}
          onChange={onFormChange}
          onClose={onDrawerClose}
          onResultsDrawerOpen={onResultsDrawerOpen}
          text={text}
          attentionText={attentionText}
          uploadData={uploadData}
          responseData={responseData}
          loading={loading}
          getExampleLink={getExampleLink}
          ContentBeforeFileUpload={ContentBeforeFileUpload}
          ContentAfterLinkExample={ContentAfterLinkExample}
        />
      </UploadDrawerWrapper>
      <UploadResultsDrawer
        open={isResultsDrawerOpen}
        onClose={onResultsDrawerClose}
        mode={mode}
        responseData={responseData}
      />
    </Drawer>
  )
}

export default UploadDrawer