import {useContext, useEffect} from "react";
import {uiContext} from "../contexts/ui";
import {BreadcrumbsItem} from "../contexts/ui";

type Dependencies = any[]

const useBreadcrumbs = (items: BreadcrumbsItem[], deps: Dependencies = [], loading: boolean = false) => {
    const cUI = useContext(uiContext)

    useEffect(() => {
        if (!items || loading) return

        cUI.setBreadcrumbs(...items.filter(Boolean) as BreadcrumbsItem[])
        return () => cUI.setBreadcrumbs()
    }, [
        ...deps
    ])
}

export default useBreadcrumbs