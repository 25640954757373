import styled from 'styled-components'
import { Stack, Typography } from '@mui/material'

export const PeriodCellLetter = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`

interface PeriodCellRowProps {
  rowColor?: string
  letterColor?: string
}
export const PeriodCellRow = styled(Stack)<PeriodCellRowProps>`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  
  & .MuiTypography-root {
     color: ${props => props.rowColor && `${props.rowColor} !important`};
   }

  ${PeriodCellLetter} {
    color: ${props => props.letterColor && `${props.letterColor} !important`};
  }
`
