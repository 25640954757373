import { DateForCell, GraphCellVariant } from '../GraphCell.types'
import { WorkStatus } from '../../../../../../../types/works'
import { parseDateForCell } from '../../../WorksTable.utils'
import { LegendColors } from '../../../../../../../types/global'
import { compareAsc, differenceInCalendarDays } from 'date-fns'
import { WorkIsExpired } from '../../../../../../../api/works'

// Color
interface GetCellColorForMonthData {
  status?: WorkStatus;
  isExpired: WorkIsExpired;
  datePlanEnd: DateForCell;
  datePlanStart: DateForCell;
  dateFactStart: DateForCell | null;
  year: number;
  month: number;
}

export const getCellColorForMonth = (data: GetCellColorForMonthData): LegendColors => {
  const { year, month, datePlanStart, datePlanEnd, dateFactStart, status, isExpired } = data
  const { start: isExpiredStart, end: isExpiredEnd } = isExpired

  const { year: planStartYear, month: planStartMonth, day: planStartDay } = datePlanStart
  const { year: planEndYear, month: planEndMonth, day: planEndDay } = datePlanEnd
  const { year: factStartYear, month: factStartMonth, day: factStartDay } = dateFactStart || {}

  const cellDate = new Date(year, month - 1)
  const planStartDate = new Date(planStartYear, planStartMonth - 1)
  const planEndDate = new Date(planEndYear, planEndMonth - 1)
  const factStartDate = (factStartYear && factStartMonth) ? new Date(factStartYear, factStartMonth - 1) : null

  const isStartYear = factStartYear ? year === factStartYear : year === planStartYear
  const isStartMonth = isStartYear && (factStartMonth ? month === factStartMonth : month === planStartMonth)

  const comparedEndDate = compareAsc(planEndDate, cellDate)
  const comparedStartDate = compareAsc(
    factStartDate || planStartDate,
    cellDate,
  )

  const planStartDateWithDay = new Date(planStartYear, planStartMonth - 1, planStartDay)
  const factStartDateWithDay = (factStartYear && factStartMonth && factStartDay) ? new Date(factStartYear, factStartMonth - 1, factStartDay) : null

  const isMoreThanHalfExpired = factStartYear === planStartYear && factStartMonth === planStartMonth
    ? factStartDateWithDay && (differenceInCalendarDays(factStartDateWithDay, planStartDateWithDay) >= 15) // 15 - half days of month
    : factStartDay && (factStartDay >= 15)

  if (
    [
      isExpiredStart && comparedStartDate === 1,
      isExpiredEnd && comparedEndDate === -1,
      (status === 'В работе' || status === 'Выполнено') && isStartMonth && isMoreThanHalfExpired,
      // status === 'Не в работе' && compareAsc(cellDate, planStartDate) !== -1,
    ].includes(true)
  ) {
    return 'red'
  }

  if (status === 'Выполнено' && comparedEndDate !== -1) return 'lightgreen'
  return 'blue'
}


// cellColorOpacity
const localDate = parseDateForCell(new Date())
const {
  year: localYear,
  month: localMonth,
} = localDate

interface GetCellColorOpacityForMonthsData {
  status?: WorkStatus;
  isExpired: WorkIsExpired;
  year: number;
  month: number;
}

export const getCellColorOpacityForMonths = (data: GetCellColorOpacityForMonthsData): number => {
  const { status, isExpired, year, month } = data
  const { start: isExpiredStart, end: isExpiredEnd } = isExpired
  if (status !== 'Не в работе' || isExpiredEnd) return 1
  if (
    (year < localYear)
    || (year === localYear && month <= localMonth)
  ) {
    return 0.2
  }

  return 1
}


// dashed
interface GetCellDashedForMonthsData {
  status?: WorkStatus;
  year: number;
  month: number;
}

export const getCellDashedForMonths = (data: GetCellDashedForMonthsData): boolean => {
  const { status, year, month } = data

  if (status === 'Выполнено') return false
  if (
    (year < localYear)
    || (year === localYear && month <= localMonth)
  ) {
    return false
  }

  return true
}

// Variant
interface GetVariantForMonthsData {
  datePlanStart: DateForCell
  datePlanEnd: DateForCell
  dateFactStart: DateForCell | null
  dateFactEnd: DateForCell | null
  status?: WorkStatus;
  year: number;
  month: number;
  isExpired: WorkIsExpired;
}

export const getVariantForMonths = (data: GetVariantForMonthsData): GraphCellVariant => {
  const { isExpired, status, datePlanStart, datePlanEnd, dateFactStart, dateFactEnd, year, month } = data
  const { start: isExpiredStart, end: isExpiredEnd } = isExpired

  const { year: planStartYear, month: planStartMonth } = datePlanStart
  const { year: planEndYear, month: planEndMonth } = datePlanEnd
  const { year: factStartYear, month: factStartMonth } = dateFactStart || {}
  const { year: factEndYear, month: factEndMonth } = dateFactEnd
  || (isExpiredEnd && (status === 'Не в работе' || status === 'В работе')
      ? localDate
      : {}
  )

  const cellDate = new Date(year, month - 1)
  const planStartDate = new Date(planStartYear, planStartMonth - 1)
  const planEndDate = new Date(planEndYear, planEndMonth - 1)
  const factStartDate = (factStartYear && factStartMonth) ? new Date(factStartYear, factStartMonth - 1) : null
  const factEndDate = (factEndYear && factEndMonth) ? new Date(factEndYear, factEndMonth - 1) : null

  const isStartYear = (factStartYear && isExpiredStart) ? year === factStartYear : year === planStartYear
  const isEndYear = year === planEndYear
  const isDoneYear = year === factEndYear

  const isStartMonth = isStartYear && ((factStartMonth && isExpiredStart) ? month === factStartMonth : month === planStartMonth)
  const isEndMonth = isEndYear && month === planEndMonth
  const isDoneMonth = isDoneYear && month === factEndMonth

  if (factStartDate) {
    if (!isExpiredStart) {
      // cellDate not before factStart AND cellDate before planStart
      if (compareAsc(cellDate, factStartDate) !== -1 && compareAsc(cellDate, planStartDate) === -1) {
        return 'own'
      }
      // cellDate not before planStart AND cellDate before factStart
    } else if (compareAsc(cellDate, planStartDate) !== -1 && compareAsc(cellDate, factStartDate) === -1) {
      return 'own'
    }
  }

  if (factEndDate) {
    if (!isExpiredEnd) {
      // cellDate after factEnd AND cellDate not after planEnd
      if (compareAsc(cellDate, factEndDate) === 1 && compareAsc(cellDate, planEndDate) !== 1) {
        return 'own'
      }
      // cellDate after planEnd AND cellDate not after factEnd
    } else if (compareAsc(cellDate, planEndDate) === 1 && compareAsc(cellDate, factEndDate) !== 1) {
      return 'own'
    }
  }

  // end or done in start month
  if ((isStartMonth && isEndMonth) || (isStartMonth && isDoneMonth)) {
    return 'own'
  }

  if (compareAsc(cellDate, planStartDate) === -1 || compareAsc(cellDate, planEndDate) === 1) {
    return 'empty'
  }

  if (isStartMonth) {
    return 'start'
  }

  if (isEndMonth || isDoneMonth) {
    return 'end'
  }

  return 'body'
}

