import {
  PdBaseResponse,
  PpdBaseResponse,
  useDeletePdMutation,
  useDeletePpdMutation
} from '../../../../../../api/pdPhase'
import { IiBaseResponse, useDeleteIiMutation } from '../../../../../../api/iiPhase'
import { IrdBaseResponse, useDeleteIrdMutation } from '../../../../../../api/irdPhase'
import { UseDeleteItemProps, UseDeleteItemResponse } from './useDeleteItem.types'
import { useCallback, useEffect, useState } from 'react'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { RdpBaseResponse, useDeleteRdpMutation } from '../../../../../../api/rdPhase/rdp'

export const useDeleteItem = ({
  variant,
  projectId,
  id,
  open,
  onClose,
}: UseDeleteItemProps): UseDeleteItemResponse => {
  const { enqueueSnackbar } = useSnackbar()
  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false)

  useEffect(() => {
    if (!open) {
      setIsDeleteClicked(false)
    }
  }, [open])

  // Rdp
  const [deleteRdp, deleteRdpResponse] = useDeleteRdpMutation()

  useMutationHandlers(
    deleteRdpResponse,
    (data: RdpBaseResponse) => {
      if (data.success) {
        onClose(false, true)
      } else {
        enqueueSnackbar(`Не удалось удалить раздел РД. ${data.description}`, { variant: 'error' })
        setIsDeleteClicked(false)
      }
    }
  )


  // Pd
  const [deletePd, deletePdResponse] = useDeletePdMutation()
  const [deletePpd, deletePpdResponse] = useDeletePpdMutation()

  useMutationHandlers(
    deletePdResponse,
    (data: PdBaseResponse) => {
      if (data.success) {
        onClose(false, true)
      } else {
        enqueueSnackbar(`Не удалось удалить раздел ПД. ${data.description}`, { variant: 'error' })
        setIsDeleteClicked(false)
      }
    }
  )

  useMutationHandlers(
    deletePpdResponse,
    (data: PpdBaseResponse) => {
      if (data.success) {
        onClose(false, true)
      } else {
        enqueueSnackbar(`Не удалось удалить раздел ПД. ${data.description}`, { variant: 'error' })
        setIsDeleteClicked(false)
      }
    }
  )


  // Ii
  const [deleteIi, deleteIiResponse] = useDeleteIiMutation()

  useMutationHandlers(
    deleteIiResponse,
    (data: IiBaseResponse) => {
      if (data.success) {
        onClose(false, true)
      } else {
        enqueueSnackbar(`Не удалось удалить раздел изысканий. ${data.description}`, { variant: 'error' })
        setIsDeleteClicked(false)
      }
    }
  )


  // Ird
  const [deleteIrd, deleteIrdResponse] = useDeleteIrdMutation()

  useMutationHandlers(
    deleteIrdResponse,
    (data: IrdBaseResponse) => {
      if (data.success) {
        onClose(false, true)
      } else {
        enqueueSnackbar(`Не удалось удалить раздел ИРД. ${data.description}`, { variant: 'error' })
        setIsDeleteClicked(false)
      }
    }
  )


  // Common
  const onDeleteButtonClick = useCallback(() => {
    setIsDeleteClicked(true)
  }, [])

  const onDeleteSubmit = useCallback(() => {
    switch (variant) {
      case 'rdp':
        deleteRdp({ id: projectId!, rdpId: id! })
        return
      case 'pd':
        deletePd({ id: id! })
        return
      case 'ppd':
        deletePpd({ id: projectId!, ppdId: id! })
        return
      case 'ii':
        deleteIi({ id: projectId!, iiId: id! })
        return
      case 'ird':
        deleteIrd({ id: projectId!, irdId: id! })
        return
    }
  }, [id, projectId])

  const onDeleteCancel = useCallback(() => {
    setIsDeleteClicked(false)
  }, [])


  return {
    onDeleteButtonClick,
    onDeleteSubmit,
    onDeleteCancel,
    isDeleteClicked,
  }
}
