import React, { MouseEvent, useState } from 'react'
import { CardContent, Stack } from '@mui/material'
import CardTitle from './CardTitle'
import CardInfo from './CardInfo'
import CardStatistics from './CardStatistics'
import { CardWrapper } from './styles'
import { HomeCardProps } from './HomeCard.types'
import { useGetStatistics } from './hooks/useGetStatistics'
import { useGetCardHandlers } from '../hooks/useGetCardHandlers'
import { ProjectPhase } from '../../../types/project'

const HomeCard: React.FC<HomeCardProps> = ({ projectWithStatistics }) => {
  const {
    project,
    statistic,
    statisticPd,
    statisticIi,
    statisticIrd,
    pdAccess,
    rdAccess,
  } = projectWithStatistics
  const { id, shortName, shifrName, phase, expertise } = project

  const [selectedPhase, setSelectedPhase] = useState<ProjectPhase>(phase)

  const onPhaseChange = (phase: ProjectPhase) => (e: MouseEvent) => {
    e.stopPropagation()
    setSelectedPhase(phase)
  }

  const { statisticByPhase } = useGetStatistics({
    phase: selectedPhase,
    statistic,
    statisticPd,
    statisticIi,
    statisticIrd,
  })

  const { onCardClick, onCardObjectsClick, onCardPdsClick } = useGetCardHandlers()

  return (
    <CardWrapper onClick={() => onCardClick(id, phase)} sx={{ boxShadow: 0 }}>
      <CardContent>
        <Stack width='100%' spacing={2.375}>
          <CardTitle title={shifrName} subtitle={shortName} />
          <CardInfo
            projectWithStatistics={projectWithStatistics}
            onCardObjectsClick={onCardObjectsClick}
            onCardPdsClick={onCardPdsClick}
            rdAccess={rdAccess}
            pdAccess={pdAccess}
          />
          {/* @ts-ignore*/}
          <CardStatistics
            data={statisticByPhase}
            projectId={id}
            phase={selectedPhase}
            onPhaseChange={onPhaseChange}
            expertise={expertise}
          />
        </Stack>
      </CardContent>
    </CardWrapper>
  )
}

export default HomeCard