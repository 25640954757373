import { Box, Button, CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import { RemarkFormConfirmTrigger, RemarkFormProps, RemarkFormValues } from "./RemarkForm.types"
import { CircleInfoText, DocumentText, FieldsGroup, RemarFormWrapper, RemarkAutocomplete, RemarkFormHeader, RemarkPageInput, RemarkTextField, StyledButton, StyledSearchIcon, TextSection, TitleSection } from "./RemarkForm.styles"
import { useAppDispatch, useTypedSelector } from "@store/store"
import { isAnnotationModeSelector, markCountSelector, remarkAnnotationsSelector, remarkFormDataSelector, remarkFormModeSelector } from "@store/slices/remarks/selectors/remarks.selectors"
import { Form, FormikProvider } from "formik"
import { useForm } from "@hooks/useForm"
import { useParams } from "react-router-dom"
import {
  useCreateRemarkMutation,
  useEditRemarkMutation,
  useGetProjectTomsQuery,
  useGetRemarkTypesListQuery,
  useGetRemarkOnIdQuery
} from "@api/remarks"
import { validationSchema } from "./RemarkForm.utils"
import { ChangeEvent, useCallback, useEffect, useState, FocusEvent, useRef } from "react"
import { useSnackbar } from "notistack"
import { getAnnotations, selectAnnotationId, setAllowScrolling, setIsAnnotationMode, setRemarkAnnotations, setRemarkFormData, setRemarkFormMode, setRemarkPage, setSelectedRemarkId } from "@store/slices/remarks/remarks"
import { reduceFileName } from "@utils/reduceFileName"
import { MAX_FILENAME_LENGTH } from "../Remark/Remark.config"
import { RemarkFormData } from "@store/slices/remarks/remarks.types"
import useConfirmDialog, { UseExitConfirmProps } from "@hooks/useConfirmDialog"
import { FileUploader } from "../FileUploader/FileUploader"
import { ScrollableContainer } from "@styles/global/ScrollableContainer"

export const RemarkForm = ({ tomId, type, closeRemarkForm }: RemarkFormProps) => {

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const dispatch = useAppDispatch()
  const formData = useTypedSelector(remarkFormDataSelector)
  const remarkFormMode = useTypedSelector(remarkFormModeSelector)
  const { enqueueSnackbar } = useSnackbar()
  const [openReasonField, setOpenReasonField] = useState<boolean>(false)
  const [createRemark, createRemarkResponse] = useCreateRemarkMutation()
  const [editRemark, editRemarkResponse] = useEditRemarkMutation()
  const [confirmTrigger, setConfirmTrigger] = useState<RemarkFormConfirmTrigger>('closeForm')
  const [pageInputValue, setPageInputValue] = useState<string | number>(() => formData.page ? formData.page : '')
  const [inputKey, setInputKey] = useState(0)
  const isAnnotationMode = useTypedSelector(isAnnotationModeSelector)
  const remarkAnnotations = useTypedSelector(remarkAnnotationsSelector)
  const markCount = useTypedSelector(markCountSelector)

  const { data: remarkTypes } = useGetRemarkTypesListQuery(
    { projectId, tomId: tomId!, type },
    { skip: !tomId },
  )
  const { data: projectToms } = useGetProjectTomsQuery(
    { projectId, type },
    { skip: !!tomId && remarkFormMode === 'edit' },
  )
  const { data: remark } = useGetRemarkOnIdQuery(
    { projectId, remarkId: formData.remarkId! },
    { skip: !formData.remarkId || remarkFormMode === 'create', refetchOnMountOrArgChange: true },
  )
  const receivedFiles = remark?.messages[0].attachments
  const receivedMarks = remark?.messages[0].marks

  const initialValues: RemarkFormValues = {
    page: formData.page,
    type: formData.type || '',
    comment: formData.comment || '',
    reason: formData.reason || '',
    fileList: formData.fileList,
    uploadedFiles: receivedFiles,
    tom: null,
  }

  const { formik } = useForm({
    validationSchema: validationSchema(formData.totalPages, !!tomId || remarkFormMode === 'edit'),
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      isAnnotationMode && !!page ? dispatch(getAnnotations(true)) : onSubmit(values)
    },
  })
  useEffect(() => {
    if (remarkAnnotations !== null && isAnnotationMode) {
      onSubmit(formik.values)
    }
  }, [formik.values, isAnnotationMode, remarkAnnotations])

  const onSubmit = (values: RemarkFormValues) => {
    if (remarkFormMode === 'create') {
      createRemark({
        projectId,
        tomId: !tomId ? (values.tom! as number) : tomId,
        type,
        mark: isAnnotationMode && !!page ? { marks: remarkAnnotations! } : { marks: [] },
        remark: {
          change: formData.change,
          comment: values.comment,
          page: values.page,
          reason: values.reason ? (values.reason.trim().length > 0 ? values.reason.trim() : null) : null,
          type: values.type,
        },
        file: values.fileList,
      })
    }
    if (remarkFormMode === 'edit' && formData.remarkId) {
      const existingFilesIds = values.uploadedFiles?.map((file) => file.id)
      editRemark({
        projectId,
        remarkId: formData.remarkId,
        mark: isAnnotationMode && !!page ? { marks: remarkAnnotations! } : { marks: [] },
        remark: {
          change: formData.change || remark?.remark.change,
          comment: values.comment,
          page: values.page,
          reason: values.reason ? (values.reason.trim().length > 0 ? values.reason.trim() : null) : null,
          type: values.type,
        },
        file: values.fileList,
        deleteFileId:
          receivedFiles?.filter((file) => !existingFilesIds?.includes(file.id)).map((file) => file.id) || null,
      })
    }
  }

  const {
    values: { fileList, uploadedFiles, page, tom: tomValues },
    isValid,
    setFieldValue,
  } = formik
  const editedFilesLength = uploadedFiles?.length || 0
  const existingFilesLength = fileList?.length || 0

  const checkIsDirty = useCallback(() => {
    return !(
      initialValues.tom === formik.values.tom &&
      (initialValues.page
        ? initialValues.page === formik.values.page
        : !!initialValues.page === !!formik.values.page) &&
      (initialValues.fileList
        ? initialValues.fileList.length === formik.values.fileList?.length
        : existingFilesLength === 0) &&
      initialValues.type === formik.values.type &&
      initialValues.comment === formik.values.comment &&
      initialValues.reason === formik.values.reason &&
      (remarkFormMode === 'edit' ? receivedFiles?.length === formik.values.uploadedFiles?.length : true)
    ) || (isAnnotationMode && (remarkFormMode === 'edit' ? receivedMarks?.length !== markCount : !!markCount))
  }, [formik.values, existingFilesLength, markCount])

  const handleCloseReasonField = () => {
    setOpenReasonField(false)
    formik.setFieldValue('reason', '')
  }

  const removeFile = (fileToRemove: File) => {
    if (fileList) {
      const updatedFiles = Array.from(fileList).filter((file) => file !== fileToRemove)
      const dataTransfer = new DataTransfer()
      updatedFiles.forEach((file) => dataTransfer.items.add(file))
      const updatedFileList = dataTransfer.files
      formik.setFieldValue('fileList', updatedFileList)
    }
  }

  const removeExistingFiles = (id: number) => {
    const editedFiles = uploadedFiles?.filter((file) => file.id !== id)
    formik.setFieldValue('uploadedFiles', editedFiles)
  }

  const onCloseRemarkForm = () => {
    formik.resetForm()
    dispatch(setRemarkFormData({ remarkFormData: {} as RemarkFormData }))
    dispatch(setRemarkFormMode({ remarkFormMode: 'create' }))
    dispatch(setIsAnnotationMode(false))
    dispatch(setRemarkPage(null))
    dispatch(getAnnotations(false))
    dispatch(setRemarkAnnotations(null))
    closeRemarkForm()
  }

  useEffect(() => {
    if (isAnnotationMode) {
      const pageNumber = Number(page)
      if (pageNumber !== 0 && !isNaN(pageNumber)) {
        dispatch(setRemarkPage(pageNumber))
      } else {
        dispatch(setRemarkPage(null))
      }
    }
  }, [page])

  useEffect(() => {
    if (createRemarkResponse.isSuccess) {
      enqueueSnackbar('Замечание успешно добавлено.', { variant: 'success' })
      onCloseRemarkForm()
      dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: true } }))
      dispatch(setSelectedRemarkId(createRemarkResponse.data.remark.id))
    }
    if (createRemarkResponse.isError) {
      enqueueSnackbar('Не удалось создать замечание, попробуйте еще раз.', { variant: 'error' })
    }
  }, [createRemarkResponse])

  useEffect(() => {
    if (editRemarkResponse.isSuccess) {
      enqueueSnackbar('Замечание успешно изменено.', { variant: 'success' })
      const id = editRemarkResponse.data.messages[0].marks?.[0]?.id
      isAnnotationMode && id && dispatch(selectAnnotationId(id))
      onCloseRemarkForm()
      dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: true, mode: 'edit' } }))
      //dispatch(setSelectedRemarkId(createRemarkResponse.data)) TODO В ответ remark.id
    }
    if (editRemarkResponse.isError) {
      enqueueSnackbar('Не удалось изменить замечание, попробуйте еще раз.', { variant: 'error' })
    }
  }, [editRemarkResponse])

  useEffect(() => {
    if (formData.reason && remarkFormMode === 'edit') {
      setOpenReasonField(true)
    }
  }, [formData.reason, remarkFormMode])

  useEffect(() => {
    if (openReasonField && formData.reason && remarkFormMode === 'edit') {
      formik.setFieldValue('reason', formData.reason)
    }
  }, [formData.reason, openReasonField, remarkFormMode])

  const handleCloseRemarkForm = (dirty: boolean) => {
    if (dirty) {
      setConfirmTrigger('closeForm')
      openConfirm()
    } else {
      onCloseRemarkForm()
    }
  }

  const onConfirmCloseForm = (confirm: boolean) => {
    if (confirm) {
      onCloseRemarkForm()
    }
  }

  const onConfirmChangeList = (confirm: boolean, value: string) => {
    if (confirm) {
      setFieldValue('page', value)
    } else {
      setPageInputValue(page || '')
    }
  }

  const handleBlurPageInput = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (page === '' || isNaN(Number(page))) {
      setFieldValue('page', pageInputValue)
    } else if (String(pageInputValue) !== String(page)) {
      setConfirmTrigger('changePage')
      openConfirm(pageInputValue)
      setInputKey(prev => prev + 1)
    }
  }

  const handleChangePageInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (isAnnotationMode && markCount) {
      setPageInputValue(e.target.value)
    } else {
      setPageInputValue(e.target.value)
      setFieldValue('page', e.target.value)
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps =
    confirmTrigger === 'closeForm' ? {
      handleConfirm: onConfirmCloseForm,
      denyButtonText: 'Отменить',
    }
      : {
        handleConfirm: onConfirmChangeList,
        title: 'Подтвердить изменение листа тома?',
        body: 'Все добавленные пометки будут безвозвратно утеряны.'
      }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  return (
    <RemarFormWrapper>
      <RemarkFormHeader variant='h2'>
        {remarkFormMode === 'create' ? 'Добавить замечание' : 'Редактировать замечание'}
      </RemarkFormHeader>
      <FormikProvider value={formik}>
        <Stack component={Form} flex={1} overflow='hidden'>
          <ScrollableContainer spacing={1.5} pt={2.5} px={2.5} textAlign='start'>
            <Stack spacing={1}>
              <Typography variant='body2'>{!tomId && remarkFormMode === 'create' ? 'Том:' : 'О томе:'}</Typography>
              {!tomId && remarkFormMode === 'create' ? (
                <RemarkAutocomplete
                  fieldName='tom'
                  placeholder='Выберите из списка'
                  nullable
                  customRenderOption={(props: any, option: any) => (
                    <Box component='li' {...props} key={option.key}>
                      <Typography variant='body2'>{option.label}</Typography>
                    </Box>
                  )}
                  data={
                    projectToms?.data.map((tom) => ({
                      value: tom.id,
                      label: tom.title,
                      key: tom.id,
                    })) || []
                  }
                />
              ) : (
                <DocumentText>
                  {formData.change !== null ? `Изменение ${formData.change}` : `Версия ${formData.version}`}
                </DocumentText>
              )}
            </Stack>

            <Stack spacing={1}>
              <Typography variant='body2'>Лист тома:</Typography>
              <RemarkPageInput
                variant='filled'
                placeholder='Страница документа'
                fullWidth
                InputProps={{ disableUnderline: true }}
                error={!!formik.errors.page}
                value={isAnnotationMode && markCount ? pageInputValue : page}
                onBlur={isAnnotationMode && markCount ? handleBlurPageInput : undefined}
                onChange={handleChangePageInput}
              />
            </Stack>

            <Stack spacing={1}>
              <Typography variant='body2'>Тип замечания:</Typography>
              <RemarkAutocomplete
                key={inputKey}
                fieldName='type'
                placeholder='Выберите из списка'
                freeSolo
                customFreeSoloMessage={undefined}
                customRenderOption={(props, option) => (
                  <Box component='li' {...props} key={option.key}>
                    <Stack direction='row' alignItems='center' spacing={2}>
                      {option.key ? (
                        <Typography variant='body2'>{option.label}</Typography>
                      ) : (
                        <Stack direction='row' alignItems='center' spacing={1}>
                          <StyledSearchIcon fontSize='small' />
                          <Typography variant='h3'>
                            Не найдено! Для добавления типа, введите название в поле и нажмите на это сообщение для
                            сохранения
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Box>
                )}
                data={
                  remarkTypes?.data?.map((type) => ({
                    value: type,
                    label: type,
                    key: type,
                  })) || []
                }
              />
            </Stack>
            <TextSection spacing={1.5}>
              <FieldsGroup direction='row'>
                <TitleSection variant='body2'>Замечание</TitleSection>
              </FieldsGroup>
              <RemarkTextField placeholder='Текст замечания' version='project' multiline name='comment' />
            </TextSection>

            {openReasonField && (
              <TextSection spacing={1.5}>
                <FieldsGroup direction='row'>
                  <TitleSection variant='body2'>Основание</TitleSection>
                  <IconButton size='medium' onClick={handleCloseReasonField}>
                    <CancelIcon fontSize='medium' color='disabled' />
                  </IconButton>
                </FieldsGroup>
                <RemarkTextField placeholder='Текст основания' version='project' multiline name='reason' />
              </TextSection>
            )}

            {!openReasonField && (
              <Box mt={1}>
                <StyledButton fullWidth onClick={() => setOpenReasonField(true)}>
                  <AddIcon fontSize='medium' />
                  Добавить основание
                </StyledButton>
              </Box>
            )}

            {markCount ?
              <TextSection spacing={1.5}>
                <FieldsGroup direction='row'>
                  <TitleSection variant='body2'>Пометки</TitleSection>
                  <CircleInfoText mb='5px'>
                    <Typography>{markCount}</Typography>
                  </CircleInfoText>
                </FieldsGroup>
              </TextSection>
              : null}

            {remarkFormMode === 'create' && fileList && fileList.length > 0 && (
              <TextSection spacing={1.5}>
                <FieldsGroup direction='row'>
                  <TitleSection variant='body2'>Вложения</TitleSection>
                </FieldsGroup>
                {Array.from(fileList).map((file) => (
                  <Stack key={file.name} spacing={1}>
                    <Stack direction='row' alignItems='center' spacing={0.5}>
                      <IconButton size='small' color='error' onClick={() => removeFile(file)}>
                        <DeleteIcon fontSize='small' />
                      </IconButton>
                      <Tooltip title={<>{file.name}</>} disableHoverListener={file.name.length < MAX_FILENAME_LENGTH}>
                        <DocumentText>{reduceFileName(file.name, MAX_FILENAME_LENGTH)}</DocumentText>
                      </Tooltip>
                    </Stack>
                  </Stack>
                ))}
              </TextSection>
            )}
            {remarkFormMode === 'edit' && (editedFilesLength > 0 || existingFilesLength > 0) && (
              <TextSection spacing={1.5}>
                <FieldsGroup direction='row'>
                  <TitleSection variant='body2'>Вложения</TitleSection>
                </FieldsGroup>
                {fileList &&
                  Array.from(fileList).map((file) => (
                    <Stack key={file.name} spacing={1}>
                      <Stack direction='row' alignItems='center' spacing={0.5}>
                        <IconButton size='small' color='error' onClick={() => removeFile(file)}>
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                        <Tooltip
                          title={<>{file.name}</>}
                          disableHoverListener={file.name.length < MAX_FILENAME_LENGTH}
                        >
                          <DocumentText>{reduceFileName(file.name, MAX_FILENAME_LENGTH)}</DocumentText>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  ))}
                {editedFilesLength > 0 &&
                  uploadedFiles!.map((file) => (
                    <Stack direction='row' alignItems='center' spacing={0.5}>
                      <IconButton size='small' color='error' onClick={() => removeExistingFiles(file.id)}>
                        <DeleteIcon fontSize='small' />
                      </IconButton>
                      <Tooltip title={<>{file.name}</>} disableHoverListener={file.name.length < MAX_FILENAME_LENGTH}>
                        <DocumentText>{reduceFileName(file.name, MAX_FILENAME_LENGTH)}</DocumentText>
                      </Tooltip>
                    </Stack>
                  ))}
              </TextSection>
            )}

            {editedFilesLength + existingFilesLength < 5 && (
              <Box mt={1}>
                <Tooltip title={<>Не более 5 файлов до 100 МБ каждый</>}>
                  <StyledButton fullWidth role={undefined} tabIndex={-1}>
                    <AttachFileIcon fontSize='medium' />
                    <FileUploader />
                    Прикрепить вложение
                  </StyledButton>
                </Tooltip>
              </Box>
            )}
          </ScrollableContainer>
          <Stack justifyContent='flex-end'>
            <Stack direction='row' spacing={2} p={2.5}>
              <Box position='relative' width='100%'>
                <Button
                  fullWidth
                  color='success'
                  type='submit'
                  disabled={!isValid || createRemarkResponse.isLoading || editRemarkResponse.isLoading}
                >
                  {remarkFormMode === 'create' ? 'Добавить' : 'Сохранить'}
                </Button>
                {(createRemarkResponse.isLoading || editRemarkResponse.isLoading) && (
                  <CircularProgress
                    size={24}
                    color='success'
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
              <Button sx={{ padding: 0 }} fullWidth onClick={() => handleCloseRemarkForm(checkIsDirty())}>
                Отменить
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormikProvider>
      <ConfirmDialog />
    </RemarFormWrapper>
  )
}