import { Avatar, Box, Stack, Typography } from '@mui/material';
import { ColDef, ColumnResizedEvent, GridReadyEvent, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AgreementPocketTomStatus } from '../../../../api/agreementPocket/types';
import { OverflowText } from '../../../../components/OverflowText/OverflowText';
import Progress from '../../../../components/Progress';
import { StyledAgGridWrapper } from "../../../../styles/global/StyledAgGridWrapper";
import { pocketStatusColorByValue } from '../PocketViewTomCard/PocketViewTomCard.types';
import { ElipsisText, PocketViewAgGridStatus, StatusBox } from './AgGridPocketToms.styles';
import { AgGridPocketTomsProps, PocketTomsRowData, ResponsibleParams, StageParams, TomInfoParams } from './AgGridPocketToms.types';
import { useAppDispatch } from '../../../../store/store';
import { setSelectedProjectPhase } from '../../../../store/slices/documentsPages/projectInfo';
import { projectPhaseByAgreementPhase } from '../../../../api/projectAgreement/types';

const DEFAULT_ROW_HEIGHT = 83;

const TomInfoCell = (params: ICellRendererParams) => {
  const { title, description }: TomInfoParams = params.value
  return (
    <Stack alignItems='flex-start' px={2} py={1}>
      <ElipsisText fontWeight={500}>{title}</ElipsisText>
      <OverflowText mode='table' text={description} defaultHeight={42} />
    </Stack>
  )
}

const StageCell = (params: ICellRendererParams) => {
  const { completed, isAwaiting, isExpired, title, transferDate }: StageParams = params.value
  return (
    isAwaiting ?
      <Stack maxWidth={250} margin='auto'>
        <StatusBox isExpired={false} maxWidth={250}>
          <Typography fontSize={12}>{transferDate}</Typography>
        </StatusBox>
      </Stack>
      : (
        <Stack spacing={0.5} textAlign='start' px={2} py={1}>
          {completed ?
            <Typography variant='subtitle2' color='#9AA2B0'>{title}</Typography>
            : <ElipsisText fontWeight={500}>{title}</ElipsisText>
          }
          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
            <Typography fontSize={14}>{completed ? 'Дата согласования:' : 'Срок прохождения:'}</Typography>
            <StatusBox isExpired={isExpired}>
              <Typography fontSize={12}>{transferDate}</Typography>
            </StatusBox>
          </Stack>
        </Stack>
      )
  )
}

const ResponsibleCell = (params: ICellRendererParams) => {
  const { company, name, avatar }: ResponsibleParams = params.value
  return (
    <Stack direction='row' spacing={1.5} px={2} alignItems='center'>
      <Avatar src={avatar || undefined} sx={{ height: 32, width: 32 }} />
      <Box textAlign='start' width='calc(100% - 44px)'>
        <ElipsisText variant='body1' fontSize={14}>{company}</ElipsisText>
        <Typography variant='body1' fontSize={14}>{name}</Typography>
      </Box>
    </Stack>
  )
}

export const AgGridPocketToms = ({ pocketToms, isAwaiting }: AgGridPocketTomsProps) => {

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { projectId: projectIdString, pocketId: pocketIdString } = useParams()
  const projectId = Number(projectIdString)
  const pocketId = Number(pocketIdString)
  const flexCell = 'ag-cell_flex_column ag-cell_justify-content_center'
  const isSavedColumnState = !!localStorage.getItem('pocketTomsColumnState')

  const columnDef = useMemo((): ColDef[] => [
    {
      field: 'tomInfo',
      headerName: 'О томе',
      flex: !isSavedColumnState ? 2 : undefined,
      minWidth: 187,
      cellRenderer: TomInfoCell,
      cellClass: [flexCell, 'ag-cell_overflow_visible'],
    },
    {
      field: 'stage',
      headerName: isAwaiting ? 'Дата накладной' : 'Этап согласования',
      flex: !isSavedColumnState ? 1 : undefined,
      minWidth: 257,
      cellClass: [flexCell],
      cellRenderer: StageCell,
    },
    {
      field: 'responsible',
      headerName: isAwaiting ? 'Ответственный за подтверждение' : 'Ответственный за этап',
      flex: !isSavedColumnState ? 1 : undefined,
      minWidth: 191,
      cellRenderer: ResponsibleCell,
      cellClass: [flexCell],
    },
    {
      field: 'status',
      headerName: 'Статус процедуры согласования',
      flex: !isSavedColumnState ? 1 : undefined,
      minWidth: 227,
      cellRenderer: (params: ICellRendererParams) => {
        const status: AgreementPocketTomStatus = params.value
        return (
          <PocketViewAgGridStatus $bg={pocketStatusColorByValue[status]}>
            {status}
          </PocketViewAgGridStatus>
        )
      },
      cellClass: [flexCell],
    },
  ], [isAwaiting])

  const rowData = useMemo((): PocketTomsRowData[] => {
    if (!pocketToms) return []
    return pocketToms.map(({ tom, stage, transferDate, isExpired, person, status }) => {
      return {
        tomInfo: {
          id: tom.id,
          title: tom.title,
          description: tom.description,
          type: tom.type,
        },
        stage: {
          title: stage ? stage.title : 'Процедура завершена',
          transferDate,
          completed: status === 'ПРОЙДЕНО',
          isAwaiting: status === 'ОЖИДАЕТ ПОДТВЕРЖДЕНИЯ',
          isExpired
        },
        responsible: {
          company: person.company,
          name: person.name,
          avatar: person.avatar,
        },
        status: status,
      }
    })
  }, [pocketToms])

  const gridReadyListener = useCallback((e: GridReadyEvent) => {
    const savedColumnState = localStorage.getItem('pocketTomsColumnState')
    if (savedColumnState) {
      e.columnApi.applyColumnState({
        state: JSON.parse(savedColumnState),
        applyOrder: true
      })
    }
  }, [])

  const columnResizedListener = useCallback((e: ColumnResizedEvent) => {
    const columnState = e.columnApi.getColumnState()
    if (e.finished) {
      localStorage.setItem('pocketTomsColumnState', JSON.stringify(columnState))
    }
  }, [])

  const rowClickedListener = (e: RowClickedEvent<PocketTomsRowData>) => {
    const tomType = e.data?.tomInfo.type
    const tomId = e.data?.tomInfo.id
    if (tomType && tomId) {
      dispatch(setSelectedProjectPhase({ selectedProjectPhase: projectPhaseByAgreementPhase[tomType] }))
      navigate(`/project/${projectId}/tom/${tomId}?menuView=remarks&pocketId=${pocketId}`)
    }
  }

  return (
    <StyledAgGridWrapper>
      <div className='ag-theme-alpine' style={{ marginTop: '16px' }}>
        <AgGridReact
          onGridReady={gridReadyListener}
          onRowClicked={rowClickedListener}
          onColumnResized={columnResizedListener}
          loadingOverlayComponent={Progress}
          columnDefs={columnDef}
          rowData={rowData}
          headerHeight={32}
          detailRowAutoHeight
          rowHeight={DEFAULT_ROW_HEIGHT}
          suppressRowTransform={true}
          suppressDragLeaveHidesColumns={true}
          suppressColumnMoveAnimation={true}
          overlayNoRowsTemplate="Нет данных для отображения"
          defaultColDef={{
            cellDataType: false,
            editable: false,
            sortable: false,
            filter: false,
            resizable: true,
          }} />
      </div>
    </StyledAgGridWrapper>
  )
}