import { IconButton, MenuItem } from "@mui/material";
import styled from "styled-components";

export const StyledIconButton = styled(IconButton)<{$position?: string}>`
  position: ${props => props.$position ? props.$position : 'relative'};
  padding: 4px 6px;
  top: 5px;
  box-sizing: content-box;
  height: 20px;
  //background-color: ${props => props.theme.palette.bg.white};
  background-color: inherit;

  &:hover {
    background-color: ${props => props.theme.palette.bg.lightBlue};
  }
`

export const StyledMenuItem = styled(MenuItem)`
  padding: 0;
  border: 1px solid ${props => props.theme.palette.primaryBorderLight};
  border-radius: 100px;
  padding: 10px 16px;
  height: 40px;
  font-size: 16px;
  color: ${props => props.theme.palette.primary.main};
  width: 100%;
`