import { ProjectPhase } from '../../types/project'
import { FilterData } from '../../store/slices/documentsPages/filter'

export type DocDrawerType =
  | 'create'
  | 'update'
  | 'upload'
  | 'uploadFiles'
  | 'version'
  | 'docChange'
  | 'notifications'
  | 'exportArchive'
  | 'attachments'
  | 'exportAttachments'
  | 'formPocket'
  | 'internalAgreement'
  | 'docAccess'
  | 'remarks'
  | 'remarksHistory'
  | 'manualSumbitRemarks'
  | 'schedules'
  | 'tim'
  | 'timView'
  | 'dashboardSettings'

export type DocViewMenuView = 'info' | 'changes' | 'versions' | 'attachments' | 'remarks'

export type FilterType = 'object' | 'rd' | 'pd' | 'ii' | 'ird' | 'docs' | 'expired' | 'iiType'
export const getFilterFieldName: Record<FilterType, keyof FilterData> = {
  object: 'selectedObjectsIds',
  rd: 'selectedRdsIds',
  pd: 'selectedPdsIds',
  ii: 'selectedIisIds',
  ird: 'selectedIrdsIds',
  docs: 'selectedDocs',
  expired: 'isExpired',
  iiType: 'iiType',
}

export type DocVariant = 'version' | 'change'
export type DocsFiltersValues = 'none' | DocVariant
export type DocsFiltersTitles = 'Без документов' | 'Есть версии' | 'Есть изменения'

export interface DocsFilterItem {
  value: DocsFiltersValues
  title: DocsFiltersTitles
}

export interface CurrentDocStorageData {
  projectPhase: ProjectPhase
  variant: DocVariant
  docId: number
}
