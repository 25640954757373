import { Avatar, Box, Stack, Typography } from '@mui/material'

import { InnermapActionsType } from '../../../../../../../api/internalAgreement/types'
import { ShiftDownContainedIcon, ShiftDownOutlinedIcon } from '../../../../../../../assets/icons/ShiftDownIcon'
import { ShiftUpContainedIcon, ShiftUpOutlinedIcon } from '../../../../../../../assets/icons/ShiftUpIcon'
import Tooltip from '../../../../../../../components/Tooltip'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../../../hooks/useConfirmDialog'
import { docsSelector, tomSelector } from '../../../../../../../store/slices/documentsPages/tom'
import { profileSelector } from '../../../../../../../store/slices/profile'
import { useTypedSelector } from '../../../../../../../store/store'
import { convertDateTime } from '../../../../../../../utils/formatDateAndTimeGMT'
import { useAccess } from '../../../../InternalAgreementSchemes.utils'
import { TableRow } from '../../InternalAgreementTable.styles'
import { MembersMenu } from '../MembersMenu'
import {
  ApprovalIcon,
  AssuranceIcon,
  IconWrapper,
  RemarksIcon,
  SigningIcon,
  StyledButton,
  StyledMoveCell,
  StyledName,
  StyledPosition,
} from './MemberRow.styles'
import { MemberRowProps } from './MemberRow.types'

export const MemberRow = ({
  allowMove,
  isApproved,
  isFirst,
  isLast,
  member,
  isSelectedProcedure,
  editMember,
  deleteMember,
  moveUpMember,
  moveDownMember,
  sendMemberAction,
}: MemberRowProps) => {
  const { isAdmin, isModerator } = useAccess()
  const { approval, assurance, remarks, signing } = member.actionLogs

  const { employeeId } = useTypedSelector(profileSelector)
  const { tomVersions } = useTypedSelector(docsSelector)
  const { tom } = useTypedSelector(tomSelector)

  const isAnnulled = tom?.features.annulment.annulled
  const hasAccess = employeeId === member.person.id || isAdmin || isModerator

  const handleMoveUpClick = () => moveUpMember({ memberId: member.id, direction: 'up' })
  const handleMoveDownClick = () => moveDownMember({ memberId: member.id, direction: 'down' })
  const handleEditMember = () => editMember(member)
  const handleDeleteMember = () => deleteMember(member.id)

  const handleButtonClick = (action: InnermapActionsType) => {
    if (action === 'approval') {
      openConfirm(action)
    } else {
      sendMemberAction(member.id, action)
    }
  }

  const confirmSendMemberAction = (confirm: boolean, action: InnermapActionsType) => {
    if (confirm) {
      sendMemberAction(member.id, action)
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmSendMemberAction,
    title: 'Утвердить документ?',
    body: <>Это завершит процедуру согласования и сделает ее недоступной для редактирования</>,
    denyButtonText: 'Отменить',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  return (
    <TableRow spacing={1} direction='row'>
      {allowMove && (
        <>
          {isFirst && isLast ? (
            <Box minWidth='30px' />
          ) : isFirst ? (
            <StyledMoveCell>
              <ShiftDownContainedIcon fontSize='small' className='icon-hover' onClick={handleMoveDownClick} />
              <ShiftDownOutlinedIcon fontSize='small' className='icon' />
            </StyledMoveCell>
          ) : isLast ? (
            <StyledMoveCell>
              <ShiftUpContainedIcon fontSize='small' className='icon-hover' onClick={handleMoveUpClick} />
              <ShiftUpOutlinedIcon fontSize='small' className='icon' />
            </StyledMoveCell>
          ) : (
            <Stack spacing={0}>
              <StyledMoveCell>
                <ShiftUpContainedIcon fontSize='small' className='icon-hover' onClick={handleMoveUpClick} />
                <ShiftUpOutlinedIcon fontSize='small' className='icon' />
              </StyledMoveCell>
              <StyledMoveCell>
                <ShiftDownContainedIcon fontSize='small' className='icon-hover' onClick={handleMoveDownClick} />
                <ShiftDownOutlinedIcon fontSize='small' className='icon' />
              </StyledMoveCell>
            </Stack>
          )}
        </>
      )}

      <Avatar src={member.person.avatar} sx={{ width: '24px', height: '24px', marginLeft: '8px' }} />
      <StyledName>{member.person.name}</StyledName>
      <StyledPosition>{member.person.position}</StyledPosition>
      <IconWrapper direction='row'>
        {isSelectedProcedure && approval.datetime ? (
          <Tooltip title={<>{approval.person.name}</>}>
            <Typography variant='body2'>
              {approval.datetime ? convertDateTime(approval.datetime, undefined, true) : '-'}
            </Typography>
          </Tooltip>
        ) : isSelectedProcedure && hasAccess && isSelectedProcedure && member.approval && !approval.datetime ? (
          <StyledButton disabled={isAnnulled} onClick={() => handleButtonClick('approval')} color='success'>
            Утвердить
          </StyledButton>
        ) : (
          <ApprovalIcon $approval={member.approval} fontSize='small' />
        )}
      </IconWrapper>
      <IconWrapper direction='row'>
        {isSelectedProcedure && assurance.datetime ? (
          <Tooltip title={<>{assurance.person.name}</>}>
            <Typography variant='body2'>
              {assurance.datetime ? convertDateTime(assurance.datetime, undefined, true) : '-'}
            </Typography>
          </Tooltip>
        ) : isSelectedProcedure && hasAccess && member.assurance && !assurance.datetime ? (
          <StyledButton disabled={isAnnulled} onClick={() => handleButtonClick('assurance')} color='primary'>
            Визировать
          </StyledButton>
        ) : (
          <AssuranceIcon $assurance={member.assurance} fontSize='small' />
        )}
      </IconWrapper>
      <IconWrapper direction='row'>
        {isSelectedProcedure && signing.datetime ? (
          <Tooltip title={<>{signing.person.name}</>}>
            <Typography variant='body2'>
              {signing.datetime ? convertDateTime(signing.datetime, undefined, true) : '-'}
            </Typography>
          </Tooltip>
        ) : isSelectedProcedure && hasAccess && member.signing && !signing.datetime ? (
          <StyledButton disabled={isAnnulled} onClick={() => handleButtonClick('signing')} color='primary'>
            Подписать
          </StyledButton>
        ) : (
          <SigningIcon $signing={member.signing} fontSize='small' />
        )}
      </IconWrapper>
      <IconWrapper direction='row'>
        {isSelectedProcedure && remarks.datetime ? (
          <Tooltip title={<>{remarks.person.name}</>}>
            <Typography variant='body2'>
              {remarks.datetime ? convertDateTime(remarks.datetime, undefined, true) : '-'}
            </Typography>
          </Tooltip>
        ) : isSelectedProcedure && hasAccess && member.remarks && !remarks.datetime && tomVersions.length > 0 ? (
          <StyledButton disabled={isAnnulled} onClick={() => handleButtonClick('remarks')} color='error'>
            Замечание
          </StyledButton>
        ) : (
          <RemarksIcon $remarks={member.remarks} fontSize='small' />
        )}
      </IconWrapper>
      <Box minWidth='30px' />
      {!isAnnulled && (isAdmin || isModerator) && !isApproved && (
        <MembersMenu
          onFirstItemClick={handleEditMember}
          onSecondItemClick={handleDeleteMember}
          firstItemTitle={'Редактировать'}
          secondItemTitle='Удалить участника'
        />
      )}
      <ConfirmDialog />
    </TableRow>
  )
}
