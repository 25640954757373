import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

import {
  TomCmnChangeBaseResponse,
  TomCmnVersionBaseResponse,
  useUploadTomCmnChangeMutation,
  useUploadTomCmnVersionMutation,
} from '@api/cmnTom'
import {
  TomIiChangeBaseResponse,
  TomIiVersionBaseResponse,
  useUpdateTomIiChangeMutation,
  useUploadTomIiChangeMutation,
  useUploadTomIiVersionMutation,
} from '@api/iiPhase'
import {
  TomIrdChangeBaseResponse,
  TomIrdVersionBaseResponse,
  useUpdateTomIrdChangeMutation,
  useUploadTomIrdChangeMutation,
  useUploadTomIrdVersionMutation,
} from '@api/irdPhase'
import {
  TomPdChangeBaseResponse,
  TomPdVersionBaseResponse,
  useUpdateTomPdChangeMutation,
  useUploadTomPdChangeMutation,
  useUploadTomPdVersionMutation,
} from '@api/pdPhase'
import {
  TomRdChangeBaseResponse,
  TomRdVersionBaseResponse,
  useUpdateTomRdChangeMutation,
  useUploadTomRdChangeMutation,
  useUploadTomRdVersionMutation,
} from '@api/rdPhase'

import { useMutationHandlers } from '@hooks/useMutationHandlers'

import { DocAddChange, DocUpdateChange, DocVersionFormData } from '@pages/Docs'

import { onDrawerClose, openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector,
} from '../../../../store/slices/documentsPages/projectInfo'
import { isCmnTomSelector, tomSelector } from '../../../../store/slices/documentsPages/tom'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { ProjectPhase } from '../../../../types/project'
import { UseDocsSubmitResponse } from './useDocsSubmit.types'

export const useDocsSubmit = (): UseDocsSubmitResponse => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)

  const { id } = tom! || {}
  const isDocChange = openedDrawer === 'docChange'

  // Rd
  const [uploadTomRdVersion, { isLoading: isTomRdVersionLoading, ...uploadTomRdVersionResponse }] =
    useUploadTomRdVersionMutation()
  const [uploadTomRdChange, { isLoading: isTomRdChangeLoading, ...uploadTomRdChangeResponse }] =
    useUploadTomRdChangeMutation()
  const [updateTomRdChange, { isLoading: isTomRdChangeUpdating, ...updateTomRdChangeResponse }] =
    useUpdateTomRdChangeMutation()

  const onAddRdVersion = useCallback(
    (values: DocVersionFormData) => {
      const { files } = values

      if (files?.length && files[0] instanceof File) {
        uploadTomRdVersion({ id, file: files[0] })
      }
    },
    [id],
  )

  const onAddRdChange = useCallback(
    (values: DocAddChange) => {
      const { files, change } = values

      if (files?.length && files[0] instanceof File) {
        uploadTomRdChange({ id, file: files[0], change })
      }
    },
    [id],
  )

  const onUpdateRdChange = useCallback(
    (values: DocUpdateChange) => {
      const { changeNum, change } = values

      updateTomRdChange({ id, changeNum, change })
    },
    [id],
  )

  useMutationHandlers(uploadTomRdVersionResponse, (data: TomRdVersionBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  useMutationHandlers(
    uploadTomRdChangeResponse,
    (data: TomRdChangeBaseResponse) => {
      enqueueSnackbar('Изменение успешно добавлено.', { variant: 'success' })
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
    (err) => {
      enqueueSnackbar('Изменение с таким номером уже существует.', { variant: 'error' })
    },
  )

  useMutationHandlers(
    updateTomRdChangeResponse,
    (data: TomRdChangeBaseResponse) => {
      enqueueSnackbar('Изменение успешно изменено.', { variant: 'success' })
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
    (err) => {
      enqueueSnackbar('Изменение с таким номером уже существует.', { variant: 'error' })
    },
  )

  // Pd
  const [uploadTomPdVersion, { isLoading: isTomPdVersionLoading, ...uploadTomPdVersionResponse }] =
    useUploadTomPdVersionMutation()
  const [uploadTomPdChange, { isLoading: isTomPdChangeLoading, ...uploadTomPdChangeResponse }] =
    useUploadTomPdChangeMutation()
  const [updateTomPdChange, { isLoading: isTomPdChangeUpdating, ...updateTomPdChangeResponse }] =
    useUpdateTomPdChangeMutation()

  const onAddPdVersion = useCallback(
    (values: DocVersionFormData) => {
      const { files } = values

      if (files?.length && files[0] instanceof File) {
        uploadTomPdVersion({ id, file: files[0] })
      }
    },
    [id],
  )

  const onAddPdChange = useCallback(
    (values: DocAddChange) => {
      const { files, change } = values

      if (files?.length && files[0] instanceof File) {
        uploadTomPdChange({ id, file: files[0], change })
      }
    },
    [id],
  )

  const onUpdatePdChange = useCallback(
    (values: DocUpdateChange) => {
      const { changeNum, change } = values

      updateTomPdChange({ id, changeNum, change })
    },
    [id],
  )

  useMutationHandlers(uploadTomPdVersionResponse, (data: TomPdVersionBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  useMutationHandlers(
    uploadTomPdChangeResponse,
    (data: TomPdChangeBaseResponse) => {
      enqueueSnackbar('Изменение успешно добавлено.', { variant: 'success' })
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
    (err) => {
      enqueueSnackbar('Изменение с таким номером уже существует.', { variant: 'error' })
    },
  )

  useMutationHandlers(
    updateTomPdChangeResponse,
    (data: TomPdChangeBaseResponse) => {
      enqueueSnackbar('Изменение успешно изменено.', { variant: 'success' })
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
    (err) => {
      enqueueSnackbar('Изменение с таким номером уже существует.', { variant: 'error' })
    },
  )

  // Ii
  const [uploadTomIiVersion, { isLoading: isTomIiVersionLoading, ...uploadTomIiVersionResponse }] =
    useUploadTomIiVersionMutation()
  const [uploadTomIiChange, { isLoading: isTomIiChangeLoading, ...uploadTomIiChangeResponse }] =
    useUploadTomIiChangeMutation()
  const [updateTomIiChange, { isLoading: isTomIiChangeUpdating, ...updateTomIiChangeResponse }] =
    useUpdateTomIiChangeMutation()

  const onAddIiVersion = useCallback(
    (values: DocVersionFormData) => {
      const { files } = values

      if (files?.length && files[0] instanceof File) {
        uploadTomIiVersion({ id, file: files[0] })
      }
    },
    [id],
  )

  const onAddIiChange = useCallback(
    (values: DocAddChange) => {
      const { files, change } = values

      if (files?.length && files[0] instanceof File) {
        uploadTomIiChange({ id, file: files[0], change })
      }
    },
    [id],
  )

  const onUpdateIiChange = useCallback(
    (values: DocUpdateChange) => {
      const { changeNum, change } = values

      updateTomIiChange({ id, changeNum, change })
    },
    [id],
  )

  useMutationHandlers(uploadTomIiVersionResponse, (data: TomIiVersionBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  useMutationHandlers(
    uploadTomIiChangeResponse,
    (data: TomIiChangeBaseResponse) => {
      enqueueSnackbar('Изменение успешно добавлено.', { variant: 'success' })
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
    (err) => {
      enqueueSnackbar('Изменение с таким номером уже существует.', { variant: 'error' })
    },
  )

  useMutationHandlers(
    updateTomIiChangeResponse,
    (data: TomIiChangeBaseResponse) => {
      enqueueSnackbar('Изменение успешно изменено.', { variant: 'success' })
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
    (err) => {
      enqueueSnackbar('Изменение с таким номером уже существует.', { variant: 'error' })
    },
  )

  // Ird
  const [uploadTomIrdVersion, { isLoading: isTomIrdVersionLoading, ...uploadTomIrdVersionResponse }] =
    useUploadTomIrdVersionMutation()
  const [uploadTomIrdChange, { isLoading: isTomIrdChangeLoading, ...uploadTomIrdChangeResponse }] =
    useUploadTomIrdChangeMutation()
  const [updateTomIrdChange, { isLoading: isTomIrdChangeUpdating, ...updateTomIrdChangeResponse }] =
    useUpdateTomIrdChangeMutation()

  const onAddIrdVersion = useCallback(
    (values: DocVersionFormData) => {
      const { files } = values

      if (files?.length && files[0] instanceof File) {
        uploadTomIrdVersion({ id, file: files[0] })
      }
    },
    [id],
  )

  const onAddIrdChange = useCallback(
    (values: DocAddChange) => {
      const { files, change } = values

      if (files?.length && files[0] instanceof File) {
        uploadTomIrdChange({ id, file: files[0], change })
      }
    },
    [id],
  )

  const onUpdateIrdChange = useCallback(
    (values: DocUpdateChange) => {
      const { changeNum, change } = values

      updateTomIrdChange({ id, changeNum, change })
    },
    [id],
  )

  useMutationHandlers(uploadTomIrdVersionResponse, (data: TomIrdVersionBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  useMutationHandlers(
    uploadTomIrdChangeResponse,
    (data: TomIrdChangeBaseResponse) => {
      enqueueSnackbar('Изменение успешно добавлено.', { variant: 'success' })
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
    (err) => {
      enqueueSnackbar('Изменение с таким номером уже существует.', { variant: 'error' })
    },
  )

  useMutationHandlers(
    updateTomIrdChangeResponse,
    (data: TomIrdChangeBaseResponse) => {
      enqueueSnackbar('Изменение успешно изменено.', { variant: 'success' })
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
    (err) => {
      enqueueSnackbar('Изменение с таким номером уже существует.', { variant: 'error' })
    },
  )

  // Cmn
  const [uploadTomCmnVersion, { isLoading: isTomCmnVersionLoading, ...uploadTomCmnVersionResponse }] =
    useUploadTomCmnVersionMutation()
  const [uploadTomCmnChange, { isLoading: isTomCmnChangeLoading, ...uploadTomCmnChangeResponse }] =
    useUploadTomCmnChangeMutation()

  const cmnSubmit = useCallback(
    (values: DocVersionFormData) => {
      const { files, change } = values

      if (files?.length && files[0] instanceof File) {
        isDocChange
          ? uploadTomCmnChange({ id: project.id, file: files[0], change: change as number })
          : uploadTomCmnVersion({ id: project.id, file: files[0] })
      }
    },
    [project],
  )

  useMutationHandlers(uploadTomCmnVersionResponse, (data: TomCmnVersionBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  useMutationHandlers(uploadTomCmnChangeResponse, (data: TomCmnChangeBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  // Common
  const onAddVersionByPhase: Record<ProjectPhase, (values: DocVersionFormData) => void> = {
    'Рабочая документация': (values: DocVersionFormData) => onAddRdVersion(values),
    'Проектная документация': (values: DocVersionFormData) => onAddPdVersion(values),
    'Инженерные изыскания': (values: DocVersionFormData) => onAddIiVersion(values),
    'Сбор исходных данных': (values: DocVersionFormData) => onAddIrdVersion(values),
  }

  const onSubmitByPhase: Record<ProjectPhase, (values: DocAddChange) => void> = {
    'Рабочая документация': (values: DocAddChange) => onAddRdChange(values),
    'Проектная документация': (values: DocAddChange) => onAddPdChange(values),
    'Инженерные изыскания': (values: DocAddChange) => onAddIiChange(values),
    'Сбор исходных данных': (values: DocAddChange) => onAddIrdChange(values),
  }

  const onUpdateByPhase: Record<ProjectPhase, (values: DocUpdateChange) => void> = {
    'Рабочая документация': (values: DocUpdateChange) => onUpdateRdChange(values),
    'Проектная документация': (values: DocUpdateChange) => onUpdatePdChange(values),
    'Инженерные изыскания': (values: DocUpdateChange) => onUpdateIiChange(values),
    'Сбор исходных данных': (values: DocUpdateChange) => onUpdateIrdChange(values),
  }
  const onCmnSubmit = (values: DocVersionFormData) => cmnSubmit(values)
  const onAddVersion = isCmnTom ? onCmnSubmit : onAddVersionByPhase[selectedProjectPhase]

  const onAddChange = onSubmitByPhase[selectedProjectPhase]
  const onUpdateChange = onUpdateByPhase[selectedProjectPhase]

  const fileLoading =
    isTomRdVersionLoading ||
    isTomRdChangeLoading ||
    isTomRdChangeUpdating ||
    isTomPdVersionLoading ||
    isTomPdChangeLoading ||
    isTomPdChangeUpdating ||
    isTomIiVersionLoading ||
    isTomIiChangeLoading ||
    isTomIiChangeUpdating ||
    isTomIrdVersionLoading ||
    isTomIrdChangeLoading ||
    isTomIrdChangeUpdating ||
    isTomCmnVersionLoading ||
    isTomCmnChangeLoading

  return {
    onAddVersion,
    onAddChange,
    onUpdateChange,
    fileLoading,
  }
}
