import { api } from "../api";
import {
  GetAgreementHistoryListRequest,
  GetAgreementHistoryListResponse,
  GetAgreementStagesHistoryRequest,
  GetAgreementStagesHistoryResponse,
  TransferTomToNextStageFromHistoryRequest,
  TransferTomToNextStageFromHistoryResponse,
} from "./api.types";

export const agreementPocketApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAgreementHistoryList: build.query<
      GetAgreementHistoryListResponse,
      GetAgreementHistoryListRequest
    >({
      query: ({ projectId, tomId, type }) => ({
        url: `/project/${projectId}/logs/${tomId}/pocket/list`,
        method: "GET",
        params: { type },
      }),
      providesTags: ["AgreementHistory"],
    }),
    getAgreementStagesHistory: build.query<
      GetAgreementStagesHistoryResponse,
      GetAgreementStagesHistoryRequest
    >({
      query: ({ projectId, tomId, pocketId }) => ({
        url: `/project/${projectId}/logs/${tomId}/pocket/${pocketId}/list`,
        method: "GET",
      }),
      providesTags: ["AgreementHistory"],
    }),
    transferTomToNextStageFromHistory: build.mutation<
      TransferTomToNextStageFromHistoryResponse,
      TransferTomToNextStageFromHistoryRequest
    >({
      query: ({ projectId, pocketId, tomId, ...body }) => ({
        url: `/project/${projectId}/logs/${tomId}/pocket/${pocketId}/transfer`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        "Remarks",
        "AgreementHistory",
        "TomAgreement",
        { type: "AgreementPocket", id: "STAGES-LIST" },
        { type: "TomIi", id: "UPLOAD" },
        { type: "TomPd", id: "UPLOAD" },
        { type: "TomRd", id: "UPLOAD" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAgreementHistoryListQuery,
  useGetAgreementStagesHistoryQuery,
  useTransferTomToNextStageFromHistoryMutation,
} = agreementPocketApi;
