import { FC, useEffect } from 'react'
import { Typography } from '@mui/material'
import { ExportArchiveTomItemProps } from './AttachmentsExportAttachmentItem.types'
import {
  AttachmentsExportAttachmentItemButton,
  AttachmentsExportAttachmentItemWrapper
} from './AttachmentsExportAttachmentItem.styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useFormikContext } from 'formik'
import { AttachmentsExportFormData, SelectedAttachmentData } from '../AttachmentsExportDrawerForm'


export const AttachmentsExportAttachmentItem: FC<ExportArchiveTomItemProps> = ({
  attachment,
  docIndex,
  attachmentIndex,
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<AttachmentsExportFormData>()
  const { name, id } = attachment

  const selected = !!formValues.docList[docIndex]?.selected || !!formValues.docList[docIndex]?.attachList[attachmentIndex]?.selected

  // set doc checkbox checked when all attachments checked
  useEffect(() => {
    const filteredAttachments = formValues.docList[docIndex]?.attachList?.filter(attachment => !attachment.hideByFilter)

    if (filteredAttachments?.length && filteredAttachments.every(attachment => attachment.selected)) {
      setFieldValue(`docList[${docIndex}].selected`, true)
    }
  }, [formValues.docList[docIndex]?.attachList[attachmentIndex]])

  const onAddClick = () => {
    setFieldValue(`docList[${docIndex}].attachList[${attachmentIndex}].selected`, true)

    const selectedAttachmentData: SelectedAttachmentData = {
      attachment: attachment,
      docIndex: docIndex,
      attachmentIndex: attachmentIndex,
    }
    setFieldValue(`selectedAttachments`, [...formValues.selectedAttachments, selectedAttachmentData])
  }

  const onRemoveClick = () => {
    setFieldValue(`docList[${docIndex}].attachList[${attachmentIndex}].selected`, false)
    setFieldValue(`docList[${docIndex}].selected`, false)

    const selectedAttachmentsCopy = [...formValues.selectedAttachments]
    const removedAttachmentIndex = selectedAttachmentsCopy.findIndex(attachmentData => attachmentData.attachment.id === id)
    selectedAttachmentsCopy.splice(removedAttachmentIndex, 1)

    setFieldValue(`selectedAttachments`, selectedAttachmentsCopy)
  }

  const buttonPropsBySelectedFlag = {
    true: {
      onClick: onRemoveClick,
      startIcon: <RemoveIcon color='primary' />,
    },
    false: {
      onClick: onAddClick,
      startIcon: <AddIcon color='primary' />,
    },
  }

  return (
    <AttachmentsExportAttachmentItemWrapper direction='row' alignItems='center' spacing={1}>
      <AttachmentsExportAttachmentItemButton
        {...buttonPropsBySelectedFlag[`${selected}`]}
        variant='text'
      >
        <Typography variant='subtitle2' component='span'>
          {name || ''}
        </Typography>
      </AttachmentsExportAttachmentItemButton>
    </AttachmentsExportAttachmentItemWrapper>
  )
}
