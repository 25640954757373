import UploadDrawer from '../../../../components/UploadDrawer'
import React, { useCallback, useEffect, useState } from 'react'
import { ObjectsUploadDrawerProps } from './ObjectsUploadDrawer.types'
import {
  GetObjectLinkExampleResponse,
  useGetObjectLinkExampleMutation,
  useUploadObjectMutation
} from '../../../../api/rdPhase'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { ExcelUploadResponse } from '../../../../types/global'

const ObjectsUploadDrawer: React.FC<ObjectsUploadDrawerProps> = ({ open, onClose, projectId }) => {
  const [files, setFiles] = useState<File[] | null>(null)

  const [uploadObject, { isLoading, ...uploadObjectResponse }] = useUploadObjectMutation()
  const [responseData, setResponseData] = useState<ExcelUploadResponse | undefined>(undefined)
  const [getObjectLinkExample, getObjectLinkExampleResponse] = useGetObjectLinkExampleMutation()

  useEffect(() => {
    if (files?.length && files[0] instanceof File) {
      uploadObject({
        id: projectId,
        file: files[0]
      })
    }
  }, [files])

  const onFormSubmit = useCallback((files: File[]) => {
    setFiles(files)
  }, [])

  const onFormChange = useCallback(() => {
    setResponseData(undefined)
  }, [])

  useMutationHandlers(
    uploadObjectResponse,
    (data) => {
      setResponseData(data)
    }
  )

  useMutationHandlers(
    getObjectLinkExampleResponse,
    (data: GetObjectLinkExampleResponse) => {
      window.location.href = data.data
    }
  )

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    onClose(dirty, immediately)
    setResponseData(undefined)
    setFiles(null)
  }, [onClose])

  return (
    <UploadDrawer
      open={open}
      mode='single'
      onSubmit={onFormSubmit}
      responseData={responseData}
      onChange={onFormChange}
      onClose={onDrawerClose}
      title='Добавление объекта'
      text='Вы можете добавить объекты с помощью exсel документа с уже подготовленными данными.'
      attentionText={<>В случае если у Вас будут совпадения названий существующих объектов с новыми из файла, новые объекты 
      заменят существующие.<br />
      Это действие нельзя отменить.</>}
      uploadData={{
        title: 'Загрузить xls(х)',
        text: 'Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
        formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel']
      }}
      loading={isLoading}
      getExampleLink={getObjectLinkExample}
    />
  )
}

export default ObjectsUploadDrawer