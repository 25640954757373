import { UseExportArchiveSubmitResponse } from './useExportArchiveSubmit.types'
import {
  SendTomsRdFileListRequest,
  SendTomsRdFileListResponse,
  useSendTomsRdFileListMutation
} from '../../../../../../../../api/rdPhase'
import {
  SendTomsPdFileListRequest,
  SendTomsPdFileListResponse,
  useSendTomsPdFileListMutation
} from '../../../../../../../../api/pdPhase'
import {
  SendTomsIiFileListRequest,
  SendTomsIiFileListResponse,
  useSendTomsIiFileListMutation
} from '../../../../../../../../api/iiPhase/tom/archiveExport'
import { useMutationHandlers } from '../../../../../../../../hooks/useMutationHandlers'
import { ProjectPhase } from '../../../../../../../../types/project'
import { ExportArchiveFormData } from '../../ExportArchiveDrawerForm.types'
import { useAppDispatch, useTypedSelector } from '../../../../../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../../../../../store/slices/documentsPages/projectInfo'
import { onDrawerClose } from '../../../../../../../../store/slices/documentsPages/drawerInfo'
import {
  SendTomsIrdFileListRequest,
  SendTomsIrdFileListResponse,
  useSendTomsIrdFileListMutation
} from '../../../../../../../../api/irdPhase/tom/archiveExport'

export const useExportArchiveSubmit = (): UseExportArchiveSubmitResponse => {
  const dispatch = useAppDispatch()

  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  // Rd
  const [sendTomsRdFileList, sendTomsRdFileListResponse] = useSendTomsRdFileListMutation()

  const submitRd = (values: ExportArchiveFormData) => {
    const dataForRd: SendTomsRdFileListRequest = {
      id: project.id!,
      includeChanges: values.includeChanges,
      includeAttach: values.includeAttach,
      includeExcel: values.includeExcel,
      docId: values.selectedRdToms.map(tomData => tomData.tom.tomId),
    }

    sendTomsRdFileList(dataForRd)
  }

  useMutationHandlers(
    sendTomsRdFileListResponse,
    (data: SendTomsRdFileListResponse) => {
      window.open(data.link)
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
  )


  // Pd
  const [sendTomsPdFileList, sendTomsPdFileListResponse] = useSendTomsPdFileListMutation()

  const submitPd = (values: ExportArchiveFormData) => {
    const dataForPd: SendTomsPdFileListRequest = {
      id: project.id!,
      includeChanges: values.includeChanges,
      includeAttach: values.includeAttach,
      includeExcel: values.includeExcel,
      docId: values.selectedPdToms.map(tomData => tomData.tom.tomId),
    }

    sendTomsPdFileList(dataForPd)
  }

  useMutationHandlers(
    sendTomsPdFileListResponse,
    (data: SendTomsPdFileListResponse) => {
      window.open(data.link)
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
  )


  // Ii
  const [sendTomsIiFileList, sendTomsIiFileListResponse] = useSendTomsIiFileListMutation()

  const submitIi = (values: ExportArchiveFormData) => {
    const dataForIi: SendTomsIiFileListRequest = {
      id: project.id!,
      includeChanges: values.includeChanges,
      includeAttach: values.includeAttach,
      includeExcel: values.includeExcel,
      docId: values.selectedIiToms.map(tomData => tomData.tom.tomId),
    }

    sendTomsIiFileList(dataForIi)
  }

  useMutationHandlers(
    sendTomsIiFileListResponse,
    (data: SendTomsIiFileListResponse) => {
      window.open(data.link)
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
  )


  // Ird
  const [sendTomsIrdFileList, sendTomsIrdFileListResponse] = useSendTomsIrdFileListMutation()

  const submitIrd = (values: ExportArchiveFormData) => {
    const dataForIrd: SendTomsIrdFileListRequest = {
      id: project.id!,
      includeChanges: values.includeChanges,
      includeAttach: values.includeAttach,
      includeExcel: values.includeExcel,
      docId: values.selectedIrdToms.map(tomData => tomData.tom.tomId),
    }

    sendTomsIrdFileList(dataForIrd)
  }

  useMutationHandlers(
    sendTomsIrdFileListResponse,
    (data: SendTomsIrdFileListResponse) => {
      window.open(data.link)
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
    },
  )


  // Common
  const onSubmit: Record<ProjectPhase, (values: ExportArchiveFormData) => void> = {
    'Рабочая документация': (values: ExportArchiveFormData) => submitRd(values),
    'Проектная документация': (values: ExportArchiveFormData) => submitPd(values),
    'Инженерные изыскания': (values: ExportArchiveFormData) => submitIi(values),
    'Сбор исходных данных': (values: ExportArchiveFormData) => submitIrd(values),
  }

  return {
    onSubmit: onSubmit[selectedProjectPhase],
  }
}
