import styled from 'styled-components'
import { Container } from '@mui/material'

export const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
`

export const InnerContainer = styled(Container)<{$noscrollable?: boolean}>`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  padding: 0 7px 16px 20px;
  overflow-y: ${props => props.$noscrollable ? 'hidden' : 'scroll'};
  overflow-x: hidden;
`