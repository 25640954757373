
export const reduceFileName = (fullFileName: string, maxLength: number): string => {
  if (fullFileName.length <= maxLength) {
      return fullFileName
  }

  const fileNameParts = fullFileName.split('.')
  const extension = fileNameParts.pop()!
  const fileName = fileNameParts.join('.')
  
  const maxFileNameLength = maxLength - extension.length - 1
  const shortenedFileName = fileName.slice(0, maxFileNameLength) + '...' + extension

  return shortenedFileName
}