import { ArrowBack } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as EmptyImage } from '../../assets/empty.svg'
import { EmptyPageProps } from './EmptyPage.types'
import { EmptyPageButton, EmptyPageWrapper, StyledButton } from './styles'

const EmptyPage: React.FC<EmptyPageProps> = ({
  data,
  forFilter = false,
  backButton = false,
  fullPage = false,
  wrapperProps,
}) => {
  const navigate = useNavigate()
  const { text, buttons } = data

  return (
    <>
      {backButton && (
        <EmptyPageButton variant='text' startIcon={<ArrowBack fontSize='medium' />} onClick={() => navigate(-1)}>
          Назад
        </EmptyPageButton>
      )}
      <EmptyPageWrapper
        spacing={forFilter ? 3 : 7.5}
        justifyContent='center'
        alignItems='center'
        forFilter={forFilter}
        fullPage={fullPage}
        {...wrapperProps}
      >
        <Stack spacing={2.5} alignItems='center'>
          <Typography variant='h2'>{text}</Typography>
          <Stack direction='row' spacing={1.5}>
            {buttons.map((button) => (
              <StyledButton
                startIcon={<button.icon />}
                onClick={() => button.onClick()}
                key={button.text}
                variant='outlined'
                color='secondary'
              >
                {button.text}
              </StyledButton>
            ))}
          </Stack>
        </Stack>

        <Box>
          <EmptyImage width={630} height={400} />
        </Box>

        {/*{!forFilter*/}
        {/*  ? (*/}
        {/*    <Box>*/}
        {/*      <Typography variant='h1' color={theme.palette.primary.main}>Нужна помощь?</Typography>*/}
        {/*      <Typography variant='h1'>*/}
        {/*        <EmptyLink to='#'>Перейти в справочный центр </EmptyLink>*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*  )*/}
        {/*  : null*/}
        {/*}*/}
      </EmptyPageWrapper>
    </>
  )
}

export default EmptyPage
