import React from 'react'
import { StyledDivider } from './styles'
import { DividerProps } from './Divider.types'

const Divider: React.FC<DividerProps> = ( {color, ...props }) => {
  return (
    <StyledDivider variant='inset' {...props} color={color} />
  )
}

export default Divider