import React, { ChangeEvent, FC } from 'react'
import { WorksTopLegendItems, WorksTopLegendProps } from './WorksTopLegend.types'
import { Box, Stack } from '@mui/material'
import Button from '../../../../components/Button'
import { theme } from '../../../../styles/theme'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'
import DownloadingIcon from '@mui/icons-material/Downloading'
import LegendItem from '../../../../components/LegendItem'
import { useNavigate, useParams } from 'react-router-dom'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { FilterSelectMultiple } from '../../../../components/FilterSelectMulti'
import { useExportWorksMutation, useGetWorkPersonsQuery } from '../../../../api/works'
import { SelectItem } from '../../../../components/FilterSelectMulti/FilterSelectMultiple.types'
import { WorkStatus, workStatuses } from '../../../../types/works'
import { StyledCheckbox } from '../../../../components/FieldForm/styles'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { ExpiredCheckboxControl } from '../../../Docs/components/DocsTopLegend/DocsTopLegend.styles'
import { ExportIcon } from '../../../../assets/icons/ExportIcon'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { downloadBlob } from '../../../../utils/dowloadBlob'
import Tooltip from '../../../../components/Tooltip'

export const WorksTopLegend: FC<WorksTopLegendProps> = ({
  currentProject,
  onAddClick,
  onUploadClick,
  filterData,
  onFilterChange
}) => {
  const navigate = useNavigate()
  const { scheduleId: scheduleIdStr } = useParams()
  const scheduleId = parseInt(scheduleIdStr!)

  const [exportWorks, exportWorksResponse] = useExportWorksMutation()

  const { id: projectId, shifrName: projectShifrName } = currentProject || {}
  const { ids = [], name = [], status = [] } = filterData
  const personFilterValues = [
    ...ids.map(Number),
    ...name,
  ]

  const onReturnClick = () => {
    navigate(`/project/${projectId}/schedules`)
  }

  const onExportClick = () => {
    exportWorks({ id: projectId, scheduleId })
  }

  useMutationHandlers(
    exportWorksResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `${projectShifrName} График.xlsx`)
    }
  )

  const legendItems: WorksTopLegendItems[] = [
    { label: 'Запланировано', color: 'blue' },
    { label: 'Выполнено', color: 'green' },
    { label: 'Просрочено', color: 'red' },
  ]

  const { data: workPersonsData } = useGetWorkPersonsQuery({ id: projectId, scheduleId })
  const { data: workPersons = [] } = workPersonsData || {}

  const personFilterItems: SelectItem<number | string>[] = workPersons
    .filter(({ employeeName }) => !!employeeName)
    .map(({ employeeId, employeeName }) => ({
      value: employeeId || employeeName,
      title: employeeName,
    }))

  const isExpired = status.includes('Просрочено')

  const statusFilterItems: SelectItem<string>[] = workStatuses.map(status => ({
    value: status,
    title: status,
  }))
  const statusFilterValues = status.filter(status => status !== 'Просрочено')

  const onPersonFilter = (values: (number | string)[]) => {
    const ids = values.filter(value => typeof value === 'number').map(value => value.toString())
    const names = values.filter(value => typeof value === 'string') as string[]

    onFilterChange('ids', ids)
    onFilterChange('name', names)
  }

  const onStatusFilter = (values: string[]) => {
    const statuses = [...values]
    isExpired && statuses.unshift('Просрочено')

    onFilterChange('status', statuses as WorkStatus[])
  }

  const expiredOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const statusFilterValueCopy = [...status]
    e.target.checked
      ? statusFilterValueCopy.unshift('Просрочено')
      : statusFilterValueCopy.shift()

    onFilterChange('status', statusFilterValueCopy as WorkStatus[])
  }

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1.5 }}>
      <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
        <Button
          variant='text'
          style={{ color: theme.palette.text.dark }}
          startIcon={<ArrowBackIcon />}
          onClick={onReturnClick}
        >
          Назад
        </Button>

        <FilterSelectMultiple<number | string>
          items={personFilterItems}
          value={personFilterValues}
          onChange={onPersonFilter}
          defaultValue='Ответственный'
          allSelectedText='Все ответственные'
          startIcon={<FilterAltIcon fontSize='medium' color='dark' />}
        />

        <FilterSelectMultiple<string>
          items={statusFilterItems}
          value={statusFilterValues}
          onChange={onStatusFilter}
          defaultValue='Статус'
          allSelectedText='Все статусы'
        />

        <ExpiredCheckboxControl
          control={
            <StyledCheckbox
              checked={isExpired}
              onChange={expiredOnChange}
              icon={isExpired
                ? <CheckBoxIcon />
                : <CheckBoxOutlineBlankIcon />
              }
            />
          }
          label={
            <Box>
              Просрочено
            </Box>
          }
        />
      </Stack>

      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3}>
        <Stack direction='row' alignItems='center' spacing={3}>
          {legendItems.map(item => (
            <LegendItem label={item.label} color={item.color} key={item.label} />
          ))}
        </Stack>

        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1.5}>
          <Tooltip title='Экспортировать график в Excel'>
              <span>
                <Button onClick={onExportClick} icon color='secondary' variant='outlined'>
                  <ExportIcon />
                </Button>
              </span>
          </Tooltip>
          <Button
            onClick={onAddClick}
            variant='outlined'
            size='small'
            color='secondary'
            startIcon={<AddIcon />}
          >
            Добавить
          </Button>
          <Button
            onClick={onUploadClick}
            variant='outlined'
            size='small'
            color='secondary'
            startIcon={<DownloadingIcon />}
          >
            Загрузить
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
