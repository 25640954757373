import { FC } from 'react'
import { IrdLegendItemProps } from './IrdLegendItem.types'
import { IrdLegendItemCircle, IrdLegendItemWrapper } from './IrdLegendItem.styles'
import { Box, Typography } from '@mui/material'

export const IrdLegendItem: FC<IrdLegendItemProps> = ({ title, color }) => {
  return (
    <IrdLegendItemWrapper>
      <IrdLegendItemCircle bgColor={color} />

      <Box>
        <Typography fontSize={14}>
          {title}
        </Typography>
      </Box>
    </IrdLegendItemWrapper>
  )
}
