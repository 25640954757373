import * as yup from 'yup'
import { InnermapMember } from '../../../../../api/internalAgreement/types';
import { RightsValues } from './InternalAgreementForm.types';

export const validationSchema = yup.object({
  department: yup.string().required('common:errors.required'),
  position: yup.string().required('common:errors.required'),
  name: yup.string().required('common:errors.required'),
})

export const checkRights = (innermapMember: InnermapMember): RightsValues[] => {
  const rightsArray: RightsValues[] = [];

  if (innermapMember.approval) {
    rightsArray.push('approval');
  }
  if (innermapMember.assurance) {
    rightsArray.push('assurance');
  }
  if (innermapMember.remarks) {
    rightsArray.push('remarks');
  }
  if (innermapMember.signing) {
    rightsArray.push('signing');
  }

  return rightsArray;
}