import DownloadIcon from '@mui/icons-material/Download'
import MailIcon from '@mui/icons-material/Mail'
import { Avatar, Box, PopoverOrigin, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState, MouseEvent } from 'react'
import { useParams } from 'react-router-dom'
import { tomSelector } from '@store/slices/documentsPages/tom'
import { useDeleteRemarkMutation, useDownloadRemarkAttachmentMutation } from '../../../../../../api/remarks'
import { RemarkInfo, RemarkMessage, RemarkStatus } from '../../../../../../api/remarks/types'
import Tooltip from '../../../../../../components/Tooltip'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../../hooks/useConfirmDialog'
import { setIsAnnotationMode, setRemarkFormData, setRemarkFormMode } from '@store/slices/remarks/remarks'
import { profileSelector } from '../../../../../../store/slices/profile'
import { RemarkFormData } from '../../../../../../store/slices/remarks/remarks.types'
import { useAppDispatch, useTypedSelector } from '../../../../../../store/store'
import { downloadBlob } from '../../../../../../utils/dowloadBlob'
import { convertDateTime } from '../../../../../../utils/formatDateAndTimeGMT'
import { formatName } from '../../../../../../utils/formatName'
import { reduceFileName } from '../../../../../../utils/reduceFileName'
import { RemarkMenu, RemarkMenuData } from '../RemarkMenu'
import { ReplyToRemark } from '../ReplyToRemark'
import { MAX_FILENAME_LENGTH, MAX_HEIGHT_REMARK, availableStatuses, remarkMenuList, titleStatus } from './Remark.config'
import {
  ChangeStatusButton,
  EllipsisText,
  ExpandButton,
  FileNameText,
  MainText,
  MessageWrapper,
  RemarkContainer,
  RemarkStatusLabel,
  StatusLabel,
  StatusText,
  SubtitleText,
  TextLink,
} from './Remark.styles'
import { RemarkMenuTitle, RemarkProps, RemarkStatusRu, remarkColorStatus, remarkStatusEnToRu } from './Remarks.types'
import { CircleInfoText } from '../RemarkForm'

const anchorOriginStatusMenu: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

const transformOriginStatusMenu: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

export const Remark = ({ canAddMessage, remarkInfo, messages, selectedRemark, scrollTrigger, selectRemark, openRemarkForm }: RemarkProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const profile = useTypedSelector(profileSelector)
  const { avatar, id, name } = remarkInfo.author || {}
  const isAdmin = profile.role === 'admin'
  const isEmployeResponsible = profile.company.userCompanyName === remarkInfo.responsible
  const isAuthor = profile.employeeId === id
  const initialRemark: string = remarkInfo.isSecond ? 'Повторное' : 'Первичное'
  const [status, setStatus] = useState<RemarkStatus | null>(null)
  const [showAll, setShowAll] = useState(false)
  const [offsetHeight, setOffsetHeight] = useState<number>(0)
  const refContainer = useRef<HTMLDivElement | null>(null)
  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled: boolean = !!tom?.features.annulment.annulled

  const [fileDataForDownload, setFileDataForDownload] = useState<string | null>(null)
  const [downloadAttachment, downloadAttachmentResponse] = useDownloadRemarkAttachmentMutation()
  const [deleteRemark, deleteRemarkResponse] = useDeleteRemarkMutation()

  const remarkTomInfoTitle = (remarkInfo: RemarkInfo, version: number | null, withPage: boolean) =>
    [
      withPage && remarkInfo.page && `Лист ${remarkInfo.page}`,
      version !== null && `версия тома ${version}`,
      remarkInfo.change !== null && `изм. ${remarkInfo.change}`,
    ]
      .filter(Boolean)
      .join(', ')

  const onEditRemark = () => {
    dispatch(
      setRemarkFormData({
        remarkFormData: {
          change: remarkInfo.change,
          page: remarkInfo.page,
          totalPages: remarkInfo.totalPages,          
          comment: messages[0].message.comment,
          reason: messages[0].message.reason,
          type: remarkInfo.type,
          version: messages[0].message.version,
          remarkId: remarkInfo.id,
        } as RemarkFormData,
      }),
    )
    handleSelectRemark(true)
    dispatch(setIsAnnotationMode(true))
    dispatch(setRemarkFormMode({ remarkFormMode: 'edit' }))
    openRemarkForm()
  }

  const menuFunctions: Record<RemarkStatusRu | RemarkMenuTitle, () => void> = {
    Направлено: () => setStatus('submitted'),
    'В работе': () => setStatus('accepted'),
    Отклонено: () => setStatus('rejected'),
    Устранено: () => setStatus('resolved'),
    Закрыто: () => setStatus('closed'),
    Снято: () => setStatus('cancelled'),
    Создано: () => setStatus('created'),
    'Удалить замечание': () => openConfirm(),
    'Редактировать замечание': () => onEditRemark(),
  }

  const confirmDeleteRemark = (confirm: boolean) => {
    if (confirm) {
      deleteRemark({ projectId, remarkId: remarkInfo.id })
    }
  }

  const statuses = isAdmin
    ? availableStatuses.admin
    : isAuthor
      ? availableStatuses.author
      : isEmployeResponsible
        ? availableStatuses.responsible
        : undefined

  const statusMenuData: RemarkMenuData[] | undefined = statuses?.[remarkInfo.status]?.map((status) => ({
    itemName: titleStatus[status],
    action: menuFunctions[status],
  }))

  const remarkMenuData: RemarkMenuData[] | undefined = remarkMenuList(isAdmin, remarkInfo.isSecond)[
    remarkInfo.status
  ]?.map((item) => ({
    itemName: titleStatus[item],
    action: menuFunctions[item],
  }))

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmDeleteRemark,
    body: <>Замечание будет удалено без возможности восстановить</>,
    title: 'Удалить замечание?',
    denyButtonText: 'Отменить',
    confirmButtonText: 'Удалить',
    confirmButtonColor: 'error',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const сroppedMessages =
    offsetHeight <= MAX_HEIGHT_REMARK || messages.length <= 2 || showAll
      ? messages
      : [messages[0], messages[messages.length - 1]]

  const handleDownloadAttachment = (attachId: number, fileName: string) => {
    downloadAttachment({ projectId, remarkId: remarkInfo.id, attachId })
    setFileDataForDownload(fileName)
  }

  const handleSelectRemark = (notCancelSelect?: boolean) => {
    selectRemark(remarkInfo.id, messages[0].message.version, remarkInfo.change, notCancelSelect)
  }

  const handleSelectMessage = (e: MouseEvent, message: RemarkMessage) => {
    
    if (message.status !== 'created' && message.status !== 'submitted') {
      e.stopPropagation()
      selectRemark(remarkInfo.id, message.version, remarkInfo.change)
    }
  }

  useEffect(() => {
    if (refContainer.current) {
      setOffsetHeight(refContainer.current.offsetHeight)
    }
  }, [refContainer])

  useEffect(() => {
    if (downloadAttachmentResponse.data) {
      downloadBlob(downloadAttachmentResponse.data, fileDataForDownload || 'Файл загрузки')
    }
    if (downloadAttachmentResponse.isError) {
      enqueueSnackbar('Не удалось загрузить файл, попробуйте еще раз.', { variant: 'error' })
    }
  }, [downloadAttachmentResponse])

  useEffect(() => {
    if (deleteRemarkResponse.isSuccess) {
      enqueueSnackbar('Замечание успешно удалено.', { variant: 'success' })
    }
    if (deleteRemarkResponse.isError) {
      enqueueSnackbar('Не удалось удалить замечание.', { variant: 'error' })
    }
  }, [deleteRemarkResponse])


  useEffect(() => {
    if (selectedRemark === remarkInfo.id && scrollTrigger) {
      refContainer.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [remarkInfo.id, scrollTrigger, selectedRemark])

  return (
    <Stack spacing={0.5} py={1.25} ref={refContainer} >
      <Stack direction='row' spacing={1} alignItems='center'>
        <StatusLabel color={remarkColorStatus[remarkInfo.status]} />
        <StatusText>
          {remarkStatusEnToRu[remarkInfo.status]}, {convertDateTime(remarkInfo.updatedAt, false, true)}
        </StatusText>
      </Stack>

      <RemarkContainer spacing={1} p={2} selected={selectedRemark === remarkInfo.id} onClick={() => handleSelectRemark()}>
        <Stack direction='row'>
          <Avatar src={avatar || ''} sx={{ height: '32px', width: '32px' }} />
          <Stack sx={{ textAlign: 'start' }} flex={1} ml={1.5}>
            <Typography variant='body1' fontWeight={500} lineHeight='normal'>
              {name}
            </Typography>
            <TextLink fontSize={14} $hover={true}>
              {remarkTomInfoTitle(remarkInfo, messages[0].message.version, true)}
            </TextLink>
            <Typography variant='body1' fontSize={14}>
              {initialRemark}, {convertDateTime(remarkInfo.createdAt, false, true)}
            </Typography>
          </Stack>
          {messages[0].mail && (
            <Tooltip
              variant='light'
              title={<></>}
              customTitle={
                <Stack direction='row' spacing={0.5}>
                  <Typography variant='tooltip' textTransform='none' color='#5C6E8C'>
                    Письмо:
                  </Typography>
                  <Typography variant='tooltip' textTransform='none' fontWeight={500}>
                    {`${messages[0].mail.mailNum} от ${messages[0].mail.mailDate}`}
                  </Typography>
                </Stack>
              }
            >
              <MailIcon fontSize='small' color='primary' sx={{ position: 'relative', top: '11px', right: '7px' }} />
            </Tooltip>
          )}
          {(isAdmin || isAuthor) && remarkMenuData && remarkMenuData.length > 0 && (
            <RemarkMenu data={remarkMenuData} title='Управление замечанием' anchorParentElement={true} />
          )}
        </Stack>

        {сroppedMessages.map(({ message, attachments, mail, marks }, index) => (
          <Stack key={message.id}>
            {!showAll && index !== 0 && messages.length > 2 && offsetHeight >= MAX_HEIGHT_REMARK && (
              <ExpandButton variant='text' fullWidth onClick={() => setShowAll(true)}>
                Показать историю
              </ExpandButton>
            )}

            <MessageWrapper
              spacing={1}
              $bg={message.status !== 'created' && message.status !== 'submitted' ? selectedRemark === remarkInfo.id ? 'blue' : 'shades' : ''}
              $hover={message.status !== 'created' && message.status !== 'submitted'}
              onClick={(e) => handleSelectMessage(e, message)}
            >
              {index === 0 ? null : (
                <Stack direction='row' spacing={1.5} >
                  <Avatar src={message.author.avatar || ''} sx={{ height: '32px', width: '32px' }} />
                  <Box textAlign='start'>
                    <Typography variant='body1' fontWeight={500} lineHeight='normal'>
                      {message.author.name}
                    </Typography>
                    <TextLink
                      $hover={message.status !== 'created' && message.status !== 'submitted'}
                      variant='body1' fontSize={14}>
                      {remarkTomInfoTitle(remarkInfo, message.version, false)}
                    </TextLink>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <RemarkStatusLabel color={remarkColorStatus[message.status]}></RemarkStatusLabel>
                      <Typography variant='body1' fontSize={14}>
                        {`${remarkStatusEnToRu[message.status]} ${convertDateTime(message.createdAt, false, true)}`}
                      </Typography>
                    </Stack>
                  </Box>
                  {mail && (
                    <Tooltip
                      variant='light'
                      title={<></>}
                      customTitle={
                        <Stack direction='row' spacing={0.5}>
                          <Typography variant='tooltip' textTransform='none' color='#5C6E8C'>
                            Письмо:
                          </Typography>
                          <Typography variant='tooltip' textTransform='none' fontWeight={500}>
                            {`${mail.mailNum} от ${mail.mailDate}`}
                          </Typography>
                        </Stack>
                      }
                    >
                      <MailIcon
                        fontSize='small'
                        color='primary'
                        sx={{ position: 'relative', top: '11px', right: '7px', marginLeft: 'auto !important' }}
                      />
                    </Tooltip>
                  )}
                </Stack>
              )}

              {message.comment && <MainText variant='body1'>{message.comment}</MainText>}
              {message.reason && (
                <Stack alignItems='flex-start'>
                  <SubtitleText>Основание</SubtitleText>
                  <MainText variant='body1'>{message.reason}</MainText>
                </Stack>
              )}

              {/* Пометки */}
              {marks && marks.length > 0 &&
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <SubtitleText>Пометки</SubtitleText>
                  <CircleInfoText>
                    <Typography>{marks.length}</Typography>
                  </CircleInfoText>
                </Stack>}

              {attachments.map((file) => (
                <Stack direction='row' alignItems='center' spacing={1} key={file.id}>
                  <Box
                    display='inline-flex'
                    alignItems='center'
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleDownloadAttachment(file.id, file.name)}
                  >
                    <DownloadIcon fontSize='small' color='primary' sx={{ marginRight: 1 }} />
                    <Tooltip title={<>{file.name}</>} disableHoverListener={file.name.length < MAX_FILENAME_LENGTH}>
                      <FileNameText>{reduceFileName(file.name, MAX_FILENAME_LENGTH)}</FileNameText>
                    </Tooltip>
                  </Box>
                </Stack>
              ))}
            </MessageWrapper>
            {message.status === 'submitted' && (
              <Stack py={1} mt={1} direction='row' alignItems='center' justifyContent='space-between'>
                <svg xmlns='http://www.w3.org/2000/svg' width='30' height='7' viewBox='0 0 30 7' fill='none'>
                  <path d='M5 3L0 0.613249V6.38675L5 4V3ZM4.5 4H30V3H4.5V4Z' fill='#7890B2' />
                </svg>
                <StatusText>
                  {index === 0 ? 'Направлено ' : 'Направлено повторно'}
                  {convertDateTime(message.createdAt, true, true)}
                </StatusText>
                <svg xmlns='http://www.w3.org/2000/svg' width='30' height='7' viewBox='0 0 30 7' fill='none'>
                  <path d='M25 4L30 6.38675V0.613249L25 3V4ZM0 4H25.5V3H0V4Z' fill='#7890B2' />
                </svg>
              </Stack>
            )}
          </Stack>
        ))}
        {status && <ReplyToRemark newStatus={status} remarkId={remarkInfo.id} closeReply={() => setStatus(null)} />}
        {!status && remarkInfo.responsible && (
          <Stack direction='row' justifyContent='space-between' spacing={1} pt={2}>
            <SubtitleText>Ответственный: </SubtitleText>
            <Typography variant='h1' fontSize={12}>
              {remarkInfo.responsible}
            </Typography>
          </Stack>
        )}
        {!canAddMessage || status ? null : (
          <RemarkMenu
            data={statusMenuData}
            title='Статус замечания'
            anchorOrigin={anchorOriginStatusMenu}
            transformOrigin={transformOriginStatusMenu}
          >
            <ChangeStatusButton selected={selectedRemark === remarkInfo.id}>
              <Stack direction='row' spacing={1.5}>
                <Avatar src={profile.avatar} sx={{ height: '32px', width: '32px' }} />
                <Box textAlign='start'>
                  <EllipsisText variant='body1' fontWeight={500} lineHeight='normal' maxWidth='240px'>
                    {formatName(profile.lastName, profile.firstName, profile.middleName)}
                  </EllipsisText>
                  <Typography variant='body2' fontSize={12}>
                    {`Нажмите для ${messages.length === 1 ? 'установки' : 'изменения'} статуса`}
                  </Typography>
                </Box>
              </Stack>
            </ChangeStatusButton>
          </RemarkMenu>
        )}
      </RemarkContainer>
      <ConfirmDialog />
    </Stack>
  )
}
