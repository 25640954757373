import React from 'react'
import { ColoredStatusProps } from './ColoredStatus.types'
import { StatusedWrapper } from './styles'
import { WithChildren } from '../../types/global'

const ColoredStatus: React.FC<ColoredStatusProps & WithChildren> = ({ color, children }) => {
  return (
    <StatusedWrapper color={color} className='status-wrapper'>
      {children}
    </StatusedWrapper>
  )
}

export default ColoredStatus