import { Box, Stack } from "@mui/material";
import styled from "styled-components";
import { PlaceholderProps } from "./Placeholder.types";

export const PlaceHolderContainer = styled(Stack) <PlaceholderProps>`
  padding: 10%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 10px;
  height: ${props => typeof props.height === 'number' ? props.height + 'px' : props.height};
  width: ${props => props.width ? typeof props.width === 'number' ? props.width + 'px' : props.width : '100%'};

  & div {
    background-color: #0000001c;
  }
`

export const HeaderBlock = styled(Box)`
  height: 6%;
  width: 80%;
  align-self: center;
  transform: none;
  transform-origin: initial;
`
export const HeaderBlock2 = styled(Box)`
  height: 8%;
  width: 93%;
  align-self: center;
  border-radius: 13px;
  margin-top: 20px;
  transform: none;
  transform-origin: initial;
`
export const HeaderBlock3 = styled(Box)`
  height: 2%;
  width: 80%;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 20px;
  transform: none;
  transform-origin: initial;
`
export const TextBlock = styled(Box)`
  height: 2%;
  width: 80%;
  margin-top: 10px;
  transform: none;
  transform-origin: initial;
`
export const TextBlock2 = styled(Box)`
  height: 2%;
  width: 40%;
  margin-top: 10px;
  transform: none;
  transform-origin: initial;
`
export const TextBlock3 = styled(Box)`
  height: 2%;
  width: 100%;
  margin-top: 10px;
  transform: none;
  transform-origin: initial;
`
export const TextBlock4 = styled(Box)`
  height: 2%;
  width: 70%;
  margin-top: 10px;
  margin-bottom: 20px;
  transform: none;
  transform-origin: initial;
`