import { Stack } from "@mui/material"
import { CompanySchemes } from "./components/CompanySchemes"
import { DocViewNavBarInternalAgreementProps } from "./DocViewNavBarInternalAgreement.types"
import { useEffect } from "react"
import { useAppDispatch, useTypedSelector } from "../../../../store/store"
import { currentSchemaSelector, setApprovedProcedure, setCurrentSchema } from "../../../../store/slices/internalAgreement"

export const DocViewNavBarInternalAgreement = ({ companyProcedures }: DocViewNavBarInternalAgreementProps) => {
  const dispatch = useAppDispatch()
  const currentSchema = useTypedSelector(currentSchemaSelector)

  useEffect(() => {
    const approvedProcedures = companyProcedures.flatMap(schema => schema.logs).filter(innermap => innermap.approved).map(schemas => (schemas.innermap.id))
    dispatch(setApprovedProcedure(approvedProcedures))
    const updatedSchema = companyProcedures.flatMap(schema => schema.logs)
      .find((procedure) => procedure.innermap.id === currentSchema.id)

    updatedSchema && dispatch(setCurrentSchema(updatedSchema.innermap))
  }, [companyProcedures])

  return (
    <Stack spacing={1.5}>
      {companyProcedures.map((procedures) => (
        <CompanySchemes
          key={procedures.company}
          company={procedures.company}
          procedures={procedures.logs}
        />
      ))}
    </Stack>
  )
}