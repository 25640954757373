import styled from 'styled-components'
import { Button, Stack } from '@mui/material'

export const CardStatisticsWrapper = styled(Stack)`
  flex: 1;
  gap: 16px;

  & .MuiDivider-root {
    height: 1px;
  }
`

interface PhaseChangeButtonProps {
  checked: boolean;
}

export const PhaseChangeButton = styled(Button)<PhaseChangeButtonProps>`
  height: 32px;
  max-width: 70px;
  border: ${props => `1px solid ${props.theme.palette.primary.main}`} !important;

  &,
  &:hover {
    background-color: ${props => !props.checked && props.theme.palette.bg.shades} !important;
    border: ${props => !props.checked && `1px solid ${props.theme.palette.bg.gray}`} !important;
    color: ${props => !props.checked && props.theme.palette.secondary.dark} !important;
  }
`
