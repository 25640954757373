import styled from 'styled-components'
import { Box, Stack } from '@mui/material'

export const ExportArchiveDrawerFormWrapper = styled(Box)`
  height: calc(100% - 66px);
  padding: 20px;
`

export const ExportArchiveDrawerSwitchesWrapper = styled(Stack)`
  flex: 1;
  flex-direction: row;
  gap: 8px;
  
  & .MuiFormControlLabel-root {
    margin-left: 0;
  }

  & .MuiCheckbox-root {
    margin-right: 0;
  }

  & .MuiButtonBase-root {
    padding: 6px;
  }

  & .MuiSvgIcon-root {
    font-size: 20px !important;
  }

  & .MuiTypography-root {
    font-size: 14px;
    letter-spacing: 0;
  }
`
