import React, { FC, MouseEvent } from 'react'
import { StatusItemModalProps } from './StatusItemModal.types'
import { Divider, Stack, Typography } from '@mui/material'
import {
  StatusItemPopover,
  StatusItemPopoverCloseButton,
  StatusItemPopoverPercentage,
  StatusItemPopoverStatWrapper,
  StatusItemPopoverSubtitle,
  StatusItemPopoverText,
  StatusItemPopoverTitle
} from './StatusItemModal.styles'
import PersonSharpIcon from '@mui/icons-material/PersonSharp'
import Tooltip from '../../../../../../components/Tooltip'
import { useGetProjectStatusStatisticsQuery } from '../../../../../../api/projectAgreement'
import {
  PocketViewTomCardAvatarWrapper
} from '../../../../../PocketView/components/PocketViewTomCard/PocketViewTomCard.styles'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { getWordWithEnding } from '../../../../../../utils/getWordWithEnding'
import Progress from '../../../../../../components/Progress'

export const StatusItemModal: FC<StatusItemModalProps> = ({
  statisticModalData,
  onClose,
}) => {
  const {
    anchorEl,
    projectId,
    phase,
    status,
    iiType,
    color = 'blue',
    title,
    percentage,
    value,
  } = statisticModalData || {}
  const onClick = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const { data, isFetching } = useGetProjectStatusStatisticsQuery({
    projectId: projectId!,
    phase: phase!,
    status: status!,
    iiType: iiType!,
  }, { skip: !statisticModalData })

  const { count, stages = [] } = data || {}

  return (
    <StatusItemPopover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClick={onClick}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {isFetching
        ? <Progress />
        : (
          <Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} pr={2.5}>
              <Stack direction='row' alignItems='center' spacing={1.25}>
                <StatusItemPopoverPercentage backgroundColor={color}>
                  <Typography variant='subtitle2'>
                    {percentage} %
                  </Typography>
                </StatusItemPopoverPercentage>
                <StatusItemPopoverTitle>
                  {title}: {value}
                </StatusItemPopoverTitle>
              </Stack>

              <StatusItemPopoverCloseButton onClick={onClose}>
                <HighlightOffOutlinedIcon />
              </StatusItemPopoverCloseButton>
            </Stack>

            <Stack my={2.5} pr={2.5}>
              <StatusItemPopoverSubtitle>
                В основной схеме согласования участвует: {getWordWithEnding(count?.inside || 0, 'том', 'тома', 'томов')}
              </StatusItemPopoverSubtitle>
              <StatusItemPopoverSubtitle>
                Не участвуют в схеме согласования: {getWordWithEnding(count?.outside || 0, 'том', 'тома', 'томов')}
              </StatusItemPopoverSubtitle>
              {!!count?.complete && (
                <StatusItemPopoverSubtitle>
                  Прошли процедуру согласования: {getWordWithEnding(count.complete, 'том', 'тома', 'томов')}
                </StatusItemPopoverSubtitle>
              )}
            </Stack>

            <ScrollableContainer maxHeight={220} spacing={1.25} divider={<Divider />}>
              {stages.map(stage => (
                <Stack direction='row' alignItems='center' spacing={1} key={`${stage.name} ${stage.count.now}`}>
                  <Stack flex={1} spacing={0.5}>
                    <StatusItemPopoverText>{stage.name}</StatusItemPopoverText>

                    <Stack direction='row' alignItems='center' spacing={1.5}>
                      <PocketViewTomCardAvatarWrapper>
                        {stage.person?.avatar
                          ? <img src={stage.person.avatar} width={32} height={32} alt='Аватар' />
                          : <PersonSharpIcon fontSize='medium' />
                        }
                      </PocketViewTomCardAvatarWrapper>

                      <Stack maxWidth={200} flex={1}>
                        <Tooltip title={stage.person?.company}>
                          <StatusItemPopoverSubtitle>{stage.person?.company || '—'}</StatusItemPopoverSubtitle>
                        </Tooltip>
                        <Tooltip title={stage.person?.name}>
                          <StatusItemPopoverSubtitle>{stage.person?.name || '—'}</StatusItemPopoverSubtitle>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Stack>
                  <StatusItemPopoverStatWrapper>
                    <Typography className='value'>
                      {stage.count.past}
                    </Typography>
                    <Typography className='title'>
                      прошли
                    </Typography>
                  </StatusItemPopoverStatWrapper>
                  <StatusItemPopoverStatWrapper>
                    <Typography className='value'>
                      {stage.count.now}
                    </Typography>
                    <Typography className='title'>
                      на этапе
                    </Typography>
                  </StatusItemPopoverStatWrapper>
                </Stack>
              ))}
            </ScrollableContainer>
          </Stack>
        )
      }
    </StatusItemPopover>
  )
}
