import qs from 'qs'

import { setTom } from '../../../store/slices/documentsPages/tom'
import { ExcelUploadResponse } from '../../../types/global'
import { api } from '../../api'
import { ExportObjectsRequest } from '../../rdPhase'
import {
  CreateTomIrdRequest,
  DeleteTomIrdRequest,
  EditTomIrdRequest,
  GetTomIrdByIdRequest,
  GetTomIrdLinkExampleResponse,
  GetTomsIrdFullRequest,
  GetTomsIrdFullResponse,
  GetTomsIrdRequest,
  GetTomsIrdResponse,
  ITerminateTomIrdRequest,
  ITerminateTomIrdResponse,
  TomIrdBaseResponse,
  TomIrdMultiUploadRequest,
  TomIrdMultiUploadResponse,
  UploadTomIrdRequest,
} from './tomApi.types'

export const tomIrdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomsIrd: build.query<GetTomsIrdResponse, GetTomsIrdRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-ird/list?${qs.stringify(params, { indices: false })}`,
        method: 'GET',
      }),
      providesTags: [
        'TomIrd',
        { type: 'TomIrd', id: 'UPDATE' },
        { type: 'TomIrd', id: 'UPLOAD' },
        { type: 'TomIrd', id: 'DELETE' },
        { type: 'TomIrd', id: 'UPLOAD_MASS' },
        { type: 'TomIrd', id: 'ARCHIVE_TOMS' },
      ],
    }),
    getTomsIrdFull: build.query<GetTomsIrdFullResponse, GetTomsIrdFullRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-ird/list-full?${qs.stringify(params, { indices: false })}`,
        method: 'GET',
      }),
      providesTags: [
        'TomIrd',
        { type: 'TomIrd', id: 'UPDATE' },
        { type: 'TomIrd', id: 'UPLOAD' },
        { type: 'TomIrd', id: 'DELETE' },
        { type: 'TomIrd', id: 'UPLOAD_MASS' },
        { type: 'TomIrd', id: 'ARCHIVE_TOMS' },
      ],
    }),
    createTomIrd: build.mutation<TomIrdBaseResponse, CreateTomIrdRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/tom-ird/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'TomIrd', id: 'UPDATE' }, { type: 'Projects', id: 'dashboard' }],
    }),
    uploadTomIrd: build.mutation<ExcelUploadResponse, UploadTomIrdRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${id}/tom-ird/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
    }),
    exportTomsIrd: build.mutation<Blob, ExportObjectsRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom-ird/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    tomIrdMultiUpload: build.mutation<TomIrdMultiUploadResponse, TomIrdMultiUploadRequest>({
      query: ({ id, files }) => {
        const formData = new FormData()
        files.forEach((file) => {
          formData.append('file', file)
        })

        return {
          url: `/project/${id}/tom-ird/mass-update`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: [{ type: 'TomIrd', id: 'UPLOAD_MASS' }],
    }),
    getTomIrdById: build.query<TomIrdBaseResponse, GetTomIrdByIdRequest>({
      query: ({ id }) => ({
        url: `/project/tom-ird/${id}/get`,
        method: 'GET',
      }),
      providesTags: ['TomIrd', { type: 'TomIrd', id: 'UPLOAD' }],
    }),
    editTomIrd: build.mutation<TomIrdBaseResponse, EditTomIrdRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/tom-ird/${id}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'TomIrd', id: 'UPDATE' }, { type: 'Projects', id: 'dashboard' }],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTomData } = await queryFulfilled
          const updatedTom = updatedTomData.data
          if (updatedTom) {
            dispatch(setTom({ tom: updatedTom }))

            // getTomIrdById
            dispatch(
              tomIrdApi.util.updateQueryData('getTomIrdById', { id: updatedTom.id }, (draft) => {
                draft.data = updatedTom
              }),
            )
          }
        } catch {}
      },
    }),
    deleteTomIrd: build.mutation<TomIrdBaseResponse, DeleteTomIrdRequest>({
      query: ({ id }) => ({
        url: `/project/tom-ird/${id}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(tomIrdApi.util.invalidateTags([{ type: 'TomIrd', id: 'DELETE' }, { type: 'Projects', id: 'dashboard' }]))
        } catch {}
      },
    }),
    getTomIrdLinkExample: build.mutation<GetTomIrdLinkExampleResponse, void>({
      query: () => ({
        url: `/project/tom-ird/link-example`,
        method: 'GET',
      }),
    }),
    terminateTomIrd: build.mutation<ITerminateTomIrdResponse, ITerminateTomIrdRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/toms/${tomId}/features/annull`,
        method: 'POST',
        params,
      }),
      async onQueryStarted({ tomId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTomData } = await queryFulfilled
          const updatedAnnulment = updatedTomData.annulment

          // getTomIrdById
          dispatch(
            tomIrdApi.util.updateQueryData('getTomIrdById', { id: tomId }, (draft) => {
              draft.data.features.annulment = updatedAnnulment
            }),
          )
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomsIrdQuery,
  useGetTomsIrdFullQuery,
  useCreateTomIrdMutation,
  useUploadTomIrdMutation,
  useExportTomsIrdMutation,
  useTomIrdMultiUploadMutation,
  useGetTomIrdByIdQuery,
  useEditTomIrdMutation,
  useDeleteTomIrdMutation,
  useGetTomIrdLinkExampleMutation,
  useTerminateTomIrdMutation,
} = tomIrdApi
