import React, { FC } from 'react'
import { ProjectAgreementAdviceProps } from './ProjectAgreementAdvice.types'
import { ProjectAgreementAdviceText, ProjectAgreementAdviceWrapper } from './ProjectAgreementAdvice.styles'
import InfoIcon from '../../../../../assets/icons/InfoIcon'

export const ProjectAgreementAdvice: FC<ProjectAgreementAdviceProps> = ({ data, ...props }) => {
  const { text, backgroundColor } = data

  return (
    <ProjectAgreementAdviceWrapper backgroundColor={backgroundColor} {...props}>
      <InfoIcon />

      <ProjectAgreementAdviceText variant='body2' parentBackgroundColor={backgroundColor}>
        {text || ''}
      </ProjectAgreementAdviceText>
    </ProjectAgreementAdviceWrapper>
  )
}
