import React, { useMemo } from 'react'
import { UseGetPhaseInfoProps, UseGetPhaseInfoResponse } from './useGetPhaseInfo.types'
import { InfoCardMediumVariant } from '../../../InfoCard'
import { Stat } from '../../../../../../types/project'
import { setSelectedProjectPhase } from '../../../../../../store/slices/documentsPages/projectInfo'
import { useAppDispatch } from '../../../../../../store/store'
import { FilterData } from '../../../../../../store/slices/documentsPages/filter'

export const useGetPhaseInfo = ({
  variant,
  statRd,
  statPd,
  statIi,
  statIrd,
  statTim
}: UseGetPhaseInfoProps): UseGetPhaseInfoResponse => {
  const dispatch = useAppDispatch()

  const { toProd } = statRd || {}

  const setProjectPhase: Record<InfoCardMediumVariant, () => void> = {
    'rd': () => dispatch(setSelectedProjectPhase({ selectedProjectPhase: 'Рабочая документация' })),
    'pd': () => dispatch(setSelectedProjectPhase({ selectedProjectPhase: 'Проектная документация' })),
    'ii': () => dispatch(setSelectedProjectPhase({ selectedProjectPhase: 'Инженерные изыскания' })),
    'ird': () => dispatch(setSelectedProjectPhase({ selectedProjectPhase: 'Сбор исходных данных' })),
    'tim': () => undefined
  }

  const emptyFilterData: FilterData = useMemo(() => {
    return {
      selectedObjectsIds: [],
      selectedRdsIds: [],
      selectedPdsIds: [],
      selectedIisIds: [],
      selectedIrdsIds: [],
      selectedDocs: [],
      isExpired: false,
      iiType: 'Технический отчет',
    }
  }, [])

  const statData: Record<InfoCardMediumVariant, Stat> = {
    'rd': statRd!,
    'pd': statPd!,
    'ii': statIi!,
    'ird': statIrd!,
    'tim': statTim!
  }
  return {
    statData: statData[variant],
    toProd,
    setProjectPhase: setProjectPhase[variant],
    emptyFilterData,
  }
}
