import styled from 'styled-components'
import { Autocomplete } from '@mui/material'

export const StyledAutocompleteField = styled(Autocomplete)`
  background-color: ${props => props.theme.palette.bg.white};

  & .MuiOutlinedInput-root {
    padding: 6px 16px;
    padding-right: 32px !important;
    padding-left: 10px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    text-align: right;
    color: ${props => props.theme.palette.text.dark};
    background-color: ${props => props.theme.palette.bg.shades};
  }

  & .MuiOutlinedInput-input {
    padding: 0 !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.1);
  }
  
  & .MuiAutocomplete-endAdornment {
    top: calc(50% - 11px);
    right: 8px;

    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.text.dark};
      font-size: 16px;
    }
  }

  & .MuiInputLabel-root {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${props => props.theme.palette.disabled};
    transform: translate(14px, 5px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(14px, -9px) scale(0.75);
    }
  }

  & .MuiFormHelperText-root {
    margin-right: 0;
    margin-left: 0;
    text-align: right;
  }
`