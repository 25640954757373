import styled from 'styled-components'

import FieldForm from '../../../../../../components/FieldForm'
import {
  StyledExportArchiveAccordion,
  StyledExportArchiveAccordionDetails,
  StyledExportArchiveAccordionSummary,
} from '../../../../../Docs/components/ExportArchiveDrawer/components/ExportArchiveAccordion/styles'

export const StyledFullExportAccordion = styled(StyledExportArchiveAccordion)``

export const StyledFullExportAccordionSummary = styled(StyledExportArchiveAccordionSummary)``

export const StyledFullExportAccordionDetails = styled(StyledExportArchiveAccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const AccordionSubCheckbox = styled(FieldForm)``
