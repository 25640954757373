import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { ReactElement, useCallback, useState } from 'react'

import { RemarkMenuFilter } from '../../../../../api/remarks/types'
import BookIcon from '../../../../../assets/icons/BookIcon'
import EditDocIcon from '../../../../../assets/icons/EditDocIcon'
import MultiBookIcon from '../../../../../assets/icons/MultiBookIcon'
import ObjectsIcon from '../../../../../assets/icons/ObjectsIcon'
import { selectedProjectPhaseSelector } from '../../../../../store/slices/documentsPages/projectInfo'
import { setRemarkGroupFilter } from '../../../../../store/slices/remarks/remarks'
import { remarkGroupFilterSelector } from '../../../../../store/slices/remarks/selectors/remarks.selectors'
import { useAppDispatch, useTypedSelector } from '../../../../../store/store'
import { ProjectPhase } from '../../../../../types/project'
import { COMMON_DOCS_NUMBER } from '../../../../../utils/constants'
import { AccordionItem } from '../../../../Docs/components/ObjectAccordion/ObjectAccordion.types'
import { NavbarItem } from '../../../../Docs/hooks/useGetNavbarItems'

export const useGetRemarkFilterGroups = (remarkGroups: RemarkMenuFilter | undefined) => {
  const dispatch = useAppDispatch()

  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const groupFilter = useTypedSelector(remarkGroupFilterSelector)

  const [expanded, setExpanded] = useState<number | false>(groupFilter.obj || false)
  const onExpand = useCallback((panelId: number, isExpanded: boolean) => {
    setExpanded(isExpanded ? false : panelId)
  }, [])

  // Rd
  const onObjectClick = (objectId: number) => {
    dispatch(
      setRemarkGroupFilter({
        remarkGroupFilter: { ...groupFilter, second: groupFilter.second, obj: objectId, group: null },
      }),
    )
  }

  const onRdClick = (objectId: number, rdId: number) => {
    dispatch(
      setRemarkGroupFilter({
        remarkGroupFilter: { ...groupFilter, second: groupFilter.second, obj: objectId, group: rdId },
      }),
    )
  }
  const rdItems: NavbarItem[] = remarkGroups?.objects
    ? remarkGroups.objects?.map((object) => {
        const item: AccordionItem = {
          id: object.id,
          title: `${object.number} — ${object.name}`,
          hasSubItems: object.groups.length > 0,
          subItemsData: {
            onSubItemClick: (e, objectId: number, rdId: number) => {
              e.stopPropagation()
              onRdClick(objectId, rdId)
            },
            icon: <EditDocIcon fontSize='small' color='dark' />,
            subItems: object.groups?.map((rd) => ({
              id: rd.id,
              title: rd.name,
            })),
          },
        }

        const commonObject = object.number === COMMON_DOCS_NUMBER

        return {
          item: item,
          id: item.id,
          onItemClick: onObjectClick,
          expanded: expanded,
          onExpand: onExpand,
          icon: commonObject ? (
            <BookIcon fontSize='small' color='primary' />
          ) : (
            <ObjectsIcon fontSize='small' color='primary' />
          ),
        }
      })
    : []

  // Pd
  const onPdClick = useCallback(
    (pdId: number) => {
      dispatch(
        setRemarkGroupFilter({
          remarkGroupFilter: { ...groupFilter, second: groupFilter.second, obj: null, group: pdId },
        }),
      )
    },
    [groupFilter],
  )

  const pdItems: NavbarItem[] = remarkGroups?.groups
    ? remarkGroups.groups.map((pd) => {
        const item: AccordionItem = {
          id: pd.id,
          title: pd.name,
          hasSubItems: false,
        }

        return {
          item: item,
          id: item.id,
          onItemClick: onPdClick,
          expanded: expanded,
          onExpand: onExpand,
          icon: <DescriptionOutlinedIcon fontSize='small' color='primary' />,
        }
      })
    : []

  // Ii
  const onIiClick = useCallback(
    (iiId: number) => {
      dispatch(
        setRemarkGroupFilter({
          remarkGroupFilter: { ...groupFilter, second: groupFilter.second, obj: null, group: iiId },
        }),
      )
    },
    [groupFilter],
  )

  const iiItems: NavbarItem[] = remarkGroups?.groups
    ? remarkGroups?.groups.map((ii) => {
        const item: AccordionItem = {
          id: ii.id,
          title: ii.name,
          hasSubItems: false,
        }

        return {
          item: item,
          id: item.id,
          onItemClick: onIiClick,
          expanded: expanded,
          onExpand: onExpand,
          icon: <DescriptionOutlinedIcon fontSize='small' color='primary' />,
        }
      })
    : []

  // Ird
  const onIrdClick = useCallback(
    (irdId: number) => {
      dispatch(
        setRemarkGroupFilter({
          remarkGroupFilter: { ...groupFilter, second: groupFilter.second, obj: null, group: irdId },
        }),
      )
    },
    [groupFilter],
  )

  const irdItems: NavbarItem[] = remarkGroups?.groups
    ? remarkGroups?.groups.map((ird) => {
        const item: AccordionItem = {
          id: ird.id,
          title: ird.name,
          hasSubItems: false,
        }

        return {
          item: item,
          id: item.id,
          onItemClick: onIrdClick,
          expanded: expanded,
          onExpand: onExpand,
          icon: <DescriptionOutlinedIcon fontSize='small' color='primary' />,
        }
      })
    : []

  const navbarItems: Record<ProjectPhase, NavbarItem[] | null> = {
    'Рабочая документация': rdItems,
    'Проектная документация': pdItems,
    'Инженерные изыскания': iiItems,
    'Сбор исходных данных': irdItems,
  }

  const navButtonIcon: Record<ProjectPhase, ReactElement> = {
    'Рабочая документация': <ObjectsIcon />,
    'Проектная документация': <MultiBookIcon />,
    'Инженерные изыскания': <MultiBookIcon />,
    'Сбор исходных данных': <MultiBookIcon />,
  }

  return {
    navbarItems: navbarItems[selectedProjectPhase],
    navButtonIcon: navButtonIcon[selectedProjectPhase],
  }
}
