import { FC } from 'react'
import { IrdStatisticsProps, NOT_IN_WORK_PERCENT } from './IrdStatistics.types'
import { IrdStatisticsItem, IrdStatisticsItemBarProps } from '../../components/IrdStatisticsItem'
import { Stack } from '@mui/material'
import { IrdStatistScrollableContainer } from './IrdStatistics.styles'
import { IrdLegendItemProps } from '../../components/IrdLegendItem/IrdLegendItem.types'
import { IrdLegendItem } from '../../components/IrdLegendItem/IrdLegendItem'
import { IrdTooltipTitle } from '../../components/IrdTooltipTitle'

export const IrdStatistics: FC<IrdStatisticsProps> = ({ data }) => {
  const { groups } = data

  const legendItems: IrdLegendItemProps[] = [
    { title: 'Выполнено', color: 'lightgreen' },
    { title: 'В работе', color: 'blue' },
    { title: 'Не в работе', color: 'gray' },
  ]

  return (
    <Stack spacing={1.5}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1.25}>
        {legendItems.map(item => (
          <IrdLegendItem {...item} />
        ))}
      </Stack>

      <IrdStatistScrollableContainer spacing={1.5}>
        {groups.map(({ group, done, inWork, notInWork, totalCount }) => {
          const title = group.fullName
          const donePercent = done.percent
          const inWorkPercent = inWork.percent

          const percentValue = donePercent

          const bars: IrdStatisticsItemBarProps[] = [
            { widthPercentage: donePercent, bgColor: 'lightgreen', priority: 3 },
            { widthPercentage: donePercent + inWorkPercent, bgColor: 'blue', priority: 2 },
            { widthPercentage: NOT_IN_WORK_PERCENT, bgColor: 'gray', priority: 1 },
          ]

          return (
            <IrdStatisticsItem
              title={title}
              percentValue={percentValue}
              bars={bars}
              tooltipTitle={<IrdTooltipTitle
                title={title}
                total={totalCount}
                done={done}
                inWork={inWork}
                notInWork={notInWork}
              />}
            />
          )
        })}
      </IrdStatistScrollableContainer>
    </Stack>
  )
}
