import { EmployeeProjectProfile } from 'api/users/types'

import { FilterValues } from '../../pages/DocView/components/DocViewRightSideBar'
import { ProjectPhaseEn } from '../../types/project'
import { TomStatus, TomType } from '../../types/tom'
import { UserRolesEn } from '../../types/user'
import { Annotation } from '@pages/DocView/components/PragmaPdfViewer'

export interface RemarkDataSend {
  change?: number | null
  comment: string
  page: number | string | null
  reason: string | null
  type: string | null
}

export interface RemarkAuthor {
  avatar: string | null
  company: string | null
  id: number
  name: string
  position: string | null
  role: UserRolesEn
}

export type RemarkStatus = 'created' | 'submitted' | 'accepted' | 'rejected' | 'resolved' | 'closed' | 'cancelled'
export const remarkStatuses: RemarkStatus[] = [
  'created',
  'submitted',
  'accepted',
  'rejected',
  'resolved',
  'closed',
  'cancelled',
]

export interface RemarkLinkedTom {
  id: number
  title: string
  type: TomType
  version: number
}

export interface RemarkProject {
  remark: RemarkInfo
  remarkMsg: RemarkMessage
  responseMsg: RemarkMessage
  tom: RemarkLinkedTom
}

export interface Remark {
  canAddMessage: boolean
  messages: RemarkMessages[]
  remark: RemarkInfo
  mails: RemarkMail[]
}
export interface TotalRemakStatus extends Record<RemarkStatus | 'all', number> {}
export interface RemarkWidget extends Record<ProjectPhaseEn, TotalRemakStatus> {}

export interface AmountRemarks extends Record<FilterValues, number> {}

export interface MessageAttachment {
  fileSize: number
  id: number
  name: string
}

export interface RemarkMail {
  attachment: Omit<MessageAttachment, 'id'>
  excel: Omit<MessageAttachment, 'id'>
  mail: RemarkMailData
}

export interface RemarkMailData {
  id: number
  createdAt: string
  creator: EmployeeProjectProfile
  isAutoSent: boolean
  mailDate: string
  mailNum: string
  responsible?: EmployeeProjectProfile
  responsibleCompany: string
}

export interface RemarkMessages {
  attachments: MessageAttachment[]
  message: RemarkMessage
  marks?: Mark[]
  mail: RemarkMailData | null
}

export interface RemarkMessage {
  author: RemarkAuthor
  comment: string | null
  createdAt: string
  id: number
  reason: string | null
  status: RemarkStatus
  version: number
}

export interface RemarkInfo {
  author: RemarkAuthor
  change: number | null
  createdAt: string
  updatedAt: string
  id: number
  isSecond: boolean
  page: number | null
  totalPages: number | null
  responsible: string
  status: RemarkStatus
  type: string | null
}

export interface ReplyMessage {
  comment: string | null
  reason: string | null
  status: RemarkStatus
}

export interface RemarkMenuGroup {
  id: number
  name: string
}

export interface RemarkMenuObject {
  groups: RemarkMenuGroup[]
  id: number
  name: string
  number: string
}

export interface RemarkMenuFilter {
  groups: RemarkMenuGroup[]
  objects: RemarkMenuObject[]
}

export interface RemarkTom {
  id: number
  title: string
}

export interface RemarkMailItems {
  groups: RemarkMailGroup[]
  objects: RemarkMailObject[]
}

export interface RemarkAuthorFilter {
  company: string
  persons: RemarkCompanyPersonFilter[]
}

export interface RemarkCompanyPersonFilter {
  id: number
  name: string
}

export interface RemarkMailGroup {
  name: string
  toms: RemarkMailTom[]
}

export interface RemarkMailObject {
  groups: RemarkMailGroup[]
  name: string
  number: string
}

export interface RemarkMailTom {
  id: number
  name: string
  status: TomStatus
}

export interface RemarkMailDataSend {
  mailDate: string
  mailNum: string
  responsibleCompany: string
  responsibleId: number | null
  toms: number[]
}

export interface RemarkAnnotation extends Omit<Annotation, 'id'> {}

export interface Mark {
  id: number | null
  annotation: RemarkAnnotation
}

