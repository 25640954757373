import { Box, IconButton, Typography } from '@mui/material'
import styled from 'styled-components'

export const Level = styled(IconButton)<{ arrowClose: boolean }>`
  padding: 1px;

  svg {
    transition: rotate linear 0.2s;
    transform: ${(props) => (props.arrowClose ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`

export const StyledIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.bg.white};
`

export const CreateIconContainer = styled(Box)`
  width: 17px;
  height: 17px;
  display: flex;
  border-radius: 50%;
  margin-left: 4px;
  background-color: ${(props) => props.theme.palette.warning.light};

  svg {
    margin: auto;
    width: 13px;
    height: 13px;

    & path {
      fill: ${(props) => props.theme.palette.text.light}!important;
    }
  }
`
export const LabelText = styled(Typography)`
  color: ${(props) => props.theme.palette.table.orange};
  text-align: center;
`
