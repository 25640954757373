import MessageIcon from '@mui/icons-material/Message';
import { IconButton } from '@mui/material';
import { useAppDispatch } from '../../../../store/store';
import { setOpenedDrawer } from '../../../../store/slices/documentsPages/drawerInfo';
import { ICellRendererParams } from 'ag-grid-community';
import { setRemarkHistoryData } from '../../../../store/slices/remarks/remarks';
import { RowsData } from '../RemarksTable/RemarksTable.types';

export const HistoryButton = (params: ICellRendererParams) => {
  const { index, remark }: RowsData = params.data
  const dispatch = useAppDispatch()

  const onHistoryButtonClick = () => {
    dispatch(setRemarkHistoryData({remarkHistoryData: {remarkId: remark.remarkId, index}}))
    dispatch(setOpenedDrawer({ openedDrawer: 'remarksHistory' }))
  }

  return (
    <IconButton onClick={onHistoryButtonClick}>
      <MessageIcon fontSize="small" color="primary" />
    </IconButton>
  )
}