import styled from 'styled-components'
import { StyledRdCardHeader } from '../../../Administration/components/RdCard/styles'
import { Stack, Typography } from '@mui/material'
import { FullUserCardWrapper } from '../../../Administration/AdminUsers/AdminUsers.styles'
import { StyledCardInfo } from '../../../Administration/components/UserCardContent/UserCardInfo/styles'

export interface StyledTomCardProps {
  empty: boolean;
}

export const StyledTomCard = styled(FullUserCardWrapper) <StyledTomCardProps>`
  display: flex;
  flex-direction: column;
  height: 321px;
  background-color: ${props => props.empty ? '#F9FAFF' : props.theme.palette.bg.white};
`

export const StyledTomCardHeader = styled(StyledRdCardHeader) <{ $disabled?: boolean }>`
      & .MuiTypography-root {
    color: ${props => props.$disabled && props.theme.palette.disabled}!important;
  }
`

export const DisabledTitle = styled(Typography)`
  color: ${props => props.theme.palette.disabled};
  font-size: 12px;
`

interface TomColoredInfoProps {
  customColor?: 'secondary' | 'orange' | 'blue' | 'error';
  thin?: boolean;
  smallPadding?: boolean;
  height?: string | number;
}

export const TomColoredInfo = styled(Stack) <TomColoredInfoProps>`
  height: ${props => props.height ? props.height : '21px'};
  padding: ${props => props.smallPadding ? '2px 6px' : '2px 8px'};
  border-radius: 4px;

  background-color: ${props => props.customColor === 'secondary' ? props.theme.palette.bg.shades : ''};
  background-color: ${props => props.customColor === 'orange' ? 'rgba(255, 205, 75, 0.15)' : ''};
  background-color: ${props => props.customColor === 'blue' ? props.theme.palette.primary.main : ''};
  background-color: ${props => props.customColor === 'error' ? props.theme.palette.bg.white : ''};

  & .MuiTypography-root {
    font-weight: ${props => props.thin ? 500 : 700};
    color: ${props => props.customColor === 'secondary' ? props.theme.palette.secondary.dark : ''};
    color: ${props => props.customColor === 'orange' ? props.theme.palette.error.main : ''};
    color: ${props => props.customColor === 'blue' ? props.theme.palette.text.light : ''};
    color: ${props => props.customColor === 'error' ? props.theme.palette.error.main : ''};
  }

  & svg {
    & path {
      fill: ${props => props.customColor === 'secondary' ? props.theme.palette.secondary.dark : ''};
      fill: ${props => props.customColor === 'orange' ? props.theme.palette.error.main : ''};
      fill: ${props => props.customColor === 'blue' ? props.theme.palette.text.light : ''};
      fill: ${props => props.customColor === 'error' ? props.theme.palette.error.main : ''};
    }
  }
`


export const StyledTomCardContent = styled(StyledCardInfo) <{ $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
    & .MuiTypography-root {
    color: ${props => props.$disabled && props.theme.palette.disabled}!important;
  }
`

export interface StyledTomCardInfoWrapperProps {
  dateExpired: boolean;
}

export const StyledTomCardInfoWrapper = styled(Stack) <StyledTomCardInfoWrapperProps>`
  & .row-value {
    height: 22px;
    padding: ${props => props.dateExpired ? '2px 6px' : 0};
  }

  & .status-wrapper {
    height: 22px;
    padding: 0;
  }
`

export interface TomInProdStatusWrapperProps {
  size?: 'thin' | 'middle'
}

export const TomInProdStatusWrapper = styled(Stack) <TomInProdStatusWrapperProps>`
  min-height: 40px;
  max-height: 40px;
  min-height: ${props => props.size === 'thin' ? '30px' : ''};
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 6px;

  & .MuiTypography-root {
    color: ${props => props.theme.palette.success.main};
  }

`

export const CardText = styled(Typography)`
  display: -webkit-box;
  overflow-wrap: anywhere;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-pack: center;
  -webkit-box-orient: vertical;
`