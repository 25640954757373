import { format as FnsFormat, isDate } from 'date-fns'

export function parseDateString(value: any, originalValue: any) {
  if (typeof originalValue === 'string') return undefined

  if (!originalValue?.getFullYear()) return undefined

  try {
    const firstFormattedDate = FnsFormat(originalValue, 'MM/dd/yy')
    const receivedYear = firstFormattedDate.split('/')[2]
    const finalYear = receivedYear.length === 2 ? `20${receivedYear}` : receivedYear
    const secondFormattedDate = [...firstFormattedDate.split('/').slice(0, 2), finalYear]

    const formattedDate = new Date(secondFormattedDate.join('/'))
    const parsedDate = isDate(formattedDate) ? formattedDate : null

    return parsedDate
  } catch {
    return null
  }
}

export const formatDateToString = (date: Date, format: string): string => {
  if (!date) return ''
  const parsedDate = parseDateString('', date)

  if (!parsedDate) return ''
  return FnsFormat(parsedDate, format)
}

export const formatToDate = (dateString: string | null | undefined, format?: string): Date | null => {
  if (!dateString) return null

  const splitedDate = dateString.split('.')
  const formattedDate = [splitedDate[1], splitedDate[0], splitedDate[2]].join('/')
  return new Date(formattedDate)
}

export const formatToShortYear = (date: string) => {
  const preShortDate = date.split('.')
  if (preShortDate.length !== 3) return date

  const shortYear = preShortDate[2].slice(2, 5)
  return [...preShortDate.slice(0, 2), shortYear].join('.')
}

interface DateResponse {
  date: string;
  dateShortYear: string;
  time: string;
  fullTime: string;
}

export const parseResponseDate = (fullDate: string): DateResponse => {
  if (!fullDate) return {} as DateResponse

  const splittedDate = fullDate.split(' ')
  const date = splittedDate[0]

  const dateShortYear = formatToShortYear(date)

  const fullTime = splittedDate[1]
  const time = fullTime.split(':').slice(0, 2).join(':')

  return {
    date,
    dateShortYear,
    time,
    fullTime,
  }
}