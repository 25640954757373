import React, { FC } from 'react'
import { DefaultStatisticsProps } from './DefaultStatistics.types'
import { Box, Stack, Typography } from '@mui/material'
import { StatusItem, StatusItemData } from '../../components/StatusItem'
import Divider from '../../../../../../components/Divider'
import { theme } from '../../../../../../styles/theme'
import { StatisticsBar, StatisticsBarGroupData } from '../../components/StatisticsBar'
import { ExpertiseItemWrapper } from './DefaultStatistics.styles'
import InfoIcon from '../../../../../../assets/icons/InfoIcon'
import { isRdStatistics } from '../../../../../../api/projects/types'

export const DefaultStatistics: FC<DefaultStatisticsProps> = ({
  data,
  onStatisticHeaderClick,
  withExpertise,
  expertise,
}) => {
  const { agreed, inWork, agreement, development, correction, notInWork, totalCount } = data

  const statisticsData: StatusItemData[] = [
    {
      title: 'Согласовано',
      value: agreed?.count,
      percentage: Math.round(agreed?.percent),
      color: 'lightgreen',
      clickable: agreed?.clickable,
      subItems: isRdStatistics(data)
        ? [
          { title: 'В производстве', value: data.inProd?.count, color: 'olive' },
          { title: 'Не передано', value: data.notInProd?.count, color: 'lightblue' },
        ]
        : []
    },
    {
      title: 'На согласовании',
      value: agreement?.count,
      percentage: Math.round(agreement?.percent),
      color: 'purple',
      clickable: agreement?.clickable,
    },
    {
      title: 'В работе',
      value: inWork?.count,
      percentage: Math.round(inWork?.percent),
      color: 'blue',
      clickable: inWork?.clickable,
      subItems: [
        { title: 'Корректировка', value: correction?.count, color: 'red' },
        { title: 'Разработка', value: development?.count, color: 'yellow' },
      ]
    },
    {
      title: 'Не в работе',
      value: notInWork?.count, percentage: Math.round(notInWork?.percent),
      color: 'disabled',
      clickable: notInWork?.clickable,
    },
  ]

  const statisticsBarData: StatisticsBarGroupData[] = [
    {
      percent: agreed?.percent,
      backgroundColor: 'lightgreen',
      ...(isRdStatistics(data) && data.inProd?.count
          ? {
            items: [
              { percent: data.inProd?.percent, backgroundColor: 'lightOlive' },
              { percent: data.notInProd?.percent, backgroundColor: 'lightblue' },
            ]
          }
          : { empty: true, typographyProps: { color: theme.palette.text.light }, }
      )
    },
    {
      percent: agreement?.percent,
      backgroundColor: 'purple',
      empty: true,
    },
    {
      percent: inWork?.percent,
      backgroundColor: 'blue',
      items: [
        { percent: correction?.percent, backgroundColor: 'red' },
        { percent: development?.percent, backgroundColor: 'yellow' },
      ],
    },
    {
      percent: notInWork?.percent,
      backgroundColor: 'disabled',
      empty: true,
      typographyProps: { color: theme.palette.text.light },
    },
  ]

  const BasicStatistics = (
    <Stack direction='row' width='100%' justifyContent='space-between' alignItems='center'>
      <Stack width='57.8%' spacing={1.25}>
        {statisticsData.map(({ title, value, percentage, color, subItems, clickable }) => (
          <StatusItem
            title={title}
            onStatisticHeaderClick={onStatisticHeaderClick}
            callbackTarget='header'
            value={value}
            percentage={percentage}
            color={color}
            clickable={clickable}
            subItems={subItems}
          />
        ))}

        <Divider />

        <Stack direction='row' justifyContent='space-between' alignItems='center' px={0.75}>
          <Box>
            <Typography
              variant='subtitle2'

              lineHeight='14px'
              color={theme.palette.secondary.dark}
            >
              Общее кол-во документов:
            </Typography>
          </Box>

          <Box>
            <Typography
              variant='subtitle2'
              lineHeight='14px'
              color={theme.palette.secondary.dark}
            >
              {totalCount}
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <StatisticsBar statisticsBarGroups={statisticsBarData} />
    </Stack>
  )

  const WithExpertiseStatistics = (
    <Stack width='100%' justifyContent='space-between' alignItems='center' spacing={3}>
      {BasicStatistics}

      <ExpertiseItemWrapper>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
          <InfoIcon fontSize='medium' />

          <Typography variant='body2'>
            Статус экспертизы:
          </Typography>
        </Stack>

        <Typography variant='body2' color={theme.palette.text.dark}>
          {expertise || 'Не указано'}
        </Typography>
      </ExpertiseItemWrapper>
    </Stack>
  )

  return withExpertise
    ? WithExpertiseStatistics
    : BasicStatistics
}
