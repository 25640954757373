export const measureTextWidth = (text: string): number => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (context) {
    context.font = '500 13px Arial'
    const metrics = context.measureText(text)

    return metrics.width
  }

  return 0
}
