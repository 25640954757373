import { FC } from 'react'
import { IrdStatisticsItemProps } from './IrdStatisticsItem.types'
import { Box, Stack, Typography } from '@mui/material'
import { IrdStatisticsItemBar } from './IrdStatisticsItem.styles'
import { theme } from '../../../../../../styles/theme'
import Tooltip from '../../../../../../components/Tooltip'

export const IrdStatisticsItem: FC<IrdStatisticsItemProps> = ({
  title,
  percentValue,
  bars,
  tooltipTitle,
}) => {
  return (
    <Tooltip title='' customTitle={tooltipTitle}>
      <Stack spacing={0.25} position='relative'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box>
            <Typography variant='body2' color={theme.palette.text.dark}>
              {title}
            </Typography>
          </Box>

          <Box>
            <Typography variant='body2' color={theme.palette.text.dark}>
              {percentValue} %
            </Typography>
          </Box>
        </Stack>

        <Box height={12}>
          {bars.map(bar => (
            <IrdStatisticsItemBar {...bar} />
          ))}
        </Box>
      </Stack>
    </Tooltip>
  )
}
