import styled from "styled-components";

export const StyledTanglViewer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .tgv-toolbar, .tgv-ui {
    z-index: 1;

    div {
      height: 40px;
      min-height: 40px;
    }

    .p-button {
      height: 40px;
      min-height: 40px;
      min-width: 40px;
      width: 40px;
    }
  }

  .tgv-metatree {
    height: 100%;
    width: 100%;
  }
`
export const ViewerCube = styled.div`
  height: 180px;
  width: 180px;
  position: absolute;
  bottom: 0;
  left: 0;
`