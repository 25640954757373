import { api } from '../api'
import {
  AttachmentRequest,
  AttachmentResponse,
  AttachmentUploadRequest,
  AttachmentUploadResponse,
  DeleteAttachmentRequest,
  DeleteAttachmentResponse,
  DownloadAttachmentRequest,
  ExportAttachmentRequest,
  ExportAttachmentResponse,
  ExportAttachmentResponseBack,
  SendAttachmentFileListRequest,
  SendAttachmentFileListResponse,
} from './types'

export const attachmentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    // Rd
    getRdAttachments: build.query<AttachmentResponse, AttachmentRequest>({
      query: ({ docId }) => ({
        url: `/attachments/rd/${docId}/list`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Attachment', id: 'LIST' }],
    }),
    uploadRdAttachment: build.mutation<AttachmentUploadResponse, AttachmentUploadRequest>({
      query: ({ docId, files }) => {
        const formData = new FormData()
        files.forEach(file => {
          formData.append('files', file)
        })

        return {
          url: `/attachments/rd/${docId}/upload`,
          method: 'POST',
          body: formData,
        }
      },
      // invalidatesTags: [{ type: 'Attachment', id: 'LIST' }, 'TomRd', 'TomRdDoc'],
    }),
    getRdAttachmentFileList: build.query<ExportAttachmentResponse, ExportAttachmentRequest>({
      query: ({ tomId }) => ({
        url: `/attachments/rd/tom/${tomId}/get-file-list`,
        method: 'GET',
      }),
      transformResponse: (res: ExportAttachmentResponseBack) => {
        return {
          changes: res.changes.map(change => ({ ...change, docVariant: 'change' })),
          versions: res.versions.map(version => ({ ...version, docVariant: 'version' })),
        }
      },
      providesTags: [{ type: 'Attachment', id: 'LIST' }],
    }),
    sendRdAttachmentFileList: build.mutation<SendAttachmentFileListResponse, SendAttachmentFileListRequest>({
      query: ({ tomId, ...body }) => ({
        url: `/attachments/rd/tom/${tomId}/create-download-link`,
        method: 'POST',
        body,
      }),
    }),

    // Pd
    getPdAttachments: build.query<AttachmentResponse, AttachmentRequest>({
      query: ({ docId }) => ({
        url: `/attachments/pd/${docId}/list`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Attachment', id: 'LIST' }],
    }),
    uploadPdAttachment: build.mutation<AttachmentUploadResponse, AttachmentUploadRequest>({
      query: ({ docId, files }) => {
        const formData = new FormData()
        files.forEach(file => {
          formData.append('files', file)
        })

        return {
          url: `/attachments/pd/${docId}/upload`,
          method: 'POST',
          body: formData,
        }
      },
      // invalidatesTags: [{ type: 'Attachment', id: 'LIST' }, 'TomPd', 'TomPdDoc'],
    }),
    getPdAttachmentFileList: build.query<ExportAttachmentResponse, ExportAttachmentRequest>({
      query: ({ tomId }) => ({
        url: `/attachments/pd/tom/${tomId}/get-file-list`,
        method: 'GET',
      }),
      transformResponse: (res: ExportAttachmentResponseBack) => {
        return {
          changes: res.changes.map(change => ({ ...change, docVariant: 'change' })),
          versions: res.versions.map(version => ({ ...version, docVariant: 'version' })),
        }
      },
      providesTags: [{ type: 'Attachment', id: 'LIST' }],
    }),
    sendPdAttachmentFileList: build.mutation<SendAttachmentFileListResponse, SendAttachmentFileListRequest>({
      query: ({ tomId, ...body }) => ({
        url: `/attachments/pd/tom/${tomId}/create-download-link`,
        method: 'POST',
        body,
      }),
    }),

    // Ii
    getIiAttachments: build.query<AttachmentResponse, AttachmentRequest>({
      query: ({ docId }) => ({
        url: `/attachments/ii/${docId}/list`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Attachment', id: 'LIST' }],
    }),
    uploadIiAttachment: build.mutation<AttachmentUploadResponse, AttachmentUploadRequest>({
      query: ({ docId, files }) => {
        const formData = new FormData()
        files.forEach(file => {
          formData.append('files', file)
        })

        return {
          url: `/attachments/ii/${docId}/upload`,
          method: 'POST',
          body: formData,
        }
      },
      // invalidatesTags: [{ type: 'Attachment', id: 'LIST' }, 'TomPd', 'TomPdDoc'],
    }),
    getIiAttachmentFileList: build.query<ExportAttachmentResponse, ExportAttachmentRequest>({
      query: ({ tomId }) => ({
        url: `/attachments/ii/tom/${tomId}/get-file-list`,
        method: 'GET',
      }),
      transformResponse: (res: ExportAttachmentResponseBack) => {
        return {
          changes: res.changes.map(change => ({ ...change, docVariant: 'change' })),
          versions: res.versions.map(version => ({ ...version, docVariant: 'version' })),
        }
      },
      providesTags: [{ type: 'Attachment', id: 'LIST' }],
    }),
    sendIiAttachmentFileList: build.mutation<SendAttachmentFileListResponse, SendAttachmentFileListRequest>({
      query: ({ tomId, ...body }) => ({
        url: `/attachments/ii/tom/${tomId}/create-download-link`,
        method: 'POST',
        body,
      }),
    }),

    // Ird
    getIrdAttachments: build.query<AttachmentResponse, AttachmentRequest>({
      query: ({ docId }) => ({
        url: `/attachments/ird/${docId}/list`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Attachment', id: 'LIST' }],
    }),
    uploadIrdAttachment: build.mutation<AttachmentUploadResponse, AttachmentUploadRequest>({
      query: ({ docId, files }) => {
        const formData = new FormData()
        files.forEach(file => {
          formData.append('files', file)
        })

        return {
          url: `/attachments/ird/${docId}/upload`,
          method: 'POST',
          body: formData,
        }
      },
      // invalidatesTags: [{ type: 'Attachment', id: 'LIST' }, 'TomPd', 'TomPdDoc'],
    }),
    getIrdAttachmentFileList: build.query<ExportAttachmentResponse, ExportAttachmentRequest>({
      query: ({ tomId }) => ({
        url: `/attachments/ird/tom/${tomId}/get-file-list`,
        method: 'GET',
      }),
      transformResponse: (res: ExportAttachmentResponseBack) => {
        return {
          changes: res.changes.map(change => ({ ...change, docVariant: 'change' })),
          versions: res.versions.map(version => ({ ...version, docVariant: 'version' })),
        }
      },
      providesTags: [{ type: 'Attachment', id: 'LIST' }],
    }),
    sendIrdAttachmentFileList: build.mutation<SendAttachmentFileListResponse, SendAttachmentFileListRequest>({
      query: ({ tomId, ...body }) => ({
        url: `/attachments/ird/tom/${tomId}/create-download-link`,
        method: 'POST',
        body,
      }),
    }),

    // Cmn
    getCmnAttachments: build.query<AttachmentResponse, AttachmentRequest>({
      query: ({ docId }) => ({
        url: `/attachments/cmn/${docId}/list`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Attachment', id: 'LIST' }],
    }),
    uploadCmnAttachment: build.mutation<AttachmentUploadResponse, AttachmentUploadRequest>({
      query: ({ docId, files }) => {
        const formData = new FormData()
        files.forEach(file => {
          formData.append('files', file)
        })

        return {
          url: `/attachments/cmn/${docId}/upload`,
          method: 'POST',
          body: formData,
        }
      },
      // invalidatesTags: [{ type: 'Attachment', id: 'LIST' }, 'TomPd', 'TomPdDoc'],
    }),
    getCmnAttachmentFileList: build.query<ExportAttachmentResponse, ExportAttachmentRequest>({
      query: ({ tomId }) => ({
        url: `/attachments/cmn/tom/${tomId}/get-file-list`,
        method: 'GET',
      }),
      transformResponse: (res: ExportAttachmentResponseBack) => {
        return {
          changes: res.changes.map(change => ({ ...change, docVariant: 'change' })),
          versions: res.versions.map(version => ({ ...version, docVariant: 'version' })),
        }
      },
      providesTags: [{ type: 'Attachment', id: 'LIST' }],
    }),
    sendCmnAttachmentFileList: build.mutation<SendAttachmentFileListResponse, SendAttachmentFileListRequest>({
      query: ({ tomId, ...body }) => ({
        url: `/attachments/cmn/tom/${tomId}/create-download-link`,
        method: 'POST',
        body,
      }),
    }),

    // Common
    downloadAttachment: build.mutation<Blob, DownloadAttachmentRequest>({
      query: ({ attachmentId }) => ({
        url: `/attachments/${attachmentId}`,
        method: 'POST',
        responseHandler: (response: any) => response.blob()
      }),
    }),
    deleteAttachment: build.mutation<DeleteAttachmentResponse, DeleteAttachmentRequest>({
      query: ({ attachmentId }) => ({
        url: `/attachments/${attachmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Attachment', id: 'LIST' },
        'TomRd',
        'TomRdDoc',
        'TomPd',
        'TomPdDoc',
        'TomIi',
        'TomIiDoc',
        'TomIrd',
        'TomIrdDoc',
        'TomCmnDoc',
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetRdAttachmentsQuery,
  useUploadRdAttachmentMutation,
  useGetRdAttachmentFileListQuery,
  useSendRdAttachmentFileListMutation,

  useGetPdAttachmentsQuery,
  useUploadPdAttachmentMutation,
  useGetPdAttachmentFileListQuery,
  useSendPdAttachmentFileListMutation,

  useGetIiAttachmentsQuery,
  useUploadIiAttachmentMutation,
  useGetIiAttachmentFileListQuery,
  useSendIiAttachmentFileListMutation,

  useGetIrdAttachmentsQuery,
  useUploadIrdAttachmentMutation,
  useGetIrdAttachmentFileListQuery,
  useSendIrdAttachmentFileListMutation,

  useGetCmnAttachmentsQuery,
  useUploadCmnAttachmentMutation,
  useGetCmnAttachmentFileListQuery,
  useSendCmnAttachmentFileListMutation,

  useDownloadAttachmentMutation,
  useDeleteAttachmentMutation,
} = attachmentsApi
