import { UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { theme } from 'styles/theme'
import { TomType } from 'types/tom'
import { UserRolesEn } from 'types/user'

export interface IChangeSignProps {}

export type TChangeSignDialogTrigger = 'changeStatus'

export type TChangeSign = 'disabled' | 'enabled'

export const tomRoleAccess: UserRolesEn[] = ['admin', 'client', 'architector']

export const changeSignLabel: Record<TChangeSign, string> = {
  disabled: 'Внесение изменений',
  enabled: 'Вносятся изменения',
}

export const changeSignTooltipColor: Record<TChangeSign, string> = {
  disabled: theme.palette.success.main,
  enabled: theme.palette.warning.main,
}

export const changeSignTooltipTitle: Record<TChangeSign, string> = {
  disabled: 'Активируйте для внесения изменений в документ',
  enabled: 'Активируйте для завершения работы над изменениями',
}

export const changeSignBgColor: Record<TChangeSign, string> = {
  disabled: '#8DD4C826',
  enabled: theme.palette.legends.lightYellow,
}

export const phaseByTomId: Record<string, TomType> = {
  irdId: 'ИРД',
  iiId: 'ИИ',
  pdId: 'ПД',
  rdId: 'РД',
}
