import React, { ReactNode, useEffect, useMemo } from 'react'
import { useGetProfileQuery } from '../../api/profile'
import Progress from '../../components/Progress'
import { ProfileState, updateProfileState } from '../../store/slices/profile'
import { useAppDispatch } from '../../store/store'

interface AppLayoutProps {
  children: ReactNode
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const {
    data: profile,
    error: profileError,
    isLoading: profileIsLoading,
    isSuccess: profileIsSuccess,
  } = useGetProfileQuery()

  const value = useMemo<ProfileState>(() => {
    return {
      profile: profileError
        ? ({} as ProfileState['profile'])
        : profile || ({} as ProfileState['profile']),
      currentCompanyId: profile?.company.companyID || 0,
      isLogout: false,
    }
  }, [
    profile,
    profileError,
  ])

  const isLoading = profileIsLoading
  const isError = profileError

  useEffect(() => {
    if (profileIsSuccess) {
      dispatch(updateProfileState(value))
    }
  }, [profileIsSuccess])

  useEffect(() => {
    if (isError) {
      console.log('Ошибка')
    }
  }, [isError])

  return isLoading
    ? <Progress />
    : <>{children}</>
}

export default AppLayout