import { Box, Button, Stack, Typography } from '@mui/material'
import useQuery from 'hooks/useQuery'
import React, { FC, useEffect } from 'react'
import { profileSelector } from 'store/slices/profile'
import { theme } from 'styles/theme'

import { TitledWrapper } from '../../../../components/TitledWrapper'
import { setOpenedDrawer } from '../../../../store/slices/documentsPages/drawerInfo'
import {
  docsSelector,
  setCurrentChange,
  setCurrentDocument,
  tomSelector,
} from '../../../../store/slices/documentsPages/tom'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { DocList } from '../DocList'
import { ChangeSign } from '../DocViewNavBarContentInfo/components/ChangeSign'
import { tomRoleAccess } from '../DocViewNavBarContentInfo/components/ChangeSign/ChangeSign.types'

export const DocViewNavBarContentChanges: FC = () => {
  const dispatch = useAppDispatch()
  const profile = useTypedSelector(profileSelector)
  const { role } = profile
  const access: boolean = tomRoleAccess.includes(role)

  const { tomChanges } = useTypedSelector(docsSelector)
  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled = tom?.features.annulment.annulled

  const { status, features } = tom || {}
  const { changeMode } = features || {}

  const addChangeBtnShown: boolean = status === 'Согласовано' && !!changeMode && access
  const warningShown: boolean = status !== 'Согласовано'
  const changeModeShown: boolean = !warningShown && ((!access && changeMode) || access)

  const onAddDocChange = () => {
    dispatch(setCurrentChange({ change: null }))
    dispatch(setOpenedDrawer({ openedDrawer: 'docChange' }))
  }

  return (
    <Stack spacing={3} flex={1} overflow='hidden'>
      {(changeModeShown || addChangeBtnShown || warningShown) && (
        <Stack pr={2.5} spacing={1}>
          {!isAnnulled && changeModeShown && <ChangeSign />}

          {!isAnnulled && addChangeBtnShown && (
            <Button onClick={onAddDocChange} variant='outlined' size='small' color='secondary' fullWidth>
              Добавить изменение документа
            </Button>
          )}

          {!isAnnulled && warningShown && (
            <Typography variant='body2' color={theme.palette.warning.main} textAlign={'center'}>
              Добавление изменений документа доступно только в статусе тома “Согласовано” и при наличии признака
              Внесение изменений.
            </Typography>
          )}

          {isAnnulled && (
            <Typography variant='body2' color={theme.palette.warning.main} textAlign={'center'}>
              Добавление изменений документа недоступно для тома с признаком “Аннулирован”.
            </Typography>
          )}
        </Stack>
      )}

      <Stack spacing={2} overflow='hidden'>
        {!!tomChanges?.length && (
          <TitledWrapper title='Все изменения к документу' variant='primary' contentWrapper='scrollable'>
            <DocList listVariant='changes' itemVariant='navbar' />
          </TitledWrapper>
        )}
      </Stack>
    </Stack>
  )
}
