import { IGetWidgetSettingsResponse } from 'api/projects/api.types'
import { TDashboardWidget } from 'api/projects/types'

export interface IWidgetsDrawerProps {
  widgetData?: IGetWidgetSettingsResponse
  isOpen: boolean
}

export interface IWidgetsDrawerFormData {
  allProjects: boolean
  widgets: IFormWidgetItem[]
}

export interface IFormWidgetItem {
  name: TDashboardWidget
  enabled: boolean
}

export const widgetDrawerRadioData = [
  {
    value: false,
    label: 'Текущий проект',
  },
  {
    value: true,
    label: 'Все проекты',
  },
]
