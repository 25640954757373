import {
  KeyboardArrowLeft as ArrowIcon,
  Check as CheckIcon,
  PriorityHigh as PriorityHighIcon,
} from '@mui/icons-material'
import { ReactNode } from 'react'
import { theme } from 'styles/theme'

import { TimStatus } from '../../types/models'
import { UserRolesEn } from '../../types/user'

export interface TIM {
  actualDate: string | null
  architectorId: number | null
  architectorName?: string
  authorId: AuthorInfo
  clientId: number | null
  clientName: string
  createdAt: string
  description?: string
  id: number
  objectId: number
  plannedDate: string
  rdpId: number | null
  status: TimStatus | null
  title: string
}

export interface TIMAndVersions {
  tim: TIM
  versions: TimVersion[]
}

export interface AuthorInfo {
  avatar?: string
  company?: string
  id: number
  name: string
  position: string
  role: UserRolesEn
}

export interface TimMenuObject {
  number: string
  objId: number
  title: string
}

export interface TimMenuRdp {
  mark: string
  rdpId: number
}

export interface TimMenuMap {
  obj: TimMenuObject
  rdpList: TimMenuRdp[]
}

export interface TimApiData {
  actualDate: string | null
  architectorId: number | null
  architectorName?: string
  clientId: number | null
  clientName?: string
  description?: string
  objectId: number
  plannedDate: string | null
  rdpId: number | null
  status: TimStatus | null
  title: string
}

export interface TimVersion {
  createdAt: string
  createdBy: AuthorInfo
  description: string | null
  extension: string
  fileSize: number
  originName: string | null
  tanglBindStatus: TanglBindStatus | null
  tanglID: string | null
  timID: number
  version: number
}

export type TanglBindStatus = 'none' | 'done' | 'upload' | 'error'

export const tanglBindInfoByStatus: Record<TanglBindStatus, string> = {
  none: 'Для просмотра моделей необходимо настроить интеграцию с Tangl в режиме администрирования.',
  done: 'Версия доступна для выбора в режиме просмотра моделей.',
  upload: 'Версия (в порядке очереди) обрабатывается на сервере Tangl. Просмотр модели временно недоступен.',
  error:
    'Просмотр модели недоступен. В процессе синхронизации произошла ошибка. Повторная синхронизация доступна в режиме администрирования.',
}

export const tanglBindBadgeColorByStatus: Record<TanglBindStatus, string> = {
  none: theme.palette.legends.grayBlue,
  done: theme.palette.success.dark,
  upload: theme.palette.warning.light,
  error: theme.palette.error.main,
}

export const tanglBindBadgeIconByStatus: Record<TanglBindStatus, ReactNode | null> = {
  none: null,
  done: <CheckIcon />,
  upload: <ArrowIcon sx={{ transform: 'rotate(-25deg)' }} />,
  error: <PriorityHighIcon />,
}

export interface TimViewerObj {
  groups: TimViewerGroup[]
  id: number
  name: string
  number: string
}

export interface TimViewerGroup {
  id: number
  name: string
  models: TimViewerModel[]
}

export interface TimViewerModel {
  id: number
  name: string
  versions: TimViewerVersion[]
}

export interface TimViewerVersion {
  fileType: string
  id: number
  tanglID?: string
  version: number
}
