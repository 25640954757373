import { Drawer, Stack, Typography } from "@mui/material"
import { DocumentAccessDrawerProps, FilteredCompany, OpenedEmployees, SelectedCompany, TabPanelProps } from "./DocumentAccessDrawer.types"
import { DrawerContent, DrawerTopBar, DrawerWrapper, StyledButtonGroup, StyledTabButton } from "./DocumentAccessDrawer.styles"
import { theme } from "../../../../../styles/theme"
import Divider from "../../../../../components/Divider"
import { useEffect, useMemo, useState } from "react"
import { useGetTomAccessListQuery, useTomAccessCompanyStatusUpdateMutation, useTomAccessUserStatusUpdateAllMutation, useTomAccessUserStatusUpdateMutation } from "../../../../../api/tomCommonApi"
import { useNavigate, useParams } from "react-router-dom"
import { Companies } from "./components/Companies"
import { PersonInfo, TomAccessStatus } from "../../../../../api/tomCommonApi/types"
import { AutocompleteCompany } from "./components/AutocompleteCompany"
import { SearchWrapper } from "./components/SearchWrapper"
import { SearchEmployees } from "./components/SearchEmployees"
import { Employees } from "./components/Employees"
import useConfirmDialog, { UseExitConfirmProps } from "../../../../../hooks/useConfirmDialog"
import { SearchCompany } from "./components/SearchCompany"
import { useTypedSelector } from "../../../../../store/store"
import { profileSelector } from "../../../../../store/slices/profile"
import { ScrollableContainer } from "@styles/global/ScrollableContainer"

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      spacing='20px'
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </Stack>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const DocumentAccessDrawer = ({ open, tomId, type, closeDrawer }: DocumentAccessDrawerProps) => {

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [tabUsed, setTabUsed] = useState<number>(0)
  const profile = useTypedSelector(profileSelector)
  const navigate = useNavigate()

  const { data: tomAccessList } = useGetTomAccessListQuery({ projectId, tomId: tomId!, type }, { skip: !(tomId && type) || !open })

  const [updateCompanyStatus, updateCompanyStatusResponse] = useTomAccessCompanyStatusUpdateMutation()
  const [updateUserStatus, updateUserStatusResponse] = useTomAccessUserStatusUpdateMutation()
  const [updateAllUserStatus, updateAllUserStatusResponse] = useTomAccessUserStatusUpdateAllMutation()
  const [isSelectedCompany, setIsSelectedCompany] = useState<SelectedCompany | null>(null)
  const [isSelectedEmployee, setIsSelectedEmployee] = useState<PersonInfo[]>([])
  const [searchCompanyValue, setSearchCompanyValue] = useState<string>('')

  const openedCompany = useMemo((): FilteredCompany[] => {
    if (!tomAccessList?.data.length) return []

    const filteredCompany = tomAccessList.data.filter(company => company.status === 'open')
    return filteredCompany.length ? filteredCompany.map((access) => ({
      name: access.company,
      employees: access.employees.length
    })) : []

  }, [tomAccessList?.data])

  const closedCompany = useMemo((): FilteredCompany[] => {
    if (!tomAccessList?.data.length) return []

    const filteredCompany = tomAccessList.data.filter(company => company.status === 'close')
    return filteredCompany.length ? filteredCompany.map((access) => ({
      name: access.company,
      employees: access.employees.length
    })) : []

  }, [tomAccessList?.data])

  const openedEmployees = useMemo((): OpenedEmployees[] => {
    if (!tomAccessList?.data.length) return []
    return tomAccessList.data.map((access) => ({
      companyName: access.company,
      employees: access.employees
        .filter((employee) => employee.status === 'open')
        .map((employee) => employee.person)
    }))
  }, [tomAccessList?.data])

  const changeAccessStatusCompany = (company: string, status: TomAccessStatus) => {
    updateCompanyStatus({
      projectId,
      access: { company, status },
      tom: { id: tomId!, type }
    })
  }

  const changeAccessStatusEmployee = (personId: number, status: TomAccessStatus) => {
    updateUserStatus({
      projectId,
      access: { personId, status },
      tom: { id: tomId!, type }
    })
  }

  const changeAccessStatusAllEmployee = (status: TomAccessStatus) => {
    if (!isSelectedEmployee.length) return
    const accessList = isSelectedEmployee.map((person) => ({
      personId: person.id,
      status
    }))
    updateAllUserStatus({
      projectId,
      accessList,
      tom: { id: tomId!, type }
    })
  }

  const changeAccessStatusFromButton = (confirm: boolean, employees: PersonInfo[]) => {
    if (confirm) {
      const accessList = employees.map((person) => ({
        personId: person.id,
        status: 'close' as TomAccessStatus
      }))
      updateAllUserStatus({
        projectId,
        accessList,
        tom: { id: tomId!, type }
      })
    }
  }

  const selectCompany = (company: string) => {
    const foundCompany = tomAccessList?.data.find(access => access.company === company)
    if (foundCompany) {
      setIsSelectedCompany({
        company: foundCompany.company,
        status: foundCompany.status
      })
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: changeAccessStatusFromButton,
    title: 'Закрыть доступ всем сотрудникам?',
    body: (<>Вы уверены, что хотите закрыть доступ всем сотрудникам выбранной компании?</>),
    denyButtonText: 'Отменить',
    confirmButtonColor: 'error'
  }
  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  useEffect(() => {
    setIsSelectedCompany(null)
  }, [tabUsed])

  useEffect(() => {
    const currentUserCompany = openedCompany.find((company => company.name === profile.company.userCompanyName))
    if (
      updateUserStatusResponse.data
      && updateUserStatusResponse.data.access.personId === profile.employeeId
      && updateUserStatusResponse.data.access.status === 'close'
      && !currentUserCompany
    ) {
      navigate(`/project/${projectId}/toms`, { replace: true })
    }
  }, [updateUserStatusResponse.data])

  useEffect(() => {
    if (updateAllUserStatusResponse.data) {
      setIsSelectedEmployee([])
      const isOpenUserCompany = openedCompany.find((company => company.name === profile.company.userCompanyName))
      const isCloseAccessUser = updateAllUserStatusResponse.data.accessList
        .find(person => person.personId === profile.employeeId && person.status === 'close')
      if (!isOpenUserCompany && isCloseAccessUser) {
        navigate(`/project/${projectId}/toms`, { replace: true })
      }
    }
  }, [updateAllUserStatusResponse.data])

  useEffect(() => {

    if (updateCompanyStatusResponse.data) {
      const accessUser = openedEmployees.flatMap(({ employees }) => employees).find(employee => employee.id === profile.employeeId)
      const isCloseUserCompany = updateCompanyStatusResponse.data.access.company === profile.company.userCompanyName
        && updateCompanyStatusResponse.data.access.status === 'close'
      if (!accessUser && isCloseUserCompany) {
        navigate(`/project/${projectId}/toms`, { replace: true })
      }
    }
  }, [updateCompanyStatusResponse.data])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => closeDrawer(false)}
    >
      <DrawerWrapper>
        <ScrollableContainer>
          <DrawerTopBar direction='row' spacing={2}>
            <Typography variant='h1' color={theme.palette.primary.main}>
              Доступ к документу
            </Typography>
          </DrawerTopBar>
          <Divider />
          <DrawerContent >
            <StyledButtonGroup
              variant="fullWidth"
              color="primary"
              value={tabUsed}
              onChange={(e, newValue) => setTabUsed(newValue)}
            >
              <StyledTabButton {...a11yProps(0)} label='Компании' />
              <StyledTabButton {...a11yProps(1)} label='Пользователи' />
            </StyledButtonGroup>

            <CustomTabPanel value={tabUsed} index={0}>
              <SearchWrapper
                title="Выберите компанию для открытия доступа">
                <SearchCompany
                  searchCompanyValue={searchCompanyValue}
                  setSearchCompanyValue={setSearchCompanyValue} />
              </SearchWrapper>
              <Companies
                searchCompanyValue={searchCompanyValue}
                companies={openedCompany}
                title="Доступ к документу открыт"
                type="open"
                changeStatus={changeAccessStatusCompany} />
              <Companies
                searchCompanyValue={searchCompanyValue}
                companies={closedCompany}
                title="Список компаний без доступа"
                type="close"
                changeStatus={changeAccessStatusCompany} />
            </CustomTabPanel>

            <CustomTabPanel value={tabUsed} index={1}>
              <SearchWrapper title="Выберите пользователей для открытия доступа">
                <AutocompleteCompany
                  tomAccessList={tomAccessList?.data}
                  isSelectedCompany={isSelectedCompany}
                  setIsSelectedCompany={setIsSelectedCompany}
                />
                <SearchEmployees
                  tomAccessList={tomAccessList?.data}
                  isSelectedCompany={isSelectedCompany}
                  isSelectedEmployee={isSelectedEmployee}
                  setIsSelectedEmployee={setIsSelectedEmployee}
                  changeStatus={changeAccessStatusAllEmployee}
                />
              </SearchWrapper>
              <Stack spacing='20px'>
                {openedEmployees.map((company) => (
                  <Employees
                    employees={company.employees}
                    title={company.companyName}
                    tabUsed={tabUsed}
                    changeStatus={changeAccessStatusEmployee}
                    onAddEmployeesClick={selectCompany}
                    onDeleteEmployeesClick={openConfirm} />
                ))}
              </Stack>
            </CustomTabPanel>
          </DrawerContent>
        </ScrollableContainer>
      </DrawerWrapper>
      <ConfirmDialog />
    </Drawer >
  )
}