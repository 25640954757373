import { DocMoveIcon } from 'assets/icons/DocMove'
import { DocReadyIcon } from 'assets/icons/DocReady'
import { FC } from 'react'

import Tooltip from '@components/Tooltip'

import { StyledTomProdButton } from './TomProdButton.styles'
import { ITomProdButtonProps } from './TomProdButton.types'

export const TomProdButton: FC<ITomProdButtonProps> = ({ params, onSendToProdClick }) => {
  const { inProd, prodDate, id, title, changeNum } = params || {}

  return (
    <Tooltip variant='light' title={inProd ? <>Передано в производство: {prodDate}</> : <>Передать в производство</>}>
      <StyledTomProdButton
        inProd={inProd}
        disableRipple={inProd}
        ref={(ref: any) => {
          if (!ref) return
          ref.onclick = (e: any) => {
            e.stopPropagation()
            !inProd && onSendToProdClick({ tomId: id!, tomTitle: title, changeNum })
          }
        }}
      >
        {inProd ? <DocReadyIcon /> : <DocMoveIcon />}
      </StyledTomProdButton>
    </Tooltip>
  )
}
