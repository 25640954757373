import { UseGetAttachmentsResponse } from './useGetAttachments.types'
import { ProjectPhase } from '../../../../../../types/project'
import { Attachment } from '../../../../../../api/attachments/types'
import {
  useGetCmnAttachmentsQuery,
  useGetIiAttachmentsQuery,
  useGetIrdAttachmentsQuery,
  useGetPdAttachmentsQuery,
  useGetRdAttachmentsQuery
} from '../../../../../../api/attachments'
import { useTypedSelector } from '../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { currentDocumentSelector, isCmnTomSelector } from '../../../../../../store/slices/documentsPages/tom'

export const useGetAttachments = (): UseGetAttachmentsResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { currentDocument } = useTypedSelector(currentDocumentSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)

  // Rd
  const { data: rdAttachmentsData, isFetching: rdAttachmentsFetching } = useGetRdAttachmentsQuery({
    docId: currentDocument.docId,
  }, {
    skip: !currentDocument.docId || selectedProjectPhase !== 'Рабочая документация',
  })
  const { data: rdAttachments } = rdAttachmentsData || {}


  // Pd
  const { data: pdAttachmentsData, isFetching: pdAttachmentsFetching } = useGetPdAttachmentsQuery({
    docId: currentDocument.docId,
  }, {
    skip: !currentDocument.docId || selectedProjectPhase !== 'Проектная документация',
  })
  const { data: pdAttachments } = pdAttachmentsData || {}


  // Ii
  const { data: iiAttachmentsData, isFetching: iiAttachmentsFetching } = useGetIiAttachmentsQuery({
    docId: currentDocument.docId,
  }, {
    skip: !currentDocument.docId || selectedProjectPhase !== 'Инженерные изыскания',
  })
  const { data: iiAttachments } = iiAttachmentsData || {}


  // Ird
  const { data: irdAttachmentsData, isFetching: irdAttachmentsFetching } = useGetIrdAttachmentsQuery({
    docId: currentDocument.docId,
  }, {
    skip: !currentDocument.docId || selectedProjectPhase !== 'Сбор исходных данных',
  })
  const { data: irdAttachments } = irdAttachmentsData || {}


  // Cmn
  const { data: cmnAttachmentsData, isFetching: cmnAttachmentsFetching } = useGetCmnAttachmentsQuery({
    docId: currentDocument.docId,
  }, {
    skip: !currentDocument.docId || selectedProjectPhase !== 'Проектная документация',
  })
  const { data: cmnAttachments } = cmnAttachmentsData || {}


  // Common
  const attachmentsByPhase: Record<ProjectPhase, Attachment[]> = {
    'Рабочая документация': rdAttachments || [],
    'Проектная документация': pdAttachments || [],
    'Инженерные изыскания': iiAttachments || [],
    'Сбор исходных данных': irdAttachments || [],
  }

  const attachments = isCmnTom ? cmnAttachments || [] : attachmentsByPhase[selectedProjectPhase]

  const isAttachmentsLoading = !!(
    rdAttachmentsFetching
    || pdAttachmentsFetching
    || iiAttachmentsFetching
    || irdAttachmentsFetching
    || cmnAttachmentsFetching
  )

  return {
    attachments,
    isAttachmentsLoading,
  }
}
