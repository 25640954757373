import styled from 'styled-components'

import { DocsDrawerFormWrapper } from '@pages/Docs'

import FieldForm from '../../../../../components/FieldForm'

export const WorksDrawerFormWrapper = styled(DocsDrawerFormWrapper)``

export const WorksDrawerNumberField = styled(FieldForm)`
  max-width: 200px;

  & .MuiFilledInput-root {
    max-width: none;
    padding-right: 3px;
    border-radius: 4px;

    &.Mui-error {
      border-color: ${(props) => props.theme.palette.error.main};
    }
  }

  & .MuiFilledInput-input {
    text-align: left;
    padding: 5px 5px 5px 12px;
  }

  & .MuiFormHelperText-root {
    display: none;
  }

  & .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.disabled};
  }
`
