import styled from 'styled-components'
import { alpha, Stack } from '@mui/material'
import { GraphCellVariant } from './GraphCell.types'
import { LegendColors } from '../../../../../../types/global'

interface GraphCellWrapperProps {
  variant: GraphCellVariant;
}

export const GraphCellWrapper = styled(Stack)<GraphCellWrapperProps>`
  padding-right: ${props => (props.variant === 'own' || props.variant === 'end') && '4px'};
  padding-left: ${props => (props.variant === 'own' || props.variant === 'start') && '4px'};
`

interface GraphCellBodyProps {
  cellColor: LegendColors;
  dashed?: boolean;
  cellColorOpacity?: number;
}

export const GraphCellBody = styled(Stack)<GraphCellBodyProps>`
  width: 100%;
  height: 16px;
  background-color: ${props => alpha(props.theme.palette.legends[props.cellColor], props.cellColorOpacity || 1)};
  background: ${props => props.dashed && `repeating-linear-gradient(
       to right,
       ${props.theme.palette.legends[props.cellColor]},
       ${props.theme.palette.legends[props.cellColor]} 1px,
       ${alpha(props.theme.palette.legends[props.cellColor], 0.2)} 1px,
       ${alpha(props.theme.palette.legends[props.cellColor], 0.2)} 3.43px,
       ${props.theme.palette.legends[props.cellColor]} 3.43px,
       ${props.theme.palette.legends[props.cellColor]} 4.43px)`};
`

export const GraphCellStart = styled(GraphCellBody)`
  border-radius: 8px 0 0 8px;
`

export const GraphCellEnd = styled(GraphCellBody)`
  border-radius: 0 8px 8px 0;
`

interface GraphCellOwnProps {
  contained?: boolean;
}

export const GraphCellOwn = styled(GraphCellBody)<GraphCellOwnProps>`
  border: ${props => `2px solid ${props.theme.palette.legends[props.cellColor]}`};
  background-color: ${props => props.contained && 'transparent'};
  border-radius: 8px;
`
