import { UseGetAddHandlerProps, UseGetAddHandlerResponse } from './useGetAddHandler.types'
import React from 'react'
import { useFormikContext } from 'formik'
import {
  ExportArchiveFormData,
  SelectedIiTomData,
  SelectedIrdTomData,
  SelectedPdTomData,
  SelectedRdTomData
} from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { ProjectPhase } from '../../../../../../../../types/project'
import { useTypedSelector } from '../../../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../../../store/slices/documentsPages/projectInfo'


export const useGetAddHandler = ({
  rdTom,
  pdTom,
  iiTom,
  irdTom,
  objectIndex,
  rdIndex,
  pdIndex,
  iiIndex,
  irdIndex,
  tomIndex,
}: UseGetAddHandlerProps): UseGetAddHandlerResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { values: formValues, setFieldValue } = useFormikContext<ExportArchiveFormData>()

  // Rd
  const onRdAddClick = () => {
    setFieldValue(`rdFileList[${objectIndex!}].rdList[${rdIndex}].tomList[${tomIndex}].selected`, true)

    const selectedTomData: SelectedRdTomData = {
      tom: rdTom!,
      objectIndex: objectIndex!,
      rdIndex: rdIndex!,
      tomIndex,
    }
    setFieldValue(`selectedRdToms`, [...formValues.selectedRdToms, selectedTomData])
  }


  // Pd
  const onPdAddClick = () => {
    setFieldValue(`pdFileList[${pdIndex!}].tomList[${tomIndex}].selected`, true)

    const selectedTomData: SelectedPdTomData = {
      tom: pdTom!,
      pdIndex: pdIndex!,
      tomIndex,
    }
    setFieldValue(`selectedPdToms`, [...formValues.selectedPdToms, selectedTomData])
  }


  // Ii
  const onIiAddClick = () => {
    setFieldValue(`iiFileList[${iiIndex!}].tomList[${tomIndex}].selected`, true)

    const selectedTomData: SelectedIiTomData = {
      tom: iiTom!,
      iiIndex: iiIndex!,
      tomIndex,
    }
    setFieldValue(`selectedIiToms`, [...formValues.selectedIiToms, selectedTomData])
  }


  // Ird
  const onIrdAddClick = () => {
    setFieldValue(`irdFileList[${irdIndex!}].tomList[${tomIndex}].selected`, true)

    const selectedTomData: SelectedIrdTomData = {
      tom: irdTom!,
      irdIndex: irdIndex!,
      tomIndex,
    }
    setFieldValue(`selectedIrdToms`, [...formValues.selectedIrdToms, selectedTomData])
  }


  // Common
  const onAddClick: Record<ProjectPhase, () => void> = {
    'Рабочая документация': onRdAddClick,
    'Проектная документация': onPdAddClick,
    'Инженерные изыскания': onIiAddClick,
    'Сбор исходных данных': onIrdAddClick,
  }

  return {
    onAddClick: onAddClick[selectedProjectPhase],
  }
}
