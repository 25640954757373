import React, { FC, useCallback, useState } from 'react'

import { ExportArchiveDrawerProps } from './ExportArchiveDrawer.types'
import { Box, Button, Drawer, Typography } from '@mui/material'
import { ExportArchiveDrawerTopBar, ExportArchiveDrawerWrapper } from './ExportArchiveDrawer.styles'
import { theme } from '../../../../styles/theme'
import Divider from '../../../../components/Divider'
import { ExportArchiveDrawerForm } from './components/ExportArchiveDrawerForm'
import { docsConfigByPhase } from '../../../../layouts/DocumentsLayout'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { onDrawerClose, openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import { selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'

export const ExportArchiveDrawer: FC<ExportArchiveDrawerProps> = () => {
  const dispatch = useAppDispatch()

  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)

  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer
      anchor='right'
      open={openedDrawer === 'exportArchive'}
      onClose={() => dispatch(onDrawerClose({ dirty }))}
    >
      <ExportArchiveDrawerWrapper>
        <ExportArchiveDrawerTopBar direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
          <Box>
            <Typography variant='h1' color={theme.palette.primary.main}>
              {docsConfigByPhase[selectedProjectPhase]?.docs.exportArchiveTitle}
            </Typography>
          </Box>

          <Button
            href='https://gge.ru/'
            target='_blank'
            size='small'
            variant='text'
            endIcon={<ArrowForwardIcon fontSize='small' />}
            style={{ height: 29 }}
          >
            <Typography
              variant='buttonSmall'
              fontWeight={400}
              fontSize={14}
              letterSpacing={0.17}
              textTransform='none'
            >
              Перейти на сайт главгосэкспертизы
            </Typography>
          </Button>
        </ExportArchiveDrawerTopBar>

        <Divider />

        <ExportArchiveDrawerForm onFormChange={onFormChange} />
      </ExportArchiveDrawerWrapper>
    </Drawer>
  )
}
