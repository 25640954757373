import styled from 'styled-components'
import { alpha, FormControlLabel, Stack } from '@mui/material'
import { FilterSelectMultiple } from '../../../../../../components/FilterSelectMulti'

export const ExportArchiveDrawerMainListWrapper = styled(Stack)`
  flex: 1;
  max-width: calc((100% - 20px) / 2); // 20px - gap
  padding: 20px;
  padding-right: 0;
  border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 16px;
`

export const ExportArchiveDrawerFilter = styled(FilterSelectMultiple)`
  flex: 1;
  background-color: ${props => props.theme.palette.bg.shades};
  border: ${props => `1px solid ${alpha(props.theme.palette.secondary.gray!, 0.2)}`};
  border-radius: 6px;

  & .MuiSelect-select {
    padding-left: 4px;
  }

  & .MuiTypography-root {
    line-height: 22px;
    color: ${props => props.theme.palette.disabled};
  }
`

export const ExportArchiveDrawerFormControlLabel = styled(FormControlLabel)`
  & .MuiCheckbox-root {
    margin-right: 4px;
  }
  
  & .MuiButtonBase-root {
    padding: 6px;
  }
  
  & .MuiSvgIcon-root {
    font-size: 20px !important;
  }
  
  & .MuiTypography-root {
    font-size: 14px;
    letter-spacing: 0;
  }
`