import { Stack } from '@mui/material'
import styled from 'styled-components'

export const TerminateTomInfoWrapper = styled(Stack)`
  gap: 12px;
  border: 1px solid ${(props) => props.theme.palette.warning.main};
  border-radius: 8px;
  padding: 10px;
  align-items: center;
`

export const RoundedItem = styled.div`
  border-radius: 100px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  padding: 2px 6px;
`
