import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import ColoredTitle from '../../../../../components/ColoredTitle'
import { CardSubtitle, StyledAvatar } from '../styles'
import { UserRoles, UserRolesColors, userRolesEnToRu } from '../../../../../types/user'
import { StyledCardHeader } from './styles'
import { UserCardHeaderProps } from './UserCardHeader.types'
import PersonSharpIcon from '@mui/icons-material/PersonSharp'
import { formatMaskPhone } from '../../../../../components/PhoneFieldForm/utils'

const UserCardHeader: React.FC<UserCardHeaderProps> = ({ userData, employeeData, mode }) => {
  const { email, firstName, middleName, avatarPreview, lastName, phone, role: roleEn } = (userData || employeeData)!
  const formattedName = <>{lastName}<br />{firstName} {middleName}</>
  const role: UserRoles = userRolesEnToRu[roleEn]

  const formattedPhone = () => {
    return phone
      ? formatMaskPhone(phone)
      : phone
  }

  return (
    <StyledCardHeader
      avatar={
        <Box width={120}>
          {mode === 'full' && (
            <ColoredTitle body={role} color={UserRolesColors[role]} />
          )}
          <StyledAvatar src={avatarPreview}>
            <PersonSharpIcon sx={{ width: '80px', height: '80px' }} />
          </StyledAvatar>
        </Box>
      }
      title={<Typography variant='subtitle1' sx={{ mb: 1 }}>{formattedName}</Typography>}
      subheader={
        <Stack spacing={0.5}>
          <CardSubtitle>Контакты:</CardSubtitle>
          <Typography variant='body2'>{formattedPhone()}</Typography>
          <Typography
            variant='body2'
            maxWidth={270}
            overflow='hidden'
            textOverflow='ellipsis'
            className='email'
          >
            {email}
          </Typography>
        </Stack>
      }
    />
  )
}

export default UserCardHeader