import styled from 'styled-components'
import { Box, Drawer, Stack } from '@mui/material'

export const StyledProjectDrawer = styled(Drawer)`
`
export const ProjectDrawerWrapper = styled(Stack)`
  width: 400px;
  height: 100%;
`

export const ProjectDrawerTopBar = styled(Box)`
  padding: 20px 20px 15px;
  text-align: center;
`
