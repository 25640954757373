import HomeIcon from "@assets/icons/HomeIcon";
import Button from "@components/Button"
import { Stack } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams } from "react-router-dom";

export const NavButtons = () => {

  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  return (
    <Stack direction='row' spacing={1.25}>
      <Button icon color='primary' sx={{ gap: 1, mr: 1 }}
        variant='text' onClick={() => navigate(-1)}>
        <ArrowBackIcon />
        Назад
      </Button>
      <Button icon color='primary'
        variant='text' onClick={() => navigate(`/project/${projectId}`)}>
        <HomeIcon />
      </Button>
    </Stack>
  )
}