import { TomStatus } from 'types/tom'

import { ChangeRd, TomRd } from '../../rdPhase'

export interface TomIi
  extends Omit<
    TomRd,
    'objectId' | 'rdId' | 'inProd' | 'prodDate' | 'prodAuthorId' | 'prodAuthorName' | 'contractorId' | 'contractorName'
  > {
  iiMark: string
  iiId: number
  type: TomIiType
}

export type TomIiType = 'Техническое задание' | 'Программа работ' | 'Технический отчет'
export type TomIiShortType = 'ТЗ' | 'ПР' | 'Отчет'
export const tomIiTypes = ['Техническое задание', 'Программа работ', 'Технический отчет']

export const getTomIiShortType: Record<TomIiType, TomIiShortType> = {
  'Техническое задание': 'ТЗ',
  'Программа работ': 'ПР',
  'Технический отчет': 'Отчет',
}

export interface TomIiFull {
  changes: ChangeIi[]
  tom: TomIi
}

export interface ChangeIi extends ChangeRd {}
