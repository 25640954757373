import { Button, Stack } from "@mui/material";
import styled from "styled-components";

export const StyledContainer = styled(Stack)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  //margin-top: 20px;
  background-color: ${props => props.theme.palette.bg.white};
  position: absolute;
  top: 0;
  z-index: 4;
`
export const Loader = styled.span` 
    position: relative;
    width:  60px;
    height: 60px;
    background: ${props => props.theme.palette.primary.main};
    transform: rotateX(65deg) rotate(45deg);
    transform: perspective(200px) rotateX(65deg) rotate(45deg); 
    color: ${props => props.theme.palette.secondary.gray};
    animation: layers1 1s linear infinite alternate;

    :after {
    content: '';
    position: absolute;
    inset: 0;
    background: ${props => props.theme.palette.bg.blue + '80'};
    animation: layerTr 1s linear infinite alternate;
  }

  @keyframes layers1 {
    0% { box-shadow: 0px 0px 0 0px  }
   90% , 100% { box-shadow: 20px 20px 0 -4px  }
  }
  @keyframes layerTr {
    0% { transform:  translate(0, 0) scale(1) }
    100% {  transform: translate(-30px, -30px) scale(1) }
  }
`
export const ButtonPage = styled(Button)`
  background-color: ${props => props.theme.palette.bg.lightBlue};
  border-color: ${props => props.theme.palette.primaryBorderLight};
  max-width: 193px;
`
    