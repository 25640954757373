import { DateForCell, GraphCellVariant } from '../GraphCell.types'
import { WorkStatus } from '../../../../../../../types/works'
import { parseDateForCell } from '../../../WorksTable.utils'
import { LegendColors } from '../../../../../../../types/global'
import { compareAsc } from 'date-fns'
import { WorkIsExpired } from '../../../../../../../api/works'

const localDate = parseDateForCell(new Date())
const {
  year: localYear,
  month: localMonth,
  day: localDay,
} = localDate

// Color
interface GetCellColorForDaysData {
  status?: WorkStatus;
  isExpired: WorkIsExpired;
  datePlanEnd: DateForCell;
  datePlanStart: DateForCell;
  dateFactStart: DateForCell | null;
  year: number;
  month: number;
  day: number;
}

export const getCellColorForDays = (data: GetCellColorForDaysData): LegendColors => {
  const { year, month, day, datePlanEnd, status, isExpired, datePlanStart, dateFactStart } = data
  const { start: isExpiredStart, end: isExpiredEnd } = isExpired

  const { year: planStartYear, month: planStartMonth, day: planStartDay } = datePlanStart
  const { year: planEndYear, month: planEndMonth, day: planEndDay } = datePlanEnd
  const { year: factStartYear, month: factStartMonth, day: factStartDay } = dateFactStart || {}

  const cellDate = new Date(year, month - 1, day)
  const planStartDate = new Date(planStartYear, planStartMonth - 1, planStartDay)
  const planEndDate = new Date(planEndYear, planEndMonth - 1, planEndDay)
  const factStartDate = (factStartYear && factStartMonth && factStartDay) ? new Date(factStartYear, factStartMonth - 1, factStartDay) : null

  const comparedEndDate = compareAsc(planEndDate, cellDate)
  const comparedStartDate = compareAsc(
    factStartDate || planStartDate,
    cellDate,
  )

  if (
    [
      isExpiredStart && comparedStartDate === 1,
      isExpiredEnd && comparedEndDate === -1,
      // status === 'Не в работе' && compareAsc(cellDate, planStartDate) !== -1
    ].includes(true)
  ) {
    return 'red'
  }

  if (status === 'Выполнено' && comparedEndDate !== -1) return 'lightgreen'
  return 'blue'
}

// cellColorOpacity

interface GetCellColorOpacityForDaysData {
  status?: WorkStatus;
  isExpired: WorkIsExpired;
  year: number;
  month: number;
  day: number;
}

export const getCellColorOpacityForDays = (data: GetCellColorOpacityForDaysData): number => {
  const { status, isExpired, year, month, day } = data
  const { start: isExpiredStart, end: isExpiredEnd } = isExpired

  if (status !== 'Не в работе' || isExpiredEnd) return 1
  if (
    (year < localYear)
    || (year === localYear && month < localMonth)
    || (year === localYear && month === localMonth && day <= localDay)
  ) {
    return 0.2
  }

  return 1
}


// dashed
interface GetCellDashedForDaysData {
  status?: WorkStatus;
  year: number;
  month: number;
  day: number;
}

export const getCellDashedForDays = (data: GetCellDashedForDaysData): boolean => {
  const { status, year, month, day } = data

  if (status === 'Выполнено') return false
  if (
    (year < localYear)
    || (year === localYear && month < localMonth)
    || (year === localYear && month === localMonth && day <= localDay)
  ) {
    return false
  }

  return true
}

// Variant
interface GetVariantForDaysData {
  datePlanStart: DateForCell
  datePlanEnd: DateForCell
  dateFactStart: DateForCell | null
  dateFactEnd: DateForCell | null
  status?: WorkStatus;
  year: number;
  month: number;
  day: number;
  isExpired: WorkIsExpired;
}

export const getVariantForDays = (data: GetVariantForDaysData): GraphCellVariant => {
  const { isExpired, status, datePlanStart, datePlanEnd, dateFactStart, dateFactEnd, year, month, day } = data
  const { start: isExpiredStart, end: isExpiredEnd } = isExpired

  const { year: planStartYear, month: planStartMonth, day: planStartDay } = datePlanStart
  const { year: planEndYear, month: planEndMonth, day: planEndDay } = datePlanEnd
  const { year: factStartYear, month: factStartMonth, day: factStartDay } = dateFactStart || {}
  const { year: factEndYear, month: factEndMonth, day: factEndDay } = dateFactEnd
  || (isExpiredEnd && (status === 'Не в работе' || status === 'В работе')
      ? localDate
      : {}
  )

  const cellDate = new Date(year, month - 1, day)
  const planStartDate = new Date(planStartYear, planStartMonth - 1, planStartDay)
  const planEndDate = new Date(planEndYear, planEndMonth - 1, planEndDay)
  const factStartDate = (factStartYear && factStartMonth && factStartDay) ? new Date(factStartYear, factStartMonth - 1, factStartDay) : null
  const factEndDate = (factEndYear && factEndMonth && factEndDay) ? new Date(factEndYear, factEndMonth - 1, factEndDay) : null

  const isStartYear = (factStartYear && isExpiredStart) ? year === factStartYear : year === planStartYear
  const isEndYear = year === planEndYear
  const isDoneYear = year === factEndYear

  const isStartMonth = isStartYear && ((factStartMonth && isExpiredStart) ? month === factStartMonth : month === planStartMonth)
  const isEndMonth = isEndYear && month === planEndMonth
  const isDoneMonth = isDoneYear && month === factEndMonth

  const isStartDay = isStartMonth && ((factStartDay && isExpiredStart) ? day === factStartDay : day === planStartDay)
  const isEndDay = isEndMonth && day === planEndDay
  const isDoneDay = isDoneMonth && day === factEndDay

  if (factStartDate) {
    if (!isExpiredStart) {
      // cellDate not before factStart AND cellDate before planStart
      if (compareAsc(cellDate, factStartDate) !== -1 && compareAsc(cellDate, planStartDate) === -1) {
        return 'own'
      }
      // cellDate not before planStart AND cellDate before factStart
    } else if (compareAsc(cellDate, planStartDate) !== -1 && compareAsc(cellDate, factStartDate) === -1) {
      return 'own'
    }
  }

  if (factEndDate) {
    if (!isExpiredEnd) {
      // cellDate after factEnd AND cellDate not after planEnd
      if (compareAsc(cellDate, factEndDate) === 1 && compareAsc(cellDate, planEndDate) !== 1) {
        return 'own'
      }
      // cellDate after planEnd AND cellDate not after factEnd
    } else if (compareAsc(cellDate, planEndDate) === 1 && compareAsc(cellDate, factEndDate) !== 1) {
      return 'own'
    }
  }

  // end or done in start day
  if ((isStartDay && isEndDay) || (isStartDay && isDoneDay)) {
    return 'own'
  }

  if (compareAsc(cellDate, planStartDate) === -1 || compareAsc(cellDate, planEndDate) === 1) {
    return 'empty'
  }

  if (isStartDay) {
    return 'start'
  }

  if (isEndDay || isDoneDay) {
    return 'end'
  }

  return 'body'
}

