import { api } from 'api/api'
import {
  IIntegrateTanglResponse,
  IIntegrateTanglRequest,
  IGetTanglIntegrationSettignsResponse,
  IGetTanglIntegrationStatusResponse,
  IUpdateTanglIntegrationStatusResponse,
  IUpdateTanglIntegrationStatusRequest,
  GetTanglTokenResponse,
  CheckTanglConnectionResponse,
  ISyncTanglIntegrationResponse,
} from './api.types'
import { IIntegrationRequestFormData } from 'pages/Administration/AdminSettings/components/IntegrationRequestDrawer/components/IntegrationRequestForm/IntegrationRequestForm.types'
import { UserSwitchSettings } from './types'

export const settingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTanglIntegrationSettigns: build.query<IGetTanglIntegrationSettignsResponse, void>({
      query: () => ({
        url: '/settings/company/tangl/credential',
        method: 'GET',
      }),
      providesTags: ['TanglIntegration'],
    }),
    getTanglIntegrationStatus: build.query<IGetTanglIntegrationStatusResponse, void>({
      query: () => ({
        url: '/settings/company/tangl/status',
        method: 'GET',
      }),
      providesTags: ['TanglIntegration'],
    }),
    updateTanglIntegrationSettins: build.mutation<IIntegrateTanglResponse, IIntegrateTanglRequest>({
      query: ({ body }) => ({
        url: '/settings/company/tangl/credential',
        method: 'POST',
        body,
      }),
    }),
    updateTanglIntegrationStatus: build.mutation<
      IUpdateTanglIntegrationStatusResponse,
      IUpdateTanglIntegrationStatusRequest
    >({
      query: ({ status }) => ({
        url: '/settings/company/tangl/status',
        method: 'POST',
        body: { status },
      }),
      invalidatesTags: ['TanglIntegration'],
    }),
    syncTanglIntegration: build.mutation<ISyncTanglIntegrationResponse, void>({
      query: () => ({
        url: '/settings/company/tangl/restart-sync',
        method: 'POST',
      }),
    }),
    sendTanglIntegrationRequest: build.mutation<void, IIntegrationRequestFormData>({
      query: (body) => ({
        url: '/cross-module/binding/send-mail',
        method: 'POST',
        body,
      }),
    }),
    getTanglToken: build.query<GetTanglTokenResponse, void>({
      query: () => ({
        url: '/cross-module/tangl/get-token',
        method: 'GET',
      }),
      providesTags: ['TanglIntegration'],
    }),
    checkTanglConnection: build.query<CheckTanglConnectionResponse, void>({
      query: () => ({
        url: '/cross-module/tangl/status',
        method: 'GET',
      }),
      providesTags: ['TanglIntegration'],
    }),
    getSwitchObjectSettings: build.query<UserSwitchSettings, void>({
      query: () => '/settings/user/switches/all-obj-tom',
      providesTags: [{ type: 'SwitchSettings', id: 'TomObject' }],
    }),
    updateSwitchObjectSettings: build.mutation<UserSwitchSettings, UserSwitchSettings>({
      query: (body) => ({
        url: '/settings/user/switches/all-obj-tom',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'SwitchSettings', id: 'TomObject' }],
    }),
    getSwitchTimObjectSettings: build.query<UserSwitchSettings, void>({
      query: () => '/settings/user/switches/all-obj-tim',
      providesTags: [{ type: 'SwitchSettings', id: 'TimObject' }],
    }),
    updateSwitchTimObjectSettings: build.mutation<UserSwitchSettings, UserSwitchSettings>({
      query: (body) => ({
        url: '/settings/user/switches/all-obj-tim',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'SwitchSettings', id: 'TimObject' }],
    }),
    getSwitchSectionsSettings: build.query<UserSwitchSettings, void>({
      query: () => '/settings/user/switches/all-sections',
      providesTags: [{ type: 'SwitchSettings', id: 'Sections' }],
    }),
    updateSwitchSectionsSettings: build.mutation<UserSwitchSettings, UserSwitchSettings>({
      query: (body) => ({
        url: '/settings/user/switches/all-sections',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'SwitchSettings', id: 'Sections' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTanglIntegrationSettignsQuery,
  useGetTanglIntegrationStatusQuery,
  useUpdateTanglIntegrationSettinsMutation,
  useUpdateTanglIntegrationStatusMutation,
  useSyncTanglIntegrationMutation,
  useSendTanglIntegrationRequestMutation,
  useGetTanglTokenQuery,
  useCheckTanglConnectionQuery,
  useGetSwitchObjectSettingsQuery,
  useUpdateSwitchObjectSettingsMutation,
  useGetSwitchTimObjectSettingsQuery,
  useUpdateSwitchTimObjectSettingsMutation,
  useGetSwitchSectionsSettingsQuery,
  useUpdateSwitchSectionsSettingsMutation,
} = settingsApi
