import { RootState } from '../../../../store'
import {
  AvailableRdsItemsSelector,
  FilterDataSelector,
  FilterItemsSelector,
  IsFilterSelector
} from './filter.selectors.types'

export const isFilterSelector = (state: RootState): IsFilterSelector => ({
  isFilter: state.filter.isFilter,
})

export const filterDataSelector = (state: RootState): FilterDataSelector => ({
  filterData: state.filter.filterData,
})

export const filterItemsSelector = (state: RootState): FilterItemsSelector => ({
  allObjectsItems: state.filter.filterItems.allObjectsItems,
  allPdsItems: state.filter.filterItems.allPdsItems,
  allIisItems: state.filter.filterItems.allIisItems,
  allIrdsItems: state.filter.filterItems.allIrdsItems,
})

export const availableRdsItemsSelector = (state: RootState): AvailableRdsItemsSelector => ({
  availableRdsItems: state.filter.filterItems.availableRdsItems,
})
