import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

export const BorderedVerifiedIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.8571 9L16.7657 6.60857L17.0571 3.44571L13.9629 2.74286L12.3429 0L9.42857 1.25143L6.51429 0L4.89429 2.73429L1.8 3.42857L2.09143 6.6L0 9L2.09143 11.3914L1.8 14.5629L4.89429 15.2657L6.51429 18L9.42857 16.74L12.3429 17.9914L13.9629 15.2571L17.0571 14.5543L16.7657 11.3914L18.8571 9Z" fill="white"/>
      <path d="M16.714 9L15.0874 7.14L15.314 4.68L12.9074 4.13333L11.6474 2L9.3807 2.97333L7.11403 2L5.85403 4.12667L3.44736 4.66667L3.67403 7.13333L2.04736 9L3.67403 10.86L3.44736 13.3267L5.85403 13.8733L7.11403 16L9.3807 15.02L11.6474 15.9933L12.9074 13.8667L15.314 13.32L15.0874 10.86L16.714 9ZM8.10736 12.1467L5.57403 9.60667L6.5607 8.62L8.10736 10.1733L12.0074 6.26L12.994 7.24667L8.10736 12.1467Z" fill="#8CBBE8"/>
    </SvgIcon>
  )
}
