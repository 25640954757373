import { Box, Button, Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const ReplyBackground = styled(Stack)`
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 16px;
`
export const ReplyWrapper = styled(Box)`
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 0px 32px 32px 32px;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
`

export const StyledButton = styled(Button).attrs({ component: 'label' })`
  text-transform: none;
  font-weight: 400;
  height: 30px;
  font-size: 14px;
  padding: 5px 10px;
  background-color: ${props => props.theme.palette.bg.white};
  color: ${props => props.theme.palette.primary.main};
  gap: 6px;

  :hover {
    background-color: ${props => props.theme.palette.bg.lightBlue};
  }
`
export const FormButton = styled(Button)`
  text-transform: none;
  height: 30px;
  font-weight: 400;
`
export const SubtitleText = styled(Typography)`
  font-size: 12px;
  color: ${props => props.theme.palette.secondary.gray};
  text-align: left;
`
export const EllipsisText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`