import React, { useCallback, useEffect, useMemo } from 'react'
import { ObjectFormData, ObjectsDrawerFormProps } from './ObjectsDrawerForm.types'
import {
  ObjectBaseResponse,
  ObjectRequest,
  useCreateObjectMutation,
  useEditObjectMutation
} from '../../../../../api/rdPhase'
import { useForm } from '../../../../../hooks/useForm'
import { validationObject } from './validation'
import { useMutationHandlers } from '../../../../../hooks/useMutationHandlers'
import { ObjectsDrawerFormWrapper } from './styles'
import { Form, FormikProvider } from 'formik'
import { Box, Stack, Typography } from '@mui/material'
import FieldForm from '../../../../../components/FieldForm'
import Button from '../../../../../components/Button'
import { useSnackbar } from 'notistack'
import { COMMON_DOCS_NUMBER } from '../../../../../utils/constants'

const ObjectsDrawerForm: React.FC<ObjectsDrawerFormProps> = ({
  projectId,
  object,
  onClose,
  onFormChange,
  onSuccessCreate,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { id, title, number, description } = object || {}
  const commonObject = number === COMMON_DOCS_NUMBER

  const initialValues: ObjectFormData = useMemo(() => ({
    title: title || '',
    number: number || '',
    description: description || '',
  }), [object])

  const [createObject, createObjectResponse] = useCreateObjectMutation()
  const [editObject, editObjectResponse] = useEditObjectMutation()

  const onSubmit = useCallback((values: ObjectFormData) => {
    const dataForRequest: ObjectRequest = {
      title: values.title,
      number: values.number,
      description: values.description,
    }
    if (id) {
      editObject({
        id,
        projectId,
        ...dataForRequest
      })
    } else {
      createObject({
        id: projectId,
        ...dataForRequest
      })
    }
  }, [object])

  const { formik } = useForm({
    validationSchema: validationObject,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { dirty, isSubmitting, isValid } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  useMutationHandlers(
    createObjectResponse,
    (data: ObjectBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onSuccessCreate?.(data.data)
      enqueueSnackbar('Объект успешно создан.', { variant: 'success' })
      onClose(false, true)
    }
  )

  useMutationHandlers(
    editObjectResponse,
    (data: ObjectBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Объект успешно изменен.', { variant: 'success' })
      onClose(false, true)
    }
  )

  return (
    <ObjectsDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={1.5} component={Form}>
          {!commonObject && (
            <FieldForm
              version='objects'
              name='number'
              placeholder='Номер объекта'
            />
          )}
          <FieldForm
            version='objects'
            name='title'
            placeholder='Краткое название'
          />
          <FieldForm
            version='objects'
            name='description'
            placeholder='Полное наименование или описание объекта'
            multiline
            minRows={2}
          />
          <Stack direction='row' spacing={2} sx={{ pt: 1.5 }}>
            <Button
              type='submit'
              disabled={isSubmitting || !isValid}
              color='success'
              size='medium'
              fullWidth
            >
              Сохранить
            </Button>
            <Button size='medium' fullWidth onClick={() => onClose(dirty)}>Отменить</Button>
          </Stack>
        </Stack>
      </FormikProvider>
    </ObjectsDrawerFormWrapper>
  )
}

export default ObjectsDrawerForm
