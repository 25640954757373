import React from 'react'
import { LegendItemProps } from './LegendItem.types'
import { Box, Stack, Typography } from '@mui/material'
import { LegendBadge } from './styles'
import { theme } from '../../styles/theme'

const LegendItem: React.FC<LegendItemProps> = ({ label, color, dot, value, justify, defaultValueView }) => {
  return (
    <Box>
      <Stack
        direction='row'
        justifyContent={justify ? 'space-between' : 'center'}
        spacing={0.5}
      >
        <Stack direction='row' spacing={1} alignItems='center' style={{ overflow: 'hidden' }}>
          <LegendBadge color={color} dot={dot} />
          <Typography
            variant='buttonSmall'
            fontSize={dot ? 10 : 12}
            lineHeight={dot ? '13.2px' : '22px'}
            color={theme.palette.text.dark}
            noWrap
            style={{ maxWidth: '130px' }}
          >
            {label}
          </Typography>
        </Stack>

        {value !== undefined && (
          <Typography
            variant='buttonSmall'

            {...(!defaultValueView
                ? {
                  fontWeight: dot ? 500 : 700,
                  fontSize: dot ? 11 : 13,
                  lineHeight: dot ? '13.2px' : '15px',
                }
                : {}
            )}
            color={theme.palette.text.dark}
          >
            {`${value}\u00A0%`}
          </Typography>
        )}
      </Stack>
    </Box>
  )
}

export default LegendItem