import styled from 'styled-components'
import { DrawerContentTextWrapper } from '../../../Objects/components/ObjectsNavBarContent/styles'
import { UserDrawerContent } from '../../../../components/UserNavBar/styles'
import { FormTitledWrapper } from '../../../Administration/ProjectManagement/components/FormLeftBlock/FormLeftBlock.styles'
import { AgreementPhaseButton, AgreementPhaseGroup } from '../../../Schemes/ProjectAgreement/components/ProjectAgreementLegend'

export const PocketsDrawerTextWrapper = styled(DrawerContentTextWrapper)`
  border: 0;
  overflow-wrap: anywhere;

  & .MuiTypography-root {
    text-align: left;
  }
`
export const PocketsTypeGroup = styled(AgreementPhaseGroup)`
  gap: 0;
  width: fit-content;
  padding: 0;
  border: ${props => `1px solid ${props.theme.palette.primary.main}`};
  background-color: ${props => props.theme.palette.bg.white};

  & .MuiDivider-root {
    height: 40px;
    background-color: ${props => props.theme.palette.primary.main};
    opacity: 1;
  }
`
export const PocketsTypeGroupButton = styled(AgreementPhaseButton)`
  min-width: 75px;
  max-width: fit-content;
  height: 40px;
  padding: 0 8px;
  border-radius: 0 !important;

  &:hover {
    background-color: rgba(25, 118, 210, 0.08);
  }

  &.Mui-selected {
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.text.light};
  }

  &,
  & .MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    color: ${props => props.theme.palette.secondary.main};
  }
`

export const PocketsDrawerContent = styled(UserDrawerContent)`
  padding: 10px 20px 20px 20px;
`

export const PocketsTitledWrapper = styled(FormTitledWrapper)`
  & .title {
    padding-left: 6px;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.palette.secondary.dark};
  }

  & .stack-wrapper {
    gap: 4px;
    padding: 10px;
    border-radius: 8px;
  }
`