import styled from 'styled-components'
import {
  TextToggleButton,
  TextToggleButtonGroup
} from '../../../../Schedules/components/WorksUploadDrawer/WorksUploadDrawer.styles'
import { alpha, Button, Stack, Typography } from '@mui/material'
import FieldForm from '../../../../../components/FieldForm'
import { ProjectAgreementFormDeleteButton } from '../../../ProjectAgreementForm/ProjectAgreementForm.styles'

export const ProjectAgreementSchemeTitle = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0;
  text-transform: none;
`

export const ProjectAgreementSchemeSubtitle = styled(Typography)`
  color: ${props => props.theme.palette.secondary.gray};
`

export const ProjectAgreementSchemeValueText = styled(Typography)`
  max-width: 200px;
  color: ${props => props.theme.palette.text.dark};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  overflow-wrap: anywhere;
`

export const AgreementSchemeTitleField = styled(FieldForm)`
  & .MuiInputBase-input {
    height: 20px;
    padding: 7px 16px;
  }
`

export const AgreementSchemeEditButton = styled(Button)`
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
`

export const AgreementSchemePhaseSelect = styled(FieldForm)`
  width: 80px;

  & .MuiSelect-select {
    padding: 7px 16px;
    text-align: left;
  }
`

interface AgreementSchemeDurationWrapperProps {
  isBroken: boolean
}

export const AgreementSchemeDurationWrapper = styled(Stack)<AgreementSchemeDurationWrapperProps>`
  gap: 4px;

  & .MuiSvgIcon-root.clockIcon,
  & .MuiTypography-root {
    color: ${props => props.isBroken ? props.theme.palette.warning.main : props.theme.palette.info.main};
  }
`

export const AgreementPhaseGroup = styled(TextToggleButtonGroup)`
  border-radius: 6px;

  & .MuiDivider-root {
    align-self: center;
    width: 1px;
    height: 16px;
    background-color: #8CBBE8;
    opacity: 0.3;
    border: 0;
  }
`

export const AgreementPhaseButton = styled(TextToggleButton)`
  padding: 2px 8px;
  border-radius: 6px !important;

  &,
  & .MuiTypography-root {
    font-weight: 400;
    white-space: nowrap;
  }
`

export const AgreementStageButton = styled(Button)`
  height: 36px;
  font-size: 13px;
`

export const AgreementStageDeleteButton = styled(ProjectAgreementFormDeleteButton)`
  padding: 6px;
`

interface AgreementMainButtonProps {
  colorDisabled?: boolean
  disableHover?: boolean
}
export const AgreementMainButton = styled(Button)<AgreementMainButtonProps>`
  gap: 6px;
  align-items: center;
  height: 30px;
  padding: 6px 8px;
  text-transform: none;
  letter-spacing: 0;
  color: ${props => props.colorDisabled ? props.theme.palette.disabled : props.theme.palette.info.main};
  background-color: ${props => props.colorDisabled ? props.theme.palette.bg.shades : alpha('#1976D2', 0.08)};
  border: ${props => props.colorDisabled ? 0 : `1px solid ${props.theme.palette.info.main}`};
  border-radius: 16px;

  &:hover {
    background-color: ${props => props.disableHover 
        ? props.colorDisabled ? props.theme.palette.bg.shades : alpha('#1976D2', 0.08)
        : (props.colorDisabled ? props.theme.palette.bg.shades : alpha('#1976D2', 0.15))
};
  }
  
  &:disabled {
    background-color: inherit;
  }
`