import { Typography } from "@mui/material";
import styled from "styled-components";
import { LegendColors } from "types/global";

export const ItemButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  height: 24px;
  width: 24px;
  transition: background-color 150ms linear;

  :active {
    background-color: ${props => props.theme.palette.bg.lightBlue};
  }
`
export const ItemText = styled(Typography)`
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.theme.palette.text.dark};
`
export const ColoredCircleIcon = styled.span<{ $color: LegendColors }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.$color === 'lightgreen' && props.theme.palette.legends.lightgreen};
  background-color: ${props => props.$color === 'blue' && props.theme.palette.legends.blue};
  background-color: ${props => props.$color === 'red' && props.theme.palette.legends.red};
  background-color: ${props => props.$color === 'yellow' && props.theme.palette.legends.yellow};
  background-color: ${props => props.$color === 'disabled' && props.theme.palette.legends.disabled};
  background-color: ${props => props.$color === 'darkLime' && props.theme.palette.legends.darkLime};
  background-color: ${props => props.$color === 'darkPink' && props.theme.palette.legends.darkPink};
`