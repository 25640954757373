import React, { FC, MouseEvent } from 'react'
import { PocketCardProps } from './PocketCard.types'
import { PocketCardWrapper, PocketColoredInfo, PocketStatusInfo, PocketSubtitle, PocketTitle } from './PocketCard.styles'
import { Avatar, Divider, Stack, Typography } from '@mui/material'
import Tooltip from '../../../../components/Tooltip'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import {
  AgreementCardSubtitle,
  AgreementCardText
} from '../../../Schemes/ProjectAgreement/components/ProjectAgreementCard'
import { useTypedSelector } from '../../../../store/store'
import { profileSelector } from '../../../../store/slices/profile'
import { useParams } from 'react-router-dom'
import { ConfirmButton } from '../AgGridPockets'
import { useStartAgreementProcedureMutation } from '../../../../api/agreementPocket'

export const PocketCard: FC<PocketCardProps> = ({ pocket, onClick }) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { employeeId, role } = useTypedSelector(profileSelector)
  const isAdmin = role === 'admin'
  const { id, title, phase, tomCount, dateSend, dateReceive, roadmapTitle, receiver, sender } = pocket

  const [startAgreement, startAgreementResponse] = useStartAgreementProcedureMutation()

  const onConfirmClick = (e: MouseEvent) => {
    e.stopPropagation()
    startAgreement({ projectId, pocketId: id })
  }

  return (
    <PocketCardWrapper onClick={() => onClick(id)}>
      <Stack direction='row' justifyContent='space-between' /* alignItems='center' width='100%' */ gap={0.25}>
        <Stack direction='row' alignItems='center' gap={1}>
          <PocketColoredInfo
            customColor='blue'
          >
            <Typography variant='tooltip' style={{ marginTop: 2 }}>{phase}</Typography>
          </PocketColoredInfo>

          <Tooltip title='Документов в пакете'>
            <PocketColoredInfo
              direction='row'
              alignItems='center'
              spacing={1}
              customColor='secondary'
            >
              <InsertDriveFileOutlinedIcon fontSize='small' />
              <Typography variant='tooltip' style={{ marginTop: 2 }}>{tomCount}</Typography>
            </PocketColoredInfo>
          </Tooltip>
        </Stack>

        <PocketColoredInfo
          direction='row'
          alignItems='center'
          spacing={1}
          customColor='secondary'
        >
          <Typography variant='tooltip' style={{ marginTop: 2 }}>Отправлен: {dateSend || '—'}</Typography>
        </PocketColoredInfo>
      </Stack>

      <Stack maxWidth='100%'>
        <PocketTitle variant='body2'>{title || ''}</PocketTitle>
        <PocketSubtitle variant='body2'>{roadmapTitle || ''}</PocketSubtitle>
      </Stack>

      <Stack width='100%' spacing={0.25}>
        <AgreementCardSubtitle>Отправитель</AgreementCardSubtitle>
        <Divider />

        <Stack direction='row' alignItems='center' spacing={1.5} maxWidth='100%'>
          <Avatar src={sender.avatar} sx={{ width: 32, height: 32 }} alt='Аватар' />
          <Stack maxWidth='calc(100% - 44px)' flex={1}>
            <Tooltip title={sender?.company}>
              <AgreementCardText>{sender?.company || '—'}</AgreementCardText>
            </Tooltip>
            <Tooltip title={sender?.name}>
              <AgreementCardText>{sender?.name || '—'}</AgreementCardText>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>

      <Stack width='100%' spacing={0.25}>
        <AgreementCardSubtitle>Получатель</AgreementCardSubtitle>
        <Divider />

        <Stack direction='row' alignItems='center' spacing={1.5} maxWidth='100%'>
          <Avatar src={receiver.avatar} sx={{ width: 32, height: 32 }} alt='Аватар' />
          <Stack maxWidth='calc(100% - 32px)' flex={1}>
            <Tooltip title={receiver?.company}>
              <AgreementCardText>{receiver?.company || '—'}</AgreementCardText>
            </Tooltip>
            <Tooltip title={receiver?.name}>
              <AgreementCardText>{receiver?.name || '—'}</AgreementCardText>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
      {dateReceive ? (
        <PocketStatusInfo spacing={1} customColor='secondary'>
          <Typography variant='body2'>{`Получен: ${dateReceive}`}</Typography>
        </PocketStatusInfo>
      ) : isAdmin || employeeId === receiver.id ? (
        <Tooltip variant='light' icon
          title={<>После подтверждения получения документации по накладной запустится процедура согласования.</>}>
          <ConfirmButton fullWidth sx={{ height: '32px!important' }} onClick={onConfirmClick}>
            Подтвердить получение
          </ConfirmButton>
        </Tooltip>
      ) : (
        <PocketStatusInfo spacing={1} customColor='orange'>
          <Typography variant='body2'>Ожидает подтверждения</Typography>
        </PocketStatusInfo>
      )}
    </PocketCardWrapper>
  )
}
