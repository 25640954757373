import styled from "styled-components";
import { UserDesignLogo, UserDrawer, UserDrawerHeader, UserLogoWrapper } from "../../../../components/UserNavBar/styles";

export const DrawerWrapper = styled(UserDrawer)`
`
export const RemarksDrawerHeader = styled(UserDrawerHeader)`
`
export const RemarksLogoWrapper = styled(UserLogoWrapper)`
`
export const RemarksDesignLogo = styled(UserDesignLogo)`
`