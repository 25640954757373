import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { useSetChangeModeMutation } from 'api/cmnTom'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import useConfirmDialog, { UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { setTom, tomSelector } from 'store/slices/documentsPages/tom'
import { profileSelector } from 'store/slices/profile'
import { useAppDispatch, useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import { ProjectPhase, projectPhaseRuToRuShort } from 'types/project'

import { ChangeSignWrapper, TextButton } from './ChangeSign.styles'
import {
  IChangeSignProps,
  TChangeSign,
  TChangeSignDialogTrigger,
  changeSignBgColor,
  changeSignLabel,
  changeSignTooltipColor,
  changeSignTooltipTitle,
  tomRoleAccess,
} from './ChangeSign.types'

export const ChangeSign: FC<IChangeSignProps> = () => {
  const { tom } = useTypedSelector(tomSelector)
  const phase = projectPhaseRuToRuShort[localStorage.getItem('selectedProjectPhase') as ProjectPhase]
  const { id, title, features } = tom || {}
  const { changeMode } = features || {}
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const status: TChangeSign = changeMode ? 'enabled' : 'disabled'
  const dispatch = useAppDispatch()
  const profile = useTypedSelector(profileSelector)
  const { role } = profile
  const access: boolean = tomRoleAccess.includes(role)

  const [setChangeStatus, { isLoading, ...setChangeStatusResponse }] = useSetChangeModeMutation()

  const handleCloseManagment = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        setChangeStatus({
          projectId,
          body: {
            changeMode: !changeMode,
            tom: {
              id: id!,
              type: phase!,
            },
          },
        })
      }
    },
    [tom, phase],
  )

  const onOpenConfirm = (trigger: TChangeSignDialogTrigger) => {
    setConfirmDialogTrigger(trigger)
    openConfirm()
  }

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<TChangeSignDialogTrigger>('changeStatus')

  const changeSignDialogDataByStatus: Record<TChangeSign, Pick<UseExitConfirmProps, 'title' | 'body'>> = {
    disabled: {
      title: 'Планируется внесение изменений?',
      body: (
        <Typography variant='body2' sx={{ overflowWrap: 'break-word' }}>
          Для документа "{title}" будет открыта возможность добавления изменений.
        </Typography>
      ),
    },
    enabled: {
      title: 'Закрыть режим внесения изменений?',
      body: (
        <Typography variant='body2' sx={{ overflowWrap: 'break-word' }}>
          Для документа "{title}" будет закрыта возможность добавления изменений.
        </Typography>
      ),
    },
  }

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    changeStatus: {
      handleConfirm: handleCloseManagment,
      ...changeSignDialogDataByStatus[status],
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const changeSignContent = useMemo(() => {
    const content: any = {
      disabled: (
        <TextButton onClick={() => onOpenConfirm('changeStatus')}>
          <Typography variant='body2' fontSize={12} color={theme.palette.primary.main}>
            Планируется внесение изменений?
          </Typography>
        </TextButton>
      ),
      enabled: (
        <Button
          onClick={() => onOpenConfirm('changeStatus')}
          style={{ maxWidth: '100%' }}
          bgColor={'#ED6C021A'}
          hoverBgColor={theme.palette.legends.lightOrange}
        >
          <Typography variant='body1' color={theme.palette.warning.main}>
            Отменить внесение изменений
          </Typography>
        </Button>
      ),
    }

    return content[status]
  }, [status])

  if (!access && status === 'disabled') return null

  return (
    <ChangeSignWrapper bgColor={changeSignBgColor[status]}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant='subtitle2' color={theme.palette.text.dark}>
          {changeSignLabel[status]}
        </Typography>
        {access && (
          <Tooltip
            title={
              <Typography variant='body2' textAlign={'center'}>
                {changeSignTooltipTitle[status]}
              </Typography>
            }
            variant='light'
            placement='top'
          >
            <div>
              <InfoIcon fontSize='medium' sx={{ fill: changeSignTooltipColor[status] }} />
            </div>
          </Tooltip>
        )}
      </Stack>

      {access && changeSignContent}

      <ConfirmDialog />
    </ChangeSignWrapper>
  )
}
