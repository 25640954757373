import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Drawer, Stack, Tab } from '@mui/material'
import { useGetProjectByIdQuery } from 'api/projects'
import { useGetTanglIntegrationStatusQuery } from 'api/settings'
import { FormikProvider } from 'formik'
import useFilteredEmployees from 'hooks/useFilteredEmployees'
import { useSnackbar } from 'notistack'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useCreateTimAndUploadMutation,
  useDeleteTimMutation,
  useGetTimSideMenuQuery,
  useUpdateTimMutation,
} from '../../../../api/tim'
import { TimApiData } from '../../../../api/tim/types'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'
import { useForm } from '../../../../hooks/useForm'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import useQuery from '../../../../hooks/useQuery'
import { defaultProjectInfoSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { setAllowScrolling } from '../../../../store/slices/remarks/remarks'
import { timFilterSelector } from '../../../../store/slices/tim/selectors/tim.selectors'
import { setTimFilter } from '../../../../store/slices/tim/tim'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { DATE_FORMAT } from '../../../../utils/constants'
import { formatDateToString, formatToDate } from '../../../../utils/formatDate'
import { DrawerInfoForm } from '../DrawerInfoForm'
import { DrawerVersionView } from '../DrawerVersionView'
import { TanglStatus } from '../TanglStatus'
import { DrawerHeader, DrawerWrapper, StyledButtonGroup } from './ModelDrawer.styles'
import { ModelDrawerConfirmTrigger, ModelDrawerProps, ModelFormData } from './ModelDrawer.types'
import { modelValidation } from './ModelDrawer.utils'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

function a11yProps(index: number) {
  return {
    id: `agreement-tab-${index}`,
    'aria-controls': `agreement-tabpanel-${index}`,
  }
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <Stack
      flex={value === index ? 1 : 0}
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`tim-drawer-tab-${index}`}
    >
      {value === index && <>{children}</>}
    </Stack>
  )
}

export const ModelDrawer = ({ open, objectId, forUpdateData, closeDrawer, setDefaultTab }: ModelDrawerProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const dispatch = useAppDispatch()
  const queryHandler = useQuery()
  const { enqueueSnackbar } = useSnackbar()
  const [tabUsed, setTabUsed] = useState<number>(0)
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const { allTims } = useTypedSelector(timFilterSelector)
  const { data: sideMenuData } = useGetTimSideMenuQuery({ id: projectId })
  const { menuList: objectList } = sideMenuData || {}
  const selectedObjectNumber = objectList?.find((item) => item.obj.objId === objectId)?.obj.number
  const [createTimAndUpload, createTimAndUploadResponse] = useCreateTimAndUploadMutation()
  const [updateTim, updateTimResponse] = useUpdateTimMutation()
  const [deleteTim, deleteTimResponse] = useDeleteTimMutation()

  const modelTitle = `${project.shifrName || ''}-${selectedObjectNumber || ''}-`

  const { data: statusConnectionResponse } = useGetTanglIntegrationStatusQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const { status: statusConnection } = statusConnectionResponse || {}

  const { data: projectData } = useGetProjectByIdQuery({ id: projectId }, { skip: !open })

  const { data } = projectData || {}
  const { responsiblePersons } = data || {}

  const setProjectArchitector = useCallback((): string | number => {
    if (responsiblePersons) {
      const projectArchitectorName = responsiblePersons.architector.name
      const projectArchitectorId = responsiblePersons.architector.id
      if (projectArchitectorId || projectArchitectorName) {
        return projectArchitectorId || projectArchitectorName
      }
    }
    return ''
  }, [responsiblePersons])

  const setProjectClient = useCallback((): string | number => {
    if (responsiblePersons) {
      const projectClientName = responsiblePersons.client.name
      const projectClientId = responsiblePersons.client.id
      if (projectClientId || projectClientName) {
        return projectClientId || projectClientName
      }
    }
    return ''
  }, [responsiblePersons])

  const initialValues = useMemo((): ModelFormData => {
    return {
      title: forUpdateData ? forUpdateData.title || '' : modelTitle,
      description: forUpdateData ? forUpdateData.description || '' : '',
      status: forUpdateData ? forUpdateData.status || 'Не установлен' : 'Не установлен',
      objectId: forUpdateData ? forUpdateData.objectId : objectId || '',
      rdpId: forUpdateData ? forUpdateData.rdpId || '' : '',
      architectorData: forUpdateData
        ? forUpdateData.architectorId || forUpdateData.architectorName || ''
        : setProjectArchitector(),
      clientData: forUpdateData ? forUpdateData.clientId || forUpdateData.clientName || '' : setProjectClient(),
      plannedDate: forUpdateData ? formatToDate(forUpdateData.plannedDate) : null,
      actualDate: forUpdateData ? formatToDate(forUpdateData.actualDate) : null,
      file: null,
    }
  }, [forUpdateData, modelTitle, objectId, setProjectArchitector, setProjectClient])

  const onSubmit = (values: ModelFormData) => {
    const commonReqData: TimApiData = {
      objectId: Number(values.objectId),
      plannedDate: values.plannedDate ? formatDateToString(values.plannedDate, DATE_FORMAT) : null,
      rdpId: values.rdpId ? values.rdpId : null,
      status: values.status === 'Не установлен' || values.status === '' ? null : values.status,
      title: values.title,
      description: values.description,
      actualDate: values.actualDate ? formatDateToString(values.actualDate, DATE_FORMAT) : null,
      architectorId: typeof values.architectorData === 'number' ? values.architectorData : null,
      architectorName:
        !!values.architectorData && typeof values.architectorData === 'string' ? values.architectorData : undefined,
      clientId: typeof values.clientData === 'number' ? values.clientData : null,
      clientName: !!values.clientData && typeof values.clientData === 'string' ? values.clientData : undefined,
    }

    const file = values.file?.length && values.file[0] instanceof File ? values.file[0] : null

    forUpdateData
      ? updateTim({
        id: projectId,
        timId: forUpdateData.timId,
        ...commonReqData,
      })
      : createTimAndUpload({
        id: projectId,
        file,
        tim: commonReqData,
      })
  }

  const { formik } = useForm({
    validationSchema: modelValidation,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values),
  })

  const { values, dirty, isValid, setFieldValue } = formik

  const onTabChange = (value: number) => {
    if (value === 1) {
      if (dirty) {
        setConfirmTrigger('switch')
        openConfirm(value)
      } else {
        setTabUsed(value)
      }
    }
    if (value === 0) {
      setTabUsed(value)
    }
  }

  const switchTabConfirm = (confirm: boolean, value: number) => {
    if (confirm) {
      setTabUsed(value)
      formik.resetForm()
    }
  }

  const onDeleteModel = () => {
    setConfirmTrigger('delete')
    openConfirm()
  }

  const deleteModelConfirm = (confirm: boolean) => {
    if (confirm && forUpdateData?.timId) {
      deleteTim({
        id: projectId,
        timId: forUpdateData.timId,
      })
    }
  }

  const [confirmTrigger, setConfirmTrigger] = useState<ModelDrawerConfirmTrigger>('switch')

  const dataForConfirmDialog: Record<ModelDrawerConfirmTrigger, UseExitConfirmProps> = {
    switch: {
      handleConfirm: switchTabConfirm,
      title: 'Сменить режим формы?',
      body: <>Режим формы будет изменен, а все введенные данные безвозвратно утеряны.</>,
    },
    delete: {
      handleConfirm: deleteModelConfirm,
      title: 'Удалить модель?',
      body: <>Модель будет удалена без возможности восстановить.</>,
      denyButtonText: 'Отменить',
      confirmButtonColor: 'error',
      confirmButtonText: 'Удалить',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmTrigger])

  useMutationHandlers(
    createTimAndUploadResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(`ТИМ-модель успешно добавлена.`, { variant: 'success' })
        if (
          statusConnection === 'ERROR_ACCESS' ||
          statusConnection === 'ERROR_AUTH' ||
          statusConnection === 'ERROR_COMPANY'
        ) {
          enqueueSnackbar(`Не удалось передать модель в Tangl.`, { variant: 'error' })
        }
        setDefaultTab()
        dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: true, mode: 'create' } }))
        if (data.objectId !== objectId) {
          queryHandler.set('obj', data.objectId)
          !allTims && dispatch(setTimFilter({ timFilter: { objectFiltered: data.objectId } }))
        }
        closeDrawer(false)
      }
    },
    (error) => {
      if ('data' in error && error.data === 'title_already_exist') {
        enqueueSnackbar(`ТИМ-модель с таким наименованием уже существует.`, { variant: 'error' })
      } else {
        enqueueSnackbar(`Не удалось добавить ТИМ-модель`, { variant: 'error' })
      }
    },
  )

  useMutationHandlers(
    updateTimResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(`ТИМ-модель успешно изменена.`, { variant: 'success' })
        setDefaultTab()
        dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: true, mode: 'edit', id: data.id } }))
        if (data.objectId !== objectId) {
          queryHandler.set('obj', data.objectId)
          !allTims && dispatch(setTimFilter({ timFilter: { objectFiltered: data.objectId } }))
        }
        closeDrawer(false)
      }
    },
    (error) => {
      if ('data' in error && error.data === 'title_already_exist') {
        enqueueSnackbar(`ТИМ-модель с таким наименованием уже существует.`, { variant: 'error' })
      } else {
        enqueueSnackbar(`Не удалось изменить ТИМ-модель`, { variant: 'error' })
      }
    },
  )

  useMutationHandlers(
    deleteTimResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(`ТИМ-модель успешно удалена.`, { variant: 'success' })
        closeDrawer(false)
      }
    },
    (error) => {
      if (error) {
        enqueueSnackbar(`Не удалось удалить ТИМ-модель`, { variant: 'error' })
      }
    },
  )

  useEffect(() => {
    if (!open) {
      formik.resetForm()
      setTabUsed(0)
    }
  }, [open])

  useEffect(() => {
    if (values.status === 'Согласовано' && !forUpdateData?.actualDate) {
      setFieldValue('actualDate', new Date())
    }

    if (values.status !== 'Согласовано') {
      setFieldValue('actualDate', null)
    }
  }, [values.status])

  return (
    <Drawer anchor='right' open={open} onClose={() => closeDrawer(dirty)}>
      <DrawerWrapper>
        <DrawerHeader variant='h2'>
          {forUpdateData ? 'Редактирование ТИМ-модели' : 'Добавление ТИМ-модели'}
        </DrawerHeader>
        {!forUpdateData && <TanglStatus />}
        <FormikProvider value={formik}>
          <Stack flex={1}>
            {forUpdateData && (
              <StyledButtonGroup variant='fullWidth' value={tabUsed} onChange={(e, newValue) => onTabChange(newValue)}>
                <Tab disableRipple {...a11yProps(0)} label='Информация о модели' />
                <Tab disableRipple {...a11yProps(1)} label='Версии' />
              </StyledButtonGroup>
            )}

            <CustomTabPanel value={tabUsed} index={0}>
              <DrawerInfoForm
                loading={createTimAndUploadResponse.isLoading}
                forUpdateData={forUpdateData}
                open={open}
              />
              <Stack position='sticky' bottom={0} alignItems='stretch'
                flex={1} justifyContent='flex-end' sx={{ backgroundColor: '#fff' }}>
                <Stack direction='row' spacing={2} py={2.5}>
                  <Button
                    onClick={formik.submitForm}
                    type='submit'
                    disabled={createTimAndUploadResponse.isLoading || !isValid || !dirty}
                    color='success'
                    size='medium'
                    fullWidth
                  >
                    Сохранить
                  </Button>
                  <Button size='medium' fullWidth onClick={() => closeDrawer(dirty)}>
                    Отменить
                  </Button>
                </Stack>
                {forUpdateData && (
                  <Button onClick={onDeleteModel} startIcon={<DeleteIcon />} variant='text' color='error' sx={{ mb: 2 }}>
                    Удалить модель
                  </Button>
                )}
              </Stack>
            </CustomTabPanel>

            <CustomTabPanel value={tabUsed} index={1}>
              {forUpdateData && <DrawerVersionView onClose={closeDrawer} timId={forUpdateData.timId} />}
            </CustomTabPanel>
          </Stack>
        </FormikProvider>
      </DrawerWrapper>
      <ConfirmDialog />
    </Drawer>
  )
}
