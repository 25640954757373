import { FC, ReactElement } from 'react'
import { InfoCardAdornmentMediumVariant, InfoCardProps, InfoCardSize } from './InfoCard.types'
import { MediumTemplateWrapper, SmallTemplateWrapper } from './InfoCard.styles'
import { Stack, Typography } from '@mui/material'
import { theme } from '../../../../styles/theme'
import { useGetHeaderStatistics } from './hooks/useGetHeaderStatistics'

export const InfoCard: FC<InfoCardProps> = ({
  size,
  smallVariant,
  mediumVariant,
  gridArea,
  onClick,
  title,
  adornmentVariant,
  statData,
  content,
}) => {
  const { MediumTemplateHeaderStatistics } = useGetHeaderStatistics({ variant: mediumVariant, statData })

  const MediumTemplateHeaderAdornment: Record<InfoCardAdornmentMediumVariant, ReactElement> = {
    stat: MediumTemplateHeaderStatistics,
    icon: <></>,
  }

  const SmallTemplate: ReactElement = (
    <SmallTemplateWrapper onClick={onClick}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Typography
          variant='body2'
        >
          {title}
        </Typography>

        icon
      </Stack>

      {content}
    </SmallTemplateWrapper>
  )

  const MediumTemplate: ReactElement = (
    <MediumTemplateWrapper onClick={onClick} /* gridArea={gridArea} */>
      <Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
        <Typography
          variant='body1'
          textAlign='left'
          color={theme.palette.primary.main}
        >
          {title}
        </Typography>

        {MediumTemplateHeaderAdornment[adornmentVariant]}
      </Stack>

      {content}
    </MediumTemplateWrapper>
  )


  const ContentBySize: Record<InfoCardSize, ReactElement> = {
    small: SmallTemplate,
    medium: MediumTemplate,
  }

  return ContentBySize[size]
}
