import { Box, Stack, Typography } from '@mui/material'
import styled from 'styled-components'

import { TomColoredInfo } from '../../../Docs/components/TomCard/TomCard.styles'

export const CardWrapper = styled(Stack)`
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: 215px;
  height: fit-content;
  padding: 20px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.bg.white};
  cursor: pointer;

  &:hover {
    box-shadow:
      0px 6px 20px 2px rgba(16, 24, 40, 0.1),
      0px 7px 8px -3px rgba(16, 24, 40, 0.08),
      0px -6px 12px 0px rgba(16, 24, 40, 0.08);
  }
`
export const ScheduleColoredInfo = styled(TomColoredInfo)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  height: 24px;

  & .MuiTypography-root {
    font-weight: 400;
    text-transform: none;
    white-space: nowrap;
  }
`
export const CardSubtitle = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 4px;
  color: ${(props) => props.theme.palette.secondary.gray};
`
export const ElipsisText = styled(Typography)`
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`
export const ScheduleCardIcon = styled(Box)`
  position: absolute;
  top: -12px;
  right: -10px;
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border: 2.667px solid ${(props) => props.theme.palette.bg.gray};
  border-radius: 50%;

  & .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.info.main};
  }
`
