import { Box, IconButton, Stack } from '@mui/material'
import React from 'react'
import { DeleteConfirmProps } from './DeleteConfirm.types'
import { DeleteConfirmButton, DeleteConfirmText, DeleteConfirmTitle, DeleteConfirmWrapper } from './styles'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CloseIcon from '@mui/icons-material/Close'

export const textColor = '#5F2B01'

const DeleteConfirm: React.FC<DeleteConfirmProps> = ({ onDelete, text, onCancel }) => {
  return (
    <DeleteConfirmWrapper direction='row' spacing={1.5} boxShadow={8}>
      <Box>
        <WarningAmberIcon style={{ color: textColor }} />
      </Box>
      <Stack spacing={0.5} sx={{ pt: 0.25 }}>
        <DeleteConfirmTitle textColor={textColor}>Удалить</DeleteConfirmTitle>
        <DeleteConfirmText variant='body2' textColor={textColor}>
          {text}
        </DeleteConfirmText>
      </Stack>
      <Stack direction='row' justifyContent='end' alignSelf='start' spacing={1.5} flex={1}>
        <DeleteConfirmButton onClick={onDelete} textColor={textColor} variant='text' disableRipple>Да</DeleteConfirmButton>
        <IconButton onClick={onCancel} disableRipple><CloseIcon style={{ color: textColor }}/></IconButton>
      </Stack>
    </DeleteConfirmWrapper>
  )
}

export default DeleteConfirm