import { UseGetCardHandlersResponse } from './useGetCardHandlers.types'
import React, { useCallback } from 'react'
import { ProjectPhase } from '../../../../types/project'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store/store'
import { setSelectedProjectPhase } from '../../../../store/slices/documentsPages/projectInfo'
import { clearFilter } from '../../../../store/slices/documentsPages/filter'

export const useGetCardHandlers = (): UseGetCardHandlersResponse => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onCardAnywayClick = useCallback((projectPhase: ProjectPhase) => {
    dispatch(setSelectedProjectPhase({ selectedProjectPhase: projectPhase }))
    dispatch(clearFilter({}))
  }, [])

  const onCardClick = useCallback((projectId: number, projectPhase: ProjectPhase) => {
    onCardAnywayClick(projectPhase)
    navigate(`/project/${projectId}`)
  }, [])

  const onCardObjectsClick = useCallback((
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    projectId: number
  ) => {
    e.stopPropagation()
    onCardAnywayClick('Рабочая документация')
    navigate(`/project/${projectId}/objects`)
  }, [])

  const onCardPdsClick = useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, projectId: number) => {
    e.stopPropagation()
    onCardAnywayClick('Проектная документация')
    navigate(`/project/${projectId}/objects`)
  }, [])

  return {
    onCardClick,
    onCardObjectsClick,
    onCardPdsClick,
  }
}
