import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CreateIcon from '@mui/icons-material/Create'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import EventNoteIcon from '@mui/icons-material/EventNote'
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff'
import { Box, Button, Stack, Typography } from '@mui/material'
import { compareAsc, startOfDay } from 'date-fns'
import React, { useMemo } from 'react'

import { TColoredTitleColor } from '@components/ColoredTitle/ColoredTitle.types'
import { OverflowTextNew } from '@components/OverflowTextNew'

import { theme } from '@styles/theme'

import { getTomIiShortType } from '../../../../api/iiPhase'
import CustomEditIcon from '../../../../assets/icons/CustomEditIcon'
import InfoIcon from '../../../../assets/icons/InfoIcon'
import VersionIcon from '../../../../assets/icons/VersionIcon'
import ColoredTitle from '../../../../components/ColoredTitle'
import Divider from '../../../../components/Divider'
import InfoRow from '../../../../components/InfoRow'
import { InfoRowData } from '../../../../components/InfoRow/InfoRow.types'
import Tooltip from '../../../../components/Tooltip'
import { selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { useTypedSelector } from '../../../../store/store'
import {
  IN_PROD_TOM_TITLE,
  TOM_ANNULLED_TITLE,
  isIiTom,
  isIrdTom,
  isRdTom,
  tomStatusesColors,
} from '../../../../types/tom'
import { formatToDate } from '../../../../utils/formatDate'
import { CreateIconContainer, LabelText } from '../AgGridToms'
import {
  DisabledTitle,
  StyledTomCard,
  StyledTomCardContent,
  StyledTomCardHeader,
  StyledTomCardInfoWrapper,
  TomColoredInfo,
} from './TomCard.styles'
import { TomCardProps } from './TomCard.types'

export const TomCard: React.FC<TomCardProps> = ({ data, onClick, showSendToProd, onSendToProdClick }) => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  const {
    accessStatus,
    id,
    title,
    description,
    version,
    status,
    plannedDate,
    forecastDate,
    actualDate,
    changeMax,
    features,
  } = data
  const { inProd, prodDate } = (isRdTom(data) && data) || {}
  const { type: iiType } = (isIiTom(data) && data) || {}
  const { issueDate, durationDate } = (isIrdTom(data) && data) || {}
  const { changeMode, annulment } = features || {}
  const { annulled } = annulment || {}

  const plannedDateExpired =
    status !== 'Согласовано' && !forecastDate && compareAsc(formatToDate(plannedDate)!, startOfDay(new Date())) === -1
  const forecastDateExpired =
    status !== 'Согласовано' && !!forecastDate && compareAsc(formatToDate(forecastDate)!, startOfDay(new Date())) === -1
  const durationDateExpired = !!durationDate && compareAsc(formatToDate(durationDate)!, startOfDay(new Date())) === -1
  const noAccess = accessStatus === 'close' || undefined

  const rowsData: InfoRowData[] =
    status === 'Не согласуется'
      ? [
        {
          icon: EventNoteIcon,
          title: 'Дата выдачи',
          value: issueDate || '—',
          iconProps: {
            fontSize: 'medium',
            color: noAccess && 'disabled',
          },
        },
        {
          icon: EventAvailableIcon,
          title: 'Срок действия',
          value: durationDate || '—',
          iconProps: {
            fontSize: 'medium',
            color: noAccess && 'disabled',
          },
          status: durationDateExpired,
          statusColor: noAccess ? 'disabled' : 'error',
        },
      ]
      : [
        {
          icon: EventNoteIcon,
          title: 'Плановая дата',
          value: plannedDate || '—',
          color: noAccess ? 'disabled' : theme.palette.error.main,
          iconProps: {
            fontSize: 'medium',
            color: noAccess && 'disabled',
          },
          status: plannedDateExpired,
          statusColor: noAccess ? 'disabled' : 'error',
        },
        {
          icon: EventAvailableIcon,
          title: 'Фактическая дата',
          value: actualDate || '—',
          iconProps: {
            fontSize: 'medium',
            color: noAccess && 'disabled',
          },
        },
        ...(inProd
          ? ([
            {
              icon: EventAvailableIcon,
              title: 'Передано в производство',
              value: prodDate || '—',
              iconProps: {
                fontSize: 'medium',
                color: noAccess && 'disabled',
              },
            },
          ] as InfoRowData[])
          : ([] as InfoRowData[])),
      ]

  const sendToProdHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tomId: number, tomTitle: string) => {
    e.stopPropagation()
    onSendToProdClick?.({ tomId, tomTitle })
  }

  const dateExpired = plannedDateExpired || forecastDateExpired
  const empty = version === null && !changeMax

  const coloredTitleData = useMemo(() => {
    let body: string = status
    let color: TColoredTitleColor = 'noAccess'

    if (annulled) body = TOM_ANNULLED_TITLE
    else if (inProd) body = IN_PROD_TOM_TITLE

    if (noAccess || annulled) color = 'noAccess'
    else if (inProd) color = tomStatusesColors[IN_PROD_TOM_TITLE]
    else if (!inProd) color = tomStatusesColors[status] || theme.palette.bg.shades

    return { body, color }
  }, [status, annulled, inProd, noAccess])

  return (
    <Box style={{ position: 'relative', width: '100%' }}>
      <StyledTomCard onClick={() => onClick(id, accessStatus)} empty={empty}>
        <StyledTomCardHeader
          $disabled={noAccess}
          title={
            <Stack spacing={1}>
              <Stack direction='row' alignItems='start' justifyContent='space-between'>
                <Stack direction='row' alignItems='center' alignSelf={'center'} spacing={1.25}>
                  {selectedProjectPhase === 'Инженерные изыскания' && iiType && (
                    <ColoredTitle body={getTomIiShortType[iiType]} color={noAccess ? 'noAccess' : 'primary'} />
                  )}

                  <ColoredTitle body={coloredTitleData.body} color={coloredTitleData.color} />
                </Stack>

                {noAccess ? (
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <DisabledTitle>Не опубликован</DisabledTitle>
                    <InfoIcon color='disabled' fontSize='small' />
                  </Stack>
                ) : (
                  <Stack direction='row' alignItems='center' spacing={1.25}>
                    {version !== null ? (
                      <Tooltip variant='light' title={`Текущая версия: ${version}`}>
                        <TomColoredInfo
                          direction='row'
                          alignItems='center'
                          spacing={1}
                          customColor='secondary'
                          alignSelf='start'
                        >
                          <VersionIcon fontSize='small' />
                          <Typography variant='tooltip' style={{ marginTop: 2 }}>
                            {version}
                          </Typography>
                        </TomColoredInfo>
                      </Tooltip>
                    ) : null}

                    {!changeMax && changeMode && !annulled && (
                      <Tooltip
                        variant='light'
                        title={undefined}
                        customTitle={
                          <LabelText variant='body2' color='' fontSize={12}>
                            Вносятся изменения
                          </LabelText>
                        }
                      >
                        <CreateIconContainer>
                          <CreateIcon fontSize='small' />
                        </CreateIconContainer>
                      </Tooltip>
                    )}

                    {changeMax && (
                      <Tooltip
                        variant='light'
                        title={undefined}
                        customTitle={
                          <Stack spacing={0.75}>
                            {changeMax && (
                              <Typography variant='body2' fontSize={12}>
                                Текущее изменение: {changeMax}
                              </Typography>
                            )}
                            {!annulled && changeMode && (
                              <LabelText variant='body2' color='' fontSize={12}>
                                Вносятся изменения
                              </LabelText>
                            )}
                          </Stack>
                        }
                      >
                        <TomColoredInfo
                          direction='row'
                          alignItems='center'
                          spacing={0.5}
                          customColor='orange'
                          alignSelf='start'
                        >
                          <CustomEditIcon fontSize='small' />
                          <Typography variant='tooltip' style={{ marginTop: 2 }}>
                            {changeMax}
                          </Typography>
                          {changeMode && !annulled && (
                            <CreateIconContainer>
                              <CreateIcon fontSize='small' />
                            </CreateIconContainer>
                          )}
                        </TomColoredInfo>
                      </Tooltip>
                    )}

                    {empty ? (
                      <Tooltip variant='light' title='Нет загруженных документов'>
                        <TomColoredInfo
                          direction='row'
                          alignItems='center'
                          spacing={1}
                          customColor='error'
                          alignSelf='start'
                        >
                          <FileDownloadOffIcon fontSize='small' />
                          <Typography variant='tooltip' style={{ marginTop: 2 }}>
                            0
                          </Typography>
                        </TomColoredInfo>
                      </Tooltip>
                    ) : null}
                  </Stack>
                )}
              </Stack>
              <Stack>
                <Box>
                  <OverflowTextNew variant='subtitle1'>{title}</OverflowTextNew>
                </Box>
                <Box>
                  <OverflowTextNew rows={2} variant='body2'>
                    {description}
                  </OverflowTextNew>
                </Box>
              </Stack>
            </Stack>
          }
        />

        <Divider />

        <StyledTomCardContent $disabled={noAccess}>
          <Stack spacing={1}>
            <Box>
              <Typography variant='body2'>{status === 'Не согласуется' ? 'Даты' : 'Даты согласования:'}</Typography>
            </Box>
            <StyledTomCardInfoWrapper spacing={1} dateExpired={dateExpired}>
              {rowsData.map((row) => (
                <InfoRow rowData={row} key={row.title} valueVariant='body2' />
              ))}
            </StyledTomCardInfoWrapper>
          </Stack>

          {!annulled && showSendToProd && !inProd && accessStatus === 'open' && (
            <Button
              onClick={(e) => sendToProdHandler(e, id, title)}
              color='secondary'
              size='thin'
              endIcon={<ArrowForwardIcon fontSize='small' />}
              fullWidth
            >
              Передать в производство
            </Button>
          )}
        </StyledTomCardContent>
      </StyledTomCard>
    </Box>
  )
}
