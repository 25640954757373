import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { ExportArchiveAccordionProps } from './AttachmentsExportAccordion.types'
import {
  StyledAttachmentsExportAccordionAccordion,
  StyledAttachmentsExportAccordionDetails,
  StyledAttachmentsExportAccordionSummary
} from './AttachmentsExportAccordion.styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FieldForm from '../../../../../../components/FieldForm'
import { Stack, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { AttachmentsExportFormData, SelectedAttachmentData } from '../AttachmentsExportDrawerForm'
import { DocVariant } from '../../../../../../layouts/DocumentsLayout'
import { AttachmentsExportAttachmentItem } from '../AttachmentsExportAttachmentItem'
import { ExportDocWithVariant } from '../../../../../../api/attachments/types'

export const AttachmentsExportAccordion: FC<ExportArchiveAccordionProps> = ({
  doc,
  docIndex,
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<AttachmentsExportFormData>()

  const [expanded, setExpanded] = useState<boolean>(false)

  const accordionLabel: Record<DocVariant, string> = {
    'change': `Изменение ${doc.number}`,
    'version': `Версия ${doc.number}`,
  }

  const onSummaryClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setExpanded(prevState => !prevState)
    setFieldValue(`docList[${docIndex}].openedManually`, !expanded)
  }

  // set doc checkbox checked when all attachments checked
  useEffect(() => {
    const filteredAttachments = formValues.docList[docIndex]?.attachList?.filter(attachment => !attachment.hideByFilter)

    if (filteredAttachments?.length && filteredAttachments.every(attachment => attachment.selected)) {
      setFieldValue(`docList[${docIndex}].selected`, true)
    }
  }, [formValues.docList[docIndex]?.attachList])

  // close and open doc accordion depending on checked status
  useEffect(() => {
    if (formValues.docList[docIndex]?.selected) {
      setExpanded(false)
      return
    }

    if (formValues.docList[docIndex]?.selected !== undefined && formValues.docList[docIndex]?.openedManually) {
      setExpanded(true)
    }
  }, [formValues.docList[docIndex]?.selected])

  const onAccordionCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const checked = e.target.checked
    setFieldValue(`docList[${docIndex}].selected`, checked)

    setCheckedDoc(doc, docIndex, checked)
  }

  const setCheckedDoc = (doc: ExportDocWithVariant, docIndex: number, checked: boolean) => {
    setFieldValue(
      `docList[${docIndex}].selected`,
      checked
    )

    doc.attachList.forEach((attachment, attachmentIndex) => {
      if (attachment.hideByFilter) return

      setFieldValue(
        `docList[${docIndex}].attachList[${attachmentIndex}].selected`,
        checked
      )
    })

    checked
      ? setFieldValue('selectedAttachments', [...formValues.selectedAttachments, ...getUpdatedSelectedDocAttachments(doc, docIndex, checked)])
      : deleteAttachmentsFromSelected(formValues.selectedAttachments, getUpdatedSelectedDocAttachments(doc, docIndex, checked))
  }

  const getUpdatedSelectedDocAttachments = (
    doc: ExportDocWithVariant,
    docIndex: number,
    checked: boolean
  ): SelectedAttachmentData[] => {
    const attachmentsForPush: SelectedAttachmentData[] = []
    const attachmentsForSplice: SelectedAttachmentData[] = []

    doc.attachList.forEach((attachment, attachmentIndex) => {
      const selectedAttachmentData: SelectedAttachmentData = {
        attachment,
        docIndex,
        attachmentIndex,
      }

      if (checked && !attachment.selected && !attachment.hideByFilter) {
        attachmentsForPush.push(selectedAttachmentData)
        return
      }

      if (!checked && attachment.selected && !attachment.hideByFilter) {
        attachmentsForSplice.push(selectedAttachmentData)
        return
      }
    })

    return checked
      ? attachmentsForPush
      : attachmentsForSplice
  }

  const deleteAttachmentsFromSelected = (
    selectedAttachments: SelectedAttachmentData[],
    deletedAttachments: SelectedAttachmentData[]
  ) => {
    const deletedAttachmentsIds = deletedAttachments.map(attachmentData => attachmentData.attachment.id)
    const selectedAttachmentsAfterDelete = selectedAttachments.filter(attachmentData => !deletedAttachmentsIds.includes(attachmentData.attachment.id))

    setFieldValue('selectedAttachments', selectedAttachmentsAfterDelete)
  }

  const disableExpandIcon = doc.selected

  if (doc.hideByFilter) return null

  return (
    <StyledAttachmentsExportAccordionAccordion
      expanded={expanded}
      disableGutters
    >
      <StyledAttachmentsExportAccordionSummary
        onClick={(e) => onSummaryClick(e)}
        expandIcon={!disableExpandIcon && (
          <ExpandMoreIcon
            fontSize='medium'
            color='primary'
          />
        )}
      >
        <FieldForm
          version='checkbox'
          name={`docList[${docIndex}].selected`}
          checkboxData={{
            label: (
              <Typography
                variant='subtitle2'
                fontWeight={500}
                component='span'
              >
                {accordionLabel[doc.docVariant]}
              </Typography>
            ),
            checked: formValues.docList[docIndex]?.selected,
            onChange: (e: ChangeEvent<HTMLInputElement>) => onAccordionCheckboxChange(e),
            onLabelClick: (e) => e.stopPropagation(),
          }}
        />
      </StyledAttachmentsExportAccordionSummary>

      <StyledAttachmentsExportAccordionDetails>
        <Stack pl={4}>
          {doc?.attachList?.map((attachment, attachmentIndex) => {
            if (attachment.selected || attachment.hideByFilter) return

            return (
              <AttachmentsExportAttachmentItem
                attachment={attachment}
                docIndex={docIndex}
                attachmentIndex={attachmentIndex}
                key={attachment.id}
              />
            )
          })}
        </Stack>
      </StyledAttachmentsExportAccordionDetails>
    </StyledAttachmentsExportAccordionAccordion>
  )
}
