import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

export const AlertIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M12 3C17.5 3 22 6.58 22 11C22 15.42 17.5 19 12 19C10.76 19 9.57 18.82 8.47 18.5C5.55 21 2 21 2 21C4.33 18.67 4.7 17.1 4.75 16.5C3.05 15.07 2 13.13 2 11C2 6.58 6.5 3 12 3ZM11 14V16H13V14H11ZM11 12H13V6H11V12Z'
      />
    </SvgIcon>
  )
}
