import { TomRdMenuItem } from '../../../../api/rdPhase'
import { SelectItem } from '../../../../components/FilterSelectMulti/FilterSelectMultiple.types'

export const getRdFromObjects = (items: TomRdMenuItem[], allSidebarObjects: TomRdMenuItem[] = []) => {
  return (items?.length ? items : allSidebarObjects)
    ?.reduce((accum, item) => {
      item.rdList?.forEach(rdItem => {
        const isExist = !!accum.filter(innerItem => innerItem.value === rdItem.rdID).length
        !isExist && accum.push({ value: rdItem.rdID, title: rdItem.mark })
      })
      return accum
    }, [] as SelectItem<number>[])
}