import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const MultiBookIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M19.2003 5.33334L17.4002 3.66667L15.6001 2H8.39964C7.92222 2 7.46436 2.17559 7.12677 2.48816C6.78919 2.80072 6.59954 3.22464 6.59954 3.66667V17C6.59954 17.442 6.78919 17.866 7.12677 18.1785C7.46436 18.4911 7.92222 18.6667 8.39964 18.6667H19.2003C19.6777 18.6667 20.1355 18.4911 20.4731 18.1785C20.8107 17.866 21.0004 17.442 21.0004 17V7L19.2003 5.33334ZM8.39964 17V3.66667H14.7V7.83334L19.2003 7.80834V17H8.39964ZM10.201 10.3333H17.4014V12H10.201V10.3333ZM10.201 13.6667H14.7013V15.3334H10.201V13.6667ZM4.8001 5.33332V20.3333H17.4008C17.4008 20.7754 17.2112 21.1993 16.8736 21.5118C16.536 21.8244 16.0781 22 15.6007 22H4.8001C4.32269 22 3.86482 21.8244 3.52724 21.5118C3.18965 21.1993 3 20.7754 3 20.3333V6.99998C3 6.55796 3.18965 6.13403 3.52724 5.82147C3.86482 5.50891 4.32269 5.33332 4.8001 5.33332Z'
      />
    </SvgIcon>
  )
}

export default MultiBookIcon