import { Stack } from "@mui/material";
import styled from "styled-components";

export const InnermapDrawerWrapper = styled(Stack)`
  height: 100%;
  width: 800px;
  overflow: hidden;
`
export const DrawerTopBar = styled(Stack)`
  padding: 20px 40px 15px;
  text-align: start;

  & .MuiTypography-root {
    color: ${props => props.theme.palette.primary.main};
  }
`