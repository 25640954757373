import styled from 'styled-components'
import {
  FullExportDrawerFilter,
  FullExportDrawerMainListWrapper,
  FullExportDrawerPhaseButton,
  FullExportDrawerPhaseGroup,
  FullExportDrawerSearch,
} from '../../../../../ProjectInfo/components/ProjectFullExportDrawer/components/FullExportDrawerMainList'

export const FormPocketDrawerMainListWrapper = styled(FullExportDrawerMainListWrapper)`
`

export const FormPocketDrawerFilter = styled(FullExportDrawerFilter)`
`

export const FormPocketDrawerPhaseGroup = styled(FullExportDrawerPhaseGroup)`
`

export const FormPocketDrawerPhaseButton = styled(FullExportDrawerPhaseButton)`
`

export const FormPocketDrawerSearch = styled(FullExportDrawerSearch)`

& ::placeholder {
  font-size: 14px;
  font-weight: 500;
}
`

