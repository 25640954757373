import { Stack } from '@mui/material'
import styled from 'styled-components'

export const RadioWrapper = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 1px;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
`
