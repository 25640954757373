import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { ProjectAgreementSchemeProps } from './ProjectAgreementScheme.types'
import { ProjectAgreementSchemeCardsWrapper, ProjectAgreementSchemeWrapper } from './ProjectAgreementScheme.styles'
import { ProjectAgreementSchemeEmpty } from '../ProjectAgreementSchemeEmpty'
import { ProjectAgreementCard } from '../ProjectAgreementCard'
import { useNavigate } from 'react-router-dom'
import Progress from '../../../../../components/Progress'
import { useTypedSelector } from '../../../../../store/store'
import {
  currentRoadmapSelector,
  isAddStageToExistSelector,
  roadmapFormModeSelector
} from '../../../../../store/slices/agreement'
import { useAdvices } from '../../hooks/useAdvices'
import { AdvicesState } from '../../hooks/useAdvices.types'
import { flushSync } from 'react-dom'
import { LockIcon } from '../../../../../layouts/AgreementLayout/AgreementNavbarContent/components/AgreementNavbarPhaseItem/AgreementNavbarPhaseItem.styles'
import { profileSelector } from '../../../../../store/slices/profile'


export const ProjectAgreementScheme: FC<ProjectAgreementSchemeProps> = ({
  stages,
  isStagesFetching,
  onAddStageClick,
}) => {
  const navigate = useNavigate()
  const currentRoadmap = useTypedSelector(currentRoadmapSelector)
  const roadmapFormMode = useTypedSelector(roadmapFormModeSelector)
  const isAddStageToExist = useTypedSelector(isAddStageToExistSelector)
  const { employeeId, role } = useTypedSelector(profileSelector)

  const [emptyAdviceShow, setEmptyAdviceShow] = useState<boolean>(false)
  const [aloneAdviceShow, setAloneAdviceShow] = useState<boolean>(false)

  const checkEditingAccess = useCallback((stageId: number) => {
    const responsibleId = stages.find(stage => stage.id === stageId)?.person.id
    const isAdmin = role === 'admin'
    const isResponsibleRoadmap = currentRoadmap.person?.id === employeeId
    if (isAdmin || isResponsibleRoadmap || employeeId === responsibleId) {
      return true
    }
    return false
  }, [currentRoadmap, employeeId, role, stages])

  useEffect(() => {
    /* flushSync(() => {
      //setEmptyAdviceShow
    }) */
    setEmptyAdviceShow(stages.some(stage => stage.results.filter(result => result.type).some(result => !result.nextStage?.id && !result.isFinal)))
    setAloneAdviceShow(stages.some(stage => stage.isAlone))
  }, [stages])

  const onCardClick = (stageId: number) => {
    const hasAccess = checkEditingAccess(stageId)
    if (hasAccess) {
      navigate(`edit/${stageId}?roadmapId=${currentRoadmap.id}&phase=${currentRoadmap.phase}`)
    }
  }

  const advicesData: AdvicesState = useMemo(() => ({
    topLeft: [
      {
        variant: 'regular',
        show: isAddStageToExist,
        priorityOrder: 1,
        backgroundColor: 'blue',
        text: 'Выберите, между какими этапами должен быть добавлен новый'
      },
      {
        variant: 'expand',
        priorityOrder: 2,
        backgroundColor: 'blue',
        expandTitle: 'Недоступно к редактированию',
        permanentExpired: true,
        ExpandIcon: <LockIcon fontSize='medium' />,
        items: [
          {
            show: currentRoadmap.isLocked,
            priorityOrder: 1,
            text: 'По схеме существуют запущенные процедуры согласования, редактирование невозможно'
          },
        ]
      },
      {
        variant: 'expand',
        priorityOrder: 3,
        backgroundColor: 'orange',
        items: [
          {
            show: currentRoadmap.isBroken,
            priorityOrder: 1,
            text: 'Переходы по схеме согласования не ведут к завершению процедуры'
          },
          {
            show: emptyAdviceShow,
            priorityOrder: 2,
            text: 'Для одного или нескольких этапов не заполнен результат согласования'
          },
          {
            show: aloneAdviceShow,
            priorityOrder: 3,
            text: 'Один или несколько этапов не включены в последовательность согласования'
          },
        ]
      },
    ],
    bottomLeft: [
      {
        variant: 'regular',
        show: !!stages.length,
        priorityOrder: 1,
        backgroundColor: 'white',
        text: 'Для просмотра сценариев прохождения согласования, наведите курсор на карточку этапа'
      },
    ],
  }), [stages, currentRoadmap.isLocked, currentRoadmap.isBroken, isAddStageToExist, emptyAdviceShow, aloneAdviceShow])

  const { TopLeftAdviceElement, BottomLeftAdviceElement } = useAdvices({
    ...advicesData,
    hideAdvices: !stages.length || roadmapFormMode === 'create',
  })

  return (
    <ProjectAgreementSchemeWrapper>
      <ProjectAgreementSchemeCardsWrapper>
        {isStagesFetching
          ? <Progress />
          : stages.length > 0 && currentRoadmap?.id && roadmapFormMode !== 'create'
            ? (
              <>
                {stages.map((stage, index) => (
                  <ProjectAgreementCard
                    stage={stage}
                    onCardClick={onCardClick}
                    onAddStageClick={onAddStageClick}
                    isFirst={index === 0}
                  />
                ))}
              </>
            )
            : <ProjectAgreementSchemeEmpty />
        }
      </ProjectAgreementSchemeCardsWrapper>

      {TopLeftAdviceElement}
      {BottomLeftAdviceElement}
    </ProjectAgreementSchemeWrapper>
  )
}
