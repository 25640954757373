import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import styled from 'styled-components'

/****************************************** Docs Accordion *************************************************/
export const DocsStyledAccordion = styled(Accordion)`
  box-shadow: none;
  width: 100%;

  &::before {
    display: none;
  }
  & .MuiAccordionSummary-expandIconWrapper {
    order: -1;
  }
  &.Mui-expanded {
    margin: 0px;
  }
`
export const DocsStyledAccordionSummary = styled(AccordionSummary)<{ $isSelected?: boolean; $level: number }>`
  min-height: 34px;
  padding: 6px 16px;
  padding-left: ${(props) => `${16 + props.$level * 8}px`};
  background-color: ${(props) => (props.$isSelected ? props.theme.palette.bg.lightBlue : 'transparent')};
  border-radius: 4px;
  width: 100%;
  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? props.theme.palette.bg.lightBlue : props.theme.palette.bg.shades};
  }
  border: 1px solid transparent;
  border-color: ${(props) => (props.$isSelected ? props.theme.palette.primaryBorderLight : 'transparent')};

  &.Mui-expanded {
    min-height: 38px;
  }
  min-height: 38px;
  gap: 4px;
  justify-content: flex-start;

  & .MuiAccordionSummary-content {
    margin: 0;
    width: 100%;
    padding-left: ${(props) => (props.expandIcon ? '0px' : '24px')};
    justify-content: flex-end;
    overflow: hidden;
    & .MuiTypography-root {
      color: ${(props) => props.theme.palette.text.dark};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-align: start;
    }
  }
`
export const DocsStyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`
