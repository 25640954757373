import { FC } from 'react'
import { IModelExtensionProps } from './ModelExtension.types'
import Tooltip from 'components/Tooltip'
import { Badge } from 'components/Badge'
import { IconWrapper, StyledLabel } from './ModelExtension.styles'
import { tanglBindBadgeColorByStatus, tanglBindInfoByStatus, tanglBindBadgeIconByStatus } from 'api/tim/types'
import { Typography } from '@mui/material'

export const ModelExtension: FC<IModelExtensionProps> = ({ extension, tanglBindStatus }) => {
  const isIFC: boolean = extension === 'IFC'
  const tooltipShown: boolean = isIFC && !!tanglBindStatus
  const isIcon: boolean = !!(tanglBindStatus && tanglBindBadgeIconByStatus[tanglBindStatus])

  return (
    <Tooltip
      title={
        tooltipShown ? (
          <Typography variant='body2' textAlign={'center'}>
            {tanglBindInfoByStatus[tanglBindStatus!]}
          </Typography>
        ) : (
          ''
        )
      }
      variant='light'
    >
      <div style={{ position: 'relative' }}>
        <Badge
          color={tanglBindStatus ? tanglBindBadgeColorByStatus[tanglBindStatus] : ''}
          sx={{ zIndex: 0 }}
          invisible={!isIFC}
        >
          <StyledLabel>{extension}</StyledLabel>

          {isIcon && isIFC && <IconWrapper>{tanglBindBadgeIconByStatus[tanglBindStatus!]}</IconWrapper>}
        </Badge>
      </div>
    </Tooltip>
  )
}
