import styled from 'styled-components'
import { Box } from '@mui/material'
import { LegendColors } from '../../../../../../types/global'

export interface IrdStatisticsItemBarStylesProps {
  bgColor: LegendColors;
  widthPercentage: number;
  priority: number;
}

export const IrdStatisticsItemBar = styled(Box)<IrdStatisticsItemBarStylesProps>`
  position: absolute;
  z-index: ${props => props.priority};
  width: ${props => `${props.widthPercentage}%`};
  height: 12px;
  background-color: ${props => props.theme.palette.legends[props.bgColor]};
  border-radius: 4px;
`
