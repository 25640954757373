import { UseRdExampleDownloadProps, UseRdExampleDownloadReturn, } from './useRdExampleDownload.types'
import React from 'react'
import { GetRdLinkExampleResponse, useGetRdLinkExampleMutation } from '../../../../../../api/rdPhase'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import {
  GetPdLinkExampleResponse,
  GetPpdLinkExampleResponse,
  useGetPdLinkExampleMutation,
  useGetPpdLinkExampleMutation
} from '../../../../../../api/pdPhase'
import { GetIiLinkExampleResponse, useGetIiLinkExampleMutation } from '../../../../../../api/iiPhase'
import { GetIrdLinkExampleResponse, useGetIrdLinkExampleMutation } from '../../../../../../api/irdPhase'
import { GetRdpLinkExampleResponse, useGetRdpLinkExampleMutation } from '../../../../../../api/rdPhase/rdp'

export const useRdExampleDownload = ({ variant, projectId }: UseRdExampleDownloadProps): UseRdExampleDownloadReturn => {
  // Rd
  const [getRdLinkExample, getRdLinkExampleResponse] = useGetRdLinkExampleMutation()

  useMutationHandlers(
    getRdLinkExampleResponse,
    (data: GetRdLinkExampleResponse) => {
      window.location.href = data.data
    }
  )


  // Rdp
  const [getRdpLinkExampleApi, getRdpLinkExampleResponse] = useGetRdpLinkExampleMutation()

  const getRdpLinkExample = () => {
    projectId && getRdpLinkExampleApi({ id: projectId })
  }

  useMutationHandlers(
    getRdpLinkExampleResponse,
    (data: GetRdpLinkExampleResponse) => {
      window.location.href = data.data
    }
  )


  // Pd
  const [getPdLinkExample, getPdLinkExampleResponse] = useGetPdLinkExampleMutation()

  useMutationHandlers(
    getPdLinkExampleResponse,
    (data: GetPdLinkExampleResponse) => {
      window.location.href = data.data
    }
  )


  // Ppd
  const [getPpdLinkExampleApi, getPpdLinkExampleResponse] = useGetPpdLinkExampleMutation()

  const getPpdLinkExample = () => {
    projectId && getPpdLinkExampleApi({ id: projectId })
  }

  useMutationHandlers(
    getPpdLinkExampleResponse,
    (data: GetPpdLinkExampleResponse) => {
      window.location.href = data.data
    }
  )


  // Ii
  const [getIiLinkExampleApi, getIiLinkExampleResponse] = useGetIiLinkExampleMutation()

  const getIiLinkExample = () => {
    projectId && getIiLinkExampleApi({ id: projectId })
  }

  useMutationHandlers(
    getIiLinkExampleResponse,
    (data: GetIiLinkExampleResponse) => {
      window.location.href = data.data
    }
  )


  // Ird
  const [getIrdLinkExampleApi, getIrdLinkExampleResponse] = useGetIrdLinkExampleMutation()

  const getIrdLinkExample = () => {
    projectId && getIrdLinkExampleApi({ id: projectId })
  }

  useMutationHandlers(
    getIrdLinkExampleResponse,
    (data: GetIrdLinkExampleResponse) => {
      window.location.href = data.data
    }
  )


  // Common
  const onExampleDownload: Record<typeof variant, () => void> = {
    'rd': getRdLinkExample,
    'rdp': getRdpLinkExample,
    'pd': getPdLinkExample,
    'ppd': getPpdLinkExample,
    'ii': getIiLinkExample,
    'ird': getIrdLinkExample,
  }


  return {
    onExampleDownload: onExampleDownload[variant],
  }
}
