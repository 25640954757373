import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedModels, TimState } from "./tim.types";

let initialState: TimState = {
  timFilter: {
    allTims: false,
    expiredFilter: false,
    objectFiltered: null,
    rdpFiltered: undefined,
    emptyFilter: false,
  },
  timStatus: null,
  selectedModels: [],
  selectedFromTreeElements: [],
  selectedFromModelElements: [],
  isolatedElements: [],
  isShowAll: false
}

const timSlice = createSlice({
  name: 'tim',
  initialState,
  reducers: {
    setTimFilter: (state, { payload }: PayloadAction<Pick<TimState, 'timFilter'>>) => {
      state.timFilter = payload.timFilter
    },
    setTimStatus: (state, { payload }: PayloadAction<Pick<TimState, 'timStatus'>>) => {
      state.timStatus = payload.timStatus
    },
    setSelectedModels: (state, { payload }: PayloadAction<SelectedModels[]>) => {
      state.selectedModels = payload
    },
    setSelectedFromTreeElements: (state, { payload }: PayloadAction<number[]>) => {
      state.selectedFromTreeElements = payload
    },
    setSelectedFromModelElements: (state, { payload }: PayloadAction<number[]>) => {
      state.selectedFromModelElements = payload
    },
    setIsolatedElements: (state, { payload }: PayloadAction<number[]>) => {
      state.isolatedElements = payload
    },
    setIsShowAll: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowAll = payload
    },
  }
})

export const { reducer: timReducer } = timSlice
export const {
  setTimFilter,
  setTimStatus,
  setSelectedModels,
  setSelectedFromTreeElements,
  setSelectedFromModelElements,
  setIsolatedElements,
  setIsShowAll
} = timSlice.actions