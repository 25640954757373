import { Box, Drawer, Tabs, ToggleButton } from "@mui/material";
import styled from "styled-components";

export const StyledDrawer = styled(Drawer) <{ open: boolean }>`
  width: ${props => props.open ? '360px' : 0};
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  

  & > .MuiPaper-root {
    width: 360px;
    box-shadow: 8px 0 8px rgba(0, 0, 0, 0.06);
    overflow: hidden;
  }
`
export const ButtonWrapper = styled(Box)`
  position: sticky;
  margin-top: auto !important;
  bottom: 0;
  padding: 20px 0;
  display: flex;
  gap: 10px;
  background-color: ${props => props.theme.palette.bg.white};

  & .MuiButtonBase-root {
    max-width: none;
    font-size: 14px;
  }

  & .MuiIconButton-root {
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.text.light};
    border-radius: 6px;

    &.Mui-disabled {
      background-color: #0000001f;
      color: #00000042;
    }
  }
`
export const StyledButtonGroup = styled(Tabs)`
  width: 100%;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  min-height: 40px;

  .MuiTabs-flexContainer {
    height: 40px;
    gap: 8px;
    align-items: center;
  }

  & .MuiTab-fullWidth {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 40px;
    min-height: min-content;
    padding: 0;
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    background-color: ${props => props.theme.palette.bg.shades};
    color: ${props => props.theme.palette.primary.main};

    svg {
      height: 24px;
      width: 24px;
    }
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .Mui-selected {
    color: ${props => props.theme.palette.text.light};
    background-color: ${props => props.theme.palette.primary.main};
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
  }
  
  .Mui-disabled {
    background-color: ${props => props.theme.palette.text.dark + '1F'};
    color: ${props => props.theme.palette.disabled};
  }
`
export const MetaButton = styled(ToggleButton)`
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 6px;
  background-color: ${props => props.theme.palette.bg.shades};
  color: ${props => props.theme.palette.primary.main};
  border: none;

  &.Mui-disabled {
    background-color: ${props => props.theme.palette.text.dark + '1F'};
    color: ${props => props.theme.palette.disabled};
    border: none;
  } 

  &.Mui-selected {
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.text.light};

    :hover {
      background-color: ${props => props.theme.palette.primary.main};
    }
  }

  
`