import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserAccessInfo, UserCompanyInfo } from '../../../api/users/types'
import { ProfileState } from './profile.types'
import { UpdateProfileStatePayload } from './profile.payloads'

let initialState: ProfileState = {
  profile: {
    access: {} as UserAccessInfo,
    company: {} as UserCompanyInfo,
  } as ProfileState['profile'],
  currentCompanyId: 0,
}

const updateState = (
  prevState: Partial<ProfileState>,
  payload: Partial<ProfileState>
) => {
  const { profile, currentCompanyId } = payload
  prevState.profile = profile
  prevState.currentCompanyId = currentCompanyId
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfileState: (state, { payload }: PayloadAction<UpdateProfileStatePayload>) => {
      updateState(state, payload)
    },
  },
})

export const { reducer: profileReducer } = profileSlice

export const { updateProfileState } = profileSlice.actions
