import React, { FC } from 'react'
import { FormItem } from '../FormItem'
import { FormTextField, FormTitledWrapper } from '../FormLeftBlock/FormLeftBlock.styles'

export const LinkBlock: FC = () => {
  return (
    <FormTitledWrapper
      title='Ссылка для перехода на внешний ресурс'
      variant='primary'
      contentWrapper='stack'
      bordered='border'
    >
      <FormItem
        title='Ссылка'
        input={(
          <FormTextField
            version='project'
            name='link.url'
            placeholder='https://yourlink.com'
          />
        )}
      />
      <FormItem
        title='Название'
        input={(
          <FormTextField
            version='project'
            name='link.name'
            placeholder='Название для отображения, макс. 30 символов'
            inputProps={{ maxLength: 30 }}
          />
        )}
      />
    </FormTitledWrapper>
  )
}
