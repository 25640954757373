import { Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const ElipsisText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`
export const StyledLabel = styled(Stack)`
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${props => props.theme.palette.bg.shades};
`
export const InfoText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${props => props.theme.palette.secondary.dark};
`