import styled from 'styled-components'
import { Stack, Typography } from '@mui/material'
import { AdviceBackgroundColor, ProjectAgreementAdvicePositionProps } from './ProjectAgreementAdvice.types'

interface ProjectAgreementAdviceWrapper extends ProjectAgreementAdvicePositionProps {
  backgroundColor: AdviceBackgroundColor
}

export const ProjectAgreementAdviceWrapper = styled(Stack)<ProjectAgreementAdviceWrapper>`
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 40px;
  padding: 8px 10px;
  background-color: ${props => props.backgroundColor === 'orange' && props.theme.palette.warning.main};
  background-color: ${props => props.backgroundColor === 'white' && props.theme.palette.bg.white};
  background-color: ${props => props.backgroundColor === 'blue' && props.theme.palette.info.light};
  border-radius: ${props => props.borderRadius};

  & .MuiSvgIcon-root {
    color: ${props => props.backgroundColor === 'orange' && props.theme.palette.text.light};
    color: ${props => props.backgroundColor === 'white' && props.theme.palette.secondary.dark};
    color: ${props => props.backgroundColor === 'blue' && props.theme.palette.text.light};
  }
`

interface ProjectAgreementAdviceTextProps {
  parentBackgroundColor: AdviceBackgroundColor
}

export const ProjectAgreementAdviceText = styled(Typography)<ProjectAgreementAdviceTextProps>`
  color: ${props => props.parentBackgroundColor === 'orange' && props.theme.palette.text.light};
  color: ${props => props.parentBackgroundColor === 'white' && props.theme.palette.secondary.dark};
  color: ${props => props.parentBackgroundColor === 'blue' && props.theme.palette.text.light};
`