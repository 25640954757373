import { Stack } from "@mui/material";
import Button from "components/Button";
import styled from "styled-components";

export const RemarkDirectionCardWrapper = styled(Stack)`
  padding: 12px 10px;
  border: 1px solid #d1d8fa;
  border-radius: 6px;
  gap: 20px;
`;

export const SmallButton = styled(Button)`
  min-width: 30px;
  width: 30px;
  height: 30px;
`;

export const RemarkCardDescription = styled(Stack)`
  padding: 9px 4px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 4px;
`;

export const AttachmentWrapper = styled(Stack)`
  &:hover {
    cursor: pointer;
  }
`;
