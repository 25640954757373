import React, { useEffect, useMemo } from 'react'
import { PdDrawerFormProps, PdFormData } from './PdDrawerForm.types'
import { isPd } from '../../../../api/pdPhase'
import { allProjectsTypes, projectTypeShort } from '../../../../types/project'
import { useForm } from '../../../../hooks/useForm'
import { Form, FormikProvider } from 'formik'
import { Stack } from '@mui/material'
import FieldForm from '../../../../components/FieldForm'
import Button from '../../../../components/Button'
import { PdDrawerFormWrapper } from './styles'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import SelectFormField from '../../../../components/SelectFormField'
import { useSubmitPd } from './hooks/useSubmitPd'

const PdDrawerForm: React.FC<PdDrawerFormProps> = ({
  variant,
  projectId,
  item,
  onFormChange,
  onClose,
  onSuccessCreate,
}) => {
  const { mark, fullName, description } = item || {}
  const { type } = (item && isPd(item) && item) || {}

  const initialValues: PdFormData = useMemo(() => ({
    mark: mark || '',
    fullName: fullName || '',
    description: description || '',
    type: type || '',
  }), [item])

  const { onSubmit, validation } = useSubmitPd({
    variant,
    item,
    onClose,
    projectId,
    onSuccessCreate,
  })

  const { formik } = useForm({
    validationSchema: validation,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { dirty, isSubmitting } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  return (
    <PdDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} component={Form}>
          <Stack spacing={1.5}>
            <FieldForm
              version='rd'
              name='mark'
              placeholder='Марка'
            />
            <FieldForm
              version='rd'
              name='fullName'
              placeholder='Полное наименование'
              multiline
              minRows={2}
            />
            <FieldForm
              version='rd'
              name='description'
              placeholder='Добавьте примечание если это необходимо'
              multiline
              minRows={2}
            />
          </Stack>

          {variant === 'pd' && (
            <SelectFormField<string, PdFormData>
              fieldName='type'
              title='Тип'
              placeholder='Выберите тип проекта'
              icon={InsertDriveFileOutlinedIcon}
              data={allProjectsTypes.map(type => ({
                value: type,
                label: projectTypeShort[type],
                key: type,
              }))}
              wrapperStyles={{
                maxWidth: 220,
                '& .MuiSelect-select': {
                  padding: '5px !important'
                },
                '& .MuiInputLabel-root': {
                  transform: 'translate(14px, 6px) scale(1) !important',

                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(14px, -9px) scale(0.75) !important'
                  }
                }
              }}
            />
          )}

          <Stack direction='row' spacing={2}>
            <Button
              type='submit'
              disabled={isSubmitting}
              color='success'
              size='medium'
              fullWidth
            >
              Сохранить
            </Button>
            <Button size='medium' fullWidth onClick={() => onClose(false, true)}>Отменить</Button>
          </Stack>

        </Stack>
      </FormikProvider>
    </PdDrawerFormWrapper>
  )
}

export default PdDrawerForm
