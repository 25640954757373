import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const BookIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon width='12' height='14' viewBox='0 0 12 14' fill='none' {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0.666748 1.60314C0.666748 0.968227 1.33341 0.333313 2.00008 0.333313H10.0001C10.6667 0.333313 11.3334 0.968227 11.3334 1.60314V11.1269C11.3334 11.4775 11.0349 11.7618 10.6667 11.7618H2.0715C2.15454 12.0786 2.32831 12.3614 2.68155 12.3967H10.6667C11.0349 12.3967 11.3334 12.6809 11.3334 13.0316C11.3334 13.3822 11.0349 13.6665 10.6667 13.6665L7.93694 13.6666C5.62738 13.6666 3.02475 13.6667 2.66575 13.6665C1.28738 13.6633 0.666748 12.2508 0.666748 11.1269V1.60314ZM5.33341 7.95229H8.66675V9.22212H5.33341V7.95229ZM8.66675 5.41262H5.33341V6.68245H8.66675V5.41262ZM5.33341 2.87297H8.66675V4.14279H5.33341V2.87297ZM4.66675 2.87297H3.33341V4.14279H4.66675V2.87297ZM3.33341 5.41262H4.66675V6.68245H3.33341V5.41262ZM4.66675 7.95229H3.33341V9.22212H4.66675V7.95229Z'
      />
    </SvgIcon>
  )
}

export default BookIcon
