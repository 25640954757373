import { StyledButtonName } from "./FormToggleButton.styles"
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined'
import DoneIcon from '@mui/icons-material/Done'
import { FormToggleButtonProps } from "./FormToggleButton.types"
import { ToggleButton } from "@mui/material"

export const FormToggleButton = ({ value, title, operations, ...props }: FormToggleButtonProps) => {

  const selected = operations.includes(value)

  return (
    <ToggleButton
      {...props}
      aria-label={value}
      value={value}>
      <StyledButtonName $active={selected}>
        {title}
      </StyledButtonName>
      {selected
        ? <DoneIcon fontSize="small" sx={{ color: "#fff", marginLeft: "8px"}} />
        : <DoDisturbAltOutlinedIcon fontSize="small" color="disabled" sx={{marginLeft: "8px"}} />}
    </ToggleButton>
  )
}