import styled from "styled-components";
import { colorNotes } from "../../PragmaPdfViewer.types";

interface TextBoxProps {
  left: number
  top: number
  width: number
  height: number
  scale: number
  fs : number
  padding: number
}

export const TextBox = styled.textarea<TextBoxProps>`
  position: absolute;
  left: ${props => props.left + 'px'};
  top: ${props => props.top + 'px'};
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  font-size: ${props => props.fs + 'px'};
  font-family: 'Roboto';
  line-height: 1.2;
  color: ${colorNotes['red']};
  background-color: transparent;
  padding: ${props => props.padding + 'px'};
  padding-top: ${props => props.padding * 1.3 + 'px'};
  border: none;
  outline: none;
  resize: none;
`