import { Stack, Tab, Tabs } from '@mui/material'
import styled from 'styled-components'

import { ExportArchiveDrawerTopBar } from '@pages/Docs'

export const DrawerWrapper = styled(Stack)`
  height: 100%;
  width: 400px;
  overflow: hidden;
`

export const DrawerTopBar = styled(ExportArchiveDrawerTopBar)`
  justify-content: center;
`
export const DrawerContent = styled(Stack)`
  padding: 0 0 20px 20px;
`
export const StyledButtonGroup = styled(Tabs)`
  padding-bottom: 20px;
  padding-top: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  .MuiTabs-flexContainer {
    border: 1px solid ${(props) => props.theme.palette.primaryBorderLight};
    border-radius: 6px;
  }

  & .MuiTab-fullWidth {
    min-height: 40px;
    padding: 8px;
    text-transform: none;
    font-size: 16px;
    font-weight: 400;

    :first-child {
      border-right: 1px solid ${(props) => props.theme.palette.primaryBorderLight};
    }
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .Mui-selected {
    background-color: ${(props) => props.theme.palette.bg.lightBlue};
  }
`

export const StyledTabButton = styled(Tab)``
