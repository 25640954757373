import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat'
import { NumberBlockAbsolute, TitleButton, TitleText, TitleWrapper } from './TitleCell.styles'
import Tooltip from '../../../../../../components/Tooltip'
import { Popover } from '../../../../../../components/Popover'
import { CustomCellProps } from '../cells.types'
import { theme } from '../../../../../../styles/theme'
import LinkIcon from '@mui/icons-material/Link'
import { useAppDispatch } from '../../../../../../store/store'
import { setSelectedProjectPhase } from '../../../../../../store/slices/documentsPages/projectInfo'
import { ProjectPhase } from '../../../../../../types/project'
import { setSelectedMenuView } from '../../../../../../store/slices/documentsPages/menu'
import { useNavigate, useParams } from 'react-router-dom'

export const TitleCell: FC<CustomCellProps> = ({ work }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { projectId: projectIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)

  const { title, numGroup, numWork, descriptionExist, description, bind } = work
  const { phase, obj, tom } = bind
  const [commentOpened, setCommentOpened] = useState<boolean>(false)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null)

  const isBound = !!(phase || obj || tom)

  const redirectToPhase = useCallback((phaseToRedirect: ProjectPhase) => {
    dispatch(setSelectedProjectPhase({ selectedProjectPhase: phaseToRedirect }))
    navigate(`/project/${projectId}/objects`)
  }, [])

  const redirectToObject = useCallback((phaseToRedirect: ProjectPhase) => {
    dispatch(setSelectedProjectPhase({ selectedProjectPhase: phaseToRedirect }))
    navigate(`/project/${projectId}/toms`)
  }, [])

  const redirectToTom = useCallback((phaseToRedirect: ProjectPhase, tomId: number) => {
    dispatch(setSelectedProjectPhase({ selectedProjectPhase: phaseToRedirect }))
    localStorage.setItem('docDetailedNavBarOpen', 'false')
    dispatch(setSelectedMenuView({ selectedMenuView: 'info' }))
    navigate(`/project/${projectId}/tom/${tomId}`)
  }, [])

  const onBindButtonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (!phase) return

    if (tom) {
      redirectToTom(phase, tom)
      return
    }

    if (obj) {
      redirectToObject(phase)
      return
    }

    redirectToPhase(phase)
  }, [bind])

  const onCommentButtonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setPopoverAnchor(e?.currentTarget)
    setCommentOpened(true)
  }, [])

  const onCommentClose = useCallback((e: MouseEvent) => {
    e.stopPropagation()
    setPopoverAnchor(null)
    setCommentOpened(false)
  }, [])

  const titleRef = useRef<HTMLParagraphElement | null>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  useEffect(() => {
    setShowTooltip((titleRef?.current?.scrollHeight || 0) > 42)
  }, [titleRef?.current])

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
      <TitleWrapper>
        <NumberBlockAbsolute bgColor={theme.palette.legends.blue}>
          <Typography variant='body2'>
            {numGroup}.{numWork}
          </Typography>
        </NumberBlockAbsolute>
        <Tooltip title={showTooltip ? title : ''}>
          <TitleText ref={titleRef} variant='body2'>
            {title}
          </TitleText>
        </Tooltip>
      </TitleWrapper>

      <Stack direction='row' alignItems='center'>
        <Tooltip title='Связь с документацией'>
          <TitleButton onClick={onBindButtonClick} disabled={!isBound}>
            <LinkIcon fontSize='medium' />
          </TitleButton>
        </Tooltip>


        <TitleButton onClick={onCommentButtonClick} disabled={!descriptionExist}>
          <ChatIcon fontSize='medium' />
        </TitleButton>
      </Stack>

      <Popover
        open={commentOpened}
        onClose={onCommentClose}
        anchorEl={popoverAnchor}
      >
        <Typography variant='body2' className='comment-title'>
          Комментарий:
        </Typography>
        <Typography variant='body2' className='comment-text'>
          {description}
        </Typography>
      </Popover>
    </Stack>
  )
}
