import styled from 'styled-components'
import { Stack } from '@mui/material'

export const IrdTooltipTitleWrapper = styled(Stack)`
  gap: 4px;
  
  & .MuiTypography-root {
    text-transform: initial;
    color: ${props => props.theme.palette.text.light};
  }
`
