// Общее API для томов

import { api } from "../api";
import {
  TomAccessCompanyStatusUpdateRequest,
  TomAccessCompanyStatusUpdateResponse,
  TomAccessListRequest,
  TomAccessListResponse,
  TomAccessUserStatusUpdateAllRequest,
  TomAccessUserStatusUpdateAllResponse,
  TomAccessUserStatusUpdateRequest,
  TomAccessUserStatusUpdateResponse,
} from "./api.types";

export const tomCommonApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomAccessList: build.query<TomAccessListResponse, TomAccessListRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/tom-access/${tomId}/list`,
        method: 'GET',
        params
      }),
      providesTags: ['TomAccess']
    }),
    tomAccessCompanyStatusUpdate: build.mutation<TomAccessCompanyStatusUpdateResponse, TomAccessCompanyStatusUpdateRequest>({
      query: ({ projectId, ...body }) => ({
        url: `/project/${projectId}/tom-access/company/update`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['TomAccess']
    }),
    tomAccessUserStatusUpdate: build.mutation<TomAccessUserStatusUpdateResponse, TomAccessUserStatusUpdateRequest>({
      query: ({ projectId, ...body }) => ({
        url: `/project/${projectId}/tom-access/person/update`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['TomAccess']
    }),
    tomAccessUserStatusUpdateAll: build.mutation<TomAccessUserStatusUpdateAllResponse, TomAccessUserStatusUpdateAllRequest>({
      query: ({ projectId, ...body }) => ({
        url: `/project/${projectId}/tom-access/person/update-all`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['TomAccess']
    }),
  })
})

export const {
  useGetTomAccessListQuery,
  useTomAccessCompanyStatusUpdateMutation,
  useTomAccessUserStatusUpdateMutation,
  useTomAccessUserStatusUpdateAllMutation,
} = tomCommonApi