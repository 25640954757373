import React from 'react'
import { UseRenderPhaseCardsProps, UseRenderPhaseCardsResponse } from './useRenderPhaseCards.types'
import { PhaseCardContent } from '../../components/PhaseCardContent'
import { InfoCard } from '../../components/InfoCard'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../store/store'
import { setSelectedProjectPhase } from '../../../../store/slices/documentsPages/projectInfo'

export const useRenderPhaseCards = ({
  rd,
  pd,
  ii,
  ird,
  tim,
  onPhaseCardClick,
}: UseRenderPhaseCardsProps): UseRenderPhaseCardsResponse => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onTIMCardClick = () => {
    dispatch(setSelectedProjectPhase({ selectedProjectPhase: 'Рабочая документация' }))
    navigate('tim')
  }

  // Rd
  const { totalObj, totalToms: rdTotalToms, stat: statRd } = rd || {}
  const RdCard = rd ? (
    <InfoCard
      size='medium'
      mediumVariant='rd'
      onClick={() => onPhaseCardClick('Рабочая документация')}
      //gridArea='rd'
      title={
        <>
          Рабочая <br /> документация
        </>
      }
      adornmentVariant='stat'
      statData={{
        firstItem: totalObj !== undefined ? totalObj : '',
        secondItem: rdTotalToms !== undefined ? rdTotalToms : '',
      }}
      content={<PhaseCardContent variant='rd' statRd={statRd!} showTask disableExport={rdTotalToms === 0} />}
    />
  ) : null

  // Pd
  const { totalPPD, totalToms: pdTotalToms, stat: statPd } = pd || {}
  const PdCard = pd ? (
    <InfoCard
      size='medium'
      mediumVariant='pd'
      onClick={() => onPhaseCardClick('Проектная документация')}
      //gridArea='pd'
      title={
        <>
          Проектная <br /> документация
        </>
      }
      adornmentVariant='stat'
      statData={{
        firstItem: totalPPD !== undefined ? totalPPD : '',
        secondItem: pdTotalToms !== undefined ? pdTotalToms : '',
      }}
      content={<PhaseCardContent variant='pd' statPd={statPd!} showTask disableExport={pdTotalToms === 0} />}
    />
  ) : null

  // Ii
  const { totalII, totalToms: iiTotalToms, stat: statIi } = ii || {}
  const IiCard = ii ? (
    <InfoCard
      size='medium'
      mediumVariant='ii'
      onClick={() => onPhaseCardClick('Инженерные изыскания')}
      //gridArea='ii'
      title={
        <>
          Инженерные <br /> изыскания
        </>
      }
      adornmentVariant='stat'
      statData={{
        firstItem: totalII !== undefined ? totalII : '',
        secondItem: iiTotalToms !== undefined ? iiTotalToms : '',
      }}
      content={<PhaseCardContent variant='ii' statIi={statIi!} disableExport={iiTotalToms === 0} />}
    />
  ) : null

  // Ird
  const { totalIRD, totalToms: irdTotalToms, stat: statIrd } = ird || {}
  const IrdCard = ird ? (
    <InfoCard
      size='medium'
      mediumVariant='ird'
      onClick={() => onPhaseCardClick('Сбор исходных данных')}
      //gridArea='ird'
      title={
        <>
          Исходно-разрешительная <br /> документация
        </>
      }
      adornmentVariant='stat'
      statData={{
        firstItem: totalIRD !== undefined ? totalIRD : '',
        secondItem: irdTotalToms !== undefined ? irdTotalToms : '',
      }}
      content={<PhaseCardContent variant='ird' statIrd={statIrd!} disableExport={irdTotalToms === 0} />}
    />
  ) : null

  // TIM
  const { totalObj: totalObjTim, totalTim, stat: statTim } = tim || {}
  const TimCard = rd ? (
    <InfoCard
      size='medium'
      mediumVariant='tim'
      onClick={onTIMCardClick}
      title={<>ТИМ-модели</>}
      adornmentVariant='stat'
      statData={{
        firstItem: totalObjTim || '',
        secondItem: totalTim || '',
      }}
      content={<PhaseCardContent variant='tim' statTim={statTim!} />}
    />
  ) : null

  return {
    RdCard,
    PdCard,
    IiCard,
    IrdCard,
    TimCard,
  }
}
