import styled from 'styled-components'
import { Stack } from '@mui/material'

export const ChangeItemWrapper = styled(Stack)`
  min-width: 195px;
  max-width: 195px;
  height: fit-content;
  padding: 20px;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 8px;
  
  &:hover {
    cursor: pointer;
  }
`

export const DocChangeImage = styled.img`
  width: 155px;
  height: 220px;
`