import { Stack, Typography } from "@mui/material"
import styled from "styled-components"

export const StyledWrapper = styled(Stack)`
  padding: 10px;
  gap: 12px;
  border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 8px;
  position: sticky;
  top: 82px;
  z-index: 1;
  background-color: #fff;
`
export const StyledTitle = styled(Typography)`
  color: ${props => props.theme.palette.secondary.gray};
  text-align: center;
`