import React, { FC, useState } from 'react'
import { AutocompleteFieldProps, OptionType } from './AutocompleteField.types'
import { StyledAutocompleteField } from './AutocompleteField.styles'
import { useTranslation } from 'react-i18next'
import { useField, useFormikContext } from 'formik'
import { CircularProgress, createFilterOptions, TextField } from '@mui/material'
import { AutocompleteInputChangeReason } from '@mui/base/AutocompleteUnstyled/useAutocomplete'
import { StyledSelectMenuItem } from '../UserManagement/components/UserRoleSelection/styles'

const filter = createFilterOptions<OptionType>()

export const AutocompleteField: FC<AutocompleteFieldProps> = ({
  fieldName,
  data,
  placeholder,
  loading,
  onInputChange: parentOnInputChange,
  customRenderOption,
  ...props
}) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<any>()
  const [disablePlaceholder, setDisablePlaceholder] = useState<boolean>(false)

  const [{ onBlur: onFieldBlur, ...field }, { value, touched, error }] = useField({
    name: fieldName,
  })

  const onInputChange = (e: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    value
      ? setDisablePlaceholder(true)
      : setDisablePlaceholder(false)

    parentOnInputChange?.(e, value, reason)
  }

  const isError = touched && !!error

  const inputColor = data.find(item => item.value === value)?.color || undefined

  return (
    <StyledAutocompleteField
      inputColor={inputColor}
      {...field}
      value={data.find(item => item.value === value)?.label || value || ''}
      onChange={(event, newValue: any) => {
        if (typeof newValue === 'string') {
          setFieldValue(fieldName, newValue)
        } else if (newValue && newValue.inputValue) {
          setFieldValue(fieldName, newValue.inputValue)
        } else if (newValue === null) {
          setFieldValue(fieldName, props.nullable ? null : '')
        } else {
          setFieldValue(fieldName, newValue.value)
        }
      }}
      onInputChange={onInputChange}
      options={data}
      renderInput={params => (
        <TextField
          {...params}
          name={fieldName}
          label={placeholder}
          helperText={isError ? t(error as string) : undefined}
          onBlur={onFieldBlur}
          error={isError}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={16} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={customRenderOption || ((props, option: any) => {
        return (
          <StyledSelectMenuItem
            {...props}
            key={option.key}
            customColor={option.color}
          >
            {option.label}
          </StyledSelectMenuItem>
        )
      })}
      filterOptions={(options: any, params) => {
        const filteredBySelected = options.filter((item: any) => !item.removeFromSelect)
        const filtered = filter(filteredBySelected, params)
        if (!props.freeSolo) return filtered
        const { inputValue } = params
        const isExisting = options.some((option: any) => option.label.toLowerCase().includes(inputValue.toLowerCase().trim()))
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `${props.customFreeSoloMessage || `Добавить "${inputValue}"`}`,
          })
        }
        return filtered
      }}
      disablePlaceholder={disablePlaceholder}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      noOptionsText={props.noOptionsText || 'Ничего не найдено'}
      clearText='Удалить'
      {...props}
    />
  )
}
