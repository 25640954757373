import { useMemo } from 'react'
import { UseGetDataResponse } from './useGetData.types'
import { Object, useGetObjectsByProjectIdQuery } from '../../../../api/rdPhase'
import { Ppd, useGetAllPpdQuery } from '../../../../api/pdPhase'
import { Ii, useGetAllIiQuery } from '../../../../api/iiPhase'
import { filterByFieldNames } from '../../../../utils/filterByFieldNames'
import useSearch from '../../../../hooks/useSearch'
import { useTypedSelector } from '../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../store/slices/documentsPages/projectInfo'
import { Ird, useGetAllIrdQuery } from '../../../../api/irdPhase'
import { Rdp, useGetAllRdpQuery } from '../../../../api/rdPhase/rdp'
import { rdViewTabSelector } from '../../../../store/slices/documentsPages/objects'

export const useGetData = (): UseGetDataResponse => {
  const { searchValue } = useSearch()
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const rdViewTab = useTypedSelector(rdViewTabSelector)

  // Rd objects
  const {
    data: objectsData,
    isFetching: isObjectsFetching
  } = useGetObjectsByProjectIdQuery({ id: project.id },
    { skip: !project.id || selectedProjectPhase !== 'Рабочая документация' || rdViewTab !== 'objects' }
  )
  const { data: objects = [] } = objectsData || {}
  // Rdp
  const {
    data: rdpData,
    isFetching: isRdpsFetching
  } = useGetAllRdpQuery({ id: project.id },
    { skip: selectedProjectPhase !== 'Рабочая документация' || rdViewTab !== 'rdp' }
  )
  const { data: rdps = [] } = rdpData || {}

  // Pd
  const {
    data: pdsData,
    isFetching: isPdsFetching
  } = useGetAllPpdQuery({ id: project.id }, { skip: !project.id || selectedProjectPhase !== 'Проектная документация' })
  const { data: pds = [] } = pdsData || {}


  // Ii
  const {
    data: iisData,
    isFetching: isIisFetching
  } = useGetAllIiQuery({ id: project.id }, { skip: !project.id || selectedProjectPhase !== 'Инженерные изыскания' })
  const { data: iis = [] } = iisData || {}


  // Ird
  const {
    data: irdsData,
    isFetching: isIrdsFetching
  } = useGetAllIrdQuery({ id: project.id }, { skip: !project.id || selectedProjectPhase !== 'Сбор исходных данных' })
  const { data: irds = [] } = irdsData || {}


  // Common
  const filteredData = useMemo(() => {
    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        if (rdViewTab === 'objects') {
          return searchValue && objects?.length
            ? filterByFieldNames<Object>(objects, ['title', 'number', 'description'], searchValue)
            : objects
        }

        return searchValue && rdps?.length
          ? filterByFieldNames<Rdp>(rdps, ['fullName', 'mark', 'description'], searchValue)
          : rdps

      case 'Проектная документация':
        return searchValue && pds?.length
          ? filterByFieldNames<Ppd>(pds, ['fullName', 'mark', 'description'], searchValue)
          : pds

      case 'Инженерные изыскания':
        return searchValue && iis?.length
          ? filterByFieldNames<Ii>(iis, ['fullName', 'mark', 'description'], searchValue)
          : iis

      case 'Сбор исходных данных':
        return searchValue && irds?.length
          ? filterByFieldNames<Ird>(irds, ['fullName', 'mark', 'description'], searchValue)
          : irds
    }
  }, [objects, rdps, pds, iis, irds, searchValue, selectedProjectPhase])

  const isDataLoading = isObjectsFetching || isRdpsFetching || isPdsFetching || isIisFetching || isIrdsFetching
  const isObjectsExist = !!objects?.length
  const isRdpsExist = !!rdps?.length
  const isPdsExist = !!pds?.length
  const isIisExist = !!iis?.length
  const isIrdsExist = !!irds?.length

  return {
    filteredData,
    isDataLoading,
    isObjectsExist,
    isRdpsExist,
    isPdsExist,
    isIisExist,
    isIrdsExist,
  }
}
