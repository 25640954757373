import React, { FC, useCallback, useState } from 'react'
import { ManagementLegendProps } from './ManagementLegend.types'
import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { TabData } from '../../../../../components/Tabs/Tabs.types'
import { ProjectFormData, ProjectManagementTabs } from '../../ProjectManagement.types'
import { useNavigate, useParams } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDeleteProjectMutation } from '../../../../../api/projects'
import { useMutationHandlers } from '../../../../../hooks/useMutationHandlers'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../hooks/useConfirmDialog'
import { useFormikContext } from 'formik'

export const ManagementLegend: FC<ManagementLegendProps> = ({
  tabValue,
  onTabChange,
}) => {
  const navigate = useNavigate()
  const { dirty, isSubmitting, submitForm } = useFormikContext<ProjectFormData>()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<'delete' | 'exit'>('exit')

  const tabsData: TabData<ProjectManagementTabs>[] = [
    { value: 'info', label: 'О проекте' },
    { value: 'additional', label: 'Дополнительно' },
  ]

  const onChange = (e: React.SyntheticEvent, tabValue: ProjectManagementTabs) => {
    onTabChange(tabValue)
  }

  const onReturnClick = useCallback(() => {
    setConfirmDialogTrigger('exit')

    dirty
      ? openConfirm()
      : navigate('/administration/projects')
  }, [dirty])

  const [deleteProject, deleteProjectResponse] = useDeleteProjectMutation()

  const onDeleteClick = useCallback(() => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }, [])

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      navigate('/administration/projects')
    }
  }, [])

  const handleDeleteConfirm = useCallback((confirm: boolean) => {
    if (confirm && projectId) {
      deleteProject({ id: projectId })
    }
  }, [projectId])

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    delete: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить проект?',
      body: <>Проект будет полностью удален <br /> из модуля Pragma.Design</>,
    },
    exit: {
      handleConfirm: handleExitConfirm,
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  useMutationHandlers(
    deleteProjectResponse,
    () => {
      navigate('/administration/projects')
    }
  )

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1.5 }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Box>
          <Button
            onClick={onReturnClick}
            startIcon={<ArrowBackIcon color='secondary' />}
            variant='text'
          >
            <Typography
              variant='buttonMedium'
            >
              К проектам
            </Typography>
          </Button>
        </Box>

        <Tabs value={tabValue} onChange={onChange}>
          {tabsData.map(tabData => (
            <Tab {...tabData} />

          ))}
        </Tabs>
      </Stack>


      <Stack direction='row' alignItems='center' spacing={1.25}>
        {!!projectId && (
          <Button
            onClick={onDeleteClick}
            startIcon={<DeleteIcon fontSize='medium' />}
            size='small'
            variant='text'
            color='error'
          >
            Удалить проект
          </Button>
        )}
        <Button
          onClick={submitForm}
          disabled={isSubmitting}
          variant='outlined'
          size='small'
          color='success'
        >
          Сохранить проект
        </Button>
      </Stack>
      <ConfirmDialog />
    </Stack>
  )
}
