import styled from 'styled-components'
import {
  ProjectDrawerTopBar,
  ProjectDrawerWrapper
} from '../../pages/Administration/AdminProjects/ProjectDrawer/styles'

export const UploadDrawerWrapper = styled(ProjectDrawerWrapper)`
`

export const UploadDrawerTopBar = styled(ProjectDrawerTopBar)`
`
