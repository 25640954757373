import * as yup from 'yup'
import { ProjectPhase } from '../../../../../../types/project'

export const getExportArchiveValidation = (selectedProjectPhase: ProjectPhase) => {
  switch (selectedProjectPhase) {
    case 'Рабочая документация':
      return yup.object({
        selectedRdToms: yup.array().min(1),
      })
    case 'Проектная документация':
      return yup.object({
        selectedPdToms: yup.array().min(1),
      })
    case 'Инженерные изыскания':
      return yup.object({
        selectedIiToms: yup.array().min(1),
      })
    case 'Сбор исходных данных':
      return yup.object({
        selectedIrdToms: yup.array().min(1),
      })
  }
}