import { UseGetStatisticsProps, UseGetStatisticsResponse } from './useGetStatistics.types'
import {
  ProjectStatisticsIi,
  ProjectStatisticsIrd,
  ProjectStatisticsPd,
  ProjectStatisticsRd
} from '../../../../../api/projects/types'
import { ProjectPhase } from '../../../../../types/project'

export const useGetStatistics = ({
  phase,
  statistic,
  statisticPd,
  statisticIi,
  statisticIrd,
}: UseGetStatisticsProps): UseGetStatisticsResponse => {
  const statisticByPhase: Record<ProjectPhase, ProjectStatisticsRd | ProjectStatisticsPd | ProjectStatisticsIi | ProjectStatisticsIrd> = {
    'Рабочая документация': statistic,
    'Проектная документация': statisticPd,
    'Инженерные изыскания': statisticIi,
    'Сбор исходных данных': statisticIrd,
  }

  return {
    statisticByPhase: statisticByPhase[phase]
  }
}
