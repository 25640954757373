import styled from 'styled-components'
import { Stack } from '@mui/material'

export const ExpertiseItemWrapper = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  padding: 9px;
  border: 1px solid #EDEFF3;
  border-radius: 8px;
  
  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.info.main};
  }
`
