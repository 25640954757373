import { FC, ReactElement, ReactNode } from 'react'
import { IPhaseCardsProps } from './PhaseCards.types'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetProjectByIdQuery, useGetProjectDashboardQuery, useGetTimWidgetQuery } from 'api/projects'
import { useRenderPhaseCards } from 'pages/ProjectInfo/hooks/useRenderPhaseCards'
import { ProjectPhase } from 'types/project'
import { useAppDispatch } from 'store/store'
import { clearFilter } from 'store/slices/documentsPages/filter'
import { setSelectedProjectPhase } from 'store/slices/documentsPages/projectInfo'
import { TDashboardPhaseWidget } from 'api/projects/types'

export const PhaseCards: FC<IPhaseCardsProps> = ({ widget }) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { data: projectRes, isFetching } = useGetProjectDashboardQuery({ id: projectId! }, { skip: !projectId })

  const { data: projectData, isFetching: projectFetching } = useGetProjectByIdQuery(
    { id: projectId },
    { skip: !projectId },
  )

  const { data: tim, isFetching: isFetchingTim } = useGetTimWidgetQuery({ id: projectId })

  const { rdAccess } = projectData || {}

  const { data: projectDashboard } = projectRes || {}
  const { project, rd, pd, ii, ird } = projectDashboard || {}

  const onPhaseCardClick = (phase: ProjectPhase) => {
    dispatch(setSelectedProjectPhase({ selectedProjectPhase: phase }))
    dispatch(clearFilter({}))
    navigate(`objects`)
  }

  const { IrdCard, IiCard, PdCard, RdCard, TimCard } = useRenderPhaseCards({
    rd: rdAccess ? rd : undefined,
    pd,
    ii,
    ird,
    tim,
    onPhaseCardClick,
  })

  const widgetByValue: Record<TDashboardPhaseWidget, any> = {
    IRD: IrdCard,
    II: IiCard,
    PD: PdCard,
    RD: RdCard,
    TIM: TimCard,
  }

  return widgetByValue[widget]
}
