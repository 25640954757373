import React, { FC, useCallback, useState } from 'react'
import { RdUploadDrawerFormData, RdUploadDrawerProps } from './RdUploadDrawer.types'
import UploadDrawer from '../../../../components/UploadDrawer'
import { useAppDispatch } from '../../../../store/store'
import { rdApi, UploadRdResponse } from '../../../../api/rdPhase'
import { useRdUpload } from './hooks/useRdUpload'
import { useRdExampleDownload } from './hooks/useRdExampleDownload'
import { pdApi, ppdApi } from '../../../../api/pdPhase'
import { iiApi } from '../../../../api/iiPhase'
import { irdApi } from '../../../../api/irdPhase'
import { rdpApi } from '../../../../api/rdPhase/rdp'

export const RdUploadDrawer: FC<RdUploadDrawerProps> = ({ open, onClose, variant, projectId }) => {
  const dispatch = useAppDispatch()

  const [files, setFiles] = useState<RdUploadDrawerFormData>(null)
  const [responseData, setResponseData] = useState<UploadRdResponse | undefined>(undefined)

  const onRdUpload = (data: UploadRdResponse) => {
    setResponseData(data)
  }

  const { rdsLoading } = useRdUpload({ variant, files, onRdUpload, projectId })

  const onFormSubmit = useCallback((files: File[]) => {
    setFiles(files)
  }, [])

  const onFormChange = useCallback(() => {
    setResponseData(undefined)
  }, [])

  const onUploadDrawerClose = (dirty: boolean, immediately?: boolean) => {
    onClose(dirty, immediately)

    if (responseData) {
      switch (variant) {
        case 'rd':
          dispatch(rdApi.util.invalidateTags([{ type: 'Rd', id: 'LIST' }]))
          break
        case 'rdp':
          dispatch(rdpApi.util.invalidateTags([{ type: 'Rdp', id: 'LIST' }]))
          break
        case 'pd':
          dispatch(pdApi.util.invalidateTags([{ type: 'Pd', id: 'LIST' }]))
          break
        case 'ppd':
          dispatch(ppdApi.util.invalidateTags([{ type: 'Ppd', id: 'LIST' }]))
          break
        case 'ii':
          dispatch(iiApi.util.invalidateTags([{ type: 'Ii', id: 'LIST' }]))
          break
        case 'ird':
          dispatch(irdApi.util.invalidateTags([{ type: 'Ird', id: 'LIST' }]))
          break
      }
    }
    setResponseData(undefined)
    setFiles(null)
  }

  const { onExampleDownload } = useRdExampleDownload({ variant, projectId })

  return (
    <UploadDrawer
      open={open}
      mode='single'
      onSubmit={onFormSubmit}
      responseData={responseData}
      onChange={onFormChange}
      onClose={onUploadDrawerClose}
      title='Загрузка разделов'
      text='Вы можете добавить разделы с помощью exсel документа с уже подготовленными данными.'
      attentionText='В случае если у Вас будут совпадения названий существующих разделов с новыми из файла, новые разделы
                заменят существующие. Это действие нельзя отменить.'
      uploadData={{
        title: 'Загрузить xls(х)',
        text: 'Чтобы загрузить разделы, выберите их из папки на компьютере или просто перетяните его в эту область.',
        formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel']
      }}
      loading={rdsLoading}
      getExampleLink={onExampleDownload}
    />
  )
}
