import { Annotation, colorNotes } from "../../../PragmaPdfViewer.types"
import { UseDefaultToolProps } from "../CanvasAnnotations.types"
import { useState } from 'react'
import { checkIsInsideSelectAnnotation, checkIsMoveCursor, checkIsSelectAnnotation, setDraggingRect, setResizingRect } from "./common"

export const useArrowTool = ({
  canvasRef,
  setAnnotations,
  annotations,
  rotate,
  scale,
  scaleFactor,
  selectedRect,
  isCreateMode,
  selectedAnnotationId,
  setSelectedRect,
  drawAnnotations,
  selectTextAnnotation
}: UseDefaultToolProps): {
  onArrowMouseDown: (e: MouseEvent) => void
  onArrowMouseMove: (e: MouseEvent) => void
  onArrowMouseUp: (e: MouseEvent) => void
  drawArrow: (ctx: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number, id?: number | null) => void
} => {

  const [isDrawing, setIsDrawing] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [resizeHandle, setResizeHandle] = useState<string | null>(null)
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 })
  const [currentRect, setCurrentRect] = useState<{
    x1: number, y1: number, x2: number, y2: number
  } | null>(null)

  const drawArrow = (ctx: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number, id?: number | null) => {
    const pathWidth = 10 * scale
    const angle = Math.atan2(y2 - y1, x2 - x1)

    ctx.strokeStyle = selectedAnnotationId && selectedAnnotationId === id ? colorNotes['blue'] : colorNotes['red']
    ctx.lineWidth = 2 * scale

    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()

    ctx.beginPath()
    ctx.moveTo(x2, y2)
    ctx.lineTo(x2 - pathWidth * Math.cos(angle - Math.PI / 6), y2 - pathWidth * Math.sin(angle - Math.PI / 6))
    ctx.lineTo(x2 - pathWidth * Math.cos(angle + Math.PI / 6), y2 - pathWidth * Math.sin(angle + Math.PI / 6))
    ctx.closePath()
    ctx.fillStyle = selectedAnnotationId && selectedAnnotationId === id ? colorNotes['blue'] : colorNotes['red']
    ctx.fill()
  }

  const onArrowMouseDown = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!canvas || e.target !== canvas || e.buttons !== 1) return
    
    const rect = canvas.getBoundingClientRect()
    const x = (e.clientX - rect.left)
    const y = (e.clientY - rect.top)

    const selected = checkIsSelectAnnotation({
      annotations,
      canvasRef,
      clientX: x,
      clientY: y,
      scale,
      isCreateMode
    })

    const isInsideSelected = checkIsInsideSelectAnnotation({
      annotations,
      canvasRef,
      clientX: x,
      clientY: y,
      isCreateMode
    })

    if (selected) {
      if (selected.type === 'highlight') {
        setSelectedRect(selected)
      } else {
        const { left, top, width, height } = annotations[selected.index].rects!
        const handleSize = 10 * scale
        const isTopLeftHandle =
          Math.abs(x - left * canvas.width) <= handleSize && Math.abs(y - top * canvas.height) <= handleSize
        const isBottomRightHandle =
          Math.abs(x - (left + width) * canvas.width) <= handleSize &&
          Math.abs(y - (top + height) * canvas.height) <= handleSize
        if (isTopLeftHandle || isBottomRightHandle) {
          setResizeHandle(isTopLeftHandle ? 'left' : 'right')
          setSelectedRect(selected)
          setIsResizing(true)
          return
        }
        setSelectedRect(selected)
        setIsDragging(true)
        setStartPosition({ x, y })
      }
    } else if (selectedRect && isInsideSelected && isInsideSelected.type === 'rectangle') {
      setIsDragging(true)
      setStartPosition({ x, y })
    } else if (isInsideSelected && isInsideSelected.type === 'text') {
      selectTextAnnotation(isInsideSelected.index)
    } else {
      setSelectedRect(null)
      setIsDrawing(true)
      setStartPosition({ x, y })
      setCurrentRect({ x1: x, y1: y, x2: x, y2: y })
    }
  }

  const isMouseOverResizeHandle = (x: number, y: number, selectedRect: number | undefined) => {
    if (selectedRect === undefined) return false

    const selectedAnnotation = annotations[selectedRect]
    const handleSize = 5 * scale
    if (selectedAnnotation.type === 'highlight') return false
    if (canvasRef.current && selectedAnnotation.rects) {
      const { height, left, top, width } = selectedAnnotation.rects
      const isTopLeftHandle =
        Math.abs(x - left * canvasRef.current.width) <= handleSize && Math.abs(y - top * canvasRef.current.height) <= handleSize
      const isBottomRightHandle =
        Math.abs(x - (left + width) * canvasRef.current.width) <= handleSize &&
        Math.abs(y - (top + height) * canvasRef.current.height) <= handleSize

      return isTopLeftHandle || isBottomRightHandle
    }
  }
  
  const onArrowMouseMove = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!canvas) return
    const rect = canvas.getBoundingClientRect()
    let x = (e.clientX - rect.left)
    let y = (e.clientY - rect.top)

    if (x < 0) x = 0
    if (y < 0) y = 0
    if (x > rect.width) x = rect.width
    if (y > rect.height) y = rect.height
    const ctx = canvas.getContext('2d')

    if (isDrawing && currentRect) {
      setCurrentRect((prev) => prev ? { ...prev, x2: x, y2: y } : null)
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        drawAnnotations()
        drawArrow(ctx, currentRect.x1, currentRect.y1, x, y)
      }
    }

    else if (isDragging && selectedRect) {
      const dx = x - startPosition.x
      const dy = y - startPosition.y

      const updatedAnnotations = setDraggingRect({
        annotations,
        canvasRef,
        selectedRect,
        deltaX: dx,
        deltaY: dy
      })

      setAnnotations(updatedAnnotations)
      setStartPosition({ x, y })
    }

    else if (isResizing && selectedRect && resizeHandle) {
      const updatedAnnotations = setResizingRect({
        annotations,
        canvasRef,
        selectedRect,
        resizeHandle,
        clientX: x,
        clientY: y,
        scale,
        scaleFactor
      })
      setAnnotations(updatedAnnotations)
    }

    if (ctx) {
      let isOnBorder = false
      isOnBorder = checkIsSelectAnnotation({
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        scale,
        isCreateMode
      }) === null ? false : true
      let isInsideAnnotation = null
      isInsideAnnotation = checkIsInsideSelectAnnotation({
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        isCreateMode
      })
      canvas.style.cursor = isOnBorder
        ? 'pointer'
        : isInsideAnnotation?.type === 'text'
          ? 'text'
          : isInsideAnnotation?.type === 'highlight'
            ? 'pointer'
            : 'default'
    }
    if (selectedRect) {
      const isMove = checkIsMoveCursor({
        annotations,
        selectedRect,
        canvasRef,
        scale,
        clientX: x,
        clientY: y
      })

      if (isMove) canvas.style.cursor = 'move'
    }

    if (resizeHandle || isMouseOverResizeHandle(x, y, selectedRect?.index)) {
      canvas.style.cursor = 'nwse-resize'
    }
  }

  const onArrowMouseUp = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!canvas) return
    const rect = canvas.getBoundingClientRect()
    const x = (e.clientX - rect.left)
    const y = (e.clientY - rect.top)

    if (isDrawing && currentRect && startPosition.x !== x && startPosition.y !== y) {
      const newAnnotation: Annotation = {
        id: null,
        color: 'red',
        rects: {
          left: currentRect.x1 / canvas.width,
          top: currentRect.y1 / canvas.height,
          width: (currentRect.x2 - currentRect.x1) / canvas.width,
          height: (currentRect.y2 - currentRect.y1) / canvas.height
        },
        rotate,
        type: 'arrow'
      }
      setAnnotations(newAnnotation)
    }
    setIsDrawing(false)
    setIsDragging(false)
    setIsResizing(false)
    setCurrentRect(null)
    setResizeHandle(null)
  }

  return { onArrowMouseDown, onArrowMouseMove, onArrowMouseUp, drawArrow }
}
