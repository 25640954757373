import styled from 'styled-components'
import {
  StyledFullExportInnerAccordionSummary
} from '../../../../../ProjectInfo/components/ProjectFullExportDrawer/components/FullExportInnerAccordion'
import {
  StyledFullExportAccordion,
  StyledFullExportAccordionDetails
} from '../../../../../ProjectInfo/components/ProjectFullExportDrawer/components/FullExportAccordion'

export const StyledFormPocketAccordion = styled(StyledFullExportAccordion)`
`

export const FormPocketInnerAccordionSummary = styled(StyledFullExportInnerAccordionSummary)`
`

export const FormPocketAccordionDetails = styled(StyledFullExportAccordionDetails)`
`