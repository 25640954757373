import React, { useCallback, useMemo, useState } from 'react'
import { Checkbox, Select, Stack, Typography, } from '@mui/material'
import { FilterSelectProps, SelectItem } from './FilterSelectMultiple.types'
import { SelectMenuProps } from '../UserManagement/components/UserObjectsSelection'
import { FilterRenderText, FilterSelectMenuItem } from './styles'

export const FilterSelectMultiple = <T extends number | string>({
  startIcon,
  items,
  value,
  defaultValue,
  onChange,
  isDisabled,
  allSelectedText,
  paperFullHeight = false,
  className,
  ...props
}: FilterSelectProps<T> & { children?: React.ReactNode }) => {
  const [open, setOpen] = useState(false)

  const isAllSelected = items?.length === value?.length

  const itemsByValue = useMemo(() => {
    const result = {} as Record<SelectItem<T>['value'], SelectItem<T>>
    items?.forEach((item) => {
      result[item.value] = item
    })
    return result
  }, [items])

  const handleChange = useCallback(
    (e: any) => {
      const value = e?.target?.value

      if (value[value.length - 1] === 'all') {
        onChange(isAllSelected ? [] : items?.map(item => (item.value as T)))
        return
      }

      onChange(typeof value === 'string' ? value.split(',') : value)
    },
    [onChange]
  )

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <Stack justifyContent='center' className={className} {...props}>
      <Select
        disabled={isDisabled}
        multiple
        sx={{
          '& > .MuiSvgIcon-root': {
            right: '6px',
            fontSize: '16px !important',
            color: '#2B3648',
          },
          '& .MuiSelect-select': {
            borderRadius: '6px'
          },
          '& div': {
            alignItems: 'center',
            paddingRight: 0.2,
          },
        }}
        variant='standard'
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        onChange={handleChange}
        disableUnderline
        displayEmpty
        MenuProps={{
          disableAutoFocusItem: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 100
          },
          PaperProps: {
            style: {
              ...(!paperFullHeight ? SelectMenuProps.PaperProps.style : {}),
              minWidth: 200
            }
          },
        }}
        renderValue={(selected) => {
          return (
            <Stack direction='row'>
              {!!startIcon ? startIcon : <Stack height={24} />}
              <Stack ml={1} className='contentWrapper'>
                {selected?.length === 0
                  ? (
                    <FilterRenderText variant='buttonSmall' className='contentText'>{defaultValue}</FilterRenderText>
                  )
                  : (
                    <FilterRenderText
                      variant='buttonSmall'
                      textOverflow='ellipsis'
                      overflow='hidden'
                      maxWidth={200}
                      className='contentText'
                    >
                      {isAllSelected
                        ? allSelectedText
                        : selected
                          ?.map((itemValue) => itemsByValue[itemValue]?.title)
                          .join(', ')}
                    </FilterRenderText>
                  )}
              </Stack>
            </Stack>
          )
        }}
      >
        <FilterSelectMenuItem value='all'>
          <Checkbox checked={isAllSelected} />
          <Typography variant='body1'>{allSelectedText}</Typography>
        </FilterSelectMenuItem>

        {items?.map((item) => {
          const { value: name, title } = item
          return (
            <FilterSelectMenuItem key={name} value={name}>
              <Checkbox checked={value?.includes(name)} />
              <Typography variant='body1'>{title}</Typography>
            </FilterSelectMenuItem>
          )
        })}
      </Select>
    </Stack>
  )
}
