import DateRangeIcon from '@mui/icons-material/DateRange'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { Stack, Typography } from '@mui/material'
import { Form, FormikProvider } from 'formik'
import React, { useEffect, useMemo } from 'react'

import { useDocsSubmit } from '@pages/Docs'

import CustomEditIcon from '../../../../assets/icons/CustomEditIcon'
import PersonWorkerIcon from '../../../../assets/icons/PersonWorkerIcon'
import VersionIcon from '../../../../assets/icons/VersionIcon'
import Button from '../../../../components/Button'
import FieldForm from '../../../../components/FieldForm'
import FileUpload from '../../../../components/FileUpload'
import useCurrentTime from '../../../../hooks/useCurrentTime'
import { useForm } from '../../../../hooks/useForm'
import { onDrawerClose, openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import { docsSelector, isCmnTomSelector } from '../../../../store/slices/documentsPages/tom'
import { profileSelector } from '../../../../store/slices/profile'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { ScrollableContainer } from '../../../../styles/global/ScrollableContainer'
import { theme } from '../../../../styles/theme'
import { TomDocument } from '../../../../types/tom'
import { formatName } from '../../../../utils/formatName'
import { DocRowData } from '../../../DocView/components/DocViewNavBarContent/DocViewNavBarContent.types'
import {
  DocInfoText,
  DocInfoTextWrapper,
  DrawerDocDescription,
  DrawerDocTitle,
} from '../../../DocView/components/DocViewNavBarContentInfo/styles'
import { CMN_TOM_TITLE } from '../../../DocView/types'
import { DocsDrawerFormWrapper } from '../DocsDrawerForm'
import { DocVersionFormData, DocsVersionDrawerFormProps } from './DocsVersionDrawerForm.types'
import { getTomVersionValidation } from './DocsVersionDrawerForm.validation'

export const DocsVersionDrawerForm: React.FC<DocsVersionDrawerFormProps> = ({
  tom,
  onFormChange,
  // onClose,
  uploadData,
}) => {
  const dispatch = useAppDispatch()

  const { firstName, lastName, middleName } = useTypedSelector(profileSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const { tomVersions } = useTypedSelector(docsSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)

  const isDocChange = openedDrawer === 'docChange'

  const formattedName = formatName(lastName, firstName, middleName)

  const dateAndTime = useCurrentTime()

  const { title, description, version } = tom || {}

  const initialValues: DocVersionFormData = {
    files: null,
    change: '',
  }

  const rowsData: DocRowData[] = [
    { title: 'Актуальная версия', icon: VersionIcon, value: isCmnTom ? tomVersions[0]?.version : version },
    { title: 'Дата загрузки', icon: DateRangeIcon, value: dateAndTime.date },
    { title: 'Время', icon: ScheduleIcon, value: dateAndTime.time },
    { title: 'ФИО', icon: PersonWorkerIcon, value: formattedName },
  ]

  const validationTomVersion = useMemo(() => {
    return getTomVersionValidation(uploadData.formats, isDocChange)
  }, [uploadData])

  const { onAddVersion, fileLoading } = useDocsSubmit()

  const { formik } = useForm({
    validationSchema: validationTomVersion,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onAddVersion(values),
  })

  const { values, setFieldValue, dirty, isSubmitting } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  return (
    <DocsDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} overflow='hidden' height='100%' component={Form}>
          <ScrollableContainer spacing={2.5}>
            <Stack spacing={1.5}>
              <DocInfoTextWrapper>
                <DrawerDocTitle variant='subtitle1'>{isCmnTom ? CMN_TOM_TITLE : title}</DrawerDocTitle>
              </DocInfoTextWrapper>
              {!isCmnTom && (
                <DocInfoTextWrapper style={{ padding: '16px' }}>
                  <DrawerDocDescription variant='body2'>{description}</DrawerDocDescription>
                </DocInfoTextWrapper>
              )}
            </Stack>

            <FileUpload<TomDocument> name='files' mode='single' uploadData={uploadData} loading={fileLoading} />

            <Stack spacing={1}>
              {isDocChange ? (
                <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
                  <Stack direction='row' spacing={1}>
                    <CustomEditIcon fontSize='medium' color='secondary' />
                    <Typography variant='body2' component='span'>
                      Изменение:
                    </Typography>
                  </Stack>

                  <FieldForm
                    value={values.change}
                    version='small'
                    name='change'
                    placeholder='изменение (2)'
                    type='number'
                    onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault()
                      }
                    }}
                    onChange={(e) => {
                      setFieldValue('change', e.target.value.slice(0, 5))
                    }}
                    sx={{
                      '& .MuiFormHelperText-root': { display: 'none' },
                      '.MuiFilledInput-root': {
                        '&.Mui-error': {
                          border: `1px solid ${theme.palette.error.main}`,
                        },
                      },
                    }}
                    inputProps={{
                      min: '0',
                    }}
                  />
                </Stack>
              ) : null}

              {rowsData.map((row) => (
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  key={row.title}
                  sx={{ height: 30 }}
                >
                  <Stack direction='row' spacing={1}>
                    <row.icon fontSize='medium' color='secondary' />
                    <Typography variant='body2' component='span'>
                      {row.title}:
                    </Typography>
                  </Stack>
                  <DocInfoText variant='subtitle2'>{row.value || '0'}</DocInfoText>
                </Stack>
              ))}
            </Stack>
          </ScrollableContainer>

          <Stack direction='row' spacing={2} pr={2.5}>
            <Button type='submit' disabled={isSubmitting} color='success' size='medium' fullWidth>
              Сохранить
            </Button>
            <Button
              size='medium'
              fullWidth
              onClick={() => dispatch(onDrawerClose({ dirty: false, immediately: true }))}
            >
              Отменить
            </Button>
          </Stack>
        </Stack>
      </FormikProvider>
    </DocsDrawerFormWrapper>
  )
}
