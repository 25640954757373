import { Stack, Typography } from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react";
import { StyledTextField } from "./PageNumberComponent.styles";
import { PageNumberComponentProps } from "./PageNumberComponent.types";

export const PageNumberComponent = ({ currentPage, numPages, onPageChange, disabled }: PageNumberComponentProps) => {

  const [inputValue, setInputValue] = useState<number | null>(1)

  const handleChangePage = (e: ChangeEvent<HTMLInputElement>) => {
    let page: number | null = Math.abs(Number(e.target.value))
    if (page > numPages) page = numPages
    if (page < 1) page = null
    setInputValue(page)
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (inputValue && currentPage !== inputValue)
        onPageChange(inputValue)
    }, 500)

    return () => clearTimeout(timeOut)
  }, [inputValue])

  useEffect(() => {
    setInputValue(currentPage)
  }, [currentPage])

  return (
    <Stack direction='row' alignItems='center' spacing='5px'>
      <StyledTextField
        disabled={disabled}
        value={inputValue}
        onFocus={(e) => e.target.select()}
        onChange={handleChangePage}
        type="number"
        InputProps={{ inputProps: { min: 1, max: numPages } }}
      />
      <Typography fontWeight={500} fontSize={14}>/ {numPages}</Typography>
    </Stack>

  )
}