import React, { useCallback, useEffect, useState } from 'react'
import { ProjectDrawerProps } from './ProjectDrawer.types'
import { ProjectDrawerTopBar, ProjectDrawerWrapper, StyledProjectDrawer } from './styles'
import { Box, Stack, Typography } from '@mui/material'
import { theme } from '../../../../styles/theme'
import Divider from '../../../../components/Divider'
import ProjectDrawerForm from '../../components/ProjectDrawerForm'
import { useDeleteProjectMutation, useGetProjectByIdQuery } from '../../../../api/projects'
import Button from '../../../../components/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteConfirm from '../../../../components/DeleteConfirm'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import Progress from '../../../../components/Progress'

const ProjectDrawer: React.FC<ProjectDrawerProps> = ({ projectId, open, onClose }) => {
  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  const { data: projectData, isLoading, isFetching } = useGetProjectByIdQuery({
    id: projectId!
  }, {
    skip: !projectId,
  })

  const { data: currentProject } = projectData || {}

  const onDeleteButtonClick = useCallback(() => {
    setIsDeleteClicked(true)
  }, [])

  const [deleteProject, deleteProjectResponse] = useDeleteProjectMutation()

  const onDeleteSubmit = useCallback(() => {
    if (projectId) {
      deleteProject({ id: projectId })
    }
  }, [currentProject])

  const onDeleteCancel = useCallback(() => {
    setIsDeleteClicked(false)
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    onClose(dirty, immediately)
  }, [])

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  useMutationHandlers(
    deleteProjectResponse,
    () => {
      onClose(false, true)
    }
  )

  useEffect(() => {
    if (!open) {
      setIsDeleteClicked(false)
    }
  }, [open])

  return (
    <StyledProjectDrawer
      anchor='right'
      open={open}
      onClose={() => onDrawerClose(dirty)}
    >
      <ProjectDrawerWrapper justifyContent='flex-start'>
        <ProjectDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {projectId ? 'Редактирование проекта' : 'Добавление проекта'}
          </Typography>
        </ProjectDrawerTopBar>

        <Divider />

        {projectId
          ? !(isLoading || isFetching)
            ? (
              <>
                <ProjectDrawerForm
                />

                <Stack justifyContent='space-between' alignItems='center' flex={1} sx={{ p: 2.5, width: '100%' }}>
                  <Box style={{ width: '100%', paddingTop: '50px' }}>
                    {isDeleteClicked
                      ? (
                        <DeleteConfirm
                          text='Вы действительно хотите удалить проект ?  Это действие нельзя отменить.'
                          onDelete={onDeleteSubmit}
                          onCancel={onDeleteCancel}
                        />
                      )
                      : null
                    }
                  </Box>
                  <Box style={{ width: 'fit-content' }}>
                    <Button
                      onClick={onDeleteButtonClick}
                      startIcon={<DeleteIcon />}
                      variant='text'
                      color='error'
                    >
                      Удалить проект
                    </Button>
                  </Box>
                </Stack>
              </>
            )
            : <Progress />

          : (
            <ProjectDrawerForm
            />
          )
        }
      </ProjectDrawerWrapper>
    </StyledProjectDrawer>
  )
}

export default ProjectDrawer