import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const DropDownIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M4.6665 6.3335L7.99984 9.66683L11.3332 6.3335L4.6665 6.3335Z' fill='currentColor' />
    </SvgIcon>
  )
}

export default DropDownIcon
