import React from 'react'
import { useExpand } from './hooks/useExpand'
import { useGetHandlers } from './hooks/useGetHandlers'
import { useGetRenderData } from './hooks/useGetRenderData'


const DocViewNavBarContentNav = () => {
  const { expanded, onExpand } = useExpand()
  const { onTomClick } = useGetHandlers()
  const { renderData } = useGetRenderData({ onTomClick, expanded, onExpand })

  return (
    <>
      {renderData}
    </>
  )
}

export default DocViewNavBarContentNav