import Progress from '../components/Progress'
import { Menu, MenuItem, Typography } from '@mui/material'
import React, { ReactNode, useCallback, useState } from 'react'
import { useGetProjectsQuery } from '../api/projects'
import { GetProjectsResponse } from '../api/projects/api.types'
import { useParams } from 'react-router-dom'

interface UseBreadcrumbMenuProps {
  onProjectItemClick: (projectId: number) => void
}

interface UseBreadcrumbMenuReturn {
  onProjectBreadClick: (event: React.MouseEvent<HTMLSpanElement>) => void
  ProjectMenu: ReactNode
  isProjectsMenuOpened: boolean
}

export const useBreadcrumbMenu = ({ onProjectItemClick }: UseBreadcrumbMenuProps): UseBreadcrumbMenuReturn => {
  const { projectId: projectIdStr } = useParams<{ projectId: string }>()
  const projectId = parseInt(projectIdStr!)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isProjectsMenuOpened = Boolean(anchorEl)
  const onProjectBreadClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const onMenuItemClick = useCallback((projectId: number) => {
    onProjectItemClick(projectId)
    onClose()
  }, [onClose, onProjectItemClick])

  const { data: projectsData, isFetching: projectsFetching } = useGetProjectsQuery({})
  const { data: projects } = projectsData || ({} as GetProjectsResponse)


  const ProjectMenu = (
    <Menu
      anchorEl={anchorEl}
      open={isProjectsMenuOpened}
      onClose={onClose}
      MenuListProps={{
        style: {
          width: '100%'
        }
      }}
      PaperProps={{
        style: {
          display: 'flex',
          justifyContent: 'center',
          minWidth: 200,
          // minHeight: 200,
          maxWidth: 450,
          maxHeight: 300,
        }
      }}
    >
      {projectsFetching
        ? <Progress />
        : projects?.length > 1
          ? projects.filter(project => project.id !== projectId)?.map(project => (
            <MenuItem onClick={() => onMenuItemClick(project.id)} style={{ maxWidth: 450 }} key={project.id}>
              <Typography style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
              >
                {project.shifrName}
              </Typography>
            </MenuItem>
          ))
          : (
            <MenuItem style={{ maxWidth: 450 }} disabled>
              <Typography>
                Другие проекты отсутствуют
              </Typography>
            </MenuItem>
          )
      }
    </Menu>
  )

  return {
    onProjectBreadClick,
    ProjectMenu,
    isProjectsMenuOpened,
  }
}