import { DateForCell, GraphCellVariant } from '../GraphCell.types'
import { LegendColors } from '../../../../../../../types/global'
import { WorkStatus } from '../../../../../../../types/works'
import { parseDateForCell } from '../../../WorksTable.utils'
import { compareAsc, differenceInCalendarDays } from 'date-fns'
import { WorkIsExpired } from '../../../../../../../api/works'

const localDate = parseDateForCell(new Date())
const {
  year: localYear,
  quarter: localQuarter,
} = localDate

// Color
interface GetCellColorForQuartersData {
  status?: WorkStatus;
  isExpired: WorkIsExpired;
  datePlanEnd: DateForCell;
  datePlanStart: DateForCell;
  dateFactStart: DateForCell | null;
  year: number;
  quarter: number;
}

export const getCellColorForQuarters = (
  data: GetCellColorForQuartersData,
): LegendColors => {
  const { year, quarter, datePlanEnd, status, isExpired, datePlanStart, dateFactStart } = data
  const { start: isExpiredStart, end: isExpiredEnd } = isExpired

  const { year: planStartYear, quarter: planStartQuarter, month: planStartMonth, day: planStartDay } = datePlanStart
  const { year: planEndYear, quarter: planEndQuarter } = datePlanEnd
  const {
    year: factStartYear,
    quarter: factStartQuarter,
    month: factStartMonth,
    day: factStartDay
  } = dateFactStart || {}

  const cellDate = new Date(year, (quarter * 3) - 1)
  const planStartDate = new Date(planStartYear, (planStartQuarter * 3) - 1)
  const planEndDate = new Date(planEndYear, (planEndQuarter * 3) - 1)
  const factStartDate = (factStartYear && factStartQuarter) ? new Date(factStartYear, (factStartQuarter * 3) - 1) : null

  const isStartYear = factStartYear ? year === factStartYear : year === planStartYear
  const isStartQuarter = isStartYear && (factStartQuarter ? quarter === factStartQuarter : quarter === planStartQuarter)

  const comparedEndDate = compareAsc(planEndDate, cellDate)
  const comparedStartDate = compareAsc(
    factStartDate || planStartDate,
    cellDate,
  )

  const planStartDateWithDay = new Date(planStartYear, planStartMonth, planStartDay)
  const factStartDateWithDay = (factStartYear && factStartMonth && factStartDay)
    ? new Date(factStartYear, factStartMonth, factStartDay)
    : null
  const factStartDateFirstDayOfQuarter = (factStartYear && factStartQuarter)
    ? new Date(factStartYear, (factStartQuarter - 1) * 3 + 1)
    : null

  const isMoreThanHalfExpired = factStartYear === planStartYear && factStartQuarter === planStartQuarter
    ? factStartDateWithDay && (differenceInCalendarDays(factStartDateWithDay, planStartDateWithDay) >= 45) // 45 - half days of quarter
    : factStartDateFirstDayOfQuarter && factStartDateWithDay && (differenceInCalendarDays(factStartDateWithDay, factStartDateFirstDayOfQuarter) >= 45)

  if (
    [
      isExpiredStart && comparedStartDate === 1,
      isExpiredEnd && comparedEndDate === -1,
      (status === 'В работе' || status === 'Выполнено') && isStartQuarter && isMoreThanHalfExpired,
      // status === 'Не в работе' && compareAsc(cellDate, planStartDate) !== -1,
    ].includes(true)
  ) {
    return 'red'
  }

  if (status === 'Выполнено' && comparedEndDate !== -1) {
    return 'lightgreen'
  }
  return 'blue'
}

// cellColorOpacity

interface GetCellColorOpacityForQuartersData {
  status?: WorkStatus;
  isExpired: WorkIsExpired;
  year: number;
  quarter: number;
}

export const getCellColorOpacityForQuarters = (data: GetCellColorOpacityForQuartersData): number => {
  const { status, isExpired, year, quarter } = data
  const { start: isExpiredStart, end: isExpiredEnd } = isExpired

  if (status !== 'Не в работе' || isExpiredEnd) return 1
  if (
    (year < localYear)
    || (year === localYear && quarter <= localQuarter)
  ) {
    return 0.2
  }

  return 1
}


// dashed
interface GetCellDashedForQuartersData {
  status?: WorkStatus;
  year: number;
  quarter: number;
}

export const getCellDashedForQuarters = (data: GetCellDashedForQuartersData): boolean => {
  const { status, year, quarter } = data

  if (status === 'Выполнено') return false
  if (
    (year < localYear)
    || (year === localYear && quarter <= localQuarter)
  ) {
    return false
  }

  return true
}

// Variant
interface GetVariantForQuartersData {
  datePlanStart: DateForCell
  datePlanEnd: DateForCell
  dateFactStart: DateForCell | null
  dateFactEnd: DateForCell | null
  status?: WorkStatus;
  year: number;
  quarter: number;
  isExpired: WorkIsExpired;
}

export const getVariantForQuarters = (data: GetVariantForQuartersData): GraphCellVariant => {
  const { isExpired, status, datePlanStart, datePlanEnd, dateFactStart, dateFactEnd, year, quarter } = data
  const { start: isExpiredStart, end: isExpiredEnd } = isExpired

  const { year: planStartYear, quarter: planStartQuarter } = datePlanStart
  const { year: planEndYear, quarter: planEndQuarter } = datePlanEnd
  const { year: factStartYear, quarter: factStartQuarter } = dateFactStart || {}
  const { year: factEndYear, quarter: factEndQuarter } = dateFactEnd
  || (isExpiredEnd && (status === 'Не в работе' || status === 'В работе')
      ? localDate
      : {}
  )


  // transform quarter to last month of this quarter
  const cellDate = new Date(year, (quarter * 3) - 1)
  const planStartDate = new Date(planStartYear, (planStartQuarter * 3) - 1)
  const planEndDate = new Date(planEndYear, (planEndQuarter * 3) - 1)
  const factStartDate = (factStartYear && factStartQuarter) ? new Date(factStartYear, (factStartQuarter * 3) - 1) : null
  const factEndDate = (factEndYear && factEndQuarter) ? new Date(factEndYear, (factEndQuarter * 3) - 1) : null

  const isStartYear = (factStartYear && isExpiredStart) ? year === factStartYear : year === planStartYear
  const isEndYear = year === planEndYear
  const isDoneYear = year === factEndYear

  const isStartQuarter = isStartYear && ((factStartQuarter && isExpiredStart) ? quarter === factStartQuarter : quarter === planStartQuarter)
  const isEndQuarter = isEndYear && quarter === planEndQuarter
  const isDoneQuarter = isDoneYear && quarter === factEndQuarter

  if (factStartDate) {
    if (!isExpiredStart) {
      // cellDate not before factStart AND cellDate before planStart
      if (compareAsc(cellDate, factStartDate) !== -1 && compareAsc(cellDate, planStartDate) === -1) {
        return 'own'
      }
      // cellDate not before planStart AND cellDate before factStart
    } else if (compareAsc(cellDate, planStartDate) !== -1 && compareAsc(cellDate, factStartDate) === -1) {
      return 'own'
    }
  }

  if (factEndDate) {
    if (!isExpiredEnd) {
      // cellDate after factEnd AND cellDate not after planEnd
      if (compareAsc(cellDate, factEndDate) === 1 && compareAsc(cellDate, planEndDate) !== 1) {
        return 'own'
      }
      // cellDate after planEnd AND cellDate not after factEnd
    } else if (compareAsc(cellDate, planEndDate) === 1 && compareAsc(cellDate, factEndDate) !== 1) {
      return 'own'
    }
  }

  // end or done in start quarter
  if ((isStartQuarter && isEndQuarter) || (isStartQuarter && isDoneQuarter)) {
    return 'own'
  }

  if (compareAsc(cellDate, planStartDate) === -1 || compareAsc(cellDate, planEndDate) === 1) {
    return 'empty'
  }

  if (isStartYear && isStartQuarter) {
    return 'start'
  }

  if (
    (isEndYear && isEndQuarter)
    || (isDoneYear && isDoneQuarter)
  ) {
    return 'end'
  }

  return 'body'
}

