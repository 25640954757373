import { useEffect, useState } from "react"
import { ButtonWrapper, MetaButton, StyledButtonGroup, StyledDrawer } from "./ModelViewDrawer.styles"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ListIcon from '@mui/icons-material/List';
import { UserDesignLogo, UserDrawerHeader, UserLogoWrapper } from "components/UserNavBar/styles"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Box, CircularProgress, IconButton, Stack, Tab, Typography } from "@mui/material"
import HomeIcon from "assets/icons/HomeIcon"
import Button from "components/Button"
import { theme } from "styles/theme"
import { useAppDispatch, useTypedSelector } from "store/store";
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import { openedDrawerSelector, setOpenedDrawer } from "store/slices/documentsPages/drawerInfo";
import { ScrollableContainer } from "styles/global/ScrollableContainer";
import { ModelViewAccordion } from "../ModelViewAccordion";
import { selectedModelsSelector } from "store/slices/tim/selectors/tim.selectors";
import { useGetTimSideMenuViewerQuery } from "api/tim";
import { IViewMode, ModelViewDrawerProps } from "./ModelViewDrawer.types";
import { setSelectedModels } from "store/slices/tim/tim";
import Tooltip from "components/Tooltip";
import Progress from "components/Progress";
import { metaManager } from "../TanglViewer/TanglManagers";
import { ModelNode } from "tangl-viewer";
import { ModelStructureAccordion, TreeNode } from "../ModelStructureAccordion";
import BoxIcon from "assets/icons/BoxIcon";
import { StructureIcon } from "assets/icons/StructureIcon";
import useConfirmDialog, { UseExitConfirmProps } from "hooks/useConfirmDialog";

export const ModelViewDrawer = ({ selectIds, onError, isLoadingModel, showMetaInfo, onToggleClick, activeIds }: ModelViewDrawerProps) => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const selectedModels = useTypedSelector(selectedModelsSelector)

  const [viewMode, setViewMode] = useState<IViewMode>('viewer')
  const [tree, setTree] = useState<ModelNode[] | null>(null)

  const { data: menuTimData, isLoading } = useGetTimSideMenuViewerQuery({ id: Number(projectId) })
  const { data: menu = [] } = menuTimData || {}

  const onTabChange = (value: IViewMode) => {
    setViewMode(value)
  }

  const collapseDrawer = () => {
    dispatch(setOpenedDrawer({ openedDrawer: null }))
  }

  metaManager.onAllLoaded(() => {
    setTree(metaManager.metaTree)
    onToggleClick()
  })

  metaManager.onError(() => {
    onError()
    setTree(null)
  })

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      navigate(-1)
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const arrowBackClick = () => {
    const isEqual = selectedModels.every((m, i) => m.tanglID === activeIds[i])
    if (isEqual) {
      navigate(-1)
    } else {
      openConfirm()
    }
  }

  const onViewClick = () => {
    selectIds(selectedModels.map(model => model.tanglID))
  }

  const onResetClick = () => {
    dispatch(setSelectedModels([]))
  }

  useEffect(() => {
    dispatch(setOpenedDrawer({ openedDrawer: 'timView' }))
  }, [])

  return (
    <StyledDrawer variant="persistent" anchor='left' open={openedDrawer === 'timView'}>
      <UserDrawerHeader direction='row' justifyContent='space-between' spacing={1}>
        <Link to='/home'>
          <UserLogoWrapper display='inline'>
            <UserDesignLogo />
          </UserLogoWrapper>
        </Link>
        <Stack direction='row' alignItems='center' spacing={1.25}>
          <Button icon bgColor={theme.palette.bg.shades} color='primary'
            variant='text' onClick={arrowBackClick}>
            <ArrowBackIcon />
          </Button>
          <Button icon bgColor={theme.palette.bg.shades} color='primary'
            variant='text' onClick={() => navigate(`/project/${projectId}`)}>
            <HomeIcon />
          </Button>
          <Button icon bgColor={theme.palette.bg.shades} color='primary'
            variant='text' onClick={collapseDrawer}>
            <Tooltip title='Свернуть'>
              <KeyboardDoubleArrowLeftIcon />
            </Tooltip>
          </Button>
        </Stack>
      </UserDrawerHeader>
      <Stack direction='row' p={2.5} spacing={1.5}>
        <StyledButtonGroup
          variant="fullWidth"
          value={viewMode}
          onChange={(e, newValue) => onTabChange(newValue)}
        >
          <Tab value='viewer' disableRipple label='Модели'
            icon={<BoxIcon color={viewMode === 'viewer' ? 'secondary' : 'primary'} />} />
          <Tab value='structure' disableRipple label='Структура' disabled={!tree}
            icon={<StructureIcon color={!tree ? 'disabled' : viewMode === 'structure' ? 'secondary' : 'primary'} />} />
        </StyledButtonGroup>
        <MetaButton disabled={!tree} value='show' selected={showMetaInfo} onChange={onToggleClick}>
          <Tooltip title={<>Свойства</>}>
            <ListIcon />
          </Tooltip>
        </MetaButton>

      </Stack>
      {
        viewMode !== 'viewer' ? null :
          isLoading ? <Progress /> :
            menuTimData && !menu.length ?
              <Stack flex={1} justifyContent='center'>
                <Typography variant="h3">Доступных для выбора моделей нет.</Typography>
              </Stack> : (
                <ScrollableContainer spacing={1.5} pl={2.5}>
                  {menu.map((object) =>
                    <ModelViewAccordion
                      object={object}
                      key={object.id}
                    />
                  )}
                  <ButtonWrapper>
                    <Box position='relative' width='100%'>
                      <Button fullWidth onClick={onViewClick} disabled={!selectedModels.length || isLoadingModel}
                        color="success">Просмотр ({selectedModels.length})
                      </Button>
                      {isLoadingModel &&
                        <CircularProgress
                          size={24}
                          color="success"
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />}
                    </Box>
                    <IconButton color="secondary" onClick={onResetClick} disabled={!selectedModels.length}>
                      <Tooltip title={<>Сбросить выбор</>}>
                        <UnpublishedOutlinedIcon />
                      </Tooltip>
                    </IconButton>
                  </ButtonWrapper>
                </ScrollableContainer>
              )
      }
      {
        viewMode !== 'structure' ? null :
          <Stack flex={1} direction='row'>
            <ModelStructureAccordion
              children={tree as TreeNode[]}
            />
          </Stack>
      }
      <ConfirmDialog />
    </StyledDrawer >
  )
}