import { FormControl, MenuItem, Stack, Typography } from "@mui/material"
import { ZoomActionButton, StyledSelect } from "./ZoomComponent.styles"
import { ZoomComponentProps } from "./ZoomComponent.types"
import { KeyboardArrowDown as ArrowIcon } from '@mui/icons-material';
import { MouseEvent, useCallback, useEffect } from "react";
import { Tooltip } from "../Tooltip";
import { ActionButton } from "../../PragmaPdfViewer.styles";

const HeightAlignIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width='18' height='18' viewBox="0 0 24 24" fill="none">
    <path d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19.01H3V4.99H21V19.01Z" fill='currentColor' />
    <path d="M7.75781 10.0439L11.0105 10.0439L12.9904 10.0439L16.2431 10.0439L12.0005 5.8013L7.75781 10.0439Z" fill="currentColor" />
    <path d="M7.75781 14.0352L11.0105 14.0352L12.9904 14.0352L16.2431 14.0352L12.0005 18.2778L7.75781 14.0352Z" fill="currentColor" />
  </svg>
)

const WidthAlignIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width='18' height='18' viewBox="0 0 24 24" fill="none">
    <path d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19.01H3V4.99H21V19.01Z" fill='currentColor' />
    <path d="M15.8184 7.75781L15.8184 11.0105L15.8184 12.9904L15.8184 16.2431L20.061 12.0005L15.8184 7.75781Z" fill="currentColor" />
    <path d="M8.20508 7.75781L8.20508 11.0105L8.20508 12.9904L8.20508 16.2431L3.96244 12.0005L8.20508 7.75781Z" fill="currentColor" />
  </svg>
)

export const ZoomComponent = ({
  zoom,
  widthAlignment,
  step = 10,
  minZoom = 10,
  maxZoom = 400,
  rootContainerRef,
  onChangeZoom,
  onChangeAlignment
}: ZoomComponentProps) => {

  const handleChangeZoom = (value: number) => {
    onChangeZoom(value)
    /*     if (typeof value === 'number') {
          onChangeZoom(value)
        } else {
          onChangeAlignment(value)
        } */
  }

  const increaseZoom = (e: MouseEvent) => {
    e.stopPropagation()
    onChangeZoom(Math.min(zoom + step, maxZoom))
  }

  const decreaseZoom = (e: MouseEvent) => {
    e.stopPropagation()
    onChangeZoom(Math.max(zoom - step, minZoom))
  }

  const getSelectContainer = () => {
    return document.fullscreenElement || document.body
  }

  const handleWheelZoom = useCallback(
    (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault()
        if (event.deltaY < 0) {
          onChangeZoom(Math.min(zoom + step, maxZoom))
        } else {
          onChangeZoom(Math.max(zoom - step, minZoom))
        }
      }
    },
    [onChangeZoom, zoom]
  )

  useEffect(() => {
    const container = rootContainerRef.current

    if (container) {
      container.addEventListener('wheel', handleWheelZoom, { passive: false })

      return () => {
        container.removeEventListener('wheel', handleWheelZoom)
      };
    }
  }, [handleWheelZoom])

  return (
    <Stack direction='row' spacing={0.45} alignItems='center'>

      <Tooltip title="По ширине страницы">
        <ActionButton iconcolor={widthAlignment !== 'width' ? 'gray' : undefined} onClick={() => onChangeAlignment("width")}>
          {WidthAlignIcon}
        </ActionButton>
      </Tooltip>
      <Tooltip title="По высоте страницы">
        <ActionButton iconcolor={widthAlignment !== 'height' ? 'gray' : undefined} onClick={() => onChangeAlignment("height")} >
          {HeightAlignIcon}
        </ActionButton>
      </Tooltip>
      <Tooltip title="По оригинальному размеру">
        <ActionButton iconcolor={widthAlignment !== 'original' ? 'gray' : undefined} onClick={() => onChangeAlignment("original")}>
          <svg xmlns="http://www.w3.org/2000/svg" width='18' height='18' viewBox="0 0 24 24" fill="none">
            <path d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19.01H3V4.99H21V19.01Z" fill='currentColor' />
          </svg>
        </ActionButton>
      </Tooltip>

      {widthAlignment === 'original' &&
      <FormControl sx={{ minWidth: 111, }} size="small" >
        <StyledSelect
          value={zoom}
          renderValue={(value) => (
            <Stack direction='row' spacing={1} justifyContent='space-between' width='100%'>
              <ZoomActionButton disableRipple onMouseDown={(e) => decreaseZoom(e)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="2" viewBox="0 0 7 2" fill="none">
                  <path d="M6.89142 0.0559082V1.94391H0.107422V0.0559082H6.89142Z" fill="#0044B4" />
                </svg>
              </ZoomActionButton>
              <Typography fontSize={14}>{value as number}%</Typography>
              <ZoomActionButton disableRipple onMouseDown={(e) => increaseZoom(e)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
                  <path d="M8.57992 5.97592H5.55592V9.07992H3.44392V5.97592H0.419922V4.02392H3.44392V0.919922H5.55592V4.02392H8.57992V5.97592Z" fill="#0044B4" />
                </svg>
              </ZoomActionButton>
            </Stack>
          )}
          onChange={(e) => handleChangeZoom(e.target.value as number)}
          MenuProps={{
            container: getSelectContainer(),
            PaperProps: {
              style: {
                maxHeight: 150,
                zIndex: 1302
              },
            },
          }}
        >
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={100}>100%</MenuItem>
          <MenuItem value={150}>150%</MenuItem>
          <MenuItem value={200}>200%</MenuItem>
          <MenuItem value={250}>250%</MenuItem>
          <MenuItem value={300}>300%</MenuItem>
          <MenuItem value={350}>350%</MenuItem>
          <MenuItem value={400}>400%</MenuItem>
        </StyledSelect>
      </FormControl>}


    </Stack>
  )
}