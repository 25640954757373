import { Box, IconButton, Stack, Typography } from "@mui/material"
import styled, {css} from "styled-components"

const selectedCss = css`
  border-color: ${props => props.theme.palette.legends.text};
`
const isBrokenCss = css`
  border-color: ${props => props.theme.palette.warning.light};
`

export const StyledListHeader = styled(Stack)`
  border-bottom: 1px solid ${props => props.theme.palette.legends.gray};
  padding: 0 0 8px 10px;
  align-items: center;
`
export const StyledHeaderText = styled(Typography)`
  color: ${props => props.theme.palette.secondary.gray};
`

export const StyledIconButton = styled(IconButton)`
  padding: 6px;
  border-radius: 6px;
  align-self: center;
  background-color: ${props => props.theme.palette.bg.lightBlue};

  &:hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }
`
export const StyledListItem = styled(Box) <{$selected: boolean, $isBroken: boolean}>`
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  gap: 3px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.palette.bg.gray};
  border-radius: 8px ;
  ${props => props.$selected && (
    props.$isBroken
    ? isBrokenCss
    : selectedCss
  )};
`
export const StyledContainer = styled(Stack)`
  justify-content: space-between;
  width: 100%;
`
export const StyledTitleText = styled(Typography) <{ $selected: boolean }>`
  font-size: 16px;
  color: ${props => props.$selected
    ? props.theme.palette.primary.main
    : props.theme.palette.text.dark};
`

export const StyledLegendText = styled(Typography)`
  font-size: 12px;
  color: ${props => props.theme.palette.secondary.dark};
`
export const StyledText = styled(Typography)`
  font-size: 14px;
  color: ${props => props.theme.palette.text.dark};
  max-width: 163px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
export const SpecialText = styled(Typography)`
  font-size: 14px;
  color: ${props => props.theme.palette.legends.chartsGreen};
  max-width: 163px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
export const IconBox = styled(Box)`
  height: 30px;
  padding: 7px;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.bg.shades};
`