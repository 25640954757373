import { Skeleton } from "@mui/material"
import { LoaderProps } from "./Loader.types"
import { LoaderContainer } from "./Loader.styles"
import { HeaderBlock, HeaderBlock2, HeaderBlock3, TextBlock, TextBlock2, TextBlock3, TextBlock4 } from "../Placeholder"

export const Loader = ({ height, width }: LoaderProps) => {
  return (
    <LoaderContainer height={height} width={width}>
      <HeaderBlock component={Skeleton} />
      <HeaderBlock2 component={Skeleton} />
      <HeaderBlock3 component={Skeleton} />
      <TextBlock component={Skeleton} />
      <TextBlock2 component={Skeleton} />
      <TextBlock3 component={Skeleton} />
      <TextBlock4 component={Skeleton} />
      <TextBlock3 component={Skeleton} />
      <TextBlock3 component={Skeleton} />
      <TextBlock3 component={Skeleton} />
      <TextBlock2 component={Skeleton} />
      <TextBlock component={Skeleton} />
      <TextBlock3 component={Skeleton} />
      <TextBlock3 component={Skeleton} />
      <TextBlock component={Skeleton} />
      <TextBlock3 component={Skeleton} />
      <TextBlock component={Skeleton} />
      <TextBlock3 component={Skeleton} />
    </LoaderContainer>
  )
}