import { Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const TableWrapper = styled(Stack)`
  flex-direction: column;
  flex: 1 0 0;
  min-width: 1150px;
  padding: 20px;
  border-radius: 20px;
  gap: 4px;
  border: 1px solid ${props => props.theme.palette.bg.gray};
`

export const HeaderRow = styled(Stack)`
  width: 100%;
  border-bottom: 1px solid #D1D8FA;
  margin-bottom: 8px;
`

export const StyledTitle = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`

export const TableRow = styled(Stack)`
  align-items: center;
  height: 30px;
  width: 100%;
  min-width: 1108px;
`