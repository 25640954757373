import React, { useCallback } from 'react'
import { Box, Stack, Toolbar, } from '@mui/material'
import { StyledHeader } from './styles'
import Divider from '../Divider'
import Breadcrumbs from '../Breadcrumbs'
import Search from './Search'
import UserMenu from './UserMenu'
import UserButtons from './UserButtons'
import { theme } from '../../styles/theme'
import { useTypedSelector } from '../../store/store'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { profileSelector } from '../../store/slices/profile'
import { config } from '../../core/config'
import { Link, matchRoutes, useLocation } from 'react-router-dom'
import { UserDesignLogo, UserLogoWrapper } from '../UserNavBar/styles'
import { isCompany } from '../../core/types'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import { NavButtons } from './NavButtons'

interface HeaderProps {
  isShowLogo?: boolean
  collapseFunc?: () => void
  isShowCollapse?: boolean
  isHasSearch?: boolean
  isShowNavButtons?: boolean
}

const routesForViewLogo = [
  { path: '/home' },
  { path: '/project/:projectId' },
  { path: '/project/:projectId/schedules/:scheduleId' },
]

const Header: React.FC<HeaderProps> = ({ isShowLogo, isShowCollapse, isHasSearch, isShowNavButtons, collapseFunc }) => {
  const location = useLocation()
  const userMatch = matchRoutes(routesForViewLogo, location)
  const routeMatch = userMatch?.length && userMatch[0].route.path
  //const { showSearch } = useContext(uiContext)
  const profile = useTypedSelector(profileSelector)
  const { role } = profile

  const subDomain = window.location.host.split('.')[0]
  const currentCompanyName = isCompany(subDomain) ? subDomain : null
  const companyParam = currentCompanyName ? `?company=${currentCompanyName}` : ''

  const showLogo = routesForViewLogo.some(route => route.path === routeMatch) || isShowLogo

  const onChangeModule = useCallback(() => {
    window.location.replace(`${config.coreURL}${companyParam}`)
  }, [currentCompanyName])

  return (
    <>
      <StyledHeader position='static'>
        <Toolbar style={{ padding: 0 }}>
          <Stack direction='row' justifyContent='space-between' width='100%' alignItems='center'>
            <Stack direction='row' alignItems='center' spacing={1.25}>
              {isShowCollapse && (
                <Tooltip title={<>Развернуть</>}>
                  <Box>
                    <Button icon bgColor={theme.palette.bg.shades} color='primary' paddingSize="small"
                      variant='text' onClick={collapseFunc}>
                      <KeyboardDoubleArrowRightIcon />
                    </Button>
                  </Box>
                </Tooltip>
              )}
              {showLogo && (
                <Link to='/home'>
                  <UserLogoWrapper>
                    <UserDesignLogo />
                  </UserLogoWrapper>
                </Link>
              )}
              {isShowNavButtons && <NavButtons />}
              <Breadcrumbs />
            </Stack>

            <Stack direction='row' spacing={theme.spacing(2.5)} alignItems='center'>
              {isHasSearch === false
                ? null
                : <Search />
              }
              <UserMenu onChangeModule={onChangeModule} profile={profile} />
              <UserButtons role={role} />
            </Stack>
          </Stack>
        </Toolbar>
        <Divider color='#CBD3F5' />
      </StyledHeader>
    </>
  )
}

export default Header