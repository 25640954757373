import { Stack, Typography } from '@mui/material'
import React, { FC, MouseEvent } from 'react'
import { StatusItemProps } from './StatusItem.types'
import { HeaderPercentage, HeaderWrapper, ItemWrapper, SubItemColor } from './StatusItem.styles'
import { TomStatus } from '../../../../../../types/tom'
import { TomIiType } from '../../../../../../api/iiPhase'
import { getIiTypeByGroupTitle, IiTypeGroupTitle } from '../../variants/IiStatistics'
import { StatisticModalInnerData } from '../../CardStatistics.types'

export const StatusItem: FC<StatusItemProps> = ({
  title,
  onStatisticHeaderClick,
  callbackTarget,
  percentage,
  clickable,
  color,
  value,
  disableInnerGap,
  subItems = [],
}) => {

  const onHeaderClick = (e: MouseEvent<HTMLDivElement>) => {
    const status: TomStatus[] = title === 'В работе'
      ? ['Корректировка', 'Разработка'] as TomStatus[]
      : [title] as TomStatus[]

    onStatisticHeaderClick(e, {
      status,
      color,
      title,
      percentage,
      value,
    })
  }

  const onChildClick = (e: MouseEvent<HTMLDivElement>, data: StatisticModalInnerData) => {
    const { status, iiType, color, title, percentage, value, } = data

    const statusArray: TomStatus[] = title === 'Прочие'
      ? ['Разработка', 'Корректировка', 'Не согласуется', 'Не в работе'] as TomStatus[]
      : [title] as TomStatus[]

    onStatisticHeaderClick(e, {
      status: statusArray,
      color,
      title,
      percentage,
      value,
      iiType,
    })
  }

  const Header = (
    <HeaderWrapper
      onClick={(clickable && callbackTarget === 'header') ? onHeaderClick : undefined}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={1}
      clickable={!!clickable}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        {percentage !== undefined && (
          <HeaderPercentage backgroundColor={color}>
            <Typography
              variant='subtitle2'
              lineHeight='14px'
            >
              {percentage} %
            </Typography>
          </HeaderPercentage>
        )}

        <Typography
          variant='subtitle2'
          fontWeight={400}
        >
          {title}
        </Typography>
      </Stack>

      <Typography
        variant='subtitle2'
      >
        {value}
      </Typography>
    </HeaderWrapper>
  )

  if (!subItems.length) return Header

  return (
    <Stack>
      {Header}

      <Stack pl={disableInnerGap ? 0.75 : 4}>
        {subItems.map(subItem => (
          <ItemWrapper
            onClick={(subItem.clickable && callbackTarget === 'child')
              ? (e) => onChildClick(e, {
                status: [subItem.title as TomStatus],
                color: subItem.color,
                title: subItem.title,
                percentage: subItem.percentage,
                value: subItem.value,
                iiType: getIiTypeByGroupTitle[title as IiTypeGroupTitle] as TomIiType
              })
              : undefined}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={1}
            clickable={!!subItem.clickable}
          >
            <Stack direction='row' alignItems='center' spacing={1}>
              {subItem.percentage !== undefined
                ? (
                  <HeaderPercentage backgroundColor={subItem.color}>
                    <Typography
                      variant='subtitle2'
                      lineHeight='14px'
                    >
                      {subItem?.percentage || 0} %
                    </Typography>
                  </HeaderPercentage>
                )
                : (
                  <SubItemColor backgroundColor={subItem.color} />
                )
              }

              <Typography
                variant='subtitle2'
                fontWeight={400}
              >
                {subItem.title}
              </Typography>
            </Stack>

            <Typography
              variant='subtitle2'
            >
              {subItem.value}
            </Typography>
          </ItemWrapper>
        ))}
      </Stack>
    </Stack>
  )
}
