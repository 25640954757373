import styled from 'styled-components'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

interface EmptyPageWrapperProps {
  forFilter: boolean;
  fullPage?: boolean;
}
export const EmptyPageWrapper = styled(Stack)<EmptyPageWrapperProps>`
  width: 100%;
  height: 100%;
  padding: 0 0 50px;
  box-sizing: content-box;
  border-radius: 8px;
  margin-top: 12px;
  background-color: ${props => props.theme.palette.bg.white};
  padding-top: ${props => props.forFilter && '50px'};
  padding-top: ${props => props.fullPage && '107px'};
`

export const EmptyLink = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: ${props => props.theme.palette.primary.main};
`
export const EmptyPageButton = styled(Button)`
  max-width: 102px;
  line-height: normal;
  margin: 14px 0;
`
export const StyledButton = styled(Button)`
  border-color: ${props => props.theme.palette.primaryBorderLight};
  min-width: 40px;

  &:hover {
    border-color: ${props => props.theme.palette.primary.main};
  }
`