import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const PersonWorkerIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M16.4961 7.45835C16.5829 7.5578 16.6317 7.69269 16.6317 7.83333V8.36364H7.36857V7.83333C7.36857 7.69269 7.41736 7.5578 7.50422 7.45835C7.59108 7.3589 7.70889 7.30303 7.83172 7.30303C7.83192 6.41437 8.0488 5.54343 8.45794 4.78827C8.86709 4.03312 9.45224 3.42375 10.1475 3.02879V5.71212H11.0738V3.06061C11.0738 2.77932 11.1714 2.50955 11.3451 2.31064C11.5189 2.11174 11.7545 2 12.0001 2C12.2458 2 12.4814 2.11174 12.6551 2.31064C12.8289 2.50955 12.9265 2.77932 12.9265 3.06061V5.71212H13.8528V3.02879C14.548 3.42375 15.1332 4.03312 15.5423 4.78827C15.9515 5.54343 16.1684 6.41437 16.1686 7.30303C16.2914 7.30303 16.4092 7.3589 16.4961 7.45835ZM12.0024 13.3636C12.9905 13.3653 13.94 12.9383 14.6477 12.1737C15.3554 11.4092 15.7652 10.368 15.7894 9.27271H8.21048C8.23592 10.3684 8.64678 11.4097 9.3553 12.1741C10.0638 12.9385 11.0138 13.3654 12.0024 13.3636ZM12.0025 15.6364C7.58384 15.6364 4 17.4288 4 19.6455V22.0001H20V19.6455C20 17.4288 16.4213 15.6364 12.0025 15.6364Z'
      />
    </SvgIcon>
  )
}

export default PersonWorkerIcon