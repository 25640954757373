import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const DocumentsIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.58579 2.58579C7.96086 2.21071 8.46957 2 9 2H14.0599C14.0599 2 14.0599 2 14.0599 2C14.3371 1.99999 14.6113 2.05758 14.865 2.16911C15.1187 2.28066 15.3465 2.44374 15.5339 2.648L20.474 8.03711C20.4739 8.03705 20.474 8.03716 20.474 8.03711C20.8122 8.40601 21 8.88849 21 9.389C21 9.38896 21 9.38904 21 9.389V16C21 16.5304 20.7893 17.0391 20.4142 17.4142C20.0391 17.7893 19.5304 18 19 18H9C8.46957 18 7.96086 17.7893 7.58579 17.4142C7.21071 17.0391 7 16.5304 7 16V4C7 3.46957 7.21071 2.96086 7.58579 2.58579ZM12 4H9V16H19V11H13C12.4477 11 12 10.5523 12 10V4ZM14 4V9H18.6435L14.0601 4H14ZM3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H6V8H5L5 20H14V19H16V20C16 20.5304 15.7893 21.0391 15.4142 21.4142C15.0391 21.7893 14.5304 22 14 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V8C3 7.46957 3.21071 6.96086 3.58579 6.58579Z'
      />
    </SvgIcon>
  )
}

export default DocumentsIcon