import { api } from '../../api'
import { GetTomIrdByIdRequest, GetTomsIrdRequest, tomIrdApi } from '../tom'
import {
  DeleteTomIrdChangeRequest,
  DeleteTomIrdChangeResponse,
  DownloadTomIrdChangeRequest,
  GetTomIrdChangesRequest,
  GetTomIrdChangesResponse,
  TomIrdChangeBaseResponse,
  UpdateTomIrdChangeRequest,
  UploadTomIrdChangeRequest,
} from './tomChangeApi.types'
import { setCurrentDocument } from '../../../store/slices/documentsPages/tom'

export const tomIrdChangeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomIrdChanges: build.query<GetTomIrdChangesResponse, GetTomIrdChangesRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/tom-ird/${id}/pdf/change/v2/list`,
        params,
        method: 'GET',
      }),
      providesTags: ['TomIrd', 'TomIrdDoc'],
    }),
    uploadTomIrdChange: build.mutation<TomIrdChangeBaseResponse, UploadTomIrdChangeRequest>({
      query: ({ id, file, change }) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('change', JSON.stringify(change))

        return {
          url: `/project/tom-ird/${id}/pdf/change/v2/upload`,
          method: 'POST',
          body: formData,
        }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: uploadedChange } = await queryFulfilled
          const { doc } = uploadedChange

          // getTomsIrd
          const getTomsIrdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomsIrd'))
          const getTomsIrdLastKey = getTomsIrdKeys[getTomsIrdKeys?.length - 1]

          dispatch(
            tomIrdApi.util.updateQueryData(
              'getTomsIrd',
              state.api.queries[getTomsIrdLastKey]?.originalArgs as GetTomsIrdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex((tom) => tom.id === doc.tomId)
                const currentChangeMax = draft.data[changedTomIndex].changeMax

                if (doc.change > currentChangeMax) {
                  draft.data[changedTomIndex].changeMax = doc.change
                }
              },
            ),
          )

          // getTomIrdById
          const getTomIrdByIdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomIrdById'))
          const getTomIrdByIdLastKey = getTomIrdByIdKeys[getTomIrdByIdKeys?.length - 1]

          dispatch(
            tomIrdApi.util.updateQueryData(
              'getTomIrdById',
              state.api.queries[getTomIrdByIdLastKey]?.originalArgs as GetTomIrdByIdRequest,
              (draft) => {
                if (doc.change > draft.data.changeMax) {
                  draft.data.changeMax = doc.change
                }
              },
            ),
          )

          // getTomIrdChanges
          const getTomIrdChangesKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomIrdChanges'))
          const getTomIrdChangesLastKey = getTomIrdChangesKeys[getTomIrdChangesKeys?.length - 1]

          dispatch(
            tomIrdChangeApi.util.updateQueryData(
              'getTomIrdChanges',
              state.api.queries[getTomIrdChangesLastKey]?.originalArgs as GetTomIrdChangesRequest,
              (draft) => {
                const indexForPlace = draft.data.findIndex((change) => doc.change > change.doc.change)
                draft.data.splice(indexForPlace, 0, uploadedChange)
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: doc }))
        } catch { }
      },
    }),
    updateTomIrdChange: build.mutation<TomIrdChangeBaseResponse, UpdateTomIrdChangeRequest>({
      query: ({ id, changeNum, change }) => ({
        url: `/project/tom-ird/${id}/pdf/change/v2/update`,
        method: 'POST',
        params: { change: changeNum },
        body: change,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: updatedChange } = await queryFulfilled
          const { doc } = updatedChange

          // getTomsIrd
          const getTomsIrdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomsIrd'))
          const getTomsIrdLastKey = getTomsIrdKeys[getTomsIrdKeys?.length - 1]

          dispatch(
            tomIrdApi.util.updateQueryData(
              'getTomsIrd',
              state.api.queries[getTomsIrdLastKey]?.originalArgs as GetTomsIrdRequest,
              (draft) => {
                const changedTomIndex = draft.data.findIndex((tom) => tom.id === doc.tomId)
                const currentChangeMax = draft.data[changedTomIndex].changeMax

                if (doc.change > currentChangeMax) {
                  draft.data[changedTomIndex].changeMax = doc.change
                }
              },
            ),
          )

          // getTomIrdById
          const getTomIrdByIdKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomIrdById'))
          const getTomIrdByIdLastKey = getTomIrdByIdKeys[getTomIrdByIdKeys?.length - 1]

          dispatch(
            tomIrdApi.util.updateQueryData(
              'getTomIrdById',
              state.api.queries[getTomIrdByIdLastKey]?.originalArgs as GetTomIrdByIdRequest,
              (draft) => {
                if (doc.change > draft.data.changeMax) {
                  draft.data.changeMax = doc.change
                }
              },
            ),
          )

          // getTomIrdChanges
          const getTomIrdChangesKeys = Object.keys(state.api.queries).filter((key) => key.includes('getTomIrdChanges'))
          const getTomIrdChangesLastKey = getTomIrdChangesKeys[getTomIrdChangesKeys?.length - 1]

          dispatch(
            tomIrdChangeApi.util.updateQueryData(
              'getTomIrdChanges',
              state.api.queries[getTomIrdChangesLastKey]?.originalArgs as GetTomIrdChangesRequest,
              (draft) => {
                const currentChangeIndex = draft.data.findIndex((change) => doc.docId === change.doc.docId)
                draft.data.splice(currentChangeIndex, 1)

                const indexForPlace = draft.data.findIndex((change) => doc.change > change.doc.change)
                draft.data.splice(indexForPlace, 0, updatedChange)
              },
            ),
          )

          // set doc as current
          dispatch(setCurrentDocument({ currentDocument: doc }))
        } catch { }
      },
    }),
    deleteTomIrdChange: build.mutation<DeleteTomIrdChangeResponse, DeleteTomIrdChangeRequest>({
      query: ({ id, change }) => ({
        url: `/project/tom-ird/${id}/pdf/change/delete`,
        params: { change },
        method: 'DELETE',
      }),
      invalidatesTags: ['TomIrd', 'TomIrdDoc'],
    }),
    downloadTomIrdChange: build.mutation<Blob, DownloadTomIrdChangeRequest>({
      query: ({ id, change }) => ({
        url: `/project/tom-ird/${id}/pdf/change/file`,
        method: 'POST',
        params: { change },
        responseHandler: (response: any) => response.blob(),
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomIrdChangesQuery,
  useUploadTomIrdChangeMutation,
  useUpdateTomIrdChangeMutation,
  useDeleteTomIrdChangeMutation,
  useDownloadTomIrdChangeMutation,
} = tomIrdChangeApi
