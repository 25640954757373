import { TypographyProps } from '@mui/material'
import { MutableRefObject, ReactNode } from 'react'

export interface IOverflowTextNewProps extends TypographyProps {
  children: ReactNode
  rows?: number
}

export const isTextOverflowed = (ref: MutableRefObject<any>) => {
  const { scrollWidth = 0, clientWidth = 0, scrollHeight = 0, clientHeight = 0 } = ref.current || {}

  return scrollHeight > clientHeight || scrollWidth > clientWidth
}
