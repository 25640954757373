import styled from 'styled-components'
import { Accordion, AccordionDetails, AccordionSummary, alpha } from '@mui/material'
import FieldForm from '../../../../../../components/FieldForm'

export const StyledExportArchiveAccordion = styled(Accordion)`
  width: 100%;
  box-shadow: none;

  &::before {
    display: none;
  }
`

export const StyledExportArchiveAccordionSummary = styled(AccordionSummary)`
  min-height: 34px;
  padding: 8px;
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 4px;

  &.Mui-expanded {
    min-height: 34px;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
  }

  & .MuiCheckbox-root {
    padding: 0;
  }

  & .MuiFormControlLabel-root {
    margin: 0;

    & .MuiCheckbox-root {
      &.Mui-checked {
        & .MuiSvgIcon-root {
          color: ${props => props.theme.palette.primary.main};
        }
      }

      & .MuiSvgIcon-root {
        color: ${props => alpha(props.theme.palette.disabled!, 0.35)};
      }
    }
  }
`

export const StyledExportArchiveAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  padding-top: 8px;

  & .MuiFormControlLabel-root {
    width: fit-content;
    height: 40px;
    margin-right: 0;
  }
`

export const AccordionSubCheckbox = styled(FieldForm)`
`