import React, { useCallback, useState } from 'react'
import { PdDrawerProps } from './PdDrawer.types'
import { PdDrawerTopBar, PdDrawerWrapper } from '../../AdminPd/PdDrawer/styles'
import { Box, Drawer, Stack, Typography } from '@mui/material'
import { theme } from '../../../../styles/theme'
import Divider from '../../../../components/Divider'
import PdDrawerForm from '../../components/PdDrawerForm'
import DeleteConfirm from '../../../../components/DeleteConfirm'
import Button from '../../../../components/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import Progress from '../../../../components/Progress'
import { useGetItem } from './hooks/useGetItem'
import { useDeleteItem } from './hooks/useDeleteItem'
import { useGetTextInfo } from './hooks/useGetTextInfo'

const PdDrawer: React.FC<PdDrawerProps> = ({
  variant,
  id,
  projectId,
  open,
  onClose
}) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const { itemByVariant, itemLoading } = useGetItem({ variant, id, projectId })
  const {
    onDeleteButtonClick,
    onDeleteSubmit,
    onDeleteCancel,
    isDeleteClicked,
  } = useDeleteItem({ variant, id, projectId, open, onClose })
  const { title, deleteConfirmText, deleteButtonText } = useGetTextInfo({ variant, id })

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    onClose(dirty, immediately)
  }, [])

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => onDrawerClose(dirty)}
    >
      <PdDrawerWrapper>
        <PdDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {title}
          </Typography>
        </PdDrawerTopBar>

        <Divider />

        {id
          ? !itemLoading
            ? (
              <>
                <PdDrawerForm
                  variant={variant}
                  item={itemByVariant}
                  onClose={onDrawerClose}
                  onFormChange={onFormChange}
                />

                <Stack justifyContent='flex-end' alignItems='center' flex={1} sx={{ p: 2.5, width: '100%' }}>
                  <Box style={{ width: '100%', paddingBottom: '20px' }}>
                    {isDeleteClicked
                      ? (
                        <DeleteConfirm
                          text={deleteConfirmText}
                          onDelete={onDeleteSubmit}
                          onCancel={onDeleteCancel}
                        />
                      )
                      : null
                    }
                  </Box>
                  <Box style={{ width: 'fit-content' }}>
                    <Button
                      onClick={onDeleteButtonClick}
                      startIcon={<DeleteIcon />}
                      variant='text'
                      color='error'
                    >
                      {deleteButtonText}
                    </Button>
                  </Box>
                </Stack>
              </>
            )
            : <Progress />
          : (
            <PdDrawerForm
              variant={variant}
              onClose={onDrawerClose}
              onFormChange={onFormChange}
            />
          )
        }
      </PdDrawerWrapper>
    </Drawer>
  )
}

export default PdDrawer