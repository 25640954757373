import { ListItem, ListItemText } from "@mui/material";
import styled from "styled-components";

export const StyledListItem = styled(ListItem)`
  & .MuiListItemSecondaryAction-root {
    right: 0;
  }
`
export const StyledItemText = styled(ListItemText)`
  max-width: 200px;
  margin: 0;


  & .MuiListItemText-primary {
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  & .MuiListItemText-secondary {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${props => props.theme.palette.disabled}
  }
`