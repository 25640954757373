import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { CardSubtitle } from './styles'
import { CardTitleProps } from './CardTitle.types'
import { theme } from '../../../../styles/theme'
import Tooltip from '../../../../components/Tooltip'

const CardTitle: React.FC<CardTitleProps> = ({ title, subtitle }) => {
  const subtitleRef = useRef<HTMLParagraphElement | null>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  useEffect(() => {
    setShowTooltip((subtitleRef?.current?.scrollHeight || 0) > 42)
  }, [subtitleRef?.current])

  return (
    <Box style={{ textAlign: 'left' }} px={1.25}>
      <Typography
        variant='h1'
        fontWeight={400}
        color={theme.palette.primary.main}
        component='h2'
      >
        {title + '\n'}
      </Typography>

      <Tooltip title={showTooltip ? subtitle : ''}>
        <CardSubtitle variant='body2' ref={subtitleRef}>{subtitle}</CardSubtitle>
      </Tooltip>
    </Box>
  )
}

export default CardTitle