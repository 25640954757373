import { FC } from 'react'
import { IrdTooltipTitleProps } from './IrdTooltipTitle.types'
import { IrdTooltipTitleWrapper } from './IrdTooltipTitle.styles'
import { Box, Stack, Typography } from '@mui/material'

export const IrdTooltipTitle: FC<IrdTooltipTitleProps> = ({
  title,
  total,
  done,
  inWork,
  notInWork,
}) => {
  const { count: doneCount, percent: donePercent } = done
  const { count: inWorkCount, percent: inWorkPercent } = inWork
  const { count: notInWorkCount, percent: notInWorkPercent } = notInWork

  return (
    <IrdTooltipTitleWrapper>
      <Box>
        <Typography variant='tooltip' fontWeight={700}>
          {title}
        </Typography>
      </Box>

      <Stack>
        <Typography variant='tooltip'>Томов: {total}</Typography>
        <Typography variant='tooltip'>Выполнено: {doneCount} ({donePercent}%)</Typography>
        <Typography variant='tooltip'>В работе: {inWorkCount} ({inWorkPercent}%)</Typography>
        <Typography variant='tooltip'>Не в работе: {notInWorkCount} ({notInWorkPercent}%)</Typography>
      </Stack>
    </IrdTooltipTitleWrapper>
  )
}
