import { Stack, Typography } from '@mui/material'
import React from 'react'

import { theme } from '../../styles/theme'
import ColoredStatus from '../ColoredStatus'
import { InfoRowProps } from './InfoRow.types'

const InfoRow: React.FC<InfoRowProps> = ({ rowData: row, valueVariant = 'subtitle2' }) => {
  const defaultColor: string = theme.palette.text.dark!

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <row.icon fontSize={row.iconProps?.fontSize || 'medium'} color={row.iconProps?.color || 'secondary'} />
        <Typography variant='body2' component='span'>
          {row.title}:
        </Typography>
      </Stack>
      {row.status ? (
        <ColoredStatus color={row.statusColor || 'error'}>
          <Typography variant={valueVariant} color={`${row.color} !important`} component='span' className='row-value'>
            {row.value}
          </Typography>
        </ColoredStatus>
      ) : (
        <Typography
          variant={valueVariant}
          component='span'
          color={row.color || `${defaultColor} !important`}
          textAlign='right'
          className='row-value'
        >
          {row.value}
        </Typography>
      )}
    </Stack>
  )
}

export default InfoRow
