import { Search as SearchIcon } from '@mui/icons-material'
import { Checkbox, Divider, Select, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { OverflowTextNew } from '@components/OverflowTextNew'
import { ObjectsSelectMenuItem } from '@components/UserManagement/components/UserObjectsSelection/styles'

import { profileSelector } from '@store/slices/profile'
import { setRemarkGroupFilter } from '@store/slices/remarks/remarks'
import { remarkGroupFilterSelector } from '@store/slices/remarks/selectors/remarks.selectors'
import { useAppDispatch, useTypedSelector } from '@store/store'

import { theme } from '@styles/theme'

import { measureTextWidth } from '@utils/measureTextWidth'

import { StyledSearch } from '../RemarkAuthorSelect/RemarkAuthorSelect.styles'
import { IRemarkResponsibleSelectProps } from './RemarkResponsibleSelect.types'

export const RemarkResponsibleSelect: FC<IRemarkResponsibleSelectProps> = ({ companies, isDisabled }) => {
  const [open, setOpen] = useState(false)
  const groupFilter = useTypedSelector(remarkGroupFilterSelector)
  const { companies: selectedCompaniesSelector } = groupFilter || {}
  const selectedCompanies: string[] = selectedCompaniesSelector || []
  const profile = useTypedSelector(profileSelector)
  const profileCompany = profile.company.userCompanyName
  const dispatch = useAppDispatch()
  const [searchValue, setSearchValue] = useState<string>('')
  const maxWidth = 230
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined)
  const selectRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (selectRef.current) {
      setMenuWidth(selectRef.current.clientWidth)
    }
  }, [])

  const handleChange = useCallback(
    (company: string) => {
      const isSelected: boolean = selectedCompanies.includes(company)
      const localSelectedCompanies: string[] = [...selectedCompanies]

      dispatch(
        setRemarkGroupFilter({
          remarkGroupFilter: {
            ...groupFilter,
            companies: isSelected
              ? localSelectedCompanies.filter((item) => item !== company)
              : [...localSelectedCompanies, company],
          },
        }),
      )
    },
    [selectedCompanies, groupFilter],
  )

  const filtredList = useMemo(() => {
    const formattedSearchValue: string = searchValue.trim().toLowerCase()

    if (!formattedSearchValue) return companies

    return companies.filter((company) => {
      const formattedCompany: string = company.trim().toLowerCase()

      return formattedCompany.includes(formattedSearchValue)
    })
  }, [companies, searchValue])

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Select
      ref={selectRef}
      disabled={isDisabled}
      multiple
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={selectedCompanies}
      variant='standard'
      disableUnderline
      displayEmpty
      sx={{
        '& > .MuiSvgIcon-root': {
          right: '8px',
          fontSize: '20px !important',
          color: '#9AA2B0',
        },
        '& .MuiInputBase-input': {
          borderRadius: '6px !important',
          padding: '7px 0px',
          paddingRight: '32px !important',
          backgroundColor: '#F6F7FB !important',
          border: !open ? '1px solid  #2B36481F' : '1px solid  #0044B4',
          ':hover': {
            border: !open ? '1px solid  #2B3648' : '1px solid  #0044B4',
          },
        },
        '& div': {
          alignItems: 'center',
          paddingRight: 0.2,
        },
      }}
      renderValue={(selected) => {
        const allCompaniesLength = companies.length
        const profileCompanySelected: boolean = selected.includes(profileCompany)
        const allSelected: boolean = selected.length >= allCompaniesLength && profileCompanySelected

        if (allSelected || selected.length === 0) {
          return (
            <Typography variant='body2' pl={1} lineHeight={'inherit'} color={theme.palette.text.dark}>
              Все компании
            </Typography>
          )
        }

        let totalWidth = 0
        let visibleCount = 0

        selected.forEach((company) => {
          const width = measureTextWidth(company + ', ')
          totalWidth += width

          if (totalWidth <= maxWidth) visibleCount++
          else return
        })

        const hiddenCount = selected.length - visibleCount

        return (
          <Stack direction={'row'} spacing={1}>
            <Typography
              variant='body2'
              textOverflow='ellipsis'
              overflow='hidden'
              className='contentText'
              lineHeight={'inherit'}
              pl={1}
            >
              {selected.join(', ')}
            </Typography>

            {!allSelected && !!hiddenCount && (
              <Tooltip
                arrow
                PopperProps={{
                  sx: {
                    filter:
                      'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10))',
                    [`& .${tooltipClasses.tooltip}`]: {
                      padding: '5px',
                      background: ' #FFF',
                      color: '#2B3648',
                      maxWidth: 270,
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      borderRadius: '6px',
                    },
                    [`& .${tooltipClasses.arrow}`]: {
                      color: ' #FFF',
                    },
                  },
                }}
                placement='right'
                title={selected.join(', ')}
              >
                <Stack
                  ml={'auto'}
                  justifyContent={'center'}
                  height={'24px'}
                  p={'0px 8px !important'}
                  width={'fit-content'}
                  borderRadius={3}
                  bgcolor={theme.palette.bg.lightBlue}
                >
                  <Typography variant='body2' color={`${theme.palette.primary.main} !important`}>
                    + {hiddenCount}
                  </Typography>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        )
      }}
      MenuProps={{
        disableAutoFocusItem: true,
        PaperProps: {
          style: {
            minWidth: `${menuWidth}px`,
            maxWidth: `${menuWidth}px`,
          },
        },
      }}
    >
      <Stack overflow={'auto'} maxHeight={'300px'}>
        <Stack direction={'row'} alignItems={'center'} p={'8px 16px'} spacing={1}>
          <SearchIcon fontSize='medium' color='secondaryDark' />
          <StyledSearch value={searchValue} onChange={onChangeSearch} placeholder='Поиск' />
        </Stack>

        <ObjectsSelectMenuItem onClick={() => handleChange(profileCompany)}>
          <Checkbox checked={selectedCompanies.includes(profileCompany)} />
          <Typography variant='body2' color={theme.palette.primary.main}>
            Замечания в адрес моей компании
          </Typography>
        </ObjectsSelectMenuItem>

        <Divider />

        {filtredList.map((company) => (
          <ObjectsSelectMenuItem onClick={() => handleChange(company)} key={company} value={company}>
            <Checkbox checked={selectedCompanies.includes(company)} />
            <OverflowTextNew variant='body2' display={'block !important'}>
              {company}
            </OverflowTextNew>
          </ObjectsSelectMenuItem>
        ))}
      </Stack>
    </Select>
  )
}
