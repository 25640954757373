import { Checkbox, InputAdornment, Stack, Typography } from "@mui/material"
import { FilterStatusSelect, FormWrapper, SelectAllCheckbox, TomNameSearch } from "./ManualSubmitSelectForm.styles"
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { SelectItem } from "components/FilterSubmitSelectMultiple"
import { tomStatusesArray } from "types/tom"
import { ProjectExportTomStatus } from "api/projects/types"
import { StyledSearchIcon } from "components/Header/Search/styles"
import { ManualSubmitSelectFormProps } from "./ManualSubmitSelectForm.types"
import { useParams } from "react-router-dom"
import { ScrollableContainer } from "styles/global/ScrollableContainer"
import { ManualSubmitAccordion } from "../ManualSubmitAccordion"
import { useFormikContext } from "formik"
import { ManualSubmitValues } from "../ManualSubmitDrawer"
import { useGetRemarkMailTomsQuery } from "api/remarks"
import { RemarkMailGroup, RemarkMailObject, RemarkMailTom } from "api/remarks/types"

export const ManualSubmitSelectForm = ({ selectedPhase, selectAll, setSelectAll }: ManualSubmitSelectFormProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { values, setFieldValue } = useFormikContext<ManualSubmitValues>()

  const [selectedTomStatuses, setSelectedTomStatuses] = useState<ProjectExportTomStatus[]>([])
  const [tomSearchValue, setTomSearchValue] = useState<string>('')

  const { data: menuData } = useGetRemarkMailTomsQuery(
    { id: projectId, type: selectedPhase },
    { skip: !selectedPhase }
  )

  const { data } = menuData || {}
  const { groups = [], objects = [] } = data || {}

  const allToms = useMemo((): RemarkMailTom[] => {
    if (objects) {
      return objects.flatMap(object =>
        object.groups.flatMap(group =>
          group.toms.map(tom => ({
            id: tom.id,
            name: tom.name,
            status: tom.status,
          }))
        )
      )
    }
    if (groups) {
      return groups.flatMap(group =>
        group.toms.map(tom => ({
          id: tom.id,
          name: tom.name,
          status: tom.status,
        }))
      )
    } else return []
  }, [groups, objects])

  const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setFieldValue('selectedToms', allToms)
    } else {
      setFieldValue('selectedToms', [])
    }
    setSelectAll(e.target.checked)
  }

  useEffect(() => {
    if (selectAll && values.selectedToms.length !== allToms.length) {
      setSelectAll(false)
    }
  }, [allToms.length, selectAll, setSelectAll, values.selectedToms.length])

  const statusSelectItems: SelectItem<string>[] = ['В производстве', ...tomStatusesArray]
    .filter(status => {
      if (status === 'В производстве') return selectedPhase === 'РД'
      if (status === 'Не согласуется') return selectedPhase === 'ИРД'
      return true
    })
    .map(status => ({
      value: status,
      title: status,
    }))

  const onFilter = (statuses: ProjectExportTomStatus[], searchName: string) => {
    statuses.length !== selectedTomStatuses.length && setSelectedTomStatuses(statuses)
    searchName !== tomSearchValue && setTomSearchValue(searchName)
  }

  const filteredObjectValues = useCallback((values: RemarkMailObject[]): RemarkMailObject[] => {
    return values.map(object => ({
      ...object,
      groups: object.groups
        .map(group => ({
          ...group,
          toms: group.toms.filter(tom => {
            const matchesStatus = selectedTomStatuses.length === 0 || selectedTomStatuses.includes(tom.status)
            const matchesName = !tomSearchValue || tom.name.toLowerCase().includes(tomSearchValue.toLowerCase())
            return matchesStatus && matchesName
          })
        }))
        .filter(group => group.toms.length > 0)
    })).filter(object => object.groups.length > 0)
  }, [selectedTomStatuses, tomSearchValue])

  const filteredGroupsValues = useCallback((values: RemarkMailGroup[]): RemarkMailGroup[] => {
    return values.map(group => ({
      ...group,
      toms: group.toms.filter(tom => {
        const matchesStatus = selectedTomStatuses.length === 0 || selectedTomStatuses.includes(tom.status)
        const matchesName = !tomSearchValue || tom.name.toLowerCase().includes(tomSearchValue.toLowerCase())
        return matchesStatus && matchesName
      })
    })).filter(group => group.toms.length > 0)
  }, [selectedTomStatuses, tomSearchValue])
  
  return (
    <FormWrapper>
      <Stack direction='row' py={1.5} >
        <SelectAllCheckbox
          disabled={selectedTomStatuses.length > 0 || !!tomSearchValue}
          control={
            <Checkbox
              checked={selectAll}
              onChange={onSelectAll}
              icon={selectAll
                ? <CheckBoxIcon />
                : <CheckBoxOutlineBlankIcon />
              }
            />
          }
          label={<Typography lineHeight='normal' fontSize={14}>
            Выбрать все доступные тома
          </Typography>}
        />
      </Stack>
      <Stack direction='row' spacing={1.5} py={2.5} pr={2.5}>
        <FilterStatusSelect
          items={statusSelectItems}
          value={selectedTomStatuses}
          onChange={(value: any) => onFilter(value as ProjectExportTomStatus[], tomSearchValue)}
          defaultValue='Статус тома'
          allSelectedText='Все типы'
          paperFullHeight
          startIcon={<FilterAltIcon fontSize='large' color='secondary' />}
        />
        <TomNameSearch
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <StyledSearchIcon color='disabled' />
              </InputAdornment>
            ),
          }}
          placeholder='Поиск по шифру тома'
          onChange={(e) => onFilter(selectedTomStatuses, e.target.value.trim())}
        />
      </Stack>
      <ScrollableContainer spacing={1.5}>
        {selectedPhase === 'РД'
          ? !objects.length
            ? <Stack margin='auto'>
              <Typography variant="h3" fontSize={16} fontWeight={500}>Нет доступных для направления замечаний.</Typography>
            </Stack>
            : filteredObjectValues(objects).map((object) => (
              <ManualSubmitAccordion
                object={object}
                key={`${selectedPhase}-object-${object.name}`}
              />
            ))
          : !groups.length
            ? <Stack margin='auto'>
              <Typography variant="h3" fontSize={16} fontWeight={500}>Нет доступных для направления замечаний.</Typography>
            </Stack>
            : filteredGroupsValues(groups).map((group) => (
              <ManualSubmitAccordion
                group={group}
                key={`${selectedPhase}-group-${group.name}`}
              />
            ))
        }
      </ScrollableContainer>
    </FormWrapper>
  )
}