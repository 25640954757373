import React, { FC, useCallback, useMemo } from 'react'
import { FormikProvider, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  Stack,
  Typography,
} from '@mui/material'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import { RadioGroupFieldForm } from '../RadioGroupFieldForm'
import { useForm } from '../../hooks/useForm'
import { CreateUserResponse } from '../../api/users/types'
import { getLabelBindField, getLabelBindFields } from './utils'
import Button from '../Button'
import { theme } from '../../styles/theme'

type UserFoundDialogProps = {
  candidates: CreateUserResponse['candidates'];
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: (values: any) => void;
};

export const FoundUserDialog: FC<UserFoundDialogProps> = ({
  candidates,
  isOpen,
  onCancel,
  onSuccess,
}) => {
  const { t } = useTranslation('user')

  const handleSubmit = useCallback(
    (values: { mergeField: number }) => {
      const candidateId = values?.mergeField
      onSuccess(candidateId)
    },
    [onSuccess]
  )

  const initialValues = useMemo(() => {
    const candidateId = candidates?.[0]?.profile?.id
    return { mergeField: candidateId }
  }, [candidates])

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
  })

  const isRadioGroupVariant = candidates && candidates?.length > 1

  return (
    <Dialog maxWidth={'sm'} open={isOpen} onClose={onCancel} PaperProps={{ style: { borderRadius: 8 } }}>
      <FormikProvider value={formik}>
        <Stack component={Form} width={400}>
          <Paper sx={{ p: 4 }}>
            {isRadioGroupVariant ? (
              <Stack alignItems='center' mb={4}>
                <Typography
                  mb={2}
                  color='primary'
                  variant='subtitle1'
                  textAlign={'center'}
                >
                  {t('status.usersFound')}
                </Typography>
                <Typography maxWidth={340} variant='body2' color={theme.palette.text.dark} textAlign='center'>
                  {t('status.descriptionUsersFound')}
                </Typography>
              </Stack>
            ) : (
              <Stack alignItems='center'>
                <Typography
                  mb={2}
                  color='primary'
                  variant='subtitle1'
                  textAlign={'center'}
                >
                  {t('status.userFound')}
                </Typography>
                <Typography
                  maxWidth={340}
                  variant='body2'
                  color={theme.palette.text.dark}
                  px={2}
                  textAlign='center'
                >
                  {t('status.descriptionUserFound')}
                </Typography>
                <Typography px={2} variant='body2' color={theme.palette.text.dark} fontWeight={700} textAlign='center'>
                  {getLabelBindField(candidates)}
                </Typography>
                <Typography
                  maxWidth={340}
                  px={2}
                  variant='body2'
                  color={theme.palette.text.dark}
                  textAlign='center'
                >
                  {t('status.descriptionEndUserFound')}
                </Typography>
              </Stack>
            )}

            {isRadioGroupVariant && (
              <RadioGroupFieldForm sx={{ marginLeft: 1.5 }} name='mergeField'>
                {candidates?.map((candidate) => {
                  const label = getLabelBindFields(candidate.bindFields)
                  const candidateId = candidate?.profile?.id
                  return (
                    <FormControlLabel
                      key={candidateId}
                      control={<Radio value={candidateId} />}
                      label={<Typography variant='body2'>{label}</Typography>}
                    />
                  )
                })}
              </RadioGroupFieldForm>
            )}
            <Stack mt={4} flex={1} spacing={1} direction='row'>
              <Button
                type='submit'
                fullWidth
                color='success'
              >
                {isRadioGroupVariant
                  ? t('common:buttons.ready')
                  : t('common:buttons.yes')}
              </Button>

              <Button
                onClick={onCancel}
                fullWidth
              >
                {isRadioGroupVariant
                  ? t('common:buttons.cancel')
                  : t('common:buttons.no')}
              </Button>
              <IconButton
                onClick={onCancel}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <CloseSharpIcon fontSize='small' />
              </IconButton>
            </Stack>
          </Paper>
        </Stack>
      </FormikProvider>
    </Dialog>
  )
}
