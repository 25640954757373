import styled from 'styled-components'
import { Stack, Typography } from '@mui/material'

export const UploadResultItemWrapper = styled(Stack)`
  padding: 16px 20px;
`

export const UploadResultRowText = styled(Typography)`
  text-transform: initial;
  color: ${props => props.theme.palette.text.dark};
`
