import AddIcon from '@mui/icons-material/Add'
import { Box, Typography } from '@mui/material'

import {
  useDeleteInnermapDepartmentMutation,
  useDeleteInnermapMemberMutation,
  useGetInnermapMembersQuery,
} from '../../../../../../api/internalAgreement'
import { InnermapMember } from '../../../../../../api/internalAgreement/types'
import InfoIcon from '../../../../../../assets/icons/InfoIcon'
import Tooltip from '../../../../../../components/Tooltip'
import {
  TableFormValues,
  isOpenFormAddMemberSelector,
  setIsOpenFormAddMember,
  setTableFormValues,
} from '../../../../../../store/slices/internalAgreement'
import { useAppDispatch, useTypedSelector } from '../../../../../../store/store'
import { useAccess } from '../../../../../Schemes/InternalAgreementSchemes'
import { EmptyTableTitle, StyledIconButton, StyledTitle, TableHeaderRow } from './SchemaTable.styles'
import { SchemaTableProps } from './SchemaTable.types'
import { columns } from './SchemaTable.utils'
import { DepartmentRow } from './components/DepartmentRow'
import { MemberRow } from './components/MemberRow'

export const SchemaTable = ({ innermapId, projectId }: SchemaTableProps) => {
  const dispatch = useAppDispatch()

  const { data: innermapMembers } = useGetInnermapMembersQuery({ projectId, innermapId })
  const { data: innermapMembersData } = innermapMembers || {}

  const { isAdmin, isModerator, isExpand, isExpandAcess } = useAccess(projectId)
  const isOpenForm = useTypedSelector(isOpenFormAddMemberSelector)

  const [deleteInnermapDepartment] = useDeleteInnermapDepartmentMutation()
  const [deleteInnermapMember] = useDeleteInnermapMemberMutation()

  const deleteMember = (memberId: number) => {
    deleteInnermapMember({ projectId, innermapId, memberId })
  }

  const deleteDepartment = (department: string) => {
    deleteInnermapDepartment({ projectId, innermapId, department })
  }

  const editMember = (member: InnermapMember) => {
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: true, formMode: 'edit' } }))
    dispatch(setTableFormValues({ tableFormValues: member }))
  }

  const openForm = (department?: string) => {
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: true, formMode: 'create' } }))
    department && dispatch(setTableFormValues({ tableFormValues: { ...({} as TableFormValues), department } }))
  }

  return (
    <>
      <TableHeaderRow direction='row'>
        {columns.map((item, index) => (
          <Box key={index} padding={item.padding} width={item.width}>
            <StyledTitle variant='body2' textAlign={item.textAlign}>
              {item.title}
            </StyledTitle>
          </Box>
        ))}
        {!isOpenForm.isOpen && (isAdmin || isModerator || (isExpand && isExpandAcess)) && (
          <Tooltip title={<>Добавить участника согласования</>}>
            <StyledIconButton onClick={() => openForm()}>
              <AddIcon fontSize='small' />
            </StyledIconButton>
          </Tooltip>
        )}
      </TableHeaderRow>
      {!innermapMembersData?.length && (
        <EmptyTableTitle direction='row' spacing={1}>
          <InfoIcon color='inherit' />
          <Typography variant='body2' color='inherit'>
            Нажмите на + чтобы добавить участников согласования
          </Typography>
        </EmptyTableTitle>
      )}
      {innermapMembersData?.map((list) => (
        <>
          <DepartmentRow
            key={list.department}
            department={list.department}
            openForm={openForm}
            deleteDepartment={deleteDepartment}
          />
          {list.members.map((member) => (
            <MemberRow key={member.id} member={member} editMember={editMember} deleteMember={deleteMember} />
          ))}
        </>
      ))}
    </>
  )
}
