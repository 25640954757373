import { UseGetRowsDataResponse } from './useGetRowsData.types'
import { useGetObjectByIdQuery } from '../../../../../../api/rdPhase'
// @ts-ignore:next-line
import { isIiTom, isIrdTom, isPdTom, isRdTom } from '../../../../../../types/tom'
import { DocRowData } from '../../../DocViewNavBarContent/DocViewNavBarContent.types'
import ObjectsIcon from '../../../../../../assets/icons/ObjectsIcon'
import RdIcon from '../../../../../../assets/icons/RdIcon'
import PersonWorkerIcon from '../../../../../../assets/icons/PersonWorkerIcon'
import PersonIcon from '@mui/icons-material/Person'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import CustomEditIcon from '../../../../../../assets/icons/CustomEditIcon'
import { useTypedSelector } from '../../../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../../../store/slices/documentsPages/projectInfo'
import { tomSelector } from '../../../../../../store/slices/documentsPages/tom'
import DocumentsIcon from '../../../../../../assets/icons/DocumentsIcon'
import { useGetRdpByIdQuery } from '../../../../../../api/rdPhase/rdp'

export const useGetRowsData = (): UseGetRowsDataResponse => {
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)

  const {
    architectorName,
    clientName,
    version,
    changeMax,
  } = tom || {}

  // Rd
  const { objectId, rdId, contractorName } = (tom && isRdTom(tom) && tom) || {}

  const { data: objectData } = useGetObjectByIdQuery({ id: objectId! }, { skip: selectedProjectPhase !== 'Рабочая документация' })
  const { data: currentObject } = objectData || {}

  const { data: rdData } = useGetRdpByIdQuery({
    id: project.id,
    rdpId: rdId!
  }, { skip: selectedProjectPhase !== 'Рабочая документация' || !project.id })
  const { data: currentRd } = rdData || {}


  // Pd
  const { pdMark } = (tom && isPdTom(tom) && tom) || {}


  // Ii
  const { iiMark, type } = (tom && isIiTom(tom) && tom) || {}


  // Ird
  const { irdMark } = (tom && isIrdTom(tom) && tom) || {}


  // Common
  const rowsData: DocRowData[] = [
    isRdTom(tom!)
      ? [
        { title: 'Объект', icon: ObjectsIcon, value: currentObject?.title },
        { title: 'Раздел РД', icon: RdIcon, value: currentRd?.mark },
      ] : [],
    isPdTom(tom!)
      ? [
        { title: 'Раздел ПД', icon: RdIcon, value: pdMark },
      ] : [],
    isIiTom(tom!)
      ? [
        { title: 'Раздел изысканий', icon: RdIcon, value: iiMark },
        { title: 'Тип документа', icon: DocumentsIcon, value: type },
      ] : [],
    isIrdTom(tom!)
      ? [
        { title: 'Раздел ИРД', icon: RdIcon, value: irdMark },
      ] : [],
    { title: 'Проектировщик', icon: PersonWorkerIcon, value: architectorName },
    isRdTom(tom!)
      ? [
        { title: 'Подрядчик', icon: PersonIcon, value: contractorName },
      ]
      : [],
    { title: 'Заказчик', icon: PersonIcon, value: clientName },
    { title: 'Версия', icon: TaskOutlinedIcon, value: version },
    changeMax
      ? [
        { title: 'Изменение', icon: CustomEditIcon, value: changeMax }
      ]
      : []
  ].flat()


  return {
    rowsData,
  }
}
