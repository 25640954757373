import { Box, Stack } from "@mui/material"
import { AutocompleteField } from "../../../../../components/AutocompleteField/AutocompleteField"
import { FormItem } from "../FormItem"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormTitledWrapper } from "../FormLeftBlock/FormLeftBlock.styles"
import { FieldArray, useFormikContext } from 'formik'
import { MapFormButton } from "../MapForm"
import { ProjectFormData } from "../../ProjectManagement.types"
import { useMemo } from "react"
import { ContractorFieldsProps } from "./ContractorFields.types"
import { EmployeeShortProfile } from "../../../../../api/users/types"
import { useSettingFormikValues } from "../../hooks/useSettingFormikValues"
import Tooltip from "../../../../../components/Tooltip";

export const ContractorFields = ({
  fieldIndex,
  companyNames,
  contractors,
}: ContractorFieldsProps) => {

  const { values: formValues } = useFormikContext<ProjectFormData>()

  const { employeesByCompany } = useSettingFormikValues({
    employeeValue: formValues.responsiblePersons.contractorsArray[fieldIndex].data,
    companyValue: formValues.responsiblePersons.contractorsArray[fieldIndex].company,
    employees: contractors,
    fieldNameCompany: `responsiblePersons.contractorsArray[${fieldIndex}].company`,
    fieldNameEmployee: `responsiblePersons.contractorsArray[${fieldIndex}].data`
  })

  const selectedContractorsData = useMemo(() => {
    return formValues.responsiblePersons?.contractorsArray.map(contractor => contractor.data) || []
  }, [formValues.responsiblePersons?.contractorsArray])

  const availableContractors: EmployeeShortProfile[] =
    contractors.filter(contractor => !selectedContractorsData.includes(contractor.id))

  return (
    <FormTitledWrapper
      title=''
      variant='primary'
      contentWrapper='stack'
      bordered='border'
    >
      <FormItem
        title='Компания'
        input={(
          <FieldArray
            name='responsiblePersons.contractorsArray'
            validateOnChange={false}
            render={() => (
              <AutocompleteField
                fieldName={`responsiblePersons.contractorsArray[${fieldIndex}].company`}
                placeholder='Введите компанию или выберите из списка'
                data={companyNames?.map(company => ({
                  value: company,
                  label: company,
                  key: company,
                })) || []}
                freeSolo
              />
            )}
          />
        )}
      />

      <FormItem
        title='Ответственный'
        input={(
          <Stack flex={1} position='relative'>
            {!employeesByCompany.length &&
              <Box position='absolute' top={11} left={-25}>
                <Tooltip variant='light' maxWidth={310} fontSize={14}
                  title={!employeesByCompany.length ? <>В компании нет доступных пользователей с ролью "Подрядчик"</> : ''}>
                  <InfoOutlinedIcon fontSize="medium" color="error" />
                </Tooltip>
              </Box>
            }
            <FieldArray
              name='responsiblePersons.contractorsArray'
              validateOnChange={false}
              render={({ push, remove }) => (
                <Stack alignItems='end' spacing={1}>
                  <Stack spacing={1.5} width='100%'>
                    <AutocompleteField
                      fieldName={`responsiblePersons.contractorsArray[${fieldIndex}].data`}
                      placeholder='Введите ФИО или выберите из списка'

                      data={employeesByCompany.map(({ id, name }) => ({
                        value: id,
                        label: name,
                        key: id,
                        removeFromSelect: !availableContractors.find(contractor => contractor.id === id),
                      }))}
                      freeSolo
                    />
                  </Stack>

                  {fieldIndex === formValues.responsiblePersons?.contractorsArray?.length - 1
                    && formValues.responsiblePersons?.contractorsArray?.every(item => item.data || item.company)
                    && (
                      <MapFormButton onClick={() => push({ data: '', company: '' })} variant='text'>
                        Добавить подрядчика
                      </MapFormButton>
                    )}
                </Stack>
              )}
            />

          </Stack>
        )}
      />
    </FormTitledWrapper>
  )
}