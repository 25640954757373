import { api } from '../../api'
import {
  CreatePpdRequest,
  DeletePpdRequest,
  EditPpdRequest,
  ExportPpdRequest,
  GetAllPpdRequest,
  GetAllPpdResponse,
  GetPpdByIdRequest,
  GetPpdLinkExampleRequest,
  GetPpdLinkExampleResponse,
  PpdBaseResponse,
  UploadPpdRequest,
  UploadPpdResponse
} from './ppdApi.types'

export const ppdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPpd: build.query<GetAllPpdResponse, GetAllPpdRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/ppd/list`,
        params,
        method: 'GET',
      }),
      providesTags: (result) =>
        result?.data?.length
          ? [
            ...result.data.map(({ id }) => ({ type: 'Ppd' as const, id })),
            { type: 'Ppd', id: 'LIST' },
            { type: 'Ppd', id: 'PARTIAL-LIST' },
          ]
          : [
            { type: 'Ppd', id: 'LIST' },
            { type: 'Ppd', id: 'PARTIAL-LIST' },
          ],
    }),
    createPpd: build.mutation<PpdBaseResponse, CreatePpdRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/ppd/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Ppd', id: 'LIST' }, { type: 'TomPd', id: 'SIDE_MENU' }, { type: 'Projects', id: 'dashboard' }],
    }),
    uploadPpd: build.mutation<UploadPpdResponse, UploadPpdRequest>({
      query: ({ id, file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/project/${id}/ppd/upload`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: [{ type: 'TomPd', id: 'SIDE_MENU' }, { type: 'Projects', id: 'dashboard' }]
    }),
    exportPpd: build.mutation<Blob, ExportPpdRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/ppd/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    getPpdById: build.query<PpdBaseResponse, GetPpdByIdRequest>({
      query: ({ id, ppdId }) => ({
        url: `/project/${id}/ppd/${ppdId}/get`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => {
        return [{ type: 'Ppd', id: arg.id }]
      },
    }),
    editPpd: build.mutation<PpdBaseResponse, EditPpdRequest>({
      query: ({ id, ppdId, ...body }) => ({
        url: `/project/${id}/ppd/${ppdId}/update`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            ppdApi.util.invalidateTags(['Ppd'])
          )

        } catch {
        }
      },
      invalidatesTags: [{ type: 'TomPd', id: 'SIDE_MENU' }]
    }),
    deletePpd: build.mutation<PpdBaseResponse, DeletePpdRequest>({
      query: ({ id, ppdId }) => ({
        url: `/project/${id}/ppd/${ppdId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Ppd', id: 'PARTIAL-LIST' }, { type: 'TomPd', id: 'SIDE_MENU' }, { type: 'Projects', id: 'dashboard' }],
    }),
    getPpdLinkExample: build.mutation<GetPpdLinkExampleResponse, GetPpdLinkExampleRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/ppd/link-example`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllPpdQuery,
  useCreatePpdMutation,
  useUploadPpdMutation,
  useExportPpdMutation,
  useGetPpdByIdQuery,
  useEditPpdMutation,
  useDeletePpdMutation,
  useGetPpdLinkExampleMutation,
} = ppdApi
