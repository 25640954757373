import { ICellRendererParams } from 'ag-grid-community'
import { useEffect, useMemo } from 'react'
import { Level } from './ShifrNameCell.styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Stack, Typography } from '@mui/material'
import { TitleParams } from '../AgGridModels'
import { OverflowText } from '../../../../components/OverflowText/OverflowText'
import Tooltip from '../../../../components/Tooltip'
import VersionIcon from '../../../../assets/icons/VersionIcon'
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff'
import { StyledLabel } from '../ModelCard'
import { theme } from '../../../../styles/theme'
import { ModelExtension } from '../ModelExtension'

interface ShifrNameCellProps extends ICellRendererParams {
  collapse: (id: number) => void
  timVersionsIds: number[]
}

export const ShifrNameCell = ({ data, value, collapse, timVersionsIds }: ShifrNameCellProps) => {
  const { title, versions, timId }: TitleParams = value

  const close = useMemo(() => timVersionsIds && timVersionsIds.includes(timId), [timVersionsIds, timId])

  return (
    <Stack direction='row' height='100%'>
      <Stack
        ref={(ref: any) => {
          if (!ref) return
          ref.onclick = (e: any) => {
            e.stopPropagation()
            collapse(timId)
          }
        }}
        width={'60px'}
        height={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {versions && versions.length > 0 && (
          <Level arrowClose={close}>
            <KeyboardArrowDownIcon fontSize='large' />
          </Level>
        )}
      </Stack>
      <Stack alignItems={'center'} direction={'row'} position='relative' width={'100%'} height='100%' px={2}>
        <OverflowText mode='table' text={data.isVersion ? value : title} defaultHeight={59 - 16} />
        {!data.isVersion ? (
          versions && versions.length ? (
            <Stack direction='row' spacing={1.25} ml={1}>
              <Tooltip title={`Текущая версия модели: ${versions[0].version}`}>
                <Stack direction='row' alignItems='center' spacing={0.75}>
                  <VersionIcon fontSize='small' />
                  <Typography variant='body2' style={{ marginTop: 2 }}>
                    {versions[0].version}
                  </Typography>
                </Stack>
              </Tooltip>
              <ModelExtension extension={versions[0].extension} tanglBindStatus={versions[0].tanglBindStatus} />
            </Stack>
          ) : (
            <Tooltip title='Нет загруженных файлов'>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <FileDownloadOffIcon fontSize='small' color='error' />
                <Typography variant='body2' color={theme.palette.error.main} style={{ marginTop: 2 }}>
                  0
                </Typography>
              </Stack>
            </Tooltip>
          )
        ) : (
          <Box>
            <ModelExtension extension={data.extension} tanglBindStatus={data.status} />
          </Box>
        )}
      </Stack>
    </Stack>
  )
}
