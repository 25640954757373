import { Menu, MenuItem, Stack, Typography } from '@mui/material'
import { TDashboardWidget, dashboardWidgetByValue, dashboardWidgets } from 'api/projects/types'
import { Badge } from 'components/Badge'
import Button from 'components/Button'
import { OverflowTextNew } from 'components/OverflowTextNew/OverflowTextNew'
import { isEqual } from 'lodash'
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { theme } from 'styles/theme'

import { useGetProjectDashboardQuery, useGetProjectsQuery, useGetWidgetSettingsQuery } from '../../api/projects'
import { GetProjectsResponse } from '../../api/projects/api.types'
import Progress from '../../components/Progress'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import { ConfirmDialog } from '../../layouts/DocumentsLayout/components/ConfirmDialog'
import { onDrawerClose, openedDrawerSelector, setOpenedDrawer } from '../../store/slices/documentsPages/drawerInfo'
import { profileSelector } from '../../store/slices/profile'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { FormPocketDrawer } from '../DocView/components/FormPocketDrawer'
import { ProjectInfoWrapper } from './ProjectInfo.styles'
import { ProjectInfoProps, TDashboardDrawerType } from './ProjectInfo.types'
import { MainCard } from './components/MainCard'
import { WidgetsDrawer } from './components/WidgetsDrawer'

export const ProjectInfo: FC<ProjectInfoProps> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const { role } = useTypedSelector(profileSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const { data: projectsData, isFetching: projectsFetching } = useGetProjectsQuery({})
  const { data: projects } = projectsData || ({} as GetProjectsResponse)

  const { data: projectRes, isFetching } = useGetProjectDashboardQuery(
    {
      id: projectId!,
    },
    {
      skip: !projectId,
    },
  )

  const { data: projectDashboard } = projectRes || {}
  const { project } = projectDashboard || {}

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const MenuOpen = Boolean(anchorEl)

  const { data: widgetData, isFetching: isWidgetSettingsFetching } = useGetWidgetSettingsQuery({ projectId })

  const [widgets, setWidgets] = useState<ReactNode[]>([])

  const enabledWidgets = useMemo(() => {
    const temp: TDashboardWidget[] = []

    widgetData?.widgets.forEach((item) => {
      item.enabled && temp.push(item.name)
    })

    return temp
  }, [widgetData])

  const isWidgetSettingsApplied: boolean = widgetData?.widgets.length
    ? isEqual(dashboardWidgets, enabledWidgets) && !widgetData?.allProjects
    : true

  useEffect(() => {
    if (isFetching || isWidgetSettingsFetching) return

    let widgetNames: TDashboardWidget[] = []

    if (widgetData?.widgets?.length === 0) dashboardWidgets.forEach((widget) => widgetNames.push(widget))
    else widgetNames = enabledWidgets

    const widgetNodes: ReactNode[] = []

    widgetNames.reduce((acc, curr, i, array) => {
      const isAgreementSchemes: boolean = curr === 'AGREEMENT_SCHEMES'
      const isDocumentation: boolean = curr === 'DOCUMENTATION'

      const prev = array[i - 1]

      if (!isAgreementSchemes && !isDocumentation) widgetNodes.push(dashboardWidgetByValue[curr])

      if (isAgreementSchemes || isDocumentation) {
        if (prev !== 'AGREEMENT_SCHEMES' && prev !== 'DOCUMENTATION') {
          widgetNodes.push(dashboardWidgetByValue[curr])
        } else {
          widgetNodes.splice(-1, 1)
          widgetNodes.push(
            <Stack spacing='20px' gridRow='span 2'>
              {dashboardWidgetByValue[prev]}
              {dashboardWidgetByValue[curr]}
            </Stack>,
          )
        }
      }

      return acc
    }, [])

    setWidgets(widgetNodes)
  }, [projectDashboard, widgetData, enabledWidgets])

  const ProjectBreadClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const onMenuItemClick = useCallback(
    (projectId: number) => {
      navigate(`/project/${projectId}`, { replace: true })
      onClose()
    },
    [onClose],
  )

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shifrName!,
        onClick: (e) => ProjectBreadClick(e),
        MenuOpen,
        type: 'project',
      },
    ],
    [MenuOpen, project],
    isFetching,
  )

  const immediatelyDrawerClose = (dirty: boolean, immediately?: boolean) => {
    dispatch(onDrawerClose({ dirty, immediately }))
  }

  useEffect(() => {
    if (role === 'none') {
      navigate('/home')
    }
  }, [role])

  const onDrawerOpen = useCallback((drawerType: TDashboardDrawerType) => {
    dispatch(setOpenedDrawer({ openedDrawer: drawerType }))
  }, [])

  const bodyForConfirmDialog: Record<TDashboardDrawerType, string> = {
    dashboardSettings: 'Форма будет закрыта, а изменения не будут применены.',
  }

  return (
    <>
      <Stack flex={1} pt={2.5}>
        <Stack direction={'row'} justifyContent={'space-between'} pb={2.5} spacing={2} alignItems={'center'}>
          <OverflowTextNew rows={2} textAlign={'start'} variant='h1' color={theme.palette.primary.main}>
            {project?.shortName}
          </OverflowTextNew>
          <Badge invisible={isWidgetSettingsApplied} color={theme.palette.primary.main}>
            <Button
              onClick={() => onDrawerOpen('dashboardSettings')}
              variant='outlined'
              color='secondary'
              width={'100%'}
            >
              Настройки отображения
            </Button>
          </Badge>
        </Stack>
        {isFetching || isWidgetSettingsFetching ? (
          <Progress />
        ) : (
          <ProjectInfoWrapper widgetsCount={widgets.length}>
            <MainCard projectDashboard={projectDashboard!} />
            {widgets}
          </ProjectInfoWrapper>
        )}

        <Menu
          anchorEl={anchorEl}
          open={MenuOpen}
          onClose={onClose}
          MenuListProps={{
            style: {
              width: '100%',
            },
          }}
          PaperProps={{
            style: {
              display: 'flex',
              justifyContent: 'center',
              minWidth: 200,
              // minHeight: 200,
              maxWidth: 450,
              maxHeight: 300,
            },
          }}
        >
          {projectsFetching ? (
            <Progress />
          ) : projects?.length > 1 ? (
            projects
              .filter((project) => project.id !== projectId)
              ?.map((project) => (
                <MenuItem onClick={() => onMenuItemClick(project.id)} style={{ maxWidth: 450 }} key={project.id}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {project.shifrName}
                  </Typography>
                </MenuItem>
              ))
          ) : (
            <MenuItem style={{ maxWidth: 450 }} disabled>
              <Typography>Другие проекты отсутствуют</Typography>
            </MenuItem>
          )}
        </Menu>
      </Stack>
      <FormPocketDrawer
        open={openedDrawer === 'formPocket'}
        onDrawerClose={immediatelyDrawerClose}
        initialPhase={undefined}
      />

      <WidgetsDrawer widgetData={widgetData} isOpen={openedDrawer === 'dashboardSettings'} />
      <ConfirmDialog body={openedDrawer === 'dashboardSettings' ? bodyForConfirmDialog[openedDrawer] : undefined} />
    </>
  )
}
