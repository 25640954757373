import React, { useCallback, useMemo, useState } from 'react'
import AdminLegend from '../components/AdminLegend'
import { Grid, Stack, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ProjectStatusesTabLabels, ProjectTabLabels } from '../../../types/project'
import { useGetProjectsQuery } from '../../../api/projects'
import { Project } from '../../../api/projects/types'
import { GetProjectsResponse } from '../../../api/projects/api.types'
import ProjectCard from '../components/ProjectCard'
import ProjectDrawer from './ProjectDrawer'
import { filterByFieldNames } from '../../../utils/filterByFieldNames'
import useSearch from '../../../hooks/useSearch'
import useConfirmDialog, { UseExitConfirmProps } from '../../../hooks/useConfirmDialog'
import Progress from '../../../components/Progress'
import EmptyPage from '../../../components/EmptyPage'
import { EmptyPageData } from '../../../components/EmptyPage/EmptyPage.types'
import { getEmptyPageData } from '../../Home'
import { TabData } from '../../../components/Tabs/Tabs.types'
import AddIcon from '@mui/icons-material/Add'
import useBreadcrumbs from '../../../hooks/useBreadcrumbs'
import { XXL_FOR_LAYOUT } from '../../../utils/constants'
import { useTypedSelector } from '../../../store/store'
import { profileSelector } from '../../../store/slices/profile'

const AdminProjects: React.FC = () => {
  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_LAYOUT})`)
  const { searchValue } = useSearch()
  const navigate = useNavigate()

  useBreadcrumbs([
    { title: 'Проекты' }
  ])

  const profile = useTypedSelector(profileSelector)
  const { firstName } = profile

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [chosenProjectId, setChosenProjectId] = useState<number | null>(null)

  const [currentTab, setCurrentTab] = useState<ProjectTabLabels>('Все проекты')
  const tabNames: ProjectTabLabels[] = Object.values(ProjectStatusesTabLabels)

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setIsDrawerOpen(false)
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onTabChange = useCallback((e: React.SyntheticEvent, tabValue: ProjectTabLabels) => {
    setCurrentTab(tabValue)
  }, [])

  const onAddProjectClick = useCallback(() => {
    navigate('add')
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty
      ? setIsDrawerOpen(false)
      : openConfirm()
  }, [])


  const { data, isLoading, isFetching } = useGetProjectsQuery({})

  const { data: projectsData } = data || ({} as GetProjectsResponse)

  const filteredBySearch = useMemo(() => {
    return searchValue && projectsData?.length
      ? filterByFieldNames<Project>(projectsData, ['fullName', 'shortName', 'shifrName'], searchValue)
      : projectsData
  }, [projectsData, searchValue])

  const filteredByStatus = useMemo(() => {
    return currentTab === 'Все проекты'
      ? filteredBySearch
      : filteredBySearch?.filter(project => project.status === currentTab)
  }, [filteredBySearch, currentTab])

  const onProjectCardClick = (projectId: number) => {
    navigate(`edit/${projectId}`)
  }

  const tabsData: TabData<ProjectTabLabels>[] = useMemo(() => {
    return tabNames.map(tabName => ({ value: tabName, label: tabName }))
  }, [filteredBySearch])

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>Здравствуйте, {firstName}, у Вас еще нет проектов для отображения.<br />
      Давайте создадим ваш первый проект.</>,
    [{
      text: 'Добавить проект',
      icon: AddIcon,
      onClick: () => onAddProjectClick()
    }]
  )

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют проекты, соответствующие результатам запроса.</>
  )

  return (
    <Stack flex={1}>
      {isLoading || isFetching
        ? <Progress />
        : projectsData?.length
          ? (
            <>
              <AdminLegend<ProjectTabLabels>
                currentTab={currentTab}
                tabsData={tabsData}
                onTabChange={onTabChange}
                onAddClick={onAddProjectClick}
              />
              {filteredByStatus?.length
                ? (
                  <Grid sx={{ py: 1.25 }} spacing={2.5} container>
                    {filteredByStatus.map(project => (
                      <Grid
                        item
                        xs={12} md={6} lg={4} xl={xxl ? 2 : 3}
                        container
                        justifyContent='center'
                        key={project.id}
                      >
                        <ProjectCard data={project} onClick={onProjectCardClick} />
                      </Grid>
                    ))}
                  </Grid>
                )
                : <EmptyPage data={emptyFilteredData} forFilter />
              }
            </>
          )
          : <EmptyPage data={emptyPageData} />
      }

      <ProjectDrawer
        open={isDrawerOpen}
        onClose={onDrawerClose}
        projectId={chosenProjectId}
      />
      <ConfirmDialog />
    </Stack>
  )
}

export default AdminProjects