import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Drawer, Stack, Typography } from '@mui/material'
import { FieldArray, Form, FormikProvider } from 'formik'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { useGetTomsRdForNotificationsQuery, useSendTomsRdNotificationsMutation } from '../../../../api/rdPhase'
import Button from '../../../../components/Button'
import Divider from '../../../../components/Divider'
import Progress from '../../../../components/Progress'
import { UploadResultsButtonWrapper } from '../../../../components/UploadDrawer/UploadResultsDrawer/styles'
import { useForm } from '../../../../hooks/useForm'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { onDrawerClose, openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import { filterDataSelector } from '../../../../store/slices/documentsPages/filter'
import { defaultProjectInfoSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { theme } from '../../../../styles/theme'
import { DocsDrawerTopBar } from '../DocsDrawer/DocsDrawer.styles'
import { NotificationItem } from '../NotificationItem'
import { NotificationDrawerInnerWrapper, NotificationsDrawerWrapper } from './NotificationsDrawer.styles'
import { NotificationsDrawerProps, NotificationsFormData } from './NotificationsDrawer.types'

export const NotificationsDrawer: FC<NotificationsDrawerProps> = () => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const { filterData } = useTypedSelector(filterDataSelector)

  const { selectedObjectsIds, selectedRdsIds, selectedDocs } = filterData

  const { data: tomsForNotificationsData, isFetching } = useGetTomsRdForNotificationsQuery(
    {
      id: project.id,
      obj: selectedObjectsIds,
      rd: selectedRdsIds,
      pdf: selectedDocs,
    },
    {
      skip: !project.id,
    },
  )

  const { data: tomsForNotifications } = tomsForNotificationsData || {}
  const [sendTomsRdNotifications, sendTomsRdNotificationsResponse] = useSendTomsRdNotificationsMutation()

  const initialValues: NotificationsFormData = {
    tomIds: [],
  }

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      if (!values.tomIds.length) {
        enqueueSnackbar('Не выбрано ни одного тома.', { variant: 'error' })
        return
      }

      sendTomsRdNotifications({
        id: project.id,
        tomIds: values.tomIds,
      })

      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  useMutationHandlers(sendTomsRdNotificationsResponse, () => {
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  const { values, setFieldValue, dirty, isSubmitting } = formik

  useEffect(() => {
    return () => {
      if (openedDrawer === 'notifications') {
        setFieldValue('tomIds', [])
      }
    }
  }, [openedDrawer])

  const onNotificationItemChange = (tomId: number) => {
    const valuesIdsCopy = [...values.tomIds]
    if (values.tomIds.includes(tomId)) {
      const existingTomIdIndex = values.tomIds.findIndex((id) => id === tomId)
      valuesIdsCopy.splice(existingTomIdIndex, 1)
      setFieldValue(`tomIds`, valuesIdsCopy)

      return
    }

    setFieldValue(`tomIds`, [...valuesIdsCopy, tomId])
  }

  const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null)
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [pageScrollable, setPageScrollable] = useState<boolean>(false)

  const handleScrollButton = (direction: 'up' | 'down') => {
    if (rootElement) {
      const scrollAmount = direction === 'down' ? 200 : -200
      rootElement.scrollBy({ top: scrollAmount, behavior: 'smooth' })
    }
  }

  const listenToScroll = (element: HTMLDivElement) => {
    if (element) {
      const scrollHeight = element.scrollTop

      const height = element.scrollHeight - element.clientHeight

      const scrolled = scrollHeight / height

      setScrollPosition(scrolled > 0.98 ? 1 : scrolled)
    }
  }

  useEffect(() => {
    setScrollPosition(0)
  }, [])

  useEffect(() => {
    rootElement?.addEventListener('scroll', () => listenToScroll(rootElement))
    if (rootElement) {
      setPageScrollable(rootElement?.scrollHeight > rootElement?.clientHeight)
    }

    return () => {
      rootElement?.removeEventListener('scroll', () => listenToScroll(rootElement))
    }
  }, [rootElement])

  const measuredRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setRootElement(node)
    }
  }, [])

  return (
    <Drawer
      anchor='right'
      open={openedDrawer === 'notifications'}
      onClose={() => dispatch(onDrawerClose({ dirty }))}
      PaperProps={{
        style: { overflow: 'hidden' },
      }}
    >
      <FormikProvider value={formik}>
        <Form style={{ height: '100%' }}>
          <NotificationsDrawerWrapper justifyContent='space-between'>
            <Stack height={'100%'} style={{ overflow: 'hidden' }}>
              <DocsDrawerTopBar>
                <Typography variant='h1' color={theme.palette.primary.main}>
                  Уведомить подрядчиков
                </Typography>
              </DocsDrawerTopBar>

              <Divider />

              <NotificationDrawerInnerWrapper ref={measuredRef}>
                {!isFetching && !tomsForNotifications?.length && (
                  <Stack height={'100%'} alignItems={'center'} justifyContent={'center'}>
                    <Typography variant='body2'>Нет доступных для выбора томов</Typography>
                  </Stack>
                )}

                {isFetching ? (
                  <Progress />
                ) : (
                  <>
                    {pageScrollable ? (
                      <UploadResultsButtonWrapper
                        onClick={() => scrollPosition > 0 && handleScrollButton('up')}
                        style={{ top: 0, boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)' }}
                        disabled={scrollPosition === 0}
                      >
                        <KeyboardArrowUpIcon />
                      </UploadResultsButtonWrapper>
                    ) : null}

                    <FieldArray
                      name='tomIds'
                      validateOnChange={false}
                      render={() => {
                        return (
                          <Stack divider={<Divider />}>
                            {tomsForNotifications?.map((tom) => (
                              <NotificationItem tom={tom} onChange={onNotificationItemChange} />
                            ))}
                          </Stack>
                        )
                      }}
                    />

                    {pageScrollable ? (
                      <UploadResultsButtonWrapper
                        onClick={() => scrollPosition < 1 && handleScrollButton('down')}
                        style={{ bottom: 0, boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.05)' }}
                        disabled={scrollPosition === 1}
                      >
                        <KeyboardArrowDownIcon />
                      </UploadResultsButtonWrapper>
                    ) : null}
                  </>
                )}
              </NotificationDrawerInnerWrapper>
            </Stack>

            <Stack direction='row' spacing={2} px={2.5}>
              <Button type='submit' disabled={isSubmitting || !dirty} color='success' size='medium' fullWidth>
                Отправить
              </Button>
              <Button
                size='medium'
                fullWidth
                onClick={() => dispatch(onDrawerClose({ dirty: false, immediately: true }))}
              >
                Отменить
              </Button>
            </Stack>
          </NotificationsDrawerWrapper>
        </Form>
      </FormikProvider>
    </Drawer>
  )
}