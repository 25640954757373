import { useContext, useEffect } from 'react'
import { uiContext } from '../contexts/ui'

interface SearchData {
  searchValue: string;
}

const useSearch = (): SearchData => {
  const cUI = useContext(uiContext)
  const { searchValue } = cUI

  useEffect(() => {
    return () => {
      cUI.clearSearchRefValue()
    }
  }, [])

  return {
    searchValue
  }
}

export default useSearch