import { IconButton } from "@mui/material";
import styled from "styled-components";

export const InternalAgreementDeleteButton = styled(IconButton)`
  border-radius: 6px;
  padding: 6px;
  margin-right: 8px!important;
  background-color: ${props => props.theme.palette.error.main};

  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.text.light};
  }

  &:hover {
    background-color: ${props => props.theme.palette.table.error};
  }
`