import { useCallback, useEffect, useRef } from "react";
import { HandToolActionButton } from "./HandToolComponent.styles"
import { Stack } from "@mui/material";
import { HandToolComponentProps } from "./HandToolComponent.types";
import { Tooltip } from "../Tooltip";

export const HandToolComponent = ({ scrollContainerRef, handTool, setHandTool }: HandToolComponentProps) => {

  const startPosition = useRef<{ x: number; y: number } | null>(null)

  const handleMouseDown = useCallback((event: MouseEvent) => {
    if (handTool) {
      startPosition.current = { x: event.clientX, y: event.clientY }
      if (scrollContainerRef.current) {
        scrollContainerRef.current.style.cursor = 'grabbing'
      }
    }
  }, [handTool, scrollContainerRef])

  const handleMouseMove = useCallback((event: MouseEvent) => {
    if (handTool && startPosition.current) {
      const container = scrollContainerRef.current

      if (container) {
        const dx = startPosition.current.x - event.clientX
        const dy = startPosition.current.y - event.clientY
        container.scrollBy(dx, dy)
        startPosition.current = { x: event.clientX, y: event.clientY }
      }
    }
  }, [handTool, scrollContainerRef])

  const handleMouseUp = useCallback(() => {
    if (handTool) {
      startPosition.current = null
      if (scrollContainerRef.current) {
        scrollContainerRef.current.style.cursor = 'grab'
      }
    }
  }, [handTool, scrollContainerRef])

  useEffect(() => {
    const container = scrollContainerRef.current

    if (container && handTool) {
      container.addEventListener('mousedown', handleMouseDown)
      container.addEventListener('mousemove', handleMouseMove)
      container.addEventListener('mouseup', handleMouseUp)
      container.addEventListener('mouseleave', handleMouseUp)
      container.style.cursor = 'grab'
    }

    return () => {
      if (container) {
        container.removeEventListener('mousedown', handleMouseDown)
        container.removeEventListener('mousemove', handleMouseMove)
        container.removeEventListener('mouseup', handleMouseUp)
        container.removeEventListener('mouseleave', handleMouseUp)
        container.style.cursor = 'default'
      }
    }
  }, [handTool, handleMouseDown, handleMouseMove, handleMouseUp, scrollContainerRef])
  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <Tooltip title='Рука'>
        <HandToolActionButton onClick={() => setHandTool(!handTool)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_13208_79088)">
              <path d="M12.7518 22C10.0351 22 7.59348 20.3417 6.58515 17.8167L4.06015 11.475C3.80181 10.8167 4.41848 10.1583 5.09348 10.375L5.75181 10.5917C6.21848 10.7417 6.60181 11.1 6.78515 11.5583L7.96015 14.5H8.58515V4.70833C8.58515 4.13333 9.05181 3.66667 9.62681 3.66667C10.2018 3.66667 10.6685 4.13333 10.6685 4.70833V12H11.5018V3.04167C11.5018 2.46667 11.9685 2 12.5435 2C13.1185 2 13.5851 2.46667 13.5851 3.04167V12H14.4185V4.29167C14.4185 3.71667 14.8851 3.25 15.4601 3.25C16.0351 3.25 16.5018 3.71667 16.5018 4.29167V12H17.3351V6.79167C17.3351 6.21667 17.8018 5.75 18.3768 5.75C18.9518 5.75 19.4185 6.21667 19.4185 6.79167V15.3333C19.4185 19.0167 16.4351 22 12.7518 22Z" fill={handTool ? "#0044B4" : "#5C6E8C"} />
            </g>
            <defs>
              <clipPath id="clip0_13208_79088">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </HandToolActionButton>
      </Tooltip>
    </Stack>
  )

}