import styled from 'styled-components'
import { LegendBadgeProps } from './LegendItem.types'

export const LegendBadge = styled.div<LegendBadgeProps>`
  width: ${props => props.dot ? '12px' : '22px'};
  height: 12px;
  margin-left: ${props => props.dot && '10px'};
  background-color: ${props => props.theme.palette.legends[props.color]};
  border-radius: 10px;
`
