import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const FolderIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13.0677 12.8685V12.3893V12.3787H13.7586C14.0455 12.3787 14.2793 12.1444 14.2793 11.8569L14.2793 4.43514C14.2793 4.14764 14.0455 3.91338 13.7586 3.91338L7.74309 3.91338C7.72784 3.86988 7.71259 3.82354 7.69678 3.77549C7.56937 3.38834 7.40575 2.89115 6.91411 2.89115L2.90734 2.89115C2.62039 2.89115 2.38657 3.12541 2.38657 3.41291V4.11569H1.90831C1.73826 4.11569 1.6001 4.25412 1.6001 4.42449L1.6001 11.8462L1.6001 12.8685C1.6001 13.0388 1.73826 13.1773 1.90831 13.1773L12.7595 13.1773C12.9296 13.1773 13.0677 13.0388 13.0677 12.8685ZM6.59527 5.13791L6.32957 4.42449C6.2658 4.26477 6.08512 4.12634 5.9257 4.12634H2.83295V3.42356C2.83295 3.38097 2.86483 3.33838 2.91797 3.33838L6.91411 3.33838C7.14177 3.33838 7.28916 3.84107 7.38281 4.16047C7.4072 4.24365 7.42794 4.3144 7.44551 4.3606L13.7692 4.3606C13.8117 4.3606 13.8542 4.39254 13.8542 4.44578L13.8542 11.8675C13.8542 11.9101 13.8223 11.9527 13.7692 11.9527H13.0677L13.0677 5.44671C13.0677 5.27634 12.9296 5.13791 12.7595 5.13791L6.59527 5.13791Z'
      />
    </SvgIcon>
  )
}

export default FolderIcon
