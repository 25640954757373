import { IconButton } from "@mui/material";
import styled from "styled-components";

export const Level = styled(IconButton)<{ arrowClose: boolean }>`
  padding: 1px;

  svg {
    transition: rotate linear 0.2s;
    transform: ${(props) =>
      props.arrowClose ? "rotate(0deg)" : "rotate(180deg)"};
  }
`;
