import { FC } from 'react'
import { AgreementNavbarPhaseItemProps } from './AgreementNavbarPhaseItem.types'
import {
  AgreementNavbarPhaseItemDuration,
  AgreementNavbarPhaseItemNumber,
  AgreementNavbarPhaseItemPerson,
  AgreementNavbarPhaseItemTitle,
  AgreementNavbarPhaseItemWrapper,
  LockIcon,
  LockOpenIcon
} from './AgreementNavbarPhaseItem.styles'
import { Stack, Typography } from '@mui/material'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Tooltip from '../../../../../components/Tooltip'
import { BorderedVerifiedIcon } from '../../../../../assets/icons/BorderedVerifiedIcon'


export const AgreementNavbarPhaseItem: FC<AgreementNavbarPhaseItemProps> = ({
  roadmap,
  onRoadmapClick,
  selected,
}) => {
  const { id, title, phase, person, number, isBroken, isLocked, isMain, duration } = roadmap

  return (
    <AgreementNavbarPhaseItemWrapper onClick={() => onRoadmapClick(roadmap)} selected={selected} isBroken={isBroken}>
      <Stack direction='row' alignItems='center' spacing={1.25}>
        <AgreementNavbarPhaseItemNumber>
          {isMain && (
            <BorderedVerifiedIcon className='verifiedIcon' />
          )}

          <Typography>{number}</Typography>
        </AgreementNavbarPhaseItemNumber>

        <Stack>
          <AgreementNavbarPhaseItemTitle variant='body2'>
            {title}
          </AgreementNavbarPhaseItemTitle>
          <AgreementNavbarPhaseItemPerson variant='body2'>
            {person.name}
          </AgreementNavbarPhaseItemPerson>
        </Stack>
      </Stack>


      {isBroken
        ? (
          <Tooltip title={<>Переходы по схеме согласования<br />не ведут к завершению процедуры.<br />Запуск процедуры невозможен</>}>
            <WarningAmberIcon fontSize='medium' color='warning' />
          </Tooltip>
        )
        : (
          <Stack direction='row' alignItems='center' spacing={1.25}>
            <Stack direction='row' alignItems='center' spacing={0.75}>
              <WatchLaterIcon fontSize='medium' color='info' />
              <AgreementNavbarPhaseItemDuration>
                {duration}дн
              </AgreementNavbarPhaseItemDuration>
            </Stack>

            {isLocked
              ? (
              <Tooltip title={<>По схеме существуют запущенные<br />процедуры согласования,<br />редактирование невозможно</>}>
                <LockIcon fontSize='medium' />
              </Tooltip>
            )
              : <LockOpenIcon fontSize='medium' />
            }
          </Stack>
        )
      }
    </AgreementNavbarPhaseItemWrapper>
  )
}
