import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { tomSelector } from '@store/slices/documentsPages/tom'
import { useTypedSelector } from '@store/store'

import { ShiftDownOutlinedIcon } from '../../../../../../../assets/icons/ShiftDownIcon'
import { ShiftUpOutlinedIcon } from '../../../../../../../assets/icons/ShiftUpIcon'
import { useAccess } from '../../../../InternalAgreementSchemes.utils'
import { TableRow } from '../../InternalAgreementTable.styles'
import { DepartmentCell, StyledIconButton } from './DepartmentRow.styles'
import { DepartmentRowProps } from './DepartmentRow.types'

export const DepartmentRow = ({
  isApproved,
  allowMove,
  department,
  isFirst,
  isLast,
  moveUpDepartment,
  moveDownDepartment,
  deleteDepartment,
  openForm,
}: DepartmentRowProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { isAdmin, isModerator, isExpand, isExpandAcess } = useAccess(projectId)
  const { tom } = useTypedSelector(tomSelector)

  const isAnnulled = tom?.features.annulment.annulled
  const handleMoveUpClick = () => moveUpDepartment({ department, direction: 'up' })
  const handleMoveDownClick = () => moveDownDepartment({ department, direction: 'down' })
  const handleClickDelete = () => deleteDepartment(department)
  const handleAddMember = () => openForm(department)

  return (
    <TableRow spacing={1} direction='row'>
      {allowMove && (
        <>
          <StyledIconButton disabled={isFirst} color='primary' onClick={handleMoveUpClick}>
            <ShiftUpOutlinedIcon fontSize='small' />
          </StyledIconButton>
          <StyledIconButton disabled={isLast} color='primary' onClick={handleMoveDownClick}>
            <ShiftDownOutlinedIcon fontSize='small' />
          </StyledIconButton>
        </>
      )}
      <DepartmentCell>
        <Typography variant='body2'>{department}</Typography>
      </DepartmentCell>
      {!isAnnulled && (isAdmin || isModerator || (isExpand && isExpandAcess)) && !isApproved && (
        <StyledIconButton color='primary' onClick={handleAddMember}>
          <AddIcon fontSize='small' />
        </StyledIconButton>
      )}
      {!isAnnulled && (isAdmin || isModerator) && !isApproved && (
        <StyledIconButton color='primary' onClick={handleClickDelete}>
          <DeleteIcon fontSize='small' />
        </StyledIconButton>
      )}
    </TableRow>
  )
}
