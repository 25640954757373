import styled from 'styled-components'
import {
  RdCardText,
  StyledRdCard,
  StyledRdCardContent,
  StyledRdCardHeader
} from '../../../Administration/components/RdCard/styles'

export const StyledObjectCard = styled(StyledRdCard)`
`

export const StyledObjectCardHeader = styled(StyledRdCardHeader)`

  & .ellipsisText {
    min-height: 50px;
    max-height: 50px;
  }

`

export const StyledObjectCardContent = styled(StyledRdCardContent)`
`

export const ObjectCardText = styled(RdCardText)`
`
