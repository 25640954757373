import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface UseRouteTrackerProps {
  record?: boolean
}

interface UseRouteTracker {
  previousPath: string | null
  removePreviousPath: () => void
}

const useRouteTracker = ({ record = true }: UseRouteTrackerProps): UseRouteTracker => {
  const location = useLocation()
  const previousPath = localStorage.getItem('previousPath')
  useEffect(() => {
    if (record) {
      localStorage.setItem('previousPath', location.pathname)
    }
  }, [location, record])

  const removePreviousPath = () => {
    localStorage.removeItem('previousPath')
  }

  return { previousPath, removePreviousPath }
}

export default useRouteTracker
