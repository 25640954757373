import { AgreementOverviewStage, CompleteOverviewStatus, StageMember } from '@api/agreementOverview/types'

export interface AgreementOverviewSchemaProps {
  onClickInnermapStage: (members: StageMember[]) => void
  stages: AgreementOverviewStage[]
}

export type AgreementOverviewColor = 'green' | 'red' | 'gray' | 'blue' | 'yellow'
export interface RoadmapStatusToColor extends Record<CompleteOverviewStatus, AgreementOverviewColor> {}
export const roadmapStatusToColor: RoadmapStatusToColor = {
  rejected: 'red',
  agreed: 'green',
  unknown: 'gray',
  remarks: 'yellow',
}
