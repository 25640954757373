import { useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'

export interface DateAndTime {
  date: string;
  time: string;
}

let interval: any
const dateFormat = 'dd.MM.yyyy'
const timeFormat = 'HH:mm'

const useCurrentTime = (): DateAndTime => {
  const now = new Date()

  const [currentDate, setCurrentDate] = useState<DateAndTime>({
    date: format(now, dateFormat),
    time: format(now, timeFormat)
  })
  const [minutes, setMinutes] = useState<number | null>(null)

  const timeout = useMemo(() => {
    setTimeout(() => {
      const innerNow = new Date()
      setMinutes(innerNow.getMinutes())
    }, 1000 * (60 - now.getSeconds()))
  }, [])

  useEffect(() => {
    if (minutes !== null) {
      const innerNow = new Date()
      setCurrentDate({
        date: format(innerNow, dateFormat),
        time: format(innerNow, timeFormat)
      })

      interval = setInterval(() => {
        const innerNow = new Date()
        setCurrentDate({
          date: format(innerNow, dateFormat),
          time: format(innerNow, timeFormat)
        })
      }, 1000 * 60)
    }
  }, [minutes])

  useEffect(() => {
    return () => {
      clearInterval(interval)
    }
  }, [])

  return currentDate
}

export default useCurrentTime