import { FC, MouseEvent, useCallback, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useGetEmployeeQuery } from '../../../../../../api/users'
import Progress from '../../../../../../components/Progress'
import { UserCardContent } from '../../../../../Administration/components/UserCardContent'
import { PersonCellPopover } from './PersonCell.styles'
import { theme } from '../../../../../../styles/theme'
import { SelfCellProps } from '../cells.types'
import { TABLE_CELL_HEIGHT } from '../../WorksTable.types'
import { StyledTableCell } from '../../WorksTable.styles'

export const PersonCell: FC<SelfCellProps> = ({ work, ...cellProps }) => {
  const { employeeId, employeeName } = work.person

  const [personOpened, setPersonOpened] = useState<boolean>(false)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null)

  const { data: employeeProfile, isFetching: employeeProfileFetching } = useGetEmployeeQuery(
    { employeeId: employeeId! },
    { skip: !employeeId || !personOpened }
  )

  const onPersonClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setPopoverAnchor(e?.currentTarget)
    setPersonOpened(true)
  }, [])

  const onPersonClose = useCallback((e: MouseEvent) => {
    e.stopPropagation()
    setPopoverAnchor(null)
    setPersonOpened(false)
  }, [])

  return (
    <StyledTableCell
      {...cellProps}
      hover={!!employeeId}
      onClick={employeeId ? onPersonClick : undefined}
    >
      <Stack height={TABLE_CELL_HEIGHT} justifyContent='center'>
        <Box>
          <Typography
            color={`${employeeId ? theme.palette.primary.main : theme.palette.text.dark} !important`}
            variant='body2'
          >
            {employeeName || '—'}
          </Typography>
        </Box>

        <PersonCellPopover
          open={personOpened}
          onClose={onPersonClose}
          anchorEl={popoverAnchor}
        >
          {employeeProfileFetching || !employeeProfile?.id
            ? <Progress />
            : <UserCardContent employeeData={employeeProfile!} mode='modal' />
          }
        </PersonCellPopover>
      </Stack>
    </StyledTableCell>
  )
}
