import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Progress from '../../components/Progress'
import { Menu, MenuItem, Stack, Typography } from '@mui/material'
import ObjectsLegend from './components/ObjectsLegend'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import { useGetProjectsQuery } from '../../api/projects'
import { GetProjectsResponse } from '../../api/projects/api.types'
import { docsConfigByPhase } from '../../layouts/DocumentsLayout'
import { useGetManagementHandlers } from './hooks/useGetManagementHandlers'
import { useGetData } from './hooks/useGetData'
import { useGetColumns } from './hooks/useGetColumns'
import { useRenderData } from './hooks/useRenderData'
import { useGetEmptyPagesData } from './hooks/useGetEmptyPagesData'
import { useRenderDrawers } from './hooks/useRenderDrawers'
import { useGetLegendProps } from './hooks/useGetLegendProps'
import { useTypedSelector } from '../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector,
} from '../../store/slices/documentsPages/projectInfo'
import { objectsViewSelector } from '../../store/slices/ui'
import { rdViewTabSelector } from '../../store/slices/documentsPages/objects'
import { useMutationHandlers } from '../../hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { useGetSwitchObjectSettingsQuery, useGetSwitchSectionsSettingsQuery, useGetSwitchTimObjectSettingsQuery, useUpdateSwitchObjectSettingsMutation, useUpdateSwitchSectionsSettingsMutation, useUpdateSwitchTimObjectSettingsMutation } from 'api/settings'

const Objects: React.FC = () => {
  const navigate = useNavigate()
  const { projectId } = useParams<{ projectId: string }>()
  const location = useLocation()
  const isTimPage = location.pathname.includes('tim')
  const { enqueueSnackbar } = useSnackbar()

  const {
    project,
    userWithLessControls,
  } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const viewMode = useTypedSelector(objectsViewSelector)
  const rdViewTab = useTypedSelector(rdViewTabSelector)

  const {
    onAddClick,
    onUploadClick,
    onExportClick,

    onObjectItemClick,
    onObjectEditClick,
    //onRdpItemClick,
    onRdpEditClick,
    onPdItemClick,
    onPdEditClick,
    onIiItemClick,
    onIiEditClick,
    onIrdItemClick,
    onIrdEditClick,

    onRowClick,

    openedDrawer,
    chosenId,
    onDrawerClose,
    ConfirmDialog,
  } = useGetManagementHandlers()

  const { DrawerByPhase, UploadDrawerByPhase } = useRenderDrawers({ openedDrawer, chosenId, onDrawerClose })

  const { filteredData, isDataLoading, isObjectsExist, isRdpsExist, isPdsExist, isIisExist, isIrdsExist } = useGetData()

  const {
    showUploadButton,
    showExportButon,
    showViewSwitcher,
    showObjSwitcher,
    showRdpSwitcher,
  } = useGetLegendProps({
    isObjectsExist,
    isRdpsExist,
    isPdsExist,
    isIisExist,
    isIrdsExist,
  })

  // Switch settings

  const { data: selectAllTomObjects } = useGetSwitchObjectSettingsQuery(undefined, { skip: !showObjSwitcher && isTimPage })
  const { data: selectAllTimObjects } = useGetSwitchTimObjectSettingsQuery(undefined, { skip: !showObjSwitcher && !isTimPage })
  const { data: selectAllSections } = useGetSwitchSectionsSettingsQuery(undefined, { skip: !showObjSwitcher && rdViewTab === 'objects' })
  const [setSelectAllTomObjects, setSelectAllTomObjectsResponse] = useUpdateSwitchObjectSettingsMutation()
  const [setSelectAllTimObjects, setSelectAllTimObjectsResponse] = useUpdateSwitchTimObjectSettingsMutation()
  const [setSelectAllSections, setSelectAllSectionsResponse] = useUpdateSwitchSectionsSettingsMutation()

  const isSelectedAllItems = useMemo(() => {
    if (rdViewTab === 'objects') {
      return isTimPage ? selectAllTimObjects?.enabled : selectAllTomObjects?.enabled
    }
    if (rdViewTab === 'rdp') {
      return selectAllSections?.enabled
    }
  }, [isTimPage, rdViewTab, selectAllSections?.enabled, selectAllTimObjects?.enabled, selectAllTomObjects?.enabled])

  const onUpdateSwitch = (checked: boolean) => {
    if (rdViewTab === 'objects') {
      isTimPage ? setSelectAllTimObjects({ enabled: checked }) : setSelectAllTomObjects({ enabled: checked })
    }
    if (rdViewTab === 'rdp') {
      setSelectAllSections({ enabled: checked })
    }
  }

  useMutationHandlers(
    setSelectAllTomObjectsResponse,
    (data) => {
      if (data) {
        enqueueSnackbar('Состояние настройки успешно сохранено.', { variant: 'success' })
      }
    }
  )

  useMutationHandlers(setSelectAllTimObjectsResponse,
    (data) => {
      if (data) {
        enqueueSnackbar('Состояние настройки успешно сохранено.', { variant: 'success' })
      }
    }
  )

  useMutationHandlers(setSelectAllSectionsResponse,
    (data) => {
      if (data) {
        enqueueSnackbar('Состояние настройки успешно сохранено.', { variant: 'success' })
      }
    }
  )

  //--------------

  const { objectsColumns, pdColumns } = useGetColumns({
    onObjectEditClick,
    onRdpEditClick,
    onPdEditClick,
    onIiEditClick,
    onIrdEditClick,
  })

  const { emptyPageData } = useGetEmptyPagesData({
    onAddClick,
    onUploadClick,
    isTimPage,
  })

  const { renderPageData } = useRenderData({
    onObjectItemClick,
    onObjectEditClick,
    //onRdpItemClick,
    onRdpEditClick,
    onPdItemClick,
    onPdEditClick,
    onIiItemClick,
    onIiEditClick,
    onIrdItemClick,
    onIrdEditClick,

    onRowClick,

    objectsColumns,
    pdColumns,
    emptyPageData
  })


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const MenuOpen = Boolean(anchorEl)
  const ProjectBreadClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const onMenuItemClick = useCallback((projectId: number) => {
    isTimPage ? navigate(`/project/${projectId}/tim`) : navigate(`/project/${projectId}/objects`)
    onClose()
  }, [onClose, isTimPage])

  useBreadcrumbs([
    {
      title: project.shifrName,
      onClick: (e) => ProjectBreadClick(e),
      MenuOpen,
      type: 'project'
    },
    {
      title: isTimPage || selectedProjectPhase === 'Рабочая документация'
        ?
        rdViewTab === 'objects' ? 'Объекты'
          : isTimPage ? 'Разделы' : 'Разделы РД'
        : docsConfigByPhase[selectedProjectPhase]?.objects.breadcrumbTitle,
    }
  ],
    [MenuOpen, project, selectedProjectPhase, rdViewTab]
  )

  const { data: projectsData, isLoading: projectsLoading, isFetching: projectsFetching } = useGetProjectsQuery({})

  const { data: projects } = projectsData || ({} as GetProjectsResponse)

  return (
    <Stack flex={1}>
      {isDataLoading /* || switchSettingsLoading */
        ? <Progress />
        : (
          <>
            <ObjectsLegend
              onAddClick={onAddClick}
              onUploadClick={onUploadClick}
              onExportClick={onExportClick}
              showButtons={!userWithLessControls}
              showExportButon={showExportButon}
              showUploadButton={showUploadButton}
              showViewSwitcher={showViewSwitcher}
              showObjSwitcher={showObjSwitcher}
              showRdpSwitcher={showRdpSwitcher}
              allItems={isSelectedAllItems}
              changeItems={onUpdateSwitch}
            />


            {renderPageData(
              viewMode,
              filteredData,
              isSelectedAllItems!,
              isObjectsExist,
              isRdpsExist,
              isPdsExist,
              isIisExist,
              isIrdsExist,
            )}
          </>
        )
      }


      {DrawerByPhase}
      {UploadDrawerByPhase}


      <Menu
        anchorEl={anchorEl}
        open={MenuOpen}
        onClose={onClose}
        MenuListProps={{
          style: {
            width: '100%'
          }
        }}
        PaperProps={{
          style: {
            display: 'flex',
            justifyContent: 'center',
            minWidth: 200,
            // minHeight: 200,
            maxWidth: 450,
            maxHeight: 300,
          }
        }}
      >
        {projectsLoading || projectsFetching
          ? <Progress />
          : projects?.length > 1
            ? projects.filter(project => project.id !== parseInt(projectId!))?.map(project => (
              <MenuItem onClick={() => onMenuItemClick(project.id)} style={{ maxWidth: 450 }} key={project.id}>
                <Typography style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
                >
                  {project.shifrName}
                </Typography>
              </MenuItem>
            ))
            : <MenuItem style={{ maxWidth: 450 }} disabled>
              <Typography>
                Другие проекты отсутствуют
              </Typography>
            </MenuItem>
        }
      </Menu>
      <ConfirmDialog />
    </Stack>
  )
}

export default Objects