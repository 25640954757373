import { AccordionDetails, Stack } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MetaInfoAccordionProps } from "./MetaInfoAccordion.types";
import { StyledAccordion, StyledAccordionSummary } from "./MetaInfoAccordion.styles";
import { SubTitleText, TitleText } from "../ModelMetaInfo";

export const MetaInfoAccordion = ({data, level = 0}: MetaInfoAccordionProps) => {

  const isObject = (value: any): value is Record<string, any> => value && typeof value === 'object' && !Array.isArray(value)
  const isBoolean = (value: any): value is boolean => typeof value === 'boolean'

  const renderContent = (key: any, value: any) => {
    if (isObject(value)) {
      return (
        <MetaInfoAccordion key={key} data={value} level={level + 1} />
      )
    } else {
      const displayValue = isBoolean(value) ? value.toString() : !value ? 'не указано' : value
      return (
        <Stack direction='row' key={key} pl={3.5} spacing={0.5} alignItems='baseline'>
        <TitleText data-type="key">{key}</TitleText>
        <span style={{pointerEvents: 'none'}}>=</span>
        <SubTitleText variant='body2'>{displayValue}</SubTitleText>
        </Stack>
      )
    }
  }

  return (
    <Stack ml={level}>
      {Object.keys(data).map(key => (
        <StyledAccordion key={key} TransitionProps={{ unmountOnExit: true }} disableGutters>
          <StyledAccordionSummary expandIcon={<ArrowDropDownIcon fontSize='medium'/>}>
            <TitleText data-type="key">{key}</TitleText>
          </StyledAccordionSummary>
          <AccordionDetails sx={{px: 0, pt: 1, pb: 0}}>
            {renderContent(key, data[key])}
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Stack>
  )
}