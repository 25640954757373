import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { Stack } from '@mui/material'
import React, { ReactNode } from 'react'

import BookIcon from '../../../../../../assets/icons/BookIcon'
import EditDocIcon from '../../../../../../assets/icons/EditDocIcon'
// @ts-ignore:next-line
import ObjectsIcon from '../../../../../../assets/icons/ObjectsIcon'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { detailedSidebarItemsSelector } from '../../../../../../store/slices/documentsPages/sidebar'
import { useTypedSelector } from '../../../../../../store/store'
import { ProjectPhase } from '../../../../../../types/project'
import { COMMON_DOCS_NUMBER } from '../../../../../../utils/constants'
import { ObjectAccordion } from '../../../../../Docs/components/ObjectAccordion'
import { AccordionItem } from '../../../../../Docs/components/ObjectAccordion/ObjectAccordion.types'
import { UseGetRenderDataProps, UseGetRenderDataResponse } from './useGetRenderData.types'

export const useGetRenderData = ({
  onTomClick,
  expanded,
  onExpand,
}: UseGetRenderDataProps): UseGetRenderDataResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { detailedSidebarObjects, detailedSidebarPds, detailedSidebarIis, detailedSidebarIrds } =
    useTypedSelector(detailedSidebarItemsSelector)

  // Rd
  const rdRenderData = (
    <Stack spacing={1.5}>
      {detailedSidebarObjects?.map((object) => {
        const { obj, rdList } = object

        const item: AccordionItem = {
          id: obj.objID,
          title: `${obj.number} — ${obj.title}`,
          hasSubItems: obj.tomExist,
          subItemsData: {
            icon: <EditDocIcon fontSize='small' color='dark' />,
            subItems: rdList?.map((rd) => ({
              id: rd.rdID,
              title: rd.mark,
              disabled: true,
              subItemsData: {
                onSubItemClick: (e, objectId: number, rdId: number, tomId: number) => {
                  e.stopPropagation()
                  onTomClick['Рабочая документация'](objectId, rdId, tomId)
                },
                subItems: rd.tomList?.map((tom) => ({
                  id: tom.tomId,
                  title: tom.title,
                })),
              },
            })),
          },
        }

        const commonObject = obj.number === COMMON_DOCS_NUMBER

        return (
          <ObjectAccordion
            item={item}
            expanded={expanded}
            onExpand={onExpand}
            icon={
              commonObject ? (
                <BookIcon fontSize='small' color='primary' />
              ) : (
                <ObjectsIcon fontSize='small' color='primary' />
              )
            }
            key={item.id}
          />
        )
      })}
    </Stack>
  )

  // Pd
  const pdRenderData = (
    <Stack spacing={1.5}>
      {detailedSidebarPds?.map((pd) => {
        const { id, mark, tomExist, tomList } = pd

        const item: AccordionItem = {
          id,
          title: mark,
          hasSubItems: tomExist,
          subItemsData: {
            onSubItemClick: (e, pdId: number, tomId: number) => {
              e.stopPropagation()
              onTomClick['Проектная документация'](pdId, tomId)
            },
            subItems: tomList?.map((tom) => ({
              id: tom.tomId,
              title: tom.title,
            })),
          },
        }

        return (
          <ObjectAccordion
            item={item}
            expanded={expanded}
            onExpand={onExpand}
            icon={<DescriptionOutlinedIcon fontSize='small' color='primary' />}
            key={item.id}
          />
        )
      })}
    </Stack>
  )

  // Ii
  const iiRenderData = (
    <Stack spacing={1.5}>
      {detailedSidebarIis?.map((ii) => {
        const { id, mark, tomExist, tomList } = ii

        const item: AccordionItem = {
          id,
          title: mark,
          hasSubItems: tomExist,
          subItemsData: {
            onSubItemClick: (e, iiId: number, tomId: number) => {
              e.stopPropagation()
              onTomClick['Инженерные изыскания'](iiId, tomId)
            },
            subItems: tomList?.map((tom) => ({
              id: tom.tomId,
              title: tom.title,
            })),
          },
        }

        return (
          <ObjectAccordion
            item={item}
            onItemClick={() => {}}
            expanded={expanded}
            onExpand={onExpand}
            icon={<DescriptionOutlinedIcon fontSize='small' color='primary' />}
            key={item.id}
          />
        )
      })}
    </Stack>
  )

  // Ird
  const irdRenderData = (
    <Stack spacing={1.5}>
      {detailedSidebarIrds?.map((ird) => {
        const { id, mark, tomExist, tomList } = ird

        const item: AccordionItem = {
          id,
          title: mark,
          hasSubItems: tomExist,
          subItemsData: {
            onSubItemClick: (e, irdId: number, tomId: number) => {
              e.stopPropagation()
              onTomClick['Сбор исходных данных'](irdId, tomId)
            },
            subItems: tomList?.map((tom) => ({
              id: tom.tomId,
              title: tom.title,
            })),
          },
        }

        return (
          <ObjectAccordion
            item={item}
            onItemClick={() => {}}
            expanded={expanded}
            onExpand={onExpand}
            icon={<DescriptionOutlinedIcon fontSize='small' color='primary' />}
            key={item.id}
          />
        )
      })}
    </Stack>
  )

  const renderData: Record<ProjectPhase, ReactNode> = {
    'Рабочая документация': rdRenderData,
    'Проектная документация': pdRenderData,
    'Инженерные изыскания': iiRenderData,
    'Сбор исходных данных': irdRenderData,
  }

  return {
    renderData: renderData[selectedProjectPhase],
  }
}
