import { Box, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material'
import DropDownIcon from 'assets/icons/DropDown'
import React from 'react'
import { ScrollableContainer } from 'styles/global/ScrollableContainer'

import {
  DocsObjectAccordionProps,
  DocsStyledAccordion,
  DocsStyledAccordionDetails,
  DocsStyledAccordionSummary,
} from '.'

export const DocsObjectAccordion: React.FC<DocsObjectAccordionProps> = (props) => {
  const renderSubItems = (items: DocsObjectAccordionProps['items']) => {
    return items?.map((item, id) => {
      return (
        <DocsStyledAccordion
          key={id}
          expanded={item.isExpanded}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            item.onItemClick()
          }}
        >
          <Tooltip
            arrow
            PopperProps={{
              sx: {
                filter:
                  'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10))',
                [`& .${tooltipClasses.tooltip}`]: {
                  padding: '5px',
                  background: ' #FFF',
                  color: '#2B3648',
                  maxWidth: 270,
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  borderRadius: '6px',
                },
                [`& .${tooltipClasses.arrow}`]: {
                  color: ' #FFF',
                },
              },
            }}
            placement='right'
            title={item.title.length > 28 ? item.title : null}
          >
            <DocsStyledAccordionSummary
              $level={item.level}
              expandIcon={
                !!item.subItems?.length ? (
                  <DropDownIcon
                    onClick={(e) => {
                      e.stopPropagation()
                      item.onExpandedClick()
                    }}
                    fontSize='medium'
                    color='secondaryDark'
                  />
                ) : null
              }
              $isSelected={item.isSelected}
            >
              <Stack direction='row' spacing={1.25} alignItems='center' width='inherit'>
                {item.icon}
                <Typography variant='subtitle2'>{item.title}</Typography>
              </Stack>
            </DocsStyledAccordionSummary>
          </Tooltip>

          {!!item.subItems?.length && (
            <DocsStyledAccordionDetails>{renderSubItems(item.subItems)}</DocsStyledAccordionDetails>
          )}
        </DocsStyledAccordion>
      )
    })
  }

  return <ScrollableContainer>{renderSubItems(props.items)}</ScrollableContainer>
}
