import styled from 'styled-components'

import { UploadResultsEntireWrapper } from '../../../../components/UploadDrawer/UploadResultsDrawer/styles'
import { DocsDrawerWrapper } from '../DocsDrawer/DocsDrawer.styles'

export const NotificationsDrawerWrapper = styled(DocsDrawerWrapper)`
  padding-bottom: 20px;
  overflow: hidden;
`

export const NotificationDrawerInnerWrapper = styled(UploadResultsEntireWrapper)`
  height: 100%;
`
