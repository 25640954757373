import { api } from '../api'
import {
  AddScheduleRequest,
  AddScheduleResponse,
  CreateWorkRequest,
  DeleteGroupRequest,
  DeleteScheduleRequest,
  DeleteScheduleResponse,
  DeleteWorkRequest,
  EditGroupNumRequest,
  EditTomStatusRequest,
  EditWorkRequest,
  EditWorkResponse,
  ExportWorksRequest,
  FullEditWorkRequest,
  FullEditWorkResponse,
  GetGroupedWorksRequest,
  GetGroupedWorksResponse,
  GetSchedulesRequest,
  GetSchedulesResponse,
  GetWorkByIdRequest,
  GetWorkLinkExampleResponse,
  GetWorksGroupsRequest,
  GetWorksGroupsResponse,
  GetWorksPersonsRequest,
  GetWorksPersonsResponse,
  GetWorksRequest,
  GetWorksResponse,
  UpdateScheduleRequest,
  UpdateScheduleResponse,
  UploadWorkRequest,
  WorksBaseResponse
} from './worksApi.types'
import qs from 'qs'
import { ExcelUploadResponse } from '../../types/global'
import { Work } from './works.types'
import { RootState } from '../../store/store'

export const worksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSchedules: build.query<GetSchedulesResponse, GetSchedulesRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/schedules/list`,
        method: 'GET',
      }),
      providesTags: ['Schedules']
    }),
    addSchedule: build.mutation<AddScheduleResponse, AddScheduleRequest>({
      query: ({ projectId, ...body }) => ({
        url: `/project/${projectId}/schedules/add`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Schedules']
    }),
    updateSchedule: build.mutation<UpdateScheduleResponse, UpdateScheduleRequest>({
      query: ({ projectId, scheduleId, ...body }) => ({
        url: `/project/${projectId}/schedules/${scheduleId}/update`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Schedules']
    }),
    deleteSchedule: build.mutation<DeleteScheduleResponse, DeleteScheduleRequest>({
      query: ({ projectId, scheduleId, ...body }) => ({
        url: `/project/${projectId}/schedules/${scheduleId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Schedules']
    }),
    getWorks: build.query<GetWorksResponse, GetWorksRequest>({
      query: ({ id, scheduleId, limit, offset, ids, status, ...multiParams }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/list?${qs.stringify(multiParams, { indices: false })}`,
        params: { limit, offset, ids, status },
        method: 'GET',
      }),
      providesTags: (result) =>
        result?.data?.length
          ? [
            ...result.data.map(({ id }) => ({ type: 'Works' as const, id })),
            { type: 'Works', id: 'LIST' },
            { type: 'Works', id: 'PARTIAL-LIST' },
          ]
          : [
            { type: 'Works', id: 'LIST' },
            { type: 'Works', id: 'PARTIAL-LIST' },
          ],
    }),
    getGroupedWorks: build.query<GetGroupedWorksResponse, GetGroupedWorksRequest>({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const { id, scheduleId, limit, offset, ids, status, ...multiParams } = arg || {}

        const data = await baseQuery({
          url: `/project/${id}/schedules/${scheduleId}/work/grouped-list?${qs.stringify(multiParams, { indices: false })}`,
          params: { limit, offset, ids, status },
          method: 'GET',
        })

        const state = queryApi.getState() as RootState
        const getGroupedWorksKeys = Object.keys(state.api.queries).filter(key => key.includes('getGroupedWorks'))
        const getGroupedWorksLastKey = getGroupedWorksKeys[getGroupedWorksKeys?.length - 1]
        const currentData = (state.api.queries[getGroupedWorksLastKey]?.data || null) as GetGroupedWorksResponse

        let groupMatchIndex = -1
        const worksData = (data.data as GetGroupedWorksResponse)?.data.map((group) => {
          groupMatchIndex++

          const currentDataGroup = currentData?.data?.[groupMatchIndex]
          const isGroupMatch = group.group === currentDataGroup?.group && group.numGroup === currentDataGroup?.numGroup

          !isGroupMatch && groupMatchIndex++

          return {
            ...group,
            hideWorks: !!currentData?.data?.[groupMatchIndex]?.hideWorks
          }
        }) || []


        return {
          data: {
            ...data.data as GetGroupedWorksResponse,
            data: worksData
          }
        } as { data: GetGroupedWorksResponse }
      },
      providesTags: (result) =>
        result?.data?.length
          ? [
            ...result.data
              .reduce<Work[]>((works, group) => [...works, ...group.works], [])
              .map(work => ({ type: 'Works' as const, id: work.id })),
            { type: 'Works', id: 'LIST' },
            { type: 'Works', id: 'PARTIAL-LIST' },
          ]
          : [
            { type: 'Works', id: 'LIST' },
            { type: 'Works', id: 'PARTIAL-LIST' },
          ],
    }),
    getWorkGroups: build.query<GetWorksGroupsResponse, GetWorksGroupsRequest>({
      query: ({ id, scheduleId }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/groups`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Works', id: 'GROUPS' }],
    }),
    getWorkPersons: build.query<GetWorksPersonsResponse, GetWorksPersonsRequest>({
      query: ({ id, scheduleId }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/persons`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Works', id: 'PERSONS' }],
    }),
    createWork: build.mutation<WorksBaseResponse, CreateWorkRequest>({
      query: ({ id, scheduleId, ...body }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/add`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: createdWork } = await queryFulfilled

          // todo сделать с проверкой на группу, если надо будет
          // getWorks
          // const getWorksKeys = Object.keys(state.api.queries).filter(key => key.includes('getWorks'))
          // const getWorksLastKey = getWorksKeys[getWorksKeys?.length - 1]
          //
          // dispatch(
          //   worksApi.util.updateQueryData(
          //     'getWorks',
          //     state.api.queries[getWorksLastKey]?.originalArgs as GetWorksRequest,
          //     (draft) => {
          //       draft.data.push(updatedWork)
          //     }
          //   )
          // )

          // getGroupedWorks
          dispatch(
            worksApi.util.invalidateTags([{ type: 'Works', id: 'LIST' }])
          )

          // getWorkGroups
          dispatch(
            worksApi.util.invalidateTags([{ type: 'Works', id: 'GROUPS' }])
          )

          // getWorkPersons
          dispatch(
            worksApi.util.invalidateTags([{ type: 'Works', id: 'PERSONS' }])
          )

          // shedules
          dispatch(
            worksApi.util.invalidateTags(['Schedules'])
          )
        } catch {
        }
      },
    }),
    uploadWork: build.mutation<ExcelUploadResponse, UploadWorkRequest>({
      query: ({ id, scheduleId, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${id}/schedules/${scheduleId}/work/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
    }),
    uploadWithReplaceWork: build.mutation<ExcelUploadResponse, UploadWorkRequest>({
      query: ({ id, scheduleId, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${id}/schedules/${scheduleId}/work/clear-and-upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
    }),
    exportWorks: build.mutation<Blob, ExportWorksRequest>({
      query: ({ id, scheduleId }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    getWorkById: build.query<WorksBaseResponse, GetWorkByIdRequest>({
      query: ({ id, scheduleId, workId }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/${workId}/get`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => {
        return [{ type: 'Works', id: arg.workId }]
      },
    }),
    editGroupNum: build.mutation<void, EditGroupNumRequest>({
      query: ({ id, scheduleId, ...body }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/group-update-num`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Works', id: 'LIST' }]
    }),
    editTomStatus: build.mutation<void, EditTomStatusRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/work/tom-update-status`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Works', id: 'LIST' }]
    }),
    editWork: build.mutation<EditWorkResponse, EditWorkRequest>({
      query: ({ id, scheduleId, workId, ...body }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/${workId}/update`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted({ id, workId, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled

          // todo сделать с проверкой на группу, если надо будет
          // getWorks
          // const getWorksKeys = Object.keys(state.api.queries).filter(key => key.includes('getWorks'))
          // const getWorksLastKey = getWorksKeys[getWorksKeys?.length - 1]
          //
          // dispatch(
          //   worksApi.util.updateQueryData(
          //     'getWorks',
          //     state.api.queries[getWorksLastKey]?.originalArgs as GetWorksRequest,
          //     (draft) => {
          //       const changedWorkIndex = draft.data.findIndex(work => work.id === updatedWork.id)
          //       draft.data[changedWorkIndex] = updatedWork
          //     }
          //   )
          // )

          // getGroupedWorks
          // const getGroupedWorksKeys = Object.keys(state.api.queries).filter(key => key.includes('getGroupedWorks'))
          // const getGroupedWorksLastKey = getGroupedWorksKeys[getGroupedWorksKeys?.length - 1]
          //
          // dispatch(
          //   worksApi.util.updateQueryData(
          //     'getGroupedWorks',
          //     state.api.queries[getGroupedWorksLastKey]?.originalArgs as GetGroupedWorksRequest,
          //     (draft) => {
          //       const changedWorkGroupIndex = draft.data.findIndex(group => group.group === updatedWork.group)
          //
          //       // new group
          //       if (changedWorkGroupIndex === -1) {
          //         dispatch(worksApi.util.invalidateTags([{ type: 'Works', id: 'LIST' }]))
          //         return
          //       }
          //
          //       const changedGroup = draft.data[changedWorkGroupIndex]
          //       const changedWorkIndex = changedGroup.works.findIndex(work => work.id === updatedWork.id)
          //
          //       // to existing other group
          //       if (changedWorkIndex === -1) {
          //         dispatch(worksApi.util.invalidateTags([{ type: 'Works', id: 'LIST' }]))
          //         return
          //       }
          //
          //       const changedWork = draft.data[changedWorkGroupIndex].works[changedWorkIndex]
          //
          //       if (patch.numGroup && patch.group && (patch.numGroup !== changedWork.numGroup || patch.group !== changedWork.group)) {
          //         dispatch(
          //           worksApi.util.invalidateTags([{ type: 'Works', id: 'LIST' }])
          //         )
          //         return
          //       }
          //
          //       draft.data[changedWorkGroupIndex].works[changedWorkIndex] = updatedWork
          //
          //
          //       // increase numWork
          //       const nextWork = draft.data[changedWorkGroupIndex].works[changedWorkIndex + 1]
          //       const prevWork = draft.data[changedWorkGroupIndex].works[changedWorkIndex - 1]
          //       if ((updatedWork.numWork === nextWork?.numWork)) {
          //         nextWork.numWork = updatedWork.numWork - 1
          //         swapItemsInArray(draft.data[changedWorkGroupIndex].works, changedWorkIndex, changedWorkIndex + 1)
          //       }
          //       // decrease numWork
          //       else if (updatedWork.numWork === prevWork?.numWork) {
          //         prevWork.numWork = updatedWork.numWork + 1
          //         swapItemsInArray(draft.data[changedWorkGroupIndex].works, changedWorkIndex - 1, changedWorkIndex)
          //       }
          //
          //       // UPDATE RATIO DATA
          //       draft.overallRatio = updatedOverallRatio
          //       draft.data[changedWorkGroupIndex].ratio = updatedGroupRatio
          //     }
          //   )
          // )
          //
          // // getWorkById
          // dispatch(
          //   worksApi.util.updateQueryData(
          //     'getWorkById',
          //     { id, workId },
          //     (draft) => {
          //       Object.assign(draft, updatedWork)
          //     }
          //   )
          // )

          dispatch(
            worksApi.util.invalidateTags([{ type: 'Works', id: workId }])
          )
        } catch {
        }
      },
      invalidatesTags: [
        { type: 'Works', id: 'LIST' },
        { type: 'Works', id: 'GROUPS' },
        { type: 'Works', id: 'PERSONS' },
        'Schedules'
      ]
    }),
    fullEditWork: build.mutation<FullEditWorkResponse, FullEditWorkRequest>({
      query: ({ id, scheduleId, workId, ...body }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/${workId}/update`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id, workId, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled

          // todo сделать с проверкой на группу, если надо будет
          // getWorks
          // const getWorksKeys = Object.keys(state.api.queries).filter(key => key.includes('getWorks'))
          // const getWorksLastKey = getWorksKeys[getWorksKeys?.length - 1]
          //
          // dispatch(
          //   worksApi.util.updateQueryData(
          //     'getWorks',
          //     state.api.queries[getWorksLastKey]?.originalArgs as GetWorksRequest,
          //     (draft) => {
          //       const changedWorkIndex = draft.data.findIndex(work => work.id === updatedWork.id)
          //       draft.data[changedWorkIndex] = updatedWork
          //     }
          //   )
          // )

          // getGroupedWorks
          // const getGroupedWorksKeys = Object.keys(state.api.queries).filter(key => key.includes('getGroupedWorks'))
          // const getGroupedWorksLastKey = getGroupedWorksKeys[getGroupedWorksKeys?.length - 1]
          //
          // dispatch(
          //   worksApi.util.updateQueryData(
          //     'getGroupedWorks',
          //     state.api.queries[getGroupedWorksLastKey]?.originalArgs as GetGroupedWorksRequest,
          //     (draft) => {
          //       const changedWorkGroupIndex = draft.data.findIndex(group => group.group === updatedWork.group)
          //
          //       // new group
          //       if (changedWorkGroupIndex === -1) {
          //         dispatch(worksApi.util.invalidateTags([{ type: 'Works', id: 'LIST' }]))
          //         return
          //       }
          //
          //       const changedGroup = draft.data[changedWorkGroupIndex]
          //       const changedWorkIndex = changedGroup.works.findIndex(work => work.id === updatedWork.id)
          //
          //       // to existing group
          //       if (changedWorkIndex === -1) {
          //         dispatch(worksApi.util.invalidateTags([{ type: 'Works', id: 'LIST' }]))
          //         return
          //       }
          //
          //       const changedWork = draft.data[changedWorkGroupIndex].works[changedWorkIndex]
          //
          //       if (patch.numGroup && patch.group && (patch.numGroup !== changedWork.numGroup || patch.group !== changedWork.group)) {
          //         dispatch(
          //           worksApi.util.invalidateTags([{ type: 'Works', id: 'LIST' }])
          //         )
          //         return
          //       }
          //
          //       draft.data[changedWorkGroupIndex].works[changedWorkIndex] = updatedWork
          //
          //       // increase numWork
          //       const nextWork = draft.data[changedWorkGroupIndex].works[changedWorkIndex + 1]
          //       const prevWork = draft.data[changedWorkGroupIndex].works[changedWorkIndex - 1]
          //       if ((updatedWork.numWork === nextWork?.numWork)) {
          //         nextWork.numWork = updatedWork.numWork - 1
          //         swapItemsInArray(draft.data[changedWorkGroupIndex].works, changedWorkIndex, changedWorkIndex + 1)
          //       }
          //       // decrease numWork
          //       else if (updatedWork.numWork === prevWork?.numWork) {
          //         prevWork.numWork = updatedWork.numWork + 1
          //         swapItemsInArray(draft.data[changedWorkGroupIndex].works, changedWorkIndex - 1, changedWorkIndex)
          //       }
          //     }
          //   )
          // )
          //
          // // getWorkById
          // dispatch(
          //   worksApi.util.updateQueryData(
          //     'getWorkById',
          //     { id, workId },
          //     (draft) => {
          //       Object.assign(draft, updatedWork)
          //     }
          //   )
          // )

          dispatch(
            worksApi.util.invalidateTags([{ type: 'Works', id: workId }])
          )

        } catch (e: any) {
          console.log('e', e)
        }
      },
      invalidatesTags: [
        { type: 'Works', id: 'LIST' },
        { type: 'Works', id: 'GROUPS' },
        { type: 'Works', id: 'PERSONS' },
        'Schedules'
      ]
    }),
    deleteWork: build.mutation<WorksBaseResponse, DeleteWorkRequest>({
      query: ({ id, scheduleId, workId }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/${workId}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        try {
          const state = getState()
          const { data: deletedWork } = await queryFulfilled

          // todo сделать с проверкой на группу, если надо будет
          // getWorks
          // const getWorksKeys = Object.keys(state.api.queries).filter(key => key.includes('getWorks'))
          // const getWorksLastKey = getWorksKeys[getWorksKeys?.length - 1]
          //
          // dispatch(
          //   worksApi.util.updateQueryData(
          //     'getWorks',
          //     state.api.queries[getWorksLastKey]?.originalArgs as GetWorksRequest,
          //     (draft) => {
          //       draft.data.push(updatedWork)
          //     }
          //   )
          // )

          // // getGroupedWorks
          // const getGroupedWorksKeys = Object.keys(state.api.queries).filter(key => key.includes('getGroupedWorks'))
          // const getGroupedWorksLastKey = getGroupedWorksKeys[getGroupedWorksKeys?.length - 1]
          //
          // dispatch(
          //   worksApi.util.updateQueryData(
          //     'getGroupedWorks',
          //     state.api.queries[getGroupedWorksLastKey]?.originalArgs as GetGroupedWorksRequest,
          //     (draft) => {
          //       const deletedWorkGroupIndex = draft.data.findIndex(group => group.group === deletedWork.group)
          //       const changedGroup = draft.data[deletedWorkGroupIndex]
          //
          //       const deletedWorkIndex = changedGroup.works.findIndex(work => work.id === deletedWork.id)
          //
          //       draft.data[deletedWorkGroupIndex].works.splice(deletedWorkIndex, 1)
          //     }
          //   )
          // )

        } catch {
        }
      },
      invalidatesTags: [
        { type: 'Works', id: 'LIST' },
        { type: 'Works', id: 'GROUPS' },
        { type: 'Works', id: 'PERSONS' },
        'Schedules'
      ],
    }),
    getWorkLinkExample: build.mutation<GetWorkLinkExampleResponse, void>({
      query: () => ({
        url: `/project/work/link-example`,
        method: 'GET',
      }),
    }),

    deleteGroup: build.mutation<void, DeleteGroupRequest>({
      query: ({ id, scheduleId, ...body }) => ({
        url: `/project/${id}/schedules/${scheduleId}/work/group-delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: 'Works', id: 'LIST' },
        { type: 'Works', id: 'GROUPS' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetSchedulesQuery,
  useAddScheduleMutation,
  useUpdateScheduleMutation,
  useDeleteScheduleMutation,
  useGetWorksQuery,
  useGetGroupedWorksQuery,
  useGetWorkGroupsQuery,
  useGetWorkPersonsQuery,
  useCreateWorkMutation,
  useUploadWorkMutation,
  useUploadWithReplaceWorkMutation,
  useExportWorksMutation,
  useGetWorkByIdQuery,
  useEditGroupNumMutation,
  useEditTomStatusMutation,
  useEditWorkMutation,
  useFullEditWorkMutation,
  useDeleteWorkMutation,
  useGetWorkLinkExampleMutation,
  useDeleteGroupMutation,
} = worksApi
