import * as yup from 'yup'
import { parseDateString } from '../../../../../utils/formatDate'
import { TomStatus } from '../../../../../types/tom'
import { WorkStatus } from '../../../../../types/works'

const checkIsFloat = (number: number | undefined): boolean => {
  return !!number && number % 1 !== 0
}

const isStatusRequired = (status: WorkStatus | undefined, context: any): boolean => {
  const isTomBound = !!context.parent.tom
  return isTomBound ? true : !!status
}

const isTomStatusRequired = (status: TomStatus | undefined, context: any): boolean => {
  const isTomBound = !!context.parent.tom
  return isTomBound ? !!status : true
}

export const validationWork = yup.object({
  title: yup.string().trim().required('common:errors.required'),
  group: yup.string().trim().required('common:errors.required'),
  number: yup.number().test('isFloat', 'Введите число в формате (3.0)', checkIsFloat).required('common:errors.required'),
  status: yup.string().test('isStatusRequired', 'common:errors.required', (
    value,
    context
  ) => isStatusRequired(value as WorkStatus | undefined, context)),
  tomStatus: yup.string()
    .test('isTomStatusRequired', 'common:errors.required', (
      value,
      context
    ) => isTomStatusRequired(value as TomStatus | undefined, context)),
  datePlanStart: yup.date().transform(parseDateString).nullable().required('common:errors.required').typeError('Неверный формат даты'),
  datePlanEnd: yup.date().transform(parseDateString).min(yup.ref('datePlanStart'), 'Дата окончания должна быть позже даты начала').nullable().required('common:errors.required').typeError('Неверный формат даты'),
  dateFactStart: yup.date().transform(parseDateString).nullable().typeError('Неверный формат даты'),
  dateFactEnd: yup.date().transform(parseDateString).min(yup.ref('dateFactStart'), 'Дата окончания должна быть позже даты начала').nullable().typeError('Неверный формат даты'),
  weight: yup.number().min(0, 'Минимум 0%').max(100, 'Максимум 100%').nullable(),
  ready: yup.number().min(0, 'Минимум 0%').max(100, 'Максимум 100%').nullable(),
  description: yup.string().trim(),
})
