import { Config, ConfigItem } from './types'

const configData: ConfigItem[] = [
  // ---------------dev-----------------------------
  {
    companyName: 'PragmaCore',
    appHost: 'localhost',
    apiHost: 'https://dev.api.design.pragma.info',

    coreURL: 'https://dev.core.pragma.info/',
  },
  // ---------------demo-----------------------------
  {
    companyName: 'PragmaCore',
    appHost: 'dev.design.pragma.info',
    apiHost: 'https://dev.api.design.pragma.info',

    coreURL: 'https://dev.core.pragma.info/',
  },
  // ---------------prod------------------------------
  {
    companyName: 'PragmaCore',
    appHost: 'design.pragma.info',
    apiHost: 'https://api.design.pragma.info',
  },
  {
    companyName: 'АО «СК «Мост»',
    appHost: 'most.design.pragma.info',
    apiHost: 'https://most.api.design.pragma.info',
  },
  {
    companyName: 'АО «Оргэнергострой»',
    appHost: 'oes.design.pragma.info',
    apiHost: 'https://oes.api.design.pragma.info',
  },
  {
    companyName: 'ПАО ГМК «Норникель»',
    appHost: 'nn.design.pragma.info',
    apiHost: 'https://nn.api.design.pragma.info',
  },
  {
    companyName: 'АО «ГК «ЕКС»',
    appHost: 'eks.design.pragma.info',
    apiHost: 'https://eks.api.design.pragma.info',
  },
  {
    companyName: 'ООО «Автоматизация Производств»',
    appHost: 'ap.design.pragma.info',
    apiHost: 'https://ap.api.design.pragma.info',
  },
  {
    companyName: 'Incepta Group',
    appHost: 'ig.design.pragma.info',
    apiHost: 'https://ig.api.design.pragma.info',
  },
  {
    companyName: 'ООО «СТИ»',
    appHost: 'sti.design.pragma.info',
    apiHost: 'https://sti.api.design.pragma.info',
  },
  {
    companyName: 'ОАО «РЖД»',
    appHost: 'rzd.design.pragma.info',
    apiHost: 'https://rzd.api.design.pragma.info',
  },
  {
    companyName: 'ООО «Эколант»',
    appHost: 'ecolant.design.pragma.info',
    apiHost: 'https://ecolant.api.design.pragma.info',
  },
  {
    companyName: 'АО «Золото Селигдара»',
    appHost: 'zs.design.pragma.info',
    apiHost: 'https://zs.api.design.pragma.info',
  },
  {
    companyName: 'Capital Group',
    appHost: 'cg.design.pragma.info',
    apiHost: 'https://cg.api.design.pragma.info',
  },
  {
    companyName: 'ООО «Физир Девелопмент»',
    appHost: 'fd.design.pragma.info',
    apiHost: 'https://fd.api.design.pragma.info',
  },
  {
    companyName: 'ФГКУ Росгранстрой',
    appHost: 'rgs.design.pragma.info',
    apiHost: 'https://rgs.api.design.pragma.info',
  },
  {
    companyName: 'ООО «СтройПроект»',
    appHost: 'sp.design.pragma.info',
    apiHost: 'https://sp.api.design.pragma.info',
  },
  {
    companyName: 'ООО «ЭСК «Энергомост»',
    appHost: 'energomost.design.pragma.info',
    apiHost: 'https://energomost.api.design.pragma.info',
  },
  {
    companyName: 'ГЕО',
    appHost: 'geo.design.pragma.info',
    apiHost: 'https://geo.api.design.pragma.info',
  },
  {
    companyName: 'ООО «СУ-555»',
    appHost: 'su555.design.pragma.info',
    apiHost: 'https://su555.api.design.pragma.info',
  },
  {
    companyName: 'ООО ГК «СУ-555»',
    appHost: 'gcsu-555.design.pragma.info',
    apiHost: 'https://gcsu-555.api.design.pragma.info',
  },
  {
    companyName: 'ООО «Рустэко»',
    appHost: 'rusteko.design.pragma.info',
    apiHost: 'https://rusteko.api.design.pragma.info',
  },
  {
    companyName: 'ООО «Демолишн»',
    appHost: 'dml.design.pragma.info',
    apiHost: 'https://dml.api.design.pragma.info',
  },
  {
    companyName: 'ООО ПО «Квант»',
    appHost: 'kvant.design.pragma.info',
    apiHost: 'https://kvant.api.design.pragma.info',
  },
  {
    companyName: 'АО «КРНО»',
    appHost: 'krno.design.pragma.info',
    apiHost: 'https://krno.api.design.pragma.info',
  },
  {
    companyName: 'ООО «ЭНЕРГОРЕШЕНИЯ»',
    appHost: 'er.design.pragma.info',
    apiHost: 'https://er.api.design.pragma.info',
  },
  {
    companyName: 'ООО «ТеплоГаз»',
    appHost: 'tg.design.pragma.info',
    apiHost: 'https://tg.api.design.pragma.info',
  },
  {
    companyName: 'ФБУ «РосСтройКонтроль»',
    appHost: 'rsk.design.pragma.info',
    apiHost: 'https://rsk.api.design.pragma.info',
  },
  {
    companyName: 'Черное море',
    appHost: 'blacksea.design.pragma.info',
    apiHost: 'https://blacksea.api.design.pragma.info',
  },
  {
    companyName: 'Группа «Самолет»',
    appHost: 'samolet.design.pragma.info',
    apiHost: 'https://samolet.api.design.pragma.info',
  },
  {
    companyName: 'ООО «Инарктика СЗ»',
    appHost: 'inarctica.design.pragma.info',
    apiHost: 'https://inarctica.api.design.pragma.info',
  },
  {
    companyName: 'ООО «Билдсервис»',
    appHost: 'buildservice.design.pragma.info',
    apiHost: 'https://buildservice.api.design.pragma.info',
  },
  {
    companyName: 'ООО «Пелискер»',
    appHost: 'pelisker.design.pragma.info',
    apiHost: 'https://pelisker.api.design.pragma.info',
  },
  {
    companyName: 'ООО «ЭЗОИС-Урал»',
    appHost: 'ezois-ural.design.pragma.info',
    apiHost: 'https://ezois-ural.api.design.pragma.info',
  },
  {
    companyName: 'ООО «СОЮЗДОНСТРОЙ»',
    appHost: 'soyuzdonstroy.design.pragma.info',
    apiHost: 'https://soyuzdonstroy.api.design.pragma.info',
  },
  {
    companyName: 'АО «ГАЗПРОМ ШЕЛЬФПРОЕКТ»',
    appHost: 'gsp.design.pragma.info',
    apiHost: 'https://gsp.api.design.pragma.info',
  },
  {
    companyName: 'Setl Group',
    appHost: 'setlgroup.design.pragma.info',
    apiHost: 'https://setlgroup.api.design.pragma.info',
  },
  {
    companyName: 'PSM',
    appHost: 'psm.design.pragma.info',
    apiHost: 'https://psm.api.design.pragma.info',
  },
]

const currentConfig = configData.find((it) => {
  return document.location.hostname === it.appHost
})

export const config: Config = {
  coreURL: currentConfig?.coreURL ?? 'https://core.pragma.info/',
  appHost: currentConfig?.appHost ?? 'localhost',
  apiHost: currentConfig?.apiHost ?? 'https://api.design.pragma.info',
  companyName: currentConfig?.companyName ?? 'PragmaCore',
}
