import { useState } from "react";
import { StyledAccordion, StyledAccordionDetails } from "../ModelViewAccordion"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Checkbox, Divider, Typography } from "@mui/material";
import { SubAccordionItem, SubAccordionProps } from "./SubAccordion.types";
import { useAppDispatch, useTypedSelector } from "store/store";
import { selectedModelsSelector } from "store/slices/tim/selectors/tim.selectors";
import { setSelectedModels } from "store/slices/tim/tim";
import { StyledSubItemsCheckbox, SubAccordionSummary } from "./SubAccordion.styles";
import { SubAccordionDetails } from "../SubAccordionDetails";
import { SelectedModels } from "store/slices/tim/tim.types";
import { TimViewerGroup, TimViewerModel } from "api/tim/types";
import Tooltip from "components/Tooltip";

export const SubAccordion = ({ item }: SubAccordionProps) => {
  const dispatch = useAppDispatch()
  const [expanded, setExpanded] = useState<boolean>(false)
  const selectedModels = useTypedSelector(selectedModelsSelector)

  const onSummaryClick = () => {
    setExpanded(prevState => !prevState)
  }

  const isGroupTim = (item: SubAccordionItem): item is TimViewerGroup => {
    return (item as TimViewerGroup)?.models !== undefined
  }
  const isModelTim = (item: SubAccordionItem): item is TimViewerModel => {
    return (item as TimViewerModel)?.versions !== undefined
  }

  const onGroupCheckboxClick = (checked: boolean, group: TimViewerGroup) => {
    const data: TimViewerModel[] = group.models
    if (data) {
      if (!checked) {
        const filteredTims = selectedModels.filter(
          selectedTim => !data.some(tim => tim.id === selectedTim.timID)
        )
        dispatch(setSelectedModels(filteredTims))
      } else {
        const newTims: SelectedModels[] = data.map(tim => ({ timID: tim.id, name: tim.name, tanglID: tim.versions[0].tanglID! }))
        const updatedSelectedTims = [...selectedModels, ...newTims]
        dispatch(setSelectedModels(updatedSelectedTims))
      }
    }
  }

  const onModelCheckboxClick = (checked: boolean, model: TimViewerModel) => {
    if (!checked) {
      const filteredTims = selectedModels.filter(
        selectedTim => model.id !== selectedTim.timID
      )
      dispatch(setSelectedModels(filteredTims))
    } else {
      const newTims: SelectedModels = { timID: model.id, name: model.name, tanglID: model.versions[0].tanglID! }
      const updatedSelectedTims = [...selectedModels, newTims]
      dispatch(setSelectedModels(updatedSelectedTims))
    }
  }

  const checkedGroup = (group: TimViewerGroup): boolean => {
    const selectedModelSet = new Set(selectedModels.map(model => model.timID))

    for (const model of group.models) {
      if (selectedModelSet.has(model.id)) {
        return true
      }
    }
    return false
  }

  const checkedModel = (model: TimViewerModel): boolean => {
    const selectedModelSet = new Set(selectedModels.map(model => model.timID))

    if (!selectedModelSet.has(model.id)) {
      return false
    }
    return true
  }

  const getCheckedState = (item: SubAccordionItem) => {
    switch (true) {
      case isGroupTim(item): return checkedGroup(item as TimViewerGroup)
      case isModelTim(item): return checkedModel(item as TimViewerModel)
    }
  }

  const onCheckboxClick = (checked: boolean, item: SubAccordionItem) => {
    switch (true) {
      case isGroupTim(item): return onGroupCheckboxClick(checked, item as TimViewerGroup)
      case isModelTim(item): return onModelCheckboxClick(checked, item as TimViewerModel)
    }
  }

  const getItemName = (item: SubAccordionItem): string => {
    if (isGroupTim(item)) {
      return item.name
    } else if (isModelTim(item)) {
      return item.name
    } else return ''
  }

  return (
    <StyledAccordion
      expanded={expanded}
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
    >
      <SubAccordionSummary
        onClick={onSummaryClick}
        expandIcon={<ArrowDropDownIcon fontSize='medium' />}
      >
        <StyledSubItemsCheckbox
          control={<Checkbox disableRipple />}
          labelPlacement='start'
          checked={getCheckedState(item)}
          onChange={(e, checked) => onCheckboxClick(checked, item)}
          onClick={(e) => e.stopPropagation()}
          label={
            <Tooltip variant="light" placement="right" title={item.name.length > 40 ? item.name : ''}>
              <Typography>{item.name}</Typography>
            </Tooltip>
          }
        />
      </SubAccordionSummary>
      <StyledAccordionDetails>
        {isGroupTim(item) ?
          item.models.map((model) =>
            <SubAccordion key={model.id} item={model} />
          ) : isModelTim(item) ?
            item.versions.map((version, index) =>
              <Box key={version.id}>
                <SubAccordionDetails
                  version={version}
                  name={item.name}
                  timID={item.id} />
                {index === item.versions.length - 1 && <Divider sx={{ my: 1.25, borderColor: '#CAD3F4' }} />}
              </Box>
            ) : null
        }
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}