import React, { FC, useCallback, useState } from 'react'

import { ProjectFullExportDrawerProps } from './ProjectFullExportDrawer.types'
import { Box, Button, Drawer, Typography } from '@mui/material'
import { ProjectFullExportDrawerTopBar, ProjectFullExportDrawerWrapper } from './ProjectFullExportDrawer.styles'
import { theme } from '../../../../styles/theme'
import Divider from '../../../../components/Divider'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ProjectFullExportForm } from './components/ProjectFullExportForm'

export const ProjectFullExportDrawer: FC<ProjectFullExportDrawerProps> = ({ open, onDrawerClose }) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => onDrawerClose(dirty)}
    >
      <ProjectFullExportDrawerWrapper>
        <ProjectFullExportDrawerTopBar direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
          <Box>
            <Typography variant='h1' color={theme.palette.primary.main}>
              Экспорт архива документации
            </Typography>
          </Box>

          <Button
            href='https://gge.ru/'
            target='_blank'
            size='small'
            variant='text'
            endIcon={<ArrowForwardIcon fontSize='small' />}
            style={{ height: 29 }}
          >
            <Typography
              variant='buttonSmall'
              fontWeight={400}
              fontSize={14}
              letterSpacing={0.17}
              textTransform='none'
            >
              Перейти на сайт главгосэкспертизы
            </Typography>
          </Button>
        </ProjectFullExportDrawerTopBar>

        <Divider />

        <ProjectFullExportForm onFormChange={onFormChange} onDrawerClose={onDrawerClose} />
      </ProjectFullExportDrawerWrapper>
    </Drawer>
  )
}
