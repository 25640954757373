import React, { useCallback, useState } from 'react'
import UploadDrawer from '../../../../components/UploadDrawer'
import { DocsUploadFilesDrawerProps } from './DocsUploadFilesDrawer.types'
import { TomMultiUploadResponse } from '../../../../types/tom'
import { useDocsFilesSubmit } from '../../hooks/useDocsFilesSubmit'
import { onDrawerClose as onDrawerCloseStore } from '../../../../store/slices/documentsPages/drawerInfo'
import { useAppDispatch } from '../../../../store/store'

export const DocsUploadFilesDrawer: React.FC<DocsUploadFilesDrawerProps> = ({ open }) => {
  const dispatch = useAppDispatch()

  const [files, setFiles] = useState<File[] | null>(null)
  const [responseData, setResponseData] = useState<TomMultiUploadResponse | undefined>(undefined)

  const { isTomLoading } = useDocsFilesSubmit({ files, setResponseData })

  const onFormSubmit = useCallback((files: File[]) => {
    setFiles(files)
  }, [])

  const onFormChange = useCallback(() => {
    setResponseData(undefined)
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    dispatch(onDrawerCloseStore({ dirty, immediately }))
    setResponseData(undefined)
    setFiles(null)
  }, [onDrawerCloseStore])

  return (
    <UploadDrawer
      open={open}
      mode='multi'
      onSubmit={onFormSubmit}
      responseData={responseData}
      onChange={onFormChange}
      onClose={onDrawerClose}
      title='Загрузка PDF файлов'
      text='Вы можете загрузить сразу несколько PDF файлов в систему, они добавятся в соответствующие тома по совпадению шифра тома и названия файла.'
      attentionText={<>
        Для успешного прикрепления файла, его название должно полностью совпадать с шифром тома. Если&nbsp;шифр тома содержит косую черту (/), вы&nbsp;можете заменить его на подчерк (_) в имени импортируемого файла.
        <br />
        <br />
        Размер одного файла не может превышать 256 МБ, а количество одновременно добавляемых файлов не может быть больше 50-и.
      </>}
      uploadData={{
        title: 'Загрузить PDF',
        text: 'Чтобы загрузить документы, выберите их из папки на компьютере или просто перетяните его в эту область.',
        formats: ['application/pdf']
      }}
      loading={isTomLoading}
    />
  )
}