import React, { FC } from 'react'
import { Stack } from '@mui/material'
import { ImageField } from '../ImageField'
import { FieldArray, useFormikContext } from 'formik'
import { ProjectFormData } from '../../ProjectManagement.types'
import { ImageItem } from '../ImageItem'
import { ImagesTitledWrapper } from './FormRightBlock.styles'

export const FormRightBlock: FC = () => {
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFormData>()

  return (
    <Stack flex={1}>
      <ImagesTitledWrapper
        title='Визуализация объекта'
        variant='primary'
        contentWrapper='stack'
        bordered='border'
      >
        <FieldArray
          name='initialImages'
          render={({ remove }) => (
            <Stack direction='row' gap={2.5} flexWrap='wrap'>
              {formValues.initialImages.map((image, index) => (
                <ImageItem
                  url={image.imageFull}
                  key={index}
                  onClick={() => {
                    setFieldValue('imagesIdsToDelete', [...formValues.imagesIdsToDelete, image.id])
                    remove(index)
                  }}
                />
              ))}
            </Stack>
          )}
        />

        <FieldArray
          name='imagesForCreate'
          render={({ insert, remove, }) => (
            <>
              {formValues.imagesForCreate.map((file, index) => (
                <ImageItem
                  url={URL.createObjectURL(file)}
                  onClick={() => remove(index)}
                />
              ))}

              {(formValues.initialImages.length + formValues.imagesForCreate.length) < 3 && (
                <ImageField insert={insert} />
              )}
            </>
          )}
        />
      </ImagesTitledWrapper>
    </Stack>
  )
}
