import React from 'react'
import { Outlet, useMatch } from 'react-router-dom'
import Header from '../../components/Header'
import { InnerContainer, MainContainer } from './styles'

const HomeLayout: React.FC = () => {

  const isHomePage = !!useMatch(`/home`)

  return (
    <MainContainer>
      <Header isHasSearch={isHomePage ? true : false}/>
      <InnerContainer>
        <Outlet />
      </InnerContainer>
    </MainContainer>
  )
}

export default HomeLayout