import { FC } from "react";
import { IRemarkDirectionCardProps } from "./RemarkDirectionCard.types";
import { AttachmentWrapper, RemarkCardDescription, RemarkDirectionCardWrapper, SmallButton } from "./RemarkDirectionCard.styles";
import { Avatar, Stack, Typography } from "@mui/material";
import { theme } from "styles/theme";
import { Download as DownloadIcon, Person as PersonIcon, CalendarMonth as CalendarIcon, InsertDriveFile as FileIcn } from '@mui/icons-material';
import Tooltip from "components/Tooltip";
import Progress from "components/Progress";
import { HomeWork as CompanyIcon } from '@mui/icons-material';
import { downloadBlob } from "utils/dowloadBlob";
import { EllipsisText } from "components/UserManagement/components/UserObjectsSelection/styles";
import { useLazyDownloadRemarkMailAttachmentQuery, useLazyDownloadRemarkMailQuery } from "api/remarks";
import { useParams } from "react-router-dom";
import { useMutationHandlers } from "hooks/useMutationHandlers";
import { useSnackbar } from "notistack";
import { convertDateTime } from "utils/formatDateAndTimeGMT";

export const RemarkDirectionCard: FC<IRemarkDirectionCardProps> = ({ item }) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { enqueueSnackbar } = useSnackbar()
  const { mail, attachment, excel } = item
  const { isAutoSent } = mail

  const [downloadRemarkMail, downloadRemarkMailResponse] = useLazyDownloadRemarkMailQuery()
  const [downloadRemarkMailAttachment, downloadRemarkMailAttachmentResponse] = useLazyDownloadRemarkMailAttachmentQuery()
  const isMailLoading: boolean = downloadRemarkMailResponse.isFetching
  const isAttachmentLoading: boolean = downloadRemarkMailAttachmentResponse.isFetching

  const onMailDownloadClick = () => {
    if (!isMailLoading) downloadRemarkMail({ projectId, mailId: mail.id })
  }

  const onAttachmentDownloadClick = () => {
    if (!isAttachmentLoading) downloadRemarkMailAttachment({ projectId, mailId: mail.id })
  }

  useMutationHandlers(
    downloadRemarkMailResponse,
    (data) => {
      downloadBlob(new File([data], ''), `№ ${mail.mailNum} от ${mail.mailDate}.xlsx`)
      enqueueSnackbar('Файл успешно сформирован.', { variant: 'success' })
    }
  )

  useMutationHandlers(
    downloadRemarkMailAttachmentResponse,
    (data) => {
      downloadBlob(new File([data], ''), attachment.name)
    }
  )

  return (
    <RemarkDirectionCardWrapper>
      <Stack spacing={1.25}>
        <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
          <EllipsisText variant="body2" color={theme.palette.primary.main}>№ {mail.mailNum} от {mail.mailDate}</EllipsisText>
          <Tooltip title="Экспортировать направленный реестр">
            <div>
              <SmallButton
                color='secondary'
                variant='outlined'
                onClick={onMailDownloadClick}
                icon
              >
                {isMailLoading ? <Progress size={'14px'} /> : <DownloadIcon fontSize="medium" color='primary' />}
              </SmallButton>
            </div>
          </Tooltip>
        </Stack>
        <Stack direction="row" alignItems={'center'} spacing={1.5}>
          <Avatar src={mail.responsible?.avatar} sx={{ height: '32px', width: '32px', bgcolor: theme.palette.bg.shades }}>
            <CompanyIcon fontSize="medium" color="disabled" />
          </Avatar>
          <Stack>
            <EllipsisText variant="body2" fontSize={12} color={theme.palette.secondary.dark}>{mail.responsibleCompany}</EllipsisText>
            {mail?.responsible && (
              <Typography variant="body2" fontSize={12} color={theme.palette.secondary.dark}>{mail.responsible.name}</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>

      {attachment && (
        <AttachmentWrapper onClick={onAttachmentDownloadClick} direction="row" alignItems={'center'} spacing={1}>
          {isAttachmentLoading ? <Stack width='fit-content'><Progress size={'20px'} /></Stack> : <FileIcn fontSize="medium" color="primary" />}
          <EllipsisText lineHeight={'inherit'} variant="body2" color={theme.palette.primary.main}>
            {attachment.name}
          </EllipsisText>
        </AttachmentWrapper>
      )}
      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction="row" alignItems={'center'} spacing={1}>
          <PersonIcon fontSize="medium" color="disabled" />
          <Typography lineHeight={'inherit'} fontSize={12} variant="body2" color={theme.palette.secondary.dark}>
            {mail.creator.name}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems={'center'} spacing={1}>
          <CalendarIcon fontSize="medium" color="disabled" />
          <Typography lineHeight={'inherit'} fontSize={12} variant="body2" color={theme.palette.secondary.dark}>
            {convertDateTime(mail.createdAt, true)}
          </Typography>
        </Stack>
      </Stack>

      {isAutoSent && (
        <RemarkCardDescription>
          <Typography textAlign={'center'} variant="body2" fontSize={12} color={theme.palette.secondary.dark}>Направлено автоматически на основании внешней процедуры согласования тома</Typography>
        </RemarkCardDescription>
      )}
    </RemarkDirectionCardWrapper>
  )
}