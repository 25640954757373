import { TypographyProps } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import { TextBox } from './OverflowText.styles'

interface OverflowTextProps extends TypographyProps {
  mode?: 'default' | 'table'
  text: string | null
  defaultHeight?: number
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  fontWeight?: number
}

export const OverflowText = ({
  mode,
  text,
  defaultHeight,
  marginTop,
  marginBottom,
  marginLeft,
  fontWeight,
  ...props
}: OverflowTextProps) => {
  const ref = useRef<HTMLSpanElement | HTMLParagraphElement>(null)
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)

  const checkIsElementOverflowing = (element: HTMLSpanElement | HTMLParagraphElement | null) => {
    return element ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight : false
  }

  useEffect(() => {
    ref.current && setIsOverflowing(() => checkIsElementOverflowing(ref.current))
  }, [ref])

  return (
    <TextBox
      ref={ref}
      $mode={mode}
      $isOverflowing={isOverflowing}
      fontSize={14}
      fontWeight={fontWeight || 400}
      minHeight={defaultHeight}
      maxHeight={defaultHeight}
      marginTop={marginTop || 0}
      marginLeft={marginLeft || 0}
      marginBottom={marginBottom || 0}
      {...props}
    >
      {text}
    </TextBox>
  )
}
