import { MoreVert as MenuIcon } from '@mui/icons-material'
import { useLazyExportRemarksQuery } from 'api/remarks'
import Button from 'components/Button'
import { Menu } from 'components/Menu'
import { IMenuData } from 'components/Menu/Menu.types'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { FC, MouseEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { setOpenedDrawer } from 'store/slices/documentsPages/drawerInfo'
import { remarkGroupFilterSelector } from 'store/slices/remarks/selectors/remarks.selectors'
import { useAppDispatch, useTypedSelector } from 'store/store'
import { determineTomType } from 'types/tom'
import { downloadBlob } from 'utils/dowloadBlob'
import { getLocalTime } from 'utils/getLocalTime'

import { IRemarksManagmentMenuProps } from './RemarksManagmentMenu.types'

const btnManagmentTxt: string = 'Управление реестром'

export const RemarksManagmentMenu: FC<IRemarksManagmentMenuProps> = ({ data }) => {
  const { project, selectedProjectPhase, selectedStatus, showManualSubmit } = data

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const dispatch = useAppDispatch()
  const [exportRemarks, exportRemarksResponse] = useLazyExportRemarksQuery()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const groupFilter = useTypedSelector(remarkGroupFilterSelector)
  const { enqueueSnackbar } = useSnackbar()

  const onOpenManagmentMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseManagmentMenu = () => {
    setAnchorEl(null)
  }

  const onExportClick = () => {
    exportRemarks({
      ...groupFilter,
      projectId,
      localDateTime: getLocalTime(),
      status: selectedStatus === 'all' ? undefined : selectedStatus,
      type: determineTomType(selectedProjectPhase),
    })
  }

  const onSumbitDrawerOpen = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'manualSumbitRemarks' }))
  }

  const managmentMenuData: IMenuData[] = [
    {
      label: 'Экспортировать реестр',
      action: () => onExportClick(),
      notCloseAfterClick: true,
      loading: exportRemarksResponse.status === 'pending',
    },
  ]

  showManualSubmit &&
    managmentMenuData.push({
      label: 'Направить замечания',
      action: () => onSumbitDrawerOpen(),
    })

  useMutationHandlers(exportRemarksResponse, (data) => {
    onCloseManagmentMenu()

    downloadBlob(
      new File([data], ''),
      `Реестр замечаний по проекту ${project?.shifrName} ${exportRemarksResponse.originalArgs?.localDateTime}.xlsx`,
    )
    enqueueSnackbar('Файл успешно сформирован.', { variant: 'success' })
  })

  return (
    <>
      <Button
        onClick={onOpenManagmentMenu}
        variant='outlined'
        color='secondary'
        endIcon={<MenuIcon />}
        sx={{
          maxWidth: '100%',
          padding: '8px 8px 6px 16px',
          justifyContent: 'space-between',
          minWidth: '266px',
        }}
      >
        {btnManagmentTxt}
      </Button>

      <Menu anchorEl={anchorEl} title={btnManagmentTxt} onClose={onCloseManagmentMenu} menuData={managmentMenuData} />
    </>
  )
}
