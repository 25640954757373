import styled from 'styled-components'
import { Accordion, AccordionDetails, AccordionSummary, alpha, Box, Button, Stack, Typography } from '@mui/material'
import { LegendColors } from '../../../../types/global'

export const AgreementAccordionSummary = styled(AccordionSummary)`
  align-items: start;
  padding: 8px 10px;
  border: ${props => `0.5px solid ${props.theme.palette.bg.gray}`};
  border-radius: 8px;

  &.Mui-expanded {
    min-height: 30px;
  }

  & .MuiAccordionSummary-content {
    flex-direction: column;
    gap: 8px;
    margin: 0;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    padding: 5px;
    background-color: ${props => props.theme.palette.bg.shades};
    border-radius: 50%;

    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.primary.main};
    }
  }
`

export const AgreementAccordionTitle = styled(Typography)`
  line-height: 100%;
  letter-spacing: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

interface AgreementAccordionProps {
  expanded: boolean
}

export const AgreementAccordion = styled(Accordion)<AgreementAccordionProps>`
  background-color: ${props => props.expanded && props.theme.palette.bg.shades};
  border-radius: 8px !important;

  ${AgreementAccordionSummary} {
    border-color: ${props => props.expanded && props.theme.palette.bg.shades};

    & .MuiAccordionSummary-content {
      gap: ${props => props.expanded && 0};
      transition: gap 0.3s;
    }

    & .MuiAccordionSummary-expandIconWrapper {
      background-color: ${props => props.expanded && props.theme.palette.bg.white};
    }
  }

  ${AgreementAccordionTitle} {
    color: ${props => props.expanded && props.theme.palette.primary.main};
  }
`

export const AgreementAccordionDetails = styled(AccordionDetails)`
  padding: 10px;
  padding-top: 0;
  border-radius: 8px !important;
`

export const AgreementAccordionDetailsItem = styled(Stack)`
  padding-bottom: 2px;
`

interface AgreementAccordionDetailsItemLineProps {
  bgColor: 'lightgreen' | 'error' | 'info'
}

export const AgreementAccordionDetailsItemLine = styled(Stack)<AgreementAccordionDetailsItemLineProps>`
  flex: 1;
  width: 1.5px;
  background-color: ${props => props.bgColor === 'lightgreen' && props.theme.palette.legends.lightgreen};
  background-color: ${props => props.bgColor === 'error' && props.theme.palette.error.main};
  background-color: ${props => props.bgColor === 'info' && props.theme.palette.info.main};
`

interface AgreementAccordionSubtitleProps {
  customColor?: 'error'
  disableEllipsis?: boolean
}

export const AgreementAccordionSubtitle = styled(Typography)<AgreementAccordionSubtitleProps>`
  max-width: 260px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0;
  color: ${props => props.theme.palette.secondary.dark};
  color: ${props => props.customColor === 'error' && props.theme.palette.error.main};
  overflow: ${props => !props.disableEllipsis && 'hidden'};
  white-space: nowrap;
  text-overflow: ellipsis;
`

interface AgreementAccordionTextProps {
  customColor?: LegendColors
  isAwaiting?: boolean
  customBgColor?: 'error'
}

export const AgreementAccordionText = styled(Typography)<AgreementAccordionTextProps>`
  padding: ${props => props.customBgColor && '0 4px'};
  max-width: 200px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.customColor ? props.theme.palette.legends[props.customColor] : props.theme.palette.text.dark};
  color: ${props => props.isAwaiting && props.theme.palette.table.orange};
  color: ${props => props.customBgColor === 'error' && props.theme.palette.error.main};
  background-color: ${props => props.customBgColor === 'error' && alpha(props.theme.palette.error.main, 0.15)};
  border-radius: 6px;
`

interface AgreementTomCardButtonProps {
  colorVariant: 'positive' | 'negative'
}

export const AgreementTomCardButton = styled(Button)<AgreementTomCardButtonProps>`
  width: 100%;
  height: 30px;
  font-weight: 400;
  font-size: 14px;
  text-transform: initial;
  letter-spacing: 0;
  color: ${props => props.colorVariant === 'positive' && props.theme.palette.legends.chartsGreen};
  color: ${props => props.colorVariant === 'negative' && props.theme.palette.error.main};

  background-color: ${props => props.colorVariant === 'positive' && alpha(props.theme.palette.legends.green, 0.15)};
  background-color: ${props => props.colorVariant === 'negative' && alpha(props.theme.palette.legends.red, 0.15)};

  &:hover {
    background-color: ${props => props.colorVariant === 'positive' && alpha(props.theme.palette.legends.green, 0.3)};
    background-color: ${props => props.colorVariant === 'negative' && alpha(props.theme.palette.legends.red, 0.3)};
  }
`
interface AgreementTomCardDoneInfoProps {
  disabled?: boolean
}

export const AgreementTomCardDoneInfo = styled(Stack)<AgreementTomCardDoneInfoProps>`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-top: 24px;
  padding: 6px 10px;
  color: ${props => props.disabled ? props.theme.palette.disabled : props.theme.palette.info.main};
  border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 6px;

  & .MuiTypography-root {
    margin: auto;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0;
    color: inherit;
  }
`
export const AwaitingStatusBox = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 32px;
  margin-top: 16px;
  background-color: #FFCD4B26;

  & .MuiTypography-root {
    color: ${props => props.theme.palette.table.orange};
    font-size: 12px;
  }
`