import { Box, Stack, Typography } from '@mui/material'
import { FC } from 'react'

import ColoredTitle from '../../../../../components/ColoredTitle'
import { ProjectStatusesColors, ProjectStatusesRu, shortStatusByFullStatus } from '../../../../../types/project'
import { ProjectCardHeaderProps } from './ProjectCardHeader.types'
import { StyledProjectCardHeader } from './styles'

const ProjectCardHeader: FC<ProjectCardHeaderProps> = ({ data }) => {
  const { id, status, shortName, shifrName, phase } = data
  const shortStatus: ProjectStatusesRu = shortStatusByFullStatus[status]

  return (
    <StyledProjectCardHeader
      title={
        <Stack spacing={1}>
          <Stack direction='row' alignItems='center' spacing={1.25}>
            <ColoredTitle body={shortStatus} color={ProjectStatusesColors[status]} />
            <ColoredTitle body={phase} color='status' />
          </Stack>
          <Box>
            <Typography variant='subtitle1'>{shifrName}</Typography>
          </Box>
        </Stack>
      }
      subheader={
        <Box>
          <Typography variant='body2'>{shortName}</Typography>
        </Box>
      }
    />
  )
}

export default ProjectCardHeader
