import * as yup from 'yup'

export const validationAgreement = yup.object({
  title: yup.string().trim().required('common:errors.required'),
  duration: yup.number().min(1).required('common:errors.required'),
  personId: yup.number().nullable().required('common:errors.required'),
  results: yup.array(
    yup.object({
      title: yup.string().test('title-with-stage-required', 'common:errors.required', (value, context) => {
        // @ts-ignore
        const isNextStageId = !!context.parent.nextStageId

        return isNextStageId ? !!value : true
      })
    })
  )
})