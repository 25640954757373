import styled from 'styled-components'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

export const StyledAttachmentsExportAccordionAccordion = styled(Accordion)`
  width: 100%;
  box-shadow: none;
  
  &::before {
    display: none;
  }
`

export const StyledAttachmentsExportAccordionSummary = styled(AccordionSummary)`
  min-height: 34px;
  padding: 8px;
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 4px;
  
  &.Mui-expanded {
    min-height: 34px;
  }
  
  & .MuiAccordionSummary-content {
    margin: 0;
  }
  
  & .MuiCheckbox-root {
    padding: 0;
  }
  
  & .MuiFormControlLabel-root {
    margin: 0;
  }
`

export const StyledAttachmentsExportAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  padding-top: 8px;
  
  & .MuiFormControlLabel-root {
    width: fit-content;
    height: 40px;
    margin-right: 0;
  }
`
