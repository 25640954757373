import { ReactNode } from 'react'
import { AdviceBackgroundColor, ProjectAgreementAdvicePositionProps } from '../components/ProjectAgreementAdvice'

export interface UseAdvicesProps extends AdvicesState {
  hideAdvices: boolean
}

export interface UseAdvicesReturn {
  TopLeftAdviceElement: ReactNode
  BottomLeftAdviceElement: ReactNode
}


export type AdvicePosition = keyof AdvicesState

export interface AdvicesState {
  topLeft: (ExpandAdviceData | AdviceData)[]
  bottomLeft: (ExpandAdviceData | AdviceData)[]
}

export interface ExpandAdviceData {
  variant: 'expand'
  priorityOrder: number
  backgroundColor: AdviceBackgroundColor
  items: ExpandAdviceDataItem[]
  expandTitle?: string
  ExpandIcon?: ReactNode
  permanentExpired?: boolean
}

export interface AdviceData {
  variant: 'regular'
  show: boolean
  priorityOrder: number
  backgroundColor: AdviceBackgroundColor
  text: string
}

export interface ExpandAdviceDataItem extends Pick<AdviceData,
  'show'
  | 'priorityOrder'
  | 'text'> {
}


export const topLeftAdvicePositionProps: ProjectAgreementAdvicePositionProps = {
  top: '30px',
  right: 'auto',
  bottom: 'auto',
  left: '30px',
  borderRadius: '0px 16px 16px 16px',
}

export const bottomLeftAdvicePositionProps: ProjectAgreementAdvicePositionProps = {
  top: 'auto',
  right: 'auto',
  bottom: '30px',
  left: '30px',
  borderRadius: '16px 16px 16px 0px',
}