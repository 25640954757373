import AddIcon from '@mui/icons-material/Add'
import EmptyPage from "../../components/EmptyPage"
import { EmptyPageData } from "../../components/EmptyPage/EmptyPage.types"
import { getEmptyPageData } from '../Home'
import { SchedulesDrawer, SchedulesFormValues } from './components/SchedulesDrawer'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { onDrawerClose, openedDrawerSelector, setOpenedDrawer } from '../../store/slices/documentsPages/drawerInfo'
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog'
import { Grid, Stack } from '@mui/material'
import { ScheduleCard } from './components/ScheduleCard'
import { MouseEvent, useState } from 'react'
import { useGetSchedulesQuery } from '../../api/works'
import { useParams } from 'react-router-dom'
import { StyledButton } from '../Pockets/components/PocketsLegend/PocketsLegend.styles'

const Schedules = () => {

  const dispatch = useAppDispatch()
  const { projectId: projectIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)

  const { data: schedulesResponse } = useGetSchedulesQuery({ id: projectId })
  const { data: schedules } = schedulesResponse || {}

  const [editableData, setEditableData] = useState<SchedulesFormValues | null>(null)

  const onEdit = (e: MouseEvent, data: SchedulesFormValues) => {
    e.stopPropagation()
    setEditableData(data)
    dispatch(setOpenedDrawer({ openedDrawer: 'schedules' }))
  }

  const closeDrawer = (dirty: boolean) => {
    if (dirty) {
      openConfirm()
    } else {
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
      editableData && setEditableData(null)
    }
  }

  const onDrawerOpen = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'schedules' }))
  }

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      dispatch(onDrawerClose({ dirty: false, immediately: true }))
      editableData && setEditableData(null)
    }
  }

  const onAddClick = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'schedules' }))
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>В проекте еще не создано ни одного графика проектирования.<br />
      Для проекта вы можете создать не более четырех графиков.<br />
      Для создания графика проектирования нажмите на кнопку Создать график.</>,
    [
      {
        text: 'Создать график',
        icon: AddIcon,
        onClick: onAddClick,
      },
    ]
  )

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
    denyButtonText: 'Отменить',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  return (
    <Stack flex={1}>
      {schedules && schedules.length < 4 && schedules.length !== 0 &&
      <StyledButton
        onClick={onDrawerOpen}
        variant='outlined'
        color='secondary'
        sx={{alignSelf: 'flex-end', mt: 1}}
        startIcon={<AddIcon color='primary' />}
      >
        Создать график
      </StyledButton>}
      <Grid sx={{ py: 1.25 }} spacing={2.5} container alignContent='flex-start'>
        {schedules?.map(item => (
          <Grid
            item
            xs={12} md={6} lg={6} xl={4} xxl={3} xxxl={2}
            container
            key={item.id}
          >
            <ScheduleCard
              card={item}
              onEdit={onEdit} />
          </Grid>
        ))}
      </Grid>

      {!schedules?.length &&
        <EmptyPage data={emptyPageData} fullPage backButton/>}
      <SchedulesDrawer
        open={openedDrawer === 'schedules'}
        closeDrawer={closeDrawer}
        editableData={editableData} />
      <ConfirmDialog />
    </Stack>

  )
}

export default Schedules