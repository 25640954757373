import { Box, Radio, Stack, Typography } from "@mui/material"
import { SubAccordionDetailsProps } from "./SubAccordionDetails.types"
import { useAppDispatch, useTypedSelector } from "store/store"
import { selectedModelsSelector } from "store/slices/tim/selectors/tim.selectors"
import { setSelectedModels } from "store/slices/tim/tim"
import { FileLabel, SubAccordionRadioButton } from "./SubAccordionDetails.styles"
import { SelectedModels } from "store/slices/tim/tim.types"

export const SubAccordionDetails = ({ version, name, timID }: SubAccordionDetailsProps) => {

  const dispatch = useAppDispatch()
  const selectedModels = useTypedSelector(selectedModelsSelector)
  const currentTim = selectedModels.find(model => model.timID === timID)

  const onRadioButtonClick = () => {
    const filteredSelectedTim = selectedModels.filter(model => model.timID !== timID)
    const newModel: SelectedModels = {timID, name, tanglID: version.tanglID!}
    const updatedSelectedTims = [...filteredSelectedTim, newModel]
    dispatch(setSelectedModels(updatedSelectedTims))
  }

  return (
    <Box pl={2.5} pr={2}>
      <SubAccordionRadioButton
        control={<Radio disableRipple />}
        labelPlacement='start'
        checked={currentTim?.tanglID === version.tanglID}
        onChange={onRadioButtonClick}
        onClick={(e) => e.stopPropagation()}
        label={
          <Stack direction='row' alignItems='center' spacing={1}>
            <FileLabel>{version.fileType}</FileLabel>
            <Typography>{`Версия ${version.version}`}</Typography>
          </Stack>
        }
      />
    </Box>
  )
}