import { FC, useCallback, useState } from 'react'
import { IPasswordFieldProps } from './PasswordField.types'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material'
import { ProjectFormTextField } from 'components/FieldForm/styles'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'

export const PasswordField: FC<IPasswordFieldProps> = ({
  InputProps,
  className,
  fieldProps,
  onBlur,
  maxLength,
  ...props
}) => {
  const { t } = useTranslation()
  const [{ onBlur: onFieldBlur, ...field }, { touched, error }, { setValue }] = useField({
    name: props.name,
    ...fieldProps,
  })
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onFieldBlur?.(e)
      onBlur?.(e)
    },
    [onBlur, onFieldBlur],
  )

  const isError = touched && !!error

  const onChangeVisibility = () => {
    setIsVisible((prev) => !prev)
  }

  return (
    <ProjectFormTextField
      {...field}
      onChange={(e) => setValue(e.target.value)}
      type={isVisible ? 'text' : 'password'}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position='end' sx={{ marginRight: -1.5 }}>
            <IconButton onClick={onChangeVisibility}>
              {isVisible ? <VisibilityOffIcon color='disabled' /> : <VisibilityIcon color='primary' />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth
      variant='filled'
      size='small'
      helperText={isError ? t(error as string, { maxLength }) : undefined}
      onBlur={handleBlur}
      error={isError}
      className={className}
      {...props}
    />
  )
}
