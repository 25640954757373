import styled from 'styled-components'
import Carousel from 'react-material-ui-carousel'
import { MapHeight } from '../AboutTab'

export const StyledCarousel = styled(Carousel)`
  width: 277px;
  height: ${MapHeight}px;
  
  & .navButtonsWrapper:hover .MuiIconButton-root,
  & .MuiIconButton-root {
    color: ${props => props.theme.palette.text.dark};
    background-color: ${props => props.theme.palette.bg.shades};
    opacity: 1;
    filter: none;
  }
`
