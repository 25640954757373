import React from 'react'
import { Stack, Typography } from '@mui/material'
import { CardSubtitle } from '../styles'
import { StyledCardInfo } from './styles'
import UserAccesses from './UserAccesses'
import { UserCardInfoProps } from './UserCardInfo.types'
import { userRolesEnToRu } from '../../../../../types/user'

export const UserCardInfo: React.FC<UserCardInfoProps> = ({ userData, employeeData, mode }) => {
  const { role } = (userData || employeeData)!
  const companyName = userData?.company?.userCompanyName || employeeData?.company
  const userPosition = userData?.company?.userPosition || employeeData?.position

  return (
    <StyledCardInfo>
      <Stack spacing={1.5}>
        <Stack spacing={0.5}>
          <CardSubtitle>Компания:</CardSubtitle>
          <Typography variant='body2'>{companyName}</Typography>
        </Stack>

        <Stack spacing={0.5}>
          <CardSubtitle>Должность:</CardSubtitle>
          <Typography variant='body2'>{userPosition}</Typography>
        </Stack>

        {mode === 'full' && (
          <Stack spacing={0.5}>
            <CardSubtitle>Доступы:</CardSubtitle>
            <UserAccesses access={userData?.access!} role={role} />
          </Stack>
        )}

        {mode === 'modal' && (
          <Stack spacing={0.5}>
            <CardSubtitle>Роль:</CardSubtitle>
            <Typography variant='body2'>{userRolesEnToRu[role]}</Typography>
          </Stack>
        )}
      </Stack>
    </StyledCardInfo>
  )
}
