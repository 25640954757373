import styled from 'styled-components'

import {
  ExportArchiveDrawerTopBar,
  ExportArchiveDrawerWrapper,
} from '../../../Docs/components/ExportArchiveDrawer/ExportArchiveDrawer.styles'

export const ProjectFullExportDrawerWrapper = styled(ExportArchiveDrawerWrapper)`
  width: 900px;
`

export const ProjectFullExportDrawerTopBar = styled(ExportArchiveDrawerTopBar)``
