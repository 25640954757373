import { useParams } from 'react-router-dom'
import { ProjectPhase } from 'types/project'

import { useTerminateTomIiMutation } from '@api/iiPhase'
import { useTerminateTomIrdMutation } from '@api/irdPhase'
import { useTerminateTomPdMutation } from '@api/pdPhase'
import { useTerminateTomRdMutation } from '@api/rdPhase'

import { selectedProjectPhaseSelector } from '@store/slices/documentsPages/projectInfo'
import { useTypedSelector } from '@store/store'

import { IUseTerminateTomResponse } from './useTerminateTom.types'

export const useTerminateTom = (): IUseTerminateTomResponse => {
  const { projectId: projectIdString, tomId: tomIdString } = useParams()
  const projectId = Number(projectIdString)
  const tomId = Number(tomIdString)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  // Rd
  const [terminateTomRd, { isLoading: isRdLoading, ...terminateTomRdResponse }] = useTerminateTomRdMutation()

  // Pd
  const [terminateTomPd, { isLoading: isPdLoading, ...terminateTomPdResponse }] = useTerminateTomPdMutation()

  // Ird
  const [terminateTomIrd, { isLoading: isIrdLoading, ...terminateTomIrdResponse }] = useTerminateTomIrdMutation()

  // Ii
  const [terminateTomIi, { isLoading: isIiLoading, ...terminateTomIiResponse }] = useTerminateTomIiMutation()

  const onTerminateTomByPhase: Record<ProjectPhase, () => void> = {
    'Рабочая документация': () => terminateTomRd({ projectId, tomId, type: 'РД' }),
    'Проектная документация': () => terminateTomPd({ projectId, tomId, type: 'ПД' }),
    'Сбор исходных данных': () => terminateTomIrd({ projectId, tomId, type: 'ИРД' }),
    'Инженерные изыскания': () => terminateTomIi({ projectId, tomId, type: 'ИИ' }),
  }

  const isLoadingByPhase: Record<ProjectPhase, boolean> = {
    'Рабочая документация': isRdLoading,
    'Проектная документация': isPdLoading,
    'Сбор исходных данных': isIrdLoading,
    'Инженерные изыскания': isIiLoading,
  }

  const onTerminateTomResponseByPhase: Record<ProjectPhase, any> = {
    'Рабочая документация': terminateTomRdResponse,
    'Проектная документация': terminateTomPdResponse,
    'Сбор исходных данных': terminateTomIrdResponse,
    'Инженерные изыскания': terminateTomIiResponse,
  }

  const onTerminateTom = onTerminateTomByPhase[selectedProjectPhase]
  const isLoading = isLoadingByPhase[selectedProjectPhase]
  const onTerminateTomResponse = onTerminateTomResponseByPhase[selectedProjectPhase]

  return {
    onTerminateTom,
    isLoading,
    onTerminateTomResponse,
  }
}
