import styled from 'styled-components'
import { Typography } from '@mui/material'
import { ObjectsSelectMenuItem } from '../UserManagement/components/UserObjectsSelection/styles'

export const FilterRenderText = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
`

export const FilterSelectMenuItem = styled(ObjectsSelectMenuItem)`
`
