import {
  GetDetailedSidebarPdsRequest,
  GetDetailedSidebarPdsResponse,
  GetSidebarPdsRequest,
  GetSidebarPdsResponse
} from './sidebarApi.types'
import { tomPdApi } from '../tomApi'

export const sidebarPdApi = tomPdApi.injectEndpoints({
  endpoints: (build) => ({
    getSidebarPds: build.query<GetSidebarPdsResponse, GetSidebarPdsRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom-pd/side-menu-map`,
        method: 'GET',
      }),
      providesTags: ['TomPd', { type: 'TomPd', id: 'SIDE_MENU' }],
    }),
    getDetailedSidebarPds: build.query<GetDetailedSidebarPdsResponse, GetDetailedSidebarPdsRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom-pd/detail-side-menu-map`,
        method: 'GET',
      }),
      providesTags: ['TomPd'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetSidebarPdsQuery,
  useGetDetailedSidebarPdsQuery,
} = sidebarPdApi
