import { UseDocsFilesSubmitProps, UseDocsFilesSubmitResponse, } from './useDocsFilesSubmit.types'
import React, { useEffect } from 'react'
import { useTomRdMultiUploadMutation } from '../../../../api/rdPhase'
import { useTomPdMultiUploadMutation } from '../../../../api/pdPhase'
import { useTomIiMultiUploadMutation } from '../../../../api/iiPhase'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { useTypedSelector } from '../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../store/slices/documentsPages/projectInfo'
import { useTomIrdMultiUploadMutation } from '../../../../api/irdPhase'

export const useDocsFilesSubmit = ({ files, setResponseData }: UseDocsFilesSubmitProps): UseDocsFilesSubmitResponse => {
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  // Rd
  const [tomRdMultiUpload, { isLoading: isTomRdLoading, ...tomRdMultiUploadResponse }] = useTomRdMultiUploadMutation()

  useMutationHandlers(
    tomRdMultiUploadResponse,
    (data) => {
      setResponseData(data)
    }
  )


  // Pd
  const [tomPdMultiUpload, { isLoading: isTomPdLoading, ...tomPdMultiUploadResponse }] = useTomPdMultiUploadMutation()

  useMutationHandlers(
    tomPdMultiUploadResponse,
    (data) => {
      setResponseData(data)
    }
  )


  // Ii
  const [tomIiMultiUpload, { isLoading: isTomIiLoading, ...tomIiMultiUploadResponse }] = useTomIiMultiUploadMutation()

  useMutationHandlers(
    tomIiMultiUploadResponse,
    (data) => {
      setResponseData(data)
    }
  )


  // Ird
  const [tomIrdMultiUpload, {
    isLoading: isTomIrdLoading,
    ...tomIrdMultiUploadResponse
  }] = useTomIrdMultiUploadMutation()

  useMutationHandlers(
    tomIrdMultiUploadResponse,
    (data) => {
      setResponseData(data)
    }
  )


  // Common
  useEffect(() => {
    if (files?.length) {
      switch (selectedProjectPhase) {
        case 'Рабочая документация':
          tomRdMultiUpload({ id: project.id, files })
          break
        case 'Проектная документация':
          tomPdMultiUpload({ id: project.id, files })
          break
        case 'Инженерные изыскания':
          tomIiMultiUpload({ id: project.id, files })
          break
        case 'Сбор исходных данных':
          tomIrdMultiUpload({ id: project.id, files })
          break
      }
    }
  }, [files])

  const isTomLoading =
    isTomRdLoading
    || isTomPdLoading
    || isTomIiLoading
    || isTomIrdLoading

  return {
    isTomLoading
  }
}
