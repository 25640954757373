import { useCallback } from "react"
import useConfirmDialog, { UseExitConfirmProps } from "../../../../../hooks/useConfirmDialog"
import { IInternalAgreementControlValues, InternalAgreementButton, compareObjects } from "../InternalAgreementControl"
import { useTypedSelector } from "../../../../../store/store"
import { currentSchemaSelector } from "../../../../../store/slices/internalAgreement"

interface CancelSchemaButtonProps {
  confirmCancelEditSchema: () => void
  values: IInternalAgreementControlValues
}
export const CancelSchemaButton = ({ confirmCancelEditSchema, values }: CancelSchemaButtonProps) => {

  const currentSchema = useTypedSelector(currentSchemaSelector)

  const confirmCancel = (confirm: boolean) => {
    if (confirm) {
      confirmCancelEditSchema()
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmCancel,
    title: 'Подтвердить закрытие формы?',
    body: (<>Форма будет закрыта, а все введенные данные безвозвратно утеряны</>),
    denyButtonText: 'Отменить',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)


  const handleClick = () => {
    if (isChanged()) {
      confirmCancelEditSchema()
    } else openConfirm()
  }

  const isChanged = useCallback(() => {
    return compareObjects(values, {
      companyName: currentSchema.company,
      duration: currentSchema.duration,
      expandability: currentSchema.expandability,
      publication: currentSchema.publication,
      personId: currentSchema.moderator.id,
      title: currentSchema.title,
      unit: currentSchema.unit === 'hour' ? true : false
    })
  }, [currentSchema, values])


  return (
    <>
      <InternalAgreementButton onClick={handleClick}>
        Отменить
      </InternalAgreementButton>
      <ConfirmDialog />
    </>
  )
}