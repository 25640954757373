import { UseRenderSelectedTomsResponse } from './useRenderSelectedToms.types'
import React, { ReactElement } from 'react'
import { useFormikContext } from 'formik'
import { ExportArchiveFormData } from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { ProjectPhase } from '../../../../../../../../types/project'
import { ExportArchiveTomItem } from '../../../ExportArchiveTomItem'
import { useTypedSelector } from '../../../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../../../store/slices/documentsPages/projectInfo'


export const useRenderSelectedToms = (): UseRenderSelectedTomsResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { values: formValues } = useFormikContext<ExportArchiveFormData>()

  // Rd
  const selectedRdToms = formValues.selectedRdToms?.map(({ tom, objectIndex, rdIndex, tomIndex }) => (
    <ExportArchiveTomItem
      rdTom={tom}
      objectIndex={objectIndex}
      rdIndex={rdIndex}
      tomIndex={tomIndex}
      key={tom.tomId}
    />
  ))


  // Pd
  const selectedPdToms = formValues.selectedPdToms?.map(({ tom, pdIndex, tomIndex }) => (
    <ExportArchiveTomItem
      pdTom={tom}
      pdIndex={pdIndex}
      tomIndex={tomIndex}
      key={tom.tomId}
    />
  ))


  // Ii
  const selectedIiToms = formValues.selectedIiToms?.map(({ tom, iiIndex, tomIndex }) => (
    <ExportArchiveTomItem
      iiTom={tom}
      iiIndex={iiIndex}
      tomIndex={tomIndex}
      key={tom.tomId}
    />
  ))


  // Ird
  const selectedIrdToms = formValues.selectedIrdToms?.map(({ tom, irdIndex, tomIndex }) => (
    <ExportArchiveTomItem
      irdTom={tom}
      irdIndex={irdIndex}
      tomIndex={tomIndex}
      key={tom.tomId}
    />
  ))


  // Common
  const selectedToms: Record<ProjectPhase, ReactElement[]> = {
    'Рабочая документация': selectedRdToms,
    'Проектная документация': selectedPdToms,
    'Инженерные изыскания': selectedIiToms,
    'Сбор исходных данных': selectedIrdToms,
  }

  return {
    selectedToms: selectedToms[selectedProjectPhase],
  }
}
