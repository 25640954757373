import { Box, Stack } from '@mui/material'
import React from 'react'

import LegendItem from '../../../../components/LegendItem'
import { theme } from '../../../../styles/theme'
import { LegendStatisticsData } from '../HomeTopLegend.types'
import { StatisticsProps } from './Statistics.types'

const Statistics: React.FC<StatisticsProps> = ({ statistics }) => {
  const { agreed, inWork, notInWork } = statistics || {}
  const legendData: LegendStatisticsData[] = [
    { label: 'Согласовано', color: 'green', value: agreed },
    { label: 'В работе', color: 'blue', value: inWork },
    { label: 'Не в работе', color: 'red', value: notInWork },
  ]
  return (
    <Box>
      <Stack direction='row' alignItems='center' spacing={theme.spacing(3)}>
        {legendData.map((item) => (
          <LegendItem label={item.label} color={item.color} value={item.value} defaultValueView key={item.label} />
        ))}
      </Stack>
    </Box>
  )
}

export default Statistics
