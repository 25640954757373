import React, { FC, useCallback, useState } from 'react'
import { ObjDrawerProps } from './ObjDrawer.types'
import { ObjectsDrawerTopBar, ObjectsDrawerWrapper } from '../../../../../../Objects/components/ObjectsDrawer/styles'
import { Drawer, Typography } from '@mui/material'
import { theme } from '../../../../../../../styles/theme'
import Divider from '../../../../../../../components/Divider'
import ObjectsDrawerForm from '../../../../../../Objects/components/ObjectsDrawer/ObjectsDrawerForm'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../../../hooks/useConfirmDialog'
import PdDrawerForm from '../../../../../../Administration/components/PdDrawerForm'
import { Object } from '../../../../../../../api/rdPhase/objects'
import { ProjectPhase } from '../../../../../../../types/project'
import { PdDrawerVariant } from '../../../../../../Administration/AdminPd/PdDrawer/PdDrawer.types'
import { useFormikContext } from 'formik'
import { WorksFormData } from '../../WorksDrawerForm.types'
import { Ird } from '../../../../../../../api/irdPhase'
import { Ii } from '../../../../../../../api/iiPhase'
import { Ppd } from '../../../../../../../api/pdPhase'

export const ObjDrawer: FC<ObjDrawerProps> = ({ phase, projectId, open, onClose }) => {
  const { setFieldValue } = useFormikContext<WorksFormData>()

  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  const pdDrawerVariantByPhase: Record<Exclude<ProjectPhase, 'Рабочая документация'>, Exclude<PdDrawerVariant, 'pd'>> = {
    'Проектная документация': 'ppd',
    'Инженерные изыскания': 'ii',
    'Сбор исходных данных': 'ird',
  }

  // drawer close
  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      onClose('obj')
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,

  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty
      ? onClose('obj')
      : openConfirm()
  }, [])


  // bind after create
  const onObjectSuccessCreate = (object: Object) => {
    setFieldValue('obj', object.id)
  }

  const onSectionSuccessCreate = (section: Ppd | Ii | Ird) => {
    setFieldValue('obj', section.id)
  }

  return (
    <Drawer
      anchor='bottom'
      open={open}
      onClose={() => onDrawerClose(dirty)}
      PaperProps={{
        sx: {
          width: 400,
          left: 'auto',
          borderRadius: '20px 20px 0px 0px',
        }
      }}
    >
      <ObjectsDrawerWrapper>
        <ObjectsDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {phase === 'Рабочая документация'
              ? 'Добавление объекта'
              : 'Добавление раздела документации'
            }
          </Typography>

          <Typography variant='body2' color={theme.palette.text.dark}>
            Заполните все данные и нажмите кнопку сохранить. В случае если Вы передумали нажмите кнопку отменить
          </Typography>
        </ObjectsDrawerTopBar>

        <Divider />

        {phase && (phase === 'Рабочая документация'
            ? (
              <ObjectsDrawerForm
                projectId={projectId}
                onClose={onDrawerClose}
                onFormChange={onFormChange}
                onSuccessCreate={onObjectSuccessCreate}
              />
            )
            : (
              <PdDrawerForm
                variant={pdDrawerVariantByPhase[phase]}
                projectId={projectId}
                onClose={onDrawerClose}
                onFormChange={onFormChange}
                onSuccessCreate={onSectionSuccessCreate}
              />
            )
        )}

      </ObjectsDrawerWrapper>

      <ConfirmDialog />
    </Drawer>
  )
}
