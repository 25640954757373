import { Mark } from "@api/remarks/types";
import { TomType } from "../../../../types/tom";
import { RootState } from "../../../store";
import { AllowScrolling, RemarkFormData, RemarkFormMode, RemarkGroupFilter, RemarkHistoryData } from "../remarks.types";

export const remarkFormDataSelector = (state: RootState): RemarkFormData => state.remarks.remarkFormData
export const remarkPageSelector = (state: RootState): number | null => state.remarks.remarkPage
export const selectedRemarkIdSelector = (state: RootState): number | null => state.remarks.selectedRemarkId
export const remarkFormModeSelector = (state: RootState): RemarkFormMode => state.remarks.remarkFormMode
export const remarkPhaseFilterSelector = (state: RootState): TomType | undefined => state.remarks.remarkPhaseFilter
export const remarkGroupFilterSelector = (state: RootState): RemarkGroupFilter => state.remarks.remarkGroupFilter
export const allowScrollingSelector = (state: RootState): AllowScrolling => state.remarks.allowScrolling
export const remarkHistoryDataSelector = (state: RootState): RemarkHistoryData => state.remarks.remarkHistoryData
export const isAnnotationModeSelector = (state: RootState): boolean => state.remarks.isAnnotationMode
export const remarkAnnotationsSelector = (state: RootState): Mark[] | null => state.remarks.remarkAnnotations
export const getAnnotationsSelector = (state: RootState): boolean => state.remarks.getAnnotations
export const selectedAnnotationIdSelector = (state: RootState): number | null => state.remarks.selectedAnnotationId
export const markCountSelector = (state: RootState): number | null => state.remarks.markCount
export const getClickHandlerSelector = (state: RootState): boolean => state.remarks.getClickHandler