import styled from 'styled-components'
import { Avatar, Typography } from '@mui/material'

export const StyledAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  border-radius: 4px;
`

export const CardSubtitle = styled(Typography)`
  font-weight: 700;
  font-size: ${props => props.theme.typography.tooltip.fontSize};
  line-height: ${props => props.theme.typography.tooltip.lineHeight};
  letter-spacing: ${props => props.theme.typography.tooltip.letterSpacing};
  color: ${props => props.theme.palette.secondary.gray};
`