import { useCallback, useState } from 'react'
import { UseDeleteDocResponse } from './useDeleteDoc.types'
import { useDeleteTomRdChangeMutation, useDeleteTomRdVersionMutation } from '../../../../../../api/rdPhase'
import { useDeleteTomPdChangeMutation, useDeleteTomPdVersionMutation } from '../../../../../../api/pdPhase'
import { useDeleteTomIiChangeMutation, useDeleteTomIiVersionMutation } from '../../../../../../api/iiPhase'
// @ts-ignore:next-line
import { TomDocument } from '../../../../../../types/tom'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../../hooks/useConfirmDialog'
import { useAppDispatch, useTypedSelector } from '../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { useDeleteTomIrdChangeMutation, useDeleteTomIrdVersionMutation } from '../../../../../../api/irdPhase'
import { useDeleteTomCmnChangeMutation, useDeleteTomCmnVersionMutation } from '../../../../../../api/cmnTom'
import { isCmnTomSelector } from '../../../../../../store/slices/documentsPages/tom'
import { onDrawerClose } from 'store/slices/documentsPages/drawerInfo'
import { useSnackbar } from 'notistack'

export const useDeleteDoc = (): UseDeleteDocResponse => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)

  const [deleteClickedPdf, setDeleteClickedPdf] = useState<TomDocument | null>(null)

  // Rd
  const [deleteTomRdVersion] = useDeleteTomRdVersionMutation()
  const [deleteTomRdChange] = useDeleteTomRdChangeMutation()

  // Pd
  const [deleteTomPdVersion] = useDeleteTomPdVersionMutation()
  const [deleteTomPdChange] = useDeleteTomPdChangeMutation()

  // Ii
  const [deleteTomIiVersion] = useDeleteTomIiVersionMutation()
  const [deleteTomIiChange] = useDeleteTomIiChangeMutation()

  // Ird
  const [deleteTomIrdVersion] = useDeleteTomIrdVersionMutation()
  const [deleteTomIrdChange] = useDeleteTomIrdChangeMutation()

  // Cmn
  const [deleteTomCmnVersion] = useDeleteTomCmnVersionMutation()
  const [deleteTomCmnChange] = useDeleteTomCmnChangeMutation()

  // Common
  const onDeleteVersionClick = (pdf: TomDocument) => {
    setDeleteClickedPdf(pdf)
    openConfirm()
  }

  const onDeleteVersionByPhase = (pdf: TomDocument) => {
    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        deleteTomRdVersion({ id: pdf.tomId, version: pdf.version })
        break
      case 'Проектная документация':
        deleteTomPdVersion({ id: pdf.tomId, version: pdf.version })
        break
      case 'Инженерные изыскания':
        deleteTomIiVersion({ id: pdf.tomId, version: pdf.version })
        break
      case 'Сбор исходных данных':
        deleteTomIrdVersion({ id: pdf.tomId, version: pdf.version })
        break
    }
  }

  const onDeleteCmnVersion = (pdf: TomDocument) => {
    deleteTomCmnVersion({ id: pdf.tomId, version: pdf.version })
  }

  const onDeleteVersion = isCmnTom ? onDeleteCmnVersion : onDeleteVersionByPhase

  const handleConfirmDeleteVersion = useCallback(
    (confirm: boolean) => {
      if (confirm && deleteClickedPdf) {
        onDeleteVersion(deleteClickedPdf)
      }
      setDeleteClickedPdf(null)
    },
    [deleteClickedPdf],
  )

  const onDeleteChangeClick = (pdf: TomDocument) => {
    setDeleteClickedPdf(pdf)
    openConfirm()
  }

  const onDeleteChangeByPhase = (pdf: TomDocument) => {
    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        deleteTomRdChange({ id: pdf.tomId, change: pdf.change })
        break
      case 'Проектная документация':
        deleteTomPdChange({ id: pdf.tomId, change: pdf.change })
        break
      case 'Инженерные изыскания':
        deleteTomIiChange({ id: pdf.tomId, change: pdf.change })
        break
      case 'Сбор исходных данных':
        deleteTomIrdChange({ id: pdf.tomId, change: pdf.change })
        break
    }
  }

  const onDeleteCmnChange = (pdf: TomDocument) => {
    deleteTomCmnChange({ id: pdf.tomId, change: pdf.change })
  }

  const onDeleteChange = isCmnTom ? onDeleteCmnChange : onDeleteChangeByPhase

  const handleConfirmDeleteChange = useCallback(
    (confirm: boolean) => {
      if (confirm && deleteClickedPdf) {
        onDeleteChange(deleteClickedPdf)
        dispatch(onDrawerClose({ dirty: false, immediately: true }))
        enqueueSnackbar('Изменение успешно удалено.', { variant: 'success' })
      }
      setDeleteClickedPdf(null)
    },
    [deleteClickedPdf],
  )

  const dataForConfirmDialog: UseExitConfirmProps = deleteClickedPdf?.change
    ? {
        handleConfirm: handleConfirmDeleteChange,
        closeDeps: [deleteClickedPdf],
        title: 'Удалить изменение?',
        body: `Изменение ${deleteClickedPdf?.change} от ${
          deleteClickedPdf?.createdAt.split(' ')[0]
        } будет удалено безвозвратно`,
        denyButtonText: 'Нет',
      }
    : {
        handleConfirm: handleConfirmDeleteVersion,
        closeDeps: [deleteClickedPdf],
        title: 'Удалить версию?',
        body: `Версия ${deleteClickedPdf?.version} от ${
          deleteClickedPdf?.createdAt.split(' ')[0]
        } будет удалена безвозвратно`,
        denyButtonText: 'Нет',
        confirmButtonColor: 'error',
      }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  return {
    onDeleteVersionClick,
    onDeleteChangeClick,
    ConfirmDialog,
  }
}
