import styled from "styled-components";
import { Tabs } from '@mui/material'

export const StyledTabs = styled(Tabs) <{ $countPagination: number | undefined }>`
  
  & .MuiTabs-flexContainer {
    flex-wrap: wrap;
  } 
  
  & button {
    font-size: ${props =>
    props.$countPagination && props.$countPagination > 3 ? '13px' : '14px'};
  }

`