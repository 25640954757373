import { Link, useNavigate, useParams } from "react-router-dom"
import { DrawerContentTextWrapper, SchedulesDesignLogo, SchedulesDrawerContent, SchedulesDrawerHeader, SchedulesLogoWrapper, SchedulesTitledWrapper, ProjectAccentText, ProjectText, StyledDrawer, ElipsisText } from "./SchedulesNavBar.styles"
import { Stack, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import GradingIcon from '@mui/icons-material/Grading'
import EventNoteIcon from '@mui/icons-material/EventNote'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import Button from "../../../../components/Button"
import HomeIcon from "../../../../assets/icons/HomeIcon"
import { projectTypeShort } from '../../../../types/project'
import { theme } from "../../../../styles/theme"
import { SchedulesNavBarProps } from "./SchedulesNavBar.types"


export const SchedulesNavBar = ({ project }: SchedulesNavBarProps) => {
  const navigate = useNavigate()
  const { projectId: projectIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)

  const { shifrName, fullName, phase, status, type, startDate, endDate } = project || {}

  const onReturnClick = () => {
    navigate(-1)
  }

  const onHomeClick = () => {
    navigate(`/project/${projectId}`)
  }

  return (
    <StyledDrawer
      variant='permanent'
    >
      <SchedulesDrawerHeader direction='row' justifyContent='space-between' spacing={1}>
        <Link to='/home'>
          <SchedulesLogoWrapper>
            <SchedulesDesignLogo />
          </SchedulesLogoWrapper>
        </Link>

        <Stack direction='row' alignItems='center' spacing={1.25}>
          <Button icon bgColor={theme.palette.bg.shades} color='primary' variant='text' onClick={() => onReturnClick()}>
            <ArrowBackIcon />
          </Button>
          <Button icon bgColor={theme.palette.bg.shades} color='primary' variant='text' onClick={() => onHomeClick()}>
            <HomeIcon />
          </Button>
        </Stack>
      </SchedulesDrawerHeader>

      <SchedulesDrawerContent spacing={2}>
        <Stack spacing={1.5}>
          <DrawerContentTextWrapper>
            <ElipsisText variant='subtitle1'>{shifrName}</ElipsisText>
          </DrawerContentTextWrapper>
          <DrawerContentTextWrapper style={{ padding: '16px' }}>
            <Typography variant='body2'>{fullName}</Typography>
          </DrawerContentTextWrapper>
        </Stack>


        <SchedulesTitledWrapper
          title='Общая информация о проекте'
          variant='primary'
          contentWrapper='stack'
          bordered='border'
        >
          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
            <Stack direction='row' spacing={1}>
              <ErrorOutlineIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Статус:</Typography>
            </Stack>

            <ProjectText variant='subtitle2'>{status}</ProjectText>
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
            <Stack direction='row' spacing={1}>
              <InsertDriveFileOutlinedIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Тип:</Typography>
            </Stack>

            <ProjectText variant='subtitle2'>{projectTypeShort[type!]}</ProjectText>
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
            <Stack direction='row' spacing={1}>
              <GradingIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Стадия:</Typography>
            </Stack>

            <ProjectAccentText variant='subtitle2'>{phase}</ProjectAccentText>
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
            <Stack direction='row' spacing={1}>
              <EventNoteIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Дата начала:</Typography>
            </Stack>

            <ProjectText variant='subtitle2'>{startDate}</ProjectText>
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
            <Stack direction='row' spacing={1}>
              <EventAvailableIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Дата окончания:</Typography>
            </Stack>

            <ProjectText variant='subtitle2'>{endDate}</ProjectText>
          </Stack>
        </SchedulesTitledWrapper>
      </SchedulesDrawerContent>
    </StyledDrawer>
  )
}