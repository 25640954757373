export const VisibilityRemarksIcon = (active: boolean) => {
  const color = active ? "#0044B4" : "#5C6E8C"
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_13882_95601)">
      <path d="M11.8203 2.77539H5.10156C4.17773 2.77539 3.43027 3.53125 3.43027 4.45508L3.42188 17.8926C3.42188 18.8164 4.16934 19.5723 5.09316 19.5723H15.1797C16.1035 19.5723 16.8594 18.8164 16.8594 17.8926V7.81445L11.8203 2.77539ZM5.10156 17.8926V4.45508H10.9805V8.6543H15.1797V17.8926H5.10156Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33728 16.3406C9.62165 16.3406 10.6628 15.2994 10.6628 14.015C10.6628 12.7306 9.62165 11.6895 8.33728 11.6895C7.05291 11.6895 6.01172 12.7306 6.01172 14.015C6.01172 15.2994 7.05291 16.3406 8.33728 16.3406Z" fill={color}/>
      <path d="M9.68203 6.41455H6.47051C6.21817 6.41455 6.01172 6.62101 6.01172 6.87334V10.0849C6.01172 10.3372 6.21817 10.5436 6.47051 10.5436H9.68203C9.93436 10.5436 10.1408 10.3372 10.1408 10.0849V6.87334C10.1408 6.62101 9.93436 6.41455 9.68203 6.41455Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2392 12C11.7178 12 8.71057 14.1903 7.49219 17.282C8.71057 20.3738 11.7178 22.5641 15.2392 22.5641C18.7605 22.5641 21.7677 20.3738 22.9861 17.282C21.7677 14.1903 18.7605 12 15.2392 12ZM15.2409 20.8033C13.2972 20.8033 11.7196 19.2258 11.7196 17.282C11.7196 15.3382 13.2972 13.7606 15.2409 13.7606C17.1847 13.7606 18.7623 15.3382 18.7623 17.282C18.7623 19.2258 17.1847 20.8033 15.2409 20.8033ZM12.1958 17.282C12.1958 15.5966 13.5563 14.2361 15.2417 14.2361C16.9271 14.2361 18.2877 15.5966 18.2877 17.282C18.2877 18.9674 16.9271 20.3279 15.2417 20.3279C13.5563 20.3279 12.1958 18.9674 12.1958 17.282Z" fill={color}/>
      <path d="M7.49219 17.282L7.02701 17.0987L6.95476 17.282L7.02701 17.4653L7.49219 17.282ZM22.9861 17.282L23.4513 17.4653L23.5236 17.282L23.4513 17.0987L22.9861 17.282ZM7.95737 17.4653C9.1033 14.5575 11.9308 12.5 15.2392 12.5V11.5C11.5048 11.5 8.31785 13.8231 7.02701 17.0987L7.95737 17.4653ZM15.2392 22.0641C11.9308 22.0641 9.1033 20.0066 7.95737 17.0987L7.02701 17.4653C8.31785 20.741 11.5048 23.0641 15.2392 23.0641V22.0641ZM22.521 17.0987C21.375 20.0066 18.5475 22.0641 15.2392 22.0641V23.0641C18.9735 23.0641 22.1605 20.741 23.4513 17.4653L22.521 17.0987ZM15.2392 12.5C18.5475 12.5 21.375 14.5575 22.521 17.4653L23.4513 17.0987C22.1605 13.8231 18.9735 11.5 15.2392 11.5V12.5ZM11.2196 17.282C11.2196 19.5019 13.021 21.3033 15.2409 21.3033V20.3033C13.5733 20.3033 12.2196 18.9496 12.2196 17.282H11.2196ZM15.2409 13.2606C13.021 13.2606 11.2196 15.0621 11.2196 17.282H12.2196C12.2196 15.6144 13.5733 14.2606 15.2409 14.2606V13.2606ZM19.2623 17.282C19.2623 15.0621 17.4609 13.2606 15.2409 13.2606V14.2606C16.9086 14.2606 18.2623 15.6144 18.2623 17.282H19.2623ZM15.2409 21.3033C17.4609 21.3033 19.2623 19.5019 19.2623 17.282H18.2623C18.2623 18.9496 16.9086 20.3033 15.2409 20.3033V21.3033ZM15.2417 13.7361C13.2802 13.7361 11.6958 15.3204 11.6958 17.282H12.6958C12.6958 15.8727 13.8324 14.7361 15.2417 14.7361V13.7361ZM18.7877 17.282C18.7877 15.3204 17.2033 13.7361 15.2417 13.7361V14.7361C16.651 14.7361 17.7877 15.8727 17.7877 17.282H18.7877ZM15.2417 20.8279C17.2033 20.8279 18.7877 19.2436 18.7877 17.282H17.7877C17.7877 18.6913 16.651 19.8279 15.2417 19.8279V20.8279ZM11.6958 17.282C11.6958 19.2436 13.2802 20.8279 15.2417 20.8279V19.8279C13.8324 19.8279 12.6958 18.6913 12.6958 17.282H11.6958Z" fill="#DCEAF8"/>
    </g>
    <defs>
      <clipPath id="clip0_13882_95601">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  )
}