import { Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'

import Button from '@components/Button'
import { ProjectFormTextField } from '@components/FieldForm/styles'

import { useMutationHandlers } from '@hooks/useMutationHandlers'

import { theme } from '@styles/theme'

import { useTerminateTom } from '../../hooks/useTerminateTom/useTerminateTom'
import { ITerminateTomDialogProps } from './TerminateTomDialog.types'

export const TerminateTomDialog: FC<ITerminateTomDialogProps> = ({ isOpen, setDialogOpened, rootShifrName }) => {
  const { onTerminateTom, isLoading, onTerminateTomResponse } = useTerminateTom()
  const [shifrNameField, setShifrNameField] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    isOpen && setShifrNameField('')
  }, [isOpen])

  const isFieldMatch = useMemo((): boolean => {
    return shifrNameField.toLowerCase().trim() === rootShifrName.toLowerCase().trim()
  }, [shifrNameField, rootShifrName])

  const onShifrNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    !isLoading && setShifrNameField(e.target.value)
  }

  const onTerminateTomClick = () => {
    onTerminateTom()
  }

  const onDialogClose = () => {
    !isLoading && setDialogOpened(false)
  }

  useMutationHandlers(onTerminateTomResponse, (data) => {
    enqueueSnackbar('Том аннулирован.', { variant: 'success' })
    onDialogClose()
  })

  return (
    <Dialog
      open={isOpen}
      onClose={onDialogClose}
      PaperProps={{
        style: {
          width: 400,
          textAlign: 'center',
        },
      }}
    >
      <DialogTitle style={{ justifyContent: 'center', paddingTop: 30, paddingBottom: 12 }}>
        <Typography variant='subtitle1'>Аннулировать том?</Typography>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          <Stack spacing={3}>
            <Stack>
              <Typography variant='body2' color={theme.palette.warning.main}>
                ВНИМАНИЕ!
              </Typography>
              <Typography variant='body2' color={theme.palette.text.dark}>
                После аннулирования внесение изменений в том, будет недоступно.
              </Typography>
            </Stack>
            <Typography
              variant='body2'
              color={theme.palette.text.dark}
              sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
            >
              Дла подтверждения аннулирования введите шифр тома [{rootShifrName.trim()}] в поле ниже.
            </Typography>
          </Stack>

          <ProjectFormTextField
            value={shifrNameField}
            onChange={onShifrNameChange}
            InputProps={{ disableUnderline: true }}
            placeholder='Шифр тома'
            fullWidth
            variant='filled'
            size='small'
            helperText={''}
          />

          <Stack direction={'row'} gap={1.25}>
            <Button onClick={onTerminateTomClick} disabled={!isFieldMatch} loading={isLoading} color='error' fullWidth>
              Аннулировать
            </Button>
            <Button onClick={onDialogClose} disabled={isLoading} fullWidth>
              Отменить
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
