import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

import { TomIiBaseResponse, useDeleteTomIiMutation } from '../../../../api/iiPhase'
import { TomIrdBaseResponse, useDeleteTomIrdMutation } from '../../../../api/irdPhase'
import { TomPdBaseResponse, useDeleteTomPdMutation } from '../../../../api/pdPhase'
import { TomRdBaseResponse, useDeleteTomRdMutation } from '../../../../api/rdPhase'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { tomSelector } from '../../../../store/slices/documentsPages/tom'
import { useTypedSelector } from '../../../../store/store'
import { UseDeleteTomProps, UseDeleteTomResponse } from './useDeleteTom.types'

export const useDeleteTom = ({ onDeleteSuccess, onDeleteError }: UseDeleteTomProps): UseDeleteTomResponse => {
  const { enqueueSnackbar } = useSnackbar()

  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)

  const [deleteRdTom, deleteRdTomResponse] = useDeleteTomRdMutation()
  const [deletePdTom, deletePdTomResponse] = useDeleteTomPdMutation()
  const [deleteIiTom, deleteIiTomResponse] = useDeleteTomIiMutation()
  const [deleteIrdTom, deleteIrdTomResponse] = useDeleteTomIrdMutation()

  const onDeleteSubmit = useCallback(() => {
    if (tom?.id) {
      switch (selectedProjectPhase) {
        case 'Рабочая документация':
          deleteRdTom({ id: tom.id })
          break
        case 'Проектная документация':
          deletePdTom({ id: tom.id })
          break
        case 'Инженерные изыскания':
          deleteIiTom({ id: tom.id })
          break
        case 'Сбор исходных данных':
          deleteIrdTom({ id: tom.id })
          break
      }
    }
  }, [tom])

  useMutationHandlers(deleteRdTomResponse, (data: TomRdBaseResponse) => {
    if (data.success) {
      onDeleteSuccess()
    } else {
      enqueueSnackbar(`Не удалось удалить том. ${data.description}`, { variant: 'error' })
      onDeleteError()
    }
  })

  useMutationHandlers(deletePdTomResponse, (data: TomPdBaseResponse) => {
    if (data.success) {
      onDeleteSuccess()
    } else {
      enqueueSnackbar(`Не удалось удалить том. ${data.description}`, { variant: 'error' })
      onDeleteError()
    }
  })

  useMutationHandlers(deleteIiTomResponse, (data: TomIiBaseResponse) => {
    if (data.success) {
      onDeleteSuccess()
    } else {
      enqueueSnackbar(`Не удалось удалить том. ${data.description}`, { variant: 'error' })
      onDeleteError()
    }
  })

  useMutationHandlers(deleteIrdTomResponse, (data: TomIrdBaseResponse) => {
    if (data.success) {
      onDeleteSuccess()
    } else {
      enqueueSnackbar(`Не удалось удалить том. ${data.description}`, { variant: 'error' })
      onDeleteError()
    }
  })

  return {
    onDeleteSubmit,
  }
}
