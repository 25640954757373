import { PhoneFieldForm } from "components/PhoneFieldForm";
import styled from "styled-components";

export const StyledPhoneFieldForm = styled(PhoneFieldForm)`
  & .MuiInputBase-root {
    height: 36px;

    & .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  }

  & .MuiInputBase-input {
    border: ${(props) => props.error && `1px solid ${props.theme.palette.error.main}`};
    background-color: ${(props) => props.theme.palette.bg.shades};
    border: 1px solid rgba(92, 110, 140, 0.1);
    border-radius: 8px;
    padding: 7px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-transform: ${(props) => props.theme.typography.subtitle1.textTransform};
    color: ${(props) => props.theme.palette.text.dark};
    text-overflow: ellipsis;

    &[aria-invalid='true'] {
      border: ${(props) => `1px solid ${props.theme.palette.error.main}`};
    }

    &::placeholder {
      font-weight: ${(props) => props.theme.typography.body2.fontWeight};
      font-size: ${(props) => props.theme.typography.body2.fontSize};
      letter-spacing: 0.17px;
      text-transform: ${(props) => props.theme.typography.body2.textTransform};
      color: ${(props) => props.theme.palette.disabled};
      opacity: 1;
    }
  }
`