import React, { FC, useCallback, useState } from 'react'
import { Box, Drawer, Typography } from '@mui/material'
import { theme } from '../../../../styles/theme'
import Divider from '../../../../components/Divider'
import { AttachmentsExportDrawerForm } from './components/AttachmentsExportDrawerForm'
import { AttachmentsExportDrawerTopBar, AttachmentsExportDrawerWrapper } from './AttachmentsExportDrawer.styles'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { onDrawerClose, openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'

export const AttachmentsExportDrawer: FC = () => {
  const dispatch = useAppDispatch()

  const { openedDrawer } = useTypedSelector(openedDrawerSelector)

  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer
      anchor='right'
      open={openedDrawer === 'exportAttachments'}
      onClose={() => dispatch(onDrawerClose({ dirty }))}
    >
      <AttachmentsExportDrawerWrapper>
        <AttachmentsExportDrawerTopBar direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
          <Box>
            <Typography variant='h1' color={theme.palette.primary.main}>
              Экспорт архива файлов документации
            </Typography>
          </Box>
        </AttachmentsExportDrawerTopBar>

        <Divider />

        <AttachmentsExportDrawerForm onFormChange={onFormChange} />
      </AttachmentsExportDrawerWrapper>
    </Drawer>
  )
}
