import styled from 'styled-components'
import { Card } from '@mui/material'

export const CardWrapper = styled(Card)`
  width: 100%;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    box-shadow: ${props => props.theme.shadows[20]};
  }

  & .MuiCardContent-root {
    display: flex;
    flex: 1;
    height: 100%;
    padding: 20px;
  }
`
