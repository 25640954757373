import { Accordion, AccordionSummary, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  
  &::before {
    display: none;
  }
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  //background-color: ${props => props.theme.palette.bg.white};
  border-radius: 4px;
  flex-direction: row-reverse;
  padding: 0;
  min-height: 20px;
  height: 20px;
  margin-top: 8px;

  & .MuiAccordionSummary-content {
    margin: 0;
    margin-left: 8px;
    flex: 1;
  }
  
  & .MuiAccordionSummary-expandIconWrapper {
    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.secondary.gray};
    }
  }
`
