import { filterDataSelector } from '../../../../store/slices/documentsPages/filter'
import { useTypedSelector } from '../../../../store/store'
import { UseAutocompleteTomResponse } from './useAutocompleteTom.types'

export const useAutocompleteTom = (): UseAutocompleteTomResponse => {
  const { filterData } = useTypedSelector(filterDataSelector)
  const { selectedObjectsIds, selectedRdsIds, selectedPdsIds, selectedIisIds, selectedIrdsIds, iiType } = filterData

  const selectedFilterObjectId = selectedObjectsIds?.length === 1 ? selectedObjectsIds[0] : null
  const selectedFilterRdId = selectedRdsIds?.length === 1 ? selectedRdsIds[0] : null
  const selectedFilterPdId = selectedPdsIds?.length === 1 ? selectedPdsIds[0] : null
  const selectedFilterIiId = selectedIisIds?.length === 1 ? selectedIisIds[0] : null
  const selectedFilterIrdId = selectedIrdsIds?.length === 1 ? selectedIrdsIds[0] : null

  return {
    defaultObjectId: selectedFilterObjectId,
    defaultRdId: selectedFilterRdId,
    defaultPdId: selectedFilterPdId,
    defaultIiId: selectedFilterIiId,
    defaultIrdId: selectedFilterIrdId,
    defaultIiType: iiType,
  }
}
