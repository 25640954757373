import { memo, useEffect, useRef, useState } from "react"
import { LazyPageProps } from "./LazyPage.types"
import { PageContainer } from "../../PragmaPdfViewer.styles"
import { Page } from "react-pdf"
import { Placeholder } from "../Placeholder"
import { Annotation } from "../../PragmaPdfViewer.types"
import { CanvasAnnotations } from "../CanvasAnnotations"
import { useAppDispatch, useTypedSelector } from "@store/store"
import { getAnnotationsSelector, isAnnotationModeSelector, remarkFormModeSelector, remarkPageSelector, selectedRemarkIdSelector } from "@store/slices/remarks/selectors/remarks.selectors"
import { setMarkCount, setRemarkAnnotations } from "@store/slices/remarks/remarks"
import { Mark } from "@api/remarks/types"

export const LazyPage = memo(({
  currentPage,
  defaultHeight,
  handTool,
  pageData,
  pageIndex,
  rotate,
  scale,
  scrollContainerRef,
  widthAlignment,
  selectedNoteMode,
  isOpenRemarkForm,
  incomingAnnotations,
  isShowAllMarks,
  selectedAnnotationId,
  selectAnnotationId,
  setPageData
}: LazyPageProps) => {

  const dispatch = useAppDispatch()
  const pageRef = useRef<HTMLDivElement>(null)
  const willUpdateRef = useRef(false)
  const [annotations, setAnnotations] = useState<Annotation[]>([])
  const remarkPage = useTypedSelector(remarkPageSelector)
  const getAnnotationTrigger = useTypedSelector(getAnnotationsSelector)
  const remarkFormMode = useTypedSelector(remarkFormModeSelector)
  const isAnnotationMode = useTypedSelector(isAnnotationModeSelector)
  const selectedRemarkId = useTypedSelector(selectedRemarkIdSelector)
  const changeAnnotations = (annotations: Annotation | Annotation[]) => {
    Array.isArray(annotations) ? setAnnotations(annotations) : setAnnotations((prev) => [...prev, annotations])
  }

  const handlePageRenderSuccess = (
    pageNumber: number,
    width: number,
    height: number,
    originalWidth: number,
  ) => {
    const existingPageData = pageData.find((data) => data.pageNumber === pageNumber)
    if (!existingPageData) {
      const scaleFactor = width / originalWidth
      setPageData({
        pageNumber,
        width: width * (1 / scale),
        height: height * (1 / scale),
        scaleFactor
      })
    }
  }

  const placeholderSize = (): { width: number, height: number, scaleFactor: number } | undefined => {
    const currentPageData = pageData.find(data => data.pageNumber === pageIndex + 1)

    const pageWidth = currentPageData
      ? currentPageData.width * scale
      : pageData.length > 0
        ? pageData[0].width * scale
        : undefined

    const pageHeight = currentPageData
      ? currentPageData.height * scale
      : pageData.length > 0
        ? pageData[0].height * scale
        : undefined

    const scaleFactor = currentPageData ? currentPageData.scaleFactor : 1

    if (pageWidth && pageHeight) {
      if (rotate === 270 || rotate === 90) {
        return { width: pageHeight, height: pageWidth, scaleFactor }
      }
      return { width: pageWidth, height: pageHeight, scaleFactor }
    }
  }

  const placeholderPageSize = placeholderSize()

  useEffect(() => {
    if (remarkPage === pageIndex + 1) {
      if (isOpenRemarkForm) {
        remarkFormMode === 'create'
          ? dispatch(setMarkCount(annotations.filter(a => !a.id).length))
          : dispatch(setMarkCount(annotations.length))
      } else {
        dispatch(setMarkCount(null))
      }
    }
  }, [annotations, isOpenRemarkForm, remarkPage])

  useEffect(() => {
    if (getAnnotationTrigger && remarkPage === pageIndex + 1) {
      willUpdateRef.current = true
      const marks: Mark[] = annotations.length > 0
        ? (remarkFormMode === 'create' ? annotations.filter(a => !a.id) : annotations).map(({ id, ...annot }) => ({ annotation: annot, id: id }))
        : []
      dispatch(setRemarkAnnotations(marks))
    }
  }, [getAnnotationTrigger])

  const filterAnnotations = () => {
    if (!isShowAllMarks) {
      selectedRemarkId
        ? setAnnotations(incomingAnnotations.filter(a => a.page === pageIndex + 1 && a.remarkId === selectedRemarkId).flatMap(a => a.annotations))
        : isOpenRemarkForm ? setAnnotations((prev) => prev.filter(a => !a.id)) : setAnnotations([])
    } else if (isShowAllMarks) {
      isOpenRemarkForm
        ? setAnnotations((prev) => [...prev.filter(a => !a.id), ...incomingAnnotations.filter(a => a.page === pageIndex + 1).flatMap(a => a.annotations)])
        : setAnnotations(incomingAnnotations.filter(a => a.page === pageIndex + 1).flatMap(a => a.annotations))
    }
  }

  useEffect(() => {
    const willUpdate = willUpdateRef.current
    if (!isAnnotationMode && !willUpdate) {
      filterAnnotations()
    } else {
      willUpdateRef.current = false
    }
  }, [isAnnotationMode])

  useEffect(() => {
    if (isOpenRemarkForm && (!remarkPage || remarkPage !== pageIndex + 1)) {
      if (remarkFormMode === 'create') {
        setAnnotations(annotations.filter(a => a.id))
      }
      if (remarkFormMode === 'edit') {
        setAnnotations([])
      }
    }
  }, [remarkPage])


  useEffect(() => {
    filterAnnotations()
  }, [incomingAnnotations, isShowAllMarks, pageIndex, selectedRemarkId])

  if (pageIndex === currentPage - 3 || pageIndex === currentPage - 2 || pageIndex === currentPage - 1 || pageIndex === currentPage || pageIndex === currentPage + 1) {
    return (
      <PageContainer data-page={pageIndex} >
        <Page
          inputRef={pageRef}
          renderTextLayer={!handTool}
          width={widthAlignment === 'width' ? scrollContainerRef.current?.offsetWidth! - 33 : undefined}
          height={widthAlignment === 'height' ? defaultHeight - 18 : undefined}
          scale={scale}
          loading={null}
          pageNumber={pageIndex + 1}
          renderAnnotationLayer={false}
          onRenderSuccess={({ pageNumber, width, height, originalHeight, originalWidth }) =>
            handlePageRenderSuccess(pageNumber, width, height, originalWidth)
          }
        /* onGetAnnotationsSuccess={(annotations) => console.log(annotations)} */
        />
        {placeholderPageSize &&
          <CanvasAnnotations
            width={placeholderPageSize.width}
            height={placeholderPageSize.height}
            rotate={rotate}
            scale={scale}
            scaleFactor={placeholderPageSize.scaleFactor}
            canEditAnnotation={isOpenRemarkForm && remarkPage === pageIndex + 1}
            annotationType={selectedNoteMode}
            annotations={annotations}
            setAnnotations={changeAnnotations}
            documentRef={pageRef}
            isSelectMode={!isOpenRemarkForm && isShowAllMarks}
            isCreateMode={remarkFormMode === 'create'}
            pageNumber={pageIndex + 1}
            selectedAnnotationId={selectedAnnotationId}
            selectAnnotationId={selectAnnotationId}
          />}
      </PageContainer>
    )
  }

  return (
    <PageContainer data-page={pageIndex}>
      {placeholderPageSize && <Placeholder height={placeholderPageSize.height} width={placeholderPageSize.width} />}
    </PageContainer>
  )
})