import { FC } from 'react'
import { AttachmentsExportDrawerSelectedListWrapper } from './AttachmentsExportDrawerSelectedList.styles'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import { AttachmentsExportFormData, SelectedAttachmentData } from '../AttachmentsExportDrawerForm'
import { AttachmentsExportAttachmentItem } from '../AttachmentsExportAttachmentItem'

export const AttachmentsExportDrawerSelectedList: FC = () => {
  const { values: formValues, setFieldValue } = useFormikContext<AttachmentsExportFormData>()

  const onDocClearSelectedAttachments = (selectedAttachments: SelectedAttachmentData[]) => {
    setFieldValue('selectedAttachments', [])

    selectedAttachments.forEach(({ docIndex, attachmentIndex }) => {
      setFieldValue(`docList[${docIndex}].attachList[${attachmentIndex}].selected`, false)
      setFieldValue(`docList[${docIndex}].selected`, false)
    })
  }

  return (
    <AttachmentsExportDrawerSelectedListWrapper>
      <Stack direction='row' justifyContent='space-between' alignItems='center' pr={2.5} pb={2} spacing={1}>
        <Box>
          <Typography
            variant='subtitle2'
            fontSize={16}
            lineHeight='24px'
            letterSpacing='0.15px'
          >
            Выбранные файлы
          </Typography>
        </Box>

        <Button
          onClick={() => onDocClearSelectedAttachments(formValues.selectedAttachments)}
          variant='text'
          color='error'
          sx={{ height: 30, p: '4px 8px' }}
        >
          <Typography
            variant='tooltip'
            color='inherit'
            fontSize={12}
            lineHeight='13.2px'
            textTransform='lowercase'
          >
            очистить список
          </Typography>

        </Button>
      </Stack>

      <ScrollableContainer>
        {formValues.selectedAttachments?.map(({ attachment, docIndex, attachmentIndex }) => (
          <AttachmentsExportAttachmentItem
            attachment={attachment}
            docIndex={docIndex}
            attachmentIndex={attachmentIndex}
            key={attachment.id}
          />
        ))
        }
      </ScrollableContainer>
    </AttachmentsExportDrawerSelectedListWrapper>
  )
}
