import { StyledToggleButton, StyledToggleButtonGroup } from './ToggleButtonGroup.styled'
import { ToggleButtonGroupProps, ToggleButtonProps } from './ToggleButtonGroup.types'
import React, { memo } from 'react'

export const PureStyledToggleButton = memo(StyledToggleButton)

export const ToggleButtonGroup = <T extends any>(props: ToggleButtonGroupProps<T>) => {
  return (
    <StyledToggleButtonGroup>
      {props.toggleButtonData.map((e: ToggleButtonProps<T>, id) => (
        <PureStyledToggleButton
          key={id}
          value={e?.value}
          variant={e?.value === props?.currentValue ? 'primary' : 'shades'}
          onClick={(e: React.SyntheticEvent<Element, Event>, toggleValue: T) =>
            props.onToggleButtonChange(e, toggleValue)
          }
        >
          {e?.label}
          {e?.children && e?.children}
        </PureStyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  )
}
