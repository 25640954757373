import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const VersionIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.0882 3.36842L19.5441 4.73684L21 6.10526V14.3158C21 14.6787 20.8466 15.0268 20.5736 15.2834C20.3006 15.54 19.9302 15.6842 19.5441 15.6842L17.8235 15.6842V17.4737C17.8235 17.8366 17.6701 18.1847 17.3971 18.4413C17.124 18.6979 16.7537 18.8421 16.3676 18.8421L14.647 18.8421V20.6316C14.647 20.9945 14.4936 21.3426 14.2206 21.5992C13.9476 21.8558 13.5773 22 13.1912 22H4.45588C4.06976 22 3.69945 21.8558 3.42642 21.5992C3.15339 21.3426 3 20.9945 3 20.6316V9.68421C3 9.32128 3.15339 8.97322 3.42642 8.71659C3.69945 8.45996 4.06976 8.31579 4.45588 8.31579H6.17645V6.52632C6.17645 6.16339 6.32984 5.81532 6.60287 5.5587C6.8759 5.30207 7.24621 5.15789 7.63233 5.15789H9.35292V6.52632H7.63233V17.4737H16.3676V15.6842L10.8088 15.6842C10.4227 15.6842 10.0524 15.54 9.77938 15.2834C9.50635 15.0268 9.35297 14.6787 9.35297 14.3158V3.36842C9.35297 3.00549 9.50635 2.65743 9.77938 2.4008C10.0524 2.14417 10.4227 2 10.8088 2H16.6324L18.0882 3.36842ZM7.63233 18.8421L13.1912 18.8421V20.6316H4.45588V9.68421H6.17645V17.4737C6.17645 17.8366 6.32984 18.1847 6.60287 18.4413C6.8759 18.6979 7.24621 18.8421 7.63233 18.8421ZM10.8088 3.36842V14.3158H19.5441V6.76895L15.9044 6.78947V3.36842H10.8088ZM16.9992 9.33282C17.183 9.0571 17.1085 8.68458 16.8328 8.50077C16.5571 8.31696 16.1846 8.39146 16.0008 8.66718L14.5267 10.8783L13.5145 9.1913C13.344 8.90716 12.9754 8.81502 12.6913 8.9855C12.4071 9.15599 12.315 9.52455 12.4855 9.8087L13.9855 12.3087C14.0913 12.4851 14.2801 12.5949 14.4857 12.5998C14.6913 12.6047 14.8851 12.504 14.9992 12.3328L16.9992 9.33282Z'
      />
    </SvgIcon>
  )
}

export default VersionIcon