import { FC } from "react";
import { SearchWrapperProps } from "./SearchWrapper.types";
import { StyledTitle, StyledWrapper } from "./SearchWrapper.styles";
import { Stack } from "@mui/material";

export const SearchWrapper: FC<SearchWrapperProps> = ({ title, children }) => {
  return (
    <StyledWrapper>
      <Stack spacing='12px'>
        <StyledTitle variant="body2">{title}</StyledTitle>
        {children}
      </Stack>
    </StyledWrapper>
  )
}