import { Stack, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

interface NavbarSchemaItemWrapperProps {
  selected: boolean
  isBroken: boolean
}

const primaryBorderCss = css`
  border-color: ${props => props.theme.palette.primaryBorderLight};
`
const isBrokenBorderCss = css`
  border-color: ${props => props.theme.palette.warning.light};
`

const selectPrimaryCss = css`
  background-color: #1976D214;
  border-color: ${props => props.theme.palette.primaryBorderLight};
`
const selectBrokenCss = css`
  background-color: ${props => props.theme.palette.warning.main}1A;
  border-color: ${props => props.theme.palette.warning.light};
`

export const NavbarSchemaItemTitle = styled(Typography)`
  max-width: 150px;
  line-height: 18px;
  color: ${props => props.theme.palette.text.dark};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const NavbarSchemaItemWrapper = styled(Stack) <NavbarSchemaItemWrapperProps>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 8px;
  cursor: pointer;

  ${props => props.selected && (props.isBroken
    ? selectBrokenCss
    : selectPrimaryCss
  )}

  &:hover {
    ${props => props.isBroken ? isBrokenBorderCss : primaryBorderCss};
  }

  ${NavbarSchemaItemTitle} {

    & .MuiTypography-root {
      color: ${props => props.selected && (props.isBroken
    ? props.theme.palette.warning.dark
    : props.theme.palette.primary.main
  )}
    }
  }
`

export const NavbarSchemaItemSubtitle = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
`
export const NavbarSchemaItemDuration = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.palette.info.main};
`

