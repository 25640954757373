import AddIcon from '@mui/icons-material/Add'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Box, Stack } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  useGetProjectArchitectorsQuery,
  useGetProjectClientsQuery,
  useGetProjectRegionsQuery,
} from '../../../api/projects'
import Button from '../../../components/Button'
import { SelectItem } from '../../../components/FilterSelectMulti/FilterSelectMultiple.types'
import { FilterSubmitSelectMultiple } from '../../../components/FilterSubmitSelectMultiple'
import { profileSelector } from '../../../store/slices/profile'
import { useTypedSelector } from '../../../store/store'
import {
  ProjectPhase,
  ProjectType,
  allProjectsPhases,
  allProjectsTypes,
  projectTypeShort,
} from '../../../types/project'
import { HomeTopLegendProps } from './HomeTopLegend.types'
import Statistics from './Statistics'
import { FilterSelectFields } from './components/FilterSelectFields'

const HomeTopLegend: React.FC<HomeTopLegendProps> = ({
  statistics,
  selectedTypes,
  onTypeFilter,
  selectedStages,
  onStageFilter,
  selectedArchitectors,
  selectedClients,
  selectedRegions,
  setSelectedArchitectors,
  setSelectedClients,
  onFilterRegion,
}) => {
  const navigate = useNavigate()
  const { role } = useTypedSelector(profileSelector)

  const { data: projectClients } = useGetProjectClientsQuery()
  const { data: projectArchitectors } = useGetProjectArchitectorsQuery()
  const { data: regions } = useGetProjectRegionsQuery()

  const onButtonClick = useCallback(() => {
    navigate('/administration/projects/add')
  }, [])

  const typeSelectItems: SelectItem<string>[] = allProjectsTypes.map((type) => ({
    value: type,
    title: projectTypeShort[type],
  }))

  const stageSelectItems: SelectItem<string>[] = allProjectsPhases.map((stage) => ({
    value: stage,
    title: stage,
  }))

  const fullNameRegions: SelectItem<string>[] = useMemo(() => {
    if (!regions) return []
    return regions.data.map((region) => ({
      value: region,
      title: region,
    }))
  }, [regions])

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1 }}>
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <FilterSubmitSelectMultiple<string>
            items={typeSelectItems}
            selectedOptions={selectedTypes}
            onSubmit={(value: any) => {
              onTypeFilter(value as ProjectType[])
            }}
            defaultValue='Тип проекта'
            allSelectedText='Все типы'
            startIcon={<FilterAltIcon fontSize='medium' color='dark' />}
            paperFullHeight
          />
          <FilterSubmitSelectMultiple<string>
            items={stageSelectItems}
            selectedOptions={selectedStages}
            onSubmit={(value: any) => {
              onStageFilter(value as ProjectPhase[])
            }}
            defaultValue='Стадия проекта'
            allSelectedText='Все стадии'
            paperFullHeight
          />
          <FilterSubmitSelectMultiple<string>
            items={fullNameRegions}
            selectedOptions={selectedRegions}
            onSubmit={(value: string[]) => {
              onFilterRegion(value)
            }}
            defaultValue='Регионы'
            allSelectedText='Все регионы'
            paperFullHeight
          />
          <FilterSelectFields
            selectedOptions={selectedClients}
            defaultValue='Заказчик'
            data={projectClients?.data}
            onSubmit={setSelectedClients}
          />
          <FilterSelectFields
            selectedOptions={selectedArchitectors}
            defaultValue='Проектировщик'
            data={projectArchitectors?.data}
            onSubmit={setSelectedArchitectors}
          />
        </Stack>

        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2.5}>
          <Statistics statistics={statistics} />
          {role === 'admin' ? (
            <Button startIcon={<AddIcon />} onClick={onButtonClick}>
              Добавить проект
            </Button>
          ) : null}
        </Stack>
      </Stack>
    </Box>
  )
}

export default HomeTopLegend
