import React, { FC } from 'react'
import { IiStatisticsItemProps } from './IiStatisticsItem.types'
import { Box, Stack } from '@mui/material'
import { StatusItem } from '../StatusItem'
import { StatisticsBar } from '../StatisticsBar'

export const IiStatisticsItem: FC<IiStatisticsItemProps> = ({
  statisticsData,
  onStatisticHeaderClick,
  statisticsBarData,
}) => {
  const { title, value, percentage, color, subItems, disableInnerGap } = statisticsData

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
      <Box width={240}>
        <StatusItem
          title={title}
          onStatisticHeaderClick={onStatisticHeaderClick}
          callbackTarget='child'
          value={value}
          percentage={percentage}
          color={color}
          subItems={subItems}
          disableInnerGap={disableInnerGap}
        />
      </Box>


      <StatisticsBar statisticsBarGroups={statisticsBarData} />
    </Stack>
  )
}
