import { UseArchiveFilterResponse } from './useArchiveFilter.types'
import { TomStatus } from '../../../../../../../../types/tom'
import { TomRdArchiveObject, TomRdArchiveRd, TomRdArchiveTom } from '../../../../../../../../api/rdPhase'
import { TomPdArchivePd, TomPdArchiveTom } from '../../../../../../../../api/pdPhase'
import { TomIiArchiveIi, TomIiArchiveTom } from '../../../../../../../../api/iiPhase/tom/archiveExport'
import { useFormikContext } from 'formik'
import { ExportArchiveFormData } from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { ProjectPhase } from '../../../../../../../../types/project'
import { useTypedSelector } from '../../../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../../../store/slices/documentsPages/projectInfo'
import { TomIrdArchiveIrd, TomIrdArchiveTom } from '../../../../../../../../api/irdPhase/tom/archiveExport'


// set 'hideByFilter' for all entities
export const useArchiveFilter = (): UseArchiveFilterResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { values: formValues, setFieldValue } = useFormikContext<ExportArchiveFormData>()

  const { rdFileList, pdFileList, iiFileList, irdFileList } = formValues

  // Rd
  const onRdFilterSelect = (statuses: (TomStatus | 'В производстве')[], existDocChecked: boolean) => {
    const updatedObjects = rdFileList.map<TomRdArchiveObject>(object => {

      const updatedRds = object.rdList.map<TomRdArchiveRd>(rd => {

        const updatedToms = rd.tomList.map<TomRdArchiveTom>(tom => ({
          ...tom,
          hideByFilter: (!!statuses.length && !(tom.inProd ? statuses.includes('В производстве') : statuses.includes(tom.status)))
            || (existDocChecked && !tom.existDoc),
        }))
        const hideRd = updatedToms.every(tom => tom.hideByFilter)
        return {
          ...rd,
          tomList: updatedToms,
          hideByFilter: hideRd,
        }

      })

      const hideObject = updatedRds.every(rd => rd.hideByFilter)

      return {
        ...object,
        rdList: updatedRds,
        hideByFilter: hideObject,
      }
    })

    setFieldValue('rdFileList', updatedObjects)
  }


  // Pd
  const onPdFilterSelect = (statuses: TomStatus[], existDocChecked: boolean) => {
    const updatedPds = pdFileList.map<TomPdArchivePd>(pd => {
      const updatedToms = pd.tomList.map<TomPdArchiveTom>(tom => ({
        ...tom,
        hideByFilter: (!!statuses.length && !statuses.includes(tom.status))
          || (existDocChecked && !tom.existDoc),
      }))

      const hidePd = updatedToms.every(tom => tom.hideByFilter)

      return {
        ...pd,
        tomList: updatedToms,
        hideByFilter: hidePd,
      }
    })

    setFieldValue('pdFileList', updatedPds)
  }


  // Ii
  const onIiFilterSelect = (statuses: TomStatus[], existDocChecked: boolean) => {
    const updatedIis = iiFileList.map<TomIiArchiveIi>(ii => {
      const updatedToms = ii.tomList.map<TomIiArchiveTom>(tom => ({
        ...tom,
        hideByFilter: (!!statuses.length && !statuses.includes(tom.status))
          || (existDocChecked && !tom.existDoc),
      }))

      const hideIi = updatedToms.every(tom => tom.hideByFilter)

      return {
        ...ii,
        tomList: updatedToms,
        hideByFilter: hideIi,
      }
    })

    setFieldValue('iiFileList', updatedIis)
  }


  // Ird
  const onIrdFilterSelect = (statuses: TomStatus[], existDocChecked: boolean) => {
    const updatedIrds = irdFileList.map<TomIrdArchiveIrd>(ird => {
      const updatedToms = ird.tomList.map<TomIrdArchiveTom>(tom => ({
        ...tom,
        hideByFilter: (!!statuses.length && !statuses.includes(tom.status))
          || (existDocChecked && !tom.existDoc),
      }))

      const hideIrd = updatedToms.every(tom => tom.hideByFilter)

      return {
        ...ird,
        tomList: updatedToms,
        hideByFilter: hideIrd,
      }
    })

    setFieldValue('irdFileList', updatedIrds)
  }


  // Common
  const onFilterSelect: Record<ProjectPhase, any> = {
    'Рабочая документация': onRdFilterSelect,
    'Проектная документация': onPdFilterSelect,
    'Инженерные изыскания': onIiFilterSelect,
    'Сбор исходных данных': onIrdFilterSelect,
  }

  return {
    onFilterSelect: onFilterSelect[selectedProjectPhase],
  }
}
