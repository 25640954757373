import { Tooltip as MuiTooltip, TooltipProps, Typography } from "@mui/material"

export const Tooltip = ({ title, ...props }: TooltipProps) => {
  return (
    <MuiTooltip
      arrow
      title={
        <Typography fontSize={14} color='#fff'>{title}</Typography>
      }
      componentsProps={{
        tooltip: {
          style: {
            marginTop: 6,
            padding: 10,
            backgroundColor: '#5C6E8C'
          }
        }
      }}
      {...props}
    >
      {props.children}
    </MuiTooltip>
  )
}