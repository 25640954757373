import { useGetProjectByIdQuery } from 'api/projects'
import { useCallback } from 'react'

import { useGetAllIiQuery } from '../../../../api/iiPhase'
import { useGetAllIrdQuery } from '../../../../api/irdPhase'
import { useGetAllPpdQuery } from '../../../../api/pdPhase'
import { useGetObjectsByProjectIdQuery } from '../../../../api/rdPhase'
import { useGetAllRdpQuery } from '../../../../api/rdPhase/rdp'
import useFilteredEmployees from '../../../../hooks/useFilteredEmployees'
import { openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector,
} from '../../../../store/slices/documentsPages/projectInfo'
import { tomSelector } from '../../../../store/slices/documentsPages/tom'
import { useTypedSelector } from '../../../../store/store'
import { ProjectPhase } from '../../../../types/project'
import { isIiTom, isIrdTom, isPdTom, isRdTom } from '../../../../types/tom'
import { formatToDate } from '../../../../utils/formatDate'
import {
  AutocompleteInitialValues,
  ContractorInitialValues,
  DateInitialValues,
  UseTomInitialValuesProps,
  UseTomInitialValuesResponse,
} from './useTomInitialValues.types'

export const useTomInitialValues = ({
  autocompleteData,
  otherPhase,
  otherProjectID,
  permanentAutocompleteData,
}: UseTomInitialValuesProps): UseTomInitialValuesResponse => {
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)

  const projectPhase = otherPhase || selectedProjectPhase
  const projectId = otherProjectID || project.id

  const { architectorsAndAdmins, clientsAndAdmins, contractors } = useFilteredEmployees()
  const isEditTom = openedDrawer === 'update' && !!tom?.id

  const { title, architectorId, architectorName, clientId, clientName, actualDate, plannedDate, forecastDate } =
    (isEditTom && tom) || {}

  const { data: projectData } = useGetProjectByIdQuery({ id: projectId })

  const { data } = projectData || {}
  const { responsiblePersons } = data || {}

  const setProjectArchitector = useCallback((): string | number => {
    if (responsiblePersons) {
      const projectArchitectorName = responsiblePersons.architector.name
      const projectArchitectorId = responsiblePersons.architector.id
      if (projectArchitectorId || projectArchitectorName) {
        return projectArchitectorId || projectArchitectorName
      }
    }
    return ''
  }, [responsiblePersons])

  const setProjectClient = useCallback((): string | number => {
    if (responsiblePersons) {
      const projectClientName = responsiblePersons.client.name
      const projectClientId = responsiblePersons.client.id
      if (projectClientId || projectClientName) {
        return projectClientId || projectClientName
      }
    }
    return ''
  }, [responsiblePersons])

  const setProjectContractor = useCallback((): string | number => {
    if (responsiblePersons && responsiblePersons.contractors.length === 1) {
      const firstProjectContractorData = responsiblePersons.contractors[0]
      const projectContractorName = firstProjectContractorData.name
      const projectContractorId = firstProjectContractorData.id
      if (projectContractorId || projectContractorName) {
        return projectContractorId || projectContractorName
      }
    }
    return ''
  }, [responsiblePersons])

  const { objectId, rdId, contractorId, contractorName, prodDate } = (isEditTom && tom && isRdTom(tom) && tom) || {}
  const { pdId } = (isEditTom && tom && isPdTom(tom) && tom) || {}
  const { iiId, type } = (isEditTom && tom && isIiTom(tom) && tom) || {}
  const { irdId, issueDate, durationDate } = (isEditTom && tom && isIrdTom(tom) && tom) || {}

  const { data: objectsData } = useGetObjectsByProjectIdQuery({ id: projectId })
  const { data: objects = [] } = objectsData || {}

  const { data: rdData } = useGetAllRdpQuery({ id: projectId })
  const { data: rdArray = [] } = rdData || {}

  const { data: pdData } = useGetAllPpdQuery({ id: projectId })
  const { data: pdArray = [] } = pdData || {}

  const { data: iiData } = useGetAllIiQuery({ id: projectId })
  const { data: iiArray = [] } = iiData || {}

  const { data: irdData } = useGetAllIrdQuery({ id: projectId })
  const { data: irdArray = [] } = irdData || {}

  const {
    projectName,
    defaultObjectId,
    defaultRdId,
    defaultPdId,
    defaultIiId,
    defaultIiType,
    defaultIrdId,
    defaultClientName,
    defaultArchitectorName,
  } = autocompleteData || {}

  const defaultObjectName = defaultObjectId ? objects?.find((object) => object.id === defaultObjectId)?.number : null
  const defaultRdName = defaultRdId ? rdArray?.find((rd) => rd.id === defaultRdId)?.mark : null
  const defaultPdName = defaultPdId ? pdArray?.find((pd) => pd.id === defaultPdId)?.mark : null
  const defaultIiName = defaultIiId ? iiArray?.find((ii) => ii.id === defaultIiId)?.mark : null
  const defaultIrdName = defaultIrdId ? irdArray?.find((ird) => ird.id === defaultIrdId)?.mark : null

  const defaultTitle: Record<ProjectPhase, string> = {
    'Рабочая документация': `${projectName || ''}-${defaultObjectName || ''}-${defaultRdName || ''}`,
    'Проектная документация': `${projectName || ''}-${defaultPdName || ''}`,
    'Инженерные изыскания': `${projectName || ''}-${defaultIiName || ''}`,
    'Сбор исходных данных': `${projectName || ''}-${defaultIrdName || ''}`,
  }

  const autocompleteInitialValues: AutocompleteInitialValues = {
    title: title || defaultTitle[projectPhase] || '',
    objectId: objectId || defaultObjectId || '',
    rdId: rdId || defaultRdId || '',
    pdId: pdId || defaultPdId || '',
    iiId: iiId || defaultIiId || '',
    iiType: type || defaultIiType || '',
    irdId: irdId || defaultIrdId || '',
    architectorData:
      (architectorId && architectorsAndAdmins.find((architector) => architector.id === architectorId)?.name) ||
      architectorName ||
      defaultArchitectorName ||
      setProjectArchitector() ||
      '',
    clientData:
      (clientId && clientsAndAdmins.find((client) => client.id === clientId)?.name) ||
      clientName ||
      defaultClientName ||
      setProjectClient() ||
      '',
  }

  const dateInitialValues: DateInitialValues = {
    plannedDate: formatToDate(plannedDate),
    forecastDate: formatToDate(forecastDate),
    actualDate: formatToDate(actualDate),
    prodDate: formatToDate(prodDate),
    issueDate: formatToDate(issueDate),
    durationDate: formatToDate(durationDate),
  }

  const contractorInitialValues: ContractorInitialValues = {
    contractorData:
      (contractorId && contractors.find((contractor) => contractor.id === contractorId)?.name) ||
      contractorName ||
      setProjectContractor() ||
      '',
  }

  return {
    autocompleteInitialValues,
    dateInitialValues,
    contractorInitialValues,
  }
}
