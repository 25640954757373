import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const EditDocIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M9.9453 19.1471H5.98177V3.9306H12.9179V8.68576L17.8724 8.65723V11.5103L19.8541 9.60826V7.7062L17.8724 5.80413L15.8906 3.90207L13.9088 2H5.98177C5.45617 2 4.9521 2.2004 4.58045 2.5571C4.20879 2.91381 4 3.39761 4 3.90207V19.1186C4 19.623 4.20879 20.1068 4.58045 20.4636C4.9521 20.8203 5.45617 21.0207 5.98177 21.0207H9.9453V19.1471ZM15.8913 11.5388H7.96427V13.4409H15.8913V11.5388ZM18.0452 14.0446L11.9562 19.884L11.9265 21.9715L14.1609 22L20.2499 16.1559L18.0452 14.0446ZM20.9734 12.6707L21.6819 13.3649C21.7807 13.4569 21.8597 13.5667 21.9142 13.688C21.9687 13.8093 21.9977 13.9398 21.9995 14.0719C22.0014 14.204 21.976 14.3351 21.9249 14.4578C21.8738 14.5805 21.7979 14.6923 21.7017 14.7867L20.9734 15.4857L18.7538 13.3697L19.4871 12.6707C19.5836 12.5752 19.6991 12.4993 19.8268 12.4474C19.9544 12.3956 20.0916 12.3689 20.2302 12.3689C20.3689 12.3689 20.5061 12.3956 20.6337 12.4474C20.7614 12.4993 20.8769 12.5752 20.9734 12.6707ZM12.9187 15.343V16.2655L11.8981 17.245H7.96427V15.343H12.9187Z'
      />
    </SvgIcon>
  )
}

export default EditDocIcon