import { FC, useState } from "react";
import { IRemarkDirectionHistoryProps } from "./RemarkDirectionHistory.types";
import { Stack, Typography } from "@mui/material";
import { RemarkDirectionCard } from "./components/RemarkDirectionCard";
import { RemarkHistoryContainer } from "../RemarkEliminationHistory/RemarkEliminationHistory.styles";
import { TitledWrapper } from "components/TitledWrapper";
import { RemarkAccordionTitle, RemarkAccordionTitleWrapper } from "./RemarkDirectionHistory.styles";
import { theme } from "styles/theme";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material'
import EmptyPage from "components/EmptyPage";

export const RemarkDirectionHistory: FC<IRemarkDirectionHistoryProps> = ({ remarkResponse }) => {
  const accordionInitial: boolean = true
  const [isAccordionOpen, setIsAccordionOpen] = useState(accordionInitial);
  const { mails = [] } = remarkResponse || {}

  const onAccordionClick = () => {
    setIsAccordionOpen(prev => !prev)
  }

  return (
    <RemarkHistoryContainer height={'100%'} spacing={1.5}>
      {mails.length ? (
        <TitledWrapper
          title={(
            <RemarkAccordionTitleWrapper onClick={onAccordionClick}>
              <RemarkAccordionTitle>
                <Typography variant='body1' fontSize={14} color={theme.palette.primary.main}>
                  Замечания
                </Typography>

                {isAccordionOpen
                  ? <RemoveIcon color={'primary'} fontSize={'medium'} />
                  : <AddIcon color={'primary'} fontSize={'medium'} />}
              </RemarkAccordionTitle>
            </RemarkAccordionTitleWrapper>
          )}
          variant='accordion'
          customTitle
          defaultExpanded={accordionInitial}
        >
          <Stack spacing={1.5}>
            {mails.map(item => <RemarkDirectionCard key={item.mail.id} item={item} />)}
          </Stack>
        </TitledWrapper>
      ) : <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
        <Typography variant="body2">История направлений отсутствует.</Typography>
      </Stack>}

    </RemarkHistoryContainer>
  )
}