import styled, { css } from 'styled-components'
import { alpha, Stack, Typography } from '@mui/material'
import MuiLockIcon from '@mui/icons-material/Lock'
import MuiLockOpenIcon from '@mui/icons-material/LockOpen'

export const AgreementNavbarPhaseItemNumber = styled(Stack)`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: ${props => `1px solid ${props.theme.palette.primaryBorderLight}`};
  border-radius: 6px;

  & .MuiTypography-root {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    color: ${props => props.theme.palette.primaryBorderLight};
  }
  
  & .verifiedIcon {
    position: absolute;
    top: -6px;
    left: -8px;
  }
`

interface AgreementNavbarPhaseItemWrapperProps {
  selected: boolean
  isBroken: boolean
}

const primaryBorderCss = css`
  border-color: ${props => props.theme.palette.primaryBorderLight};
`
const isBrokenBorderCss = css`
  border-color: ${props => props.theme.palette.legends.lightOrange};
`
export const AgreementNavbarPhaseItemWrapper = styled(Stack)<AgreementNavbarPhaseItemWrapperProps>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    ${props => props.isBroken ? isBrokenBorderCss : primaryBorderCss};
  }

  ${props => props.selected && (props.isBroken
          ? isBrokenBorderCss
          : primaryBorderCss
  )}
  ${AgreementNavbarPhaseItemNumber} {
    background-color: ${props => props.selected && (props.isBroken
            ? props.theme.palette.legends.lightOrange
            : props.theme.palette.primaryBorderLight
    )};
    border-color: ${props => props.isBroken && props.theme.palette.legends.lightOrange};


    & .MuiTypography-root {
      color: ${props => props.isBroken && props.theme.palette.legends.lightOrange};
      color: ${props => props.selected && props.theme.palette.text.light};
    }
  }
`


export const AgreementNavbarPhaseItemTitle = styled(Typography)`
  max-width: 150px;
  line-height: 18px;
  color: ${props => props.theme.palette.text.dark};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const AgreementNavbarPhaseItemPerson = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
`

export const AgreementNavbarPhaseItemDuration = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.palette.info.main};
`

export const LockIcon = styled(MuiLockIcon)`
  color: ${props => props.theme.palette.disabled};
`

export const LockOpenIcon = styled(MuiLockOpenIcon)`
  color: ${props => alpha(props.theme.palette.text.dark!, 0.12)};
`