import { Tabs } from "@mui/material";
import styled from "styled-components";
import { UserDesignLogo, UserDrawer, UserDrawerContent, UserDrawerHeader, UserLogoWrapper } from "../../components/UserNavBar/styles";

export const AgreementDrawer = styled(UserDrawer)`
`

export const AgreementDrawerHeader = styled(UserDrawerHeader)`
`

export const AgreementLogoWrapper = styled(UserLogoWrapper)`
`

export const AgreementDesignLogo = styled(UserDesignLogo)`
`
export const AgreementDrawerContent = styled(UserDrawerContent)`
`

export const StyledButtonGroup = styled(Tabs)`
  padding: 20px 20px 10px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: transparent;

  .MuiTabs-flexContainer {
    background-color: ${props => props.theme.palette.bg.white};
    border: 1px solid ${props => props.theme.palette.primaryBorderLight};
    border-radius: 6px;
    height: 40px;
    align-items: center;
    
  }

  & .MuiTab-fullWidth {
    height: 40px;
    min-height: min-content;
    padding: 0;
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.palette.secondary.dark};

    &:first-child {
      border-right: 1px solid ${props => props.theme.palette.primaryBorderLight};
    }
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .Mui-selected {
      color: ${props => props.theme.palette.primary.main};
      background-color: ${props => props.theme.palette.bg.lightBlue};
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    }
`