import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

export const ShiftUpOutlinedIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M13.322 2.60299C13.1577 2.4136 12.9547 2.26171 12.7266 2.15761C12.4985 2.05352 12.2507 1.99965 12 1.99965C11.7493 1.99965 11.5015 2.05352 11.2734 2.15761C11.0453 2.26171 10.8423 2.4136 10.678 2.60299L2.433 12.107C1.449 13.24 2.254 15.004 3.754 15.004H7V17.25C7 18.216 7.784 19 8.75 19H15.25C15.7141 19 16.1593 18.8156 16.4874 18.4874C16.8156 18.1592 17 17.7141 17 17.25V15.004H20.245C21.745 15.004 22.55 13.24 21.567 12.107L13.322 2.60299ZM11.811 3.58599C11.8345 3.55889 11.8635 3.53715 11.8961 3.52225C11.9287 3.50735 11.9642 3.49964 12 3.49964C12.0359 3.49964 12.0713 3.50735 12.1039 3.52225C12.1365 3.53715 12.1655 3.55889 12.189 3.58599L20.434 13.09C20.4655 13.1262 20.4859 13.1707 20.4927 13.2182C20.4996 13.2657 20.4927 13.3142 20.4727 13.3578C20.4528 13.4015 20.4207 13.4385 20.3804 13.4644C20.34 13.4903 20.293 13.5041 20.245 13.504H16.25C16.0511 13.504 15.8603 13.583 15.7197 13.7237C15.579 13.8643 15.5 14.0551 15.5 14.254V17.25C15.5 17.3163 15.4737 17.3799 15.4268 17.4268C15.3799 17.4737 15.3163 17.5 15.25 17.5H8.75C8.6837 17.5 8.62011 17.4737 8.57323 17.4268C8.52634 17.3799 8.5 17.3163 8.5 17.25V14.254C8.5 14.0551 8.42099 13.8643 8.28033 13.7237C8.13968 13.583 7.94892 13.504 7.75 13.504H3.754C3.70611 13.5039 3.65925 13.49 3.61902 13.464C3.57879 13.438 3.54687 13.401 3.52706 13.3574C3.50726 13.3137 3.5004 13.2654 3.5073 13.218C3.51421 13.1706 3.53458 13.1261 3.566 13.09L11.811 3.58599ZM7.75 20.5C7.55109 20.5 7.36033 20.579 7.21967 20.7197C7.07902 20.8603 7 21.0511 7 21.25C7 21.4489 7.07902 21.6397 7.21967 21.7803C7.36033 21.921 7.55109 22 7.75 22H16.25C16.4489 22 16.6397 21.921 16.7803 21.7803C16.921 21.6397 17 21.4489 17 21.25C17 21.0511 16.921 20.8603 16.7803 20.7197C16.6397 20.579 16.4489 20.5 16.25 20.5H7.75Z'
      />
    </SvgIcon>
  )
}

export const ShiftUpContainedIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M13.322 2.60299C13.1577 2.4136 12.9547 2.26171 12.7266 2.15761C12.4985 2.05352 12.2507 1.99965 12 1.99965C11.7493 1.99965 11.5015 2.05352 11.2734 2.15761C11.0453 2.26171 10.8423 2.4136 10.678 2.60299L2.433 12.107C1.449 13.24 2.254 15.004 3.754 15.004H7V17.25C7 18.216 7.784 19 8.75 19H15.25C15.7141 19 16.1593 18.8156 16.4874 18.4874C16.8156 18.1592 17 17.7141 17 17.25V15.004H20.245C21.745 15.004 22.55 13.24 21.567 12.107L13.322 2.60299ZM7.75 20.5C7.55109 20.5 7.36033 20.579 7.21967 20.7197C7.07902 20.8603 7 21.0511 7 21.25C7 21.4489 7.07902 21.6397 7.21967 21.7803C7.36033 21.921 7.55109 22 7.75 22H16.25C16.4489 22 16.6397 21.921 16.7803 21.7803C16.921 21.6397 17 21.4489 17 21.25C17 21.0511 16.921 20.8603 16.7803 20.7197C16.6397 20.579 16.4489 20.5 16.25 20.5H7.75Z'
      />
    </SvgIcon>
  )
}