import { Box, Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const DrawerWrapper = styled(Stack)`
  height: 100%;
  width: 400px;
  overflow: hidden;
`;

export const DrawerHeader = styled(Typography)`
  padding: 20px 0 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #d1d8fa;
`;

export const ButtonWrapper = styled(Stack)`
  position: sticky;
  bottom: 0;
  padding: 20px 0;
  background-color: ${(props) => props.theme.palette.bg.white};
`;
