import { Button, Stack } from '@mui/material'
import styled from 'styled-components'

interface IDocNavBarItemWrapperProps {
  isSelected?: boolean
}

export const DocNavBarItemWrapper = styled(Stack)<IDocNavBarItemWrapperProps>`
  padding: 10px;
  border: ${(props) =>
    `1px solid ${props.isSelected ? props.theme.palette.primary.light : props.theme.palette.bg.gray}`};
  border-radius: 8px;
  background-color: ${(props) => (props.isSelected ? props.theme.palette.bg.lightBlue : props.theme.palette.bg.white)};

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.light};
    cursor: pointer;
  }
`

export const DocNavBarItemAttachmentsButton = styled(Button)`
  gap: 4px;
  min-width: auto;
  height: 20px;
  padding: 0;

  &:hover {
    background-color: transparent;

    & .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.primary.main};
    }

    & .MuiTypography-root {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`
