import { FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ExportArchiveTomItemProps } from './types'
import { ExportArchiveTomItemButton, ExportArchiveTomItemWrapper } from './styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { theme } from '../../../../../../styles/theme'
import { useCheckboxChecked } from './hooks/useCheckboxChecked'
import { useGetAddHandler } from './hooks/useGetAddHandler'
import { useGetRemoveHandler } from './hooks/useGetRemoveHandler'


export const ExportArchiveTomItem: FC<ExportArchiveTomItemProps> = ({
  objectIndex,
  rdTom,
  pdTom,
  iiTom,
  irdTom,
  rdIndex,
  pdIndex,
  iiIndex,
  irdIndex,
  tomIndex,
}) => {
  const { title, status } = rdTom || pdTom || iiTom || irdTom || {}

  const { selected } = useCheckboxChecked({
    objectIndex,
    rdIndex,
    pdIndex,
    iiIndex,
    irdIndex,
    tomIndex,
  })

  const { onAddClick } = useGetAddHandler({
    rdTom,
    pdTom,
    iiTom,
    irdTom,
    objectIndex,
    rdIndex,
    pdIndex,
    iiIndex,
    irdIndex,
    tomIndex,
  })

  const { onRemoveClick } = useGetRemoveHandler({
    rdTom,
    pdTom,
    iiTom,
    irdTom,
    objectIndex,
    rdIndex,
    pdIndex,
    iiIndex,
    irdIndex,
    tomIndex,
  })

  const buttonPropsBySelectedFlag = {
    true: {
      onClick: onRemoveClick,
      startIcon: <RemoveIcon color='primary' />,
    },
    false: {
      onClick: onAddClick,
      startIcon: <AddIcon color='primary' />,
    },
  }

  return (
    <ExportArchiveTomItemWrapper direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <ExportArchiveTomItemButton
          {...buttonPropsBySelectedFlag[`${selected}`]}
          variant='text'
        >
          <Typography variant='subtitle2' component='span'>
            {title || ''}
          </Typography>
        </ExportArchiveTomItemButton>
      </Stack>

      <Box>
        <Typography
          variant='tooltip'
          textTransform='none'
          color={theme.palette.secondary.dark}
          component='p'
        >
          {status || ''}
        </Typography>
      </Box>
    </ExportArchiveTomItemWrapper>
  )
}
