import styled from 'styled-components'
import {
  ProjectFullExportFormWrapper,
  ProjectFullExportSwitchesWrapper
} from '../../../../../ProjectInfo/components/ProjectFullExportDrawer/components/ProjectFullExportForm'

export const FormPocketDrawerFormWrapper = styled(ProjectFullExportFormWrapper)`
`

export const FormPocketDrawerSwitchesWrapper = styled(ProjectFullExportSwitchesWrapper)`
`
