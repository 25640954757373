import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const InfoWrapper = styled(Box) <{ expired?: boolean }>`
  border-radius: 4px;
  padding: 4px;
  border-radius: ${props => props.expired && '20px'};
  padding: ${props => props.expired && '2px 6px'};

  background-color: ${props => props.theme.palette.bg.shades};
  background-color: ${props => props.expired && props.theme.palette.legends.red + '26'};

  .MuiTypography-root {
    color: ${props => props.theme.palette.secondary.dark};
    color: ${props => props.expired && props.theme.palette.error.main};
  }
`
export const BoxText = styled(Typography)`
  display: -webkit-box;
  overflow-wrap: anywhere;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-pack: center;
  -webkit-box-orient: vertical;
`