import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Checkbox, Stack, Typography } from '@mui/material'
import BoxIcon from 'assets/icons/BoxIcon'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetTimSideMenuQuery } from '../../../../api/tim'
import BookIcon from '../../../../assets/icons/BookIcon'
import EditDocIcon from '../../../../assets/icons/EditDocIcon'
import HomeIcon from '../../../../assets/icons/HomeIcon'
import ObjectsIcon from '../../../../assets/icons/ObjectsIcon'
import Button from '../../../../components/Button'
import Divider from '../../../../components/Divider'
import useQuery from '../../../../hooks/useQuery'
import { timFilterSelector } from '../../../../store/slices/tim/selectors/tim.selectors'
import { setTimFilter } from '../../../../store/slices/tim/tim'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { ScrollableContainer } from '../../../../styles/global/ScrollableContainer'
import { theme } from '../../../../styles/theme'
import { COMMON_DOCS_NUMBER } from '../../../../utils/constants'
import { ObjectAccordion } from '../../../Docs/components/ObjectAccordion/ObjectAccordion'
import { AccordionItem } from '../../../Docs/components/ObjectAccordion/ObjectAccordion.types'
import { NavbarItem } from '../../../Docs/hooks/useGetNavbarItems'
import {
  DrawerWrapper,
  ExpiredCheckboxControl,
  ModelsDesignLogo,
  ModelsDrawerHeader,
  ModelsLogoWrapper,
} from './ModelsFilterDrawer.styles'
import { ModelsFilterDrawerProps } from './ModelsFilterDrawer.types'

export const ModelsFilterDrawer = ({ statusIntegration }: ModelsFilterDrawerProps) => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const queryHandler = useQuery()
  const objectFromQuery = queryHandler.get('obj')

  const { data: sideMenuData } = useGetTimSideMenuQuery({ id: Number(projectId) })
  const { menuList } = sideMenuData || {}

  const { allTims, expiredFilter, objectFiltered, rdpFiltered, emptyFilter } = useTypedSelector(timFilterSelector)
  const [expanded, setExpanded] = useState<number | false>(rdpFiltered || false)
  const onExpand = useCallback((panelId: number, isExpanded: boolean) => {
    setExpanded(isExpanded ? false : panelId)
  }, [])

  const onObjectClick = (objectId: number) => {
    dispatch(
      setTimFilter({
        timFilter: { objectFiltered: objectId, rdpFiltered: undefined, expiredFilter, allTims: false, emptyFilter },
      }),
    )
    objectId && queryHandler.set('obj', objectId)
  }

  const onRdpClick = (objectId: number, rdpId: number) => {
    dispatch(
      setTimFilter({
        timFilter: { objectFiltered: objectId, rdpFiltered: rdpId, expiredFilter, allTims: false, emptyFilter },
      }),
    )
  }

  const onFilterExpiredChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setTimFilter({
        timFilter: {
          objectFiltered: allTims ? null : objectFiltered || Number(objectFromQuery),
          rdpFiltered,
          expiredFilter: e.target.checked,
          allTims,
          emptyFilter,
        },
      }),
    )
  }

  const onFilterEmptyChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setTimFilter({
        timFilter: {
          objectFiltered: allTims ? null : objectFiltered || Number(objectFromQuery),
          rdpFiltered,
          expiredFilter,
          allTims,
          emptyFilter: e.target.checked,
        },
      }),
    )
  }

  const onFilterAllModelsChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked && !objectFromQuery) return
    dispatch(
      setTimFilter({
        timFilter: {
          objectFiltered: e.target.checked ? null : Number(objectFromQuery),
          rdpFiltered: undefined,
          expiredFilter,
          allTims: e.target.checked,
          emptyFilter,
        },
      }),
    )
  }

  const onViewModelClick = () => {
    if (statusIntegration === 'ENABLED') {
      navigate('view')
    }
  }

  const navbarItems: NavbarItem[] = menuList?.length
    ? menuList.map((object) => {
        const item: AccordionItem = {
          id: object.obj.objId,
          title: `${object.obj.number} — ${object.obj.title}`,
          hasSubItems: object.rdpList.length > 0,
          isSelectedObject: object.obj.objId === objectFiltered,
          subItemsData: {
            onSubItemClick: (e, objectId: number, rdpId: number) => {
              e.stopPropagation()
              onRdpClick(objectId, rdpId)
            },
            icon: <EditDocIcon fontSize='small' color='dark' />,
            subItems: object.rdpList?.map((rdp) => ({
              id: rdp.rdpId,
              title: rdp.mark,
              isSelectedSubItem: rdp.rdpId === rdpFiltered && object.obj.objId === objectFiltered,
            })),
          },
        }

        const commonObject = object.obj.number === COMMON_DOCS_NUMBER

        return {
          item: item,
          id: item.id,
          onItemClick: onObjectClick,
          expanded: expanded,
          onExpand: onExpand,
          icon: commonObject ? (
            <BookIcon fontSize='small' color='primary' />
          ) : (
            <ObjectsIcon fontSize='small' color='primary' />
          ),
        }
      })
    : []

  const onObjectsButtonClick = () => {
    navigate(`/project/${projectId}/tim`)
  }

  useEffect(() => {
    if (allTims || objectFromQuery !== objectFiltered) {
      setExpanded(false)
    }
  }, [allTims, objectFiltered, objectFromQuery])

  return (
    <DrawerWrapper variant='permanent'>
      <ModelsDrawerHeader direction='row' justifyContent='space-between' spacing={1}>
        <Link to='/home'>
          <ModelsLogoWrapper>
            <ModelsDesignLogo />
          </ModelsLogoWrapper>
        </Link>
        <Stack direction='row' alignItems='center' spacing={1.25}>
          <Button icon bgColor={theme.palette.bg.shades} color='primary' variant='text' onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </Button>
          <Button
            icon
            bgColor={theme.palette.bg.shades}
            color='primary'
            variant='text'
            onClick={() => navigate(`/project/${projectId}`)}
          >
            <HomeIcon />
          </Button>
        </Stack>
      </ModelsDrawerHeader>
      <Stack padding='20px 0 20px 20px' height='calc(100% - 64px)'>
        <Stack spacing={2} pr={2.5} mb={2}>
          <Stack direction='row' spacing={1.5}>
            <Button
              startIcon={<ObjectsIcon />}
              variant='text'
              bgColor={theme.palette.bg.shades}
              width='100%'
              onClick={() => onObjectsButtonClick()}
              paddingSize='small'
              style={{ maxWidth: '100%' }}
            >
              Объекты
            </Button>
            <Button
              startIcon={<BoxIcon color={statusIntegration !== 'ENABLED' ? 'disabled' : 'primary'} />}
              variant='text'
              disabled={statusIntegration !== 'ENABLED'}
              bgColor={statusIntegration === 'ENABLED' ? theme.palette.bg.shades : theme.palette.text.dark + '1F'}
              width='100%'
              onClick={onViewModelClick}
              paddingSize='small'
              sx={{
                maxWidth: '100%',
                svg: { height: '24px', width: '24px' },
                '&.Mui-disabled': { color: theme.palette.disabled },
              }}
            >
              Вьювер
            </Button>
          </Stack>
          <ExpiredCheckboxControl
            control={
              <Checkbox
                checked={emptyFilter}
                onChange={onFilterEmptyChange}
                icon={emptyFilter ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              />
            }
            label={
              <Typography textTransform='uppercase' lineHeight='normal' fontWeight={500} fontSize={14}>
                Пустые модели
              </Typography>
            }
          />
          <ExpiredCheckboxControl
            control={
              <Checkbox
                checked={expiredFilter}
                onChange={onFilterExpiredChange}
                icon={expiredFilter ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              />
            }
            label={
              <Typography textTransform='uppercase' lineHeight='normal' fontWeight={500} fontSize={14}>
                Просрочено
              </Typography>
            }
          />
          <Divider />
          <Typography textTransform='uppercase' fontWeight={500} fontSize={14} mt={2.5} mb={1.25}>
            Фильтрация по объектам
          </Typography>
          <ExpiredCheckboxControl
            control={
              <Checkbox
                color='primary'
                checked={allTims}
                onChange={onFilterAllModelsChange}
                icon={allTims ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
              />
            }
            label={
              <Typography textTransform='uppercase' lineHeight='normal' fontWeight={500} fontSize={14}>
                Все объекты
              </Typography>
            }
          />
        </Stack>

        <ScrollableContainer spacing={1.5}>
          {navbarItems?.map((navbarItem) => {
            return <ObjectAccordion {...navbarItem} key={navbarItem.id} />
          })}
        </ScrollableContainer>
      </Stack>
    </DrawerWrapper>
  )
}
