import { api } from '../../api'
import {
  CreateRdRequest,
  DeleteRdRequest,
  EditRdRequest,
  GetAllRdRequest,
  GetAllRdResponse,
  GetRdByIdRequest,
  GetRdLinkExampleResponse,
  RdBaseResponse,
  UploadRdRequest,
  UploadRdResponse,
} from './rdApi.types'
import { NUMBER_OF_ROWS_PER_RD_PAGE } from '../../../utils/constants'

export const rdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllRd: build.query<GetAllRdResponse, GetAllRdRequest>({
      query: ({ limit, offset }) => ({
        url: '/rd/list',
        params: { limit, offset },
        method: 'GET',
      }),
      providesTags: (result) =>
        result?.data?.length
          ? [
            ...result.data.map(({ id }) => ({ type: 'Rd' as const, id })),
            { type: 'Rd', id: 'LIST' },
            { type: 'Rd', id: 'PARTIAL-LIST' },
          ]
          : [
            { type: 'Rd', id: 'LIST' },
            { type: 'Rd', id: 'PARTIAL-LIST' },
          ],
    }),
    createRd: build.mutation<RdBaseResponse, CreateRdRequest>({
      query: (body) => ({
        url: `/rd/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Rd', id: 'LIST' }],
    }),
    uploadRd: build.mutation<UploadRdResponse, UploadRdRequest>({
      query: ({ file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/rd/upload`,
          method: 'POST',
          body: formData,
        }
      },
    }),
    exportRd: build.mutation<Blob, void>({
      query: () => ({
        url: `/rd/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    getRdById: build.query<RdBaseResponse, GetRdByIdRequest>({
      query: ({ id }) => ({
        url: `/rd/${id}/get`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => {
        return [{ type: 'Rd', id: arg.id }]
      },
    }),
    editRd: build.mutation<RdBaseResponse, EditRdRequest>({
      query: ({ id, ...body }) => ({
        url: `/rd/${id}/update`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedRd } = await queryFulfilled

          dispatch(
            rdApi.util.updateQueryData('getAllRd', {
              limit: NUMBER_OF_ROWS_PER_RD_PAGE[0].value,
              offset: 0
            }, (draft) => {
              const changedRd = draft.data.findIndex(rd => rd.id === updatedRd.data.id)
              draft.data.splice(changedRd, 1, updatedRd.data)
            })
          )

          dispatch(
            rdApi.util.updateQueryData('getRdById', { id }, (draft) => {
              Object.assign(draft, updatedRd)
            })
          )
        } catch {
        }
      },
      // invalidatesTags: (result, error, body) => {
      //   return [{ type: 'Rd', id: body.id }]
      // },
      // invalidatesTags: ['Rd']
    }),
    deleteRd: build.mutation<RdBaseResponse, DeleteRdRequest>({
      query: ({ id }) => ({
        url: `/rd/${id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Rd', id: 'PARTIAL-LIST' }],
    }),
    getRdLinkExample: build.mutation<GetRdLinkExampleResponse, void>({
      query: () => ({
        url: `/rd/link-example`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllRdQuery,
  useCreateRdMutation,
  useUploadRdMutation,
  useExportRdMutation,
  useGetRdByIdQuery,
  useEditRdMutation,
  useDeleteRdMutation,
  useGetRdLinkExampleMutation,
} = rdApi
