import { RootState } from '../../../../store'
import { DetailedSidebarItemsSelector, SidebarItemsSelector } from './sidebar.selectors.types'

export const sidebarItemsSelector = (state: RootState): SidebarItemsSelector => ({
  sidebarObjects: state.sidebar.sidebarObjects,
  sidebarPds: state.sidebar.sidebarPds,
  sidebarIis: state.sidebar.sidebarIis,
  sidebarIrds: state.sidebar.sidebarIrds,
})

export const detailedSidebarItemsSelector = (state: RootState): DetailedSidebarItemsSelector => ({
  detailedSidebarObjects: state.sidebar.detailedSidebarObjects,
  detailedSidebarPds: state.sidebar.detailedSidebarPds,
  detailedSidebarIis: state.sidebar.detailedSidebarIis,
  detailedSidebarIrds: state.sidebar.detailedSidebarIrds,
})
