import React, { useEffect, useMemo, useRef } from 'react'
import { UseFiltersResponse } from './useFilters.types'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import _ from 'lodash'
import {
  FilterData,
  filterDataSelector,
  setAvailableRdsIds,
  setFilterData,
  setIsFilter
} from '../../../../store/slices/documentsPages/filter'
import { sidebarItemsSelector } from '../../../../store/slices/documentsPages/sidebar'
import { getRdFromObjects } from '../../../../store/slices/documentsPages/tom/tom.utils'

export const useFilters = (): UseFiltersResponse => {
  const dispatch = useAppDispatch()

  const { sidebarObjects, sidebarPds, sidebarIis, sidebarIrds } = useTypedSelector(sidebarItemsSelector, _.isEqual)
  const { filterData } = useTypedSelector(filterDataSelector, _.isEqual)

  // set available rds ids on sidebarObjects fetch
  useEffect(() => {
    if (sidebarObjects?.length) {
      dispatch(setAvailableRdsIds({
        selectedObjectsIds: filterData.selectedObjectsIds,
        allSidebarObjects: sidebarObjects
      }))
    }
  }, [dispatch, filterData.selectedObjectsIds, sidebarObjects])


  // Common

  // set current filters and selected phase on doc change (route change)
  useEffect(() => {
    const storageData: FilterData = JSON.parse(localStorage.getItem('docsFilterData') || JSON.stringify({
      selectedObjectsIds: [],
      selectedRdsIds: [],
      selectedPdsIds: [],
      selectedIisIds: [],
      selectedIrdsIds: [],
      selectedDocs: [],
      isExpired: false,
      iiType: 'Технический отчет',
    } as FilterData))

    storageData.iiType = 'Технический отчет'

    dispatch(setFilterData({
      filterData: storageData,
    }))
  }, [dispatch])

  // write filter data to localStorage
  useEffect(() => {
    dispatch(setIsFilter({
      isFilter: !!(
        filterData?.selectedObjectsIds?.length
        || filterData?.selectedRdsIds?.length
        || filterData?.selectedPdsIds?.length
        || filterData?.selectedIisIds?.length
        || filterData?.selectedIrdsIds?.length
        || filterData?.selectedDocs?.length
      ),
    }))
  }, [dispatch, filterData])

  const filterItems = useMemo(() => {
    return {
      allObjectsItems: sidebarObjects?.map(item => ({ value: item.obj.objID, title: item.obj.title })),
      allPdsItems: sidebarPds?.map(item => ({ value: item.id, title: item.mark })),
      allIisItems: sidebarIis?.map(item => ({ value: item.id, title: item.mark })),
      allIrdsItems: sidebarIrds?.map(item => ({ value: item.id, title: item.mark })),
      availableRdsItems: getRdFromObjects(sidebarObjects),
    }
  }, [sidebarObjects, sidebarPds, sidebarIis, sidebarIrds])

  return {
    filterItems,
  }
}
