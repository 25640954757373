import { Button, Switch, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  border-color: ${(props) => props.theme.palette.primaryBorderLight};
  min-width: 40px;

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`

export const StyledSwitch = styled(Switch)`
  & .MuiSwitch-track {
    background-color: ${(props) => props.theme.palette.text.dark + '1F'};
    opacity: 1;
  }

  & .MuiSwitch-thumb {
    background-color: ${(props) => props.theme.palette.disabled};
  }

  & .MuiSwitch-switchBase.Mui-checked {
    & .MuiSwitch-thumb {
      background-color: ${(props) => props.theme.palette.primary.main};
    }
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${(props) => props.theme.palette.legends.gray};
    opacity: 1;
  }
`
export const TextLegend = styled(Typography)`
  color: ${(props) => props.theme.palette.text.dark};
`
