import { Avatar, Box, Stack, TextField, Typography } from "@mui/material"
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import EditIcon from '@mui/icons-material/Edit';
import { DrawerTextBlock, DrawerTextField, SchemeSelectionWrapper, StyledAutocomplete, StyledButton, StyledIconButton, StyledText, WatchDurationField, WatchDurationText } from "./SchemeSelection.styles"
import { useParams } from "react-router-dom"
import { useCallback, useEffect, useMemo, useState } from "react"
import icon from '../../../../../../assets/empty-project-preview.png'
import { InnermapOfCompany, InnermapAutocomplete, SchemeSelectionProps } from "./SchemeSelection.types"
import { useGetInnermapsMenuQuery, useGetInnermapsOfCompanyQuery } from "../../../../../../api/internalAgreement"
import { theme } from "../../../../../../styles/theme"
import { useAppDispatch, useTypedSelector } from "../../../../../../store/store"
import { profileSelector } from "../../../../../../store/slices/profile"
import { currentSchemaSelector, setCurrentSchema, setInitialSchemaCompanyFromMenu, setSchemaFormMode } from "../../../../../../store/slices/internalAgreement"
import Tooltip from "../../../../../../components/Tooltip"
import { Innermap, MenuInnermap } from "../../../../../../api/internalAgreement/types";
import { setStageCompanySelector } from "../../../../../../store/slices/agreement";
import { useAccess } from "../../../../../Schemes/InternalAgreementSchemes";

export const SchemeSelection = ({ isSchemaBinding, onAddSchemaClick }: SchemeSelectionProps) => {

  const dispatch = useAppDispatch()
  const { projectId: projectIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)
  const profile = useTypedSelector(profileSelector)
  const { isAdmin, isModerator } = useAccess()

  const currentSchema = useTypedSelector(currentSchemaSelector)
  const stageCompany = useTypedSelector(setStageCompanySelector)
  const unitValue = currentSchema?.unit === 'hour' ? 'ч' : 'дн'
  const isSelectedSchema = useMemo((): number | undefined => currentSchema?.id, [currentSchema])

  const { data: innermapsOfCompanyMenuData } = useGetInnermapsOfCompanyQuery(
    { projectId, company: stageCompany },
    { skip: !stageCompany || !isSchemaBinding }
  )
  const { data: innermapsMenuData } = useGetInnermapsMenuQuery({ id: projectId })

  const menuInnermap = useMemo((): MenuInnermap[] => {
    if (isSchemaBinding) {
      if (isAdmin) {
        return innermapsOfCompanyMenuData ? [innermapsOfCompanyMenuData] : []
      } else {
        const isValidInnermaps = innermapsOfCompanyMenuData?.innermaps.filter(innermap => !innermap.isBroken)
        return innermapsOfCompanyMenuData && isValidInnermaps ? [{
          company: innermapsOfCompanyMenuData.company,
          innermaps: isValidInnermaps
        }] : []
      }
    }
    return innermapsMenuData?.data ? innermapsMenuData.data : []

  }, [innermapsMenuData?.data, innermapsOfCompanyMenuData, isAdmin, isSchemaBinding])

  const companies = useMemo(() => {
    return menuInnermap.map(data => ({
      name: data.company,
      avatar: icon,
      innermaps: `Схем согласования - ${data.innermaps.length}`
    }))
  }, [menuInnermap])

  const foundUserCompany = useMemo(() => {
    if (companies && profile) {
      return companies.find(company => company.name === profile.company.userCompanyName)?.name
    }
  }, [profile, companies])

  const [innermapValue, setInnermapValue] = useState<number | undefined>(currentSchema?.id)
  const [companyValue, setCompanyValue] = useState<string>(
    isSelectedSchema ? currentSchema.company : foundUserCompany || profile.company.userCompanyName
  )

  useEffect(() => {
    if (isSchemaBinding && menuInnermap.length > 0) {
      setCompanyValue(menuInnermap[0].company)
    }
    if (isSchemaBinding && menuInnermap.length === 0) {
      setCompanyValue(stageCompany)
    }
  }, [isSchemaBinding, menuInnermap, stageCompany])

  const schemes = useMemo((): InnermapOfCompany[] => {
    const filteredSchemes = menuInnermap.find((innermap) =>
      innermap.company === companyValue)
    if (filteredSchemes) {
      return filteredSchemes.innermaps.map((innermap) => ({
        title: innermap.title,
        id: innermap.id,
        duration: innermap.duration,
        unit: innermap.unit === 'hour' ? 'ч.' : 'дн.',
        moderator: innermap.moderator.name,
        company: innermap.company
      }))
    } else {
      const innermapsArray: InnermapOfCompany[] = []

      menuInnermap.forEach(menuInnermap => {
        menuInnermap.innermaps.forEach(innermap => {
          const schema = {
            title: innermap.title,
            id: innermap.id,
            duration: innermap.duration,
            unit: innermap.unit === 'hour' ? 'ч.' : 'дн.',
            moderator: innermap.moderator.name,
            company: innermap.company
          }
          innermapsArray.push(schema)
        })
      })
      return innermapsArray
    }

  }, [menuInnermap, companyValue])

  useEffect(() => {
    if (innermapValue && schemes.length > 0) {
      const innermapOfCompany = schemes.find((schema) => schema.id === innermapValue)
      if (!innermapOfCompany) {
        setInnermapValue(undefined)
      }
    }
  }, [companyValue, innermapValue, schemes])

  useEffect(() => {
    if (innermapValue) {
      const companyOfInnermap = schemes.find((schema) => schema.id === innermapValue)?.company
      if (companyOfInnermap) {
        setCompanyValue(companyOfInnermap)
      }
    } else {
      dispatch(setCurrentSchema({} as Innermap))
    }

  }, [innermapValue, schemes])

  useEffect(() => {
    if (companyValue && innermapValue && menuInnermap) {
      const foundInnermap = menuInnermap
        .flatMap(menuInnermap => menuInnermap.innermaps)
        .find(innermap => innermap.id === innermapValue)

      if (foundInnermap) {
        dispatch(setCurrentSchema(foundInnermap))
      }
    }
  }, [companyValue, dispatch, innermapValue, menuInnermap])

  const addButton = useCallback((): InnermapAutocomplete[] => {
    if (isSchemaBinding && !isAdmin) {
      return schemes
    }
    return [{ type: 'element', title: 'KEYElement', id: 0 }, ...schemes]
  }, [isAdmin, isSchemaBinding, profile.company.userCompanyName, schemes, stageCompany])

  const onFormModeEditClick = () => dispatch(setSchemaFormMode({ schemaFormMode: 'edit' }))


  const handleAddSchemaClick = () => {
    /*     isAdmin
          ? dispatch(setInitialSchemaCompanyFromMenu({
            initialSchemaCompanyFromMenu: companyValue
          }))
          : dispatch(setInitialSchemaCompanyFromMenu({
            initialSchemaCompanyFromMenu: foundUserCompany || profile.company.userCompanyName
          })) */
    dispatch(setInitialSchemaCompanyFromMenu({
      initialSchemaCompanyFromMenu: companyValue
    }))
    onAddSchemaClick()
  }

  return (
    <SchemeSelectionWrapper spacing={isSelectedSchema ? 1 : '20px'} direction='row'>
      {menuInnermap && menuInnermap.length > 1
        ? (<StyledAutocomplete
          $width={isSelectedSchema ? '211px' : 'none'}
          blurOnSelect
          clearOnBlur
          handleHomeEndKeys
          noOptionsText='Ничего не найдено'
          clearText='Удалить'
          openText="Выбрать"
          //isOptionEqualToValue={(option: any) => option.label === option.value}
          renderOption={(props, option: any) => (
            <Box component="li" {...props} key={option.key}>
              {!isSelectedSchema && (
                <Avatar src={option.avatar} alt={option.name} sx={{ width: "32px", height: "32px", marginRight: "16px" }} />
              )}
              <Stack spacing={0}>
                <Typography variant="body2" color={theme.palette.text.dark}>{option.label}</Typography>
                <Typography variant="caption" color={theme.palette.secondary.dark}>{option.innermaps}</Typography>
              </Stack>
            </Box>
          )}
          value={companyValue}
          onChange={(e, newValue: any) => setCompanyValue(newValue?.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Выберите компанию"
            />
          )}
          options={companies?.map(({ name, avatar, innermaps }) => ({
            value: name,
            label: name,
            key: name,
            avatar,
            innermaps
          })) || []}
        />
        ) : (
          <DrawerTextBlock direction='row' spacing={1} $width={isSelectedSchema ? '211px' : 'none'}>
            <Typography color={theme.palette.secondary.gray} variant='body2'>Компания:</Typography>
            <DrawerTextField variant='body2'>{companyValue}</DrawerTextField>
          </DrawerTextBlock>
        )}
      <StyledAutocomplete
        $width={isSelectedSchema ? '211px' : 'none'}
        blurOnSelect
        clearOnBlur
        handleHomeEndKeys
        noOptionsText='Ничего не найдено'
        clearText='Удалить'
        openText="Выбрать"
        renderOption={
          (props, option: any) => (
            <>
              {option.type === 'element' ? (
                <StyledButton $selectedSchema={!!isSelectedSchema} onClick={handleAddSchemaClick}>Новая схема согласования</StyledButton>
              ) : (
                <Stack component="li" {...props}>
                  <Typography variant="body2" color={theme.palette.text.dark} alignSelf='flex-start'>{option.label}</Typography>
                  <Typography variant="caption" color={theme.palette.secondary.dark} alignSelf='flex-start'>
                    {option.duration ? `Срок: ${option.duration} ${option.unit},` : ''} {option.moderator}
                  </Typography>
                </Stack>)}
            </>
          )
        }
        value={schemes.find(item => item.id === innermapValue)?.title || ''}
        onChange={(e, newValue: any) => setInnermapValue(newValue?.value)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Выберите схему"
          />
        )}
        options={addButton().map(({ duration, moderator, title, unit, type, id }) => ({
          value: type === 'element' ? '' : id,
          label: type === 'element' ? '' : title,
          key: id,
          duration,
          moderator,
          unit,
          type
        })) || []}

      />
      {isSelectedSchema && (
        <>
          {currentSchema?.duration && (
            <WatchDurationField isBroken={false} direction='row'>
              <WatchLaterIcon fontSize='small' className='clockIcon' />
              <WatchDurationText variant='body2'>
                {currentSchema.duration || 0} {unitValue}
              </WatchDurationText>
            </WatchDurationField>
          )}

          < Stack direction='row' alignItems='center' spacing={1}>
            <Avatar src={currentSchema.moderator.avatar} sx={{ width: "24px", height: "24px" }} />
            <StyledText variant='body2'>{currentSchema.moderator.name}</StyledText>
          </Stack>

          {(isAdmin || isModerator) && (
            <Tooltip title={<>Редактировать схему согласования</>}>
              <StyledIconButton onClick={onFormModeEditClick}>
                <EditIcon fontSize="small" />
              </StyledIconButton>
            </Tooltip>
          )}
        </>
      )}

    </SchemeSelectionWrapper >

  )
}