import * as React from 'react'
import { useRef, useState } from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { CompanyWrapper, StyledMenu, UserData, UserInfo, UserMenuButton, UserName } from './styles'
import Button from '../../Button'
import { UserMenuProps } from './UserMenu.types'
import { useAppDispatch } from '../../../store/store'
import { onLogout } from '../../../store/slices/auth'


const UserMenu: React.FC<UserMenuProps> = ({ profile, onChangeModule }) => {
  const dispatch = useAppDispatch()

  const { firstName, middleName, lastName, avatarPreview, company } = profile
  const { companyName, userPosition, userCompanyName } = company

  const [open, setOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const logoutHandler = () => {
    dispatch(onLogout())
  }

  const fullName = <>{lastName}<br />{firstName} {middleName}</>
  const partedName = <>{lastName} {firstName}</>
  const firstLetter = lastName?.[0] || ''

  return (
    <Box>
      <UserMenuButton
        startIcon={<Avatar src={avatarPreview}>{firstLetter}</Avatar>}
        onClick={handleToggle}
        endIcon={open ? <KeyboardArrowUpIcon fontSize='medium' /> : <KeyboardArrowDownIcon fontSize='medium' />}
        ref={anchorRef}
        variant='text'
      >
        <Typography variant='subtitle1' component='span'>{partedName}</Typography>
      </UserMenuButton>
      <StyledMenu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <CompanyWrapper>{companyName}</CompanyWrapper>

        <UserInfo>
          <Avatar src={avatarPreview}>{firstLetter}</Avatar>

          <UserData>
            <UserName>{fullName}</UserName>
            <div>{userCompanyName}</div>
            <div>{userPosition}</div>
          </UserData>
        </UserInfo>

        <Button onClick={onChangeModule} width='100%' style={{ maxWidth: 'none' }}>
          К выбору модуля
        </Button>
        <Button onClick={() => logoutHandler()} width='100%' style={{ maxWidth: 'none' }}>
          Выйти из системы
        </Button>
      </StyledMenu>
    </Box>
  )
}

export default UserMenu
