import styled from 'styled-components'
import { Button, Stack } from '@mui/material'

export const InfoBlockWrapper = styled(Stack)`
  gap: 4px;
  padding: 12px 20px;
  border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 20px;
`

export const InfoCardButton = styled(Button)`
  justify-content: start;
  height: 28px;
  padding: 5px 6px;
  color: ${props => props.theme.palette.text.dark};
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  text-transform: none;

  &.Mui-disabled {
    & .MuiButton-startIcon {

      & .MuiTypography-root {
        color: ${props => props.theme.palette.disabled};
      }
    }
  }

  & .MuiButton-endIcon {
    margin-left: auto;

    & .MuiTypography-root {
      font-weight: 500;
      font-size: 14px;
      color: ${props => props.theme.palette.text.dark};
    }
  }

  &:hover {
    color: ${props => props.theme.palette.primary.main};
    background-color: transparent;

    & .MuiTypography-root {
      color: ${props => props.theme.palette.primary.main};
    }

    & .MuiButton-endIcon {
      opacity: 1;
    }
  }
`
export const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
`