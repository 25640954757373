import { useGetAllIiQuery } from '../../../../api/iiPhase'
import { useGetAllIrdQuery } from '../../../../api/irdPhase'
import { useGetAllPpdQuery } from '../../../../api/pdPhase'
import { useGetObjectsByProjectIdQuery } from '../../../../api/rdPhase'
import { useGetAllRdpQuery } from '../../../../api/rdPhase/rdp'
import ObjectsIcon from '../../../../assets/icons/ObjectsIcon'
import RdIcon from '../../../../assets/icons/RdIcon'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector,
} from '../../../../store/slices/documentsPages/projectInfo'
import { useTypedSelector } from '../../../../store/store'
import { ProjectPhase } from '../../../../types/project'
import { TomSelectData, UseGetTomsSelectDataProps, UseGetTomsSelectDataResponse } from './useGetTomsSelectData.types'

export const useGetTomsSelectData = ({
  otherPhase,
  otherProjectID,
  permanentAutocompleteData,
}: UseGetTomsSelectDataProps): UseGetTomsSelectDataResponse => {
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  const projectPhase = otherPhase || selectedProjectPhase
  const projectId = otherProjectID || project.id
  const { isPermanentObjectId, isPermanentPdId, isPermanentIiId, isPermanentIrdId } = permanentAutocompleteData || {}

  // Rd
  const { data: objectsData } = useGetObjectsByProjectIdQuery({ id: projectId })
  const { data: objects } = objectsData || {}

  const { data: rdData } = useGetAllRdpQuery({ id: projectId })
  const { data: rdArray } = rdData || {}

  const rdSelectsData: TomSelectData[] = [
    {
      fieldName: 'objectId',
      title: 'Объект',
      placeholder: 'Выберите объект',
      icon: ObjectsIcon,
      data:
        objects?.map(({ id, title }) => ({
          value: id,
          label: title,
          key: id,
        })) || [],
      permanentValue: isPermanentObjectId,
    },
    {
      fieldName: 'rdId',
      title: 'Раздел РД',
      placeholder: 'Выберите раздел РД',
      icon: RdIcon,
      data:
        rdArray?.map(({ id, mark }) => ({
          value: id,
          label: mark,
          key: id,
        })) || [],
    },
  ]

  // Pd
  const { data: pdData } = useGetAllPpdQuery({ id: projectId })
  const { data: pdArray } = pdData || {}

  const pdSelectsData: TomSelectData[] = [
    {
      fieldName: 'pdId',
      title: 'ПД',
      placeholder: 'Выберите раздел ПД',
      icon: RdIcon,
      data:
        pdArray?.map(({ id, mark }) => ({
          value: id,
          label: mark,
          key: id,
        })) || [],
      permanentValue: isPermanentPdId,
    },
  ]

  // Ii
  const { data: iiData } = useGetAllIiQuery({ id: projectId })
  const { data: iiArray } = iiData || {}

  const iiSelectsData: TomSelectData[] = [
    {
      fieldName: 'iiId',
      title: 'Раздел',
      placeholder: 'Выберите раздел',
      icon: RdIcon,
      data:
        iiArray?.map(({ id, mark }) => ({
          value: id,
          label: mark,
          key: id,
        })) || [],
      permanentValue: isPermanentIiId,
    },
  ]

  // Ird
  const { data: irdData } = useGetAllIrdQuery({ id: projectId })
  const { data: irdArray } = irdData || {}

  const irdSelectsData: TomSelectData[] = [
    {
      fieldName: 'irdId',
      title: 'Раздел',
      placeholder: 'Выберите раздел',
      icon: RdIcon,
      data:
        irdArray?.map(({ id, mark }) => ({
          value: id,
          label: mark,
          key: id,
        })) || [],
      permanentValue: isPermanentIrdId,
    },
  ]

  // Common
  const tomSelectsData: Record<ProjectPhase, TomSelectData[]> = {
    'Рабочая документация': rdSelectsData,
    'Проектная документация': pdSelectsData,
    'Инженерные изыскания': iiSelectsData,
    'Сбор исходных данных': irdSelectsData,
  }

  return {
    tomSelectsData: tomSelectsData[projectPhase],
  }
}
