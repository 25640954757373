import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import { StyledAgGridWrapper } from '../../../../styles/global/StyledAgGridWrapper'
import { AgGridModelsProps, AuthorParams, ModelRowData, VersionRowData } from './AgGridModels.types'
import { useCallback, useMemo, useState } from 'react'
import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  ColumnResizedEvent,
  GridReadyEvent,
  ICellRendererParams,
  RowClassParams,
  RowDataUpdatedEvent,
} from 'ag-grid-community'
import Progress from '../../../../components/Progress'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import { ElipsisText } from '../../../../styles/global/ElipsisText'
import { InfoWrapper } from './AgGridModels.styles'
import ColoredTitle from '../../../../components/ColoredTitle'
import { timStatusesColors, TimStatusView } from '../../../../types/models'
import { StyledData } from '../ModelCard'
import { compareAsc, startOfDay } from 'date-fns'
import { formatToDate } from '../../../../utils/formatDate'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { allowScrollingSelector } from '../../../../store/slices/remarks/selectors/remarks.selectors'
import { setAllowScrolling } from '../../../../store/slices/remarks/remarks'
import { ShifrNameCell } from '../ShifrNameCell/ShifrNameCell'
import { convertDateTime } from '../../../../utils/formatDateAndTimeGMT'

const AuthorCell = (params: ICellRendererParams) => {
  const { createdAt, name, avatar, company }: AuthorParams = params.value

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} px={2} width='100%'>
      <Stack direction='row' spacing={1.5} alignItems='center' sx={{ width: 'calc(100% - 180px)' }}>
        <Avatar src={avatar || undefined} sx={{ height: 32, width: 32 }} />
        <Box textAlign='start' width='calc(100% - 44px)'>
          <ElipsisText fontSize={14}>{company}</ElipsisText>
          <Typography variant='body1' fontSize={14}>
            {name}
          </Typography>
        </Box>
      </Stack>
      <InfoWrapper>
        <Typography variant='body2' fontSize={12}>{`Дата создания: ${createdAt}`}</Typography>
      </InfoWrapper>
    </Stack>
  )
}

const StatusCell = (params: ICellRendererParams) => {
  const status: TimStatusView = params.value
  return status ? (
    <ColoredTitle
      body={status}
      color={timStatusesColors[status]}
      style={{ width: '132px', justifyContent: 'center' }}
    />
  ) : (
    '—'
  )
}

export const AgGridModels = ({ models, onUpdateData }: AgGridModelsProps) => {
  const dispatch = useAppDispatch()
  const isSavedColumnState = !!localStorage.getItem('modelsColumnState')
  const flexCell = 'ag-cell_flex ag-cell_justify-content_center ag-cell_align-items_center'
  const { allowScrolling, mode, id: updatedId } = useTypedSelector(allowScrollingSelector)
  const rowClassRules = {
    'ag-row_style_shades': (params: RowClassParams) => {
      return !params.data?.isVersion
    },
  }

  const [timVersionsIds, setTimVersionsIds] = useState<number[]>([])

  const collapse = useCallback((timId: number) => {
    setTimVersionsIds((idsArray) => {
      if (idsArray.includes(timId)) {
        return idsArray.filter((id) => id !== timId)
      } else {
        return [...idsArray, timId]
      }
    })
  }, [])

  const columnDef = useMemo(
    (): (ColDef | ColGroupDef)[] => [
      {
        field: 'title',
        headerName: 'Шифр модели',
        flex: !isSavedColumnState ? 4 : undefined,
        width: isSavedColumnState ? JSON.parse(localStorage.getItem('modelsColumnState')!)[0].width : 275,
        minWidth: 275,
        cellClass: ['ag-cell_flex, ag-cell_overflow_visible'],
        cellRenderer: (params: ICellRendererParams<ModelRowData>) => (
          <ShifrNameCell {...params} collapse={collapse} timVersionsIds={timVersionsIds} />
        ),
      },
      {
        field: 'authorId',
        headerName: 'Автор',
        flex: !isSavedColumnState ? 5 : undefined,
        minWidth: 425,
        cellClass: ['ag-cell_flex ag-cell_align-items_center'],
        cellRenderer: AuthorCell,
      },
      {
        headerName: 'Даты согласования',
        flex: !isSavedColumnState ? 2 : undefined,
        minWidth: 200,
        marryChildren: true,
        children: [
          {
            field: 'plannedDate',
            headerName: 'Плановая',
            flex: !isSavedColumnState ? 1 : undefined,
            minWidth: 100,
            cellClass: [flexCell, 'font-size_14'],
            cellRenderer: (params: ICellRendererParams) => {
              const plannedDate = params.value
              const hasNoActualDate = params.data.actualDate === '—'
              const durationDateExpired =
                !!plannedDate &&
                hasNoActualDate &&
                compareAsc(formatToDate(plannedDate)!, startOfDay(new Date())) === -1
              return plannedDate ? (
                <StyledData expired={durationDateExpired} variant='subtitle2' fontWeight={400}>
                  {params.value}
                </StyledData>
              ) : (
                '—'
              )
            },
          },
          {
            field: 'actualDate',
            headerName: 'Фактическая',
            flex: !isSavedColumnState ? 1 : undefined,
            minWidth: 100,
            cellClass: [flexCell, 'font-size_14'],
            valueGetter: (params) => params.data.actualDate || '—',
          },
        ],
      },
      {
        field: 'status',
        headerName: 'Статус',
        flex: !isSavedColumnState ? 2 : undefined,
        minWidth: 145,
        cellRenderer: StatusCell,
        cellClass: [flexCell],
      },
    ],
    [collapse, isSavedColumnState, timVersionsIds],
  )

  const rowData = useMemo((): (ModelRowData | VersionRowData)[] | undefined => {
    if (!models) return undefined

    const data: (ModelRowData | VersionRowData)[] = models.flatMap((model) => {
      const mainRow: ModelRowData = {
        title: {
          title: model.tim.title,
          versions: model.versions,
          timId: model.tim.id,
        },
        authorId: {
          avatar: model.tim.authorId.avatar,
          name: model.tim.authorId.name,
          company: model.tim.authorId.company || '—',
          createdAt: model.tim.createdAt,
        },
        plannedDate: model.tim.plannedDate || '—',
        actualDate: model.tim.actualDate || '—',
        status: model.tim.status || ('Не установлен' as TimStatusView),
        timId: model.tim.id,
      }

      if (timVersionsIds.includes(model.tim.id)) {
        const versionRows: VersionRowData[] = model.versions.map((version) => ({
          title: `Версия: ${version.version}`,
          extension: version.extension,
          authorId: {
            avatar: version.createdBy.avatar,
            createdAt: convertDateTime(version.createdAt, false, false),
            name: version.createdBy.name,
            company: version.createdBy.company,
          },
          timId: version.timID,
          isVersion: true,
          status: version.tanglBindStatus,
        }))
        return [mainRow, ...versionRows]
      }
      return [mainRow]
    })

    return data
  }, [timVersionsIds, models])

  const gridReadyListener = useCallback((e: GridReadyEvent) => {
    const savedColumnState = localStorage.getItem('modelsColumnState')
    e.api.showLoadingOverlay()
    if (savedColumnState) {
      e.columnApi.applyColumnState({
        state: JSON.parse(savedColumnState),
        applyOrder: true,
      })
    }
    setTimeout(() => {
      e.api.hideOverlay()
    }, 250)
  }, [])

  const columnResizedListener = useCallback((e: ColumnResizedEvent) => {
    const columnState = e.columnApi.getColumnState()
    const withoutSortState = columnState.map(({ sort, ...state }) => state)
    if (e.finished) {
      localStorage.setItem('modelsColumnState', JSON.stringify(withoutSortState))
    }
  }, [])

  const cellClickedListener = (e: CellClickedEvent<ModelRowData>) => {
    const timId = e.data?.timId
    timId && onUpdateData(timId)
  }

  const onRowDataUpdatedListener = (e: RowDataUpdatedEvent) => {
    const lastRenderedRow = e.api.getDisplayedRowCount()
    const createdRowNode = e.api.getDisplayedRowAtIndex(lastRenderedRow - 1)!

    if (lastRenderedRow && allowScrolling) {
      e.api.showLoadingOverlay()
      setTimeout(() => {
        if (mode === 'edit') {
          const updatedRow = models.findIndex((model) => model.tim.id === updatedId)
          const editedCell = updatedRow === 0 ? 1 : updatedRow
          const editedRowNode = e.api.getDisplayedRowAtIndex(updatedRow)!
          e.api.ensureIndexVisible(editedCell, 'middle')
          e.api.flashCells({ rowNodes: [editedRowNode], flashDelay: 3000, fadeDelay: 1000 })
        } else {
          e.api.ensureIndexVisible(lastRenderedRow - 1, 'bottom')
          e.api.flashCells({ rowNodes: [createdRowNode], flashDelay: 3000, fadeDelay: 1000 })
        }
      }, 250)
      setTimeout(() => {
        e.api.hideOverlay()
        dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: false, mode: 'create', id: null } }))
      }, 500)
    }
  }

  return (
    <StyledAgGridWrapper style={{ marginTop: '10px' }}>
      <div className='ag-theme-alpine'>
        <AgGridReact
          onCellClicked={cellClickedListener}
          onGridReady={gridReadyListener}
          onColumnResized={columnResizedListener}
          onRowDataUpdated={onRowDataUpdatedListener}
          loadingOverlayComponent={Progress}
          getRowId={(params) => `${params.data.timId}-${params.data.title.title || params.data.title}`}
          columnDefs={columnDef}
          rowData={rowData}
          headerHeight={32}
          detailRowAutoHeight
          rowHeight={58}
          rowClassRules={rowClassRules}
          suppressRowTransform={true}
          suppressDragLeaveHidesColumns={true}
          overlayNoRowsTemplate='Нет данных для отображения'
          className='scroll_shades'
          defaultColDef={{
            cellDataType: false,
            editable: false,
            sortable: false,
            filter: false,
            resizable: true,
          }}
        />
      </div>
    </StyledAgGridWrapper>
  )
}
