import { api } from '../../api'
import {
  CreateRdpRequest,
  DeleteRdpRequest,
  EditRdpRequest,
  ExportRdpRequest,
  GetAllRdpRequest,
  GetAllRdpResponse,
  GetRdpByIdRequest,
  GetRdpLinkExampleRequest,
  GetRdpLinkExampleResponse,
  RdpBaseResponse,
  UploadRdpRequest,
  UploadRdpResponse
} from './rdpApi.types'

export const rdpApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllRdp: build.query<GetAllRdpResponse, GetAllRdpRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/rdp/list`,
        params,
        method: 'GET',
      }),
      providesTags: (result) =>
        result?.data?.length
          ? [
            ...result.data.map(({ id }) => ({ type: 'Rdp' as const, id })),
            { type: 'Rdp', id: 'LIST' },
            { type: 'Rdp', id: 'PARTIAL-LIST' },
          ]
          : [
            { type: 'Rdp', id: 'LIST' },
            { type: 'Rdp', id: 'PARTIAL-LIST' },
          ],
    }),
    createRdp: build.mutation<RdpBaseResponse, CreateRdpRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/rdp/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Rdp', id: 'LIST' }, { type: 'Tim', id: 'SIDE_MENU' }],
    }),
    uploadRdp: build.mutation<UploadRdpResponse, UploadRdpRequest>({
      query: ({ id, file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/project/${id}/rdp/upload`,
          method: 'POST',
          body: formData,
        }
      },
    }),
    exportRdp: build.mutation<Blob, ExportRdpRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/rdp/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    getRdpById: build.query<RdpBaseResponse, GetRdpByIdRequest>({
      query: ({ id, rdpId }) => ({
        url: `/project/${id}/rdp/${rdpId}/get`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => {
        return [{ type: 'Rdp', id: arg.id }]
      },
    }),
    editRdp: build.mutation<RdpBaseResponse, EditRdpRequest>({
      query: ({ id, rdpId, ...body }) => ({
        url: `/project/${id}/rdp/${rdpId}/update`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            rdpApi.util.invalidateTags(['Rdp'])
          )

        } catch {
        }
      },
      invalidatesTags: [{ type: 'Tim', id: 'SIDE_MENU' }]
    }),
    deleteRdp: build.mutation<RdpBaseResponse, DeleteRdpRequest>({
      query: ({ id, rdpId }) => ({
        url: `/project/${id}/rdp/${rdpId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Rdp', id: 'PARTIAL-LIST' }, { type: 'Tim', id: 'SIDE_MENU' }],
    }),
    getRdpLinkExample: build.mutation<GetRdpLinkExampleResponse, GetRdpLinkExampleRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/rdp/link-example`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllRdpQuery,
  useCreateRdpMutation,
  useUploadRdpMutation,
  useExportRdpMutation,
  useGetRdpByIdQuery,
  useEditRdpMutation,
  useDeleteRdpMutation,
  useGetRdpLinkExampleMutation,
} = rdpApi
