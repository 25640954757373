import styled from 'styled-components'
import { Typography } from '@mui/material'

export const CardSubtitle = styled(Typography)`
  min-height: 42px;
  max-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
