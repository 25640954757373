import { FC } from 'react'
import { ProjectAgreementCardLineProps } from './ProjectAgreementCardLine.types'
import {
  CARDLINE_TOOLTIP_MINLENGTH,
  ProjectAgreementCardLineDuration,
  ProjectAgreementCardLineTitle,
  ProjectAgreementCardLineWrapper
} from './ProjectAgreementCardLine.styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Stack, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import Tooltip from '../../../../../components/Tooltip'

export const ProjectAgreementCardLine: FC<ProjectAgreementCardLineProps> = ({
  lineDirection,
  verticalStart,
  fullCardsSkip,
  title,
  duration,
  mode,
  show,
}) => {

  return (
    <ProjectAgreementCardLineWrapper
      lineDirection={lineDirection}
      verticalStart={verticalStart}
      fullCardsSkip={fullCardsSkip}
      show={show}
      mode={mode}
    >
      <ProjectAgreementCardLineTitle lineDirection={lineDirection}>
        {verticalStart === 'top'
          ? <CheckCircleIcon fontSize='medium' color='lightgreen' />
          : <CancelIcon fontSize='medium' color='error' />
        }
        <Tooltip title={title.length > CARDLINE_TOOLTIP_MINLENGTH ? title : ''}>
          <Typography variant='body2'>
            {title}
          </Typography>
        </Tooltip>
      </ProjectAgreementCardLineTitle>

      {mode === 'full' && (
        <>
          <ProjectAgreementCardLineDuration lineDirection={lineDirection} verticalStart={verticalStart}>
            <Stack className='innerWrapper'>
              <WatchLaterIcon fontSize='medium' />

              <Typography variant='body2'>
                {duration}
              </Typography>
            </Stack>
          </ProjectAgreementCardLineDuration>

          <ArrowDropDownIcon className='arrowIcon' />
        </>
      )}
    </ProjectAgreementCardLineWrapper>
  )
}
