import EditIcon from '@mui/icons-material/Edit'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

import { OverflowTextNew } from '@components/OverflowTextNew'

import Button from '../../../../components/Button'
import Divider from '../../../../components/Divider'
import { ElipsisText } from '../../../../styles/global/ElipsisText'
import { theme } from '../../../../styles/theme'
import { COMMON_DOCS_NUMBER } from '../../../../utils/constants'
import { RdCardTooltip } from '../../../Administration/components/RdCard/styles'
import { ObjectCardProps } from './ObjectCard.types'
import { ObjectCardText, StyledObjectCard, StyledObjectCardContent, StyledObjectCardHeader } from './styles'

const ObjectCard: React.FC<ObjectCardProps> = ({ data, onClick, onEditClick, disableEdit }) => {
  const { id, title, number, description } = data
  const isCommonDocs = number === COMMON_DOCS_NUMBER

  return (
    <Box style={{ position: 'relative', width: '100%' }}>
      <StyledObjectCard onClick={() => onClick(id)}>
        <StyledObjectCardHeader
          title={
            <Box>
              <OverflowTextNew
                rows={2}
                lineHeight={'150%'}
                variant='subtitle1'
                color={isCommonDocs ? theme.palette.warning.main : ''}
              >
                {title}
              </OverflowTextNew>
            </Box>
          }
          subheader={
            <Box>
              <OverflowTextNew rows={2} variant='body2'>
                № {number}
              </OverflowTextNew>
            </Box>
          }
          action={
            disableEdit ? null : (
              <Button onClick={(e) => onEditClick(e, id)} variant='text' icon>
                <EditIcon fontSize='medium' />
              </Button>
            )
          }
        />

        <Divider />

        <StyledObjectCardContent>
          {description && (
            <Stack spacing={0.5}>
              <Box>
                <RdCardTooltip variant='body2'>Описание:</RdCardTooltip>
              </Box>
              <Box>
                <OverflowTextNew variant='body1' fontSize={14}>
                  {description}
                </OverflowTextNew>
              </Box>
            </Stack>
          )}
        </StyledObjectCardContent>
      </StyledObjectCard>
    </Box>
  )
}

export default ObjectCard
