import { useState } from 'react';
import { Annotation, colorNotes } from '../../../PragmaPdfViewer.types';
import { UseDefaultToolProps } from '../CanvasAnnotations.types';
import { checkIsInsideSelectAnnotation, checkIsMoveCursor, checkIsSelectAnnotation, setDraggingRect, setResizingRect } from './common';

export const useRectangleTool = ({
  canvasRef,
  setAnnotations,
  annotations,
  rotate,
  scale,
  scaleFactor,
  selectedRect,
  isCreateMode,
  setSelectedRect,
  drawAnnotations,
  setDataTextBox,
  selectTextAnnotation
}: UseDefaultToolProps): {
  onRectangleMouseDown: (e: MouseEvent) => void
  onRectangleMouseMove: (e: MouseEvent) => void
  onRectangleMouseUp: (e: MouseEvent) => void
} => {
  const [isDrawing, setIsDrawing] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [resizeHandle, setResizeHandle] = useState<string | null>(null)
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 })
  const [currentRect, setCurrentRect] = useState<{
    x: number
    y: number
    width: number
    height: number
  } | null>(null)

  const onRectangleMouseDown = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!canvas || e.target !== canvas || e.buttons !== 1) return

    const rect = canvas.getBoundingClientRect()
    const x = (e.clientX - rect.left)
    const y = (e.clientY - rect.top)

    const selected = checkIsSelectAnnotation(
      {
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        scale,
        isCreateMode
      }
    )

    const isInsideSelected = checkIsInsideSelectAnnotation({
      annotations,
      canvasRef,
      clientX: x,
      clientY: y,
      isCreateMode,
    })

    if (selected) {
      if (selected.type === 'highlight') {
        setSelectedRect(selected)
      } else {
        const { left, top, width, height } = annotations[selected.index].rects!
        const handleSize = 10 * scale
        const isTopLeftHandle =
          Math.abs(x - left * canvas.width) <= handleSize && Math.abs(y - top * canvas.height) <= handleSize
        const isBottomRightHandle =
          Math.abs(x - (left + width) * canvas.width) <= handleSize &&
          Math.abs(y - (top + height) * canvas.height) <= handleSize
        if (isTopLeftHandle || isBottomRightHandle) {
          setResizeHandle(isTopLeftHandle ? 'left' : 'right')
          setSelectedRect(selected)
          setIsResizing(true)
          return
        }
        setSelectedRect(selected)
        setIsDragging(true)
        setStartPosition({ x, y })
      }
    } else if (selectedRect && isInsideSelected && isInsideSelected.type === 'rectangle') {
      setIsDragging(true)
      setStartPosition({ x, y })
    } else if (isInsideSelected && isInsideSelected.type === 'text') {
      selectTextAnnotation(isInsideSelected.index)
    } else {
      setSelectedRect(null)
      setIsDrawing(true)
      setStartPosition({ x, y })
      setCurrentRect({ x, y, width: 0, height: 0 })
    }
  }

  const isMouseOverResizeHandle = (x: number, y: number, selectedRect: number | undefined) => {
    if (selectedRect === undefined) return false

    const selectedAnnotation = annotations[selectedRect]
    const handleSize = 5 * scale
    if (selectedAnnotation.type === 'highlight') return false
    if (canvasRef.current && selectedAnnotation.rects) {
      const { height, left, top, width } = selectedAnnotation.rects
      const isTopLeftHandle =
        Math.abs(x - left * canvasRef.current.width) <= handleSize && Math.abs(y - top * canvasRef.current.height) <= handleSize
      const isBottomRightHandle =
        Math.abs(x - (left + width) * canvasRef.current.width) <= handleSize &&
        Math.abs(y - (top + height) * canvasRef.current.height) <= handleSize

      return isTopLeftHandle || isBottomRightHandle
    }
  }

  const onRectangleMouseMove = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!canvas) return

    const rect = canvas.getBoundingClientRect()
    let x = (e.clientX - rect.left)
    let y = (e.clientY - rect.top)

    if (x < 0) x = 0
    if (y < 0) y = 0
    if (x > rect.width) x = rect.width
    if (y > rect.height) y = rect.height
    const ctx = canvas.getContext('2d')

    if (isDrawing && currentRect) {
      const width = x - startPosition.x
      const height = y - startPosition.y
      setCurrentRect({ x: startPosition.x, y: startPosition.y, width, height })

      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        drawAnnotations()
        ctx.strokeStyle = colorNotes['red']
        ctx.lineWidth = 2 * scale
        ctx.strokeRect(startPosition.x, startPosition.y, width, height)
      }
    }

    else if (isDragging && selectedRect) {
      const dx = x - startPosition.x
      const dy = y - startPosition.y
      const updatedAnnotations = setDraggingRect({
        annotations,
        canvasRef,
        selectedRect,
        deltaX: dx,
        deltaY: dy
      })

      setAnnotations(updatedAnnotations)
      setStartPosition({ x, y })
    }

    else if (isResizing && selectedRect && resizeHandle) {
      const updatedAnnotations = setResizingRect({
        annotations,
        canvasRef,
        selectedRect,
        resizeHandle,
        clientX: x,
        clientY: y,
        scale,
        scaleFactor
      })
      setAnnotations(updatedAnnotations)
    }

    if (ctx) {
      let isOnBorder = false
      isOnBorder = checkIsSelectAnnotation({
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        scale,
        isCreateMode
      }) === null ? false : true
      let isInsideAnnotation = null
      isInsideAnnotation = checkIsInsideSelectAnnotation({
        annotations,
        canvasRef,
        clientX: x,
        clientY: y,
        isCreateMode
      })
      canvas.style.cursor = isOnBorder
        ? 'pointer'
        : isInsideAnnotation?.type === 'text'
          ? 'text'
          : isInsideAnnotation?.type === 'highlight'
            ? 'pointer'
            : 'default'
    }
    if (selectedRect) {
      const isMove = checkIsMoveCursor({
        annotations,
        selectedRect,
        canvasRef,
        scale,
        clientX: x,
        clientY: y
      })
      if (isMove) canvas.style.cursor = 'move'
    }

    if (resizeHandle || isMouseOverResizeHandle(x, y, selectedRect?.index)) {
      canvas.style.cursor = 'nwse-resize'
    }
  }

  const onRectangleMouseUp = (e: MouseEvent) => {
    const canvas = canvasRef.current
    if (!canvas) return
    const rect = canvas.getBoundingClientRect()
    const x = (e.clientX - rect.left)
    const y = (e.clientY - rect.top)
    if (isDrawing && currentRect && startPosition.x !== x && startPosition.y !== y) {
      let { x, y, width, height } = currentRect
      if (width < 0) {
        x += width
        width = Math.abs(width)
      }
      if (height < 0) {
        y += height
        height = Math.abs(height)
      }
      const normalizedRect = {
        left: x / canvas.width,
        top: y / canvas.height,
        width: width / canvas.width,
        height: height / canvas.height,
      }
      const newAnnotation: Annotation = {
        id: null,
        color: 'red',
        rects: normalizedRect,
        rotate,
        type: 'rectangle'
      }
      setAnnotations(newAnnotation)
    }

    setIsDrawing(false)
    setIsDragging(false)
    setIsResizing(false)
    setCurrentRect(null)
    setResizeHandle(null)
  }

  return { onRectangleMouseDown, onRectangleMouseMove, onRectangleMouseUp }
}
