
import { Store } from '@reduxjs/toolkit';
import { setIsolatedElements, setIsShowAll } from '@store/slices/tim/tim';
import ReactDOM from 'react-dom';
import { ExtensionBase } from 'tangl-viewer';
import { ExtVisibilityButtons } from './components/ExtVisibilityButtons';
import { RootState } from '@store/store';

export class CustomVisibilityExtension extends ExtensionBase {
  static getName = (): string => "custom-visibility"
  private dispatch: Store['dispatch']
  private store: Store
  private selectedElements: number[] = []

  constructor(viewerName: string, args: { dispatch: Store['dispatch'], store: Store }) {
    super(viewerName)
    this.dispatch = args.dispatch
    this.store = args.store

    this.store.subscribe(() => {
      const state: RootState = this.store.getState()
      this.selectedElements = state.tim.selectedFromModelElements.length > 0
      ? state.tim.selectedFromModelElements : state.tim.selectedFromTreeElements
    })

    this.handleIsolation = this.handleIsolation.bind(this)
    this.showAll = this.showAll.bind(this)
  }

  renderIconButton(container: HTMLElement) {
    const buttonContainer = document.createElement('div')
    container.appendChild(buttonContainer)
    ReactDOM.render(<ExtVisibilityButtons isolate={this.handleIsolation} showAll={this.showAll} />, buttonContainer)
  }

  sharedToolbarRender(container: HTMLElement) {
    this.renderIconButton(container)
  }

  handleIsolation() {
    if (this.selectedElements.length === 0) {
      return
    }

    this.dispatch(setIsolatedElements(this.selectedElements))

    const renderManager = this.getRenderManager()
    renderManager.sceneManager.tools.isolateElements(this.selectedElements)
    renderManager.requestUpdate()
  }

  showAll() {
    this.dispatch(setIsShowAll(true))
    const renderManager = this.getRenderManager()
    renderManager.sceneManager.tools.showAllElements()
    renderManager.requestUpdate()
  }

  added(): void {
  }

  deleted(): void {
  }
}