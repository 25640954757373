import React, { FC, MouseEvent } from 'react'
import { IconButton, Stack } from '@mui/material'
import { MoveCellIconWrapper } from './MoveCell.styles'
import { TABLE_CELL_HEIGHT } from '../../WorksTable.types'
import { StyledTableCell } from '../../WorksTable.styles'
import { ShiftUpContainedIcon, ShiftUpOutlinedIcon } from '../../../../../../assets/icons/ShiftUpIcon'
import { ShiftDownContainedIcon, ShiftDownOutlinedIcon } from '../../../../../../assets/icons/ShiftDownIcon'
import { MoveCellProps, MoveType } from './MoveCell.types'
import {
  EditGroupNumRequest,
  EditWorkRequest,
  useEditGroupNumMutation,
  useEditWorkMutation
} from '../../../../../../api/works'
import { useParams } from 'react-router-dom'

export const MoveCell: FC<MoveCellProps> = ({
  moveVariant,
  projectId,
  numGroup,
  group,
  workId,
  numWork,
  disableUp,
  disableDown,
  ...cellProps
}) => {

  const { scheduleId: scheduleIdStr} = useParams()
  const scheduleId = parseInt(scheduleIdStr!)

  const [editWork] = useEditWorkMutation()
  const [editGroup] = useEditGroupNumMutation()

  const onMove = (type: MoveType) => (e: MouseEvent) => {
    e.stopPropagation()

    if (moveVariant === 'work' && workId && numWork) {
      const commonWorkRequest: Pick<EditWorkRequest, 'id' | 'scheduleId' | 'workId'> = {
        id: projectId,
        scheduleId,
        workId,
      }

      type === 'increase' && editWork({ ...commonWorkRequest, numWork: numWork + 1 })
      type === 'decrease' && editWork({ ...commonWorkRequest, numWork: numWork - 1 })
      return
    }

    if (moveVariant === 'group' && group) {
      const commonGroupRequest: Pick<EditGroupNumRequest, 'id' | 'scheduleId' | 'groupKey'> = {
        id: projectId,
        scheduleId,
        groupKey: {
          group,
          numGroup,
        },
      }

      type === 'increase' && editGroup({ ...commonGroupRequest, newNumGroup: numGroup + 1 })
      type === 'decrease' && editGroup({ ...commonGroupRequest, newNumGroup: numGroup - 1 })
      return
    }
  }

  return (
    <StyledTableCell
      {...cellProps}
      className='moveCell'
    >
      <Stack height={TABLE_CELL_HEIGHT} justifyContent='center'>
        {!disableUp && (
          <MoveCellIconWrapper>
            <IconButton onClick={onMove('decrease')} className='icon'>
              <ShiftUpOutlinedIcon fontSize='small' />
            </IconButton>
            <IconButton onClick={onMove('decrease')} className='icon-hover'>
              <ShiftUpContainedIcon fontSize='small' color='primary' />
            </IconButton>
          </MoveCellIconWrapper>
        )}

        {!disableDown && (
          <MoveCellIconWrapper>
            <IconButton onClick={onMove('increase')} className='icon'>
              <ShiftDownOutlinedIcon fontSize='small' />
            </IconButton>
            <IconButton onClick={onMove('increase')} className='icon-hover'>
              <ShiftDownContainedIcon fontSize='small' color='primary' />
            </IconButton>
          </MoveCellIconWrapper>
        )}
      </Stack>
    </StyledTableCell>
  )
}
