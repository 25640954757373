import styled from "styled-components";
import { StyledAccordionSummary, StyledCheckbox } from "../ModelViewAccordion/ModelViewAccordion.styles";

export const SubAccordionSummary = styled(StyledAccordionSummary)`
  background-color: ${props => props.theme.palette.bg.white};

  & .MuiAccordionSummary-content {
    margin: 0;
    margin-left: 8px;
  }
`

export const StyledSubItemsCheckbox = styled(StyledCheckbox)`
  & .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: anywhere;
    color: ${props => props.theme.palette.text.dark};
  }
`