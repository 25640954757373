import styled from 'styled-components'
import { alpha, Stack } from '@mui/material'
import { FilterSelectMultiple } from '../../../../../../components/FilterSelectMulti'

export const AttachmentsExportDrawerMainListWrapper = styled(Stack)`
  flex: 1;
  max-width: calc((100% - 20px) / 2); // 20px - gap
  padding: 20px;
  padding-right: 0;
  border: ${props => `1px solid ${props.theme.palette.legends.gray}`};
  border-radius: 16px;
`

export const AttachmentsExportDrawerFilter = styled(FilterSelectMultiple)`
  background-color: ${props => props.theme.palette.bg.shades};
  border: ${props => `1px solid ${alpha(props.theme.palette.secondary.gray!, 0.2)}`};
  border-radius: 6px;

  & .MuiSelect-select {
    padding-left: 4px;
  }

  & .MuiTypography-root {
    line-height: 22px;
    color: ${props => props.theme.palette.disabled};
  }
`