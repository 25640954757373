import CancelIcon from '@mui/icons-material/Cancel';
import { HeaderSectionProps } from "./HeaderSection.types";
import { StyledButton, StyledSection, StyledTitle } from "./HeaderSection.styles";

export const HeaderSection = ({ title, onButtonClick }: HeaderSectionProps) => {
  return (
    <StyledSection direction='row'>
      <StyledTitle variant='body1'>{title}</StyledTitle>
      <StyledButton variant="text"
        onClick={onButtonClick}
        color="primary"
        startIcon={<CancelIcon fontSize="small" />}>
        Закрыть
      </StyledButton>
    </StyledSection>
  )
}