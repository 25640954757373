import { CloudCancelIcon } from 'assets/icons/CloudCancelIcon'
import { CloudCheckIcon } from 'assets/icons/CloudCheckIcon'
import { CloudCogIcon } from 'assets/icons/CloudCogIcon'
import { ReactNode } from 'react'
import { theme } from 'styles/theme'

export type TTanglIntegrationErrorStatus = 'ERROR_AUTH' | 'ERROR_COMPANY' | 'ERROR_ACCESS'
export type TTanglIntegrationStatus = 'ENABLED' | 'DISABLED' | TTanglIntegrationErrorStatus

export type TTanglIntegrationStatusRu = 'Подключено' | 'Настройка подключения' | 'Ошибка подключения'
export type TanglStatusConnection = 'no_credentials' | 'offline' | 'ok' | 'bad_credentials'

export const tanglIntegrationStatusRuByEn: Record<TTanglIntegrationStatus, TTanglIntegrationStatusRu> = {
  ENABLED: 'Подключено',
  DISABLED: 'Настройка подключения',
  ERROR_AUTH: 'Ошибка подключения',
  ERROR_COMPANY: 'Ошибка подключения',
  ERROR_ACCESS: 'Ошибка подключения',
}

export const tanglIntegrationStatusEnByRu: Record<TTanglIntegrationStatusRu, TTanglIntegrationStatus> = {
  Подключено: 'ENABLED',
  'Настройка подключения': 'DISABLED',
  'Ошибка подключения': 'ERROR_AUTH',
}

export const tanglIntegrationStatusColorByEn: Record<TTanglIntegrationStatus, string> = {
  ENABLED: theme.palette.success.dark,
  DISABLED: theme.palette.legends.textBlue,
  ERROR_AUTH: theme.palette.error.main,
  ERROR_COMPANY: theme.palette.error.main,
  ERROR_ACCESS: theme.palette.error.main,
}

export const tanglIntegrationStatusIconByEn: Record<TTanglIntegrationStatus, ReactNode> = {
  ENABLED: <CloudCheckIcon color='success' />,
  DISABLED: <CloudCogIcon color='info' />,
  ERROR_AUTH: <CloudCancelIcon color='error' />,
  ERROR_COMPANY: <CloudCancelIcon color='error' />,
  ERROR_ACCESS: <CloudCancelIcon color='error' />,
}

export const tanglIntegrationErrorTextByStatus: Record<TTanglIntegrationErrorStatus, string> = {
  ERROR_AUTH: 'Не удалось авторизоваться в аккаунте. Проверьте правильность реквизитов.',
  ERROR_COMPANY: 'Нет доступа в указанную компанию. Проверьте правильность реквизитов.',
  ERROR_ACCESS: 'Отсутствуют права на изменение данных в аккаунте. Проверьте правильность реквизитов.',
}

export const tanglIntegrationErrorTextByStatusAdditional: Record<TTanglIntegrationErrorStatus, string> = {
  ERROR_AUTH: 'Не удалось авторизоваться в аккаунте Tangl. Проверьте правильность реквизитов в режиме администрирования.',
  ERROR_COMPANY: 'Нет доступа в указанную компанию аккаунта Tangl. Проверьте правильность реквизитов в режиме администрирования.',
  ERROR_ACCESS: 'Отсутствуют права на изменение данных в аккаунте Tangl. Проверьте правильность реквизитов в режиме администрирования.',
}

export interface IIntegrateTanglRequestAccount {
  clientId: string
  clientSecret: string
  username: string
  password: string
  companyId: string
}

export interface TanglToken {
  access_token: string
  expires_in: number
  scope: string
  token_type: string
}

export interface UserSwitchSettings {
  enabled: boolean
}
