import { UseAccordionCheckStateProps, UseAccordionCheckStateResponse, } from './useAccordionCheckState.types'
import { ChangeEvent } from 'react'
import { useFormikContext } from 'formik'
import {
  ExportArchiveFormData,
  SelectedIiTomData,
  SelectedIrdTomData,
  SelectedPdTomData,
  SelectedRdTomData
} from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { TomRdArchiveObject, TomRdArchiveRd } from '../../../../../../../../api/rdPhase'
import { TomPdArchivePd } from '../../../../../../../../api/pdPhase'
import { TomIiArchiveIi } from '../../../../../../../../api/iiPhase/tom/archiveExport'
import { SelectedTomData } from '../../../../../../../../types/tom'
import { useTypedSelector } from '../../../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../../../store/slices/documentsPages/projectInfo'
import { TomIrdArchiveIrd } from '../../../../../../../../api/irdPhase/tom/archiveExport'

export const useAccordionCheckState = ({
  object,
  pd,
  ii,
  ird,
  objectIndex,
  pdIndex,
  iiIndex,
  irdIndex,
}: UseAccordionCheckStateProps): UseAccordionCheckStateResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { values: formValues, setFieldValue } = useFormikContext<ExportArchiveFormData>()

  // Rd
  const setCheckedObject = (
    object: TomRdArchiveObject,
    objectIndex: number,
    checked: boolean
  ) => {
    const tomsForPush: SelectedRdTomData[] = []
    const tomsForSplice: SelectedRdTomData[] = []

    object?.rdList?.forEach((rd, rdIndex) => {
      setCheckedRd(rd, objectIndex, rdIndex, checked)

      checked
        ? tomsForPush.push(...getUpdatedSelectedRdToms(rd, objectIndex, rdIndex, checked))
        : tomsForSplice.push(...getUpdatedSelectedRdToms(rd, objectIndex, rdIndex, checked))
    })

    checked
      ? setFieldValue('selectedRdToms', [...formValues.selectedRdToms, ...tomsForPush])
      : deleteTomsFromSelected(formValues.selectedRdToms, tomsForSplice)
  }

  const setCheckedRd = (rd: TomRdArchiveRd, objectIndex: number, rdIndex: number, checked: boolean) => {
    setFieldValue(
      `rdFileList[${objectIndex}].rdList[${rdIndex}].selected`,
      checked
    )

    rd?.tomList?.forEach((tom, tomIndex) => {
      if (tom.hideByFilter) return

      setFieldValue(
        `rdFileList[${objectIndex}].rdList[${rdIndex}].tomList[${tomIndex}].selected`,
        checked
      )
    })
  }

  const getUpdatedSelectedRdToms = (
    rd: TomRdArchiveRd,
    objectIndex: number,
    rdIndex: number,
    checked: boolean
  ): SelectedRdTomData[] => {
    const tomsForPush: SelectedRdTomData[] = []
    const tomsForSplice: SelectedRdTomData[] = []

    rd?.tomList?.forEach((tom, tomIndex) => {
      const selectedTomData: SelectedRdTomData = {
        tom,
        objectIndex: objectIndex!,
        rdIndex: rdIndex!,
        tomIndex,
      }

      if (checked && !tom.selected && !tom.hideByFilter) {
        tomsForPush.push(selectedTomData)
        return
      }

      if (!checked && tom.selected && !tom.hideByFilter) {
        tomsForSplice.push(selectedTomData)
        return
      }
    })

    return checked
      ? tomsForPush
      : tomsForSplice
  }


  // Pd
  const setCheckedPd = (pd: TomPdArchivePd, pdIndex: number, checked: boolean) => {
    setFieldValue(
      `pdFileList[${pdIndex}].selected`,
      checked
    )

    pd?.tomList?.forEach((tom, tomIndex) => {
      if (tom.hideByFilter) return

      setFieldValue(
        `pdFileList[${pdIndex}].tomList[${tomIndex}].selected`,
        checked
      )
    })

    checked
      ? setFieldValue('selectedPdToms', [...formValues.selectedPdToms, ...getUpdatedSelectedPdToms(pd, pdIndex!, checked)])
      : deleteTomsFromSelected(formValues.selectedPdToms, getUpdatedSelectedPdToms(pd, pdIndex!, checked))
  }

  const getUpdatedSelectedPdToms = (
    pd: TomPdArchivePd,
    pdIndex: number,
    checked: boolean
  ): SelectedPdTomData[] => {
    const tomsForPush: SelectedPdTomData[] = []
    const tomsForSplice: SelectedPdTomData[] = []

    pd?.tomList?.forEach((tom, tomIndex) => {
      const selectedTomData: SelectedPdTomData = {
        tom,
        pdIndex: pdIndex!,
        tomIndex,
      }

      if (checked && !tom.selected && !tom.hideByFilter) {
        tomsForPush.push(selectedTomData)
        return
      }

      if (!checked && tom.selected && !tom.hideByFilter) {
        tomsForSplice.push(selectedTomData)
        return
      }
    })

    return checked
      ? tomsForPush
      : tomsForSplice
  }


  // Ii
  const setCheckedIi = (ii: TomIiArchiveIi, iiIndex: number, checked: boolean) => {
    setFieldValue(
      `iiFileList[${iiIndex}].selected`,
      checked
    )

    ii?.tomList?.forEach((tom, tomIndex) => {
      if (tom.hideByFilter) return

      setFieldValue(
        `iiFileList[${iiIndex}].tomList[${tomIndex}].selected`,
        checked
      )
    })

    checked
      ? setFieldValue('selectedIiToms', [...formValues.selectedIiToms, ...getUpdatedSelectedIiToms(ii, iiIndex, checked)])
      : deleteTomsFromSelected(formValues.selectedIiToms, getUpdatedSelectedIiToms(ii, iiIndex, checked))
  }

  const getUpdatedSelectedIiToms = (
    ii: TomIiArchiveIi,
    iiIndex: number,
    checked: boolean
  ): SelectedIiTomData[] => {
    const tomsForPush: SelectedIiTomData[] = []
    const tomsForSplice: SelectedIiTomData[] = []

    ii?.tomList?.forEach((tom, tomIndex) => {
      const selectedTomData: SelectedIiTomData = {
        tom,
        iiIndex,
        tomIndex,
      }

      if (checked && !tom.selected && !tom.hideByFilter) {
        tomsForPush.push(selectedTomData)
        return
      }

      if (!checked && tom.selected && !tom.hideByFilter) {
        tomsForSplice.push(selectedTomData)
        return
      }
    })

    return checked
      ? tomsForPush
      : tomsForSplice
  }


  // Ird
  const setCheckedIrd = (ird: TomIrdArchiveIrd, irdIndex: number, checked: boolean) => {
    setFieldValue(
      `irdFileList[${irdIndex}].selected`,
      checked
    )

    ird?.tomList?.forEach((tom, tomIndex) => {
      if (tom.hideByFilter) return

      setFieldValue(
        `irdFileList[${irdIndex}].tomList[${tomIndex}].selected`,
        checked
      )
    })

    checked
      ? setFieldValue('selectedIrdToms', [...formValues.selectedIrdToms, ...getUpdatedSelectedIrdToms(ird, irdIndex, checked)])
      : deleteTomsFromSelected(formValues.selectedIrdToms, getUpdatedSelectedIrdToms(ird, irdIndex, checked))
  }

  const getUpdatedSelectedIrdToms = (
    ird: TomIrdArchiveIrd,
    irdIndex: number,
    checked: boolean
  ): SelectedIrdTomData[] => {
    const tomsForPush: SelectedIrdTomData[] = []
    const tomsForSplice: SelectedIrdTomData[] = []

    ird?.tomList?.forEach((tom, tomIndex) => {
      const selectedTomData: SelectedIrdTomData = {
        tom,
        irdIndex,
        tomIndex,
      }

      if (checked && !tom.selected && !tom.hideByFilter) {
        tomsForPush.push(selectedTomData)
        return
      }

      if (!checked && tom.selected && !tom.hideByFilter) {
        tomsForSplice.push(selectedTomData)
        return
      }
    })

    return checked
      ? tomsForPush
      : tomsForSplice
  }


  // Common
  const onAccordionCheckboxChange = (e: ChangeEvent<HTMLInputElement>, accordionFieldName: string) => {
    e.stopPropagation()
    const checked = e.target.checked
    setFieldValue(
      accordionFieldName,
      checked
    )

    selectedProjectPhase === 'Рабочая документация' && setCheckedObject(object!, objectIndex!, checked)
    selectedProjectPhase === 'Проектная документация' && setCheckedPd(pd!, pdIndex!, checked)
    selectedProjectPhase === 'Инженерные изыскания' && setCheckedIi(ii!, iiIndex!, checked)
    selectedProjectPhase === 'Сбор исходных данных' && setCheckedIrd(ird!, irdIndex!, checked)
  }


  const deleteTomsFromSelected = (selectedToms: SelectedTomData[], deletedToms: SelectedTomData[]) => {
    const deletedTomsIds = deletedToms.map(tom => tom.tom.tomId)
    const selectedTomsAfterDelete = selectedToms.filter(tom => !deletedTomsIds.includes(tom.tom.tomId))

    selectedProjectPhase === 'Рабочая документация' && setFieldValue('selectedRdToms', selectedTomsAfterDelete)
    selectedProjectPhase === 'Проектная документация' && setFieldValue('selectedPdToms', selectedTomsAfterDelete)
    selectedProjectPhase === 'Инженерные изыскания' && setFieldValue('selectedIiToms', selectedTomsAfterDelete)
    selectedProjectPhase === 'Сбор исходных данных' && setFieldValue('selectedIrdToms', selectedTomsAfterDelete)
  }

  const disableExpandIcon = !!(pd?.selected || ii?.selected || ird?.selected)
  const accordionHideByFilter = !!(object?.hideByFilter || pd?.hideByFilter || ii?.hideByFilter || ird?.hideByFilter)

  return {
    onAccordionCheckboxChange,
    setCheckedRd,
    getUpdatedSelectedRdToms,
    deleteTomsFromSelected,
    disableExpandIcon,
    accordionHideByFilter,
  }
}
