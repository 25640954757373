import styled from 'styled-components'
import FieldForm from '../../../../../components/FieldForm'
import { Button, Stack, Typography } from '@mui/material'
import { Form } from 'formik'

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 35px;
  flex-wrap: wrap;
  gap: 12px;
`

export const InternalAgreementTextField = styled(FieldForm)`
  width: 150px;

  & .MuiFilledInput-root {

    &.Mui-error {
      border: 1px solid ${props => props.theme.palette.warning.main};
    }

    &.Mui-focused  {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }
  }

  & .MuiFilledInput-input {
    padding: 8px;
  }

  & .MuiFormHelperText-root {
    display: none;
  }
`
export const TitleField = styled(Typography)`
  color: ${props => props.theme.palette.secondary.gray};
  font-size: 14px;
  width: max-content;
  white-space: nowrap;
`

export const InternalAgreementCheckBox = styled(FieldForm)`
  height: 36px;
  margin-left: 8px!important;

  & .MuiCheckbox-root {
    color: #2B36481F;
  }

  & .Mui-checked {
    color: #0044B4;
  }
`
export const StyledSpan = styled.span<{ $checked: boolean }>`
  position: absolute;
  top: 10px;
  left: 57px;
  pointer-events: none;
  color: ${props => props.$checked ? props.theme.palette.primary.main : props.theme.palette.legends.text};
  font-size: 12px;

  & ~ & {
    left: 104px;
  }
`

export const InternalAgreementSwitchField = styled(FieldForm)`
  margin-right: 8px;

  & .MuiSwitch-input {
    height: 38px;
  }

  & .MuiSwitch-root {
    align-items: center;
    margin: 0;
    padding: 0;
    width: 90px;
  }

  & .MuiSwitch-switchBase {
    padding: 2px;
    top: 5px;
  }

  & .MuiSwitch-thumb {
    width: 40px;
    height: 24px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    background-color: #fff!important;
  }

  & .MuiSwitch-track {
    background-color: ${props => props.theme.palette.primaryBorderLight}!important;
    height: 28px;
    width: 90px;
  }

  & .Mui-checked {
    transform: translateX(46px);
  }
`

export const InternalAgreementTimeField = styled(Stack)`
  border: 1px solid rgba(92,110,140,0.1);
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 8px;
  width: 148px;
  height: 36px;
  position: relative;
`

export const InternalAgreementTimeValueField = styled(FieldForm)`
  
 & .MuiFilledInput-root {
  max-width: 53px;
  border: none;
  background-color: inherit;
 }

 & .MuiFilledInput-input {
  padding: 8px 8px 8px 8px;
  text-align: center;
 }

 & .MuiFormHelperText-root {
    width: 146px;
    position: absolute;
    top: 31px;
    left: -57px;
    width: max-content;
 }
`

export const InternalAgreementButton = styled(Button)`
  height: 36px;
  font-size: 13px;
  white-space: normal;
  line-height: 1rem;
  min-width: 110px;
`

export const InternalAgreementWatchDurationField = styled(Stack) <{ isBroken: boolean }>`
 gap: 4px;
 & .MuiSvgIcon-root.clockIcon,
 & .MuiTypography-root {
   color: ${props => props.isBroken ? props.theme.palette.warning.main : props.theme.palette.info.main};
 }
`

export const InternalAgreementWatchDurationText = styled(Typography)`
  color: ${props => props.theme.palette.info.main};
  width: max-content;
`
export const InternalAgreementViewField = styled(Typography)`
  width: max-content;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.theme.palette.text.dark};
`

export const InternalAgreementEditButton = styled(Button)`
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 8px;
  text-transform: capitalize;
`

