import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InternalAgreementState, TableFormValues } from './internalAgreement.types'
import {
  SetInitialSchemaCompanyFromMenuPayload,
  SetIsOpenFormAddMemberPayload,
  SetIsSelectedProcedure,
  SetSchemaFormModePayload,
  SetTableFormValuesPayload
} from './internalAgreement.payloads.types'
import { Innermap } from '../../../api/internalAgreement/types'

let initialState: InternalAgreementState = {
  currentSchema: {} as Innermap,
  schemaFormMode: 'view',
  initialSchemaCompanyFromMenu: '',
  isOpenFormAddMember: { isOpen: false, formMode: 'create' },
  tableFormValues: {} as TableFormValues,
  isSelectedProcedure: false,
  approvedProcedures: [],
}

const internalAgreementSlice = createSlice({
  name: 'internalAgreementState',
  initialState,
  reducers: {
    setSchemaFormMode: (state, { payload }: PayloadAction<SetSchemaFormModePayload>) => {
      state.schemaFormMode = payload.schemaFormMode
    },
    setCurrentSchema: (state, { payload }: PayloadAction<Innermap>) => {
      state.currentSchema = payload
    },
    setInitialSchemaCompanyFromMenu: (state, { payload }: PayloadAction<SetInitialSchemaCompanyFromMenuPayload>) => {
      state.initialSchemaCompanyFromMenu = payload.initialSchemaCompanyFromMenu
    },
    setIsOpenFormAddMember: (state, { payload }: PayloadAction<SetIsOpenFormAddMemberPayload>) => {
      state.isOpenFormAddMember = payload.isOpenFormAddMember
    },
    setTableFormValues: (state, { payload }: PayloadAction<SetTableFormValuesPayload>) => {
      state.tableFormValues = payload.tableFormValues
    },
    setIsSelectedProcedure: (state, { payload }: PayloadAction<SetIsSelectedProcedure>) => {
      state.isSelectedProcedure = payload.isSelectedProcedure
    },
    setApprovedProcedure: (state, { payload }: PayloadAction<number[]>) => {
      state.approvedProcedures = payload
    },
  },
})

export const { reducer: internalAgreementReducer } = internalAgreementSlice
export const {
  setSchemaFormMode,
  setCurrentSchema,
  setInitialSchemaCompanyFromMenu,
  setIsOpenFormAddMember,
  setTableFormValues,
  setIsSelectedProcedure,
  setApprovedProcedure,
} = internalAgreementSlice.actions
