import { Avatar, Collapse, List, ListItemAvatar, Stack } from "@mui/material"
import icon from '../../../../../../../assets/empty-project-preview.png'
import { useEffect, useMemo, useState } from "react";
import { CloseButton, OpenButton, StyledItemText, StyledListItem } from "./Companies.styles";
import { CompaniesProps } from "./Companies.types";
import { ListHeader } from "../ListHeader";
import { FilteredCompany } from "../../DocumentAccessDrawer.types";

export const Companies = ({ companies, title, type, searchCompanyValue, changeStatus }: CompaniesProps) => {

  const [open, setOpen] = useState(true)
  const setIsOpen = (value: boolean) => setOpen(value)

  const filteredCompanies = useMemo((): FilteredCompany[] => {
    if (!searchCompanyValue.length) return companies
    return companies.filter((company) => company.name
      .toLowerCase().includes(searchCompanyValue.toLowerCase()))
  }, [companies, searchCompanyValue])

  useEffect(() => {
    if (searchCompanyValue) {
      setOpen(true)
    }

  }, [searchCompanyValue])

  return (
    <Stack spacing="12px">
      <ListHeader
        open={open}
        title={title}
        setIsOpen={setIsOpen} />

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component={Stack} spacing='12px' disablePadding>
          {filteredCompanies.map((company) => (
            <StyledListItem
              key={company.name}
              disablePadding
              secondaryAction={
                type === 'open' ?
                  <CloseButton onClick={() => changeStatus(company.name, 'close')}>Закрыть</CloseButton>
                  : type === 'close' ?
                    <OpenButton onClick={() => changeStatus(company.name, 'open')}>Открыть</OpenButton>
                    : null
              }>
              <ListItemAvatar>
                <Avatar src={icon} sx={{ width: "32px", height: "32px", marginRight: "16px" }} />
              </ListItemAvatar>
              <StyledItemText
                primary={company.name}
                secondary={'Пользователей: ' + company.employees} />
            </StyledListItem>
          ))}

        </List>

      </Collapse>
    </Stack>
  )
}