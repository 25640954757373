import styled from 'styled-components'
import { RdCardText, RdCardTooltip, StyledRdCard, StyledRdCardContent, StyledRdCardHeader } from '../RdCard/styles'

export const StyledPdCard = styled(StyledRdCard)`
  min-height: auto;
`

export const StyledPdCardHeader = styled(StyledRdCardHeader)`
  padding-bottom: 8px; 
`

export const StyledPdCardContent = styled(StyledRdCardContent)`
  padding-top: 10px !important;
`

export const PdCardTooltip = styled(RdCardTooltip)`
  font-weight: 700;
  font-size: 12px;
`
export const PdCardText = styled(RdCardText)`
  font-size: 14px;
`
