import { Stack, Typography } from "@mui/material";
import styled from "styled-components";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { theme } from "../../../../../../../../styles/theme";

const checkedColor = theme.palette.secondary.main
const disabledColor = theme.palette.text.dark + '1F'
const approvalColor = theme.palette.warning.light

export const TableRow = styled(Stack)`
  height: 36px;
  align-items: center;
  justify-content: space-between;
`

export const NameBlock = styled(Stack)`
  width: 290px;
  align-items: center;
`
export const StyledName = styled(Typography)`
  font-size: 14px;
  color: ${props => props.theme.palette.text.dark};
  text-align: start;
`
export const StyledPosition = styled(Typography)`
  font-size: 12px;
  color: ${props => props.theme.palette.secondary.dark};
  text-align: start;
`
export const IconWrapper = styled(Stack)`
  width: 96px;
  justify-content: center;
  align-items: center;
`

export const ApprovalIcon = styled(DoneAllIcon)<{$approval: boolean}>`
  color: ${props => props.$approval ? approvalColor : disabledColor};
`
export const AssuranceIcon = styled(DoneAllIcon)<{$assurance: boolean}>`
  color: ${props => props.$assurance ? checkedColor : disabledColor};
`
export const SigningIcon = styled(DoneAllIcon)<{$signing: boolean}>`
  color: ${props => props.$signing ? checkedColor : disabledColor};
`
export const RemarksIcon = styled(DoneAllIcon)<{$remarks: boolean}>`
  color: ${props => props.$remarks ? checkedColor : disabledColor};
`