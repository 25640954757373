import { RootState } from '../../../store'
import { Roadmap, RoadmapPhase, Stage } from '../../../../api/projectAgreement/types'
import { RoadmapFormMode } from '../agreement.types'

export const currentRoadmapSelector = (state: RootState): Roadmap => state.agreement.currentRoadmap
export const roadmapFormModeSelector = (state: RootState): RoadmapFormMode => state.agreement.roadmapFormMode
export const initialRoadmapPhaseFromMenuSelector = (state: RootState): RoadmapPhase => state.agreement.initialRoadmapPhaseFromMenu
export const nextRoadmapNumberSelector = (state: RootState): number => state.agreement.nextRoadmapNumber
export const isStageFormDirtySelector = (state: RootState): boolean => state.agreement.isStageFormDirty

export const setStageCompanySelector = (state: RootState): string => state.agreement.stageCompany
export const stagesSelector = (state: RootState): Stage[] => state.agreement.stages
export const isAddStageToExistSelector = (state: RootState): boolean => state.agreement.isAddStageToExist
export const hoverCardIdSelector = (state: RootState): number | null => state.agreement.hoverCardId
export const isBeenHoveredSelector = (state: RootState): boolean => state.agreement.isBeenHovered



