import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { AccessTitle } from './styles'
import { UserAccessesProps } from './UserAccesses.types'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'

const UserAccesses: React.FC<UserAccessesProps> = ({ access, role }) => {
  if (!access.allProjects && !access.projects.length) {
    return <Typography variant='body2'>Нет проектов</Typography>
  }

  return (
    <ScrollableContainer spacing={2} maxHeight='200px'>
      {access.allProjects
        ? (
          <Box>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box>
                <DescriptionOutlinedIcon fontSize='medium' color='primary' />
              </Box>
              <AccessTitle variant='subtitle2'>Все проекты и объекты</AccessTitle>
            </Stack>
          </Box>
        )
        : access.projects.map(project => (
          <Box key={project.id}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box>
                <DescriptionOutlinedIcon fontSize='medium' color='primary' />
              </Box>
              <AccessTitle variant='subtitle2'>{project.name}</AccessTitle>
            </Stack>

            <Box sx={{ mt: 0.5, ml: 3.5 }}>
              {(role === 'supervisor' || role === 'contractor') && project.irdAccess
                ? <Typography variant='body2' mb={1}>Исходно-разрешительная документация</Typography>
                : null
              }

              {(role === 'supervisor' || role === 'contractor') && project.iiAccess
                ? <Typography variant='body2' mb={1}>Инженерные изыскания</Typography>
                : null
              }

              {(role === 'supervisor' || role === 'contractor') && project.pdAccess
                ? <Typography variant='body2' mb={1}>Проектная документация</Typography>
                : null
              }

              {project.allObjects
                ? (
                  <Typography variant='body2'>
                    {role === 'supervisor' ? 'Рабочая документация' : 'Все объекты'}
                  </Typography>
                )
                : (
                  <Grid container rowSpacing={0.5} columnSpacing={1.25}>
                    {project.objects?.map((object, index) => {
                      const isLast = project.objects?.length === index + 1
                      return (
                        <Grid item key={object.id}>
                          <Typography variant='body2'>{object.name}{!isLast ? ', ' : null}</Typography>
                        </Grid>
                      )
                    })}
                  </Grid>
                )
              }
            </Box>
          </Box>
        ))
      }
    </ScrollableContainer>
  )
}

export default UserAccesses