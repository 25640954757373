import { useSnackbar } from 'notistack'
import { ChangeEvent, useCallback, useState } from 'react'

import { TomIi, TomIiBaseResponse, useEditTomIiMutation } from '../../../../../../api/iiPhase'
import { TomIrd, TomIrdBaseResponse, useEditTomIrdMutation } from '../../../../../../api/irdPhase'
import { TomPd, TomPdBaseResponse, useEditTomPdMutation } from '../../../../../../api/pdPhase'
import {
  TomRd,
  TomRdBaseResponse,
  useEditTomRdMutation,
  useSendToProdTomRdMutation,
} from '../../../../../../api/rdPhase'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../../hooks/useConfirmDialog'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import { onDrawerClose } from '../../../../../../store/slices/documentsPages/drawerInfo'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { tomSelector } from '../../../../../../store/slices/documentsPages/tom'
import { useAppDispatch, useTypedSelector } from '../../../../../../store/store'
import { ProjectPhase } from '../../../../../../types/project'
import { TomStatus } from '../../../../../../types/tom'
import { UseEditTomResponse } from './useEditTom.types'

export const useEditTom = (): UseEditTomResponse => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)

  // Rd
  const [editRdTom, editRdTomResponse] = useEditTomRdMutation()
  const [sendToProdTomRd] = useSendToProdTomRdMutation()

  const [selectedTomToProdTitle, setSelectedTomToProdTitle] = useState<string | null>(null)

  const onTomSendToProdClick = (tomId: number, tomTitle: string) => {
    setSelectedTomToProdTitle(tomTitle)
    openConfirm(tomId)
  }

  const onSendToProdConfirm = useCallback((confirm: boolean, tomId: number) => {
    setSelectedTomToProdTitle(null)
    if (confirm) {
      sendToProdTomRd({ id: tomId })
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: onSendToProdConfirm,
    title: 'Передать том в производство?',
    body: `Документ "${selectedTomToProdTitle || ''}" будет передан подрядчику для производства работ.`,
    denyButtonText: 'Нет',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  useMutationHandlers(editRdTomResponse, (data: TomRdBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  // Pd
  const [editPdTom, editPdTomResponse] = useEditTomPdMutation()

  useMutationHandlers(editPdTomResponse, (data: TomPdBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  // Ii
  const [editIiTom, editIiTomResponse] = useEditTomIiMutation()

  useMutationHandlers(editIiTomResponse, (data: TomIiBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  // Ird
  const [editIrdTom, editIrdTomResponse] = useEditTomIrdMutation()

  useMutationHandlers(editIrdTomResponse, (data: TomIrdBaseResponse) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(onDrawerClose({ dirty: false, immediately: true }))
  })

  // Common
  const onStatusChange: Record<ProjectPhase, (e: ChangeEvent<HTMLInputElement>) => void> = {
    'Рабочая документация': (e: ChangeEvent<HTMLInputElement>) =>
      editRdTom({
        ...(tom as TomRd),
        status: e.target.value as TomStatus,
      }),
    'Проектная документация': (e: ChangeEvent<HTMLInputElement>) =>
      editPdTom({
        ...(tom as TomPd),
        status: e.target.value as TomStatus,
      }),
    'Инженерные изыскания': (e: ChangeEvent<HTMLInputElement>) =>
      editIiTom({
        ...(tom as TomIi),
        status: e.target.value as TomStatus,
      }),
    'Сбор исходных данных': (e: ChangeEvent<HTMLInputElement>) =>
      editIrdTom({
        ...(tom as TomIrd),
        status: e.target.value as TomStatus,
      }),
  }

  return {
    onStatusChange: onStatusChange[selectedProjectPhase],
    onTomSendToProdClick,
    ConfirmDialog,
  }
}
