import styled from "styled-components";
import { StyledAutocomplete } from "../AutocompleteCompany";
import { Box, Stack, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';

export const SearchEmployeesAutocomplete = styled(StyledAutocomplete)`
`
export const TitleText = styled(Typography)`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.theme.palette.text.dark};
`
export const SubTitleText = styled(Typography)`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  color: ${props => props.theme.palette.secondary.dark};
`
export const StyledLegend = styled(Stack)`
  height: 43px;
  align-items: center;
  justify-content: space-between;
  padding: 6px 18px;
  border-bottom: 1px solid ${props => props.theme.palette.legends.gray};
`
export const LegendText = styled(Typography)`
  font-size: 12px;
  color: ${props => props.theme.palette.info.main};
  font-weight: 500;
`

export const StyledListItem = styled(Box)`
  :first-child {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
  }
`
export const StyledCheckbox = styled(Checkbox)`
  color: ${props => props.theme.palette.text.dark + '1F'};
  margin-right: 16px;
  padding: 0;
`