interface ICustomStamp {
  type: string
  company?: string
  position?: string
  name?: string
  date: string
}

{/* <style>
  @font-face {
    font-family: 'Roboto';
    src: url(https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
  }
  text {
    font-family: 'Roboto', sans-serif;
  }
</style> */}

export const CustomStamp = ({
  type,
  company = 'Компания',
  position = 'Должность',
  name = 'Фамилия И.О.',
  date
}: ICustomStamp) => {
  const svg = `
<svg width="300" height="123" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="298" height="121" fill="white" stroke="#6a9ddb" stroke-width="1" />

  <text x="150" y="25" font-family="Arial" font-weight="bold" font-size="16" fill="#6a9ddb" text-anchor="middle">
    ${type}
  </text>

  <text x="150" y="45" font-family="Arial" font-weight="bold" font-size="12" fill="#6a9ddb" text-anchor="middle">
    ${company}
  </text>

  <text x="150" y="65" font-family="Arial" font-weight="bold" font-size="12" fill="#6a9ddb" text-anchor="middle">
    ${position}
  </text>

  <line x1="30" y1="85" x2="130" y2="85" stroke="#6a9ddb" stroke-width="1" />

  <text x="80" y="95" font-family="Arial" font-size="7" fill="#6a9ddb" text-anchor="middle">
    (Подпись)
  </text>

  <text x="180" y="85" font-family="Arial" font-size="12" font-weight="bold" fill="#6a9ddb">
    ${name}
  </text>

  <text x="150" y="110" font-family="Arial" font-size="12" font-weight="bold" fill="#6a9ddb" text-anchor="middle">
    ${date}
  </text>
</svg>`

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`
}
