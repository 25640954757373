import { ReactElement } from "react"
import { ButtonPage, Loader, StyledContainer } from "./InfoPage.styles"
import { Stack, Typography } from "@mui/material"
import ErrorIcon from '@mui/icons-material/Error';
import { ReactComponent as TimInfo } from '../../../../assets/tim-info.svg'
import { theme } from "styles/theme"
import { InfoPageProps } from "./InfoPage.types";

export const InfoPage = ({ variant, callBackClick }: InfoPageProps) => {

  const infoContent: ReactElement = (
    <Stack spacing={4.5} alignItems='center'>
      <Stack spacing={5} maxWidth={936}>
        <Typography variant="h2">Для просмотра моделей в формате IFC размером до 300 МБ необходимо настроить интеграцию с Tangl в режиме администрирования. Для получения обработанной модели интеграция должна быть активной на момент добавления версии модели.</Typography>
        <Typography variant="h2">Выбор моделей для визуализации доступен в списке в левой части экрана. Доступен выбор как отдельных версий модели, так и всех моделей по объектам / разделам в формате IFC.</Typography>
        <Typography variant="h2">После выбора моделей нажмите на кнопку “Просмотр”.</Typography>
      </Stack>
      <TimInfo width={411} height={261} />
    </Stack>
  )

  const loadingContent: ReactElement = (
    <Stack alignItems='center' spacing={2.5}>
      <Loader />
      <Typography variant="h2">Пожалуйста, подождите, модель загружается.</Typography>
    </Stack>
  )

  const errorContent: ReactElement = (
    <Stack spacing={2.5} alignItems='center'>
      <ErrorIcon sx={{ width: 64, height: 64 }} color='error' />
      <Stack>
        <Typography variant="body1" color={theme.palette.error.main}>Что-то пошло не так...</Typography>
        <Typography variant="body1" color={theme.palette.error.main}>Попробуйте изменить список выбранных моделей либо повторите попытку позже.</Typography>
      </Stack>
      <ButtonPage onClick={callBackClick} variant="outlined">Попробовать еще раз</ButtonPage>
    </Stack>
  )

  const renderContent = () => {
    switch (variant) {
      case "info": return infoContent
      case "loading": return loadingContent
      case "error": return errorContent
    }
  }

  return (
    <StyledContainer>
      {renderContent()}
    </StyledContainer>
  )
}