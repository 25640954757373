import { CompleteOverviewDropdown } from '@api/agreementOverview/types'

import { CompleteDropdownData, ConfirmStatus } from '.'

const completeDropdownData: CompleteDropdownData = {
  agreed: {
    type: 'agreed',
    label: 'Согласовано',
  },
  remarks: {
    type: 'remarks',
    label: 'Согласовано с замечанием',
  },
  rejected: {
    type: 'rejected',
    label: 'Отклонено',
  },
}

export const completeStageSuccessMessageByStatus: Record<ConfirmStatus, string> = {
  agreed: 'Том успешно согласован.',
  remarks: 'Том успешно согласован.',
  rejected: 'Том успешно отклонен.',
}

export const getCompleteDropdownData = (list: CompleteOverviewDropdown) => {
  return list.filter((v) => v !== 'unknown').map((v) => completeDropdownData[v as ConfirmStatus])
}
