import { Box, Stack, Typography } from "@mui/material"
import { theme } from "../../../../styles/theme"
import { FC, useCallback, MouseEvent, useState } from "react"
import { InfoRowLegendProps } from "./InfoRowLegend.types"
import { PersonCellPopover } from "./InfoRowLegend.styles"
import Progress from "../../../../components/Progress"
import { UserCardContent } from "../../../Administration/components/UserCardContent"
import { useGetEmployeeQuery } from "../../../../api/users"

export const InfoRowLegend: FC<InfoRowLegendProps> = ({ data: row }) => {
  const defaultColor: string = theme.palette.text.dark!

  const [personOpened, setPersonOpened] = useState<boolean>(false)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null)

  const { data: employeeProfile, isFetching: employeeProfileFetching } = useGetEmployeeQuery(
    { employeeId: row.legendId! },
    { skip: !row.legendId || !personOpened }
  )

  const onPersonClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setPopoverAnchor(e?.currentTarget)
    setPersonOpened(true)
  }

  const onPersonClose = useCallback((e: MouseEvent) => {
    e.stopPropagation()
    setPopoverAnchor(null)
    setPersonOpened(false)
  }, [])

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <row.icon fontSize={row.iconProps?.fontSize || 'medium'} color={row.iconProps?.color || 'secondary'} />
        <Typography variant='body2' component='span'>{row.title}:</Typography>
      </Stack>
      <Stack>
        {row.value && (
        <Typography
          variant='subtitle2'
          component='span'
          color={row.color ?? defaultColor}
          textAlign='right'
        >
          {row.value}
        </Typography>
        )}
        {row.subValue && (
        <Box textAlign='right' p='1px' onClick={(e) => row.legendId ? onPersonClick(e) : undefined}>
          <Typography
            variant='subtitle2'
            component='span'
            color={row.legendId ? theme.palette.primary.main : row.color ?? defaultColor}
          >
            {row.subValue}
          </Typography>
          <PersonCellPopover
            open={personOpened}
            onClose={onPersonClose}
            anchorEl={popoverAnchor}
          >
            {employeeProfileFetching || !employeeProfile?.id
              ? <Progress />
              : <UserCardContent employeeData={employeeProfile!} mode='modal' />
            }
          </PersonCellPopover>
        </Box>
        )}
        {(!row.value && !row.subValue) && (
          <Typography
          variant='subtitle2'
          component='span'
          color={row.color ?? defaultColor}
          textAlign='right'
        >
          —
        </Typography>
        )}
      </Stack>

    </Stack>
  )
}