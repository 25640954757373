import React, { FC, forwardRef } from 'react'
import Tooltip from '../Tooltip'
import { ToggleButton } from '@mui/material'
import { TooltipToggleButtonProps } from './TooltipToggleButton.types'

export const TooltipToggleButton: FC<TooltipToggleButtonProps> = forwardRef(
  ({ TooltipProps, children, ...props }, ref) => {
    return (
      <Tooltip {...TooltipProps}>
        <ToggleButton ref={ref} {...props}>
          {children}
        </ToggleButton>
      </Tooltip>
    )
  }
)
