import { RootState } from '../../../../store'
import { OpenedConfirmSelector, OpenedDrawerSelector } from './drawerInfo.selectors.types'

export const openedDrawerSelector = (state: RootState): OpenedDrawerSelector => ({
  openedDrawer: state.drawerInfo.openedDrawer,
})

export const openedConfirmSelector = (state: RootState): OpenedConfirmSelector => ({
  openedConfirm: state.drawerInfo.openedConfirm,
})
