import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Stack, useMediaQuery } from '@mui/material'
import { NUMBER_OF_ROWS_PER_PD_PAGE, XXL_FOR_LAYOUT } from '../../../utils/constants'
import useSearch from '../../../hooks/useSearch'
import useBreadcrumbs from '../../../hooks/useBreadcrumbs'
import useConfirmDialog, { UseExitConfirmProps } from '../../../hooks/useConfirmDialog'
import { Limit } from '../../../types/global'
import { GetAllPdResponse, Pd, useGetAllPdQuery } from '../../../api/pdPhase'
import { filterByFieldNames } from '../../../utils/filterByFieldNames'
import { GridColumns } from '@mui/x-data-grid'
import { StyledDataGrid } from '../../../styles/global/StyledDataGrid'
import { EmptyPageData } from '../../../components/EmptyPage/EmptyPage.types'
import { getEmptyPageData } from '../../Home'
import AddIcon from '@mui/icons-material/Add'
import Progress from '../../../components/Progress'
import EmptyPage from '../../../components/EmptyPage'
import PdCard from '../components/PdCard'
import PdLegend from '../components/PdLegend'
import PdDrawer from './PdDrawer'
import { ProjectType, projectTypeShort } from '../../../types/project'
import { adminPdViewSelector } from '../../../store/slices/ui'
import { useTypedSelector } from '../../../store/store'
import { RdUploadDrawer } from '../AdminRd/RdUploadDrawer'
import DownloadingIcon from '@mui/icons-material/Downloading'

const AdminPd: React.FC = () => {
  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_LAYOUT})`)
  const { searchValue } = useSearch()

  const viewMode = useTypedSelector(adminPdViewSelector)

  useBreadcrumbs([
    { title: 'Разделы ПД' }
  ])

  const [openedDrawer, setOpenedDrawer] = useState<'create' | 'upload' | null>(null)
  const [chosenPdId, setChosenPdId] = useState<number | undefined>(undefined)

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setOpenedDrawer(null)
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onAddPdClick = useCallback(() => {
    setOpenedDrawer('create')
    setChosenPdId(undefined)
  }, [])

  const onUploadClick = useCallback(() => {
    setOpenedDrawer('upload')
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty
      ? setOpenedDrawer(null)
      : openConfirm()
  }, [])

  const onPdItemClick = useCallback((pdId: number) => {
    setOpenedDrawer('create')
    setChosenPdId(pdId)
  }, [])

  const [limitValue, setLimitValue] = useState<Limit['value']>(
    NUMBER_OF_ROWS_PER_PD_PAGE[0].value
  )
  const [page, setPage] = useState(1)

  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page])

  const { data, isLoading, isFetching } = useGetAllPdQuery({
    limit: limitValue,
    offset: offset,
  })

  const { data: pds, total } = data || ({} as GetAllPdResponse)

  const countPagination = useMemo(
    () => Math.ceil(total / limitValue) || 1,
    [total, limitValue]
  )

  const handleChangeLimit = useCallback(
    (limit: number) => {
      setLimitValue(Number(limit))
      setPage(1)
    },
    [setLimitValue, setPage]
  )
  const handleChangePage = useCallback((page: number) => setPage(page), [setPage])


  const [selectedPdTypes, setSelectedPdTypes] = useState<string[]>([])
  const [filter, setFilter] = useState<boolean>(false)

  useEffect(() => {
    setFilter(!!selectedPdTypes.length)
  }, [selectedPdTypes])

  const onFilterType = useCallback((value: string[]) => {
    setSelectedPdTypes(value)
  }, [])

  const filteredByFilter = useMemo(() => {
    return filter && pds?.length
      ? pds.filter(pd => {
        return (!selectedPdTypes.length || selectedPdTypes.includes(pd.type))
      })
      : pds
  }, [pds, filter, selectedPdTypes])

  //TODO если нужно поставить setPage(1)
  const filteredBySearch = useMemo(() => {
    return searchValue && filteredByFilter?.length
      ? filterByFieldNames<Pd>(pds, ['mark', 'description', 'fullName'], searchValue)
      : filteredByFilter
  }, [filteredByFilter, searchValue])


  const columns: GridColumns = [
    // {
    //   field: 'id',
    //   headerName: '№',
    //   width: 60,
    //   align: 'center',
    //   headerAlign: 'center',
    //   sortable: false,
    // },
    {
      field: 'mark',
      headerName: 'Марка',
      width: 204,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'fullName',
      headerName: 'Наименование',
      width: 595,
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Тип проекта',
      width: 291,
      headerAlign: 'center',
      sortable: false,
      renderCell: params => {
        return <div className='MuiDataGrid-cellContent'>{projectTypeShort[params.value as ProjectType]}</div>
      },
    },
    {
      field: 'description',
      headerName: 'Примечание',
      minWidth: 480,
      flex: 1,
      headerAlign: 'center',
      sortable: false,
    },
  ]

  const renderDataView = () => {
    switch (viewMode) {
      case 'list':
        return (
          <Grid sx={{ py: 1.25 }} spacing={2.5} container>
            {filteredBySearch?.map(pd => (
              <Grid
                item
                xs={12} md={6} lg={4} xl={xxl ? 2.4 : 3}
                container
                justifyContent='center'
                key={pd.id}
              >
                <PdCard data={pd} onClick={onPdItemClick} />
              </Grid>
            ))}
          </Grid>
        )
      case 'table':
        return (
          <StyledDataGrid
            rows={filteredBySearch}
            columns={columns}
            pageSize={limitValue}
            rowsPerPageOptions={[limitValue]}
            onRowClick={(params) => onPdItemClick(params.row.id)}
            hideFooter
            headerHeight={40}
            rowHeight={40}
            showCellRightBorder
            showColumnRightBorder
            disableSelectionOnClick
            disableColumnMenu
          />
        )
    }
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>Все группы ПД были удалены,
      для добавления документации добавьте марки.</>,
    [
      {
        text: 'Добавить раздел ПД',
        icon: AddIcon,
        onClick: () => onAddPdClick()
      },
      {
        text: 'Загрузить',
        icon: DownloadingIcon,
        onClick: () => onUploadClick()
      }
    ]
  )

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют группы ПД, соответствующие результатам запроса.</>
  )

  return (
    <Stack flex={1}>
      {isLoading || isFetching
        ? <Progress />
        : pds?.length
          ? (
            <>
              <PdLegend
                onAddClick={onAddPdClick}
                onUploadClick={onUploadClick}
                selectedTypes={selectedPdTypes}
                onTypeFilter={onFilterType}
                countPagination={countPagination}
                limit={limitValue}
                onChangeLimit={handleChangeLimit}
                onChangePage={handleChangePage}
                page={page}
                numberRows={NUMBER_OF_ROWS_PER_PD_PAGE}
              />
              {filteredBySearch?.length
                ? renderDataView()
                : <EmptyPage data={emptyFilteredData} forFilter />
              }
            </>
          )
          : <EmptyPage data={emptyPageData} fullPage />
      }

      <PdDrawer
        variant='pd'
        open={openedDrawer === 'create'}
        onClose={onDrawerClose}
        id={chosenPdId}
      />

      <RdUploadDrawer
        variant='pd'
        open={openedDrawer === 'upload'}
        onClose={onDrawerClose}
      />
      <ConfirmDialog />
    </Stack>
  )
}

export default AdminPd