import { FC } from 'react'
import { ImageItemProps } from './ImageItem.types'
import { ImageItemDeleteButton, ImageItemWrapper } from './ImageItem.styles'
import DeleteIcon from '@mui/icons-material/Delete'

export const ImageItem: FC<ImageItemProps> = ({ url, onClick }) => {
  return (
    <ImageItemWrapper>
      <img src={url} width={277} height={305} alt='Фото проекта' />

      <ImageItemDeleteButton onClick={() => onClick()}>
        <DeleteIcon color='error' />
      </ImageItemDeleteButton>
    </ImageItemWrapper>
  )
}
