import { Box, TextField } from "@mui/material"
import { StyledAutocomplete, StyledText } from "./AutocompleteCompany.styles"
import { AutocompleteCompanyProps } from "./AutocompleteCompany.types"
import { useMemo } from "react"

export const AutocompleteCompany = ({
  tomAccessList,
  isSelectedCompany,
  setIsSelectedCompany
}: AutocompleteCompanyProps) => {

  const allCompany = useMemo(() => {
    if (!tomAccessList) return []
    return tomAccessList.map(access => ({
      company: access.company,
      status: access.status
    }))
  }, [tomAccessList])

  return (
    <StyledAutocomplete
      blurOnSelect
      clearOnBlur
      handleHomeEndKeys
      noOptionsText='Ничего не найдено'
      clearText='Удалить'
      openText="Выберите из списка"
      getOptionLabel={(option: any) => option.company}
      renderOption={
        (props, option: any) => (
          <Box component="li" {...props} key={option.company}>
            <StyledText variant="body2" >
              {option.company}
            </StyledText>
          </Box>
        )
      }
      value={isSelectedCompany}
      onChange={(e, newValue: any) => setIsSelectedCompany(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}         
          placeholder="Поиск компании"
        />
      )}
      options={allCompany}
    />
  )
}