import { UseCheckboxCheckedProps, UseCheckboxCheckedResponse } from './useCheckboxChecked.types'
import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { ExportArchiveFormData } from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { ProjectPhase } from '../../../../../../../../types/project'
import { useTypedSelector } from '../../../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../../../store/slices/documentsPages/projectInfo'

export const useCheckboxChecked = ({
  objectIndex,
  rdIndex,
  pdIndex,
  iiIndex,
  irdIndex,
  tomIndex,
}: UseCheckboxCheckedProps): UseCheckboxCheckedResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { values: formValues, setFieldValue } = useFormikContext<ExportArchiveFormData>()

  // Rd
  // set rd checkbox checked when all toms checked
  useEffect(() => {
    const filteredToms = formValues.rdFileList[objectIndex!]?.rdList[rdIndex!]?.tomList?.filter(tom => !tom.hideByFilter)

    if (filteredToms?.length && filteredToms.every(tom => tom.selected)) {
      setFieldValue(`rdFileList[${objectIndex!}].rdList[${rdIndex}].selected`, true)
    }
  }, [formValues.rdFileList[objectIndex!]?.rdList[rdIndex!]?.tomList[tomIndex]])


  // Pd
  // set pd checkbox checked when all toms checked
  useEffect(() => {
    const filteredToms = formValues.pdFileList[pdIndex!]?.tomList?.filter(tom => !tom.hideByFilter)

    if (filteredToms?.length && filteredToms.every(tom => tom.selected)) {
      setFieldValue(`pdFileList[${pdIndex!}].selected`, true)
    }
  }, [formValues.pdFileList[pdIndex!]?.tomList[tomIndex]])


  // Ii
  // set ii checkbox checked when all toms checked
  useEffect(() => {
    const filteredToms = formValues.iiFileList[iiIndex!]?.tomList?.filter(tom => !tom.hideByFilter)

    if (filteredToms?.length && filteredToms.every(tom => tom.selected)) {
      setFieldValue(`iiFileList[${iiIndex!}].selected`, true)
    }
  }, [formValues.iiFileList[iiIndex!]?.tomList[tomIndex]])


  // Ird
  // set ird checkbox checked when all toms checked
  useEffect(() => {
    const filteredToms = formValues.irdFileList[irdIndex!]?.tomList?.filter(tom => !tom.hideByFilter)

    if (filteredToms?.length && filteredToms.every(tom => tom.selected)) {
      setFieldValue(`irdFileList[${irdIndex!}].selected`, true)
    }
  }, [formValues.irdFileList[irdIndex!]?.tomList[tomIndex]])


  // Common
  const selected: Record<ProjectPhase, boolean> = {
    'Рабочая документация':
      !!formValues.rdFileList[objectIndex!]?.rdList[rdIndex!]?.selected
      || !!formValues.rdFileList[objectIndex!]?.rdList[rdIndex!]?.tomList[tomIndex]?.selected,
    'Проектная документация':
      !!formValues.pdFileList[pdIndex!]?.selected
      || !!formValues.pdFileList[pdIndex!]?.tomList[tomIndex]?.selected,
    'Инженерные изыскания':
      !!formValues.iiFileList[iiIndex!]?.selected
      || !!formValues.iiFileList[iiIndex!]?.tomList[tomIndex]?.selected,
    'Сбор исходных данных':
      !!formValues.irdFileList[irdIndex!]?.selected
      || !!formValues.irdFileList[irdIndex!]?.tomList[tomIndex]?.selected,
  }


  return {
    selected: selected[selectedProjectPhase],
  }
}
