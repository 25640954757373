import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../../../api/api'
import { AuthEndpoints } from '../../../api/auth/types'
import { AuthData, AuthState } from './auth.types'
import { SetCredentialsPayload } from './auth.payloads'
import { config } from '../../../core/config'
import { isCompany } from '../../../core/types'

let initialState: AuthState

try {
  initialState = JSON.parse(
    localStorage.getItem('auth') || '{}'
  ) as AuthState
} catch (error) {
  initialState = {}
}

const updateState = (
  prevState: AuthState,
  payload: AuthState
) => {
  const { refreshToken, token, refreshDurationMs, tokenDurationMs, isLogout } = payload
  prevState.token = token
  prevState.tokenDurationMs = tokenDurationMs
  prevState.refreshToken = refreshToken
  prevState.refreshDurationMs = refreshDurationMs
  prevState.isLogout = isLogout

  const authData: AuthData = {
    refreshToken,
    token,
    refreshDurationMs,
    tokenDurationMs,
  }
  localStorage.setItem('auth', JSON.stringify(authData))
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<SetCredentialsPayload>) => {
      updateState(state, payload)
    },
    onLogout: (state) => {
      updateState(state, { isLogout: true })
      localStorage.setItem('auth', '{}')

      const subDomain = window.location.host.split('.')[0]
      const currentCompanyName = isCompany(subDomain) ? subDomain : null
      const companyParam = currentCompanyName ? `?company=${currentCompanyName}` : ''
      window.location.replace(`${config.coreURL}${companyParam}`)
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (props) =>
          !!(api.endpoints as AuthEndpoints).loginByToken?.matchFulfilled(
            props
          ),
        (state, { payload }) => {
          if (payload?.token) {
            updateState(state, payload)
          }
        }
      )
  },
})

export const { reducer: authReducer } = authSlice
export const { setCredentials, onLogout } = authSlice.actions
