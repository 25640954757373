import React, { FC, useEffect, useMemo } from 'react'
import { AttachmentsExportDrawerFormProps, AttachmentsExportFormData, } from './AttachmentsExportDrawerForm.types'
import { AttachmentsExportDrawerFormWrapper } from './AttachmentsExportDrawerForm.styles'
import { Form, FormikProvider } from 'formik'
import { Button, Stack } from '@mui/material'
import { useForm } from '../../../../../../hooks/useForm'
import { attachmentsExportValidation } from './validation'
import { useSnackbar } from 'notistack'
import { AttachmentsExportDrawerMainList } from '../AttachmentsExportDrawerMainList'
import { AttachmentsExportDrawerSelectedList } from '../AttachmentsExportDrawerSelectedList'
import { useAttachmentsExportSubmit } from './hooks/useAttachmentsExportSubmit'
import { useGetInitialDocList } from './hooks/useGetInitialDocList'
import { useAppDispatch } from '../../../../../../store/store'
import { onDrawerClose } from '../../../../../../store/slices/documentsPages/drawerInfo'

export const AttachmentsExportDrawerForm: FC<AttachmentsExportDrawerFormProps> = ({ onFormChange }) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { docList } = useGetInitialDocList()
  const initialValues: AttachmentsExportFormData = useMemo(() => ({
    docList,
    selectedAttachments: [],
  }), [docList])

  const { onSubmit } = useAttachmentsExportSubmit()
  const { formik } = useForm({
    validationSchema: attachmentsExportValidation,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { errors, dirty, isSubmitting } = formik

  useEffect(() => {
    if (isSubmitting && errors.selectedAttachments) {
      enqueueSnackbar('Выберите минимум один файл', { variant: 'error' })
    }
  }, [isSubmitting, errors])

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])


  return (
    <AttachmentsExportDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} component={Form} justifyContent='space-between' height='100%'>
          <Stack direction='row' justifyContent='space-between' spacing={2.5} overflow='hidden' height='100%'>
            <AttachmentsExportDrawerMainList />
            <AttachmentsExportDrawerSelectedList />
          </Stack>


          <Stack direction='row' justifyContent='end' alignItems='center'>
            <Stack direction='row' spacing={2}>
              <Button
                type='submit'
                disabled={isSubmitting}
                color='success'
                size='small'
                fullWidth
              >
                Сформировать архив
              </Button>
              <Button
                onClick={() => dispatch(onDrawerClose({ dirty: false, immediately: true }))}
                size='small'
                fullWidth
              >
                Отменить и закрыть
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormikProvider>
    </AttachmentsExportDrawerFormWrapper>
  )
}
