import { useState, useEffect, useMemo } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledCheckbox, StyledSubItemsCheckbox } from "./ManualSubmitAccordion.styles"
import { ManualSubmitAccordionProps } from "./ManualSubmitAccordion.types"
import { Checkbox, Stack, Typography } from "@mui/material"
import { useFormikContext } from "formik"
import { ManualSubmitValues } from "../ManualSubmitDrawer"
import { AccordionDetailsItem } from "../AccordionDetailsItem"
import { RemarkMailGroup, RemarkMailTom } from "api/remarks/types"

export const ManualSubmitAccordion = ({ group, object }: ManualSubmitAccordionProps) => {

  const { values, setFieldValue } = useFormikContext<ManualSubmitValues>()
  const [expanded, setExpanded] = useState<boolean>(false)
  const [checkedObject, setCheckedObject] = useState<boolean>(false)

  const tomsData = useMemo(() => !object ? group?.toms : object.groups.flatMap((group) => group.toms), [group, object])
  const isEverySelected = tomsData?.every(tom => values.selectedToms.some(selectedTom => tom.id === selectedTom.id))

  const onSummaryClick = () => {
    if (isEverySelected) return undefined
    setExpanded(prevState => !prevState)
  }

  const onObjectCheckboxClick = (checked: boolean) => {
    if (tomsData) {
      if (!checked) {
        const updatedSelectedToms = values.selectedToms.filter(
          selectedTom => !tomsData.some(tom => tom.id === selectedTom.id)
        )
        setFieldValue('selectedToms', updatedSelectedToms)
      } else {
        const newToms: RemarkMailTom[] = tomsData.filter(tom => !values.selectedToms.some(selectedTom => selectedTom.id === tom.id)).map((tom) => ({
          id: tom.id,
          name: tom.name,
          status: tom.status,
        }))
        const updatedSelectedToms = [...values.selectedToms, ...newToms]
        setFieldValue('selectedToms', updatedSelectedToms)
      }
    }
  }

  const onGroupCheckboxClick = (checked: boolean, group: RemarkMailGroup) => {
    const data = group.toms
    if (data) {
      if (!checked) {
        const updatedSelectedToms = values.selectedToms.filter(
          selectedTom => !data.some(tom => tom.id === selectedTom.id)
        )
        setFieldValue('selectedToms', updatedSelectedToms)
      } else {
        const newToms: RemarkMailTom[] = data.map((tom) => ({
          id: tom.id,
          name: tom.name,
          status: tom.status,
        }))
        const updatedSelectedToms = [...values.selectedToms, ...newToms]
        setFieldValue('selectedToms', updatedSelectedToms)
      }
    }
  }

  useEffect(() => {
    if (isEverySelected) {
      setCheckedObject(true)
      setExpanded(false)
    } else {
      setCheckedObject(false)
    }
  }, [isEverySelected, tomsData, values.selectedToms])

  return (
    <StyledAccordion
      expanded={expanded}
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
    >
      <StyledAccordionSummary
        onClick={onSummaryClick}
        expandIcon={!isEverySelected ? <ExpandMoreIcon fontSize='medium' /> : null}
      >
        <StyledCheckbox
          control={<Checkbox disableRipple />}
          checked={checkedObject}
          onChange={(e, checked) => onObjectCheckboxClick(checked)}
          onClick={(e) => e.stopPropagation()}
          label={<Typography>{object?.name || group?.name}</Typography>}
        />
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {!object?.groups.length ? (
          <Stack pl={4} >
            {group?.toms.filter(tom => !values.selectedToms.some(selectedTom => selectedTom.id === tom.id)).map((tom) =>
              <AccordionDetailsItem
                tom={tom}
                key={tom.id}
              />
            )}
          </Stack>
        ) :
          object?.groups.map((group) =>
            <Stack pl={4} key={`${object.name}-${group.name}`}>
              <StyledSubItemsCheckbox
                control={<Checkbox disableRipple />}
                checked={values.selectedToms.some(selectedTom => group.toms.some(tom => tom.id === selectedTom.id))}
                onChange={(e, checked) => onGroupCheckboxClick(checked, group)}
                onClick={(e) => e.stopPropagation()}
                label={<Typography>{group.name}</Typography>}
              />

              <Stack pl={4}>
                {group.toms?.filter(tom => !values.selectedToms.some(selectedTom => selectedTom.id === tom.id)).map((tom) =>
                  <AccordionDetailsItem
                    tom={tom}
                    key={tom.id}
                  />
                )}
              </Stack>
            </Stack>
          )

        }
      </StyledAccordionDetails>

    </StyledAccordion>
  )
}