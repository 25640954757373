import styled from "styled-components";
import { HeaderRow } from "../../../InternalAgreementSchemes/components/InternalAgreementTable";
import { Stack, Typography } from "@mui/material";

export const TableHeaderRow = styled(HeaderRow)`
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 0;
`
export const StyledTitle = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`
export const StyledCell = styled(Stack)`
  background-color: ${props => props.theme.palette.bg.lightBlue};
  width: 100%;
  min-width: 945px;
  height: inherit;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;

  & .MuiTypography-root {
    padding: 0 8px;
    color: ${props => props.theme.palette.primary.main};
  }
  min-width: auto;
  height: 30px;
`