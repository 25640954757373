import {
  GetTomsRdForNotificationsRequest,
  GetTomsRdForNotificationsResponse,
  SendTomsRdNotificationsRequest
} from './notificationsApi.types'
import { tomRdApi } from '../tomApi'


export const notificationsRdApi = tomRdApi.injectEndpoints({
  endpoints: (build) => ({
    getTomsRdForNotifications: build.query<GetTomsRdForNotificationsResponse, GetTomsRdForNotificationsRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom/notification/list`,
        method: 'GET',
        params
      }),
      providesTags: [{ type: 'TomRd', id: 'NOTIFICATIONS' }],
    }),
    sendTomsRdNotifications: build.mutation<void, SendTomsRdNotificationsRequest>({
      query: ({ id, tomIds }) => ({
        url: `/project/${id}/tom/notification/notify`,
        method: 'POST',
        body: {
          id: tomIds,
        }
      }),
      invalidatesTags: [{ type: 'TomRd', id: 'NOTIFICATIONS' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomsRdForNotificationsQuery,
  useSendTomsRdNotificationsMutation,
} = notificationsRdApi
