import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Switch, ToggleButtonGroup } from '@mui/material'
import { StyledSelectField } from 'components/FieldForm/styles'
import styled from 'styled-components'
import { LegendColors } from 'types/global'

import { StyledToggleButton } from '../../../Administration/components/AdminRdLegend/styles'

/****************************************** DocsFilter Accordion *************************************************/
export const DocsFilterStyledAccordion = styled(Accordion)`
  box-shadow: none;
  padding-right: 20px;
  &::before {
    display: none;
  }
  &.Mui-expanded {
    margin: 0px;
  }
`
export const DocsFilterStyledAccordionSummary = styled(AccordionSummary)`
  min-height: 36px;
  height: 36px;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.palette.bg.lightBlue};
  border-radius: 4px;
  width: 100%;
  border: 1px solid transparent;

  &.Mui-expanded {
    min-height: 36px;
  }
  gap: 4px;
  justify-content: flex-start;

  & .MuiAccordionSummary-content {
    margin: 0;
    width: 100%;
    justify-content: flex-end;
    overflow: hidden;
    & .MuiTypography-root {
      color: ${(props) => props.theme.palette.primary.main};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-align: start;
      margin-left: 8px;
    }
  }
`
export const DocsFilterStyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & .MuiTypography-root {
    color: ${(props) => props.theme.palette.secondary.dark};
  }
  & .MuiSelect-select {
    /* padding: 9px 16px; */
  }
`

export const DocsStatusSelectField = styled(StyledSelectField)`
  & .MuiInputBase-root {
    background-color: ${(props) => props.theme.palette.bg.white};
  }
`

interface DocsStyledSelectMenuItemProps {
  checked?: boolean
  customColor?: LegendColors
}

export const DocsStyledSelectMenuItem = styled(MenuItem)<DocsStyledSelectMenuItemProps>`
  color: ${(props) => props.customColor && props.theme.palette.legends[props.customColor]};
  background-color: ${(props) => props.checked && props.theme.palette.bg.gray};

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  &.Mui-focusVisible {
    background-color: ${(props) => props.theme.palette.bg.gray};
  }

  &.select-item-button {
    color: ${(props) => props.theme.palette.text.light};
    background-color: ${(props) => props.theme.palette.primary.light};
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: ${(props) => props.theme.palette.primary.main};
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`

export const DocsStyledSwitch = styled(Switch)`
  & .MuiSwitch-track {
    background-color: ${(props) => props.theme.palette.legends.gray} !important;
  }

  & .MuiSwitch-thumb {
    background-color: ${(props) => props.theme.palette.disabled};
  }

  & .MuiSwitch-switchBase.Mui-checked {
    & .MuiSwitch-thumb {
      background-color: ${(props) => props.theme.palette.primary.main};
    }
  }
`

export const DocsStyledToggleButton = styled(StyledToggleButton)`
  font-size: 13px;
  letter-spacing: 0.46px;
  color: ${(props) => props.theme.palette.secondary.dark};
  width: -webkit-fill-available;
  background-color: transparent;
  padding: 8px;
`

export const DocsStyledToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 40px;
  padding-right: 20px;
  border-radius: 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &.MuiToggleButtonGroup-root {
    width: 100%;
  }
`
