import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AgreementState } from './agreement.types'
import {
  SetHoverCardIdPayload,
  SetInitialRoadmapPhaseFromMenuPayload,
  SetIsAddStageToExistPayload,
  SetIsBeenHoveredPayload,
  SetNextRoadmapNumberPayload,
  SetRoadmapFormModePayload,
  SetStagesPayload
} from './agreement.payloads.types'
import { Roadmap } from '../../../api/projectAgreement/types'


let initialState: AgreementState = {
  currentRoadmap: {} as Roadmap,
  roadmapFormMode: 'view',
  initialRoadmapPhaseFromMenu: 'РД',
  nextRoadmapNumber: 0,
  isStageFormDirty: false,

  stageCompany: '',
  stages: [],
  isAddStageToExist: false,

  hoverCardId: null,
  isBeenHovered: false,
}

const agreementSlice = createSlice({
  name: 'agreementState',
  initialState,
  reducers: {
    setCurrentRoadmap: (state, { payload }: PayloadAction<Roadmap>) => {
      state.currentRoadmap = payload
    },
    setRoadmapFormMode: (state, { payload }: PayloadAction<SetRoadmapFormModePayload>) => {
      const { roadmapFormMode } = payload
      state.roadmapFormMode = roadmapFormMode
    },
    setInitialRoadmapPhaseFromMenu: (state, { payload }: PayloadAction<SetInitialRoadmapPhaseFromMenuPayload>) => {
      const { initialRoadmapPhaseFromMenu } = payload
      state.initialRoadmapPhaseFromMenu = initialRoadmapPhaseFromMenu
    },
    setNextRoadmapNumber: (state, { payload }: PayloadAction<SetNextRoadmapNumberPayload>) => {
      const { nextRoadmapNumber } = payload
      state.nextRoadmapNumber = nextRoadmapNumber
    },
    setIsStageFormDirty: (state, { payload }: PayloadAction<boolean>) => {
      state.isStageFormDirty = payload
    },
    setStageCompany: (state, { payload }: PayloadAction<string>) => {
      state.stageCompany = payload
    },
    setStages: (state, { payload }: PayloadAction<SetStagesPayload>) => {
      const { stages } = payload
      state.stages = stages
    },
    setIsAddStageToExist: (state, { payload }: PayloadAction<SetIsAddStageToExistPayload>) => {
      const { isAddStageToExist } = payload
      state.isAddStageToExist = isAddStageToExist
    },
    setHoverCardId: (state, { payload }: PayloadAction<SetHoverCardIdPayload>) => {
      const { hoverCardId } = payload
      state.hoverCardId = hoverCardId
    },
    setIsBeenHovered: (state, { payload }: PayloadAction<SetIsBeenHoveredPayload>) => {
      const { isBeenHovered } = payload
      state.isBeenHovered = isBeenHovered
    },
  },
})

export const { reducer: agreementReducer } = agreementSlice
export const {
  setCurrentRoadmap,
  setRoadmapFormMode,
  setInitialRoadmapPhaseFromMenu,
  setNextRoadmapNumber,
  setIsStageFormDirty,
  setStageCompany,
  setStages,
  setIsAddStageToExist,
  setHoverCardId,
  setIsBeenHovered
} = agreementSlice.actions
