import * as yup from 'yup'

import { parseDateString } from '../../../../utils/formatDate'

export const modelValidation = yup.object({
  title: yup.string().trim().required(),
  objectId: yup.number().required(),
  plannedDate: yup.date().transform(parseDateString).nullable(),
  actualDate: yup
    .date()
    .transform(parseDateString)
    .nullable()
    .when('status', {
      is: 'Согласовано',
      then: yup.date().required('common:errors.required'),
    }),
})
