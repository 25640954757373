import { Autocomplete, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledText = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
`

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  
  & .MuiOutlinedInput-root {
    height: 30px;
    width: 100%;
    padding: 4.5px 18px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${props => props.theme.palette.text.dark};
    background-color: ${props => props.theme.palette.bg.shades};
    border-radius: 8px;
  }

  & .MuiInputLabel-root {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${props => props.theme.palette.disabled};
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(18px, 5px) scale(1);
  }

  & .MuiOutlinedInput-input {
    padding: 0 !important;
  }

  & .MuiAutocomplete-inputRoot {

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.secondary.dark + '3A'};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }    
  }

  & .MuiAutocomplete-endAdornment {
    top: calc(50% - 11px);
    right: 12px;

    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.text.dark};
      font-size: 16px !important;
    }
  }
`
