import styled from 'styled-components'
import { Typography } from '@mui/material'
import { StyledProjectCardHeader } from '../ProjectCard/ProjectCardHeader/styles'
import { StyledCardInfo } from '../UserCardContent/UserCardInfo/styles'
import { FullUserCardWrapper } from '../../AdminUsers/AdminUsers.styles'

export const StyledRdCard = styled(FullUserCardWrapper)`
  z-index: 5;
  min-height: 191px;
  box-shadow: none;

  & .ellipsisText {
    min-height: 42px;
    max-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    position: absolute;
    left: 0;
    right: 0;

    & .ellipsisText {
      display: block;
      overflow: initial;
      max-height: 100%;
    }
  }
`

export const StyledRdCardHeader = styled(StyledProjectCardHeader)`
  padding-bottom: 8px;

  & .MuiCardHeader-action {
    position: absolute;
    right: -4px;
    top: -8px;

    & .MuiButton-root {
      min-width: 32px;
      height: 32px;
      padding: 4px;
      border-radius: 50%;
    }
  }
`

export const StyledRdCardContent = styled(StyledCardInfo)`
  padding-top: 8px !important;
`

export const RdCardTooltip = styled(Typography)`
  font-weight: 700;
  font-size: 12px;
`
export const RdCardText = styled(Typography)`
  font-size: 14px;
`
