import { Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const DrawerHeader = styled(Typography)`
  padding: 20px 0 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #D1D8FA;
`

export const DrawerContent = styled(Stack)`
  height: 100%;
  padding: 20px;
`
export const UploadDrawerText = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
`

export const UploadDrawerAttention = styled(Typography)`
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  color: ${props => props.theme.palette.warning.main};
`