import { Typography } from "@mui/material";
import styled from "styled-components";

export const SnackBarTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.palette.text.light};
`
export const SnackBarText = styled(Typography)`
  font-size: 14px;
  color: ${props => props.theme.palette.text.light}
`