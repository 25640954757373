import styled from "styled-components";
import { Autocomplete, Button, IconButton, Stack, Typography } from "@mui/material";
import { InternalAgreementAutocomplete } from "../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementPerson";
import { InternalAgreementViewField, InternalAgreementWatchDurationField, InternalAgreementWatchDurationText } from "../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementControl";

export const SchemeSelectionWrapper = styled(Stack)`
  align-items: center;
  justify-content: space-between;
`

export const DrawerAutocomplete = styled(InternalAgreementAutocomplete) <{ $width?: string }>`
  max-width: ${props => props.$width && props.$width};
  & .MuiOutlinedInput-root {
    height: 30px;
    width: 100%;
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(18px, 5px) scale(1);
  }
`

export const DrawerTextBlock = styled(Stack)<{ $width?: string }>`
  width: 100%;
  max-width: ${props => props.$width && props.$width};
  align-items: center;
`

export const DrawerTextField = styled(InternalAgreementViewField)`
  max-width: none;
`

export const StyledButton = styled(Button) <{ $selectedSchema?: boolean }>`
  background-color: ${props => props.theme.palette.bg.white};
  border: 1px solid ${props => props.theme.palette.primaryBorderLight};
  text-transform: none;
  color: ${props => props.theme.palette.primary.main};
  font-weight: 400;
  height: 30px;
  font-size: ${props => props.$selectedSchema ? '12px' : '14px'};
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
  margin: ${props => props.$selectedSchema ? '8px' : '8px 16px'};
  width: ${props => props.$selectedSchema ? 'calc(100% - 16px)' : 'calc(100% - 32px)'};

  :hover {
    background-color: ${props => props.theme.palette.bg.lightBlue};
  }
`

export const WatchDurationField = styled(InternalAgreementWatchDurationField)`
`
export const WatchDurationText = styled(InternalAgreementWatchDurationText)`
`
export const StyledIconButton = styled(IconButton)`
  background-color: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.bg.white};
  border-radius: 6px;
  padding: 7px;

  :hover {
    background-color: ${props => props.theme.palette.primary.main};
  }
`
export const StyledText = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
  width: max-content;
`

export const StyledAutocomplete = styled(Autocomplete) <{ $width?: string }>`
  max-width: ${props => props.$width && props.$width};
  width: 100%;
  
  & .MuiOutlinedInput-root {
    height: 30px;
    width: 100%;
    padding: 4.5px 18px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${props => props.theme.palette.text.dark};
    background-color: ${props => props.theme.palette.bg.shades};
    border-radius: 8px;
  }

  & .MuiInputLabel-root {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${props => props.theme.palette.disabled};
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(18px, 5px) scale(1);
  }

  & .MuiOutlinedInput-input {
    padding: 0 !important;
  }

  & .MuiAutocomplete-inputRoot {

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.secondary.dark + '3A'};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }    
  }

  & .MuiAutocomplete-endAdornment {
    top: calc(50% - 11px);
    right: 12px;

    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.text.dark};
      font-size: 16px !important;
    }
  }
`