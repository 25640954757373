import React, { FC, useEffect, useState } from 'react'
import { ProjectAgreementExpandAdviceProps, } from './ProjectAgreementExpandAdvice.types'
import {
  ProjectAgreementExpandAdviceText,
  ProjectAgreementExpandAdviceTextWrapper,
  ProjectAgreementExpandAdviceTitle,
  ProjectAgreementExpandAdviceTitleWrapper,
  ProjectAgreementExpandAdviceWrapper,
  ProjectAgreementExpandCloseButton
} from './ProjectAgreementExpandAdvice.styles'
import { AlertIcon } from '../../../../../assets/icons/AlertIcon'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { getWordWithEnding } from '../../../../../utils/getWordWithEnding'
import { useTypedSelector } from '../../../../../store/store'
import { currentRoadmapSelector } from '../../../../../store/slices/agreement'

export const ProjectAgreementExpandAdvice: FC<ProjectAgreementExpandAdviceProps> = ({ data, ...props }) => {
  const { items, backgroundColor, expandTitle, ExpandIcon, permanentExpired } = data
  const currentRoadmap = useTypedSelector(currentRoadmapSelector)

  const [expanded, setExpanded] = useState<boolean>(false)

  const itemsForShow = items.filter(item => item.show)

  useEffect(() => {
    setExpanded(false)
  }, [currentRoadmap])

  useEffect(() => {
    setExpanded(!!permanentExpired)
  }, [permanentExpired])

  const onExpandClick = () => {
    setExpanded(true)
  }

  const onShrinkClick = () => {
    setExpanded(false)
  }

  return (
    <ProjectAgreementExpandAdviceWrapper
      onClick={expanded ? onShrinkClick : onExpandClick}
      expanded={expanded}
      backgroundColor={backgroundColor}
      {...props}
    >
      <ProjectAgreementExpandAdviceTitleWrapper>
        {ExpandIcon || <AlertIcon fontSize='medium' />}

        <ProjectAgreementExpandAdviceTitle
          variant='body2'
          expanded={expanded}
          parentBackgroundColor={backgroundColor}
        >
          {expanded
            ? expandTitle || 'Предупреждение'
            : getWordWithEnding(itemsForShow.length, 'Предупреждение', 'Предупреждения', 'Предупреждений')
          }
        </ProjectAgreementExpandAdviceTitle>
      </ProjectAgreementExpandAdviceTitleWrapper>


      {expanded && (
        <>
          <ProjectAgreementExpandAdviceTextWrapper>
            {itemsForShow.map(item => (
              <ProjectAgreementExpandAdviceText
                variant='body2'
                expanded={expanded}
                parentBackgroundColor={backgroundColor}
              >
                {item.text || ''}
              </ProjectAgreementExpandAdviceText>
            ))}
          </ProjectAgreementExpandAdviceTextWrapper>

          {!permanentExpired && (
            <ProjectAgreementExpandCloseButton onClick={onShrinkClick}>
              <HighlightOffIcon color='warning' />
            </ProjectAgreementExpandCloseButton>
          )}
        </>

      )}
    </ProjectAgreementExpandAdviceWrapper>
  )
}
