import React from "react"
import { MoreVert as MenuIcon } from '@mui/icons-material'
import PrintIcon from '@mui/icons-material/Print';
import { useState, MouseEvent } from "react"
import { ActionButton } from "../../PragmaPdfViewer.styles"
import { Menu, MenuItem, Typography } from "@mui/material"
import { DownloadDocumentComponent } from "../DownloadDocumentComponent"
import { MenuToolBarProps } from "./MenuToolBar.types"
import printJS from "print-js";

export const MenuToolBar = ({ file }: MenuToolBarProps) => {

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedItem, setSelectedItem] = useState<number | null>(null)

  const getSelectContainer = () => document.fullscreenElement || document.body

  const onOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ActionButton onClick={e => onOpenMenu(e)}>
        <MenuIcon />
      </ActionButton>
      <Menu open={!!anchorEl} anchorEl={anchorEl}
        onClose={onCloseMenu}
        container={getSelectContainer()}
        PaperProps={{ sx: { minWidth: 210, mt: 1.25, borderRadius: '4px 0 4px 4px', zIndex: 1302 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => setSelectedItem(1)}>
          <DownloadDocumentComponent file={file} selectedItem={selectedItem} clearAction={() => setSelectedItem(null)} />
        </MenuItem>
        <MenuItem onClick={() => printJS({
          printable: file,
          type: 'pdf',
          showModal: true,
          modalMessage: 'Получение документа...'
        })}>
          <PrintIcon />
          <Typography ml={1.5}>Печать</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}