import { useMemo } from "react"
import { currentSchemaSelector } from "../../../store/slices/internalAgreement"
import { profileSelector } from "../../../store/slices/profile"
import { useTypedSelector } from "../../../store/store"
import { useGetInnermapMembersQuery } from "../../../api/internalAgreement"

interface UseAccessData {
  isAdmin: boolean
  isModerator: boolean
  isExpand: boolean
  isExpandAcess?: boolean
}

export const useAccess = (projectId?: number): UseAccessData => {

  const profile = useTypedSelector(profileSelector)
  const currentSchema = useTypedSelector(currentSchemaSelector)

  const { data: innermapMembers } = useGetInnermapMembersQuery(
    { projectId: projectId!, innermapId: currentSchema?.id },
     { skip: !(currentSchema?.id && projectId) }
    )
  const { data: innermapMembersData } = innermapMembers || {}

  const isModerator = useMemo(() => {
    if (!currentSchema?.moderator) return false
    return currentSchema.moderator.id === profile.employeeId
  }, [currentSchema])

  const isAdmin = profile.role === 'admin'
  const isExpand = currentSchema?.expandability

  const isExpandAcess = useMemo(() => {
    if (!innermapMembersData) return false
    return innermapMembersData.flatMap(({ members }) => members).some(user => user.person.id === profile.employeeId)
  }, [innermapMembersData, profile.employeeId])

  return { isAdmin, isModerator, isExpand, isExpandAcess }
}