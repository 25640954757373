import {
  GetDetailedSidebarIiRequest,
  GetDetailedSidebarIiResponse,
  GetSidebarIiRequest,
  GetSidebarIiResponse
} from './sidebarApi.types'
import { tomIiApi } from '../tomApi'
import { api } from '../../../api'

export const sidebarIiApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSidebarIi: build.query<GetSidebarIiResponse, GetSidebarIiRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom-ii/side-menu-map`,
        method: 'GET',
      }),
      providesTags: [
        'TomIi',
        { type: 'TomIi', id: 'SIDEBAR_MENU' },
        { type: 'TomIi', id: 'UPLOAD' },
        { type: 'TomIi', id: 'DELETE' },
      ],
    }),
    getDetailedSidebarIi: build.query<GetDetailedSidebarIiResponse, GetDetailedSidebarIiRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom-ii/detail-side-menu-map`,
        method: 'GET',
      }),
      providesTags: [
        'TomIi',
        { type: 'TomIi', id: 'UPLOAD' },
        { type: 'TomIi', id: 'DELETE' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetSidebarIiQuery,
  useGetDetailedSidebarIiQuery,
} = sidebarIiApi