interface IListItem {
  primary: string
  secondary: string
}

export const listItem: IListItem[] = [
  {
    primary: 'Участник согласования',
    secondary:
      'Добавленный во внутреннюю процедуру участник согласования может просматривать схему и пакеты документов, созданных по ней',
  },
  {
    primary: 'Утверждение',
    secondary:
      'Сотрудник, утверждающий согласование документации, завершает процедуру, только одно лицо может иметь право утверждения',
  },
  {
    primary: 'Визирование',
    secondary: 'Участники процедуры могут визировать документы, число виз в процедуре согласования не ограничено',
  },
  {
    primary: 'Подписание',
    secondary: 'Предоставляет право подписания документов в соответствии с процедурой с помощью ЭЦП *',
  },
  {
    primary: 'Замечания',
    secondary: 'Открывает доступ к написанию замечений к документам внутри системы',
  },
]
