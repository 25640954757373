import { useEffect, useMemo, useState } from "react"
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledCheckbox } from "./ModelViewAccordion.styles"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ModelViewAccordionProps } from "./ModelViewAccordion.types"
import { Checkbox, Typography } from "@mui/material";
import { useAppDispatch, useTypedSelector } from "store/store";
import { selectedModelsSelector } from "store/slices/tim/selectors/tim.selectors";
import { setSelectedModels } from "store/slices/tim/tim";
import { SubAccordion } from "../SubAccordion";
import { SelectedModels } from "store/slices/tim/tim.types";
import Tooltip from "components/Tooltip";

export const ModelViewAccordion = ({ object }: ModelViewAccordionProps) => {
  const dispatch = useAppDispatch()
  const selectedModels = useTypedSelector(selectedModelsSelector)
  const [expanded, setExpanded] = useState<boolean>(false)
  const [checkedObject, setCheckedObject] = useState<boolean>(false)

  const parsedTims = useMemo(() => object.groups.flatMap((group) => group.models), [object])
  const isSomeSelected = parsedTims?.some(tim => selectedModels.some(selectedTim => tim.id === selectedTim.timID))

  const onSummaryClick = () => {
    setExpanded(prevState => !prevState)
  }

  const onObjectCheckboxClick = (checked: boolean) => {
    if (parsedTims) {
      if (!checked) {
        const updatedSelectedTims = selectedModels.filter(
          selectedTim => !parsedTims.some(tim => tim.id === selectedTim.timID)
        )
        dispatch(setSelectedModels(updatedSelectedTims))
      } else {
        const newTims: SelectedModels[] = parsedTims.filter(
          tim => !selectedModels.some(selectedTim => selectedTim.timID === tim.id)
        ).map(tim => ({ timID: tim.id, name: tim.name, tanglID: tim.versions[0].tanglID! }))
        const updatedSelectedTims = [...selectedModels, ...newTims]
        dispatch(setSelectedModels(updatedSelectedTims))
      }
    }
  }

  useEffect(() => {
    if (isSomeSelected) {
      setCheckedObject(true)
    } else {
      setCheckedObject(false)
    }
  }, [isSomeSelected, parsedTims, selectedModels])

  return (
    <>
      <StyledAccordion
        expanded={expanded}
        disableGutters
        TransitionProps={{ unmountOnExit: true }}
      >
        <StyledAccordionSummary
          onClick={onSummaryClick}
          expandIcon={<ArrowDropDownIcon fontSize='medium' />}
        >
          <StyledCheckbox
            control={<Checkbox disableRipple />}
            labelPlacement='start'
            checked={checkedObject}
            onChange={(e, checked) => onObjectCheckboxClick(checked)}
            onClick={(e) => e.stopPropagation()}
            label={
              <Tooltip variant="light" placement="right" title={object.name.length > 40 ? object.name : ''}>
                <Typography>{object.name}</Typography>
              </Tooltip>
            }
          />
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {object?.groups.map((group) =>
            <SubAccordion item={group} key={`${object.name}-${group.name}`} />
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </>
  )
}