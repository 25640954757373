import React, { ReactElement } from 'react'
import { UseRenderDrawersProps, UseRenderDrawersResponse } from './useRenderDrawers.types'

import ObjectsDrawer from '../../components/ObjectsDrawer'
import PdDrawer from '../../../Administration/AdminPd/PdDrawer'
import { ProjectPhase } from '../../../../types/project'
import { useTypedSelector } from '../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../store/slices/documentsPages/projectInfo'
import { RdUploadDrawer, RdUploadDrawerVariant } from '../../../Administration/AdminRd/RdUploadDrawer'
import ObjectsUploadDrawer from '../../components/ObjectsUploadDrawer'
import { rdViewTabSelector } from '../../../../store/slices/documentsPages/objects'

export const useRenderDrawers = ({
  chosenId,
  openedDrawer,
  onDrawerClose,
}: UseRenderDrawersProps): UseRenderDrawersResponse => {
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const rdViewTab = useTypedSelector(rdViewTabSelector)

  // Rd object
  const objectsDrawer = (
    <ObjectsDrawer
      open={openedDrawer === 'object'}
      onClose={onDrawerClose}
      projectId={project.id}
      objectId={chosenId}
    />
  )


  // Rdp
  const RdpDrawer = (
    <PdDrawer
      variant='rdp'
      open={openedDrawer === 'rdp'}
      onClose={onDrawerClose}
      id={chosenId}
      projectId={project.id}
    />
  )

  // Pd
  const PpdDrawer = (
    <PdDrawer
      variant='ppd'
      open={openedDrawer === 'ppd'}
      onClose={onDrawerClose}
      id={chosenId}
      projectId={project.id}
    />
  )


  // Ii
  const IiDrawer = (
    <PdDrawer
      variant='ii'
      open={openedDrawer === 'ii'}
      onClose={onDrawerClose}
      id={chosenId}
      projectId={project.id}
    />
  )


  // Ird
  const IrdDrawer = (
    <PdDrawer
      variant='ird'
      open={openedDrawer === 'ird'}
      onClose={onDrawerClose}
      id={chosenId}
      projectId={project.id}
    />
  )


  // Upload
  const objectsUploadDrawer = (
    <ObjectsUploadDrawer
      open={openedDrawer === 'objectsUpload'}
      onClose={onDrawerClose}
      projectId={project.id}
    />
  )

  const uploadDrawerVariantByPhase: Record<ProjectPhase, RdUploadDrawerVariant> = {
    'Рабочая документация': 'rdp',
    'Проектная документация': 'ppd',
    'Инженерные изыскания': 'ii',
    'Сбор исходных данных': 'ird',
  }

  const uploadDrawerOpenByPhase: Record<ProjectPhase, boolean> = {
    'Рабочая документация': openedDrawer === 'rdpUpload',
    'Проектная документация': openedDrawer === 'ppdUpload',
    'Инженерные изыскания': openedDrawer === 'iiUpload',
    'Сбор исходных данных': openedDrawer === 'irdUpload',
  }

  const UploadDrawer = (
    <RdUploadDrawer
      variant={uploadDrawerVariantByPhase[selectedProjectPhase]}
      open={uploadDrawerOpenByPhase[selectedProjectPhase]}
      onClose={onDrawerClose}
      projectId={project.id}
    />
  )

  const UploadDrawerByPhase = ((selectedProjectPhase === 'Рабочая документация' && rdViewTab === 'objects'))
    ? objectsUploadDrawer
    : UploadDrawer


  // Common
  const DrawerByPhase: Record<ProjectPhase, ReactElement> = {
    'Рабочая документация': rdViewTab === 'objects' ? objectsDrawer : RdpDrawer,
    'Проектная документация': PpdDrawer,
    'Инженерные изыскания': IiDrawer,
    'Сбор исходных данных': IrdDrawer,
  }

  return {
    DrawerByPhase: DrawerByPhase[selectedProjectPhase],
    UploadDrawerByPhase
  }
}
