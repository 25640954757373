import React, { SyntheticEvent } from 'react'
import { Tooltip as MuiTooltip, Stack, Typography } from '@mui/material'
import { theme } from '../../styles/theme'
import { TooltipProps } from './Tooltip.types'
import { isIOS, isMobile } from '../../utils/isIOS'
import InfoIcon from '../../assets/icons/InfoIcon'

const Tooltip: React.FC<TooltipProps> = ({ title, customTitle, variant = 'dark', maxWidth, fontSize, icon, children, ...props }) => {
  if (!(title || customTitle)) return <>{children}</>

  const preventOnClickOnMobileIos = (e: SyntheticEvent) => {
    if (isIOS() && isMobile()) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <MuiTooltip
      onClick={preventOnClickOnMobileIos}
      title={
        customTitle
          ? customTitle
          : (
            <Stack direction='row' spacing={1}>
              {icon && <InfoIcon fontSize='medium' sx={{ color: '#D1D8FA' }} />}
              <Typography variant='tooltip'
                fontSize={fontSize || 12}
                style={{
                  overflowWrap: 'anywhere',
                  textTransform: 'initial',
                  color: variant === 'light'
                    ? theme.palette.secondary.dark
                    : theme.palette.text.light
                }}>
                {title}
              </Typography>
            </Stack>
          )
      }
      arrow
      componentsProps={{
        tooltip: {
          style: {
            padding: props.padding || '8px 12px',
            marginTop: 6,
            maxWidth: maxWidth || 300,
            backgroundColor: variant === 'light'
              ? theme.palette.bg.white
              : theme.palette.secondary.dark,
            boxShadow: theme.shadows[10]
          }
        },
        arrow: {
          style: {
            color: variant === 'light'
              ? theme.palette.bg.white
              : theme.palette.secondary.dark
          }
        }
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  )
}

export default Tooltip
