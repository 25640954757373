import styled from "styled-components";
import { IconButton, Stack, Typography } from "@mui/material";
import { HeaderRow } from "../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementTable";

export const TableHeaderRow = styled(HeaderRow)`
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 0;
`
export const StyledTitle = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`
export const EmptyTableTitle = styled(Stack)`
  color: ${props => props.theme.palette.secondary.gray};
  align-items: center;
  justify-content: center;
  padding: 20px;
`
export const StyledIconButton = styled(IconButton)`
  padding: 6px;
  border-radius: 6px;
  align-self: center;
  color: ${props => props.theme.palette.text.light};
  background-color: ${props => props.theme.palette.primary.main};

  &:hover {
    background-color: ${props => props.theme.palette.primary.main};
  }
`