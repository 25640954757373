import { Stack } from "@mui/material"
import { SnackBarText, SnackBarTitle } from "./SnackBarMessage.styles"

interface SnackBarMessageProps {
  title: string
  text: string
}

export const SnackBarMessage = ({ title, text }: SnackBarMessageProps) => {
  return (
    <Stack>
      <SnackBarTitle>{title}</SnackBarTitle>
      <SnackBarText>{text}</SnackBarText>
    </Stack>
  )
}