import React, { FC } from 'react'
import { FormItemProps } from './FormItem.types'
import { Stack, Typography } from '@mui/material'

export const FormItem: FC<FormItemProps> = ({ title, input, Icon }) => {
  return (
    <Stack direction='row' justifyContent='space-between' alignItems='start' width='100%' spacing={2}>
      <Stack direction='row' alignItems='center' spacing={0.75} marginTop='11px' minWidth={157} textAlign='left' className='formItemTitle'>
        {Icon}

        <Typography variant='body2'>
          {title}:
        </Typography>
      </Stack>

      {input}
    </Stack>
  )
}
