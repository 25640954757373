import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const MappingIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <mask id='path-1-inside-1_2648_70399'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M3 4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8V4ZM5 5H19V7H5V5ZM3 12C3 11.4477 3.44772 11 4 11H7C7.55228 11 8 11.4477 8 12V14C8 14.5523 7.55228 15 7 15H4C3.44772 15 3 14.5523 3 14V12ZM3 18C3 17.4477 3.44772 17 4 17H7C7.55228 17 8 17.4477 8 18V20C8 20.5523 7.55228 21 7 21H4C3.44772 21 3 20.5523 3 20V18ZM11 11C10.4477 11 10 11.4477 10 12V14C10 14.5523 10.4477 15 11 15H20C20.5523 15 21 14.5523 21 14V12C21 11.4477 20.5523 11 20 11H11ZM10 18C10 17.4477 10.4477 17 11 17H20C20.5523 17 21 17.4477 21 18V20C21 20.5523 20.5523 21 20 21H11C10.4477 21 10 20.5523 10 20V18Z'
        />
      </mask>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3 4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8V4ZM5 5H19V7H5V5ZM3 12C3 11.4477 3.44772 11 4 11H7C7.55228 11 8 11.4477 8 12V14C8 14.5523 7.55228 15 7 15H4C3.44772 15 3 14.5523 3 14V12ZM3 18C3 17.4477 3.44772 17 4 17H7C7.55228 17 8 17.4477 8 18V20C8 20.5523 7.55228 21 7 21H4C3.44772 21 3 20.5523 3 20V18ZM11 11C10.4477 11 10 11.4477 10 12V14C10 14.5523 10.4477 15 11 15H20C20.5523 15 21 14.5523 21 14V12C21 11.4477 20.5523 11 20 11H11ZM10 18C10 17.4477 10.4477 17 11 17H20C20.5523 17 21 17.4477 21 18V20C21 20.5523 20.5523 21 20 21H11C10.4477 21 10 20.5523 10 20V18Z'
      />
      <path
        d='M19 5H21V3H19V5ZM5 5V3H3V5H5ZM19 7V9H21V7H19ZM5 7H3V9H5V7ZM4 1C2.34315 1 1 2.34315 1 4H5C5 4.55228 4.55229 5 4 5V1ZM20 1H4V5H20V1ZM23 4C23 2.34315 21.6569 1 20 1V5C19.4477 5 19 4.55229 19 4H23ZM23 8V4H19V8H23ZM20 11C21.6569 11 23 9.65685 23 8H19C19 7.44771 19.4477 7 20 7V11ZM4 11H20V7H4V11ZM1 8C1 9.65686 2.34315 11 4 11V7C4.55228 7 5 7.44771 5 8H1ZM1 4V8H5V4H1ZM19 3H5V7H19V3ZM21 7V5H17V7H21ZM5 9H19V5H5V9ZM3 5V7H7V5H3ZM4 9C2.34315 9 1 10.3431 1 12H5C5 12.5523 4.55228 13 4 13V9ZM7 9H4V13H7V9ZM10 12C10 10.3431 8.65685 9 7 9V13C6.44772 13 6 12.5523 6 12H10ZM10 14V12H6V14H10ZM7 17C8.65685 17 10 15.6569 10 14H6C6 13.4477 6.44771 13 7 13V17ZM4 17H7V13H4V17ZM1 14C1 15.6569 2.34314 17 4 17V13C4.55229 13 5 13.4477 5 14H1ZM1 12V14H5V12H1ZM4 15C2.34315 15 1 16.3431 1 18H5C5 18.5523 4.55228 19 4 19V15ZM7 15H4V19H7V15ZM10 18C10 16.3431 8.65685 15 7 15V19C6.44772 19 6 18.5523 6 18H10ZM10 20V18H6V20H10ZM7 23C8.65685 23 10 21.6569 10 20H6C6 19.4477 6.44771 19 7 19V23ZM4 23H7V19H4V23ZM1 20C1 21.6569 2.34314 23 4 23V19C4.55229 19 5 19.4477 5 20H1ZM1 18V20H5V18H1ZM12 12C12 12.5523 11.5523 13 11 13V9C9.34315 9 8 10.3431 8 12H12ZM12 14V12H8V14H12ZM11 13C11.5523 13 12 13.4477 12 14H8C8 15.6569 9.34314 17 11 17V13ZM20 13H11V17H20V13ZM19 14C19 13.4477 19.4477 13 20 13V17C21.6569 17 23 15.6569 23 14H19ZM19 12V14H23V12H19ZM20 13C19.4477 13 19 12.5523 19 12H23C23 10.3431 21.6569 9 20 9V13ZM11 13H20V9H11V13ZM11 15C9.34315 15 8 16.3431 8 18H12C12 18.5523 11.5523 19 11 19V15ZM20 15H11V19H20V15ZM23 18C23 16.3431 21.6569 15 20 15V19C19.4477 19 19 18.5523 19 18H23ZM23 20V18H19V20H23ZM20 23C21.6569 23 23 21.6569 23 20H19C19 19.4477 19.4477 19 20 19V23ZM11 23H20V19H11V23ZM8 20C8 21.6569 9.34314 23 11 23V19C11.5523 19 12 19.4477 12 20H8ZM8 18V20H12V18H8Z'
        mask='url(#path-1-inside-1_2648_70399)'
      />
    </SvgIcon>
  )
}

export default MappingIcon