import { PopoverOrigin } from "@mui/material";
import { GetRemarkOnIdResponse } from "api/remarks/api.types";

export interface IRemarkEliminationHistoryProps {
  remarkResponse: GetRemarkOnIdResponse | null;
  onFormChange: (value: boolean) => void;
}

export const anchorOriginStatusMenu: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

export const transformOriginStatusMenu: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};
