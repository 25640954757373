import { Stack, Typography } from "@mui/material"
import { StyledCell } from "./DepartmentRow.styles"
import { DepartmentRowProps } from "./DepartmentRow.types"
import { useAccess } from "../../../../../../../Schemes/InternalAgreementSchemes"
import { MembersMenu } from "../../../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementTable/components/MembersMenu"

export const DepartmentRow = ({ department, openForm, deleteDepartment }: DepartmentRowProps) => {

  const { isAdmin, isModerator } = useAccess()

  const handleAddMember = () => openForm(department)
  const handleDeleteDepartment = () => deleteDepartment(department)

  return (
    <Stack direction='row' spacing={1}>
      <StyledCell>
        <Typography variant='body2'>{department}</Typography>
      </StyledCell>
      {(isAdmin || isModerator) &&
        <MembersMenu
          onFirstItemClick={handleAddMember}
          onSecondItemClick={handleDeleteDepartment}
          firstItemTitle='Добавить участника'
          secondItemTitle='Удалить департамент'
          $color="lightBlue" />
      }
    </Stack>
  )
}