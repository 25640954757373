import { FC, MouseEvent, useCallback } from 'react'
import { PopoverProps } from './Popover.types'
import { Popover as MuiPopover } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { PopoverCloseButton, PopoverContentWrapper } from './Popover.styles'

export const Popover: FC<PopoverProps> = ({ onWrapperClick, onClose, children, ...popoverProps }) => {
  const wrapperClickHandler = useCallback((e: MouseEvent) => {
    e.stopPropagation()
    onWrapperClick?.(e)
  }, [onWrapperClick])

  return (
    <MuiPopover
      onClose={onClose}
      anchorReference='anchorEl'
      anchorOrigin={{
        vertical: -10,
        horizontal: 50,
      }}
      {...popoverProps}
    >
      <PopoverContentWrapper
        onClick={wrapperClickHandler}
        className='popover-content-wrapper'
        spacing={1}
        boxShadow={24}
      >
        {children}

        <PopoverCloseButton onClick={onClose}>
          <CloseIcon fontSize='small' color='secondary' />
        </PopoverCloseButton>
      </PopoverContentWrapper>
    </MuiPopover>
  )
}
