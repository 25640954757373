import { SearchCompanyProps } from "./SearchCompany.types"
import { StyledTextField } from "./SearchCompany.styles"
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment } from "@mui/material";

export const SearchCompany = ({ searchCompanyValue, setSearchCompanyValue }: SearchCompanyProps) => {

  return (
    <StyledTextField
      type="text"
      fullWidth

      placeholder="Поиск компании"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchCompanyValue ? (
              <IconButton sx={{ padding: 0 }} onClick={() => setSearchCompanyValue('')}>
                <ClearIcon fontSize="medium" />
              </IconButton>
            ) : (
              <SearchIcon fontSize="medium" />
            )}
          </InputAdornment>
        )
      }}
      value={searchCompanyValue}
      onChange={(e) => setSearchCompanyValue(e.target.value)} />
  )
}
