import styled from 'styled-components'
import { alpha, CardHeader, IconButton } from '@mui/material'

export const StyledProjectCardHeader = styled(CardHeader)`
  position: relative;
  padding: 0 0 20px 0;

  .MuiCardHeader-content {
    width: 100%;
    align-self: flex-end;
    text-align: left;
  }
`

export const ProjectCardAgreementButton = styled(IconButton)`
  padding: 3px;
  color: ${props => props.theme.palette.info.main};
  border: ${props => `1px solid ${props.theme.palette.info.main}`};
  border-radius: 6px;
  
  &:hover {
    background-color: ${props => alpha(props.theme.palette.info.main, 0.05)};
  }
  
  & a {
    display: flex;
    color: inherit;
  }
`