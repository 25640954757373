import { UseRenderAccordionsResponse } from './useRenderAccordions.types'
import React, { ReactElement } from 'react'
import { useFormikContext } from 'formik'
import { ExportArchiveFormData } from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { ExportArchiveAccordion } from '../../../ExportArchiveAccordion'
import { ProjectPhase } from '../../../../../../../../types/project'
import { useTypedSelector } from '../../../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../../../store/slices/documentsPages/projectInfo'


export const useRenderAccordions = (): UseRenderAccordionsResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { values: formValues } = useFormikContext<ExportArchiveFormData>()

  const { rdFileList, pdFileList, iiFileList, irdFileList } = formValues

  // Rd
  const rdData = rdFileList?.map((object, index) => (
    <ExportArchiveAccordion
      object={object}
      objectIndex={index}
      key={object.name}
    />
  ))


  // Pd
  const pdData = pdFileList?.map((pd, index) => (
    <ExportArchiveAccordion pd={pd} pdIndex={index} key={pd.name} />
  ))


  // Ii
  const iiData = iiFileList?.map((ii, index) => (
    <ExportArchiveAccordion ii={ii} iiIndex={index} key={ii.name} />
  ))


  // Ird
  const irdData = irdFileList?.map((ird, index) => (
    <ExportArchiveAccordion ird={ird} irdIndex={index} key={ird.name} />
  ))


  // Common
  const accordions: Record<ProjectPhase, ReactElement[]> = {
    'Рабочая документация': rdData,
    'Проектная документация': pdData,
    'Инженерные изыскания': iiData,
    'Сбор исходных данных': irdData,
  }

  return {
    accordions: accordions[selectedProjectPhase],
  }
}
