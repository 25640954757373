import React from 'react'
import { Box, Stack } from '@mui/material'
import { HoveredButton, ProjectCardInfoWrapper } from './styles'
import { CardInfoProps } from './CardInfo.types'
import InfoRow from '../../../../components/InfoRow'
import { theme } from '../../../../styles/theme'
import ObjectsIcon from '../../../../assets/icons/ObjectsIcon'
import GradingIcon from '@mui/icons-material/Grading'
import EventNoteIcon from '@mui/icons-material/EventNote'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { projectTypeShort } from '../../../../types/project'
import RoomIcon from '@mui/icons-material/Room'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import DirectorIcon from '../../../../assets/icons/DirectorIcon'
import BuildingIcon from '../../../../assets/icons/BuildingIcon'
import { InfoRowData } from '../../../../components/InfoRow/InfoRow.types'
import { InfoRowLegend, InfoRowLegendData } from '../InfoRowLegend'

const CardInfo: React.FC<CardInfoProps> = ({
  projectWithStatistics,
  onCardObjectsClick,
  onCardPdsClick,
  pdAccess,
  rdAccess
}) => {
  const {
    project,
    groupCount,
    responsiblePersons,
    addressData,
  } = projectWithStatistics
  const { id, status, phase, type, startDate, endDate } = project
  const { architector, client } = responsiblePersons

  const generalRowsData: InfoRowData[] = [
    {
      icon: EventNoteIcon,
      title: 'Срок реализации',
      value: `${startDate} — ${endDate}`,
      iconProps: {
        fontSize: 'small',
      },
    },
    {
      icon: InsertDriveFileOutlinedIcon,
      title: 'Тип проекта',
      value: projectTypeShort[type],
      iconProps: {
        fontSize: 'small',
      },
    },
    {
      icon: RoomIcon,
      title: 'Регион',
      value: addressData.region || '—',
      iconProps: {
        fontSize: 'small',
      },
    },
  ]

  const infoRowsData: InfoRowData[] = [
    {
      icon: ErrorOutlineIcon,
      title: 'Статус проекта',
      value: status,
      iconProps: {
        fontSize: 'small',
      },
    },
    {
      icon: GradingIcon,
      title: 'Стадия проекта',
      value: phase,
      color: theme.palette.primary.main,
      iconProps: {
        fontSize: 'small',
      },
    },
  ]

  const personsRowsData: InfoRowLegendData[] = [
    {
      icon: DirectorIcon,
      title: 'Заказчик',
      value: client.company,
      subValue: client.customName,
      legendId: client.id,
      iconProps: {
        fontSize: 'small',
      },
    },
    {
      icon: BuildingIcon,
      title: 'Проектировщик',
      value: architector.company,
      subValue: architector.customName,
      legendId: architector.id,
      iconProps: {
        fontSize: 'small',
      },
    },
  ]


  return (
    <Box>
      <Stack spacing={1}>
        <Stack direction='row' alignItems='center' spacing={2.5}>
          {pdAccess &&
            <HoveredButton
              onClick={(e) => onCardPdsClick(e, id)}
              startIcon={<GradingIcon />}
              bgColor={theme.palette.bg.shades}
              variant='text'
              fullWidth
            >
              Разделы ПД ({groupCount?.pd || ''})
            </HoveredButton>
          }
          {rdAccess &&
            <HoveredButton
              onClick={(e) => onCardObjectsClick(e, id)}
              startIcon={<ObjectsIcon />}
              bgColor={theme.palette.bg.shades}
              variant='text'
              fullWidth
            >
              Объекты ({groupCount?.obj || ''})
            </HoveredButton>
          }
        </Stack>
        <ProjectCardInfoWrapper
          title=''
          variant='primary'
          contentWrapper='stack'
          bordered='border'
        >
          {generalRowsData.map(row => (
            <InfoRow rowData={row} key={row.title} />
          ))}
        </ProjectCardInfoWrapper>

        <ProjectCardInfoWrapper
          title=''
          variant='primary'
          contentWrapper='stack'
          bordered='border'
        >
          {infoRowsData.map(row => (
            <InfoRow rowData={row} key={row.title} />
          ))}
        </ProjectCardInfoWrapper>

        <ProjectCardInfoWrapper
          title=''
          variant='primary'
          contentWrapper='stack'
          bordered='border'
        >
          {personsRowsData.map(row => (
            <InfoRowLegend data={row} key={row.title} />
          ))}
        </ProjectCardInfoWrapper>

      </Stack>
    </Box>
  )
}

export default CardInfo