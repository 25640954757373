import styled from 'styled-components'
import { Box, Typography } from '@mui/material'

interface IProjectInfoWrapperProps {
  widgetsCount: number
}

export const ProjectInfoWrapper = styled(Box)<IProjectInfoWrapperProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, ${(props) => (props.widgetsCount > 1 ? 1 : 0)}fr));
  grid-template-rows: repeat(4, minmax(215px, 1fr));
  grid-auto-flow: dense;
  grid-auto-rows: 210px;
  gap: 20px;
  max-height: 100%;
`
