import { LegendColors } from "../global"

export type TimStatus =
  'Согласовано'
  | 'Корректировка'
  | 'На согласовании'
  | 'Разработка'
  | 'Не в работе'

export type TimStatusView =
  'Не установлен'
  | 'Согласовано'
  | 'Корректировка'
  | 'На согласовании'
  | 'Разработка'
  | 'Не в работе'

export type TimStatusLabels = TimStatusView | 'Все модели'
export type TimStatusLabelsEn = 'agreed' | 'all' | 'correction' | 'development' | 'notInWork' | 'notSet' | 'toAgreed'

export const timStatusArray: TimStatusView[] = ["Согласовано", "Корректировка", "На согласовании", "Разработка", "Не в работе", "Не установлен"]  

export interface TimStatusLabelsRuToEn extends Record<TimStatusLabels, TimStatusLabelsEn> { }

export const timStatusLabelsRuToEn: TimStatusLabelsRuToEn = {
  "Все модели": 'all',
  "На согласовании": 'toAgreed',
  "Не в работе": 'notInWork',
  "Не установлен": 'notSet',
  "Корректировка": 'correction',
  "Разработка": 'development',
  "Согласовано": 'agreed'
}

export const timStatusesColors: Record<TimStatusView, LegendColors | '#F6F7FB'> = {
  'Не установлен': '#F6F7FB',
  'Согласовано': 'lightgreen',
  'Корректировка': 'red',
  'На согласовании': 'yellow',
  'Разработка': 'blue',
  'Не в работе': 'disabled',
}

// ---meta interface--->

export interface MetaInterface {
  Guid: string;
  Id: number;
  ElementGuid: string;
  Category: string;
  Type: string;
  Name: string;
  IsRef: boolean;
  Meta: Meta;
}

export interface Meta {
  Element: object;
}

// <--meta interface---