import { matchRoutes, Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import Header from "../../components/Header"
import { MainContainer, InnerContainer } from "../HomeLayout/styles"
import { useBreadcrumbMenu } from "../../hooks/useBreadcrumbMenu"
import useBreadcrumbs from "../../hooks/useBreadcrumbs"
import { useGetProjectByIdQuery } from "../../api/projects"
import { useAppDispatch, useTypedSelector } from "../../store/store"
import { profileSelector } from "../../store/slices/profile"
import { useCallback, useEffect } from "react"
import { Stack } from "@mui/material"
import { setDefaultProjectInfo } from "../../store/slices/documentsPages/projectInfo"
import Progress from "../../components/Progress"
import { openedDrawerSelector, setOpenedDrawer } from "store/slices/documentsPages/drawerInfo"
import { HelpButton } from "./components/HelpButton"

export const ModelsLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { role } = useTypedSelector(profileSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const timViewPagePatch = '/project/:projectId/tim/models/view'
  const userMatch = matchRoutes([{ path: timViewPagePatch }], location)
  const timViewPage = userMatch && userMatch[0].route.path === timViewPagePatch
  const { data: projectData, isFetching: isProjectFetching } = useGetProjectByIdQuery({ id: projectId })
  const { data: project, pdAccess, rdAccess, iiAccess, irdAccess } = projectData || {}

  const onProjectItemClick = useCallback((projectId: number) => {
    timViewPage
      ? navigate(`/project/${projectId}/tim/models/view`)
      : navigate(`/project/${projectId}/tim/models`)
  }, [timViewPage])

  const { onProjectBreadClick, isProjectsMenuOpened, ProjectMenu } = useBreadcrumbMenu({ onProjectItemClick })
  useBreadcrumbs([
    {
      title: project?.shifrName || '',
      onClick: (e) => onProjectBreadClick(e),
      MenuOpen: isProjectsMenuOpened,
      type: 'project'
    },
    { title: timViewPage ? 'Просмотр ТИМ-модели' : 'ТИМ-модели' },
  ],
    [isProjectsMenuOpened, project, timViewPage],
    isProjectFetching
  )

  const collapseDrawer = () => {
    dispatch(setOpenedDrawer({ openedDrawer: 'timView' }))
  }


  // set initial data
  useEffect(() => {
    if (project?.id) {
      dispatch(setDefaultProjectInfo({
        project,
        iiAccess: iiAccess!,
        irdAccess: irdAccess!,
        pdAccess: pdAccess!,
        rdAccess: rdAccess!,
        userWithLessControls: role === 'contractor' || role === 'supervisor'
      }))
    }
  }, [projectData])

  useEffect(() => {
    if (role === 'none' || rdAccess === false) {
      navigate('/home')
    }
  }, [role, rdAccess])

  return isProjectFetching
    ? <Progress />
    : (
      <>
        <MainContainer>
          <Stack direction='row'
            alignItems='center'
            ml={timViewPage && !openedDrawer ? 0 : '360px'}>
            <Header
              isHasSearch={timViewPage ? false : true}
              isShowLogo={!!timViewPage && !openedDrawer}
              isShowCollapse={!!timViewPage && !openedDrawer}
              collapseFunc={collapseDrawer} />
          </Stack>
          {timViewPage && <HelpButton />}
          <InnerContainer>
            <Outlet />
          </InnerContainer>
        </MainContainer>
        {ProjectMenu}
      </>
    )
}