import { Drawer, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { values } from 'lodash'
import React, { FC, useCallback, useMemo, useState } from 'react'

import { AutocompleteData, DocsDrawerForm, PermanentAutocompleteData } from '@pages/Docs'

import { useGetProjectByIdQuery } from '../../../../../../../api/projects'
import Divider from '../../../../../../../components/Divider'
import { UploadPropsData } from '../../../../../../../components/FileUpload/FileUpload.types'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../../../hooks/useConfirmDialog'
import { theme } from '../../../../../../../styles/theme'
import { Tom } from '../../../../../../../types/tom'
import { ObjectsDrawerTopBar, ObjectsDrawerWrapper } from '../../../../../../Objects/components/ObjectsDrawer/styles'
import { WorksFormData } from '../../WorksDrawerForm.types'
import { TomDrawerProps } from './TomDrawer.types'

export const TomDrawer: FC<TomDrawerProps> = ({ phase, projectId, open, onClose }) => {
  const { values, setFieldValue } = useFormikContext<WorksFormData>()

  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  const uploadData: UploadPropsData = useMemo(
    () => ({
      title: 'Загрузить PDF',
      text: 'Чтобы добавить документ к тому, выберите его из папки на компьютере или просто перетяните его в эту область.',
      formats: ['application/pdf'],
    }),
    [],
  )

  // drawer close
  const handleConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        onClose('tom')
      }
    },
    [onClose],
  )

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onDrawerClose = useCallback(
    (dirty: boolean, immediately?: boolean) => {
      immediately || !dirty ? onClose('tom') : openConfirm()
    },
    [onClose],
  )

  // Permanent obj
  const { data: projectData } = useGetProjectByIdQuery({
    id: projectId,
  })
  const { data: currentProject } = projectData || {}

  const autocompleteData: AutocompleteData = {
    projectName: currentProject?.shifrName || '',
    defaultObjectId: typeof values.obj === 'number' ? values.obj : null,
    defaultPdId: typeof values.obj === 'number' ? values.obj : null,
    defaultIiId: typeof values.obj === 'number' ? values.obj : null,
    defaultIrdId: typeof values.obj === 'number' ? values.obj : null,
  }

  const permanentAutocompleteData: PermanentAutocompleteData = {
    isPermanentObjectId: typeof values.obj === 'number',
    isPermanentPdId: typeof values.obj === 'number',
    isPermanentIiId: typeof values.obj === 'number',
    isPermanentIrdId: typeof values.obj === 'number',
  }
  // bind after create
  const onTomSuccessCreate = (tom: Tom) => {
    setFieldValue('tom', tom.id)
  }

  return (
    <Drawer
      anchor='bottom'
      open={open}
      onClose={() => onDrawerClose(dirty)}
      PaperProps={{
        sx: {
          width: 400,
          left: 'auto',
          borderRadius: '20px 20px 0px 0px',
        },
      }}
    >
      <ObjectsDrawerWrapper>
        <ObjectsDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            Создание тома
          </Typography>

          <Typography variant='body2' color={theme.palette.text.dark}>
            Заполните все данные и нажмите кнопку сохранить. В случае если Вы передумали нажмите кнопку отменить
          </Typography>
        </ObjectsDrawerTopBar>

        <Divider />

        <DocsDrawerForm
          onClose={onDrawerClose}
          onFormChange={onFormChange}
          uploadData={uploadData}
          onSuccessCreate={onTomSuccessCreate}
          otherPhase={phase}
          otherProjectID={projectId}
          autocompleteData={autocompleteData}
          permanentAutocompleteData={permanentAutocompleteData}
        />
      </ObjectsDrawerWrapper>

      <ConfirmDialog />
    </Drawer>
  )
}
