import styled from 'styled-components'
import { Box, Drawer, ListItemButton } from '@mui/material'
import {ReactComponent as DesignIcon} from '../../assets/design-logo.svg'

export const StyledDrawer = styled(Drawer)`
  transition: width .2s ease-in-out;

  & > .MuiPaper-root {
    overflow: hidden;
    justify-content: space-between;
    box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.06);
    transition: width .2s ease-out;
  }

  & .MuiList-root {
    padding: unset;

    & > a:not(:last-child) > .MuiListItemButton-root {
      margin-bottom: 2px;
    }

    & .MuiListItemButton-root {
      width: auto;
      height: 40px;
      padding: 8px 20px;
      margin-inline: 8px;
      border-radius: 8px;
      overflow: hidden;


      & > .MuiListItemText-root > .MuiTypography-root {
        color: ${props => props.theme.palette.text.dark};
        margin-left: 32px;
        white-space: nowrap;
      }

      & > .MuiListItemIcon-root {
        min-width: unset;

        & svg > * {
          fill: ${props => props.theme.palette.text.dark};
        }
      }

      &.Mui-selected {
        background-color: ${props => props.theme.palette.primary.main};

        & > .MuiListItemText-root > .MuiTypography-root {
          color: #fff;  
        }

        & > .MuiListItemIcon-root svg > * {
          fill: #fff
        }
      }
    }
  }
`

export const TopBox = styled(Box)`
  display: flex;
  flex-direction: column;

  & > a {
    display: block;
    width: 260px;
    height: 70px;
    margin-bottom: 4px;
  }
`

export const LogoWrapper = styled(Box)`
  position: relative;
  height: 100%;
  cursor: pointer;

  &:hover::after {
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.04);
  }
`

export const DesignLogo = styled(DesignIcon)`
  width: 260px;
  height: 70px;
  transform: translateX(-31px);
`

export const BottomBox = styled(Box)`
  & .MuiListItem-root {
    padding: 8px 28px !important;
    margin: unset !important;
    border-radius: unset !important;
  }
`

export const BottomItemButton = styled(ListItemButton)`
  margin-inline: 0 !important;
`