import { useCallback, useMemo } from 'react'
import { docsFilterItems } from '@pages/Docs'
import { updateFilterData } from '@store/slices/documentsPages/filter'
import { sidebarItemsSelector } from '@store/slices/documentsPages/sidebar'
import { useAppDispatch, useTypedSelector } from '@store/store'

export const useResetFilter = () => {
  const dispatch = useAppDispatch()
  const { sidebarObjects } = useTypedSelector(sidebarItemsSelector)

  const uniqueRdIDs = useMemo(
    () => Array.from(new Set(sidebarObjects.flatMap((e) => e.rdList.map((a) => a.rdID)))),
    [sidebarObjects],
  )
  const uniqueDocsValues = useMemo(() => Array.from(new Set(docsFilterItems.map((e) => e.value))), [])

  const resetFilterData = useCallback(() => {
    // dispatch(clearFilter({}))
    dispatch(updateFilterData({ selectedIds: uniqueRdIDs, filterType: 'rd' }))
    dispatch(updateFilterData({ selectedIds: uniqueDocsValues as unknown as number[], filterType: 'docs' }))
  }, [dispatch, uniqueDocsValues, uniqueRdIDs])

  return { resetFilterData }
}
