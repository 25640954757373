import React, { FC, useEffect, useState } from 'react'
import {
  FullExportDrawerFilter,
  FullExportDrawerMainListWrapper,
  FullExportDrawerPhaseButton,
  FullExportDrawerPhaseGroup,
  FullExportDrawerSearch
} from './FullExportDrawerMainList.styles'
import { Divider, InputAdornment, Stack } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { SelectItem } from '../../../../../../components/FilterSelectMulti/FilterSelectMultiple.types'
import { tomStatusesArray } from '../../../../../../types/tom'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import {
  ProjectExportGroup,
  ProjectExportPhaseType,
  ProjectExportTomStatus
} from '../../../../../../api/projects/types'
import { useFormikContext } from 'formik'
import { ProjectFullExportFormData } from '../ProjectFullExportForm'
import { FullExportAccordion } from '../FullExportAccordion'
import { StyledSearchIcon } from '../../../../../../components/Header/Search/styles'

export const FullExportDrawerMainList: FC = () => {
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFullExportFormData>()
  const { phaseList } = formValues

  const [selectedProjectPhase, setSelectedProjectPhase] = useState<ProjectExportPhaseType | 'all'>('all')
  const [selectedTomStatuses, setSelectedTomStatuses] = useState<ProjectExportTomStatus[]>([])
  const [tomSearchValue, setTomSearchValue] = useState<string>('')

  const onPhaseChange = (phase: ProjectExportPhaseType | 'all') => {
    setSelectedProjectPhase(phase)
  }

  const statusSelectItems: SelectItem<string>[] = ['В производстве', ...tomStatusesArray]
    .filter(status => {
      if (selectedProjectPhase === 'all') return true
      if (status === 'В производстве') return selectedProjectPhase === 'РД'
      if (status === 'Не согласуется') return selectedProjectPhase === 'ИРД'
      return true
    })
    .map(status => ({
      value: status,
      title: status,
    }))

  const filterGroups = (groups: ProjectExportGroup[], statuses: ProjectExportTomStatus[], searchName: string) => {
    const updatedGroups = groups.map<ProjectExportGroup>(group => {

      const updatedToms = group.toms.map(tom => ({
        ...tom,
        hideByFilter: (!!statuses.length && !statuses.includes(tom.status))
          || !tom.name.includes(searchName),
      }))
      const hideRd = updatedToms.every(tom => tom.hideByFilter)
      return {
        ...group,
        toms: updatedToms,
        hideByFilter: hideRd,
      }

    })

    return updatedGroups
  }

  const filterData = (statuses: ProjectExportTomStatus[], searchName: string) => {
    const updatedPhases = phaseList.map(phase => {
      if (phase.type === 'РД') {
        const updatedObjects = phase.objects?.map(object => {
          const updatedGroups = filterGroups(object.groups, statuses, searchName)
          const hideObject = updatedGroups.every(group => group.hideByFilter)

          return {
            ...object,
            groups: updatedGroups,
            hideByFilter: hideObject,
          }
        })

        return {
          ...phase,
          objects: updatedObjects,
        }
      }

      const updatedGroups = filterGroups(phase.groups!, statuses, searchName)

      return {
        ...phase,
        groups: updatedGroups,
      }
    })

    setFieldValue('phaseList', updatedPhases)
  }

  const onFilter = (statuses: ProjectExportTomStatus[], searchName: string) => {
    statuses.length !== selectedTomStatuses.length && setSelectedTomStatuses(statuses)
    searchName !== tomSearchValue && setTomSearchValue(searchName)
  }

  useEffect(() => {
    filterData(selectedTomStatuses, tomSearchValue)
  }, [selectedTomStatuses, tomSearchValue])

  return (
    <FullExportDrawerMainListWrapper>
      <Stack spacing={1.5}>
        <FullExportDrawerPhaseGroup
          value={selectedProjectPhase}
          exclusive
          onChange={(e, newValue) => onPhaseChange(newValue)}
        >
          <FullExportDrawerPhaseButton value='all'>Все</FullExportDrawerPhaseButton>
          <Divider flexItem orientation='vertical' />

          <FullExportDrawerPhaseButton value='ИРД'>ИРД</FullExportDrawerPhaseButton>
          <Divider flexItem orientation='vertical' />

          <FullExportDrawerPhaseButton value='ИИ'>ИИ</FullExportDrawerPhaseButton>
          <Divider flexItem orientation='vertical' />

          <FullExportDrawerPhaseButton value='ПД'>ПД</FullExportDrawerPhaseButton>
          <Divider flexItem orientation='vertical' />

          <FullExportDrawerPhaseButton value='РД'>РД</FullExportDrawerPhaseButton>
        </FullExportDrawerPhaseGroup>

        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3} pr={2.5} pb={2}>
          <FullExportDrawerFilter
            items={statusSelectItems}
            value={selectedTomStatuses}
            onChange={(value: any) => onFilter(value as ProjectExportTomStatus[], tomSearchValue)}
            defaultValue='Статус тома'
            allSelectedText='Все типы'
            paperFullHeight
            startIcon={<FilterAltIcon fontSize='large' color='secondary' />}
          />

          <FullExportDrawerSearch
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <StyledSearchIcon color='disabled' />
                </InputAdornment>
              ),
            }}
            placeholder='Поиск'
            onChange={(e) => onFilter(selectedTomStatuses, e.target.value)}
          />
        </Stack>
      </Stack>

      <ScrollableContainer spacing={1.5}>
        {phaseList.map((phaseData, index) => {
          const accordionHideByFilterByFilter = selectedProjectPhase !== 'all' && selectedProjectPhase !== phaseData.type
          const accordionHideByFilterBySearch = phaseData.type === 'РД'
            ? phaseData.objects?.every(object => object.hideByFilter)
            : phaseData.groups?.every(group => group.hideByFilter)

          const accordionHideByFilter = !!(accordionHideByFilterByFilter || accordionHideByFilterBySearch)

          return (
            <FullExportAccordion
              phase={phaseData}
              phaseIndex={index}
              accordionHideByFilter={accordionHideByFilter}
              key={phaseData.name}
            />
          )
        })}
      </ScrollableContainer>
    </FullExportDrawerMainListWrapper>
  )
}
