import styled from "styled-components";
import { ObjectsSelectMenuItem } from "../UserManagement/components/UserObjectsSelection/styles";
import { Button, Stack, TextField, Typography } from "@mui/material";

export const FilterSelectMenuItem = styled(ObjectsSelectMenuItem)`
`
export const FilterRenderText = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
`

export const StyledTextField = styled(TextField)`
  & .MuiInputBase-input {
    padding: 8px 16px 8px 8px;
  }

  & .MuiInputBase-root {
    height: 40px;
    width: 180px;
    border-radius: 6px;
    padding: 0;
    color: ${props => props.theme.palette.secondary.dark};

    & .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 1px;
    }

    svg {
      margin-left: 8px;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primaryBorderLight};
    }
  }
`
export const StyledButton = styled(Button)`
  text-transform: none;
  font-size: 12px;
  padding: 0;
`
export const StickyContainer = styled(Stack)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  align-items: center;
`