import { FC } from 'react'
import { IiStatisticsProps, IiTypeGroupTitle } from './IiStatistics.types'
import { IiStatisticsWrapper } from './IiStatistics.styles'
import { IiStatisticsItem } from '../../components/IiStatisticsItem'
import { StatusItemData } from '../../components/StatusItem'
import { StatisticsBarGroupData } from '../../components/StatisticsBar'
import { theme } from '../../../../../../styles/theme'
import Divider from '../../../../../../components/Divider'


export const IiStatistics: FC<IiStatisticsProps> = ({ data, onStatisticHeaderClick }) => {
  const { tz, pr, to } = data

  const iiGroupsWithName = [
    { group: tz, title: 'Технические задания' as IiTypeGroupTitle },
    { group: pr, title: 'Программы работ' as IiTypeGroupTitle },
    { group: to, title: 'Технические отчеты' as IiTypeGroupTitle },
  ]

  return (
    <IiStatisticsWrapper spacing={1} divider={<Divider />}>
      {iiGroupsWithName.map(({ group, title }) => {
        const statisticsData: StatusItemData = {
          title: title,
          value: group.totalCount,
          color: 'blue',
          disableInnerGap: true,
          subItems: [
            {
              title: 'Согласовано',
              percentage: Math.round(group.agreed.percent),
              value: group.agreed.count,
              color: 'lightgreen',
              clickable: group.agreed.clickable,
            },
            {
              title: 'На согласовании',
              percentage: Math.round(group.agreement.percent),
              value: group.agreement.count,
              color: 'purple',
              clickable: group.agreement.clickable,
            },
            {
              title: 'Прочие',
              percentage: Math.round(group.other.percent),
              value: group.other.count,
              color: 'grayBlue',
              clickable: group.other.clickable,
            },
          ]
        }

        const statisticsBarData: StatisticsBarGroupData[] = [
          {
            percent: Math.round(group.agreed.percent),
            backgroundColor: 'lightgreen',
            empty: true,
            typographyProps: { color: theme.palette.text.light },
          },
          {
            percent: Math.round(group.agreement.percent),
            backgroundColor: 'purple',
            empty: true,
            typographyProps: { color: theme.palette.text.light },
          },
          {
            percent: Math.round(group.other.percent),
            backgroundColor: 'grayBlue',
            empty: true,
            typographyProps: { color: theme.palette.text.light },
          },
        ]

        return (
          <IiStatisticsItem
            statisticsData={statisticsData}
            onStatisticHeaderClick={onStatisticHeaderClick}
            statisticsBarData={statisticsBarData}
          />
        )
      })}
    </IiStatisticsWrapper>
  )
}
