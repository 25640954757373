import * as yup from 'yup'

export const commonValidation = {
  mark: yup.string().trim().required('common:errors.required'),
  fullName: yup.string().trim().required('common:errors.required'),
  description: yup.string().trim(),
}

export const pdValidation = yup.object({
  ...commonValidation,
  type: yup.string().required('common:errors.required'),
})

export const ppdValidation = yup.object({
  ...commonValidation,
})
