import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useLoginByTokenMutation } from '../../api/auth'
import { useMutationHandlers } from '../../hooks/useMutationHandlers'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { isAuthSelector, isLogoutSelector, setCredentials } from '../../store/slices/auth'
import useQuery from '../../hooks/useQuery'
import AppLayout from '../AppLayout'
import { config } from '../../core/config'
import { isCompany } from '../../core/types'

export const Layout: React.FC = () => {
  const dispatch = useAppDispatch()
  const tokenHandler = useQuery()
  const tokenQuery: string | null = tokenHandler.get('token')
  const [token, setToken] = useState<string | null>(tokenQuery)

  const subDomain = window.location.host.split('.')[0]
  const currentCompanyName = isCompany(subDomain) ? subDomain : null
  const companyParam = currentCompanyName ? `&company=${currentCompanyName}` : ''

  useEffect(() => {
    if (token) {
      dispatch(setCredentials({}))
    }
  }, [token])

  const isAuth = useTypedSelector(isAuthSelector)
  const isLogout = useTypedSelector(isLogoutSelector)

  const [loginByToken, loginByTokenResponse] = useLoginByTokenMutation()

  useEffect(() => {
    if (!isAuth && !isLogout) {
      token
        ? loginByToken({ token: token! })
        : window.location.replace(`${config.coreURL}?source=design${companyParam}`)
    }
  }, [isAuth, token, isLogout, currentCompanyName])

  useMutationHandlers(loginByTokenResponse, () => {
    tokenHandler.remove('token')
    setToken(null)
  })

  return isAuth ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : null
}
