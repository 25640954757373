import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { AttachmentRequest, AttachmentResponse } from '../attachments/types'
import { dadataSecret, dadataToken, dadataUrl } from '../../utils/constants'



const baseQuery = fetchBaseQuery({
  baseUrl: dadataUrl,
  mode: 'cors',
  prepareHeaders: (headers, { getState }) => {
    headers.set('Content-Type', `application/json`)
    headers.set('Accept', `application/json`)
    headers.set('Authorization', `Token ${dadataToken}`)
    headers.set('X-Secret', dadataSecret)

    return headers
  },
})

export const dadataApi = createApi({
  baseQuery: async (args, queryApi, extraOptions) => {
    let result = await baseQuery(args, queryApi, extraOptions)

    return result
  },
  endpoints: () => ({}),
})