import { Stack, Typography } from "@mui/material"
import styled from "styled-components"
import FieldForm from "../../../../components/FieldForm"
import { InternalAgreementAutocomplete } from "../../../Schemes/InternalAgreementSchemes/components/InternalAgreementPerson"

export const DrawerWrapper = styled(Stack)`
  height: 100%;
  width: 400px;
  padding: 0 16px 16px;
`
export const DrawerHeader = styled(Typography)`
  padding: 20px 0 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #D1D8FA;
`
export const SchedulesTextField = styled(FieldForm)`
  width: ${props => !props.multiline && '238px'};

  & .MuiFilledInput-root {
    height: ${props => props.multiline ? '120px' : '36px'};

    &.Mui-error {
      border: 1px solid ${props => props.theme.palette.warning.main};
    }

    &.Mui-focused  {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }

    & .MuiFilledInput-input {
      padding: ${props => props.multiline ? '16px' : '5px 12px'};
      height: ${props => props.multiline && 'inherit'}!important;
      box-sizing: border-box;
      overflow-y: auto!important;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  & .MuiFormHelperText-root {
    display: none;
  }
`

export const SchedulesAutocomplete = styled(InternalAgreementAutocomplete)`
  max-width: 238px;
  height: 36px;

  & .MuiOutlinedInput-root {
    height: 36px;
    width: inherit;

    & .MuiAutocomplete-input {
      width: 90%;
      flex-grow: 0;
    }
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(12px, 8px) scale(1);
  }

  & .MuiAutocomplete-inputRoot {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }
  }

  & .MuiFormHelperText-root {
    display: none;
  }
`
export const ScheduleSwitch = styled(FieldForm)`
  margin-right: 0;

  & .MuiSwitch-root {
    margin: 0;
  }
`