import {
  GetUpdatedSelectedTomsArgs,
  SetCheckedGroupArgs,
  SetCheckedObjectAccordionArgs,
  SetCheckedPhaseAccordionArgs,
  SetCheckedRdGroupArgs,
  UseAccordionCheckStateProps,
  UseAccordionCheckStateResponse,
} from './useAccordionCheckState.types'
import { ChangeEvent } from 'react'
import { useFormikContext } from 'formik'
import { ProjectFullExportFormData, SelectedTomData } from '../../../ProjectFullExportForm'

export const useAccordionCheckState = ({
  phase,
  phaseIndex,
  object,
  group,
  objectIndex,
  groupIndex,
}: UseAccordionCheckStateProps): UseAccordionCheckStateResponse => {
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFullExportFormData>()

  const setCheckedPhaseAccordion = ({
    phase,
    phaseIndex,
    objects,
    groups,
    checked
  }: SetCheckedPhaseAccordionArgs) => {
    const tomsForPush: SelectedTomData[] = []
    const tomsForSplice: SelectedTomData[] = []

    const baseArgs = { phase, phaseIndex, checked }

    if (phase === 'РД') {
      objects.map((object, objectIndex) => {
        setFieldValue(
          `phaseList[${phaseIndex}].objects[${objectIndex}].selected`,
          checked
        )

        const {
          tomsForPush: objectTomsForPush = [],
          tomsForSplice: objectTomsForSplice = []
        } = setCheckedObjectAccordion({ ...baseArgs, object, objectIndex }, 'return') || {}

        tomsForPush.push(...objectTomsForPush)
        tomsForSplice.push(...objectTomsForSplice)
      })
    } else {
      groups.map((group, groupIndex) => {
        const {
          tomsForPush: groupTomsForPush = [],
          tomsForSplice: groupTomsForSplice = []
        } = setCheckedGroup({ ...baseArgs, group, groupIndex }, 'return') || {}

        tomsForPush.push(...groupTomsForPush)
        tomsForSplice.push(...groupTomsForSplice)
      })
    }

    checked
      ? setFieldValue('selectedToms', [...formValues.selectedToms, ...tomsForPush])
      : deleteTomsFromSelected(formValues.selectedToms, tomsForSplice)
  }

  const setCheckedObjectAccordion = (args: SetCheckedObjectAccordionArgs, action: 'update' | 'return') => {
    const {
      phase,
      phaseIndex,
      object,
      objectIndex,
      checked
    } = args
    const tomsForPush: SelectedTomData[] = []
    const tomsForSplice: SelectedTomData[] = []

    object?.groups?.forEach((group, groupIndex) => {
      const dataForRdGroup: SetCheckedRdGroupArgs = {
        phaseIndex,
        group,
        groupIndex,
        objectIndex,
        checked
      }

      setCheckedRdGroup(dataForRdGroup)

      const dataForGetToms: GetUpdatedSelectedTomsArgs = {
        phase,
        phaseIndex,
        objectIndex,
        group,
        groupIndex,
        checked,
      }

      checked
        ? tomsForPush.push(...getUpdatedSelectedToms(dataForGetToms))
        : tomsForSplice.push(...getUpdatedSelectedToms(dataForGetToms))
    })

    switch (action) {
      case 'update':
        checked
          ? setFieldValue('selectedToms', [...formValues.selectedToms, ...tomsForPush])
          : deleteTomsFromSelected(formValues.selectedToms, tomsForSplice)
        return
      case 'return':
        return { tomsForPush, tomsForSplice }
    }
  }

  const setCheckedRdGroup = ({
    phaseIndex,
    group,
    groupIndex,
    objectIndex,
    checked,
  }: SetCheckedRdGroupArgs) => {
    setFieldValue(
      `phaseList[${phaseIndex}].objects[${objectIndex}].groups[${groupIndex}].selected`,
      checked
    )

    group?.toms?.forEach((tom, tomIndex) => {
      if (tom.hideByFilter) return

      setFieldValue(
        `phaseList[${phaseIndex}].objects[${objectIndex}].groups[${groupIndex}].toms[${tomIndex}].selected`,
        checked
      )
    })
  }

  const setCheckedGroup = (args: SetCheckedGroupArgs, action: 'update' | 'return') => {
    const {
      phase,
      phaseIndex,
      group,
      groupIndex,
      checked,
    } = args

    setFieldValue(
      `phaseList[${phaseIndex}].groups[${groupIndex}].selected`,
      checked
    )

    group?.toms?.forEach((tom, tomIndex) => {
      if (tom.hideByFilter) return

      setFieldValue(
        `phaseList[${phaseIndex}].groups[${groupIndex}].toms[${tomIndex}].selected`,
        checked
      )
    })

    const dataForGetToms: GetUpdatedSelectedTomsArgs = {
      phase,
      phaseIndex,
      group,
      groupIndex,
      checked,
    }

    const tomsForUpdate = [...getUpdatedSelectedToms(dataForGetToms)]
    switch (action) {
      case 'update':
        checked
          ? setFieldValue('selectedToms', [...formValues.selectedToms, ...tomsForUpdate])
          : deleteTomsFromSelected(formValues.selectedToms, [...tomsForUpdate])
        return
      case 'return':
        return { tomsForPush: tomsForUpdate, tomsForSplice: tomsForUpdate }
    }

  }

  const getUpdatedSelectedToms = ({
    phase,
    phaseIndex,
    group,
    groupIndex,
    objectIndex,
    checked,
  }: GetUpdatedSelectedTomsArgs): SelectedTomData[] => {
    const tomsForPush: SelectedTomData[] = []
    const tomsForSplice: SelectedTomData[] = []

    group?.toms?.forEach((tom, tomIndex) => {
      const selectedTomData: SelectedTomData = {
        phase,
        phaseIndex,
        objectIndex: phase === 'РД' ? objectIndex! : undefined,
        groupIndex,
        tom,
        tomIndex,
      }

      if (checked && !tom.selected && !tom.hideByFilter) {
        tomsForPush.push(selectedTomData)
        return
      }

      if (!checked && tom.selected && !tom.hideByFilter) {
        tomsForSplice.push(selectedTomData)
        return
      }
    })

    return checked
      ? tomsForPush
      : tomsForSplice
  }

  // Common
  const onAccordionCheckboxChange = (
    accordionFieldName: string,
    e?: ChangeEvent<HTMLInputElement>,
    rootChecked?: boolean
  ) => {
    e?.stopPropagation()
    const checked = !!(e?.target?.checked || rootChecked)
    setFieldValue(
      accordionFieldName,
      checked
    )

    if (!checked) {
      setFieldValue(`phaseList[${phaseIndex}].selected`, false)
    }

    const dataForSetGroup: SetCheckedGroupArgs = {
      phase,
      phaseIndex,
      group,
      groupIndex,
      checked,
    }

    const dataForSetObject: SetCheckedObjectAccordionArgs = {
      ...dataForSetGroup,
      object,
      objectIndex,
    }

    phase === 'РД' && setCheckedObjectAccordion(dataForSetObject, 'update')
    phase !== 'РД' && setCheckedGroup(dataForSetGroup, 'update')
  }


  const deleteTomsFromSelected = (selectedToms: SelectedTomData[], deletedToms: SelectedTomData[]) => {
    const deletedTomsIds = deletedToms.map(tom => tom.tom.id)
    const selectedTomsAfterDelete = selectedToms.filter(tom => !deletedTomsIds.includes(tom.tom.id))

    setFieldValue('selectedToms', selectedTomsAfterDelete)
  }

  const disableExpandIcon = !!(group?.selected)
  const accordionHideByFilter = !!(object?.hideByFilter || group?.hideByFilter)

  return {
    setCheckedPhaseAccordion,
    onAccordionCheckboxChange,
    setCheckedRdGroup,
    getUpdatedSelectedToms,
    deleteTomsFromSelected,
    disableExpandIcon,
    accordionHideByFilter,
  }
}
