import React, { useCallback, useEffect, useState } from 'react'
import { FileUploadProps } from './FileUpload.types'
import { useField } from 'formik'
import { Box, FormHelperText, Stack } from '@mui/material'
import {
  DragWrapper,
  ReLoadButton,
  ShowErrorButton,
  ShowResultsButton,
  UploadText,
  UploadTitle,
  UploadWrapper
} from './styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { theme } from '../../styles/theme'
import { useTranslation } from 'react-i18next'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoRow from '../InfoRow'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded'
import {
  ExcelUploadResponse,
  FileUploadResponse,
  isBlockError,
  isExcelResponse,
  isMultiResponse,
  MultiResponse,
  SingleResponse
} from '../../types/global'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Progress from '../Progress'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { useDropzone } from 'react-dropzone'
import { TomRdDocument } from '../../api/rdPhase'
import { TomPdDocument } from '../../api/pdPhase'
import { MAX_FILE_SIZE_IN_BYTES, MAX_FILE_SIZE_IN_MB, MAX_FILES_UPLOAD } from '../../utils/constants'
import { getWordWithEnding } from '../../utils/getWordWithEnding'
import { TomIiDocument } from '../../api/iiPhase'

const FileUpload = <T extends TomRdDocument | TomPdDocument | TomIiDocument | null>({
  mode,
  responseData,
  uploadData,
  placeholder,
  loading,
  height,
  onResultsDrawerOpen,
  fieldProps,
  ...props
}: FileUploadProps<T> & { children?: React.ReactNode }) => {
  const { t } = useTranslation()
  const { title, text, formats, mimeTypes } = uploadData
  const [successUpload, setSuccessUpload] = useState<boolean>(false)

  const [{ value: formValue }, { touched, error }, { setValue, setTouched, setError }] = useField({
    name: props.name,
    ...fieldProps,
  })

  const isError = touched && !!error
  const blockClickUpload = responseData && mode === 'single'
    ? isExcelResponse(responseData as SingleResponse<T>) && !!((responseData as ExcelUploadResponse)?.success || (responseData as ExcelUploadResponse)?.error?.length)
    : false

  useEffect(() => {
    if (responseData?.success) {
      setSuccessUpload(true)
    }
  }, [responseData])

  // const handleSetValue = useCallback(
  //   (files: File[]) => {
  //     setValue('')
  //     const reader = new FileReader()
  //     reader.onload = (props) => {
  //       // setValue(new File([target!.result as ArrayBuffer], file.name, { type: file.type }))
  //     }
  //     if (files[0]) {
  //       reader.readAsArrayBuffer(files[0])
  //     }
  //   },
  //   [setValue]
  // )

  const onDropFile = useCallback((files: File[]) => {
    setTouched(true)
    if (files?.length) {
      setValue(files)
    }
  }, [setValue])

  const getAcceptObject = (formats: string[], mimeTypes?: Record<string, string>) => {
    if (mimeTypes) {
      return formats.reduce((result, currentFormat) => {
        const mimeType = mimeTypes[currentFormat]
        if (mimeType) {
          if (!result[mimeType]) {
            result[mimeType] = []
          }
          result[mimeType].push(currentFormat)
        }
        return result
      }, {} as Record<string, string[]>)
    } else {
      return formats.reduce((result, currentFormat) => {
        result[currentFormat] = []
        return result
      }, {} as Record<string, string[]>)
    }
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections, open } = useDropzone({
    onDrop: onDropFile,
    noClick: blockClickUpload,
    accept: getAcceptObject(formats, mimeTypes),
    maxFiles: mode === 'single' ? 1 : MAX_FILES_UPLOAD,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    disabled: successUpload
  })

  const getSingleUploadResponse = useCallback((response: FileUploadResponse<T>) => {
    if (response.success) return

    return (
      <>
        <WarningAmberIcon color='error' sx={{ fontSize: '48px !important' }} />
        <Stack spacing={1} sx={{ px: 2.5, py: 1, width: '100%' }} alignItems='center'>
          <UploadText variant='body2' style={{ color: theme.palette.error.main }}>
            Произошла ошибка при загрузке файла!
          </UploadText>
          <UploadText variant='body2'>
            {response.description}
          </UploadText>
        </Stack>

        <Box sx={{ mt: 3 }}>
          <label htmlFor='upload-file2'>
            <ReLoadButton onClick={open} spacing={1} direction='row' alignItems='center'>
              <AutorenewIcon fontSize='medium' color='primary' />
              <UploadText>Загрузить еще раз</UploadText>
            </ReLoadButton>
          </label>
        </Box>
      </>
    )
  }, [])

  const getExcelUploadResponse = useCallback((response: ExcelUploadResponse) => {
    if (response.success) {
      return (
        <>
          <CheckCircleIcon sx={{ fontSize: '48px !important', color: theme.palette.legends.lightgreen }} />
          <Stack spacing={2} sx={{ px: 2.5, py: 2, width: '100%' }}>
            <UploadText variant='body2'>Успешно обработано!</UploadText>
            <InfoRow
              rowData={{
                title: 'Добавлено новых объектов',
                icon: InsertDriveFileOutlinedIcon,
                value: response.create.toString(),
                iconProps: {
                  fontSize: 'small',
                  color: 'primary'
                }
              }}
            />
            <InfoRow
              rowData={{
                title: 'Обновлено объектов',
                icon: InsertDriveFileRoundedIcon,
                value: response.update.toString(),
                color: theme.palette.error.main,
                iconProps: {
                  fontSize: 'small',
                  color: 'error'
                }
              }}
            />
          </Stack>
        </>
      )
    }

    return (
      <>
        <WarningAmberIcon color='error' sx={{ fontSize: '48px !important' }} />
        <Stack spacing={1} sx={{ px: 2.5, py: 1, width: '100%' }} alignItems='center'>
          {isBlockError(response.error[0].type)
            ? (
              <>
                <UploadText variant='body2' style={{ color: theme.palette.error.main }}>
                  Произошла ошибка при загрузке файла!
                </UploadText>
                <UploadText variant='body2'>
                  {response.error[0].message}
                </UploadText>
              </>
            )
            : (
              <>
                <Stack>
                  <UploadText variant='body2' style={{ color: theme.palette.error.main }}>
                    Произошла ошибка при загрузке файла!
                  </UploadText>
                  <UploadText variant='body2' style={{ fontWeight: 500, color: theme.palette.error.main }}>
                    Всего строк с ошибками {response.error.length}
                  </UploadText>
                </Stack>
                <ShowErrorButton onClick={onResultsDrawerOpen} disableRipple>Посмотреть ошибки</ShowErrorButton>
              </>
            )
          }
        </Stack>

        <Box sx={{ mt: 3 }}>
          <label htmlFor='upload-file2'>
            <ReLoadButton onClick={open} spacing={1} direction='row' alignItems='center'>
              <AutorenewIcon fontSize='medium' color='primary' />
              <UploadText>Загрузить еще раз</UploadText>
            </ReLoadButton>
          </label>
        </Box>
      </>
    )
  }, [])

  const getMultiUploadResponse = useCallback((response: MultiResponse) => {
    return (
      <>
        <CheckCircleIcon sx={{ fontSize: '48px !important', color: theme.palette.legends.lightgreen }} />
        <Stack sx={{ px: 2.5, width: '100%' }} alignItems='center'>
          <UploadText variant='body2'>Успешно обработано!</UploadText>
          <UploadText variant='body2'>Добавлено документов: {response.success?.length}</UploadText>
          <UploadText variant='body2'>Пропущено документов: {response.failed?.length}</UploadText>
          <ShowResultsButton onClick={onResultsDrawerOpen} disableRipple>Посмотреть список</ShowResultsButton>
        </Stack>
      </>
    )
  }, [])


  const getResultsElement = (data: NonNullable<typeof responseData>) => {
    if (isMultiResponse(data)) return getMultiUploadResponse(data)

    return isExcelResponse(data)
      ? getExcelUploadResponse(data)
      : getSingleUploadResponse(data)
  }

  useEffect(() => {
    const errors: string[] = fileRejections.map(file => {
      return file.errors.map(error => {
        return error.code
      })
    }).flat()

    switch (errors[0]) {
      case 'file-invalid-type':
        setError('common:errors.file_format')
        break
      case 'too-many-files':
        setError(t('common:errors.too_many_files', { maxFiles: mode === 'single' ? 1 : MAX_FILES_UPLOAD }))
        break
      case 'file-too-large':
        setError(t('common:errors.file_max_size', { maxSize: MAX_FILE_SIZE_IN_MB }))
        break
    }
  }, [fileRejections])

  return (
    <Box>
      <DragWrapper drag={isDragActive} $height={height} {...getRootProps()}>
        {!responseData
          ? (
            <input
              {...getInputProps()}
            />
          )
          : null
        }

        <UploadWrapper
          $height={height}
          justifyContent='center'
          alignItems='center'
          spacing={1}
          isPointer={!responseData}
        >
          {loading
            ? <Progress />
            : responseData
              ? getResultsElement(responseData as NonNullable<typeof responseData>)
              : (
                <>
                  <CloudUploadIcon sx={{ fontSize: '48px !important', color: theme.palette.secondary.gray }} />
                  {!isError && formValue?.length
                    ? mode === 'single'
                      ? (
                        <>
                          <UploadTitle variant='body2'>Файл загружен</UploadTitle>
                          <UploadText variant='body2'>{formValue[0].name}</UploadText>
                        </>
                      )
                      : (
                        <>
                          <UploadTitle variant='body2'>Файлы загружены</UploadTitle>
                          <UploadText
                            variant='body2'>Всего {getWordWithEnding(formValue.length, 'файл', 'файла', 'файлов')}</UploadText>
                        </>
                      )
                    : (
                      <>
                        <UploadTitle variant='body2'>{title}</UploadTitle>
                        <UploadText variant='body2'>{text}</UploadText>
                      </>
                    )
                  }
                </>
              )
          }
        </UploadWrapper>
      </DragWrapper>

      {isError
        ? (
          <FormHelperText error={isError} style={{ paddingLeft: 8 }}>{t(error as string)}</FormHelperText>
        )
        : null
      }
    </Box>
  )
}

export default FileUpload