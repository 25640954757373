import { Stack } from '@mui/material'
import styled from 'styled-components'

import { ExportArchiveDrawerTopBar } from '../../../Docs/components/ExportArchiveDrawer/ExportArchiveDrawer.styles'

export const DrawerWrapper = styled(Stack)`
  height: 100%;
  width: 800px;
`

export const DrawerTopBar = styled(ExportArchiveDrawerTopBar)`
  justify-content: space-between;
  align-items: center;
`

export const DrawerBox = styled(Stack)`
  max-width: 100%;
  padding: 20px;
  gap: 12px;
  border: ${(props) => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 16px;
`
