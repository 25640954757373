import React, { FC, MouseEvent } from 'react'
import { pocketStatusColorByValue, PocketViewTomCardProps } from './PocketViewTomCard.types'
import {
  AgreementCardDate,
  PocketStatusInfo,
  PocketViewTomCardAvatarWrapper,
  PocketViewTomCardStageTitle,
  PocketViewTomCardStatus,
  PocketViewTomCardSubtitle,
  PocketViewTomCardTitle,
  PocketViewTomCardWrapper
} from './PocketViewTomCard.styles'
import {
  AgreementCardSubtitle,
  AgreementCardText
} from '../../../Schemes/ProjectAgreement/components/ProjectAgreementCard'
import PersonSharpIcon from '@mui/icons-material/PersonSharp'
import { Divider, Stack, Typography } from '@mui/material'
import Tooltip from '../../../../components/Tooltip'
import { useNavigate, useParams } from 'react-router-dom'
import { setSelectedProjectPhase } from '../../../../store/slices/documentsPages/projectInfo'
import { useAppDispatch } from '../../../../store/store'
import { projectPhaseByAgreementPhase } from '../../../../api/projectAgreement/types'

export const PocketViewTomCard: FC<PocketViewTomCardProps> = ({ tomData }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { projectId: projectIdString, pocketId: pocketIdString } = useParams()
  const projectId = Number(projectIdString)
  const pocketId = Number(pocketIdString)

  const { id, tom, person, status, access, stage, transferDate, isExpired } = tomData
  const isAwaiting = status === 'ОЖИДАЕТ ПОДТВЕРЖДЕНИЯ'

  const onClick = (e: MouseEvent) => {
    e.stopPropagation()

    dispatch(setSelectedProjectPhase({ selectedProjectPhase: projectPhaseByAgreementPhase[tom.type] }))
    navigate(`/project/${projectId}/tom/${tom.id}?menuView=remarks&pocketId=${pocketId}`)
  }

  return (
    <PocketViewTomCardWrapper onClick={onClick}>
      <PocketViewTomCardStatus bgColor={pocketStatusColorByValue[status]}>
        {status}
      </PocketViewTomCardStatus>

      <PocketViewTomCardTitle>
        {tom.title}
      </PocketViewTomCardTitle>

      <PocketViewTomCardSubtitle variant='body2'>
        {tom.description}
      </PocketViewTomCardSubtitle>

      {!isAwaiting &&
        <PocketViewTomCardStageTitle disabled={!stage}>
          {status === 'ПРОЙДЕНО' ? 'Процедура завершена' : stage?.title}
        </PocketViewTomCardStageTitle>}

      {!isAwaiting &&
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
          <AgreementCardText>{status === 'ПРОЙДЕНО' ? 'Дата согласования:' : 'Срок прохождения:'}</AgreementCardText>
          <AgreementCardDate error={isExpired}>
            <AgreementCardText>{transferDate || '—'}</AgreementCardText>
          </AgreementCardDate>
        </Stack>}

      <AgreementCardSubtitle mt={1.25}>
        {status === 'ПРОЙДЕНО'
          ? 'Согласовал:'
          : status === 'ОЖИДАЕТ ПОДТВЕРЖДЕНИЯ' ? 'Ответственный за подтверждение'
            : 'Ответственный за этап:'
        }
      </AgreementCardSubtitle>
      <Divider />
      <Stack flex={1} justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={1.5} mt={0.75}>
          <PocketViewTomCardAvatarWrapper>
            {person?.avatar
              ? <img src={person.avatar} width={32} height={32} alt='Аватар' />
              : <PersonSharpIcon fontSize='medium' />
            }
          </PocketViewTomCardAvatarWrapper>

          <Stack maxWidth={175} flex={1}>
            <Tooltip title={person?.company}>
              <AgreementCardText>{person?.company || '—'}</AgreementCardText>
            </Tooltip>
            <Tooltip title={person?.name}>
              <AgreementCardText>{person?.name || '—'}</AgreementCardText>
            </Tooltip>
          </Stack>
        </Stack>
        {isAwaiting &&
          <PocketStatusInfo spacing={1}>
            <Typography variant='body2'>{`Отправлен: ${transferDate}`}</Typography>
          </PocketStatusInfo>}
      </Stack>
    </PocketViewTomCardWrapper>
  )
}
