import { useEffect } from 'react'
import { UseUploadAttachmentsProps, UseUploadAttachmentsResponse } from './useUploadAttachments.types'
import {
  useUploadCmnAttachmentMutation,
  useUploadIiAttachmentMutation,
  useUploadIrdAttachmentMutation,
  useUploadPdAttachmentMutation,
  useUploadRdAttachmentMutation
} from '../../../../../../api/attachments'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import { useTypedSelector } from '../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { isCmnTomSelector } from '../../../../../../store/slices/documentsPages/tom'

export const useUploadAttachments = ({
  values,
  onAttachmentUpload
}: UseUploadAttachmentsProps): UseUploadAttachmentsResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)

  // Rd
  const [uploadRdAttachment, {
    isLoading: isUploadRdAttachmentLoading,
    ...uploadRdAttachmentResponse
  }] = useUploadRdAttachmentMutation()

  useMutationHandlers(
    uploadRdAttachmentResponse,
    (data) => {
      onAttachmentUpload(data)
    }
  )


  // Pd
  const [uploadPdAttachment, {
    isLoading: isUploadPdAttachmentLoading,
    ...uploadPdAttachmentResponse
  }] = useUploadPdAttachmentMutation()

  useMutationHandlers(
    uploadPdAttachmentResponse,
    (data) => {
      onAttachmentUpload(data)
    }
  )


  // Ii
  const [uploadIiAttachment, {
    isLoading: isUploadIiAttachmentLoading,
    ...uploadIiAttachmentResponse
  }] = useUploadIiAttachmentMutation()

  useMutationHandlers(
    uploadIiAttachmentResponse,
    (data) => {
      onAttachmentUpload(data)
    }
  )


  // Ird
  const [uploadIrdAttachment, {
    isLoading: isUploadIrdAttachmentLoading,
    ...uploadIrdAttachmentResponse
  }] = useUploadIrdAttachmentMutation()

  useMutationHandlers(
    uploadIrdAttachmentResponse,
    (data) => {
      onAttachmentUpload(data)
    }
  )


  // Cmn
  const [uploadCmnAttachment, {
    isLoading: isUploadCmnAttachmentLoading,
    ...uploadCmnAttachmentResponse
  }] = useUploadCmnAttachmentMutation()

  useMutationHandlers(
    uploadCmnAttachmentResponse,
    (data) => {
      onAttachmentUpload(data)
    }
  )


  // Common
  useEffect(() => {
    const { docId, files } = values
    if (files?.length) {
      if (isCmnTom) {
        uploadCmnAttachment({ docId, files })
        return
      }

      switch (selectedProjectPhase) {
        case 'Рабочая документация':
          uploadRdAttachment({ docId, files })
          break
        case 'Проектная документация':
          uploadPdAttachment({ docId, files })
          break
        case 'Инженерные изыскания':
          uploadIiAttachment({ docId, files })
          break
        case 'Сбор исходных данных':
          uploadIrdAttachment({ docId, files })
          break
      }
    }
  }, [values.files])

  const attachmentsLoading =
    isUploadRdAttachmentLoading
    || isUploadPdAttachmentLoading
    || isUploadIiAttachmentLoading
    || isUploadIrdAttachmentLoading
    || isUploadCmnAttachmentLoading

  return {
    attachmentsLoading
  }
}
