import { Typography } from '@mui/material'
import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search'
import { AutocompleteField } from '../../../../components/AutocompleteField/AutocompleteField'

interface IModelAutocompleteProps {
  $width?: string
}

export const LabelTextRequired = styled(Typography)`
  ::after {
    content: ' *';
    color: #f46b6b;
  }
`

export const ModelAutocomplete = styled(AutocompleteField)<IModelAutocompleteProps>`
  width: ${(props) => props.$width || '200px'};
  height: 30px;

  & .MuiOutlinedInput-root {
    height: 30px;
    width: inherit;
    padding: 4.5px 12px;
  }

  & .MuiFormHelperText-root {
    display: none;
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    max-width: calc(100% - 50px);
    padding: 0;
    transform: translate(12px, 5px) scale(1);
  }

  & .MuiAutocomplete-inputRoot {
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.palette.secondary.dark + '3A'};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${(props) => props.theme.palette.legends.border};
    }
  }

  & .MuiAutocomplete-popper {
    width: 270px;
  }
`

export const StyledSearchIcon = styled(SearchIcon)`
  color: ${(props) => props.theme.palette.primaryBorderLight};
`
