import { Stack } from '@mui/material'
import Button from 'components/Button'
import FieldForm from 'components/FieldForm'
import { FieldFormLabel } from 'components/FieldFormLabel'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { validationTanglIntegrating } from './validation'
import { IIntegrationRequestFormData, IIntegrationRequestFormProps } from './IntegrationRequestForm.types'
import { StyledPhoneFieldForm } from './IntegrationRequestForm.styles'
import { useTypedSelector } from 'store/store'
import { profileSelector } from 'store/slices/profile'
import { connectNames } from 'utils/connectNames'
import { useSendTanglIntegrationRequestMutation } from 'api/settings'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { isEqual } from 'lodash'

export const IntegrationRequestForm: FC<IIntegrationRequestFormProps> = ({ onFormChange, onClose }) => {
  const profile = useTypedSelector(profileSelector)
  const { phone, email } = profile || {}
  const [sendTanglIntegrationRequest, { isLoading, ...response }] = useSendTanglIntegrationRequestMutation()
  const { enqueueSnackbar } = useSnackbar()

  const defaultValues: IIntegrationRequestFormData = useMemo(
    () => ({
      name: connectNames(profile) || '',
      phone: phone || '',
      email: email || '',
      description: '',
    }),
    [profile],
  )

  const initialValues: IIntegrationRequestFormData = useMemo(
    () => ({
      name: '',
      phone: '',
      email: '',
      description: '',
    }),
    [],
  )

  const onSubmit = useCallback((values: IIntegrationRequestFormData) => {
    sendTanglIntegrationRequest(values)
  }, [])

  const { formik } = useForm({
    validationSchema: validationTanglIntegrating,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values),
  })

  const { values, dirty, isValid, setValues } = formik

  useEffect(() => {
    const isDirty: boolean = !isEqual(values, defaultValues)

    onFormChange(isDirty)
  }, [defaultValues, values])

  useEffect(() => {
    setValues(defaultValues)
  }, [])

  useMutationHandlers(response, () => {
    enqueueSnackbar('Заявка успешно отправлена.', { variant: 'success' })
    onClose(true)
  })

  return (
    <FormikProvider value={formik}>
      <Stack component={Form} height={'100%'}>
        <Stack p={2.5} height={'100%'} overflow={'auto'} spacing={1.5}>
          <FieldFormLabel label='Контактное лицо' isRequired>
            <FieldForm name='name' version='doc' placeholder='Введите ФИО' helperText='' />
          </FieldFormLabel>

          <FieldFormLabel label='Телефон' isRequired>
            <StyledPhoneFieldForm name='phone' placeholder='Введите номер телефона' helperText='' />
          </FieldFormLabel>

          <FieldFormLabel label='Почта' isRequired>
            <FieldForm name='email' version='doc' placeholder='Укажите почтовый адрес' helperText='' />
          </FieldFormLabel>

          <FieldFormLabel label='Комментарий'>
            <FieldForm
              name='description'
              version='doc'
              placeholder='Введите комментарий'
              helperText=''
              multiline
              minRows={5}
            />
          </FieldFormLabel>
        </Stack>

        <Stack p={2.5} direction={'row'} spacing={2}>
          <Button type='submit' disabled={!dirty || !isValid} color='success' fullWidth>
            Отправить
          </Button>

          <Button fullWidth onClick={() => onClose()}>
            Отменить
          </Button>
        </Stack>
      </Stack>
    </FormikProvider>
  )
}
