import styled from 'styled-components'
import {
  AgreementTitledWrapperAutocomplete
} from '../../../../ProjectAgreementForm/components/ProjectAgreementFormContent'
import { alpha } from '@mui/material'
import { AgreementCardAvatarWrapper } from '../../ProjectAgreementCard'

export const ProjectAgreementLegendPersonBlockAutocomplete = styled(AgreementTitledWrapperAutocomplete)`
  width: 170px;

  & .MuiOutlinedInput-root {
    height: 36px;
    padding: 4.5px 18px;
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    max-width: calc(100% - 50px);
    transform: translate(18px, 8px) scale(1);
  }

  & .MuiFormHelperText-root {
    display: none;
  }
`

export const AgreementLegendAvatarWrapper = styled(AgreementCardAvatarWrapper)`
  width: 24px;
  height: 24px;
  background-color: ${props => alpha(props.theme.palette.secondary.gray!, 0.3)};

  & .MuiSvgIcon-root {
    padding-top: 3px;
    color: ${props => props.theme.palette.bg.white};
  }
`