import { StyledMenu } from "./Menu.styles";
import { IMenuData, IMenuProps } from "./Menu.types";
import { Close as CloseIcon } from "@mui/icons-material";
import { MenuItem, Stack, Typography } from "@mui/material";
import Button from "components/Button";
import Progress from "components/Progress";
import { FC, useMemo } from "react";
import { theme } from "styles/theme";

export const Menu: FC<IMenuProps> = ({
  anchorEl,
  title,
  onClose,
  menuData,
}) => {
  const onMenuItemClick = (item: IMenuData) => {
    if (item?.loading) return

    item.action();
    !item.notCloseAfterClick && onClose();
  };

  const filtredMenuData = useMemo(() => {
    return menuData.filter((item) => !item.hidden);
  }, [menuData]);

  return (
    <StyledMenu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
      <Stack spacing={0.5}>
        <Stack direction={"row"} alignItems={"center"} minWidth={'250px'}>
          <Typography
            color={theme.palette.secondary.gray}
            variant="body2"
            display={"flex"}
            flex={1}
            justifyContent={"center"}
            paddingLeft={"40px"}
          >
            {title}
          </Typography>
          <Button icon variant="text" onClick={onClose}>
            <CloseIcon color="primary" />
          </Button>
        </Stack>
        <Stack spacing={1}>
          {filtredMenuData.map((item) => (
            <MenuItem
              onClick={() => onMenuItemClick(item)}
              sx={{
                borderRadius: "100px",
                border: `1px solid ${theme.palette.primary.main}`,
                justifyContent: "center",
              }}
            >
              {item?.loading
                ? <Progress size={'24px'} />
                : (
                  <Stack direction={"row"} spacing={1}>
                    {item.icon}
                    <Typography
                      color={item.labelColor || theme.palette.primary.main}
                      variant="body1"
                    >
                      {item.label}
                    </Typography>
                  </Stack>
                )}
            </MenuItem>
          ))}
        </Stack>
      </Stack>
    </StyledMenu>
  );
};
