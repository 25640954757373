import FieldForm from 'components/FieldForm'
import styled from 'styled-components'

export const ChangeFieldForm = styled(FieldForm)`
  width: 180px;

  & .MuiFilledInput-root {
    height: 30px;
  }

  & .MuiInputBase-input {
    text-align: right;
  }
`
