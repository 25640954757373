import { TableHeaders } from "./SchemaTable.types";

export const columns: TableHeaders[] = [
  {
    title: 'ФИО, должность',
    width: '290px',
    padding: '8px 16px 8px 8px',
    textAlign: 'start'
  },
  {
    title: 'Утверждение',
    width: '96px',
    padding: '8px'
  },
  {
    title: 'Визирование',
    width: '96px',
    padding: '8px'
  },
  {
    title: 'Подписание',
    width: '96px',
    padding: '8px'
  },
  {
    title: 'Замечания',
    width: '96px',
    padding: '8px'
  },
]