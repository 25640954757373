import { Box, Button, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import { profileSelector } from 'store/slices/profile'
import { theme } from 'styles/theme'

import { TitledWrapper } from '../../../../components/TitledWrapper'
import { setOpenedDrawer } from '../../../../store/slices/documentsPages/drawerInfo'
import { docsSelector, tomSelector } from '../../../../store/slices/documentsPages/tom'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { DocList } from '../DocList'
import { tomRoleAccess } from '../DocViewNavBarContentInfo/components/ChangeSign/ChangeSign.types'
import { ActualVersionWrapper } from './DocViewNavBarContentVersions.styles'

export const DocViewNavBarContentVersions: FC = () => {
  const dispatch = useAppDispatch()

  const { tomVersions } = useTypedSelector(docsSelector)
  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled = tom?.features.annulment.annulled
  const profile = useTypedSelector(profileSelector)
  const { role } = profile
  const access: boolean = tomRoleAccess.includes(role)

  const { status } = tom || {}

  return (
    <Stack spacing={3} flex={1} overflow='hidden'>
      <Box pr={2.5}>
        {status !== 'Согласовано' && access && !isAnnulled && (
          <Button
            onClick={() => dispatch(setOpenedDrawer({ openedDrawer: 'version' }))}
            variant='outlined'
            size='small'
            color='secondary'
            fullWidth
          >
            Добавить новую версию документа
          </Button>
        )}

        {status === 'Согласовано' && !isAnnulled && (
          <Typography variant='body2' color={theme.palette.warning.main} textAlign={'center'}>
            Добавление новой версии документа недоступно в статусе тома “Согласовано”.
          </Typography>
        )}

        {isAnnulled && (
          <Typography variant='body2' color={theme.palette.warning.main} textAlign={'center'}>
            Добавление новой версии документа недоступно для тома с признаком “Аннулирован”.
          </Typography>
        )}
      </Box>

      <Stack spacing={2} overflow='hidden'>
        {!!tomVersions?.length && (
          <ActualVersionWrapper title='Текущая версия документа' variant='primary' contentWrapper='none'>
            <DocList listVariant='lastVersion' itemVariant='navbar' />
          </ActualVersionWrapper>
        )}

        {tomVersions?.length > 1 && (
          <TitledWrapper title='Архив версий документа' variant='primary' contentWrapper='scrollable'>
            <DocList listVariant='archiveVersions' itemVariant='navbar' />
          </TitledWrapper>
        )}
      </Stack>
    </Stack>
  )
}
