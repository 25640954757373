import { Avatar, ListItemAvatar, MenuItem, SelectChangeEvent, Stack } from "@mui/material"
import { FilterTitleText, StickyContainer, StyledButton, StyledCompanyText, StyledDivider, StyledItemText, StyledListItem, StyledSelect, StyledTextField } from "./FilterSelectFields.styles"
import { useEffect, useState } from "react"
import { FilterSelectFieldsProps, FilterValues } from "./FilterSelectFields.types"
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SearchIcon from '@mui/icons-material/Search';

const arrayCompare = (a: FilterValues[], b: FilterValues[]) => (a.length === b.length)
  && a.every((element, index) => element?.uId === b[index]?.uId)


export const FilterSelectFields = ({
  selectedOptions,
  defaultValue,
  onSubmit,
  data,
}: FilterSelectFieldsProps) => {

  const [options, setOptions] = useState<FilterValues[]>([])
  const [filteredOptions, setFilteredOptions] = useState<FilterValues[]>([])
  const [values, setValues] = useState<FilterValues[]>([])

  const resetFilter = () => {
    onSubmit([])
    setValues([])
  }

  const buildOptionsList = (data: FilterValues[]) => {
    const optionsList: JSX.Element[] = []
    data.forEach((item, index) => {
      if (typeof item.company === 'string') {
        optionsList.push(
          <MenuItem
            sx={{ flexDirection: 'column', alignItems: 'stretch', padding: 0 }}
            key={`${item.company}-${index}`}
            value={item.uId}>
            <Stack padding="6px 16px">
              <StyledCompanyText>{item.company || 'Без компании'}</StyledCompanyText>
            </Stack>
            <StyledDivider />
          </MenuItem>
        )
      }

      if (item.id || item.name) {
        optionsList.push(
          <MenuItem
            key={`${item.name}-${index}`}
            value={item.uId}>
            <StyledListItem disablePadding>
              <ListItemAvatar sx={{ minWidth: 'auto' }}>
                <Avatar src={item.avatar || undefined} sx={{ width: "32px", height: "32px", marginRight: "16px" }} />
              </ListItemAvatar>
              <StyledItemText
                primary={item.name}
                secondary={item.position} />
            </StyledListItem>
          </MenuItem>
        )
      }
    })
    return optionsList
  }

  useEffect(() => {
    if (selectedOptions.length > 0) {
      const selectedValues = selectedOptions.map(option => options.find(opt => opt.uId === option.uId)!)
      setValues(selectedValues)
    }
  }, [options, selectedOptions])

  useEffect(() => {
    if (data) {
      const filterValues: FilterValues[] = []
      data.forEach((item, index) => {
        filterValues.push({ company: item.company || '', uId: `${index}-${item.company}` })
        item.persons.length > 0 && item.persons.forEach((person) => {
          filterValues.push({ id: person.id, name: person.name, avatar: person.avatar, position: person.position, uId: `${index}-${person.name}` })
        })
      })
      setOptions(filterValues)
      setFilteredOptions(filterValues)
    }
  }, [data])

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedIds = event.target.value as string[]
    const selectedValues = options.filter(option => selectedIds.includes(option.uId))
    setValues(selectedValues)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toLowerCase()
    const filteredData = options.filter((item) => {
      const company = item.company ? item.company.toLowerCase() : ''
      const name = item.name ? item.name.toLowerCase() : ''
      return company.includes(inputValue) || name.includes(inputValue)
    })
    setFilteredOptions(filteredData)
  }

  return (
    <StyledSelect
      multiple
      disableUnderline
      displayEmpty
      variant="standard"
      value={values.filter(value => value !== undefined).map(value => value.uId)}
      onChange={handleChange}
      MenuProps={{
        disableAutoFocusItem: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 100,
        },
        MenuListProps: {
          sx: {paddingTop: 0}
        },
        PaperProps: {
          style: { minWidth: 320, maxHeight: 320 }
        },
      }}
      renderValue={() => {
        return (
          <Stack ml={1} direction='row'>
            {selectedOptions.length === 0
              ? (
                <FilterTitleText>{defaultValue}</FilterTitleText>
              ) : (
                <Stack direction='row' spacing={0.5} alignItems='center'>
                  <FilterAltIcon fontSize="small" color="primary" />
                  <FilterTitleText>{defaultValue}</FilterTitleText>
                </Stack>
              )}
          </Stack>
        )
      }}
    >
      <StickyContainer direction='row' spacing={2} py={1} px={2}>
        <StyledTextField
          onKeyDown={e => e.stopPropagation()}
          type="search"
          placeholder="Поиск"
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <SearchIcon fontSize="medium" />
            )
          }}
        />
        {!selectedOptions.length || !arrayCompare(selectedOptions, values) ? (
          <StyledButton
            variant="text"
            color="primary"
            onClick={() => onSubmit(values)}
            disabled={values.length === 0 && selectedOptions.length === 0}
          >
            Применить
          </StyledButton>
        ) : (
          <StyledButton variant="text" color="warning" onClick={resetFilter}>
            Сбросить
          </StyledButton>
        )}

      </StickyContainer>
      {buildOptionsList(filteredOptions)}
    </StyledSelect>
  )
}
