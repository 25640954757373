import styled from 'styled-components'

import FieldForm from '../../../../../components/FieldForm'
import { AutocompleteField } from '../../../../../components/AutocompleteField/AutocompleteField'
import { FormSelectFieldProps, FormTitledWrapper } from '../../../../Administration/ProjectManagement/components/FormLeftBlock/FormLeftBlock.styles'

export const AgreementTitledWrapper = styled(FormTitledWrapper)`
  & .title {
    padding-left: 6px;
    color: ${props => props.theme.palette.secondary.dark};
  }

  & .formItemTitle {
    min-width: fit-content;
    margin-top: 4px;
    white-space: nowrap;
  }

  & .MuiFormControl-root,
  & .MuiAutocomplete-root {
    max-width: 230px;
  }

  & .MuiInputBase-root {
    height: 32px;
  }
`

export const AgreementTitledWrapperAutocomplete = styled(AutocompleteField)`
  & .MuiInputBase-root {
    padding: 7px 18px;
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    max-width: calc(100% - 50px);
    transform: translate(18px, 6px) scale(1);
  }
`

export const AgreementDurationField = styled(FieldForm)`
  width: 100px;

  & .MuiInputBase-root {
    padding-left: 10px;

    &.Mui-error {
      border-color: ${props => props.theme.palette.error.main};
    }
  }

  & .MuiInputBase-input {
    text-align: center;
  }

  & .MuiSvgIcon-root.clockIcon {
    color: ${props => props.theme.palette.info.main};
  }

  & .MuiFormHelperText-root {
    display: none;
  }
`

export const AgreementSelect = styled(FieldForm)<FormSelectFieldProps>`
  width: 100%;

  & .MuiSelect-select {
    text-align: left !important;
  }

  & .MuiInputLabel-root {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${props => props.theme.palette.disabled} !important;
    transform: translate(14px, 6px) scale(1);

    &.MuiInputLabel-shrink {
      opacity: ${props => props.disablePlaceholder ? 0 : 1};
      transform: translate(14px, 6px) scale(1);
    }
  }

  & .MuiOutlinedInput-notchedOutline legend {
    padding: 0;

    & span {
      display: none;
    }
  }
`

export const AgreementCheckbox = styled(FieldForm)`
  margin-left: 0;
`

export const AgreementCheckboxStart = styled(AgreementCheckbox)`
  align-self: start;
  height: 28px;
  margin-left: -11px !important;
`