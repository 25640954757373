import { FC } from 'react'
import { ExportArchiveDrawerSelectedListWrapper } from './styles'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import { useArchiveClear } from './hooks/useArchiveClear'
import { useRenderSelectedToms } from './hooks/useRenderSelectedToms'

export const ExportArchiveDrawerSelectedList: FC = () => {
  const { onClearSelectedToms } = useArchiveClear()
  const { selectedToms } = useRenderSelectedToms()

  return (
    <ExportArchiveDrawerSelectedListWrapper>
      <Stack direction='row' justifyContent='space-between' alignItems='center' pr={2.5} pb={2} spacing={1}>
        <Box>
          <Typography
            variant='subtitle2'
            fontSize={16}
            lineHeight='24px'
            letterSpacing='0.15px'
          >
            Выбранные документы
          </Typography>
        </Box>

        <Button
          onClick={() => onClearSelectedToms()}
          variant='text'
          color='error'
          sx={{ height: 30, p: '4px 8px' }}
        >
          <Typography
            variant='tooltip'
            color='inherit'
            fontSize={12}
            lineHeight='13.2px'
            textTransform='lowercase'
          >
            очистить список
          </Typography>

        </Button>
      </Stack>

      <ScrollableContainer>
        {selectedToms}
      </ScrollableContainer>
    </ExportArchiveDrawerSelectedListWrapper>
  )
}
