import styled from "styled-components";
import {
  InternalAgreementCheckBox,
  InternalAgreementSwitchField,
  InternalAgreementTextField,
  InternalAgreementTimeField,
  InternalAgreementTimeValueField,
  InternalAgreementViewField,
  StyledSpan,
  TitleField
} from "../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementControl";
import { StyledSubmitButton } from "../../../../../Schemes/InternalAgreementSchemes/components/InternalAgreementForm";

export const FieldName = styled(TitleField)`
  font-size: 12px;
`
export const ViewField = styled(InternalAgreementViewField)`
`

export const TextInput = styled(InternalAgreementTextField)`
  width: auto;
  & .MuiInputBase-root {
    height: 30px;
  }
`

export const TimeField = styled(InternalAgreementTimeField)`
  height: 30px;
`

export const TimeInput = styled(InternalAgreementTimeValueField)`
   & .MuiFilledInput-input {
  padding: 5px 8px;
 }
`

export const SwitchInput = styled(InternalAgreementSwitchField)`
    margin-right: 0;

    & .MuiSwitch-input {
    height: 30px;
  }

  & .MuiSwitch-switchBase {
    padding: 2px;
    top: 5px;
  }
`

export const StyledSpanSwithInput = styled(StyledSpan)`
  top: 8px;
  left: 64px;

  & ~ & {
    left: 111px;
  }
`

export const CheckBoxInput = styled(InternalAgreementCheckBox)`
  height: 30px;

  .MuiCheckbox-root {
    padding: 0;
  }
`
export const StyledButton = styled(StyledSubmitButton)`
  font-weight: 400;
`