import { Stack } from "@mui/material";
import styled from "styled-components";
import { LoaderProps } from "./Loader.types";

export const LoaderContainer = styled(Stack) <LoaderProps>`
  padding: 30px;
  flex-direction: column;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 10px;
  height: ${props => typeof props.height === 'number' ? props.height + 'px' : props.height};
  width: ${props => props.width ? typeof props.width === 'number' ? props.width + 'px' : props.width : '36vw'};
`