import { Box, Stack } from '@mui/material'
import { ReactNode } from 'react'

import Tabs from '@components/Tabs'
import { TabsEntities } from '@components/Tabs/Tabs.types'

import { DocsBottomLegendProps } from '.'

export const DocsBottomLegend = <T extends TabsEntities>({
  currentTab,
  tabsData,
  onTabChange,
  countPagination,
}: DocsBottomLegendProps<T> & { children?: ReactNode }) => {
  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ pb: 1.5 }}>
        <Stack direction='row' alignItems='center' spacing={1.5}>
          <Tabs<T>
            currentTab={currentTab}
            onTabChange={onTabChange}
            tabsData={tabsData}
            countPagination={countPagination}
          />
        </Stack>
      </Stack>
    </Box>
  )
}
