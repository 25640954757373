import { Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { theme } from "../../../styles/theme"
import InfoIcon from "../../../assets/icons/InfoIcon"
import { useGetInnermapsMenuQuery } from "../../../api/internalAgreement"
import Progress from "../../../components/Progress"
import { NavbarSchema } from "./components/NavbarSchema"
import { Innermap, MenuInnermap } from "../../../api/internalAgreement/types"
import { TableFormValues, currentSchemaSelector, setCurrentSchema, setInitialSchemaCompanyFromMenu, setIsOpenFormAddMember, setSchemaFormMode, setTableFormValues } from "../../../store/slices/internalAgreement"
import { useAppDispatch, useTypedSelector } from "../../../store/store"
import { useCallback, useEffect } from "react"
import { profileSelector } from "../../../store/slices/profile"
import { UserDrawerContent } from "../../../components/UserNavBar/styles"

export const InternalAgreementNavbarContent = () => {

  const dispatch = useAppDispatch()
  const { projectId: projectIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)

  const profile = useTypedSelector(profileSelector)
  const currentSchema = useTypedSelector(currentSchemaSelector)

  const { data: innermapsMenuData, isFetching } = useGetInnermapsMenuQuery({ id: projectId })
  const { data: innermapsMenu = [] } = innermapsMenuData || {}

  const onInnermapAddClick = (companyData: MenuInnermap) => {
    dispatch(setCurrentSchema({} as Innermap))
    dispatch(setSchemaFormMode({ schemaFormMode: 'create' }))
    dispatch(setInitialSchemaCompanyFromMenu({ initialSchemaCompanyFromMenu: companyData.company }))
  }

  const onInnermapClick = (innermap: Innermap) => {
    dispatch(setCurrentSchema(innermap))
    dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
    dispatch(setTableFormValues({ tableFormValues: {} as TableFormValues }))
  }

  useEffect(() => {
    if (currentSchema?.id) return
    dispatch(setCurrentSchema(innermapsMenu[0]?.innermaps[0]))
    if (!innermapsMenu.length) {
      dispatch(setInitialSchemaCompanyFromMenu({ initialSchemaCompanyFromMenu: profile.company.userCompanyName }))
    }
  }, [innermapsMenu])

  const setInnnermap = useCallback(() => {
    if (innermapsMenuData) {
      dispatch(setCurrentSchema(innermapsMenu[0]?.innermaps[0]))
    }
  }, [innermapsMenuData])

  useEffect(() => {
    setInnnermap()
  }, [projectId, setInnnermap])

  useEffect(() => {
    if (!innermapsMenu.length && !isFetching) {
      dispatch(setSchemaFormMode({ schemaFormMode: 'create' }))
    }
    return () => {
      dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
    }

  }, [innermapsMenu])

  useEffect(() => {
    const updatedSchema = innermapsMenu
      .find((innermap) => innermap.company === currentSchema?.company)
      ?.innermaps.find((schema) => schema.id === currentSchema.id)

    updatedSchema && dispatch(setCurrentSchema(updatedSchema))
  }, [innermapsMenu])

  return (
    <UserDrawerContent flex={1}>
      {isFetching
        ? <Progress />
        : !innermapsMenu.length
          ? (
            <Stack direction='row' alignItems='center' margin='auto' color={theme.palette.legends.disabled}>
              <InfoIcon color='disabled' />
              <Typography ml='8px' variant='caption'>Нет схем внутреннего согласования</Typography>
            </Stack>
          ) : (
            <Stack spacing={2.5}>
              {innermapsMenu.map(companyData => (
                <NavbarSchema
                  companyData={companyData}
                  onInnermapClick={onInnermapClick}
                  onInnermapAddClick={onInnermapAddClick}
                  key={companyData.company}
                />
              ))}
            </Stack>
          )
      }
    </UserDrawerContent>
  )
}