import React, { FC, useEffect, useMemo } from 'react'
import { ProjectFullExportFormData, ProjectFullExportFormProps } from './ProjectFullExportForm.types'
import { ProjectFullExportFormWrapper, ProjectFullExportSwitchesWrapper } from './ProjectFullExportForm.styles'
import { Form, FormikProvider } from 'formik'
import { Button, Stack, Typography } from '@mui/material'
import { useForm } from '../../../../../../hooks/useForm'
import FieldForm from '../../../../../../components/FieldForm'
import { theme } from '../../../../../../styles/theme'
import { FullExportDrawerMainList } from '../FullExportDrawerMainList'
import { FullExportDrawerSelectedList } from '../FullExportDrawerSelectedList'
import { fullExportFormValidation } from './ProjectFullExportForm.validation'
import { useSnackbar } from 'notistack'
import { useAppDispatch } from '../../../../../../store/store'
import Tooltip from '../../../../../../components/Tooltip'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import { useGetTomsFileListQuery, useSendTomsFileListMutation } from '../../../../../../api/projects'
import { useParams } from 'react-router-dom'
import { SendTomsFileListRequest, SendTomsFileListResponse } from '../../../../../../api/projects/api.types'

export const ProjectFullExportForm: FC<ProjectFullExportFormProps> = ({ onFormChange, onDrawerClose }) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const { data: tomsFileListData } = useGetTomsFileListQuery({ id: projectId }, { skip: !projectId })
  const { phases = [], existDoc } = tomsFileListData || {}

  const initialValues: ProjectFullExportFormData = useMemo(() => ({
    includeChanges: false,
    includeAttach: false,
    includeExcel: false,
    phaseList: phases,
    selectedToms: []
  }), [phases])


  const [sendTomsFileList, sendTomsFileListResponse] = useSendTomsFileListMutation()

  const onSubmit = (values: ProjectFullExportFormData) => {
    const dataForRd: SendTomsFileListRequest = {
      id: projectId,
      includeChanges: values.includeChanges,
      includeAttach: values.includeAttach,
      includeExcel: values.includeExcel,
      toms: values.selectedToms.map(({ tom, phase }) => ({ id: tom.id, type: phase })),
    }

    sendTomsFileList(dataForRd)
  }

  useMutationHandlers(
    sendTomsFileListResponse,
    (data: SendTomsFileListResponse) => {
      window.open(data.link)
      onDrawerClose(false, true )
    },
  )

  const { formik } = useForm({
    validationSchema: fullExportFormValidation,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, errors, dirty, isSubmitting } = formik
  const minTomsCountError = errors.selectedToms

  useEffect(() => {
    if (isSubmitting && minTomsCountError) {
      enqueueSnackbar('Выберите минимум один том', { variant: 'error' })
    }
  }, [isSubmitting, errors])

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  // const [exportTomsRd, exportTomsRdResponse] = useExportTomsRdMutation()
  //
  // const onExportClick = () => {
  //   exportTomsRd({ id: project.id })
  // }
  //
  // useMutationHandlers(
  //   exportTomsRdResponse,
  //   (data: Blob) => {
  //     downloadBlob(new File([data], ''), `${project.shifrName} РД.xlsx`)
  //   }
  // )


  return (
    <ProjectFullExportFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} component={Form} justifyContent='space-between' height='100%'>
          <Stack direction='row' justifyContent='space-between' spacing={2.5} overflow='hidden' height='100%'>
            <FullExportDrawerMainList />
            <FullExportDrawerSelectedList />
          </Stack>


          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <ProjectFullExportSwitchesWrapper>
              <Tooltip title='Добавить к экспортируемому архиву реестр в Excel'>
                <div>
                  <FieldForm
                    version='checkbox'
                    name='includeExcel'
                    checkboxData={{
                      label: (
                        <Typography
                          variant='subtitle2'
                          color={theme.palette.text.dark}
                          fontWeight={400}
                          component='span'
                        >
                          Включить Excel отчет
                        </Typography>
                      ),
                      checked: values.includeExcel,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          `includeExcel`,
                          e.target.checked
                        )
                      }
                    }}
                  />
                </div>
              </Tooltip>

              <Tooltip title='Добавить к экспортируемому архиву редактируемые версии документов'>
                <div>
                  <FieldForm
                    version='checkbox'
                    name='includeAttach'
                    checkboxData={{
                      label: (
                        <Typography
                          variant='subtitle2'
                          color={theme.palette.text.dark}
                          fontWeight={400}
                          component='span'
                        >
                          Ред. версии
                        </Typography>
                      ),
                      checked: values.includeAttach,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          `includeAttach`,
                          e.target.checked
                        )
                      }
                    }}
                  />
                </div>
              </Tooltip>

              <Tooltip title='Добавить к экспортируемому архиву все изменения документов'>
                <div>
                  <FieldForm
                    version='checkbox'
                    name='includeChanges'
                    checkboxData={{
                      label: (
                        <Typography
                          variant='subtitle2'
                          color={theme.palette.text.dark}
                          fontWeight={400}
                          component='span'
                        >
                          Изменения док-тов
                        </Typography>
                      ),
                      checked: values.includeChanges,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          `includeChanges`,
                          e.target.checked
                        )
                      }
                    }}
                  />
                </div>
              </Tooltip>
            </ProjectFullExportSwitchesWrapper>

            <Stack direction='row' spacing={2}>
              {/*<Tooltip title='Экспортировать реестр в Excel'>*/}
              {/*<Button*/}
              {/*  onClick={onExportClick}*/}
              {/*  color='success'*/}
              {/*  size='small'*/}
              {/*  sx={{ minWidth: 40, padding: '8px' }}*/}
              {/*>*/}
              {/*  <ExportIcon />*/}
              {/*</Button>*/}
              {/*</Tooltip>*/}

              <Tooltip title='Сформировать и скачать архив с рабочей документацией'>
                <Button
                  type='submit'
                  disabled={isSubmitting}
                  color='success'
                  size='small'
                >
                  Сформировать
                </Button>
              </Tooltip>

              <Button
                size='small'
                onClick={() => onDrawerClose(false, true )}
              >
                Отменить
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormikProvider>
    </ProjectFullExportFormWrapper>
  )
}
