import { useMemo } from 'react'
import { UseGetChangesProps, UseGetChangesResponse } from './useGetChanges.types'
import { useGetTomRdChangesQuery } from '../../../../api/rdPhase'
import { useGetTomPdChangesQuery } from '../../../../api/pdPhase'
import { useGetTomIiChangesQuery } from '../../../../api/iiPhase'
import { ProjectPhase } from '../../../../types/project'
import { TomChange, TomDocument } from '../../../../types/tom'
import { useGetTomIrdChangesQuery } from '../../../../api/irdPhase'
import { useGetTomCmnChangesQuery } from '../../../../api/cmnTom'

export const useGetChanges = ({
  currentTomId = 0,
  currentTomStatus,
  currentPage,
  selectedProjectPhase,
  isCmnTom,
}: UseGetChangesProps): UseGetChangesResponse => {
  // Rd
  const { data: tomRdChangesData, isFetching: tomRdChangesFetching } = useGetTomRdChangesQuery(
    { id: currentTomId },
    { skip: currentPage !== 'doc-view' || selectedProjectPhase !== 'Рабочая документация' || !currentTomId },
  )
  const { data: tomRdChanges } = tomRdChangesData || {}

  // Pd
  const { data: tomPdChangesData, isFetching: tomPdChangesFetching } = useGetTomPdChangesQuery(
    { id: currentTomId },
    { skip: currentPage !== 'doc-view' || selectedProjectPhase !== 'Проектная документация' || !currentTomId },
  )
  const { data: tomPdChanges } = tomPdChangesData || {}

  // Ii
  const { data: tomIiChangesData, isFetching: tomIiChangesFetching } = useGetTomIiChangesQuery(
    { id: currentTomId },
    { skip: currentPage !== 'doc-view' || selectedProjectPhase !== 'Инженерные изыскания' || !currentTomId },
  )
  const { data: tomIiChanges } = tomIiChangesData || {}

  // Ird
  const { data: tomIrdChangesData, isFetching: tomIrdChangesFetching } = useGetTomIrdChangesQuery(
    { id: currentTomId },
    { skip: currentPage !== 'doc-view' || selectedProjectPhase !== 'Сбор исходных данных' || !currentTomId },
  )
  const { data: tomIrdChanges } = tomIrdChangesData || {}

  // Cmn
  const { data: tomCmnChangesData, isFetching: tomCmnChangesFetching } = useGetTomCmnChangesQuery(
    { id: currentTomId },
    {
      skip:
        currentPage !== 'doc-view' ||
        !currentTomId ||
        selectedProjectPhase !== 'Проектная документация' ||
        !currentTomId,
    },
  )
  const { data: tomCmnChanges = [] } = tomCmnChangesData || {}

  // Common
  const tomChangesByPhase: Record<ProjectPhase, TomChange[]> = useMemo(
    () => ({
      'Рабочая документация': tomRdChanges || [],
      'Проектная документация': tomPdChanges || [],
      'Инженерные изыскания': tomIiChanges || [],
      'Сбор исходных данных': tomIrdChanges || [],
    }),
    [tomRdChanges, tomPdChanges, tomIiChanges, tomIrdChanges],
  )

  const tomChanges = tomChangesByPhase[selectedProjectPhase]

  const changesLoading = useMemo(
    () =>
      tomRdChangesFetching ||
      tomPdChangesFetching ||
      tomIiChangesFetching ||
      tomIrdChangesFetching ||
      tomCmnChangesFetching,
    [tomRdChangesFetching, tomPdChangesFetching, tomIiChangesFetching, tomIrdChangesFetching, tomCmnChangesFetching],
  )

  return {
    tomChanges,
    tomCmnChanges,
    changesLoading,
  }
}
