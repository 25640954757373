import { TomChange, TomStatus } from 'types/tom'

export interface IDocsChangeFormProps {
  onFormChange: (dirty: boolean) => void
  currentChange: TomChange | null
  onClose: (dirty: boolean, immediately?: boolean) => void
}

export interface IDocsChangeFormData {
  mode: 'add' | 'edit'
  inProd: boolean
  change: string | null
  status: TChangeStatus | null
  architector: IChangeArchitector | null
  plannedDate: Date | null
  actualDate: Date | null
  files: File[] | null
  description: string | null
  durationDate: Date | null
  issueDate: Date | null
  prodDate: Date | null
}

export interface IChangeArchitector {
  id: number | null
  name: string
}

export const TOM_CHANGE_DEFAULT_STATUS = 'Не установлен'

export type TChangeStatus = TomStatus

export const changeStatusesIrd: TChangeStatus[] = [
  'Согласовано',
  'Корректировка',
  'На согласовании',
  'Разработка',
  'Не в работе',
  'Не согласуется',
]

export const changeStatuses: TChangeStatus[] = changeStatusesIrd.filter((status) => status !== 'Не согласуется')
