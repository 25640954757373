import { TableCellProps } from '@mui/material/TableCell/TableCell'
import { EntireWorkPeriod, Work, WorkGroup, WorkRatio } from '../../../../api/works'
import { FC, ReactNode } from 'react'
import { CustomCellProps, SelfCellProps } from './cells/cells.types'

// Common
export interface WorksTableProps {
  data: WorkGroup[];
  onRowClick: (workId: number) => void;
  tableView: WorksTableView;
  entirePeriod: EntireWorkPeriod;
  overallRatio: WorkRatio;
}

export type WorksTableView = 'quarters' | 'months' | 'days'

// Common columns
export type WorksTableColumn = WorksTableColumnWithField | WorksTableColumnCustomCell | WorksTableColumnSelfCell

export type CommonWorksTableColumn = TableCellProps & {
  label: string;
  hover?: boolean;
  actions?: ReactNode[];
}

type WorksTableColumnWithField = CommonWorksTableColumn & {
  field: keyof Pick<Work, 'id' | 'title' | 'status' | 'group'>;
  CustomCell?: never;
  SelfCell?: never;
}

type WorksTableColumnCustomCell = CommonWorksTableColumn & {
  CustomCell: FC<CustomCellProps>;
  field?: never;
  SelfCell?: never;
}

type WorksTableColumnSelfCell = CommonWorksTableColumn & {
  SelfCell: FC<SelfCellProps>;
  CustomCell?: never;
  field?: never;
}

// Graph columns
export type QuarterCellTemplate = CommonWorksTableColumn & {
  quarter: number;
  year?: number;
  isCurrentDate?: boolean;
}
export type QuarterCell = QuarterCellTemplate & Required<Pick<QuarterCellTemplate, 'year' | 'isCurrentDate'>>

export type MonthCellTemplate = CommonWorksTableColumn & {
  month: number;
  year?: number;
  isCurrentDate?: boolean;
}
export type MonthCell = MonthCellTemplate & Required<Pick<MonthCellTemplate, 'year' | 'isCurrentDate'>>

export type YearDayCell = CommonWorksTableColumn & {
  year: number;
  month: number;
}

export type DayCell = YearDayCell & {
  day: number;
  isCurrentDate: boolean;
}

// Ratio column
export type RatioBottomColumn = WorksTableColumn & {
  data?: number
  dataVariant?: keyof WorkRatio
}

// Constants
export const GROUP_CELL_DEFAULT_COLSPAN = 20

export const TABLE_CELL_HEIGHT = 64

export const MOVE_CELL_WIDTH = 44
export const TITLE_CELL_WIDTH = 400
export const PERSON_CELL_WIDTH = 160
export const PERIOD_CELL_WIDTH = 170
export const DURATION_CELL_WIDTH = 120
export const RATIO_CELL_WIDTH = 360
export const RATIO_ITEM_CELL_WIDTH = RATIO_CELL_WIDTH / 3
export const STATUS_CELL_WIDTH = 180

export const MOVE_CELL_OFFSET = 0
export const TITLE_CELL_OFFSET = MOVE_CELL_WIDTH + MOVE_CELL_OFFSET
export const PERSON_CELL_OFFSET = TITLE_CELL_WIDTH + TITLE_CELL_OFFSET
export const PERIOD_CELL_OFFSET = PERSON_CELL_WIDTH + PERSON_CELL_OFFSET
export const DURATION_CELL_OFFSET = PERIOD_CELL_WIDTH + PERIOD_CELL_OFFSET
export const RATIO_CELL_OFFSET = DURATION_CELL_WIDTH + DURATION_CELL_OFFSET
export const STATUS_CELL_OFFSET = RATIO_CELL_WIDTH + RATIO_CELL_OFFSET
