import React, { useCallback, useEffect, useMemo } from 'react'
import { RdDrawerFormProps, RdFormData } from './RdDrawerForm.styles'
import { RdDrawerFormWrapper } from './styles'
import { Form, FormikProvider } from 'formik'
import { Stack } from '@mui/material'
import FieldForm from '../../../../components/FieldForm'
import Button from '../../../../components/Button'
import { RdBaseResponse, RdRequest, useCreateRdMutation, useEditRdMutation } from '../../../../api/rdPhase'
import { useForm } from '../../../../hooks/useForm'
import { validationRd } from './validation'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'

const RdDrawerForm: React.FC<RdDrawerFormProps> = ({ rd, onClose, onFormChange }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { id, mark, fullName, description } = rd || {}

  const initialValues: RdFormData = useMemo(() => ({
    mark: mark || '',
    fullName: fullName || '',
    description: description || '',
  }), [rd])

  const [createRd, createRdResponse] = useCreateRdMutation()
  const [editRd, editRdResponse] = useEditRdMutation()

  const onSubmit = useCallback((values: RdFormData) => {
    const dataForRequest: RdRequest = {
      mark: values.mark,
      fullName: values.fullName,
      description: values.description,
    }
    if (id) {
      editRd({
        id,
        ...dataForRequest
      })
    } else {
      createRd(dataForRequest)
    }
  }, [rd])

  const { formik } = useForm({
    validationSchema: validationRd,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { dirty, isSubmitting } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  useMutationHandlers(
    createRdResponse,
    (data: RdBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
    }
  )

  useMutationHandlers(
    editRdResponse,
    (data: RdBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
    }
  )

  return (
    <RdDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} component={Form}>
          <Stack spacing={1.5}>
            <FieldForm
              version='rd'
              name='mark'
              placeholder='Марка'
            />
            <FieldForm
              version='rd'
              name='fullName'
              placeholder='Полное наименование'
              multiline
              minRows={2}
            />
            <FieldForm
              version='rd'
              name='description'
              placeholder='Добавьте примечание если это необходимо'
              multiline
              minRows={2}
            />
          </Stack>


          <Stack direction='row' spacing={2}>
            <Button
              type='submit'
              disabled={isSubmitting}
              color='success'
              size='medium'
              fullWidth
            >
              Сохранить
            </Button>
            <Button size='medium' fullWidth onClick={() => onClose(false, true)}>Отменить</Button>
          </Stack>

        </Stack>
      </FormikProvider>
    </RdDrawerFormWrapper>
  )
}

export default RdDrawerForm
