import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const HomeIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.796 2L15.2564 5.52278V3.20031H19.4381V9.77981L20.7986 11.1648V22.4051H12.654V16.0437H10.4616V22.4051H2.24414V11.1468L11.796 2ZM4.24414 12.0008V20.4051H8.46163V14.0437H14.654V20.4051H18.7986V11.9828L11.753 4.81027L4.24414 12.0008Z'
      />
    </SvgIcon>
  )
}

export default HomeIcon