import { TimStatusView } from "../../../../types/models";
import { RootState } from "../../../store";
import { SelectedModels, TimFilter } from "../tim.types";

export const timFilterSelector = (state: RootState): TimFilter => state.tim.timFilter
export const timStatusSelector = (state: RootState): TimStatusView | null => state.tim.timStatus
export const selectedModelsSelector = (state: RootState): SelectedModels[] => state.tim.selectedModels
export const selectedFromTreeElementsSelector = (state: RootState): number[] => state.tim.selectedFromTreeElements
export const selectedFromModelElementsSelector = (state: RootState): number[] => state.tim.selectedFromModelElements
export const isolatedElementsSelector = (state: RootState): number[] => state.tim.isolatedElements
export const isShowAllSelector = (state: RootState): boolean => state.tim.isShowAll