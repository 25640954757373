import { StageResultRequest } from '../../../api/projectAgreement/types'

export interface ProjectAgreementFormProps {

}

export interface ProjectAgreementFormData {
  title: string;
  duration: number;
  personId: number | null
  companyName?: string
  idLeft?: number;
  idRight?: number;
  optDocs: boolean;
  optVisa: boolean;
  isStart: boolean;
  results: StageResultRequest[]
}

export const emptyResultTemplate: Omit<StageResultRequest, 'type'> = {
  title: '',
  isFinal: false,
  tomStatus: undefined,
  nextStageId: undefined,
}

export type AgreementFormConfirmTrigger = 'return' | 'closeDrawer' | 'delete' | 'deleteBoundInnermap' | 'changeCompany'