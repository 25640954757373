import React, { FC, SyntheticEvent } from 'react'
import { WorksBottomLegendProps } from './WorksBottomLegend.types'
import { Pagination, Stack, Tab, Tabs } from '@mui/material'
import { TabData } from '../../../../components/Tabs/Tabs.types'
import { WorksTableView } from '../WorksTable'
import FilterSelect from '../../../../components/FilterSelect'
import { theme } from '../../../../styles/theme'
import { useTranslation } from 'react-i18next'

export const WorksBottomLegend: FC<WorksBottomLegendProps> = ({
  tabValue,
  onTabChange,
  page,
  countPagination,
  numberRows,
  onChangeLimit,
  onChangePage,
  limit,
}) => {
  const { t } = useTranslation('user')

  const tabsData: TabData<WorksTableView>[] = [
    { value: 'days', label: 'По дням' },
    { value: 'months', label: 'По месяцам' },
    { value: 'quarters', label: 'По кварталам' },
  ]

  const onChange = (e: SyntheticEvent, tabValue: WorksTableView) => {
    onTabChange(tabValue)
  }

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ pb: 1.5 }}>
      <Tabs value={tabValue} onChange={onChange}>
        {tabsData.map(tabData => (
          <Tab {...tabData} />

        ))}
      </Tabs>

      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3}>
        <FilterSelect
          items={numberRows!}
          value={limit!}
          onChange={onChangeLimit!}
          startIcon={t('header.rows')}
        />
        <Pagination
          onChange={(e, v) => onChangePage!(v)}
          count={countPagination}
          shape='rounded'
          page={page}
          sx={{
            '& .MuiPaginationItem-root': {
              '&.Mui-selected': {
                background: 'rgba(109, 154, 220, 0.2)',
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
              },
              '& .MuiSvgIcon-root': {
                fontSize: '20px !important',
              },
            },
          }}
        />
      </Stack>
    </Stack>
  )
}
