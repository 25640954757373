import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import styled from 'styled-components'

import Button from '../../../../components/Button'

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;

  &::before {
    display: none;
  }
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 34px;
  padding: 6px 16px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 4px;
  width: 100%;

  &.Mui-expanded {
    min-height: 34px;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    width: ${(props) => (props.expandIcon ? 'calc(100% - 20px)' : '100%')};

    & .MuiTypography-root {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-align: start;
    }
  }
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  padding-top: 8px;
`

export const AccordionDocButton = styled(Button)`
  justify-content: start;
  max-width: 400px;
  width: 100%;

  & .MuiTypography-root {
    color: ${(props) => props.theme.palette.text.dark};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-align: start;
  }
`

export const CircleSelected = styled.span`
  background-color: ${(props) => props.theme.palette.primary.main};
  width: 6px;
  height: 6px;
  border-radius: 50%;
`
