import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const SpeakIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M20.1402 15.3887C21.3574 13.3797 22.0017 11.0639 22.0007 8.70148C22.0043 6.33425 21.3599 4.01311 20.1402 2L18.4332 3.55782C19.2906 5.11933 19.7436 6.87845 19.7493 8.66867C19.755 10.4589 19.3132 12.221 18.4658 13.7881L20.1402 15.3887ZM13.163 8.70158C13.163 10.8 11.4971 12.5011 9.44209 12.5011C7.38708 12.5011 5.72117 10.8 5.72117 8.70158C5.72117 6.60314 7.38708 4.90202 9.44209 4.90202C11.4971 4.90202 13.163 6.60314 13.163 8.70158ZM13.1581 14.8758V14.7713C15.386 15.403 16.8837 16.5809 16.8837 17.963V22H2V17.9392C2 16.5809 3.49302 15.403 5.72093 14.7713V14.8758C5.72093 17.7397 6.73488 20.1572 8.15348 21.1071L8.80929 15.6642C8.58075 15.5455 8.38865 15.3648 8.25409 15.142C8.11953 14.9191 8.0477 14.6627 8.0465 14.4009C8.04421 14.3835 8.04421 14.366 8.0465 14.3486C8.51162 14.3059 8.97674 14.2821 9.44185 14.2821C9.90697 14.2821 10.3721 14.3059 10.8372 14.3486C10.8395 14.366 10.8395 14.3835 10.8372 14.4009C10.836 14.6627 10.7642 14.9191 10.6296 15.142C10.495 15.3648 10.303 15.5455 10.0744 15.6642L10.7256 21.1071C12.1442 20.1715 13.1581 17.7255 13.1581 14.8758ZM17.8143 8.70155C17.8158 9.97215 17.5377 11.2268 17.0003 12.3729L15.1817 10.6346C15.386 10.0115 15.4897 9.35862 15.4887 8.70155C15.4882 7.98249 15.3655 7.26897 15.1259 6.59279L16.9492 4.92573C17.5194 6.09919 17.8155 7.3916 17.8143 8.70155Z'
      />
    </SvgIcon>
  )
}

export default SpeakIcon