import React, { useCallback, useState } from 'react'
import { Divider, List, ListItemIcon, ListItemText } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import AdminNavBarItem from './AdminNavBarItem'
import { BottomBox, BottomItemButton, DesignLogo, LogoWrapper, StyledDrawer, TopBox } from './styles'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import { NavBarItem } from './AdminNavBar.types'
import MultiBookIcon from '../../assets/icons/MultiBookIcon'
import EditDocIcon from '../../assets/icons/EditDocIcon'
import {
  Person as PersonIcon,
  Edit as EditIcon,
  DescriptionOutlined as DescriptionIcon,
  SettingsOutlined as SettingsIcon,
} from '@mui/icons-material'
import useConfirmDialog from 'hooks/useConfirmDialog'
import { openedConfirmSelector, setOpenedConfirm } from 'store/slices/documentsPages/drawerInfo'
import { useAppDispatch, useTypedSelector } from 'store/store'

const items: NavBarItem[] = [
  {
    label: 'Пользователи',
    icon: PersonIcon,
    link: '/administration/users',
  },
  {
    label: 'Проекты',
    icon: EditIcon,
    link: '/administration/projects',
  },
  {
    label: 'Разделы РД',
    icon: DescriptionIcon,
    selectedIcon: EditDocIcon,
    link: '/administration/rd',
  },
  {
    label: 'Разделы ПД',
    icon: MultiBookIcon,
    link: '/administration/pd',
  },
  {
    label: 'Настройки',
    icon: SettingsIcon,
    link: '/administration/settings',
  },
]

const AdminNavBar: React.FC = () => {
  const _navbarIsOpen = localStorage.getItem('navBarIsOpen') === 'true'
  const [open, setOpen] = useState(_navbarIsOpen)
  const [overrideOpen, setOverrideOpen] = useState(_navbarIsOpen)
  const [localUrl, setLocalUrl] = useState<string | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { openedConfirm } = useTypedSelector(openedConfirmSelector)

  const mouseHandler = (value: boolean) => {
    if (overrideOpen) return
    setOpen(value)
  }

  const onOverride = () => {
    setOverrideOpen((value) => {
      localStorage.setItem('navBarIsOpen', String(!value))
      return !value
    })
    setOpen(true)
  }

  const onItemClick = (url: string) => {
    if (location.pathname === url) return

    if (openedConfirm) {
      setLocalUrl(url)
      openConfirm()
    } else navigate(url)
  }

  const handleConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        navigate(localUrl!)
        dispatch(setOpenedConfirm({ openedConfirm: false }))
      }

      setOpen(false)
    },
    [localUrl],
  )

  const { ConfirmDialog, openConfirm } = useConfirmDialog({ handleConfirm })

  const style = {
    width: overrideOpen ? '236px' : '80px',
    '> .MuiPaper-root': {
      width: open ? '236px' : '80px',
    },
  }

  return (
    <StyledDrawer
      variant='permanent'
      className='navbar'
      onMouseMove={() => mouseHandler(true)}
      onMouseEnter={() => mouseHandler(true)}
      onMouseLeave={() => mouseHandler(false)}
      sx={style}
    >
      <TopBox>
        <LogoWrapper onClick={() => onItemClick('/home')}>
          <DesignLogo />
        </LogoWrapper>

        <List>
          {items.map((item) => (
            <AdminNavBarItem
              key={item.label}
              label={item.label}
              icon={item.icon}
              selectedIcon={item.selectedIcon}
              link={item.link}
              onItemClick={onItemClick}
            />
          ))}
        </List>
      </TopBox>

      <BottomBox>
        <List>
          <Divider />
          <BottomItemButton onClick={onOverride}>
            {overrideOpen ? (
              <>
                <ListItemIcon>
                  <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '35px !important' }} />
                </ListItemIcon>
                <ListItemText primary='Открепить меню' />
              </>
            ) : (
              <>
                <ListItemIcon>
                  <KeyboardDoubleArrowRightIcon sx={{ fontSize: '35px !important' }} />
                </ListItemIcon>
                <ListItemText primary='Закрепить меню' />
              </>
            )}
          </BottomItemButton>
        </List>
      </BottomBox>

      <ConfirmDialog />
    </StyledDrawer>
  )
}

export default AdminNavBar
