import { Box, Divider, Stack, Typography } from '@mui/material'
import { TanglIntegrationInfoCard } from './SettingsInfo.styles'
import Button from 'components/Button'
import { theme } from 'styles/theme'
import { ArrowForward as ArrowIcon } from '@mui/icons-material'
import InstructionImage from 'assets/agreement-instruction.png'
import { useCallback, useState } from 'react'
import { TSettingsDrawerType } from '../../AdminSettings.types'
import { IntegrationRequestDrawer } from '../IntegrationRequestDrawer'
import { useGetTanglIntegrationStatusQuery } from 'api/settings'

export const SettingsInfo = () => {
  const [openedDrawer, setOpenedDrawer] = useState<TSettingsDrawerType | null>(null)
  const { data: tanglIntegrationStatus, isLoading: isTanglIntegrationStatusLoading } =
    useGetTanglIntegrationStatusQuery()

  const onDrawerOpen = useCallback((drawerType: TSettingsDrawerType) => {
    setOpenedDrawer(drawerType)
  }, [])

  const onDrawerClose = useCallback(() => {
    setOpenedDrawer(null)
  }, [])

  return (
    <Stack width={'50%'} alignItems={'center'}>
      <TanglIntegrationInfoCard>
        <Box>
          <img src={InstructionImage} width={230} height={230} alt='Картинка' draggable={false} />
        </Box>

        <Stack spacing={2.5}>
          <Stack>
            <Typography variant='body2' fontWeight={300} fontSize={32}>
              Интеграция с Tangl
            </Typography>
            <Divider />
          </Stack>
          <Typography variant='body2' textAlign={'start'}>
            Интеграция с Tangl необходима для просмотра ТИМ-моделей.
          </Typography>
          <Typography variant='body2' textAlign={'start'}>
            Для настройки интеграции нужно указать валидные данные индентификаторов корпоративной учетной записи вашей
            компании в Tangl с правами администратора.
          </Typography>
        </Stack>

        {tanglIntegrationStatus?.status !== 'ENABLED' && (
          <Button
            onClick={() => onDrawerOpen('integrationRequest')}
            variant='text'
            endIcon={<ArrowIcon fontSize='small' />}
            width={'100%'}
            style={{ maxWidth: '100%', justifyContent: 'space-between' }}
          >
            <Typography variant='body2' color={theme.palette.primary.main}>
              Нет аккаунта в Tangl?
            </Typography>
          </Button>
        )}
      </TanglIntegrationInfoCard>

      {<IntegrationRequestDrawer isOpen={openedDrawer === 'integrationRequest'} onClose={onDrawerClose} />}
    </Stack>
  )
}
