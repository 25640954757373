import * as yup from 'yup'
import { UploadPropsData } from '../../../../components/FileUpload/FileUpload.types'

export const versionValidation = (formats: string[]) => yup.object({
  description: yup.string().trim(),
  file: yup.mixed().required().test('is-correct-format', 'Неверный формат файла', (value: File[]) => {
    return value && value.every(file => formats.some(format => file.name.endsWith(format)))
  }),
})

export const uploadData: UploadPropsData = {
  title: 'Загрузить RVT или IFC',
  text: 'Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
  formats: ['.rvt', '.ifc'],
  mimeTypes: {
    '.rvt': 'application/vnd.autodesk.revit',
    '.ifc': 'application/octet-stream'
  }
}