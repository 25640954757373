import { api } from '../../api'
import {
  CreateIrdRequest,
  DeleteIrdRequest,
  EditIrdRequest,
  ExportIrdRequest,
  GetAllIrdRequest,
  GetAllIrdResponse,
  GetIrdByIdRequest,
  GetIrdLinkExampleRequest,
  GetIrdLinkExampleResponse,
  IrdBaseResponse,
  UploadIrdRequest,
  UploadIrdResponse,
} from './irdApi.types'

export const irdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllIrd: build.query<GetAllIrdResponse, GetAllIrdRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/ird/list`,
        params,
        method: 'GET',
      }),
      providesTags: (result) =>
        result?.data?.length
          ? [
            ...result.data.map(({ id }) => ({ type: 'Ird' as const, id })),
            { type: 'Ird', id: 'LIST' },
            { type: 'Ird', id: 'PARTIAL-LIST' },
          ]
          : [
            { type: 'Ird', id: 'LIST' },
            { type: 'Ird', id: 'PARTIAL-LIST' },
          ],
    }),
    createIrd: build.mutation<IrdBaseResponse, CreateIrdRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/ird/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Ird', id: 'LIST' }, { type: 'TomIrd', id: 'SIDEBAR_MENU' }, { type: 'Projects', id: 'dashboard' }],
    }),
    uploadIrd: build.mutation<UploadIrdResponse, UploadIrdRequest>({
      query: ({ id, file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/project/${id}/ird/upload`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: [{ type: 'TomIrd', id: 'SIDEBAR_MENU' }, { type: 'Projects', id: 'dashboard' }]
    }),
    exportIrd: build.mutation<Blob, ExportIrdRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/ird/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    getIrdById: build.query<IrdBaseResponse, GetIrdByIdRequest>({
      query: ({ id, irdId }) => ({
        url: `/project/${id}/ird/${irdId}/get`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => {
        return [{ type: 'Ird', id: arg.id }]
      },
    }),
    editIrd: build.mutation<IrdBaseResponse, EditIrdRequest>({
      query: ({ id, irdId, ...body }) => ({
        url: `/project/${id}/ird/${irdId}/update`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            irdApi.util.invalidateTags(['Ird'])
          )

        } catch {
        }
      },
      invalidatesTags: [{ type: 'TomIrd', id: 'SIDEBAR_MENU' }]
    }),
    deleteIrd: build.mutation<IrdBaseResponse, DeleteIrdRequest>({
      query: ({ id, irdId }) => ({
        url: `/project/${id}/ird/${irdId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Ird', id: 'PARTIAL-LIST' }, { type: 'TomIrd', id: 'SIDEBAR_MENU' }, 'Projects'],
    }),
    getIrdLinkExample: build.mutation<GetIrdLinkExampleResponse, GetIrdLinkExampleRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/ird/link-example`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllIrdQuery,
  useCreateIrdMutation,
  useUploadIrdMutation,
  useExportIrdMutation,
  useGetIrdByIdQuery,
  useEditIrdMutation,
  useDeleteIrdMutation,
  useGetIrdLinkExampleMutation,
} = irdApi
