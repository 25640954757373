import { Box, Button, Drawer, Stack, Typography } from "@mui/material"
import { DrawerVersionFormProps, DrawerVersionFormValues } from "./DrawerVersionForm.types"
import { DrawerContent, DrawerHeader, UploadDrawerAttention, UploadDrawerText } from "./DrawerVersionForm.styles"
import { FormikProvider } from "formik"
import { useForm } from "../../../../hooks/useForm"
import FileUpload from "../../../../components/FileUpload"
import FieldForm from "../../../../components/FieldForm"
import { uploadData, versionValidation } from "./DrawerVersionForm.utils"
import useConfirmDialog, { UseExitConfirmProps } from "../../../../hooks/useConfirmDialog"
import { useAddTimVersionMutation } from "../../../../api/tim"
import { useParams } from "react-router-dom"
import { useMutationHandlers } from "../../../../hooks/useMutationHandlers"
import { useSnackbar } from "notistack"
import { useAppDispatch } from "../../../../store/store"
import { setAllowScrolling } from "../../../../store/slices/remarks/remarks"
import { TanglStatus } from "../TanglStatus"
import { useGetTanglIntegrationStatusQuery } from "api/settings"

export const DrawerVersionForm = ({ open, timId, closeVersionDrawer }: DrawerVersionFormProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const [addTimVersion, addTimVersionResponse] = useAddTimVersionMutation()

  const { data: statusConnectionResponse } = useGetTanglIntegrationStatusQuery()
  const { status: statusConnection } = statusConnectionResponse || {}

  const initialValues: DrawerVersionFormValues = {
    file: null,
    description: ''
  }

  const onSubmit = (values: DrawerVersionFormValues) => {
    const file = values.file?.length && values.file[0] instanceof File ? values.file[0] : null
    if (file) {
      addTimVersion({
        id: projectId,
        timId,
        file,
        description: values.description
      })
    }
  }

  const { formik } = useForm({
    validationSchema: versionValidation(uploadData.formats),
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values)
  })

  const { dirty, isValid } = formik

  const onDrawerClose = (dirty: boolean) => {
    dirty ? openConfirm() : closeVersionDrawer(false)
  }

  const confirmCloseDrawer = (confirm: boolean) => {
    if (confirm) {
      closeVersionDrawer(false)
      formik.resetForm()
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmCloseDrawer,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  useMutationHandlers(
    addTimVersionResponse,
    (data) => {
      if (data) {
        enqueueSnackbar(`Версия успешно добавлена.`, { variant: 'success' })
        if (statusConnection === 'ERROR_ACCESS'
          || statusConnection === 'ERROR_AUTH'
          || statusConnection === 'ERROR_COMPANY') {
          enqueueSnackbar(`Не удалось передать модель в Tangl.`, { variant: 'error' })
        }
        dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: true, mode: 'edit', id: timId } }))
        closeVersionDrawer(true)
      }
    },
    (error) => {
      error && enqueueSnackbar(`Не удалось добавить версию`, { variant: 'error' })
    }
  )

  return (
    <Drawer
      anchor='bottom'
      open={open}
      onClose={() => onDrawerClose(dirty)}
      PaperProps={{
        sx: {
          width: 400,
          height: '100%',
          left: 'auto',
        }
      }}
    >
      <Stack flex={1}>
        <DrawerHeader variant="h2">
          Добавление версии
        </DrawerHeader>
        <Box px={2.5}>
          <TanglStatus />
        </Box>
        <DrawerContent>
          <FormikProvider value={formik} >
            <Stack textAlign='center'>
              <UploadDrawerAttention variant='body2'>
                Внимание!
              </UploadDrawerAttention>
              <UploadDrawerText variant='body2'>
                В наименовании прикрепляемого файла требуется учитывать правила наименования файла модели и использовать исключительно символы латинского алфавита.
              </UploadDrawerText>
              <UploadDrawerText variant='body2' pt={3}>
                Для просмотра модели в формате IFC интеграция с Tangl должна быть активной на момент добавления версии модели.
              </UploadDrawerText>
            </Stack>
            <Box py={2.5}>
              <FileUpload
                name='file'
                mode='single'
                uploadData={uploadData}
                loading={addTimVersionResponse.isLoading}
                responseData={undefined/* responseData */}
              />
            </Box>
            <Typography variant='body2' component='span' pb={0.75}>Комментарий</Typography>
            <FieldForm
              version='doc'
              name='description'
              placeholder='Введите комментарий'
              multiline
              minRows={2}
            />
            <Stack direction='row' spacing={2} flex={1} alignItems='flex-end'>
              <Button
                onClick={formik.submitForm}
                type='submit'
                disabled={addTimVersionResponse.isLoading || !isValid || !dirty}
                color='success'
                size='medium'
                fullWidth
              >
                Сохранить
              </Button>
              <Button size='medium' fullWidth onClick={() => onDrawerClose(dirty)}>Отменить</Button>
            </Stack>
          </FormikProvider>
        </DrawerContent>
      </Stack>
      <ConfirmDialog />
    </Drawer>
  )
}