import { MonthCellTemplate, QuarterCellTemplate } from './WorksTable.types'
import { DateForCell } from './cells/GraphCell'
import { format } from 'date-fns'

export const parseDateForCell = (date: Date): DateForCell => ({
  year: Number(format(date, 'yyyy')),
  quarter: Number(format(date, 'q')),
  month: Number(format(date, 'M')),
  day: Number(format(date, 'd')),
})

export const quarterColumnsTemplate: QuarterCellTemplate[] = [
  { label: 'I', quarter: 1, style: { minWidth: 58, width: 58 } },
  { label: 'II', quarter: 2, style: { minWidth: 58, width: 58 } },
  { label: 'III', quarter: 3, style: { minWidth: 58, width: 58 } },
  { label: 'IV', quarter: 4, style: { minWidth: 58, width: 58 } },
]

export const monthsColumnsTemplate: MonthCellTemplate[] = [
  { label: 'янв', month: 1, style: { minWidth: 39, width: 39 } },
  { label: 'фев', month: 2, style: { minWidth: 39, width: 39 } },
  { label: 'мар', month: 3, style: { minWidth: 39, width: 39 } },
  { label: 'апр', month: 4, style: { minWidth: 39, width: 39 } },
  { label: 'май', month: 5, style: { minWidth: 39, width: 39 } },
  { label: 'июн', month: 6, style: { minWidth: 39, width: 39 } },
  { label: 'июл', month: 7, style: { minWidth: 39, width: 39 } },
  { label: 'авг', month: 8, style: { minWidth: 39, width: 39 } },
  { label: 'сен', month: 9, style: { minWidth: 39, width: 39 } },
  { label: 'окт', month: 10, style: { minWidth: 39, width: 39 } },
  { label: 'ноя', month: 11, style: { minWidth: 39, width: 39 } },
  { label: 'дек', month: 12, style: { minWidth: 39, width: 39 } },
]