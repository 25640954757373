import React, { FC, useEffect } from 'react'
import { ProjectAgreementLegendProps, ProjectRoadmapFormData } from './ProjectAgreementLegend.types'
import { Button, Stack } from '@mui/material'
import {
  AgreementMainButton,
  AgreementSchemeDurationWrapper,
  AgreementSchemeEditButton,
  AgreementSchemePhaseSelect,
  AgreementSchemeTitleField,
  AgreementStageButton,
  AgreementStageDeleteButton,
  ProjectAgreementSchemeSubtitle,
  ProjectAgreementSchemeTitle,
  ProjectAgreementSchemeValueText
} from './ProjectAgreementLegend.styles'
import { useAppDispatch, useTypedSelector } from '../../../../../store/store'
import {
  currentRoadmapSelector,
  initialRoadmapPhaseFromMenuSelector,
  isAddStageToExistSelector,
  nextRoadmapNumberSelector,
  roadmapFormModeSelector,
  setCurrentRoadmap,
  setIsAddStageToExist,
  setRoadmapFormMode,
  setStages,
  stagesSelector
} from '../../../../../store/slices/agreement'
import {
  useCreateRoadmapMutation,
  useDeleteRoadmapMutation,
  useEditRoadmapMutation,
  useGetRoadmapByIdQuery,
  useSetRoadmapMainMutation
} from '../../../../../api/projectAgreement'
import { useForm } from '../../../../../hooks/useForm'
import { useMutationHandlers } from '../../../../../hooks/useMutationHandlers'
import { Form, FormikProvider } from 'formik'
import { useParams } from 'react-router-dom'
import { SelectMenuProps } from '../../../../../components/UserManagement/components/UserObjectsSelection'
import { StyledSelectMenuItem } from '../../../../../components/UserManagement/components/UserRoleSelection/styles'
import { Roadmap, roadmapPhases } from '../../../../../api/projectAgreement/types'
import { ProjectAgreementLegendPersonBlock } from './ProjectAgreementLegendPersonBlock'
import EditIcon from '@mui/icons-material/Edit'
import { validationRoadmap } from './ProjectAgreementLegend.validation'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../../hooks/useConfirmDialog'
import DeleteIcon from '@mui/icons-material/Delete'
import { useSnackbar } from 'notistack'
import { CreateRoadmapResponse } from '../../../../../api/projectAgreement/api.types'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Tooltip from '../../../../../components/Tooltip'
import VerifiedIcon from '@mui/icons-material/Verified'
import { profileSelector } from '../../../../../store/slices/profile'

export const ProjectAgreementLegend: FC<ProjectAgreementLegendProps> = ({
  addStageHandler,
  loading
}) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const stages = useTypedSelector(stagesSelector)
  const selectedMenuRoadmap = useTypedSelector(currentRoadmapSelector)
  const roadmapFormMode = useTypedSelector(roadmapFormModeSelector)
  const initialRoadmapPhaseFromMenu = useTypedSelector(initialRoadmapPhaseFromMenuSelector)
  const nextRoadmapNumber = useTypedSelector(nextRoadmapNumberSelector)
  const isAddStageToExist = useTypedSelector(isAddStageToExistSelector)
  const { employeeId, role } = useTypedSelector(profileSelector)

  const isAdmin = role === 'admin'
  const isResponsibleRoadmap = selectedMenuRoadmap?.person && selectedMenuRoadmap.person.id === employeeId

  const onCancelRoadmapCreateClick = () => {
    dispatch(setRoadmapFormMode({ roadmapFormMode: 'view' }))
  }

  const onCancelAddClick = () => {
    dispatch(setIsAddStageToExist({ isAddStageToExist: false }))
  }

  const onRoadmapAddClick = () => {
    dispatch(setRoadmapFormMode({ roadmapFormMode: 'create' }))
  }

  const onRoadmapEditClick = () => {
    dispatch(setRoadmapFormMode({ roadmapFormMode: 'edit' }))
  }

  const { data: currentRoadmapData, isFetching } = useGetRoadmapByIdQuery({
    id: projectId,
    roadmapId: selectedMenuRoadmap.id
  }, { skip: !selectedMenuRoadmap.id })
  const { id: roadmapId, number, title, phase, person, duration, isBroken, isLocked, isMain } = currentRoadmapData || {}

  const initialValues: ProjectRoadmapFormData = {
    title: (roadmapFormMode === 'edit' && title) || '',
    phase: (roadmapFormMode === 'edit' && phase) || initialRoadmapPhaseFromMenu,
    personId: (roadmapFormMode === 'edit' && person?.id) || null,
    companyName: (roadmapFormMode === 'edit' && person?.company) || '',
  }

  const [createRoadmap, createRoadmapResponse] = useCreateRoadmapMutation()
  const [editRoadmap, editRoadmapResponse] = useEditRoadmapMutation()
  const [deleteRoadmap, deleteRoadmapResponse] = useDeleteRoadmapMutation()
  const [setRoadmapMain, setRoadmapMainResponse] = useSetRoadmapMainMutation()

  const onSubmit = ({ title, phase, personId }: ProjectRoadmapFormData) => {
    if (!personId) return

    const commonData = {
      id: projectId,
      title: title || 'Схема согласования',
      personId
    }

    roadmapFormMode === 'create' && createRoadmap({ ...commonData, phase })
    roadmapFormMode === 'edit' && roadmapId && editRoadmap({ ...commonData, roadmapId })
  }

  const { formik } = useForm({
    validationSchema: validationRoadmap,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    }
  })

  const { values, isValid, isSubmitting, dirty } = formik

  const onSetMainButtonClick = (roadmapId?: number) => {
    roadmapId && setRoadmapMain({ projectId, roadmapId })
  }

  useEffect(() => {
    if (currentRoadmapData) {
      dispatch(setCurrentRoadmap(currentRoadmapData))
    }
  }, [currentRoadmapData])

  useMutationHandlers(
    createRoadmapResponse,
    (data: CreateRoadmapResponse) => {
      dispatch(setRoadmapFormMode({ roadmapFormMode: 'view' }))
      dispatch(setCurrentRoadmap(data))
      formik.resetForm()
    }
  )

  useMutationHandlers(
    editRoadmapResponse,
    () => {
      dispatch(setRoadmapFormMode({ roadmapFormMode: 'view' }))
    }
  )

  useMutationHandlers(
    deleteRoadmapResponse,
    () => {
      dispatch(setCurrentRoadmap({} as Roadmap))
      dispatch(setStages({ stages: [] }))
      dispatch(setRoadmapFormMode({ roadmapFormMode: 'view' }))
      enqueueSnackbar('Схема успешно удалена', { variant: 'success' })
    },
    () => {
      dispatch(setCurrentRoadmap({} as Roadmap))
      dispatch(setRoadmapFormMode({ roadmapFormMode: 'view' }))
      enqueueSnackbar('Схема не удалена, попробуйте еще раз', { variant: 'error' })
    }
  )

  useMutationHandlers(
    setRoadmapMainResponse,
    () => {
      enqueueSnackbar('Схема назначена основной', { variant: 'success' })
    },
  )

  const confirmDelete = (confirm: boolean) => {
    if (confirm && roadmapId) {
      deleteRoadmap({ id: projectId, roadmapId })
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmDelete,
    title: 'Удалить схему согласования?',
    body: (<>Схема согласования будет удалена <br />без возможности восстановить.</>)
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const isRoadmapsExist = !!selectedMenuRoadmap?.id
  if (!isRoadmapsExist && roadmapFormMode === 'view' && !isFetching && !loading) return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' height={36}>
      <ProjectAgreementSchemeTitle variant='buttonMedium'>Схемы процедуры согласования</ProjectAgreementSchemeTitle>
      {isAdmin && <Button onClick={onRoadmapAddClick} size='small'>
        Создать Схему
      </Button>}
    </Stack>
  )

  return (
    <FormikProvider value={formik}>
      {!isFetching && !loading &&
        <Stack direction='row' justifyContent='space-between' alignItems='center' component={Form}
          minHeight={36} flexWrap='wrap' gap={1} flexShrink={0}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <ProjectAgreementSchemeTitle variant='buttonMedium'>Схема</ProjectAgreementSchemeTitle>

            <Stack direction='row' alignItems='center' spacing={1}>
              <ProjectAgreementSchemeSubtitle variant='body2'>Номер:</ProjectAgreementSchemeSubtitle>
              <ProjectAgreementSchemeValueText variant='body2'>
                {roadmapFormMode === 'create' ? nextRoadmapNumber : number}
              </ProjectAgreementSchemeValueText>

            </Stack>

            <Stack direction='row' alignItems='center' spacing={1}>
              <ProjectAgreementSchemeSubtitle variant='body2'>Название:</ProjectAgreementSchemeSubtitle>
              {roadmapFormMode === 'view'
                ?
                <Tooltip title={title && title.length > 23 ? title : ''}>
                  <ProjectAgreementSchemeValueText variant='body2'>{title}</ProjectAgreementSchemeValueText>
                </Tooltip>
                : (
                  <AgreementSchemeTitleField
                    version='project'
                    name='title'
                    placeholder='Название схемы'
                  />
                )
              }
            </Stack>

            <Stack direction='row' alignItems='center' spacing={1}>
              <ProjectAgreementSchemeSubtitle variant='body2'>Стадия:</ProjectAgreementSchemeSubtitle>
              {roadmapFormMode !== 'create'
                ? <ProjectAgreementSchemeValueText variant='body2'>{phase}</ProjectAgreementSchemeValueText>
                : (
                  <AgreementSchemePhaseSelect
                    version='select'
                    name='phase'
                    SelectProps={{
                      MenuProps: SelectMenuProps,
                    }}
                  >
                    {roadmapPhases.map(phase => (
                      <StyledSelectMenuItem value={phase} key={phase}>{phase}</StyledSelectMenuItem>
                    ))}
                  </AgreementSchemePhaseSelect>
                )
              }
            </Stack>

            <ProjectAgreementLegendPersonBlock person={person} />

            {roadmapFormMode === 'view' && (
              <Stack direction='row' alignItems='center' spacing={1}>
                <ProjectAgreementSchemeSubtitle variant='body2'>Общий срок:</ProjectAgreementSchemeSubtitle>

                <AgreementSchemeDurationWrapper direction='row' alignItems='center' isBroken={!!isBroken}>
                  {isBroken
                    ? <WarningAmberIcon fontSize='small' color='warning' />
                    : <WatchLaterIcon fontSize='small' className='clockIcon' />
                  }

                  {!isBroken && (
                    <ProjectAgreementSchemeValueText variant='body2'>{duration || 0} дн</ProjectAgreementSchemeValueText>
                  )}
                </AgreementSchemeDurationWrapper>

              </Stack>
            )}

            {!(isBroken && !isMain) && roadmapFormMode === 'view' && isAdmin && (
              <Tooltip
                title={isMain
                  ? <>Схема выбрана в качестве<br />основной для стадии, вы можете<br />назначить другую схему</>
                  : <>Назначить схему согласования<br />в качестве основной для стадии</>
                }
              >
                <AgreementMainButton
                  onClick={!isMain ? () => onSetMainButtonClick(roadmapId) : undefined}
                  colorDisabled={!isMain}
                  disableHover={isMain}
                >
                  <VerifiedIcon fontSize='medium' />

                  Основная
                </AgreementMainButton>
              </Tooltip>
            )}


            {roadmapFormMode === 'view' && !isLocked && (isAdmin || isResponsibleRoadmap) && (
              <AgreementSchemeEditButton
                onClick={onRoadmapEditClick}
                variant='text'
                startIcon={<EditIcon fontSize='small' />}
              >
                Изменить
              </AgreementSchemeEditButton>
            )}
          </Stack>

          {roadmapFormMode === 'view'
            ? !isLocked && (isAdmin || isResponsibleRoadmap) && ( // buttons for stage
              <AgreementStageButton onClick={isAddStageToExist ? onCancelAddClick : addStageHandler}>
                {isAddStageToExist ? 'Отменить' : 'Добавить этап'}
              </AgreementStageButton>
            )
            : ( // buttons for roadmap
              <Stack direction='row' alignItems='center' spacing={1.5}>
                <AgreementStageButton type='submit' color='success' disabled={!dirty || !isValid || isSubmitting}>
                  Сохранить
                </AgreementStageButton>
                <AgreementStageButton onClick={onCancelRoadmapCreateClick}>
                  Отменить
                </AgreementStageButton>
                {roadmapFormMode === 'edit' && (
                  <AgreementStageDeleteButton onClick={openConfirm}>
                    <DeleteIcon />
                  </AgreementStageDeleteButton>
                )}
              </Stack>
            )
          }
        </Stack>}

      <ConfirmDialog />
    </FormikProvider>
  )
}
