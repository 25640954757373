import styled from 'styled-components'

import { theme } from '@styles/theme'

export const StyledSearch = styled.input`
  width: 100%;
  border: none;
  outline: none;
  padding: 4px;
  border-bottom: 1px solid transparent;

  &:focus {
    border-bottom: 1px solid ${theme.palette.text.dark};
  }
`
