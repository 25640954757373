import { Stack, Typography, IconButton, Divider } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '../../../../../assets/icons/InfoIcon'
import { NavbarSchemaWrapper } from "./NavbarSchema.styles"
import { NavbarSchemaProps } from "./NavbarSchema.types"
import { NavbarSchemaItem } from "../NavbarSchemaItem"
import { useTypedSelector } from "../../../../../store/store"
import { currentSchemaSelector } from "../../../../../store/slices/internalAgreement"

export const NavbarSchema = ({ companyData, onInnermapAddClick, onInnermapClick }: NavbarSchemaProps) => {

  const currentSchema = useTypedSelector(currentSchemaSelector)

  return (
    <NavbarSchemaWrapper spacing={1.5}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
        <Typography variant='body2' className='phaseTitle'>
          {companyData.company}
        </Typography>
        <IconButton onClick={() => onInnermapAddClick(companyData)}>
          <AddIcon fontSize='medium' color='primary' />
        </IconButton>
      </Stack>

      <Divider flexItem />

      {!companyData?.innermaps.length
        ? (
          <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
            <InfoIcon color='disabled' />
            <Typography variant='body2' className='emptyPhase'>
              Нет схем согласования
            </Typography>
          </Stack>
        )
        : companyData.innermaps.map(schema => (
          <NavbarSchemaItem
            schema={schema}
            onInnermapClick={onInnermapClick}
            selected={currentSchema?.id === schema.id}
            key={schema.id}
          />
        ))
      }

    </NavbarSchemaWrapper>
  )
}