import styled from 'styled-components'
import {
  StyledExportArchiveAccordionSummary
} from '../../../../../Docs/components/ExportArchiveDrawer/components/ExportArchiveAccordion/styles'

export const StyledFullExportInnerAccordionSummary = styled(StyledExportArchiveAccordionSummary)`
  & .MuiFormControlLabel-root {
    height: 24px;
  }
  
  & .MuiAccordionSummary-expandIconWrapper {
    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.text.dark};
    }
  }
  
  & .MuiTypography-root {
    font-weight: 400;
    color: ${props => props.theme.palette.text.dark};
  }
`