import qs from 'qs'

import { setTom } from '../../../store/slices/documentsPages/tom'
import { ExcelUploadResponse } from '../../../types/global'
import { api } from '../../api'
import { ExportObjectsRequest } from '../../rdPhase'
import {
  CreateTomPdRequest,
  DeleteTomPdRequest,
  EditTomPdRequest,
  GetTomPdByIdRequest,
  GetTomPdLinkExampleResponse,
  GetTomsPdFullRequest,
  GetTomsPdFullResponse,
  GetTomsPdRequest,
  GetTomsPdResponse,
  ITerminateTomPdRequest,
  ITerminateTomPdResponse,
  TomPdBaseResponse,
  TomPdMultiUploadRequest,
  TomPdMultiUploadResponse,
  UploadTomPdRequest,
} from './tomApi.types'

export const tomPdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomsPd: build.query<GetTomsPdResponse, GetTomsPdRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-pd/list?${qs.stringify(params, { indices: false })}`,
        method: 'GET',
      }),
      providesTags: [
        'TomPd',
        { type: 'TomPd', id: 'UPDATE' },
        { type: 'TomPd', id: 'UPLOAD' },
        { type: 'TomPd', id: 'DELETE' },
        { type: 'TomPd', id: 'UPLOAD_MASS' },
        { type: 'TomPd', id: 'ARCHIVE_TOMS' },
      ],
    }),
    getTomsPdFull: build.query<GetTomsPdFullResponse, GetTomsPdFullRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/tom-pd/list-full?${qs.stringify(params, { indices: false })}`,
        method: 'GET',
      }),
      providesTags: [
        'TomPd',
        { type: 'TomPd', id: 'UPDATE' },
        { type: 'TomPd', id: 'UPLOAD' },
        { type: 'TomPd', id: 'DELETE' },
        { type: 'TomPd', id: 'UPLOAD_MASS' },
        { type: 'TomPd', id: 'ARCHIVE_TOMS' },
      ],
    }),
    createTomPd: build.mutation<TomPdBaseResponse, CreateTomPdRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/tom-pd/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'TomPd', id: 'UPDATE' }, { type: 'Projects', id: 'dashboard' }],
    }),
    uploadTomPd: build.mutation<ExcelUploadResponse, UploadTomPdRequest>({
      query: ({ id, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${id}/tom-pd/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
    }),
    exportTomsPd: build.mutation<Blob, ExportObjectsRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/tom-pd/export-excel-file`,
        method: 'POST',
        responseHandler: async (response: any) => await response.blob(),
      }),
    }),
    tomPdMultiUpload: build.mutation<TomPdMultiUploadResponse, TomPdMultiUploadRequest>({
      query: ({ id, files }) => {
        const formData = new FormData()
        files.forEach((file) => {
          formData.append('file', file)
        })

        return {
          url: `/project/${id}/tom-pd/mass-update`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: [{ type: 'TomPd', id: 'UPLOAD_MASS' }],
    }),
    getTomPdById: build.query<TomPdBaseResponse, GetTomPdByIdRequest>({
      query: ({ id }) => ({
        url: `/project/tom-pd/${id}/get`,
        method: 'GET',
      }),
      providesTags: ['TomPd', { type: 'TomPd', id: 'UPLOAD' }],
    }),
    editTomPd: build.mutation<TomPdBaseResponse, EditTomPdRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/tom-pd/${id}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'TomPd', id: 'UPDATE' }, { type: 'Projects', id: 'dashboard' }],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTomData } = await queryFulfilled
          const updatedTom = updatedTomData.data

          dispatch(setTom({ tom: updatedTom }))

          // getTomPdById
          dispatch(
            tomPdApi.util.updateQueryData('getTomPdById', { id: updatedTom.id }, (draft) => {
              draft.data = updatedTom
            }),
          )
        } catch {}
      },
    }),
    deleteTomPd: build.mutation<TomPdBaseResponse, DeleteTomPdRequest>({
      query: ({ id }) => ({
        url: `/project/tom-pd/${id}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(tomPdApi.util.invalidateTags([{ type: 'TomPd', id: 'DELETE' }, { type: 'Projects', id: 'dashboard' }]))
        } catch {}
      },
    }),
    // downloadTomPdExample: build.mutation<string, void>({
    //   query: () => ({
    //     url: `/project/tom-pd/download-example`,
    //     method: 'GET',
    //   }),
    // }),
    getTomPdLinkExample: build.mutation<GetTomPdLinkExampleResponse, void>({
      query: () => ({
        url: `/project/tom-pd/link-example`,
        method: 'GET',
      }),
    }),
    terminateTomPd: build.mutation<ITerminateTomPdResponse, ITerminateTomPdRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/toms/${tomId}/features/annull`,
        method: 'POST',
        params,
      }),
      async onQueryStarted({ tomId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTomData } = await queryFulfilled
          const updatedAnnulment = updatedTomData.annulment

          // getTomPdById
          dispatch(
            tomPdApi.util.updateQueryData('getTomPdById', { id: tomId }, (draft) => {
              draft.data.features.annulment = updatedAnnulment
            }),
          )
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomsPdQuery,
  useGetTomsPdFullQuery,
  useCreateTomPdMutation,
  useUploadTomPdMutation,
  useExportTomsPdMutation,
  useTomPdMultiUploadMutation,
  useGetTomPdByIdQuery,
  useEditTomPdMutation,
  useDeleteTomPdMutation,
  useGetTomPdLinkExampleMutation,
  useTerminateTomPdMutation,
} = tomPdApi
