import { TextField } from "@mui/material";
import styled from "styled-components";

export const StyledTextField = styled(TextField)`
  

  .MuiInputBase-root {
    font-size: 14px;
    line-height: 16.41px;
    color: ${props => props.theme.palette.text.dark};
    background-color: ${props => props.theme.palette.bg.shades};
    border-radius: 8px;
    padding-right: 6px;

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.secondary.dark + '3A'};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }

    .MuiSvgIcon-root {
    color: ${props => props.theme.palette.disabled};
  }

  }

  .MuiInputBase-input {
    padding: 6px 8px;

    & .MuiInput-inputAdornedEnd {
      color: ${props => props.theme.palette.text.dark};
    }
  }

  

/*   & .MuiInputBase-root-endAdornment {

    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.text.dark};
      font-size: 16px !important;
    }
  } */

  
`