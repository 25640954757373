import { Innermap } from '../../../../api/internalAgreement/types'
import { RootState } from '../../../store'
import { FormAddMemberStatus, ISchemaFormMode, TableFormValues } from '../internalAgreement.types'

export const schemaFormModeSelector = (state: RootState): ISchemaFormMode => state.internalAgreement.schemaFormMode
export const currentSchemaSelector = (state: RootState): Innermap => state.internalAgreement.currentSchema
export const initialSchemaCompanyFromMenuSelector = (state: RootState): string => state.internalAgreement.initialSchemaCompanyFromMenu
export const isOpenFormAddMemberSelector = (state: RootState): FormAddMemberStatus => state.internalAgreement.isOpenFormAddMember
export const tableFormValuesSelector = (state: RootState): TableFormValues => state.internalAgreement.tableFormValues
export const isSelectedProcedureSelector = (state: RootState): boolean => state.internalAgreement.isSelectedProcedure
export const approvedProcedureSelector = (state: RootState): number[] => state.internalAgreement.approvedProcedures
