import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

type AcceptedValue = string | boolean | number | null

export interface QueryHandler {
  get: <T = string> (key: string) => T | null
  set: (key: string, value: AcceptedValue, history?: boolean) => void
  remove: (key: string) => void
}

/**
 * Хук позволяющий взаимодействовать с query параметрами.
 */
export default function useQuery(callback?: (handler: QueryHandler) => any): QueryHandler {
  const location = useLocation()
  const [query, setQuery] = useState(new URLSearchParams(location.search))

  useEffect(() => {
    setQuery(new URLSearchParams(location.search))
  }, [location.search])

  const updateURL = useCallback(
    (newQuery: URLSearchParams, history?: boolean) => {
      const arr = [window.location.origin + window.location.pathname, newQuery.toString()]
      const url = arr.filter(Boolean).join('?')

      history
       ? window.history.pushState({ path: url }, '', url)
       : window.history.replaceState({ path: url }, '', url)
       setQuery(newQuery)
    },
    []
  )

  const get = useCallback(
    (key: string) => {
      return query.get(key) as any
    },
    [query]
  )

  const set = useCallback(
    (key: string, value: AcceptedValue, history?: boolean) => {
      const newQuery = new URLSearchParams(query.toString())
      newQuery.set(key, String(value))
      updateURL(newQuery, history)
    },
    [query, updateURL]
  )

  const remove = useCallback(
    (key: string) => {
      const newQuery = new URLSearchParams(query.toString())
      newQuery.delete(key)
      updateURL(newQuery)
    },
    [query, updateURL]
  )

  const handler = {
    get,
    set,
    remove
  }

  useEffect(() => {
    if (callback) callback(handler)
  }, [callback, handler])

  return handler
}