import { useCallback, useMemo } from 'react'
import { UseSubmitPdProps, UseSubmitPdResponse } from './useSubmitPd.types'
import {
  PdBaseResponse,
  PdRequest,
  PpdBaseResponse,
  useCreatePdMutation,
  useCreatePpdMutation,
  useEditPdMutation,
  useEditPpdMutation
} from '../../../../../../api/pdPhase'
import { IiBaseResponse, useCreateIiMutation, useEditIiMutation } from '../../../../../../api/iiPhase'
import { PdFormData } from '../../PdDrawerForm.types'
import { ProjectType } from '../../../../../../types/project'
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { IrdBaseResponse, useCreateIrdMutation, useEditIrdMutation } from '../../../../../../api/irdPhase'
import { useTypedSelector } from '../../../../../../store/store'
import { defaultProjectInfoSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { pdValidation, ppdValidation } from '../../validation'
import { RdpBaseResponse, useCreateRdpMutation, useEditRdpMutation } from '../../../../../../api/rdPhase/rdp'

export const useSubmitPd = ({
  variant,
  item,
  onClose,
  projectId: customProjectId,
  onSuccessCreate
}: UseSubmitPdProps): UseSubmitPdResponse => {
  const { enqueueSnackbar } = useSnackbar()

  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const projectId = customProjectId || project.id

  const { id } = item || {}

  // Rdp
  const [createRdp, createRdpResponse] = useCreateRdpMutation()
  const [editRdp, editRdpResponse] = useEditRdpMutation()

  useMutationHandlers(
    createRdpResponse,
    (data: RdpBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
      onSuccessCreate?.(data.data)
    }
  )

  useMutationHandlers(
    editRdpResponse,
    (data: RdpBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
    }
  )


  // Pd
  const [createPd, createPdResponse] = useCreatePdMutation()
  const [editPd, editPdResponse] = useEditPdMutation()

  const [createPpd, createPpdResponse] = useCreatePpdMutation()
  const [editPpd, editPpdResponse] = useEditPpdMutation()

  useMutationHandlers(
    createPdResponse,
    (data: PdBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
    }
  )

  useMutationHandlers(
    createPpdResponse,
    (data: PpdBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
      onSuccessCreate?.(data.data)
    }
  )

  useMutationHandlers(
    editPdResponse,
    (data: PdBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
    }
  )

  useMutationHandlers(
    editPpdResponse,
    (data: PpdBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
    }
  )


  // Ii
  const [createIi, createIiResponse] = useCreateIiMutation()
  const [editIi, editIiResponse] = useEditIiMutation()

  useMutationHandlers(
    createIiResponse,
    (data: IiBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
      onSuccessCreate?.(data.data)
    }
  )

  useMutationHandlers(
    editIiResponse,
    (data: IiBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
    }
  )


  // Ird
  const [createIrd, createIrdResponse] = useCreateIrdMutation()
  const [editIrd, editIrdResponse] = useEditIrdMutation()

  useMutationHandlers(
    createIrdResponse,
    (data: IrdBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
      onSuccessCreate?.(data.data)
    }
  )

  useMutationHandlers(
    editIrdResponse,
    (data: IrdBaseResponse) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      onClose(false, true)
    }
  )


  // Common
  const onSubmit = useCallback((values: PdFormData) => {
    const dataForRequest: PdRequest = {
      mark: values.mark,
      fullName: values.fullName,
      description: values.description,
      type: values.type as ProjectType,
    }

    switch (variant) {
      case 'rdp':
        id
          ? editRdp({ id: projectId, rdpId: id, ...dataForRequest })
          : createRdp({ id: projectId, ...dataForRequest })
        break
      case 'pd':
        id
          ? editPd({ id, ...dataForRequest })
          : createPd(dataForRequest)
        break
      case 'ppd':
        id
          ? editPpd({ id: projectId, ppdId: id, ...dataForRequest })
          : createPpd({ id: projectId, ...dataForRequest })
        break
      case 'ii':
        id
          ? editIi({ id: projectId, iiId: id, ...dataForRequest })
          : createIi({ id: projectId, ...dataForRequest })
        break
      case 'ird':
        id
          ? editIrd({ id: projectId, irdId: id, ...dataForRequest })
          : createIrd({ id: projectId, ...dataForRequest })
        break
    }
  }, [item, project, projectId])

  const validation = useMemo(() => {
    switch (variant) {
      case 'pd':
        return pdValidation
      case 'rdp':
      case 'ppd':
      case 'ii':
      case 'ird':
        return ppdValidation
    }
  }, [variant])

  return {
    onSubmit,
    validation,
  }
}
