import AddIcon from '@mui/icons-material/Add'
import DownloadingIcon from '@mui/icons-material/Downloading'
import { Grid, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import EmptyPage from '@components/EmptyPage'
import { EmptyPageData } from '@components/EmptyPage/EmptyPage.types'
import Progress from '@components/Progress'

import useQuery from '@hooks/useQuery'

import { clearFilter } from '@store/slices/documentsPages/filter'

import {
  DocsBottomLegend,
  DocsSelector,
  DocsTopLegend,
  DocsUploadDrawer,
  DocsUploadFilesDrawer,
  NotificationsDrawer,
  TomCard,
  docsFilterItems,
  useResetFilter,
} from '.'
import { useGetProjectByIdQuery, useGetProjectsQuery } from '../../api/projects'
import { GetProjectsResponse } from '../../api/projects/api.types'
import { useSendToProdChangeRdMutation, useSendToProdTomRdMutation } from '../../api/rdPhase'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog'
import { DocDrawerType, docsConfigByPhase } from '../../layouts/DocumentsLayout'
import { openedDrawerSelector, setOpenedDrawer } from '../../store/slices/documentsPages/drawerInfo'
import { setSelectedMenuView } from '../../store/slices/documentsPages/menu'
import {
  defaultProjectInfoSelector,
  isExtraAccessArchitectorSelector,
  selectedProjectPhaseSelector,
} from '../../store/slices/documentsPages/projectInfo'
import { profileSelector } from '../../store/slices/profile'
import { docsViewSelector } from '../../store/slices/ui'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { TomAccessStatus, TomStatusTabLabels, isRdTom } from '../../types/tom'
import { NUMBER_OF_ROWS_PER_TOMS_PAGE, XXL_FOR_LAYOUT } from '../../utils/constants'
import { getEmptyPageData } from '../Home'
import { AgGridToms, onSendToProdClickProps } from './components/AgGridToms'
import { ExportArchiveDrawer } from './components/ExportArchiveDrawer'
import { useGetTomsData } from './hooks/useGetTomsData'
import { ProjectPhase, projectPhaseRuToRuShort } from '@typesGlobal/project'

export const Toms: React.FC = () => {
  const { resetFilterData } = useResetFilter()
  const dispatch = useAppDispatch()
  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_LAYOUT})`)
  const savedDocsSelector = localStorage.getItem('docsSelector')
  const [docsSelector, setDocsSelector] = useState<DocsSelector>(
    () => savedDocsSelector !== null ? savedDocsSelector as DocsSelector : 'docs'
  )

  const { role } = useTypedSelector(profileSelector)
  const isExtraAccessArchitector = useTypedSelector(isExtraAccessArchitectorSelector)
  const { userWithLessControls } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const viewMode = useTypedSelector(docsViewSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const queryHandler = useQuery()
  const projectPhase = useRef<ProjectPhase | null>(null)

  const initialDrawerOpen: DocDrawerType = (localStorage.getItem('initialDrawerOpened') as DocDrawerType) || null

  useEffect(() => {
    if (initialDrawerOpen) {
      dispatch(setOpenedDrawer({ openedDrawer: initialDrawerOpen }))
      localStorage.removeItem('initialDrawerOpened')
    }
  }, [initialDrawerOpen])

  const { projectId } = useParams<{ projectId: string }>()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const MenuOpen = Boolean(anchorEl)
  const ProjectBreadClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const navigate = useNavigate()

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onMenuItemClick = useCallback(
    (projectId: number) => {
      navigate(`/project/${projectId}/toms`)
      onClose()
      dispatch(clearFilter({}))
      resetFilterData()
    },
    [dispatch, navigate, onClose, resetFilterData],
  )

  const {
    data: projectData,
    isLoading: projectLoading,
    isFetching: projectFetching,
  } = useGetProjectByIdQuery(
    {
      id: parseInt(projectId!),
    },
    {
      skip: !parseInt(projectId!),
    },
  )

  const { data: currentProject } = projectData || {}

  const loading = projectLoading || projectFetching

  const [selectedTomToProdTitle, setSelectedTomToProdTitle] = useState<string | null>(null)
  const [selectedChangeNum, setSelectedChangeNum] = useState<number | null>(null)

  const [sendToProdTomRd] = useSendToProdTomRdMutation()
  const [sendToProdChangeRd] = useSendToProdChangeRdMutation()

  const onSendTomToProdConfirm = useCallback((confirm: boolean, tomId: number) => {
    setSelectedTomToProdTitle(null)
    if (confirm) {
      sendToProdTomRd({ id: tomId })
    }
  }, [])

  const onSendChangeToProdConfirm = useCallback((confirm: boolean, tomId: number, changeNum: number) => {
    if (confirm) {
      sendToProdChangeRd({ id: tomId, change: changeNum, shouldUpdateTable: true })
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<'sendTomToProd' | 'sendChangeToProd'>(
    'sendTomToProd',
  )

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    sendTomToProd: {
      handleConfirm: onSendTomToProdConfirm,
      title: 'Передать том в производство?',
      body: `Документ "${selectedTomToProdTitle || ''}" будет передан подрядчику для производства работ.`,
      denyButtonText: 'Нет',
    },
    sendChangeToProd: {
      handleConfirm: onSendChangeToProdConfirm,
      title: 'Передать изменение в производство?',
      body: `Документ "Изменение №${selectedChangeNum}" будет передан подрядчику для производства работ.`,
      denyButtonText: 'Нет',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  useBreadcrumbs(
    [
      {
        title: currentProject?.shifrName!,
        onClick: (e) => ProjectBreadClick(e),
        MenuOpen,
        type: 'project',
      },
      { title: docsConfigByPhase[selectedProjectPhase]?.docs.breadcrumbTitle },
    ],
    [MenuOpen, currentProject, selectedProjectPhase],
    loading,
  )

  const { data: projectsData, isLoading: projectsLoading, isFetching: projectsFetching } = useGetProjectsQuery({})
  const { data: projects } = projectsData || ({} as GetProjectsResponse)

  // Tabs
  const selectedStatus: TomStatusTabLabels | null = queryHandler.get('status')
  const currentStatus: TomStatusTabLabels = selectedStatus || 'Все документы'
  const [currentTab, setCurrentTab] = useState<TomStatusTabLabels>(currentStatus)

  const onTabChange = useCallback((tabValue: TomStatusTabLabels) => {
    setCurrentTab(tabValue)
    queryHandler.set('status', tabValue)
  }, [])

  // Очистка фильтра при смене стадии
  useEffect(() => {
    if (selectedProjectPhase && projectPhase.current === null) {
      projectPhase.current = selectedProjectPhase
    }
    if (selectedProjectPhase && projectPhase.current !== selectedProjectPhase) {
      dispatch(clearFilter({}))
      onTabChange('Все документы')
      projectPhase.current = selectedProjectPhase
    }
  }, [selectedProjectPhase])

  // Handlers
  const changeDocsSelector = (value: DocsSelector) => {
    setDocsSelector(value)
    localStorage.setItem('docsSelector', value)
  }

  const onSendNotificationsOpenClick = useCallback(() => {
    dispatch(setOpenedDrawer({ openedDrawer: 'notifications' }))
  }, [])

  const onAddTomClick = useCallback(() => {
    changeDocsSelector('docs')
    dispatch(setOpenedDrawer({ openedDrawer: 'create' }))
  }, [])

  const onUploadTomClick = useCallback(() => {
    dispatch(setOpenedDrawer({ openedDrawer: 'upload' }))
  }, [])

  const onUploadFilesClick = useCallback(() => {
    dispatch(setOpenedDrawer({ openedDrawer: 'uploadFiles' }))
  }, [])

  const onExportArchiveOpenClick = useCallback(() => {
    dispatch(setOpenedDrawer({ openedDrawer: 'exportArchive' }))
  }, [])

  const onTomItemClick = (tomId: number, accessStatus: TomAccessStatus, changeId?: number | null) => {
    if (docsSelector === 'docs' || selectedProjectPhase === 'Сбор исходных данных') {
      if (accessStatus === 'close') return
      localStorage.setItem('docDetailedNavBarOpen', 'false')
      if (changeId) {
        dispatch(setSelectedMenuView({ selectedMenuView: 'info' }))
        navigate(`/project/${projectId}/tom/${tomId}?change=${changeId}&tab=docs`)
      } else {
        dispatch(setSelectedMenuView({ selectedMenuView: 'info' }))
        navigate(`/project/${projectId}/tom/${tomId}?tab=docs`)
      }
    } else {
      const phase = projectPhaseRuToRuShort[selectedProjectPhase]
      navigate(`/project/${projectId}/schemes/tom/${tomId}?type=${phase}`)
    }
  }

  const onTomSendToProdClick = (data: onSendToProdClickProps) => {
    const { tomId, changeNum, tomTitle } = data
    if (tomTitle && !changeNum) {
      setConfirmDialogTrigger('sendTomToProd')
      setSelectedTomToProdTitle(tomTitle)
    }

    if (changeNum && !tomTitle) {
      setConfirmDialogTrigger('sendChangeToProd')
      setSelectedChangeNum(changeNum)
    }

    openConfirm(tomId, changeNum)
  }

  const { dataForRender, tabsData, isTomsLoading, isTomsFetching, isTomsExist } = useGetTomsData({
    currentTab,
    projectId: parseInt(projectId!),
    limitValue: undefined,
    offset: undefined,
    inProd: currentTab === 'Все документы' ? undefined : currentTab === 'В производстве' ? true : false,
    status: currentTab === 'Все документы' ? undefined : currentTab === 'В производстве' ? undefined : currentTab,
    approval: docsSelector === 'agreement' ? true : undefined
  })

  const renderDataView = () => {
    switch (viewMode) {
      case 'list':
        return (
          <Grid sx={{ py: 1.25 }} spacing={2.5} container>
            {dataForRender?.map(({ tom }) => (
              <Grid item xs={12} md={6} lg={4} xl={xxl ? 2 : 3} container justifyContent='center' key={tom.id}>
                <TomCard
                  data={tom}
                  onClick={onTomItemClick}
                  showSendToProd={
                    (role === 'admin' || role === 'client' || isExtraAccessArchitector) &&
                    isRdTom(tom) &&
                    tom.status === 'Согласовано'
                  }
                  onSendToProdClick={onTomSendToProdClick}
                />
              </Grid>
            ))}
          </Grid>
        )
      case 'table':
        return (
          <AgGridToms
            tomData={dataForRender}
            onTomItemClick={onTomItemClick}
            onSendToProdClick={onTomSendToProdClick}
          />
        )
    }
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>
      Нет томов для отображения. <br />
      Нажмите добавить том или загрузите заранее подготовленные тома с помощью загрузки из файла
    </>,
    [
      {
        text: 'Добавить том',
        icon: AddIcon,
        onClick: () => onAddTomClick(),
      },
      {
        text: 'Загрузка из файла',
        icon: DownloadingIcon,
        onClick: () => onUploadTomClick(),
      },
    ],
  )

  const emptyPageDataWithoutControls: EmptyPageData = getEmptyPageData(<>Нет томов для отображения.</>)

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют тома, соответствующие результатам запроса.</>,
    [],
  )

  return (
    <Stack width='100%'>
      {isTomsLoading ? (
        <Progress />
      ) : isTomsExist ? (
        <>
          <DocsTopLegend
            onAddClick={onAddTomClick}
            onUploadExcelClick={onUploadTomClick}
            onUploadFilesClick={onUploadFilesClick}
            onExportArchiveOpenClick={onExportArchiveOpenClick}
            docsSelector={docsSelector}
            changeDocsSelector={changeDocsSelector}
            showSendNotificationsButton={
              !!docsConfigByPhase[selectedProjectPhase]?.docs.showSendNotificationsButton &&
              (role === 'admin' || role === 'client' || isExtraAccessArchitector)
            }
            onSendNotificationsOpenClick={onSendNotificationsOpenClick}
          />
          <DocsBottomLegend<TomStatusTabLabels>
            currentTab={currentTab}
            onTabChange={(e, tabValue) => onTabChange(tabValue)}
            tabsData={tabsData}
            numberRows={NUMBER_OF_ROWS_PER_TOMS_PAGE}
          />
          {isTomsFetching ? (
            <Progress />
          ) : dataForRender?.length ? (
            renderDataView()
          ) : (
            <EmptyPage data={emptyFilteredData} forFilter />
          )}
        </>
      ) : (
        <EmptyPage data={userWithLessControls ? emptyPageDataWithoutControls : emptyPageData} />
      )}

      <DocsUploadDrawer open={openedDrawer === 'upload'} />
      <DocsUploadFilesDrawer open={openedDrawer === 'uploadFiles'} />
      <NotificationsDrawer />
      <ExportArchiveDrawer />

      <Menu
        anchorEl={anchorEl}
        open={MenuOpen}
        onClose={onClose}
        MenuListProps={{
          style: {
            width: '100%',
          },
        }}
        PaperProps={{
          style: {
            display: 'flex',
            justifyContent: 'center',
            minWidth: 200,
            // minHeight: 200,
            maxWidth: 450,
            maxHeight: 300,
          },
        }}
      >
        {projectsLoading || projectsFetching ? (
          <Progress />
        ) : projects?.length > 1 ? (
          projects
            .filter((project) => project.id !== parseInt(projectId!))
            ?.map((project) => (
              <MenuItem onClick={() => onMenuItemClick(project.id)} style={{ maxWidth: 450 }} key={project.id}>
                <Typography
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {project.shifrName}
                </Typography>
              </MenuItem>
            ))
        ) : (
          <MenuItem style={{ maxWidth: 450 }} disabled>
            <Typography>Другие проекты отсутствуют</Typography>
          </MenuItem>
        )}
      </Menu>

      <ConfirmDialog />
    </Stack>
  )
}
