// Формат двузначного года входящей даты, в четырехзначный

export const formatDateToFullYear = (date: Date | null) => {
  if (!(date instanceof Date)) {
    return date
  }

  const day = date.getDate()
  const month = date.getMonth()
  const shortYear = date.getFullYear()

  if (shortYear < 100) {
    const currentYear = new Date().getFullYear()
    const currentMillennium = Math.floor(currentYear / 100) * 100

    const fullYear = currentMillennium + shortYear
    return new Date(fullYear, month, day)
  }

  return date
}