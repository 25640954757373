import styled from 'styled-components'
import { StyledSelectMenuItem } from '../../../../components/UserManagement/components/UserRoleSelection/styles'
import { StyledSelectField } from '../../../../components/FieldForm/styles'

export const AttachmentsDrawerSelect = styled(StyledSelectField)`
  & .MuiSelect-select {
    text-align: left;
  }
`

export const AttachmentsDrawerSelectMenuItem = styled(StyledSelectMenuItem)`
  max-width: 100%;
`
