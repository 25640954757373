import AppsIcon from '@mui/icons-material/Apps'
import TocIcon from '@mui/icons-material/Toc'
import { StyledToggleButton, StyledToggleButtonGroup } from './ViewModeButtonGroup.styles'
import { ViewMode } from '../../store/slices/ui'

interface ViewModeButtonGroupProps {
  viewMode: ViewMode
  onViewChange: (newValue: ViewMode) => void
}

export const ViewModeButtonGroup = ({ viewMode, onViewChange }: ViewModeButtonGroupProps) => {
  return (
    <StyledToggleButtonGroup
      color='primary'
      value={viewMode}
      exclusive
      onChange={(e, newValue) => onViewChange(newValue)}
    >
      <StyledToggleButton value='list' >
        <AppsIcon color='primary' />
      </StyledToggleButton>
      <StyledToggleButton value='table'>
        <TocIcon color='primary' />
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  )
}