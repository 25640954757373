import { useGetCompanyNamesByProjectQuery, useGetEmployeesByProjectQuery } from '../../../../../../api/users'
import { useTypedSelector } from '../../../../../../store/store'
import { currentCompanyIdSelector } from '../../../../../../store/slices/profile'
import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { ProjectAgreementFormData } from '../../../ProjectAgreementForm.types'
import { EmployeeProjectProfile } from '../../../../../../api/users/types'
import { ProjectRoadmapFormData } from '../../../../ProjectAgreement/components/ProjectAgreementLegend'

export interface UseAgreementPersonProps {
  projectId: number
}

export interface UseAgreementPersonReturn {
  companyNames: string[]
  employeesByProjectAndCompany: EmployeeProjectProfile[]
  isFetching: boolean
}

export const useAgreementPerson = <T extends ProjectAgreementFormData | ProjectRoadmapFormData>({
  projectId,
}: UseAgreementPersonProps): UseAgreementPersonReturn => {
  const companyId = useTypedSelector(currentCompanyIdSelector)

  const { values, setFieldValue } = useFormikContext<T>()

  const { data: companyNamesData, isFetching: companyFetching } = useGetCompanyNamesByProjectQuery(
    { companyId, projectId },
    { skip: !(companyId && projectId) }
  )
  const { data: companyNames = [] } = companyNamesData || {}

  const { data: employeesByProjectData, isFetching: employeesFetching } = useGetEmployeesByProjectQuery(
    { companyId, projectId },
    { skip: !(companyId && projectId) }
  )
  const { data: employeesByProject = [] } = employeesByProjectData || {}

  const employeesByProjectAndCompany = values.companyName
    ? employeesByProject.filter(({ company }) => company === values.companyName)
    : employeesByProject

  // clear user on company select
  useEffect(() => {
    if (!employeesByProject.length) return
    const personData = employeesByProject.find(employee => employee.id === values.personId)
    if (personData?.company !== values.companyName) {
      setFieldValue('personId', null)
    }
  }, [values.companyName, employeesByProject])

  // set company on user select
  useEffect(() => {
    const personData = employeesByProject.find(employee => employee.id === values.personId)
    const companyNameByPersonId = companyNames.find(companyName => companyName === personData?.company)

    if (companyNameByPersonId) {
      setFieldValue('companyName', companyNameByPersonId)
    }
  }, [values.personId])

  return {
    companyNames,
    employeesByProjectAndCompany,
    isFetching: employeesFetching || companyFetching
  }
}