import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { useGetProjectDashboardQuery, useGetWorksWidgetQuery } from 'api/projects'
import { TProjectWorksStatistic, projectWorksStatisticsDefault } from 'api/projects/types'
import SmallDownloadIcon from 'assets/icons/SmallDownloadIcon'
import { OverflowTextNew } from 'components/OverflowTextNew/OverflowTextNew'
import Progress from 'components/Progress'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import React, { FC, MouseEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { downloadBlob } from 'utils/dowloadBlob'

import { useExportWorksMutation } from '../../../../api/works'
import { theme } from '../../../../styles/theme'
import { InfoBlockWrapper, InfoCardButton } from '../PhaseCardContent'
import { DesignGraphCardWrapper, ScheduleSelect, ScheduleSelectMenuItemWrapper } from './DesignGraphCard.styles'
import { DesignGraphCardProps, projectWorksLabelStatistics } from './DesignGraphCard.types'

export const DesignGraphCard: FC<DesignGraphCardProps> = () => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { data: projectRes, isFetching } = useGetProjectDashboardQuery({ id: projectId! }, { skip: !projectId })

  const navigate = useNavigate()
  const { id, shifrName } = projectRes?.data?.project || {}

  const [exportWorks, exportWorksResponse] = useExportWorksMutation()
  const { enqueueSnackbar } = useSnackbar()

  const { data: widgetData, isLoading } = useGetWorksWidgetQuery({ id: id! }, { skip: !id })
  const data = widgetData?.data || []

  const [selectedScheduleId, setSelectedScheduleId] = useState<number | null>(null)

  const selectedScheduleData = useMemo(() => {
    const index: number = data.findIndex((item) => item.schedule.id == selectedScheduleId)

    return data[index]
  }, [data, selectedScheduleId])

  useEffect(() => {
    if (data.length) {
      setSelectedScheduleId(data[0].schedule.id)
    }
  }, [data])

  const onCardClick = (e: MouseEvent) => {
    e.stopPropagation()

    if (selectedScheduleData) navigate(`schedules/${selectedScheduleData?.schedule?.id}`)
    else navigate('schedules')
  }

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    e.stopPropagation()
    setSelectedScheduleId(e.target.value as number)
  }

  const onExportClick = (e: MouseEvent) => {
    e.stopPropagation()
    exportWorks({ id: id!, scheduleId: selectedScheduleData.schedule.id })
  }

  useMutationHandlers(exportWorksResponse, (data: Blob) => {
    downloadBlob(new File([data], ''), `${shifrName} График.xlsx`)
    enqueueSnackbar('Файл успешно сформирован.', { variant: 'success' })
  })

  const formattedPercent = useMemo(() => {
    const { donePercent = null } = selectedScheduleData || {}

    return donePercent ? `${selectedScheduleData?.donePercent.toFixed(2).replace('.', ',')} %` : '—'
  }, [selectedScheduleData])

  return (
    <DesignGraphCardWrapper onClick={onCardClick}>
      <Stack spacing={1.25} width={'100%'}>
        <Box>
          <Typography variant='body1' textAlign='left' color={theme.palette.primary.main}>
            График проектирования
          </Typography>
        </Box>
        {data.length > 0 && (
          <ScheduleSelect
            value={selectedScheduleId}
            onChange={(e) => handleChange(e)}
            onClose={(e) => e.stopPropagation()}
            SelectDisplayProps={{ style: { width: '100%' } }}
            MenuProps={{
              PaperProps: {
                style: { maxWidth: 331, maxHeight: 320 },
              },
            }}
          >
            {data.map((item) => (
              <ScheduleSelectMenuItemWrapper key={item.schedule.id} value={item.schedule.id}>
                <OverflowTextNew>{item.schedule.title}</OverflowTextNew>
              </ScheduleSelectMenuItemWrapper>
            ))}
          </ScheduleSelect>
        )}
      </Stack>
      {!isLoading ? (
        <Stack flex={1} justifyContent='space-between' spacing={1.5}>
          <Stack height={'100%'} justifyContent={'space-between'} spacing={1.5}>
            <Stack spacing={1.5}>
              <InfoBlockWrapper>
                {Object.entries(selectedScheduleData?.count || projectWorksStatisticsDefault).map((item) => (
                  <InfoCardButton
                    variant='text'
                    onClick={() => {}}
                    disabled={!item[1]}
                    endIcon={<Typography variant='body2'>{item[1]}</Typography>}
                  >
                    {projectWorksLabelStatistics[item[0] as TProjectWorksStatistic]}
                  </InfoCardButton>
                ))}
              </InfoBlockWrapper>

              <InfoBlockWrapper>
                <InfoCardButton
                  disabled={!data.length}
                  variant='text'
                  onClick={onExportClick}
                  startIcon={<SmallDownloadIcon fontSize='small' />}
                >
                  Экспортировать график
                </InfoCardButton>
              </InfoBlockWrapper>
            </Stack>

            <Stack bgcolor={theme.palette.bg.shades} p={1} borderRadius={1}>
              <Typography variant='body2' fontSize={12} color={theme.palette.text.dark}>
                Общая готовность: {formattedPercent}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Progress />
      )}
    </DesignGraphCardWrapper>
  )
}
