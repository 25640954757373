import { RootState } from '../../../store'
import { ViewMode } from '../ui.types'

export const adminRdViewSelector = (state: RootState): ViewMode => state.ui.pagesViewMode.adminRd
export const adminPdViewSelector = (state: RootState): ViewMode => state.ui.pagesViewMode.adminPd
export const objectsViewSelector = (state: RootState): ViewMode => state.ui.pagesViewMode.objects
export const docsViewSelector = (state: RootState): ViewMode => state.ui.pagesViewMode.docs
export const pocketsViewSelector = (state: RootState): ViewMode => state.ui.pagesViewMode.pockets
export const pocketTomsViewSelector = (state: RootState): ViewMode => state.ui.pagesViewMode.pocketToms
export const modelsViewSelector = (state: RootState): ViewMode => state.ui.pagesViewMode.models



