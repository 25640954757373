import { UseArchiveClearResponse } from './useArchiveClear.types'
import { useFormikContext } from 'formik'
import {
  ExportArchiveFormData,
  SelectedIiTomData,
  SelectedIrdTomData,
  SelectedPdTomData,
  SelectedRdTomData
} from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { ProjectPhase } from '../../../../../../../../types/project'
import { useTypedSelector } from '../../../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../../../store/slices/documentsPages/projectInfo'

export const useArchiveClear = (): UseArchiveClearResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { values: formValues, setFieldValue } = useFormikContext<ExportArchiveFormData>()

  // Rd
  const onRdClearSelectedToms = (selectedToms: SelectedRdTomData[]) => {
    setFieldValue('selectedRdToms', [])

    selectedToms.forEach(({ objectIndex, rdIndex, tomIndex }) => {
      setFieldValue(`rdFileList[${objectIndex}].rdList[${rdIndex}].tomList[${tomIndex}].selected`, false)
      setFieldValue(`rdFileList[${objectIndex}].rdList[${rdIndex}].selected`, false)
      setFieldValue(`rdFileList[${objectIndex}].selected`, false)
    })
  }


  // Pd
  const onPdClearSelectedToms = (selectedToms: SelectedPdTomData[]) => {
    setFieldValue('selectedPdToms', [])

    selectedToms.forEach(({ pdIndex, tomIndex }) => {
      setFieldValue(`pdFileList[${pdIndex}].tomList[${tomIndex}].selected`, false)
      setFieldValue(`pdFileList[${pdIndex}].selected`, false)
    })
  }


  // Ii
  const onIiClearSelectedToms = (selectedToms: SelectedIiTomData[]) => {
    setFieldValue('selectedIiToms', [])

    selectedToms.forEach(({ iiIndex, tomIndex }) => {
      setFieldValue(`iiFileList[${iiIndex}].tomList[${tomIndex}].selected`, false)
      setFieldValue(`iiFileList[${iiIndex}].selected`, false)
    })
  }


  // Ird
  const onIrdClearSelectedToms = (selectedToms: SelectedIrdTomData[]) => {
    setFieldValue('selectedIrdToms', [])

    selectedToms.forEach(({ irdIndex, tomIndex }) => {
      setFieldValue(`irdFileList[${irdIndex}].tomList[${tomIndex}].selected`, false)
      setFieldValue(`irdFileList[${irdIndex}].selected`, false)
    })
  }


  // Common
  const onClearSelectedToms: Record<ProjectPhase, () => void> = {
    'Рабочая документация': () => onRdClearSelectedToms(formValues.selectedRdToms),
    'Проектная документация': () => onPdClearSelectedToms(formValues.selectedPdToms),
    'Инженерные изыскания': () => onIiClearSelectedToms(formValues.selectedIiToms),
    'Сбор исходных данных': () => onIrdClearSelectedToms(formValues.selectedIrdToms),
  }

  return {
    onClearSelectedToms: onClearSelectedToms[selectedProjectPhase],
  }
}
