import React, { useEffect } from 'react'
import AdminNavBar from '../../components/AdminNavBar'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import { InnerContainer, MainContainer } from '../HomeLayout/styles'
import { useTypedSelector } from '../../store/store'
import { profileSelector } from '../../store/slices/profile'

const AdminLayout: React.FC = () => {
  const { role } = useTypedSelector(profileSelector)
  const navigate = useNavigate()

  const isSettingsPage = !!useMatch(`/administration/settings`)

  useEffect(() => {
    if (role && role !== 'admin') {
      navigate('/home')
    }
  }, [role])

  return role === 'admin'
    ? (
      <>
        <AdminNavBar />
        <MainContainer>
          <Header isHasSearch={isSettingsPage ? false : true}/>
          <InnerContainer>
            <Outlet />
          </InnerContainer>
        </MainContainer>
      </>
    )
    : null
}

export default AdminLayout