import React from 'react'
import { CircularProgress, CircularProgressProps, Stack } from '@mui/material'


const Progress: React.FC<CircularProgressProps> = (props?) => {
  return (
    <Stack flex={1} alignItems='center' justifyContent='center' height='100%'>
      <CircularProgress {...props}/>
    </Stack>
  )
}

export default Progress