import { Select, Stack, Tabs } from "@mui/material";
import styled from "styled-components";

export const SideBarWrapper = styled(Stack)<{$hasNoDocument?: boolean}>`
  width: 330px;
  min-width: 330px;
  padding: ${props => props.$hasNoDocument && '16px 0 0 16px'};
  background-color: ${props => props.$hasNoDocument && '#f6f7f9'};
`

export const StyledButtonGroup = styled(Tabs)`
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: transparent;

  .MuiTabs-flexContainer {
    background-color: ${props => props.theme.palette.bg.shades};
    border-radius: 6px;
    height: 36px;
    align-items: center;
    margin-right: 13px;

    & .MuiDivider-root {
      opacity: 0.3;
      border-color: ${props => props.theme.palette.primaryBorderLight};
    }
    
  }

  & .MuiTab-fullWidth {
    height: 36px;
    min-height: min-content;
    padding: 0;
    border-radius: 6px;
    margin: 0 2px;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.palette.primary.main};
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .Mui-selected {
      color: ${props => props.theme.palette.text.light};
      background-color: ${props => props.theme.palette.primary.main};
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    }
`
export const ScrollableContainer = styled(Stack)`
  //height: calc(100vh - 142px);
  // chrome 13px;
  // moz 8px;
  flex: 1;
  padding-right: 13px;
  overflow: auto scroll;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    padding-right: 0;

    &::-webkit-scrollbar {
      display: block;
    }
  }

  @supports (-moz-appearance:none) {
    scrollbar-width: none;

    &:hover {
      padding-right: 5px;
      scrollbar-width: thin;
    }
  }
`

export const StyledSelect = styled(Select)`

  & .MuiSelect-select {
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: ${props => props.theme.palette.bg.lightBlue};
  }
  

  & .MuiSelect-iconStandard {
    font-size: 20px!important;
    right: 6px;
    color: ${props => props.theme.palette.primary.main};
  }
`
export const StyledBox = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  border: 0.5px solid ${props => props.theme.palette.primaryBorderLight};

  & .MuiTypography-root {
    color: ${props => props.theme.palette.primaryBorderLight};
   }
`
