import { Stack } from "@mui/material";
import styled from "styled-components";

export const NavigationContainer = styled(Stack) <{ active: boolean }>`
  align-items: center;
  cursor: ${props => props.active ? 'pointer' : 'default'};
  
 & .MuiTypography-root {
  color: ${props => !props.active && props.theme.palette.disabled};
 }

 & :not(:first-of-type).MuiSvgIcon-root  {
  color: ${props => !props.active && props.theme.palette.disabled};
 }
`