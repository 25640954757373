import styled from 'styled-components'
import { Stack } from '@mui/material'

export const AttachmentsExportDrawerWrapper = styled(Stack)`
  width: 882px;
  height: 100%;
`

export const AttachmentsExportDrawerTopBar = styled(Stack)`
  padding: 20px 40px 15px;
`