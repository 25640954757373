import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectInfoState } from './projectInfo.types'
import { FullProject } from '../../../../api/projects/types'
import { ProjectPhase } from '../../../../types/project'
import { SetDefaultProjectInfoPayload, SetSelectedProjectPhasePayload } from './projectInfo.payloads.types'


let initialState: ProjectInfoState = {
  project: {} as FullProject,
  irdAccess: false,
  iiAccess: false,
  pdAccess: false,
  rdAccess: false,
  userWithLessControls: false,
  selectedProjectPhase: '' as ProjectPhase,
}

const projectInfoSlice = createSlice({
  name: 'projectInfoState',
  initialState,
  reducers: {
    setDefaultProjectInfo: (state, { payload }: PayloadAction<SetDefaultProjectInfoPayload>) => {
      const {
        project,
        irdAccess,
        iiAccess,
        pdAccess,
        rdAccess,
        userWithLessControls,
      } = payload

      state.project = project
      state.irdAccess = irdAccess
      state.iiAccess = iiAccess
      state.pdAccess = pdAccess
      state.rdAccess = rdAccess
      state.userWithLessControls = userWithLessControls
    },
    setSelectedProjectPhase: (state, { payload }: PayloadAction<SetSelectedProjectPhasePayload>) => {
      const selectedProjectPhase = payload.selectedProjectPhase

      state.selectedProjectPhase = payload.selectedProjectPhase
      localStorage.setItem('selectedProjectPhase', selectedProjectPhase)
    },

  },
})

export const { reducer: projectInfoReducer } = projectInfoSlice
export const { setDefaultProjectInfo, setSelectedProjectPhase } = projectInfoSlice.actions
