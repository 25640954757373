import styled from 'styled-components'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import Button from '../../components/Button'

export const RowEditIconButton = styled(Button)`
  min-width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 50%;
`

export const StyledDataGrid = styled(DataGrid)`
  background-color: ${props => props.theme.palette.bg.white};
  border: none;
  border-radius: 8px;

  & .MuiDataGrid-columnHeaders {
    text-align: center;
    color: #ffffff;
    border: none;
    background-color: ${props => props.theme.palette.secondary.gray};
    border-radius: 8px 8px 0 0;
  }

  & .MuiDataGrid-columnHeader {
    border: none;
    outline: none !important;

    &:not(:first-child) {
      box-shadow: inset 1px 0 0 rgba(0, 36, 95, 0.1);
    }

    & .MuiDataGrid-columnHeaderTitle {
      font-weight: 400;
      letter-spacing: 0.1px;
    }
  }

  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;

      & ${RowEditIconButton} {
        display: block;
      }
    }
  }

  & .MuiDataGrid-cell {
    letter-spacing: ${props => props.theme.typography.body2.letterSpacing};
    color: ${props => props.theme.palette.text.dark};
    border: none;
    box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1), inset 1px 0px 0px rgba(0, 36, 95, 0.1);
    outline: none !important;
    padding: 0 16px;

    &:first-child {
      box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
    }

    &:focus {
      outline: none;
    }
  }

  //custom classes
  & ${RowEditIconButton} {
    display: none;
  }

  & .statusCell {
    padding: 0 16px !important;

    & .MuiBox-root {
      justify-content: center;
    }
  }

  & .multilineHeader {
    & .MuiDataGrid-columnHeaderTitle {
      line-height: 18px;
      text-overflow: initial;
      white-space: normal;
    }
  }

  & .editableRow {
    padding-right: 8px !important;
  }

  & .MuiDataGrid-row.disableHover {
    &:hover {
      background-color: transparent;
      cursor: initial;
    }
  }

  & .commonDocsRow .MuiDataGrid-cell {
    color: ${props => props.theme.palette.warning.main};
  }

  & .emptyDocRow .MuiDataGrid-cell {
    color: ${props => props.theme.palette.disabled};
  }

  & .MuiDataGrid-columnHeader.removeLeftBorderHeader {
      box-shadow: none;
  }

  & .MuiDataGrid-cell.removeLeftBorderCell {
    box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
  }

  & .MuiDataGrid-columnHeader.mediumXPaddingHeader {
    padding: 0 16px;
  }

  & .MuiDataGrid-cell.mediumXPaddingCell {
    padding: 0 16px;
  }
`

export const StyledGridActionsCellItem = styled(GridActionsCellItem)`
  & .MuiSvgIcon-root {
    font-size: 20px !important;
  }
`