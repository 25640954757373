import React, { FC } from 'react'

import { Link, useNavigate, useParams } from 'react-router-dom'
import { Divider, Stack, Typography } from '@mui/material'
import Button from '../../../../components/Button'
import { theme } from '../../../../styles/theme'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import HomeIcon from '../../../../assets/icons/HomeIcon'
import {
  DrawerProjectDescription,
  DrawerProjectTitle,
  ProjectAccentText,
  ProjectText
} from '../../../Objects/components/ObjectsNavBarContent/styles'
import { PocketsDrawerContent, PocketsDrawerTextWrapper, PocketsTitledWrapper, PocketsTypeGroup, PocketsTypeGroupButton } from './PocketsNavbar.styles'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { projectTypeShort } from '../../../../types/project'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import GradingIcon from '@mui/icons-material/Grading'
import EventNoteIcon from '@mui/icons-material/EventNote'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { PocketsNavbarProps } from './PocketsNavbar.types'
import { AgreementDesignLogo, AgreementDrawer, AgreementDrawerHeader, AgreementLogoWrapper } from '../../../../layouts/AgreementLayout/AgreementNavbar.styles'

export const PocketsNavbar: FC<PocketsNavbarProps> = ({ project, pocketPhase, onPocketPhaseChange }) => {
  const navigate = useNavigate()
  const { projectId: projectIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)


  const { shifrName, fullName, phase, status, type, startDate, endDate } = project || {}

  const onReturnClick = () => {
    navigate(-1)
  }

  const onHomeClick = () => {
    navigate(`/project/${projectId}`)
  }

  return (
    <AgreementDrawer
      variant='permanent'
    >
      <AgreementDrawerHeader direction='row' justifyContent='space-between' spacing={1}>
        <Link to='/home'>
          <AgreementLogoWrapper>
            <AgreementDesignLogo />
          </AgreementLogoWrapper>
        </Link>

        <Stack direction='row' alignItems='center' spacing={1.25}>
          <Button icon bgColor={theme.palette.bg.shades} color='primary' variant='text' onClick={() => onReturnClick()}>
            <ArrowBackIcon />
          </Button>
          <Button icon bgColor={theme.palette.bg.shades} color='primary' variant='text' onClick={() => onHomeClick()}>
            <HomeIcon />
          </Button>
        </Stack>
      </AgreementDrawerHeader>

      <PocketsDrawerContent spacing={2}>
        <PocketsTypeGroup
          value={pocketPhase}
          exclusive
          onChange={(e, newValue) => onPocketPhaseChange(newValue)}
        >
          <PocketsTypeGroupButton value='all'>Все</PocketsTypeGroupButton>
          <Divider flexItem orientation='vertical' />

          <PocketsTypeGroupButton value='ИИ'>ИИ</PocketsTypeGroupButton>
          <Divider flexItem orientation='vertical' />

          <PocketsTypeGroupButton value='ПД'>ПД</PocketsTypeGroupButton>
          <Divider flexItem orientation='vertical' />

          <PocketsTypeGroupButton value='РД'>РД</PocketsTypeGroupButton>
        </PocketsTypeGroup>
        <Stack spacing={1.5}>
          <PocketsDrawerTextWrapper>
            <DrawerProjectTitle variant='subtitle1'>{shifrName}</DrawerProjectTitle>
          </PocketsDrawerTextWrapper>
          <PocketsDrawerTextWrapper style={{ padding: '16px' }}>
            <DrawerProjectDescription variant='body2'>{fullName}</DrawerProjectDescription>
          </PocketsDrawerTextWrapper>
        </Stack>


        <PocketsTitledWrapper
          title='Общая информация о проекте'
          variant='primary'
          contentWrapper='stack'
          bordered='border'
        >
          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
            <Stack direction='row' spacing={1}>
              <ErrorOutlineIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Статус:</Typography>
            </Stack>

            <ProjectText variant='subtitle2'>{status}</ProjectText>
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
            <Stack direction='row' spacing={1}>
              <InsertDriveFileOutlinedIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Тип:</Typography>
            </Stack>

            <ProjectText variant='subtitle2'>{projectTypeShort[type!]}</ProjectText>
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
            <Stack direction='row' spacing={1}>
              <GradingIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Стадия:</Typography>
            </Stack>

            <ProjectAccentText variant='subtitle2'>{phase}</ProjectAccentText>
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
            <Stack direction='row' spacing={1}>
              <EventNoteIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Дата начала:</Typography>
            </Stack>

            <ProjectText variant='subtitle2'>{startDate}</ProjectText>
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: 30 }}>
            <Stack direction='row' spacing={1}>
              <EventAvailableIcon fontSize='medium' color='secondary' />
              <Typography variant='body2' component='span'>Дата окончания:</Typography>
            </Stack>

            <ProjectText variant='subtitle2'>{endDate}</ProjectText>
          </Stack>
        </PocketsTitledWrapper>
      </PocketsDrawerContent>
    </AgreementDrawer>
  )
}
