import React, { FC, useCallback, useEffect, useState } from 'react'
import { WorksDrawerProps } from './WorksDrawer.types'
import { Box, Button, Drawer, Stack, Typography } from '@mui/material'
import { theme } from '../../../../styles/theme'
import Divider from '../../../../components/Divider'
import { WorksDrawerTopBar, WorksDrawerWrapper } from './WorksDrawer.styles'
import { WorksDrawerForm } from './WorksDrawerForm'
import DeleteConfirm from '../../../../components/DeleteConfirm'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDeleteWorkMutation } from '../../../../api/works'
import { useParams } from 'react-router-dom'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'

export const WorksDrawer: FC<WorksDrawerProps> = ({ open, onClose, chosenWorkId, numbersOfsubGroup }) => {
  const { projectId: projectIdStr, scheduleId: scheduleIdStr} = useParams()
  const projectId = parseInt(projectIdStr!)
  const scheduleId = parseInt(scheduleIdStr!)

  const isEdit = chosenWorkId
  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  // Delete
  const [deleteWork, deleteWorkResponse] = useDeleteWorkMutation()

  const onDeleteButtonClick = useCallback(() => {
    setIsDeleteClicked(true)
  }, [])

  const onDeleteSubmit = useCallback(() => {
    if (!chosenWorkId) return

    deleteWork({ id: projectId, scheduleId, workId: chosenWorkId })
  }, [projectId, chosenWorkId])

  const onDeleteCancel = useCallback(() => {
    setIsDeleteClicked(false)
  }, [])

  useMutationHandlers(deleteWorkResponse,
    () => {
      onClose(false, true)
    }
  )

  // Utils
  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  useEffect(() => {
    if (!open) {
      setIsDeleteClicked(false)
    }
  }, [open])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => onClose(dirty)}
    >
      <WorksDrawerWrapper>
        <WorksDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {isEdit ? 'Редактировать работу' : 'Добавить работу'}
          </Typography>
        </WorksDrawerTopBar>

        <Divider />

        <WorksDrawerForm
          chosenWorkId={chosenWorkId}
          numbersOfsubGroup={numbersOfsubGroup}
          onClose={onClose}
          onFormChange={onFormChange}
        />

        {isEdit && (
          <Stack justifyContent='space-between' alignItems='center' flex={1} sx={{ p: 2.5, width: '100%' }}>
            <Box style={{ width: '100%', paddingBottom: 24 }}>
              {isDeleteClicked
                ? (
                  <DeleteConfirm
                    text='Вы действительно хотите удалить работу?  Это действие нельзя отменить.'
                    onDelete={onDeleteSubmit}
                    onCancel={onDeleteCancel}
                  />
                )
                : null
              }
            </Box>
            <Box style={{ width: 'fit-content' }}>
              <Button
                onClick={onDeleteButtonClick}
                startIcon={<DeleteIcon />}
                variant='text'
                color='error'
              >
                Удалить работу
              </Button>
            </Box>
          </Stack>
        )}
      </WorksDrawerWrapper>
    </Drawer>
  )
}
