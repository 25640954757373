import { OnTomClick, UseGetHandlersResponse } from './useGetHandlers.types'

// @ts-ignore:next-line
import { flushSync } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../../../store/store'
import { clearFilter, updateFilterData } from '../../../../../../store/slices/documentsPages/filter'

export const useGetHandlers = (): UseGetHandlersResponse => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // Rd
  const onTomRdClick = (objectId: number, rdId: number, tomRdId: number) => {
    flushSync(() => {
      dispatch(clearFilter({ recalculateAvailableRds: true }))
      dispatch(updateFilterData({ selectedIds: [objectId], filterType: 'object' }))
      dispatch(updateFilterData({ selectedIds: [rdId], filterType: 'rd' }))
    })
    navigate(`./tom/${tomRdId}`)
  }


  // Pd
  const onTomPdClick = (pdId: number, tomPdId: number) => {
    flushSync(() => {
      dispatch(updateFilterData({ selectedIds: [pdId], filterType: 'pd' }))
    })
    navigate(`./tom/${tomPdId}`)
  }


  // Ii
  const onTomIiClick = (iiId: number, tomIiId: number) => {
    flushSync(() => {
      dispatch(updateFilterData({ selectedIds: [iiId], filterType: 'ii' }))
    })
    navigate(`./tom/${tomIiId}`)
  }


  // Ird
  const onTomIrdClick = (irdId: number, tomIrdId: number) => {
    flushSync(() => {
      dispatch(updateFilterData({ selectedIds: [irdId], filterType: 'ird' }))
    })
    navigate(`./tom/${tomIrdId}`)
  }


  // Common
  const onTomClick: OnTomClick = {
    'Рабочая документация': onTomRdClick,
    'Проектная документация': onTomPdClick,
    'Инженерные изыскания': onTomIiClick,
    'Сбор исходных данных': onTomIrdClick,
  }

  return {
    onTomClick,
  }
}
