import { Button, Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledSection = styled(Stack)`
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`

export const StyledTitle = styled(Typography)`
  color: ${props => props.theme.palette.secondary.main};
`

export const StyledButton = styled(Button)`
  text-transform: none;
  height: auto;
  padding: 6px 8px;
  font-weight: 400;
  color: ${props => props.theme.palette.secondary.main};
`
