import { ScheduleItem } from 'api/works'
import { AgreementSchemes } from 'pages/ProjectInfo/components/AgreementSchemes'
import { DesignGraphCard } from 'pages/ProjectInfo/components/DesignGraphCard'
import { PhaseCards } from 'pages/ProjectInfo/components/PhaseCards'
import { PocketCard } from 'pages/ProjectInfo/components/PocketCard'
import { RemarksCard } from 'pages/ProjectInfo/components/RemarksCard'
import { ReactNode } from 'react'

import { IFormWidgetItem } from '@pages/ProjectInfo/components/WidgetsDrawer/WidgetsDrawer.types'

import { ProjectExpertise, ProjectFullStatusesRu, ProjectPhase, ProjectType } from '../../types/project'
import { EmployeeShortProfile } from '../users/types'

export interface Project {
  id: number
  endDate: string
  fullName: string
  shifrName: string
  shortName: string
  startDate: string
  status: ProjectFullStatusesRu
  phase: ProjectPhase
  type: ProjectType
  expertise?: ProjectExpertise
}

export interface FullProject extends Project {
  addressData: ProjectAddress
  responsiblePersons: ProjectResponsiblePersons<EmployeeData>
}

export interface DashboardProject {
  project: Project
  rd: ProjectStatRd
  pd: ProjectStatPd
  ii: ProjectStatIi
  ird: ProjectStatIrd
  link?: ProjectLink
  addressData: ProjectAddress
  responsiblePersons: ProjectResponsiblePersons<EmployeeDataEnhanced>
  images: ProjectImage[]
}

export interface ProjectAddress {
  address: string
  latitude: string
  longitude: string
  region: string
}

export interface EmployeeData extends Pick<EmployeeShortProfile, 'id' | 'name'> {
  company: string
}

export interface EmployeeDataEnhanced extends Pick<EmployeeData, 'id' | 'company'> {
  firstName: string
  lastName: string
  middleName: string
  phone: string
  company: string
  customName: string
}

export interface ProjectImage {
  id: number
  imageFull: string
  imagePreview: string
  originName: string
}

export interface ProjectStatPd {
  stat: StatPd
  totalPPD: number
  totalToms: number
}

export interface StatPd {
  empty: number
  expired: number
  onCorrect: number
  toAgreed: number
  toWork: number
}

export interface ProjectStatRd {
  stat: StatRd
  totalObj: number
  totalToms: number
}

export interface StatRd extends StatPd {
  toProd: number
}

export interface ProjectStatIi {
  stat: StatIi
  totalII: number
  totalToms: number
}

export interface StatIi extends StatPd {}

export interface ProjectStatIrd {
  stat: StatIrd
  totalIRD: number
  totalToms: number
}

export interface StatIrd extends StatPd {}

export interface ProjectStatTIM {
  stat: StatTIM
  totalObj: number
  totalTim: number
}

export interface StatTIM extends StatPd {}

export interface ProjectResponsiblePersons<T> {
  architector: T
  client: T
  contractors: T[]
}

export interface ProjectTomStatistics {
  count: number
  percent: number
  clickable: boolean
}

export interface ProjectStatisticsPd {
  agreed: ProjectTomStatistics
  inWork: ProjectTomStatistics
  agreement: ProjectTomStatistics
  correction: ProjectTomStatistics
  development: ProjectTomStatistics
  notInWork: ProjectTomStatistics
  totalCount: number
}

export interface ProjectStatisticsRd extends ProjectStatisticsPd {
  inProd: ProjectTomStatistics
  notInProd: ProjectTomStatistics
}

// Ii
export interface ProjectStatisticsIi {
  pr: ProjectStatisticsIiItem
  to: ProjectStatisticsIiItem
  tz: ProjectStatisticsIiItem
}

export interface ProjectStatisticsIiItem extends Pick<ProjectStatisticsPd, 'agreed' | 'agreement' | 'totalCount'> {
  other: ProjectTomStatistics
}

// Ird
export interface ProjectStatisticsIrd {
  groups: ProjectStatisticsIrdGroup[]
}

export interface ProjectStatisticsIrdGroup extends Pick<ProjectStatisticsPd, 'inWork' | 'notInWork' | 'totalCount'> {
  done: ProjectTomStatistics
  group: ProjectStatisticsIrdGroupInfo
}

export interface ProjectStatisticsIrdGroupInfo {
  id: number
  fullName: string
  mark: string
}

export interface ProjectShortStatistics {
  agreed: number
  inWork: number
  notInWork: number
}

export interface GroupCount {
  obj: number
  pd: number
}

export interface ProjectLink {
  url?: string
  name?: string
}

export interface ProjectWithStatistics extends Pick<FullProject, 'addressData'> {
  irdAccess: boolean
  iiAccess: boolean
  pdAccess: boolean
  rdAccess: boolean
  groupCount: GroupCount
  responsiblePersons: ProjectResponsiblePersons<EmployeeDataEnhanced>
  project: Project
  statistic: ProjectStatisticsRd
  statisticPd: ProjectStatisticsPd
  statisticIi: ProjectStatisticsIi
  statisticIrd: ProjectStatisticsIrd
}

// export toms
export type ProjectExportPhaseType = 'РД' | 'ПД' | 'ИИ' | 'ИРД'
export type ProjectExportTomStatus =
  | 'Не в работе'
  | 'Разработка'
  | 'На согласовании'
  | 'Корректировка'
  | 'Согласовано'
  | 'Не согласуется'
  | 'В производстве'

export interface ProjectExportPhase {
  name: string
  groups?: ProjectExportGroup[]
  objects?: ProjectExportObject[]
  type: ProjectExportPhaseType
  selected: boolean
  hideByFilter: boolean
  openedManually: boolean
}

export interface ProjectExportObject {
  name: string
  groups: ProjectExportGroup[]
  number: string
  selected: boolean
  hideByFilter: boolean
  openedManually: boolean
}

export interface ProjectExportGroup {
  name: string
  toms: ProjectExportTom[]
  selected: boolean
  hideByFilter: boolean
  openedManually: boolean
}

export interface ProjectExportTom {
  id: number
  name: string
  status: ProjectExportTomStatus
  selected: boolean
  hideByFilter: boolean
}

export interface ProjectExportTomRequest {
  id: number
  type: ProjectExportPhaseType
}

export const isRdStatistics = (
  data: ProjectStatisticsRd | ProjectStatisticsPd | ProjectStatisticsIi | ProjectStatisticsIrd,
): data is ProjectStatisticsRd => {
  return (data as ProjectStatisticsRd).inProd !== undefined
}

export const isIrdStatistics = (
  data: ProjectStatisticsRd | ProjectStatisticsPd | ProjectStatisticsIi | ProjectStatisticsIrd,
): data is ProjectStatisticsIrd => {
  return (data as ProjectStatisticsIrd).groups !== undefined
}

export interface ProjectPerson {
  avatar: string | null
  company: string | null
  id: number | null
  name: string | null
  position: string | null
}

export interface ProjectClients {
  company: string | null
  persons: ProjectPerson[]
}

export interface IWorksWidgetDataItem {
  count: IProjectWorksStatistics
  donePercent: number
  schedule: ScheduleItem
}

export type TProjectWorksStatistic = 'totalGroups' | 'totalWorks' | 'notInWork' | 'inWork' | 'done' | 'expired'

export const projectWorksStatisticsDefault: IProjectWorksStatistics = {
  totalGroups: 0,
  totalWorks: 0,
  notInWork: 0,
  inWork: 0,
  done: 0,
  expired: 0,
}

export interface IProjectWorksStatistics {
  totalGroups: number
  totalWorks: number
  notInWork: number
  inWork: number
  done: number
  expired: number
}

export type TDashboardPhaseWidget = 'IRD' | 'II' | 'PD' | 'RD' | 'TIM'

export type TDashboardWidget =
  | TDashboardPhaseWidget
  | 'DESING_GRAPH'
  | 'AGREEMENT_SCHEMES'
  | 'DOCUMENTATION'
  | 'REMARKS'

export const dashboardWidgets: TDashboardWidget[] = [
  'IRD',
  'II',
  'PD',
  'RD',
  'TIM',
  'DESING_GRAPH',
  'AGREEMENT_SCHEMES',
  'DOCUMENTATION',
  'REMARKS',
]

export const dashboardWidgetsDefaultData: IFormWidgetItem[] = dashboardWidgets.map((widgetName) => ({
  name: widgetName,
  enabled: true,
}))

export const dashboardWidgetLabelByValue: Record<TDashboardWidget, string> = {
  IRD: 'Исходно-разрешительная документация',
  II: 'Инженерные изыскания',
  PD: 'Проектная документация',
  RD: 'Рабочая документация',
  TIM: 'ТИМ-модели',
  DESING_GRAPH: 'График проектирования',
  AGREEMENT_SCHEMES: 'Схемы согласования',
  DOCUMENTATION: 'Реестр передачи документации',
  REMARKS: 'Реестр замечаний',
}

export const dashboardWidgetByValue: Record<TDashboardWidget, ReactNode> = {
  IRD: <PhaseCards widget='IRD' />,
  II: <PhaseCards widget='II' />,
  PD: <PhaseCards widget='PD' />,
  RD: <PhaseCards widget='RD' />,
  TIM: <PhaseCards widget='TIM' />,
  DESING_GRAPH: <DesignGraphCard />,
  AGREEMENT_SCHEMES: <AgreementSchemes />,
  DOCUMENTATION: <PocketCard />,
  REMARKS: <RemarksCard />,
}
