import { Box, Button } from "@mui/material";
import styled from "styled-components";

export const HelpButtonContainer = styled(Box)`
  position: absolute;
  z-index: 5;
  bottom: 25px;
  right: 30px;
`

export const StyledHelpButton = styled(Button)`
  height: 40px;
  min-width: 40px;
  padding: 5px 10px;
  background-color: ${props => props.theme.palette.bg.lightBlue};
  color: ${props => props.theme.palette.primary.main};

  :hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }
`