import { Button, Typography } from "@mui/material";
import { AutocompleteField } from "components/AutocompleteField/AutocompleteField";
import FieldForm from "components/FieldForm";
import styled from "styled-components";

export const LabelTextRequired = styled(Typography)`
  
  ::after {
    content: ' *';
    color: #F46B6B;
  }
`
export const ManualSubmitField = styled(FieldForm)`
  width: 200px;

  & .MuiFilledInput-root {
    height: 32px;
  }
`
export const ManualSubmitAutocomplete = styled(AutocompleteField)`
    width: 200px;
    height: 30px;

  & .MuiOutlinedInput-root {
    height: 30px;
    width: inherit;
    padding: 4.5px 12px;
  }

  & .MuiFormHelperText-root {
    display: none;
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    max-width: calc(100% - 50px);
    padding: 0;
    transform: translate(12px, 5px) scale(1);
  }

  & .MuiAutocomplete-inputRoot {

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.secondary.dark + '3A'};
    }

    &.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border: 1px solid rgba(92,110,140,0.1);
      background-color: ${props => props.theme.palette.bg.gray};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }

    & .MuiAutocomplete-input {
      width: calc(100% - 15px);
    }
  }

  & .MuiAutocomplete-popper {
    width: 270px;
  }
`
export const StyledButton = styled(Button).attrs({ component: 'label' })`
  text-transform: none;
  font-weight: 400;
  height: 36px;
  font-size: 16px;
  padding: 5px 10px;
  background-color: ${props => props.theme.palette.bg.lightBlue};
  color: ${props => props.theme.palette.primary.main};
  margin-top: 12px;
  gap: 6px;

  :hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }
`
export const FileName = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
  font-size: 14px;
  overflow-wrap: break-word;
  overflow: hidden;
`