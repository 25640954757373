import styled from 'styled-components'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  border-radius: 6px;
`

export interface StyledToggleButtonProps {
  variant?: 'primary' | 'shades'
}

export const StyledToggleButton = styled(ToggleButton)<StyledToggleButtonProps>`
  padding: 7px;
  background-color: ${props => props.theme.palette.bg.gray};
  background-color: ${props => props.variant === 'shades' ? props.theme.palette.bg.shades : ''};
  border: 1px solid #0044B4;

  &:hover {
    background-color: ${props => props.theme.palette.bg.gray};
    background-color: ${props => props.variant === 'shades' ? props.theme.palette.bg.shades : ''};
  }

  &:not(:last-child) {
    box-shadow: ${props => `inset -1px 0 0 ${props.theme.palette.primary.main}`};
  }

  &,
  & .MuiTypography-root {
    color: ${props => props.theme.palette.primary.main};
  }

  &.Mui-selected {
    background-color: ${props => props.theme.palette.primary.main};

    &,
    & .MuiTypography-root {
      color: ${props => props.theme.palette.bg.white};
    }

    &:hover {
      background-color: ${props => props.theme.palette.primary.main};
    }
  }
`
