import React, { useCallback, useEffect } from 'react'
import { Box, Stack, Tab, Tabs } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add'
import DownloadingIcon from '@mui/icons-material/Downloading'
import { ObjectsLegendProps } from './ObjectsLegend.types'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { objectsViewSelector, PagesViewMode, setPageViewMode, ViewMode } from '../../../../store/slices/ui'
import { ExportIcon } from '../../../../assets/icons/ExportIcon'
import Tooltip from '../../../../components/Tooltip'
import { TabData } from '../../../../components/Tabs/Tabs.types'
import { RdViewTab } from '../../Objects.types'
import { rdViewTabSelector, setRdViewTab } from '../../../../store/slices/documentsPages/objects'
import { StyledButton, StyledSwitch, TextLegend } from './ObjectsLegend.styles'
import { useLocation } from 'react-router-dom';
import { ViewModeButtonGroup } from '../../../../components/ViewModeButtonGroup';


const ObjectsLegend: React.FC<ObjectsLegendProps> = ({
  onAddClick,
  onUploadClick,
  onExportClick,
  changeItems,
  allItems,
  showButtons,
  showUploadButton,
  showExportButon,
  showViewSwitcher,
  showObjSwitcher,
  showRdpSwitcher,
}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isTimPage = location.pathname.includes('tim')
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const viewMode = useTypedSelector(objectsViewSelector)
  const rdViewTab = useTypedSelector(rdViewTabSelector)

  const onViewChange = useCallback((updatedViewMode: ViewMode) => {
    if (updatedViewMode !== null) {
      dispatch(setPageViewMode({ page: 'objects', viewMode: updatedViewMode }))
    }
  }, [])

  useEffect(() => {
    const cachedPagesViewMode: PagesViewMode = JSON.parse(localStorage.getItem('pagesViewMode') || '{}')
    if (cachedPagesViewMode?.objects) {
      dispatch(setPageViewMode({ page: 'objects', viewMode: cachedPagesViewMode.objects }))
    }
    return () => {
      dispatch(setRdViewTab({ rdViewTab: 'objects' }))
    }
  }, [])

  const tabsData: TabData<RdViewTab>[] = [
    { value: 'objects', label: 'Объекты' },
    { value: 'rdp', label: 'Разделы' },
  ]

  const onRdViewTabChange = (e: React.SyntheticEvent, tabValue: RdViewTab) => {
    tabValue !== rdViewTab && dispatch(setRdViewTab({ rdViewTab: tabValue }))
  }

  const getSwitchTooltip = () => {
    if (rdViewTab === 'objects') {
      if (isTimPage) {
        return <>По умолчанию отображаются Все объекты.<p>При неактивном положении переключателя на странице будут отображаться только объекты, к которым привязана хотя бы одна ТИМ-модель.</p></>
      } else {
        return <>По умолчанию отображаются Все объекты.<p>При неактивном положении переключателя на странице будут отображаться только объекты, к которым привязан хотя бы один том.</p></>
      }
    } else {
      return <>По умолчанию отображаются Все разделы.<p>При неактивном положении переключателя на странице будут отображаться только разделы, к которым привязан хотя бы один том или ТИМ-модель.</p></>
    }
  }

  return (
    <Stack>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1.5 }}>
        <Stack direction='row' alignItems='center' spacing={2.5}>
          {showButtons
            ? (
              <Stack direction='row' alignItems='center' spacing={1.25}>
                <Box>
                  <StyledButton
                    startIcon={<AddIcon />}
                    onClick={onAddClick}
                    variant='outlined'
                    color='secondary'>
                    {(selectedProjectPhase === 'Рабочая документация' && rdViewTab === 'objects')
                      ? 'Добавить объект' : 'Добавить раздел'}
                  </StyledButton>
                </Box>
                {showUploadButton
                  ? (
                    <Box>
                      <StyledButton
                        startIcon={<DownloadingIcon />}
                        onClick={onUploadClick}
                        variant='outlined'
                        color='secondary'>
                        Импорт excel
                      </StyledButton>
                    </Box>
                  )
                  : null
                }
              </Stack>
            )
            : null
          }
          {showExportButon &&
            <Tooltip title='Экспортировать документацию в Excel'>
              <span style={{ marginLeft: 10 }}>
                <StyledButton onClick={onExportClick} variant='outlined' color='secondary' sx={{ p: 1 }}>
                  <ExportIcon />
                </StyledButton>
              </span>
            </Tooltip>}
        </Stack>

        <Stack direction='row' alignItems='center' spacing={5}>
          {showObjSwitcher &&
            <Stack direction='row' alignItems='center' spacing={1.25}>
              <StyledSwitch
                checked={allItems}
                onChange={(e) => changeItems(e.target.checked)} />
              <TextLegend variant='buttonSmall'>{rdViewTab === 'objects' ? 'Все объекты' : 'Все разделы'}</TextLegend>
              <Tooltip variant='light' maxWidth={340} fontSize={14} padding='16px'
                title={getSwitchTooltip()}>
                <InfoOutlinedIcon fontSize="medium" sx={{ color: '#D1D8FA' }} />
              </Tooltip>
            </Stack>}

          {showViewSwitcher
            ? (
              <Stack direction='row' justifyContent='center' alignItems='center'>
                <ViewModeButtonGroup viewMode={viewMode} onViewChange={onViewChange} />
              </Stack>
            )
            : null
          }
        </Stack>
      </Stack>

      {showRdpSwitcher && (
        <Stack direction='row' alignItems='center' pb={1.5}>
          <Tabs value={rdViewTab} onChange={onRdViewTabChange}>
            {tabsData.map(tabData => (
              <Tab {...tabData} sx={{ fontSize: 14 }} />
            ))}
          </Tabs>
        </Stack>
      )}
    </Stack>
  )
}

export default ObjectsLegend