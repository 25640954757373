import { FC } from 'react'
import { Typography } from '@mui/material'
import { CustomCellProps } from '../cells.types'
import { DeviationCellWrapper, DeviationElement } from './DurationCell.styles'

export const DurationCell: FC<CustomCellProps> = ({ work }) => {
  const { period } = work
  const { duration, deviation } = period

  return (
    <>
      <DeviationCellWrapper>
        <Typography variant='body2'>
          {duration.total}
        </Typography>

        {!!deviation.end && (
          <DeviationElement value={deviation.end}>
            {deviation.end > 0 && '+'}{deviation.end}
          </DeviationElement>
        )}
      </DeviationCellWrapper>
    </>
  )
}
