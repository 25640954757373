import { Divider, Stack, Typography } from '@mui/material'
import { TomIiType } from 'api/iiPhase'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetNavbarItems } from '@pages/Docs/hooks/useGetNavbarItems'

import Button from '../../../../components/Button'
import { docsConfigByPhase } from '../../../../layouts/DocumentsLayout'
import { clearFilter, filterDataSelector, setIiType } from '../../../../store/slices/documentsPages/filter'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector,
  setSelectedProjectPhase,
} from '../../../../store/slices/documentsPages/projectInfo'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { theme } from '../../../../styles/theme'
import { ProjectPhase } from '../../../../types/project'
import { StyledToggleButton, StyledToggleButtonGroup } from '../../../Administration/components/AdminRdLegend/styles'
import { DocsFilterAccordion, DocsStyledToggleButton, DocsStyledToggleButtonGroup } from '../DocsFilterAccordion'
import { DocsObjectAccordion } from '../DocsObjectAccordion'
import { useMainFilterData } from '@pages/Docs'

export const DocsNavBarContent: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  
  const { mainFilterItems, mainFilterOnChange } = useMainFilterData()

  const { project, pdAccess, rdAccess } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { id } = project

  const onObjectsButtonClick = () => {
    navigate(`/project/${id}/objects`)
  }
  const { filterData } = useTypedSelector(filterDataSelector)
  const { iiType } = filterData
  const onViewPhaseChange = (newViewPhase: ProjectPhase) => {
    if (newViewPhase !== null) {
      dispatch(setSelectedProjectPhase({ selectedProjectPhase: newViewPhase }))
      mainFilterOnChange(mainFilterItems.map((e) => e.value))
    }
  }
  const { navbarItems, navButtonIcon } = useGetNavbarItems()
  const onIiTypeChange = useCallback(
    (updatedIiType: TomIiType) => {
      if (updatedIiType !== null) {
        dispatch(setIiType({ iiType: updatedIiType }))
      }
    },
    [dispatch],
  )
  return (
    <Stack gap={2.5} overflow={'hidden'}>
      <Stack pr={2.5} direction='row' alignItems='center' spacing={1.5}>
        {(selectedProjectPhase === 'Рабочая документация' || selectedProjectPhase === 'Проектная документация') &&
          rdAccess &&
          pdAccess && (
            <StyledToggleButtonGroup
              value={selectedProjectPhase}
              exclusive
              onChange={(e, newValue) => onViewPhaseChange(newValue)}
            >
              <StyledToggleButton value='Проектная документация' variant='shades'>
                <Typography width={24} height={24} variant='buttonMedium' lineHeight='25px'>
                  ПД
                </Typography>
              </StyledToggleButton>
              <StyledToggleButton value='Рабочая документация' variant='shades'>
                <Typography width={24} height={24} variant='buttonMedium' lineHeight='25px'>
                  РД
                </Typography>
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          )}

        <Button
          startIcon={navButtonIcon}
          variant='text'
          bgColor={theme.palette.bg.shades}
          width='100%'
          onClick={() => onObjectsButtonClick()}
          paddingSize='small'
          style={{ maxWidth: '100%' }}
        >
          {docsConfigByPhase[selectedProjectPhase]?.docs.navLinkText}
        </Button>
      </Stack>
      {selectedProjectPhase === 'Инженерные изыскания' && (
        <DocsStyledToggleButtonGroup value={iiType} exclusive onChange={(_e, newValue) => onIiTypeChange(newValue)}>
          <DocsStyledToggleButton value='Техническое задание'>ТЗ</DocsStyledToggleButton>
          <DocsStyledToggleButton value='Программа работ'>ПР</DocsStyledToggleButton>
          <DocsStyledToggleButton value='Технический отчет'>Отчеты</DocsStyledToggleButton>
        </DocsStyledToggleButtonGroup>
      )}
      <DocsFilterAccordion />
      <Divider sx={{ borderColor: '#CAD3F4', mr: 2.5 }} />
      <DocsObjectAccordion items={navbarItems} />
    </Stack>
  )
}
