import { IiCardStatisticsProps } from '../../CardStatistics.types'
import { DefaultStatisticsProps } from '../DefaultStatistics'
import { TomIiType } from '../../../../../../api/iiPhase'

export interface IiStatisticsProps extends
  Pick<IiCardStatisticsProps, 'data'>,
  Pick<DefaultStatisticsProps, 'onStatisticHeaderClick'> {
}

export type IiTypeGroupTitle = 'Технические задания' | 'Программы работ' | 'Технические отчеты'

export const getIiTypeByGroupTitle: Record<IiTypeGroupTitle, TomIiType> = {
  'Технические задания': 'Техническое задание',
  'Программы работ': 'Программа работ',
  'Технические отчеты': 'Технический отчет',
}