import { Tabs, Typography } from "@mui/material"
import styled from "styled-components"
import { ScrollableContainer } from "styles/global/ScrollableContainer"

export const DrawerWrapper = styled(ScrollableContainer)`
  height: 100%;
  width: 400px;
  padding-top: 0;
  padding-left: 20px;
  padding-bottom: 0;
`
export const DrawerHeader = styled(Typography)`
  padding: 20px 0 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #D1D8FA;
`
export const StyledButtonGroup = styled(Tabs)`
  padding-top: 20px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0px 0px 15px 5px #fff;

  .MuiTabs-flexContainer {
    background-color: ${props => props.theme.palette.bg.white};
    border: 1px solid ${props => props.theme.palette.primaryBorderLight};
    border-radius: 6px;
    height: 32px;
    align-items: center;
    
  }

  & .MuiTab-fullWidth {
    height: 32px;
    min-height: min-content;
    padding: 0;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.palette.secondary.dark};

    &:first-child {
      border-right: 1px solid ${props => props.theme.palette.primaryBorderLight};
    }
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .Mui-selected {
      color: ${props => props.theme.palette.primary.main};
      background-color: ${props => props.theme.palette.bg.lightBlue};
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    }
`