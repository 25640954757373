import styled from 'styled-components'
import { Box } from '@mui/material'
import { isLegendColor, LegendColors } from '../../types/global'

interface StyledBoxProps {
  bgColor: LegendColors | 'status' | 'primary' | 'noAccess' | '#F6F7FB';
}

export const StyledBox = styled(Box) <StyledBoxProps>`
  display: flex;
  width: fit-content;
  padding: 5px 8px 3px;
  background-color: ${props => props.bgColor === 'noAccess' && props.theme.palette.bg.gray};
  background-color: ${props => isLegendColor(props.bgColor) && props.theme.palette.legends[props.bgColor]};
  background-color: ${props => props.bgColor === 'status' && 'rgba(25, 118, 210, 0.08)'};
  background-color: ${props => props.bgColor === 'primary' && props.theme.palette.primary.main};
  background-color: ${props => props.bgColor === '#F6F7FB' && '#F6F7FB'};
  border-radius: 4px;

  & .MuiTypography-root {

    font-weight: ${props => props.bgColor === 'status' ? 400 : 500};
    color: ${props => props.bgColor === 'noAccess' || props.bgColor === '#F6F7FB'
    ? props.theme.palette.disabled
    : props.bgColor === 'status'
      ? props.theme.palette.primary.main
      : props.theme.palette.text.light};
  }
`
