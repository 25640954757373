import { Stack } from "@mui/material";
import styled from "styled-components";

export const DocumentLabel = styled.span`
  width: 40px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.text.light};
`
export const RemarkCounterBlock = styled(Stack)`
  flex-direction: row;
  padding: 8px;
  border-radius: 4px;
  background-color: ${props => props.theme.palette.bg.lightBlue};
  cursor: pointer;

  & .MuiTypography-root:last-of-type {
    color: ${props => props.theme.palette.error.main};
  }
`