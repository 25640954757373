import styled from 'styled-components'
import { ToggleButtonGroup } from '@mui/material'

export const DocViewToggleButtonGroup = styled(ToggleButtonGroup)`
  background-color: ${props => props.theme.palette.bg.shades};

  & .MuiToggleButton-root {
    height: 40px;
    padding: 0;
    border: 0;
    border-right: ${props => `1px solid ${props.theme.palette.bg.gray}`};
    border-radius: 6px;
    box-shadow: none;

    &:last-child {
      border-right: 0;
    }

    & .MuiSvgIcon-root {
      color: ${props => props.theme.palette.primary.main};
    }

    &.Mui-selected {
      background-color: ${props => props.theme.palette.primary.main};

      & .MuiSvgIcon-root {
        color: ${props => props.theme.palette.text.light};
      }
    }

    &.Mui-disabled {
      & .MuiSvgIcon-root {
        color: ${props => props.theme.palette.disabled};
      }
    }
  }
`