import styled from 'styled-components'
import { Button, Tab } from '@mui/material'

export const PocketsTab = styled(Tab)`
  min-width: auto;
`

export const StyledButton = styled(Button)`
  border-color: ${props => props.theme.palette.primaryBorderLight};

  &:hover {
    border-color: ${props => props.theme.palette.primary.main};
  }
`
