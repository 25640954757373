import { IconButton, Stack } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ListHeaderProps } from "./ListHeader.types"
import { StyledIconButton, StyledListHeader, StyledText } from "./ListHeader.styles";

export const ListHeader = ({ open, title, tabUsed, setIsOpen, onAddClick, onDeleteClick }: ListHeaderProps) => {

  const handleClick = () => {
    setIsOpen(!open)
  }

  return (

    <StyledListHeader direction="row" spacing={1}>
      <Stack direction='row' spacing={3} alignItems='center'>
        <IconButton onClick={handleClick} sx={{ padding: 0 }} color="secondary">
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <StyledText variant="body2">
          {title}
        </StyledText>
      </Stack>

      {tabUsed === 1 && (
        <Stack direction='row' spacing={1}>
          <StyledIconButton onClick={onAddClick}>
            <AddIcon fontSize='small' color="primary" />
          </StyledIconButton>
          <StyledIconButton onClick={onDeleteClick}>
            <DeleteOutlineIcon fontSize='small' color="primary"/>
          </StyledIconButton>
        </Stack>
      )}
    </StyledListHeader>


  )
}