import React from 'react'
import { Box, FormHelperText, IconButton, Stack, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { AccessCardItem, AccessCardWrapper, CheckboxesWrapper } from './styles'
import { theme } from '../../../../styles/theme'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import UserObjectsSelection from '../UserObjectsSelection'
import { AccessCardProps } from './AccessCard.types'
import { useFormikContext } from 'formik'
import { UserFormData } from '../../UserForm/UserForm.types'
import FieldForm from '../../../FieldForm'
import GradingIcon from '@mui/icons-material/Grading'
import { GetObjectsResponse, useGetObjectsByProjectIdQuery } from '../../../../api/rdPhase'

const AccessCard: React.FC<AccessCardProps> = ({
  projectData,
  index,
  onAccessItemDelete,
}) => {
  const { values: formValues, setFieldValue, errors } = useFormikContext<UserFormData>()
  const selectedRole = formValues.role
  const { id, name } = projectData

  const fieldError = errors?.projects?.[index]

  const { data, isFetching } = useGetObjectsByProjectIdQuery({ id })
  const { data: objects } = data || ({} as GetObjectsResponse)

  const isDataLoading = isFetching

  return (
    <Box style={{ width: '100%' }}>
      <AccessCardWrapper style={{ width: '100%' }} spacing={1}>
        <Box style={{ alignSelf: 'flex-end' }}>
          <IconButton onClick={() => onAccessItemDelete()}>
            <DeleteIcon fontSize='medium' color='error' />
          </IconButton>
        </Box>

        <Stack spacing={2} pr={1.25}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <DescriptionOutlinedIcon fontSize='medium' color='secondary' />
              <Typography variant='subtitle1' color={theme.palette.secondary.dark} fontWeight={500}
                          component='span'>Проект:</Typography>
            </Stack>
            <AccessCardItem>
              <Typography variant='subtitle2' component='span'>
                {name}
              </Typography>
            </AccessCardItem>
          </Stack>

          {selectedRole === 'contractor' || selectedRole === 'supervisor'
            ? (
              <Stack direction='row' justifyContent='space-between' alignItems='start' spacing={2}>
                <Stack direction='row' alignItems='center' spacing={1} pt={0.75}>
                  <GradingIcon fontSize='medium' color='secondary' />
                  <Typography variant='subtitle1' color={theme.palette.secondary.dark} fontWeight={500}
                              component='span'>Доступ:</Typography>
                </Stack>
                <CheckboxesWrapper>
                  <FieldForm
                    version='checkbox'
                    name={`projects[${index}].irdAccess`}
                    checkboxData={{
                      label: (
                        <Typography
                          variant='subtitle2'
                          color={theme.palette.text.dark}
                          fontWeight={400}
                          component='span'
                        >
                          Исходно-разрешительная документация
                        </Typography>
                      ),
                      checked: formValues.projects[index].irdAccess,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          `projects[${index}].irdAccess`,
                          e.target.checked
                        )
                      }
                    }}
                  />
                  <FieldForm
                    version='checkbox'
                    name={`projects[${index}].iiAccess`}
                    checkboxData={{
                      label: (
                        <Typography
                          variant='subtitle2'
                          color={theme.palette.text.dark}
                          fontWeight={400}
                          component='span'
                        >
                          Инженерные изыскания
                        </Typography>
                      ),
                      checked: formValues.projects[index].iiAccess,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          `projects[${index}].iiAccess`,
                          e.target.checked
                        )
                      }
                    }}
                  />

                  <FieldForm
                    version='checkbox'
                    name={`projects[${index}].pdAccess`}
                    checkboxData={{
                      label: (
                        <Typography
                          variant='subtitle2'
                          color={theme.palette.text.dark}
                          fontWeight={400}
                          component='span'
                        >
                          Проектная документация
                        </Typography>
                      ),
                      checked: formValues.projects[index].pdAccess,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          `projects[${index}].pdAccess`,
                          e.target.checked
                        )
                      }
                    }}
                  />

                  {selectedRole === 'supervisor' && (
                    <FieldForm
                      version='checkbox'
                      name={`projects[${index}].allObjects`}
                      checkboxData={{
                        label: (
                          <Typography
                            variant='subtitle2'
                            color={theme.palette.text.dark}
                            fontWeight={400}
                            component='span'
                          >
                            Рабочая документация
                          </Typography>
                        ),
                        checked: formValues.projects[index].allObjects,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            `projects[${index}].allObjects`,
                            e.target.checked
                          )
                        }
                      }}
                    />
                  )}
                </CheckboxesWrapper>
              </Stack>
            )
            : null
          }

          {selectedRole === 'architector' && (
            <Stack direction='row' justifyContent='end' alignItems='start' spacing={2}>
              <CheckboxesWrapper>
                <FieldForm
                  version='checkbox'
                  name={`projects[${index}].extraAccess`}
                  checkboxData={{
                    label: (
                      <Typography
                        variant='subtitle2'
                        color={theme.palette.text.dark}
                        fontWeight={400}
                        component='span'
                      >
                        Расширенные права
                      </Typography>
                    ),
                    checked: formValues.projects[index].extraAccess,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(
                        `projects[${index}].extraAccess`,
                        e.target.checked
                      )
                    }
                  }}
                />
              </CheckboxesWrapper>
            </Stack>
          )}

          {!isDataLoading && selectedRole === 'contractor' && (
            <UserObjectsSelection
              objects={objects}
              index={index}
            />
          )}
        </Stack>
      </AccessCardWrapper>
      {!!fieldError
        ? (
          <FormHelperText
            error={!!fieldError}
            style={{ paddingLeft: 8 }}
          >
            {fieldError.toString()}
          </FormHelperText>
        )
        : null
      }
    </Box>
  )
}

export default AccessCard