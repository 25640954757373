import styled from 'styled-components'
import { alpha, IconButton, Stack, Typography } from '@mui/material'
import {
  AdviceBackgroundColor,
  ProjectAgreementExpandAdviceProps
} from './ProjectAgreementExpandAdvice.types'

interface ProjectAgreementExpandAdviceWrapperProps extends Omit<ProjectAgreementExpandAdviceProps, 'data'> {
  backgroundColor: AdviceBackgroundColor
  expanded: boolean
}

export const ProjectAgreementExpandAdviceWrapper = styled(Stack)<ProjectAgreementExpandAdviceWrapperProps>`
  position: absolute;
  z-index: 3;
  top: ${props => props.top};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  padding: ${props => props.expanded ? '8px 20px 8px 16px' : '8px 12px'};
  gap: 10px;

  background-color: ${props => props.backgroundColor === 'orange' && alpha(props.theme.palette.table.yellow, 0.15)};
  background-color: ${props => props.expanded && props.backgroundColor === 'orange' && props.theme.palette.warning.main};

  background-color: ${props => props.backgroundColor === 'blue' && alpha('#1976D2', 0.08)};
  background-color: ${props => props.expanded && props.backgroundColor === 'blue' && props.theme.palette.info.light};
    // background-color: ${props => props.backgroundColor === 'white' && props.theme.palette.bg.white};
  border-radius: ${props => props.borderRadius};
  cursor: pointer;

  & .MuiSvgIcon-root {
    color: ${props => props.backgroundColor === 'orange' && props.theme.palette.warning.main};
    color: ${props => props.expanded && props.backgroundColor === 'orange' && props.theme.palette.text.light};

    color: ${props => props.backgroundColor === 'blue' && props.theme.palette.info.main};
    color: ${props => props.expanded && props.backgroundColor === 'blue' && props.theme.palette.text.light};
      // color: ${props => props.backgroundColor === 'white' && props.theme.palette.secondary.dark};
  }
`

export const ProjectAgreementExpandAdviceTitleWrapper = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 20px;


`

interface ProjectAgreementExpandAdviceTextProps {
  parentBackgroundColor: AdviceBackgroundColor
  expanded: boolean
}

export const ProjectAgreementExpandAdviceTitle = styled(Typography)<ProjectAgreementExpandAdviceTextProps>`
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.parentBackgroundColor === 'orange' && props.theme.palette.warning.main};
  color: ${props => props.expanded && props.parentBackgroundColor === 'orange' && props.theme.palette.text.light};

  color: ${props => props.parentBackgroundColor === 'blue' && props.theme.palette.info.main};
  color: ${props => props.expanded && props.parentBackgroundColor === 'blue' && props.theme.palette.text.light};
    // color: ${props => props.parentBackgroundColor === 'white' && props.theme.palette.secondary.dark};
`

export const ProjectAgreementExpandAdviceTextWrapper = styled(Stack)`
  padding-left: 20px;
  gap: 2px;
`

export const ProjectAgreementExpandAdviceText = styled(Typography)<ProjectAgreementExpandAdviceTextProps>`
  position: relative;
  text-align: left;
  color: ${props => props.parentBackgroundColor === 'orange' && props.theme.palette.warning.main};
  color: ${props => props.expanded && props.parentBackgroundColor === 'orange' && props.theme.palette.text.light};

  color: ${props => props.parentBackgroundColor === 'blue' && props.theme.palette.info.main};
  color: ${props => props.expanded && props.parentBackgroundColor === 'blue' && props.theme.palette.text.light};
    // color: ${props => props.parentBackgroundColor === 'white' && props.theme.palette.secondary.dark};

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: -12px;
    width: 3px;
    height: 3px;
    background-color: ${props => props.theme.palette.text.light};
    border-radius: 50%;
  }
`

export const ProjectAgreementExpandCloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`