import { UseExportInitialValuesResponse } from './useExportInitialValues.types'
import { useMemo } from 'react'
import {
  TomRdArchiveObject,
  TomRdArchiveRd,
  TomRdArchiveTom,
  useGetTomsRdFileListQuery
} from '../../../../../../../../api/rdPhase'
import { TomPdArchivePd, TomPdArchiveTom, useGetTomsPdFileListQuery } from '../../../../../../../../api/pdPhase'
import {
  TomIiArchiveIi,
  TomIiArchiveTom,
  useGetTomsIiFileListQuery
} from '../../../../../../../../api/iiPhase/tom/archiveExport'
import {
  ExportArchiveFormData,
  SelectedIiTomData,
  SelectedIrdTomData,
  SelectedPdTomData,
  SelectedRdTomData
} from '../../ExportArchiveDrawerForm.types'
import { useTypedSelector } from '../../../../../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../../../../../store/slices/documentsPages/projectInfo'
import { filterDataSelector } from '../../../../../../../../store/slices/documentsPages/filter'
import {
  TomIrdArchiveIrd,
  TomIrdArchiveTom,
  useGetTomsIrdFileListQuery
} from '../../../../../../../../api/irdPhase/tom/archiveExport'

export const useExportInitialValues = (): UseExportInitialValuesResponse => {
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { filterData } = useTypedSelector(filterDataSelector)

  const {
    selectedObjectsIds,
    selectedRdsIds,
    selectedPdsIds,
    selectedIisIds,
    selectedIrdsIds
  } = filterData


  // Rd
  const { data: rdFileListData } = useGetTomsRdFileListQuery({
    id: project?.id,
  }, {
    skip: selectedProjectPhase !== 'Рабочая документация',
  })
  const { objectList: rdFileList } = rdFileListData || {}

  const initialRdData: Pick<ExportArchiveFormData, 'rdFileList' | 'selectedRdToms'> = useMemo(() => {
    if (!rdFileList) return { rdFileList: [], selectedRdToms: [] }
    if (!selectedObjectsIds?.length && !selectedRdsIds.length) return { rdFileList, selectedRdToms: [] }

    const initialSelectedRdToms: SelectedRdTomData[] = []

    const initialRdFileList = rdFileList?.map<TomRdArchiveObject>((object, objectIndex) => {
      const isObjectDefaultSelected = selectedObjectsIds.includes(object.objId)

      const updatedRdList = object.rdList?.map<TomRdArchiveRd>((rd, rdIndex) => {
        const isRdDefaultSelected = isObjectDefaultSelected || selectedRdsIds.includes(rd.id)

        const updatedTomList = rd.tomList?.map<TomRdArchiveTom>((tom, tomIndex) => {
          isRdDefaultSelected && initialSelectedRdToms.push({
            tom,
            objectIndex,
            rdIndex,
            tomIndex,
          })

          return {
            ...tom,
            selected: isRdDefaultSelected,
          }
        })

        return {
          ...rd,
          tomList: updatedTomList,
          selected: isRdDefaultSelected,
        }
      })

      return {
        ...object,
        rdList: updatedRdList,
        selected: isObjectDefaultSelected,
      }
    })

    return {
      rdFileList: initialRdFileList,
      selectedRdToms: initialSelectedRdToms,
    }
  }, [rdFileList])


  // Pd
  const { data: pdFileListData } = useGetTomsPdFileListQuery({
    id: project?.id
  }, {
    skip: selectedProjectPhase !== 'Проектная документация',
  })
  const { objectList: pdFileList } = pdFileListData || {}

  const initialPdData: Pick<ExportArchiveFormData, 'pdFileList' | 'selectedPdToms'> = useMemo(() => {
    if (!pdFileList) return { pdFileList: [], selectedPdToms: [] }
    if (!selectedPdsIds.length) return { pdFileList, selectedPdToms: [] }

    const initialSelectedPdToms: SelectedPdTomData[] = []

    const initialPdFileList = pdFileList?.map<TomPdArchivePd>((pd, pdIndex) => {
      const isPdDefaultSelected = selectedPdsIds.includes(pd.id)

      const updatedTomList = pd.tomList?.map<TomPdArchiveTom>((tom, tomIndex) => {
        isPdDefaultSelected && initialSelectedPdToms.push({
          tom,
          pdIndex,
          tomIndex,
        })

        return {
          ...tom,
          selected: isPdDefaultSelected,
        }
      })

      return {
        ...pd,
        tomList: updatedTomList,
        selected: isPdDefaultSelected,
      }
    })

    return {
      pdFileList: initialPdFileList,
      selectedPdToms: initialSelectedPdToms,
    }
  }, [pdFileList])


  // Ii
  const { data: iiFileListData } = useGetTomsIiFileListQuery({
    id: project?.id
  }, {
    skip: selectedProjectPhase !== 'Инженерные изыскания',
  })
  const { objectList: iiFileList } = iiFileListData || {}

  const initialIiData: Pick<ExportArchiveFormData, 'iiFileList' | 'selectedIiToms'> = useMemo(() => {
    if (!iiFileList) return { iiFileList: [], selectedIiToms: [] }
    if (!selectedIisIds.length) return { iiFileList, selectedIiToms: [] }

    const initialSelectedIiToms: SelectedIiTomData[] = []

    const initialIiFileList = iiFileList?.map<TomIiArchiveIi>((ii, iiIndex) => {
      const isIiDefaultSelected = selectedIisIds.includes(ii.id)

      const updatedTomList = ii.tomList?.map<TomIiArchiveTom>((tom, tomIndex) => {
        isIiDefaultSelected && initialSelectedIiToms.push({
          tom,
          iiIndex,
          tomIndex,
        })

        return {
          ...tom,
          selected: isIiDefaultSelected,
        }
      })

      return {
        ...ii,
        tomList: updatedTomList,
        selected: isIiDefaultSelected,
      }
    })

    return {
      iiFileList: initialIiFileList,
      selectedIiToms: initialSelectedIiToms,
    }
  }, [iiFileList])


  // Ird
  const { data: irdFileListData } = useGetTomsIrdFileListQuery({
    id: project?.id
  }, {
    skip: selectedProjectPhase !== 'Сбор исходных данных',
  })
  const { objectList: irdFileList } = irdFileListData || {}

  const initialIrdData: Pick<ExportArchiveFormData, 'irdFileList' | 'selectedIrdToms'> = useMemo(() => {
    if (!irdFileList) return { irdFileList: [], selectedIrdToms: [] }
    if (!selectedIrdsIds.length) return { irdFileList, selectedIrdToms: [] }

    const initialSelectedIrdToms: SelectedIrdTomData[] = []

    const initialIrdFileList = irdFileList?.map<TomIrdArchiveIrd>((ird, irdIndex) => {
      const isIrdDefaultSelected = selectedIrdsIds.includes(ird.id)

      const updatedTomList = ird.tomList?.map<TomIrdArchiveTom>((tom, tomIndex) => {
        isIrdDefaultSelected && initialSelectedIrdToms.push({
          tom,
          irdIndex,
          tomIndex,
        })

        return {
          ...tom,
          selected: isIrdDefaultSelected,
        }
      })

      return {
        ...ird,
        tomList: updatedTomList,
        selected: isIrdDefaultSelected,
      }
    })

    return {
      irdFileList: initialIrdFileList,
      selectedIrdToms: initialSelectedIrdToms,
    }
  }, [irdFileList])


  // Common
  const initialPhaseValues = {
    ...initialRdData,
    ...initialPdData,
    ...initialIiData,
    ...initialIrdData,
  }

  return {
    initialPhaseValues,
  }
}
