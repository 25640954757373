import styled from 'styled-components'
import { Stack } from '@mui/material'

export const TabFormWrapper = styled(Stack)`
  flex-direction: row;
  flex: 1;
  gap: 80px;
  padding: 30px;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 16px;
`
