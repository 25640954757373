import { Stack, Tab } from "@mui/material";
import { AgreementDesignLogo, AgreementDrawer, AgreementDrawerHeader, AgreementLogoWrapper, StyledButtonGroup } from "./AgreementNavbar.styles";
import { useEffect, useState } from "react";
import { ScrollableContainer } from "../../styles/global/ScrollableContainer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { theme } from "../../styles/theme";
import Button from "../../components/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import HomeIcon from "../../assets/icons/HomeIcon";
import { InternalAgreementNavbarContent } from "./InternalAgreementNavbarContent/InternalAgreementNavbarContent";
import { AgreementNavbarContent } from "./AgreementNavbarContent";

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function a11yProps(index: number) {
  return {
    id: `agreement-tab-${index}`,
    'aria-controls': `agreement-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`agreement-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </Stack>
  )
}

export const AgreementNavbar = () => {

  const location = useLocation()
  const navigate = useNavigate()
  const { projectId: projectIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)
  const isRoadmap = location.pathname.includes('roadmap')

  const onTabChange = (newValue: number) => {
    setTabUsed(newValue)
    if (newValue === 0) {
      navigate('innermap', {replace: true})
    } else if (newValue === 1) {
      navigate('roadmap', {replace: true})
    } else return
  }

  useEffect(() => {
    const isInnermapPage = location.pathname.includes('innermap') || location.pathname.endsWith('schemes')
    if (location.pathname.includes('roadmap')) {
      setTabUsed(1)
    }
    if (isInnermapPage) {
      setTabUsed(0)
    }

  }, [location])

  const [tabUsed, setTabUsed] = useState<number>(isRoadmap ? 1 : 0)

  return (
    <AgreementDrawer variant='permanent'>
      <AgreementDrawerHeader direction='row' justifyContent='space-between' spacing={1}>
        <Link to='/home'>
          <AgreementLogoWrapper>
            <AgreementDesignLogo />
          </AgreementLogoWrapper>
        </Link>

        <Stack direction='row' alignItems='center' spacing={1.25}>
          <Button icon bgColor={theme.palette.bg.shades} color='primary'
            variant='text' onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </Button>
          <Button icon bgColor={theme.palette.bg.shades} color='primary'
            variant='text' onClick={() => navigate(`/project/${projectId}`)}>
            <HomeIcon />
          </Button>
        </Stack>
      </AgreementDrawerHeader>
      <StyledButtonGroup
        variant="fullWidth"
        value={tabUsed}
        onChange={(e, newValue) => onTabChange(newValue)}
      >
        <Tab disableRipple {...a11yProps(0)} label='Внутренние' />
        <Tab disableRipple {...a11yProps(1)} label='Внешние' />
      </StyledButtonGroup>
      <ScrollableContainer>
        <CustomTabPanel value={tabUsed} index={0}>
          <InternalAgreementNavbarContent />
        </CustomTabPanel>
        <CustomTabPanel value={tabUsed} index={1}>
          <AgreementNavbarContent />
        </CustomTabPanel>
      </ScrollableContainer>
    </AgreementDrawer>
  )
}