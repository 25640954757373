import { UseAccordionFieldDataProps, UseAccordionFieldDataResponse } from './useAccordionFieldData.types'
import { ProjectPhase } from '../../../../../../../../types/project'
import { useFormikContext } from 'formik'
import { ExportArchiveFormData } from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { useTypedSelector } from '../../../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../../../store/slices/documentsPages/projectInfo'

export const useAccordionFieldData = ({
  object,
  pd,
  ii,
  ird,
  objectIndex,
  pdIndex,
  iiIndex,
  irdIndex,
  expanded,
}: UseAccordionFieldDataProps): UseAccordionFieldDataResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  const { values: formValues, setFieldValue } = useFormikContext<ExportArchiveFormData>()

  const accordionLabel: Record<ProjectPhase, string> = {
    'Рабочая документация': object?.name || '',
    'Проектная документация': pd?.name || '',
    'Инженерные изыскания': ii?.name || '',
    'Сбор исходных данных': ird?.name || '',
  }

  const accordionFieldName: Record<ProjectPhase, string> = {
    'Рабочая документация': `rdFileList[${objectIndex}].selected`,
    'Проектная документация': `pdFileList[${pdIndex}].selected`,
    'Инженерные изыскания': `iiFileList[${iiIndex}].selected`,
    'Сбор исходных данных': `irdFileList[${irdIndex}].selected`,
  }

  const accordionFieldChecked: Record<ProjectPhase, boolean> = {
    'Рабочая документация': formValues.rdFileList[objectIndex!]?.selected,
    'Проектная документация': formValues.pdFileList[pdIndex!]?.selected,
    'Инженерные изыскания': formValues.iiFileList[iiIndex!]?.selected,
    'Сбор исходных данных': formValues.irdFileList[irdIndex!]?.selected,
  }

  const setOpenedManually: Record<ProjectPhase, any> = {
    'Рабочая документация': () => setFieldValue(`rdFileList[${objectIndex}].openedManually`, !expanded),
    'Проектная документация': () => setFieldValue(`pdFileList[${pdIndex}].openedManually`, !expanded),
    'Инженерные изыскания': () => setFieldValue(`iiFileList[${iiIndex}].openedManually`, !expanded),
    'Сбор исходных данных': () => setFieldValue(`irdFileList[${irdIndex}].openedManually`, !expanded),
  }

  return {
    accordionLabel: accordionLabel[selectedProjectPhase],
    accordionFieldName: accordionFieldName[selectedProjectPhase],
    accordionFieldChecked: accordionFieldChecked[selectedProjectPhase],
    setOpenedManually: setOpenedManually[selectedProjectPhase],
  }
}
