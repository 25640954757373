import { Drawer, Typography } from '@mui/material'
import { FC, useCallback, useState } from 'react'

import Divider from '@components/Divider'

import { DocsChangeForm, DocsDrawerTopBar, DocsDrawerWrapper } from '@pages/Docs'

import { currentChangeSelector } from '@store/slices/documentsPages/tom'
import { useTypedSelector } from '@store/store'

import { theme } from '@styles/theme'

import { IDocsChangeDrawerProps } from '.'

export const DocsChangeDrawer: FC<IDocsChangeDrawerProps> = (props) => {
  const [dirty, setDirty] = useState<boolean>(false)
  const { currentChange } = useTypedSelector(currentChangeSelector)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer anchor='right' open={props.open} onClose={() => props.onClose(dirty)}>
      <DocsDrawerWrapper>
        <DocsDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {currentChange ? 'Редактирование изменения' : 'Добавление изменения'}
          </Typography>
        </DocsDrawerTopBar>

        <Divider />

        <DocsChangeForm onFormChange={onFormChange} currentChange={currentChange} onClose={props.onClose} />
      </DocsDrawerWrapper>
    </Drawer>
  )
}
