import { Checkbox, Select, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material'
import { FilterSelectProps, SelectItem } from 'components/FilterSelectMulti/FilterSelectMultiple.types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { SelectMenuProps } from '@components/UserManagement/components/UserObjectsSelection'

import { measureTextWidth } from '@utils/measureTextWidth'

import { FilterSelectMenuItem, FilterSelectMultipleTwoStyledTypography } from '.'

export const FilterSelectMultipleTwo = <T extends number | string>({
  startIcon,
  items,
  value,
  defaultValue,
  onChange,
  isDisabled,
  allSelectedText,
  paperFullHeight = false,
  className,
  ...props
}: FilterSelectProps<T> & { children?: React.ReactNode }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const maxWidth = 200
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined)
  const selectRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (selectRef.current) {
      setMenuWidth(selectRef.current.clientWidth)
    }
  }, [])
  const isAllSelected = items?.length === value?.length

  const itemsByValue = useMemo(() => {
    const result = {} as Record<SelectItem<T>['value'], SelectItem<T>>
    items?.forEach((item) => {
      result[item.value] = item
    })
    return result
  }, [items])

  const handleChange = useCallback(
    (e: any) => {
      const value = e?.target?.value

      if (value[value.length - 1] === 'all') {
        onChange(isAllSelected ? [] : items?.map((item) => item.value as T))
        return
      }

      onChange(typeof value === 'string' ? value.split(',') : value)
    },
    [isAllSelected, items, onChange],
  )

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <Stack justifyContent='center' className={className} {...props}>
      <Select
        ref={selectRef}
        disabled={isDisabled}
        multiple
        sx={{
          '& > .MuiSvgIcon-root': {
            right: '8px',
            fontSize: '20px !important',
            color: '#9AA2B0',
          },
          '& .MuiInputBase-input': {
            borderRadius: '6px !important',
            padding: '7px 0px',
            paddingRight: '32px !important',
            backgroundColor: '#F6F7FB !important',
            border: !open ? '1px solid  #2B36481F' : '1px solid  #0044B4',
            ':hover': {
              border: !open ? '1px solid  #2B3648' : '1px solid  #0044B4',
            },
          },
          '& div': {
            alignItems: 'center',
            paddingRight: 0.2,
          },
        }}
        variant='standard'
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        onChange={handleChange}
        disableUnderline
        displayEmpty
        MenuProps={{
          disableAutoFocusItem: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              ...(!paperFullHeight ? SelectMenuProps.PaperProps.style : {}),
              minWidth: `${menuWidth}px`,
              maxWidth: `${menuWidth}px`,
              marginTop: '3px',
              marginLeft: '4px',
            },
          },
        }}
        renderValue={(selected) => {
          let totalWidth = 0
          let visibleCount = 0

          selected.forEach((itemValue) => {
            const item = itemsByValue[itemValue]
            if (item) {
              const width = measureTextWidth(item.title + ', ')
              totalWidth += width
              if (totalWidth <= maxWidth) visibleCount++
              else return
            }
          })

          const hiddenCount = selected.length - visibleCount

          return (
            <Stack direction='row'>
              {!!startIcon ? startIcon : <Stack height={24} />}
              <Stack ml={1} className='contentWrapper'>
                {selected?.length === 0 ? (
                  <FilterSelectMultipleTwoStyledTypography variant='h3' className='contentText'>
                    {defaultValue}
                  </FilterSelectMultipleTwoStyledTypography>
                ) : (
                  <FilterSelectMultipleTwoStyledTypography
                    variant='h3'
                    textOverflow='ellipsis'
                    overflow='hidden'
                    maxWidth={maxWidth}
                    className='contentText'
                  >
                    {isAllSelected
                      ? allSelectedText
                      : selected?.map((itemValue) => itemsByValue[itemValue]?.title).join(', ')}
                  </FilterSelectMultipleTwoStyledTypography>
                )}
              </Stack>
              {!isAllSelected && !!hiddenCount && selected.length > 1 && (
                <Tooltip
                  arrow
                  PopperProps={{
                    sx: {
                      filter:
                        'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10))',
                      [`& .${tooltipClasses.tooltip}`]: {
                        padding: '5px',
                        background: ' #FFF',
                        color: '#2B3648',
                        maxWidth: 270,
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        borderRadius: '6px',
                      },
                      [`& .${tooltipClasses.arrow}`]: {
                        color: ' #FFF',
                      },
                    },
                  }}
                  placement='right'
                  title={selected?.map((itemValue) => itemsByValue[itemValue]?.title).join(', ')}
                >
                  <Stack
                    ml={'auto'}
                    justifyContent={'center'}
                    height={'24px'}
                    p={'0px 8px !important'}
                    width={'fit-content'}
                    borderRadius={3}
                    bgcolor={theme.palette.bg.lightBlue}
                  >
                    <Typography variant='body2' color={`${theme.palette.primary.main} !important`}>
                      + {hiddenCount}
                    </Typography>
                  </Stack>
                </Tooltip>
              )}
            </Stack>
          )
        }}
      >
        <FilterSelectMenuItem value='all'>
          <Checkbox checked={isAllSelected} />
          <Typography variant='body1'>{allSelectedText}</Typography>
        </FilterSelectMenuItem>

        {items?.map((item) => {
          const { value: name, title } = item
          return (
            <FilterSelectMenuItem key={name} value={name}>
              <Checkbox checked={value?.includes(name)} />
              <Typography variant='body1'>{title}</Typography>
            </FilterSelectMenuItem>
          )
        })}
      </Select>
    </Stack>
  )
}
