import { Box, Stack } from '@mui/material'
import styled from 'styled-components'

import { theme } from '@styles/theme'

import { AgreementOverviewColor } from '.'

interface PropsColor extends Record<AgreementOverviewColor, string> {}

const propsColorBorder: PropsColor = {
  blue: theme.palette.info.light,
  green: theme.palette.legends.chartsGreen,
  red: theme.palette.error.main,
  gray: '#0000001A',
  yellow: '#ffb804',
}

const propsColor: PropsColor = {
  blue: theme.palette.info.light,
  green: theme.palette.legends.chartsGreen,
  red: theme.palette.error.main,
  gray: theme.palette.secondary.main,
  yellow: '#ffb804',
}

export const SchemaWrapper = styled(Box)`
  height: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }
`

export const SchemaContainer = styled(Stack)`
  justify-content: center;
  margin-top: 25px;
`
export const RoadmapStep = styled.button<{ color: AgreementOverviewColor }>`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: ${(props) => propsColor[props.color]};
  background-color: ${(props) => (props.color === 'gray' ? theme.palette.bg.shades : 'transparent')};
  border: 1px solid ${(props) => propsColorBorder[props.color]};
  position: relative;
  cursor: pointer;

  &:not(:last-of-type):after {
    content: '';
    position: absolute;
    right: -13px;
    top: 50%;
    width: 13px;
    border-bottom: 1px solid #0000001a;
  }
`
export const SelectedStep = styled(RoadmapStep)`
  width: 180px;
  color: ${(props) => props.theme.palette.text.light};
  background-color: ${(props) => propsColorBorder[props.color]};

  :before {
    content: '';
    position: absolute;
    right: calc(50% - 13px);
    top: 39px;
    width: 26px;
    height: 0;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(90deg, #fff 12px, #0000001a 12px 13px, #fff 13px);
  }

  &.appear::before {
    height: 37px;
    transition:
      height 0.3s ease,
      transform 0.3s ease;
  }
`
export const InnermapContainer = styled(Stack)`
  position: absolute;
  top: 76px;
  left: calc(50% - 20px);
  z-index: 3;
  cursor: default;
`
export const InnermapStep = styled(RoadmapStep)`
  border-radius: 50%;
  opacity: 0;
  transform: translateY(-38px);

  &:last-of-type {
    margin-right: 24px;
  }

  &.appear {
    opacity: 1;
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;
    transform: translateY(0);
  }
`
