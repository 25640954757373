import * as yup from 'yup'

import { TOM_CHANGE_DEFAULT_STATUS } from './DocsChangeForm.types'

const today = new Date(new Date().setHours(0, 0, 0, 0))

export const validationDocsChange = yup.object({
  mode: yup.string(),
  inProd: yup.boolean(),
  change: yup.string().required('common:errors.required'),
  status: yup.string().notOneOf([TOM_CHANGE_DEFAULT_STATUS]).required(),
  plannedDate: yup.date().nullable(),
  actualDate: yup.date().when('status', {
    is: 'Согласовано',
    then: yup.date().max(today).required(),
    otherwise: yup.date().nullable(),
  }),
  prodDate: yup.date().when('status', {
    is: 'Согласовано',
    then: yup.date().when('inProd', {
      is: true,
      then: yup.date().required(),
      otherwise: yup.date().nullable(),
    }),
    otherwise: yup.date().nullable(),
  }),
  files: yup.mixed().when('mode', {
    is: 'add',
    then: yup.mixed().required(),
    otherwise: yup.mixed(),
  }),
  description: yup.string().nullable(),
  durationDate: yup.date().nullable(),
  issueDate: yup.date().when('status', {
    is: 'Не согласуется',
    then: yup.date().required(),
    otherwise: yup.date().nullable(),
  }),
})
