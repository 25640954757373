import { UseGetInitialDocListResponse } from './useGetInitialDocList.types'
import {
  useGetCmnAttachmentFileListQuery,
  useGetIiAttachmentFileListQuery,
  useGetIrdAttachmentFileListQuery,
  useGetPdAttachmentFileListQuery,
  useGetRdAttachmentFileListQuery
} from '../../../../../../../../api/attachments'
import { ProjectPhase } from '../../../../../../../../types/project'
import { ExportDocWithVariant } from '../../../../../../../../api/attachments/types'
import { useTypedSelector } from '../../../../../../../../store/store'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector
} from '../../../../../../../../store/slices/documentsPages/projectInfo'
import { isCmnTomSelector, tomSelector } from '../../../../../../../../store/slices/documentsPages/tom'

export const useGetInitialDocList = (): UseGetInitialDocListResponse => {
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)
  const tomId = isCmnTom ? project.id : tom?.id

  // Rd
  const { data: rdAttachmentFileListData } = useGetRdAttachmentFileListQuery({
    tomId: tomId!,
  }, {
    skip: !tomId || selectedProjectPhase !== 'Рабочая документация',
  })
  const { changes: rdAttachmentChangeList, versions: rdAttachmentVersionList } = rdAttachmentFileListData || {}


  // Pd
  const { data: pdAttachmentFileListData } = useGetPdAttachmentFileListQuery({
    tomId: tomId!,
  }, {
    skip: !tomId || selectedProjectPhase !== 'Проектная документация',
  })
  const { changes: pdAttachmentChangeList, versions: pdAttachmentVersionList } = pdAttachmentFileListData || {}


  // Ii
  const { data: iiAttachmentFileListData } = useGetIiAttachmentFileListQuery({
    tomId: tomId!,
  }, {
    skip: !tomId || selectedProjectPhase !== 'Инженерные изыскания',
  })
  const { changes: iiAttachmentChangeList, versions: iiAttachmentVersionList } = iiAttachmentFileListData || {}


  // Ird
  const { data: irdAttachmentFileListData } = useGetIrdAttachmentFileListQuery({
    tomId: tomId!,
  }, {
    skip: !tomId || selectedProjectPhase !== 'Сбор исходных данных',
  })
  const { changes: irdAttachmentChangeList, versions: irdAttachmentVersionList } = irdAttachmentFileListData || {}


  // Cmn
  const { data: cmnAttachmentFileListData } = useGetCmnAttachmentFileListQuery({
    tomId: tomId!,
  }, {
    skip: !tomId || selectedProjectPhase !== 'Проектная документация',
  })
  const { changes: cmnAttachmentChangeList, versions: cmnAttachmentVersionList } = cmnAttachmentFileListData || {}


  // Common
  const docListByPhase: Record<ProjectPhase, ExportDocWithVariant[]> = {
    'Рабочая документация': (rdAttachmentChangeList && rdAttachmentVersionList) ? [...rdAttachmentChangeList, ...rdAttachmentVersionList] : [],
    'Проектная документация': (pdAttachmentChangeList && pdAttachmentVersionList) ? [...pdAttachmentChangeList, ...pdAttachmentVersionList] : [],
    'Инженерные изыскания': (iiAttachmentChangeList && iiAttachmentVersionList) ? [...iiAttachmentChangeList, ...iiAttachmentVersionList] : [],
    'Сбор исходных данных': (irdAttachmentChangeList && irdAttachmentVersionList) ? [...irdAttachmentChangeList, ...irdAttachmentVersionList] : [],
  }

  const cmnDocList = (cmnAttachmentChangeList && cmnAttachmentVersionList) ? [...cmnAttachmentChangeList, ...cmnAttachmentVersionList] : []
  const docList = isCmnTom ? cmnDocList : docListByPhase[selectedProjectPhase]

  return {
    docList,
  }
}
