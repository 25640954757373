import React from 'react'

import { GetTomIiLinkExampleResponse, useGetTomIiLinkExampleMutation } from '../../../../api/iiPhase'
import { GetTomIrdLinkExampleResponse, useGetTomIrdLinkExampleMutation } from '../../../../api/irdPhase'
import { GetTomPdLinkExampleResponse, useGetTomPdLinkExampleMutation } from '../../../../api/pdPhase'
import { GetTomRdLinkExampleResponse, useGetTomRdLinkExampleMutation } from '../../../../api/rdPhase'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { useTypedSelector } from '../../../../store/store'
import { ProjectPhase } from '../../../../types/project'
import { UseExampleDownloadProps, UseExampleDownloadResponse } from './useExampleDownload.types'

export const useExampleDownload = ({}: UseExampleDownloadProps): UseExampleDownloadResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  // Rd
  const [getTomRdLinkExample, getTomRdLinkExampleResponse] = useGetTomRdLinkExampleMutation()

  useMutationHandlers(getTomRdLinkExampleResponse, (data: GetTomRdLinkExampleResponse) => {
    window.location.href = data.data
  })

  // Pd
  const [getTomPdLinkExample, getTomPdLinkExampleResponse] = useGetTomPdLinkExampleMutation()

  useMutationHandlers(getTomPdLinkExampleResponse, (data: GetTomPdLinkExampleResponse) => {
    window.location.href = data.data
  })

  // Ii
  const [getTomIiLinkExample, getTomIiLinkExampleResponse] = useGetTomIiLinkExampleMutation()

  useMutationHandlers(getTomIiLinkExampleResponse, (data: GetTomIiLinkExampleResponse) => {
    window.location.href = data.data
  })

  // Ird
  const [getTomIrdLinkExample, getTomIrdLinkExampleResponse] = useGetTomIrdLinkExampleMutation()

  useMutationHandlers(getTomIrdLinkExampleResponse, (data: GetTomIrdLinkExampleResponse) => {
    window.location.href = data.data
  })

  // Common
  const onExampleDownload: Record<ProjectPhase, () => void> = {
    'Рабочая документация': getTomRdLinkExample,
    'Проектная документация': getTomPdLinkExample,
    'Инженерные изыскания': getTomIiLinkExample,
    'Сбор исходных данных': getTomIrdLinkExample,
  }

  return {
    onExampleDownload: onExampleDownload[selectedProjectPhase],
  }
}
