import { Stack, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { MAX_WIDTH_USER_MANAGEMENT_BLOCK, MIN_WIDTH_USER_MANAGEMENT_BLOCK } from '../../../../utils/constants'
import UserRoleSelection from '../../components/UserRoleSelection'
import { AccessStatusBlock } from './styles'
import { theme } from '../../../../styles/theme'
import UserAccessesSelection from '../../components/UserAccessesSelection'
import Button from '../../../Button'
import { UserAccessesProps } from './UserAccesses.types'
import { useFormikContext } from 'formik'
import { UserFormData } from '../UserForm.types'

const UserAccesses: React.FC<UserAccessesProps> = ({
  isEditUser,
}) => {
  const { values: formValues, isSubmitting } = useFormikContext<UserFormData>()
  const role = formValues.role
  const contractor = role === 'contractor'

  const renderAccessesBlock = useCallback(() => {
    switch (role) {
      case 'admin':
        return (
          <UserAccessesSelection>
            <AccessStatusBlock>
              <Typography variant='body1'>Доступ ко всем проектам</Typography>
            </AccessStatusBlock>
          </UserAccessesSelection>
        )
      case 'client':
      case 'architector':
      case 'supervisor':
      case 'contractor':
        return <UserAccessesSelection contractor={contractor} />
      case 'none':
        return (
          <UserAccessesSelection>
            <AccessStatusBlock color={theme.palette.error.main}>
              <Typography variant='body1'>Нет доступа к проектам</Typography>
            </AccessStatusBlock>
          </UserAccessesSelection>
        )
    }
  }, [role])

  return (
    <Stack
      maxWidth={MAX_WIDTH_USER_MANAGEMENT_BLOCK}
      minWidth={MIN_WIDTH_USER_MANAGEMENT_BLOCK}
      flex={1}
      spacing={4.375}
    >
      <UserRoleSelection />
      {renderAccessesBlock()}
      <Button
        type='submit'
        disabled={isSubmitting}
        color='success'
        width='100%'
        style={{ alignSelf: 'end' }}
      >
        {isEditUser ? 'Сохранить' : 'Создать'}
      </Button>
    </Stack>
  )
}

export default UserAccesses