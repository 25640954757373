import React, { ReactNode } from 'react'
import { createFilterOptions, FormControl, Stack, TextField, Typography } from '@mui/material'
import { theme } from '../../styles/theme'
import { StyledSelectMenuItem } from '../UserManagement/components/UserRoleSelection/styles'
import { SelectFormItemProps } from './SelectFormField.types'
import { useField, useFormikContext } from 'formik'
import FieldForm from '../FieldForm'
import { SelectMenuProps } from '../UserManagement/components/UserObjectsSelection'
import { useTranslation } from 'react-i18next'
import { StyledAutocompleteField } from './styles'
import { AutocompleteInputChangeReason } from '@mui/base/AutocompleteUnstyled/useAutocomplete'

interface OptionType {
  inputValue?: string;
  label: string;
  value?: number;
}

const filter = createFilterOptions<OptionType>()

const SelectFormField = <Value extends string | number, Values extends Record<string, any>>({
  title,
  icon: Icon,
  placeholder,
  data,
  fieldName,
  wrapperStyles,
  primary,
  disabled,
  parentOnChange,
  autocompleteProps
}: SelectFormItemProps<Value, Values> & { children?: ReactNode }) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<Values>()

  const [{ onBlur: onFieldBlur, ...field }, { touched, error }] = useField({
    name: fieldName,
  })

  const isError = touched && !!error
  const autocompleteInputValue = data.find(item => item.value === values[fieldName])?.label || values[fieldName] || ''

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Stack direction='row' spacing={1}>
        <Icon fontSize='medium' color='secondary' />
        <Typography variant='body2' component='span'>{title}:</Typography>
      </Stack>


      <FormControl
        style={{ width: '100%' }}
        sx={{
          maxWidth: 200,
          '& .MuiSelect-outlined': {
            fontWeight: primary ? 500 : 400,
            textAlign: primary ? 'center !important' : '',
            color: primary ? `${theme.palette.primary.main} !important` : '',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '16px !important',
          },
          ...wrapperStyles
        }}
      >
        {autocompleteProps?.autocomplete
          ? (
            <StyledAutocompleteField
              {...field}
              value={autocompleteInputValue}
              onChange={(event, newValue: any) => {
                parentOnChange?.(event, newValue)

                if (typeof newValue === 'string') {
                  setFieldValue(fieldName, newValue)
                } else if (newValue && newValue.inputValue) {
                  setFieldValue(fieldName, newValue.inputValue)
                } else if (newValue === null) {
                  setFieldValue(fieldName, '')
                } else {
                  setFieldValue(fieldName, newValue.value)
                }
              }}
              onInputChange={(event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
                if (reason === 'clear') {
                  event.stopPropagation()
                  setFieldValue(fieldName, '')
                }
              }}
              renderInput={params => {
                return autocompleteProps?.permanentValue
                  ? (
                    <Typography variant='body2' fontWeight={500} textAlign='right' paddingRight={2}>
                      {autocompleteInputValue}
                    </Typography>
                  )
                  : (
                    <TextField
                      {...params}
                      name={fieldName}
                      label={placeholder}
                      helperText={isError ? t(error as string) : undefined}
                      onBlur={onFieldBlur}
                      error={isError}
                    />

                  )
              }}
              options={data}
              renderOption={(props, option: any) => {
                return (
                  <StyledSelectMenuItem
                    {...props}
                    className={option.className || ''}
                    key={option.key}
                  >
                    {option.label}
                  </StyledSelectMenuItem>
                )
              }}
              freeSolo={autocompleteProps?.freeSolo}
              filterOptions={(options: any, params) => {
                const filtered = filter(options, params)
                if (!autocompleteProps?.freeSolo) return filtered

                const { inputValue } = params
                const isExisting = options.some((option: any) => option.label.includes(inputValue))
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    label: `Добавить "${inputValue}"`,
                  })
                }

                return filtered
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              noOptionsText='Ничего не найдено'
              clearText='Удалить'
              disabled={disabled}
            />
          )
          : (
            <FieldForm
              version='select'
              name={fieldName}
              value={values[fieldName]}
              onChange={e => {
                parentOnChange?.(e, e.target.value)
                setFieldValue(fieldName, e.target.value)
              }}
              label={placeholder}
              SelectProps={{
                MenuProps: SelectMenuProps
              }}
              disabled={disabled}
            >
              {
                data.map(({ label, value, key }) => (
                  <StyledSelectMenuItem
                    value={value}
                    key={key}
                  >
                    {label}
                  </StyledSelectMenuItem>
                ))
              }
            </FieldForm>
          )
        }
      </FormControl>
    </Stack>
  )
}

export default SelectFormField
