import { UseGetRemoveHandlerProps, UseGetRemoveHandlerResponse } from './useGetRemoveHandler.types'
import React from 'react'
import { useFormikContext } from 'formik'
import { ExportArchiveFormData } from '../../../ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { ProjectPhase } from '../../../../../../../../types/project'
import { useTypedSelector } from '../../../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../../../store/slices/documentsPages/projectInfo'


export const useGetRemoveHandler = ({
  rdTom,
  pdTom,
  iiTom,
  irdTom,
  objectIndex,
  rdIndex,
  pdIndex,
  iiIndex,
  irdIndex,
  tomIndex,
}: UseGetRemoveHandlerProps): UseGetRemoveHandlerResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { values: formValues, setFieldValue } = useFormikContext<ExportArchiveFormData>()

  const { tomId } = rdTom || pdTom || iiTom || irdTom || {}

  // Rd
  const onRdRemoveClick = () => {
    setFieldValue(`rdFileList[${objectIndex!}].rdList[${rdIndex}].tomList[${tomIndex}].selected`, false)
    setFieldValue(`rdFileList[${objectIndex!}].rdList[${rdIndex}].selected`, false)
    setFieldValue(`rdFileList[${objectIndex!}].selected`, false)

    const selectedTomsCopy = [...formValues.selectedRdToms]
    const removedTomIndex = selectedTomsCopy.findIndex(tomData => tomData.tom.tomId === tomId)
    selectedTomsCopy.splice(removedTomIndex, 1)

    setFieldValue(`selectedRdToms`, selectedTomsCopy)
  }


  // Pd
  const onPdRemoveClick = () => {
    setFieldValue(`pdFileList[${pdIndex!}].tomList[${tomIndex}].selected`, false)
    setFieldValue(`pdFileList[${pdIndex!}].selected`, false)

    const selectedTomsCopy = [...formValues.selectedPdToms]
    const removedTomIndex = selectedTomsCopy.findIndex(tomData => tomData.tom.tomId === tomId)
    selectedTomsCopy.splice(removedTomIndex, 1)

    setFieldValue(`selectedPdToms`, selectedTomsCopy)
  }


  // Ii
  const onIiRemoveClick = () => {
    setFieldValue(`iiFileList[${iiIndex!}].tomList[${tomIndex}].selected`, false)
    setFieldValue(`iiFileList[${iiIndex!}].selected`, false)

    const selectedTomsCopy = [...formValues.selectedIiToms]
    const removedTomIndex = selectedTomsCopy.findIndex(tomData => tomData.tom.tomId === tomId)
    selectedTomsCopy.splice(removedTomIndex, 1)

    setFieldValue(`selectedIiToms`, selectedTomsCopy)
  }


  // Ird
  const onIrdRemoveClick = () => {
    setFieldValue(`irdFileList[${irdIndex!}].tomList[${tomIndex}].selected`, false)
    setFieldValue(`irdFileList[${irdIndex!}].selected`, false)

    const selectedTomsCopy = [...formValues.selectedIrdToms]
    const removedTomIndex = selectedTomsCopy.findIndex(tomData => tomData.tom.tomId === tomId)
    selectedTomsCopy.splice(removedTomIndex, 1)

    setFieldValue(`selectedIrdToms`, selectedTomsCopy)
  }


  // Common
  const onRemoveClick: Record<ProjectPhase, () => void> = {
    'Рабочая документация': onRdRemoveClick,
    'Проектная документация': onPdRemoveClick,
    'Инженерные изыскания': onIiRemoveClick,
    'Сбор исходных данных': onIrdRemoveClick,
  }

  return {
    onRemoveClick: onRemoveClick[selectedProjectPhase],
  }
}
