import React, { useCallback } from 'react'
import { AdminNavBarItemProps } from './AdminNavBarItem.types'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { ListItemIcon, ListItemText } from '@mui/material'
import { StyledListItem } from './styles'
import useConfirmDialog from 'hooks/useConfirmDialog'

class NavBarValue {
  url: string = ''
  selected: boolean = false
}

const AdminNavBarItem: React.FC<AdminNavBarItemProps> = ({
  label,
  icon: Icon,
  link,
  selectedIcon: SelectedIcon,
  onItemClick,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const value = new NavBarValue()
  value.url = link
  value.selected = matchPath(location.pathname, value.url) !== null

  // const onItemClick = () => {
  //   // if (openedConfirm) openConfirm()
  //   // else navigate(value.url)
  //   openConfirm()
  // }

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) navigate(value.url)
  }, [])

  const { ConfirmDialog, openConfirm } = useConfirmDialog({ handleConfirm })

  const listItem = (
    <StyledListItem onClick={() => onItemClick(value.url)} selected={value.selected}>
      <ListItemIcon>
        {value.selected && SelectedIcon ? <SelectedIcon height='24' width='24' /> : <Icon height='24' width='24' />}
      </ListItemIcon>
      <ListItemText primary={label} />

      <ConfirmDialog />
    </StyledListItem>
  )

  return listItem
}

export default AdminNavBarItem
