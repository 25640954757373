import { Box, Tab } from '@mui/material'
import React, { ReactNode } from 'react'
import { TabsEntities, TabsProps } from './Tabs.types'
import { StyledTabs } from './Tabs.styles'

const Tabs = <T extends TabsEntities>({
  currentTab,
  onTabChange,
  tabsData,
  countPagination
}: TabsProps<T> & { children?: ReactNode }) => {
  return (
    <Box>
      <StyledTabs value={currentTab} onChange={onTabChange} $countPagination={countPagination}>
        {tabsData.map(({ value, label, disabled }) => (
          <Tab label={label} value={value} sx={{ p: 1 }} key={value} disabled={disabled}/>
        ))}
      </StyledTabs>
    </Box>
  )
}

export default Tabs