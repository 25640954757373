import { Avatar, Box, Stack, TextField } from "@mui/material"
import { LegendText, SearchEmployeesAutocomplete, StyledCheckbox, StyledLegend, StyledListItem, SubTitleText, TitleText } from "./SearchEmployees.styles"
import { SearchEmployeesProps } from "./SearchEmployees.types"
import { useEffect, useMemo, useState } from "react"
import { PersonInfo } from "../../../../../../../api/tomCommonApi/types"
import { OpenButton } from "../Companies";
import { createFilterOptions } from "@mui/material/Autocomplete";

const addGroupList = (list: PersonInfo[]) => {
  return [{ type: '<3', id: 0 }, ...list]
}

export const SearchEmployees = ({
  tomAccessList,
  isSelectedCompany,
  isSelectedEmployee,
  setIsSelectedEmployee,
  changeStatus
}: SearchEmployeesProps) => {

  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)

  const closedEmployees = useMemo((): PersonInfo[] => {
    if (!tomAccessList) return []
    const filteredEmployees = tomAccessList
      .flatMap((item) => item.employees)
      .filter((employee) => employee.status === 'close')
      .map((employee) => employee.person)

    if (isSelectedCompany) {
      return filteredEmployees.filter(person => person.company === isSelectedCompany.company)
    }
    return filteredEmployees

  }, [tomAccessList, isSelectedCompany])

  const _filterOptions = createFilterOptions()
  const filterOptions = (options: any, state: any) => {
    const results = _filterOptions(options, state)
    const defaultOption = { type: '<3', id: 0 }

    if (!results.includes(defaultOption) && inputValue && results.length > 0) {
      results.unshift(defaultOption)
    }
    return results
  }

  useEffect(() => {
    setOpen(false)
  }, [tomAccessList])

  return (
    <SearchEmployeesAutocomplete
      multiple
      disableCloseOnSelect
      freeSolo
      noOptionsText='Ничего не найдено'
      ListboxProps={{ style: { padding: 0 } }}
      clearText='Очистить выбранные'
      openText="Выбрать"
      filterOptions={filterOptions}
      getOptionLabel={(option: any) => option.type ? '' : option.name}
      renderTags={() => null}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderOption={
        (props, option: any, { selected }) => (
          <StyledListItem key={option.id} >
            {option.type ? (
              <StyledLegend direction='row'>
                <LegendText>Выбрано: {isSelectedEmployee.length}</LegendText>
                {isSelectedEmployee.length > 0 &&
                  <OpenButton onClick={() => changeStatus('open')}>Открыть</OpenButton>}
              </StyledLegend>
            ) : (
              <Box component='li' {...props}>
                <StyledCheckbox
                  disableRipple
                  checked={selected}
                />
                <Avatar src={option.avatar} sx={{ width: "32px", height: "32px", marginRight: "16px" }} />
                <Stack>
                  <TitleText variant="body2" >{option.name}</TitleText>
                  <SubTitleText >{option.position}</SubTitleText>
                </Stack>
              </Box>
            )}
          </StyledListItem>
        )}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
      value={isSelectedEmployee}
      onChange={(e, newValue: any) => {
        setIsSelectedEmployee(newValue)
        setInputValue(inputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Выберите пользователей из списка"
        />
      )}
      options={addGroupList(closedEmployees)}
    />
  )
}