import { RemarkStatus } from "../../api/remarks/types";
import { RemarkStatusRu } from "../../pages/DocView/components/DocViewRightSideBar/components/Remark";

export type RemarkStatusLabelsRu = RemarkStatusRu | "Все статусы"
export type RemarkStatusLabelsEn = RemarkStatus | 'all'

export interface RemarkStatusLabelsRuToEn extends Record<RemarkStatusLabelsRu, RemarkStatusLabelsEn> { }
export const remarkStatusLabelsRuToEn: RemarkStatusLabelsRuToEn = {
  'Направлено': 'submitted',
  'Закрыто': 'closed',
  'В работе': 'accepted',
  'Отклонено': 'rejected',
  'Снято': 'cancelled',
  'Создано': 'created',
  'Устранено': 'resolved',
  'Все статусы': 'all'
}

export interface RemarkStatusLabelsEnToRu extends Record<RemarkStatusLabelsEn, RemarkStatusLabelsRu> { }
export const remarkStatusLabelsEnToRu: RemarkStatusLabelsEnToRu = {
  'submitted': 'Направлено',
  'closed': 'Закрыто',
  'accepted': 'В работе',
  'rejected': 'Отклонено',
  'cancelled': 'Снято',
  'created': 'Создано',
  'resolved': 'Устранено',
  'all': 'Все статусы'
}