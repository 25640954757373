import React, { FC } from 'react'
import { RowItemProps } from './RowItem.types'
import { Box, Stack, Typography } from '@mui/material'
import { theme } from '../../../../../../styles/theme'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import Tooltip from '../../../../../../components/Tooltip'

export const RowItem: FC<RowItemProps> = ({
  title,
  rowStrings,
  emptyDataText = 'Нет данных',
}) => {
  return (
    <Stack flex={1} width='100%'>
      <Box>
        <Typography
          variant='subtitle2'
          fontWeight={400}
          fontSize={12}
          letterSpacing={0}
          textAlign='left'

          color={theme.palette.secondary.gray}
        >
          {title}:
        </Typography>
      </Box>
      <Stack
        //maxHeight={2 * 20} //20-lineHeight
        component={ScrollableContainer}
      >
        {rowStrings.some(string => string !== '')
          ? rowStrings.map(row => row && (
            <Tooltip title={row.length > 35 ? row : ''} variant='light' fontSize={14} arrow>
              <Typography
                overflow='hidden'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
                variant='body2'
                textAlign='left'
                color={theme.palette.text.dark}
              >
                {row}
              </Typography>
            </Tooltip>
          ))
          : (
            <Box height={20}>
              <Typography
                variant='body2'
                textAlign='left'
                color={theme.palette.disabled}
              >
                {emptyDataText}
              </Typography>
            </Box>
          )
        }
      </Stack>
    </Stack>
  )
}
