import { Box, Stack, Typography } from '@mui/material'
import {
  CellClickedEvent,
  ColDef,
  ColumnResizedEvent,
  GridReadyEvent,
  ICellRendererParams,
  RowDataUpdatedEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useDeleteRemarkMutation } from '../../../../api/remarks'
import { RemarkInfo, RemarkMessage } from '../../../../api/remarks/types'
import { OverflowText } from '../../../../components/OverflowText/OverflowText'
import Progress from '../../../../components/Progress'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'
import { setOpenedDrawer } from '../../../../store/slices/documentsPages/drawerInfo'
import { profileSelector } from '../../../../store/slices/profile'
import { setAllowScrolling, setRemarkFormData, setRemarkFormMode } from '../../../../store/slices/remarks/remarks'
import { RemarkFormData } from '../../../../store/slices/remarks/remarks.types'
import { allowScrollingSelector } from '../../../../store/slices/remarks/selectors/remarks.selectors'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { StyledAgGridWrapper } from '../../../../styles/global/StyledAgGridWrapper'
import { convertDateTime } from '../../../../utils/formatDateAndTimeGMT'
import {
  RemarkMenuTitle,
  StatusLabel,
  remarkColorStatus,
  remarkStatusEnToRu,
} from '../../../DocView/components/DocViewRightSideBar/components/Remark'
import {
  remarkMenuList,
  titleStatus,
} from '../../../DocView/components/DocViewRightSideBar/components/Remark/Remark.config'
import { RemarkMenu } from '../../../DocView/components/DocViewRightSideBar/components/RemarkMenu'
import { HistoryButton } from '../HistoryButtonCell/HistoryButtonCell'
import { ElipsisText, InfoText, StyledLabel } from './RemarksTable.styles'
import { MenuParams, RemarkParams, RemarkTableProps, ReplyParams, RowsData, TomInfoParams } from './RemarksTable.types'

import 'ag-grid-community/styles/ag-theme-alpine.css'

const DEFAULT_ROW_HEIGHT = 110

const AuthorInfo = (company: string | null, person: string, createdAt: string) => {
  return (
    <Stack direction='row' spacing={2} flex={1} alignItems='flex-end'>
      <Box display='inline-flex' maxWidth='calc(33% - 16px)'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
          <path
            d='M13.9998 12.6667H15.3332V14H0.666504V12.6667H1.99984V2.66667C1.99984 2.48986 2.07008 2.32029 2.1951 2.19526C2.32012 2.07024 2.48969 2 2.6665 2H9.33317C9.50998 2 9.67955 2.07024 9.80457 2.19526C9.9296 2.32029 9.99984 2.48986 9.99984 2.66667V12.6667H11.3332V6H13.3332C13.51 6 13.6796 6.07024 13.8046 6.19526C13.9296 6.32029 13.9998 6.48986 13.9998 6.66667V12.6667ZM4.6665 7.33333V8.66667H7.33317V7.33333H4.6665ZM4.6665 4.66667V6H7.33317V4.66667H4.6665Z'
            fill='#5C6E8C'
          />
        </svg>
        <InfoText ml={1}>{company}</InfoText>
      </Box>
      <Box display='inline-flex' maxWidth='calc(33% - 16px)'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M11.3013 4.51531C11.3013 6.27258 9.891 7.69712 8.1513 7.69712C6.4116 7.69712 5.0013 6.27258 5.0013 4.51531C5.0013 2.75804 6.4116 1.3335 8.1513 1.3335C9.891 1.3335 11.3013 2.75804 11.3013 4.51531ZM8 9.51532C11.3265 9.51532 14 10.9706 14 12.7608V14.6668H2V12.7608C2 10.9706 4.67346 9.51532 8 9.51532Z'
            fill='#5C6E8C'
          />
        </svg>
        <InfoText ml={1}>{person}</InfoText>
      </Box>
      <Box display='inline-flex' maxWidth='calc(33% - 16px)'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
          <path
            d='M6 6.6665V7.99984H4.66667V6.6665H6ZM8.66667 6.6665V7.99984H7.33333V6.6665H8.66667ZM11.3333 6.6665V7.99984H10V6.6665H11.3333ZM12.6667 1.99984C13.0203 1.99984 13.3594 2.14031 13.6095 2.39036C13.8595 2.64041 14 2.97955 14 3.33317V12.6665C14 13.0201 13.8595 13.3593 13.6095 13.6093C13.3594 13.8594 13.0203 13.9998 12.6667 13.9998H3.33333C2.97971 13.9998 2.64057 13.8594 2.39052 13.6093C2.14048 13.3593 2 13.0201 2 12.6665V3.33317C2 2.97955 2.14048 2.64041 2.39052 2.39036C2.64057 2.14031 2.97971 1.99984 3.33333 1.99984H4V0.666504H5.33333V1.99984H10.6667V0.666504H12V1.99984H12.6667ZM12.6667 12.6665V5.33317H3.33333V12.6665H12.6667ZM6 9.33317V10.6665H4.66667V9.33317H6ZM8.66667 9.33317V10.6665H7.33333V9.33317H8.66667ZM11.3333 9.33317V10.6665H10V9.33317H11.3333Z'
            fill='#5C6E8C'
          />
        </svg>
        <InfoText ml={1}>{convertDateTime(createdAt, false, true)}</InfoText>
      </Box>
    </Stack>
  )
}

const TomInfoCell = (params: ICellRendererParams) => {
  const { shifr, list, version, change }: TomInfoParams = params.value
  return (
    <Box px={2} textAlign='start' width='inherit'>
      <ElipsisText fontWeight={500} fontSize={14}>{`Том: ${shifr}`}</ElipsisText>
      <Typography fontSize={12}>{`Версия: ${version !== null ? version : '—'}`}</Typography>
      {change && <Typography fontSize={12}>{`Изменение: ${change}`}</Typography>}
      <Typography fontSize={12}>{`Лист: ${list || '—'}`}</Typography>
    </Box>
  )
}

const ReplyCell = (params: ICellRendererParams) => {
  const { text, company, person, createdAt }: ReplyParams = params.value || {}
  if (!params.value)
    return (
      <Stack width='100%' alignItems='center' justifyContent='center'>
        <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
          <path d='M13.1668 8.66683H3.8335V7.3335H13.1668V8.66683Z' fill='#5C6E8C' />
        </svg>
      </Stack>
    )
  return (
    <Stack px={2} py={1} width='100%'>
      <OverflowText mode='table' text={text} defaultHeight={42} marginTop={3} marginBottom={1} />
      {AuthorInfo(company, person, createdAt)}
    </Stack>
  )
}

const RemarkCell = (params: ICellRendererParams) => {
  const { status, isSecond, text, company, person, createdAt }: RemarkParams = params.value
  return (
    <Stack px={2} py={1} width='100%'>
      <Stack direction='row' spacing={1}>
        <StyledLabel direction='row' spacing={1}>
          <StatusLabel color={remarkColorStatus[status]} />
          <Typography fontSize={12} fontWeight={500}>
            {remarkStatusEnToRu[status]}
          </Typography>
        </StyledLabel>
        {isSecond && (
          <StyledLabel justifyContent='flex-end'>
            <Typography variant='subtitle2' fontSize={12}>
              Повторное
            </Typography>
          </StyledLabel>
        )}
      </Stack>
      <OverflowText mode='table' text={text} defaultHeight={42} marginBottom={1} />
      {AuthorInfo(company, person, createdAt)}
    </Stack>
  )
}

const MenuButton = (params: ICellRendererParams) => {
  const { remarkMenu, isShow }: MenuParams = params.value
  return (
    <RemarkMenu
      position='static'
      data={remarkMenu}
      title='Управление замечанием'
      anchorParentElement={true}
      disabled={!remarkMenu.length || !isShow}
    />
  )
}

const flexStartClass = 'ag-cell_flex_column ag-cell_justify-content_center ag-cell_align-items_start'
const flexCenterClass = 'ag-cell_flex_column ag-cell_justify-content_center ag-cell_align-items_center'

export const RemarksTable = ({ remarks }: RemarkTableProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useTypedSelector(profileSelector)
  const isAdmin = profile.role === 'admin'
  const gridRef = useRef<AgGridReact<RowsData> | null>(null)
  const { allowScrolling, mode } = useTypedSelector(allowScrollingSelector)
  const isSavedColumnState = !!localStorage.getItem('remarkColumnState')

  const [deleteRemark, deleteRemarkResponse] = useDeleteRemarkMutation()

  const confirmDeleteRemark = (confirm: boolean, id: number) => {
    if (confirm) {
      deleteRemark({ projectId, remarkId: id })
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmDeleteRemark,
    body: <>Замечание будет удалено без возможности восстановить</>,
    title: 'Удалить замечание?',
    denyButtonText: 'Отменить',
    confirmButtonText: 'Удалить',
    confirmButtonColor: 'error',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onEditRemark = (remark: RemarkInfo, message: RemarkMessage) => {
    dispatch(
      setRemarkFormData({
        remarkFormData: {
          change: remark.change,
          page: remark.page,
          totalPages: remark.totalPages,
          comment: message.comment,
          reason: message.reason,
          type: remark.type,
          version: message.version,
          remarkId: remark.id,
        } as RemarkFormData,
      }),
    )
    dispatch(setRemarkFormMode({ remarkFormMode: 'edit' }))
    dispatch(setOpenedDrawer({ openedDrawer: 'remarks' }))
  }

  const executeMenuFunction = useCallback(
    (status: RemarkMenuTitle, remark: RemarkInfo, message: RemarkMessage) => {
      let callback: (() => void) | undefined

      switch (status) {
        case 'Удалить замечание':
          callback = () => openConfirm(remark.id)
          break

        case 'Редактировать замечание':
          callback = () => onEditRemark(remark, message)
          break
      }
      return callback
    },
    [remarks],
  )

  const [columnDef, setColumnDef] = useState<ColDef[]>([
    {
      field: 'index',
      headerName: '№',
      flex: !isSavedColumnState ? 1 : undefined,
      minWidth: 56,
      cellClass: [flexCenterClass, 'font-size_12'],
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
    },
    {
      field: 'tomName',
      headerName: 'О томе',
      flex: !isSavedColumnState ? 3 : undefined,
      minWidth: 145,
      cellRenderer: TomInfoCell,
      cellClass: [flexStartClass],
    },
    {
      field: 'remark',
      headerName: 'Замечание',
      flex: !isSavedColumnState ? 10 : undefined,
      minWidth: 322,
      cellRenderer: RemarkCell,
      cellClass: [flexStartClass, 'ag-cell_overflow_visible'],
    },
    {
      field: 'reply',
      headerName: 'Устранение замечания',
      flex: !isSavedColumnState ? 10 : undefined,
      minWidth: 322,
      cellRenderer: ReplyCell,
      cellClass: [flexStartClass, 'ag-cell_overflow_visible'],
    },
    {
      field: 'history',
      headerName: 'История',
      flex: !isSavedColumnState ? 2 : undefined,
      minWidth: 61,
      cellRenderer: HistoryButton,
      cellClass: [flexCenterClass],
    },
    {
      field: 'menu',
      headerName: '',
      flex: !isSavedColumnState ? 2 : undefined,
      cellRenderer: MenuButton,
      cellClass: [flexCenterClass],
    },
  ])

  const rowData = useMemo((): RowsData[] => {
    if (!remarks) return []
    return remarks.map(({ remark, remarkMsg, responseMsg, tom }, index) => {
      const isAuthor = profile.employeeId === remark.author.id
      return {
        index: index + 1,
        tomName: {
          id: tom.id,
          shifr: tom.title,
          version: tom.version, // или из remarkMsg.version ?
          change: remark.change,
          list: remark.page,
        },
        remark: {
          remarkId: remark.id,
          status: remark.status,
          isSecond: remark.isSecond,
          text: remarkMsg.comment,
          company: remark.author.company,
          person: remark.author.name,
          createdAt: remark.createdAt,
        },
        reply: responseMsg
          ? {
              text: responseMsg.comment,
              company: responseMsg.author.company,
              person: responseMsg.author.name,
              createdAt: responseMsg.createdAt,
            }
          : undefined,
        menu: {
          remarkMenu: remarkMenuList(isAdmin, remark.isSecond)[remark.status]?.map((item) => ({
            itemName: titleStatus[item],
            action: executeMenuFunction(item, remark, remarkMsg),
          })),
          isShow: isAdmin || isAuthor,
        },
      }
    })
  }, [remarks])

  const cellClickedListener = (e: CellClickedEvent) => {
    const notAllowedCell = e.colDef.field === 'history' || e.colDef.field === 'menu'
    if (notAllowedCell) return
    navigate(`/project/${projectId}/tom/${e.data.tomName.id}?tab=remarks`)
  }

  const columnResizedListener = useCallback((e: ColumnResizedEvent) => {
    const columnState = e.columnApi.getColumnState()
    const withoutSortState = columnState.map(({ sort, ...state }) => state)
    const debounceSave = setTimeout(() => {
      localStorage.setItem('remarkColumnState', JSON.stringify(withoutSortState))
    }, 700)

    return () => {
      clearTimeout(debounceSave)
    }
  }, [])

  const gridReadyListener = useCallback((e: GridReadyEvent) => {
    dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: false, mode: 'create' } }))
    const savedColumnState = localStorage.getItem('remarkColumnState')
    if (savedColumnState) {
      e.columnApi.applyColumnState({
        state: JSON.parse(savedColumnState),
        applyOrder: true,
      })
    }
  }, [])

  useEffect(() => {
    if (deleteRemarkResponse.isSuccess) {
      enqueueSnackbar('Замечание успешно удалено.', { variant: 'success' })
    }
  }, [deleteRemarkResponse])

  const onRowDataUpdatedListener = (e: RowDataUpdatedEvent) => {
    const isSorted = e.columnApi.getColumnState()[0].sort === 'desc'
    const lastRenderedRow = e.api.getDisplayedRowCount()
    const lastFocucedCell = e.api.getFocusedCell()?.rowIndex
    const editedCell = lastFocucedCell === 0 ? lastFocucedCell + 1 : lastFocucedCell
    const editedRowNode = e.api.getDisplayedRowAtIndex(lastFocucedCell!)!
    const firstRow = e.api.getFirstDisplayedRow()
    const createdRowNode = e.api.getDisplayedRowAtIndex(isSorted ? firstRow : lastRenderedRow - 1)!

    if (lastRenderedRow && allowScrolling) {
      e.api.showLoadingOverlay()
      setTimeout(() => {
        if (mode === 'edit' && editedCell) {
          e.api.ensureIndexVisible(editedCell, 'middle')
          e.api.flashCells({ rowNodes: [editedRowNode], flashDelay: 3000, fadeDelay: 1000 })
        } else {
          if (isSorted) {
            e.api.flashCells({ rowNodes: [createdRowNode], flashDelay: 3000, fadeDelay: 1000 })
          } else {
            e.api.ensureIndexVisible(lastRenderedRow - 1, 'bottom')
            e.api.flashCells({ rowNodes: [createdRowNode], flashDelay: 3000, fadeDelay: 1000 })
          }
        }
      }, 250)
      setTimeout(() => {
        e.api.hideOverlay()
        dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: false, mode: 'create' } }))
      }, 500)
    }
  }

  return (
    <>
      <StyledAgGridWrapper style={{ marginTop: '20px' }}>
        <div className='ag-theme-alpine'>
          <AgGridReact
            ref={gridRef}
            onCellClicked={cellClickedListener}
            onGridReady={gridReadyListener}
            overlayNoRowsTemplate='Нет данных для отображения'
            onColumnResized={columnResizedListener}
            onRowDataUpdated={onRowDataUpdatedListener}
            loadingOverlayComponent={Progress}
            columnDefs={columnDef}
            rowData={rowData}
            headerHeight={32}
            detailRowAutoHeight
            rowHeight={DEFAULT_ROW_HEIGHT}
            suppressRowTransform={true}
            animateRows={true}
            suppressDragLeaveHidesColumns={true}
            defaultColDef={{
              cellDataType: false,
              editable: false,
              sortable: false,
              filter: false,
              resizable: true,
            }}
          />
        </div>
      </StyledAgGridWrapper>
      <ConfirmDialog />
    </>
  )
}
