import { Stack } from '@mui/material'
import styled from 'styled-components'

interface IChangeSignWrapperProps {
  bgColor: string
}

export const ChangeSignWrapper = styled(Stack)<IChangeSignWrapperProps>`
  padding: 10px;
  background-color: ${(props) => props.bgColor};
  border-radius: 6px;
  gap: 10px;
`

export const TextButton = styled.button`
  background-color: transparent;
  border: none;
  width: fit-content;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`
