import * as yup from 'yup'

import { checkIfFilesAreCorrectType } from '../../../../components/UploadDrawer/UploadDrawerForm/validation'

const validateChange = (value: number, isDocChange: boolean) => {
  return isDocChange ? !!value : true
}

export const getTomVersionValidation = (formats: string[], isDocChange: boolean) => {
  return yup.object({
    files: yup
      .mixed()
      .required('Необходимо загрузить файл')
      .test('is-correct-format', 'Неверный формат файла', (value) => checkIfFilesAreCorrectType(value, formats, true)),
    change: yup
      .number()
      .min(0)
      .not([''], 'common:errors.required')
      .test('is-required', 'common:errors.required', (value) => validateChange(value, isDocChange)),
  })
}
