import { SideBarWrapper } from "./StampRightSideBar.styles"
import { CustomStamp } from "./config"
import { format as FnsFormat } from 'date-fns'

const currentDate = FnsFormat(new Date(), 'dd.MM.yyyy')

export const StampRightSideBar = () => {
  
  const agreedStamp = CustomStamp({
    date: currentDate,
    type: "Согласовано",
  })

  const approvedStamp = CustomStamp({
    date: currentDate,
    type: "Утверждено",
  })

  const inProdStamp = CustomStamp({
    date: currentDate,
    type: "В производство работ",
  })

  return (
    <SideBarWrapper direction='column' spacing={2.5}>
      <img src={agreedStamp} alt="Штамп Согласовано" />
      <img src={approvedStamp} alt="Штамп Утверждено" />
      <img src={inProdStamp} alt="Штамп В производство работ" />
    </SideBarWrapper>
  )
}