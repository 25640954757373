import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { AttachmentsDrawerProps, AttachmentsFormValues } from './AttachmentsDrawer.types'
import UploadDrawer from '../../../../components/UploadDrawer'
import { SelectItem } from '../../../../components/FilterSelectMulti/FilterSelectMultiple.types'
import { AttachmentsDrawerSelect, AttachmentsDrawerSelectMenuItem } from './AttachmentsDrawer.styles'
import { attachmentsApi } from '../../../../api/attachments'
import { AttachmentUploadResponse } from '../../../../api/attachments/types'
import { useUploadAttachments } from './hooks/useUploadAttachments'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { onDrawerClose, openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import { currentDocumentSelector, docsSelector } from '../../../../store/slices/documentsPages/tom'
import { useGetTomStoreUpdateHandlers } from './hooks/useGetTomStoreUpdateHandlers'
import { convertDateTime } from '../../../../utils/formatDateAndTimeGMT'

export const AttachmentsDrawer: FC<AttachmentsDrawerProps> = () => {
  const dispatch = useAppDispatch()

  const { tomVersions, tomChanges } = useTypedSelector(docsSelector)
  const { currentDocument } = useTypedSelector(currentDocumentSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)

  const [values, setValues] = useState<AttachmentsFormValues>({ files: [], docId: currentDocument.docId })
  const [responseData, setResponseData] = useState<AttachmentUploadResponse | undefined>(undefined)

  const onAttachmentUpload = (data: AttachmentUploadResponse) => {
    setResponseData(data)
  }

  const { attachmentsLoading } = useUploadAttachments({ values, onAttachmentUpload })

  const onInnerFormSubmit = useCallback((files: File[]) => {
    setValues((prevState) => ({ ...prevState, files }))
  }, [])

  useEffect(() => {
    setValues((prevState) => ({ ...prevState, docId: currentDocument.docId }))
  }, [currentDocument])

  const onFormChange = useCallback(() => {
    setResponseData(undefined)
  }, [])

  const docsSelectItems: SelectItem<number>[] = useMemo(
    () => [
      ...tomChanges.map(({ doc }) => {
        const { docId, change, createdAt } = doc

        return {
          title: `Файлы к изменению ${change} от ${convertDateTime(createdAt)}`,
          value: docId,
        }
      }),
      ...tomVersions.map(({ docId, version, createdAt }) => ({
        title: `Файлы к версии ${version} от ${convertDateTime(createdAt)}`,
        value: docId,
      })),
    ],
    [tomVersions, tomChanges],
  )

  const onSelectChange = (docId: number) => {
    setValues((prevState) => ({ ...prevState, docId }))
  }

  const { updateFullTomStore } = useGetTomStoreUpdateHandlers()

  const onAttachmentsDrawerClose = (dirty: boolean, immediately?: boolean) => {
    dispatch(onDrawerClose({ dirty, immediately }))

    if (responseData) {
      dispatch(attachmentsApi.util.invalidateTags([{ type: 'Attachment', id: 'LIST' }]))
      updateFullTomStore()
    }
  }

  return (
    <UploadDrawer
      open={openedDrawer === 'attachments'}
      mode='multi'
      onSubmit={onInnerFormSubmit}
      responseData={responseData}
      onChange={onFormChange}
      onClose={onAttachmentsDrawerClose}
      title='Загрузка файлов'
      text='Вы можете загрузить сразу несколько файлов в систему, они добавятся в папку с дополнением к выбранной
       версии или изменению.'
      attentionText='Для успешной загрузки размер одного файла не может превышать 256 МБ,
       а количество одновременно добавляемых файлов не может быть больше 50-и.'
      uploadData={{
        title: 'Загрузить файлы',
        text: 'Чтобы загрузить файлы, выберите их из папки на компьютере или просто перетяните его в эту область.',
        formats: [],
      }}
      loading={attachmentsLoading}
      ContentBeforeFileUpload={
        <AttachmentsDrawerSelect
          select
          value={values.docId}
          onChange={(e) => onSelectChange(e.target.value as unknown as number)}
        >
          {docsSelectItems.map(({ value, title }) => (
            <AttachmentsDrawerSelectMenuItem value={value} key={value}>
              {title}
            </AttachmentsDrawerSelectMenuItem>
          ))}
        </AttachmentsDrawerSelect>
      }
    />
  )
}
