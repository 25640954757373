import { MouseEvent, useEffect, useState } from 'react'

import { StageMember } from '@api/agreementOverview/types'

import { OverflowTextNew } from '@components/OverflowTextNew'

import {
  AgreementOverviewSchemaProps,
  InnermapContainer,
  InnermapStep,
  RoadmapStep,
  SchemaContainer,
  SchemaWrapper,
  SelectedStep,
  roadmapStatusToColor,
} from '.'

export const AgreementOverviewSchema = ({ stages, onClickInnermapStage }: AgreementOverviewSchemaProps) => {
  const [selected, setSelected] = useState<number | null>(null)
  const [appearClass, setAppearClass] = useState(false)

  const handleClickInnermapStage = (e: MouseEvent, members: StageMember[]) => {
    e.stopPropagation()
    onClickInnermapStage(members)
  }

  useEffect(() => {
    setAppearClass(true)
  }, [selected])

  const onChangeSelect = (value: number | null) => {
    setAppearClass(false)
    setSelected(value)
  }

  useEffect(() => {
    const currentStage = stages.find((stage) => stage.isCurrent)
    if (currentStage) setSelected(currentStage.num)
  }, [stages])

  return (
    <SchemaWrapper>
      <SchemaContainer direction='row' spacing={1.5}>
        {stages.map((stage) =>
          stage.num === selected ? (
            <SelectedStep
              key={stage.num}
              color={stage.isCurrent ? 'blue' : roadmapStatusToColor[stage.status]}
              className={appearClass ? 'appear' : ''}
              onClick={() => onChangeSelect(null)}
            >
              <OverflowTextNew color='#fff' fontSize={14} rows={2} lineHeight='normal'>
                {stage.num + '. ' + stage.name}
              </OverflowTextNew>
              <InnermapContainer spacing={1.5} direction='row' onClick={(e) => e.stopPropagation()}>
                {stage.innermap !== null ? (
                  stage.innermap.members.map((innermap, index, members) => (
                    <InnermapStep
                      key={innermap.num}
                      color={roadmapStatusToColor[innermap.status]}
                      onClick={(e) => handleClickInnermapStage(e, members)}
                      className={appearClass ? 'appear' : ''}
                    >
                      {innermap.num}
                    </InnermapStep>
                  ))
                ) : (
                  <InnermapStep
                    style={{ cursor: 'default' }}
                    color={roadmapStatusToColor[stage.status]}
                    className={appearClass ? 'appear' : ''}
                  >
                    О
                  </InnermapStep>
                )}
              </InnermapContainer>
            </SelectedStep>
          ) : (
            <RoadmapStep
              key={stage.num}
              color={stage.isCurrent ? 'blue' : roadmapStatusToColor[stage.status]}
              onClick={() => onChangeSelect(stage.num)}
            >
              {stage.num}
            </RoadmapStep>
          ),
        )}
      </SchemaContainer>
    </SchemaWrapper>
  )
}
