import { useFormikContext } from "formik";
import { useCallback, useEffect } from "react";
import { ProjectFormData } from "../ProjectManagement.types";
import { EmployeeShortProfile } from "../../../../api/users/types";

interface SettingFormikValues {
  employeeValue?: string | number
  companyValue?: string
  employees: EmployeeShortProfile[]
  fieldNameCompany: string
  fieldNameEmployee: string
}

export const useSettingFormikValues = ({
  employeeValue,
  companyValue,
  employees,
  fieldNameCompany,
  fieldNameEmployee
}: SettingFormikValues) => {

  const { setFieldValue } = useFormikContext<ProjectFormData>()

  const employeesByCompany = useCallback((employees: EmployeeShortProfile[], company: string | undefined) => {
    return employees.filter(employee => company ? employee.company === company : employee)
  }, [])

  // Подстановка значения компании, если сотрудник выбран
  useEffect(() => {
    if (typeof employeeValue === 'number') {
      const foundPerson = employees.find(person => person.id === employeeValue);
      if (foundPerson) {
        setFieldValue(fieldNameCompany, foundPerson.company);
      }
    }
  }, [employeeValue]);

  // Проверка принадлежности сотрудника к выбранной компании
  useEffect(() => {
    const existingPerson = employees.find(person => person.id === employeeValue)
    if (existingPerson && existingPerson.company !== companyValue) {
      setFieldValue(fieldNameEmployee, existingPerson.name)
    }
  }, [companyValue]);

  return {
    employeesByCompany: employeesByCompany(employees, companyValue)
  }
}