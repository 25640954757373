import React, { FC, MouseEvent, useCallback } from 'react'
import { AboutTabProps, MapHeight } from './AboutTab.types'
import { Button, Stack } from '@mui/material'
import { googleKey } from '../../../../../../utils/constants'
import { RowItem } from '../RowItem'
import { projectTypeShort } from '../../../../../../types/project'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import { MapWrapper } from './AboutTab.styles'

export const AboutTab: FC<AboutTabProps> = ({ project, addressData, link, mainCardFullView }) => {
  const { fullName, status, phase, type, startDate, endDate } = project
  const { address } = addressData
  const { url, name } = link || {}

  const getMapParams = () => {
    const marker = address ? `markers=${address}` : ''
    const size = `size=277x${MapHeight}`
    const key = `key=${googleKey}`
    const center = !address ? `center=-30,0` : ''

    return [marker, center, size, key].join('&')
  }

  const onProjectUrlClick = useCallback(
    (url: string) => (e: MouseEvent<HTMLButtonElement>) => {
      window.open(url, '_blank')
    },
    [],
  )

  const onMapClick = useCallback((address: string) => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`, '_blank')
  }, [])
  const mapClickable = !!address

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='start' spacing={2.5} height={'100%'}>
      {mainCardFullView && (
        <MapWrapper onClick={() => mapClickable && onMapClick(address)} hover={mapClickable}>
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?${getMapParams()}`}
            width={277}
            height={MapHeight}
            alt='Адрес проекта на карте'
            style={{ borderRadius: 16 }}
          />
        </MapWrapper>
      )}

      <Stack height={'100%'} flex={1} spacing={1} width='50%'>
        <ScrollableContainer alignItems='start' flex={1} spacing={1.25}>
          <RowItem title='Полное наименование' rowStrings={[fullName]} />
          <RowItem title='Адрес проекта' rowStrings={[address]} />
          <RowItem title='Статус проекта' rowStrings={[status]} />
          <RowItem title='Тип проекта' rowStrings={[projectTypeShort[type]]} />
          <RowItem title='Стадия проекта' rowStrings={[phase]} />
          <RowItem title='Срок реализации' rowStrings={[`${startDate} — ${endDate}`]} />
        </ScrollableContainer>

        {url && (
          <Button
            onClick={onProjectUrlClick(url)}
            fullWidth
            size='thin'
            color='secondary'
            sx={{ fontWeight: 400, textTransform: 'inherit' }}
          >
            {name}
          </Button>
        )}
      </Stack>
    </Stack>
  )
}
