import styled from 'styled-components'
import {
  ProjectFullExportTomItemButton,
  ProjectFullExportTomItemPhase,
  ProjectFullExportTomItemWrapper,
} from '../../../../../ProjectInfo/components/ProjectFullExportDrawer/components/ProjectFullExportTomItem'

export const FormPocketDrawerTomItemWrapper = styled(ProjectFullExportTomItemWrapper)`
`

export const FormPocketDrawerTomItemButton = styled(ProjectFullExportTomItemButton)`
`

export const FormPocketDrawerTomItemPhase = styled(ProjectFullExportTomItemPhase)`
`