import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const CustomEditIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M20.4182 2.25388L22.0562 3.88962C22.3948 4.22675 22.3948 4.77537 22.0562 5.11362L20.746 6.41975L17.8813 3.56L19.1916 2.2535C19.3607 2.08437 19.5827 2 19.8047 2C20.0267 2 20.2491 2.08475 20.4182 2.25388ZM12.241 14.9097L9.37598 12.0496L17.2679 4.17236L20.1329 7.03249L12.241 14.9097ZM8.75762 12.6616L8.146 16.1311L11.6226 15.5214L8.75762 12.6616ZM5.87538 17.6772H16.6776V11.5411L18.5526 9.6695V18.3399C18.5526 19.007 18.007 19.5526 17.3399 19.5526H5.21275C4.54562 19.5526 4 19.007 4 18.3399V6.21275C4 5.54562 4.54562 5 5.21275 5H15.3775L13.4987 6.875H5.87538V17.6772Z'
      />
    </SvgIcon>
  )
}

export default CustomEditIcon