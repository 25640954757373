import { FormikProvider } from "formik"
import { useForm } from "../../../../../hooks/useForm"
import {
  StyledAutocomplete,
  StyledCancelButton,
  StyledForm,
  StyledFormTitle,
  StyledLabel,
  StyledSearchIcon,
  StyledSubmitButton,
  StyledToggleButtonGroup
} from "./InternalAgreementForm.styles"
import { Box, Stack, Typography } from "@mui/material"
import { useGetInnermapAvailableMembersListQuery, useGetInnermapDepartmentListQuery } from "../../../../../api/internalAgreement"
import { InternalAgreementAutocompleteValues, InternalAgreementFormProps, RightsValues } from "./InternalAgreementForm.types"
import { useParams } from "react-router-dom"
import { PersonAutocompleteFields } from "../PersonAutocompleteFields/PersonAutocompleteFields";
import { MouseEvent, useState, KeyboardEvent } from "react";
import { FormToggleButton } from "../FormToggleButton";
import { checkRights, validationSchema } from "./InternalAgreementForm.utils";
import { useTypedSelector } from "../../../../../store/store"
import { tableFormValuesSelector } from "../../../../../store/slices/internalAgreement"
import { HeaderSection } from "../../../../DocView/components/InternalAgreementDrawer/components/HeaderSection"

export const InternalAgreementForm = ({
  closeForm,
  addMember,
  editMember,
  innermapId,
  formMode,
  variant,
  styles
}: InternalAgreementFormProps) => {

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const tableFormValues = useTypedSelector(tableFormValuesSelector)

  const { data: departmentListData } = useGetInnermapDepartmentListQuery({ projectId, innermapId }, { skip: !innermapId })
  const { data: departmentList = [] } = departmentListData || {}

  const { data: availablePersonsData } = useGetInnermapAvailableMembersListQuery({ projectId, innermapId }, { skip: !innermapId })
  const { data: availablePersons = [] } = availablePersonsData || {}

  const [operations, setOperations] = useState<RightsValues[]>(checkRights(tableFormValues) || [])

  const handleChange = (e: MouseEvent<HTMLElement>, value: RightsValues[]) => {
    setOperations(value)
  }

  const initialValues = {
    department: tableFormValues.department ?? '',
    position: tableFormValues.person?.position ?? '',
    name: tableFormValues.person?.name ?? ''
  }

  const onSubmit = (values: InternalAgreementAutocompleteValues) => {

    const person = availablePersons.find((person) => {
      return person.name === values.name && person.position === values.position
    })

    formMode === 'create' && addMember({
      approval: operations.includes('approval') ? true : false,
      assurance: operations.includes('assurance') ? true : false,
      department: values.department,
      personId: person!.id,
      remarks: operations.includes('remarks') ? true : false,
      signing: operations.includes('signing') ? true : false,
    })

    formMode === 'edit' && editMember({
      approval: operations.includes('approval') ? true : false,
      assurance: operations.includes('assurance') ? true : false,
      memberId: tableFormValues.id,
      remarks: operations.includes('remarks') ? true : false,
      signing: operations.includes('signing') ? true : false,
    })
  }

  const { formik } = useForm({
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    }
  })

  const handleCancel = () => closeForm()

  const onKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault()

    }
  }

  return (
    <FormikProvider value={formik}>
      {variant === 'page' && (
        <StyledFormTitle variant="body2">
          {formMode === 'create' ? 'Добавить участника' : 'Редактировать участника'}
        </StyledFormTitle>)}
      {variant === 'drawer' && (
        <HeaderSection
          title={formMode === 'create'
            ? 'Добавить участника'
            : 'Редактировать участника'}
          onButtonClick={closeForm} />
      )}
      <StyledForm $minWidth={styles?.minWidthForm} onKeyDown={(e) => onKeyDown(e)}>
        <Stack direction='row' alignItems='center' spacing={styles?.spacing || 2}>
          <Typography
            variant="body2"
            minWidth={variant === 'drawer' ? '60px' : '80px'}
            fontSize={styles?.fontSize}>
            Участник:
          </Typography>
          <Stack direction='row' spacing={styles?.spacing || 2}>
            <StyledAutocomplete
              fieldName="department"
              placeholder="Подразделение*"
              disabled={formMode === 'edit'}
              freeSolo
              $width={styles?.autocompleteWidth}
              customFreeSoloMessage={undefined}
              customRenderOption={(props, option) => (
                <Box component="li" {...props} key={option.key}>
                  <Stack direction="row" alignItems='center' spacing={2}>
                    {option.value ? (
                      <StyledLabel variant="body2">
                        {option.label}
                      </StyledLabel>
                    ) : (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <StyledSearchIcon fontSize="small" />
                        <Typography variant="h3">
                          Не найдено! Для добавления подразделения, введите название в поле и нажмите на это сообщение или Enter на клавиатуре для сохранения
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Box>
              )}
              data={departmentList?.map(department => ({
                value: department,
                label: department,
                key: department,
              })) || []}
            />
            <PersonAutocompleteFields
              availablePersons={availablePersons}
              formMode={formMode}
              width={styles?.autocompleteWidth} />
          </Stack>
        </Stack>
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction='row' alignItems='center' spacing={styles?.spacing || 2}>
            <Typography variant="body2"
              minWidth={variant === 'drawer' ? '60px' : '80px'}
              fontSize={styles?.fontSize}>
              Операции:
            </Typography>
            <StyledToggleButtonGroup
              sx={{ gap: styles?.spacing || '16px' }}
              value={operations}
              onChange={handleChange}
              aria-label="Operations"
            >
              <FormToggleButton
                operations={operations}
                value={'assurance'}
                title={'Визирование'}
              />
              <FormToggleButton
                operations={operations}
                value={'signing'}
                title={'Подписание'}
              />
              <FormToggleButton
                operations={operations}
                value={'remarks'}
                title={'Замечания'}
              />
              <FormToggleButton
                operations={operations}
                value={'approval'}
                title={'Утверждение'}
              />
            </StyledToggleButtonGroup>
          </Stack>
          <Stack direction='row'>
            <StyledSubmitButton type="submit"
              disabled={!formik.isValid || formik.isSubmitting}>
              {formMode === 'create' ? 'Добавить' : 'Сохранить'}
            </StyledSubmitButton>
            {variant === 'page' &&
              <StyledCancelButton onClick={handleCancel}>
                Отменить
              </StyledCancelButton>}
          </Stack>
        </Stack>
      </StyledForm>
    </FormikProvider>
  )
}