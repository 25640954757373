import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserRoleByTabLabel, UserRoleTabLabels, userTabNamesArray } from '../../../types/user'
import { Grid, Stack, useMediaQuery } from '@mui/material'
import AdminLegend from '../components/AdminLegend'
import { useGetUsersQuery } from '../../../api/users'
import { GetUsersResponse, PublicUserProfile } from '../../../api/users/types'
import { Limit } from '../../../types/global'
import { NUMBER_OF_ROWS_PER_USERS_PAGE, XXL_FOR_LAYOUT } from '../../../utils/constants'
import useSearch from '../../../hooks/useSearch'
import { filterByFieldNames } from '../../../utils/filterByFieldNames'
import Progress from '../../../components/Progress'
import { TabData } from '../../../components/Tabs/Tabs.types'
import useBreadcrumbs from '../../../hooks/useBreadcrumbs'
import { EmptyPageData } from '../../../components/EmptyPage/EmptyPage.types'
import { getEmptyPageData } from '../../Home'
import EmptyPage from '../../../components/EmptyPage'
import { useTypedSelector } from '../../../store/store'
import { currentCompanyIdSelector } from '../../../store/slices/profile'
import { FullUserCardWrapper } from './AdminUsers.styles'
import { UserCardContent } from '../components/UserCardContent'


const AdminUsers: React.FC = () => {
  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_LAYOUT})`)
  const navigate = useNavigate()
  const currentCompanyId = useTypedSelector(currentCompanyIdSelector)

  const { searchValue } = useSearch()
  
  const isSearch = useMemo(() => {
    return !!searchValue.length
  }, [searchValue])

  const [currentTab, setCurrentTab] = useState<UserRoleTabLabels>('Все пользователи')
  const tabNames: UserRoleTabLabels[] = userTabNamesArray

  const onTabChange = useCallback((e: React.SyntheticEvent, tabValue: UserRoleTabLabels) => {
    setCurrentTab(tabValue)
  }, [])

  useBreadcrumbs([
    { title: 'Управление пользователями' }
  ])

  const onAddUserClick = useCallback(() => {
    navigate('add')
  }, [])

  const [limitValue, setLimitValue] = useState<Limit['value']>(
    NUMBER_OF_ROWS_PER_USERS_PAGE[0].value
  )
  const [page, setPage] = useState(1)

  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page])

  const { data, isLoading, isFetching } = useGetUsersQuery({
    companyId: currentCompanyId,
    limit: isSearch ? undefined : limitValue,
    offset: isSearch ? undefined: offset,
  }, {refetchOnMountOrArgChange: true})

  const { data: usersData, total } = data || ({} as GetUsersResponse)

  const filteredBySearch = useMemo(() => {
    return searchValue && usersData?.length
      ? filterByFieldNames<PublicUserProfile>(
        usersData,
        [
          'email',
          'phone',
          'userFullName' as keyof PublicUserProfile,
          'company.userCompanyName' as keyof PublicUserProfile,
          'company.userPosition' as keyof PublicUserProfile,
          'projects' as keyof PublicUserProfile,
          'objects' as keyof PublicUserProfile,
        ],
        searchValue
      )
      : usersData
  }, [usersData, searchValue])

  const filteredByStatus = useMemo(() => {
    return currentTab === 'Все пользователи'
      ? filteredBySearch
      : filteredBySearch?.filter(user => user.role === UserRoleByTabLabel[currentTab])
  }, [filteredBySearch, currentTab])

  const onUserCardClick = (userId: number) => {
    navigate(`edit/${userId}`)
  }

  const countPagination = useMemo(
    () => Math.ceil(total / limitValue) || 1,
    [total, limitValue]
  )

  const handleChangeLimit = useCallback(
    (limit: number) => {
      setLimitValue(Number(limit))
      setPage(1)
    },
    [setLimitValue, setPage]
  )
  const handleChangePage = useCallback((page: number) => setPage(page), [setPage])

  const tabsData: TabData<UserRoleTabLabels>[] = useMemo(() => {
    return tabNames.map(tabName => ({ value: tabName, label: tabName }))
  }, [tabNames])

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют пользователи, соответствующие результатам запроса.</>
  )

  return (
    <Stack flex={1}>
      <AdminLegend<UserRoleTabLabels>
        currentTab={currentTab}
        pagination={!isSearch}
        tabsData={tabsData}
        onTabChange={onTabChange}
        onAddClick={onAddUserClick}
        countPagination={countPagination}
        limit={limitValue}
        onChangeLimit={handleChangeLimit}
        onChangePage={handleChangePage}
        page={page}
        numberRows={NUMBER_OF_ROWS_PER_USERS_PAGE}
      />
      {isLoading || isFetching
        ? <Progress />
        : filteredByStatus?.length
          ? (
            <Grid sx={{ py: 1.25 }} spacing={2.5} container>
              {filteredByStatus?.map(user => (
                <Grid
                  item
                  xs={12} md={6} lg={4} xl={xxl ? 2 : 3}
                  container
                  justifyContent='center'
                  key={user.id}
                >
                  <FullUserCardWrapper onClick={() => onUserCardClick(user.id)} textColor='dark'>
                    <UserCardContent userData={user} mode='full' />
                  </FullUserCardWrapper>
                </Grid>
              ))}
            </Grid>
          )
          : <EmptyPage data={emptyFilteredData} forFilter />
      }
    </Stack>
  )
}


export default AdminUsers