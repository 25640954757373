import { Box, IconButton, Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const CardWrapper = styled(Stack)`
  border: 1px solid #D1D8FA;
  border-radius: 6px;
  padding: 12px;
  min-height: 177px;
`
export const StyledLabel = styled(Box)`
  background-color: ${props => props.theme.palette.primary.main};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 24px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${props => props.theme.palette.text.light};
`
export const StyledIconButton = styled(IconButton)`
  padding: 6px;
  border-radius: 6px;
  background-color: ${props => props.theme.palette.bg.shades};
`
export const CardText = styled(Typography)`
  font-size: 14px;
  margin-top: 8px;
`