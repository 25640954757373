import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from "styled-components";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  border-radius: 6px;
`
export const StyledToggleButton = styled(ToggleButton)`
  padding: 7px;
  background-color: transparent;
  border: 1px solid ${props => props.theme.palette.primaryBorderLight};

  &.Mui-selected {
    background-color: ${props => props.theme.palette.bg.lightBlue};
  }
`