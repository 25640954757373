import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";
import { ColorStatus } from "./AgGridPockets.types";

export const InfoWrapper = styled(Box)<{$color?: ColorStatus, $autoWidth?: boolean}>`
  border-radius: 4px;
  padding: 3.5px 7px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: min-content;
  min-width: ${props => props.$autoWidth ? 'auto' : '160px'};
  background-color: ${props => props.theme.palette.bg.shades};
  background-color: ${props => props.$color === 'awaiting' && '#FFCD4B26'};

  .MuiTypography-root {
    color: ${props => props.$color === 'awaiting' && props.theme.palette.table.orange};
  }
`
export const ConfirmButton = styled(Button)`
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 4px;
  height: 24px;
  background-color: #EEF9F7;
  color: ${props => props.theme.palette.legends.chartsGreen};
  

  :hover {
    background-color: inherit;
    box-shadow: 0 0 4px 2px #e5e9ef;
  }
`
export const ElipsisText = styled(Typography)`
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`

export const StyledBox = styled(Box)`
  height: 24px;
  min-width: 40px;
  border-radius: 4px;
  background-color: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.text.light};
`