import { ArrowDropDown as ArrowIcon } from '@mui/icons-material'
import { ListItemText, MenuItem, Select, Stack, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'

import { theme } from '@styles/theme'

import { IFilterSelectNewProps } from './FilterSelectNew.types'

export const FilterSelectNew: React.FC<IFilterSelectNewProps> = ({
  startIcon,
  items,
  value,
  defaultValue,
  onChange,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  const handleChange = useCallback(
    (event: any) => {
      const value = event?.target?.value
      onChange(value)
    },
    [onChange],
  )

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  return (
    <Stack justifyContent='center' {...props}>
      <Select
        sx={{
          '& > .MuiSvgIcon-root': {
            right: '8px',
            fontSize: '20px !important',
            color: '#9AA2B0',
          },
          '& .MuiInputBase-input': {
            borderRadius: '6px !important',
            padding: '7px 0px',
            paddingRight: '32px !important',
            backgroundColor: '#F6F7FB !important',
            border: !open ? '1px solid  #2B36481F' : '1px solid  #0044B4',
            ':hover': {
              border: !open ? '1px solid  #2B3648' : '1px solid  #0044B4',
            },
          },
          '& div': {
            alignItems: 'center',
            paddingRight: 0.2,
          },
        }}
        variant='standard'
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        onChange={handleChange}
        displayEmpty
        disableUnderline
        IconComponent={ArrowIcon}
        renderValue={(selected: string) => {
          return (
            <Stack direction='row' alignItems='center'>
              <Typography variant='body2' pl={1} lineHeight={'inherit'} color={theme.palette.text.dark}>
                {selected}
              </Typography>
            </Stack>
          )
        }}
      >
        {items?.map((item) => {
          const { label, value } = item

          return (
            <MenuItem key={label} value={value}>
              <ListItemText primary={label} />
            </MenuItem>
          )
        })}
      </Select>
    </Stack>
  )
}
