import * as yup from 'yup'
import { MIN_PASSWORD_LENGTH_VALIDATION } from '../../utils/constants'
import { UserProjectAccessInfo } from '../../api/users/types'
import { userRolesArrayEn, UserRolesEn } from '../../types/user'

const checkMinObjects = (value: UserProjectAccessInfo, role: UserRolesEn) => {
  switch (role) {
    case 'contractor':
      return value?.allObjects || value?.pdAccess || value?.iiAccess || value?.irdAccess || !!value?.objects.length
    case 'supervisor':
      return value?.allObjects || value?.pdAccess || value?.iiAccess || value?.irdAccess
    case 'admin':
    case 'architector':
    case 'client':
    case 'none':
      return true
  }
}

export const validationProfile = yup.object({
  lastName: yup.string().trim().required('common:errors.required'),
  firstName: yup.string().trim().required('common:errors.required'),
  middleName: yup.string().trim(),
  companyName: yup.string().trim(),
  position: yup.string().trim().required('common:errors.required'),
  phone: yup.string().required('common:errors.required'),
  email: yup
    .string()
    .email('common:errors.email')
    .required('common:errors.required'),
  login: yup.string().trim(),
  password: yup
    .string()
    .min(MIN_PASSWORD_LENGTH_VALIDATION, 'common:errors.min')
    .required('common:errors.required'),
  role: yup.string().oneOf(userRolesArrayEn),
  projects: yup.array(
    yup.object({}).test('min-access', 'В проекте не назначены доступы.', (value, context) => {
      return checkMinObjects(
        value as UserProjectAccessInfo,
        (context as any).from[1].value.role as UserRolesEn
      )
    })
  )
})

export const validationProfileWithoutPassword = yup.object({
  lastName: yup.string().trim().required('common:errors.required'),
  firstName: yup.string().trim().required('common:errors.required'),
  middleName: yup.string().trim(),
  companyName: yup.string().trim(),
  position: yup.string().trim().required('common:errors.required'),
  phone: yup.string().required('common:errors.required'),
  email: yup
    .string()
    .email('common:errors.email')
    .required('common:errors.required'),
  login: yup.string().trim(),
  role: yup.string().oneOf(userRolesArrayEn),
  projects: yup.array(
    yup.object({}).test('min-access', 'В проекте не назначены доступы.', (value, context) => {
      return checkMinObjects(
        value as UserProjectAccessInfo,
        (context as any).from[1].value.role as UserRolesEn
      )
    })
  )
})