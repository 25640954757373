import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterData, FilterState } from './filter.types'
import {
  ClearFilterPayload,
  SetAvailableRdsIdsPayload,
  SetAvailableRdsItemsPayload,
  SetFilterDataPayload,
  SetFilterItemsPayload,
  SetIiTypePayload,
  SetIsExpiredPayload,
  SetIsFilterPayload,
  UpdateFilterDataPayload,
} from './filter.payloads.types'
import { getRdFromObjects } from '../tom/tom.utils'
import { AppDispatch, RootState } from '../../../store'
import { getFilterFieldName } from '../../../../layouts/DocumentsLayout'

let initialState: FilterState = {
  isFilter: false,
  filterData: {
    selectedObjectsIds: [],
    selectedRdsIds: [],
    selectedPdsIds: [],
    selectedIisIds: [],
    selectedIrdsIds: [],
    selectedDocs: [],
    isExpired: false,
    iiType: 'Технический отчет',
  },
  filterItems: {
    allObjectsItems: [],
    availableRdsItems: [],
    allPdsItems: [],
    allIisItems: [],
    allIrdsItems: [],
  },
}

const filterSlice = createSlice({
  name: 'filterState',
  initialState,
  reducers: {
    setFilterData: (state, { payload }: PayloadAction<SetFilterDataPayload>) => {
      const { filterData } = payload

      state.filterData = filterData
      localStorage.setItem('docsFilterData', JSON.stringify(filterData))
    },
    setIsFilter: (state, { payload }: PayloadAction<SetIsFilterPayload>) => {
      const { isFilter } = payload

      state.isFilter = isFilter
    },
    setAvailableRdsIds: (state, { payload }: PayloadAction<SetAvailableRdsIdsPayload>) => {
      const { selectedObjectsIds, allSidebarObjects } = payload
      const selectedObjects = allSidebarObjects?.filter((objectItem) =>
        selectedObjectsIds?.includes(objectItem.obj.objID),
      )

      state.filterItems.availableRdsItems = getRdFromObjects(selectedObjects, allSidebarObjects)
    },
    setFilterItems: (state, { payload }: PayloadAction<SetFilterItemsPayload>) => {
      const { allObjectsItems, allPdsItems, allIisItems, allIrdsItems } = payload

      state.filterItems.allObjectsItems = allObjectsItems
      state.filterItems.allPdsItems = allPdsItems
      state.filterItems.allIisItems = allIisItems
      state.filterItems.allIrdsItems = allIrdsItems
    },
    setIsExpired: (state, { payload }: PayloadAction<SetIsExpiredPayload>) => {
      const { isExpired } = payload

      state.filterData.isExpired = isExpired
      localStorage.setItem(
        'docsFilterData',
        JSON.stringify({
          ...state.filterData,
          isExpired,
        }),
      )
    },
    setIiType: (state, { payload }: PayloadAction<SetIiTypePayload>) => {
      const { iiType } = payload

      state.filterData.iiType = iiType
      localStorage.setItem(
        'docsFilterData',
        JSON.stringify({
          ...state.filterData,
          iiType,
        }),
      )
    },
    setAvailableRdsItems: (state, { payload }: PayloadAction<SetAvailableRdsItemsPayload>) => {
      const { availableRdsItems } = payload

      state.filterItems.availableRdsItems = availableRdsItems
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateFilterData.fulfilled, (state, { payload }) => {
        const { selectedIds, filterType, sidebarObjects } = payload

        // @ts-ignore:next-line
        state.filterData[getFilterFieldName[filterType]] = selectedIds

        if (filterType === 'object' && sidebarObjects?.length) {
          filterSlice.caseReducers.setAvailableRdsIds(state, {
            type: 'setAvailableRdsIds',
            payload: {
              selectedObjectsIds: selectedIds,
              allSidebarObjects: sidebarObjects,
            },
          })
        }
      })

      .addCase(clearFilter.fulfilled, (state, { payload }) => {
        const { recalculateAvailableRds, sidebarObjects } = payload

        if (recalculateAvailableRds) {
          filterSlice.caseReducers.setAvailableRdsItems(state, {
            type: 'setAvailableRdsItems',
            payload: {
              availableRdsItems: getRdFromObjects(sidebarObjects),
            },
          })
        }
      })
  },
})

export const updateFilterData = createAsyncThunk<
  UpdateFilterDataPayload,
  Omit<UpdateFilterDataPayload, 'sidebarObjects'>,
  { dispatch: AppDispatch; state: RootState }
>('filter/update', async ({ selectedIds, filterType }, { getState }) => {
  const state = getState()
  const {
    sidebar: { sidebarObjects },
  } = state

  localStorage.setItem(
    'docsFilterData',
    JSON.stringify({
      ...state.filter.filterData,
      [getFilterFieldName[filterType]]: selectedIds,
    } as FilterData),
  )

  return {
    selectedIds,
    filterType,
    sidebarObjects,
  }
})

export const clearFilter = createAsyncThunk<
  ClearFilterPayload,
  Omit<ClearFilterPayload, 'sidebarObjects'>,
  { dispatch: AppDispatch; state: RootState }
>(
  'filter/clear',
  // @ts-ignore
  async ({ recalculateAvailableRds = false }, { getState, dispatch }) => {
    try {
      const state = getState()
      const {
        sidebar: { sidebarObjects },
      } = state
      const emptyFilterData: FilterData = {
        selectedObjectsIds: [],
        selectedRdsIds: [],
        selectedPdsIds: [],
        selectedIisIds: [],
        selectedIrdsIds: [],
        selectedDocs: [],
        isExpired: false,
        iiType: 'Технический отчет',
      }

      localStorage.setItem('docsFilterData', JSON.stringify(emptyFilterData))
      dispatch(
        setFilterData({
          filterData: emptyFilterData,
        }),
      )

      return {
        recalculateAvailableRds,
        sidebarObjects,
      }
    } catch (e: any) {
      console.log('e', e)
    }
  },
)

export const { reducer: filterReducer } = filterSlice
export const {
  setFilterData,
  setIsFilter,
  setIsExpired,
  setIiType,

  setAvailableRdsIds,
  setFilterItems,
  setAvailableRdsItems,
} = filterSlice.actions
