import { Avatar, Box, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { ProjectRoadmapFormData } from "../../../ProjectAgreement/components/ProjectAgreementLegend"
import { useAgreementPerson } from "../../../ProjectAgreementForm/components/ProjectAgreementFormContent/hooks/useAgreementPerson"
import { InternalAgreementAutocomplete, InternalAgreementViewPerson } from "./InternalAgreementPerson.styles"
import { theme } from "../../../../../styles/theme"
import { InternalAgreementPersonProps } from "./InternalAgreementPerson.types"
import { InternalAgreementViewField } from "../InternalAgreementControl"
import { useAccess } from "../../InternalAgreementSchemes.utils"
import { profileSelector } from "../../../../../store/slices/profile"
import { useTypedSelector } from "../../../../../store/store"

export const InternalAgreementPerson = ({ mode, person, autocompleteHeight, withAvatar, stageCompany }: InternalAgreementPersonProps) => {

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const profile = useTypedSelector(profileSelector)
  const { companyNames, employeesByProjectAndCompany } = useAgreementPerson<ProjectRoadmapFormData>({ projectId })
  const { isAdmin } = useAccess()
  const userCompanyName = stageCompany ? stageCompany : person?.company ? person.company : profile.company.userCompanyName

  return (
    <>
      <Stack direction='row' mr='16px' alignItems='center' spacing={1}>
        <Typography color={theme.palette.secondary.gray} variant={autocompleteHeight ? 'caption' : 'body2'}>Компания:</Typography>
        {(mode === 'create' && isAdmin && !stageCompany)
          ? (
            <InternalAgreementAutocomplete
              $height={autocompleteHeight}
              fieldName='companyName'
              placeholder='Компания*'
              data={companyNames?.map(company => ({
                value: company,
                label: company,
                key: company,
              })) || []} />
          ) : (
            <InternalAgreementViewField variant='body2' margin="8px">{userCompanyName}</InternalAgreementViewField>
          )
        }
      </Stack>
      <Stack direction='row' mr='16px' alignItems='center' spacing={1}>
        <Typography color={theme.palette.secondary.gray} variant={autocompleteHeight ? 'caption' : 'body2'}>Модератор:</Typography>
        {mode === 'view' ?
          <InternalAgreementViewPerson direction='row' alignItems='center'>
            <Avatar src={person?.avatar} alt={person?.name} sx={{ width: "24px", height: "24px", marginRight: "8px" }} />
            <Typography variant='body2' color='inherit' maxWidth='200px'>{person?.name}</Typography>
          </InternalAgreementViewPerson>
          :
          <InternalAgreementAutocomplete
            $height={autocompleteHeight}
            componentsProps={{ paper: { sx: { width: withAvatar ? "270px" : 'inherit', left: "100px" } } }}
            fieldName='personId'
            placeholder='Укажите ФИО*'
            customRenderOption={(props, option) => (
              <Box component="li" {...props} key={option.key}>
                {withAvatar && <Avatar src={option.avatar} alt={option.name} sx={{ width: "32px", height: "32px", marginRight: "16px" }} />}
                <Stack spacing={0}>
                  <Typography variant="body2">{option.label}</Typography>
                  <Typography variant="caption" color={theme.palette.secondary.dark}>{option.position}</Typography>
                </Stack>
              </Box>
            )}
            data={employeesByProjectAndCompany.map(({ id, name, avatar, position }) => ({
              value: id,
              label: name,
              key: id,
              avatar,
              position
            }))}/>}
      </Stack>
    </>
  )
}