import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Select, Typography } from '@mui/material'
import styled from 'styled-components'

import { MainCardWrapper } from '../MainCard/MainCard.styles'

export const DesignGraphCardWrapper = styled(MainCardWrapper)`
  /* grid-area: graph; */
  grid-column: span 1;
  grid-row: span 2;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 6px 6px -3px rgba(0, 0, 0, 0.2),
      0px 10px 14px 1px rgba(0, 0, 0, 0.14),
      0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
`

export const GraphCardAccordion = styled(Accordion)`
  box-shadow: none;

  &::before {
    display: none;
  }

  &:not(:last-child) {
    box-shadow: ${(props) => `inset 0 -1px 0 ${props.theme.palette.bg.gray}`};
  }

  &.Mui-expanded {
    margin: 0;
  }
`

export const GraphCardAccordionSummary = styled(AccordionSummary)`
  min-height: 40px;
  padding: 0;

  &.Mui-expanded {
    min-height: auto;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`

export const GraphCardAccordionDetails = styled(AccordionDetails)`
  padding: 10px 0 0 30px;
`
export const ScheduleSelect = styled(Select)`
  & .MuiSelect-select {
    width: 264px;
    padding: 8px;
    font-size: 14px;
    border-radius: 6px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiSvgIcon-root {
    font-size: 16px !important;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.secondary.dark + 'A3'};
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.theme.palette.legends.border};
  }
`
export const GroupText = styled(Typography)`
  display: -webkit-box;
  overflow-wrap: anywhere;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-pack: center;
  -webkit-box-orient: vertical;
`
export const ScheduleSelectMenuItemWrapper = styled(MenuItem)`
  background-color: ${(props) => props.theme.palette.bg.white}!important;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.bg.shades}!important;
  }

  &.Mui-selected {
    background-color: ${(props) => props.theme.palette.bg.white};
  }

  & .MuiTypography-root {
    display: block;
  }
`
