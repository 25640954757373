import { isDate } from 'date-fns'
import * as yup from 'yup'

import { checkIfFilesAreCorrectType } from '../../../../components/UploadDrawer/UploadDrawerForm/validation'
import { ProjectPhase } from '../../../../types/project'
import { TomStatus } from '../../../../types/tom'
import { parseDateString } from '../../../../utils/formatDate'

export const getTomValidation = (formats: string[], phase: ProjectPhase) => {
  let validationByPhase

  const regularDateValidation = {
    plannedDate: yup
      .date()
      .transform(parseDateString)
      .nullable()
      .typeError('Неверный формат даты')
      .test('is-required-date', 'common:errors.required', (value, context) =>
        setRequiredDate(value, phase, context.parent.status as TomStatus, 'plannedDate'),
      ),
    forecastDate: yup.date().nullable().default(undefined).transform(parseDateString).typeError('Неверный формат даты'),
    actualDate: yup
      .date()
      .nullable()
      .max(new Date(), 'Фактическая дата не может быть позже текущей даты')
      .typeError('Неверный формат даты'),
    //forecastDate: yup.date().nullable().default(undefined).transform(parseDateString).min(yup.ref('plannedDate'), 'Прогнозируемая дата окончания должна быть позже даты начала').typeError('Неверный формат даты'),
  }

  const irdDateValidation = {
    issueDate: yup
      .date()
      .transform(parseDateString)
      .nullable()
      .typeError('Неверный формат даты')
      .test('is-required-date', 'common:errors.required', (value, context) =>
        setRequiredDate(value, phase, context.parent.status as TomStatus, 'issueDate'),
      ),
    // durationDate: yup.date().nullable().default(undefined).transform(parseDateString).typeError('Неверный формат даты'),
    durationDate: yup
      .date()
      .nullable()
      .default(undefined)
      .transform(parseDateString)
      .min(yup.ref('issueDate'), 'Срок действия должен быть позже даты выдачи')
      .typeError('Неверный формат даты'),
  }

  switch (phase) {
    case 'Рабочая документация':
      validationByPhase = {
        objectId: yup.number().not([''], 'common:errors.required').required('common:errors.required'),
        rdId: yup.number().not([''], 'common:errors.required').required('common:errors.required'),
        ...regularDateValidation,
      }
      break
    case 'Проектная документация':
      validationByPhase = {
        pdId: yup.number().not([''], 'common:errors.required').required('common:errors.required'),
        ...regularDateValidation,
      }
      break
    case 'Инженерные изыскания':
      validationByPhase = {
        iiId: yup.number().not([''], 'common:errors.required').required('common:errors.required'),
        iiType: yup.string().not([''], 'common:errors.required').required('common:errors.required'),
        ...regularDateValidation,
      }
      break
    case 'Сбор исходных данных':
      validationByPhase = {
        irdId: yup.number().not([''], 'common:errors.required').required('common:errors.required'),
        ...regularDateValidation,
        ...irdDateValidation,
      }
      break
  }

  return yup.object({
    title: yup.string().trim().required('common:errors.required'),
    description: yup.string().trim().required('common:errors.required'),
    status: yup.string().not([''], 'common:errors.required').required('common:errors.required'),
    ...validationByPhase,
    architectorData: yup.mixed(),
    contractorData: yup.mixed(),
    clientData: yup.mixed(),
    version: yup.number().min(0),
    file: yup
      .mixed()
      .test('is-correct-format', 'Неверный формат файла', (value) => checkIfFilesAreCorrectType(value, formats, false)),
  })
}

const setRequiredDate = (
  value: Date | null | undefined,
  phase: ProjectPhase,
  status: TomStatus,
  dateField: 'plannedDate' | 'issueDate',
) => {
  if (dateField === 'issueDate' && phase === 'Сбор исходных данных' && status === 'Не согласуется') {
    return !!value && isDate(value)
  }

  if (dateField === 'plannedDate' && (phase !== 'Сбор исходных данных' || status !== 'Не согласуется')) {
    return !!value && isDate(value)
  }

  return true
}
