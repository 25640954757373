import { Typography } from '@mui/material'
import styled from 'styled-components'

import { ObjectsSelectMenuItem } from '@components/UserManagement/components/UserObjectsSelection/styles'

export const FilterSelectMultipleTwoStyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.text.dark} !important;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FilterSelectMenuItem = styled(ObjectsSelectMenuItem)``
