import React from 'react'
import { ChangeItemProps } from './DocSideBarItem.types'
import { Box, Stack, Typography } from '@mui/material'
import { ChangeItemWrapper, DocChangeImage } from './DocSideBarItem.styles'
import { TomColoredInfo } from '../../../Docs/components/TomCard/TomCard.styles'
import { theme } from '../../../../styles/theme'
import Button from '../../../../components/Button'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import CustomEditIcon from '../../../../assets/icons/CustomEditIcon'
import VersionIcon from '../../../../assets/icons/VersionIcon'
import { useTypedSelector } from '../../../../store/store'
import { profileSelector } from '../../../../store/slices/profile'
import { TomDocument } from '../../../../types/tom'
import { isExtraAccessArchitectorSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { convertDateTime } from '../../../../utils/formatDateAndTimeGMT'

export const DocSideBarItem: React.FC<ChangeItemProps> = ({
  doc,
  onClick,
  onDownload,
  onDelete,
}) => {
  const isExtraAccessArchitector = useTypedSelector(isExtraAccessArchitectorSelector)
  const { role } = useTypedSelector(profileSelector)
  const { change, version: currentVersion, uploadedBy, createdAt, preview } = doc
  const isVersion = !change

  const onDownloadClick = (e: React.MouseEvent<HTMLButtonElement>, doc: TomDocument) => {
    e.stopPropagation()
    onDownload(doc)
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement>, doc: TomDocument) => {
    e.stopPropagation()
    onDelete(doc)
  }

  return (
    <ChangeItemWrapper alignItems='center' spacing={1.25} onClick={() => onClick(doc)}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} style={{ width: '100%' }}>
        <Stack direction='row' alignItems='center' spacing={1}>
          <TomColoredInfo direction='row' alignItems='center' customColor='blue' smallPadding>
            <Typography variant='tooltip' style={{ marginTop: 2 }}>PDF</Typography>
          </TomColoredInfo>

          <TomColoredInfo direction='row' alignItems='center' spacing={0.5}
            customColor={isVersion ? 'secondary' : 'orange'} smallPadding>
            {isVersion
              ? <VersionIcon fontSize='small' color='secondary' />
              : <CustomEditIcon fontSize='small' color='error' />
            }

            <Typography variant='tooltip' style={{ marginTop: 2 }}>{isVersion ? currentVersion : change}</Typography>
          </TomColoredInfo>
        </Stack>


        <Stack direction='row' alignItems='center' spacing={0.5}>
          <Button
            onClick={(e) => onDownloadClick(e, doc)}
            icon
            variant='text'
            style={{ minWidth: 21, height: 21, padding: '4px 2px' }}
          >
            <FileDownloadIcon fontSize='medium' color='primary' />
          </Button>

          {role === 'admin' || (!isVersion && (role === 'client' || isExtraAccessArchitector))
            ? (
              <Button
                onClick={(e) => onDeleteClick(e, doc)}
                icon
                variant='text'
                style={{ minWidth: 21, height: 21, padding: '4px 2px' }}
              >
                <DeleteIcon fontSize='medium' color='primary' />
              </Button>
            )
            : null
          }
        </Stack>
      </Stack>

      <Box>
        <DocChangeImage src={preview} width={155} height={220} alt='Превью документа' />
      </Box>

      <Box>
        <Typography variant='body2' fontSize={12} color={theme.palette.text.dark}>
          {convertDateTime(createdAt, false, true)}
        </Typography>
        <Typography variant='body2' fontWeight={500} color={theme.palette.text.dark}>
          {uploadedBy}
        </Typography>
      </Box>
    </ChangeItemWrapper>
  )
}
