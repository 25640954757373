import styled from 'styled-components'
import { alpha, Box, IconButton, Stack, Typography } from '@mui/material'
import { AGREEMENT_CARD_WIDTH } from '../ProjectAgreementCardLine'
import { LegendColors } from '../../../../../types/global'

// минимальная длина текста, для отображения тултипа
export const CARD_TOOLTIP_MINLENGTH = 23

export const ProjectAgreementCardFullWrapper = styled(Stack)`
  position: relative;
`

export const AgreementCardAvatarWrapper = styled(Box)`
  width: 32px;
  height: 32px;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 50%;

  & img {
    border-radius: 50%;
  }

  & .MuiSvgIcon-root {
    padding-top: 6px;
    color: ${props => props.theme.palette.secondary.gray};
  }
`

interface ProjectAgreementCardWrapperProps {
  yellowBg: boolean
  whiteBg: boolean
}

export const ProjectAgreementCardWrapper = styled(Stack) <ProjectAgreementCardWrapperProps>`
  width: ${AGREEMENT_CARD_WIDTH}px;
  height: 418px;
  padding: 20px;
  background-color: ${props => props.theme.palette.bg.shades};
  background-color: ${props => props.yellowBg && '#FFFCF3'};
  background-color: ${props => props.whiteBg && props.theme.palette.bg.white};
  outline: ${props => props.whiteBg && `2px solid ${props.theme.palette.bg.shades}`};
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    
    box-shadow: 0px 6px 20px 2px rgba(16, 24, 40, 0.08),
      0px 7px 8px -3px rgba(16, 24, 40, 0.08),
      0px -6px 12px 0px rgba(16, 24, 40, 0.08);

  }

  & .MuiDivider-root {
    margin-top: 2px;
    border-color: #D1D8FA;
  }

  ${AgreementCardAvatarWrapper} {
    background-color: ${props => props.whiteBg && props.theme.palette.bg.shades};
  }
`

export const AgreementCardTitle = styled(Typography)`
  min-height: 38px;
  max-height: 38px;
  letter-spacing: 0;
  line-height: 18px;
  color: ${props => props.theme.palette.secondary.gray};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

interface AgreementCardTextProps {
  disabled?: boolean
  isLightGreen?: boolean
  isWarning?: boolean
}

export const AgreementCardText = styled(Typography) <AgreementCardTextProps>`
  max-width: 100%;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  letter-spacing: 0;
  color: ${props => props.disabled && props.theme.palette.disabled};
  color: ${props => props.isLightGreen && props.theme.palette.legends.lightgreen};
  color: ${props => props.isWarning && props.theme.palette.warning.main};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

interface AgreementCardDurationProps {
  disabled?: boolean
}
export const AgreementCardDuration = styled(Stack) <AgreementCardDurationProps>`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 42px;
  height: 20px;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 6px;

  & .MuiSvgIcon-root {
    color: ${props => props.disabled ? props.theme.palette.secondary.gray : props.theme.palette.info.main};
  }

  & .MuiTypography-root {
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.disabled ? props.theme.palette.secondary.gray : props.theme.palette.info.main};
  }
`

export const AgreementCardSubtitle = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: ${props => props.theme.palette.secondary.gray};
`

export const AgreementCardAddButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: -48px;
  width: 30px;
  height: 30px;
  background-color: ${props => props.theme.palette.primary.main};
  border-radius: 50%;
  transform: translateY(-50%);

  &:hover {
    background-color: ${props => props.theme.palette.primary.main};
  }

  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.text.light};
  }
`

export const AgreementCardAddButtonLeft = styled(AgreementCardAddButton)`
  right: auto;
  left: -48px;
`

export const AgreementCardEmptyNotification = styled(Box)`
  width: 100%;
  height: 40px;
  padding: 5px 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.palette.warning.main};
  border: ${props => `1px solid ${props.theme.palette.warning.main}`};
  border-radius: 8px;
`

export const AgreementCardFirstStage = styled(Box)`
  position: absolute;
  top: -14px;
  right: -6px;
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 50%;

  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.info.main};
  }
`

interface AgreementCardStatusIconProps {
  lightgreenIcon?: boolean
  yellowBg?: boolean
  whiteBg?: boolean
}
export const AgreementCardStatusIcon = styled(AgreementCardFirstStage) <AgreementCardStatusIconProps>`
  width: 28px;
  height: 28px;
  padding: 3px;
  background-color: ${props => props.theme.palette.bg.shades};
  background-color: ${props => props.whiteBg && props.theme.palette.bg.white};
  background-color: ${props => props.yellowBg && '#FFFCF3'};

  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.warning.main};
    color: ${props => props.lightgreenIcon && '#14B8A6'};
  }
`
export const BoundStatusIcon = styled(Box)`
  position: absolute;
  top: -14px;
  left: -6px;
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 50%;
`

export const ColoredCircleIcon = styled.span<{ $color: LegendColors }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.$color === 'lightgreen' && props.theme.palette.legends.lightgreen};
  background-color: ${props => props.$color === 'blue' && props.theme.palette.legends.blue};
  background-color: ${props => props.$color === 'red' && props.theme.palette.legends.red};
  background-color: ${props => props.$color === 'yellow' && props.theme.palette.legends.yellow};
  background-color: ${props => props.$color === 'disabled' && props.theme.palette.legends.disabled};
`
