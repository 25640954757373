import React, { useCallback, useEffect, useMemo } from 'react'
import { UploadDrawerAttention, UploadDrawerFormWrapper, UploadDrawerText, UploadTextWrapper } from './styles'
import { Box, Stack } from '@mui/material'
import { Form, FormikProvider } from 'formik'
import Button from '../../Button'
import FileUpload from '../../FileUpload'
import { useForm } from '../../../hooks/useForm'
import { UploadFormData } from '../../FileUpload/FileUpload.types'
import { UploadDrawerFormProps } from './UploadDrawerForm.types'
import { getUploadValidation } from './validation'
import DownloadIcon from '@mui/icons-material/Download'
import { isExcelResponseForErrors } from '../../../types/global'

const UploadDrawerForm: React.FC<UploadDrawerFormProps> = ({
  mode,
  onSubmit,
  onChange,
  onClose,
  onResultsDrawerOpen,
  text,
  attentionText,
  uploadData,
  responseData,
  loading,
  getExampleLink,
  ContentBeforeFileUpload,
  ContentAfterLinkExample,
}) => {
  const onDownload = useCallback(() => {
    getExampleLink?.()
  }, [])

  const initialValues: UploadFormData = useMemo(() => ({
    files: null,
  }), [])

  const validationUpload = useMemo(() => {
    return getUploadValidation(uploadData.formats)
  }, [uploadData])

  const { formik } = useForm({
    validationSchema: validationUpload,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      if (values?.files?.length && values.files.every(file => file instanceof File)) {
        onSubmit(values.files)
      }

      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, dirty, isSubmitting } = formik

  useEffect(() => {
    onChange(dirty)
  }, [values])

  return (
    <UploadDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={5} component={Form}>
          <UploadTextWrapper spacing={3.5}>
            <Box>
              <UploadDrawerText variant='body2'>
                {text}
              </UploadDrawerText>
            </Box>

            <Stack>
              <UploadDrawerAttention variant='body2'>
                Внимание!
              </UploadDrawerAttention>
              <UploadDrawerText variant='body2'>
                {attentionText}
              </UploadDrawerText>
            </Stack>
          </UploadTextWrapper>

          <Stack spacing={2}>
            {ContentBeforeFileUpload}

            <FileUpload<null>
              name='files'
              uploadData={uploadData}
              onResultsDrawerOpen={onResultsDrawerOpen}
              loading={loading}
              responseData={responseData}
              mode={mode}
            />

            {getExampleLink
              ? (
                <Button
                  onClick={() => onDownload()}
                  variant='text'
                  startIcon={<DownloadIcon fontSize='small' style={{ marginTop: 1 }} />}
                  width='100%'
                  style={{
                    maxWidth: '100%'
                  }}
                >
                  Скачать форму документа
                </Button>
              )
              : null
            }

            {ContentAfterLinkExample}

            <Stack direction='row' spacing={2}>
              {!responseData?.success
                ? (
                  <Button
                    type='submit'
                    disabled={!(values.files?.length &&
                      (!responseData || !isExcelResponseForErrors(responseData) || !responseData?.error?.length))}
                    color='success'
                    size='medium'
                    fullWidth
                    loading={isSubmitting}
                  >
                    Обработать
                  </Button>
                )
                : null
              }

              <Button
                size='medium'
                fullWidth
                onClick={() => onClose(dirty, !!responseData?.success)}
                style={{ maxWidth: '100%' }}
              >
                {responseData?.success
                  ? 'Закрыть'
                  : 'Отменить'
                }
              </Button>
            </Stack>
          </Stack>


        </Stack>
      </FormikProvider>
    </UploadDrawerFormWrapper>
  )
}

export default UploadDrawerForm
