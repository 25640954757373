import React, { useCallback, useEffect } from 'react'
import { Box, Pagination, Stack } from '@mui/material'
import Button from '../../../../components/Button'
import AddIcon from '@mui/icons-material/Add'
import AppsIcon from '@mui/icons-material/Apps'
import TocIcon from '@mui/icons-material/Toc'
import DownloadingIcon from '@mui/icons-material/Downloading'
import { StyledToggleButton, StyledToggleButtonGroup } from './styles'
import FilterSelect from '../../../../components/FilterSelect'
import { theme } from '../../../../styles/theme'
import { AdminRdLegendProps } from './AdminRdLegend.types'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { adminRdViewSelector, PagesViewMode, setPageViewMode, ViewMode } from '../../../../store/slices/ui'
import { ExportIcon } from '../../../../assets/icons/ExportIcon'
import Tooltip from '../../../../components/Tooltip'
import { useExportRdMutation } from '../../../../api/rdPhase'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { downloadBlob } from '../../../../utils/dowloadBlob'

const AdminRdLegend: React.FC<AdminRdLegendProps> = ({
  onAddClick,
  onUploadClick,
  page,
  countPagination,
  numberRows,
  onChangeLimit,
  onChangePage,
  limit
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('user')

  const viewMode = useTypedSelector(adminRdViewSelector)

  const onViewChange = useCallback((updatedViewMode: ViewMode) => {
    if (updatedViewMode !== null) {
      dispatch(setPageViewMode({ page: 'adminRd', viewMode: updatedViewMode }))
    }
  }, [])

  useEffect(() => {
    const cachedPagesViewMode: PagesViewMode = JSON.parse(localStorage.getItem('pagesViewMode') || '{}')
    if (cachedPagesViewMode?.adminRd) {
      dispatch(setPageViewMode({ page: 'adminRd', viewMode: cachedPagesViewMode.adminRd }))
    }
  }, [])

  const [exportRd, exportRdResponse] = useExportRdMutation()

  const onRdExportClick = () => {
    exportRd()
  }

  useMutationHandlers(
    exportRdResponse,
    (data: Blob) => {
      downloadBlob(new File([data], ''), `РД.xlsx`)
    }
  )

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1.5 }}>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Button startIcon={<AddIcon />} onClick={onAddClick}>Добавить</Button>
          <Button startIcon={<DownloadingIcon />} onClick={onUploadClick}>Загрузить</Button>
          <Tooltip title='Экспортировать разделы в Excel'>
              <span>
                <Button onClick={onRdExportClick} icon>
                  <ExportIcon />
                </Button>
              </span>
          </Tooltip>
        </Stack>

        <Stack spacing={5} direction='row' justifyContent='center' alignItems='center'>
          <FilterSelect
            items={numberRows!}
            value={limit!}
            onChange={onChangeLimit!}
            startIcon={t('header.rows')}
          />
          <Pagination
            onChange={(e, v) => onChangePage!(v)}
            count={countPagination}
            shape='rounded'
            page={page}
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: 'rgba(109, 154, 220, 0.2)',
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '20px !important',
                },
              },
            }}
          />
          <StyledToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={(e, newValue) => onViewChange(newValue)}
          >
            <StyledToggleButton value='list'>
              <AppsIcon />
            </StyledToggleButton>
            <StyledToggleButton value='table'>
              <TocIcon />
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Stack>
      </Stack>
    </Box>
  )
}

export default AdminRdLegend