import { alpha, Box, FormControlLabel } from "@mui/material";
import styled from "styled-components";

export const FileLabel = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.text.light};
  width: 24px;
  height: 16px;
  border-radius: 2px;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
`
export const SubAccordionRadioButton = styled(FormControlLabel)`
  margin-left: 0;

  & .MuiRadio-root {
    margin-left: 16px;
    padding: 5px;

    &.Mui-checked {
      & .MuiSvgIcon-root {
        color: ${props => props.theme.palette.primary.main};
      }
    }

    & .MuiSvgIcon-root {
      color: ${props => alpha(props.theme.palette.disabled!, 0.35)};
    }
  }

  & .MuiTypography-root {
    font-size: 14px;
    text-align: start;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: anywhere;
    color: ${props => props.theme.palette.text.dark};
  }
`