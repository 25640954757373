import React, { FC, useCallback } from 'react'
import { PocketsLegendProps } from './PocketsLegend.types'
import { Stack, Tabs } from '@mui/material'
import { PocketsTab, StyledButton } from './PocketsLegend.styles'
import { TabData } from '../../../../components/Tabs/Tabs.types'
import { PackIcon } from '../../../../assets/icons/PackIcon'
import { ViewMode, pocketsViewSelector, setPageViewMode } from '../../../../store/slices/ui'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { AgreementPocketType } from '../../../../api/agreementPocket/types'
import { ViewModeButtonGroup } from '../../../../components/ViewModeButtonGroup'

export const PocketsLegend: FC<PocketsLegendProps> = ({
  onPocketDrawerOpen,
  pocketType,
  onPocketTypeChange,
}) => {

  const dispatch = useAppDispatch()
  const viewMode = useTypedSelector(pocketsViewSelector)
  const tabsData: TabData<AgreementPocketType | 'all'>[] = [
    { value: 'all', label: 'Все' },
    { value: 'incoming', label: 'Входящие' },
    { value: 'outgoing', label: 'исходящие' },
  ]

  const onViewChange = useCallback((updatedViewMode: ViewMode) => {
    if (updatedViewMode !== null) {
      dispatch(setPageViewMode({ page: 'pockets', viewMode: updatedViewMode }))
    }
  }, [])

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1} pt={0.5} pb={1} ml={2}>
      <Tabs value={pocketType} onChange={(e, value) => onPocketTypeChange(value)}>
        {tabsData.map(tabData => (
          <PocketsTab {...tabData} />
        ))}
      </Tabs>
      <Stack direction='row' spacing={1.5}>
        <StyledButton
          onClick={onPocketDrawerOpen}
          variant='outlined'
          color='secondary'
          startIcon={<PackIcon color='primary' />}
        >
          Создать накладную
        </StyledButton>
        <ViewModeButtonGroup viewMode={viewMode} onViewChange={onViewChange}/>
      </Stack>
    </Stack>
  )
}
