import { AccordionSummary, Stack } from "@mui/material";
import styled from "styled-components";

export const RemarkAccordionTitleWrapper = styled(AccordionSummary)`
  padding: 0 0 12px 0;
  min-height: auto;

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const RemarkAccordionTitle = styled(Stack)`
  padding: 7px 12px;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 6px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;
