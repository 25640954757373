import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

export const PackIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M5.24922 17.0263C5.24922 16.5209 5.65894 16.1112 6.16435 16.1112H13.7841C14.2895 16.1112 14.6992 16.5209 14.6992 17.0263C14.6992 17.5317 14.2895 17.9415 13.7841 17.9415H6.16435C5.65894 17.9415 5.24922 17.5317 5.24922 17.0263Z'
      />
      <path
        d='M6.16435 13.0609C5.65894 13.0609 5.24922 13.4706 5.24922 13.976C5.24922 14.4814 5.65894 14.8911 6.16435 14.8911H11.0841C11.5895 14.8911 11.9992 14.4814 11.9992 13.976C11.9992 13.4706 11.5895 13.0609 11.0841 13.0609H6.16435Z'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.99922 5.7411V4.50039C2.99922 3.34059 3.93942 2.40039 5.09922 2.40039H18.8992C20.059 2.40039 20.9992 3.34059 20.9992 4.50039V8.18108H21.5992C22.262 8.18108 22.7992 8.71834 22.7992 9.38108V20.401C22.7992 21.0637 22.262 21.601 21.5992 21.601H2.39922C1.73648 21.601 1.19922 21.0637 1.19922 20.401V6.9411C1.19922 6.27836 1.73648 5.7411 2.39922 5.7411H2.99922ZM4.79922 4.50039C4.79922 4.33471 4.93353 4.20039 5.09922 4.20039H18.8992C19.0649 4.20039 19.1992 4.3347 19.1992 4.50039V8.18108H13.1361C12.8388 8.18108 12.5521 8.07073 12.3315 7.8714L10.3169 6.05079C10.0963 5.85146 9.80963 5.7411 9.51233 5.7411H4.79922V4.50039ZM13.1361 9.98108H20.9992V19.801H2.99922V7.5411H9.28139L11.1247 9.20687C11.6761 9.70519 12.3929 9.98108 13.1361 9.98108Z'
      />
    </SvgIcon>
  )
}
