import React, { useCallback, useState } from 'react'
import { Checkbox, FormControl, Grid, Stack, Typography } from '@mui/material'
import { EllipsisText, ObjectsSelect, ObjectsSelectMenuItem } from './styles'
import { theme } from '../../../../styles/theme'
import Button from '../../../Button'
import EditIcon from '@mui/icons-material/Edit'
import { AccessCardItem } from '../AccessCard/styles'
import { UserObjectsSelectionProps } from './UserObjectsSelection.types'
import { FieldArray, useFormikContext } from 'formik'
import { UserFormData } from '../../UserForm/UserForm.types'
import ObjectsIcon from '../../../../assets/icons/ObjectsIcon'
import Tooltip from '../../../Tooltip'

const ITEM_HEIGHT = 36
const ITEM_PADDING_TOP = 8
export const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const UserObjectsSelection: React.FC<UserObjectsSelectionProps> = ({
  objects,
  index,
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<UserFormData>()
  const isObjectSelected = !!(formValues.projects[index].objects.length || formValues.projects[index].allObjects)
  const [selectHidden, setSelectHidden] = useState<boolean>(isObjectSelected)
  const [open, setOpen] = useState<boolean>(false)

  const isAllSelected = formValues.projects[index].allObjects

  const onAddClick = useCallback(() => {
    setSelectHidden(false)
    setOpen(true)
  }, [])

  const onSelectOpen = useCallback(() => {
    setOpen(true)
    setSelectHidden(false)
  }, [])

  const onSelectClose = useCallback(() => {
    setOpen(false)
    if (isObjectSelected) {
      setSelectHidden(true)
    }
  }, [formValues.projects[index]])

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
      <Stack direction='row' alignItems='center' spacing={1} style={{ alignSelf: 'flex-start', height: 40 }}>
        <ObjectsIcon fontSize='medium' color='secondary' />
        <Typography
          variant='subtitle1'
          color={theme.palette.secondary.dark}
          fontWeight={500}
          component='span'
        >
          Объекты:
        </Typography>
      </Stack>

      <Stack spacing={2} style={{ width: '100%', maxWidth: '310px' }}>
        <FieldArray
          name={`projects[${index}].objects`}
          validateOnChange={false}
          render={() => {
            return (
              <>
                {isAllSelected
                  ? (
                    <AccessCardItem>
                      <Typography variant='subtitle2' component='span' style={{ color: theme.palette.primary.main }}>
                        Доступ ко всем объектам
                      </Typography>
                    </AccessCardItem>
                  )
                  : (
                    <Grid container spacing={2.5}>
                      {formValues.projects[index].objects.map((selectedObject) => {
                        const objectTodDisplay = objects.find(object => object.id === selectedObject.id)?.title
                        return (
                          <Grid item xs={6} key={selectedObject.id}>
                            <Tooltip title={objectTodDisplay && objectTodDisplay?.length > 11 ? <>{objectTodDisplay}</> : ''}>
                              <AccessCardItem isObject>
                                <EllipsisText>
                                  {objectTodDisplay}
                                </EllipsisText>
                              </AccessCardItem>
                            </Tooltip>
                          </Grid>
                        )
                      })}
                    </Grid>
                  )
                }

                {objects?.length
                  ? (
                    selectHidden
                      ? (
                        <Button
                          startIcon={<EditIcon fontSize='medium' />}
                          variant='text'
                          bgColor={theme.palette.bg.white}
                          onClick={onAddClick}
                          style={{ maxWidth: '300px', width: '100%', height: '44px' }}
                        >
                          Изменить объекты
                        </Button>
                      )
                      : (
                        <FormControl>
                          <ObjectsSelect
                            version='select'
                            name='helper'
                            value={formValues.projects[index].objects.map(object => object.id)}
                            SelectProps={{
                              multiple: true,
                              displayEmpty: true,
                              renderValue: () => (
                                <Typography component='span'>
                                  Выберите объекты
                                </Typography>
                              ),

                              onChange: (e) => {
                                const value = e.target.value as (number | 'all')[]
                                if (value[value.length - 1] === 'all') {
                                  setFieldValue(
                                    `projects[${index}].allObjects`,
                                    !isAllSelected
                                  )
                                  setFieldValue(`projects[${index}].objects`, [], false)
                                  return
                                }

                                if (isAllSelected) {
                                  setFieldValue(`projects[${index}].objects`, objects?.filter(object => object.id !== value[0]).map(object => ({
                                    id: object.id,
                                    name: ''
                                  })))
                                  setFieldValue(`projects[${index}].allObjects`, false)
                                  return
                                }

                                if (value?.length === objects?.length) {
                                  setFieldValue(`projects[${index}].objects`, [])
                                  setFieldValue(`projects[${index}].allObjects`, true)
                                  return
                                }

                                setFieldValue(`projects[${index}].objects`, value.sort().map(id => ({
                                  id,
                                  name: ''
                                })))
                              },
                              MenuProps: SelectMenuProps,
                              open: open,
                              onOpen: onSelectOpen,
                              onClose: onSelectClose,
                            }}
                          >
                            <ObjectsSelectMenuItem
                              value='all'
                            >
                              <Checkbox checked={isAllSelected} />
                              <Typography variant='body1'>Все объекты</Typography>
                            </ObjectsSelectMenuItem>
                            {
                              objects.map(object => (
                                <ObjectsSelectMenuItem value={object.id} key={object.id}>
                                  <Checkbox checked={isAllSelected
                                    ? true
                                    : formValues.projects[index].objects.findIndex(rootObject => rootObject.id === object.id) > -1
                                  } />
                                  <EllipsisText $maxWidth='330px' variant='body1'>{object.title}</EllipsisText>

                                </ObjectsSelectMenuItem>
                              ))
                            }
                          </ObjectsSelect>
                        </FormControl>
                      )
                  )
                  : (
                    <AccessCardItem>
                      <Typography variant='subtitle2' component='span'>
                        Объекты отсутствуют
                      </Typography>
                    </AccessCardItem>
                  )
                }
              </>
            )
          }}
        />
      </Stack>
    </Stack>
  )
}

export default UserObjectsSelection