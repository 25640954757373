import { FC, useCallback, useState } from 'react'
import { IIntegrationRequestDrawerProps } from './IntegrationRequestDrawer.types'
import { Drawer, Stack, Typography } from '@mui/material'
import { IntegrationRequestForm } from './components/IntegrationRequestForm'
import useConfirmDialog, { UseExitConfirmProps } from 'hooks/useConfirmDialog'

export const IntegrationRequestDrawer: FC<IIntegrationRequestDrawerProps> = ({ isOpen, onClose }) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  const onDrawerClose = (immediately?: boolean) => {
    if (immediately || !dirty) {
      onClose(false)
    } else {
      setConfirmDialogTrigger('close')
      openConfirm()
    }
  }

  const handleCloseManagment = useCallback((confirm: boolean) => {
    if (confirm) {
      onDrawerClose(true)
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<'close'>('close')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    close: { handleConfirm: handleCloseManagment },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  return (
    <Drawer anchor='right' open={isOpen} onClose={() => onDrawerClose()}>
      <Stack width={'400px'}>
        <Typography p={2.5} variant='body2' textAlign={'center'}>
          Оставьте заявку на подключение Tangl. <br />
          Мы свяжемся с Вами в ближайшее время.
        </Typography>
      </Stack>

      <IntegrationRequestForm onFormChange={onFormChange} onClose={onDrawerClose} />

      <ConfirmDialog />
    </Drawer>
  )
}
