import { IconButton, Stack, Typography } from "@mui/material"
import {
  MetaHeader,
  MetaInfoWrapper,
  RoundingWrapper,
  SnackBarText,
  StickyWrapper,
  StyledContainer,
  StyledControlButton,
  StyledIconButton,
  StyledInfoIcon,
  StyledTextField,
  StyledToggleButton,
  SubTitleText,
  TitleName,
  TitleText,
  WarningIcon
} from "./ModelMetaInfo.styles"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CancelIcon from '@mui/icons-material/Cancel';
import { ModelMetaInfoProps } from "./ModelMetaInfo.types";
import { useTypedSelector } from "store/store";
import { selectedFromModelElementsSelector, selectedFromTreeElementsSelector } from "store/slices/tim/selectors/tim.selectors";
import { useEffect, useMemo, useState } from "react";
import { metaManager } from "../TanglViewer/TanglManagers";
import { useSnackbar } from "notistack";
import { MetaInterface } from "types/models";
import { MetaInfoAccordion } from "../MetaInfoAccordion";
import Tooltip from "components/Tooltip";

export const ModelMetaInfo = ({ opened, onClose }: ModelMetaInfoProps) => {
  const selectedFromTreeElements = useTypedSelector(selectedFromTreeElementsSelector)
  const selectedFromModelElements = useTypedSelector(selectedFromModelElementsSelector)
  const { enqueueSnackbar } = useSnackbar()
  const [metaInfo, setMetaInfo] = useState<MetaInterface | undefined>(undefined)
  const [searchItem, setSearchItem] = useState<string>('')
  const [isRounding, setIsRounding] = useState<boolean>(false)
  const [precision, setPrecision] = useState<number>(3)

  const selectedElements = useMemo(() => {
    return selectedFromTreeElements.length > 0 ? selectedFromTreeElements : selectedFromModelElements
  }, [selectedFromModelElements, selectedFromTreeElements])

  const toggleRounding = () => {
    setIsRounding(prev => !prev)
  }

  const increasePrecision = () => {
    setPrecision(prev => prev + 1)
  }

  const decreasePrecision = () => {
    setPrecision(prev => (prev > 0 ? prev - 1 : 0))
  }

  const applyPrecision = (value: any): any => {
    if (typeof value === 'number') {
      return Number.isInteger(value) ? value : value.toFixed(precision)
    } else if (Array.isArray(value)) {
      return value.map(applyPrecision)
    } else if (typeof value === 'object' && value !== null) {
      const newObj: Record<string, any> = {}
      for (const key in value) {
        newObj[key] = applyPrecision(value[key])
      }
      return newObj
    }
    return value
  }

  const processedData = useMemo(() => {
    if (!metaInfo?.Meta.Element) return {}
    return isRounding ? applyPrecision(metaInfo.Meta.Element) : metaInfo.Meta.Element
  }, [metaInfo?.Meta.Element, isRounding, precision])

  useEffect(() => {
    if (opened && selectedElements.length === 1) {
      metaManager.getElementMetaByNumbers(selectedElements[0])
        .then((el) => {
          setMetaInfo(el)
        })
        .catch(err => enqueueSnackbar('Ошибка получения свойств элемента', { variant: 'error' }))
    } else {
      setMetaInfo(undefined)
    }
  }, [opened, selectedElements])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey || event.ctrlKey) {
        document.addEventListener('click', handleClick)
      } else {
        document.removeEventListener('click', handleClick)
      }
    }

    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (event.altKey && event.ctrlKey && target.dataset.type === 'key') {
        const text = target.textContent || ''
        setSearchItem(text)

      } else if (event.altKey) {
        const text = target.textContent || ''
        navigator.clipboard.writeText(text).then(() => {
          enqueueSnackbar(
            <Stack>
              <SnackBarText fontWeight={500} fontSize={14}>Скопировано в буфер</SnackBarText>
              <SnackBarText>{text}</SnackBarText>
            </Stack>,
            { variant: 'info' })
        })
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyDown)
      document.removeEventListener('click', handleClick)
    }
  }, [])



  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(event.target.value)
  }

  const clearValue = () => {
    setSearchItem('')
  }

  const isObject = (value: any): value is Record<string, any> => value && typeof value === 'object' && !Array.isArray(value)
  const isBoolean = (value: any): value is boolean => typeof value === 'boolean'

  const matchesSearch = (key: string, value: any): boolean => {
    const search = searchItem.toLowerCase()
    if (key.toLowerCase().includes(search)) return true
    if (typeof value === 'string' && value.toLowerCase().includes(search)) return true
    if (isBoolean(value) && value.toString().includes(search)) return true
    if (isObject(value)) return Object.keys(value).some(k => matchesSearch(k, value[k]))
    return false
  }


  const filterObject = (obj: Record<string, any>): Record<string, any> | null => {
    let result: Record<string, any> = {}
    let containsSearchTerm = false
  
    for (let key in obj) {
      if (matchesSearch(key, obj[key])) {
        result[key] = obj[key]
        containsSearchTerm = true
      } else if (isObject(obj[key])) {
        const nestedMatches = filterObject(obj[key])
        if (nestedMatches) {
          result[key] = nestedMatches
          containsSearchTerm = true
        }
      }
    }
  
    if (!containsSearchTerm) {
      return null
    }
  
    return result
  }
  
  const filteredData = useMemo(() => {
    const filtered = filterObject(processedData)
  
    const cleanResult = (obj: Record<string, any>): Record<string, any> => {
      let cleaned: Record<string, any> = {}
  
      for (let key in obj) {
        if (isObject(obj[key])) {
          const nestedCleaned = cleanResult(obj[key])
          if (nestedCleaned && Object.keys(nestedCleaned).length > 0) {
            cleaned[key] = nestedCleaned
          } else if (matchesSearch(key, obj[key])) {
            cleaned[key] = obj[key]
          }
        } else if (matchesSearch(key, obj[key])) {
          cleaned[key] = obj[key]
        }
      }
  
      return cleaned
    }
  
    return filtered ? cleanResult(filtered) : {}
  }, [processedData, searchItem])

  if (opened) {
    return (
      <StyledContainer open={true}>
        <MetaHeader>
          <Tooltip variant="light" maxWidth={260} title={<>Cкопировать в буфер обмена: ALT+Click. Отфильтровать: ALT+CTRL+Click.</>}>
            <StyledInfoIcon/>
          </Tooltip>
          <Typography variant="h2" fontWeight={500}>Свойства</Typography>
          <StyledIconButton onClick={onClose}>
            <HighlightOffIcon />
          </StyledIconButton>
        </MetaHeader>
        {!metaInfo ? (
          <Stack flex={1} justifyContent='center' px={2.5}>
            <Typography variant="h3" fontSize={16} fontWeight={500}>
              Выберите элемент модели для отображения его свойств.
            </Typography>
          </Stack>
        ) : (
          <>
            <StickyWrapper>
              <TitleName >{metaInfo.Name}</TitleName>
              <RoundingWrapper spacing={2} isRounding={isRounding}>
                <Typography variant="h3" fontWeight={500} textTransform='uppercase'>Точность округления</Typography>
                <Stack direction='row' justifyContent="space-between" alignItems="center">
                  <Stack direction='row' justifyContent='space-between' spacing={3} alignItems="center" width={206}>
                    <StyledControlButton variant="contained" onClick={decreasePrecision}>—</StyledControlButton>
                    <Typography fontSize={13} fontWeight={500} textTransform='uppercase'>{precision}</Typography>
                    <StyledControlButton variant="contained" onClick={increasePrecision}>+</StyledControlButton>
                  </Stack>
                  <Stack direction='row' spacing={1} alignItems="center">
                    {isRounding &&
                      <Tooltip variant="light" maxWidth={195} title={<>Некоторые величины отображаются с округлением.</>}>
                        <WarningIcon />
                      </Tooltip>
                    }
                    <StyledToggleButton onChange={toggleRounding} value='isRounding' selected={isRounding}>
                      {isRounding ? <VisibilityOffIcon fontSize="medium" /> : <VisibilityIcon color="primary" fontSize="medium" />}
                    </StyledToggleButton>
                  </Stack>
                </Stack>
              </RoundingWrapper>
              <StyledTextField
                size="small"
                variant="outlined"
                placeholder="Поиск"
                fullWidth
                value={searchItem}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: searchItem.length > 0 && (
                    <IconButton size="small" onClick={clearValue}>
                      <CancelIcon fontSize="small" color="secondary" />
                    </IconButton>
                  )
                }}
              />
            </StickyWrapper>
            <MetaInfoWrapper>
            <TitleText>ID</TitleText>
            <SubTitleText variant="body2" mb={1.5}>{metaInfo.Guid}</SubTitleText>
            <TitleText>Class</TitleText>
            <SubTitleText variant="body2" mb={1.5}>{metaInfo.Category}</SubTitleText>
            <TitleText>Name</TitleText>
            <SubTitleText variant="body2" mb={1.5}>{metaInfo.Name}</SubTitleText>
            <MetaInfoAccordion data={!searchItem.length ? processedData : filteredData} />
          </MetaInfoWrapper>
          </>
        )}
      </StyledContainer>
    )
  } else return null

}
