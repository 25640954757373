import styled from "styled-components"
import { UserDrawer, UserDrawerHeader, UserLogoWrapper, UserDesignLogo, UserDrawerContent } from "../../../../components/UserNavBar/styles"
import { Box, Typography } from "@mui/material"
import { TitledWrapper } from "../../../../components/TitledWrapper"

export const StyledDrawer = styled(UserDrawer)`
`

export const SchedulesDrawerHeader = styled(UserDrawerHeader)`
`

export const SchedulesLogoWrapper = styled(UserLogoWrapper)`
`

export const SchedulesDesignLogo = styled(UserDesignLogo)`
`
export const SchedulesDrawerContent = styled(UserDrawerContent)`
  padding: 10px 20px 20px 20px;
`
export const DrawerContentTextWrapper = styled(Box)`
  padding: 7px 16px;
  background-color: ${props => props.theme.palette.bg.shades};
  border: 1px solid rgba(92, 110, 140, 0.1);
  border-radius: 6px;
  overflow-wrap: anywhere;
`
export const SchedulesTitledWrapper = styled(TitledWrapper)`
    & .title {
    padding-left: 6px;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.palette.secondary.dark};
  }

  & .stack-wrapper {
    gap: 4px;
    padding: 10px;
    border-radius: 8px;

    & > div:last-of-type {
      margin: 0;
    }
  }
`
export const ProjectText = styled(Typography)`
  line-height: 22px;
  color: ${props => props.theme.palette.text.dark};
`
export const ProjectAccentText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: ${props => props.theme.palette.primary.main};
`
export const ElipsisText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`