import { EmployeeData } from '../projects/types';
import { ProjectPhase } from '../../types/project'

export type RoadmapPhase = 'РД' | 'ПД' | 'ИИ'
export const roadmapPhases: RoadmapPhase[] = ['РД', 'ПД', 'ИИ']
export interface MenuRoadmap {
  phase: RoadmapPhase
  title: string
  roadmaps: Roadmap[]
}

export interface Roadmap {
  id: number
  title: string
  number: number
  phase: RoadmapPhase
  person: StagePerson
  isBroken: boolean
  isLocked: boolean
  isMain: boolean
  duration: number | null
}


// Stages
export interface Stage {
  id: number;
  title: string;
  duration: number;
  idLeft?: number;
  idRight?: number;
  innermapId: number | null
  optDocs: boolean;
  optVisa: boolean;
  person: StagePerson;
  results: StageResult[];
  isAlone: boolean
  isStart: boolean
}

export interface StagePerson extends EmployeeData {
  avatar?: string
}

export interface StageResult {
  title: string
  type: boolean
  isFinal: boolean
  tomStatus?: AgreementTomStatus
  nextStage?: NextStage
  duration?: number
}

export interface NextStage extends Pick<Stage, 'id' | 'title' | 'duration'> {
}

export interface StageResultRequest extends Omit<StageResult, 'nextStage'> {
  nextStageId?: number | 'final'
}

export type ProjectAgreementPhase = 'Инженерные изыскания' | 'Проектная документация' | 'Рабочая документация'

export type AgreementTomStatus =
  'В производстве'
  | 'Согласовано'
  | 'Корректировка'
  | 'На согласовании'
  | 'Разработка'
  | 'Не в работе'

export const agreementTomStatusArray: AgreementTomStatus[] = [
  'В производстве',
  'Согласовано',
  'Корректировка',
  'На согласовании',
  'Разработка',
  'Не в работе'
]

export const agreementPhaseByProjectPhase: Record<ProjectPhase, RoadmapPhase> = {
  'Рабочая документация': 'РД',
  'Проектная документация': 'ПД',
  'Инженерные изыскания': 'ИИ',
  'Сбор исходных данных': 'ИИ',
}

export const projectPhaseByAgreementPhase: Record<RoadmapPhase, ProjectPhase> = {
  'РД': 'Рабочая документация',
  'ПД': 'Проектная документация',
  'ИИ': 'Инженерные изыскания',
}

//getProjectStatusStatistics
export interface ProjectStatusStatisticsCount {
  complete: number
  inside: number
  outside: number
}

export interface ProjectStatusStageStatistics {
  count: ProjectStatusStageCount
  name: string
  person: StagePerson
}

export interface ProjectStatusStageCount {
  now: number
  past: number
}