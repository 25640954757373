import { Avatar, Box, Button, Drawer, Stack, Typography } from "@mui/material"
import { DrawerTopBar, InnermapDrawerProps, InnermapDrawerWrapper } from "."
import Divider from "@components/Divider"
import { columns, StyledTitle, TableHeaderRow } from "@pages/DocView/components/InternalAgreementDrawer/components/SchemaTable"
import { DrawerBox } from "@pages/DocView/components/InternalAgreementDrawer"
import { StyledCell } from "@pages/DocView/components/InternalAgreementDrawer/components/SchemaTable/components/DepartmentRow"
import { ApprovalIcon, AssuranceIcon, IconWrapper, NameBlock, RemarksIcon, SigningIcon, StyledName, StyledPosition, TableRow } from "@pages/DocView/components/InternalAgreementDrawer/components/SchemaTable/components/MemberRow"
import { AgreementOverviewMemberAction } from "@api/agreementOverview/types"
import { ScrollableContainer } from "@styles/global/ScrollableContainer"

export const InnermapDrawer = ({ open, members, closeDrawer }: InnermapDrawerProps) => {

  const renderActions = (actions: AgreementOverviewMemberAction[]): JSX.Element => {
    const approval = actions.find(a => a.action === 'approval')
    const assurance = actions.find(a => a.action === 'assurance')
    const signing = actions.find(a => a.action === 'signing')
    const remarks = actions.find(a => a.action === 'remarks')
    return (
      <>
        <IconWrapper direction='row'>
          {approval?.datetime
            ? <Typography variant='body2'>
              {approval.datetime}
            </Typography>
            : <ApprovalIcon $approval={!!approval} fontSize='small' />
          }
        </IconWrapper>
        <IconWrapper direction='row'>
          {assurance?.datetime
            ? <Typography variant='body2'>
              {assurance.datetime}
            </Typography>
            : <AssuranceIcon $assurance={!!assurance} fontSize='small' />
          }
        </IconWrapper>
        <IconWrapper direction='row'>
          {signing?.datetime
            ? <Typography variant='body2'>
              {signing.datetime}
            </Typography>
            : <SigningIcon $signing={!!signing} fontSize='small' />
          }
        </IconWrapper>
        <IconWrapper direction='row'>
          {remarks?.datetime
            ? <Typography variant='body2'>
              {remarks.datetime}
            </Typography>
            : <RemarksIcon $remarks={!!remarks} fontSize='small' />
          }
        </IconWrapper>
      </>
    )
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={closeDrawer}>
      <InnermapDrawerWrapper>
        <DrawerTopBar>
          <Typography variant="h1">Процедура согласования</Typography>
        </DrawerTopBar>
        <Divider />
        <ScrollableContainer pt={2.5} pl={2.5}>
          <DrawerBox>
            <TableHeaderRow direction='row'>
              {columns.map((item, index) => (
                <Box key={index} padding={item.padding} width={item.width}>
                  <StyledTitle variant='body2' textAlign={item.textAlign}>
                    {item.title}
                  </StyledTitle>
                </Box>
              ))}
            </TableHeaderRow>
            {members?.map(({ actions, num, person }) =>
              <Stack direction='column' spacing={1} key={num}>
                <StyledCell>
                  <Typography variant='body2'>{num}</Typography>
                </StyledCell>
                <TableRow direction='row' spacing={1}>
                  <Stack direction='row' justifyContent='space-between' width='100%'>
                    <NameBlock direction='row' spacing={1}>
                      <Avatar src={person.avatar || ''} sx={{ width: '24px', height: '24px', marginLeft: '8px' }} />
                      <Stack direction='column'>
                        <StyledName>{person.name}</StyledName>
                        <StyledPosition>{person.position}</StyledPosition>
                      </Stack>
                    </NameBlock>
                    {renderActions(actions)}
                  </Stack>
                </TableRow>
              </Stack>
            )}
          </DrawerBox>
        </ScrollableContainer>
        <Stack m={2.5} position='sticky' bottom={0} alignItems='flex-end'>
          <Button onClick={closeDrawer}>Закрыть</Button>
        </Stack>
      </InnermapDrawerWrapper>
    </Drawer>
  )
}