import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

import { AccordionSubItemsData, ObjectAccordionProps } from './ObjectAccordion.types'
import {
  AccordionDocButton,
  CircleSelected,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './ObjectAccordion.styles'

export const ObjectAccordion: React.FC<ObjectAccordionProps> = ({ item, icon, onItemClick, expanded, onExpand }) => {
  const { id, title, hasSubItems, subItemsData, isSelectedObject } = item

  const itemClickHandler = () => {
    onExpand(id, false)
    onItemClick?.(id)
  }

  const expandClickHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    onExpand(id, expanded === id)
  }

  // render all sub items tree.
  // for callback will be provided event, all root ids and current item id
  const renderSubItems = (data: AccordionSubItemsData, rootItemsIds: number[]) => {
    const { onSubItemClick, icon, subItems } = data

    return subItems?.map((subItem) => {
      const allIds = [...rootItemsIds, subItem.id]
      return (
        <Stack pl={1.5}>
          <AccordionDocButton
            onClick={(e) => onSubItemClick?.(e, ...allIds)}
            variant='text'
            startIcon={icon}
            disabled={subItem.disabled}
            key={subItem.id}
          >
            <Typography variant='buttonSmall'>{subItem.title}</Typography>
            {subItem.isSelectedSubItem && <CircleSelected />}
          </AccordionDocButton>

          {subItem.subItemsData?.subItems?.length && renderSubItems(subItem.subItemsData, allIds)}
        </Stack>
      )
    })
  }

  return (
    <Box>
      <StyledAccordion expanded={expanded === id} onClick={() => itemClickHandler()}>
        <StyledAccordionSummary
          expandIcon={
            hasSubItems ? <ExpandMoreIcon onClick={expandClickHandler} fontSize='medium' color='primary' /> : null
          }
        >
          <Stack direction='row' spacing={1} alignItems='center' width='inherit'>
            {icon}
            <Typography variant='buttonSmall'>{title}</Typography>
            {isSelectedObject && <CircleSelected />}
          </Stack>
        </StyledAccordionSummary>
        {hasSubItems && <StyledAccordionDetails>{renderSubItems(subItemsData!, [id])}</StyledAccordionDetails>}
      </StyledAccordion>
    </Box>
  )
}