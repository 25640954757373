import React, { FC, useState } from 'react'
import { useFormikContext } from 'formik'
import {
  AttachmentsExportDrawerFilter,
  AttachmentsExportDrawerMainListWrapper,
} from './AttachmentsExportDrawerMainList.styles'
import { Box } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { SelectItem } from '../../../../../../components/FilterSelectMulti/FilterSelectMultiple.types'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import { AttachmentsExportAccordion } from '../AttachmentsExportAccordion'
import { AttachmentsExportFormData } from '../AttachmentsExportDrawerForm'
import { DocVariant } from '../../../../../../layouts/DocumentsLayout'
import { ExportDocWithVariant } from '../../../../../../api/attachments/types'

export const AttachmentsExportDrawerMainList: FC = () => {
  const { values: formValues, setFieldValue } = useFormikContext<AttachmentsExportFormData>()
  const { docList } = formValues

  const [selectedDocVariants, setSelectedDocVariants] = useState<DocVariant[]>([])

  const docVariantSelectItems: SelectItem<string>[] = [
    { value: 'version', title: 'Версия' },
    { value: 'change', title: 'Изменение' },
  ]

  // set 'hideByFilter' for all entities
  const onDocFilterSelect = (variants: DocVariant[]) => {
    const updatedDocs = docList.map<ExportDocWithVariant>(doc => {
      const hideDoc = !!variants.length && !variants.includes(doc.docVariant)

      return {
        ...doc,
        hideByFilter: hideDoc,
      }
    })

    setSelectedDocVariants(variants as DocVariant[])
    setFieldValue('docList', updatedDocs)
  }

  return (
    <AttachmentsExportDrawerMainListWrapper>
      <Box pr={2.5} pb={2}>
        <AttachmentsExportDrawerFilter
          items={docVariantSelectItems}
          value={selectedDocVariants}
          onChange={(value: any) => onDocFilterSelect(value as DocVariant[])}
          defaultValue='Тип документа'
          allSelectedText='Все типы'
          startIcon={<FilterAltIcon fontSize='large' color='secondary' />}
        />
      </Box>
      <ScrollableContainer spacing={1.5}>
        {docList?.map((doc, index) => (
          <AttachmentsExportAccordion
            doc={doc}
            docIndex={index}
            key={doc.number}
          />
        ))
        }
      </ScrollableContainer>
    </AttachmentsExportDrawerMainListWrapper>
  )
}
