import styled from 'styled-components'
import {
  DrawerContentTextWrapper,
  DrawerProjectDescription,
  DrawerProjectTitle,
  ProjectText
} from '../../../Objects/components/ObjectsNavBarContent/styles'
import { Button, FormControl, Stack } from '@mui/material'
import { TitledWrapper } from '../../../../components/TitledWrapper'
import { StyledSelectField } from '../../../../components/FieldForm/styles'

export const TitleWrapper = styled(TitledWrapper)`
  & .box-wrapper {
    padding: 10px 16px;
  }
`

export const EditButton = styled(Button)`
  height: 13px;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 13.2px;
  text-transform: lowercase;

  &:hover {
    background-color: transparent;
  }
`

export const DescriptionWrapper = styled(TitledWrapper)`
  & .box-wrapper {
    & .MuiTypography-root {
      max-height: calc((20px * 3)); // lineHeight * max line count
    }
  }
`

export const DateWrapper = styled(TitledWrapper)`
  & .stack-wrapper {
    gap: 8px;
  }
`

export const StatusWrapper = styled(TitledWrapper)`
  & .stack-wrapper {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 40px;

    & .MuiTypography-root {
      margin: 0;
      padding-top: 2px;
    }
  }
`


export const StatusSelectField = styled(StyledSelectField)`
  & .MuiInputBase-root {
    background-color: ${props => props.theme.palette.bg.white};
  }
`

export const DocInfoTextWrapper = styled(DrawerContentTextWrapper)`
  width: 100%;
  border: 0;
`

export const DocViewStatusSelectFormControl = styled(FormControl)`
  & .MuiInputBase-root {
    height: 40px;
    padding-left: 10px;

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.primary.light};
    }
  }

  & .MuiSelect-select {
    text-align: left;
    background-color: ${props => props.theme.palette.bg.white};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: ${props => `1px solid ${props.theme.palette.bg.gray}`};
    border-radius: 8px;
  }
`

export const DrawerDocTitle = styled(DrawerProjectTitle)`
`

export interface DocInfoDateRowsWrapperProps {
  dateExpired: boolean;
}

export const DocInfoDateRowsWrapper = styled(Stack)<DocInfoDateRowsWrapperProps>`
  & .row-value {
    height: 22px;
    padding: ${props => props.dateExpired ? '0 6px' : 0};
  }

  & .status-wrapper {
    height: 22px;
    padding: 0;
  }
`


export const DrawerDocDescription = styled(DrawerProjectDescription)`
`

export const DocInfoText = styled(ProjectText)`
`
