import AddIcon from '@mui/icons-material/Add'
import { Box, Grid, useMediaQuery } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetProjectsOverallQuery } from '../../api/projects'
import { GetProjectsOverallRequest, GetProjectsOverallResponse, ProjectStatusCount } from '../../api/projects/api.types'
import EmptyPage from '../../components/EmptyPage'
import { ButtonData, EmptyPageData } from '../../components/EmptyPage/EmptyPage.types'
import Progress from '../../components/Progress'
import { TabData } from '../../components/Tabs/Tabs.types'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import useSearch from '../../hooks/useSearch'
import { profileSelector } from '../../store/slices/profile'
import { useTypedSelector } from '../../store/store'
import { Limit } from '../../types/global'
import {
  ProjectPhase,
  ProjectStatuses,
  ProjectStatusesTabLabels,
  ProjectTabLabels,
  ProjectType,
  projectStatusesTabLabelRuByEn,
} from '../../types/project'
import { NUMBER_OF_ROWS_PER_USERS_PAGE, XXL_FOR_HOME_CARDS, XXXL_FOR_HOME_CARDS } from '../../utils/constants'
import HomeBottomLegend from './HomeBottomLegend'
import HomeCard from './HomeCard'
import HomeTopLegend from './HomeTopLegend'
import { FilterValues } from './HomeTopLegend/components/FilterSelectFields'

export const getEmptyPageData = (text: React.ReactNode, buttons?: ButtonData[]): EmptyPageData => ({
  text,
  buttons:
    buttons?.map((button) => ({
      text: button.text,
      icon: button.icon,
      onClick: button.onClick,
    })) || [],
})

const Home: React.FC = () => {
  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_HOME_CARDS})`)
  const xxxl = useMediaQuery(`(min-width: ${XXXL_FOR_HOME_CARDS})`)
  const navigate = useNavigate()
  const { searchValue } = useSearch()
  const profile = useTypedSelector(profileSelector)
  const { firstName, role } = profile

  const [currentTab, setCurrentTab] = useState<ProjectTabLabels>('Все проекты')

  const onTabChange = useCallback(
    (e: React.SyntheticEvent, tabValue: ProjectTabLabels) => {
      setCurrentTab(tabValue)
    },
    [setCurrentTab],
  )

  const [filteredParams, setFilteredParams] = useState<GetProjectsOverallRequest>({})
  const { data, isFetching } = useGetProjectsOverallQuery(filteredParams, {
    skip: !Object.keys(filteredParams).length,
  })
  const {
    statisticAverage,
    data: projectsWithStatistics,
    total,
    statusCount,
  } = data || ({} as GetProjectsOverallResponse)

  const [selectedProjectTypes, setSelectedProjectTypes] = useState<ProjectType[]>([])
  const [selectedProjectStages, setSelectedProjectStages] = useState<ProjectPhase[]>([])
  const [selectedRegions, setSelectedRegions] = useState<string[]>([])
  const [selectedClients, setSelectedClients] = useState<FilterValues[]>([])
  const [selectedArchitectors, setSelectedArchitectors] = useState<FilterValues[]>([])

  const [page, setPage] = useState(1)
  const [limitValue, setLimitValue] = useState<Limit['value']>(NUMBER_OF_ROWS_PER_USERS_PAGE[0].value)
  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page])

  const countPagination = useMemo(() => Math.ceil(total / limitValue) || 1, [total, limitValue])

  const hasValuesFilters: boolean = useMemo(() => {
    return Object.keys(filteredParams).some(
      (key) =>
        key !== 'offset' && key !== 'limit' && filteredParams[key as keyof GetProjectsOverallRequest] !== undefined,
    )
  }, [filteredParams])

  const handleChangeLimit = useCallback(
    (limit: number) => {
      setLimitValue(Number(limit))
      setPage(1)
    },
    [setLimitValue, setPage],
  )

  const handleChangePage = useCallback((page: number) => setPage(page), [setPage])

  const onFilterType = useCallback((value: ProjectType[]) => {
    setSelectedProjectTypes(value)
  }, [])

  const onFilterStage = useCallback((value: ProjectPhase[]) => {
    setSelectedProjectStages(value)
  }, [])

  const onFilterRegion = useCallback((value: string[]) => {
    setSelectedRegions(value)
  }, [])

  useEffect(() => {
    const selectedArchitectorCompanies = selectedArchitectors
      .filter((f) => f.company !== undefined)
      .map((i) => i.company!)
    const selectedClientCompanies = selectedClients.filter((f) => f.company !== undefined).map((i) => i.company!)
    const selectedClientIds = selectedClients.filter((f) => f.id).map((i) => i.id!)
    const selectedArchitecorIds = selectedArchitectors.filter((f) => f.id).map((i) => i.id!)
    const selectedClientNames = selectedClients.filter((f) => !f.id && f.name).map((i) => i.name!)
    const selectedArchitectorNames = selectedArchitectors.filter((f) => !f.id && f.name).map((i) => i.name!)

    const params: typeof filteredParams = {
      architector_company: selectedArchitectorCompanies.length ? selectedArchitectorCompanies : undefined,
      client_company: selectedClientCompanies.length ? selectedClientCompanies : undefined,
      architector_id: selectedArchitecorIds.length ? selectedArchitecorIds : undefined,
      client_id: selectedClientIds.length ? selectedClientIds : undefined,
      client_name: selectedClientNames.length ? selectedClientNames : undefined,
      architector_name: selectedArchitectorNames.length ? selectedArchitectorNames : undefined,
      phase: selectedProjectStages.length ? selectedProjectStages : undefined,
      type: selectedProjectTypes.length ? selectedProjectTypes : undefined,
      region: selectedRegions.length ? selectedRegions : undefined,
      search: searchValue.trim().length ? searchValue.trim() : undefined,
      limit: limitValue,
      offset: offset,
      status: currentTab,
    }
    setFilteredParams(params)
  }, [
    selectedProjectTypes,
    selectedProjectStages,
    selectedClients,
    selectedArchitectors,
    searchValue,
    selectedRegions,
    limitValue,
    offset,
    hasValuesFilters,
  ])

  useEffect(() => {
    setFilteredParams((p) => ({ ...p, status: currentTab }))
  }, [currentTab])

  useBreadcrumbs([{ title: 'Проекты' }])

  const tabsData: TabData<ProjectTabLabels>[] = useMemo(() => {
    if (!statusCount) return []

    const projectStatuses = Object.keys(statusCount) as ProjectStatuses[]

    const tabs = projectStatuses.map((key) => ({
      value: projectStatusesTabLabelRuByEn[key],
      label: `${projectStatusesTabLabelRuByEn[key]} (${statusCount[key]})`,
    }))

    return tabs
  }, [statusCount])

  const emptyPageDataForAdmin: EmptyPageData = getEmptyPageData(
    <>
      Здравствуйте, {firstName}, у Вас еще нет проектов для отображения.
      <br />
      Давайте создадим ваш первый проект.
    </>,
    [
      {
        text: 'Добавить проект',
        icon: AddIcon,
        onClick: () => navigate('/administration/projects/add'),
      },
    ],
  )

  const emptyPageDataForUser: EmptyPageData = getEmptyPageData(
    <>
      Здравствуйте, {firstName}, Вам еще не был предоставлен доступ к проектам,
      <br />
      либо проекты в системе отсутствуют.
    </>,
  )

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют проекты, соответствующие результатам запроса.</>,
  )

  return (
    <Box style={{ width: '100%', height: 'calc(100% - 66px)' }}>
      {isFetching ? (
        <Progress />
      ) : !projectsWithStatistics?.length && !hasValuesFilters ? (
        <EmptyPage data={role === 'admin' ? emptyPageDataForAdmin : emptyPageDataForUser} />
      ) : (
        <>
          <HomeTopLegend
            statistics={statisticAverage}
            selectedTypes={selectedProjectTypes}
            onTypeFilter={onFilterType}
            selectedStages={selectedProjectStages}
            onStageFilter={onFilterStage}
            selectedArchitectors={selectedArchitectors}
            selectedClients={selectedClients}
            setSelectedArchitectors={setSelectedArchitectors}
            setSelectedClients={setSelectedClients}
            selectedRegions={selectedRegions}
            onFilterRegion={onFilterRegion}
          />
          <HomeBottomLegend<ProjectTabLabels>
            currentTab={currentTab}
            onTabChange={onTabChange}
            tabsData={tabsData}
            countPagination={countPagination}
            limit={limitValue}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
            page={page}
            numberRows={NUMBER_OF_ROWS_PER_USERS_PAGE}
          />
          {projectsWithStatistics?.length ? (
            <Grid sx={{ py: 1.25 }} spacing={2.5} container>
              {projectsWithStatistics.map((project) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  xl={xxxl ? 2.4 : xxl ? 3 : 4}
                  container
                  justifyContent='center'
                  key={project.project.id}
                >
                  <HomeCard projectWithStatistics={project} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <EmptyPage data={emptyFilteredData} forFilter />
          )}
        </>
      )}
    </Box>
  )
}

export default Home
