import { Pd, Ppd, useGetPdByIdQuery, useGetPpdByIdQuery } from '../../../../../../api/pdPhase'
import { Ii, useGetIiByIdQuery } from '../../../../../../api/iiPhase'
import { Ird, useGetIrdByIdQuery } from '../../../../../../api/irdPhase'
import { UseGetItemProps, UseGetItemResponse } from './useGetItem.types'
import { Rdp, useGetRdpByIdQuery } from '../../../../../../api/rdPhase/rdp'

export const useGetItem = ({ variant, id, projectId }: UseGetItemProps): UseGetItemResponse => {
  // Rdp
  const { data: rdpData, isFetching: isRdpFetching } = useGetRdpByIdQuery({
    id: projectId!,
    rdpId: id!,
  }, {
    skip: variant !== 'rdp' || !projectId || !id,
  })
  const { data: currentRdp } = rdpData || {}


  // Pd
  const { data: pdData, isFetching: isPdFetching } = useGetPdByIdQuery({
    id: id!
  }, {
    skip: variant !== 'pd' || !id,
  })
  const { data: currentPd } = pdData || {}

  const { data: ppdData, isFetching: isPpdFetching } = useGetPpdByIdQuery({
    id: projectId!,
    ppdId: id!,
  }, {
    skip: variant !== 'ppd' || !projectId || !id,
  })
  const { data: currentPpd } = ppdData || {}


  // Ii
  const { data: iiData, isFetching: isIiFetching } = useGetIiByIdQuery({
    id: projectId!,
    iiId: id!,
  }, {
    skip: variant !== 'ii' || !projectId || !id,
  })
  const { data: currentIi } = iiData || {}


  // Ird
  const { data: irdData, isFetching: isIrdFetching } = useGetIrdByIdQuery({
    id: projectId!,
    irdId: id!,
  }, {
    skip: variant !== 'ird' || !projectId || !id,
  })
  const { data: currentIrd } = irdData || {}


  // Common
  const itemByVariant: Record<typeof variant, Rdp | Pd | Ppd | Ii | Ird> = {
    rdp: currentRdp!,
    pd: currentPd!,
    ppd: currentPpd!,
    ii: currentIi!,
    ird: currentIrd!,
  }

  const itemLoading = isRdpFetching || isPdFetching || isPpdFetching || isIiFetching || isIrdFetching

  return {
    itemByVariant: itemByVariant[variant],
    itemLoading,
  }
}
