import { UseGetTextInfoProps, UseGetTextInfoResponse } from './useGetTextInfo.types'

export const useGetTextInfo = ({ variant, id }: UseGetTextInfoProps): UseGetTextInfoResponse => {
  const title: Record<typeof variant, string> = {
    rdp: id ? 'Редактирование раздела РД' : 'Добавление раздела РД',
    pd: id ? 'Редактирование раздела ПД' : 'Добавление раздела ПД',
    ppd: id ? 'Редактирование раздела ПД' : 'Добавление раздела ПД',
    ii: id ? 'Редактирование раздела изысканий' : 'Добавление раздела изысканий',
    ird: id ? 'Редактирование раздела ИРД' : 'Добавление раздела ИРД',
  }

  const deleteConfirmText: Record<typeof variant, string> = {
    rdp: 'Вы действительно хотите удалить раздел рабочей документации? Это действие нельзя отменить.',
    pd: 'Вы действительно хотите удалить раздел проектной документации? Это действие нельзя отменить.',
    ppd: 'Вы действительно хотите удалить раздел проектной документации? Это действие нельзя отменить.',
    ii: 'Вы действительно хотите удалить раздел инженерных изысканий? Это действие нельзя отменить.',
    ird: 'Вы действительно хотите удалить раздел исходно-разрешительной документации? Это действие нельзя отменить.',
  }

  const deleteButtonText: Record<typeof variant, string> = {
    rdp: 'Удалить раздел РД',
    pd: 'Удалить раздел ПД',
    ppd: 'Удалить раздел ПД',
    ii: 'Удалить раздел изысканий',
    ird: 'Удалить раздел ИРД',
  }

  return {
    title: title[variant],
    deleteConfirmText: deleteConfirmText[variant],
    deleteButtonText: deleteButtonText[variant],
  }
}
