import styled from 'styled-components'
import { alpha, Stack } from '@mui/material'

export const ImageFieldWrapper = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 277px;
  height: 305px;

  background-color: ${props => alpha(props.theme.palette.primary.main, 0.08)};
  border-radius: 16px;

  &:hover {
    border: ${props => `1px solid ${props.theme.palette.primary.main}`};;
    cursor: pointer;
  }
`