import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Stack, Typography } from '@mui/material'
import { FilterSelectMultipleTwo } from 'components/FilterSelectMultiTwo'
import React, { useState } from 'react'
import { selectedProjectPhaseSelector } from 'store/slices/documentsPages/projectInfo'

import {
  DocsFilterStyledAccordion,
  DocsFilterStyledAccordionDetails,
  DocsFilterStyledAccordionSummary,
  DocsStyledSwitch,
} from '.'
import { DocsFilterItem, FilterType, docsConfigByPhase } from '../../../../layouts/DocumentsLayout'
import {
  availableRdsItemsSelector,
  filterDataSelector,
  setIsExpired,
  updateFilterData,
} from '../../../../store/slices/documentsPages/filter'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { useMainFilterData } from '../../hooks/useMainFilterData'

export const docsFilterItems: DocsFilterItem[] = [
  { value: 'none', title: 'Без документов' },
  { value: 'version', title: 'Есть версии' },
  { value: 'change', title: 'Есть изменения' },
]

export const DocsFilterAccordion: React.FC = () => {
  const [expanded, setExpanded] = useState(true)

  const changeExpanded = () => {
    setExpanded((prevState) => !prevState)
  }
  const dispatch = useAppDispatch()
  const { mainFilterItems, mainFilterValue, mainFilterOnChange } = useMainFilterData()

  const { availableRdsItems } = useTypedSelector(availableRdsItemsSelector)
  const { filterData } = useTypedSelector(filterDataSelector)
  const { selectedRdsIds, selectedDocs, isExpired } = filterData

  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  const subFilterOnChange = (selectedIds: number[], filterType: Exclude<FilterType, 'object' | 'pd'>) => {
    dispatch(updateFilterData({ selectedIds, filterType }))
  }
  const expiredOnChange = (newValue: boolean) => {
    dispatch(setIsExpired({ isExpired: newValue }))
  }

  return (
    <>
      <DocsFilterStyledAccordion
        expanded={expanded}
        onClick={(e) => {
          e.stopPropagation()
          changeExpanded()
        }}
      >
        <DocsFilterStyledAccordionSummary
          expandIcon={
            <ExpandMoreIcon
              color='primary'
              onClick={(e) => {
                e.stopPropagation()
                changeExpanded()
              }}
              fontSize='medium'
            />
          }
        >
          <Stack direction='row' spacing={1.25} alignItems='center' width='inherit'>
            <FilterAltIcon fontSize='small' color='primary' />
            <Typography variant='buttonSmall'>Фильтры</Typography>
          </Stack>
        </DocsFilterStyledAccordionSummary>
        <DocsFilterStyledAccordionDetails onClick={(e) => e.stopPropagation()}>
          <Typography variant='h4'>{docsConfigByPhase[selectedProjectPhase]?.docs.mainFilterDefaultValue!}</Typography>
          <FilterSelectMultipleTwo<number>
            items={mainFilterItems}
            value={mainFilterValue}
            onChange={(value: any) => mainFilterOnChange(value as number[])}
            defaultValue={docsConfigByPhase[selectedProjectPhase]?.docs.mainFilterDefaultValue!}
            allSelectedText={docsConfigByPhase[selectedProjectPhase]?.docs.mainFilterAllSelectedText}
          />

          {docsConfigByPhase[selectedProjectPhase]?.docs.subFilter ? (
            <>
              <Typography variant='h4'>Разделы РД</Typography>
              <FilterSelectMultipleTwo<number>
                items={availableRdsItems}
                value={selectedRdsIds}
                onChange={(value: any) => subFilterOnChange(value as number[], 'rd')}
                defaultValue='Разделы РД'
                allSelectedText='Все разделы РД'
              />
            </>
          ) : null}
          <Typography variant='h4'>Наличие документов</Typography>
          <FilterSelectMultipleTwo<string>
            items={docsFilterItems}
            value={selectedDocs}
            onChange={(value: any) => subFilterOnChange(value as number[], 'docs')}
            defaultValue='Наличие документов'
            allSelectedText='Все документы'
          />

          <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
            <DocsStyledSwitch
              type='checkbox'
              checked={isExpired}
              onChange={(_event, checked: boolean) => expiredOnChange(checked)}
            />
            <Typography color={'#2B3648'} variant='h3'>
              Просрочено
            </Typography>
          </Stack>
        </DocsFilterStyledAccordionDetails>
      </DocsFilterStyledAccordion>
    </>
  )
}
