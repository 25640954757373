import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import FolderIcon from 'assets/icons/FolderIcon'
import { DocsAccordionItemProps } from 'pages/Docs/components/DocsObjectAccordion'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import BookIcon from '../../../../assets/icons/BookIcon'
import EditDocIcon from '../../../../assets/icons/EditDocIcon'
import MultiBookIcon from '../../../../assets/icons/MultiBookIcon'
import ObjectsIcon from '../../../../assets/icons/ObjectsIcon'
import { filterDataSelector, updateFilterData } from '../../../../store/slices/documentsPages/filter'
import { selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { sidebarItemsSelector } from '../../../../store/slices/documentsPages/sidebar'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { ProjectPhase } from '../../../../types/project'
import { COMMON_DOCS_NUMBER } from '../../../../utils/constants'
import { UseGetNavbarItemsExpandedProps, UseGetNavbarItemsResponse } from './useGetNavbarItems.types'

export const useGetNavbarItems = (): UseGetNavbarItemsResponse => {
  const dispatch = useAppDispatch()

  /****************************************** useTypedSelector *************************************************/
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { sidebarObjects, sidebarPds, sidebarIis, sidebarIrds } = useTypedSelector(sidebarItemsSelector)
  const { filterData } = useTypedSelector(filterDataSelector)

  const { selectedObjectsIds, selectedRdsIds, selectedPdsIds, selectedIisIds, selectedIrdsIds } = filterData

  /****************************************** Unique IDs *************************************************/
  const uniqueObjectIDs = useMemo(() => Array.from(new Set(sidebarObjects.map((e) => e.obj.objID))), [sidebarObjects])
  const uniqueRdIDs = useMemo(
    () => Array.from(new Set(sidebarObjects.flatMap((e) => e.rdList.map((a) => a.rdID)))),
    [sidebarObjects],
  )
  const uniquePdsIDs = useMemo(() => Array.from(new Set(sidebarPds.map((e) => e.id))), [sidebarPds])
  const uniqueIiIDs = useMemo(() => Array.from(new Set(sidebarIis.map((e) => e.id))), [sidebarIis])
  const uniqueIrdIDs = useMemo(() => Array.from(new Set(sidebarIrds.map((e) => e.id))), [sidebarIrds])

  /****************************************** Expanded *************************************************/
  const [expanded, setExpanded] = useState<UseGetNavbarItemsExpandedProps>({
    isAll: true,
    items: [],
  })

  useEffect(() => {
    if (selectedProjectPhase) {
      setExpanded({ isAll: true, items: [] })
    }
  }, [selectedProjectPhase])
  /****************************************** Expand *************************************************/
  const onExpand = useCallback((panelId: number, isExit: boolean) => {
    setExpanded((prevState) => ({
      ...prevState,
      items: prevState.items.includes(panelId)
        ? prevState.items.filter((item) => item !== panelId)
        : isExit
          ? [panelId]
          : [...prevState.items, panelId],
    }))
  }, [])
  const onExpandAll = useCallback((isExit: boolean) => {
    setExpanded((prevState) => ({
      isAll: !prevState.isAll,
      items: isExit ? [] : prevState.items,
    }))
  }, [])

  /****************************************** RD *************************************************/
  const onObjectClick = useCallback(
    (objectId: number, rdIds: number[]) => {
      dispatch(updateFilterData({ selectedIds: [objectId], filterType: 'object' }))
      dispatch(updateFilterData({ selectedIds: rdIds, filterType: 'rd' }))
    },
    [dispatch],
  )

  const onRdClick = useCallback(
    (objectId: number, rdId: number) => {
      dispatch(updateFilterData({ selectedIds: [objectId], filterType: 'object' }))
      dispatch(updateFilterData({ selectedIds: [rdId], filterType: 'rd' }))
    },
    [dispatch],
  )

  const onAllRdClick = useCallback(() => {
    dispatch(updateFilterData({ selectedIds: uniqueObjectIDs, filterType: 'object' }))
    dispatch(updateFilterData({ selectedIds: uniqueRdIDs, filterType: 'rd' }))
  }, [dispatch, uniqueObjectIDs, uniqueRdIDs])

  const isAllRdItems = useMemo(
    () => uniqueObjectIDs.length === selectedObjectsIds.length && uniqueRdIDs.length === selectedRdsIds.length,
    [selectedObjectsIds.length, selectedRdsIds.length, uniqueObjectIDs.length, uniqueRdIDs.length],
  )

  const rdItems = useCallback((): DocsAccordionItemProps[] => {
    const itemChildren: DocsAccordionItemProps[] = sidebarObjects?.map((object) => {
      const { obj, rdList } = object

      const item: DocsAccordionItemProps = {
        id: obj.objID,
        title: `${obj.number} — ${obj.title}`,
        icon:
          obj.number === COMMON_DOCS_NUMBER ? (
            <BookIcon fontSize='small' color='secondaryDark' />
          ) : (
            <ObjectsIcon fontSize='small' color='secondaryDark' />
          ),
        level: 1,
        isSelected: isAllRdItems ? false : selectedObjectsIds.includes(obj.objID),
        isExpanded: expanded.items.includes(obj.objID),

        onItemClick: () => {
          onObjectClick(obj.objID, rdList.map((e) => e.rdID) ?? [])
        },
        onExpandedClick: () => {
          onExpand(obj.objID, true)
        },

        subItems: rdList?.map((rd) => ({
          id: rd.rdID,
          title: rd.mark,
          icon: <EditDocIcon fontSize='small' color='secondaryDark' />,
          level: 2,

          isSelected: isAllRdItems ? false : selectedRdsIds.includes(rd.rdID) && selectedObjectsIds.includes(obj.objID),
          isExpanded: expanded.items.includes(rd.rdID),

          onItemClick: () => {
            onRdClick(obj.objID, rd.rdID)
          },
          onExpandedClick: () => {
            // onExpand(rd.rdID, false)
          },
        })),
      }

      return item
    })
    const itemCommon: DocsAccordionItemProps = {
      id: 'all',
      level: 0,
      title: 'Рабочая документация',
      icon: <FolderIcon fontSize='small' color='secondaryDark' />,
      isSelected: isAllRdItems,
      isExpanded: expanded.isAll,
      subItems: itemChildren,
      onItemClick: () => {
        onAllRdClick()
      },
      onExpandedClick: () => {
        onExpandAll(false)
      },
    }

    return [itemCommon]
  }, [
    expanded.isAll,
    expanded.items,
    isAllRdItems,
    onAllRdClick,
    onExpand,
    onExpandAll,
    onObjectClick,
    onRdClick,
    selectedObjectsIds,
    selectedRdsIds,
    sidebarObjects,
  ])

  /****************************************** PD *************************************************/
  const onPdClick = useCallback(
    (pdId: number) => {
      dispatch(updateFilterData({ selectedIds: [pdId], filterType: 'pd' }))
    },
    [dispatch],
  )

  const onAllPdClick = useCallback(() => {
    dispatch(updateFilterData({ selectedIds: uniquePdsIDs, filterType: 'pd' }))
  }, [dispatch, uniquePdsIDs])

  const isAllPdItems = useMemo(
    () => sidebarPds.length === selectedPdsIds.length,
    [selectedPdsIds.length, sidebarPds.length],
  )

  const pdItems = useCallback((): DocsAccordionItemProps[] => {
    const itemChildren: DocsAccordionItemProps[] = sidebarPds?.map((object) => {
      const item: DocsAccordionItemProps = {
        id: object.id,
        title: object.mark,
        icon: <DescriptionOutlinedIcon fontSize='small' color='secondaryDark' />,
        level: 1,
        isSelected: isAllPdItems ? false : selectedPdsIds.includes(object.id),
        isExpanded: expanded.items.includes(object.id),

        onItemClick: () => {
          onPdClick(object.id)
        },
        onExpandedClick: () => {
          onExpand(object.id, true)
        },
      }

      return item
    })
    const itemCommon: DocsAccordionItemProps = {
      id: 'all',
      level: 0,
      title: 'Проектная документация',
      icon: <FolderIcon fontSize='small' color='secondaryDark' />,
      isSelected: isAllPdItems,
      isExpanded: expanded.isAll,
      subItems: itemChildren,
      onItemClick: () => {
        onAllPdClick()
      },
      onExpandedClick: () => {
        onExpandAll(false)
      },
    }

    return [itemCommon]
  }, [
    expanded.isAll,
    expanded.items,
    isAllPdItems,
    onAllPdClick,
    onExpand,
    onExpandAll,
    onPdClick,
    selectedPdsIds,
    sidebarPds,
  ])

  /****************************************** II *************************************************/
  const onIiClick = useCallback(
    (iiId: number) => {
      dispatch(updateFilterData({ selectedIds: [iiId], filterType: 'ii' }))
    },
    [dispatch],
  )

  const onAllIiClick = useCallback(() => {
    dispatch(updateFilterData({ selectedIds: uniqueIiIDs, filterType: 'ii' }))
  }, [dispatch, uniqueIiIDs])

  const isAllIiItems = useMemo(
    () => sidebarIis.length === selectedIisIds.length,
    [selectedIisIds.length, sidebarIis.length],
  )

  const iiItems = useCallback((): DocsAccordionItemProps[] => {
    const itemChildren: DocsAccordionItemProps[] = sidebarIis?.map((object) => {
      const item: DocsAccordionItemProps = {
        id: object.id,
        title: object.mark,
        icon: <DescriptionOutlinedIcon fontSize='small' color='secondaryDark' />,
        level: 1,
        isSelected: isAllIiItems ? false : selectedIisIds.includes(object.id),
        isExpanded: expanded.items.includes(object.id),

        onItemClick: () => {
          onIiClick(object.id)
        },
        onExpandedClick: () => {
          onExpand(object.id, true)
        },
      }

      return item
    })
    const itemCommon: DocsAccordionItemProps = {
      id: 'all',
      level: 0,
      title: 'Инженерные изыскания',
      icon: <FolderIcon fontSize='small' color='secondaryDark' />,
      isSelected: isAllIiItems,
      isExpanded: expanded.isAll,
      subItems: itemChildren,
      onItemClick: () => {
        onAllIiClick()
      },
      onExpandedClick: () => {
        onExpandAll(false)
      },
    }

    return [itemCommon]
  }, [
    expanded.isAll,
    expanded.items,
    isAllIiItems,
    onAllIiClick,
    onExpand,
    onExpandAll,
    onIiClick,
    selectedIisIds,
    sidebarIis,
  ])
  /****************************************** IRD *************************************************/
  const onIrdClick = useCallback(
    (irdId: number) => {
      dispatch(updateFilterData({ selectedIds: [irdId], filterType: 'ird' }))
    },
    [dispatch],
  )

  const onAllIrdClick = useCallback(() => {
    dispatch(updateFilterData({ selectedIds: uniqueIrdIDs, filterType: 'ird' }))
  }, [dispatch, uniqueIrdIDs])

  const isAllIrdItems = useMemo(
    () => sidebarIrds.length === selectedIrdsIds.length,
    [selectedIrdsIds.length, sidebarIrds.length],
  )

  const irdItems = useCallback((): DocsAccordionItemProps[] => {
    const itemChildren: DocsAccordionItemProps[] = sidebarIrds?.map((object) => {
      const item: DocsAccordionItemProps = {
        id: object.id,
        title: object.mark,
        icon: <DescriptionOutlinedIcon fontSize='small' color='secondaryDark' />,
        level: 1,
        isSelected: isAllIrdItems ? false : selectedIrdsIds.includes(object.id),
        isExpanded: expanded.items.includes(object.id),

        onItemClick: () => {
          onIrdClick(object.id)
        },
        onExpandedClick: () => {
          onExpand(object.id, true)
        },
      }

      return item
    })
    const itemCommon: DocsAccordionItemProps = {
      id: 'all',
      level: 0,
      title: 'Исходно-разрешительная документация',
      icon: <FolderIcon fontSize='small' color='secondaryDark' />,
      isSelected: isAllIrdItems,
      isExpanded: expanded.isAll,
      subItems: itemChildren,
      onItemClick: () => {
        onAllIrdClick()
      },
      onExpandedClick: () => {
        onExpandAll(false)
      },
    }

    return [itemCommon]
  }, [
    expanded.isAll,
    expanded.items,
    isAllIrdItems,
    onAllIrdClick,
    onExpand,
    onExpandAll,
    onIrdClick,
    selectedIrdsIds,
    sidebarIrds,
  ])

  /****************************************** Common *************************************************/
  const navbarItems: Record<ProjectPhase, DocsAccordionItemProps[]> = {
    'Рабочая документация': rdItems(),
    'Проектная документация': pdItems(),
    'Инженерные изыскания': iiItems(),
    'Сбор исходных данных': irdItems(),
  }

  const navButtonIcon: Record<ProjectPhase, ReactElement> = {
    'Рабочая документация': <ObjectsIcon />,
    'Проектная документация': <MultiBookIcon />,
    'Инженерные изыскания': <MultiBookIcon />,
    'Сбор исходных данных': <MultiBookIcon />,
  }

  /****************************************** Return *************************************************/
  return {
    navbarItems: navbarItems[selectedProjectPhase],
    navButtonIcon: navButtonIcon[selectedProjectPhase],
  }
}
