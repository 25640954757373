import { Drawer, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'

import Divider from '../../../../components/Divider'
import { UploadPropsData } from '../../../../components/FileUpload/FileUpload.types'
import { openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import { tomSelector } from '../../../../store/slices/documentsPages/tom'
import { useTypedSelector } from '../../../../store/store'
import { theme } from '../../../../styles/theme'
import { DocsDrawerTopBar, DocsDrawerWrapper } from '../DocsDrawer/DocsDrawer.styles'
import { DocsVersionDrawerProps } from './DocsVersionDrawer.types'
import { DocsVersionDrawerForm } from '../DocsVersionDrawerForm/DocsVersionDrawerForm'

export const DocsVersionDrawer: React.FC<DocsVersionDrawerProps> = ({ open, onClose }) => {
  const { tom } = useTypedSelector(tomSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)

  const isDocChange = openedDrawer === 'docChange'

  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  const uploadData: UploadPropsData = {
    title: 'Загрузить PDF',
    text: isDocChange
      ? 'Чтобы изменить документ, выберите его из папки на компьютере или просто перетяните его в эту область.'
      : 'Чтобы добавить документ к тому, выберите его из папки на компьютере или просто перетяните его в эту область.',
    formats: ['application/pdf'],
  }

  return (
    <Drawer anchor='right' open={open} onClose={() => onClose(dirty)}>
      <DocsDrawerWrapper>
        <DocsDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {isDocChange ? 'Изменение версии' : 'Добавление новой версии'}
          </Typography>
        </DocsDrawerTopBar>

        <Divider />

        <DocsVersionDrawerForm onClose={onClose} onFormChange={onFormChange} tom={tom!} uploadData={uploadData} />
      </DocsDrawerWrapper>
    </Drawer>
  )
}
