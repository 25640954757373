import { FC } from 'react'
import { StatisticsItemProps } from './StatisticsItem.types'
import { Box, Stack, Typography } from '@mui/material'
import {
  StatisticsItemDescription,
  StatisticsItemIconWrapper,
  StatisticsItemNumberWrapper,
  StatisticsItemWrapper
} from './StatisticsItem.styles'
import { theme } from '../../styles/theme'
import Tooltip from '../Tooltip'

export const StatisticsItem: FC<StatisticsItemProps> = ({
  onClick,
  title,
  subTitle,
  Icon,
  color = 'primary',
  format,
  numberValue,
  icons,
  checked,
}) => {

  return (
    <StatisticsItemWrapper onClick={onClick} checked={checked}>
      <Stack direction='row' justifyContent='space-between' alignItems='start' spacing={1.25}>
        <StatisticsItemIconWrapper color={color}>
          <Icon fontSize='medium' />
        </StatisticsItemIconWrapper>

        <Stack spacing={0.5}>
          <Box>
            <Typography variant='body2' lineHeight='100%' color={theme.palette.text.dark}>{title}</Typography>
          </Box>
          <Box>
            <Tooltip title={subTitle}>
              <StatisticsItemDescription
                variant='subtitle2'
                fontWeight={400}
                lineHeight='100%'
                color={theme.palette.secondary.dark}
              >
                {subTitle}
              </StatisticsItemDescription>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>

      {format === 'number' && (
        <StatisticsItemNumberWrapper>
          <Typography
            variant='subtitle2'
            fontWeight={400}
            fontSize={12}
            lineHeight='100%'
            color={theme.palette.secondary.gray}
          >
            {numberValue}
          </Typography>
        </StatisticsItemNumberWrapper>
      )}

      {/* TODO icons */}
    </StatisticsItemWrapper>
  )
}
