import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

const DownloadFileIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z'
      />
      <path d='M8 14.99L9.41 13.58L11 15.16V11H13V15.16L14.59 13.57L16 14.99L12.01 19L8 14.99Z' />
    </SvgIcon>
  )
}

export default DownloadFileIcon