import { LegendColors } from '../global'

export type WorkStatus = 'Выполнено' | 'В работе' | 'Не в работе'

export const workStatuses: WorkStatus[] = ['Выполнено', 'В работе', 'Не в работе']

export const workStatusesColors: Record<WorkStatus, LegendColors> = {
  'Выполнено': 'lightgreen',
  'В работе': 'blue',
  'Не в работе': 'disabled',
}