import { UseGetLegendPropsProps, UseGetLegendPropsResponse } from './useGetLegendProps.types'
import { ProjectPhase } from '../../../../types/project'
import { useTypedSelector } from '../../../../store/store'
import { defaultProjectInfoSelector, selectedProjectPhaseSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { rdViewTabSelector } from '../../../../store/slices/documentsPages/objects'
import { useLocation } from 'react-router-dom'

export const useGetLegendProps = ({
  isObjectsExist,
  isRdpsExist,
  isPdsExist,
  isIisExist,
  isIrdsExist,
}: UseGetLegendPropsProps): UseGetLegendPropsResponse => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const rdViewTab = useTypedSelector(rdViewTabSelector)
  const { iiAccess, irdAccess, pdAccess, rdAccess } = useTypedSelector(defaultProjectInfoSelector)
  const location = useLocation()
  const isTimPage = location.pathname.includes('tim')

  const showRdpSwitcher = selectedProjectPhase === 'Рабочая документация' || isTimPage
  const showObjSwitcher = selectedProjectPhase === 'Рабочая документация' || isTimPage
  const showUploadButton = true
  const showExportButton: Record<ProjectPhase, boolean> = {
    "Рабочая документация": rdAccess,
    "Проектная документация": pdAccess,
    "Инженерные изыскания": iiAccess,
    "Сбор исходных данных": irdAccess
  }

  //const showUploadButton = accessStatus[selectedProjectPhase]
  const showViewSwitcher: Record<ProjectPhase, boolean> = {
    'Рабочая документация': rdViewTab === 'objects' ? isObjectsExist : isRdpsExist,
    'Проектная документация': isPdsExist,
    'Инженерные изыскания': isIisExist,
    'Сбор исходных данных': isIrdsExist,
  }

  return {
    showUploadButton,
    showExportButon: showExportButton[selectedProjectPhase],
    showViewSwitcher: showViewSwitcher[selectedProjectPhase],
    showObjSwitcher,
    showRdpSwitcher
  }
}
