import styled from 'styled-components'
import { CardHeader } from '@mui/material'

export const StyledCardHeader = styled(CardHeader)`
  position: relative;
  padding: 0 0 20px 0;
  
  .MuiCardHeader-avatar {
    align-self: flex-start;
    
    & > div {
      & > div:first-child {
        position: absolute;
      }
    }
  }
  
  .MuiAvatar-root {
    margin-top: 29px;
  }

  .MuiCardHeader-content {
    align-self: flex-end;
    padding-top: 24px;
    text-align: left;
  }
`