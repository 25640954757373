import { Button, Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  background-color: ${props => props.theme.palette.bg.shades};
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  height: 36px;
`
export const SubtitleText = styled(Typography)`
  font-size: 12px;
  letter-spacing: 0.17px;
  color: ${props => props.theme.palette.secondary.gray};
`
export const ButtonWrapper = styled(Stack)`
  position: sticky;
  background: #fff;
  align-items: flex-end;
  padding: 20px 0;
  bottom: 0;
`