import FieldForm from "@components/FieldForm";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { InternalAgreementAutocomplete } from "@pages/Schemes/InternalAgreementSchemes/components/InternalAgreementPerson";
import styled from "styled-components";
import SearchIcon from '@mui/icons-material/Search';

export const RemarFormWrapper = styled(Stack)`
  height: 100%;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 8px;
`

export const RemarkFormHeader = styled(Typography)`
  padding: 20px 0 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #D1D8FA;
`
export const FieldsGroup = styled(Stack)`
  justify-content: space-between;
  align-items: center;
`
export const RemarkAutocomplete = styled(InternalAgreementAutocomplete)`
  max-width: 100%;
  height: 30px;

  & .MuiOutlinedInput-root {
    height: 32px;
    width: inherit;
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(12px, 5px) scale(1);
  }

  & .MuiAutocomplete-inputRoot {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }
  }

  & .MuiFormHelperText-root {
    display: none;
  }
`
export const DocumentText = styled(Typography)`
  color: ${props => props.theme.palette.text.dark};
  font-size: 14px;
  overflow-wrap: break-word;
  overflow: hidden;
`
export const RemarkTextField = styled(FieldForm)`
  //width: ${props => !props.multiline && '200px'};

  & .MuiFilledInput-root {
    height: ${props => props.multiline ? '120px' : '32px'};

    &.Mui-error {
      border: 1px solid ${props => props.theme.palette.warning.main};
    }

    &.Mui-focused  {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }

    & .MuiFilledInput-input {
      padding: ${props => props.multiline ? '16px' : '5px 12px'};
      height: ${props => props.multiline && 'inherit'}!important;
      box-sizing: border-box;
      overflow-y: auto!important;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  & .MuiFormHelperText-root {
    display: none;
  }
`
export const RemarkPageInput = styled(TextField)`
  & .MuiFilledInput-root {
    height: 32px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.bg.shades};
    border: 1px solid rgba(92, 110, 140, 0.1);
    border: ${(props) => props.error && `1px solid ${props.theme.palette.error.main}`};
    &.Mui-error {
      border: 1px solid ${props => props.theme.palette.warning.main};
    }

    &.Mui-focused  {
      border: 1px solid ${props => props.theme.palette.legends.border};
    }
  }
  & .MuiFilledInput-input {
    padding: 5px 12px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-transform: ${(props) => props.theme.typography.subtitle1.textTransform};
    color: ${(props) => props.theme.palette.text.dark};
    text-overflow: ellipsis;
    border-radius: 8px;

    &::placeholder {
      font-weight: ${(props) => props.theme.typography.body2.fontWeight};
      font-size: ${(props) => props.theme.typography.body2.fontSize};
      letter-spacing: 0.17px;
      text-transform: ${(props) => props.theme.typography.body2.textTransform};
      color: ${(props) => props.theme.palette.disabled};
      opacity: 1;
    }
  }
`
export const StyledSearchIcon = styled(SearchIcon)`
  color: ${props => props.theme.palette.primaryBorderLight};
`
export const TextSection = styled(Stack)`
  & ${FieldsGroup}:first-child {
    border-bottom: 1px solid #D1D8FA;
  }
`
export const TitleSection = styled(Typography)`
  margin-top: 8px;
  color: ${props => props.theme.palette.secondary.gray};
  padding-bottom: 10px;
`
export const StyledButton = styled(Button).attrs({ component: 'label' })`
  text-transform: none;
  font-weight: 400;
  height: 36px;
  font-size: 16px;
  padding: 5px 10px;
  background-color: ${props => props.theme.palette.bg.lightBlue};
  color: ${props => props.theme.palette.primary.main};
  margin-top: 8px;
  gap: 6px;

  :hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }
`
export const CircleInfoText = styled(Box)`
  display: flex;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  background-color: ${props => props.theme.palette.primary.main};

  & .MuiTypography-root {
    color: ${props => props.theme.palette.text.light};
    font-size: 14px;
    margin: auto;
    padding: 0 3px;
  }
`