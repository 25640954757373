import styled from 'styled-components'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

export const TextToggleButtonGroup = styled(ToggleButtonGroup)`
  width: 100%;
  gap: 4px;
  padding: 2px;
  background-color: ${props => props.theme.palette.bg.gray};
  border-radius: 8.91px;
`

export interface TextToggleButtonProps {
  variant?: 'primary' | 'shades'
}

export const TextToggleButton = styled(ToggleButton)<TextToggleButtonProps>`
  width: 100%;
  padding: 7px;
  
  border: none;
  border-radius: 6.93px !important;

  &,
  & .MuiTypography-root {
    font-size: 16px;
    text-transform: inherit;
    color: ${props => props.theme.palette.primary.main};
    letter-spacing: 0;
  }

  &.Mui-selected {
    background-color: ${props => props.theme.palette.primary.main};

    &,
    & .MuiTypography-root {
      color: ${props => props.theme.palette.bg.white};
    }

    &:hover {
      background-color: ${props => props.theme.palette.primary.main};
    }
  }
`
