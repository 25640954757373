import React, { ChangeEvent, FC, useState } from 'react'
import {
  ExportArchiveDrawerFilter,
  ExportArchiveDrawerFormControlLabel,
  ExportArchiveDrawerMainListWrapper
} from './styles'
import { Box, Stack } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { SelectItem } from '../../../../../../components/FilterSelectMulti/FilterSelectMultiple.types'
import { TomStatus, tomStatusesArray } from '../../../../../../types/tom'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import { useArchiveFilter } from './hooks/useArchiveFilter'
import { useRenderAccordions } from './hooks/useRenderAccordions'
import { useTypedSelector } from '../../../../../../store/store'
import { selectedProjectPhaseSelector } from '../../../../../../store/slices/documentsPages/projectInfo'
import { StyledCheckbox } from '../../../../../../components/FieldForm/styles'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

export const ExportArchiveDrawerMainList: FC = () => {
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)

  const [selectedTomStatuses, setSelectedTomStatuses] = useState<TomStatus[]>([])
  const [isDocExistFilter, setIsDocExistFilter] = useState<boolean>(false)

  const statusSelectItems: SelectItem<string>[] = ['В производстве', ...tomStatusesArray]
    .filter(status => {
      if (status === 'В производстве') return selectedProjectPhase === 'Рабочая документация'
      if (status === 'Не согласуется') return selectedProjectPhase === 'Сбор исходных данных'
      return true
    })
    .map(status => ({
      value: status,
      title: status,
    }))

  const { onFilterSelect } = useArchiveFilter()
  const { accordions } = useRenderAccordions()

  const onStatusFilter = (value: any) => {
    onFilterSelect(value as TomStatus[], isDocExistFilter)
    setSelectedTomStatuses(value as TomStatus[])
  }

  const onDocExistFilter = (e: ChangeEvent<HTMLInputElement>) => {
    onFilterSelect(selectedTomStatuses, e.target.checked)
    setIsDocExistFilter(e.target.checked)
  }

  return (
    <ExportArchiveDrawerMainListWrapper>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3} pr={2.5} pb={2}>
        <ExportArchiveDrawerFilter
          items={statusSelectItems}
          value={selectedTomStatuses}
          onChange={onStatusFilter}
          defaultValue='Статус тома'
          allSelectedText='Все типы'
          paperFullHeight
          startIcon={<FilterAltIcon fontSize='large' color='secondary' />}
        />

        <ExportArchiveDrawerFormControlLabel
          control={
            <StyledCheckbox
              checked={isDocExistFilter}
              onChange={onDocExistFilter}
              icon={isDocExistFilter
                ? <CheckBoxIcon color='error' />
                : <CheckBoxOutlineBlankIcon />
              }
            />
          }
          label={
            <Box>
              Есть документы
            </Box>
          }
          sx={{ mr: 0 }}
        />
      </Stack>
      <ScrollableContainer spacing={1.5}>
        {accordions}
      </ScrollableContainer>
    </ExportArchiveDrawerMainListWrapper>
  )
}
