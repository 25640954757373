import { IconButton, Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledListHeader = styled(Stack)`
  border-bottom: 1px solid ${props => props.theme.palette.legends.gray};
  padding: 0 0 6px 6px;
  align-items: center;
  justify-content: space-between;
`
export const StyledText = styled(Typography)`
  color: ${props => props.theme.palette.secondary.gray};
`

export const StyledIconButton = styled(IconButton)`
  padding: 6px;
  border-radius: 6px;
  align-self: center;
  background-color: ${props => props.theme.palette.bg.lightBlue};

  &:hover {
    background-color: ${props => props.theme.palette.legends.lightBlue};
  }
`