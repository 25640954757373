import styled from 'styled-components'
import { alpha, Box, Button, Stack, Typography } from '@mui/material'
import { LegendColors } from '../../../../types/global'
import { AgreementCardAvatarWrapper } from '../../../Schemes/ProjectAgreement/components/ProjectAgreementCard'

export const PocketViewTomCardWrapper = styled(Stack)`
  width: 100%;
  height: 279px;
  padding: 20px;
  text-align: left;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 6px 20px 2px rgba(16, 24, 40, 0.10), 0px 7px 8px -3px rgba(16, 24, 40, 0.08), 0px -6px 12px 0px rgba(16, 24, 40, 0.08);
  }
  
  & .MuiDivider-root {
    margin-top: 2px;
    border-color: #D1D8FA;
  }
`

interface PocketViewTomCardStatusProps {
  bgColor: LegendColors
}

export const PocketViewTomCardStatus = styled(Stack)<PocketViewTomCardStatusProps>`
  justify-content: center;
  width: fit-content;
  height: 24px;
  margin-bottom: 16px;
  padding: 4px 6px;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${props => props.theme.palette.text.light};
  background-color: ${props => props.theme.palette.legends[props.bgColor]};
  border-radius: 4px;
`

export const PocketViewTomCardTitle = styled(Typography)`
  margin-bottom: 4px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PocketViewTomCardSubtitle = styled(Typography)`
  min-height: 40px;
  max-height: 40px;
  margin-bottom: 16px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-break: anywhere;
`

interface PocketViewTomCardStageTitleProps {
  disabled: boolean
}

export const PocketViewTomCardStageTitle = styled(Typography)<PocketViewTomCardStageTitleProps>`
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0;
  color: ${props => props.disabled && props.theme.palette.disabled};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface AgreementCardDateProps {
  error: boolean
}

export const AgreementCardDate = styled(Box)<AgreementCardDateProps>`
  padding: 2px 4px;
  color: ${props => props.error && props.theme.palette.error.main};
  background-color: ${props => props.error && alpha(props.theme.palette.legends.red, 0.15)};
  border-radius: 4px;

  & .MuiTypography-root {
    color: inherit;
  }
`

export const PocketViewTomCardAvatarWrapper = styled(AgreementCardAvatarWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => alpha(props.theme.palette.secondary.gray!, 0.3)};

  & .MuiSvgIcon-root {
    padding: 0;
    color: ${props => props.theme.palette.bg.white};
  }
`
export const PocketStatusInfo = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 32px;
  background-color: ${props => props.theme.palette.bg.shades};
`
