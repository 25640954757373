import { EventNote as EventNoteIcon, Person as PersonIcon } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { FC } from 'react'

import { DocsChangeFormFieldItem } from '@pages/Docs'

import { theme } from '@styles/theme'

import { RoundedItem, TerminateTomInfoWrapper } from './TeminateTomInfo.styles'
import { ITeminateTomInfoProps } from './TeminateTomInfo.types'

export const TeminateTomInfo: FC<ITeminateTomInfoProps> = ({ data }) => {
  const { annulledDate, annulledPerson } = data || {}

  return (
    <TerminateTomInfoWrapper>
      <Typography variant='subtitle2' fontSize={16} color={theme.palette.warning.main}>
        Том аннулирован
      </Typography>

      <DocsChangeFormFieldItem title='Дата:' icon={<EventNoteIcon fontSize='small' color='secondary' />}>
        <RoundedItem>
          <Typography variant='body2' color={theme.palette.text.dark}>
            {annulledDate}
          </Typography>
        </RoundedItem>
      </DocsChangeFormFieldItem>

      <DocsChangeFormFieldItem title='Ответственный:' icon={<PersonIcon fontSize='small' color='secondary' />}>
        <Typography variant='body2' color={theme.palette.text.dark}>
          {annulledPerson?.name}
        </Typography>
      </DocsChangeFormFieldItem>
    </TerminateTomInfoWrapper>
  )
}
