import AddIcon from '@mui/icons-material/Add'
import AppsIcon from '@mui/icons-material/Apps'
import DownloadingIcon from '@mui/icons-material/Downloading'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import TocIcon from '@mui/icons-material/Toc'
import { Box, Pagination, Stack } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useExportPdMutation } from '../../../../api/pdPhase'
import { ExportIcon } from '../../../../assets/icons/ExportIcon'
import Button from '../../../../components/Button'
import FilterSelect from '../../../../components/FilterSelect'
import { FilterSelectMultiple } from '../../../../components/FilterSelectMulti'
import { SelectItem } from '../../../../components/FilterSelectMulti/FilterSelectMultiple.types'
import Tooltip from '../../../../components/Tooltip'
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { PagesViewMode, ViewMode, adminPdViewSelector, setPageViewMode } from '../../../../store/slices/ui'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { theme } from '../../../../styles/theme'
import { allProjectsTypes, projectTypeShort } from '../../../../types/project'
import { downloadBlob } from '../../../../utils/dowloadBlob'
import { StyledToggleButton, StyledToggleButtonGroup } from '../AdminRdLegend/styles'
import { PdLegendProps } from './PdLegend.types'

const PdLegend: React.FC<PdLegendProps> = ({
  onAddClick,
  onUploadClick,
  selectedTypes,
  onTypeFilter,
  page,
  countPagination,
  numberRows,
  onChangeLimit,
  onChangePage,
  limit,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('user')

  const viewMode = useTypedSelector(adminPdViewSelector)

  const onViewChange = useCallback((updatedViewMode: ViewMode) => {
    if (updatedViewMode !== null) {
      dispatch(setPageViewMode({ page: 'adminPd', viewMode: updatedViewMode }))
    }
  }, [])

  useEffect(() => {
    const cachedPagesViewMode: PagesViewMode = JSON.parse(localStorage.getItem('pagesViewMode') || '{}')
    if (cachedPagesViewMode?.adminPd) {
      dispatch(setPageViewMode({ page: 'adminPd', viewMode: cachedPagesViewMode.adminPd }))
    }
  }, [])

  const typeSelectItems: SelectItem<string>[] = allProjectsTypes.map((type) => ({
    value: type,
    title: projectTypeShort[type],
  }))

  const [exportPd, exportPdResponse] = useExportPdMutation()

  const onPdExportClick = () => {
    exportPd()
  }

  useMutationHandlers(exportPdResponse, (data: Blob) => {
    downloadBlob(new File([data], ''), `ПД.xlsx`)
  })

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1.5 }}>
        <Stack direction='row' alignItems='center' spacing={2.5}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Button startIcon={<AddIcon />} onClick={onAddClick}>
              Добавить
            </Button>
            <Button startIcon={<DownloadingIcon />} onClick={onUploadClick}>
              Загрузить
            </Button>
            <Tooltip title='Экспортировать разделы в Excel'>
              <span>
                <Button onClick={onPdExportClick} icon>
                  <ExportIcon />
                </Button>
              </span>
            </Tooltip>
          </Stack>

          <FilterSelectMultiple<string>
            items={typeSelectItems}
            value={selectedTypes}
            onChange={(value: any) => {
              onTypeFilter(value as string[])
            }}
            defaultValue='Тип проекта'
            allSelectedText='Все типы'
            startIcon={<FilterAltIcon fontSize='medium' color='dark' />}
          />
        </Stack>

        <Stack spacing={5} direction='row' justifyContent='center' alignItems='center'>
          <FilterSelect items={numberRows!} value={limit!} onChange={onChangeLimit!} startIcon={t('header.rows')} />
          <Pagination
            onChange={(e, v) => onChangePage!(v)}
            count={countPagination}
            shape='rounded'
            page={page}
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: 'rgba(109, 154, 220, 0.2)',
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '20px !important',
                },
              },
            }}
          />
          <StyledToggleButtonGroup value={viewMode} exclusive onChange={(e, newValue) => onViewChange(newValue)}>
            <StyledToggleButton value='list'>
              <AppsIcon />
            </StyledToggleButton>
            <StyledToggleButton value='table'>
              <TocIcon />
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Stack>
      </Stack>
    </Box>
  )
}

export default PdLegend
