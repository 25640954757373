import React, { useCallback, useState } from 'react'
import { Box, Stack } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Button from '../../Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonData, UserButtonsProps } from './UserButtons.types'
import { useAppDispatch, useTypedSelector } from 'store/store'
import { openedConfirmSelector, setOpenedConfirm } from 'store/slices/documentsPages/drawerInfo'
import useConfirmDialog from 'hooks/useConfirmDialog'
import { Link } from 'react-router-dom';
import Tooltip from '@components/Tooltip';

const UserButtons: React.FC<UserButtonsProps> = ({ role }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isAdminPage: boolean = location.pathname.split('/')[1] === 'administration'
  const { openedConfirm } = useTypedSelector(openedConfirmSelector)
  const [localUrl, setLocalUrl] = useState<string | null>(null)
  const dispatch = useAppDispatch()

  const adminButtonData: ButtonData = {
    text: 'Администрирование',
    icon: <EditIcon />,
    href: '/administration/users',
  }
  const viewButtonData: ButtonData = {
    text: 'Режим просмотра',
    icon: <VisibilityIcon />,
    href: '/home',
  }

  const buttonData = isAdminPage ? viewButtonData : adminButtonData

  const onButtonClick = (url: string) => {
    if (openedConfirm) {
      setLocalUrl(url)
      openConfirm()
    } else navigate(url)
  }

  const handleConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        navigate(localUrl!)
        dispatch(setOpenedConfirm({ openedConfirm: false }))
      }
    },
    [localUrl],
  )

  const { ConfirmDialog, openConfirm } = useConfirmDialog({ handleConfirm })

  return (
    <Box>
      <Stack direction='row' spacing={1.5}>
        {/*{[<NotificationsIcon />, <HelpOutlineIcon />].map((icon, index) => (*/}
        {/*  <Button icon key={index}>*/}
        {/*    {icon}*/}
        {/*  </Button>*/}
        {/*))}*/}
        <Tooltip title={<>Руководство пользователя</>}>
          <Link to={'//design.pragma.info/files/Design_User_Guide.pdf'} target='_blank' >
            <Button icon >
              <HelpOutlineIcon />
            </Button>
          </Link>
        </Tooltip>
        {role === 'admin' ? (
          <Button customSize='medium' startIcon={buttonData.icon} onClick={() => onButtonClick(buttonData.href)}>
            {buttonData.text}
          </Button>
        ) : null}
      </Stack>

      <ConfirmDialog />
    </Box>
  )
}

export default UserButtons
