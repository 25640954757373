import React from 'react'
import UserCardHeader from './UserCardHeader'
import Divider from '../../../../components/Divider'
import { UserCardContentProps } from './UserCardContent.types'
import { UserCardInfo } from './UserCardInfo'

export const UserCardContent: React.FC<UserCardContentProps> = ({ userData, employeeData, mode }) => {
  return (
    <>
      <UserCardHeader userData={userData} employeeData={employeeData} mode={mode} />
      <Divider />
      <UserCardInfo userData={userData} employeeData={employeeData} mode={mode} />
    </>
  )
}
