import { Stack } from "@mui/material";
import styled from "styled-components";

export const ManualSubmitDrawerWrapper = styled(Stack)`
  height: 100%;
  width: 1300px;
  overflow: hidden;
`
export const ManualSubmitDrawerHeader = styled(Stack)`
  padding: 20px 20px 15px;

  & .MuiTypography-root {
    color: ${props => props.theme.palette.primary.main};
  }
`