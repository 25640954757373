import { FC } from 'react'
import { IIntegrationStatusProps } from './IntegrationStatus.types'
import { Stack, Typography } from '@mui/material'
import {
  tanglIntegrationStatusColorByEn,
  tanglIntegrationStatusIconByEn,
  tanglIntegrationStatusRuByEn,
} from 'api/settings/types'

export const IntegrationStatus: FC<IIntegrationStatusProps> = ({ status, ...props }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={1} {...props}>
      {tanglIntegrationStatusIconByEn[status]}
      <Typography
        textAlign={'start'}
        color={tanglIntegrationStatusColorByEn[status]}
        variant='body2'
        lineHeight={'normal'}
      >
        {tanglIntegrationStatusRuByEn[status]}
      </Typography>
    </Stack>
  )
}
