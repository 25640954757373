import React, { useCallback } from 'react'
import { useField } from 'formik'
import { FieldHookConfig } from 'formik/dist/Field'
import { RadioGroup, RadioGroupProps } from '@mui/material'

export type RadioGroupFieldFormProps = {
  name: string;
  fieldProps?: Partial<FieldHookConfig<string>>;
} & Omit<RadioGroupProps, 'name'>;

export const RadioGroupFieldForm: React.FC<RadioGroupFieldFormProps> = ({
  fieldProps,
  onBlur,
  children,
  ...props
}) => {
  const [{ onBlur: onFieldBlur, ...field }] = useField({
    name: props.name,
    ...fieldProps,
  })

  const handleBlur = useCallback(
    (e: any) => {
      onFieldBlur?.(e)
      onBlur?.(e)
    },
    [onBlur, onFieldBlur]
  )

  return (
    <RadioGroup {...field} onBlur={handleBlur} {...props}>
      {children}
    </RadioGroup>
  )
}
