import { AgreementPocketTom, AgreementPocketTomStatus } from '../../../../api/agreementPocket/types'
import { LegendColors } from '../../../../types/global'

export interface PocketViewTomCardProps {
  tomData: AgreementPocketTom
}

export const pocketStatusColorByValue: Record<AgreementPocketTomStatus, LegendColors> = {
  'ПРОХОЖДЕНИЕ': 'violet',
  'ПРОЙДЕНО': 'chartsGreen',
  'ОЖИДАЕТ ПОДТВЕРЖДЕНИЯ': 'lightOrange'
}