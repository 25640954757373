import { FC } from 'react'
import { IBadgeProps } from './Badge.types'
import { StyledBadge } from './Badge.styles'

export const Badge: FC<IBadgeProps> = ({ color, children, ...props }) => {
  return (
    <StyledBadge {...props} variant='dot' _color={color}>
      {children}
    </StyledBadge>
  )
}
