import styled from 'styled-components'
import { Card } from '@mui/material'

interface StyledCardProps {
  textColor?: 'dark';
}

export const FullUserCardWrapper = styled(Card)<StyledCardProps>`
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 6px -3px rgba(74, 104, 176, 0.08),
    0px 9px 12px 1px rgba(109, 154, 220, 0.08),
    0px 3px 16px 2px rgba(109, 154, 220, 0.08);

  &:hover {
    cursor: pointer;
    box-shadow: ${props => props.theme.shadows[20]};
  }

  & .MuiCardHeader-root .MuiTypography-body2 {
    color: ${props => props.theme.palette.text.dark};
  }

  & .MuiCardContent-root .MuiTypography-body2 {
    color: ${props => props.textColor ? props.theme.palette.text.dark : props.theme.palette.secondary.gray};
  }
`