import { MouseEvent, useCallback } from 'react'
import { Avatar, Box, Divider, IconButton, Stack, Typography } from "@mui/material"
import VerifiedIcon from '@mui/icons-material/Verified'
import EditIcon from '@mui/icons-material/Edit';
import { CardSubtitle, CardWrapper, ElipsisText, ScheduleCardIcon, ScheduleColoredInfo } from "./ScheduleCard.styles"
import { ScheduleCardProps } from "./ScheduleCard.types"
import { OverflowText } from "../../../../components/OverflowText/OverflowText";
import { useNavigate } from "react-router-dom";
import { determineTomType } from "../../../../types/tom";
import { SchedulesFormValues } from "../SchedulesDrawer";

export const ScheduleCard = ({ card, onEdit }: ScheduleCardProps) => {
  const navigate = useNavigate()

  const { author, id, isMain, createdAt, title, description, phase } = card

  const onCardClick = () => {
    navigate(`${id}`)
  }

  const handleEdit = useCallback((e: MouseEvent) => {
    const data: SchedulesFormValues = {
      description,
      isMain,
      title,
      phase,
      scheduleId: id
    }
    onEdit(e, data)
  }, [description, id, isMain, onEdit, phase, title])

  return (
    <CardWrapper onClick={onCardClick}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' alignItems='center' gap={1}>
          {phase && <ScheduleColoredInfo customColor='blue'>
            <Typography variant='tooltip' style={{ marginTop: 2 }}>{determineTomType(phase)}</Typography>
          </ScheduleColoredInfo>}
          <ScheduleColoredInfo
            direction='row'
            alignItems='center'
            spacing={1}
            customColor='secondary'
          >
            <Typography variant='tooltip' style={{ marginTop: 2 }}>Дата создания: {createdAt || '—'}</Typography>
          </ScheduleColoredInfo>
        </Stack>
        <IconButton color="primary" size="medium" onClick={handleEdit}>
          <EditIcon fontSize="medium" />
        </IconButton>
      </Stack>
      <OverflowText
        text={title}
        fontWeight={500}
        defaultHeight={42}
        marginTop={2.5}
        marginBottom={1.5} />
      <CardSubtitle>Автор</CardSubtitle>
      <Divider />
      <Stack direction='row' spacing={1.5} alignItems='center' mt={0.5}>
        <Avatar src={author?.avatar} sx={{ height: 32, width: 32 }} />
        {author ? (
        <Box textAlign='start' width='calc(100% - 40px)'>
          <ElipsisText>{author?.company || ''}</ElipsisText>
          <Typography variant='body1' fontSize={14}>{author?.name || ''}</Typography>
        </Box>
        ) : (
          <Typography variant='body1' fontSize={14}>—</Typography>
        )}
      </Stack>
      {isMain &&
        <ScheduleCardIcon>
          <VerifiedIcon fontSize='medium' />
        </ScheduleCardIcon>}
    </CardWrapper>
  )
}