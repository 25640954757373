import { FC } from 'react'
import { Stack, Typography } from '@mui/material'
import { StyledTableCell } from '../../WorksTable.styles'
import { TABLE_CELL_HEIGHT } from '../../WorksTable.types'
import { SelfCellProps } from '../cells.types'

export const RatioCell: FC<SelfCellProps> = ({
  work,
  ...cellProps
}) => {
  const { ratio } = work

  return (
    <StyledTableCell
      {...cellProps}
    >
      <Stack height={TABLE_CELL_HEIGHT} justifyContent='center'>
        <Typography variant='body2'>
          {/* @ts-ignore*/}
          {new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 }).format(ratio[cellProps?.dataVariant] || 0)} %
        </Typography>
      </Stack>
    </StyledTableCell>
  )
}
