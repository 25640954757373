import { Typography } from '@mui/material'
import styled from 'styled-components'

interface IStyledOverflowTextNewProps {
  rows: number
}

export const StyledOverflowTextNew = styled(Typography)<IStyledOverflowTextNewProps>`
  display: -webkit-box;
  // display: ${(props) => (props.rows > 1 ? '-webkit-box' : 'block')};
  -webkit-line-clamp: ${(props) => props.rows};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-all;
`
