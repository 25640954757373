import styled from 'styled-components'
import { Stack } from '@mui/material'

export const ProjectAgreementWrapper = styled(Stack)`
  flex: 1;
  gap: 20px;
  max-width: 100%;
  margin-top: 10px;
  padding: 30px;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 16px;
  overflow-y: auto;
`