import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Drawer, Stack, Typography } from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../../../components/Button'
import DeleteConfirm from '../../../../components/DeleteConfirm'
import Divider from '../../../../components/Divider'
import { UploadPropsData } from '../../../../components/FileUpload/FileUpload.types'
import { openedDrawerSelector } from '../../../../store/slices/documentsPages/drawerInfo'
import { defaultProjectInfoSelector } from '../../../../store/slices/documentsPages/projectInfo'
import { tomSelector } from '../../../../store/slices/documentsPages/tom'
import { profileSelector } from '../../../../store/slices/profile'
import { useTypedSelector } from '../../../../store/store'
import { theme } from '../../../../styles/theme'
import { formatName } from '../../../../utils/formatName'
import { useAutocompleteTom } from '../../hooks/useAutocompleteTom'
import { useDeleteTom } from '../../hooks/useDeleteTom'
import { AutocompleteData, DocsDrawerForm } from '../DocsDrawerForm'
import { DocsDrawerTopBar, DocsDrawerWrapper } from './DocsDrawer.styles'
import { DocsDrawerProps } from './DocsDrawer.types'

export const DocsDrawer: FC<DocsDrawerProps> = ({ open, onClose }) => {
  const navigate = useNavigate()

  const profile = useTypedSelector(profileSelector)
  const { project } = useTypedSelector(defaultProjectInfoSelector)
  const { tom } = useTypedSelector(tomSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)

  const { role, lastName, firstName, middleName } = profile

  const defaultClientName = role === 'client' ? formatName(lastName, firstName, middleName) : null
  const defaultArchitectorName = role === 'architector' ? formatName(lastName, firstName, middleName) : null

  const isEdit = openedDrawer === 'update' && tom?.id
  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  const onDeleteButtonClick = useCallback(() => {
    setIsDeleteClicked(true)
  }, [])

  const onDeleteSuccess = useCallback(() => {
    onClose(false, true)
    navigate('./toms')
  }, [onClose, navigate])

  const onDeleteError = useCallback(() => {
    setIsDeleteClicked(false)
  }, [setIsDeleteClicked])

  const { onDeleteSubmit } = useDeleteTom({ onDeleteSuccess, onDeleteError })

  const onDeleteCancel = useCallback(() => {
    setIsDeleteClicked(false)
  }, [])

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  const uploadData: UploadPropsData = useMemo(
    () => ({
      title: 'Загрузить PDF',
      text: 'Чтобы добавить документ к тому, выберите его из папки на компьютере или просто перетяните его в эту область.',
      formats: ['application/pdf'],
    }),
    [],
  )

  const autocompleteTomData = useAutocompleteTom()

  const autocompleteData: AutocompleteData = {
    projectName: project.shifrName,
    ...autocompleteTomData,
    defaultArchitectorName,
    defaultClientName,
  }

  useEffect(() => {
    if (!open) {
      setIsDeleteClicked(false)
    }
  }, [open])

  return (
    <Drawer anchor='right' open={open} onClose={() => onClose(dirty)}>
      <DocsDrawerWrapper>
        <DocsDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {isEdit ? 'Редактирование тома' : 'Создание тома'}
          </Typography>
        </DocsDrawerTopBar>
        <Divider />

        {isEdit ? (
          <>
            <DocsDrawerForm onClose={onClose} onFormChange={onFormChange} doc={tom} uploadData={uploadData} />

            <Stack justifyContent='space-between' alignItems='center' flex={1} sx={{ p: 2.5, width: '100%' }}>
              <Box style={{ width: '100%', paddingBottom: 24 }}>
                {isDeleteClicked ? (
                  <DeleteConfirm
                    text='Вы действительно хотите удалить том?  Это действие нельзя отменить.'
                    onDelete={onDeleteSubmit}
                    onCancel={onDeleteCancel}
                  />
                ) : null}
              </Box>
              <Box style={{ width: 'fit-content' }}>
                <Button onClick={onDeleteButtonClick} startIcon={<DeleteIcon />} variant='text' color='error'>
                  Удалить том
                </Button>
              </Box>
            </Stack>
          </>
        ) : (
          <DocsDrawerForm
            onClose={onClose}
            onFormChange={onFormChange}
            uploadData={uploadData}
            autocompleteData={autocompleteData}
          />
        )}
      </DocsDrawerWrapper>
    </Drawer>
  )
}
