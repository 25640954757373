import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Menu, MenuItem, Stack, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'

import FilterSelect from '@components/FilterSelect'
import { FilterSelectNew } from '@components/FilterSelectNew'

import { DocsFilterStyledAccordionDetails, DocsFilterStyledAccordionSummary } from '@pages/Docs'

import { setRemarkGroupFilter } from '@store/slices/remarks/remarks'
import { remarkGroupFilterSelector } from '@store/slices/remarks/selectors/remarks.selectors'

import { DocsFilterItem } from '../../../../layouts/DocumentsLayout'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { RemarksFilterStyledAccordion } from './RemarksFilterAccordion.styles'
import {
  IRemarkTypeDataItem,
  IRemarksFilterAccordionProps,
  TRemarkType,
  remarkTypes,
} from './RemarksFilterAccordion.types'
import { RemarkAuthorSelect } from './components/RemarkAuthorSelect'
import { RemarkResponsibleSelect } from './components/RemarkResponsibleSelect'

export const docsFilterItems: DocsFilterItem[] = [
  { value: 'none', title: 'Без документов' },
  { value: 'version', title: 'Есть версии' },
  { value: 'change', title: 'Есть изменения' },
]

export const RemarksFilterAccordion: FC<IRemarksFilterAccordionProps> = ({ authors, companies }) => {
  const [expanded, setExpanded] = useState(true)
  const groupFilter = useTypedSelector(remarkGroupFilterSelector)
  const { second } = groupFilter || {}
  const selectedType: TRemarkType = second ? 'Повторные' : second === false ? 'Первичные' : 'Все замечания'

  const changeExpanded = () => {
    setExpanded((prevState) => !prevState)
  }

  const dispatch = useAppDispatch()

  const onChangeType = (value: any) => {
    dispatch(
      setRemarkGroupFilter({ remarkGroupFilter: { ...groupFilter, second: value === 'all' ? undefined : value } }),
    )
  }

  const typeData: IRemarkTypeDataItem[] = [
    { value: 'all', label: 'Все замечания' },
    { value: false, label: 'Первичные' },
    { value: true, label: 'Повторные' },
  ]

  return (
    <>
      <RemarksFilterStyledAccordion
        expanded={expanded}
        onClick={(e) => {
          e.stopPropagation()
          changeExpanded()
        }}
      >
        <DocsFilterStyledAccordionSummary
          expandIcon={
            <ExpandMoreIcon
              color='primary'
              onClick={(e) => {
                e.stopPropagation()
                changeExpanded()
              }}
              fontSize='medium'
            />
          }
        >
          <Stack direction='row' spacing={1.25} alignItems='center' width='inherit'>
            <FilterAltIcon fontSize='small' color='primary' />
            <Typography variant='buttonSmall'>Фильтры</Typography>
          </Stack>
        </DocsFilterStyledAccordionSummary>
        <DocsFilterStyledAccordionDetails onClick={(e) => e.stopPropagation()}>
          <Typography variant='h4'>Тип замечаний</Typography>
          <FilterSelectNew
            items={typeData}
            onChange={(v: string) => onChangeType(v as TRemarkType)}
            value={selectedType}
            sx={{}}
          />

          <Typography variant='h4'>Автор</Typography>
          <RemarkAuthorSelect authors={authors} />

          <Typography variant='h4'>Ответственный</Typography>
          <RemarkResponsibleSelect companies={companies} />
        </DocsFilterStyledAccordionDetails>
      </RemarksFilterStyledAccordion>
    </>
  )
}
