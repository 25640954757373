import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'

import { theme } from '@styles/theme'

import { StatisticsItem } from '../../../../components/StatisticsItem'
import { TitledWrapper } from '../../../../components/TitledWrapper'
import {
  currentDocumentSelector,
  docsSelector,
  setCurrentDocument,
  tomSelector,
} from '../../../../store/slices/documentsPages/tom'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { ScrollableContainer } from '../../../../styles/global/ScrollableContainer'
import { TomDocument } from '../../../../types/tom'
import { convertDateTime } from '../../../../utils/formatDateAndTimeGMT'

export const DocViewNavBarContentAttachments: FC = () => {
  const dispatch = useAppDispatch()

  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled = tom?.features.annulment.annulled

  const { tomVersions, tomChanges } = useTypedSelector(docsSelector)
  const { currentDocument } = useTypedSelector(currentDocumentSelector)

  const onDocItemClick = (pdf: TomDocument) => {
    dispatch(setCurrentDocument({ currentDocument: pdf }))
  }

  return (
    <ScrollableContainer spacing={2}>
      {isAnnulled && (
        <Typography variant='body2' color={theme.palette.warning.main} textAlign={'center'}>
          Добавление доп. файлов недоступно для тома с признаком “Аннулирован”.
        </Typography>
      )}

      <Stack spacing={2}>
        <TitledWrapper title='Дополнительные файлы к версиям' variant='accordion' defaultExpanded>
          <Stack spacing={0.75}>
            {tomVersions?.map((tomVersion) => {
              const parsedDate = convertDateTime(tomVersion.createdAt, true, true)
              const subTitle = [parsedDate, tomVersion.uploadedBy].join(', ')

              return (
                <StatisticsItem
                  onClick={() => onDocItemClick(tomVersion)}
                  title={`Файлы к версии ${tomVersion.version}`}
                  subTitle={subTitle}
                  Icon={FolderOpenOutlinedIcon}
                  checked={tomVersion.docId === currentDocument.docId}
                  format='number'
                  numberValue={tomVersion.attachCount}
                />
              )
            })}
          </Stack>
        </TitledWrapper>

        <TitledWrapper title='Дополнительные файлы к изменениям' variant='accordion' defaultExpanded>
          <Stack spacing={0.75}>
            {tomChanges?.map(({ doc }) => {
              const parsedDate = convertDateTime(doc.createdAt, true, true)
              const subTitle = [parsedDate, doc.uploadedBy].join(', ')

              return (
                <StatisticsItem
                  onClick={() => onDocItemClick(doc)}
                  title={`Файлы к изменению ${doc.change}`}
                  subTitle={subTitle}
                  Icon={FolderOpenOutlinedIcon}
                  checked={doc.docId === currentDocument.docId}
                  format='number'
                  numberValue={doc.attachCount}
                />
              )
            })}
          </Stack>
        </TitledWrapper>
      </Stack>
    </ScrollableContainer>
  )
}
