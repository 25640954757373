import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DrawerInfoState } from './drawerInfo.types'
import { OnDrawerClosePayload, SetOpenedConfirmPayload, SetOpenedDrawerPayload } from './drawerInfo.payloads.types'


let initialState: DrawerInfoState = {
  openedDrawer: null,
  openedConfirm: false,
}


const drawerInfoSlice = createSlice({
  name: 'drawerInfoState',
  initialState,
  reducers: {
    setOpenedDrawer: (state, { payload }: PayloadAction<SetOpenedDrawerPayload>) => {
      const { openedDrawer } = payload

      state.openedDrawer = openedDrawer
    },
    setOpenedConfirm: (state, { payload }: PayloadAction<SetOpenedConfirmPayload>) => {
      const { openedConfirm } = payload

      state.openedConfirm = openedConfirm
    },
    onDrawerClose: (state, { payload }: PayloadAction<OnDrawerClosePayload>) => {
      const { dirty, immediately = false } = payload

      immediately || !dirty
        ? drawerInfoSlice.caseReducers.setOpenedDrawer(state, {
          type: 'setOpenedDrawer',
          payload: {
            openedDrawer: null,
          }
        })
        : drawerInfoSlice.caseReducers.setOpenedConfirm(state, {
          type: 'setOpenedConfirm',
          payload: {
            openedConfirm: true,
          }
        })
    },
  },
})

export const { reducer: drawerInfoReducer } = drawerInfoSlice
export const { setOpenedDrawer, setOpenedConfirm, onDrawerClose } = drawerInfoSlice.actions
