import React, { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { BindDrawer, WorksDrawerFormProps, WorksFormData } from './WorksDrawerForm.types'
import { Form, FormikProvider } from 'formik'
import { Box, Stack, Typography } from '@mui/material'
import { ScrollableContainer } from '../../../../../styles/global/ScrollableContainer'
import FieldForm from '../../../../../components/FieldForm'
import SelectFormField from '../../../../../components/SelectFormField'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonIcon from '@mui/icons-material/Person'
import Button from '../../../../../components/Button'
import { useForm } from '../../../../../hooks/useForm'
import { WorksDrawerFormWrapper, WorksDrawerNumberField } from './WorksDrawerForm.styles'
import { workStatuses } from '../../../../../types/works'
import {
  useCreateWorkMutation,
  useEditTomStatusMutation,
  useFullEditWorkMutation,
  useGetWorkByIdQuery,
  useGetWorkGroupsQuery,
  Work,
  WorkBind,
  WorkDates,
  WorkPerson,
  WorkRatio,
  WorksRequest
} from '../../../../../api/works'
import { useParams } from 'react-router-dom'
import MappingIcon from '../../../../../assets/icons/MappingIcon'
import { formatDateToString, formatToDate } from '../../../../../utils/formatDate'
import { DATE_FORMAT } from '../../../../../utils/constants'
import { validationWork } from './validation'
import { useMutationHandlers } from '../../../../../hooks/useMutationHandlers'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import useFilteredEmployees from '../../../../../hooks/useFilteredEmployees'
import NumberListIcon from '../../../../../assets/icons/NumberListIcon'
import NumberIcon from '../../../../../assets/icons/NumberIcon'
import GradingIcon from '@mui/icons-material/Grading'
import { allProjectsPhases } from '../../../../../types/project'
import ObjectsIcon from '../../../../../assets/icons/ObjectsIcon'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import EditDocIcon from '../../../../../assets/icons/EditDocIcon'
import { useGetBindSelectsData } from './hooks/useGetBindSelectsData'
import { ObjDrawer } from './components/ObjDrawer'
import { OptionData } from '../../../../../components/SelectFormField/SelectFormField.types'
import { TomDrawer } from './components/TomDrawer'
import { StatusCellView } from '../../WorksTable/cells/StatusCell'
import { TomStatus, tomStatusesArray } from '../../../../../types/tom'
import DateRangeIcon from '@mui/icons-material/DateRange'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { CalendarWithClockIcon } from '../../../../../assets/icons/CalendarWithClockIcon'
import { PercentCircleIcon } from '../../../../../assets/icons/PercentCircleIcon'
import Tooltip from '../../../../../components/Tooltip'

export const WorksDrawerForm: FC<WorksDrawerFormProps> = ({ chosenWorkId, numbersOfsubGroup, onFormChange, onClose }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const { projectId: projectIdStr, scheduleId: scheduleIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)
  const scheduleId = parseInt(scheduleIdStr!)

  const { data: currentWork } = useGetWorkByIdQuery(
    { id: projectId, scheduleId, workId: chosenWorkId! },
    { skip: !chosenWorkId }
  )

  const { data: workGroupsData } = useGetWorkGroupsQuery({ id: projectId, scheduleId })
  const { data: workGroups = [] } = workGroupsData || {}

  const { admins, architectors, clients, contractors } = useFilteredEmployees()
  const workPersons = [...admins, ...architectors, ...clients, ...contractors]

  const [createWork, createWorkResponse] = useCreateWorkMutation()
  const [fullEditWork, fullEditWorkResponse] = useFullEditWorkMutation()
  const [editTomStatus] = useEditTomStatusMutation()

  const {
    title,
    numGroup,
    numWork,
    status,
    group,
    period,
    person,
    bind,
    ratio,
    description,
  } = currentWork || {}
  const { dateFactEnd, dateFactStart, datePlanStart, datePlanEnd } = period?.dates || {}
  const { employeeId, employeeName } = person || {}
  const { phase: boundPhase, obj: boundObj, tom: boundTom } = bind || {}
  const { weight, ready } = ratio || {}

  const initialValues: WorksFormData = useMemo(() => ({
    title: title || '',
    number: (numGroup && numWork) ? `${numGroup}.${numWork}` : '',
    group: group || '',
    status: status || '',
    tomStatus: bind?.status || '',
    personData: employeeId || employeeName || '',
    phase: boundPhase || '',
    obj: boundObj || '',
    tom: boundTom || '',
    datePlanStart: formatToDate(datePlanStart),
    datePlanEnd: formatToDate(datePlanEnd),
    dateFactStart: formatToDate(dateFactStart),
    dateFactEnd: formatToDate(dateFactEnd),
    weight,
    ready,
    description: description || '',
  }), [currentWork])

  const onSubmit = useCallback((values: WorksFormData) => {
    const { phase, obj, tom } = values

    const [numGroupStr, numWorkStr] = values.number.split('.')
    const baseWorkInfo: Pick<Work, 'title' | 'numGroup' | 'numWork' | 'status' | 'group' | 'description'> = {
      title: values.title,
      numGroup: Number(numGroupStr),
      numWork: Number(numWorkStr),
      status: values.status || 'В работе',
      group: values.group,
      description: values.description,
    }

    const personData = values.personData
    const person: WorkPerson = {
      employeeId: typeof personData === 'number' ? personData : undefined,
      employeeName: typeof personData === 'string' ? personData : '',
    }

    const bind: WorkBind = {
      phase: phase || undefined,
      obj: typeof obj === 'number' ? obj : undefined,
      tom: typeof tom === 'number' ? tom : undefined,
    }

    const dates: WorkDates = {
      datePlanStart: (values?.datePlanStart && formatDateToString(values.datePlanStart, DATE_FORMAT)) || '',
      datePlanEnd: (values?.datePlanEnd && formatDateToString(values.datePlanEnd, DATE_FORMAT)) || '',
      dateFactEnd: (values?.dateFactEnd && formatDateToString(values.dateFactEnd, DATE_FORMAT)) || undefined,
      dateFactStart: (values?.dateFactStart && formatDateToString(values.dateFactStart, DATE_FORMAT)) || undefined,
    }

    const ratio: Partial<Pick<WorkRatio, 'ready' | 'weight'>> = {
      weight: values?.weight || undefined,
      ready: values?.ready || undefined,
    }

    const dataForSubmit: WorksRequest = {
      ...baseWorkInfo,
      ...person,
      bind,
      ...dates,
      ...ratio,
    }


    chosenWorkId
      ? fullEditWork({ id: projectId, scheduleId, workId: chosenWorkId, ...dataForSubmit, })
      : createWork({ id: projectId, scheduleId, ...dataForSubmit, })
  }, [chosenWorkId])

  const { formik } = useForm({
    validationSchema: validationWork,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isSubmitting } = formik

  const { objData, tomData, tomInfo } = useGetBindSelectsData({
    projectId,
    phase: values.phase || undefined,
    selectedObjId: typeof values.obj === 'number' ? values.obj : undefined,
  })

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  const changeBoundTomStatusClick = useCallback((status: TomStatus) => {
    if (!values.phase || !values.tom || typeof values.tom !== 'number') return

    editTomStatus({
      id: projectId,
      phase: values.phase,
      newStatus: status,
      tom: values.tom,
    })
  }, [projectId, values])

  useMutationHandlers(
    createWorkResponse,
    () => {
      values.tomStatus && changeBoundTomStatusClick(values.tomStatus)
      onClose(false, true)
    },
    (error: any) => {
      enqueueSnackbar(t('works:' + error?.data.split('works.')[1]), { variant: 'error' })
    }
  )

  useMutationHandlers(
    fullEditWorkResponse,
    () => {
      values.tomStatus && changeBoundTomStatusClick(values.tomStatus)
      onClose(false, true)
    },
    (error: any) => {
      enqueueSnackbar(t('works:' + error?.data.split('works.')[1]), { variant: 'error' })
    }
  )

  const onNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const regex = /^\d+\.?(\d?)+$/
    if (e.target.value === '' || regex.test(e.target.value)) {
      setFieldValue('number', e.target.value)
    }
  }

  // bind
  const [openedBindDrawer, setOpenedBindDrawer] = useState<BindDrawer | null>(null)

  const clearObj = useCallback(() => {
    setFieldValue('obj', '')
  }, [])

  const clearTom = useCallback(() => {
    setFieldValue('tom', '')
  }, [])

  const onBindDrawerClose = (drawer: BindDrawer) => {
    setOpenedBindDrawer(null)

    if (drawer === 'tom' && values.tom === 'create') {
      clearTom()
    }

    if (drawer === 'obj') {
      clearObj()
    }
  }

  const onPhaseChange = () => {
    clearObj()
    clearTom()
  }

  const onObjChange = (e: SyntheticEvent, option: OptionData<number | string>) => {
    clearTom()
    if (!option) return
    if (option.value === 'create') {
      setOpenedBindDrawer('obj')
    }
  }

  const onTomChange = (e: SyntheticEvent, option: OptionData<number | string>) => {
    if (!option) return
    if (option.value === 'create') {
      setOpenedBindDrawer('tom')
    } else {
      const selectedTom = tomInfo.find(tom => tom.id === option.value)
      selectedTom && formik.setFieldValue('tomStatus', selectedTom.status)
    }
  }

  // Tom status when bound
  const currentStatusCellView: StatusCellView = values.tom ? 'tom' : 'work'

  const statusMenuData: Record<StatusCellView, string[]> = {
    work: workStatuses,
    tom: tomStatusesArray.filter(status => status === 'Не согласуется' ? values.phase === 'Сбор исходных данных' : true),
  }

  // Автозаполнение номера работы после выбора группы
  useEffect(() => {
    const numberOfsubgroup = numbersOfsubGroup.find(group => group.nameGroup === values.group)
    if (values.group === initialValues.group && values.number === initialValues.number) return
    if (values.group) {
      if (numberOfsubgroup) {
        formik.setFieldValue('number', `${numberOfsubgroup.numGroup}.${numberOfsubgroup.numSubgroup + 1}`)
      } else {
        const lastGroup = workGroups[workGroups.length - 1]?.numGroup
        formik.setFieldValue('number', `${lastGroup ? lastGroup + 1 : 1}.1`)
      }
    }
  }, [numbersOfsubGroup, values.group, dirty])

  return (
    <WorksDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={4} overflow='hidden' height='100%' component={Form}>
          <ScrollableContainer spacing={2.5}>
            <FieldForm
              version='doc'
              name='title'
              placeholder='Наименование работ'
            />

            <Stack spacing={1.5}>
              <SelectFormField<string, WorksFormData>
                fieldName='group'
                title='Группа работ'
                placeholder='Укажите группу работ'
                icon={MappingIcon}
                data={workGroups.map(({ group }) => ({
                  value: group,
                  label: group,
                  key: group,
                }))}
                autocompleteProps={{ autocomplete: true, freeSolo: true }}
              />

              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' spacing={1}>
                  <NumberListIcon fontSize='medium' color='secondary' />
                  <Typography variant='body2' component='span'>Номер:</Typography>
                  <Tooltip variant='light' maxWidth={350} fontSize={14}
                    title={<>Номер работы определяется автоматически в зависимости от выбранной группы работ и может быть переопределен для сохранения последовательности. Допускается одинаковое наименование групп. Для создания новой группы с тем же наименованием либо перемещения работы доступно мануальное изменение номера работы.</>}>
                    <InfoOutlinedIcon fontSize="medium" color="disabled" />
                  </Tooltip>
                </Stack>

                <WorksDrawerNumberField
                  value={values.number}
                  version='small'
                  name='number'
                  placeholder='Укажите номер работы'
                  onChange={onNumberChange}
                  InputProps={{
                    endAdornment: <NumberIcon fontSize='medium' />,
                  }}
                />
              </Stack>

              <SelectFormField<string, WorksFormData>
                fieldName={currentStatusCellView === 'tom' ? 'tomStatus' : 'status'}
                title='Статус'
                placeholder='Укажите статус'
                icon={ErrorOutlineIcon}
                data={statusMenuData[currentStatusCellView].map(status => ({
                  value: status,
                  label: status,
                  key: status,
                }))}
                autocompleteProps={{ autocomplete: true, freeSolo: false }}
              />


              <SelectFormField<number | string, WorksFormData>
                fieldName='personData'
                title='Ответственный'
                placeholder='Укажите ответственного'
                icon={PersonIcon}
                data={workPersons.map(({ id, name }) => ({
                  value: id,
                  label: name,
                  key: id,
                }))}
                autocompleteProps={{ autocomplete: true, freeSolo: true }}
              />
            </Stack>

            <Stack spacing={2}>
              <Box>
                <Typography variant='body2'>Привязка к документам:</Typography>
              </Box>

              <SelectFormField<string, WorksFormData>
                fieldName='phase'
                title='Стадия'
                placeholder='Стадия работ'
                icon={GradingIcon}
                data={allProjectsPhases.map(phase => ({
                  value: phase,
                  label: phase,
                  key: phase,
                }))}
                parentOnChange={onPhaseChange}
                autocompleteProps={{ autocomplete: true, freeSolo: false }}
              />

              {values.phase === 'Рабочая документация'
                ? (
                  <SelectFormField<number | string, WorksFormData>
                    fieldName='obj'
                    title='Объект'
                    placeholder='Укажите объект'
                    icon={ObjectsIcon}
                    data={objData}
                    parentOnChange={onObjChange}
                    disabled={!values.phase}
                    autocompleteProps={{ autocomplete: true, freeSolo: false }}
                  />
                )
                : (
                  <SelectFormField<number | string, WorksFormData>
                    fieldName='obj'
                    title='Раздел'
                    placeholder='Раздел документации'
                    icon={EditDocIcon}
                    data={objData}
                    parentOnChange={onObjChange}
                    disabled={!values.phase}
                    autocompleteProps={{ autocomplete: true, freeSolo: false }}
                  />
                )
              }

              <SelectFormField<number | string, WorksFormData>
                fieldName='tom'
                title='Том'
                placeholder='Укажите том'
                icon={InsertDriveFileOutlinedIcon}
                data={tomData}
                parentOnChange={onTomChange}
                disabled={!values.obj}
                autocompleteProps={{ autocomplete: true, freeSolo: false }}
              />
            </Stack>

            <Stack spacing={2}>
              <Box>
                <Typography variant='body2'>Плановые сроки реализации:</Typography>
              </Box>

              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={1} pt={0.75}>
                  <DateRangeIcon fontSize='medium' color='secondary' />
                  <Typography variant='body2' component='span'>Дата начала:</Typography>
                </Stack>

                <FieldForm
                  version='date'
                  name='datePlanStart'
                  dataValue={values.datePlanStart}
                  onDataChange={(value: Date | null) => setFieldValue('datePlanStart', value)}
                  inputProps={{
                    placeholder: 'дд.мм.год',
                  }}
                  sx={{ maxWidth: 140 }}
                />
              </Stack>

              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={1} pt={0.75}>
                  <CalendarMonthIcon fontSize='medium' color='secondary' />
                  <Typography variant='body2' component='span'>Дата окончания:</Typography>
                </Stack>

                <FieldForm
                  version='date'
                  name='datePlanEnd'
                  dataValue={values.datePlanEnd}
                  onDataChange={(value: Date | null) => setFieldValue('datePlanEnd', value)}
                  inputProps={{
                    placeholder: 'дд.мм.год',
                  }}
                  sx={{ maxWidth: 140 }}
                />
              </Stack>
            </Stack>

            <Stack spacing={2}>
              <Box>
                <Typography variant='body2'>Фактические сроки реализации:</Typography>
              </Box>

              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={1} pt={0.75}>
                  <CalendarWithClockIcon fontSize='medium' color='secondary' />
                  <Typography variant='body2' component='span'>Дата начала:</Typography>
                </Stack>

                <FieldForm
                  version='date'
                  name='dateFactStart'
                  dataValue={values.dateFactStart}
                  onDataChange={(value: Date | null) => setFieldValue('dateFactStart', value)}
                  inputProps={{
                    placeholder: 'дд.мм.год',
                  }}
                  sx={{ maxWidth: 140 }}
                />
              </Stack>

              {values.status === 'Выполнено' && (
                <Stack direction='row' justifyContent='space-between'>
                  <Stack direction='row' spacing={1} pt={0.75}>
                    <EventAvailableIcon fontSize='medium' color='secondary' />
                    <Typography variant='body2' component='span'>Дата выполнения:</Typography>
                  </Stack>

                  <FieldForm
                    version='date'
                    name='dateFactEnd'
                    dataValue={values.dateFactEnd}
                    onDataChange={(value: Date | null) => setFieldValue('dateFactEnd', value)}
                    inputProps={{
                      placeholder: 'дд.мм.год',
                    }}
                    sx={{ maxWidth: 140 }}
                  />
                </Stack>
              )}
            </Stack>

            <Stack spacing={2}>
              <Box>
                <Typography variant='body2'>Выполнение работы:</Typography>
              </Box>

              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={1} pt={0.75}>
                  <PercentCircleIcon fontSize='medium' color='secondary' />
                  <Typography variant='body2' component='span' whiteSpace='nowrap'>Вес работы:</Typography>
                </Stack>

                <FieldForm
                  value={values.weight}
                  version='small'
                  name='weight'
                  placeholder='0'
                  type='number'

                  onKeyDown={e => {
                    if (e.key === '-' || e.key === 'e') {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    setFieldValue('weight', e.target.value.slice(0, 6))
                  }}
                  inputProps={{
                    min: '0',
                    step: 0.01
                  }}
                  InputProps={{
                    endAdornment: '%'
                  }}
                  style={{ maxWidth: 140 }}
                />
              </Stack>

              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={1} pt={0.75}>
                  <PercentCircleIcon fontSize='medium' color='secondary' />
                  <Typography variant='body2' component='span'>Готовность:</Typography>
                </Stack>

                <FieldForm
                  version='small'
                  name='ready'
                  placeholder='0'
                  type='number'
                  onKeyDown={e => {
                    if (e.key === '-' || e.key === 'e') {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    setFieldValue('ready', e.target.value.slice(0, 6))
                  }}
                  inputProps={{
                    min: '0',
                    step: 0.01
                  }}
                  InputProps={{
                    endAdornment: '%'
                  }}
                  style={{ maxWidth: 140 }}
                />
              </Stack>
            </Stack>

            <Stack spacing={2}>
              <Box>
                <Typography variant='body2'>Комментарий:</Typography>
              </Box>
              <FieldForm
                version='rd'
                name='description'
                placeholder='Комментарий или описание если необходимо'
                multiline
                minRows={3}
              />
            </Stack>
          </ScrollableContainer>

          <Stack direction='row' spacing={2} pr={2.5}>
            <Button
              type='submit'
              disabled={isSubmitting || !dirty}
              color='success'
              size='medium'
              fullWidth
            >
              Сохранить
            </Button>
            <Button size='medium' fullWidth onClick={() => onClose(dirty)}>Отменить</Button>
          </Stack>
        </Stack>

        <ObjDrawer
          phase={values.phase || undefined}
          projectId={projectId}
          open={openedBindDrawer === 'obj'}
          onClose={onBindDrawerClose}
        />

        <TomDrawer
          phase={values.phase || undefined}
          projectId={projectId}
          open={openedBindDrawer === 'tom'}
          onClose={onBindDrawerClose}
        />
      </FormikProvider>
    </WorksDrawerFormWrapper>
  )
}
