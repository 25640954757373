import { FC } from 'react'
import { IWidgetsSectionProps } from './WidgetsSection.types'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { Stack } from '@mui/material'
import { WidgetsSectionItem } from '../WidgetsSectionItem'
import { useFormikContext } from 'formik'
import { IWidgetsDrawerFormData } from '../../WidgetsDrawer.types'

export const WidgetsSection: FC<IWidgetsSectionProps> = () => {
  const { values, setFieldValue } = useFormikContext<IWidgetsDrawerFormData>()

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const sortedWidgets = reorder(values.widgets, result.source.index, result.destination.index)

    setFieldValue('widgets', sortedWidgets)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} style={{ overflow: 'auto' }}>
            <Stack>
              {values.widgets.map((widget, index) => (
                <WidgetsSectionItem data={widget} index={index} />
              ))}
            </Stack>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
