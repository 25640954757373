import styled from 'styled-components'
import { Stack } from '@mui/material'
import { LegendColors } from '../../../../../../types/global'

interface ItemWrapperProps {
  clickable: boolean
}

export const ItemWrapper = styled(Stack)<ItemWrapperProps>`
  padding: 4px 6px;
  padding-left: 0;
  border-radius: 6px;

  &:hover {
    outline: ${props => props.clickable && `1px solid ${props.theme.palette.primary.light}`};
  }

  & .MuiTypography-root {
    color: ${props => props.theme.palette.text.dark};
    color: ${props => props.clickable && props.theme.palette.primary.main};
  }
`

interface HeaderWrapperProps {
  clickable: boolean
}

export const HeaderWrapper = styled(ItemWrapper)<HeaderWrapperProps>`
  padding-left: 6px;
  background-color: ${props => props.theme.palette.bg.shades};

  &:hover {
    outline: ${props => props.clickable && `1px solid ${props.theme.palette.primary.light}`};
  }

  & .MuiTypography-root {
    color: ${props => props.clickable && props.theme.palette.primary.main};
  }
`

interface HeaderPercentageProps {
  backgroundColor: LegendColors;
}

export const HeaderPercentage = styled(Stack)<HeaderPercentageProps>`
  justify-content: center;
  align-content: center;
  width: 38px;
  height: 18px;
  padding: 2.5px 0;
  background-color: ${props => props.theme.palette.legends[props.backgroundColor]};
  border-radius: 6px;

  & .MuiTypography-root {
    font-size: 12px;
    color: ${props => props.theme.palette.text.light} !important;
  }
`

export const SubItemColor = styled(Stack)<HeaderPercentageProps>`
  width: 12px;
  height: 12px;
  background-color: ${props => props.theme.palette.legends[props.backgroundColor]};
  border-radius: 50%;
`
