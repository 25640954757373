import styled from 'styled-components'
import { MenuItem } from '@mui/material'
import { LegendColors } from '../../../../types/global'

interface StyledSelectMenuItemProps {
  checked?: boolean;
  customColor?: LegendColors
}

export const StyledSelectMenuItem = styled(MenuItem)<StyledSelectMenuItemProps>`
  color: ${props => props.customColor && props.theme.palette.legends[props.customColor]};
  background-color: ${props => props.checked && props.theme.palette.bg.gray};

  &:hover {
    color: ${props => props.theme.palette.primary.main};
    background-color: ${props => props.theme.palette.bg.shades};
  }

  &.Mui-focusVisible {
    background-color: ${props => props.theme.palette.bg.gray};
  }
  
  &.select-item-button {
    color: ${props => props.theme.palette.text.light};
    background-color:  ${props => props.theme.palette.primary.light};
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    
    &:hover {
      background-color:  ${props => props.theme.palette.primary.main};
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`

export const DisabledSelectMenuItem = styled(StyledSelectMenuItem)`
  &.Mui-disabled {
    background-color: ${props => props.theme.palette.bg.white};
  }
`