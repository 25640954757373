import styled from 'styled-components'
import {
  FullExportDrawerSelectedListWrapper
} from '../../../../../ProjectInfo/components/ProjectFullExportDrawer/components/FullExportDrawerSelectedList'

import { StyledSelectMenuItem } from '../../../../../../components/UserManagement/components/UserRoleSelection/styles'
import { alpha } from '@mui/material'
import { AgreementSchemeTitleField } from '../../../../../Schemes/ProjectAgreement/components/ProjectAgreementLegend'
import { ProjectAgreementLegendPersonBlockAutocomplete } from '../../../../../Schemes/ProjectAgreement/components/ProjectAgreementLegend/ProjectAgreementLegendPersonBlock'

export const FormPocketDrawerSelectedListWrapper = styled(FullExportDrawerSelectedListWrapper)`
`

interface FormPocketDrawerSelectedListTextFieldProps {
  error: boolean
}
export const FormPocketDrawerSelectedListTextField = styled(AgreementSchemeTitleField)<FormPocketDrawerSelectedListTextFieldProps>`
  & .MuiInputBase-root {
    border-color: ${props => props.error && props.theme.palette.error.main};
  }
  
  & .MuiFormHelperText-root {
    display: none;
  }
`

export const FormPocketDrawerSelectedListAutocomplete = styled(ProjectAgreementLegendPersonBlockAutocomplete)`
  min-width: 230px;
`

interface FormPocketSelectedListAutocompleteItemProps {
  isBroken: boolean
}

export const FormPocketSelectedListAutocompleteItem = styled(StyledSelectMenuItem)<FormPocketSelectedListAutocompleteItemProps>`
  justify-content: space-between !important;
  gap: 8px;
  background-color: ${props => props.isBroken && alpha(props.theme.palette.table.yellow, 0.15)};
  opacity: 1 !important;

  & .number {
    border-color: ${props => props.isBroken && props.theme.palette.legends.lightOrange};

    & .MuiTypography-root {
      color: ${props => props.isBroken && props.theme.palette.legends.lightOrange};
    }
  }

  & .MuiTypography-root {
    max-width: ${props => props.isBroken ? 175 : 210}px;
    color: ${props => props.isBroken && props.theme.palette.disabled};
  }
`