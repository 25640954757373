import React from 'react'
import { UseGetDetailedSidebarItemsProps, UseGetDetailedSidebarItemsResponse } from './useGetDetailedSidebarItems.types'
import { useGetDetailedSidebarObjectsQuery } from '../../../../api/rdPhase'
import { useGetDetailedSidebarPdsQuery } from '../../../../api/pdPhase'
import { useGetDetailedSidebarIiQuery } from '../../../../api/iiPhase'
import { useGetDetailedSidebarIrdQuery } from '../../../../api/irdPhase'
import { DetailedSidebarItems } from '../../../../store/slices/documentsPages/sidebar'

export const useGetDetailedSidebarItems = ({
  projectId,
  currentPage,
  selectedProjectPhase,
}: UseGetDetailedSidebarItemsProps): UseGetDetailedSidebarItemsResponse => {

  // Rd
  const {
    data: detailedSidebarObjectsData,
    isFetching: detailedSidebarObjectsFetching
  } = useGetDetailedSidebarObjectsQuery(
    { id: projectId },
    { skip: currentPage !== 'doc-view' || selectedProjectPhase !== 'Рабочая документация' }
  )
  const { menuList: detailedSidebarObjects = [] } = detailedSidebarObjectsData || {}


  // Pd
  const { data: detailedSidebarPdsData, isFetching: detailedSidebarPdsFetching } = useGetDetailedSidebarPdsQuery(
    { id: projectId },
    { skip: currentPage !== 'doc-view' || selectedProjectPhase !== 'Проектная документация' }
  )
  const { menuList: detailedSidebarPds = [] } = detailedSidebarPdsData || {}


  // Ii
  const { data: detailedSidebarIisData, isFetching: detailedSidebarIisFetching } = useGetDetailedSidebarIiQuery(
    { id: projectId },
    { skip: currentPage !== 'doc-view' || selectedProjectPhase !== 'Инженерные изыскания' }
  )
  const { menuList: detailedSidebarIis = [] } = detailedSidebarIisData || {}


  // Ird
  const { data: detailedSidebarIrdsData, isFetching: detailedSidebarIrdsFetching } = useGetDetailedSidebarIrdQuery(
    { id: projectId },
    { skip: currentPage !== 'doc-view' || selectedProjectPhase !== 'Сбор исходных данных' }
  )
  const { menuList: detailedSidebarIrds = [] } = detailedSidebarIrdsData || {}


  // Common
  const detailedSidebarItems: DetailedSidebarItems = {
    detailedSidebarObjects,
    detailedSidebarPds,
    detailedSidebarIis,
    detailedSidebarIrds,
  }

  const detailedSidebarDataLoading =
    detailedSidebarObjectsFetching
    || detailedSidebarPdsFetching
    || detailedSidebarIisFetching
    || detailedSidebarIrdsFetching

  return {
    detailedSidebarItems,
    detailedSidebarDataLoading,
  }
}
