import { Avatar, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetAgreementOverviewQuery } from '@api/agreementOverview'
import { StageMember } from '@api/agreementOverview/types'

import ColoredTitle from '@components/ColoredTitle'
import { OverflowTextNew } from '@components/OverflowTextNew'
import Progress from '@components/Progress'

import useQuery from '@hooks/useQuery'

import { TomType, tomStatusesColors } from '@typesGlobal/tom'

import {
  BlockWrapper,
  OverflowingText,
  PageSection,
  RightBlock,
  SchemaSeparator,
  TitleNormalText,
  TitleText,
  UserBlock,
} from './AgreementOverview.styles'
import { AgreementOverviewSchema } from './components/AgreementOverviewSchema'
import { InnermapDrawer } from './components/InnermapDrawer'
import { InnermapOverview } from './components/InnermapOverview'
import { TomInteractionBlock } from './components/TomInteractionBlock'

export const AgreementOverview = () => {
  const { projectId, tomId: tomIdString } = useParams()
  const id = Number(projectId)
  const tomId = Number(tomIdString)
  const queryHandler = useQuery()
  const type: TomType | null = queryHandler.get('type')
  const [open, setOpen] = useState<boolean>(false)
  const [innermapMembers, setInnermapMembers] = useState<StageMember[]>([])

  const { data, isLoading } = useGetAgreementOverviewQuery(
    { id, tomId, type: type! },
    { skip: !type, refetchOnMountOrArgChange: true },
  )
  const { innermap, remarks, complete, roadmap, tom } = data || {}
  const currentStageNumber = roadmap?.stages.find((stage) => stage.isCurrent)?.num

  const onClickInnermapStage = (members: StageMember[]) => {
    setInnermapMembers(members)
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
    setInnermapMembers([])
  }

  if (!isLoading && tom && roadmap && remarks) {
    return (
      <Stack width='100%' spacing={1.25} pt={2}>
        <PageSection height='76px' textAlign='start'>
          <BlockWrapper minWidth='185px' width='10%'>
            <TitleText>Шифр РД</TitleText>
            <OverflowTextNew rows={2}>{tom.title}</OverflowTextNew>
          </BlockWrapper>
          <BlockWrapper minWidth='200px' width='52%'>
            <TitleText>Наименование</TitleText>
            <OverflowingText>{tom.description}</OverflowingText>
          </BlockWrapper>
          <RightBlock direction='row' minWidth='675px' width='38%'>
            <BlockWrapper width='15%' minWidth='89px'>
              <TitleText>Версия</TitleText>
              <Typography>{tom.version === null ? '—' : tom.version}</Typography>
            </BlockWrapper>
            <BlockWrapper width='15%' minWidth='112px'>
              <TitleText>Изменения</TitleText>
              <Typography>{tom.change === null ? '—' : tom.change}</Typography>
            </BlockWrapper>
            <BlockWrapper width='20%' minWidth='148px'>
              <TitleText>Плановая дата</TitleText>
              <Typography>{tom.plannedDate}</Typography>
            </BlockWrapper>
            <BlockWrapper width='20%' minWidth='148px'>
              <TitleText>Прогнозная дата</TitleText>
              <Typography>{tom.forecastDate || '—'}</Typography>
            </BlockWrapper>
            <BlockWrapper width='30%' minWidth='178px'>
              <TitleText>Статус</TitleText>
              <ColoredTitle
                body={tom.status}
                color={tomStatusesColors[tom.status]}
                style={{ width: '132px', justifyContent: 'center' }}
              />
            </BlockWrapper>
          </RightBlock>
        </PageSection>
        <PageSection height='164px'>
          <Stack width='62%' position='relative'>
            <AgreementOverviewSchema onClickInnermapStage={onClickInnermapStage} stages={roadmap.stages} />
            <SchemaSeparator />
          </Stack>
          {roadmap.status === 'ПРОХОЖДЕНИЕ' ? (
            <UserBlock minWidth='675px' width='38%'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Avatar src={roadmap.responsible.avatar || undefined} sx={{ width: 32, height: 32 }} />
                <Stack>
                  <Typography>{roadmap.responsible.name}</Typography>
                  <TitleNormalText>{roadmap.responsible.company || ''}</TitleNormalText>
                </Stack>
              </Stack>
              {innermap && innermap.responsible.id !== roadmap.responsible.id && (
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Avatar src={innermap.responsible.avatar || undefined} sx={{ width: 32, height: 32 }} />
                  <Stack>
                    <Typography>{innermap.responsible.name}</Typography>
                    <TitleNormalText>{innermap.responsible.company || ''}</TitleNormalText>
                  </Stack>
                </Stack>
              )}
            </UserBlock>
          ) : (
            <Stack minWidth='675px' width='38%' margin='auto'>
              <Typography variant='body2'>
                {roadmap.status === 'ПРОЙДЕНО' ? 'Процедура завершена' : 'Процедура ожидает подтверждения'}
              </Typography>
            </Stack>
          )}
        </PageSection>
        <PageSection flex={1}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            alignSelf='flex-start'
            p={3}
            width='100%'
          >
            <TomInteractionBlock remarks={remarks} title={tom.title} />

            <InnermapOverview
              buttonAvailable={remarks.buttonAvailable}
              innermap={innermap}
              complete={complete}
              currentStageNumber={currentStageNumber}
            />
          </Stack>
        </PageSection>
        <InnermapDrawer open={open} members={innermapMembers} closeDrawer={closeDrawer} />
      </Stack>
    )
  } else
    return (
      <Stack width='100%' spacing={1.25} pt={2}>
        <Stack height={76} borderRadius={'10px'} bgcolor={'white'}>
          <Progress />
        </Stack>
        <Stack height={164} borderRadius={'10px'} bgcolor={'white'}>
          <Progress />
        </Stack>
        <Stack sx={{ flex: 1 }} borderRadius={'10px'} bgcolor={'white'}>
          <Progress />
        </Stack>
      </Stack>
    )
}
