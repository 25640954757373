import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useMemo, useRef, useState } from 'react';
import { StyledAgGridWrapper } from "../../../../styles/global/StyledAgGridWrapper"
import { AgGridReact } from 'ag-grid-react';
import { CellClickedEvent, ColDef, ColumnResizedEvent, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import Progress from '../../../../components/Progress';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { AgGridPocketsProps, PocketParams, PocketRowData, RecieverParams, RoadmapRecieverCellParams, RoadmapTitleCellParams, RoadmapTitleParams, SenderParams } from './AgGridPockets.types';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { OverflowText } from '../../../../components/OverflowText/OverflowText';
import { InfoWrapper, StyledBox, ElipsisText, ConfirmButton } from './AgGridPockets.styles';
import { useAppDispatch, useTypedSelector } from '../../../../store/store';
import { profileSelector } from '../../../../store/slices/profile';
import { useStartAgreementProcedureMutation } from '../../../../api/agreementPocket';
import Tooltip from '../../../../components/Tooltip';
import { allowScrollingSelector } from '../../../../store/slices/remarks/selectors/remarks.selectors';
import { setAllowScrolling } from '../../../../store/slices/remarks/remarks';

const DEFAULT_ROW_HEIGHT = 60;

const PocketCell = (params: ICellRendererParams) => {
  const { title, tomCount }: PocketParams = params.value
  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} px={2} py={1}>
      <OverflowText mode='table' text={title} fontWeight={500} defaultHeight={DEFAULT_ROW_HEIGHT - 16} />
      <InfoWrapper $autoWidth>
        <InsertDriveFileOutlinedIcon fontSize='small' color='secondary' />
        <Typography variant='body2' fontSize={12}>{tomCount}</Typography>
      </InfoWrapper>
    </Stack>
  )
}

const RoadmapTitleCell = (params: RoadmapTitleCellParams) => {
  const { phase, title }: RoadmapTitleParams = params.value
  return (
    <Stack direction='row' alignItems='center' justifyContent="space-between" spacing={1} px={2} py={1}>
      <OverflowText mode='table' text={title} defaultHeight={DEFAULT_ROW_HEIGHT - 16} />
      {params.allPhases && <StyledBox>{phase}</StyledBox>}
    </Stack>
  )
}

const SenderCell = (params: ICellRendererParams) => {
  const { company, dateSend, id, name, avatar }: SenderParams = params.value
  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} px={2}>
      <Stack direction='row' spacing={1.5} alignItems='center' sx={{ width: 'calc(100% - 170px)' }}>
        <Avatar src={avatar || undefined} sx={{ height: 32, width: 32 }} />
        <Box textAlign='start' width='calc(100% - 44px)'>
          <ElipsisText >{company}</ElipsisText>
          <Typography variant='body1' fontSize={14}>{name}</Typography>
        </Box>
      </Stack>
      <InfoWrapper>
        <Typography variant='body2' fontSize={12}>{`Отправлен: ${dateSend}`}</Typography>
      </InfoWrapper>
    </Stack>
  )
}

const RecieverCell = (params: RoadmapRecieverCellParams) => {
  const { company, id, name, avatar, dateReceive, pocketId }: RecieverParams = params.value
  const { employeeId, role, onConfirmClick } = params
  const isAdmin = role === 'admin'

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} px={2}>
      <Stack direction='row' spacing={1.5} alignItems='center' sx={{ width: 'calc(100% - 215px)' }}>
        <Avatar src={avatar || undefined} sx={{ height: 32, width: 32 }} />
        <Box textAlign='start' width='100%'>
          <ElipsisText>{company}</ElipsisText>
          <Typography variant='body1' fontSize={14}>{name}</Typography>
        </Box>
      </Stack>
      {dateReceive ?
        (
          <InfoWrapper>
            <Typography variant='body2' fontSize={12}>{`Получен: ${dateReceive}`}</Typography>
          </InfoWrapper>
        ) :
        isAdmin || employeeId === id ? (
          <Tooltip variant='light' icon
            title={<>После подтверждения получения документации по накладной запустится процедура согласования.</>}>
            <ConfirmButton onClick={() => onConfirmClick(pocketId)}>Подтвердить получение</ConfirmButton>
          </Tooltip>
        ) : (
          <InfoWrapper $color='awaiting'>
            <Typography variant='body2' fontSize={12}>Ожидает подтверждения</Typography>
          </InfoWrapper>
        )}
    </Stack>
  )
}

export const AgGridPockets = ({ pockets, allPhases }: AgGridPocketsProps) => {

  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { employeeId, role } = useTypedSelector(profileSelector)
  const flexCell = 'ag-cell_flex_column ag-cell_justify-content_center'
  const isSavedColumnState = !!localStorage.getItem('pocketsColumnState')
  const gridRef = useRef<AgGridReact>(null)
  const dispatch = useAppDispatch()
  const { allowScrolling } = useTypedSelector(allowScrollingSelector)

  const [startAgreement, startAgreementResponse] = useStartAgreementProcedureMutation({ fixedCacheKey: 'startAgreement' })

  const onConfirmClick = (pocketId: number) => {
    startAgreement({ pocketId, projectId })
    dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: true, mode: 'create' } }))
  }

  const [columnDef, setColumnDef] = useState<ColDef[]>([
    {
      field: 'pocket',
      headerName: 'Накладная',
      flex: !isSavedColumnState ? 1 : undefined,
      minWidth: 192,
      cellRenderer: PocketCell,
      cellClass: [flexCell, 'ag-cell_overflow_visible'],
    },
    {
      field: 'roadmapTitle',
      headerName: 'Наименование схемы согласования',
      flex: !isSavedColumnState ? 1 : undefined,
      minWidth: 250,
      cellClass: [flexCell, 'ag-cell_overflow_visible'],
      cellRenderer: RoadmapTitleCell,
      cellRendererParams: { allPhases },
    },
    {
      field: 'sender',
      headerName: 'Отправитель',
      flex: !isSavedColumnState ? 2 : undefined,
      minWidth: 322,
      cellRenderer: SenderCell,
      cellClass: [flexCell],
    },
    {
      field: 'receiver',
      headerName: 'Получатель',
      flex: !isSavedColumnState ? 2 : undefined,
      minWidth: 322,
      cellRenderer: RecieverCell,
      cellRendererParams: {
        employeeId,
        role,
        onConfirmClick
      },
      cellClass: [flexCell],
    },
  ])

  const rowData = useMemo((): PocketRowData[] => {
    if (!pockets) return []
    return pockets.map((pocket) => {
      return {
        pocket: {
          id: pocket.id,
          title: pocket.title,
          tomCount: pocket.tomCount,
        },
        roadmapTitle: {
          phase: pocket.phase,
          title: pocket.roadmapTitle,
        },
        sender: {
          avatar: pocket.sender.avatar,
          company: pocket.sender.company,
          id: pocket.sender.id,
          name: pocket.sender.name,
          dateSend: pocket.dateSend,
        },
        receiver: {
          avatar: pocket.receiver.avatar,
          company: pocket.receiver.company,
          id: pocket.receiver.id,
          name: pocket.receiver.name,
          dateReceive: pocket.dateReceive,
          pocketId: pocket.id,
        }
      }
    })
  }, [pockets])

  const gridReadyListener = useCallback((e: GridReadyEvent) => {
    const savedColumnState = localStorage.getItem('pocketsColumnState')
    if (savedColumnState) {
      e.columnApi.applyColumnState({
        state: JSON.parse(savedColumnState),
        applyOrder: true
      })
    }

    if (startAgreementResponse.data && allowScrolling) {
      const updatedRow = pockets.findIndex(pocket => pocket.id === startAgreementResponse.data?.id)
      const editedCell = updatedRow === 0 ? 1 : updatedRow
      const editedRowNode = e.api.getDisplayedRowAtIndex(updatedRow)!
      if (editedCell && editedRowNode) {
        e.api.showLoadingOverlay()
        setTimeout(() => {
          e.api.ensureIndexVisible(editedCell, 'middle')
          e.api.flashCells({ rowNodes: [editedRowNode], flashDelay: 3000, fadeDelay: 1000 })
        }, 250)
        setTimeout(() => {
          e.api.hideOverlay()
          dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: false, mode: 'create' } }))
        }, 500)
      }
    }

  }, [startAgreementResponse, allowScrolling])

  const columnResizedListener = useCallback((e: ColumnResizedEvent) => {
    const columnState = e.columnApi.getColumnState()
    if (e.finished) {
      localStorage.setItem('pocketsColumnState', JSON.stringify(columnState))
    }
  }, [])

  const cellClickedListener = (e: CellClickedEvent<PocketRowData>) => {
    const pocketId = e.data?.pocket.id
    const notAllowedCell = e.colDef.field === 'receiver'
    if (notAllowedCell) return
    navigate(`${pocketId}`)
  }

  return (
    <StyledAgGridWrapper>
      <div className='ag-theme-alpine'>
        <AgGridReact
          ref={gridRef}
          onCellClicked={cellClickedListener}
          onGridReady={gridReadyListener}
          onColumnResized={columnResizedListener}
          loadingOverlayComponent={Progress}
          columnDefs={columnDef}
          rowData={rowData}
          headerHeight={32}
          detailRowAutoHeight
          rowHeight={DEFAULT_ROW_HEIGHT}
          suppressRowTransform={true}
          suppressDragLeaveHidesColumns={true}
          suppressColumnMoveAnimation={false}
          overlayNoRowsTemplate="Нет данных для отображения"
          defaultColDef={{
            cellDataType: false,
            editable: false,
            sortable: false,
            filter: false,
            resizable: true,
          }} />
      </div>
    </StyledAgGridWrapper>
  )
}