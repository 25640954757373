import { api } from '../api'
import {
  GetInnermapsMenuResponse,
  GetInnermapsMenuRequest,
  CreateInnermapRequest,
  CreateInnermapResponse,
  EditInnermapRequest,
  EditInnermapResponse,
  DeleteInnermapResponse,
  DeleteInnermapRequest,
  GetInnermapDepartmentListRequest,
  GetInnermapDepartmentListResponse,
  GetInnermapAvailableMembersListRequest,
  GetInnermapAvailableMembersListResponse,
  AddInnermapMemberRequest,
  AddInnermapMemberResponse,
  GetInnermapMembersRequest,
  GetInnermapMembersResponse,
  MoveInnermapDepartmentRequest,
  MoveInnermapMemberRequest,
  DeleteInnermapDepartmentRequest,
  DeleteInnermapMemberRequest,
  EditInnermapMemberRequest,
  EditInnermapMemberResponse,
  SendTomForInternalAgreementResponse,
  SendTomForInternalAgreementRequest,
  GetInnermapTomProceduresResponse,
  GetInnermapTomProceduresRequest,
  SendMemberActionInternalAgreementResponse,
  SendMemberActionInternalAgreementRequest,
  GetInnermapsOfCompanyRequest,
  GetInnermapByIdResponse,
  GetInnermapByIdRequest
} from './api.types'
import { MenuInnermap } from './types'

export const internalAgreementApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInnermapsMenu: build.query<GetInnermapsMenuResponse, GetInnermapsMenuRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/innermap/menu`,
        method: 'GET',
      }),
      providesTags: ['InternalAgreement'],
    }),
    getInnermapById: build.query<GetInnermapByIdResponse, GetInnermapByIdRequest>({
      query: ({ id, innermapId }) => ({
        url: `/project/${id}/innermap/${innermapId}/get`,
        method: 'GET',
      }),
      providesTags: ['InternalAgreement'],
    }),
    createInnermap: build.mutation<CreateInnermapResponse, CreateInnermapRequest>({
      query: ({ id, body }) => ({
        url: `/project/${id}/innermap/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InternalAgreement'],
    }),
    editInnermap: build.mutation<EditInnermapResponse, EditInnermapRequest>({
      query: ({ id, innermapId, ...body }) => ({
        url: `/project/${id}/innermap/${innermapId}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InternalAgreement', 'TomAgreement'],
    }),
    deleteInnermap: build.mutation<DeleteInnermapResponse, DeleteInnermapRequest>({
      query: ({ id, innermapId }) => ({
        url: `/project/${id}/innermap/${innermapId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InternalAgreement', 'TomAgreement'],
    }),
    getInnermapDepartmentList: build.query<GetInnermapDepartmentListResponse, GetInnermapDepartmentListRequest>({
      query: ({ projectId, innermapId }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/member/department/list`,
        method: 'GET',
      }),
      providesTags: ['InnermapDepartment'],
    }),
    getInnermapAvailableMembersList: build.query<GetInnermapAvailableMembersListResponse, GetInnermapAvailableMembersListRequest>({
      query: ({ projectId, innermapId }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/member/persons`,
        method: 'GET',
      }),
      providesTags: ['InnermapPersons'],
    }),
    getInnermapMembers: build.query<GetInnermapMembersResponse, GetInnermapMembersRequest>({
      query: ({ projectId, innermapId }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/member/list`,
        method: 'GET',
      }),
      providesTags: ['InnermapPersons'],
    }),
    addInnermapMember: build.mutation<AddInnermapMemberResponse, AddInnermapMemberRequest>({
      query: ({ projectId, innermapId, ...body }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/member/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InnermapPersons', 'InternalAgreement', 'InnermapDepartment', 'TomAgreement'],
    }),
    editInnermapMember: build.mutation<EditInnermapMemberResponse, EditInnermapMemberRequest>({
      query: ({ projectId, innermapId, memberId, ...body }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/member/${memberId}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InnermapPersons', 'InternalAgreement', 'TomAgreement'],
    }),
    moveInnermapDepartment: build.mutation<{}, MoveInnermapDepartmentRequest>({
      query: ({ projectId, innermapId, ...body }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/member/department/order`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InnermapPersons'],
    }),
    moveInnermapMember: build.mutation<{}, MoveInnermapMemberRequest>({
      query: ({ projectId, innermapId, memberId, ...body }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/member/${memberId}/order`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InnermapPersons'],
    }),
    deleteInnermapDepartment: build.mutation<{}, DeleteInnermapDepartmentRequest>({
      query: ({ projectId, innermapId, ...body }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/member/department/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InnermapPersons', 'InternalAgreement', 'TomAgreement'],
    }),
    deleteInnermapMember: build.mutation<{}, DeleteInnermapMemberRequest>({
      query: ({ projectId, innermapId, memberId }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/member/${memberId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InnermapPersons', 'InternalAgreement', 'TomAgreement'],
    }),
    sendTomForInternalAgreement: build.mutation<SendTomForInternalAgreementResponse, SendTomForInternalAgreementRequest>({
      query: ({ projectId, innermapId, ...body }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/tom/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TomAgreement']
    }),
    getInnermapTomProcedures: build.query<GetInnermapTomProceduresResponse, GetInnermapTomProceduresRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/innermap/tom/${tomId}/logs`,
        method: 'GET',
        params
      }),
      providesTags: ['TomAgreement']
    }),
    sendMemberActionInternalAgreement: build.mutation<SendMemberActionInternalAgreementResponse, SendMemberActionInternalAgreementRequest>({
      query: ({ projectId, innermapId, memberId, ...body }) => ({
        url: `/project/${projectId}/innermap/${innermapId}/member/${memberId}/action`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InnermapPersons', 'TomAgreement'],
    }),
    getInnermapsOfCompany: build.query<MenuInnermap, GetInnermapsOfCompanyRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/project/${projectId}/innermap/bind-menu`,
        method: 'GET',
        params,
      }),
      providesTags: ['InternalAgreement'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetInnermapsMenuQuery,
  useGetInnermapByIdQuery,
  useCreateInnermapMutation,
  useEditInnermapMutation,
  useDeleteInnermapMutation,
  useGetInnermapDepartmentListQuery,
  useGetInnermapAvailableMembersListQuery,
  useGetInnermapMembersQuery,
  useAddInnermapMemberMutation,
  useEditInnermapMemberMutation,
  useMoveInnermapDepartmentMutation,
  useMoveInnermapMemberMutation,
  useDeleteInnermapDepartmentMutation,
  useDeleteInnermapMemberMutation,
  useSendTomForInternalAgreementMutation,
  useGetInnermapTomProceduresQuery,
  useSendMemberActionInternalAgreementMutation,
  useGetInnermapsOfCompanyQuery,
} = internalAgreementApi

